import { Checkbox, Grid, Stack, Text, TitleFour } from '@shared/components'
import { PrimaryInsurancePlanType } from '@shared/types'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { useInsuranceCardFormContext } from '../../../../utils/encounterUtils'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'
import { LabeledData } from '../components/LabeledData'

export type InsuranceCardSectionProps = {
  isEditing: boolean
  onCancel: () => void
  onSave: () => void
  saving: boolean
}

const ReadOnlyHeader = ({ form }: { form: ReturnType<typeof useInsuranceCardFormContext> }) => {
  if (form.values.useMedicaidInfo && form.values.medicaidInfo?.payer_name) {
    return <TitleFour>{form.values.medicaidInfo?.payer_name}</TitleFour>
  }
  if (form.values.useMedicaidInfo && !form.values.medicaidInfo?.payer_name) {
    return <LabeledData data={undefined} label='Medicaid payer name' />
  }
  if (!form.values.useMedicaidInfo && form.values.payer_name) {
    return <TitleFour>{form.values.payer_name}</TitleFour>
  }
  return <LabeledData data={undefined} label='Payer name' />
}

export const InsuranceCardSection = ({
  isEditing,
  onCancel,
  onSave,
  saving,
}: InsuranceCardSectionProps) => {
  const form = useInsuranceCardFormContext()

  return (
    <Stack>
      <Grid columns={12}>
        <Grid.Col span={12}>
          {isEditing ? (
            <CardTextInput
              {...form.getInputProps('payer_name')}
              label='Payer name'
              editable={isEditing}
              placeholder='Payer name'
            />
          ) : (
            <ReadOnlyHeader form={form} />
          )}
        </Grid.Col>
        {!isEditing && form.values.useMedicaidInfo ? (
          <>
            <Grid.Col span={4}>
              <LabeledData data={form.values.medicaidInfo?.member_id} label='Medicaid member ID' />
            </Grid.Col>
            <Grid.Col span={4}>
              <LabeledData data={form.values.medicaidInfo?.payer_id} label='Medicaid payer ID' />
            </Grid.Col>
          </>
        ) : (
          <>
            <Grid.Col span={4}>
              <CardTextInput
                {...form.getInputProps('payer_id')}
                label='Payer ID'
                editable={isEditing}
                placeholder='000010'
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CardTextInput
                {...form.getInputProps('member_id')}
                label='Member ID'
                editable={isEditing}
                placeholder='123456789'
              />
            </Grid.Col>
          </>
        )}
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('group_number')}
            label='Group number (optional)'
            editable={isEditing}
            placeholder='123456'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('rx_bin')}
            label='RX bin (optional)'
            editable={isEditing}
            placeholder='123456'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('rx_pcn')}
            label='RX PCN (optional)'
            editable={isEditing}
            placeholder='Empire Gold Connection'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('rx_group')}
            label='RX Group (optional)'
            editable={isEditing}
            placeholder='123456'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('plan_name')}
            label='Plan name (optional)'
            editable={isEditing}
            placeholder='Freedom Network'
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <CardSelect
            {...form.getInputProps('plan_type')}
            label='Plan type'
            editable={isEditing}
            data={Object.keys(PrimaryInsurancePlanType).map(planType => ({
              label: PrimaryInsurancePlanType[planType as keyof typeof PrimaryInsurancePlanType],
              value: planType,
            }))}
          />
        </Grid.Col>
        {form.values.plan_type === 'MC' && isEditing && (
          <>
            <Grid.Col span={12}>
              <Text bold>Medicaid info</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <CardTextInput
                {...form.getInputProps('medicaidInfo.member_id')}
                label='Medicaid member ID (optional)'
                editable={isEditing}
                placeholder='123456789'
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CardTextInput
                {...form.getInputProps('medicaidInfo.payer_id')}
                label='Medicaid payer id (optional)'
                editable={isEditing}
                placeholder='000010'
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CardTextInput
                {...form.getInputProps('medicaidInfo.payer_name')}
                label='Medicaid payer name (optional)'
                editable={isEditing}
                placeholder='Payer name'
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Checkbox
                label='Submit medicaid info to Candid'
                {...form.getInputProps('useMedicaidInfo', { type: 'checkbox' })}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
      {isEditing ? (
        <EditableSectionFooter onCancel={onCancel} onSave={onSave} isSaving={saving} />
      ) : null}
    </Stack>
  )
}
