import { hasLength, useForm } from '@mantine/form'
import {
  Group,
  SaveIcon,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  Textarea,
} from '@shared/components'
import { useState } from 'react'
import { useLunaMutation, useLunaQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

export const InternalNotesContent = (props: { active: boolean; open: () => void }) => {
  const { patientId } = usePatient()
  const [isEditing, setIsEditing] = useState(false)

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      content: '',
    },
    validate: {
      content: hasLength({ max: 160 }, 'Character limit of 160 exceeded'),
    },
  })

  const internalNotesQuery = useLunaQuery(
    'GET /patients/:patientId/notes/internal',
    {
      params: {
        patientId,
      },
    },
    {
      onSuccess: ({ data }) => {
        form.setValues({ content: data.content })
        // if not currently active and there are notes, open the section automatically
        if (!props.active && data.content) {
          props.open()
        }
      },
    },
  )

  const internalNotesMutation = useLunaMutation('POST /patients/:patientId/notes/internal')

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await internalNotesMutation.mutateAsync({
      params: {
        patientId,
      },
      data: {
        content: form.values.content,
      },
    })

    await internalNotesQuery.refetch()
    setIsEditing(false)
  }

  if (internalNotesQuery.isLoading) {
    return <Skeleton h='5rem' />
  }

  const prevContent = internalNotesQuery.data?.data?.content || ''

  if (!isEditing) {
    if (!prevContent) {
      // empty state
      return (
        <Stack spacing='sm' px='md'>
          <SidebarEmptyState
            pillMessage='No internal notes'
            textMessage={`Click the "Add" button to get started.`}
          />
          <Group position='right'>
            <TertiaryButton onClick={() => setIsEditing(true)} size='sm'>
              Add
            </TertiaryButton>
          </Group>
        </Stack>
      )
    }

    // Not editing, show the content
    return (
      <Stack spacing='sm' px='md'>
        <Text>{prevContent}</Text>
        <Group position='right'>
          <TertiaryButton onClick={() => setIsEditing(true)} size='sm'>
            Edit
          </TertiaryButton>
        </Group>
      </Stack>
    )
  }

  return (
    <Stack spacing='sm' px='md'>
      <Textarea
        disabled={internalNotesMutation.isLoading}
        placeholder='Add internal notes'
        {...form.getInputProps('content')}
      />
      <Group position='right'>
        <TertiaryButton
          onClick={onSave}
          loading={internalNotesMutation.isLoading}
          disabled={internalNotesQuery.isLoading && internalNotesMutation.isLoading}
          size='sm'
          leftIcon={<SaveIcon />}
        >
          Save
        </TertiaryButton>
      </Group>
    </Stack>
  )
}
