import { useForm } from '@mantine/form'
import {
  BetterDrawer,
  Divider,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  PlusCircleIcon,
  PrimaryButton,
  SaveIcon,
  Select,
  Stack,
  TertiaryButton,
  TextInput,
} from '@shared/components'
import { useInvalidateLunaQuery, useLunaMutation } from '../../../../utils/hooks'
import { PayerEnrollmentListItem } from '../BillingInsuranceMatchingV2'
import { PayerStatusMap } from '../BillingInsuranceMatchingV2Table'
import { PayerMapSection } from './PayerMapSection'

type EditPayerDrawerProps = {
  opened: boolean
  onClose: () => void
  payer: PayerEnrollmentListItem | null
}

type PayerEnrollmentForm = {
  status: keyof typeof PayerStatusMap
  eligiblePayerIds: string[]
  candidPayerIds: string[]
}

type PayerMap = {
  eligiblePayerId: string
  candidPayerId: string
}

const NewPayerMap = {
  eligiblePayerId: '',
  candidPayerId: '',
}

/**
 * Note: There is a positional relationship b/t eligiblePayerIds + candidPayerIds
 * EG: eligiblePayerIds[0] is the eligible payer for candidPayerIds[0]
 */
const convertPayloadToPayerMap = (payload: {
  eligiblePayerIds: string[]
  candidPayerIds: string[]
}): PayerMap[] => {
  if (payload?.eligiblePayerIds.length === 0) {
    return [NewPayerMap]
  }

  return (payload?.eligiblePayerIds || []).map((eligiblePayerId, index) => {
    return {
      eligiblePayerId,
      candidPayerId: payload?.candidPayerIds[index] || '',
    }
  })
}

export const EditPayerDrawerContent = (props: EditPayerDrawerProps) => {
  const updatePayerMutation = useLunaMutation('PUT /payer-enrollments/:id')

  const form = useForm<PayerEnrollmentForm>({
    initialValues: {
      status: props.payer?.status || 'in_network',
      eligiblePayerIds: props.payer?.eligiblePayerIds || [''],
      candidPayerIds: props.payer?.candidPayerIds || [''],
    },
    validate: {
      status: val => (val ? undefined : 'Status is required'),
      // ensure that there are no empty string in each list
      eligiblePayerIds: val => (val.every(Boolean) ? undefined : 'Eligible payer ID is required'),
    },
  })

  const payerIdMap = convertPayloadToPayerMap(form.values)

  const invalidateLunaQuery = useInvalidateLunaQuery()

  const onMutationSuccess = () => {
    // Invalidate the payer enrollments query to reflect the changes on the table
    void invalidateLunaQuery('GET /payer-enrollments')
    props.onClose()
  }

  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    // submit updates payer map
    await updatePayerMutation.mutateAsync({
      params: {
        id: props.payer?.oid || '',
      },
      data: form.values,
    })

    onMutationSuccess()
  }

  /**
   * Can only add a new payer ID map if all the fields in the existing payer map are filled.
   * This is to prevent the user from adding a new payer map with empty fields.
   */
  const canAddNewPayerIdMap = payerIdMap.every(d => {
    return d.eligiblePayerId
  })

  return (
    <>
      <DrawerHeader onClose={props.onClose}>Edit payer</DrawerHeader>
      <DrawerContent>
        <Stack spacing='md' p='md'>
          <TextInput label='QGenda Payer name' disabled value={props.payer?.payerName} />
          <TextInput
            label='Line of Business'
            disabled
            value={props.payer?.lineOfBusiness.join(', ')}
          />
          <Group>
            <Select
              label='Status'
              data={[
                { label: PayerStatusMap.in_network, value: 'in_network' },
                { label: PayerStatusMap.out_of_network, value: 'out_of_network' },
                { label: PayerStatusMap.inactive, value: 'inactive' },
              ]}
              {...form.getInputProps('status')}
            />
            <TextInput
              style={{ flex: 1 }}
              label='States'
              disabled
              value={props.payer?.states.join(', ')}
            />
          </Group>

          {payerIdMap.map(({ eligiblePayerId, candidPayerId }, index) => {
            return (
              <>
                <Divider />
                <PayerMapSection
                  /**
                   * Every payer should have an Eligible ID mapped to it.
                   * Therefore, hide the remove button if there is only one payer.
                   * Also, hide the remove button if the current payer is the first payer.
                   */
                  canRemove={payerIdMap.length > 1 && index !== 0}
                  candidPayerIdError={form.errors.candidPayerIds?.toString()}
                  eligiblePayerIdError={form.errors.eligiblePayerIds?.toString()}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  values={{
                    eligiblePayerId,
                    candidPayerId,
                  }}
                  setEligiblePayerId={(eligiblePayerId: string) => {
                    form.setFieldValue(`eligiblePayerIds.${index}`, eligiblePayerId)
                  }}
                  setCandidPayerId={(candidPayerId: string) => {
                    form.setFieldValue(`candidPayerIds.${index}`, candidPayerId)
                  }}
                  /**
                   * Removing a payer map will remove the payer map at the current index.
                   * This updates the form's values to reflect the removal of the payer map.
                   */
                  removePayerMap={() => {
                    const updatedValues = {
                      eligiblePayerIds: form.values.eligiblePayerIds.filter((_, i) => i !== index),
                      candidPayerIds: form.values.candidPayerIds.filter((_, i) => i !== index),
                    }
                    form.setValues(updatedValues)
                  }}
                />
              </>
            )
          })}

          <Divider />

          {canAddNewPayerIdMap && (
            <TertiaryButton
              leftIcon={<PlusCircleIcon />}
              onClick={() => {
                /**
                 * By setting the last value to an empty string, we are adding a new payer ID map,
                 * which will be handled by the PayerIdSection component above when mapping the values.
                 */
                form.setValues({
                  eligiblePayerIds: [...form.values.eligiblePayerIds, ''],
                  candidPayerIds: [...form.values.candidPayerIds, ''],
                })
              }}
            >
              Add Eligible payer
            </TertiaryButton>
          )}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            loading={updatePayerMutation.isLoading}
            leftIcon={<SaveIcon />}
            onClick={onSubmit}
          >
            Save changes
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const EditPayerDrawer = (props: EditPayerDrawerProps) => {
  return (
    <BetterDrawer position='right' size='lg' opened={props.opened} onClose={props.onClose}>
      <EditPayerDrawerContent {...props} />
    </BetterDrawer>
  )
}
