import { TextInput, TextInputProps } from '@shared/components'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitNoteReadOnlyText } from './PatientVisitNoteReadOnlyText'

export const PatientVisitNoteTextInput = (props: TextInputProps) => {
  const { canEdit } = usePatientVisitNote()

  if (!canEdit) {
    return <PatientVisitNoteReadOnlyText label={props.label} value={props.value} />
  }

  return <TextInput {...props} />
}
