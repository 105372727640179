import { Group, Modal, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import { useMutation, useQueryClient } from 'react-query'
import { patientsApi } from '../../api'

type RemoveFromInsurancePayModalProps = {
  closeModal: () => void
  userId: string
  firstName: string
}

const RemoveFromInsurancePayModal = ({
  userId,
  closeModal,
  firstName,
}: RemoveFromInsurancePayModalProps) => {
  const queryClient = useQueryClient()

  const removePatient = useMutation(patientsApi.removeFromInsuranceAllowList, {
    onSuccess: () => {
      void queryClient.invalidateQueries()
      closeModal()
    },
  })

  return (
    <Modal
      opened
      onClose={closeModal}
      title={`Remove ${firstName} from insurance pay`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>{`No, don't remove`}</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              removePatient.mutate({ userId })
            }}
          >
            Yes, remove
          </PrimaryButton>
        </Group>
      }
    >
      <Text>
        If this patient is in treatment, this will start a subscription for them. Are you sure you
        want to remove this patient from insurance pay?
      </Text>
    </Modal>
  )
}

export default RemoveFromInsurancePayModal
