import { Checkbox, CheckboxGroup, Stack, TextInput } from '@shared/components'
import { dischargeNoteMapping } from '@shared/types'

import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const ReasonsForDischargeSection = ({ form }: { form: DischargeNoteForm }) => {
  return (
    <Stack spacing='sm'>
      <CheckboxGroup
        label='Reasons for discharge'
        spacing='sm'
        {...form.getInputProps('reasons_for_discharge')}
      >
        <Checkbox label={dischargeNoteMapping.failure_to_pay} value='failure_to_pay' />
        <Checkbox
          label={dischargeNoteMapping.administrative_non_compliance_aggressive_threatening}
          value='administrative_non_compliance_aggressive_threatening'
        />
        <Checkbox label={dischargeNoteMapping.not_responding} value='not_responding' />
        <Checkbox label={dischargeNoteMapping.death} value='death' />
        <Checkbox
          label={dischargeNoteMapping.patient_self_determines_to_stop}
          value='patient_self_determines_to_stop'
        />
        <Checkbox
          label={dischargeNoteMapping.medical_or_psychiatric_worsening}
          value='medical_or_psychiatric_worsening'
        />
        <Checkbox
          label={dischargeNoteMapping.reason_for_discharge_other}
          value='reason_for_discharge_other'
        />
      </CheckboxGroup>
      {form.values.reasons_for_discharge?.includes('reason_for_discharge_other') && (
        <TextInput
          placeholder='Additional details...'
          {...form.getInputProps('additional_explanation_for_discharge')}
        />
      )}
    </Stack>
  )
}
