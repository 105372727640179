import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import { Grid, PhoneInput, TextInput, TitleThree, validateWith } from '@shared/components'
import {
  Patient,
  PatientEmergencyContact as PatientEmergencyContactType,
  getOpheliaHttpError,
} from '@shared/types'
import { phone } from '@shared/utils'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { isAtleastOneWord, isPhone, isRequired } from '../../../utils/formValidation'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const getEmergencyContactData = (patient?: Patient): PatientEmergencyContactType => {
  return {
    fullName: patient?.emergencyContact?.fullName ?? '',
    relationship: patient?.emergencyContact?.relationship ?? '',
    phoneNumber: patient?.emergencyContact?.phoneNumber ?? '',
  }
}

export const PatientProfileEmergencyContact = () => {
  const { patientId, patientQuery } = usePatient()
  const patient = patientQuery?.data

  const form = useForm<ReturnType<typeof getEmergencyContactData>>({
    initialValues: getEmergencyContactData(patient),
    validate: {
      fullName: validateWith(isRequired, isAtleastOneWord),
      relationship: validateWith(isRequired, isAtleastOneWord),
      phoneNumber: validateWith(isRequired, isPhone),
    },
  })

  const initializeForm = (patient?: Patient) => {
    form.setValues(getEmergencyContactData(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const [isEditing, toggleIsEditing] = useToggle()

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: {
          emergencyContact: form.values,
        },
      })
    }

    void patientQuery?.refetch()
    toggleIsEditing()
  }

  return (
    <EditableSection
      title={<TitleThree>Emergency contact</TitleThree>}
      isEditing={isEditing}
      isLoading={patientQuery?.isLoading ?? false}
      onEdit={() => toggleIsEditing()}
      isSaving={updatePatient.isLoading}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      onSave={() => onSave()}
      error={getOpheliaHttpError(
        updatePatient.error,
        `Error updating emergency contact information`,
      )}
    >
      <Grid>
        <EditableCol span={4} isEditing={isEditing} label='Full name' text={form.values.fullName}>
          <TextInput placeholder='Full name' {...form.getInputProps('fullName')} />
        </EditableCol>
        <EditableCol
          span={4}
          isEditing={isEditing}
          label='Relationship'
          text={form.values.relationship}
        >
          <TextInput placeholder='Relationship' {...form.getInputProps('relationship')} />
        </EditableCol>
        <EditableCol
          span={4}
          isEditing={isEditing}
          label='Phone'
          text={phone(form.values.phoneNumber).formatted}
        >
          <PhoneInput {...form.getInputProps('phoneNumber')} />
        </EditableCol>
      </Grid>
    </EditableSection>
  )
}
