import React from 'react'
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner'

const ALargeLoadingSpinner = () => {
  return (
    <div className='w-full h-full flex justify-center items-center -mt-4'>
      <CgSpinner className='h-14 w-14 text-daybreak-actions1 animate-spin' />
    </div>
  )
}

export default ALargeLoadingSpinner
