import { ChevronLeftIcon, ChevronRightIcon, Group, SecondaryButton, Text } from '@shared/components'

type Props = {
  page: number
  pageSize: number
  totalItems: number
  onDecrement: () => void
  onIncrement: () => void
}

export const Pagination = (props: Props) => {
  const label = [
    'Showing',
    // Start at 1 instead of 0 (unless there are actually 0 items)
    Math.max(props.page * props.pageSize - props.pageSize, props.totalItems === 0 ? 0 : 1),
    'to',
    // Should not exceed totalItems
    Math.min(props.page * props.pageSize, props.totalItems),
    'of',
    props.totalItems,
  ].join(' ')

  return (
    <Group>
      <SecondaryButton
        size='sm'
        disabled={props.page === 1}
        leftIcon={<ChevronLeftIcon />}
        onClick={() => {
          props.onDecrement()
        }}
      />
      <Text>{label}</Text>
      <SecondaryButton
        size='sm'
        disabled={props.page * props.pageSize >= props.totalItems}
        leftIcon={<ChevronRightIcon />}
        onClick={() => {
          props.onIncrement()
        }}
      />
    </Group>
  )
}
