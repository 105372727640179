import noop from 'lodash/noop'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { patientsApi, storageApi } from '../../api'

export function useGcsFile({
  fileId,
  patientId,
  onError = noop,
}: {
  fileId: string | undefined
  patientId: string
  onError?: () => void
}) {
  const [file, setFile] = useState<File>()
  const isFileMissing = Boolean(fileId) && !file

  /*
   * If file ID is present but the file is missing, it means we need to load
   * the file from GCS, components can locally set a file to avoid GCS fetching.
   */
  useQuery(
    ['filesApi.getFile', fileId],
    async () => {
      const { signedUrl } = await patientsApi.getFile({ fileId: fileId ?? '', patientId })
      return storageApi.getFile({ fileName: fileId ?? '', url: signedUrl })
    },
    {
      onSuccess: file => {
        setFile(file)
      },
      onError,
      enabled: isFileMissing,
    },
  )

  return { file, setFile, isFileMissing }
}
