import { hasGroupRole } from '@shared/types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'

export const useAuthForAdminPages = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!hasGroupRole(currentUser, 'admin')) {
      navigate('/')
    }
  })
}
