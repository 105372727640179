import { useToggle } from '@mantine/hooks'
import {
  AccordionTimelineCard,
  ClockIcon,
  List,
  ListItem,
  Stack,
  Text,
  TimelineCardTitle,
  UserIcon,
  useMantineTheme,
} from '@shared/components'
import { IneligibleNoteModel } from '@shared/types'
import { dayjs } from '@shared/utils'
import { JourneyItemContent } from '../../PPatientContext'
import { FooterContentSection } from './SharedContent'

const IneligibleRationale = ({ ineligibleNote }: { ineligibleNote: IneligibleNoteModel }) => {
  const {
    other: { colors },
  } = useMantineTheme()

  if (ineligibleNote.substance_user_or_psychiatric_ineligibility) {
    if (
      ineligibleNote.substance_user_or_psychiatric_ineligibility_dsm_five ||
      ineligibleNote.substance_user_or_psychiatric_ineligibility_medically_unstable ||
      Boolean(
        ineligibleNote.substance_user_or_psychiatric_ineligibility_severe_polysubstance_use?.length,
      ) ||
      ineligibleNote.substance_user_or_psychiatric_ineligibility_other
    ) {
      return (
        <>
          <Text color={({ text }) => text[0]}>Substance use or psychiatric ineligibility: </Text>
          <List listStyleType='disc'>
            {ineligibleNote.substance_user_or_psychiatric_ineligibility_dsm_five && (
              <ListItem style={{ color: colors.text[0] }}>
                Does not meet DSM-5 criteria for OUD
              </ListItem>
            )}
            {ineligibleNote.substance_user_or_psychiatric_ineligibility_medically_unstable && (
              <ListItem style={{ color: colors.text[0] }}>
                Medically or psychiatrically unstable (e.g. active suicidality or psychosis)
              </ListItem>
            )}
            {Boolean(
              ineligibleNote.substance_user_or_psychiatric_ineligibility_severe_polysubstance_use
                ?.length,
            ) && (
              <ListItem style={{ color: colors.text[0] }}>
                Severe polysubstance use with physiological dependence (e.g. IV cocaine/meth use +
                binge alcohol)
              </ListItem>
            )}
            {ineligibleNote.substance_user_or_psychiatric_ineligibility_other && (
              <ListItem style={{ color: colors.text[0] }}>Other</ListItem>
            )}
          </List>
          <Text bold color={({ text }) => text[0]}>
            Explanation
          </Text>
          <Text color={({ text }) => text[0]}>
            {ineligibleNote.ineligible_explanation || 'None'}
          </Text>
        </>
      )
    }
  }
  return (
    <>
      <Text color={({ text }) => text[0]}>
        Patient self-determined not interested/ready to begin treatment
      </Text>
      <Text bold color={({ text }) => text[0]}>
        Explanation
      </Text>
      <Text color={({ text }) => text[0]}>{ineligibleNote.ineligible_explanation || 'None'}</Text>
    </>
  )
}
const ReferralAndTransitionOfCarePlan = ({
  ineligibleNote,
}: {
  ineligibleNote: IneligibleNoteModel
}) => {
  const {
    other: { colors },
  } = useMantineTheme()

  if (!ineligibleNote.referral_request) {
    return (
      <>
        <Text bold color={({ text }) => text[0]}>
          Explanation
        </Text>
        <Text color={({ text }) => text[0]}>
          {ineligibleNote.no_referral_request_explanation || 'None'}
        </Text>
      </>
    )
  }
  if (
    ineligibleNote.referral_request_inpatient_program ||
    ineligibleNote.referral_request_residential_program ||
    ineligibleNote.referral_request_intensive_outpatient_program ||
    ineligibleNote.referral_request_methadone_program ||
    ineligibleNote.referral_request_pcp ||
    ineligibleNote.referral_request_mental_health_psychiatric ||
    ineligibleNote.referral_request_buprenorphine_clinic ||
    ineligibleNote.referral_request_other
  ) {
    return (
      <>
        <Text color={({ text }) => text[0]}>Referral request for:</Text>
        <List listStyleType='disc'>
          {ineligibleNote.referral_request_inpatient_program && (
            <ListItem style={{ color: colors.text[0] }}>Inpatient program</ListItem>
          )}
          {ineligibleNote.referral_request_residential_program && (
            <ListItem style={{ color: colors.text[0] }}>Residential program</ListItem>
          )}
          {ineligibleNote.referral_request_intensive_outpatient_program && (
            <ListItem style={{ color: colors.text[0] }}>Intensive outpatient program</ListItem>
          )}
          {ineligibleNote.referral_request_methadone_program && (
            <ListItem style={{ color: colors.text[0] }}>Methadone program (OTP)</ListItem>
          )}
          {ineligibleNote.referral_request_pcp && (
            <ListItem style={{ color: colors.text[0] }}>PCP</ListItem>
          )}
          {ineligibleNote.referral_request_mental_health_psychiatric && (
            <ListItem style={{ color: colors.text[0] }}>
              Mental health/psychiatric clinic/services
            </ListItem>
          )}
          {ineligibleNote.referral_request_buprenorphine_clinic && (
            <ListItem style={{ color: colors.text[0] }}>Other buprenorphine clinic</ListItem>
          )}
          {ineligibleNote.referral_request_other && (
            <ListItem style={{ color: colors.text[0] }}>Other</ListItem>
          )}
        </List>
      </>
    )
  }
  return (
    <>
      <Text color={({ text }) => text[0]}>Referral request for:</Text>
      <Text color={({ text }) => text[0]}>None</Text>
    </>
  )
}

const AdditionalNotes = ({ ineligibleNote }: { ineligibleNote: IneligibleNoteModel }) => {
  if (!ineligibleNote.additonal_notes) {
    return (
      <>
        <Text bold color={({ text }) => text[0]}>
          Additional notes
        </Text>
        <Text color={({ text }) => text[0]}>{ineligibleNote.additonal_notes || 'None'}</Text>
      </>
    )
  }
  return (
    <>
      <Text bold color={({ text }) => text[0]}>
        Additional notes
      </Text>
      <Text color={({ text }) => text[0]}>None</Text>
    </>
  )
}
export const IneligibleNoteContent = ({
  ineligibleNote,
}: {
  ineligibleNote: JourneyItemContent<'ineligible-note'>
}) => {
  const [_, toggleExpand] = useToggle()
  const datetime = dayjs(ineligibleNote.timestamp)
  const {
    other: { colors },
  } = useMantineTheme()
  return (
    <AccordionTimelineCard
      onExpand={value => toggleExpand(value)}
      leftSide={<TimelineCardTitle title='Ineligible note' color={colors.text[0]} />}
    >
      <Stack spacing='sm'>
        <Text bold color={({ text }) => text[0]}>
          Rationale
        </Text>
        <IneligibleRationale ineligibleNote={ineligibleNote} />
        <Text bold color={({ text }) => text[0]}>
          Referral and transition of care plan
        </Text>
        <ReferralAndTransitionOfCarePlan ineligibleNote={ineligibleNote} />
        <AdditionalNotes ineligibleNote={ineligibleNote} />
        <FooterContentSection.Item>
          <UserIcon color={({ text }) => text[0]} />
          <Text size='xs' color={({ text }) => text[0]}>
            {ineligibleNote.employeeName}
          </Text>
          <ClockIcon color={({ text }) => text[0]} />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs' color={({ text }) => text[0]}>
            {datetime.format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
      </Stack>
    </AccordionTimelineCard>
  )
}
