import React from 'react'
import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle'
import ODSErrorIcon from './ODSErrorIcon'
import ODSInput from './ODSInput'
import { Tooltip } from '@shared/components'

export type ODSLabeledTextInputProps = {
  label: string
  placeholder?: string
  error?: string
  disabled?: boolean
  info?: string
  type?: 'text' | 'password'
}

const ODSLabeledTextInput = React.forwardRef<HTMLInputElement, ODSLabeledTextInputProps>(
  ({ label, error, disabled, info, type = 'text', ...props }, ref) => (
    <div className='col-span-3'>
      <div className='flex justify-between'>
        <label
          className={`block text-sm font-medium leading-5 mb-1 ${
            disabled ? 'text-gray-400' : 'text-gray-700'
          }`}
        >
          {label}
        </label>
        {info && (
          <Tooltip label={info} position='top'>
            <div className='mt-1'>
              <HiInformationCircle />
            </div>
          </Tooltip>
        )}
      </div>
      <ODSInput ref={ref} disabled={disabled} type={type} {...props} />
      {error && (
        <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
          <ODSErrorIcon />
          {error}
        </p>
      )}
    </div>
  ),
)

ODSLabeledTextInput.displayName = 'ODSLabeledTextInput'

export default ODSLabeledTextInput
