import { Group, SelectItem, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import { forwardRef } from 'react'

type CustomSelectItemProps = {
  label: string
  description: React.ReactNode
}

export const getRelativeTimeUnitData = (date: string): SelectItem[] => {
  if (!dayjs(date).isValid()) {
    return []
  }

  const BASE_DATE = dayjs(date).tz('America/New_York').startOf('day')
  const NINE_AM = 9
  const MORNING = BASE_DATE.hour(NINE_AM)
  const ONE_PM = 13
  const AFTERNOON = BASE_DATE.hour(ONE_PM)
  const FIVE_PM = 17
  const EVENING = BASE_DATE.hour(FIVE_PM)

  return [
    {
      label: 'Morning',
      description: MORNING.format('h:mma z'),
      value: MORNING.toISOString(),
    },
    {
      label: 'Afternoon',
      description: AFTERNOON.format('h:mma z'),
      value: AFTERNOON.toISOString(),
    },
    {
      label: 'Evening',
      description: EVENING.format('h:mma z'),
      value: EVENING.toISOString(),
    },
  ]
}

export const RelativeTimeSelectItem = forwardRef<HTMLDivElement, CustomSelectItemProps>(
  ({ label, description, ...others }: CustomSelectItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group position='apart' noWrap>
        <Text size='md'>{label}</Text>
        <Text size='xs' opacity={0.65}>
          {description}
        </Text>
      </Group>
    </div>
  ),
)

RelativeTimeSelectItem.displayName = 'SnoozeUntilSelectItem'
