import {
  ClipboardIcon,
  RichTextEditor,
  TertiaryButton,
  TimelineCard,
  TimelineCardTitle,
} from '@shared/components'
import { EMRTaskTypeMap } from '@shared/types'
import { formatSubCategory } from '../../../../utils/emrTasks'
import { useSidePane } from '../../../../utils/hooks/use-side-pane'
import { IssueDrawer } from '../../../care_team/tasks/IssueDrawer'
import { TaskStatusPill } from '../../../care_team/tasks/notes/NotesNoteAddedEntry'
import { JourneyItemContent } from '../../PPatientContext'

export const TaskContent = ({ task }: { task: JourneyItemContent<'task'> }) => {
  const emrTaskType = EMRTaskTypeMap.find(type => type.value === task.type)
  const { presentPane, hidePane } = useSidePane()

  return (
    <TimelineCard
      styles={({ background }) => ({ backgroundColor: background[2] })}
      leftSide={
        <TimelineCardTitle title={`${emrTaskType?.label} | ${formatSubCategory(emrTaskType)}`}>
          <TaskStatusPill status={task.status} />
        </TimelineCardTitle>
      }
      rightSide={
        <TertiaryButton
          onClick={() => {
            presentPane({
              key: `issue-notes-${task.oid}`,
              content: <IssueDrawer step='notes' taskId={task.oid} onClose={hidePane} />,
            })
          }}
          leftIcon={<ClipboardIcon />}
        >
          Notes
        </TertiaryButton>
      }
    >
      {task.wrapUpNote && <RichTextEditor value={task.wrapUpNote.text} editable={false} />}
    </TimelineCard>
  )
}
