import { useHover } from '@mantine/hooks'
import { Box, Center, Tooltip, useMantineTheme } from '@shared/components'
import { NavLink } from 'react-router-dom'

export type MenuItemProps = {
  icon: React.ReactNode
  label: string
  to?: string
  onClick?: () => void
}

const CoreItem = ({
  isActive = false,
  icon,
  label,
  onClick,
}: {
  isActive?: boolean
  icon: React.ReactNode
  label: string
  onClick?: () => void
}) => {
  const { hovered, ref } = useHover()
  const {
    other: { sizes, colors },
  } = useMantineTheme()

  return (
    <Center ref={ref} onClick={onClick}>
      <Tooltip label={label}>
        <Box
          p='sm'
          sx={{
            cursor: 'pointer',
            borderRadius: sizes.padding.sm,
            color: isActive ? colors.background[7] : colors.actions[0],
            // eslint-disable-next-line no-nested-ternary
            backgroundColor: hovered
              ? colors.background[6]
              : isActive
              ? colors.actions[0]
              : 'inherit',
          }}
        >
          {icon}
        </Box>
      </Tooltip>
    </Center>
  )
}

const MenuItem = ({ icon, label, to, onClick }: MenuItemProps) => {
  if (to) {
    return (
      <NavLink
        to={to}
        style={{
          textDecoration: 'none',
        }}
      >
        {({ isActive }) => <CoreItem isActive={isActive} icon={icon} label={label} />}
      </NavLink>
    )
  }

  return <CoreItem icon={icon} label={label} onClick={onClick} />
}

export default MenuItem
