import { useForm } from '@mantine/form'
import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SaveIcon,
  SecondaryButton,
  Stack,
  TextInput,
  validateWith,
} from '@shared/components'
import { useEffect } from 'react'
import { isRequired } from '../../utils/formValidation'
import { useLunaMutation } from '../../utils/hooks'

export type SmartPhraseCategory = {
  oid: string
  employeeId: string
  name: string
}

type SmartPhraseAddOrEditCategoryModalProps = {
  opened: boolean
  onClose: () => void
  smartPhraseCategory: SmartPhraseCategory | null
  onSubmit: (categoryId: string) => void
}

export const SmartPhraseAddOrEditCategoryModal = ({
  opened,
  onClose,
  smartPhraseCategory,
  onSubmit,
}: SmartPhraseAddOrEditCategoryModalProps) => {
  const isEditing = Boolean(smartPhraseCategory)

  const smartPhraseCategoryForm = useForm<{
    name: string
  }>({
    initialValues: {
      name: smartPhraseCategory?.name || '',
    },
    validate: {
      name: validateWith(isRequired),
    },
  })

  const addSmartPhraseCategoryMutation = useLunaMutation('POST /smart-phrase-categories')

  const editSmartPhraseCategoryMutation = useLunaMutation(
    'PUT /smart-phrase-categories/:smartPhraseCategoryId',
  )

  const addOrEditSmartPhraseCategory = () => {
    if (smartPhraseCategoryForm.validate().hasErrors) {
      return
    }

    if (isEditing) {
      editSmartPhraseCategoryMutation.mutate(
        {
          params: {
            smartPhraseCategoryId: smartPhraseCategory?.oid || '',
          },
          data: {
            ...smartPhraseCategoryForm.values,
          },
        },
        {
          onSuccess: () => {
            onSubmit(smartPhraseCategory?.oid || '')
          },
        },
      )
    } else {
      addSmartPhraseCategoryMutation.mutate(
        {
          data: {
            ...smartPhraseCategoryForm.values,
          },
        },
        {
          onSuccess: data => {
            onSubmit(data.data.id)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (smartPhraseCategory) {
      smartPhraseCategoryForm.setValues({ name: smartPhraseCategory.name })
    }
  }, [smartPhraseCategory])

  return (
    // Adding a Z-Index because the modal otherwise renders behind the drawer
    <BetterModal opened={opened} onClose={onClose} zIndex={1000}>
      <ModalHeader onClose={onClose}>{isEditing ? 'Edit' : 'Add new'} category</ModalHeader>
      <ModalContent>
        <Stack>
          <TextInput
            label='Category'
            placeholder='Enter category...'
            {...smartPhraseCategoryForm.getInputProps('name')}
          />
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Go back</SecondaryButton>
          <PrimaryButton
            leftIcon={<SaveIcon />}
            onClick={addOrEditSmartPhraseCategory}
            loading={
              addSmartPhraseCategoryMutation.isLoading || editSmartPhraseCategoryMutation.isLoading
            }
          >
            Save
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}
