import dayjs from 'dayjs'

export const getWeekRange = (date: string) => {
  const minDate = dayjs(date).startOf('isoWeek')
  const maxDate = dayjs(date).endOf('isoWeek')
  const dates = minDate.getBetween(maxDate, 'days').map(day => dayjs(day))
  return {
    dates,
    minDate,
    maxDate,
  }
}
