import { useForm } from '@mantine/form'
import { PrimaryButton, Stack, Textarea, TitleThree } from '@shared/components'
import { InsuranceReviewQueueItem } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'

export type IRQBillingNotesSectionProps = {
  item: InsuranceReviewQueueItem
}

type Form = {
  billingNotes: string
}

const IRQBillingNotesSection = ({ item }: IRQBillingNotesSectionProps) => {
  const form = useForm<Form>({
    initialValues: {
      billingNotes: item.freeformData || '',
    },
  })

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const save = async () => {
    await updatePatient.mutateAsync({
      params: { patientId: item.userId },
      data: {
        insuranceData: {
          freeformData: form.values.billingNotes,
        },
      },
    })
  }

  return (
    <>
      <TitleThree mb='md'>Billing notes</TitleThree>
      <Stack>
        <Textarea minRows={4} {...form.getInputProps('billingNotes')} />
        <PrimaryButton onClick={save} loading={updatePatient.isLoading}>
          Save
        </PrimaryButton>
      </Stack>
    </>
  )
}

export default IRQBillingNotesSection
