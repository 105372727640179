import { Group, Modal, PrimaryButton, SecondaryButton, useBanner } from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { useEmrMutation, useInvalidateQuery } from '../../../../utils/hooks'

export const RemovePhoneAuthModal = ({
  phone,
  opened,
  onClose,
  patientId,
}: {
  phone: string
  opened: boolean
  onClose: () => void
  patientId: string
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateQuery()
  const removePhoneAuth = useEmrMutation('PUT /patient/:patientId/remove-auth-phone')

  return (
    <Modal
      title='Remove phone'
      opened={opened}
      onClose={() => {
        if (!removePhoneAuth.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={removePhoneAuth.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={removePhoneAuth.isLoading}
            onClick={() =>
              removePhoneAuth.mutate(
                { params: { patientId } },
                {
                  onSuccess: () => {
                    void invalidate('GET /patient/:patientId/validate-auth', {
                      params: { patientId },
                    })
                    void invalidate('GET /patient/:patientId/auth', {
                      params: { patientId },
                    })
                    onClose()
                  },
                  onError: error => {
                    showBanner({
                      type: 'error',
                      dismissable: true,
                      label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                    })
                    onClose()
                  },
                },
              )
            }
          >
            Remove
          </PrimaryButton>
        </Group>
      }
    >
      {`Are you sure you want to remove the phone ${phone} from this user?`}
    </Modal>
  )
}
