import { Stack, Table } from '@shared/components'
import { InvoiceListItem } from '@shared/types'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'
import { useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { PatientShellScroll } from '../PatientShell'
import { EmptyInvoiceTable } from './EmptyInvoiceTable'
import { InvoicesTableBody } from './InvoicesTableBody'
import { InvoicesTableHeader } from './InvoicesTableHeader'
import { LoadingTable } from './LoadingTable'

type ColumnName =
  | 'Toggle row'
  | 'ID'
  | 'Description'
  | 'Status'
  | 'Date'
  | 'Due'
  | 'Amount'
  | 'Balance'
  | 'Actions'

type InvoiceTableColumn = {
  name: ColumnName
  isSortable: boolean
  sortingColumn: OrderByColumn | null
}

export const INVOICE_TABLE_COLUMNS: InvoiceTableColumn[] = [
  { name: 'Toggle row', isSortable: false, sortingColumn: null },
  { name: 'ID', isSortable: false, sortingColumn: null },
  { name: 'Description', isSortable: false, sortingColumn: null },
  { name: 'Status', isSortable: true, sortingColumn: 'paymentStatus' },
  { name: 'Date', isSortable: true, sortingColumn: 'invoiceDate' },
  { name: 'Due', isSortable: true, sortingColumn: 'dueDate' },
  { name: 'Amount', isSortable: false, sortingColumn: null },
  { name: 'Balance', isSortable: false, sortingColumn: null },
  { name: 'Actions', isSortable: false, sortingColumn: null },
]

export type OrderByColumn = keyof InvoiceListItem

export type OrderByDirection = 'asc' | 'desc'

export const PatientInvoicesContent = () => {
  const { patientId } = usePatient()

  const [orderByColumn, setOrderByColumn] = useState<OrderByColumn>('invoiceDate')
  const [orderByDirection, setOrderByDirection] = useState<OrderByDirection>('desc')

  const { data, isLoading } = useEmrQuery('GET /invoices', {
    query: {
      patientId,
    },
  })

  const invoices = data?.invoiceListItems || []

  const sortedInvoices = orderBy(invoices, [orderByColumn], [orderByDirection])

  if (!isLoading && invoices.length === 0) {
    return <EmptyInvoiceTable />
  }

  /*
   * If the column is already sorted, reverse the sort direction
   * Otherwise, sort by the new column in descending order
   */
  const handleSortingChange = (columnKey: OrderByColumn | undefined) => {
    if (!columnKey) {
      return
    }

    if (orderByColumn === columnKey) {
      setOrderByDirection(prev => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setOrderByColumn(columnKey)
      setOrderByDirection('desc')
    }
  }

  return (
    <PatientShellScroll>
      <Stack p='md' mx='sm'>
        {isLoading ? (
          <LoadingTable />
        ) : (
          <Table striped={false}>
            <InvoicesTableHeader
              orderByColumn={orderByColumn}
              orderByDirection={orderByDirection}
              handleSortingChange={handleSortingChange}
            />
            <InvoicesTableBody invoices={sortedInvoices} />
          </Table>
        )}
      </Stack>
    </PatientShellScroll>
  )
}
