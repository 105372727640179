import { Alert, AlertIcon, Group, PrimaryButton, SecondaryButton, Stack } from '@shared/components'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../../api'
import ALoadingSpinner from '../../../../../components/atoms/ALoadingSpinner'
import { QuickMergeStatusContent } from './QuickMergeStatusContent'

type QuickMergeStatusProps = {
  fromPatientId: string
  toPatientId: string | null
  onBack: () => void
  onClose: () => void
  onNext: () => void
}

export const QuickMergeStatusStep = ({
  fromPatientId,
  toPatientId,
  onBack,
  onClose,
  onNext,
}: QuickMergeStatusProps) => {
  const patientQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId', {
      params: {
        patientId: toPatientId || '',
      },
    }),
  )

  if (!toPatientId) {
    return (
      <QuickMergeErrorAlert onBack={onBack} onClose={onClose} innerText='Invalid patient ID.' />
    )
  }

  if (fromPatientId === toPatientId) {
    return (
      <QuickMergeErrorAlert
        onBack={onBack}
        onClose={onClose}
        innerText='Cannot merge with the same patient.'
      />
    )
  }

  if (patientQuery?.isLoading) {
    return <ALoadingSpinner />
  }

  const patient = patientQuery?.data
  if (!patient) {
    return (
      <QuickMergeErrorAlert
        onBack={onBack}
        onClose={onClose}
        innerText={`Patient ${toPatientId} not found.`}
      />
    )
  }

  return (
    <Stack>
      <QuickMergeStatusContent
        fromPatientId={fromPatientId}
        toPatientId={toPatientId}
        onBack={onBack}
        onNext={onNext}
      />
    </Stack>
  )
}

type QuickMergeErrorAlertProps = {
  innerText: string
  onBack: () => void
  onClose: () => void
}

const QuickMergeErrorAlert = ({ innerText, onBack, onClose }: QuickMergeErrorAlertProps) => {
  return (
    <Stack>
      <Alert variant='error' icon={<AlertIcon />}>
        {innerText}
      </Alert>
      <Group position='right'>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <PrimaryButton onClick={onClose}>Close</PrimaryButton>
      </Group>
    </Stack>
  )
}
