import {
  OcpOverrideModel,
  OcpRecurring,
  OcpRecurringModel,
  OnCallProvider,
  StateAbbr,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

export class OcpApi extends BaseApi {
  getRecurring = () => {
    return this.json<OcpRecurring[]>({ method: 'GET', url: '/ocp/recurring' })
  }

  createRecurring = (model: OcpRecurringModel) => {
    return this.json<void>({ method: 'POST', url: '/ocp/recurring', data: model })
  }

  createOverride = (model: OcpOverrideModel) => {
    return this.json<void>({ method: 'POST', url: '/ocp/override', data: model })
  }

  deleteOverride = ({ overrideId }: { overrideId: string }) => {
    return this.json<void>({ method: 'DELETE', url: `/ocp/override/${overrideId}` })
  }

  // LINK - server/functions/emr/router/index.ts#get-ocp
  getCurrentOcp = ({ state }: { state: StateAbbr }) => {
    return this.json<OnCallProvider | undefined>({ method: 'GET', url: `/ocp/provider/${state}` })
  }
}
