import { UseFormReturnType, useForm } from '@mantine/form'
import { Stack, skipIfOtherField, validateWith } from '@shared/components'
import { ReenrollmentCallVisitNoteContent } from '@shared/types'
import { toTime } from '@shared/utils'
import debounce from 'lodash/debounce'
import { useCallback, useEffect } from 'react'
import { isRequired } from '../../../../utils/formValidation'
import { usePatientVisitNote } from './PatientVisitNoteContext'
import { PatientVisitNoteUtilityBar } from './visitNoteForm/PatientVisitNoteUtilityBar'
import { BalanceSection } from './visitNoteSections/BalanceSection'
import { BarriersToTreatmentSection } from './visitNoteSections/BarriersToTreatmentSection'
import { BridgePrescriptionSection } from './visitNoteSections/BridgePrescriptionSection'
import { DischargeNoteSection } from './visitNoteSections/DischargeNoteSection'
import { InsuranceSection } from './visitNoteSections/InsuranceSection'
import { MedicalHistorySection } from './visitNoteSections/MedicalHistorySection'
import { MotivationsForTreatmentSection } from './visitNoteSections/MotivationsForTreatmentSection'
import { MAIN_CONTENT_NOTE_WIDTH, SIDEBAR_NOTE_WIDTH } from './visitNoteUtils'

export type FullReenrollmentCallVisitNoteFormContent = ReenrollmentCallVisitNoteContent &
  Partial<Record<`${keyof ReenrollmentCallVisitNoteContent}_not_discussed`, boolean>>

export type ReenrollmentCallVisitNoteForm = UseFormReturnType<
  FullReenrollmentCallVisitNoteFormContent,
  (values: FullReenrollmentCallVisitNoteFormContent) => FullReenrollmentCallVisitNoteFormContent
>

export const ReenrollmentCallVisitNote = () => {
  const { setFormRef, visitNoteQuery, saveVisitNote, isSidebar } =
    usePatientVisitNote<ReenrollmentCallVisitNoteContent>()

  const form = useForm<FullReenrollmentCallVisitNoteFormContent>({
    initialValues:
      (visitNoteQuery?.data?.content as FullReenrollmentCallVisitNoteFormContent) || undefined,
    validate: {
      motivations_for_treatment_more_details: validateWith(
        skipIfOtherField('motivations_for_treatment_more_details_not_discussed', 'is', true),
        isRequired,
      ),
      has_insurance: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        isRequired,
      ),
      insurance_provider: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      insurance_determination: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      insurance_payment_method: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      outstanding_balance: validateWith(
        skipIfOtherField('outstanding_balance_not_discussed', 'is', true),
        isRequired,
      ),
      bridge_prescription_eligibility: validateWith(
        skipIfOtherField('bridge_prescription_eligibility_not_discussed', 'is', true),
        isRequired,
      ),
      primary_barrier_to_treatment: validateWith(isRequired),
    },
  })

  const debouncedSaveVisitNote = useCallback(
    debounce(() => {
      void saveVisitNote?.()
    }, toTime('1 sec').ms()),
    [saveVisitNote],
  )

  useEffect(() => {
    void debouncedSaveVisitNote()
  }, [form.values])

  setFormRef?.(form)

  return (
    <Stack
      spacing='lg'
      py='md'
      pr='md'
      w={isSidebar ? SIDEBAR_NOTE_WIDTH : MAIN_CONTENT_NOTE_WIDTH}
    >
      <PatientVisitNoteUtilityBar />
      <DischargeNoteSection />
      <MotivationsForTreatmentSection form={form} />
      <BalanceSection form={form} />
      <InsuranceSection form={form} />
      <BridgePrescriptionSection form={form} />
      <MedicalHistorySection form={form} />
      <BarriersToTreatmentSection form={form} />
      <PatientVisitNoteUtilityBar />
    </Stack>
  )
}
