import { UseFormReturnType } from '@mantine/form'
import { Alert, Box, Group, InfoIcon, Loader, Stack, Tabs, Text } from '@shared/components'
import { AppointmentTypes, Employee, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { ReactNode, forwardRef } from 'react'
import { SelectEmployee } from './CalendarDrawer'
import { OpenWinbacks } from './PickSlotStep'
import { CalendarDaysDateslot, ScheduleView, SchedulingFormData } from './SchedulerDrawer'
import { SchedulerSlots } from './SchedulerSlots'
import { ClinicianOption } from './SelectClinician'
import { CalendarSlotsData } from './use-calendar'

type ScheduleSlotsProps = {
  patient: Patient
  schedulingForm: UseFormReturnType<SchedulingFormData>
  today: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  isLoading: boolean
  availableCalendarDates: CalendarDaysDateslot[]
  availableAppointments: CalendarSlotsData[]
  appointmentTypeId: string
  hasInsurance: boolean
  appointmentTypes?: AppointmentTypes
  scheduleView: ScheduleView
  employee: Employee
  header?: ReactNode
  recommendedOptions: ClinicianOption[]
  otherOptions: ClinicianOption[]
  openWinbacks?: OpenWinbacks
}

type SchedulerContentWithTabsProps = ScheduleSlotsProps & {
  changeTab: (value: ScheduleView) => void
}

export const SchedulerContentWithTabs = forwardRef<HTMLDivElement, SchedulerContentWithTabsProps>(
  (
    {
      patient,
      schedulingForm,
      hasInsurance,
      appointmentTypeId,
      appointmentTypes,
      recommendedOptions,
      otherOptions,
      employee,
      today,
      maxDate,
      isLoading,
      availableCalendarDates,
      availableAppointments,
      scheduleView,
      changeTab,
    },
    ref,
  ) => {
    return (
      <Box ref={ref}>
        <Tabs
          defaultValue='next-available'
          onTabChange={(value: ScheduleView) => changeTab(value)}
          pl='md'
        >
          <Tabs.List>
            <Tabs.Tab value='next-available'>Next available</Tabs.Tab>
            <Tabs.Tab value='choose-clinician'>Choose clinician</Tabs.Tab>
          </Tabs.List>
        </Tabs>

        <SchedulerContent
          patient={patient}
          schedulingForm={schedulingForm}
          today={today}
          maxDate={maxDate}
          isLoading={isLoading}
          availableCalendarDates={availableCalendarDates}
          availableAppointments={availableAppointments}
          hasInsurance={hasInsurance}
          scheduleView={scheduleView}
          appointmentTypeId={appointmentTypeId}
          appointmentTypes={appointmentTypes}
          employee={employee}
          recommendedOptions={recommendedOptions}
          otherOptions={otherOptions}
        />
      </Box>
    )
  },
)

SchedulerContentWithTabs.displayName = 'SchedulerContentWithTabs'

export const SchedulerContent = ({
  schedulingForm,
  hasInsurance,
  appointmentTypeId,
  patient,
  header,
  appointmentTypes,
  scheduleView,
  recommendedOptions,
  otherOptions,
  openWinbacks,
  employee,
  today,
  maxDate,
  isLoading,
  availableCalendarDates,
  availableAppointments,
}: ScheduleSlotsProps) => {
  return (
    <Stack p='md'>
      {openWinbacks && openWinbacks.length > 0 && (
        <Alert
          icon={<InfoIcon />}
          title={openWinbacks.length > 1 ? 'Open winback issues' : 'Open winback issue'}
          variant='primary'
        >
          {openWinbacks.length > 1
            ? "After scheduling, please review/close patient's open winback issues"
            : "After scheduling, you'll be redirected to close out the patient's open winback issue"}
        </Alert>
      )}
      {header}
      {appointmentTypes && scheduleView === 'choose-clinician' && (
        <SelectEmployee
          {...schedulingForm.getInputProps('employeeId')}
          appointmentTypes={appointmentTypes}
          appointmentTypeId={appointmentTypeId}
          recommendedOptions={recommendedOptions}
          otherOptions={otherOptions}
        />
      )}
      {isLoading ? (
        <Group spacing='sm'>
          <Loader size='sm' />
          <Text color={({ text }) => text[1]}>Fetching availability...</Text>
        </Group>
      ) : (
        (scheduleView === 'next-available' || Boolean(employee)) && (
          <SchedulerSlots
            patient={patient}
            schedulingForm={schedulingForm}
            today={today}
            maxDate={maxDate}
            isLoading={isLoading}
            availableCalendarDates={availableCalendarDates}
            availableAppointments={availableAppointments}
            hasInsurance={hasInsurance}
            scheduleView={scheduleView}
            employee={employee}
          />
        )
      )}
    </Stack>
  )
}
