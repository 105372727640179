import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import {
  DateInput,
  Grid,
  Select,
  TextInput,
  TitleThree,
  skipIfEmpty,
  validateWith,
} from '@shared/components'
import { DeepPartial, Patient, genders, getOpheliaHttpError, pronouns, sexes } from '@shared/types'
import capitalize from 'lodash/capitalize'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import {
  isAtleastOneWord,
  isConditionallyRequired,
  isDate,
  isRequired,
} from '../../../utils/formValidation'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const getBasicData = (patient?: Patient): DeepPartial<Patient> => {
  return {
    personalData: {
      firstName: patient?.personalData?.firstName ?? '',
      lastName: patient?.personalData?.lastName,
      preferredName: patient?.personalData?.preferredName ?? '',
      sex: patient?.personalData?.sex,
      gender: patient?.personalData?.gender,
      pronouns: patient?.personalData?.pronouns,
      birthday: patient?.personalData?.birthday ?? '',
    },
  }
}

export const PatientProfilePersonalInformation = () => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const initialValues = getBasicData(patient)

  const form = useForm<ReturnType<typeof getBasicData>>({
    initialValues,
    validate: {
      personalData: {
        firstName: validateWith(isRequired, isAtleastOneWord),
        lastName: validateWith(isRequired, isAtleastOneWord),
        preferredName: validateWith(skipIfEmpty, isAtleastOneWord),
        birthday: validateWith(
          isConditionallyRequired(Boolean(initialValues.personalData?.birthday)),
          isDate,
        ),
      },
    },
  })

  const initializeForm = (patient?: Patient) => {
    form.setValues(getBasicData(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const [isEditing, toggleIsEditing] = useToggle()

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: form.values,
      })

      void patientQuery?.refetch()
    }

    toggleIsEditing()
  }

  return (
    <EditableSection
      isEditing={isEditing}
      isLoading={patientQuery?.isLoading ?? false}
      onEdit={() => toggleIsEditing()}
      onSave={() => onSave()}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      isSaving={updatePatient.isLoading}
      title={<TitleThree>Personal information</TitleThree>}
      error={getOpheliaHttpError(
        updatePatient.error,
        `Error updating patient's personal information.`,
      )}
    >
      <Grid>
        <EditableCol
          isEditing={isEditing}
          label='First name'
          text={form.values.personalData?.firstName}
        >
          <TextInput placeholder='First name' {...form.getInputProps('personalData.firstName')} />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Preferred name (optional)'
          text={form.values.personalData?.preferredName}
        >
          <TextInput
            placeholder='Preferred name'
            {...form.getInputProps('personalData.preferredName')}
          />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Last name'
          text={form.values.personalData?.lastName}
        >
          <TextInput placeholder='Last name' {...form.getInputProps('personalData.lastName')} />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Birthday'
          text={form.values.personalData?.birthday}
        >
          <DateInput
            disabled={!isEditing}
            label='Birthday'
            {...form.getInputProps('personalData.birthday')}
          />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Biological sex'
          text={capitalize(form.values.personalData?.sex)}
        >
          <Select
            placeholder='Biological sex'
            data={sexes.map(sex => ({ label: capitalize(sex), value: sex }))}
            {...form.getInputProps('personalData.sex')}
          />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Gender'
          text={capitalize(form.values.personalData?.gender)}
        >
          <Select
            placeholder='Gender'
            data={genders.map(gender => ({ label: capitalize(gender), value: gender }))}
            {...form.getInputProps('personalData.gender')}
          />
        </EditableCol>

        <EditableCol
          isEditing={isEditing}
          label='Pronouns'
          text={capitalize(form.values.personalData?.pronouns)}
        >
          <Select
            placeholder='Pronouns'
            data={pronouns.map(pronoun => ({ label: capitalize(pronoun), value: pronoun }))}
            {...form.getInputProps('personalData.pronouns')}
          />
        </EditableCol>
      </Grid>
    </EditableSection>
  )
}
