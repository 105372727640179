import { useDisclosure } from '@mantine/hooks'
import {
  Center,
  Menu,
  MoreVerticalIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Table,
  Td,
  Text,
  Th,
} from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import { MutationModal } from '../../../components/MutationModal'
import { PayerConfigDrawer } from './PayerConfigDrawer'

export type PayerConfigs = EmrLunaApi['GET /payer-configs']['res']['data']

type Props = {
  payerConfigs: PayerConfigs
}

export const PayerConfigRow = (props: { payerConfig: PayerConfigs[0] }) => {
  const [drawerOpen, drawerHandlers] = useDisclosure()
  const [menuOpen, menuHandlers] = useDisclosure()
  const [deleteOpen, deleteHandlers] = useDisclosure()

  return (
    <>
      <tr>
        <Td>
          <Text>{props.payerConfig.name}</Text>
        </Td>
        <Td>
          <Text>{props.payerConfig.changePayerId}</Text>
        </Td>
        <Td>
          <Text>{props.payerConfig.eligiblePayerName}</Text>
        </Td>
        <Td>
          <Text>{props.payerConfig.category}</Text>
        </Td>
        <Td>
          <Text>{props.payerConfig.planTypes.join(', ')}</Text>
        </Td>
        <Td>
          <Menu position='bottom-end' opened={menuOpen} onClose={menuHandlers.close}>
            <Center>
              <Menu.Target>
                <SecondaryButton
                  size='xs'
                  onClick={menuHandlers.open}
                  leftIcon={<MoreVerticalIcon />}
                />
              </Menu.Target>
            </Center>
            <Menu.Dropdown>
              <Menu.Item disabled onClick={() => ({})}>
                Duplicate
              </Menu.Item>
              <Menu.Item onClick={drawerHandlers.open}>Edit</Menu.Item>
              <Menu.Item onClick={deleteHandlers.open}>Delete</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Td>
      </tr>
      <PayerConfigDrawer
        payerConfigId={props.payerConfig.oid}
        opened={drawerOpen}
        onClose={drawerHandlers.close}
      />
      <MutationModal
        header={`Delete ${props.payerConfig.name}`}
        description={`Are you sure you want to delete ${props.payerConfig.name}?`}
        callToAction='Delete'
        opened={deleteOpen}
        onClose={deleteHandlers.close}
        endpoint='DELETE /payer-configs/:id'
        payload={{
          params: {
            id: props.payerConfig.oid,
          },
        }}
      />
    </>
  )
}

export const PayerConfigsTable = (props: Props) => {
  const [drawerOpen, drawerHandlers] = useDisclosure()

  return (
    <Stack>
      <PrimaryButton style={{ alignSelf: 'flex-end' }} onClick={drawerHandlers.open}>
        Add bundle
      </PrimaryButton>
      <Table striped withBorder>
        <thead className='mantine'>
          <tr className='mantine'>
            <Th sortable={false}>Bundle name</Th>
            <Th sortable={false}>Candid payer ID</Th>
            <Th sortable={false}>Payer name</Th>
            <Th sortable={false}>Category</Th>
            <Th sortable={false}>Plan types</Th>
            <Th
              sortable={false}
              style={{
                width: '6ch',
              }}
            >
              Action
            </Th>
          </tr>
        </thead>
        <tbody className='mantine'>
          {props.payerConfigs.map(payerConfig => {
            return <PayerConfigRow key={payerConfig.changePayerId} payerConfig={payerConfig} />
          })}
        </tbody>
      </Table>
      <PayerConfigDrawer opened={drawerOpen} onClose={drawerHandlers.close} />
    </Stack>
  )
}
