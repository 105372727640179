import {
  Group,
  IMAGE_MIME_TYPE,
  Image,
  PDFViewer,
  PDF_MIME_TYPE,
  PhotoFrame,
  Text,
} from '@shared/components'
import { useGcsFile } from '../../utils/hooks'

type PhotoProps = {
  value: string | undefined
  patientId: string
  label: string
  ratio?: number
}

export const Photo = ({ value, label, patientId, ratio }: PhotoProps) => {
  const { file, isFileMissing } = useGcsFile({ fileId: value, patientId })

  return (
    <PhotoFrame loading={isFileMissing} ratio={ratio}>
      <PreviewPhoto file={file} label={label} />
    </PhotoFrame>
  )
}

export const PreviewPhoto = ({ file, label }: { file: File | undefined; label?: string }) => {
  if (file && IMAGE_MIME_TYPE.some(type => type === file.type)) {
    return <Image src={URL.createObjectURL(file)} fit='scale-down' />
  }

  if (file && PDF_MIME_TYPE.some(type => type === file.type)) {
    return <PDFViewer src={file} fit />
  }

  return (
    <Group p='lg' align='center' position='center' sx={{ width: '100%', height: '100%' }}>
      <Text>{label}</Text>
    </Group>
  )
}
