import { ClockIcon, Group, Pill, RichTextEditor, Stack, Text } from '@shared/components'
import { EMRTaskNoteDecorated } from '@shared/types'
import { dayjs } from '@shared/utils'
import { NoteCreatedBy } from './NoteCreatedBy'

export type NotesDescriptionEntryProps = {
  note: EMRTaskNoteDecorated<'description'>
}

export const NotesDescriptionEntry = ({ note }: NotesDescriptionEntryProps) => {
  return (
    <Stack spacing='sm'>
      <Pill variant='filled' status='error'>
        New issue
      </Pill>
      <Group>
        <Text size='xs'>
          <NoteCreatedBy note={note} />
        </Text>
        <Text size='xs'>
          <Group spacing='xs'>
            <ClockIcon size='sm' />
            {dayjs(note.createdAt).format('MM/DD/YYYY')} | {dayjs(note.createdAt).format('h:mma z')}
          </Group>
        </Text>
      </Group>
      <RichTextEditor value={note.text} editable={false} />
    </Stack>
  )
}
