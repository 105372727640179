import {
  EMRTask,
  EMRTaskStatus,
  Patient,
  TaskType,
  hasRole,
  isCareCoordinator,
} from '@shared/types'
import { toTime } from '@shared/utils'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { AddEmrBugIssueDrawerContent } from './CreateEmrBugTaskDrawer'
import { AddIssueDrawerContent } from './CreateTaskDrawer'
import { ReviewIssuesDrawerContent } from './ViewTasksDrawer'
import { NotesDrawerContent } from './notes/NotesDrawer'

export type Step = 'view' | 'add' | 'notes'

export type IssueStep = {
  step: Step
  taskId?: EMRTask['oid']
}

type IssueDrawerProps = {
  step: Step
  taskId?: EMRTask['oid']
  patient?: Patient | null
  issueType?: TaskType
  onClose: () => void
}

export const IssueDrawer = ({ onClose, step, patient, taskId, issueType }: IssueDrawerProps) => {
  const { currentUser } = useAuth()

  const isTrackingTriageMinutes =
    isCareCoordinator(currentUser) ||
    hasRole(currentUser, 'sncm', 'ncm', 'stn', 'tn', 'resourceNavigator')

  const [currentStep, setCurrentStep] = useState<IssueStep>({
    step,
    taskId,
  })

  const tasksQuery = useQuery(
    ...emrApi.getQuery('GET /tasks/v2', {
      query: { patientId: patient?.oid || '' },
    }),
    { enabled: Boolean(patient), cacheTime: toTime('30 sec').ms() },
  )

  const counts: Record<EMRTaskStatus, number> | undefined = useMemo(
    () =>
      tasksQuery.data
        ? {
            open: tasksQuery.data.filter(task => task.status === 'open').length ?? 0,
            snoozed: tasksQuery.data.filter(task => task.status === 'snoozed').length ?? 0,
            closed: tasksQuery.data.filter(task => task.status === 'closed').length ?? 0,
          }
        : undefined,
    [tasksQuery.data],
  )

  return (
    <>
      {currentStep.step === 'add' &&
        (issueType === 'emr_tech_issue' ? (
          <AddEmrBugIssueDrawerContent
            onBack={step === 'add' ? undefined : () => setCurrentStep({ step })}
            onClose={onClose}
          />
        ) : (
          <AddIssueDrawerContent
            onBack={step === 'add' ? undefined : () => setCurrentStep({ step })}
            patient={patient || undefined}
            onClose={onClose}
            counts={counts}
            defaultTaskType={issueType}
            onClickAlert={() => setCurrentStep({ step: 'view' })}
            isTrackingTriageMinutes={isTrackingTriageMinutes}
          />
        ))}
      {currentStep.step === 'view' && (
        <ReviewIssuesDrawerContent
          onBack={step === 'view' ? undefined : () => setCurrentStep({ step })}
          onClose={onClose}
          counts={counts}
          onClickAddIssue={() => setCurrentStep({ step: 'add' })}
          isLoading={tasksQuery.isLoading}
          tasks={tasksQuery.data ?? []}
          openNotes={taskId => setCurrentStep({ step: 'notes', taskId })}
        />
      )}
      {currentStep.step === 'notes' && currentStep.taskId && (
        <NotesDrawerContent
          taskId={currentStep.taskId}
          onBack={patient ? () => setCurrentStep({ step: 'view' }) : undefined}
          onClose={onClose}
        />
      )}
    </>
  )
}
