import { createFormContext } from '@mantine/form'
import { Employee, EmrApi, hasGroupRole, hasRole } from '@shared/types'
import { PodFilterValue } from '../../../utils/hooks/use-pod-filters-options'

type TaskFilters = EmrApi['GET /tasks/v2']['req']['query']
type FrontendTaskFilters = Omit<TaskFilters, 'pod'> & { pod: PodFilterValue }

export type OrderBy = {
  orderBy: TaskFilters['orderBy']
  orderByDirection: TaskFilters['orderByDirection']
}

export const getDefaultPod = (employee: Employee): PodFilterValue => {
  if (employee.role === 'financialCounselor') {
    return 'fc'
  }
  if (hasRole(employee, 'stn', 'tn', 'ncm_tn')) {
    return 'tn'
  }
  if (hasGroupRole(employee, 'enrollmentCoordinator')) {
    return 'assigned_to_me'
  }
  if (hasGroupRole(employee, 'patientEngagementSpecialist')) {
    return 'pes'
  }
  if (hasGroupRole(employee, 'patientSupportSpecialist')) {
    return 'pss'
  }
  if (hasGroupRole(employee, 'product')) {
    return 'product'
  }
  if (hasGroupRole(employee, 'engineer')) {
    return 'engineer'
  }
  // Clinicians should see tasks 'Assigned to me' by default
  if (hasGroupRole(employee, 'clinician')) {
    return 'assigned_to_me'
  }
  return ''
}

export const getInitialFilters = (
  filters: Partial<FrontendTaskFilters>,
): Partial<FrontendTaskFilters> => ({
  employeeId: undefined,
  endBefore: undefined,
  startAfter: undefined,
  orderBy: 'priority',
  orderByDirection: 'desc',
  types: undefined,
  ...filters,
})

export const [FiltersFormProvider, useFiltersFormContext, useFiltersForm] =
  createFormContext<Partial<FrontendTaskFilters>>()
