import { arrayIncludes } from '@shared/types'
import { toTime } from '@shared/utils'
import { useEmrQuery, useFlags } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'

/**
 * Use this hook to determine if the care pathway modal should be shown.
 * It accounts for the flags, patientID, and if the clinician is actaully on the relevant visit
 * note for determining the next care pathway.
 */
export const useCarePathway = () => {
  const { isCarePathwayModalEnabled } = useFlags()
  const { patientId, patientQuery } = usePatient()
  const isInTreatment = arrayIncludes(
    ['in treatment', 'not responding', 'paused'],
    patientQuery?.data?.statuses?.patient,
  )

  const carePathwayQuery = useEmrQuery(
    'GET /care-pathways/next',
    {
      query: {
        patientId,
      },
    },
    {
      /*
       * This hook may mount in several places on the visit note at once.
       * Therefore, allow the data to be stale for 30 seconds to avoid unnecessary refetches.
       */
      staleTime: toTime('30 seconds').ms(),
      enabled: Boolean(isCarePathwayModalEnabled && patientId && isInTreatment),
    },
  )

  const carePathway = carePathwayQuery.data

  const isEnabled = isCarePathwayModalEnabled
  return {
    isEnabled,
    data: carePathway,
    // The current visit type is the last visit in the visits array (the first should be the intake visit)
    currentVisitType: carePathway?.visits.at(-1)?.type,
  }
}
