import { Combobox, Dialog, Transition } from '@headlessui/react'
import { useDebouncedValue } from '@mantine/hooks'
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch'
import { Patient } from '@shared/types'
import { toTime } from '@shared/utils'
import cn from 'classnames'
import { Fragment, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { emrApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import CommandPalettePatientRow from '../../../pages/core/command_palette/CommandPalettePatientRow'
import { useKeyCombo } from '../../../utils/hooks'
import CommandPalettePreview from './CommandPalettePreview'

const PaddedSpinner = () => {
  return (
    <div className='flex justify-center items-center py-4'>
      <ALoadingSpinner />
    </div>
  )
}

export const CommandPalette = () => {
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebouncedValue(query, toTime('400 ms').ms())
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [patientsQueryKey, patientsQueryFn] = emrApi.getQuery('GET /patients', {
    query: {
      q: debouncedQuery,
      orderBy: 'name',
    },
  })
  const patientsQuery = useQuery(patientsQueryKey, patientsQueryFn, { enabled: Boolean(query) })

  const people = patientsQuery.data ? patientsQuery.data.items : []
  const hasQuery = query !== ''
  const filteredPeople = hasQuery ? people : []

  useKeyCombo(['k', 'l', 'Control'], setOpen)

  const onPatientSelect = (patient: Patient) => {
    setOpen(false)
    navigate(`/patients/${patient.oid}`)
  }

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20'
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Combobox
            as='div'
            className='mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'
            onChange={onPatientSelect}
            value={people[0]}
          >
            {({ activeOption }) => (
              <>
                <div className='relative flex'>
                  <AiOutlineSearch
                    className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <Combobox.Input
                    className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm'
                    placeholder='Search...'
                    onChange={event => setQuery(event?.target.value)}
                  />
                </div>

                {!patientsQuery.isLoading && people.length > 0 && (
                  <Combobox.Options
                    as='div'
                    static
                    hold
                    className='grid grid-cols-3 flex divide-x divide-gray-100'
                  >
                    <div
                      className={cn(
                        'col-span-1 max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                        { 'h-96': activeOption },
                      )}
                    >
                      <div className='-mx-2 text-sm text-gray-700 col-span-1'>
                        {filteredPeople.map((person: Patient) => (
                          <CommandPalettePatientRow key={person.oid} person={person} />
                        ))}
                      </div>
                    </div>
                    {activeOption && (
                      <CommandPalettePreview
                        onPatientSelect={onPatientSelect}
                        activeOption={activeOption}
                      />
                    )}
                  </Combobox.Options>
                )}

                {!patientsQuery.isLoading && hasQuery && filteredPeople.length === 0 && (
                  <div className='py-14 px-6 text-center text-sm sm:px-14'>
                    <p className='mt-4 font-semibold text-gray-900'>No people found</p>
                    <p className='mt-2 text-gray-500'>
                      We couldn’t find anything with that term. Please try again.
                    </p>
                  </div>
                )}
                {patientsQuery.isLoading && <PaddedSpinner />}
              </>
            )}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
