import { Colors, Stack, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import { TooltipProps as RechartsTooltipProps } from 'recharts'

type DrawerTooltipProps = {
  colors: Colors
  scoreMapping: {
    [key: number]: string
  }
} & RechartsTooltipProps<number, string>

const DrawerTooltip = ({ colors, scoreMapping, active, payload }: DrawerTooltipProps) => {
  if (!active || !payload) {
    return null
  }

  return (
    <Stack p='sm' spacing='sm' align='center' sx={{ backgroundColor: colors.background[6] }}>
      <Text bold color={colors.text[3]}>
        {scoreMapping[payload[0]?.payload.score]}
      </Text>
      <Text bold color={colors.text[3]}>
        {dayjs(payload[0]?.payload.completedAt).format('MMM DD YYYY')}
      </Text>
    </Stack>
  )
}

export default DrawerTooltip
