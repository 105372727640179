import {
  Card,
  CheckCircleIcon,
  Colors,
  Group,
  PaperClipIcon,
  Pill,
  SendIcon,
  SlashIcon,
  Stack,
  TertiaryButton,
  Text,
  useMantineTheme,
} from '@shared/components'
import {
  DispenseUnitType,
  PrescriptionResponse,
  hasRole,
  isClinician,
  readablePrescriptionStatusMap,
} from '@shared/types'
import { useReducer } from 'react'
import { useAuth } from '../../../context/auth'
import { PRESCRIPTION_QUEUER_ROLES } from '../../../utils/prescriptionUtils'
import { PrescriptionDeleteModal } from '../PrescriptionDeleteModal'
import { PrescriptionDetailDrawer } from '../PrescriptionDetailDrawer'
import { PrescriptionReviewSendDrawer } from '../PrescriptionReviewSendModal'

export const StatusIcon = ({ status }: { status: string }) => {
  const PENDING = ['Entered', 'eRxSent', 'Cancel Requested', 'Cancel Pending', 'EPCS Signed']
  const ERROR = ['Cancel Denied', 'Error', 'EPCS Error']
  const COMPLETED = ['Completed', 'Pharmacy Verified']
  const theme = useMantineTheme()
  if (!status) {
    return <SlashIcon size='sm' color={theme.other.colors.error[0]} />
  }

  if (PENDING.includes(status)) {
    return <SendIcon size='sm' color={theme.other.colors.background[4]} />
  }
  if (ERROR.includes(status)) {
    return <SlashIcon size='sm' color={theme.other.colors.error[0]} />
  }
  if (COMPLETED.includes(status)) {
    return <CheckCircleIcon size='sm' color={theme.other.colors.success[0]} />
  }
  if (status === 'Cancelled') {
    return <SlashIcon size='sm' color={theme.other.colors.error[0]} />
  }
  if (status === 'Queued') {
    return <PaperClipIcon size='sm' color={theme.other.colors.warning[0]} />
  }
  return <SendIcon size='sm' color={theme.other.colors.background[4]} />
}

const drawerStateReducer = (
  _state:
    | { drawer: 'reorder' | 'cancel' | 'view' }
    | { drawer: 'send'; editOnly: boolean }
    | { drawer: null },
  action:
    | 'SHOW_SEND_DRAWER'
    | 'SHOW_EDIT_DRAWER'
    | 'SHOW_CANCEL_DRAWER'
    | 'SHOW_VIEW_DRAWER'
    | 'CLOSE_DRAWER',
) => {
  switch (action) {
    case 'SHOW_SEND_DRAWER':
      return { drawer: 'send' as const, editOnly: false }
    case 'SHOW_EDIT_DRAWER':
      return { drawer: 'send' as const, editOnly: true }
    case 'SHOW_CANCEL_DRAWER':
      return { drawer: 'cancel' as const }
    case 'SHOW_VIEW_DRAWER':
      return { drawer: 'view' as const }
    case 'CLOSE_DRAWER':
      return { drawer: null }
  }
}

export type PrescriptionRowProps = {
  prescription: PrescriptionResponse
  patientId: string
  setPrescriptionBanner?: (message: string, type: 'success' | 'warning' | 'error') => void
}

const PrescriptionRow = ({
  prescription,
  patientId,
  setPrescriptionBanner,
}: PrescriptionRowProps) => {
  const { currentUser } = useAuth()

  const status = prescription.status ?? ''

  const getStatus = () => {
    if (!status) {
      return 'Missing DoseSpot Id'
    }
    if (readablePrescriptionStatusMap[status]) {
      return readablePrescriptionStatusMap[status]
    }
    if (status === 'Queued' && prescription.prescription_queued_by) {
      return `Queued by ${prescription.prescription_queued_by}`
    }
    return status
  }
  const isCancelable = status === 'Entered' || status === 'Queued'
  const cancelPrescriptionEnabled = isClinician(currentUser) && isCancelable

  const [drawerState, dispatchDrawerAction] = useReducer(drawerStateReducer, {
    drawer: null,
  })

  // prescription_sent_by is the person for whom the prescription is queued in DS
  const sendPrescriptionEnabled =
    isClinician(currentUser) &&
    prescription.status === 'Queued' &&
    hasRole(currentUser, 'pc', 'spc') &&
    prescription.prescription_sent_by === currentUser.name

  const editPrescriptionEnabled =
    isClinician(currentUser) &&
    PRESCRIPTION_QUEUER_ROLES.includes(currentUser.role) &&
    prescription.status === 'Queued' &&
    prescription.prescription_queued_by === currentUser.name

  const medicationQuantityString =
    prescription.medication_quantity && prescription.dispense_unit_id
      ? ` - ${prescription.medication_quantity} (${
          DispenseUnitType[prescription.dispense_unit_id]
        })`
      : ''

  let daysSupplyString = ''
  if (prescription.medication_days_supply && Number(prescription.medication_days_supply) > 1) {
    daysSupplyString = ` - ${prescription.medication_days_supply} days`
  }
  if (prescription.medication_days_supply && Number(prescription.medication_days_supply) === 1) {
    daysSupplyString = ` - 1 day`
  }

  let refillsString = ''
  // will say `0 refills` if there are none
  if (prescription.refills && Number(prescription.refills) !== 1) {
    refillsString = ` - ${prescription.refills} refills`
  }
  if (prescription.refills && Number(prescription.refills) === 1) {
    refillsString = ` - 1 refill`
  }

  const closeDrawer = () => dispatchDrawerAction('CLOSE_DRAWER')

  return (
    <>
      <Card
        styles={({ background, warning }) => ({
          borderColor: prescription.status === 'Queued' ? warning[0] : undefined,
          backgroundColor: background[1],
        })}
      >
        <Stack spacing='sm'>
          <Group position='apart'>
            <Group spacing='sm'>
              <StatusIcon status={status} />
              <Text bold>{getStatus()}</Text>
            </Group>
            {prescription.type === 'bridge' && (
              <Pill variant='filled' status='warning'>
                Bridge
              </Pill>
            )}
          </Group>
          <Group>
            <Text color={(colors: Colors) => colors.text[1]}>
              {(prescription.effective_date &&
                new Date(prescription.effective_date).toLocaleDateString()) ||
                (prescription.timestamp &&
                  new Date(prescription.timestamp).toLocaleDateString())}{' '}
              | {prescription.medication_name}
              {medicationQuantityString}
              {daysSupplyString}
              {refillsString}
            </Text>
          </Group>
          {prescription.prescription_id && (
            <Group position='right' spacing='md'>
              {sendPrescriptionEnabled && (
                <TertiaryButton
                  onClick={() => {
                    dispatchDrawerAction('SHOW_SEND_DRAWER')
                  }}
                >
                  Review & send
                </TertiaryButton>
              )}
              {editPrescriptionEnabled && !sendPrescriptionEnabled && (
                <TertiaryButton
                  onClick={() => {
                    if (prescription.prescription_id) {
                      dispatchDrawerAction('SHOW_EDIT_DRAWER')
                    }
                  }}
                >
                  Edit
                </TertiaryButton>
              )}
              {cancelPrescriptionEnabled && (
                <TertiaryButton
                  onClick={() => {
                    dispatchDrawerAction('SHOW_CANCEL_DRAWER')
                  }}
                >
                  Delete
                </TertiaryButton>
              )}
              <TertiaryButton onClick={() => dispatchDrawerAction('SHOW_VIEW_DRAWER')}>
                Details
              </TertiaryButton>
            </Group>
          )}
        </Stack>
      </Card>
      {prescription.prescription_id && (
        <>
          <PrescriptionReviewSendDrawer
            key={`${prescription.prescription_id}-review-send-drawer`}
            onClose={closeDrawer}
            opened={drawerState.drawer === 'send'}
            patientId={patientId}
            prescriptionId={prescription.prescription_id}
            editOnly={Boolean(drawerState.editOnly)}
            setPrescriptionBanner={(...args) => setPrescriptionBanner?.(...args)}
          />
          <PrescriptionDeleteModal
            key={`${prescription.prescription_id}-delete-modal`}
            opened={drawerState.drawer === 'cancel'}
            onClose={closeDrawer}
            patientId={patientId}
            prescriptionId={prescription.prescription_id}
            setPrescriptionBanner={(...args) => setPrescriptionBanner?.(...args)}
          />
          <PrescriptionDetailDrawer
            key={`${prescription.prescription_id}-delete-drawer`}
            onClose={closeDrawer}
            opened={drawerState.drawer === 'view'}
            patientId={patientId}
            prescriptionId={prescription.prescription_id}
            setPrescriptionBanner={(...args) => setPrescriptionBanner?.(...args)}
          />
        </>
      )}
    </>
  )
}

export default PrescriptionRow
