import { EncounterStatuses, hasGroupRole } from '@shared/types'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { useAccess } from '../../utils/hooks/use-access'
import { useFlags } from '../../utils/hooks/use-flags'
import PIRQ from '../care_team/irq/PIRQ'
import BillingEncounters from './encounters/BillingEncounters'
import BillingInsuranceMatching from './insurance/BillingInsuranceMatching'
import { BillingInsuranceMatchingV2 } from './insurance/BillingInsuranceMatchingV2'
import { PayerConfigs } from './payerConfigs'

const PBilling = () => {
  const { currentUser } = useAuth()
  const hasAccess = useAccess()
  const flags = useFlags()

  const defaultPage = hasGroupRole(currentUser, 'enrollmentCoordinator')
    ? '/billing/irq'
    : `/billing/encounters/${EncounterStatuses.Unsubmitted}`

  return (
    <Routes>
      <Route path='/irq' element={<PIRQ />} />
      <Route path='/encounters/:status/:encounterId' element={<BillingEncounters />} />
      <Route path='/encounters/:status' element={<BillingEncounters />} />
      <Route path='/payer-configs' element={<PayerConfigs />} />
      {hasAccess.insuranceMatchingPage && (
        <Route path='/insurance-matching' element={<BillingInsuranceMatching />} />
      )}
      {hasAccess.insuranceMatchingPage && flags.qgendaMigration && (
        <Route path='/insurance-matching-v2' element={<BillingInsuranceMatchingV2 />} />
      )}
      <Route path='*' element={<Navigate replace to={defaultPage} />} />
    </Routes>
  )
}

export default PBilling
