import { showNotification } from '@shared/components'
import { EmrLunaApi, OpheliaHttpError, getOpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { emrLunaApi } from '../../api'
import * as FullStory from '../../utils/fullstory'

export const useLunaMutation = <OpheliaRoute extends keyof EmrLunaApi>(
  route: OpheliaRoute,
  options?: Omit<
    UseMutationOptions<
      EmrLunaApi[OpheliaRoute]['res'],
      AxiosError<OpheliaHttpError>,
      Partial<EmrLunaApi[OpheliaRoute]['req']>
    >,
    'mutationFn'
  > & { showNotificationOnError?: boolean },
) => {
  // By default, an error notification will be shown when an error occurs
  const showErrorNotif = options?.showNotificationOnError !== false

  // Create a copy of the options object to avoid mutating the original object
  const optionsCopy = { ...(options || {}) }

  // Get the onError function from the options object (if it exists)
  const { onError } = optionsCopy

  /**
   * Create a new onError function that shows a notification if the error is an OpheliaHttpError
   * and then calls the original onError function (if it exists)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatedOnError = (error: AxiosError<OpheliaHttpError>, variables: any, context: any) => {
    const opheliaHttpError = getOpheliaHttpError(error)

    if (opheliaHttpError && showErrorNotif) {
      showNotification({
        variant: 'error',
        title: 'Something went wrong',
        message: opheliaHttpError,
        autoClose: false,
      })
      FullStory.event('Error Notification Shown', {
        errorMessage: opheliaHttpError,
      })
    }

    void onError?.(error, variables, context)
  }

  // Update the onError function in the options object
  optionsCopy.onError = updatedOnError

  return useMutation<
    EmrLunaApi[OpheliaRoute]['res'],
    AxiosError<OpheliaHttpError>,
    Partial<EmrLunaApi[OpheliaRoute]['req']>
  >(emrLunaApi.getMutation(route), optionsCopy)
}
