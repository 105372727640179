import { CaseReviewNote } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class CaseReviewNoteApi extends BaseApi {
  update = ({ oid, data }: { oid: string; data: Partial<CaseReviewNote> }) => {
    return this.json<CaseReviewNote>({
      method: 'PUT',
      url: `/caseReviewNote/${oid}`,
      data,
    })
  }

  delete = (oid: string) => {
    return this.json<Res>({ method: 'DELETE', url: `/caseReviewNote/${oid}` })
  }
}
