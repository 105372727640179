import { useForm } from '@mantine/form'
import { useDidUpdate } from '@mantine/hooks'
import {
  Alert,
  AlertIcon,
  Center,
  Checkbox,
  DateInput,
  Drawer,
  FormSkipPredicate,
  Group,
  PrimaryButton,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import { InsuranceData, InsuranceType, ManualInsuranceData } from '@shared/types'
import { dayjs, getInsurancePlanId } from '@shared/utils'
import { isAxiosError } from 'axios'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi, patientsApi } from '../../../api'
import { InsuranceSelect } from '../../../components/forms/InsuranceSelect'
import { IconEligible } from '../../../components/icons/IconEligible'
import { isAtleastOneWord, isBirthday, isRequired } from '../../../utils/formValidation'
import IRQCardViewer from './IRQCardViewer'

type Form = {
  provider: string
  firstName: string
  lastName: string
  birthday: string
  memberId: string
  patientIsPrimarySubscriber: boolean
  primarySubscriberFirstName: string
  primarySubscriberLastName: string
  primarySubscriberBirthday: string
}

export type IRQEligibleVerificationDrawerProps = {
  opened: boolean
  patientId: string
  insuranceData?: InsuranceData | null
  manualInsuranceData?: ManualInsuranceData | null
  insuranceType: InsuranceType
  frontFileId?: string
  backFileId?: string
  verifyMethodRadioGroup: React.ReactNode
  onClose: () => void
  onSave: () => void
}

const IRQEligibleVerificationDrawer = ({
  opened,
  insuranceData,
  manualInsuranceData,
  patientId,
  insuranceType,
  frontFileId,
  backFileId,
  onClose,
  onSave,
  verifyMethodRadioGroup,
}: IRQEligibleVerificationDrawerProps) => {
  const skipIfIsPrimarySubscriber: FormSkipPredicate<string, Form> = (_, values) =>
    Boolean(values?.patientIsPrimarySubscriber)

  const primaryInsuranceValidation = {
    provider: isRequired,
    firstName: validateWith(isRequired, isAtleastOneWord),
    lastName: validateWith(isRequired, isAtleastOneWord),
    birthday: validateWith(isRequired, isBirthday),
    memberId: validateWith(isRequired),
    primarySubscriberFirstName: validateWith(
      skipIfIsPrimarySubscriber,
      isRequired,
      isAtleastOneWord,
    ),
    primarySubscriberLastName: validateWith(
      skipIfIsPrimarySubscriber,
      isRequired,
      isAtleastOneWord,
    ),
    primarySubscriberBirthday: validateWith(skipIfIsPrimarySubscriber, isRequired, isBirthday),
  }

  const form = useForm<Form>({
    initialValues: {
      provider: getInsurancePlanId(insuranceData?.eligiblePayerId, insuranceData?.provider),
      firstName: insuranceData?.firstName ?? '',
      lastName: insuranceData?.lastName ?? '',
      birthday: insuranceData?.cardholderBirthday ?? manualInsuranceData?.birthday ?? '',
      memberId: insuranceData?.memberId ?? '',
      patientIsPrimarySubscriber: insuranceData?.patientIsPrimarySubscriber ?? false,
      primarySubscriberFirstName: insuranceData?.primarySubscriberFirstName ?? '',
      primarySubscriberLastName: insuranceData?.primarySubscriberLastName ?? '',
      primarySubscriberBirthday: insuranceData?.primarySubscriberBirthday ?? '',
    },
    validate: primaryInsuranceValidation,
  })

  const initializeForm = (
    insuranceData?: InsuranceData | null,
    manualInsuranceData?: ManualInsuranceData | null,
  ) => {
    form.setValues({
      provider: getInsurancePlanId(insuranceData?.eligiblePayerId, insuranceData?.provider),
      firstName: insuranceData?.firstName ?? '',
      lastName: insuranceData?.lastName ?? '',
      birthday: insuranceData?.cardholderBirthday ?? manualInsuranceData?.birthday ?? '',
      memberId: insuranceData?.memberId ?? '',
      patientIsPrimarySubscriber: insuranceData?.patientIsPrimarySubscriber ?? false,
      primarySubscriberFirstName: insuranceData?.primarySubscriberFirstName ?? '',
      primarySubscriberLastName: insuranceData?.primarySubscriberLastName ?? '',
      primarySubscriberBirthday: insuranceData?.primarySubscriberBirthday ?? '',
    })
  }

  useDidUpdate(
    () => initializeForm(insuranceData, manualInsuranceData),
    [insuranceData, manualInsuranceData],
  )

  const [error, setError] = useState<{
    details: string
    follow_up_action_description: string
    reject_reason_description: string
  }>()

  const eligiblePayerId = form.values.provider?.split('__')[0] as string

  const updateInsuranceDataMutationFunction = emrApi.getMutation(
    'PUT /patient/:patientId/insurance/update',
  )

  const verifyInsurance = useMutation(
    ['patientsApi.verifyInsuranceData', patientId],
    async () => {
      const formData = {
        firstName: form.values.firstName ?? '',
        lastName: form.values.lastName ?? '',
        memberId: form.values.memberId ?? '',
        eligiblePayerId,
      }

      await updateInsuranceDataMutationFunction({
        params: {
          patientId,
        },
        data: {
          ...formData,
          provider: form.values.provider.split('__')[1],
          cardholderBirthday: form.values.birthday,
          patientIsPrimarySubscriber: form.values.patientIsPrimarySubscriber,
          primarySubscriberFirstName: form.values.primarySubscriberFirstName,
          primarySubscriberLastName: form.values.primarySubscriberLastName,
          primarySubscriberBirthday: form.values.primarySubscriberBirthday,
          insuranceType,
        },
      })
      return patientsApi.verifyInsuranceData({
        patientId,
        data: { ...formData, insuranceType },
      })
    },
    {
      onSuccess: () => {
        onSave()
      },
      onError: error => {
        if (isAxiosError(error)) {
          setError(error.response?.data.error)
        }
      },
    },
  )

  const verify = async () => {
    if (form.validate().hasErrors) {
      return
    }
    if (eligiblePayerId) {
      await verifyInsurance.mutateAsync()
      onClose()
    } else {
      setError({
        reject_reason_description: 'Unknown Payer',
        details: "We weren't able to match the selected insurance provider with a known payer.",
        follow_up_action_description: 'Please select a known insurance provider from the dropdown.',
      })
    }
  }

  return (
    <Drawer
      title='Verify via Eligible'
      size='lg'
      opened={opened}
      position='right'
      onClose={onClose}
      footer={
        <Group position='right'>
          <PrimaryButton
            onClick={verify}
            disabled={verifyInsurance.isLoading}
            loading={verifyInsurance.isLoading}
          >
            Edit & verify
          </PrimaryButton>
        </Group>
      }
    >
      {verifyInsurance.isLoading && (
        <Center sx={{ height: '100%' }}>
          <Stack spacing='xs'>
            <IconEligible />
            <Text>Verifying insurance...</Text>
          </Stack>
        </Center>
      )}
      {!verifyInsurance.isLoading && (
        <Stack
          sx={theme => ({
            padding: theme.other.sizes.gap.xl,
          })}
        >
          {error && (
            <Alert
              variant='primary'
              icon={<AlertIcon color='red' />}
              title='Status: Failed'
              actionIcon={dayjs().format('MM/DD/YYYY')}
            >
              <Text bold mt='sm'>
                Details
              </Text>
              <Text>{error.details}</Text>
              <Text bold mt='sm'>
                Follow-up action
              </Text>
              <Text>{error.follow_up_action_description}</Text>
            </Alert>
          )}
          {verifyMethodRadioGroup}
          <IRQCardViewer patientId={patientId} frontFileId={frontFileId} backFileId={backFileId} />
          <InsuranceSelect
            label='Insurance plan'
            patientId={patientId}
            {...form.getInputProps('provider')}
          />
          <TextInput
            label='Cardholder first name'
            placeholder='e.g., John'
            maxLength={64}
            {...form.getInputProps('firstName')}
          />
          <TextInput
            label='Cardholder last name'
            placeholder='e.g., Smith'
            maxLength={64}
            {...form.getInputProps('lastName')}
          />
          <DateInput label='Cardholder birthday' {...form.getInputProps('birthday')} />
          <TextInput
            label='Member ID'
            placeholder='Member ID'
            maxLength={64}
            {...form.getInputProps('memberId')}
          />
          <Checkbox
            label='Patient is the primary subscriber'
            {...form.getInputProps('patientIsPrimarySubscriber', { type: 'checkbox' })}
          />
          {!form.values.patientIsPrimarySubscriber && (
            <>
              <TextInput
                label='Primary subscriber first name'
                placeholder='e.g., John'
                maxLength={64}
                {...form.getInputProps('primarySubscriberFirstName')}
              />
              <TextInput
                label='Primary subscriber last name'
                placeholder='e.g., Smith'
                maxLength={64}
                {...form.getInputProps('primarySubscriberLastName')}
              />
              <TextInput
                label='Primary subscriber birthday'
                placeholder='MM/DD/YYYY'
                maxLength={64}
                {...form.getInputProps('primarySubscriberBirthday')}
              />
            </>
          )}
        </Stack>
      )}
    </Drawer>
  )
}

export default IRQEligibleVerificationDrawer
