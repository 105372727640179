import { Stack, Text } from '@shared/components'
import { ReactNode } from 'react'

export const PatientVisitNoteReadOnlyText = ({
  label,
  value,
}: {
  label?: string | ReactNode | null
  value?: string | readonly string[] | number | null
}) => {
  return (
    <Stack spacing='sm'>
      <Text color={colors => colors.text[1]}>{label}</Text>
      <Text color={colors => colors.text[0]}>{value}</Text>
    </Stack>
  )
}
