import { InputWrapper, Stack } from '@shared/components'
import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { BarrierToTreatmentCheckboxSection } from './BarrierToTreatmentCheckboxSection'

export const BarriersToTreatmentSection = ({ form }: { form: OnboardingVisitNoteForm }) => {
  return (
    <PatientVisitNoteSection title='Barriers to treatment'>
      <InputWrapper {...form.getInputProps('primary_barrier_to_treatment')}>
        <Stack>
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_none_or_no_barriers'
            label='None/no barriers to treatment'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_cannot_afford_services'
            label='Cannot afford services'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_wait_time_too_long'
            label='Wait time too long'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_lack_of_technology_access'
            label='Technology access or literacy issues'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_clinical_services_not_offered'
            label='Clinical services not offered'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_preferred_appointment_time_not_offered'
            label='Preferred appointment time not offered'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_does_not_have_required_documentation'
            label='Does not have required documentation'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_other'
            label='Other'
            form={form}
          />
          <BarrierToTreatmentCheckboxSection
            barrierKey='barriers_to_treatment_not_discussed'
            label='Not discussed'
            form={form}
          />
        </Stack>
      </InputWrapper>
    </PatientVisitNoteSection>
  )
}
