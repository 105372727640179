import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Text,
  useBanner,
} from '@shared/components'
import { ShipmentResult } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'
import { useEmrMutation } from '../../../utils/hooks'

type LabOrderCancelModalProps = {
  orderBeingCanceled: ShipmentResult | null
  onClose: () => void
}

const LabOrderCancelContent = ({ orderBeingCanceled, onClose }: LabOrderCancelModalProps) => {
  const queryClient = useQueryClient()
  const { patientID, patientQuery } = usePatient()
  const { showBanner } = useBanner()

  const cancelOrder = useEmrMutation('DELETE /shipments/:shipmentId')

  const handleOrderCancelation = ({ shipmentId }: { shipmentId: string }) => {
    cancelOrder.mutate(
      {
        params: { shipmentId },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(
            emrApi.getQuery('GET /patient/:patientId/shipments', {
              params: { patientId: patientID },
            })[0],
          )
          onClose()
        },
        onError: () => {
          showBanner({
            type: 'error',
            label: 'Something went wrong, unable to cancel lab order',
            dismissable: true,
          })
          onClose()
        },
      },
    )
  }

  return (
    <>
      <ModalHeader onClose={onClose}>Cancel Shipfusion order</ModalHeader>
      <ModalContent>
        <Text>
          Are you sure you want to cancel {patientQuery?.data?.personalData.firstName}&apos;s order
          from {dayjs(orderBeingCanceled?.timestamp).format('M/D/YYYY')}?
        </Text>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don&apos;t cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => handleOrderCancelation({ shipmentId: orderBeingCanceled?.id || '' })}
          >
            Yes, cancel order
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const LabOrderCancelModal = ({
  opened,
  ...props
}: LabOrderCancelModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <LabOrderCancelContent {...props} />
    </BetterModal>
  )
}
