import { Divider, Group, PencilIcon, TertiaryButton } from '@shared/components'

export type EditableSectionHeaderProps = {
  onEdit: (() => void) | null
  moreActions?: React.ReactNode
  isEditing: boolean
  title: React.ReactNode
}

export const EditableSectionHeader = (props: EditableSectionHeaderProps) => {
  return (
    <>
      <Group align='center' position='apart'>
        {props.title}
        {/* hide edit button if onEdit prop is nullish */}
        {props.onEdit && !props.isEditing && (
          <Group>
            <TertiaryButton
              rightIcon={<PencilIcon color={c => c.actions[0]} />}
              onClick={props.onEdit}
            >
              Edit
            </TertiaryButton>
            {props.moreActions}
          </Group>
        )}
      </Group>
      <Divider my='sm' />
    </>
  )
}
