import { useForm } from '@mantine/form'
import {
  Alert,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  ShieldIcon,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { YesOrNoRadio } from '../../../components/forms/YesOrNoRadio'
import { isRequired } from '../../../utils/formValidation'
import * as FullStory from '../../../utils/fullstory'
import { useEmrMutation } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'

type DelaySchedulingModalProps = {
  opened: boolean
  onClose: () => void
}

export const DelaySchedulingModal = ({ opened, onClose }: DelaySchedulingModalProps) => {
  const { patientId, patientQuery, setModal } = usePatient()

  const patient = patientQuery?.data

  const form = useForm<{
    canDelayScheduling: 'yes' | 'no' | null
  }>({
    initialValues: {
      canDelayScheduling: null,
    },
    validate: {
      canDelayScheduling: validateWith(isRequired),
    },
  })

  const updatePatient = useEmrMutation('PUT /patient/:patientId', {
    onSuccess: () => {
      FullStory.event('Delayed scheduling patient')
      onClose()
    },
  })

  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    const canDelayScheduling = form.values.canDelayScheduling === 'yes'

    // Delay scheduling the patient for 5 weeks
    const DELAY_DAYS = 35
    const delaySchedulingUntil = dayjs().add(DELAY_DAYS, 'days').toISOString()

    if (canDelayScheduling) {
      await updatePatient.mutateAsync({
        params: { patientId },
        data: { delaySchedulingUntil },
      })
    } else {
      setModal({ type: 'schedule-visit-calendar' })
    }
  }

  return (
    <Modal
      title='Delay scheduling'
      opened={opened}
      onClose={onClose}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={() => setModal({ type: 'care-pathway-modal' })}>
            No, go back
          </SecondaryButton>
          <PrimaryButton loading={updatePatient.isLoading} onClick={onSubmit}>
            Yes, continue
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Alert variant='primary' title='In 4 weeks' icon={<ShieldIcon />}>
          {patient?.personalData.firstName} is {patient?.statuses.levelOfCare} and available anytime
          in the week
        </Alert>
        <YesOrNoRadio
          label='Can we delay scheduling for this patient?'
          {...form.getInputProps('canDelayScheduling')}
          isEditing
        />
        <Text>Would you like to continue?</Text>
      </Stack>
    </Modal>
  )
}
