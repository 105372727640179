/* eslint-disable no-magic-numbers */

import { useForm as useMantineForm } from '@mantine/form'
import {
  Grid,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  isAnySelected,
  validateWith,
} from '@shared/components'
import { Appointment, ClosedNoteDetails, Employee } from '@shared/types'
import { dayjs, toTime, validCharacters } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FieldValues, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import ANoteComponentGroup from '../../../components/atoms/ANoteComponentGroup'
import ANoteDropdown from '../../../components/atoms/ANoteDropdown'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import ANoteTextInput from '../../../components/atoms/ANoteTextInput'
import ANoteYesNoRadioInput from '../../../components/atoms/ANoteYesNoRadioInput'
import { required } from '../../../components/molecules/MDictionary'
import ODSSecondaryButton from '../../../components/ods/ODSSecondaryButton'
import { isRequired } from '../../../utils/formValidation'
import {
  isNotAbleToWriteToNote,
  medicationName2Strength,
  medicationNames,
  renderDurationString,
} from '../../../utils/utils'
import MVisitNotePrescriptionSection from './MVisitNotePrescriptionSection'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'
import UpcomingVisitsSection from './UpcomingVisitsSection'
import { VisitNoteSection } from './VisitNoteSection'

const emptyForm = {
  note_sub_type: '',
}

type DateInputBoxProps = {
  value: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

const DateInputBox = React.forwardRef(({ value, onClick, disabled }: DateInputBoxProps) => (
  <ODSSecondaryButton
    onClick={onClick}
    type='button'
    message={value}
    className='mt-2'
    disabled={disabled}
  />
))

DateInputBox.displayName = 'DateInputBox'

type CheckInMedicationForm = {
  medication_name: string
  medication_strength: string
  medication_days_supply: string
  medication_quantity: string
}

type OCheckInCallVisitFormProps = {
  currentUser?: Employee
  patientID: string
  visitID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: Record<string, any> | null
  editMode: boolean
  sidebar: boolean
  closedNoteDetails?: ClosedNoteDetails | null
  visit?: Appointment | null
  setCloseNoteModal: (closeNoteModal: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meetingDetails?: Record<string, any> | null
}

const OCheckInCallVisitForm = ({
  currentUser,
  patientID,
  visitID,
  notes,
  editMode,
  sidebar,
  closedNoteDetails,
  visit,
  meetingDetails,
  setCloseNoteModal,
}: OCheckInCallVisitFormProps) => {
  const { errors, register, handleSubmit, setValue, watch } = useForm()
  const [errorMessage, setErrorMessage] = useState('')
  const [savedAt, setSavedAt] = useState('')
  const [saving, setSaving] = useState(false)
  const [contentsToSave, setContentsToSave] = useState<FieldValues | null>(null)
  const [inductionStartDate, setInductionStartDate] = useState<Date | null>()
  const [firstDoseMedicationStrengthDefaultValue, setFirstDoseMedicationStrengthDefaultValue] =
    useState('')
  const [secondDoseMedicationStrengthDefaultValue, setSecondDoseMedicationStrengthDefaultValue] =
    useState('')
  const [stableMedicationStrengthDefaultValue, setStableMedicationStrengthDefaultValue] =
    useState('')
  const masterWatcher = watch()
  const past_buprenorphine_exposure = watch('past_buprenorphine_exposure')
  const patient_desire_of_dose_change = watch('patient_desire_of_dose_change')
  const stable_dose_medication_name = watch('stable_dose_medication_name')
  const first_dose_medication_name = watch('first_dose_medication_name')
  const second_dose_medication_name = watch('second_dose_medication_name')
  const note_sub_type = watch('note_sub_type')

  const [medicationSentViaDosespot, setMedicationSentViaDosespot] = useState(false)

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const skipIfMedicationNotSentViaDosespot = () => !medicationSentViaDosespot

  const medicationForm = useMantineForm<CheckInMedicationForm>({
    initialValues: {
      medication_name: notes?.medication_name ?? '',
      medication_strength: notes?.medication_strength ?? '',
      medication_days_supply: notes?.medication_days_supply ?? '',
      medication_quantity: notes?.medication_quantity ?? '',
    },
    validate: {
      medication_name: validateWith(
        skipIfMedicationNotSentViaDosespot,
        isAnySelected(medicationNames(), 'Required'),
      ),
      medication_strength: validateWith(
        skipIfMedicationNotSentViaDosespot,
        (value?: string, values?: CheckInMedicationForm) =>
          isAnySelected(medicationName2Strength(values?.medication_name), 'Required')(value),
      ),
      medication_days_supply: validateWith(skipIfMedicationNotSentViaDosespot, isRequired),
      medication_quantity: validateWith(skipIfMedicationNotSentViaDosespot, isRequired),
    },
  })

  const recordedMedication = medicationForm.values.medication_name
    ? {
        medication_name: medicationForm.values.medication_name,
        medication_strength: medicationForm.values.medication_strength,
        medication_quantity: medicationForm.values.medication_quantity,
        medication_days_supply: medicationForm.values.medication_days_supply,
      }
    : undefined

  useEffect(() => {
    if (!notes) {
      return
    }
    setValue('note_sub_type', notes.note_sub_type ?? 'Default')
  }, [notes, setValue])

  useEffect(() => {
    if (!notes) {
      return
    }
    if (notes.first_dose_medication_strength) {
      setFirstDoseMedicationStrengthDefaultValue(notes.first_dose_medication_strength)
    }
    if (notes.second_dose_medication_strength) {
      setSecondDoseMedicationStrengthDefaultValue(notes.second_dose_medication_strength)
    }
    if (notes.stable_medication_strength) {
      setStableMedicationStrengthDefaultValue(notes.stable_medication_strength)
    }

    for (const [key, value] of Object.entries(notes)) {
      if (key === 'induction_start_date') {
        setInductionStartDate(new Date(value))
      } else if (key !== 'note_sub_type') {
        setValue(key, value)
      }
    }
  }, [notes, setValue, note_sub_type])

  const save = useCallback(
    async (data: FieldValues) => {
      if (isNotAbleToWriteToNote(currentUser?.calendarId, visit)) {
        return
      }

      if (isEqual({ ...data }, emptyForm)) {
        return
      }

      if (recordedMedication && medicationForm.validate().hasErrors) {
        return
      }

      const body = {
        type: 'blank' as const,
        content: {
          ...data,
          induction_start_date:
            note_sub_type === 'Induction' && past_buprenorphine_exposure === 'no'
              ? dayjs(inductionStartDate).format('MM/DD/YYYY')
              : null,
          ...(recordedMedication && { ...recordedMedication }),
        },
      }

      await updateVisitNote.mutateAsync(
        { params: { patientId: patientID, visitId: visitID }, data: body },
        {
          onSuccess: () => {
            setSavedAt(dayjs().format('MMMM Do YYYY, h:mm:ss a'))
            setErrorMessage('')
            setContentsToSave(data)
          },
          onError: () => {
            setErrorMessage(
              'Failed to save your last edits. Please try to save again or contact the engineering team.',
            )
            setCloseNoteModal(false)
          },
        },
      )
    },
    [
      editMode,
      patientID,
      visitID,
      visit,
      inductionStartDate,
      note_sub_type,
      past_buprenorphine_exposure,
      currentUser,
      setCloseNoteModal,
    ],
  )

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (!editMode) {
        return
      }
      if (isEmpty(masterWatcher)) {
        return
      }
      if (isEqual(masterWatcher, contentsToSave)) {
        return
      }
      await save(masterWatcher)
    }, toTime('3 sec').ms())

    return () => clearTimeout(interval)
  }, [masterWatcher, editMode, save, contentsToSave])

  const saveNote = async () => {
    setSaving(true)
    await save(masterWatcher)
  }

  const onSubmit = async () => {
    setCloseNoteModal(true)
    setSaving(true)
    await save(masterWatcher)
  }

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  const getRequired = (variable: string, value: string) => {
    if (variable === value) {
      return true
    }
    return false
  }

  useEffect(() => {
    setValue('second_dose_medication_name', first_dose_medication_name)
  }, [first_dose_medication_name, setValue])

  const pastBupExposure = (exposure: string, val: string) => {
    if (exposure === val) {
      return ''
    }
    return 'hidden'
  }

  const medicationStrengthOptions = (medicationName: string) => {
    if (
      medicationName === 'Suboxone (sublingual - film)' ||
      medicationName === 'Buprenorphine-Naloxone (sublingual - film)'
    ) {
      const strengths = medicationName2Strength(medicationName) || []
      return ['0.5 mg', '1 mg', ...strengths]
    }
    return medicationName2Strength(medicationName) ?? []
  }

  const dateSigned = closedNoteDetails?.locked_at ? dayjs(closedNoteDetails.locked_at) : undefined

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      {!sidebar && (
        <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-50'>
          <div className='flex flex-row h-full'>
            <div className='flex justify-start items-center flex-grow-1 flex-shrink-0'>
              {errorMessage && editMode && (
                <p className='text-xs text-red-800 font-semibold'>{errorMessage}</p>
              )}
              {savedAt && editMode && (
                <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
              )}
              {!editMode && closedNoteDetails && (
                <p className='text-xs text-gray-500'>
                  Signed on
                  {dateSigned?.isValid()
                    ? dateSigned.format(' MMMM D, YYYY [at] h:mm a ')
                    : ` ${closedNoteDetails.locked_at} `}
                  by {closedNoteDetails.locked_by_name}
                  <br />
                  {renderDurationString({
                    clinicianTime: meetingDetails?.clinicianTime,
                    patientTime: meetingDetails?.patientTime,
                  })}
                </p>
              )}
            </div>
            {editMode && (
              <MVisitNoteSavingHeader
                dateTime={visit?.datetime}
                saveNote={saveNote}
                saving={saving}
                editMode={editMode}
              />
            )}
          </div>
        </div>
      )}
      <ANoteSection hideBorder>
        <ANoteDropdown
          name='note_sub_type'
          id='note_sub_type'
          ref={register()}
          label='Check-In Call Note Type'
          options={['Default', 'Induction']}
          error={errors}
          disabled={!editMode}
        />
      </ANoteSection>
      {note_sub_type === 'Induction' && (
        <ANoteSection>
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteYesNoRadioInput
              name='past_buprenorphine_exposure'
              id='past_buprenorphine_exposure'
              ref={register({
                required: { value: note_sub_type === 'Induction', message: required },
              })}
              label='Was this patient taking buprenorphine prior to starting with Ophelia?'
              className='col-span-6'
              disabled={!editMode}
              error={errors}
            />
            <ANoteYesNoRadioInput
              name='patient_stable_on_current_dose'
              id='patient_stable_on_current_dose'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'yes'),
                  message: required,
                },
              })}
              label='Is the patient stable on a current daily dose?'
              className={`${pastBupExposure(past_buprenorphine_exposure, 'yes')} col-span-6`}
              disabled={!editMode}
              error={errors}
            />
            <ANoteComponentGroup
              groupTitle={"What is the patient's current dose?"}
              className={`${pastBupExposure(past_buprenorphine_exposure, 'yes')}`}
            >
              <ANoteDropdown
                name='stable_dose_medication_name'
                id='stable_dose_medication_name'
                ref={register({
                  required: {
                    value: getRequired(past_buprenorphine_exposure, 'yes'),
                    message: required,
                  },
                })}
                label='Medication Name'
                options={medicationNames()}
                disabled={!editMode}
                naValue
                error={errors}
                className={`${pastBupExposure(past_buprenorphine_exposure, 'yes')} col-span-6`}
              />
              {stable_dose_medication_name && (
                <ANoteDropdown
                  name='stable_medication_strength'
                  id='stable_medication_strength'
                  ref={register({
                    required: {
                      value: getRequired(past_buprenorphine_exposure, 'yes'),
                      message: required,
                    },
                  })}
                  label='Medication Strength'
                  options={medicationName2Strength(stable_dose_medication_name) ?? []}
                  disabled={!editMode}
                  error={errors}
                  defaultValue={stableMedicationStrengthDefaultValue}
                  naValue
                  className={`${pastBupExposure(past_buprenorphine_exposure, 'yes')} col-span-3`}
                />
              )}
              <ANoteTextInput
                name='stable_medication_quantity'
                id='stable_medication_quantity'
                ref={register({
                  required: {
                    value: getRequired(past_buprenorphine_exposure, 'yes'),
                    message: required,
                  },
                })}
                label='Medication Quantity'
                error={errors}
                disabled={!editMode}
                type='number'
                className={`${pastBupExposure(past_buprenorphine_exposure, 'yes')}`}
              />
            </ANoteComponentGroup>
            {past_buprenorphine_exposure === 'no' && (
              <div className='flex flex-col items-start justify-start col-span-6'>
                <p className='block text-sm font-medium leading-5 text-gray-700'>
                  Induction Start Date
                </p>
                <DatePicker
                  disabled={!editMode}
                  selected={inductionStartDate}
                  onChange={date => setInductionStartDate(date as Date)}
                  customInput={<DateInputBox disabled={!editMode} value='' onClick={() => ({})} />}
                />
              </div>
            )}
            <ANoteComponentGroup
              groupTitle={
                past_buprenorphine_exposure === 'no' ? "What was the patient's first dose?" : ''
              }
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')}`}
            >
              <ANoteDropdown
                name='first_dose_medication_name'
                id='first_dose_medication_name'
                ref={register({
                  required: {
                    value: getRequired(past_buprenorphine_exposure, 'no'),
                    message: required,
                  },
                })}
                label='Medication Name'
                options={medicationNames()}
                disabled={!editMode}
                naValue
                error={errors}
                className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
              />
              {first_dose_medication_name && (
                <ANoteDropdown
                  name='first_dose_medication_strength'
                  id='first_dose_medication_strength'
                  ref={register({
                    required: {
                      value: getRequired(past_buprenorphine_exposure, 'no'),
                      message: required,
                    },
                  })}
                  label='Medication Strength'
                  options={medicationStrengthOptions(first_dose_medication_name)}
                  disabled={!editMode}
                  error={errors}
                  defaultValue={firstDoseMedicationStrengthDefaultValue}
                  naValue
                  className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
                />
              )}
            </ANoteComponentGroup>
            <ANoteYesNoRadioInput
              name='precipitated_withdrawal'
              id='precipitated_withdrawal'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'no'),
                  message: required,
                },
              })}
              label='Did the patient experience precipitated withdrawal symptoms after his or her first dose?'
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
              disabled={!editMode}
              error={errors}
            />
            <ANoteComponentGroup
              groupTitle={
                past_buprenorphine_exposure === 'no' ? "What was the patient's second dose?" : ''
              }
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')}`}
            >
              <ANoteDropdown
                name='second_dose_medication_name'
                id='second_dose_medication_name'
                ref={register()}
                label='Medication Name'
                options={medicationNames()}
                disabled={!editMode}
                naValue
                error={errors}
                className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
              />
              {second_dose_medication_name && (
                <ANoteDropdown
                  name='second_dose_medication_strength'
                  id='second_dose_medication_strength'
                  ref={register()}
                  label='Medication Strength'
                  options={medicationStrengthOptions(second_dose_medication_name)}
                  disabled={!editMode}
                  error={errors}
                  defaultValue={secondDoseMedicationStrengthDefaultValue}
                  naValue
                  className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
                />
              )}
              <ANoteTextInput
                name='length_since_first_dose'
                id='length_since_first_dose'
                ref={register({
                  required: {
                    value: getRequired(past_buprenorphine_exposure, 'no'),
                    message: required,
                  },
                })}
                label='What was the length between the first and second dose?'
                error={errors}
                disabled={!editMode}
                sidebar={sidebar}
                className={`${pastBupExposure(past_buprenorphine_exposure, 'no')}`}
                span='col-span-6'
              />
            </ANoteComponentGroup>
            <ANoteDropdown
              name='first_full_tablet_strength'
              id='first_full_tablet_strength'
              ref={register()}
              label="What was the patient's first full tablet/film strength?"
              options={['4 mg', '8 mg', '16 mg']}
              disabled={!editMode}
              naValue
              error={errors}
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')}`}
            />
            <ANoteTextInput
              name='first_full_tablet_time'
              id='first_full_tablet_time'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'no'),
                  message: required,
                },
              })}
              label="When was the patient's first full tablet/film taken?"
              error={errors}
              disabled={!editMode}
              sidebar={sidebar}
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')}`}
              span='col-span-6'
            />
            <ANoteYesNoRadioInput
              name='withdrawal_symptoms'
              id='withdrawal_symptoms'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'no'),
                  message: required,
                },
              })}
              label='Is the patient currently experiencing any withdrawal symptoms (use COWS as applicable)?'
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
              disabled={!editMode}
              error={errors}
            />
            <ANoteDropdown
              name='cravings_rating'
              id='cravings_rating'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'no'),
                  message: required,
                },
              })}
              label="What are the patient's cravings on a scale from 0 to 10?"
              options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              disabled={!editMode}
              error={errors}
              naValue
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-4`}
            />
            <ANoteDropdown
              name='patient_desire_of_dose_change'
              id='patient_desire_of_dose_change'
              ref={register({ required: { value: true, message: required } })}
              label='Does the patient desire to change his or her current daily dose?'
              options={[
                'No',
                'Yes, the patient wants a higher dose',
                'Yes, the patient wants a lower dose',
              ]}
              disabled={!editMode}
              error={errors}
              naValue
              className={`${past_buprenorphine_exposure ? '' : 'hidden'} col-span-4`}
            />
            <ANoteTextArea
              name='patient_desire_of_dose_change_comments'
              id='patient_desire_of_dose_change_comments'
              minRows={1}
              ref={register()}
              className={`${
                patient_desire_of_dose_change && patient_desire_of_dose_change !== 'No'
                  ? ''
                  : 'hidden'
              } ml-4 col-span-6`}
              disabled={!editMode}
              label='Why does the patient desire to change his or her dose?'
              error={errors}
              sidebar={sidebar}
            />
            <ANoteYesNoRadioInput
              name='follow_up_required'
              id='follow_up_required'
              ref={register({
                required: {
                  value: getRequired(past_buprenorphine_exposure, 'no'),
                  message: required,
                },
              })}
              label='Does the patient require another check-in call prior to his or her first weekly follow-up with the PC?'
              className={`${pastBupExposure(past_buprenorphine_exposure, 'no')} col-span-6`}
              disabled={!editMode}
              error={errors}
            />
          </ANoteSectionContent>
        </ANoteSection>
      )}
      {note_sub_type && (
        <ANoteSection>
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteTextArea
              label={note_sub_type === 'Induction' ? 'Additional Notes' : 'Notes'}
              name='content'
              id='content'
              minRows={2}
              ref={register()}
              disabled={!editMode}
              placeholder='Please include all details from your visit.'
              error={errors}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>
      )}
      <VisitNoteSection title='Medication'>
        <Stack spacing='sm'>
          <Text color={colors => colors.text[editMode ? 3 : 1]}>Medication</Text>
          <MVisitNotePrescriptionSection
            visitId={Number(visitID)}
            patientId={patientID}
            isLocked={Boolean(closedNoteDetails)}
            editMode={editMode}
            recordedMedication={recordedMedication}
            medicationSentViaDosespot={medicationSentViaDosespot}
            setMedicationSentViaDosespot={value => {
              setMedicationSentViaDosespot(value)
              medicationForm.setValues({
                medication_name: '',
                medication_quantity: '',
                medication_strength: '',
                medication_days_supply: '',
              })
            }}
          />
          {medicationSentViaDosespot && editMode && (
            <Grid gutter='sm'>
              <Grid.Col span={12}>
                <Select
                  label='Medication Name'
                  {...medicationForm.getInputProps('medication_name')}
                  data={medicationNames()}
                  disabled={!editMode}
                  placeholder='Select one...'
                />
              </Grid.Col>
              <Grid.Col
                span={12}
                sx={{ display: medicationForm.values.medication_name ? 'block' : 'none' }}
              >
                <Select
                  label='Medication Strength'
                  {...medicationForm.getInputProps('medication_strength')}
                  data={medicationName2Strength(medicationForm.values.medication_name) ?? []}
                  disabled={!editMode}
                  placeholder='Select one...'
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label='Medication Quantity'
                  {...medicationForm.getInputProps('medication_quantity')}
                  disabled={!editMode}
                  formatter={validCharacters.numbers}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label='Medication Days Supply'
                  {...medicationForm.getInputProps('medication_days_supply')}
                  disabled={!editMode}
                  formatter={validCharacters.numbers}
                />
              </Grid.Col>
            </Grid>
          )}
        </Stack>
      </VisitNoteSection>
      <Space h='md' />
      <UpcomingVisitsSection isNoteClosed={Boolean(closedNoteDetails)} isSidebar={sidebar} />
    </form>
  )
}

export default OCheckInCallVisitForm
