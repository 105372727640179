import { useToggle } from '@mantine/hooks'
import { AlertIcon, Container, Group, Stack, Text } from '@shared/components'
import { Diagnosis } from '@shared/types'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { AddDiagnosesDrawer } from '../components/AddDiagnosesDrawer'
import { CardHeader } from '../components/CardHeader'
import { EncounterDiagnosisItem } from '../components/EncounterDiagnosisItem'

export type EncounterDiagnosisSectionProps = {
  diagnoses?: Diagnosis[]
  encounterId: string
  showAddButton: boolean
}

export const EncounterDiagnosisSection = ({
  diagnoses,
  encounterId,
  showAddButton,
}: EncounterDiagnosisSectionProps) => {
  const queryClient = useQueryClient()
  const [diagnosisDrawerOpened, toggleDiagnosisDrawer] = useToggle()
  const [diagnosesBeingDeleted, setDiagnosesBeingDeleted] = useState<{ code: string }[]>([])

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  const removeDiagnosis = (diagnosis: { code: string; code_type: string; name: string }) => {
    const newDiagnoses = diagnoses?.filter(d => d.code !== diagnosis.code)
    setDiagnosesBeingDeleted([...diagnosesBeingDeleted, diagnosis])
    updateEncounter.mutate(
      {
        data: {
          id: encounterId,
          encounter: { diagnoses: newDiagnoses },
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(encounterQueryKey)
          setDiagnosesBeingDeleted(diagnosesBeingDeleted.filter(d => d.code !== diagnosis.code))
        },
      },
    )
  }

  return (
    <>
      <AddDiagnosesDrawer
        diagnoses={diagnoses || []}
        encounterId={encounterId}
        opened={diagnosisDrawerOpened}
        onClose={() => {
          toggleDiagnosisDrawer(false)
        }}
      />
      <Stack>
        <CardHeader
          title='Diagnosis'
          buttonLabel='Add'
          buttonOnClick={() => {
            toggleDiagnosisDrawer(true)
          }}
          showButton={showAddButton}
        />
        <Stack>
          {diagnoses?.length ? (
            diagnoses?.map(diagnosis => {
              return (
                <EncounterDiagnosisItem
                  key={diagnosis.code}
                  beingDeleted={diagnosesBeingDeleted.some(d => d.code === diagnosis.code)}
                  removeOnClick={() => removeDiagnosis(diagnosis)}
                  {...diagnosis}
                />
              )
            })
          ) : (
            <Container>
              <Group spacing='sm'>
                <AlertIcon />
                <Text>No diagnoses found</Text>
              </Group>
            </Container>
          )}
        </Stack>
      </Stack>
    </>
  )
}
