import { EMRTaskTypeMapType } from '@shared/types'
import capitalize from 'lodash/capitalize'

export const formatSubCategory = (type: EMRTaskTypeMapType | undefined) => {
  const category = type?.category || ''
  const unstyledSubCategory = category.split('.').pop()
  const subCategory = capitalize(unstyledSubCategory?.replaceAll('_', ' '))

  if (subCategory === 'Roi') {
    return 'ROI'
  }
  if (subCategory === 'Uds') {
    return 'UDS'
  }
  if (subCategory === 'Cocm discharge') {
    return 'CoCM discharge'
  }
  return subCategory
}
