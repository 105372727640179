import { useForm } from '@mantine/form'
import {
  Alert,
  BetterModal,
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  InfoIcon,
  Loader,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  ShieldIcon,
  Stack,
  Text,
  TextInput,
} from '@shared/components'
import {
  CARE_PATHWAY_RISK_FACTORS,
  CarePathway,
  CarePathwayOption,
  ISOString,
  LevelOfCareStatus,
  hasStatus,
} from '@shared/types'
import { useState } from 'react'
import * as FullStory from '../../../../utils/fullstory'
import { useEmrMutation, useEmrQuery, useFlags } from '../../../../utils/hooks'
import { useAccess } from '../../../../utils/hooks/use-access'
import { usePatient } from '../../PPatientContext'
import {
  InductionCarePathwayProgress,
  IntermediateCarePathwayProgress,
  StableCarePathwayProgress,
} from './CarePathwayProgress'

const CarePathwayProgress = (props: {
  patientName: string
  prevCarePathway: CarePathwayOption
  nextCarePathway: CarePathwayOption
  visits: { datetime: ISOString }[]
}) => {
  /*
   * Base case: patient is on stable care pathway and is not transitioning to a custom care pathway
   */
  if (props.prevCarePathway === 'stable' && props.nextCarePathway === 'stable') {
    return (
      <Stack spacing='md'>
        <Alert variant='success' icon={<ShieldIcon />}>
          {props.patientName} is on stable care pathway
        </Alert>
        <Text>Would you like to continue?</Text>
      </Stack>
    )
  }

  // Show plain text when asking the clinician if they want to transition to stable care pathway
  if (props.prevCarePathway === 'custom' && props.nextCarePathway === 'stable') {
    return <Text>Is {props.patientName} ready to transition to stable care pathway?</Text>
  }

  // For the other cases, we show the progress bar. Because of that, we cannot use the Alert component.
  let message = `${props.patientName} is on ${props.nextCarePathway} care pathway`

  if (props.nextCarePathway === 'intermediate') {
    message = `${props.patientName} is eligible for ${props.nextCarePathway} care pathway`
  }

  return (
    <Stack spacing='md'>
      <Box
        p='md'
        sx={({ other, radius }) => ({
          borderRadius: radius.sm,
          backgroundColor: other.colors.background[2],
        })}
      >
        <Stack spacing='md'>
          <Group spacing='sm'>
            <InfoIcon />
            <Text bold>{message}</Text>
          </Group>
          {props.nextCarePathway === 'induction' && (
            <InductionCarePathwayProgress visits={props.visits} />
          )}
          {props.nextCarePathway === 'intermediate' && (
            <IntermediateCarePathwayProgress visits={props.visits} />
          )}
          {/* show the stable pathway progress if the patient is just starting the stable pathway */}
          {props.nextCarePathway === 'stable' && props.visits.length < 3 && (
            <StableCarePathwayProgress visits={props.visits} />
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

type Props = {
  opened: boolean
  onClose: () => void
}

export const CarePathwayModalContent = (props: Props) => {
  /*
   * DEFAULT = what we determined the patient should be on
   * CUSTOM = what the clinician wants to put the patient on if not DEFAULT
   * INTERMEDIATE = what the clinician wants to put the patient on if they are not ready for stable after intake
   */
  const [selectedLevelOfCare, setSelectedLevelOfCare] = useState<
    'DEFAULT' | 'CUSTOM' | 'INTERMEDIATE'
  >('DEFAULT')

  const { patientId, patientQuery, setModal } = usePatient()
  const { canScheduleVisitHolds } = useAccess()
  const firstName = patientQuery?.data?.personalData?.firstName || ''
  const prevLevelOfCare = patientQuery?.data?.statuses?.levelOfCare || 'n/a'

  const { delayScheduling, visitHoldsV2 } = useFlags()

  const form = useForm<{
    riskFactors: CarePathway['riskFactors']
    riskFactorsFreeText: string
    nextCarePathway: CarePathwayOption | undefined
    nextLevelOfCare: LevelOfCareStatus | undefined
  }>({
    initialValues: {
      riskFactors: [],
      riskFactorsFreeText: '',
      nextCarePathway: undefined,
      nextLevelOfCare: undefined,
    },
    validate: {
      riskFactors: val => {
        if (selectedLevelOfCare !== 'DEFAULT' && val.length === 0) {
          return `Patient must have a risk factor for custom care pathway`
        }
      },
      riskFactorsFreeText: val => {
        if (form.values.riskFactors.includes('other') && val.trim().length === 0) {
          return 'Required'
        }
      },
      nextLevelOfCare: val => {
        if (selectedLevelOfCare !== 'DEFAULT' && !val) {
          return 'Required'
        }
      },
      nextCarePathway: val => {
        if (selectedLevelOfCare !== 'DEFAULT' && !val) {
          return 'Required'
        }
      },
    },
  })

  const onComplete = () => {
    // If the clinician is eligible to schedule visit holds, set the modal to `schedule-visit-hold`. Note: this is only for monthly patients.
    if (
      visitHoldsV2 &&
      canScheduleVisitHolds &&
      form.values.nextLevelOfCare === 'monthly' &&
      hasStatus(patientQuery?.data, 'in treatment')
    ) {
      setModal({ type: 'schedule-visit-hold' })
      return
    }

    // If the the clinician is eligible to delay scheduling the patient, set the modal to `delay-scheduling` and return
    if (delayScheduling) {
      setModal({ type: 'delay-scheduling' })
      return
    }

    // NOTE: calling `onComplete` effectively closes the modal bc it sets the modal to the calendar drawer.
    setModal({ type: 'schedule-visit-calendar', props: { visitTypesAllowed: ['Follow-Up Visit'] } })
  }

  const carePathwayQuery = useEmrQuery(
    'GET /care-pathways/next',
    {
      query: {
        patientId,
      },
    },
    {
      onSuccess: data => {
        form.setValues({
          nextLevelOfCare: data?.nextLevelOfCare,
          nextCarePathway: data?.nextCarePathway,
        })
      },
      onError: () => {
        if (props.opened) {
          // To not block scheduling, therefore close the modal and move on
          onComplete()
        }
      },
    },
  )

  const carePathway = carePathwayQuery?.data
  const currVisit = carePathway?.visits?.at(-1)

  const isEligibleForIntermediateCarePathway =
    carePathway?.visits.length === 2 && carePathway?.prevCarePathway !== 'induction'

  const createCarePathway = useEmrMutation('POST /care-pathways')

  const updatePatient = useEmrMutation('PUT /patient/:patientId')

  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await createCarePathway.mutateAsync({
      data: {
        patientId,
        appointmentId: currVisit?.oid || '',
        // We validate that this is set in the form
        selectedCarePathway: form.values.nextCarePathway as CarePathwayOption,
        riskFactors: form.values.riskFactors,
        riskFactorsFreeText: form.values.riskFactorsFreeText,
        // We validate that this is set in the form
        selectedLevelOfCare: form.values.nextLevelOfCare as LevelOfCareStatus,
      },
    })

    await carePathwayQuery.refetch()

    if (prevLevelOfCare !== form.values.nextLevelOfCare) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: {
          statuses: {
            levelOfCare: form.values.nextLevelOfCare,
          },
        },
      })

      /*
       * NOTE: refetch the patient query before moving on to the next step, else the calendar drawer will not
       * know the PT's correct level of care and therefore not show the correct window of time for the next visit.
       */
      await patientQuery?.refetch()
    }

    FullStory.event('Care Pathway Submitted')

    /*
     * This makes the model progress to the next step, which is the calendar.
     * No need to call close here because the modal will close itself when the calendar is opened.
     */
    onComplete()
  }

  if (
    carePathwayQuery.isFetching ||
    // The error gets handled in the `onError` cb
    carePathwayQuery.isError ||
    !carePathway
  ) {
    return (
      <>
        <ModalHeader onClose={props.onClose}>Schedule</ModalHeader>
        <ModalContent>
          <Stack align='center' justify='center' p='xl'>
            <Loader />
          </Stack>
        </ModalContent>
      </>
    )
  }

  const isLoading =
    createCarePathway.isLoading ||
    carePathwayQuery.isLoading ||
    updatePatient.isLoading ||
    patientQuery?.isLoading

  return (
    <>
      <ModalHeader onClose={props.onClose}>
        {selectedLevelOfCare === 'CUSTOM'
          ? 'Schedule'
          : `Schedule as ${form.values.nextLevelOfCare}`}
      </ModalHeader>
      <ModalContent>
        <Stack spacing='sm'>
          {/*
           * DEFAULT level of care
           */}
          {selectedLevelOfCare === 'DEFAULT' && (
            <CarePathwayProgress
              patientName={firstName}
              prevCarePathway={carePathway.prevCarePathway}
              nextCarePathway={carePathway.nextCarePathway}
              visits={carePathway.visits}
            />
          )}

          {/*
           * CUSTOM or INTERMEDIATE level of care must select risk factors
           */}
          {(selectedLevelOfCare === 'CUSTOM' || selectedLevelOfCare === 'INTERMEDIATE') && (
            <>
              {/* RISK FACTORS */}
              <CheckboxGroup
                label='Select the factors that change patient’s level of care'
                {...form.getInputProps('riskFactors')}
              >
                {CARE_PATHWAY_RISK_FACTORS.map(rf => {
                  return <Checkbox key={rf.value} value={rf.value} label={rf.label} />
                })}
              </CheckboxGroup>
              {form.values.riskFactors.includes('other') && (
                <TextInput
                  {...form.getInputProps('riskFactorsFreeText')}
                  placeholder='Please explain'
                />
              )}
              {/* NEW LEVEL OF CARE - Only applicable to custom. Intermediate is predetermined at weekly. */}
              {selectedLevelOfCare === 'CUSTOM' && (
                <RadioGroup
                  label='What is the patient’s new level of care?'
                  {...form.getInputProps('nextLevelOfCare')}
                  onChange={value => {
                    const typedValue = value as LevelOfCareStatus
                    form.setValues({
                      // When changing the level of care to anything other than monthly, the patient is being moved to a custom care pathway.
                      nextCarePathway: typedValue === 'monthly' ? 'stable' : 'custom',
                      nextLevelOfCare: typedValue,
                    })
                  }}
                  orientation='horizontal'
                >
                  {/*
                   * Hide the level of care option we suggested to the clinician before they select a custom level of care.
                   * If suggesting intermediate, offer weekly and monthly only.
                   */}
                  {carePathway.nextLevelOfCare !== 'weekly' &&
                    // Do not show weekly option if the patient is eligible for intermediate care pathway bc that option was already presented on the prev step.
                    !isEligibleForIntermediateCarePathway && (
                      <Radio style={{ flexGrow: 1 }} label='Weekly' value='weekly' />
                    )}
                  {carePathway.nextLevelOfCare !== 'biweekly' && (
                    <Radio style={{ flexGrow: 1 }} label='Bi-weekly' value='biweekly' />
                  )}
                  {(carePathway.nextLevelOfCare !== 'monthly' ||
                    // If the patient is eligible for intermediate care pathway, we want to show the monthly option too
                    isEligibleForIntermediateCarePathway) && (
                    <Radio style={{ flexGrow: 1 }} label='Monthly' value='monthly' />
                  )}
                </RadioGroup>
              )}
              {/* Hooray, patient is being moved to stable pathway. Anything else is custom. */}
              {form.isDirty('nextLevelOfCare') && form.values.nextCarePathway === 'stable' && (
                <Alert variant='success' icon={<ShieldIcon />}>
                  {firstName} is on stable care pathway
                </Alert>
              )}
              {form.isDirty('nextLevelOfCare') && form.values.nextCarePathway === 'custom' && (
                <Alert variant='warning' icon={<ShieldIcon />}>
                  {firstName} is on custom care pathway
                </Alert>
              )}
              {/* In the case of intermediate care pathway, show the pathway progress on the next step too */}
              {form.isDirty('nextLevelOfCare') && selectedLevelOfCare === 'INTERMEDIATE' && (
                <CarePathwayProgress
                  prevCarePathway={carePathway.prevCarePathway}
                  nextCarePathway='intermediate'
                  patientName={firstName}
                  visits={carePathway.visits}
                />
              )}
              <Text>Would you like to continue?</Text>
            </>
          )}
        </Stack>
      </ModalContent>
      <ModalFooter>
        {selectedLevelOfCare === 'DEFAULT' && (
          <Group position='right'>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => {
                if (isEligibleForIntermediateCarePathway) {
                  form.setValues({
                    // Pre-select weekly level of care if the patient is eligible for intermediate care pathway
                    nextLevelOfCare: 'weekly',
                    nextCarePathway: 'intermediate',
                  })
                  setSelectedLevelOfCare('INTERMEDIATE')
                } else {
                  form.setValues({
                    nextLevelOfCare: undefined,
                    nextCarePathway: 'custom',
                  })
                  setSelectedLevelOfCare('CUSTOM')
                }
              }}
            >
              No, change level of care
            </SecondaryButton>
            <PrimaryButton
              loading={isLoading}
              onClick={onSubmit}
            >{`Yes, continue as ${form.values.nextLevelOfCare}`}</PrimaryButton>
          </Group>
        )}
        {selectedLevelOfCare === 'CUSTOM' && (
          <Group position='right'>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => {
                // Clinician does not want to use custom LoC and wants to go back to the default LoC
                form.setValues({
                  nextLevelOfCare: carePathway.nextLevelOfCare,
                  nextCarePathway: carePathway.nextCarePathway,
                })
                setSelectedLevelOfCare('DEFAULT')
              }}
            >
              No, go back
            </SecondaryButton>
            <PrimaryButton loading={isLoading} onClick={onSubmit}>
              Yes, continue
            </PrimaryButton>
          </Group>
        )}
        {selectedLevelOfCare === 'INTERMEDIATE' && (
          <Group position='right'>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => {
                // Clinician does not want to use weekly LoC and wants to select a custom level of care instead
                form.setValues({
                  nextLevelOfCare: undefined,
                  nextCarePathway: 'custom',
                })
                setSelectedLevelOfCare('CUSTOM')
              }}
            >
              No, change level of care
            </SecondaryButton>
            <PrimaryButton loading={isLoading} onClick={onSubmit}>
              {`Yes, continue as ${form.values.nextLevelOfCare}`}
            </PrimaryButton>
          </Group>
        )}
      </ModalFooter>
    </>
  )
}

export const CarePathwayModal = (props: Props) => {
  return (
    <BetterModal opened={props.opened} onClose={props.onClose}>
      <CarePathwayModalContent {...props} />
    </BetterModal>
  )
}
