import { useForm } from '@mantine/form'
import {
  Banner,
  BetterDrawer,
  DollarSignIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  NumberInput,
  PrimaryButton,
  Select,
  Stack,
  isInRange,
  useBanner,
  validateWith,
} from '@shared/components'
import { InvoiceListItem, getOpheliaHttpError } from '@shared/types'
import { CENTS_PER_DOLLAR, formatDollarAmount, template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import multiply from 'lodash/multiply'
import round from 'lodash/round'
import { isNumber, isRequired } from '../../../utils/formValidation'
import { useEmrMutation, useEmrQuery, useInvalidateQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'

const MIN_PAYMENT_AMOUNT_IN_DOLLARS = 1

type AddInvoicePaymentDrawerProps = {
  invoice: InvoiceListItem
  opened: boolean
  onClose: () => void
}

export const AddPaymentDrawerContent = (props: AddInvoicePaymentDrawerProps) => {
  const { showBanner } = useBanner()
  const invalidateQuery = useInvalidateQuery()
  const { patientQuery } = usePatient()

  const form = useForm({
    initialValues: {
      method: '',
      amountInDollars: props.invoice.amountRemaining,
    },
    validate: {
      amountInDollars: validateWith(
        isRequired,
        isNumber,
        isInRange(
          {
            // Must be at least $1
            min: MIN_PAYMENT_AMOUNT_IN_DOLLARS,
            // Must be less than or equal to the amount remaining on the invoice
            max: Number(props.invoice.amountRemaining),
          },
          `Must be between $${MIN_PAYMENT_AMOUNT_IN_DOLLARS} and $${props.invoice.amountRemaining}`,
        ),
      ),
      method: validateWith(isRequired),
    },
  })

  const methodsQuery = useEmrQuery(
    'GET /patient/:patientId/payment/methods',
    {
      params: {
        patientId: props.invoice.patientId || '',
      },
    },
    {
      onSuccess: paymentMethods => {
        const defaultMethod = paymentMethods?.find(method => method.isDefault)
        // pre-select the default payment method
        if (defaultMethod) {
          form.setValues({
            method: defaultMethod.id,
          })
        }
      },
    },
  )

  const methods = (methodsQuery.data || []).map(method => {
    return {
      value: method.id,
      label: template('{brand} (*{last4}) {isDefault}', {
        brand: capitalize(method.brand),
        last4: method.last4,
        isDefault: method.isDefault ? '(Default)' : '',
      }),
    }
  })

  const addPayment = useEmrMutation('POST /invoices/:invoiceId/pay')

  const onPay = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await addPayment.mutateAsync({
      params: {
        invoiceId: props.invoice.oid,
      },
      data: {
        stripePaymentMethodId: form.values.method,
        /*
         * In JavaScript, 72.50 * 100 = 7284.999999999999
         * Therefore, must round. Else, Stripe will yell about an invalid integer.
         */
        amountInCents: round(multiply(Number(form.values.amountInDollars), CENTS_PER_DOLLAR)),
      },
    })

    const formattedAmountPaid = formatDollarAmount({ amount: form.values.amountInDollars })
    const patientFirstName = patientQuery?.data?.personalData?.firstName || ''
    const bannerLabel = `Payment of ${formattedAmountPaid} was successfully applied to ${patientFirstName}'s invoice`

    showBanner({
      type: 'success',
      label: bannerLabel,
      dismissable: true,
    })

    form.reset()

    void invalidateQuery('GET /invoices', {
      query: {
        patientId: props.invoice.patientId || '',
      },
    })
    void invalidateQuery('GET /invoices/:invoiceId/payments', {
      params: {
        invoiceId: props.invoice.oid,
      },
    })

    props.onClose()
  }

  return (
    <>
      <DrawerHeader onClose={props.onClose}>Add Payment</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          {addPayment.error && (
            <Banner
              type='error'
              label={getOpheliaHttpError(addPayment.error, 'Error adding payment')}
            />
          )}
          <NumberInput
            label='Amount'
            icon={<DollarSignIcon />}
            precision={2}
            {...form.getInputProps('amountInDollars')}
          />
          <Select label='Method' data={methods} {...form.getInputProps('method')} />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton onClick={() => onPay()} loading={addPayment.isLoading}>
            Add payment
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const AddInvoicePaymentDrawer = (props: AddInvoicePaymentDrawerProps) => {
  return (
    <BetterDrawer size='sm' position='right' onClose={props.onClose} opened={props.opened}>
      <AddPaymentDrawerContent {...props} />
    </BetterDrawer>
  )
}
