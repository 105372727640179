import { AlertIcon, Group, Text, useMantineTheme } from '@shared/components'

type EmptyDataCellProps = {
  message?: string
}
const EmptyDataCell = ({ message }: EmptyDataCellProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()
  return (
    <Group spacing='sm' color={colors.text[1]}>
      <AlertIcon color={colors.text[1]} strokeWidth={sizes.border.md} />
      <Text color={colors.text[1]}>{message ?? 'No data'}</Text>
    </Group>
  )
}

export default EmptyDataCell
