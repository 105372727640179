import { Group, PrimaryButton, SecondaryButton } from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import { useState } from 'react'
import { Page } from '../../components/templates/TDefault'
import { useLunaQuery } from '../../utils/hooks'
import { useAccess } from '../../utils/hooks/use-access'
import { AddOrEditClinicDrawer } from './AddOrEditClinicDrawer'
import { DropInClinicSelect } from './DropInClinicSelect'
import { DropInClinicTable } from './DropInClinicTable'

export type DropInClinic = EmrLunaApi['GET /drop-in-clinics/:clinicId']['res']['data']

export const ClinicPage = () => {
  const { canAddOrEditClinic } = useAccess()
  const [openAddClinic, setOpenAddClinic] = useState(false)
  const [openEditClinic, setOpenEditClinic] = useState(false)
  const [clinicId, setClinicId] = useState<string | null>(null)

  const clinicQuery = useLunaQuery(
    'GET /drop-in-clinics/:clinicId',
    {
      params: {
        clinicId: clinicId || '',
      },
    },
    {
      enabled: Boolean(clinicId),
    },
  )

  const clinic = clinicQuery.data?.data || null

  return (
    <>
      <Page title='Clinic'>
        <Group px='lg' pt='lg' position='right'>
          <DropInClinicSelect onClinicIdChange={setClinicId} clinicId={clinicId} />
          {canAddOrEditClinic && (
            <SecondaryButton onClick={() => setOpenEditClinic(true)}>Edit clinic</SecondaryButton>
          )}
          {canAddOrEditClinic && (
            <PrimaryButton onClick={() => setOpenAddClinic(true)}>Add clinic</PrimaryButton>
          )}
        </Group>
        <DropInClinicTable clinic={clinic} />
      </Page>
      {/* ADD CLINIC */}
      <AddOrEditClinicDrawer
        opened={openAddClinic}
        onClose={() => setOpenAddClinic(false)}
        clinic={null}
      />
      {/* EDIT CLINIC */}
      <AddOrEditClinicDrawer
        opened={openEditClinic}
        onClose={() => setOpenEditClinic(false)}
        clinic={clinic}
      />
    </>
  )
}
