import { useCallback } from 'react'
import { useInvalidateQuery } from '../../../utils/hooks'

export const useInvalidateSubcollections = () => {
  const invalidate = useInvalidateQuery()

  return useCallback(
    ({
      patientId,
      subcollections,
      documents,
    }: {
      patientId: string
      subcollections?: string[]
      documents?: { subcollection: string; documentId: string }[]
    }) => {
      void invalidate('GET /patient/:patientId/subcollections', {
        params: { patientId },
      })

      if (subcollections) {
        for (const subcollection of subcollections) {
          void invalidate('GET /patient/:patientId/subcollections/:subcollection', {
            params: { patientId, subcollection },
          })
        }
      }

      if (documents) {
        for (const { subcollection, documentId } of documents) {
          void invalidate('GET /patient/:patientId/subcollections/:subcollection/:documentId', {
            params: { patientId, subcollection, documentId },
          })
        }
      }

      void invalidate('GET /patient/:patientId/operations', {
        params: { patientId },
      })
    },
    [invalidate],
  )
}
