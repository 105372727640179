import { Stack, Text, TextInputProps } from '@shared/components'
import { usePatientVisitNote } from '../PatientVisitNoteContext'

type PatientVisitNoteDefaultTextMultilineProps = Omit<TextInputProps, 'value'> & {
  values: string[]
}
/*
 * For multiline text that is not input by the user,
 * but we still want to show consistent colors for header and response
 */
export const PatientVisitNoteDefaultTextMultiline = (
  props: PatientVisitNoteDefaultTextMultilineProps,
) => {
  const { canEdit } = usePatientVisitNote()

  if (!canEdit) {
    return (
      <Stack spacing='sm'>
        <Text color={colors => colors.text[1]}>{props.label}</Text>
        {props.values.map((value, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={i} color={colors => colors.text[0]}>
            {value}
          </Text>
        ))}
      </Stack>
    )
  }

  return (
    <Stack spacing='sm'>
      <Text color={colors => colors.text[0]}>{props.label}</Text>
      {props.values.map((value, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text key={i} color={colors => colors.text[1]}>
          {value}
        </Text>
      ))}
    </Stack>
  )
}
