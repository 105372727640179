import { useEffect } from 'react'
import { postFlexChartViewedMessage } from '../utils'

// Used to alert Twilio Flex when a chart is being viewed
export const useIframeChartViewedMessage = (phone: string | undefined) => {
  useEffect(() => {
    const isInIframe = window !== window.parent
    if (isInIframe && phone) {
      // Any time a patient chart is loaded, we tell flex to load that chart's phone's conversation
      postFlexChartViewedMessage(phone)
    }
  }, [phone])
}
