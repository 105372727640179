type HeaderTabProps = {
  selected: boolean
  onClick: () => void
  label: string
}

const HeaderTab = ({ selected, onClick, label }: HeaderTabProps) => {
  return (
    <span className='relative z-0 inline-flex rounded-md'>
      <button
        className={`text-gray-500 hover:text-gray-900 mx-2 border-b-2 border-transparent hover:border-gray-200 ${
          selected && 'border-daybreak-actions0 font-bold text-gray-900'
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    </span>
  )
}

export default HeaderTab
