import { Loader, Select } from '@shared/components'
import { ISOString, StateAbbr } from '@shared/types'
import { dayjs, template } from '@shared/utils'
import minBy from 'lodash/minBy'
import orderBy from 'lodash/orderBy'
import { useEffect } from 'react'
import { useLunaQuery } from '../../utils/hooks'

const getLabel = ({
  clinicianFirstName,
  datetimeStart,
  states,
}: {
  clinicianFirstName: string
  datetimeStart: ISOString
  states: StateAbbr[]
}) => {
  const date = dayjs(datetimeStart).tz('America/New_York').format('MM/DD/YYYY').toUpperCase()
  const startHour = dayjs(datetimeStart).tz('America/New_York').format('h:mma')

  return template('{clinicianFirstName} | {date} | {states} | {startHour} ET', {
    date,
    startHour,
    clinicianFirstName,
    states: states.join(', '),
  })
}

export const DropInClinicSelect = (props: {
  onClinicIdChange: (clinic: string | null) => void
  clinicId: string | null
}) => {
  const clinicsQuery = useLunaQuery('GET /drop-in-clinics')
  const clinics = clinicsQuery.data

  useEffect(() => {
    if (!props.clinicId) {
      /**
       * On initial load, select the first upcoming clinic or
       * the last clinic if there are no upcoming ones.
       * Pad by 1 hour to account for flexibility in clinic end times in practice.
       */
      const futureClinics = (clinics?.data || []).filter(clinic => {
        return dayjs(clinic.datetimeEnd).add(1, 'hour').isAfter(dayjs())
      })
      const firstUpcomingClinic = minBy(futureClinics, clinic => {
        return dayjs(clinic.datetimeEnd).valueOf()
      })
      const lastClinic = clinics?.data.at(-1)
      props.onClinicIdChange(firstUpcomingClinic?.oid || lastClinic?.oid || null)
    }
  }, [clinics])

  const data = orderBy(clinicsQuery.data?.data || [], d => d.datetimeStart, 'desc').map(d => ({
    value: d.oid,
    label: getLabel(d),
  }))

  return (
    <Select
      miw='285px'
      value={props.clinicId || ''}
      onChange={clinicId => {
        props.onClinicIdChange(clinicId)
      }}
      icon={clinicsQuery.isLoading ? <Loader size='xs' /> : null}
      data={data}
      disabled={!data.length}
    />
  )
}
