import { EmrApi, WorkflowType } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class WorkflowsApi extends BaseApi {
  createSession = ({
    patientId,
    workflowType,
  }: {
    patientId: string
    workflowType: WorkflowType
  }) => {
    return this.json<EmrApi['POST /workflow/:type']['res']>({
      method: 'POST',
      url: `/workflow/${workflowType}`,
      data: {
        patientId,
      },
    })
  }
}
