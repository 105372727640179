import { Textarea } from '@shared/components'

import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const AdditionalNotesSection = ({
  form,
  optional,
}: {
  form: DischargeNoteForm
  optional: boolean
}) => {
  return (
    <Textarea
      label={formLabel('Additional notes', optional)}
      {...form.getInputProps('additional_notes')}
    />
  )
}
