import { Loader, Stack } from '@shared/components'
import { FullWorkflowState, Patient, PromptResponsePayload, WorkflowType } from '@shared/types'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi, workflowsApi } from '../../api'
import { WorkflowDrawer } from '../../components/workflows/WorkflowDrawer'
import { usePatient } from '../../pages/patient/PPatientContext'

const WORKFLOW_TYPE_TITLE_MAP: Record<WorkflowType, string> = {
  onboarding: 'Welcome flow',
  phq_9_form: 'PHQ-9',
  medical_intake_form: 'Medical intake form',
  patient_referral_form: 'Patient referral form',
  phq_8_form: 'PHQ-8',
  gad_7_form: 'GAD-7',
  barc_10_form: 'BARC-10',
  reconnect: 'Reconnect',
  patient_referral_form_partner: 'Patient referral form (Partner)',
  patient_referral_form_spring_health: 'Patient referral form (Spring Health)',
  patient_referral_form_partner_with_patient: 'Patient referral form (Partner with patient)',
  asq_form: 'ASQ form',
  not_ready_flow: 'Not ready flow',
}

const WORKFLOWS_WITH_OPTIONAL_FIELDS: WorkflowType[] = [
  'onboarding',
  'reconnect',
  'medical_intake_form',
]

export function usePatientWorkflowSession({
  patientId,
  workflowType,
}: {
  patientId: Patient['oid']
  workflowType: WorkflowType
}) {
  const { patientQuery } = usePatient()
  const [workflowState, setWorkflowState] = useState<FullWorkflowState | null>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const patientWorkflows = patientQuery?.data?.workflows
  const existingSessionId = patientWorkflows?.[workflowType]?.sessionId

  const createWorkflowSession = useMutation(workflowsApi.createSession)
  const inProgressSession = useMutation(emrApi.getMutation('GET /workflow/session/:sessionId'))
  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const queryClient = useQueryClient()

  const openWorkflow = async () => {
    if (patientWorkflows?.[workflowType]?.sessionId) {
      // If there's an in-progress session, fetch its state
      const existingWorkflowState = await inProgressSession.mutateAsync({
        params: {
          sessionId: existingSessionId || '',
        },
        query: {
          patientId,
        },
      })

      // If this is an EMR-based workflow, re-use it and return early. Otherwise, create a new one below
      if (existingWorkflowState?.workflowSession?.employeeId) {
        setWorkflowState(existingWorkflowState)
        setIsDrawerOpen(true)
        return
      }
    }

    // If there's no in-progress emr-based session, create a new one
    const newWorkflowState = await createWorkflowSession.mutateAsync({
      workflowType,
      patientId,
    })

    await updatePatient.mutateAsync({
      params: {
        patientId,
      },
      data: {
        workflows: {
          [workflowType]: {
            sessionId: newWorkflowState.workflowSession.oid,
            workflowId: newWorkflowState.workflowSession.workflowId,
            status: 'in_progress',
          },
        },
      },
    })

    // Refresh the patient chart to show that a welcome flow is in progress
    void queryClient.invalidateQueries(emrApi.getQuery('GET /patient/:patientId')[0])
    setWorkflowState(newWorkflowState)
    setIsDrawerOpen(true)
  }

  const onSubmit = () => {
    void queryClient.invalidateQueries('formsApi.getForms')
    void queryClient.invalidateQueries(emrApi.getQuery('GET /patient/:patientId')[0])
    setIsDrawerOpen(false)
  }

  const workflowIsLoading = inProgressSession.isLoading || createWorkflowSession.isLoading

  return {
    // A pre-configured, controlled Drawer component - this needs to be inserted into the DOM wherever the hook is used
    drawer: (
      <PatientWorkflowDrawer
        type={workflowType}
        patientId={patientId}
        workflowState={workflowState}
        opened={isDrawerOpen}
        isLoading={workflowIsLoading}
        onSubmit={onSubmit}
        onClose={() => setIsDrawerOpen(false)}
      />
    ),
    openWorkflow,
  }
}

export const PatientWorkflowDrawer = ({
  type,
  patientId,
  workflowState,
  opened,
  isLoading,
  onSubmit,
  onClose,
}: {
  type: WorkflowType
  patientId: Patient['oid']
  workflowState: FullWorkflowState | null
  opened: boolean
  isLoading: boolean
  onSubmit: (responses: { promptId: string; promptResponse: PromptResponsePayload }[]) => void
  onClose: () => void
}) => {
  if (!opened || isLoading || !workflowState) {
    return null
  }

  return (
    <WorkflowDrawer
      title={WORKFLOW_TYPE_TITLE_MAP[type]}
      fullWorkflowState={workflowState}
      opened={opened}
      onClose={onClose}
      onSubmit={onSubmit}
      componentDisplayedWhileSubmitting={
        <Stack pos='absolute' justify='center' align='center' top={0} left={0} right={0} bottom={0}>
          <Loader />
        </Stack>
      }
      patientId={patientId}
      saveResponsesOnClose
      allFieldsOptional={WORKFLOWS_WITH_OPTIONAL_FIELDS.includes(type)}
    />
  )
}
