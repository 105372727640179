import { UseFormReturnType } from '@mantine/form'
import {
  ChevronDownIcon,
  DailyCalendar,
  DatePicker,
  Group,
  Loader,
  Menu,
  MultiCalendarRadioGroup,
  ScrollAreaAutosize,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Patient } from '@shared/types'
import { dayjs, getTimeZones } from '@shared/utils'
import { CalendarSlotsData } from './use-calendar'

import { CalendarDaysDateslot, SchedulingFormData } from './SchedulerDrawer'
type NextAvailableSchedulerProps = {
  patient: Patient
  schedulingForm: UseFormReturnType<SchedulingFormData>
  today: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  isLoading: boolean
  availableCalendarDates: CalendarDaysDateslot[]
  availableAppointments: CalendarSlotsData[]
}

export const ChooseClinicianScheduler = ({
  patient,
  schedulingForm,
  today,
  maxDate,
  isLoading,
  availableCalendarDates,
  availableAppointments,
}: NextAvailableSchedulerProps) => {
  if (isLoading) {
    return (
      <Group spacing='sm'>
        <Loader size='sm' />
        <Text color={({ text }) => text[1]}>Fetching availability...</Text>
      </Group>
    )
  }

  const timeZoneOptions = getTimeZones().map(({ value, label, time }) => ({
    value,
    label: `${label} - ${time}${patient.account?.timezone === value ? ' (Patient time zone)' : ''}`,
  }))

  return (
    <>
      <Group grow>
        <DatePicker
          {...schedulingForm.getInputProps('date')}
          label='Schedule a date for the visit'
          explanation={`We'll show the following 4 days with availability as well`}
          clearable={false}
          minDate={today.toDate()}
          maxDate={maxDate.toDate()}
          placeholder='Select day...'
          excludeDate={(date: Date) =>
            !availableCalendarDates?.some(slot => dayjs(slot.date).isSame(date, 'day'))
          }
        />
      </Group>
      {Boolean(schedulingForm.values.date) && (
        <Stack>
          <DailyCalendar
            {...schedulingForm.getInputProps('date')}
            range={availableCalendarDates?.map(slot => slot.date) ?? []}
            header={
              <>
                <Text size='xs' color={({ text }) => text[1]}>
                  Time zone
                </Text>
                <Menu>
                  <Menu.Target>
                    <TertiaryButton rightIcon={<ChevronDownIcon />}>
                      {timeZoneOptions.find(tz => tz.value === schedulingForm.values.timezone)
                        ?.label ?? 'Select a time zone...'}
                    </TertiaryButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <ScrollAreaAutosize maxHeight={256}>
                      {timeZoneOptions.map(tz => {
                        return (
                          <Menu.Item
                            onClick={() => schedulingForm.setFieldValue('timezone', tz.value)}
                            key={tz.value}
                          >
                            {tz.label}
                          </Menu.Item>
                        )
                      })}
                    </ScrollAreaAutosize>
                  </Menu.Dropdown>
                </Menu>
              </>
            }
            step={5}
          >
            <MultiCalendarRadioGroup
              {...schedulingForm.getInputProps('datetime')}
              data={availableAppointments.map(day => ({
                date: day.date,
                slots: day.slots.map(slot => slot.time),
                loading: day.loading,
              }))}
              tz={schedulingForm.values.timezone}
            />
          </DailyCalendar>
        </Stack>
      )}
    </>
  )
}
