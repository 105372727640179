import {
  AlertIcon,
  CheckCircleIcon,
  Group,
  HoverCard,
  HoverCardProps,
  Loader,
  Stack,
  Text,
} from '@shared/components'
import { isPatientOnboardingOffline } from '@shared/types'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

const ConsentsToDisplay = [
  'termsOfUse',
  'telehealthInformedConsent',
  'financialConsent',
  'treatmentConsent',
  'textMessageConsent',
  'tpoConsent',
] as const

const ConsentsToDisplayForOfflinePatients = [
  'financialConsent',
  'treatmentConsent',
  'tpoConsent',
] as const

export const ConsentsPopover = ({ position }: { position?: HoverCardProps['position'] }) => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const consentsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/consents', { params: { patientId } }),
  )

  const applicableConsents = isPatientOnboardingOffline(patient)
    ? ConsentsToDisplayForOfflinePatients
    : ConsentsToDisplay

  const consentStatuses = useMemo(
    () =>
      applicableConsents.map(key => {
        const consents = patientQuery?.data?.consents

        switch (key) {
          case 'termsOfUse':
            return { consent: key, label: 'Terms of use', signed: Boolean(consents?.[key]) }
          case 'telehealthInformedConsent':
            return { consent: key, label: 'Telehealth consent', signed: Boolean(consents?.[key]) }
          case 'treatmentConsent':
            return {
              consent: key,
              label: 'Consent for treatment',
              signed: Boolean(consentsQuery.data?.some(consent => consent.type === 'treatment')),
            }
          case 'financialConsent':
            return {
              consent: key,
              label: 'Financial agreement',
              signed: Boolean(consentsQuery.data?.some(consent => consent.type === 'financial')),
            }
          case 'textMessageConsent':
            return {
              consent: key,
              label: 'Text message consent',
              signed: Boolean(consents?.[key]),
            }
          case 'tpoConsent':
            return {
              consent: key,
              label: 'TPO consent',
              signed: Boolean(consents?.[key]),
            }

          default:
            return null
        }
      }),
    [consentsQuery.data, patientQuery?.data?.consents],
  )

  const hasMissingSignedConsent = consentStatuses.some(value => !value?.signed)
  const isLoadingOrError =
    patientQuery?.isLoading ||
    patientQuery?.isError ||
    consentsQuery.isLoading ||
    consentsQuery.isError

  return (
    <HoverCard openDelay={500} withinPortal disabled={isLoadingOrError} position={position}>
      <HoverCard.Target>
        <Group spacing='xs'>
          {isLoadingOrError ? (
            <Loader size='xs' />
          ) : (
            <>
              {hasMissingSignedConsent ? (
                <AlertIcon color={colors => colors.warning[0]} />
              ) : (
                <CheckCircleIcon color={colors => colors.success[0]} />
              )}
            </>
          )}
          <Text>Consents</Text>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack spacing='sm'>
          {consentStatuses.map(
            value =>
              value && (
                <Group key={value.consent} spacing='sm'>
                  {value.signed ? (
                    <CheckCircleIcon color={colors => colors.success[0]} />
                  ) : (
                    <AlertIcon color={colors => colors.warning[0]} />
                  )}
                  <Text>{value.label}</Text>
                </Group>
              ),
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
