import { useLifecycle } from '@shared/components'
import { dayjs } from '@shared/utils'
import { useRef } from 'react'
import * as FullStory from '../fullstory'

export type PageTrace = {
  start: () => void
  end: ({ pageName }: { pageName: string }) => void
}

export const usePageTrace = ({ startOnMount }: { startOnMount?: boolean }): PageTrace => {
  const startRef = useRef<number>(0)
  const endRef = useRef<number>(0)

  useLifecycle({
    onMount: () => {
      if (startOnMount) {
        startRef.current = dayjs().valueOf()
      }
    },
  })

  const start = () => {
    if (startRef.current) {
      return
    }

    startRef.current = dayjs().valueOf()
  }

  const end = ({ pageName }: { pageName: string }) => {
    if (endRef.current) {
      return
    }

    const endTime = dayjs().valueOf()

    endRef.current = endTime

    FullStory.page({
      pageName,
      loadTimeMs: endTime - startRef.current,
    })
  }

  return {
    start,
    end,
  }
}
