import {
  CalendarColumnData,
  CalendarColumnHeader,
  hasGroupRole,
  hasRole,
  isAdmin,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { analytics } from '../../analytics'
import { consultationQueueApi } from '../../api'
import { Page } from '../../components/templates/TDefault'
import { Config } from '../../config'
import { useAuth } from '../../context/auth'
import { useFlags } from '../../utils/hooks'
import OVisits from './OVisits'
import { useCalendarDate } from './PCalendar'
import { useAppointments, useBlocks, useWorkingHours } from './hooks'
import { getWeekRange } from './utils'

export type VisitsPageParams = { providerID: string }

export const PVisitsByProvider = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { outOfOffice } = useFlags()

  const consultationQueueQuery = useQuery(
    ['consultationQueueApi', 'getConfig'],
    consultationQueueApi.getConfig,
  )
  const isConsultationQueueAvailable = Boolean(consultationQueueQuery.data?.available)

  const consultationQueueMutation = useMutation(consultationQueueApi.updateConfig, {
    onSuccess: () => {
      void consultationQueueQuery.refetch()
    },
  })

  const consultationCallQueueControls =
    hasGroupRole(currentUser, 'enrollmentCoordinator') || hasGroupRole(currentUser, 'admin')

  const { providerID = '' } = useParams<VisitsPageParams>()
  const [date, setDate] = useCalendarDate()
  const { dates, minDate, maxDate } = getWeekRange(date)

  const { appointments, isLoading: appointmentsLoading } = useAppointments({
    calendarID: providerID,
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
  })

  const { blocks, isLoading: blocksLoading } = useBlocks({
    calendarID: providerID,
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
  })

  const { workingHours, isLoading: workingHoursLoading } = useWorkingHours({
    calendarId: providerID,
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
  })

  const isLoading = appointmentsLoading || blocksLoading || workingHoursLoading

  const columns: CalendarColumnHeader[] = dates.map(date => {
    return {
      id: date.day().toString(),
      date: date.format('YYYY-MM-DD'),
      name: date.format('ddd'),
      day: date.format('DD'),
    }
  })

  const data: CalendarColumnData[] = isLoading
    ? []
    : dates.map(day => {
        const dayAppointments = (appointments || []).filter(appointment =>
          day.isSame(dayjs(appointment.datetime).tz(appointment.timezone), 'day'),
        )
        const dayBlocks = (blocks || []).filter(block => day.isSame(block.start, 'day'))

        return {
          id: day.format('YYYY-MM-DD'),
          date: day.format('YYYY-MM-DD'),
          visits: dayAppointments,
          blocks: dayBlocks,
          workingHours: workingHours ? workingHours[day.format('YYYY-MM-DD')] : undefined,
        }
      })

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!currentUser) {
      return
    }

    analytics.page({
      name: 'Visits By Provider',
      properties: {
        userId: currentUser.oid,
        userName: currentUser.name,
        providerId: providerID,
      },
    })
  }, [currentUser, providerID])

  return (
    <Page
      title='Calendar'
      test-id='page:provider-calendar'
      headerContent={
        <>
          {providerID === Config.CONSULTATION_CALL_QUEUE_CALENDAR_ID &&
            !consultationCallQueueControls && (
              <div className='flex items-center mr-5'>
                <span
                  className={`${
                    isConsultationQueueAvailable
                      ? 'bg-indigo-100 text-indigo-800'
                      : 'bg-gray-100 text-gray-800'
                  } inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium`}
                >
                  <svg
                    className={`${
                      isConsultationQueueAvailable ? 'text-indigo-400' : 'text-gray-400'
                    } -ml-0.5 mr-1.5 h-2 w-2`}
                    fill='currentColor'
                    viewBox='0 0 8 8'
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  {`${isConsultationQueueAvailable ? 'Queue On' : 'Queue Off'}`}
                </span>
              </div>
            )}
          {providerID === Config.CONSULTATION_CALL_QUEUE_CALENDAR_ID &&
            consultationCallQueueControls && (
              <div className='flex items-center'>
                <button
                  onClick={() => {
                    consultationQueueMutation.mutate({
                      available: !isConsultationQueueAvailable,
                    })
                  }}
                  type='button'
                  className={`${
                    isConsultationQueueAvailable ? 'bg-indigo-600' : 'bg-gray-200'
                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  aria-pressed='false'
                >
                  <span
                    aria-hidden='true'
                    className={`${
                      isConsultationQueueAvailable ? 'translate-x-5' : 'translate-x-0'
                    } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  ></span>
                </button>
                <span className='ml-3 mr-5'>
                  <span className='text-sm text-gray-900'>Consultation Call Queue</span>
                </span>
              </div>
            )}
        </>
      }
    >
      <OVisits
        columns={columns}
        data={data}
        date={date}
        setDate={setDate}
        calendarId={providerID}
        setCalendarId={(calendarId: string) => {
          if (calendarId === 'all') {
            navigate(`/visits/all`)
          } else {
            navigate(`/visits/${calendarId}`)
          }
        }}
        isLoading={isLoading}
        includeOutOfOffice={
          outOfOffice &&
          // Clinicians are allowed to call out for themselves, and admins + supervising pcs can call out for other clinicians
          (providerID === String(currentUser?.calendarId) ||
            isAdmin(currentUser) ||
            hasRole(currentUser, 'spc') ||
            hasRole(currentUser, 'sncm')) &&
          // We will roll out functionality for TNs later
          !hasRole(currentUser, 'tn')
        }
      />
    </Page>
  )
}

export default PVisitsByProvider
