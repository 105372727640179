import { Group, Progress, Text, toPercentage } from '@shared/components'

export const TaskProgress = ({ step, total }: { step: number; total: number }) => (
  <Group spacing='md' noWrap>
    <Progress w='100%' value={toPercentage(step / total)} />
    <Text size='xs' bold style={{ whiteSpace: 'nowrap' }}>
      {step} of {total}
    </Text>
  </Group>
)
