import {
  ClockIcon,
  Menu,
  MinusCircleIcon,
  MoreVerticalIcon,
  PlusCircleIcon,
  SecondaryButton,
  UserIcon,
} from '@shared/components'
import { DecoratedCocmItem } from '@shared/types'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useEmrMutation } from '../../../utils/hooks'
import RemovePatientCaseReviewModal from '../caseReview/RemovePatientCaseReviewModal'
import { TimeTrackingDrawer } from './TimeTrackingDrawer'

type ContextMenuPopoverProps = {
  cocmPatient: DecoratedCocmItem
}

const ContextMenuPopover = ({ cocmPatient }: ContextMenuPopoverProps) => {
  const queryClient = useQueryClient()

  const [patientToRemoveFromCaseReview, setPatientToRemoveFromCaseReview] =
    useState<DecoratedCocmItem | null>(null)

  const [timeTrackingDrawer, setTimeTrackingDrawer] = useState<boolean>(false)

  const updateRegistryItem = useEmrMutation('PUT /cocmRegistry/:patientId', {
    onSuccess: () => {
      void queryClient.invalidateQueries('GET /cocmRegistry')
      void queryClient.invalidateQueries('GET /caseReview')
    },
  })

  const createCaseReviewNote = useEmrMutation('POST /caseReviewNote/patient/:patientId')

  const onClickUpdateNeedsReview = () => {
    if (cocmPatient.needsReview) {
      setPatientToRemoveFromCaseReview(cocmPatient)
    } else {
      updateRegistryItem.mutate({
        params: { patientId: cocmPatient.patientId },
        data: { needsReview: true },
      })
      createCaseReviewNote.mutate({
        params: {
          patientId: cocmPatient.patientId,
        },
        data: {},
      })
    }
  }

  return (
    <>
      <TimeTrackingDrawer
        patientId={cocmPatient.patientId}
        opened={timeTrackingDrawer}
        onClose={() => setTimeTrackingDrawer(false)}
      />
      <RemovePatientCaseReviewModal
        patientToRemove={patientToRemoveFromCaseReview}
        onCloseRemovePatientModal={() => setPatientToRemoveFromCaseReview(null)}
      />
      <Menu position='bottom-end'>
        <Menu.Target>
          <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<UserIcon />} component={Link} to={`/patients/${cocmPatient.patientId}`}>
            Go to chart
          </Menu.Item>
          <Menu.Item
            icon={cocmPatient.needsReview ? <MinusCircleIcon /> : <PlusCircleIcon />}
            onClick={() => onClickUpdateNeedsReview()}
          >
            {cocmPatient.needsReview ? 'Remove from case review' : 'Add to case review'}
          </Menu.Item>
          <Menu.Item icon={<ClockIcon />} onClick={() => setTimeTrackingDrawer(true)}>
            Time tracking
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  )
}

export default ContextMenuPopover
