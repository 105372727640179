import { Skeleton, Stack, Text } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasure,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import { useState } from 'react'
import { useEmrQuery } from '../../../utils/hooks'
import { ObjectiveMeasureScore } from '../../registry/objectiveMeasures/ObjectiveMeasureScore'
import { getMostRecentResponses } from '../../registry/objectiveMeasures/ObjectiveMeasuresSection'
import { usePatient } from '../PPatientContext'
import ObjectiveMeasuresChartDrawer from '../forms/ObjectiveMeasuresChartDrawer'
import { SidebarEmptyState } from './SidebarEmptyState'

export const MeasuresContent = ({ active }: { active: boolean }) => {
  const { patientId, patientQuery } = usePatient()

  const patient = patientQuery?.data

  const [measuresData, setMeasuresData] = useState<
    DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  >([])

  const [drawerContentIndex, setDrawerContentIndex] = useState<number | null>(null)
  const [selectedMeasuresTitle, setSelectedMeasuresTitle] = useState<ObjectiveMeasuresTitle>()

  const gad7ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/gad7Responses',
    {
      params: { patientId },
    },
    { enabled: active },
  )

  const barc10ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/barc10Responses',
    {
      params: { patientId },
    },
    { enabled: active },
  )

  const phq8ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/phq8Responses',
    {
      params: { patientId },
    },
    {
      enabled: active,
    },
  )

  if (
    phq8ResponsesQuery.isLoading ||
    phq8ResponsesQuery.isError ||
    gad7ResponsesQuery.isLoading ||
    gad7ResponsesQuery.isError ||
    barc10ResponsesQuery.isLoading ||
    barc10ResponsesQuery.isError
  ) {
    return (
      <Stack spacing='sm'>
        <Skeleton h='5rem' />
        <Skeleton h='5rem' />
        <Skeleton h='5rem' />
      </Stack>
    )
  }

  const phqResponses = phq8ResponsesQuery?.data ?? []
  const gad7Responses = gad7ResponsesQuery?.data ?? []
  const barc10Responses = barc10ResponsesQuery?.data ?? []

  if (!phqResponses.length && !gad7Responses.length && !barc10Responses.length) {
    return (
      <SidebarEmptyState
        pillMessage='No measures'
        textMessage={`${
          patient?.personalData.preferredName || patient?.personalData.firstName
        }'s objective measures will appear here`}
      />
    )
  }

  const {
    mostRecentResponse: mostRecentPhqResponse,
    secondMostRecentResponse: secondMostRecentPhqResponse,
  } = getMostRecentResponses({ responses: phqResponses })

  const {
    mostRecentResponse: mostRecentGad7Response,
    secondMostRecentResponse: secondMostRecentGad7Response,
  } = getMostRecentResponses({ responses: gad7Responses })

  const {
    mostRecentResponse: mostRecentBarc10Response,
    secondMostRecentResponse: secondMostRecentBarc10Response,
  } = getMostRecentResponses({ responses: barc10Responses })

  const handleMeasuresClick = ({
    allMeasureData,
    selectedMeasureData,
    title,
  }: {
    allMeasureData: ObjectiveMeasure[]
    selectedMeasureData: ObjectiveMeasure
    title: ObjectiveMeasuresTitle
  }) => {
    const index = allMeasureData.findIndex(i => i.completedAt === selectedMeasureData.completedAt)
    setMeasuresData(allMeasureData as DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[])
    setDrawerContentIndex(index)
    setSelectedMeasuresTitle(title)
  }

  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]}>
        Latest scores
      </Text>
      {mostRecentPhqResponse && (
        <ObjectiveMeasureScore
          measure='PHQ-8'
          {...mostRecentPhqResponse}
          scoreToCompare={secondMostRecentPhqResponse?.compositeScore}
          onClick={() =>
            handleMeasuresClick({
              allMeasureData: phqResponses,
              selectedMeasureData: mostRecentPhqResponse,
              title: 'PHQ-8',
            })
          }
        />
      )}
      {mostRecentGad7Response && (
        <ObjectiveMeasureScore
          measure='GAD-7'
          {...mostRecentGad7Response}
          scoreToCompare={secondMostRecentGad7Response?.compositeScore}
          onClick={() =>
            handleMeasuresClick({
              allMeasureData: gad7Responses,
              selectedMeasureData: mostRecentGad7Response,
              title: 'GAD-7',
            })
          }
        />
      )}
      {mostRecentBarc10Response && (
        <ObjectiveMeasureScore
          measure='BARC-10'
          {...mostRecentBarc10Response}
          scoreToCompare={secondMostRecentBarc10Response?.compositeScore}
          onClick={() =>
            handleMeasuresClick({
              allMeasureData: barc10Responses,
              selectedMeasureData: mostRecentBarc10Response,
              title: 'BARC-10',
            })
          }
        />
      )}
      <ObjectiveMeasuresChartDrawer
        data={measuresData}
        drawerContentIndex={drawerContentIndex}
        onClose={() => setDrawerContentIndex(null)}
        title={selectedMeasuresTitle ?? 'ASQ'}
        patientName={`${patient?.personalData.firstName} ${patient?.personalData.lastName}`}
      />
    </Stack>
  )
}
