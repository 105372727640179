import { Alert, PhoneOutgoingIcon, Skeleton } from '@shared/components'
import { EMRTask, NewEMRTaskBody, Patient, hasGroupRole } from '@shared/types'
import { dayjs, phone, sortBy } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../context/auth'
import { useEmrMutation } from '../../../../utils/hooks'
import { useSidePane } from '../../../../utils/hooks/use-side-pane'
import { useTasksCache } from '../../../../utils/hooks/use-tasks-cache'
import { IssueDrawer } from '../../../care_team/tasks/IssueDrawer'
import { CreateWinbackCallModal } from './CreateWinbackCallModal'

export const WinbackCallAlert = ({
  patient,
  tasks,
  isTasksFetched,
}: {
  patient?: Patient
  tasks?: EMRTask[]
  isTasksFetched?: boolean
}) => {
  const { currentUser } = useAuth()
  const tasksCache = useTasksCache()
  const [currentWinbackCallIssueId, setCurrentWinbackCallIssueId] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const { presentPane, paneVisible, hidePane, pane } = useSidePane()

  const createWinbackCallTaskMutation = useEmrMutation('POST /tasks/v2', {
    onSettled: () => {
      tasksCache.invalidate(patient?.oid)
    },
    onSuccess: data => {
      setCurrentWinbackCallIssueId(data.emrTaskId)
      setShowModal(false)
    },
  })

  useEffect(() => {
    const winbackCallIssuePaneKey = `winback-call-issue-${currentWinbackCallIssueId}`
    if (currentWinbackCallIssueId) {
      presentPane({
        key: winbackCallIssuePaneKey,
        content: <IssueDrawer step='notes' taskId={currentWinbackCallIssueId} onClose={hidePane} />,
      })
    } else if (!currentWinbackCallIssueId && pane?.key === winbackCallIssuePaneKey) {
      hidePane()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWinbackCallIssueId, paneVisible])

  const updatePatientMutation = useEmrMutation('PUT /patient/:patientId')

  const currentUserShouldSeeAlert = hasGroupRole(
    currentUser,
    'admin',
    'engineer',
    'enrollmentCoordinator',
  )

  const isPatientStatusEligibleForWinbackCall = ['lead', 'candidate'].includes(
    patient?.statuses?.patient || '',
  )

  const mostRecentWinbackCallIssue = tasks
    ?.sort(sortBy({ key: 'createdAt', order: 'DESC' }))
    ?.find(task => task.type === 'winback_call' && task.status === 'open')

  const showAlert =
    patient && isTasksFetched && currentUserShouldSeeAlert && isPatientStatusEligibleForWinbackCall

  const handleClose = () => {
    setShowModal(false)
    setCurrentWinbackCallIssueId(null)
  }

  const handleClick = () => {
    setShowModal(true)
  }

  const handleSubmit = async () => {
    if (mostRecentWinbackCallIssue || createWinbackCallTaskMutation.isSuccess) {
      return setCurrentWinbackCallIssueId(
        mostRecentWinbackCallIssue?.oid || createWinbackCallTaskMutation?.data?.emrTaskId || '',
      )
    }

    if (!patient || createWinbackCallTaskMutation.isLoading || updatePatientMutation.isLoading) {
      return
    }

    const issueDescription = `
      <p>New winback issue added for ${patient?.personalData?.firstName} ${patient?.personalData
        ?.lastName}:</p>
      <p>• ${capitalize(patient?.statuses?.patient)}</p>
      <p>• ${phone(patient?.personalData?.phone).formatted}</p>`

    const newWinbackCallTask: NewEMRTaskBody = {
      type: 'winback_call',
      priority: 3,
      description: issueDescription,
      patientId: patient?.oid,
      employeeId: currentUser.oid,
      pod: 'ec',
      results: {
        callOutcome: {
          label: 'Call outcome',
          value: null,
          optional: false,
        },
        nextWinbackCallDatetime: {
          label: 'Schedule next winback call (optional)',
          value: null,
          optional: true,
        },
      },
    }

    await Promise.all([
      createWinbackCallTaskMutation.mutateAsync({
        data: newWinbackCallTask,
      }),
      updatePatientMutation.mutateAsync({
        params: { patientId: patient?.oid },
        data: {
          statuses: {
            winback: {
              lastClaimedAt: dayjs().toISOString(),
              lastClaimedBy: currentUser.oid,
            },
          },
        },
      }),
    ])

    tasksCache.invalidate(patient?.oid)
    setShowModal(false)
  }

  if (!showAlert) {
    return null
  }

  const patientFirstName = patient?.personalData?.preferredName || patient?.personalData?.firstName
  const alertBodyText = mostRecentWinbackCallIssue
    ? `Click this banner to open ${patientFirstName}'s existing winback issue, then click 'Call' in the lower right corner.`
    : `Click this banner to add an issue, then click 'Call' in the lower right corner.`

  return (
    <>
      <CreateWinbackCallModal
        opened={showModal}
        handleSubmit={handleSubmit}
        isLoading={createWinbackCallTaskMutation.isLoading || updatePatientMutation.isLoading}
        onClose={handleClose}
      />
      <Skeleton visible={createWinbackCallTaskMutation.isLoading}>
        <Alert
          variant='secondary'
          title={`Ready to start a winback call with ${patientFirstName}?`}
          icon={<PhoneOutgoingIcon />}
          onClick={handleClick}
        >
          {alertBodyText}
        </Alert>
      </Skeleton>
    </>
  )
}
