import { Box, BoxProps, MantineColor, colorToHex, useMantineTheme } from '@shared/components'
import { PropsWithChildren } from 'react'

type MaxWidth = '50%' | '100%'

export type CommunicationsCardProps = {
  backgroundColor?: MantineColor
  textColor?: MantineColor
  maxWidth?: MaxWidth
} & BoxProps

export const CommunicationsCard = ({
  children,
  backgroundColor,
  maxWidth,
  ...props
}: PropsWithChildren<CommunicationsCardProps>) => {
  const {
    radius,
    other: { colors },
  } = useMantineTheme()

  return (
    <Box
      sx={{
        background: colorToHex(backgroundColor, colors) || colors.background[2],
        borderRadius: radius.sm,
        maxWidth: maxWidth || '100%',
      }}
      w='fit-content'
      p='sm'
      {...props}
    >
      {children}
    </Box>
  )
}
