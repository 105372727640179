import {
  CheckCircleIcon,
  EM_DASH,
  Group,
  SlashIcon,
  Text,
  useMantineTheme,
} from '@shared/components'
import { EnrollmentPanelLeadsRow, EnrollmentPanelRow } from './types'

export const InsuranceCellContent = ({ row }: { row: EnrollmentPanelRow }) => {
  const {
    other: { colors },
  } = useMantineTheme()

  if (!row.patientInsurancePlanName) {
    return <Text>{EM_DASH}</Text>
  }

  const icon =
    row.patientBillingOption === 'insurance' ? (
      <CheckCircleIcon size='sm' color={colors.success[0]} />
    ) : (
      <SlashIcon color={colors.background[4]} />
    )

  return (
    <Group spacing='xs'>
      {icon}
      <Text>{row.patientInsurancePlanName}</Text>
    </Group>
  )
}

export const LeadInsuranceCellContent = ({ row }: { row: EnrollmentPanelLeadsRow }) => {
  const {
    other: { colors },
  } = useMantineTheme()

  if (row.insurance_provider) {
    return <Text>{EM_DASH}</Text>
  }

  const icon =
    row.current_billing_option === 'insurance' ? (
      <CheckCircleIcon size='sm' color={colors.success[0]} />
    ) : (
      <SlashIcon color={colors.background[4]} />
    )

  return (
    <Group spacing='xs'>
      {icon}
      <Text>{row.insurance_provider}</Text>
    </Group>
  )
}
