import { useLifecycle } from '@shared/components'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { ReactHookFormErrorsObject } from '../../utils/reactHookForm'

export type ANoteTextAreaProps = {
  className?: string
  defaultValue?: string
  disabled?: boolean
  error?: ReactHookFormErrorsObject
  name?: string
  pattern?: string
  placeholder?: string
  type?: string
  value?: string
  id?: string
  minRows?: number
  maxRows?: number
  borderless?: boolean
  sidebar?: boolean
  label?: string
  onMount?: () => void
}

const ANoteTextArea = React.forwardRef<HTMLTextAreaElement, ANoteTextAreaProps>(
  (
    {
      name,
      id,
      minRows,
      maxRows,
      label,
      className,
      disabled,
      placeholder,
      error,
      defaultValue,
      sidebar,
      borderless,
      onMount,
      ...props
    },
    ref,
  ) => {
    /*
     * This is to solve a very specific issue with react-hook-form.
     * Basically, there are instances where we want to call setValue after the component mounts, since this is not possible before mounting.
     */
    useLifecycle({
      onMount,
    })

    let stateSpecificClassNames = ''

    if (error && name && error[name]) {
      stateSpecificClassNames = 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
    }

    const path =
      'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'

    return (
      <div className={`${className || ''} col-span-6`}>
        {label && (
          <label htmlFor={name} className='block text-sm font-medium leading-5 text-gray-700'>
            {label}
          </label>
        )}
        <div className='mt-1'>
          <TextareaAutosize
            defaultValue={defaultValue}
            placeholder={placeholder}
            spellCheck
            disabled={disabled}
            name={name}
            ref={ref}
            id={id}
            minRows={minRows}
            maxRows={maxRows}
            className={`shadow-sm block w-full border-daybreak-actions0 focus:border-daybreak-actions1 focus:ring-daybreak-actions1 focus:ring-2 focus:ring-offset-2 sm:text-sm rounded-md ${
              stateSpecificClassNames ?? ''
            } ${sidebar ? 'bg-near-white border-transparent resize-none' : ''} ${
              borderless ? 'border-white resize-none' : ''
            }`}
            {...props}
          />
          {error && name && error[name] && (
            <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
              <svg
                fill='currentColor'
                viewBox='0 0 20 20'
                className='mt-px w-4 h-4 mr-1 flex-shrink-0'
              >
                <path fillRule='evenodd' d={path} clipRule='evenodd'></path>
              </svg>
              {error[name]?.message}
            </p>
          )}
        </div>
      </div>
    )
  },
)

ANoteTextArea.displayName = 'ANoteTextArea'

export default ANoteTextArea
