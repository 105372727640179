import {
  Group,
  JsonInput,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  useBanner,
} from '@shared/components'
import { databaseMetadataFields, getOpheliaHttpError } from '@shared/types'
import { diff } from 'deep-object-diff'
import omit from 'lodash/omit'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useEmrMutation } from '../../../../utils/hooks'
import { jsonFormat } from '@shared/utils'

export const SaveCollectionDocumentModal = ({
  opened,
  onClose,
  patientId,
  collection,
  documentId,
  documentData,
  updatedDocumentData,
  onSubmit,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  collection: string
  documentId: string
  documentData: object
  updatedDocumentData: object
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const saveCollectionDocument = useEmrMutation(
    'POST /patient/:patientId/collections/:collection/:documentId',
  )

  const documentDataModel = omit(documentData, databaseMetadataFields)
  const afterDetails = diff(documentDataModel, updatedDocumentData)
  const beforeDetails = diff(updatedDocumentData, documentDataModel)

  return (
    <Modal
      title='Save collection document'
      opened={opened}
      size='64rem'
      onClose={() => {
        if (!saveCollectionDocument.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={saveCollectionDocument.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={saveCollectionDocument.isLoading}
            onClick={async () => {
              await saveCollectionDocument.mutateAsync(
                {
                  params: { patientId, collection, documentId },
                  data: { before: documentDataModel, after: updatedDocumentData },
                },
                {
                  onError: error => {
                    showBanner({
                      type: 'error',
                      dismissable: true,
                      label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                    })
                  },
                },
              )

              invalidate({ patientId, documents: [{ collection, documentId }] })

              onClose()
              onSubmit()
            }}
          >
            Save
          </PrimaryButton>
        </Group>
      }
    >
      <Group grow>
        <Stack spacing='sm'>
          <Text>Deleted</Text>
          <JsonInput minRows={19} value={jsonFormat(beforeDetails)} />
        </Stack>
        <Stack spacing='sm'>
          <Text>Added</Text>
          <JsonInput minRows={19} value={jsonFormat(afterDetails)} />
        </Stack>
      </Group>
    </Modal>
  )
}
