import { useForm } from '@mantine/form'
import {
  DatePicker,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { PaymentPlan } from '@shared/types'
import { dayjs } from '@shared/utils'
import min from 'lodash/min'
import { isRequired } from '../../../../utils/formValidation'
import { useEmrMutation, useEmrQuery } from '../../../../utils/hooks'
import { getFinalPaymentDate } from '../../invoices/paymentPlanUtils'

type EditPlanForm = {
  nextPaymentDate: Date
}

type EditPaymentPlanModalProps = {
  opened: boolean
  onClose: () => void
  paymentPlan: PaymentPlan
}

export const EditPaymentPlanModal = ({
  opened,
  onClose,
  paymentPlan,
}: EditPaymentPlanModalProps) => {
  const { paymentDates } = paymentPlan
  const remainingPayments = paymentDates.filter(paymentDate => dayjs().isBefore(dayjs(paymentDate)))

  const nextScheduledPaymentDate = dayjs(min(remainingPayments)).toDate()

  const editPlanForm = useForm<EditPlanForm>({
    initialValues: {
      nextPaymentDate: dayjs(min(remainingPayments)).toDate(),
    },
    validate: {
      nextPaymentDate: validateWith(isRequired),
    },
  })

  const { nextPaymentDate: nextSelectedPaymentDate } = editPlanForm.values

  const editPaymentPLan = useEmrMutation('PUT /payment-plan/:paymentPlanId')

  const { refetch: refetchPaymentPlans } = useEmrQuery('GET /patient/:patientId/payment-plans', {
    params: {
      patientId: paymentPlan.patientId,
    },
  })

  const onSubmit = async () => {
    if (editPlanForm.validate().hasErrors) {
      return
    }

    await editPaymentPLan.mutateAsync({
      params: {
        paymentPlanId: paymentPlan.oid,
      },
      data: {
        nextPaymentDate: dayjs(nextSelectedPaymentDate).toISOString(),
      },
    })

    editPaymentPLan.reset()

    void refetchPaymentPlans()

    onClose()
  }

  const finalPaymentDate = getFinalPaymentDate({
    firstPaymentDate: dayjs(nextSelectedPaymentDate).toISOString(),
    numPayments: `${remainingPayments.length}`,
    paymentFrequency: paymentPlan.paymentFrequency,
  })

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Edit next payment date'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Go back</SecondaryButton>
          <PrimaryButton onClick={onSubmit} loading={editPaymentPLan.isLoading}>
            Update
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <DatePicker
          label='Next payment date'
          // Only allow FCs to select a date that is at least 1 day in the future so that the cron job picks up the next payment
          minDate={dayjs().startOf('day').add(1, 'days').toDate()}
          {...editPlanForm.getInputProps('nextPaymentDate')}
        />
        {!dayjs(nextScheduledPaymentDate).isSame(nextSelectedPaymentDate, 'day') && (
          <Text>
            All subsequent payment dates will also be updated. The updated last payment date is{' '}
            {dayjs(finalPaymentDate).format('M/D/YYYY')}.
          </Text>
        )}
      </Stack>
    </Modal>
  )
}
