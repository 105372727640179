import {
  ClockIcon,
  ScrollArea,
  Table,
  TertiaryButton,
  Text,
  Th,
  TimelineCard,
  TimelineCardTitle,
  TrashCanIcon,
  UserIcon,
  useMantineTheme,
} from '@shared/components'
import { drugScreenInfo, hasGroupRole } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useAuth } from '../../../../context/auth'
import { useEmrQuery } from '../../../../utils/hooks'
import { JourneyItemContent, usePatient } from '../../PPatientContext'
import { DeleteDrugScreenModal } from '../../labs/DeleteDrugScreenModal'
import DrugScreenRow from '../../labs/DrugScreenRow'
import { FieldSkeleton, FooterContentSection } from './SharedContent'

export const DrugScreenContent = ({
  drugScreen,
}: {
  drugScreen: JourneyItemContent<'drug-screen'>
}) => {
  const { currentUser } = useAuth()
  const { patientId } = usePatient()
  const [openDeleteDrugScreenModal, setOpenDeleteDrugScreenModal] = useState(false)

  const datetime = dayjs(drugScreen.administeredAt || drugScreen.createdAt)
  // eslint-disable-next-line no-magic-numbers
  const allowDeletionTime = dayjs().subtract(12, 'hour')

  const canDelete =
    dayjs(drugScreen.administeredAt).isAfter(allowDeletionTime) ||
    hasGroupRole(currentUser, 'engineer')

  const employeeQuery = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: drugScreen.authorId } },
    { enabled: Boolean(drugScreen.authorId) },
  )

  const employeeQueryData = employeeQuery.data

  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <TimelineCard leftSide={<TimelineCardTitle title='Urine drug screen' />}>
      <ScrollArea scrollbarSize='0.25rem' type='hover'>
        <Table verticalSpacing='sm' horizontalSpacing='xs' withBorder>
          <thead className='mantine'>
            <tr className='mantine'>
              {Object.keys(drugScreenInfo).map(drugScreen => {
                return (
                  <Th
                    sortable={false}
                    center
                    style={{ width: '60px', backgroundColor: colors.background[1] }}
                    key={drugScreen}
                  >
                    <Text bold size='xs'>
                      {drugScreenInfo[drugScreen as keyof typeof drugScreenInfo].abbreviation}
                    </Text>
                  </Th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <DrugScreenRow
              key={drugScreen.oid}
              drugScreen={drugScreen}
              onEdit={() => void 0}
              onDelete={() => void 0}
              readOnly={!canDelete}
              showDateColumn={false}
            />
          </tbody>
        </Table>
      </ScrollArea>
      <FooterContentSection
        right={
          canDelete && (
            <TertiaryButton
              onClick={() => setOpenDeleteDrugScreenModal(true)}
              leftIcon={<TrashCanIcon />}
            >
              Delete
            </TertiaryButton>
          )
        }
      >
        <FooterContentSection.Item>
          <UserIcon color={({ text }) => text[1]} />
          <FieldSkeleton isLoading={employeeQuery.isLoading} isError={employeeQuery.isError}>
            <Text size='xs' color={({ text }) => text[1]}>
              {employeeQueryData?.name}
            </Text>
          </FieldSkeleton>
        </FooterContentSection.Item>
        <FooterContentSection.Item>
          <ClockIcon color={({ text }) => text[1]} />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs' color={({ text }) => text[1]}>
            {dayjs(datetime).format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
      </FooterContentSection>
      <DeleteDrugScreenModal
        drugScreenId={drugScreen.oid}
        patientId={patientId}
        onClose={() => setOpenDeleteDrugScreenModal(false)}
        opened={openDeleteDrugScreenModal}
      />
    </TimelineCard>
  )
}
