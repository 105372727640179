import { Group, Modal, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import { Patient } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'

type CreateCallMeNowWelcomeCallModalProps = {
  closeModal: () => void
  patient: Patient
}

const CreateCallMeNowWelcomeCallModal = ({
  closeModal,
  patient,
}: CreateCallMeNowWelcomeCallModalProps) => {
  const isPatientDischarged = patient.statuses.patient === 'discharged'
  const callMeNowUrl = isPatientDischarged
    ? 'POST /patient/:patientId/appointments/returning-call-me-now'
    : 'POST /patient/:patientId/appointments/call-me-now'

  const createCallMeNowMutation = useMutation(emrApi.getMutation(callMeNowUrl))

  const handleCreateCallMeNow = async () => {
    await createCallMeNowMutation.mutateAsync({
      params: {
        patientId: patient.oid,
      },
    })

    location.reload()
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title={`Create "Call me now" ${isPatientDischarged ? 'returning ' : ''}welcome call`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>No, go back</SecondaryButton>
          <PrimaryButton
            loading={createCallMeNowMutation.isLoading}
            onClick={handleCreateCallMeNow}
          >
            Yes
          </PrimaryButton>
        </Group>
      }
    >
      <Text>
        {`You're about to create a "Call me now" ${
          isPatientDischarged ? 'returning ' : ''
        }welcome call for this
        patient. Are you sure you want to proceed?`}
      </Text>
    </Modal>
  )
}

export default CreateCallMeNowWelcomeCallModal
