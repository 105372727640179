import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { patientsApi } from '../../api'
import ANoteDateInput from '../../components/atoms/ANoteDateInput'
import ANoteSectionContent from '../../components/atoms/ANoteSectionContent'
import ANoteTextInput from '../../components/atoms/ANoteTextInput'
import { alternativeDate, required } from '../../components/molecules/MDictionary'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'
import ODSSecondaryButton from '../../components/ods/ODSSecondaryButton'

export type OAddPriorAuthorizationModalProps = {
  closeModal: () => void
  patientID: string
}

const OAddPriorAuthorizationModal = ({
  closeModal,
  patientID,
}: OAddPriorAuthorizationModalProps) => {
  const { register, handleSubmit } = useForm()
  const [errorPresent, setErrorPresent] = useState<boolean>(false)

  const submit = async (data: Record<string, string>) => {
    if (data.startDate) {
      const dateWithTZ = new Date(
        parseInt(data.startDate.split('-')[0]!),
        parseInt(data.startDate.split('-')[1]!) - 1,
        parseInt(data.startDate.split('-')[2]!),
      )
      data = {
        ...data,
        startDate: dateWithTZ.toISOString(),
      }
    }
    if (data.endDate) {
      const dateWithTZ = new Date(
        parseInt(data.endDate.split('-')[0]!),
        parseInt(data.endDate.split('-')[1]!) - 1,
        parseInt(data.endDate.split('-')[2]!),
      )
      data = {
        ...data,
        endDate: dateWithTZ.toISOString(),
      }
    }

    try {
      setErrorPresent(false)
      await patientsApi.createPriorAuthorization({ patientId: patientID, data })
      window.location.reload()
    } catch (_err) {
      setErrorPresent(true)
    }
  }

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(submit)}>
        <div className='flex flex-col'>
          <div className='sticky top-0 bg-white w-full z-30 py-6 border-b border-gray-200'>
            <div className='flex justify-between items-center'>
              <div className='flex flex-col justify-start'>
                <p className='text-xl leading-4 w-full text-left mb-4'>New Prior Authorization</p>
              </div>
              <div className='flex space-x-2 h-3/4'>
                <ODSPrimaryButton message='Submit' type='submit' />
                <ODSSecondaryButton type='button' message='Cancel' onClick={closeModal} />
              </div>
            </div>
          </div>
          {errorPresent && (
            <div className='bg-red-100 border border-red-400 text-error px-4 py-3 rounded relative'>
              Error: Drug field is required
            </div>
          )}

          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-2 pb-10'>
            <div className='col-span-2'>
              <div className='flex flex-col items-start justify-start w-full mt-8 pb-4'>
                <p className='text-base leading-6'>Drug info (required):</p>
              </div>
              <div className='w-full justify-items-start'>
                <div className='w-100'>
                  <ANoteTextInput
                    name='drug'
                    id='drug'
                    data-testid='drug'
                    placeholder='Buprenorphine HCL Sublingual Tablet Sublingual 8 MG'
                    ref={register({ required: { value: true, message: required } })}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex flex-col items-start justify-start w-full mt-8 pb-4'>
                <p className='text-base leading-6'>Quantity:</p>
              </div>
              <div className='w-full justify-items-start'>
                <div className='w-60'>
                  <ANoteTextInput name='quantity' id='quantity' placeholder='30' ref={register()} />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex flex-col items-start justify-start w-full mt-8 pb-4'>
                <p className='text-base leading-6'>Duration:</p>
              </div>
              <div className='w-full justify-items-start'>
                <div className='w-60'>
                  <ANoteTextInput
                    name='duration'
                    id='duration'
                    placeholder='90 days'
                    ref={register()}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex flex-col items-start justify-start w-full mt-8 pb-4'>
                <p className='text-base leading-6'>Begin Date:</p>
              </div>
              <div className='w-full justify-items-start'>
                <div className='w-60'>
                  <ANoteDateInput
                    name='startDate'
                    id='startDate'
                    placeholder='YYYY-MM-DD'
                    inputRef={register({
                      pattern: {
                        value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                        message: alternativeDate,
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex flex-col items-start justify-start w-full mt-8 pb-4'>
                <p className='text-base leading-6'>End Date:</p>
              </div>
              <div className='w-full justify-items-start'>
                <div className='w-60'>
                  <ANoteDateInput
                    name='endDate'
                    id='endDate'
                    placeholder='YYYY-MM-DD'
                    inputRef={register({
                      pattern: {
                        value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                        message: alternativeDate,
                      },
                    })}
                  />
                </div>
              </div>
            </div>
          </ANoteSectionContent>
        </div>
      </form>
    </div>
  )
}

export default OAddPriorAuthorizationModal
