import { WelcomeCallVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextarea } from '../visitNoteForm/PatientVisitNoteTextarea'

export const LegacyPatientStorySection = ({ form }: { form: WelcomeCallVisitNoteForm }) => {
  if (!form.values.patient_story) {
    return null
  }

  return (
    <PatientVisitNoteSection title='Patient story'>
      <PatientVisitNoteTextarea disabled {...form?.getInputProps('patient_story')} />
    </PatientVisitNoteSection>
  )
}
