import { useToggle } from '@mantine/hooks'
import {
  Alert,
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  RichTextEditor,
  Stack,
  Text,
  UnstyledButton,
} from '@shared/components'
import { NonVisitEvent } from '@shared/types'
import { dayjs } from '@shared/utils'
import pluralize from 'pluralize'

export type PastEventsAlertSectionProps = {
  pastEvents: NonVisitEvent[]
}

const PastEventsAlertSection = ({ pastEvents }: PastEventsAlertSectionProps) => {
  const [expanded, toggleExpanded] = useToggle([true, false])
  if (!pastEvents || pastEvents.length === 0) {
    return null
  }
  return (
    <Alert
      variant='warning'
      title={
        <UnstyledButton
          onClick={() => toggleExpanded()}
          sx={{ outline: 'none', '&:focus': { outline: 'none' } }}
        >
          <Text bold>
            {pastEvents.length} non-visit {pluralize('event', pastEvents.length)}
          </Text>
        </UnstyledButton>
      }
      icon={<BookmarkIcon />}
      actionIcon={
        <UnstyledButton
          onClick={() => toggleExpanded()}
          sx={{ outline: 'none', '&:focus': { outline: 'none' } }}
        >
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </UnstyledButton>
      }
    >
      {expanded && (
        <Stack mt='md' ml='md'>
          {pastEvents.map(pastEvent => {
            return (
              <Stack spacing='sm' key={pastEvent.oid}>
                <Text bold>
                  {pastEvent.title}
                  {pastEvent.titleFreeform ? ` | ${pastEvent.titleFreeform}` : ''}
                  {` on ${dayjs(pastEvent.createdAt).format('MMMM Do, YYYY')}`}
                </Text>
                <RichTextEditor value={pastEvent.content} editable={false} />
              </Stack>
            )
          })}
        </Stack>
      )}
    </Alert>
  )
}

export default PastEventsAlertSection
