import { AwardIcon, Box, Checkbox, ClockIcon, Group, Pill, Stack, Text } from '@shared/components'
import { DoseSpotPharmacy } from '@shared/types'
import { phone } from '@shared/utils'

type PharmacyRowProps = {
  pharmacy: DoseSpotPharmacy
  onSelect: (dsPharmacy?: DoseSpotPharmacy) => void
  selected: boolean
  isPreferred?: boolean
  isMostRecent?: boolean
}
const PharmacyRow = ({
  pharmacy,
  onSelect,
  selected,
  isPreferred,
  isMostRecent,
}: PharmacyRowProps) => {
  return (
    <Box
      sx={theme => ({
        cursor: 'pointer',
        borderColor: `${theme.other.colors.actions[0]}`,
        borderStyle: 'solid',
        borderWidth: theme.other.sizes.border.md,
        borderRadius: theme.radius.sm,
      })}
    >
      <Checkbox
        checked={selected}
        onChange={event => {
          if (event.target.checked) {
            onSelect(pharmacy)
          } else {
            onSelect(undefined)
          }
        }}
        label={
          <Stack spacing={0}>
            <Group
              noWrap
              sx={() => ({
                justifyContent: 'space-between',
              })}
            >
              <Text bold>{pharmacy.StoreName}</Text>
              <Group noWrap>
                {isMostRecent && (
                  <Pill status='none' variant='filled' leftSection={<ClockIcon />}>
                    Most Recent
                  </Pill>
                )}
                {isPreferred && (
                  <Pill status='none' variant='filled' leftSection={<AwardIcon />}>
                    Preferred
                  </Pill>
                )}
              </Group>
            </Group>
            <Text>{pharmacy.Address1}</Text>
            <Text>
              {pharmacy.City}, {pharmacy.State} {pharmacy.ZipCode}
            </Text>
            <Text>{phone(pharmacy.PrimaryPhone).formatted}</Text>
          </Stack>
        }
      />
    </Box>
  )
}

export default PharmacyRow
