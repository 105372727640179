import React from 'react'

export type ODSProgressTrackerProps = {
  total: number
  current: number
}

const ODSProgressTracker = ({ total, current }: ODSProgressTrackerProps) => {
  if (current > total) {
    return null
  }
  const uncompleted = total - current
  const completed = total - uncompleted - 1

  return (
    <ul className='flex items-center space-x-5'>
      {Boolean(completed) &&
        [...Array(completed)].map((_, i) => (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className='block w-2.5 h-2.5 bg-daybreak-actions1 rounded-full'
          />
        ))}
      <li>
        <div className='relative flex items-center justify-center'>
          <span className='absolute w-5 h-5 p-px flex'>
            <span className='w-full h-full rounded-full bg-daybreak-actions0' />
          </span>
          <span className='relative block w-2.5 h-2.5 bg-daybreak-actions1 rounded-full' />
        </div>
      </li>
      {Boolean(uncompleted) &&
        [...Array(uncompleted)].map((_, i) => (
          <li
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className='block w-2.5 h-2.5 bg-gray-200 rounded-full'
          />
        ))}
    </ul>
  )
}

export default ODSProgressTracker
