import { FeatherIcon, Group, Stack, Text, UserCheckIcon } from '@shared/components'
import { useEmrQuery } from '../../../utils/hooks'
import { DurationText } from './DurationText'

export type LockedCaseReviewNoteInfoSectionProps = {
  patientTime?: number
  clinicianTime?: number
  lockedBy?: string
  psychConsultantAttestationInfo?: {
    attestedBy?: string
    comments?: string
  }
  pcAttestationInfo?: {
    attestedBy?: string
    comments?: string
  }
  preWorkTime?: number
  postCaseReviewTime?: number
  caseReviewTime?: number
  hideDuration?: boolean
}

export const LockedCaseReviewNoteInfoSection = ({
  patientTime,
  clinicianTime,
  lockedBy,
  psychConsultantAttestationInfo,
  pcAttestationInfo,
  preWorkTime,
  postCaseReviewTime,
  caseReviewTime,
  hideDuration,
}: LockedCaseReviewNoteInfoSectionProps) => {
  const attestedByEmployee = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: {
        employeeId: psychConsultantAttestationInfo?.attestedBy || '',
      },
    },
    {
      enabled: Boolean(psychConsultantAttestationInfo?.attestedBy),
    },
  )

  const attestedByPcQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: {
        employeeId: pcAttestationInfo?.attestedBy || '',
      },
    },
    {
      enabled: Boolean(pcAttestationInfo?.attestedBy),
    },
  )

  return (
    <Stack spacing='sm'>
      {lockedBy && (
        <Group spacing='xs'>
          <FeatherIcon color={colors => colors.text[1]} />
          <Text size='xs' color={colors => colors.text[1]}>
            {`Signed by ${lockedBy}`}
          </Text>
        </Group>
      )}
      {!hideDuration && (
        <DurationText
          patientTime={patientTime}
          clinicianTime={clinicianTime}
          preWorkTime={preWorkTime}
          postCaseReviewTime={postCaseReviewTime}
          caseReviewTime={caseReviewTime}
        />
      )}
      {psychConsultantAttestationInfo?.attestedBy && (
        <Stack spacing='sm'>
          <Group spacing='xs'>
            <UserCheckIcon color={colors => colors.text[1]} />
            <Text size='xs' color={colors => colors.text[1]}>
              Attested by {attestedByEmployee.data?.name}
            </Text>
          </Group>
          {psychConsultantAttestationInfo.comments && (
            <Text size='xs' color={colors => colors.text[1]}>
              {psychConsultantAttestationInfo.comments}
            </Text>
          )}
        </Stack>
      )}
      {pcAttestationInfo?.attestedBy && (
        <Stack spacing='sm'>
          <Group spacing='xs'>
            <UserCheckIcon color={colors => colors.text[1]} />
            <Text size='xs' color={colors => colors.text[1]}>
              Attested by {attestedByPcQuery?.data?.name}
            </Text>
          </Group>
          {pcAttestationInfo.comments && (
            <Text size='xs' color={colors => colors.text[1]}>
              {pcAttestationInfo.comments}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  )
}
