import {
  AlertIcon,
  CheckCircleIcon,
  EM_DASH,
  Group,
  Select,
  SelectItem,
  Skeleton,
  SlashIcon,
  Stack,
  Table,
  Td,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
} from '@shared/components'
import { EmrApi, hasGroupRole } from '@shared/types'
import { dayjs, sortBy, toTime } from '@shared/utils'
import range from 'lodash/range'
import upperFirst from 'lodash/upperFirst'
import { useEffect, useMemo, useState } from 'react'
import { getConfirmedLabel } from '../../../components/VisitPillStatus'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { useAuth } from '../../../context/auth'
import { useEmrQuery } from '../../../utils/hooks'
import { PatientTasksPopover } from '../../patient/journey/PatientTasksPopover'
import { EmptyState } from '../EmptyState'

const NUMBER_OF_LOADING_ROWS = 5
const TABLE_COLUMNS = [
  'Patient name',
  'Tasks',
  'Tasks due',
  'Intake visit',
  'Confirmation due',
  'Enrollment coordinator',
] as const

export type OrderBy = {
  key: OrderByKey
  direction: 'ASC' | 'DESC'
}

type TableColumn = (typeof TABLE_COLUMNS)[number]

type OrderByKey = keyof EmrApi['GET /enrollment-panel/booked-candidates']['res'][number]

const SORT_KEYS: Partial<Record<TableColumn, OrderByKey>> = {
  'Intake visit': 'visitDatetime',
  'Tasks due': 'tasksDue',
  'Confirmation due': 'visitConfirmationDue',
  'Enrollment coordinator': 'enrollmentCoordinatorName',
}

const FIXED_WIDTH_COLUMNS: TableColumn[] = ['Tasks', 'Tasks due', 'Confirmation due']
const NO_EMPLOYEE: SelectItem = { value: '', label: 'Display all' }

const isOpenTask = (
  task: EmrApi['GET /enrollment-panel/booked-candidates']['res'][number]['patientTasks'][number],
) => {
  return task.requiredFor.includes('intake-visit') && !task.isComplete
}

export const BookedCandidatesTables = () => {
  const { currentUser } = useAuth()
  const [orderByKey, setOrderByKey] = useState<OrderByKey>('visitDatetime')
  const [orderByDirection, setOrderByDirection] = useState<OrderBy['direction']>('ASC')
  const [enrollmentCoordinators, setEnrollmentCoordinators] = useState<SelectItem[]>([NO_EMPLOYEE])
  const [employeeId, setEmployeeId] = useState<string>(
    hasGroupRole(currentUser, 'enrollmentCoordinator') ? currentUser.oid : '',
  )

  const enrollmentCoordinatorQuery = useEmrQuery(
    'GET /employees/list',
    {
      query: { role: ['enrollmentCoordinator', 'leadEnrollmentCoordinator'] },
    },
    {
      onSuccess: data => {
        setEnrollmentCoordinators(
          [NO_EMPLOYEE].concat(
            data.map(employee => ({ value: employee.oid, label: employee.name })),
          ),
        )
      },
    },
  )

  const enrollmentPanelQuery = useEmrQuery('GET /enrollment-panel/booked-candidates', {
    query: { employeeId },
  })

  const now = dayjs()
  const rows = useMemo(() => {
    const data = (enrollmentPanelQuery.data ?? []).filter(
      item => item.patientTasks.some(isOpenTask) || !item.visitConfirmed,
    )

    if (orderByKey === 'tasksDue' || orderByKey === 'visitDatetime') {
      return (data.filter(item => item.patientTasks.some(isOpenTask)) ?? [])
        .sort(
          sortBy({
            key: orderByKey,
            order: orderByDirection,
          }),
        )
        .concat(
          (data.filter(item => !item.patientTasks.some(isOpenTask)) ?? []).sort(
            sortBy({
              key: orderByKey,
              order: orderByDirection,
            }),
          ),
        )
    }

    if (orderByKey === 'visitConfirmationDue') {
      return (
        data.filter(item => now.isBefore(item.visitConfirmationDue) && !item.visitConfirmed) || []
      )
        .sort(
          sortBy({
            key: orderByKey,
            order: orderByDirection,
          }),
        )
        .concat(
          (
            data.filter(item => now.isAfter(item.visitConfirmationDue) || item.visitConfirmed) || []
          ).sort(
            sortBy({
              key: orderByKey,
              order: orderByDirection,
            }),
          ),
        )
    }

    return data.sort(
      sortBy({
        key: orderByKey,
        order: orderByDirection,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentPanelQuery.data, now, orderByDirection, orderByKey, now.format('MM/DD/YYYY h:mma')])

  useEffect(() => {
    const interval = setInterval(() => {}, toTime('1 min').ms())
    return () => clearInterval(interval)
  }, [])

  const tableColumns = TABLE_COLUMNS.filter(column =>
    employeeId === '' ? true : column !== 'Enrollment coordinator',
  )

  return (
    <Stack>
      {hasGroupRole(currentUser, 'engineer') && (
        <Group>
          <Skeleton visible={enrollmentCoordinatorQuery.isLoading}>
            <Select
              value={employeeId}
              onChange={value => setEmployeeId(value ?? '')}
              data={enrollmentCoordinators}
            />
          </Skeleton>
        </Group>
      )}
      {!enrollmentPanelQuery.isLoading && rows.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          striped
          withBorder
          verticalSpacing='sm'
          sx={({ radius, other: { sizes, colors } }) => ({
            tableLayout: 'auto',
            backgroundColor: colors.background[0],
            borderWidth: sizes.border.lg,
            borderRadius: radius.sm,
            borderCollapse: 'separate',
            borderStyle: 'solid',
            borderSpacing: '0',
            borderColor: colors.background[2],
          })}
        >
          <thead className='mantine'>
            <tr className='mantine'>
              {tableColumns.map(header => {
                const isCurrentColumn = orderByKey === SORT_KEYS[header]
                const isReversed = orderByDirection === 'ASC'
                const toggledOrder = isReversed ? 'DESC' : 'ASC'

                return (
                  <Th
                    sortable={header in SORT_KEYS}
                    sorted={isCurrentColumn}
                    reversed={isReversed}
                    onSort={() => {
                      setOrderByKey(SORT_KEYS[header] ?? 'tasksDue')
                      setOrderByDirection(isCurrentColumn ? toggledOrder : 'ASC')
                    }}
                    key={header}
                    style={FIXED_WIDTH_COLUMNS.includes(header) ? { width: `18ch` } : undefined}
                  >
                    {header}
                  </Th>
                )
              })}
            </tr>
          </thead>
          <tbody className='mantine'>
            {enrollmentPanelQuery.isLoading &&
              range(0, NUMBER_OF_LOADING_ROWS + 1).map(i => (
                <LoadingRow key={i} headersLength={TABLE_COLUMNS.length} />
              ))}
            {!enrollmentPanelQuery.isLoading && rows.length === 0 && (
              <NoDataRow message='No winback patients found' headersLength={TABLE_COLUMNS.length} />
            )}
            {!enrollmentPanelQuery.isLoading &&
              rows.length > 0 &&
              rows.map(row => {
                return (
                  // eslint-disable-next-line no-magic-numbers
                  <tr key={row.patientId} style={{ opacity: row.claimed ? 0.3 : 1 }}>
                    {tableColumns.map(col => {
                      const visitTaskDueDatetime = dayjs(row.tasksDue)
                      const openTasks = row.patientTasks.filter(isOpenTask).length
                      const visitConfirmationDueDatetime = dayjs(row.visitConfirmationDue)
                      const needsConfirmation = visitConfirmationDueDatetime.isWithinNext(1, 'day')

                      switch (col) {
                        case 'Patient name':
                          return (
                            <Td key={col}>
                              <TertiaryButton
                                component='a'
                                target='_blank'
                                href={`/patients/${row.patientId}`}
                              >
                                {row.patientName || EM_DASH}
                              </TertiaryButton>
                            </Td>
                          )
                        case 'Tasks':
                          return (
                            <Td key={col}>
                              <PatientTasksPopover
                                data={row.patientTasks}
                                isLoading={false}
                                isError={false}
                                position='right-start'
                                forTable
                              />
                            </Td>
                          )
                        case 'Intake visit':
                          return (
                            <Td key={col}>
                              <Group spacing='xs'>
                                <Tooltip
                                  label={
                                    needsConfirmation || row.visitConfirmed
                                      ? getConfirmedLabel({
                                          confirmed: row.visitConfirmed,
                                          confirmedAt: row.visitConfirmedAt,
                                          confirmedBy: row.visitConfirmedBy,
                                          confirmationDue: '',
                                        })
                                      : 'Not ready to be confirmed'
                                  }
                                >
                                  {row.visitConfirmed && (
                                    <CheckCircleIcon color={colors => colors.success[0]} />
                                  )}
                                  {!row.visitConfirmed && needsConfirmation && (
                                    <AlertIcon color={colors => colors.warning[0]} />
                                  )}
                                  {!row.visitConfirmed && !needsConfirmation && (
                                    <SlashIcon color={colors => colors.background[4]} />
                                  )}
                                </Tooltip>

                                <Text>
                                  {dayjs(row.visitDatetime).format('ddd, MM/DD/YYYY | h:mma z')}
                                </Text>
                              </Group>
                            </Td>
                          )
                        case 'Tasks due':
                          return (
                            <Td key={col}>
                              <Tooltip label={visitTaskDueDatetime.format('MM/DD/YYYY h:mma z')}>
                                <Text>
                                  {openTasks === 0
                                    ? EM_DASH
                                    : upperFirst(visitTaskDueDatetime.fromNow())}
                                </Text>
                              </Tooltip>
                            </Td>
                          )
                        case 'Confirmation due':
                          return (
                            <Td key={col}>
                              <Tooltip
                                label={visitConfirmationDueDatetime.format('MM/DD/YYYY h:mma z')}
                              >
                                <Text>
                                  {row.visitConfirmed
                                    ? EM_DASH
                                    : upperFirst(visitConfirmationDueDatetime.fromNow())}
                                </Text>
                              </Tooltip>
                            </Td>
                          )
                        case 'Enrollment coordinator':
                          return (
                            <Td key={col}>
                              <Text>{row.enrollmentCoordinatorName}</Text>
                            </Td>
                          )
                        default:
                          return <Td key={col} />
                      }
                    })}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      )}
    </Stack>
  )
}
