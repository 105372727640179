import { useForm } from '@mantine/form'
import {
  Alert,
  BetterDrawer,
  BookmarkIcon,
  CheckCircleIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Group,
  PrimaryButton,
  SaveIcon,
  SecondaryButton,
  Stack,
} from '@shared/components'
import {
  Appointment,
  Patient,
  VisitNoteContent,
  VisitNoteType,
  YES_OR_NO,
  dischargeStatuses,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { DischargeAlert } from '../../../components/banners/DischargeAlert'
import { YesOrNoRadio } from '../../../components/forms/YesOrNoRadio'
import { useEmrMutation, useEmrQuery } from '../../../utils/hooks'

export type DischargeVisitDrawerProps = {
  visitId: string
  patientId: Patient['oid']
  visitNoteType: VisitNoteType
  onClose: () => void
}

type Form = {
  discussed_discharge: 'yes' | 'no'
  is_discharge_visit: 'yes' | 'no'
}

export const isScheduledForDischarge = ({ patient }: { patient?: Patient }) => {
  const discharge = patient?.discharge

  return (
    dischargeStatuses.some(status => status === patient?.statuses?.patient) &&
    discharge?.date &&
    discharge?.reason &&
    discharge?.note
  )
}

export const isDischargeNoteCompleted = ({
  visitNoteContent,
}: {
  visitNoteContent: VisitNoteContent | null
}) => {
  const isDiscussed = YES_OR_NO.includes(visitNoteContent?.discussed_discharge)
  const isDischargeVisit = YES_OR_NO.includes(visitNoteContent?.is_discharge_visit)
  return isDiscussed && isDischargeVisit
}

export const DISCHARGE_NOTE_RELEASE_DATE = dayjs('2023-10-22')

export const mustCompleteDischargeNote = ({
  patient,
  visitNoteContent,
  appointment,
}: {
  patient?: Patient
  appointment?: Appointment
  visitNoteContent: VisitNoteContent | null
}) => {
  /*
   * Auto-open the discharge notes drawer when the following conditions are met:
   * 1. The visit is _after_ the discharge note release date
   * 2. The note is not locked
   * 3. The patient is scheduled for discharge
   * 4. The discharge note is not yet completed
   */
  const datetime = appointment?.datetime
  const isAfterRelease = datetime && dayjs(datetime).isAfter(DISCHARGE_NOTE_RELEASE_DATE)
  const isVisitNoteLocked = visitNoteContent?.locked_at

  return Boolean(
    isAfterRelease &&
      !isVisitNoteLocked &&
      isScheduledForDischarge({ patient }) &&
      !isDischargeNoteCompleted({ visitNoteContent }),
  )
}

export const DischargeNotesAlert = ({
  patient,
  visitNoteContent,
  appointment,
  openDischargeNotesDrawer,
}: {
  patient?: Patient
  visitNoteContent: VisitNoteContent | null
  appointment?: Appointment
  openDischargeNotesDrawer: (() => void) | null
}) => {
  if (!isScheduledForDischarge({ patient })) {
    return null
  }

  if (isDischargeNoteCompleted({ visitNoteContent })) {
    return (
      <Flex align='center' bg='white' pb='xs'>
        <Alert style={{ flexGrow: 1 }} variant='success' icon={<CheckCircleIcon />}>
          Discharge notes complete
        </Alert>
        {openDischargeNotesDrawer && (
          <SecondaryButton size='sm' ml='md' onClick={openDischargeNotesDrawer}>
            Discharge notes
          </SecondaryButton>
        )}
      </Flex>
    )
  }

  if (mustCompleteDischargeNote({ patient, visitNoteContent, appointment })) {
    return (
      <Flex align='center' bg='white' pb='xs'>
        <Alert style={{ flexGrow: 1 }} variant='warning' icon={<BookmarkIcon />}>
          Discharge notes pending
        </Alert>
        {openDischargeNotesDrawer && (
          <SecondaryButton size='sm' ml='md' onClick={openDischargeNotesDrawer}>
            Discharge notes
          </SecondaryButton>
        )}
      </Flex>
    )
  }

  return null
}

const DischargeVisitDrawerContent = ({
  patientId,
  visitId,
  visitNoteType,
  onClose,
}: DischargeVisitDrawerProps) => {
  const queryClient = useQueryClient()
  const form = useForm<Form>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEditing, setIsEditing] = React.useState(true)

  const patientQuery = useEmrQuery('GET /patient/:patientId', {
    params: { patientId },
  })

  const notes = useEmrQuery('GET /patient/:patientId/visits/:visitId/notes', {
    params: {
      patientId,
      visitId,
    },
  })

  const visitNoteContent = notes.data?.[visitNoteType]
  const isVisitNoteLocked = visitNoteContent?.locked_at

  useEffect(() => {
    if (visitNoteContent) {
      // Init form values
      form.setValues({
        discussed_discharge: visitNoteContent?.discussed_discharge,
        is_discharge_visit: visitNoteContent?.is_discharge_visit,
      })

      // If note is locked, disable editing further
      if (visitNoteContent.locked_at) {
        setIsEditing(false)
      }
    }
  }, [visitNoteContent])

  const updateVisitNote = useEmrMutation('PUT /patient/:patientId/visits/:visitId/notes')

  const onSave = async () => {
    await updateVisitNote.mutateAsync({
      params: {
        patientId,
        visitId,
      },
      data: {
        type: visitNoteType,
        content: {
          discussed_discharge: form.values.discussed_discharge,
          is_discharge_visit: form.values.is_discharge_visit,
        },
      },
    })

    // Refetch notes
    void notes.refetch()
    // Refetch notes from old query client (TODO convert to useEmrQuery)
    void queryClient.invalidateQueries('visitNotesApi.getVisitNote')

    onClose()
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>Discharge Notes</DrawerHeader>
      <DrawerContent>
        <Stack spacing='md' p='md'>
          <DischargeAlert patient={patientQuery.data} />
          <YesOrNoRadio
            label='Did you discuss discharge with patient'
            {...form.getInputProps('discussed_discharge')}
            isEditing={isEditing}
          />
          <YesOrNoRadio
            label={`Is this the patient's discharge visit`}
            {...form.getInputProps('is_discharge_visit')}
            isEditing={isEditing}
          />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group spacing='md' position='right'>
          <SecondaryButton onClick={onClose} disabled={isVisitNoteLocked}>
            Finish later
          </SecondaryButton>
          <PrimaryButton
            onClick={onSave}
            loading={updateVisitNote.isLoading}
            disabled={isVisitNoteLocked}
            leftIcon={<SaveIcon />}
          >
            Save discharge notes
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const DischargeVisitDrawer = ({
  opened,
  ...props
}: DischargeVisitDrawerProps & { opened: boolean }) => {
  return (
    <BetterDrawer position='right' size='lg' opened={opened} onClose={props.onClose}>
      <DischargeVisitDrawerContent {...props} />
    </BetterDrawer>
  )
}
