import { EmrApi, OpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { emrApi } from '../../api'

export const useEmrQuery = <OpheliaRoute extends keyof EmrApi>(
  route: OpheliaRoute,
  config?: Partial<EmrApi[OpheliaRoute]['req']>,
  options?: Omit<
    UseQueryOptions<EmrApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<EmrApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>(
    ...emrApi.getQuery(route, config),
    options,
  )
}
