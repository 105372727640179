import {
  CheckCircleIcon,
  MessageCircleIcon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@shared/components'
import { useEmrMutation } from '../../../utils/hooks'
import { FormToSend } from './use-ordered-mbc-forms-to-send'

type SendMbcButtonProps = {
  patientId: string
  formToSend: FormToSend
}

export const SendMbcButton = ({ patientId, formToSend }: SendMbcButtonProps) => {
  const sendMbcFormLinkMutation = useEmrMutation('POST /patient/:patientId/send-mbc-link/:type')

  const { title, form, status } = formToSend

  if (sendMbcFormLinkMutation.isSuccess) {
    return (
      <TertiaryButton size='sm' leftIcon={<CheckCircleIcon />} disabled>
        {`Link sent for ${title}`}
      </TertiaryButton>
    )
  }

  const textLinkButtonProps = {
    size: 'sm' as const,
    leftIcon: <MessageCircleIcon />,
    onClick: () =>
      sendMbcFormLinkMutation.mutate({
        params: { patientId, type: form },
      }),
    loading: sendMbcFormLinkMutation.isLoading,
    disabled: sendMbcFormLinkMutation.isLoading,
  }

  const textLinkButtonText = `Text patient ${title}`

  if (status === 'primary') {
    return <PrimaryButton {...textLinkButtonProps}>{textLinkButtonText}</PrimaryButton>
  }

  return <SecondaryButton {...textLinkButtonProps}>{textLinkButtonText}</SecondaryButton>
}
