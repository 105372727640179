import { AlertCircleIcon, Group, Stack, Text } from '@shared/components'
import { ReactNode } from 'react'
import EmptyDataCell from '../../../../components/atoms/EmptyDataCell'

export type LabeledDataProps = {
  label: ReactNode
  data?: string | null
  formatText?: (text: string) => string
  hasError?: boolean
}
export const LabeledData = ({ label, data, formatText, hasError }: LabeledDataProps) => {
  return (
    <Stack spacing='xs'>
      <Group spacing='xs'>
        <Text bold>{label}</Text>
        {hasError ? <AlertCircleIcon color={colors => colors.error[0]} /> : null}
      </Group>
      {data ? (
        <Text style={{ whiteSpace: 'pre-wrap' }}>{formatText ? formatText(data) : data}</Text>
      ) : (
        <EmptyDataCell />
      )}
    </Stack>
  )
}
