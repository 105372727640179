import { ExternalLinkText, Group, Stack, TertiaryButton, Text, TitleFour } from '@shared/components'
import { DoseSpotPharmacy } from '@shared/types'
import { phone } from '@shared/utils'
import { ReactNode } from 'react'

export type PharmacySectionProps = {
  pharmacy?: DoseSpotPharmacy
  pharmacyUpdateOnClick: () => void
  error: ReactNode
}

const PharmacySection = ({ pharmacy, pharmacyUpdateOnClick, error }: PharmacySectionProps) => {
  return (
    <Stack>
      <Stack spacing={0}>
        <Group>
          <TitleFour>Pharmacy</TitleFour>{' '}
          <TertiaryButton onClick={pharmacyUpdateOnClick}>Update</TertiaryButton>
        </Group>
        {pharmacy ? (
          <>
            <Text>{pharmacy.StoreName}</Text>
            <Text>{pharmacy.Address1}</Text>
            {pharmacy.Address2 && <Text>{pharmacy.Address2}</Text>}
            <Text>
              {pharmacy.City}, {pharmacy.State} {pharmacy.ZipCode}
            </Text>
            <ExternalLinkText href={`tel:${pharmacy.PrimaryPhone}`}>
              <TertiaryButton>{phone(pharmacy.PrimaryPhone).arealessFormat}</TertiaryButton>
            </ExternalLinkText>
          </>
        ) : (
          <Text>
            No pharmacy found for patient. Please update the pharmacy information by clicking the
            Update button above.
          </Text>
        )}
        <Text bold color={({ error }) => error[0]}>
          {error}
        </Text>
      </Stack>
    </Stack>
  )
}

export default PharmacySection
