import { Box, Stack } from '@shared/components'
import { InsuranceReviewQueueItem, InsuranceType } from '@shared/types'
import IRQCardViewer from './IRQCardViewer'
import IRQInsuranceVerificationSection from './IRQInsuranceVerificationSection'
import IRQItemPersonal from './IRQItemPersonal'

export type IRQItemInsuranceProps = {
  item: InsuranceReviewQueueItem
  insuranceType: InsuranceType
  refreshQueue: () => void
}

const IRQItemData = ({ item, insuranceType, refreshQueue }: IRQItemInsuranceProps) => {
  const eligibleInsuranceData =
    insuranceType === 'primary' ? item.eligibleInsuranceData : item.secondaryEligibleInsuranceData

  const manualInsuranceData =
    insuranceType === 'primary' ? item.manualInsuranceData : item.secondaryManualInsuranceData

  const frontPhoto =
    insuranceType === 'primary'
      ? item.files.primaryInsuranceFront
      : item.files.secondaryInsuranceFront
  const backPhoto =
    insuranceType === 'primary'
      ? item.files.primaryInsuranceBack
      : item.files.secondaryInsuranceBack

  const insurance = insuranceType === 'primary' ? item.insurance : item.secondaryInsurance

  if (insurance) {
    insurance.insuranceCardFrontPhotoUrl = frontPhoto?.oid
    insurance.insuranceCardBackPhotoUrl = backPhoto?.oid
  }

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.other.colors.background[1],
        padding: theme.spacing.md,
      })}
    >
      {insurance && (
        <Stack>
          <IRQInsuranceVerificationSection
            item={item}
            insuranceType={insuranceType}
            refreshQueue={refreshQueue}
          />
          <IRQItemPersonal
            insurance={insurance}
            eligibleData={eligibleInsuranceData ?? null}
            manualData={manualInsuranceData ?? null}
            insuranceType={insuranceType}
          />
          <IRQCardViewer
            patientId={item.userId}
            frontFileId={insurance.insuranceCardFrontPhotoUrl}
            backFileId={insurance.insuranceCardBackPhotoUrl}
          />
        </Stack>
      )}
    </Box>
  )
}

export default IRQItemData
