import { useForm } from '@mantine/form'
import {
  ArrowLeftCircleIcon,
  Checkbox,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  LoadingOverlay,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  Textarea,
  TitleThree,
  UserCheckIcon,
  validateWith,
} from '@shared/components'
import { CaseReviewNote } from '@shared/types'
import { useQueryClient } from 'react-query'
import { isRequired } from '../../../utils/formValidation'
import { useEmrMutation, useInvalidateQuery } from '../../../utils/hooks'

export const CaseReviewAttestationContent = ({
  onClose,
  lockedBy,
  note,
  onBack,
}: {
  onClose: () => void
  lockedBy: string
  note: CaseReviewNote
  onBack: () => void
}) => {
  const queryClient = useQueryClient()
  const { getInputProps, values, validate, reset } = useForm<{
    attestationComments: string
    confirmation: string
  }>({
    validate: {
      confirmation: validateWith(isRequired),
    },
  })

  const attestCaseReviewNote = useEmrMutation('POST /caseReviewNote/:caseReviewNoteId/attestation')
  const invalidateQuery = useInvalidateQuery()

  const onSubmit = async () => {
    if (validate().hasErrors) {
      return
    }

    await attestCaseReviewNote.mutateAsync(
      {
        params: { caseReviewNoteId: note.oid },
        data: {
          comments: values.attestationComments,
        },
      },
      {
        onSuccess: () => {
          if (note?.visitId) {
            void invalidateQuery('GET /caseReviewNote/visit/:visitId', {
              params: {
                visitId: `${note.visitId}`,
              },
            })
          }

          void invalidateQuery('GET /caseReviewNote/patient/:patientId', {
            params: { patientId: note.patientId },
            query: { isLocked: 'true' },
          })

          void queryClient.invalidateQueries('GET /caseReview')
          void queryClient.invalidateQueries('GET /cocmRegistry')
        },
      },
    )

    reset()
    onClose()
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Group>
          <TertiaryButton leftIcon={<ArrowLeftCircleIcon styled />} onClick={onBack} />
          <TitleThree>Attest note</TitleThree>
        </Group>
      </DrawerHeader>
      <DrawerContent>
        <LoadingOverlay visible={attestCaseReviewNote.isLoading} />
        <Stack p='md'>
          <Text>
            The below treatment considerations and suggestions are based on consultations with the
            patient’s Behavioral Health Care Manager and a review of information available in the
            electronic medical record. I have not personally examined the patient. All
            recommendations should be implemented with consideration of the patient’s relevant prior
            history and current clinical status. Please feel free to call me with any questions
            about the care of this patient.
          </Text>
          <Textarea
            label='Additional comments'
            placeholder='Type here...'
            {...getInputProps('attestationComments')}
          />
          <Checkbox
            label={`I have reviewed ${lockedBy}’s note and agree with the assessment and plan, unless noted above`}
            {...getInputProps('confirmation')}
          />
          {attestCaseReviewNote?.isError && (
            <Text color='red'>Error attesting note: {attestCaseReviewNote?.error?.message}</Text>
          )}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton leftIcon={<UserCheckIcon />} onClick={onSubmit}>
            Attest note
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}
