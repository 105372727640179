import { AlertIcon, CheckCircleIcon, Divider, Loader, RadioGroup, Text } from '@shared/components'
import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteRadio } from '../visitNoteForm/PatientVisitNoteRadio'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

import {
  INSURANCE_ACTIVE_BENEFITS,
  INSURANCE_DETERMINATION,
  INSURANCE_LINE_OF_BUSINESS,
  INTENDED_PAYMENT_METHOD,
  InsurancePlanId,
  IntendedPaymentMethod,
  YesNo,
} from '@shared/types'
import { getPayerIdAndProvider, toTime } from '@shared/utils'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../../../api'
import { useInvalidateQuery } from '../../../../../utils/hooks'
import { usePatient } from '../../../PPatientContext'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitIssueButton } from '../visitNoteForm/PatientVisitIssueButton'
import { PatientVisitNoteAlert } from '../visitNoteForm/PatientVisitNoteAlert'
import { PatientVisitNoteInsuranceSelect } from '../visitNoteForm/PatientVisitNoteInsuranceSelect'
import { PatientVisitNoteRSButton } from '../visitNoteForm/PatientVisitNoteRSButton'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'

type InsuranceMessageStatus = 'empty' | 'changed' | 'loading' | 'error'

const InsuranceMessage = ({
  insuranceChangeStatus,
}: {
  insuranceChangeStatus: InsuranceMessageStatus
}) => {
  if (insuranceChangeStatus === 'loading') {
    return <Loader size='sm' />
  } else if (insuranceChangeStatus === 'changed') {
    return (
      <PatientVisitNoteAlert variant='success' icon={<CheckCircleIcon />}>
        Insurance saved to patient chart
      </PatientVisitNoteAlert>
    )
  } else if (insuranceChangeStatus === 'error') {
    return (
      <PatientVisitNoteAlert variant='error' icon={<AlertIcon />}>
        Error: could not save insurance to patient chart
      </PatientVisitNoteAlert>
    )
  }
  return null
}

export const InsuranceSection = ({ form }: { form: OnboardingVisitNoteForm }) => {
  const [insuranceChangeStatus, setInsuranceChangeStatus] =
    useState<InsuranceMessageStatus>('empty')

  const { patientId } = usePatientVisitNote()

  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const invalidate = useInvalidateQuery()

  const submitInsuranceDataMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/insurance/update'),
    {},
  )

  const updatePatientMutation = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const save = async ({
    hasInsurance,
    intendedPaymentMethod,
    insuranceProvider,
    hasMedicaid,
  }: {
    hasInsurance?: boolean
    intendedPaymentMethod?: IntendedPaymentMethod
    insuranceProvider?: string | null | undefined
    hasMedicaid?: boolean
  }) => {
    setInsuranceChangeStatus('loading')

    try {
      const { eligiblePayerId, provider } = getPayerIdAndProvider(
        (insuranceProvider as InsurancePlanId) ?? '',
      )

      if (insuranceProvider !== undefined || hasMedicaid !== undefined) {
        await submitInsuranceDataMutation.mutateAsync({
          params: { patientId },
          data: {
            insuranceType: 'primary',
            ...(insuranceProvider === undefined ? {} : { provider, eligiblePayerId }),
            ...(hasMedicaid === undefined ? {} : { planType: hasMedicaid ? 'MC' : null }),
          },
        })
      }

      if (hasInsurance !== undefined || intendedPaymentMethod !== undefined) {
        await updatePatientMutation.mutateAsync({
          params: { patientId },
          data: {
            insuranceData: {
              ...(hasInsurance === undefined ? {} : { hasInsurance }),
              ...(intendedPaymentMethod === undefined ? {} : { intendedPaymentMethod }),
            },
          },
        })
      }

      const primaryInsuranceId = patient?.insuranceData?.primaryInsuranceId

      if (primaryInsuranceId) {
        void invalidate('GET /patient/:patientId/insurance/:insuranceId', {
          params: {
            patientId,
            insuranceId: primaryInsuranceId,
          },
        })
      }
      await invalidate('GET /patient/:patientId')
      await invalidate('GET /patient/:patientId/insurances')
      await invalidate('GET /patient/:patientId/tasks')
    } catch (error) {
      setInsuranceChangeStatus('error')
    } finally {
      setTimeout(() => {
        if ((insuranceProvider && insuranceProvider.length > 0) || hasMedicaid) {
          setInsuranceChangeStatus('changed')
        } else {
          setInsuranceChangeStatus('empty')
        }
      }, toTime('0.5 sec').ms())
    }
  }

  const saveHasInsurance = async (hasInsuranceValue: YesNo) => {
    form.getInputProps('has_insurance').onChange(hasInsuranceValue)

    const hasInsurance = hasInsuranceValue === 'yes'

    if (hasInsurance) {
      await save({
        hasInsurance,
        intendedPaymentMethod: form.values.insurance_payment_method,
        insuranceProvider: form.values.insurance_provider,
        hasMedicaid: form.values.insurance_line_of_business === 'Medicaid',
      })
    } else {
      await save({
        hasInsurance,
        intendedPaymentMethod: 'Cash',
        insuranceProvider: '',
        hasMedicaid: false,
      })
    }
  }

  const saveIntendedPaymentMethod = async (IntendedPaymentMethodValue: IntendedPaymentMethod) => {
    form.getInputProps('insurance_payment_method').onChange(IntendedPaymentMethodValue)

    await save({ intendedPaymentMethod: IntendedPaymentMethodValue })
  }

  const saveInsuranceLineOfBusiness = async (insuranceLineOfBusinessValue: string | null) => {
    // In addition to onChange from props, save incoming has_medicaid option with current insurance_provider
    form.getInputProps('insurance_line_of_business').onChange(insuranceLineOfBusinessValue)

    await save({ hasMedicaid: insuranceLineOfBusinessValue === 'Medicaid' })
  }

  const saveInsuranceProvider = async (insuranceProviderValue: string | null) => {
    // In addition to onChange from props, save incoming insurance_provider with current has_medicaid option
    form.getInputProps('insurance_provider').onChange(insuranceProviderValue)

    await save({ insuranceProvider: insuranceProviderValue || '' })
  }

  const hasInsurance = form.values.has_insurance === 'yes'
  const isInNYOrPA =
    patient?.homeData?.state === 'Pennsylvania' || patient?.homeData?.state === 'New York'
  const hasMedicaid = form.values.insurance_line_of_business === 'Medicaid'
  const isOutOfNetwork = form.values.insurance_determination === 'Out of network'
  const shouldShowMCOTransferButton = isInNYOrPA && hasInsurance && hasMedicaid && isOutOfNetwork

  const medicaidEventData = {
    state: patient?.homeData?.state,
    hasInsurance,
  }

  return (
    <PatientVisitNoteSection title='Insurance'>
      <RadioGroup
        label='Does the patient have insurance?'
        orientation='horizontal'
        {...form?.getInputProps('has_insurance')}
        onChange={(value: YesNo) => saveHasInsurance(value)}
      >
        <PatientVisitNoteRadio value='yes' label='Yes' />
        <PatientVisitNoteRadio value='no' label='No' />
      </RadioGroup>
      <InsuranceMessage insuranceChangeStatus={insuranceChangeStatus} />
      {form.values.has_insurance === 'yes' && (
        <>
          <PatientVisitNoteInsuranceSelect
            patientId={patientId}
            label='Insurance provider'
            placeholder='Insurance provider'
            {...form?.getInputProps('insurance_provider')}
            onChange={value => saveInsuranceProvider(value)}
          />
          <PatientVisitNoteSelect
            label='Line of business (optional)...'
            placeholder='Line of business'
            data={INSURANCE_LINE_OF_BUSINESS}
            {...form?.getInputProps('insurance_line_of_business')}
            onChange={value => saveInsuranceLineOfBusiness(value)}
          />
          <PatientVisitNoteSelect
            label='Active benefits? (optional)...'
            placeholder='Active benefits?'
            data={INSURANCE_ACTIVE_BENEFITS}
            {...form?.getInputProps('insurance_active_benefits')}
          />
          <PatientVisitNoteSelect
            label='Insurance determination'
            placeholder='Insurance determination'
            data={INSURANCE_DETERMINATION}
            {...form?.getInputProps('insurance_determination')}
          />
          <RadioGroup
            label='Enrollment billing method'
            orientation='horizontal'
            {...form?.getInputProps('insurance_payment_method')}
            onChange={(value: IntendedPaymentMethod) => saveIntendedPaymentMethod(value)}
          >
            {INTENDED_PAYMENT_METHOD.map(paymentMethod => (
              <PatientVisitNoteRadio
                key={paymentMethod}
                label={paymentMethod}
                value={paymentMethod}
              />
            ))}
          </RadioGroup>
        </>
      )}

      <PatientVisitNoteTextInput
        label='Other relevant information (optional)'
        placeholder='Type here...'
        {...form?.getInputProps('has_insurance_additional_details')}
      />

      <PatientVisitNoteNotDiscussedCheckbox field='has_insurance' form={form} />
      <PatientVisitIssueButton issueName='Insurance verification' issueType='verification' />

      {shouldShowMCOTransferButton && (
        <>
          <Divider />
          <Text>Patient is eligible for MCO transfer</Text>
          <PatientVisitNoteRSButton
            label='Send MCO transfer text'
            event='Send MCO Transfer Information'
            additionalData={medicaidEventData}
          />
        </>
      )}

      {!hasInsurance && (
        <>
          <Divider />
          <Text>Please review household income chart to determine Medicaid eligibility</Text>
          <PatientVisitNoteRSButton
            label='Send Medicaid information'
            event='Send MCO Transfer Information'
            additionalData={medicaidEventData}
          />
        </>
      )}
    </PatientVisitNoteSection>
  )
}
