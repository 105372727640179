import { Box, Pill, PlusIcon, SecondaryButton, Stack } from '@shared/components'
import { usePatient } from '../PPatientContext'
import { SectionHeader } from './SectionHeader'

export type FilesEmptyStateProps = {
  pillText: string
  buttonText: string
  displayHeader?: boolean
  headerTitle?: string
}

export const FilesEmptyState = ({
  displayHeader = true,
  headerTitle,
  pillText,
  buttonText,
}: FilesEmptyStateProps) => {
  const { setModal } = usePatient()
  return (
    <Stack>
      {displayHeader && <SectionHeader sectionTitle={headerTitle ?? ''} button={null} />}
      <Box
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
        })}
        p='lg'
      >
        <Stack align='center'>
          <Pill variant='filled' status='none'>
            {pillText}
          </Pill>
          <SecondaryButton
            onClick={() => setModal({ type: 'upload-file' })}
            leftIcon={<PlusIcon />}
          >
            {buttonText}
          </SecondaryButton>
        </Stack>
      </Box>
    </Stack>
  )
}
