import {
  AlertIcon,
  Center,
  CheckCircleIcon,
  ClockIcon,
  DownloadIcon,
  EyeIcon,
  FileIcon,
  Group,
  HelpCircleIcon,
  Menu,
  Modal,
  MoreVerticalIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Table,
  Text,
  Th,
  Tooltip,
  XIcon,
} from '@shared/components'
import { PatientReleaseOfInformation, ReleaseOfInformationStatus } from '@shared/types'
import { IANAZone, dayjs } from '@shared/utils'
import times from 'lodash/times'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi, patientsApi, releaseOfInformationApi, storageApi } from '../../../../api'
import LoadingRow from '../../../../components/atoms/LoadingRow'
import { usePatient } from '../../PPatientContext'
import { FilesEmptyState } from '../FilesEmptyState'
import { SectionHeader } from '../SectionHeader'
import { ReleaseOfInformationDrawer } from './ReleaseOfInformationDrawer'

type DisplayReleaseOfInformationStatus =
  | ReleaseOfInformationStatus
  | 'Legacy ROI - review before use'

const statusToIconMap = {
  Active: [CheckCircleIcon, 'success', 0],
  'Under review': [HelpCircleIcon, 'warning', 0],
  'Needs changes': [ClockIcon, 'actions', 2],
  Expired: [AlertIcon, 'error', 0],
  Revoked: [AlertIcon, 'error', 0],
  'In progress': [ClockIcon, 'actions', 2],
  'Legacy ROI - review before use': [HelpCircleIcon, 'warning', 0],
} as const

type ReleaseOfInformationRowProps = {
  date: string
  createdBy?: string | undefined
  status: DisplayReleaseOfInformationStatus
  description: string
  handleDownload?: () => void
  handleOpen?: () => void
  handleReview?: () => void
  handleRevoke?: () => void
}

const ReleaseOfInformationRow = ({
  date,
  createdBy,
  status,
  description,
  handleDownload,
  handleOpen,
  handleReview,
  handleRevoke,
}: ReleaseOfInformationRowProps): JSX.Element => {
  const employeeQuery = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: { employeeId: createdBy ?? '' },
    }),
    {
      enabled: Boolean(createdBy),
    },
  )

  const employee = employeeQuery?.data

  const [StatusIcon, colorKey, colorIndex] = statusToIconMap[status]

  return (
    <tr className='mantine'>
      <td className='mantine'>
        <Group spacing='sm' noWrap>
          <Text size='xs'>{dayjs(date).format('MM/DD/YYYY')}</Text>
          <Tooltip
            label={
              <Text bold size='xs' color='white' p='xs'>
                {dayjs(date).tz(IANAZone.Eastern).format('h:mma z')}
              </Text>
            }
          >
            <ClockIcon color={colors => colors.actions[0]} />
          </Tooltip>
        </Group>
      </td>
      <td className='mantine'>
        <Text size='xs' style={{ whiteSpace: 'nowrap' }}>
          {employee?.name ? employee.name : 'Patient'}
        </Text>
      </td>
      <td className='mantine'>
        <Group spacing='xs' noWrap>
          <StatusIcon color={colors => colors[colorKey][colorIndex]} />
          {status}
        </Group>
      </td>
      <td className='mantine'>
        <Text size='xs' lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
          {description}
        </Text>
      </td>
      <td>
        <Menu position='bottom-end'>
          <Center>
            <Menu.Target>
              <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
            </Menu.Target>
          </Center>
          <Menu.Dropdown>
            {status !== 'Under review' && status !== 'Needs changes' && handleOpen && (
              <Menu.Item
                onClick={() => handleOpen()}
                icon={<FileIcon color={colors => colors.actions[0]} />}
              >
                Preview
              </Menu.Item>
            )}
            {status !== 'Needs changes' && status !== 'Under review' && handleDownload && (
              <Menu.Item
                onClick={() => handleDownload()}
                icon={<DownloadIcon color={colors => colors.actions[0]} />}
              >
                Download
              </Menu.Item>
            )}
            {(status === 'Under review' || status === 'Needs changes') && handleReview && (
              <Menu.Item
                onClick={() => handleReview()}
                icon={<EyeIcon color={colors => colors.actions[0]} />}
              >
                Review ROI
              </Menu.Item>
            )}
            {status === 'Active' && handleRevoke && (
              <Menu.Item
                onClick={() => handleRevoke()}
                icon={<XIcon color={colors => colors.actions[0]} />}
              >
                Revoke
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  )
}

export const ReleaseOfInformationTable = (): JSX.Element => {
  const { patientId } = usePatient()

  const roiQuery = useQuery(
    [releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY, patientId],
    () => releaseOfInformationApi.getAllReleasesOfInformation(patientId),
  )
  const rois = roiQuery?.data
  const hasRois = rois && rois.length > 0

  const [roiFilesQueryKey, roiFilesQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/files',
    {
      params: { patientId },
      query: { category: 'ROI' },
    },
  )

  // Older ROI files are only in the patient files subcollection
  const roiFilesQuery = useQuery(roiFilesQueryKey, roiFilesQueryFunction, {
    enabled: Boolean(patientId),
  })
  const roiFiles = roiFilesQuery?.data
  const hasRoiFiles = roiFiles && Object.entries(roiFiles).length > 0

  const isLoading = roiFilesQuery.isLoading || roiQuery.isLoading

  const openFile = useMutation(
    async (fileId: string) => {
      const file = await patientsApi.getFile({ patientId, fileId })
      return storageApi.getFile({ url: file.signedUrl, fileName: file.name })
    },
    {
      onSuccess: file => {
        window.open(URL.createObjectURL(file), '_blank')
        openFile.reset()
      },
    },
  )

  const downloadFile = useMutation(patientsApi.getFile, {
    onSuccess: file => {
      /*
       * Opening the signed URL downloads the file
       * window.open(file.signedUrl, '_self')
       */
      window.open(file.signedUrl, '_self')
      downloadFile.reset()
    },
  })

  const [roiToReview, setRoiToReview] = useState<PatientReleaseOfInformation | undefined>()
  const [roiToRevoke, setRoiToRevoke] = useState<string>()

  const queryClient = useQueryClient()
  const { isLoading: isRevoking, mutate: revokeRoi } = useMutation(
    (id: string) =>
      releaseOfInformationApi.updateReleaseOfInformation({
        patientId,
        roiId: id,
        data: {
          status: 'Revoked',
          revokedOnDate: new Date().toISOString(),
        },
      }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([
          releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY,
          patientId,
        ])
        setRoiToRevoke(undefined)
      },
    },
  )

  // New ROIs' `createdBy` value will be "AUTOGENERATED" and shouldn't show up with old ROIs
  const autoGeneratedRois = Object.entries(roiFiles ?? {}).filter(
    ([, file]) => file.createdBy !== 'AUTOGENERATED',
  )

  const hasData = hasRois || hasRoiFiles

  return (
    <>
      {roiToRevoke && (
        <Modal
          opened={Boolean(roiToRevoke)}
          onClose={() => setRoiToRevoke(undefined)}
          title='Revoke release of information'
          footer={
            <Stack>
              <SecondaryButton fullWidth onClick={() => setRoiToRevoke(undefined)}>
                No, don&apos;t revoke
              </SecondaryButton>
              <PrimaryButton
                fullWidth
                loading={isRevoking}
                disabled={isRevoking}
                onClick={() => revokeRoi(roiToRevoke)}
              >
                Yes, revoke
              </PrimaryButton>
            </Stack>
          }
        >
          <Text>
            By revoking this document, Ophelia will no longer share your protected health
            information with the person or organization stated in the document. Are you sure you
            want to proceed?
          </Text>
        </Modal>
      )}
      {roiToReview && (
        <ReleaseOfInformationDrawer
          opened={Boolean(roiToReview)}
          onClose={() => setRoiToReview(undefined)}
          roi={roiToReview}
        />
      )}

      {!isLoading && !hasData && (
        <FilesEmptyState
          headerTitle='Release of information (ROIs)'
          pillText='No ROIs uploaded yet'
          buttonText='Upload ROI'
          displayHeader
        />
      )}

      {(hasData || isLoading) && (
        <Stack mt='md'>
          <SectionHeader sectionTitle='Release of information (ROIs)' />
          <Table
            striped
            withBorder
            verticalSpacing='sm'
            sx={({ other: { colors, sizes } }) => ({
              tableLayout: 'fixed',
              backgroundColor: colors.background[0],
              borderWidth: sizes.border.md,
              borderStyle: 'solid',
              borderColor: colors.background[3],
            })}
          >
            <thead className='mantine'>
              <tr className='mantine'>
                <Th sortable={false} key='signedOnDate' style={{ width: `14ch` }}>
                  Signed on
                </Th>
                <Th sortable={false}>Uploaded by</Th>
                <Th sortable={false}>Status</Th>
                <Th sortable={false}>Description</Th>
                <Th sortable={false} style={{ width: `7ch` }}>
                  Action
                </Th>
              </tr>
            </thead>

            <tbody className='mantine'>
              {isLoading &&
                /* eslint-disable-next-line no-magic-numbers */
                times(8, index => <LoadingRow key={index} headersLength={5} />)}
              {!isLoading &&
                autoGeneratedRois.map(([fileId, file]) => (
                  <ReleaseOfInformationRow
                    createdBy={file.createdBy}
                    key={fileId}
                    date={file.createdAt ?? ''}
                    status='Legacy ROI - review before use'
                    description={file.name}
                    handleOpen={() => openFile.mutate(fileId)}
                    handleDownload={() => fileId && downloadFile.mutate({ patientId, fileId })}
                  />
                ))}

              {!isLoading &&
                rois
                  // In progress ROIs haven't been submitted yet
                  ?.filter(roi => roi.status !== 'In progress')
                  ?.map(roi => {
                    return (
                      <ReleaseOfInformationRow
                        key={roi.oid}
                        date={roi.signedOnDate}
                        status={roi.status}
                        description={`${roi.recipient} | Expire${
                          dayjs(roi.expirationDate).isBefore(dayjs()) ? 'd' : 's'
                        } ${dayjs(roi.expirationDate).format('MM/DD/YYYY')}`}
                        handleReview={() => setRoiToReview(roi)}
                        handleOpen={() => roi.fileId && openFile.mutate(roi.fileId)}
                        handleDownload={() =>
                          roi.fileId && downloadFile.mutate({ patientId, fileId: roi.fileId })
                        }
                        handleRevoke={() => setRoiToRevoke(roi.oid)}
                      />
                    )
                  })}
            </tbody>
          </Table>
        </Stack>
      )}
    </>
  )
}
