import { AppointmentTypeString, PatientStatus, hasGroupRole } from '@shared/types'
import { useQuery } from 'react-query'
import { appointmentsApi } from '../../api'
import { useAuth } from '../../context/auth'
import { useEmrQuery, useFlags } from '../../utils/hooks'
import { mapIdToName } from '../../utils/utils'

export type AlertEntry = {
  title: string
  description: string
}

export type VisitTypeEntry = {
  label: string
  value: string
  alert?: AlertEntry
}

export type UseVisitTypesParams = {
  patientId: string
  visitTypesAllowed?: AppointmentTypeString | AppointmentTypeString[] | null
}

export type UseVisitTypesReturn = {
  visitTypes: VisitTypeEntry[]
  isLoading: boolean
}

export const useVisitTypes = ({
  patientId,
  visitTypesAllowed,
}: UseVisitTypesParams): UseVisitTypesReturn => {
  const { currentUser } = useAuth()
  const { unconfirmedAppointmentsPage } = useFlags()

  const patientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId,
      },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const patient = patientQuery.data
  const firstName = patient?.personalData.firstName

  const appointmentTypesQuery = useQuery(['appointmentsApi.types'], appointmentsApi.types, {
    enabled: Boolean(patientId),
  })
  const appointmentTypes = appointmentTypesQuery.data

  const eligibleEmployeesQuery = useEmrQuery(
    'GET /patients/:patientId/ccmMatching',
    {
      params: { patientId },
      /*
       * Note: we added `shouldFilterByInNetworkStatus` because we use 1 function on the backend to
       * get a list of eligible CCMs for many contexts (scheduling drawer, assigning CCMs to patients, etc.).
       * In some contexts, we want to filter by in-network status, and in others (like this one), we don't.
       */
      query: { shouldFilterByInNetworkStatus: 'no' },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const isLoading =
    appointmentTypesQuery.isLoading || patientQuery.isLoading || eligibleEmployeesQuery.isLoading

  // Satisfy the type checker
  const dataExists = patient && appointmentTypes?.followUpTypes

  const visitTypes: VisitTypeEntry[] = []

  const isVisitTypeAllowed = ({
    appointmentTypeString,
  }: {
    appointmentTypeString: AppointmentTypeString
  }) => {
    // If no allowed visit types are specified, allow all visit types
    if (!visitTypesAllowed) {
      return true
    }

    if (typeof visitTypesAllowed === 'string') {
      if (visitTypesAllowed === appointmentTypeString) {
        return true
      }
    }

    return visitTypesAllowed.includes(appointmentTypeString)
  }

  if (!isLoading && dataExists) {
    const state: string = patient?.homeData?.state ?? ''
    const stateAbbreviation = appointmentTypes.stateNameToAbbr[state] ?? ''
    const intakeVisitId = appointmentTypes.initialVisitTypes[stateAbbreviation]
    let array: number[] = isVisitTypeAllowed({ appointmentTypeString: 'Follow-Up Visit' })
      ? appointmentTypes.followUpTypes.map(type => type.id)
      : []

    if (isVisitTypeAllowed({ appointmentTypeString: 'Check-In Call' })) {
      array.push(appointmentTypes.CHECK_IN_CALL_VISIT_ID)
    }
    if (isVisitTypeAllowed({ appointmentTypeString: 'UDS Visit' })) {
      array.push(appointmentTypes.UDS_VISIT_ID)
    }
    if (
      intakeVisitId &&
      hasGroupRole(currentUser, 'admin', 'enrollmentCoordinator') &&
      isVisitTypeAllowed({ appointmentTypeString: 'Initial Visit' })
    ) {
      array.push(intakeVisitId)
    }

    // We want these two appointments to be at the end
    array = array.filter(
      id =>
        ![
          appointmentTypes.DOUBLE_BOOKING_FOLLOWUP_VISIT_ID,
          appointmentTypes.STAGGERING_FOLLOWUP_VISIT_ID,
        ].includes(id),
    )

    if (
      unconfirmedAppointmentsPage &&
      isVisitTypeAllowed({ appointmentTypeString: 'Follow-Up Visit' })
    ) {
      array.unshift(appointmentTypes.STAGGERING_FOLLOWUP_VISIT_ID)
      array.unshift(appointmentTypes.DOUBLE_BOOKING_FOLLOWUP_VISIT_ID)
    }

    const eligibleForConsultationStatuses: PatientStatus[] = ['lead', 'candidate']
    if (
      patient &&
      eligibleForConsultationStatuses.includes(patient.statuses.patient) &&
      isVisitTypeAllowed({ appointmentTypeString: 'Free Consultation Call' })
    ) {
      array.push(appointmentTypes.CONSULTATION_VISIT_ID)
    }

    const eligibleForReenrollmentStatuses: PatientStatus[] = ['discharged']
    if (patient && eligibleForReenrollmentStatuses.includes(patient.statuses.patient)) {
      array.push(appointmentTypes.REENROLLMENT_VISIT_ID)
    }

    const eligibleForEnrollmentSupportStatuses: PatientStatus[] = ['lead', 'candidate']
    if (
      patient &&
      eligibleForEnrollmentSupportStatuses.includes(patient.statuses.patient) &&
      isVisitTypeAllowed({ appointmentTypeString: 'Enrollment Support Call' })
    ) {
      array.push(appointmentTypes.ENROLLMENT_SUPPORT_CALL_VISIT_ID)
    }

    for (const visitId of array) {
      const visitEntry: VisitTypeEntry = {
        value: String(visitId),
        label: mapIdToName(visitId, appointmentTypes),
      }

      if (visitId === appointmentTypes.DOUBLE_BOOKING_FOLLOWUP_VISIT_ID) {
        if (patient?.isEligibleForStaggering) {
          continue
        } else if (!patient?.isEligibleForStaggering && !patient?.isEligibleForDoubleBooking) {
          visitEntry.alert = {
            title: `Help improve clinical utilization`,
            description: `Select 'Follow-up visit (20 min, double booked)' to double book ${firstName}
                with another patient (they will need to confirm their visit or else they
                will be re-scheduled). Select 'Follow-up visit (20 min, staggered)' to stagger their visit with
                another patient's visit.`,
          }
        } else if (patient?.isEligibleForDoubleBooking || patient?.isEligibleForStaggering) {
          visitEntry.alert = {
            title: `${firstName} has a history of late cancels/no-shows`,
            description: `Select 'Follow-up visit (20 min, double booked)' to double book them
              with another patient. The patient will need to confirm their visit or else they
              will be re-scheduled.`,
          }
        }
      } else if (visitId === appointmentTypes.STAGGERING_FOLLOWUP_VISIT_ID) {
        if (patient?.isEligibleForDoubleBooking) {
          continue
        } else if (patient?.isEligibleForStaggering) {
          visitEntry.alert = {
            title: `${firstName} has a history of late cancels/no-shows`,
            description: `Select 'Follow-up visit (20 min, staggered)' to stagger their visit with
                    another patient's visit.`,
          }
        }
      }

      visitTypes.push(visitEntry)
    }
  }

  return {
    visitTypes,
    isLoading,
  }
}
