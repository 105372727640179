import { Modal, validateWith } from '@shared/components'

import { useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { isRequired } from '../../../../../utils/formValidation'
import { QuickMergeArchiveStep } from './QuickMergeArchiveStep'
import { QuickMergeFormStep } from './QuickMergeFormStep'
import { QuickMergeStatusStep } from './QuickMergeStatusStep'

export type QuickMergeStep = 'merge-form' | 'merge-status' | 'archive'
type QuickMergeStepFields = {
  step: QuickMergeStep
  toPatientId?: string
}

type MergeStatusModalProps = {
  opened: boolean
  onClose: () => void
  fromPatientId: string
  step: QuickMergeStep
}

export type QuickMergeForm = {
  toPatientId: string
}

export const QuickMergeModal = ({
  opened,
  onClose,
  fromPatientId,
  step,
}: MergeStatusModalProps) => {
  const [currentStep, setCurrentStep] = useState<QuickMergeStepFields>({
    step,
    toPatientId: '',
  })

  useEffect(() => setCurrentStep({ step: 'merge-form' }), [opened])

  const form = useForm<QuickMergeForm>({
    initialValues: {
      toPatientId: '',
    },
    validate: {
      toPatientId: validateWith(isRequired),
    },
  })

  const onFormSuccess = (id: string) => {
    setCurrentStep({ step: 'merge-status', toPatientId: id.trim() })
  }

  return (
    <Modal title='Quick merge' opened={opened} onClose={onClose} p='sm' closeOnClickOutside={false}>
      {currentStep.step === 'merge-form' && (
        <QuickMergeFormStep
          form={form}
          fromPatientId={fromPatientId}
          onClose={onClose}
          onFormSuccess={onFormSuccess}
        />
      )}
      {currentStep.step === 'merge-status' && currentStep.toPatientId && (
        <QuickMergeStatusStep
          fromPatientId={fromPatientId}
          toPatientId={currentStep.toPatientId}
          onClose={onClose}
          onBack={() => setCurrentStep({ step: 'merge-form' })}
          onNext={() => setCurrentStep({ step: 'archive', toPatientId: currentStep.toPatientId })}
        />
      )}
      {currentStep.step === 'archive' && currentStep.toPatientId && (
        <QuickMergeArchiveStep
          fromPatientId={fromPatientId}
          toPatientId={currentStep.toPatientId}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}
