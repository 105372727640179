import {
  Box,
  ClockIcon,
  Group,
  LinkText,
  SecondaryButton,
  Stack,
  Text,
  Tooltip,
  TrashIcon,
  UserIcon,
  useMantineTheme,
} from '@shared/components'
import { ActionItem, ActionItemType } from '@shared/types'
import { dayjs } from '@shared/utils'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { actionItemsApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { getActionItemLink } from '../../../utils/actionItems'

type MActionItemProps = {
  actionItem: ActionItem
  refetch: () => void
}

const MActionItem = ({ actionItem, refetch }: MActionItemProps) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const isDateWithinOneDay = (date: string) => {
    const formattedDate = dayjs(date)
    return formattedDate.isToday() || formattedDate.isTomorrow() || formattedDate.isYesterday()
  }

  const dateIsWithinOneDayText = (date: string) => {
    const formattedDate = dayjs(date)
    if (formattedDate.isToday()) {
      return 'Today'
    } else if (formattedDate.isYesterday()) {
      return 'Yesterday'
    } else if (formattedDate.isTomorrow()) {
      return 'Tomorrow'
    }
    return date
  }

  const formatDueDateInCard = (date: string) => {
    return isDateWithinOneDay(date) ? dateIsWithinOneDayText(date) : dayjs(date).format('MMM D')
  }

  const formatDueDateInTooltip = (date: string) => {
    return isDateWithinOneDay(date) ? dateIsWithinOneDayText(date) : dayjs(date).format('MMMM Do')
  }

  const formatAssignedByDate = (date: string) => {
    return isDateWithinOneDay(date)
      ? dateIsWithinOneDayText(date)
      : `on ${dayjs(date).format('MMMM Do')}`
  }

  const actionIsOverdue = (date: string) => {
    const today = dayjs().startOf('day')
    return dayjs(date).isBefore(today)
  }

  const clockIconColor = (date: string) => {
    return actionIsOverdue(date) ? colors.error[0] : colors.text[1]
  }

  const dueDateTextColor = (date: string) => {
    return actionIsOverdue(date) ? colors.error[0] : colors.text[1]
  }

  const deleteActionItem = useMutation(actionItemsApi.deleteActionItem)

  const ignoreActionItem = async (event: React.MouseEvent) => {
    event.stopPropagation()
    await deleteActionItem.mutateAsync({
      actionItemId: actionItem.oid,
      employeeId: currentUser.oid,
    })
    refetch()
  }

  const navigateToPage = (url: string, event: React.MouseEvent) => {
    event.stopPropagation()
    navigate(url)
  }

  const allowActionItemDeletion =
    location.pathname.includes('/employees') ||
    actionItem.type === ActionItemType.SchedulePostInTakeCheckInAppointment ||
    actionItem.type === ActionItemType.ScheduleFollowUpAppointment ||
    // allowing sendPrescription to be deleted by the clinician for now, since a script sent in DS won't have a closed AI
    actionItem.type === ActionItemType.SendPrescription

  const dueAtTooltipText = () => {
    const dueAtDate = dayjs(actionItem.dueAt)
    const formattedDueAtText = formatDueDateInTooltip(actionItem.dueAt)
    return `Due by ${dueAtDate.format('h:mma z')} ${
      isDateWithinOneDay(actionItem.dueAt)
        ? formattedDueAtText.toLowerCase()
        : `on ${formattedDueAtText}`
    }`
  }

  const assignedByTooltipText = () => {
    const assignmentDate = dayjs(actionItem.createdAt)
    return `Assigned by ${actionItem.createdByName} at ${assignmentDate.format(
      'h:mma z',
    )} ${formatAssignedByDate(actionItem.createdAt)}`
  }

  return (
    <Box
      onClick={(event: React.MouseEvent) => navigateToPage(getActionItemLink(actionItem), event)}
      sx={{
        backgroundColor: colors.background[0],
        paddingLeft: sizes.gap.xl,
        paddingRight: sizes.gap.xl,
        paddingTop: sizes.padding.lg,
        paddingBottom: sizes.padding.lg,
        cursor: 'pointer',
        '&:hover': { backgroundColor: colors.background[2] },
      }}
    >
      <Group position='apart'>
        <Stack spacing='sm'>
          {actionItem.patientName && (
            <LinkText
              size='xs'
              onClick={(event: React.MouseEvent) =>
                navigateToPage(`/patients/${actionItem.patientId}`, event)
              }
              color={colors.text[1]}
            >
              {actionItem.patientName}
            </LinkText>
          )}
          <Group spacing='xs'>
            {actionItem.title && <Text color={colors.text[0]}>{actionItem.title}</Text>}
          </Group>
          <Group>
            {actionItem.dueAt && (
              <Tooltip label={dueAtTooltipText()} color={colors.background[6]}>
                <Group spacing='xs'>
                  <ClockIcon color={clockIconColor(actionItem.dueAt)} size='sm' />
                  <Text size='xs' color={dueDateTextColor(actionItem.dueAt)}>
                    {formatDueDateInCard(actionItem.dueAt)}
                  </Text>
                </Group>
              </Tooltip>
            )}
            {actionItem.createdByName && (
              <Tooltip label={assignedByTooltipText()} color={colors.background[6]}>
                <Group spacing='xs'>
                  <UserIcon color={colors.text[1]} size='sm' />
                  <Text size='xs' color={colors.text[1]}>
                    {actionItem.createdByName}
                  </Text>
                </Group>
              </Tooltip>
            )}
          </Group>
        </Stack>
        <Group spacing='lg'>
          {allowActionItemDeletion && (
            <Tooltip label='Delete'>
              <SecondaryButton
                radius='xl'
                size='sm'
                onClick={ignoreActionItem}
                leftIcon={<TrashIcon color={colors.actions[1]} />}
              />
            </Tooltip>
          )}
        </Group>
      </Group>
    </Box>
  )
}

export default MActionItem
