import {
  Alert,
  ClockIcon,
  Group,
  MantineColor,
  RepeatIcon,
  SlashIcon,
  Text,
  Timeline,
  UserIcon,
  useMantineTheme,
} from '@shared/components'
import { PatientStatus } from '@shared/types'
import { dayjs } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import { ReactNode } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { JourneyItemContent, JourneyItemType } from '../PPatientContext'
import { journeyLabels } from './PatientJourneyContext'
import { AppointmentContent } from './content/AppointmentContent'
import { CaseReviewContent } from './content/CaseReviewContent'
import { DrugScreenContent } from './content/DrugScreenContent'
import { IneligibleNoteContent } from './content/IneligibleNoteContent'
import { NonVisitEventContent } from './content/NonVisitEventContent'
import { PrescriptionContent } from './content/PrescriptionContent'
import { FieldSkeleton } from './content/SharedContent'
import { TaskContent } from './content/TaskContent'
import { UpcomingAppointmentContent } from './content/UpcomingAppointmentContent'
import { VisitHoldContent } from './content/VisitHoldContent'
import { DischargeNoteContent } from './content/dischargeNote/DischargeNoteContent'

type JourneyGroupType = 'year' | 'cards' | 'patient-status'

export type JourneyGroup<T extends JourneyGroupType = JourneyGroupType> = {
  id: string
  datetime: string
  type: T
  content: T extends 'cards'
    ? JourneyItem[]
    : T extends 'patient-status'
    ? JourneyItemContent<'patient-status'>
    : undefined
  status: PatientStatus | undefined
}

export type JourneyItem<T extends JourneyItemType = JourneyItemType> = {
  id: string
  datetime: string
  type: T
  content: JourneyItemContent<T>
}

function isJourneyGroup<T extends JourneyGroupType>(
  item: JourneyGroup,
  type: T,
): item is JourneyGroup<T> {
  return item.type === type
}

function isJourneyItem<T extends JourneyItemType>(
  item: JourneyItem,
  type: T,
): item is JourneyItem<T> {
  return item.type === type
}

export const PatientJourneyContent = ({ item }: { item: JourneyItem }) => {
  if (isJourneyItem(item, 'appointment')) {
    const now = dayjs()
    const appointmentEndDatetime = dayjs(item.content.datetime).add(
      Number(item.content.duration),
      'minutes',
    )

    if (appointmentEndDatetime.isAfter(now) && !item.content.canceled) {
      return <UpcomingAppointmentContent appointment={item.content} />
    }

    return <AppointmentContent appointment={item.content} />
  }

  if (isJourneyItem(item, 'non-visit-event')) {
    return <NonVisitEventContent nonVisitEvent={item.content} />
  }

  if (isJourneyItem(item, 'case-review-note')) {
    return <CaseReviewContent caseReview={item.content} />
  }

  if (isJourneyItem(item, 'task')) {
    return <TaskContent task={item.content} />
  }

  if (isJourneyItem(item, 'visit-hold')) {
    return <VisitHoldContent visitHold={item.content} />
  }

  if (isJourneyItem(item, 'prescription')) {
    return <PrescriptionContent prescription={item.content} />
  }

  if (isJourneyItem(item, 'ineligible-note')) {
    return <IneligibleNoteContent ineligibleNote={item.content} />
  }

  if (isJourneyItem(item, 'discharge-note')) {
    return <DischargeNoteContent dischargeNote={item.content} />
  }

  if (isJourneyItem(item, 'drug-screen')) {
    return <DrugScreenContent drugScreen={item.content} />
  }

  return null
}

export const JourneyGroup = ({
  group,
  header,
  index,
}: {
  group: JourneyGroup
  index: number
  header?: ReactNode
}) => {
  const {
    other: { colors },
  } = useMantineTheme()

  if (group.type === 'year') {
    return (
      <Timeline.Item
        index={index}
        bullet={
          <Text size='xs' bold>
            {dayjs(group.datetime).format('YYYY')}
          </Text>
        }
        color={colors.background[3]}
        solidBullet
        fullWidthBullet
      />
    )
  }

  if (isJourneyGroup(group, 'patient-status')) {
    return <PatientJourneyStatus index={index} {...group} />
  }

  if (isJourneyGroup(group, 'cards')) {
    const color = getTimelineGroupColor(group.status)

    return (
      <Timeline.Item
        index={index}
        bullet={
          <Text size='xs' bold>
            {dayjs(group.datetime).format('MMM DD').toUpperCase()}
          </Text>
        }
        color={color}
        bulletTooltip={capitalize(group.status)}
        bulletFooter={
          <Text size='xs' color={color}>
            {dayjs(group.datetime).format('YYYY')}
          </Text>
        }
      >
        {header}
        {group.content.map(item => (
          <PatientJourneyContent key={`${item.type}-${item.id}`} item={item} />
        ))}
      </Timeline.Item>
    )
  }

  return null
}

export const PatientJourneyStatus = ({
  status,
  datetime,
  content,
  index,
}: JourneyGroup<'patient-status'> & { index: number }) => {
  const employeeQuery = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: { employeeId: content.changedById as string },
    }),
    { enabled: Boolean(content.changedById) },
  )

  const color = getTimelineGroupColor(status)

  const timelineDate: string = dayjs(datetime).format('MMM DD YYYY')

  return (
    <Timeline.Item
      index={index}
      bullet={
        <Group sx={({ other: { sizes } }) => ({ gap: sizes.padding.lg })}>
          <Text size='xs' bold>
            {timelineDate.toUpperCase()}
          </Text>
          <Group spacing='xs'>
            <RepeatIcon color={color} />
            <Text size='xs'>{capitalize(content.value)}</Text>
          </Group>
          {content.changedById && (
            <Group spacing='xs'>
              <UserIcon color={({ text }) => text[1]} />
              <FieldSkeleton isLoading={employeeQuery.isLoading} isError={employeeQuery.isError}>
                <Text color={({ text }) => text[1]} size='xs'>
                  {employeeQuery.data?.name ?? content.changedById}
                </Text>
              </FieldSkeleton>
            </Group>
          )}
          <Group spacing='xs'>
            <ClockIcon color={({ text }) => text[1]} />
            <Text color={({ text }) => text[1]} size='xs'>
              {dayjs(datetime).format('h:mma z')}
            </Text>
          </Group>
        </Group>
      }
      color={color}
    />
  )
}

export const PatientJourneyError = ({ errors }: { errors: JourneyItemType[] }) => {
  if (errors.length) {
    return (
      <Alert variant='error' icon={<SlashIcon />}>
        {capitalize(errors.length === 1 ? lowerCase(journeyLabels[errors[0]!]) : 'Multiple items')}{' '}
        failed to load. Refresh your browser to try again.
      </Alert>
    )
  }

  return null
}

function getTimelineGroupColor(status: PatientStatus | undefined): MantineColor {
  if (status === 'paused' || status === 'not responding') {
    return ({ warning }) => warning[0]
  }

  if (status === 'discharged') {
    return ({ error }) => error[0]
  }

  if (status === 'in treatment') {
    return ({ success }) => success[0]
  }

  return ({ actions }) => actions[2]
}
