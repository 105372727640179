import React from 'react'

const ANoteLoading = () => (
  <div className='p-4 h-screen'>
    <div className='animate-pulse h-8 w-1/2 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-5/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-4/6 bg-gray-400 rounded mx-2 my-4'></div>

    <div className='animate-pulse h-8 w-1/4 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-3/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-5/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-5/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-4/6 bg-gray-400 rounded mx-2 my-4'></div>

    <div className='animate-pulse h-8 w-1/4 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-3/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-5/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-5/6 bg-gray-400 rounded mx-2 my-4'></div>
    <div className='animate-pulse h-4 w-4/6 bg-gray-400 rounded mx-2 my-4'></div>
  </div>
)

export default ANoteLoading
