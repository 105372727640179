import React from 'react'

export type ASubheadingType = {
  children: JSX.Element | string
  className?: string
}

const ASubheading = ({ children, className = '' }: ASubheadingType) => {
  return <h3 className={`text-xl ${className}`}>{children}</h3>
}

export default ASubheading
