import { Box, Center, Stack, Text, TitleTwo } from '@shared/components'
import { CalendarColumnHeader } from '@shared/types'

export type ColumnHeaderProps = {
  column: CalendarColumnHeader
  totalColumns: number
  highlightDay: boolean
}

export const ColumnHeader = ({ column, totalColumns, highlightDay }: ColumnHeaderProps) => (
  <div
    className={`relative scroll-snap-align-start ${
      totalColumns === 7 ? '' : 'w-full min-w-64 max-w-sm'
    }`}
    style={{ width: totalColumns === 7 ? '14%' : '' }}
  >
    <Center mt='xs'>
      <Stack spacing={0}>
        <Center>
          <Text>{column.name}</Text>
        </Center>
        {highlightDay ? (
          <Box
            sx={theme => ({
              backgroundColor: theme.other.colors.background[3],
              borderRadius: '2rem',
              width: '2.5rem',
              height: '2.5rem',
            })}
          >
            <Center>
              <TitleTwo mt='0.5rem' color={colors => colors.text[0]}>
                {column.day}
              </TitleTwo>
            </Center>
          </Box>
        ) : (
          <TitleTwo mt='0.5rem' color={colors => colors.text[0]}>
            {column.day}
          </TitleTwo>
        )}
      </Stack>
    </Center>
  </div>
)
