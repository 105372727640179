import { createFormContext } from '@mantine/form'
import { UseFormInput } from '@mantine/form/lib/types'
import {
  Checkbox,
  CheckboxGroup,
  FormSkipPredicate,
  List,
  ListItem,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import {
  OPIOID_TYPE_SELECT_ITEMS,
  OTHER_STIMULANT_TYPE_SELECT_ITEMS,
  OpioidType,
  OtherStimulantType,
  ROUTE_OF_USE_CHECKBOX_ITEMS,
  RouteOfUse,
  SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS,
  SubstanceUseFrequency,
  SubstanceUseNoteContent,
} from '@shared/types'
import { DeepPartial } from 'react-hook-form'
import { VisitNoteSelect } from '../../../components/forms/VisitNoteSelect'
import { YesOrNoRadio } from '../../../components/forms/YesOrNoRadio'
import { isRequired } from '../../../utils/formValidation'
import { VisitNoteSection } from './VisitNoteSection'

type SubstanceUseFormData = {
  opioid: {
    usedSinceLastVisit: 'yes' | 'no' | ''
    primarySource: OpioidType | ''
    routeOfUse: RouteOfUse[]
    frequencyOfUse: SubstanceUseFrequency | ''
  }
  cocaine: {
    usedSinceLastVisit: 'yes' | 'no' | ''
    routeOfUse: RouteOfUse[]
    frequencyOfUse: SubstanceUseFrequency | ''
  }
  otherStimulant: {
    usedSinceLastVisit: 'yes' | 'no' | ''
    primarySource: OtherStimulantType | ''
    routeOfUse: RouteOfUse[]
    frequencyOfUse: SubstanceUseFrequency | ''
  }
}

export const convertSudNoteContentToFormData = (
  // using DeepPartial since OMaintenenceVisit content is typed as "any"
  content?: DeepPartial<SubstanceUseNoteContent>,
): SubstanceUseFormData => {
  const convertBoolToYesNo = (value: boolean | undefined) => {
    if (value === true) {
      return 'yes'
    }
    if (value === false) {
      return 'no'
    }
    return ''
  }

  return {
    opioid: {
      usedSinceLastVisit: convertBoolToYesNo(content?.opioid?.usedSinceLastVisit),
      primarySource: content?.opioid?.primarySource || '',
      routeOfUse: content?.opioid?.routeOfUse || [],
      frequencyOfUse: content?.opioid?.frequencyOfUse || '',
    },
    cocaine: {
      usedSinceLastVisit: convertBoolToYesNo(content?.cocaine?.usedSinceLastVisit),
      routeOfUse: content?.cocaine?.routeOfUse || [],
      frequencyOfUse: content?.cocaine?.frequencyOfUse || '',
    },
    otherStimulant: {
      usedSinceLastVisit: convertBoolToYesNo(content?.cocaine?.usedSinceLastVisit),
      primarySource: content?.otherStimulant?.primarySource || '',
      routeOfUse: content?.otherStimulant?.routeOfUse || [],
      frequencyOfUse: content?.otherStimulant?.frequencyOfUse || '',
    },
  }
}

export const convertSudFormDataToNoteContent = ({
  formData,
}: {
  formData: SubstanceUseFormData
}): SubstanceUseNoteContent => {
  const convertYesNoToBool = (value: 'yes' | 'no' | '') => {
    if (value === 'yes') {
      return true
    }
    if (value === 'no') {
      return false
    }
    return undefined
  }

  return {
    opioid: {
      usedSinceLastVisit: convertYesNoToBool(formData.opioid.usedSinceLastVisit),
      primarySource: formData.opioid.primarySource || undefined,
      routeOfUse: formData.opioid.routeOfUse,
      frequencyOfUse: formData.opioid.frequencyOfUse || undefined,
    },
    cocaine: {
      usedSinceLastVisit: convertYesNoToBool(formData.cocaine.usedSinceLastVisit),
      routeOfUse: formData.cocaine.routeOfUse,
      frequencyOfUse: formData.cocaine.frequencyOfUse || undefined,
    },
    otherStimulant: {
      usedSinceLastVisit: convertYesNoToBool(formData.otherStimulant.usedSinceLastVisit),
      primarySource: formData.otherStimulant.primarySource || undefined,
      routeOfUse: formData.otherStimulant.routeOfUse,
      frequencyOfUse: formData.otherStimulant.frequencyOfUse || undefined,
    },
  }
}

export const getSubstanceUseFormValidator = ({
  hasOudDiagnosis,
  hasCocaineUseDiagnosis,
  hasOtherStimulantUseDiagnosis,
  sudSectionEnabled,
}: {
  hasOudDiagnosis: boolean
  hasCocaineUseDiagnosis: boolean
  hasOtherStimulantUseDiagnosis: boolean
  sudSectionEnabled: boolean
}): UseFormInput<SubstanceUseFormData>['validate'] => {
  const skipIfNotUsed: (
    type: 'opioid' | 'cocaine' | 'otherStimulant',
  ) => FormSkipPredicate<unknown, SubstanceUseFormData> = type => (_, values) =>
    hasOudDiagnosis && values?.[type]?.usedSinceLastVisit === 'no'

  if (!sudSectionEnabled) {
    return {}
  }

  let validate: UseFormInput<SubstanceUseFormData>['validate'] = {}

  if (hasOudDiagnosis) {
    validate = {
      opioid: {
        usedSinceLastVisit: isRequired,
        primarySource: validateWith(skipIfNotUsed('opioid'), isRequired),
        routeOfUse: validateWith(skipIfNotUsed('opioid'), isRequired),
        frequencyOfUse: validateWith(skipIfNotUsed('opioid'), isRequired),
      },
      ...validate,
    }
  }
  if (hasCocaineUseDiagnosis) {
    validate = {
      cocaine: {
        usedSinceLastVisit: isRequired,
        routeOfUse: validateWith(skipIfNotUsed('cocaine'), isRequired),
        frequencyOfUse: validateWith(skipIfNotUsed('cocaine'), isRequired),
      },
      ...validate,
    }
  }
  if (hasOtherStimulantUseDiagnosis) {
    validate = {
      otherStimulant: {
        usedSinceLastVisit: validateWith(isRequired),
        routeOfUse: validateWith(skipIfNotUsed('otherStimulant'), isRequired),
        primarySource: validateWith(skipIfNotUsed('otherStimulant'), isRequired),
        frequencyOfUse: validateWith(skipIfNotUsed('otherStimulant'), isRequired),
      },
      ...validate,
    }
  }

  return validate
}

export const [SubstanceUseFormProvider, useSubstanceUseFormContext, useSubstanceUseForm] =
  createFormContext<SubstanceUseFormData>()

const RouteOfUseCheckboxGroup = ({
  inputProps,
  isEditing,
}: {
  inputProps: {
    value: RouteOfUse[]
    onChange: (value: RouteOfUse[]) => void
    error?: string
  }
  isEditing: boolean
}) => {
  if (!isEditing) {
    return (
      <Stack spacing='xs'>
        <Text color={colors => colors.text[1]}>Route of use</Text>
        <List>
          {inputProps?.value.map(route => {
            const item = ROUTE_OF_USE_CHECKBOX_ITEMS.find(({ value }) => value === route)
            if (item) {
              return <ListItem key={route}>{item.label}</ListItem>
            }
            return null
          })}
        </List>
      </Stack>
    )
  }

  return (
    <CheckboxGroup label='Route of use (select all that apply)' {...inputProps}>
      {ROUTE_OF_USE_CHECKBOX_ITEMS.map(({ label, value }) => (
        <Checkbox key={value} label={label} value={value} />
      ))}
    </CheckboxGroup>
  )
}

const INTAKE_TEXT = 'in the last month'
const FOLLOW_UP_TEXT = 'since last visit'

const OudSection = ({ isIntake, isEditing }: { isIntake: boolean; isEditing: boolean }) => {
  const form = useSubstanceUseFormContext()
  const timeFrameText = isIntake ? INTAKE_TEXT : FOLLOW_UP_TEXT

  return (
    <VisitNoteSection title='Opioid use'>
      <Stack>
        <YesOrNoRadio
          label={`Has the patient reported use of non-rx opioids ${timeFrameText}?`}
          isEditing={isEditing}
          {...form.getInputProps('opioid.usedSinceLastVisit')}
        />

        {form.values.opioid.usedSinceLastVisit === 'yes' && (
          <>
            <VisitNoteSelect
              isEditing={isEditing}
              label='Primary opioid?'
              placeholder='Select ...'
              data={OPIOID_TYPE_SELECT_ITEMS}
              {...form.getInputProps('opioid.primarySource')}
            />
            <RouteOfUseCheckboxGroup
              isEditing={isEditing}
              inputProps={form.getInputProps('opioid.routeOfUse')}
            />
            <VisitNoteSelect
              isEditing={isEditing}
              placeholder='Select ...'
              label='Average frequency of use'
              data={SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS}
              {...form.getInputProps('opioid.frequencyOfUse')}
            />
          </>
        )}
      </Stack>
    </VisitNoteSection>
  )
}

const CocaineUseSection = ({ isIntake, isEditing }: { isIntake: boolean; isEditing: boolean }) => {
  const form = useSubstanceUseFormContext()
  const timeFrameText = isIntake ? INTAKE_TEXT : FOLLOW_UP_TEXT

  return (
    <VisitNoteSection title='Cocaine use'>
      <Stack>
        <YesOrNoRadio
          label={`Has the patient reported use of cocaine ${timeFrameText}?`}
          isEditing={isEditing}
          {...form.getInputProps('cocaine.usedSinceLastVisit')}
        />
        {form.values.cocaine.usedSinceLastVisit === 'yes' && (
          <>
            <RouteOfUseCheckboxGroup
              isEditing={isEditing}
              inputProps={form.getInputProps('cocaine.routeOfUse')}
            />
            <VisitNoteSelect
              isEditing={isEditing}
              placeholder='Select ...'
              label='Average frequency of use'
              data={SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS}
              {...form.getInputProps('cocaine.frequencyOfUse')}
            />
          </>
        )}
      </Stack>
    </VisitNoteSection>
  )
}

const OtherStimulantSection = ({
  isIntake,
  isEditing,
}: {
  isIntake: boolean
  isEditing: boolean
}) => {
  const form = useSubstanceUseFormContext()
  const timeFrameText = isIntake ? INTAKE_TEXT : FOLLOW_UP_TEXT
  return (
    <VisitNoteSection title='Other stimulant use'>
      <Stack>
        <YesOrNoRadio
          label={`Has the patient reported use of other stimulants ${timeFrameText}?`}
          isEditing={isEditing}
          {...form.getInputProps('otherStimulant.usedSinceLastVisit')}
        />
        {form.values.otherStimulant.usedSinceLastVisit === 'yes' && (
          <>
            <VisitNoteSelect
              isEditing={isEditing}
              label='Primary stimulant?'
              placeholder='Select ...'
              data={OTHER_STIMULANT_TYPE_SELECT_ITEMS}
              {...form.getInputProps('otherStimulant.primarySource')}
            />
            <RouteOfUseCheckboxGroup
              isEditing={isEditing}
              inputProps={form.getInputProps('otherStimulant.routeOfUse')}
            />
            <VisitNoteSelect
              isEditing={isEditing}
              placeholder='Select ...'
              label='Average frequency of use'
              data={SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS}
              {...form.getInputProps('otherStimulant.frequencyOfUse')}
            />
          </>
        )}
      </Stack>
    </VisitNoteSection>
  )
}

type SubstanceUseSectionProps = {
  hasOudDiagnosis: boolean
  hasCocaineUseDiagnosis: boolean
  hasOtherStimulantUseDiagnosis: boolean
  isIntake?: boolean
  isEditing: boolean
}

export const SubstanceUseSection = ({
  hasOudDiagnosis,
  hasCocaineUseDiagnosis,
  hasOtherStimulantUseDiagnosis,
  isIntake = false,
  isEditing,
}: SubstanceUseSectionProps) => {
  return (
    <Stack>
      {hasOudDiagnosis && <OudSection isIntake={isIntake} isEditing={isEditing} />}
      {hasCocaineUseDiagnosis && <CocaineUseSection isIntake={isIntake} isEditing={isEditing} />}
      {hasOtherStimulantUseDiagnosis && (
        <OtherStimulantSection isIntake={isIntake} isEditing={isEditing} />
      )}
    </Stack>
  )
}
