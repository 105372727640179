import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  useBanner,
} from '@shared/components'
import { useMutation, useQueryClient } from 'react-query'
import { patientsApi } from '../../api'

type DeleteNonVisitEventModalProps = {
  onClose: () => void
  nonVisitEventId: string
  patientId: string
}

const DeleteNonVisitEventContent = ({
  onClose,
  nonVisitEventId,
  patientId,
}: DeleteNonVisitEventModalProps) => {
  const queryClient = useQueryClient()
  const deleteEvent = useMutation(patientsApi.deleteNonVisitEvent)
  const { showBanner } = useBanner()

  const onSubmit = async () => {
    await deleteEvent.mutateAsync(
      { patientId, nonVisitEventId },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries('patientsApi.listNonVisitEvents')
          onClose()
        },
        onError: () => {
          showBanner({
            type: 'error',
            dismissable: true,
            label: 'Something went wrong, unable to delete non-visit event',
          })
          onClose()
        },
      },
    )
  }

  return (
    <>
      <ModalHeader onClose={onClose}>Delete non-visit event</ModalHeader>
      <ModalContent>Are you sure you want to delete this non-visit event?</ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don&apos;t delete</SecondaryButton>
          <PrimaryButton loading={deleteEvent.isLoading} onClick={onSubmit}>
            Yes, delete
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const DeleteNonVisitEventModal = ({
  opened,
  ...props
}: DeleteNonVisitEventModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <DeleteNonVisitEventContent {...props} />
    </BetterModal>
  )
}
