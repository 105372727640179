import { useBanner } from '@shared/components'
import { useMutation } from 'react-query'
import { visitNotesApi } from '../../../../../api'
import { useAuth } from '../../../../../context/auth'
import { usePatient } from '../../../PPatientContext'
import MCloseConsultationNoteModal from '../../MCloseConsultationNoteModal'
import { usePatientVisitNote } from '../PatientVisitNoteContext'

export const PatientVisitNoteLockModal = ({ close }: { close: () => void }) => {
  const { patientId, visitNoteId, visitNoteQuery } = usePatientVisitNote()
  const { patientQuery } = usePatient()
  const { currentUser } = useAuth()
  const { showBanner } = useBanner()

  const lockVisitNoteMutation = useMutation(visitNotesApi.lockVisitNote, {
    onSuccess: () => {
      void visitNoteQuery?.refetch()
      void patientQuery?.refetch()
    },
  })

  const signAndLockNote = async () => {
    await lockVisitNoteMutation.mutateAsync({
      patientID: patientId,
      visitID: visitNoteId,
      data: {
        type: visitNoteQuery?.data?.type,
        clinicianCalendarId: currentUser.calendarId,
        clinicianName: currentUser.name,
        clinicianUserId: currentUser.oid,
      },
    })
    showBanner({ label: 'Note successfully signed.', type: 'success', dismissable: true })
  }

  switch (visitNoteQuery?.data?.type) {
    case 'consultation':
    case 'reenrollment':
      return (
        <MCloseConsultationNoteModal
          patient={patientQuery?.data}
          patientID={patientId}
          visitID={visitNoteId}
          open
          currentUser={currentUser}
          closeModal={close}
          signNote={signAndLockNote}
          setAlert={({ message, type }) =>
            showBanner({ label: message, type: type ?? 'success', dismissable: true })
          }
        />
      )

    default:
      return null
  }
}
