import { EmrLunaApi } from '@shared/types'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { emrLunaApi } from '../../api'

export const useInvalidateLunaQuery = () => {
  const queryClient = useQueryClient()

  return useCallback(
    <OpheliaRoute extends keyof EmrLunaApi>(
      route: OpheliaRoute,
      // Can pass the config in order to get a more granular query key to invalidate
      config?: Partial<EmrLunaApi[OpheliaRoute]['req']>,
    ) => {
      const [queryKey] = emrLunaApi.getQuery(route, config)
      return queryClient.invalidateQueries({
        queryKey,
      })
    },
    [queryClient],
  )
}
