import { IoBookmarkOutline } from '@react-icons/all-files/io5/IoBookmarkOutline'
import { Divider, Stack, Text, useMantineTheme } from '@shared/components'
import { PrescriptionFavorite } from '@shared/types'
import QuickApplyListItem from './QuickApplyListItem'

export type PrescriptionRecent = PrescriptionFavorite & { writtenDate?: Date }

export type PatientRecentsTabContentProps = {
  recentPrescriptionsList: PrescriptionRecent[]
  recentOnClick: (item: PrescriptionFavorite) => void
}

const PatientRecentsTabContent = ({
  recentPrescriptionsList,
  recentOnClick,
}: PatientRecentsTabContentProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  if (recentPrescriptionsList.length) {
    return (
      <Stack
        spacing='xs'
        sx={() => ({
          overflowY: 'scroll',
          maxHeight: '320px',
          paddingLeft: '0px',
          background: colors.background[1],
        })}
      >
        {recentPrescriptionsList.map((prescription, index) => {
          return (
            <>
              <QuickApplyListItem
                key={prescription.oid}
                favoriteName={prescription.favoriteName}
                medicationName={prescription.medicationName}
                quantity={prescription.quantity}
                daysSupply={prescription.daysSupply}
                doseForm={prescription.doseForm}
                strength={prescription.strength}
                onClick={() => {
                  recentOnClick(prescription)
                }}
                writtenDate={prescription.writtenDate}
              />
              {index !== recentPrescriptionsList.length - 1 && <Divider />}
            </>
          )
        })}
      </Stack>
    )
  }
  return (
    <Stack
      align='center'
      justify='center'
      spacing='xs'
      sx={theme => ({ padding: theme.spacing.lg, background: colors.background[1] })}
    >
      <IoBookmarkOutline size='25px' />
      <Text bold>No recent patient prescriptions</Text>
    </Stack>
  )
}
export default PatientRecentsTabContent
