import { CalendarIcon, ClockIcon, Menu } from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { CurrentTaskOperation } from '../../TaskTable'

export type ActionMenuItemsForTaskTypeProps = {
  task: EMRTaskDecorated
  onClick: ({ operation, task }: CurrentTaskOperation) => void
}

export const ActionMenuItemsForTaskType = ({ task, onClick }: ActionMenuItemsForTaskTypeProps) => {
  switch (task.type) {
    case 'review_time':
      return (
        <Menu.Item
          onClick={() => onClick({ operation: 'review_time', task })}
          icon={<ClockIcon color={colors => colors.actions[0]} />}
        >
          Review time
        </Menu.Item>
      )
    case 'clinical_contact':
      return (
        <Menu.Item
          onClick={() => onClick({ operation: 'clinical_contact', task })}
          icon={<CalendarIcon color={colors => colors.actions[0]} />}
        >
          Schedule
        </Menu.Item>
      )
    case 'schedule_wellness_visit':
      return (
        <Menu.Item
          onClick={() => onClick({ operation: 'schedule_wellness_visit', task })}
          icon={<CalendarIcon color={colors => colors.actions[0]} />}
        >
          Schedule
        </Menu.Item>
      )
    default:
      return null
  }
}
