import { BookmarkIcon, CheckCircleIcon, SlashIcon } from '@shared/components'

type AAlertBannerProps = {
  onClose?: () => void
  message: string
  type?: string
  dismissMessage?: string
}

const getColor = (type: string) => {
  if (type === 'success') {
    return 'daybreak-success1'
  } else if (type === 'error') {
    return 'daybreak-error1'
  }
  return 'daybreak-warning1'
}

const AAlertBanner = ({
  onClose,
  message,
  type = 'success',
  dismissMessage,
}: AAlertBannerProps) => {
  return (
    <div className={`bg-${getColor(type)} rounded-md p-4 mb-2`}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          {type === 'error' && <SlashIcon className='h-4 w-4' />}
          {type === 'success' && <CheckCircleIcon className='h-4 w-4' />}
          {type === 'warning' && <BookmarkIcon className='h-4 w-4' />}
        </div>
        <div className='ml-3 flex-1 md:flex md:justify-between'>
          <p className='text-sm leading-5 font-medium' style={{ wordBreak: 'break-word' }}>
            {message}
          </p>
        </div>
        {onClose &&
          (dismissMessage ? (
            <button className='mt-3 text-sm md:mt-0 md:ml-6 font-medium' onClick={onClose}>
              {dismissMessage} <span aria-hidden='true'>&rarr;</span>
            </button>
          ) : (
            <div className='ml-auto pl-3'>
              <div className='-mx-1.5 -my-1.5'>
                <button
                  onClick={onClose}
                  className={`inline-flex rounded-md p-1.5 focus:outline-none text-black hover:bg-${getColor(
                    type,
                  )} focus:bg-${getColor(type)} transition`}
                  aria-label='Dismiss'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-5 w-5 text-black'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default AAlertBanner
