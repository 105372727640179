import {
  DoseSpotMedicationSearchResult,
  Employee,
  FormularyMedicationNDC,
  Patient,
  PrescriptionResponse,
  hasRole,
  isClinician,
} from '@shared/types'
import { prescriptionsApi } from '../api'

export const PRESCRIPTION_QUEUER_ROLES: Employee['role'][] = ['sncm', 'ncm', 'stn', 'tn', 'ncm_tn']

export const canReOrderPrescription = (
  prescription: PrescriptionResponse,
  currentUser: Employee,
) => {
  return (
    prescription.status !== 'Queued' &&
    (isClinician(currentUser) || hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES, 'engineer'))
  )
}

export const canSendPrescription = (prescription: PrescriptionResponse, currentUser: Employee) => {
  return (
    isClinician(currentUser) &&
    prescription.status === 'Queued' &&
    hasRole(currentUser, 'pc', 'spc', ...PRESCRIPTION_QUEUER_ROLES, 'engineer') &&
    prescription.prescription_sent_by === currentUser.name
  )
}

export const canDeletePrescription = (
  prescription: PrescriptionResponse,
  currentUser: Employee,
) => {
  const isCancelable = prescription.status === 'Entered' || prescription.status === 'Queued'
  return prescription.prescription_id && isClinician(currentUser) && isCancelable
}

/**
 * medication search name is a combination of different fields we get back on a prescription:
 * it's a mix of part of the display name, plus the strength and doseform. Eg:
 * DisplayName: Buprenorphine 0.3 mg/mL solution
 * Strength: 0.3 mg/mL
 * DoseForm: solution
 * Route: injectable
 * Desired search name: Buprenorphine (injectable - solution)
 *
 */
export const buildSearchString = ({
  displayName,
  strength,
  route,
}: {
  displayName: string | null
  strength: string
  route?: string
}) => {
  // route is related to the v1 API
  if (route) {
    return `${displayName?.replace(strength, `(${route} -`)})`
  }

  // In v2, we can send the medication name without the strength
  return `${displayName?.replace(strength, '').trim()}`
}

export const searchForExactMedication = async ({
  medicationName,
  dispensableDrugId,
}: {
  medicationName: string
  dispensableDrugId?: number
}): Promise<DoseSpotMedicationSearchResult | string> => {
  const searchResults = await prescriptionsApi.search(medicationName)

  /*
   * @dosespotV2Migration
   * If we pass in a dispensableDrugId, then we're trying to fetch the specific
   * variant of the medication. This is new to the v2 API, because DS used to return
   * one medication with many strengths, and now each search result represents
   * a specific medication + strength combination. If we have the dispensableDrugId
   * of the exact variation we want, we will find it in searchResult and return it
   */
  if (searchResults.length > 0) {
    if (dispensableDrugId) {
      const exactMedication = searchResults.find(
        medication => medication.DispensableDrugId === dispensableDrugId,
      )

      if (exactMedication) {
        return Promise.resolve(exactMedication)
      }
    }

    // Otherwise, return the first result like we did previously
    return Promise.resolve(searchResults[0]!)
  }
  return Promise.reject(`No dosespot medication found with name: ${medicationName}.`)
}

export const getPharmacyNotesTemplate = (nadea?: string) =>
  `NADEA #: ${
    nadea || '[your x-waiver number here]'
  }; PDMP checked; Telemed eRx; follow up visit scheduled; prescription for F11.20 opioid use disorder maintenance treatment, not for detox; call with questions: (215) 585-2144.`

export const sortPrescriptions = (prescriptionData: PrescriptionResponse[]) => {
  return prescriptionData.sort((a: PrescriptionResponse, b: PrescriptionResponse) => {
    const dateA = new Date(a.effective_date ?? a.timestamp)
    const dateB = new Date(b.effective_date ?? b.timestamp)

    const statusA = a.status
    const statusB = b.status
    if (statusA === 'Queued' && statusB !== 'Queued') {
      return -1
    }

    if (statusB === 'Queued' && statusA !== 'Queued') {
      return 1
    }

    // check calendar date congruence
    if (dateB.toLocaleDateString() === dateA.toLocaleDateString()) {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    }

    return dateB.getTime() - dateA.getTime()
  })
}

export const prescriptionRequiresPriorAuthorization = ({
  patient,
  ndc,
}: {
  patient?: Patient
  ndc?: string
}) => {
  const patientPrescriptionCoverage =
    patient?.prescriptionBenefits?.prescriptionCoverageData?.medications
  return Boolean(
    patientPrescriptionCoverage?.[(ndc ?? '') as FormularyMedicationNDC]
      ?.priorAuthorizationRequired,
  )
}
