import {
  Accordion,
  Center,
  CircleWithText,
  Container,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  Loader,
  MinusCircleIcon,
  Pill,
  PlusIcon,
  PrimaryButton,
  Stack,
  Text,
  useMantineTheme,
} from '@shared/components'
import { EMRTaskDecorated, EMRTaskStatus, getEMRTaskStatusLabel } from '@shared/types'
import { sortBy } from '@shared/utils'
import { useState } from 'react'
import { TaskEntry } from './TaskEntry'

export const ReviewIssuesDrawerContent = ({
  onBack,
  onClose,
  onClickAddIssue,
  counts,
  isLoading,
  tasks,
  openNotes,
}: {
  onBack: (() => void) | undefined
  onClose: () => void
  onClickAddIssue: () => void
  counts: Record<EMRTaskStatus, number> | undefined
  isLoading: boolean
  tasks: EMRTaskDecorated[]
  openNotes: (taskId: string) => void
}) => {
  const [currentValue, setCurrentValue] = useState<EMRTaskStatus[]>(['open', 'snoozed'])

  return (
    <>
      <DrawerHeader onBack={onBack} onClose={onClose}>
        Issues
      </DrawerHeader>
      <DrawerContent trapFocus={false}>
        <Accordion
          multiple
          value={currentValue}
          onChange={(value: EMRTaskStatus[]) => setCurrentValue(value)}
        >
          {STATUSES.map(status => (
            <Accordion.Item key={status} value={status}>
              <Accordion.Control
                chevron={currentValue.includes(status) && <MinusCircleIcon styled />}
              >
                <Group>
                  <Text>{getEMRTaskStatusLabel(status)}</Text>
                  <CircleWithText
                    variant={
                      status !== 'closed' && counts && counts[status] > 0 ? 'error' : 'default'
                    }
                  >
                    {counts?.[status]}
                  </CircleWithText>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Container>
                  {isLoading ? (
                    <Center>
                      <Loader size='sm' />
                    </Center>
                  ) : (
                    <TasksByStatus tasks={tasks} status={status} openNotes={openNotes} />
                  )}
                </Container>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton onClick={onClickAddIssue} leftIcon={<PlusIcon />}>
            Issue
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const TasksByStatus = ({
  tasks,
  status,
  openNotes,
}: {
  tasks: EMRTaskDecorated[]
  status: EMRTaskStatus
  openNotes: (taskId: string) => void
}) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const filteredTasks = tasks.filter(task => task.status === status)
  if (filteredTasks.length > 0) {
    return (
      <Stack>
        {filteredTasks.sort(sortBy({ order: 'DESC', key: 'createdAt' })).map(task => (
          <TaskEntry
            key={task.oid}
            background={colors.background[1]}
            task={task}
            openNotes={openNotes}
          />
        ))}
      </Stack>
    )
  }

  const { badge, text } = STATUS_TEXT[status]

  return (
    <Stack
      align='center'
      p='md'
      sx={({ other: { colors } }) => ({ backgroundColor: colors.background[1] })}
    >
      <Pill status='none' variant='filled'>
        {badge}
      </Pill>
      <Text>{text}</Text>
    </Stack>
  )
}

// A subset of EMRTaskStatuses that the UI cares about
const STATUSES: EMRTaskStatus[] = ['open', 'snoozed', 'closed']

const STATUS_TEXT: Record<EMRTaskStatus, { badge: string; text: string }> = {
  open: {
    badge: 'No open issues',
    text: "When an issue is created for this patient, it'll show up here",
  },
  snoozed: {
    badge: 'No snoozed issues',
    text: "When an issue is snoozed for this patient, it'll show up here",
  },
  closed: {
    badge: 'No closed issues',
    text: "When an issue is closed for this patient, it'll show up here",
  },
}
