import React, { ChangeEvent } from 'react'

export type ASelectType = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  error?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  value?: string | number
}

export const ASelect = React.forwardRef<HTMLSelectElement, ASelectType>(
  ({ children, className, disabled, error, name, onChange, value }, ref) => {
    let stateSpecificClassNames = 'focus:shadow-focus'

    if (error) {
      stateSpecificClassNames = 'select-error focus:shadow-focus-error text-red border-red-light'
    }

    return (
      <select
        className={`block w-full pl-3 pr-10 py-2 text-base border-daybreak-actions0 focus:outline-none focus:ring-daybreak-actions1 focus:border-daybreak-actions1 sm:text-sm rounded-md ${stateSpecificClassNames} ${
          disabled ? 'opacity-40 cursor-not-allowed' : ''
        } ${className}`}
        name={name}
        disabled={disabled}
        onChange={onChange}
        ref={ref}
        value={value}
      >
        {children}
      </select>
    )
  },
)

ASelect.displayName = 'ASelect'

export default ASelect
