import {
  Alert,
  AlertIcon,
  Banner,
  Checkbox,
  Grid,
  Group,
  HeartIcon,
  LoadingOverlay,
  Select,
  TertiaryButton,
  Textarea,
  TextInput,
  TooltipLabel,
  useMantineTheme,
} from '@shared/components'
import {
  DispenseUnitType,
  DOSESPOT_DIRECTIONS_CHAR_LIMIT,
  DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT,
  DoseSpotMedication,
  DoseSpotPharmacy,
  FormularyMedicationNDC,
  NameAndId,
  Patient,
  PrescriptionFavorite,
  StateAbbr,
  states,
} from '@shared/types'
import { isControlledSubstance } from '@shared/utils'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi, prescriptionsApi } from '../../../api'
import {
  getPharmacyNotesTemplate,
  prescriptionRequiresPriorAuthorization,
} from '../../../utils/prescriptionUtils'
import { MedicationFormData, NARCAN_DISPENSABLE_IDS, useMedicationFormContext } from './formHelpers'
import { useMedicationInfoQuery } from './hooks/useMedicationInfoQuery'
import { MedicationSearchBar } from './MedicationSearchBar'
import PharmacySection from './PharmacySection'
import { PrescriptionStrengthItemComponent } from './PrescriptionStrengthItemComponent'
import QueuedForIdSelector from './QueuedForIdSelector'
import QuickApplyPopoverContent from './quickApply/QuickApplyPopoverContent'

export type MPrescriptionFormProps = {
  favoritesBanner: string
  favoritesBannerOnClick: () => void
  saveFavoriteOnClick: (data: MedicationFormData) => void
  pc?: NameAndId
  patient: Patient
  isPrescriptionQueued: boolean
  editFavoriteOnClick: (template: PrescriptionFavorite) => void
  pharmacy?: DoseSpotPharmacy
  pharmacyUpdateOnClick: () => void
  nadea?: string
  needsNewPriorAuth?: boolean
}

const MPrescriptionForm = ({
  favoritesBanner,
  favoritesBannerOnClick,
  saveFavoriteOnClick,
  editFavoriteOnClick,
  pc,
  isPrescriptionQueued,
  pharmacy,
  pharmacyUpdateOnClick,
  patient,
  nadea,
  needsNewPriorAuth,
}: MPrescriptionFormProps) => {
  const form = useMedicationFormContext()

  const [selectedTemplate, setSelectedTemplate] = useState<undefined | PrescriptionFavorite>(
    undefined,
  )

  const {
    other: { colors },
  } = useMantineTheme()

  const [quickApplyError, setQuickApplyError] = useState('')

  const applyTemplate = (formData: Partial<MedicationFormData>) => {
    const entries = Object.entries(formData) as [
      keyof MedicationFormData,
      MedicationFormData[keyof MedicationFormData],
    ][]
    entries.forEach(([key, val]) => {
      form.setFieldValue(key, val)
    })
  }

  const convertPerscriptionFavoriteToFormData = (
    fav: PrescriptionFavorite,
  ): Partial<MedicationFormData> => {
    return {
      medication_strength: fav.strength,
      medication_days_supply: fav.daysSupply ? String(fav.daysSupply) : '',
      medication_quantity: fav.quantity ? String(fav.quantity) : '',
      medication_refills: fav.refills ? String(fav.refills) : '',
      directions: fav.directions,
      notes: fav.pharmacyNotes,
      bridge: false,
      substitutionAllowed: fav.substitutionAllowed,
    }
  }

  const medicationInfoForSelectedTemplate = useMedicationInfoQuery({
    medicationName: selectedTemplate?.medicationName,
    medicationStrength: selectedTemplate?.strength,
    // @dosespotV2Migration - searching for medications in v2 includes a dispensableDrugId
    dispensableDrugId: selectedTemplate?.dispensableDrugId,
    onSuccess: (fullMedication, searchResult) => {
      if (selectedTemplate) {
        const formData = convertPerscriptionFavoriteToFormData(selectedTemplate)
        selectedTemplate.pharmacyNotes = selectedTemplate.pharmacyNotes?.trim()
        if (isControlledSubstance(fullMedication.Schedule)) {
          formData.medication_refills = '0'
          formData.notes = selectedTemplate.pharmacyNotes
        } else {
          formData.notes = selectedTemplate.pharmacyNotes || ''
        }
        setQuickApplyError('')
        applyTemplate({
          ...formData,
          full_medication: fullMedication,
          medication: searchResult,
        })
      }
    },
    onError: (err: string) => {
      setQuickApplyError(`Unable to apply template: ${err}`)
      setSelectedTemplate(undefined)
    },
    enabled: Boolean(selectedTemplate),
  })

  const { medication_strength } = form.values
  const { medication } = form.values
  const medication_ndc = medication?.Strength.find(
    strength => strength.Strength === medication_strength,
  )?.NDC as FormularyMedicationNDC | undefined

  const priorAuthorizationRequired =
    needsNewPriorAuth &&
    prescriptionRequiresPriorAuthorization({
      patient,
      ndc: medication_ndc,
    })

  const medicationStrengths = medication
    ? medication.Strength.map(strength => ({
        value: strength.Strength,
        label: strength.Strength,
        priorAuthorizationRequired: prescriptionRequiresPriorAuthorization({
          patient,
          ndc: strength.NDC as FormularyMedicationNDC,
        }),
      }))
    : []

  const { full_medication } = form.values

  useQuery(
    ['prescriptionsApi.getMedication', medication],
    () => {
      return prescriptionsApi.getMedication({
        medication_strength,
        medication: medication?.Name || '',
        dispensableDrugId: medication?.DispensableDrugId,
      })
    },
    {
      onSuccess: (fullMedication: DoseSpotMedication) => {
        if (isControlledSubstance(fullMedication.Schedule)) {
          form.setFieldValue('medication_refills', '0')
          form.setFieldValue('notes', form.values.notes || getPharmacyNotesTemplate(nadea))
        } else {
          form.setFieldValue('medication_refills', form.values.medication_refills ?? '')
          form.setFieldValue('notes', form.values.notes ?? '')
        }
        form.setFieldValue('full_medication', fullMedication)
      },
      enabled: Boolean(medication) && Boolean(medication_strength),
    },
  )
  const patientState = patient.homeData?.state || patient.shippingData?.state
  const stateAbbreviation = states.find(element => element.state === patientState)
    ?.abbr as StateAbbr

  const [currentOcpKey, currentOcpFunction] = emrApi.getQuery('GET /ocp/provider/:state', {
    params: { state: stateAbbreviation },
  })
  const currentOcp = useQuery(currentOcpKey, currentOcpFunction, {
    enabled: isPrescriptionQueued || !stateAbbreviation,
  })

  return (
    <>
      <LoadingOverlay
        visible={medicationInfoForSelectedTemplate.isFetching || currentOcp.isLoading}
      />
      <Grid>
        <Grid.Col span={12}>
          <QuickApplyPopoverContent
            patientId={patient.oid}
            onTemplateSelected={(template: PrescriptionFavorite) => {
              setSelectedTemplate(template)
            }}
            editFavoriteOnClick={editFavoriteOnClick}
          />
        </Grid.Col>
        {favoritesBanner === 'success' && (
          <Grid.Col span={12}>
            <Banner
              type='success'
              label='Favorite successfully saved'
              onClick={favoritesBannerOnClick}
            ></Banner>
          </Grid.Col>
        )}
        {quickApplyError && (
          <Grid.Col span={12}>
            <Banner
              type='error'
              label={quickApplyError}
              onClick={() => setQuickApplyError('')}
            ></Banner>
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <MedicationSearchBar
            {...form.getInputProps('medication')}
            value={medication}
            onChange={item => {
              form.setFieldValue('full_medication', undefined)
              form.setFieldValue('medication', item)
              form.setFieldValue('medication_strength', item?.Strength[0]?.Strength || '')
              form.setFieldValue('medication_ndc', item?.Strength[0]?.NDC || '')
              if (!item) {
                form.setFieldValue('medication_refills', '')
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            {...form.getInputProps('medication_strength')}
            label='Strength'
            itemComponent={needsNewPriorAuth ? PrescriptionStrengthItemComponent : undefined}
            data={medicationStrengths}
            disabled={!medication}
            placeholder='Select one...'
            defaultValue={medication_strength}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_refills')}
            label={
              <TooltipLabel
                label={
                  isControlledSubstance(full_medication?.Schedule)
                    ? `Schedule ${full_medication?.Schedule} drugs may not enter a refill value.`
                    : undefined
                }
              >
                Refills
              </TooltipLabel>
            }
            disabled={!full_medication || isControlledSubstance(full_medication.Schedule)}
            placeholder={
              (NARCAN_DISPENSABLE_IDS.includes(full_medication?.DispensableDrugId || 0) &&
                '0 - 5') ||
              (isControlledSubstance(full_medication?.Schedule) && '0') ||
              '0, 1, or 2'
            }
          />
        </Grid.Col>

        {priorAuthorizationRequired && (
          <Grid.Col span={12}>
            <Alert
              variant='warning'
              title='If appropriate, consider another formulation'
              icon={<AlertIcon />}
            >
              This medication at this strength isn&apos;t covered by the patient&apos;s insurance
              and will require prior authorization. This may delay pick-up while the care team
              coordinates with the patient&apos;s insurance.
            </Alert>
          </Grid.Col>
        )}

        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_quantity')}
            label={
              <TooltipLabel label='Include 2 to 3 day buffer' disabled={!medication}>
                {`Dispense quantity ${
                  full_medication?.DispenseUnitId
                    ? ` (${DispenseUnitType[full_medication.DispenseUnitId]})`
                    : ''
                }`}
              </TooltipLabel>
            }
            disabled={!medication}
            placeholder='e.g. 28'
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_days_supply')}
            label='Days supply'
            disabled={!medication}
            placeholder='e.g. 7'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            {...form.getInputProps('substitutionAllowed')}
            label='Allow substitution'
            checked={form.values.substitutionAllowed}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            {...form.getInputProps('directions')}
            minRows={2}
            autosize
            label='Directions'
            maxLength={DOSESPOT_DIRECTIONS_CHAR_LIMIT}
            placeholder='e.g. Place one and a half (1.5) films under tongue daily as instructed'
            disabled={!full_medication}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            {...form.getInputProps('effective_date')}
            label={
              <TooltipLabel
                label="Check PDMP to align with patient's next fill date"
                disabled={!full_medication}
              >
                Effective date
              </TooltipLabel>
            }
            placeholder='MM/DD/YYYY'
            disabled={!full_medication}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            {...form.getInputProps('bridge')}
            label='This is a bridge prescription'
            checked={form.values.bridge}
          />
        </Grid.Col>
        {isPrescriptionQueued && pc && (
          <Grid.Col span={12}>
            <QueuedForIdSelector
              pc={pc}
              ocp={currentOcp.data}
              patient={patient}
              {...form.getInputProps('queuedForId')}
            />
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <Textarea
            label='Pharmacy notes'
            {...form.getInputProps('notes')}
            minRows={4}
            placeholder='Justification for early refill request, justification for change in dose, stating if you are a licensed out of state prescriber, etc.'
            maxLength={DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT}
            disabled={!full_medication}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Group position='apart'>
            <TertiaryButton
              onClick={() => {
                saveFavoriteOnClick(form.values)
              }}
              leftIcon={<HeartIcon color={colors.actions[0]} />}
            >
              Save as favorite
            </TertiaryButton>
          </Group>
        </Grid.Col>
        <Grid.Col span={12}>
          <PharmacySection
            pharmacy={pharmacy}
            pharmacyUpdateOnClick={pharmacyUpdateOnClick}
            error={form.errors.pharmacyId}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}

export default MPrescriptionForm
