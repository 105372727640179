import { Group, Modal, PrimaryButton, SecondaryButton } from '@shared/components'

type DeleteTimeTrackingItemModalProps = {
  onClose: () => void
  onDeleteEntry: () => void
  opened: boolean
}

const DeleteTimeTrackingItemModal = ({
  onClose,
  onDeleteEntry,
  opened,
}: DeleteTimeTrackingItemModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Delete time tracking entry'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don&apos;t delete</SecondaryButton>
          <PrimaryButton onClick={onDeleteEntry}>Yes, delete</PrimaryButton>
        </Group>
      }
    >
      Are you sure you want to delete this entry?
    </Modal>
  )
}

export default DeleteTimeTrackingItemModal
