import { Center, Menu, MoreVerticalIcon, SecondaryButton } from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { CurrentTaskOperation } from '../TaskTable'
import { ActionMenuItemsForTaskStatus } from './menu/ActionMenuItemsForTaskStatus'
import { ActionMenuItemsForTaskType } from './menu/ActionMenuItemsForTaskType'

export type ActionMenuProps = {
  task: EMRTaskDecorated
  onClick: ({ operation, task }: CurrentTaskOperation) => void
}

export const ActionMenu = ({ task, onClick }: ActionMenuProps) => {
  return (
    <Menu position='bottom-end'>
      <Center>
        <Menu.Target>
          <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
        </Menu.Target>
      </Center>
      <Menu.Dropdown>
        <ActionMenuItemsForTaskType task={task} onClick={onClick} />
        <ActionMenuItemsForTaskStatus task={task} onClick={onClick} />
      </Menu.Dropdown>
    </Menu>
  )
}
