import { useMatch } from 'react-router-dom'
import { useEmrQuery } from './use-emr-query'

/**
 * usePatientGlobal is a hook that fetches the patient's document, if a patientId
 * can be found in the URL params
 *
 * This can be used anywhere in the app to get the patient's data, regardless of where
 * in the App's DOM tree we are (compared to usePatient which must be used within PatientContext)
 */
export function usePatientGlobal() {
  const match = useMatch('/patients/:patientID/*')
  const patientId = match?.params?.patientID || ''

  const patientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId,
      },
    },
    { enabled: Boolean(patientId) },
  )

  return { patientId, patientQuery }
}
