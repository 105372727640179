import { EMRTaskNoteDecorated } from '@shared/types'
import { NotesAssigneeChangedEntry } from './NotesAssigneeChangedEntry'
import { NotesDescriptionEntry } from './NotesDescriptionEntry'
import { NotesNoteAddedEntry } from './NotesNoteAddedEntry'
import { NotesStatusChangedEntry } from './NotesStatusChangedEntry'

export type NotesEntryProps = {
  note: EMRTaskNoteDecorated
}

export const NotesEntry = ({ note }: NotesEntryProps) => {
  if (note.type === 'description') {
    return <NotesDescriptionEntry note={note} />
  }

  if (note.type === 'note_added') {
    return <NotesNoteAddedEntry note={note} />
  }

  if (note.type === 'assignee_changed') {
    return <NotesAssigneeChangedEntry note={note} />
  }

  if (note.type === 'status_changed') {
    return <NotesStatusChangedEntry note={note} />
  }

  return null
}
