import { useForm } from '@mantine/form'
import { Skeleton, Stack } from '@shared/components'
import { useEffect } from 'react'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { CardHeader } from '../components/CardHeader'
import { CardTextarea } from '../components/CardTextarea'

export type BillingTeamNotesSectionProps = {
  loading?: boolean
  isEditing: boolean
  setEditing: (bool: boolean) => void
  editButtonEnabled: boolean
  notes: string
  onSave: (notes: string) => Promise<void>
  saving: boolean
}

export const BillingTeamNotesSection = ({
  isEditing,
  setEditing,
  editButtonEnabled,
  notes,
  onSave,
  saving,
  loading,
}: BillingTeamNotesSectionProps) => {
  const form = useForm({
    initialValues: {
      notes: '',
    },
  })

  useEffect(() => {
    form.setFieldValue('notes', notes)
  }, [notes])

  return (
    <Stack>
      <CardHeader
        title='Billing team notes'
        showButton={editButtonEnabled && !loading}
        buttonOnClick={() => setEditing(true)}
        buttonLabel='Edit'
      />
      <Stack>
        {loading ? (
          <>
            <Skeleton width='100%' height={10} />
            <Skeleton width='100%' height={10} />
            <Skeleton width='100%' height={10} />
          </>
        ) : (
          <CardTextarea {...form.getInputProps('notes')} editable={isEditing} />
        )}

        {isEditing ? (
          <EditableSectionFooter
            onCancel={() => {
              setEditing(false)
              form.setFieldValue('notes', notes)
            }}
            onSave={form.onSubmit(async () => {
              await onSave(form.values.notes)
              setEditing(false)
            })}
            isSaving={saving}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}
