import { useToggle } from '@mantine/hooks'
import {
  Group,
  InputWrapper,
  Modal,
  PlusCircleIcon,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { ProblemListProblem } from '@shared/types'
import { usePatient } from '../../pages/patient/PPatientContext'
import OAddProblemListModal from '../../pages/patient/visits/OAddProblemListModal'
import { DiagnosisItem } from './DiagnosisItem'

export type DiagnosisSectionProps = {
  value: ProblemListProblem[]
  onChange: (value: ProblemListProblem[]) => void
  error?: string
  editable: boolean
  saveDiagnoses: ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => Promise<void>
  deactivateDiagnosis: ({ diagnosis }: { diagnosis: ProblemListProblem }) => Promise<void>
}

export const DiagnosisSection = ({
  value,
  onChange,
  error,
  editable,
  saveDiagnoses,
  deactivateDiagnosis,
}: DiagnosisSectionProps) => {
  const { problemsListQuery } = usePatient()

  // NOTE: in a follow-up we're going to differentiate between active and inactive diagnoses
  const activeDiagnoses = (problemsListQuery?.data || []) as ProblemListProblem[]

  const unaddressedDiagnoses = activeDiagnoses.filter(
    item => value?.every(diagnosis => diagnosis.code !== item.code),
  )

  // We want the addressed diagnoses to appear above unaddressed diagnoses
  const allDiagnoses = [...(value ?? []), ...unaddressedDiagnoses]

  const [showModal, toggleShowModal] = useToggle()

  return (
    <InputWrapper error={error} disabled={editable}>
      <Stack spacing='sm'>
        <Group position='apart'>
          <Text>Select diagnoses addressed during this visit</Text>
          {editable && (
            <TertiaryButton onClick={() => toggleShowModal()} leftIcon={<PlusCircleIcon styled />}>
              Add diagnosis
            </TertiaryButton>
          )}
        </Group>
        {allDiagnoses.length > 0 ? (
          allDiagnoses.map(diagnosis => {
            const isAddressed = value.some(item => item.code === diagnosis.code)
            return (
              <DiagnosisItem
                key={diagnosis.code}
                editable={editable}
                diagnosis={diagnosis}
                updateDiagnosis={({
                  diagnosis,
                  allowDiagnosisToBeRemoved = false,
                }: {
                  diagnosis: ProblemListProblem
                  allowDiagnosisToBeRemoved?: boolean
                }) => {
                  const otherDiagnoses = value.filter(item => item.code !== diagnosis.code)

                  // If the diagnosis can be marked as unaddressed, and the diagnosis is already marked as addressed, remove it from the list
                  if (allowDiagnosisToBeRemoved && isAddressed) {
                    return onChange(otherDiagnoses)
                  }

                  // Otherwise, just update or add the diagnosis to the list of addressed diagnoses
                  return onChange([...otherDiagnoses, diagnosis])
                }}
                isAddressed={isAddressed}
                removeDiagnosis={diagnosis => deactivateDiagnosis({ diagnosis })}
              />
            )
          })
        ) : (
          <Text size='xs' color={colors => colors.text[1]}>
            At least one problem must be indicated as addressed to submit this note.
          </Text>
        )}
        <Modal opened={showModal} onClose={() => toggleShowModal(false)} title='Add diagnosis'>
          <OAddProblemListModal
            closeModal={() => toggleShowModal(false)}
            setDiagnoses={diagnoses => {
              // Update / add diagnoses to existing value
              const otherDiagnoses = value.filter(
                item => !diagnoses.some(diagnosis => diagnosis.code === item.code),
              )

              onChange([...otherDiagnoses, ...diagnoses])
            }}
            saveDiagnoses={saveDiagnoses}
          />
        </Modal>
      </Stack>
    </InputWrapper>
  )
}
