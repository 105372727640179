import { Aside, Flex, useMantineTheme } from '@shared/components'
import React, { createContext, ReactNode, useContext, useState } from 'react'

/**
 * SidePaneContext is a React context that provides a way to show a side pane
 * in the EMR. The side pane is like a static drawer, except it does not overlay
 * the main content, instead pushing it to the side.
 *
 * The useSidePane hook provides a way to interact with the side pane or present
 * a new component in the side pane imperatively.
 */

type Pane = {
  key: string
  width?: number
  content: ReactNode | undefined
}

type PaneContext = {
  pane: Pane | undefined
  paneVisible: boolean
  presentPane: (pane: Pane) => void
  hidePane: () => void
}

const DEFAULT_PANE_WIDTH = 512

const initialContext: PaneContext = {
  pane: {
    key: 'INITIAL_PANE_KEY',
    width: DEFAULT_PANE_WIDTH,
    content: undefined,
  },
  paneVisible: false,
  presentPane: () => void 0,
  hidePane: () => void 0,
}

const SidePaneContext = createContext<PaneContext>(initialContext)

export const SidePaneProvider = ({ children }: { children: React.ReactNode }) => {
  const [pane, setPane] = useState<Pane | undefined>(undefined)

  const presentPane = ({ key, content, width = DEFAULT_PANE_WIDTH }: Pane) => {
    setPane({ key, content, width })
  }

  const hidePane = () => {
    setPane(undefined)
  }

  const context: PaneContext = {
    pane,
    paneVisible: Boolean(pane),
    presentPane,
    hidePane,
  }

  return <SidePaneContext.Provider value={context}>{children}</SidePaneContext.Provider>
}

export const useSidePane = () => {
  return useContext(SidePaneContext)
}

export const SidePane = () => {
  const { pane, paneVisible } = useSidePane()
  const {
    other: { colors },
  } = useMantineTheme()

  if (!paneVisible || !pane?.content) {
    return null
  }

  return (
    <Aside key={pane.key} height='100vh' width={{ xs: pane.width }}>
      <Flex h='100vh' direction='column' bg={colors.background[0]}>
        {pane.content}
      </Flex>
    </Aside>
  )
}
