import { Stack, TertiaryButton, Text, hideNotification, showNotification } from '@shared/components'
import { EMRNotificationResponse, hasGroupRole } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import differenceBy from 'lodash/differenceBy'
import isEqual from 'lodash/isEqual'
import { createElement, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import {
  getNotificationInfo,
  getNotificationTitle,
} from '../../pages/core/notifications/notificationUtils'

export const useToastNotifications = () => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()
  const [previousNotifications, setPreviousNotifications] = useState<
    EMRNotificationResponse[] | null
  >()
  const shouldShowToastNotifications = hasGroupRole(
    currentUser,
    'engineer',
    'enrollmentCoordinator',
  )

  const [notificationsQueryKey, notificationsQueryFunction] = emrApi.getQuery(
    'GET /emrNotifications',
    {
      query: {
        employeeId: currentUser.oid,
      },
    },
  )
  const [unseenNotificationsCountQueryKey] = emrApi.getQuery('GET /emrNotifications/count', {
    query: {
      employeeId: currentUser.oid,
    },
  })

  const markNotificationAsSeen = useMutation(
    emrApi.getMutation('PUT /emrNotifications/:notificationId'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(notificationsQueryKey)
        void queryClient.invalidateQueries(unseenNotificationsCountQueryKey)
      },
    },
  )

  const notificationsQuery = useQuery(notificationsQueryKey, notificationsQueryFunction, {
    enabled: Boolean(currentUser.oid) && shouldShowToastNotifications,
    refetchInterval: toTime('10 sec').ms(),
    onSuccess(data) {
      detectNewNotificationAndTrigger(data)
    },
  })

  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}`
    const notificationForCurrentPath = notificationsQuery.data?.find(
      notification => !notification.seen && notification.url === currentPath,
    )
    if (notificationForCurrentPath) {
      markNotificationAsSeen.mutate({
        params: { notificationId: notificationForCurrentPath.oid },
        data: { seenAt: dayjs().toISOString() },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, notificationsQuery.data])

  const onClick = ({ notificationId, url }: { notificationId: string; url: string }) => {
    hideNotification(notificationId)
    markNotificationAsSeen.mutate({
      params: { notificationId },
      data: { seenAt: dayjs().toISOString() },
    })
    navigate(url)
  }

  const detectNewNotificationAndTrigger = (latestNotifications: EMRNotificationResponse[]) => {
    if (!shouldShowToastNotifications) {
      return
    }

    /*
     * We never want to show notifications on the first load of the EMR app, only when the user is actively on the EMR
     * and a _new_ notification comes in
     */
    if (!previousNotifications) {
      setPreviousNotifications(latestNotifications)
      return
    }

    if (!isEqual(new Set(previousNotifications), new Set(latestNotifications))) {
      const newNotifications = differenceBy(latestNotifications, previousNotifications, 'oid')

      newNotifications.forEach(notification => {
        const { buttonIcon, buttonText } = getNotificationInfo(notification)

        showNotification({
          id: notification.oid,
          title: <Text bold>{getNotificationTitle(notification)}</Text>,
          message: (
            <Stack align='end' spacing='xs'>
              <Text>{notification.message}</Text>
              <TertiaryButton
                leftIcon={createElement(buttonIcon)}
                onClick={() => onClick({ notificationId: notification.oid, url: notification.url })}
              >
                {buttonText}
              </TertiaryButton>
            </Stack>
          ),
          autoClose: toTime('1 min').ms(),
          variant: 'neutral',
        })
      })

      setPreviousNotifications(latestNotifications)
    }
  }
}
