import {
  DownloadIcon,
  ExternalLinkText,
  Group,
  SecondaryButton,
  Text,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import { getTwilioUrl } from '../../../../../utils/twilio'
import { CommunicationsCard, CommunicationsCardProps } from '../CommunicationsCard'

type MessageFileProps = {
  url: string
  buttonPosition?: 'left' | 'right'
} & CommunicationsCardProps

export const MessageFile = ({ url, backgroundColor, textColor, ...props }: MessageFileProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <ExternalLinkText href={getTwilioUrl(url)} target='_blank'>
      <CommunicationsCard
        maxWidth='50%'
        p='md'
        backgroundColor={backgroundColor || colors.background[2]}
        {...props}
      >
        <Group spacing='sm'>
          <Tooltip label='Download file' position='top'>
            <SecondaryButton leftIcon={<DownloadIcon color={textColor || colors.text[1]} />} />
          </Tooltip>
          <Text color={textColor || colors.text[1]}>File shared</Text>
        </Group>
      </CommunicationsCard>
    </ExternalLinkText>
  )
}
