import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../api'
import { usePatient } from '../../pages/patient/PPatientContext'

export const useOrphanedConversation = () => {
  const queryClient = useQueryClient()
  const { patientId } = usePatient()

  const closeConversationMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/twilio/conversations/:conversationId/close'),
  )

  const [conversationsQueryCacheKey, conversationQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/twilio/conversations',
    {
      params: {
        patientId,
      },
    },
  )
  const conversationsQuery = useQuery(conversationsQueryCacheKey, conversationQueryFn)
  const activeConversationSid = conversationsQuery.data?.find(c => c.conversationState === 'active')
    ?.conversationSid

  const tasksQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/tasks', {
      params: {
        patientId,
      },
    }),
    { enabled: Boolean(activeConversationSid) },
  )

  const activeConversationTask = tasksQuery.data?.find(
    t => JSON.parse(t.attributes)?.conversationSid === activeConversationSid,
  )

  const handleCloseConversation = async () => {
    if (
      activeConversationSid &&
      confirm(
        `You are about to set update the state of conversation ID ${activeConversationSid} to "closed". Do you want to proceed?`,
      )
    ) {
      await closeConversationMutation.mutateAsync({
        params: {
          patientId,
          conversationId: activeConversationSid,
        },
      })
      void queryClient.invalidateQueries(conversationsQueryCacheKey)
    }
  }

  return {
    isOrphanedConversation:
      !tasksQuery.isLoading && activeConversationSid && !activeConversationTask,
    isLoading: closeConversationMutation.isLoading,
    handleCloseConversation,
  }
}
