import {
  Box,
  CircleWithText,
  EditIcon,
  Group,
  PlusCircleIcon,
  TertiaryButton,
  TitleThree,
} from '@shared/components'

export type CardHeaderProps = {
  title: string
  showButton: boolean
  buttonOnClick?: () => void
  buttonLabel: 'Edit' | 'Add'
  errorCount?: number
}

export const CardHeader = ({
  title,
  showButton,
  buttonOnClick,
  errorCount,
  buttonLabel,
}: CardHeaderProps) => {
  return (
    <Box
      sx={theme => ({
        borderBottomColor: theme.other.colors.background[3],
        borderBottomWidth: theme.other.sizes.border.md,
        paddingBottom: theme.other.sizes.padding.md,
      })}
    >
      <Group position='apart'>
        <Group spacing='sm' align='flex-start'>
          {errorCount ? <CircleWithText variant='error'>{errorCount}</CircleWithText> : null}
          <TitleThree>{title}</TitleThree>
        </Group>
        {showButton ? (
          <TertiaryButton
            rightIcon={
              buttonLabel === 'Edit' ? (
                <EditIcon color={colors => colors.actions[0]} />
              ) : (
                <PlusCircleIcon color={colors => colors.actions[0]} />
              )
            }
            onClick={buttonOnClick}
          >
            {buttonLabel}
          </TertiaryButton>
        ) : null}
      </Group>
    </Box>
  )
}
