import {
  Box,
  EditIcon,
  Grid,
  Group,
  Select,
  Stack,
  TertiaryButton,
  TitleFour,
} from '@shared/components'
import { RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS, stateAbbreviations } from '@shared/types'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { usePrimarySubscriberFormContext } from '../../../../utils/encounterUtils'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'
import { LabeledData } from '../components/LabeledData'

export type PrimarySubscriberSectionProps = {
  onSave: () => void
  onCancel: () => void
  isEditing: boolean
  saving: boolean
  showEditButton: boolean
  editOnClick: () => void
}

export const PrimarySubscriberSection = ({
  onSave,
  onCancel,
  isEditing,
  saving,
  showEditButton,
  editOnClick,
}: PrimarySubscriberSectionProps) => {
  const form = usePrimarySubscriberFormContext()
  return (
    <Stack>
      <Box
        sx={theme => ({
          borderBottomColor: theme.other.colors.background[3],
          borderBottomWidth: theme.other.sizes.border.md,
          paddingBottom: theme.other.sizes.padding.md,
        })}
      >
        <Group position='apart'>
          <TitleFour>Primary subscriber</TitleFour>
          {showEditButton ? (
            <TertiaryButton
              onClick={editOnClick}
              rightIcon={<EditIcon color={colors => colors.actions[0]} />}
            >
              Edit
            </TertiaryButton>
          ) : null}
        </Group>
      </Box>
      <Grid>
        <Grid.Col span={4}>
          <CardTextInput
            label='First name'
            {...form.getInputProps('first_name')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Last name'
            {...form.getInputProps('last_name')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Date of birth'
            {...form.getInputProps('date_of_birth')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            label='Gender'
            {...form.getInputProps('gender')}
            editable={isEditing}
            data={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'other', label: 'Other' },
              { value: 'not_given', label: 'Not given' },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 1'
            {...form.getInputProps('address.address1')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 2 (optional)'
            {...form.getInputProps('address.address2')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='City'
            {...form.getInputProps('address.city')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            data={stateAbbreviations}
            searchable
            label='State'
            {...form.getInputProps('address.state')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip'
            {...form.getInputProps('address.zip_code')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip plus four (optional)'
            {...form.getInputProps('address.zip_plus_four_code')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          {isEditing ? (
            <Select
              label='Patient relationship to subscriber'
              {...form.getInputProps('patient_relationship_to_subscriber_code')}
              data={RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS}
            />
          ) : (
            <LabeledData
              label='Patient relationship to subscriber code'
              data={form.values.patient_relationship_to_subscriber_code}
            />
          )}
        </Grid.Col>
      </Grid>
      {isEditing ? (
        <EditableSectionFooter onCancel={onCancel} onSave={onSave} isSaving={saving} />
      ) : null}
    </Stack>
  )
}
