import { useToggle } from '@mantine/hooks'
import { EMRTaskDecorated, getOpheliaHttpError } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { CloseOutageTaskModal } from './CloseOutageTaskModal'
import { CloseTaskNoteForm, CloseTaskNoteFormProps } from './CloseTaskNoteForm'
import { TimeSpentForm, useTimeSpentForm } from './TimeSpentForm'

export type CloseTaskModalProps = {
  task: EMRTaskDecorated
  onClose: () => void
}

const CLOSE_TASK_STEPS = ['TIME_SPENT_ON_TASK_FORM', 'CLOSE_TASK_NOTE_FORM'] as const
export const CloseTaskModal = ({ task, onClose }: CloseTaskModalProps) => {
  const [step, nextStep] = useToggle<(typeof CLOSE_TASK_STEPS)[number]>(CLOSE_TASK_STEPS)
  const timeSpentForm = useTimeSpentForm()

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))

  const closeTask = (noteForm: CloseTaskNoteFormProps) => {
    updateTaskMutation.mutate(
      {
        params: { taskId: task.oid },
        data: {
          task: { status: 'closed', employeeId: task.employeeId },
          note: noteForm.note ? { text: noteForm.note } : undefined,
          results: noteForm.results || undefined,
          timeEntry: {
            isBillableCoCM: Boolean(timeSpentForm.values.isBillableCoCM),
            minutes: Number(timeSpentForm.values.minutes),
          },
        },
      },
      { onSuccess: onClose },
    )
  }

  if (task.type === 'outage') {
    return <CloseOutageTaskModal task={task} onClose={onClose} />
  }

  if (step === 'TIME_SPENT_ON_TASK_FORM') {
    return (
      <TimeSpentForm
        task={task}
        title='Close issue'
        step={{ current: CLOSE_TASK_STEPS.indexOf(step) + 1, total: CLOSE_TASK_STEPS.length }}
        onClose={onClose}
        next={nextStep}
        form={timeSpentForm}
      />
    )
  }

  if (step === 'CLOSE_TASK_NOTE_FORM') {
    return (
      <CloseTaskNoteForm
        task={task}
        step={{ current: CLOSE_TASK_STEPS.indexOf(step) + 1, total: CLOSE_TASK_STEPS.length }}
        onClose={onClose}
        isSubmitting={updateTaskMutation.isLoading}
        onSubmit={closeTask}
        error={getOpheliaHttpError(
          updateTaskMutation.error,
          'Something went wrong, try again later.',
        )}
      />
    )
  }

  return null
}
