import {
  ClockIcon,
  FileIcon,
  GlobeIcon,
  Group,
  Pill,
  RefreshIcon,
  SecondaryButton,
  Text,
  TimelineCard,
  TimelineCardTitle,
  Tooltip,
  VideoIcon,
} from '@shared/components'
import {
  Employee,
  hasGroupRole,
  isAdmin,
  isCareCoordinator,
  isEnrollmentCoordinator,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { VisitPillStatus } from '../../../../components/VisitPillStatus'
import { useAuth } from '../../../../context/auth'
import { getVisitTypeLabel, getZoomUrl } from '../../../../utils/visits'
import { OScheduleVisitModal } from '../../../calendar/OScheduleVisitModal'
import { PatientTaskExtensionModal } from '../../../core/task_extension/PatientTaskExtensionModal'
import { JourneyItemContent, usePatient } from '../../PPatientContext'
import { ScheduleVisitDrawer } from '../../ScheduleVisitDrawer'
import { FooterContentSection } from './SharedContent'

const HOURS_BEFORE_RESCHEDULING = 24

const canReschedule = ({
  currentUser,
  appointment: { type, datetime },
}: {
  currentUser: Employee
  appointment: JourneyItemContent<'appointment'>
}) => {
  if (dayjs(datetime).isBefore(dayjs().add(HOURS_BEFORE_RESCHEDULING, 'hours'))) {
    // Can't reschedule if visit is in less than 24 hours
    return false
  }

  if (type !== 'Initial Visit' && isCareCoordinator(currentUser)) {
    // CCs can reschedule all visits except for intakes
    return true
  } else if (type === 'Initial Visit' && isEnrollmentCoordinator(currentUser)) {
    // ECs can only reschedule intake visits
    return true
  } else if (isAdmin(currentUser)) {
    // Admins and engineers can reschedule any visits
    return true
  }

  return false
}

export const UpcomingAppointmentContent = ({
  appointment,
  /*
   * Include displayDate as an optional param because we use this component in the upcoming visits section
   * and want to display the date there, but not in the journey
   */
  displayDate = false,
}: {
  appointment: JourneyItemContent<'appointment'>
  displayDate?: boolean
}) => {
  const { currentUser } = useAuth()
  const { patientQuery } = usePatient()
  const patientTimezone = patientQuery?.data?.account?.timezone
  const [showRescheduleVisitDrawer, setShowRescheduleVisitDrawer] = useState(false)
  const [showOScheduleVisitModal, setShowOScheduleVisitModal] = useState(false)
  const [showPatientTaskExtensionModal, setShowPatientTaskExtensionModal] = useState(false)
  const { zoomUrl, meetingCanceled } = getZoomUrl(currentUser, appointment)

  const time = dayjs(appointment.datetime)
  const isToday = dayjs(appointment.datetime).isToday()

  const rescheduleVisit = () => {
    if (appointment.type === 'Initial Visit') {
      setShowOScheduleVisitModal(true)
    } else {
      setShowRescheduleVisitDrawer(true)
    }
  }

  return (
    <TimelineCard
      styles={({ background }) => ({
        backgroundColor: background[6],
      })}
      leftSide={
        <TimelineCardTitle color={({ text }) => text[3]} title={getVisitTypeLabel(appointment)}>
          {isToday && (
            <Pill variant='filled' status='none'>
              Today
            </Pill>
          )}
          <VisitPillStatus visit={appointment} />
        </TimelineCardTitle>
      }
    >
      <FooterContentSection
        right={
          <Group spacing='sm' noWrap>
            {canReschedule({ currentUser, appointment }) && (
              <SecondaryButton
                color={({ text }) => text[3]}
                backgroundColor={({ background }) => background[6]}
                size='sm'
                onClick={() => rescheduleVisit()}
                leftIcon={<RefreshIcon color={colors => colors.actions[0]} />}
              >
                Reschedule
              </SecondaryButton>
            )}
            {!meetingCanceled && isToday && (
              <SecondaryButton
                color={({ text }) => text[3]}
                backgroundColor={({ background }) => background[6]}
                size='sm'
                href={zoomUrl}
                target='_blank'
                component='a'
                rel='noopener noreferrer'
                leftIcon={<VideoIcon color={colors => colors.actions[0]} />}
              >
                Join Zoom
              </SecondaryButton>
            )}
            <SecondaryButton
              color={({ text }) => text[3]}
              backgroundColor={({ background }) => background[6]}
              size='sm'
              component={Link}
              to={`/patients/${appointment.userId}/notes/${appointment.id}`}
              leftIcon={<FileIcon color={colors => colors.actions[0]} />}
            >
              Visit note
            </SecondaryButton>
            {appointment.type === 'Initial Visit' &&
              appointment.metadata?.patientTaskExpirationDatetime &&
              hasGroupRole(currentUser, 'admin', 'engineer', 'enrollmentCoordinator') && (
                <SecondaryButton
                  color={({ text }) => text[3]}
                  backgroundColor={({ background }) => background[6]}
                  size='sm'
                  onClick={() => setShowPatientTaskExtensionModal(true)}
                  leftIcon={<ClockIcon color={colors => colors.actions[0]} />}
                >
                  Extend task window
                </SecondaryButton>
              )}
          </Group>
        }
      >
        <Group spacing='xs' noWrap>
          <Text color={({ text }) => text[3]} style={{ whiteSpace: 'nowrap' }}>
            {displayDate
              ? `${time.format('MM/DD/YYYY')} | ${time.format('h:mma z')}`
              : time.format('h:mma z')}
          </Text>
          <Tooltip
            label={
              patientTimezone
                ? `Patient time: ${dayjs(appointment.datetime)
                    .tz(patientTimezone)
                    .format('h:mma z')}`
                : 'Missing time zone'
            }
          >
            <GlobeIcon color={colors => colors.background[5]} />
          </Tooltip>
        </Group>
        <Text style={{ whiteSpace: 'nowrap' }} color={({ text }) => text[3]}>
          {appointment.calendar}
        </Text>
      </FooterContentSection>

      {showOScheduleVisitModal && (
        <OScheduleVisitModal
          patientId={appointment.userId}
          rescheduleAppointment={appointment}
          closeModal={() => setShowOScheduleVisitModal(false)}
        />
      )}
      <ScheduleVisitDrawer
        opened={showRescheduleVisitDrawer}
        rescheduleAppointment={appointment}
        onClose={() => setShowRescheduleVisitDrawer(false)}
      />
      {showPatientTaskExtensionModal && (
        <PatientTaskExtensionModal
          appointmentId={appointment.oid}
          patientId={appointment.userId}
          onClose={() => setShowPatientTaskExtensionModal(false)}
        />
      )}
    </TimelineCard>
  )
}
