import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@shared/components'

type CreateWinbackCallModalProps = {
  onClose: () => void
  handleSubmit: () => void
  isLoading: boolean
}

const CreateWinbackCallContent = ({
  onClose,
  handleSubmit,
  isLoading,
}: CreateWinbackCallModalProps) => {
  return (
    <>
      <ModalHeader onClose={onClose}>Are you sure?</ModalHeader>
      <ModalContent>
        <Text>
          You are about to create a winback issue for this patient. Do you want to proceed?
        </Text>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, go back</SecondaryButton>
          <PrimaryButton loading={isLoading} disabled={isLoading} onClick={() => handleSubmit()}>
            Yes, create winback issue
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const CreateWinbackCallModal = ({
  opened,
  ...props
}: CreateWinbackCallModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <CreateWinbackCallContent {...props} />
    </BetterModal>
  )
}
