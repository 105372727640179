import { Group, Radio, RadioGroup, RadioGroupProps, Stack, Text } from '@shared/components'
import { PropsWithChildren } from 'react'

export const YesOrNoRadio = ({
  disabled,
  children,
  isEditing,
  invert = false,
  ...props
}: PropsWithChildren<Omit<RadioGroupProps, 'children'>> & {
  disabled?: boolean
  invert?: boolean
  isEditing: boolean
}) => {
  if (isEditing) {
    const response = invert ? 'no' : 'yes'
    return (
      <Stack spacing='sm'>
        <RadioGroup {...props} orientation='horizontal'>
          <Radio value='yes' label='Yes' disabled={disabled} />
          <Radio value='no' label='No' disabled={disabled} />
        </RadioGroup>
        {props.value === response ? children : undefined}
      </Stack>
    )
  }

  return (
    <Stack spacing='xs'>
      <Group spacing='xs' sx={{ overflowWrap: 'anywhere' }}>
        <Text color={colors => colors.text[1]}>{props.label}</Text>
      </Group>
      <Text transform='capitalize'>{props.value}</Text>
      {children}
    </Stack>
  )
}
