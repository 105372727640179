import { useForm } from '@mantine/form'
import { Accordion, Box, CircleWithText, Group, Space, Text } from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../../../api'
import { UploadPhoto } from '../../../../components/forms/UploadPhoto'
import { AttachmentsTable } from './AttachmentsTable'

export type AttachmentsSectionProps = {
  task: EMRTaskDecorated
}

type FormData = {
  fileId: string | undefined
}

export const AttachmentsSection = ({ task }: AttachmentsSectionProps) => {
  const form = useForm<FormData>()

  const [filesQueryKey, filesQueryFn] = emrApi.getQuery('GET /tasks/v2/:taskId/files', {
    params: {
      taskId: task.oid,
    },
  })
  const filesQuery = useQuery(filesQueryKey, filesQueryFn)
  const files = filesQuery.data || []

  const addFileByIdMutation = useMutation(
    emrApi.getMutation('POST /patient/:patientId/files/:fileId'),
  )

  const onUpload = ({ fileId, file }: { fileId: string; file: File }) => {
    if (!task.patientId) {
      // TODO(haven): Add file store for patient-agnostic EMR tasks
      return
    }

    addFileByIdMutation.mutate(
      {
        params: { patientId: task.patientId, fileId },
        data: { name: file.name, category: 'TASK_ATTACHMENT', details: { taskId: task.oid } },
      },
      {
        onSuccess: () => {
          void filesQuery.refetch()
          form.setFieldValue('fileId', undefined)
        },
      },
    )
  }

  const loading = addFileByIdMutation.isLoading || filesQuery.isFetching

  return (
    <Box sx={theme => ({ backgroundColor: theme.other.colors.background[1] })}>
      <Accordion>
        <Accordion.Item value='attachments'>
          <Accordion.Control>
            <Group>
              <Text>Attachments</Text>
              <CircleWithText variant='default'>{files.length}</CircleWithText>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Box mx='md' mb='md'>
              {files.length > 0 && (
                <>
                  <AttachmentsTable task={task} files={files} />
                  <Space h='md' />
                </>
              )}
              {task.patientId ? (
                <UploadPhoto
                  {...form.getInputProps('fileId')}
                  primaryText='Upload file'
                  setError={error => form.setFieldError('fileId', error)}
                  patientId={task.patientId}
                  loading={loading}
                  onUpload={onUpload}
                />
              ) : (
                <Text>File uploads on EMR tech issues not supported yet.</Text>
              )}
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}
