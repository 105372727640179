import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  useBanner,
} from '@shared/components'
import { useForm } from '@mantine/form'
import { isRequired } from '../../../../utils/formValidation'
import { validCharacters } from '@shared/utils'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useEmrMutation } from '../../../../utils/hooks'
import { getOpheliaHttpError } from '@shared/types'

export const TransferCollectionDocumentsModal = ({
  opened,
  onClose,
  patientId,
  collection,
  documentIds,
  onSubmit,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  collection: string
  documentIds: string[]
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const { getInputProps, values, validate } = useForm({
    initialValues: { toPatientId: '' },
    validate: { toPatientId: isRequired },
  })

  const transferCollectionDocuments = useEmrMutation(
    'PUT /patient/:patientId/collections/:collection/transfer',
  )

  return (
    <Modal
      title='Transfer collection documents'
      opened={opened}
      onClose={() => {
        if (!transferCollectionDocuments.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={transferCollectionDocuments.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={transferCollectionDocuments.isLoading}
            onClick={async () => {
              if (!validate().hasErrors) {
                await transferCollectionDocuments.mutateAsync(
                  {
                    params: { patientId, collection },
                    data: { toPatientId: values.toPatientId, documentIds },
                  },
                  {
                    onError: error => {
                      showBanner({
                        type: 'error',
                        dismissable: true,
                        label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                      })
                    },
                  },
                )

                invalidate({
                  patientId,
                  collections: [collection],
                  documents: documentIds.map(documentId => ({
                    collection,
                    documentId,
                  })),
                })

                invalidate({
                  patientId: values.toPatientId,
                  collections: [collection],
                  documents: documentIds.map(documentId => ({
                    collection,
                    documentId,
                  })),
                })

                onClose()
                onSubmit()
              }
            }}
          >
            Transfer
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>
          {`You will transfer ${documentIds.length} document(s) from the collection `}
          <Text bold span>
            {collection}
          </Text>
        </Text>
        <TextInput
          label='Patient ID'
          placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
          explanation='Patient to transfer selected collections'
          formatter={validCharacters.id}
          {...getInputProps('toPatientId')}
        />
      </Stack>
    </Modal>
  )
}
