export const IconEligible = () => {
  return (
    <svg
      width='128'
      height='16'
      viewBox='0 0 112 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6101_97700)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.25482 12.1952C8.95475 11.9058 3.82911 6.27698 3.82911 6.27698L5.18111 4.90583C6.43899 5.79003 8.42879 7.90229 8.42879 7.90229C11.042 4.13375 14.3661 1.60182 15.996 0.493363C15.5367 0.17268 14.9841 2.01709e-06 14.417 2.01709e-06H3.17249C1.68303 -0.00176727 0.473758 1.16064 0.470703 2.59708V13.4029C0.473758 14.8394 1.68303 16.0018 3.17249 16H14.417C15.1327 16.0014 15.8196 15.7286 16.3267 15.2415C16.8338 14.7545 17.1195 14.0931 17.121 13.4029V2.59708C17.121 1.99693 16.9062 1.44484 16.5496 1.00594C12.6188 4.45091 9.25482 12.1941 9.25482 12.1941V12.1952ZM25.1909 15.1617V0.50511H34.2209V3.13956H28.4984V6.40086H33.4148V9.03531H28.4984V12.5273H34.4811V15.1617H25.1909ZM40.7705 0.50511V12.5273H46.3844V15.1617H37.4409V0.50511H40.7705ZM48.6688 0.50511H51.9984V15.1617H48.6688V0.50511ZM66.9678 1.98946L64.8352 4.22666C64.2705 3.64146 63.4643 3.05627 62.0503 3.05627C60.0484 3.05627 58.352 4.12201 58.352 7.59261C58.352 11.2106 59.57 12.7782 62.1157 12.7782C62.573 12.7782 63.2473 12.6949 63.5518 12.5689V7.61396H66.8803V14.0746C65.7708 14.8061 64.2483 15.371 61.4192 15.371C57.0897 15.371 54.936 12.8615 54.936 7.96957C54.936 2.67931 58.1339 0.296874 62.181 0.296874C65.184 0.296874 66.5548 1.53027 66.9678 1.98946ZM70.1878 0.50511H73.5174V15.1617H70.1878V0.50511ZM88.2477 10.667C88.2477 12.5689 87.2467 15.1617 82.1775 15.1617H77.1294V0.50511H82.5905C85.289 0.50511 87.5069 1.36261 87.5069 4.31102C87.5069 5.96196 86.4417 7.00741 85.289 7.40466V7.48795C86.7462 7.65561 88.2477 8.59641 88.2477 10.667ZM82.1986 3.13956H80.4147V6.38057H82.069C83.679 6.38057 84.3091 5.58607 84.3091 4.74992C84.3091 3.85077 83.7665 3.13956 82.1986 3.13956ZM84.876 10.7706C84.876 9.49556 83.7654 9.01502 82.5252 9.01502H80.4147V12.5273H82.7002C84.3744 12.5273 84.876 11.6068 84.876 10.7706ZM94.5149 0.50511V12.5273H100.129V15.1617H91.1864V0.50511H94.5149ZM102.239 15.1617V0.50511H111.269V3.13956H105.547V6.40086H110.463V9.03531H105.547V12.5273H111.53V15.1617H102.239Z'
          fill='#122048'
        />
      </g>
      <defs>
        <clipPath id='clip0_6101_97700'>
          <rect width='111.059' height='16' fill='white' transform='translate(0.470703)' />
        </clipPath>
      </defs>
    </svg>
  )
}
