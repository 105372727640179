import { initCustomerIO } from '@shared/utils'
import { createRoot } from 'react-dom/client'
import { Root } from './Root'
import { Config } from './config'
import './index.css'
import * as FullStory from './utils/fullstory'

void (() => {
  FullStory.init()

  initCustomerIO({
    siteId: Config.CUSTOMER_IO_SITE_ID,
  })

  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)

  root.render(<Root />)
})()
