import { useQuery } from 'react-query'

type LottieFile = 'send-animation' | 'spider-animation'

export const useLottieAnimation = (animation: LottieFile) => {
  const animationQuery = useQuery(
    `lottie-${animation}`,
    async () => {
      const data = await fetch(`${process.env.IMAGE_BASE_URL}lottie/${animation}.json`)
      return data.json()
    },
    {
      // Disable cache
      cacheTime: 0,
    },
  )

  return animationQuery.data
}
