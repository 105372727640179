export enum LoadingContentType {
  Icon,
  Pill,
  Word,
  Line,
}

export enum LoadingContentSize {
  Small,
  Medium,
  Large,
}

export type LoadingContentProps = {
  type: LoadingContentType
  size?: LoadingContentSize
}

export const LoadingContent = ({ type, size }: LoadingContentProps) => {
  const style: string = getLoadingContentStyle(type, size)

  return <div className={style}></div>
}

const getLoadingContentStyle = (type: LoadingContentType, size?: LoadingContentSize): string => {
  switch (type) {
    case LoadingContentType.Icon:
      switch (size) {
        case LoadingContentSize.Small:
          return 'animate-pulse rounded-full h-5 w-5 bg-gray-400'
        case LoadingContentSize.Large:
          return 'animate-pulse rounded-full h-16 w-16 bg-gray-400'
        default:
          return 'animate-pulse rounded-full h-10 w-10 bg-gray-400'
      }
    case LoadingContentType.Pill:
      switch (size) {
        case LoadingContentSize.Small:
          return 'animate-pulse rounded-full h-5 w-8 bg-gray-400'
        case LoadingContentSize.Large:
          return 'animate-pulse rounded-full h-5 w-24 bg-gray-400'
        default:
          return 'animate-pulse rounded-full h-5 w-16 bg-gray-400'
      }
    case LoadingContentType.Word:
      switch (size) {
        case LoadingContentSize.Small:
          return 'inline-block animate-pulse rounded-md mx-1 my-2 py-2 h-5 w-16 bg-gray-400'
        case LoadingContentSize.Large:
          return 'inline-block animate-pulse rounded-md mx-1 my-2 py-2 h-5 w-36 bg-gray-400'
        default:
          return 'inline-block animate-pulse rounded-md mx-1 my-2 py-2 h-5 w-28 bg-gray-400'
      }
    case LoadingContentType.Line:
      switch (size) {
        case LoadingContentSize.Small:
          return 'animate-pulse rounded-md my-2 py-2 h-5 w-20 bg-gray-400'
        case LoadingContentSize.Medium:
          return 'animate-pulse rounded-md my-2 py-2 h-5 w-36 bg-gray-400'
        default:
          return 'animate-pulse rounded-md my-2 py-2 h-5 w-full bg-gray-400'
      }
    default:
      return 'animate-pulse rounded-md my-2 py-2 h-5 w-20 bg-gray-400'
  }
}

export const LoadingParagraph = ({
  wordCount,
  sizes,
}: {
  wordCount: number
  sizes: LoadingContentSize[]
}) => {
  const defaultWordSizes = [LoadingContentSize.Medium, LoadingContentSize.Large]
  const randomlySelectWordSize = () =>
    sizes
      ? sizes[Math.floor(Math.random() * sizes.length)]
      : defaultWordSizes[Math.floor(Math.random() * defaultWordSizes.length)]

  return (
    <p>
      {[...Array(wordCount)].map((_, i) => {
        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <LoadingContent
              type={LoadingContentType.Word}
              size={randomlySelectWordSize()}
            ></LoadingContent>
          </span>
        )
      })}
    </p>
  )
}
