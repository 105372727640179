import { IoMdDownload } from '@react-icons/all-files/io/IoMdDownload'
import { Appointment, ClosedNoteDetails, isAdmin, isCoordinator, Patient } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import ANoteCheckboxPositiveInput from '../../../components/atoms/ANoteCheckboxPositiveInput'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteSubheader from '../../../components/atoms/ANoteSubheader'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import ANoteYesNoRadioInput from '../../../components/atoms/ANoteYesNoRadioInput'
import { required } from '../../../components/molecules/MDictionary'
import ODSIconButton from '../../../components/ods/ODSIconButton'
import { useAuth } from '../../../context/auth'
import { ReactHookFormErrorsObject } from '../../../utils/reactHookForm'
import {
  drugTestVariables,
  exportToPdf,
  isNotAbleToWriteToNote,
  renderDurationString,
} from '../../../utils/utils'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'
import UpcomingVisitsSection from './UpcomingVisitsSection'

export type DrugScreenDefaults = {
  drug_screen_administered: string
  drug_screen_buprenorphine_result: boolean
  drug_screen_tramadol_result: boolean
  drug_screen_marijuana_result: boolean
  drug_screen_phencyclidine_result: boolean
  drug_screen_oxycodone_result: boolean
  drug_screen_opiates_result: boolean
  drug_screen_methadone_result: boolean
  drug_screen_methylenedioxymethamphetamine_result: boolean
  drug_screen_methamphetamine_result: boolean
  drug_screen_synthetic_cannabinoid_result: boolean
  drug_screen_fentanyl_result: boolean
  drug_screen_alcohol_result: boolean
  drug_screen_cocaine_result: boolean
  drug_screen_amphetamines_result: boolean
  drug_screen_barbiturates_result: boolean
  drug_screen_benzodiazepines_result: boolean
}

const defaultValues: DrugScreenDefaults = {
  drug_screen_administered: 'yes',
  drug_screen_buprenorphine_result: false,
  drug_screen_tramadol_result: false,
  drug_screen_marijuana_result: false,
  drug_screen_phencyclidine_result: false,
  drug_screen_oxycodone_result: false,
  drug_screen_opiates_result: false,
  drug_screen_methadone_result: false,
  drug_screen_methylenedioxymethamphetamine_result: false,
  drug_screen_methamphetamine_result: false,
  drug_screen_synthetic_cannabinoid_result: false,
  drug_screen_fentanyl_result: false,
  drug_screen_alcohol_result: false,
  drug_screen_cocaine_result: false,
  drug_screen_amphetamines_result: false,
  drug_screen_barbiturates_result: false,
  drug_screen_benzodiazepines_result: false,
}

type OUdsVisitFormProps = {
  patientID: string
  visitID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: Record<string, any> | null
  editMode: boolean
  sidebar: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closedNoteDetails: ClosedNoteDetails | undefined
  visit: Appointment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCloseNoteModal: (...args: any[]) => any
  patient: Patient
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meetingDetails?: Record<string, any> | null
}

const OUdsVisitForm = ({
  patientID,
  visitID,
  notes,
  editMode,
  sidebar,
  closedNoteDetails,
  visit,
  setCloseNoteModal,
  patient,
  meetingDetails,
}: OUdsVisitFormProps) => {
  const { errors, register, handleSubmit, setValue, watch } = useForm({ defaultValues })
  const { currentUser } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [savedAt, setSavedAt] = useState<string | null>(null)
  const [saving, setSaving] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [contentsToSave, setContentsToSave] = useState<Record<string, any> | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const masterWatcher: Record<string, any> = watch()

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  useEffect(() => {
    if (!notes) {
      return
    }
    for (const [key, value] of Object.entries(notes)) {
      if (drugTestVariables.includes(key)) {
        setValue(key, value === 'positive')
      } else {
        setValue(key, value)
      }
    }
  }, [notes, setValue, editMode])

  const save = useCallback(async () => {
    if (isNotAbleToWriteToNote(currentUser.calendarId, visit)) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const content: Record<string, any> = {
      ...masterWatcher,
    }

    if (masterWatcher.drug_screen_administered === 'no') {
      for (const key of drugTestVariables) {
        Reflect.deleteProperty(content, key)
      }
    }

    if (masterWatcher.drug_screen_administered === 'yes') {
      for (const key of drugTestVariables) {
        content[key] = masterWatcher[key] ? 'positive' : 'negative'
      }
    }

    const body = {
      type: 'uds' as const,
      content,
    }

    await updateVisitNote.mutateAsync(
      {
        params: {
          patientId: patientID,
          visitId: visitID,
        },
        data: body,
      },
      {
        onSuccess: () => {
          const savedTime = dayjs().format('MMMM Do YYYY, h:mm:ss a')
          setSavedAt(savedTime)
          setErrorMessage('')
          setContentsToSave(content)
        },
        onError: () => {
          setErrorMessage(
            'Failed to save your last edits. Please try to save again or contact the engineering team.',
          )
          setCloseNoteModal(false)
        },
      },
    )
  }, [editMode, patientID, visitID, visit, masterWatcher, currentUser, setCloseNoteModal])

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (!editMode) {
        return
      }
      if (isEmpty(masterWatcher)) {
        return
      }
      if (isEqual(masterWatcher, contentsToSave)) {
        return
      }

      await save()
    }, toTime('3 sec').ms())

    return () => clearTimeout(interval)
  }, [masterWatcher, editMode, save, contentsToSave])

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  const onSubmit = async () => {
    setCloseNoteModal(true)
    setSaving(true)
    await save()
  }

  const dateSigned = closedNoteDetails?.locked_at ? dayjs(closedNoteDetails.locked_at) : undefined

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      {!sidebar && (
        <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-50'>
          <div className='flex flex-row h-full'>
            <div className='flex justify-start items-center flex-grow-1 flex-shrink-0'>
              <div className='flex flex-col'>
                {errorMessage && editMode && (
                  <p className='text-xs text-error font-semibold'>{errorMessage}</p>
                )}
                {savedAt && editMode && (
                  <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
                )}
                {!editMode && closedNoteDetails && (
                  <p className='text-xs text-gray-500'>
                    Signed on
                    {dateSigned?.isValid()
                      ? dateSigned?.format(' MMMM D, YYYY [at] h:mm a ')
                      : ` ${closedNoteDetails.locked_at} `}
                    by {closedNoteDetails.locked_by_name}
                    <br />
                    {renderDurationString({
                      clinicianTime: meetingDetails?.clinicianTime,
                      patientTime: meetingDetails?.patientTime,
                    })}
                  </p>
                )}
              </div>
            </div>
            {editMode && (
              <MVisitNoteSavingHeader
                dateTime={visit.datetime}
                saveNote={async () => {
                  setSaving(true)
                  await save()
                }}
                saving={saving}
                editMode={editMode}
              />
            )}
            {(isAdmin(currentUser) || isCoordinator(currentUser)) && !editMode && (
              <div className='flex justify-end items-center w-full h-full'>
                <span className='ml-3 inline-flex rounded-md shadow-sm'>
                  <ODSIconButton
                    tooltipText={
                      closedNoteDetails ? 'Export to PDF' : 'Cannot download unlocked notes'
                    }
                    onClick={async e => {
                      e.stopPropagation()
                      await exportToPdf({
                        masterWatcher,
                        type: 'uds',
                        firstName: patient.personalData.firstName,
                        lastName: patient.personalData.lastName,
                        dateOfBirth: patient?.personalData.birthday,
                        userId: patientID,
                        datetime: dayjs(visit.datetime)
                          .tz(visit.timezone)
                          .format('dddd, MMMM D, YYYY hh:mmA'),
                        visitType: visit.type,
                        notes,
                      })
                    }}
                    icon={<IoMdDownload className='h-5 w-5' />}
                    className='ml-3'
                    disabled={!closedNoteDetails}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <ANoteSection hideBorder>
        <ANoteHeader text='Urine Drug Assessment' />
        <ANoteSectionContent className='grid gap-y-2 grid-cols-6'>
          <ANoteYesNoRadioInput
            name='drug_screen_administered'
            id='drug_screen_administered'
            ref={register({ required: { value: true, message: required } })}
            label='Was a drug screening administered?'
            disabled={!editMode}
            error={errors as ReactHookFormErrorsObject}
            className='col-span-6'
          />
          <ANoteSubheader
            text='Please mark which substances were positive.'
            className='col-span-6'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_buprenorphine_result'
            id='drug_screen_buprenorphine_result'
            ref={register()}
            label='Buprenorphine (BUP)'
            disabled={!editMode}
            className='col-span-6 my-4'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_alcohol_result'
            id='drug_screen_alcohol_result'
            ref={register()}
            label='Alcohol (ETG)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_amphetamines_result'
            id='drug_screen_amphetamines_result'
            ref={register()}
            label='Amphetamines (AMP)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_barbiturates_result'
            id='drug_screen_barbiturates_result'
            ref={register()}
            label='Barbiturates (BAR)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_benzodiazepines_result'
            id='drug_screen_benzodiazepines_result'
            ref={register()}
            label='Benzodiazepines (BZO)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_cocaine_result'
            id='drug_screen_cocaine_result'
            ref={register()}
            label='Cocaine (COC)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_fentanyl_result'
            id='drug_screen_fentanyl_result'
            ref={register()}
            label='Fentanyl (FEN)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_marijuana_result'
            id='drug_screen_marijuana_result'
            ref={register()}
            label='Marijuana (THC)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_methadone_result'
            id='drug_screen_methadone_result'
            ref={register()}
            label='Methadone (MTD)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_methamphetamine_result'
            id='drug_screen_methamphetamine_result'
            ref={register()}
            label='Methamphetamine (mAMP)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_methylenedioxymethamphetamine_result'
            id='drug_screen_methylenedioxymethamphetamine_result'
            ref={register()}
            label='Methylenediozymethamphetamine (MDMA)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_opiates_result'
            id='drug_screen_opiates_result'
            ref={register()}
            label='Opiates (OPI)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_oxycodone_result'
            id='drug_screen_oxycodone_result'
            ref={register()}
            label='Oxycodone (OXY)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_phencyclidine_result'
            id='drug_screen_phencyclidine_result'
            ref={register()}
            label='Phencyclidine (PCP)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_synthetic_cannabinoid_result'
            id='drug_screen_synthetic_cannabinoid_result'
            ref={register()}
            label='Synthetic Cannabinoid (K2)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteCheckboxPositiveInput
            name='drug_screen_tramadol_result'
            id='drug_screen_tramadol_result'
            ref={register()}
            label='Tramadol (TRAM)'
            disabled={!editMode}
            className='col-span-3'
          />
          <ANoteTextArea
            name='drug_screen_comments'
            id='drug_screen_comments'
            minRows={1}
            ref={register()}
            disabled={!editMode}
            error={errors as ReactHookFormErrorsObject}
            className='mt-4'
            sidebar={sidebar}
            placeholder={
              editMode ? 'Please include any noteworthy details' : 'No additional details'
            }
          />
        </ANoteSectionContent>
        <UpcomingVisitsSection isNoteClosed={Boolean(closedNoteDetails)} isSidebar={sidebar} />
      </ANoteSection>
    </form>
  )
}

export default OUdsVisitForm
