import { Task, TaskDetails } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class TasksApi extends BaseApi {
  list = (params: { page: number; per: number; open: boolean }) => {
    return this.json<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tasks: any[]
      pages: number
    }>({ method: 'GET', url: '/tasks', params })
  }

  createPrescription = (data: { prescriptionDetails: TaskDetails; selectedPatient: string }) => {
    return this.json<Task>({
      method: 'POST',
      url: '/tasks/prescription/create',
      data,
    })
  }

  updateTaskStatus = ({
    taskId,
    data,
  }: {
    taskId: string
    data: {
      prescription_task_sent: string
      prescription_task_notes: string
      status: boolean
    }
  }) => {
    return this.json({
      method: 'PUT',
      url: `/tasks/${taskId}/update`,
      data,
    })
  }
}
