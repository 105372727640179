import {
  Card,
  Group,
  Skeleton,
  Stack,
  Table,
  TertiaryButton,
  Text,
  TrashCanIcon,
  useMantineTheme,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi, patientsApi } from '../../../api'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

type PriorAuthRow = {
  priorAuthKey: string
  value: string
}

const PriorAuthRow = ({ priorAuthKey, value }: PriorAuthRow) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <tr className='mantine'>
      <td
        className='mantine'
        style={{
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          borderColor: colors.background[3],
        }}
      >
        <Text size='xs' style={{ whiteSpace: 'nowrap' }}>
          {priorAuthKey}
        </Text>
      </td>
      <td
        className='mantine'
        style={{
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          borderColor: colors.background[3],
        }}
      >
        <Text size='xs' style={{ whiteSpace: 'nowrap' }}>
          {value}
        </Text>
      </td>
    </tr>
  )
}

export const PriorAuthContent = ({ active }: { active: boolean }) => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const queryClient = useQueryClient()

  const [priorAuthsQueryKey] = emrApi.getQuery('GET /patient/:patientId/priorAuthorizations', {
    params: {
      patientId,
    },
  })

  const deletePriorAuth = useMutation(patientsApi.deletePriorAuthorization, {
    onSuccess: () => {
      void queryClient.invalidateQueries(priorAuthsQueryKey)
    },
  })

  const priorAuthorizationsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/priorAuthorizations', {
      params: {
        patientId,
      },
    }),
    {
      enabled: active,
    },
  )

  const priorAuthorizations = priorAuthorizationsQuery?.data

  const priorAuths = Object.keys(priorAuthorizations ?? {}).map(key => priorAuthorizations?.[key])

  if (priorAuthorizationsQuery.isLoading || priorAuthorizationsQuery.isError) {
    return <Skeleton h='5rem' />
  }

  if (!priorAuths.length) {
    return (
      <SidebarEmptyState
        pillMessage='No prior authorizations'
        textMessage={`Prior authorizations for ${
          patient?.personalData.preferredName || patient?.personalData.firstName
        } will appear here`}
      />
    )
  }

  const deletePriorAuthorization = async (priorAuthorizationId: string) => {
    await deletePriorAuth.mutateAsync({ patientId, priorAuthorizationId })
    void queryClient.invalidateQueries()
  }

  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]}>
        Prior authorizations
      </Text>
      {priorAuths.map(priorAuth => (
        <Card key={priorAuth?.oid}>
          <Stack spacing='sm'>
            <Text bold size='xs'>
              {priorAuth?.drug}
            </Text>
            <Table
              withBorder
              withColumnBorders
              sx={({ other: { colors, sizes } }) => ({
                borderWidth: sizes.border.md,
                borderStyle: 'solid',
                borderColor: colors.background[3],
              })}
            >
              <tbody>
                {priorAuth?.duration && (
                  <PriorAuthRow priorAuthKey='Duration' value={priorAuth.duration} />
                )}
                {priorAuth?.quantity && (
                  <PriorAuthRow priorAuthKey='Quantity' value={priorAuth.quantity} />
                )}
                {priorAuth?.startDate && (
                  <PriorAuthRow
                    priorAuthKey='Start'
                    value={dayjs(priorAuth.startDate).format('MM/DD/YYYY')}
                  />
                )}
                {priorAuth?.endDate && (
                  <PriorAuthRow
                    priorAuthKey='End'
                    value={dayjs(priorAuth.endDate).format('MM/DD/YYYY')}
                  />
                )}
              </tbody>
            </Table>
            <Group position='right'>
              <TertiaryButton
                disabled={deletePriorAuth.isLoading}
                loading={deletePriorAuth.isLoading}
                leftIcon={<TrashCanIcon />}
                onClick={() => priorAuth?.oid && deletePriorAuthorization(priorAuth?.oid)}
              >
                Delete
              </TertiaryButton>
            </Group>
          </Stack>
        </Card>
      ))}
    </Stack>
  )
}
