import {
  BetterDrawer,
  Box,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Grid,
  Group,
  JsonInput,
  LockIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  Tooltip,
  UnlockIcon,
  useBanner,
} from '@shared/components'
import { PatientOperation, getOpheliaHttpError, isPatientOperation } from '@shared/types'
import { useToggle } from '@mantine/hooks'
import { PropsWithChildren } from 'react'
import { diff } from 'deep-object-diff'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useInvalidateSubcollections } from '../use-invalidate-subcollections'
import { useEmrMutation } from '../../../../utils/hooks'
import { jsonFormat } from '@shared/utils'

type ViewPatientOperationDrawerProps = {
  onClose: () => void
  operation: PatientOperation | null
}

const ViewPatientOperationContent = ({ onClose, operation }: ViewPatientOperationDrawerProps) => {
  const { showBanner } = useBanner()
  const [unlockActions, toggleUnlockActions] = useToggle()
  const invalidateCollections = useInvalidateCollections()
  const invalidateSubcollections = useInvalidateSubcollections()

  const revert = useEmrMutation('PUT /patient-operations/:operationId/revert')

  return (
    <>
      <DrawerHeader onClose={onClose}>Operation {operation?.type}</DrawerHeader>
      <DrawerContent>
        <Stack m='md'>
          <PatientOperationContent operation={operation} />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        {operation?.type === 'update-subcollection-document' ? undefined : (
          <Group position='apart'>
            <Tooltip label='Toggle actions'>
              {unlockActions ? (
                <SecondaryButton leftIcon={<UnlockIcon />} onClick={() => toggleUnlockActions()} />
              ) : (
                <PrimaryButton leftIcon={<LockIcon />} onClick={() => toggleUnlockActions()} />
              )}
            </Tooltip>
            <Tooltip
              label={
                operation?.reverted ? 'Operation has already been reverted' : 'Revert the operation'
              }
            >
              <Box>
                <PrimaryButton
                  disabled={operation?.reverted || !unlockActions}
                  loading={revert.isLoading}
                  onClick={async () => {
                    await revert.mutateAsync(
                      { params: { operationId: operation?.oid ?? '' } },
                      {
                        onError: error => {
                          showBanner({
                            type: 'error',
                            dismissable: true,
                            label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                          })
                        },
                      },
                    )

                    if (operation) {
                      if (isPatientOperation(operation, 'transfer-collection')) {
                        invalidateCollections({
                          patientId: operation.patientId,
                          collections: [operation.collection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            collection: operation.collection,
                          })),
                        })

                        invalidateCollections({
                          patientId: operation.toPatientId,
                          collections: [operation.collection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            collection: operation.collection,
                          })),
                        })
                      } else if (isPatientOperation(operation, 'transfer-subcollection')) {
                        invalidateSubcollections({
                          patientId: operation.patientId,
                          subcollections: [operation.subcollection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            subcollection: operation.subcollection,
                          })),
                        })

                        invalidateSubcollections({
                          patientId: operation.toPatientId,
                          subcollections: [operation.subcollection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            subcollection: operation.subcollection,
                          })),
                        })
                      } else if (isPatientOperation(operation, 'delete-collection')) {
                        invalidateCollections({
                          patientId: operation.patientId,
                          collections: [operation.collection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            collection: operation.collection,
                          })),
                        })
                      } else if (isPatientOperation(operation, 'delete-subcollection')) {
                        invalidateSubcollections({
                          patientId: operation.patientId,
                          subcollections: [operation.subcollection],
                          documents: Object.keys(operation.data).map(documentId => ({
                            documentId,
                            subcollection: operation.subcollection,
                          })),
                        })
                      }

                      onClose()
                    }
                  }}
                >
                  Revert
                </PrimaryButton>
              </Box>
            </Tooltip>
          </Group>
        )}
      </DrawerFooter>
    </>
  )
}

const PatientOperationContent = ({ operation }: { operation: PatientOperation | null }) => {
  if (!operation) {
    return null
  }

  if (isPatientOperation(operation, 'transfer-collection')) {
    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='To patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.toPatientId}`}
              >
                {operation.toPatientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Collection'>{operation.collection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document count'>{Object.keys(operation.data).length}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Data'>
          <JsonInput w='100%' autosize value={jsonFormat(operation.data)} />
        </DisplayField>
      </>
    )
  }

  if (isPatientOperation(operation, 'transfer-subcollection')) {
    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='To patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.toPatientId}`}
              >
                {operation.toPatientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Subcollection'>{operation.subcollection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document count'>{Object.keys(operation.data).length}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Data'>
          <JsonInput w='100%' autosize value={jsonFormat(operation.data)} />
        </DisplayField>
      </>
    )
  }

  if (isPatientOperation(operation, 'delete-collection')) {
    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6} />
          <Grid.Col span={6}>
            <DisplayField title='Collection'>{operation.collection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document count'>{Object.keys(operation.data).length}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Data'>
          <JsonInput w='100%' autosize value={jsonFormat(operation.data)} />
        </DisplayField>
      </>
    )
  }

  if (isPatientOperation(operation, 'delete-subcollection')) {
    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6} />
          <Grid.Col span={6}>
            <DisplayField title='Subcollection'>{operation.subcollection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document count'>{Object.keys(operation.data).length}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Data'>
          <JsonInput w='100%' autosize value={jsonFormat(operation.data)} />
        </DisplayField>
      </>
    )
  }

  if (isPatientOperation(operation, 'update-collection-document')) {
    const afterDetails = diff(operation.before, operation.after)
    const beforeDetails = diff(operation.after, operation.before)

    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6} />
          <Grid.Col span={6}>
            <DisplayField title='Collection'>{operation.collection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document ID'>{operation.documentId}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Deleted'>
          <JsonInput minRows={19} value={jsonFormat(beforeDetails)} />
        </DisplayField>
        <DisplayField title='Added'>
          <JsonInput minRows={19} value={jsonFormat(afterDetails)} />
        </DisplayField>
      </>
    )
  }

  if (isPatientOperation(operation, 'update-subcollection-document')) {
    const afterDetails = diff(operation.before, operation.after)
    const beforeDetails = diff(operation.after, operation.before)

    return (
      <>
        <Grid columns={12}>
          <Grid.Col span={6}>
            <DisplayField title='From patient'>
              <TertiaryButton
                component='a'
                target='_blank'
                href={`/patients/${operation.patientId}`}
              >
                {operation.patientId}
              </TertiaryButton>
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6} />
          <Grid.Col span={6}>
            <DisplayField title='Subcollection'>{operation.subcollection}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Document ID'>{operation.documentId}</DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            <DisplayField title='Performed by'>
              <PerformedByContent operation={operation} />
            </DisplayField>
          </Grid.Col>
          <Grid.Col span={6}>
            {operation.reverted && (
              <DisplayField title='Reverted by'>
                <RevertedByContent operation={operation} />
              </DisplayField>
            )}
          </Grid.Col>
        </Grid>
        <DisplayField title='Deleted'>
          <JsonInput minRows={19} value={jsonFormat(beforeDetails)} />
        </DisplayField>
        <DisplayField title='Added'>
          <JsonInput minRows={19} value={jsonFormat(afterDetails)} />
        </DisplayField>
      </>
    )
  }

  return null
}

export const PerformedByContent = ({ operation }: { operation: PatientOperation }) => {
  if (operation.performedById) {
    return (
      <TertiaryButton component='a' target='_blank' href={`/employees/${operation.performedById}`}>
        {operation.performedById}
      </TertiaryButton>
    )
  }

  return <Text>{operation.performedBy}</Text>
}

export const RevertedByContent = ({ operation }: { operation: PatientOperation }) => {
  if (operation.revertedById) {
    return (
      <TertiaryButton component='a' target='_blank' href={`/employees/${operation.revertedById}`}>
        {operation.revertedById}
      </TertiaryButton>
    )
  }

  return <Text>{operation.revertedBy}</Text>
}

export const DisplayField = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  return (
    <Stack spacing='xs'>
      <Text bold>{title}</Text>
      <Text>{children}</Text>
    </Stack>
  )
}

export const ViewPatientOperationDrawer = ({
  opened,
  ...props
}: ViewPatientOperationDrawerProps & { opened: boolean }) => {
  return (
    <BetterDrawer opened={opened} onClose={props.onClose} position='right' size='xl'>
      <ViewPatientOperationContent {...props} />
    </BetterDrawer>
  )
}
