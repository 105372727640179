import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  showNotification,
} from '@shared/components'
import { PaymentPlan, YYYYMMDD } from '@shared/types'
import { dayjs, formatDollarAmount } from '@shared/utils'
import { useEmrMutation, useEmrQuery } from '../../../../utils/hooks'

type RemovePaymentPlanPaymentModalProps = {
  opened: boolean
  onClose: () => void
  paymentPlan: PaymentPlan
  paymentDate: YYYYMMDD
}

export const RemovePaymentPlanPaymentModal = ({
  opened,
  onClose,
  paymentPlan,
  paymentDate,
}: RemovePaymentPlanPaymentModalProps) => {
  const { refetch: refetchPaymentPlanInfo } = useEmrQuery('GET /patient/:patientId/payment-plans', {
    params: {
      patientId: paymentPlan.patientId,
    },
  })

  const cancelFuturePayment = useEmrMutation('DELETE /payment-plan/:paymentPlanId/cancel-payment')

  const paymentAmountToDeductInCents =
    paymentPlan.totalAmountInCents / paymentPlan.paymentDates.length

  const handleCancel = async () => {
    await cancelFuturePayment.mutateAsync(
      {
        params: {
          paymentPlanId: paymentPlan.oid,
        },
        data: {
          paymentDateToRemove: paymentDate,
        },
      },
      {
        onSuccess: () => {
          showNotification({
            message: 'Successfully deleted payment.',
            variant: 'success',
          })

          void refetchPaymentPlanInfo()
        },
        onError: () => {
          showNotification({
            message: 'Failed to delete payment, please try again.',
            variant: 'error',
          })
        },
      },
    )

    onClose()
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={`Delete payment for ${dayjs(paymentDate).format('M/D')}`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, go back</SecondaryButton>
          <PrimaryButton onClick={handleCancel} loading={cancelFuturePayment.isLoading}>
            Yes, continue
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Text>
          Deleting the payment will remove the scheduled payment and deduct{' '}
          {formatDollarAmount({ amount: paymentAmountToDeductInCents, unit: 'cents' })} from the
          total ABP amount.
        </Text>
        <Text>Are you sure you want to continue?</Text>
      </Stack>
    </Modal>
  )
}
