import { ConcentricCirclesIcon, Group, Text, Tooltip, useMantineTheme } from '@shared/components'
import { dayjs } from '@shared/utils'
import { useQuery } from 'react-query'
import { TaskInstance } from 'twilio/lib/rest/taskrouter/v1/workspace/task'
import { emrApi } from '../../api'
import * as Fullstory from '../../utils/fullstory'
import { usePatient } from './PPatientContext'

export const TwilioStatus = () => {
  const { patientId } = usePatient()

  const {
    other: { colors },
  } = useMantineTheme()

  const conversationsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/conversations', {
      params: {
        patientId,
      },
    }),
  )
  const conversations = conversationsQuery.data || []
  const hasActiveConversation = Boolean(conversations.find(c => c.conversationState === 'active'))

  const [tasksQueryCacheKey, tasksQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/twilio/tasks',
    {
      params: {
        patientId,
      },
    },
  )
  const tasksQuery = useQuery(tasksQueryCacheKey, tasksQueryFn)
  const tasks = tasksQuery.data || []
  const pendingTasks = tasks.filter(t => t.assignmentStatus === 'pending')

  const inStuckState = hasActiveConversation && tasks.length === 0
  if (inStuckState) {
    return (
      <Group spacing='xs'>
        <ConcentricCirclesIcon variant={colors.warning[0]} />
        <Text size='xs' bold>
          Patient in stuck Twilio state
        </Text>
      </Group>
    )
  }

  const hasUnassignedTask = pendingTasks.length > 0
  if (hasUnassignedTask) {
    const pendingTask = pendingTasks[0]
    const queueText = `${pendingTask?.taskQueueFriendlyName} queue`

    return (
      <Tooltip label={`Received ${dayjs(pendingTask?.dateCreated).format('h:mma z | MM/DD/YYYY')}`}>
        <Group spacing='xs'>
          <ConcentricCirclesIcon variant={colors.warning[0]} />
          <Text size='xs' bold>
            {`Unread message in ${queueText} queue`}
          </Text>
        </Group>
      </Tooltip>
    )
  }

  const inConversation = hasActiveConversation
  if (inConversation) {
    const activeConversation = conversations.find(c => c.conversationState === 'active')
    let activeConversationTask: TaskInstance | undefined
    try {
      activeConversationTask = tasksQuery.data?.find(
        t => JSON.parse(t.attributes)?.conversationSid === activeConversation?.conversationSid,
      )
    } catch (error) {
      Fullstory.log('error', JSON.stringify(error))
      return null
    }

    const queueText = `${activeConversationTask?.taskQueueFriendlyName} queue`

    return (
      <Tooltip label={`${queueText} queue`}>
        <Group spacing='xs'>
          <ConcentricCirclesIcon variant={colors.success[0]} />
          <Text size='xs' bold>
            Active conversation
          </Text>
        </Group>
      </Tooltip>
    )
  }

  return null
}
