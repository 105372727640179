import cn from 'classnames'
import React from 'react'

export type ODSRadioButtonProps = {
  buttonLabel?: string
  buttonValue: string
  disabled?: boolean
}

const ODSRadioButton = React.forwardRef<HTMLInputElement, ODSRadioButtonProps>(
  ({ buttonLabel, disabled = false, buttonValue, ...props }, ref) => (
    <div className='flex flex-row'>
      <input
        disabled={disabled}
        value={buttonValue}
        type='radio'
        ref={ref}
        className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
        {...props}
      />
      {buttonLabel && (
        <label className='ml-2'>
          <span
            className={cn('block text-sm leading-5 font-medium', {
              'text-gray-400': disabled,
              'text-gray-700': !disabled,
            })}
          >
            {buttonLabel}
          </span>
        </label>
      )}
    </div>
  ),
)

ODSRadioButton.displayName = 'ODSRadioButton'
export default ODSRadioButton
