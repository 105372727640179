import { CheckCircleIcon, CircleIcon, Divider, Flex, MantineColor } from '@shared/components'
import { ISOString } from '@shared/types'
import { dayjs } from '@shared/utils'

const RadioCircle = (props: { visit?: { datetime: ISOString }; color: MantineColor }) => {
  const isToday = props.visit && dayjs(props.visit.datetime).isToday()

  // If today, show a filled circle
  if (isToday) {
    return <CircleIcon fill='currentColor' style={{ padding: 2 }} color={props.color} />
  }

  const isBeforeToday = props.visit && dayjs(props.visit.datetime).isBefore(dayjs(), 'day')

  // If before today, show a checkmark for the completed visit
  if (isBeforeToday) {
    return <CheckCircleIcon style={{ padding: 2 }} color={props.color} />
  }

  // Otherwise, show an empty circle for all the remaining visits in the pathway (not yet booked)
  return <CircleIcon color={props.color} style={{ padding: 2 }} />
}

export const InductionCarePathwayProgress = ({ visits }: { visits: { datetime: ISOString }[] }) => {
  return (
    <Flex align='center'>
      {/* intake */}
      <CheckCircleIcon color={c => c.actions[0]} style={{ padding: 2 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first weekly */}
      <RadioCircle visit={visits[1]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second weekly */}
      <RadioCircle visit={visits[2]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      {/* third weekly */}
      <RadioCircle visit={visits[3]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      {/* fourth weekly */}
      <RadioCircle visit={visits[4]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first biweekly */}
      <RadioCircle visit={visits[5]} color={c => c.warning[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second biweekly */}
      <RadioCircle visit={visits[6]} color={c => c.warning[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first monthly */}
      <RadioCircle visit={visits[7]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
    </Flex>
  )
}

export const IntermediateCarePathwayProgress = ({
  visits,
}: {
  visits: { datetime: ISOString }[]
}) => {
  return (
    <Flex align='center'>
      {/* intake */}
      <CheckCircleIcon color={c => c.actions[0]} style={{ padding: 2 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first weekly */}
      <RadioCircle visit={visits[1]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second weekly */}
      <RadioCircle visit={visits[2]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first biweekly */}
      <RadioCircle visit={visits[3]} color={c => c.warning[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second biweekly */}
      <RadioCircle visit={visits[4]} color={c => c.warning[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first monthly */}
      <RadioCircle visit={visits[5]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second monthly - this is just for illustration, will not be filled */}
      <RadioCircle visit={undefined} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
    </Flex>
  )
}

export const StableCarePathwayProgress = ({ visits }: { visits: { datetime: ISOString }[] }) => {
  return (
    <Flex align='center'>
      {/* intake */}
      <CheckCircleIcon color={c => c.actions[0]} style={{ padding: 2 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first weekly */}
      <RadioCircle visit={visits[1]} color={c => c.error[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* first monthly */}
      <RadioCircle visit={visits[2]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* second monthly */}
      <RadioCircle visit={visits[3]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* third monthly biweekly */}
      <RadioCircle visit={visits[4]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
      {/* fourth monthly */}
      <RadioCircle visit={visits[5]} color={c => c.success[0]} />
      <Divider style={{ flexGrow: 1 }} />
      <Divider style={{ flexGrow: 1 }} />
    </Flex>
  )
}
