import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  ArrowRightIcon,
  BetterDrawer,
  Box,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  EditIcon,
  Group,
  PlusIcon,
  PrimaryButton,
  SaveIcon,
  SearchIcon,
  SecondaryButton,
  Select,
  Stack,
  TertiaryButton,
  Text,
  Textarea,
  TextInput,
  TrashIcon,
} from '@shared/components'
import { forwardRef, useState } from 'react'
import { MutationModal } from '../../components/MutationModal'
import * as FullStory from '../../utils/fullstory'
import { useInvalidateLunaQuery, useLunaMutation, useLunaQuery } from '../../utils/hooks'
import { SmartPhrase } from './MEmployeesSmartPhrasesTab'
import {
  SmartPhraseAddOrEditCategoryModal,
  SmartPhraseCategory,
} from './SmartPhraseAddOrEditCategoryModal'

type SmartPhraseAddOrEditDrawerProps = {
  opened: boolean
  onClose: () => void
  smartPhrase?: SmartPhrase
}

type SmartPhraseCategorySelectItemProps = {
  value: string
  label: string
  onClick: () => void
  onEdit: () => void
  onDelete: () => void
}

const NEW_CATEGORY_VALUE = 'NEW_CATEGORY'

export const SmartPhraseCategorySelectItem = forwardRef<
  HTMLDivElement,
  SmartPhraseCategorySelectItemProps
>((props, _ref) => {
  const { value, label, onEdit, onDelete, ...others } = props

  // If this is the button to add a new category, render the following
  if (value === NEW_CATEGORY_VALUE) {
    return (
      <Box p='sm' {...others}>
        <Group position='apart'>
          <Group spacing='sm'>
            <PlusIcon color={colors => colors.actions[0]} />
            <Text>Create new category</Text>
          </Group>
          <ArrowRightIcon color={colors => colors.actions[0]} />
        </Group>
      </Box>
    )
  }

  // Otherwise, render the existing category
  return (
    <Box p='sm' key={value} {...others}>
      {/* Add some right padding so that the scroll bar doesn't interfere with the delete button */}
      <Group position='apart' pr='sm'>
        <Text>{label}</Text>
        <Group>
          <TertiaryButton
            leftIcon={<EditIcon />}
            // Need to use onMouseDown to prevent the main onChange behavior
            onMouseDown={e => {
              e.stopPropagation()
              onEdit()
            }}
          />
          <TertiaryButton
            leftIcon={<TrashIcon />}
            // Need to use onMouseDown to prevent the main onChange behavior
            onMouseDown={e => {
              e.stopPropagation()
              onDelete()
            }}
          />
        </Group>
      </Group>
    </Box>
  )
})

// We need to specify the displayName or else React will throw an error
SmartPhraseCategorySelectItem.displayName = 'SmartPhraseCategorySelectItem'

export const SmartPhraseAddOrEditDrawer = ({
  opened,
  onClose,
  smartPhrase,
}: SmartPhraseAddOrEditDrawerProps) => {
  const invalidateLunaQuery = useInvalidateLunaQuery()

  const isEditing = Boolean(smartPhrase)

  const [isDisplayingAddOrEditCategoryModal, toggleAddOrEditCategoryModal] = useToggle()
  const [isDisplayingDeleteCategoryModal, toggleDeleteCategoryModal] = useToggle()
  const [selectedCategory, setSelectedCategory] = useState<SmartPhraseCategory | null>(null)

  const smartPhraseCategoriesQuery = useLunaQuery('GET /smart-phrase-categories')
  const smartPhraseCategories = smartPhraseCategoriesQuery.data?.data || []

  const addSmartPhraseMutation = useLunaMutation('POST /smart-phrases')

  const editSmartPhraseMutation = useLunaMutation('PUT /smart-phrases/:smartPhraseId')

  const smartPhraseForm = useForm({
    initialValues: {
      name: smartPhrase?.name || '',
      categoryId:
        smartPhraseCategories.find(category => category.name === smartPhrase?.categoryName)?.oid ||
        '',
      text: smartPhrase?.text || '',
    },
  })

  const addOrEditSmartPhrase = () => {
    if (smartPhraseForm.validate().hasErrors) {
      return
    }

    if (isEditing) {
      editSmartPhraseMutation.mutate(
        {
          params: {
            smartPhraseId: smartPhrase?.oid || '',
          },
          data: {
            ...smartPhraseForm.values,
          },
        },
        {
          onSuccess: () => {
            void invalidateLunaQuery('GET /smart-phrases')

            smartPhraseForm.reset()

            onClose()
          },
        },
      )
    } else {
      addSmartPhraseMutation.mutate(
        {
          data: {
            ...smartPhraseForm.values,
          },
        },
        {
          onSuccess: () => {
            FullStory.event('Smart Phrase Added')

            void invalidateLunaQuery('GET /smart-phrases')

            smartPhraseForm.reset()

            onClose()
          },
        },
      )
    }
  }

  const handleEditCategory = (category: SmartPhraseCategory) => {
    setSelectedCategory(category)
    toggleAddOrEditCategoryModal(true)
  }

  const handleDeleteCategory = (category: SmartPhraseCategory) => {
    setSelectedCategory(category)
    toggleDeleteCategoryModal(true)
  }

  const smartPhraseCategoriesSelectData = [
    ...smartPhraseCategories.map(category => ({
      value: category?.oid,
      label: category?.name,
      onEdit: () => handleEditCategory(category),
      onDelete: () => handleDeleteCategory(category),
    })),
    // We're going to use the following value to allow clinicians to add a new category through the Select dropdown
    {
      value: NEW_CATEGORY_VALUE,
      label: 'Create new category',
      // Cannot edit or delete a new category
      onEdit: undefined,
      onDelete: undefined,
    },
  ]

  const searchSmartPhraseCategories = (query: string) => {
    return smartPhraseCategories.filter(
      category => category?.name.toLowerCase().includes(query.toLowerCase()),
    )
  }

  return (
    <>
      <SmartPhraseAddOrEditCategoryModal
        opened={isDisplayingAddOrEditCategoryModal}
        onClose={() => toggleAddOrEditCategoryModal(false)}
        smartPhraseCategory={selectedCategory}
        onSubmit={(categoryId: string) => {
          void invalidateLunaQuery('GET /smart-phrase-categories')

          smartPhraseForm.setValues({ categoryId })

          toggleAddOrEditCategoryModal(false)
        }}
      />
      <MutationModal
        header={`Delete ${selectedCategory?.name} label`}
        description={`Are you sure you want to delete "${selectedCategory?.name}" label? It will be
          removed from all smart phrases`}
        callToAction='Yes, delete label'
        opened={isDisplayingDeleteCategoryModal}
        onClose={() => toggleDeleteCategoryModal(false)}
        endpoint='DELETE /smart-phrase-categories/:smartPhraseCategoryId'
        payload={{
          params: {
            smartPhraseCategoryId: selectedCategory?.oid || '',
          },
        }}
        onSuccess={() => {
          void invalidateLunaQuery('GET /smart-phrases')
          void invalidateLunaQuery('GET /smart-phrase-categories')

          smartPhraseForm.setValues({ categoryId: '' })

          toggleDeleteCategoryModal(false)
        }}
        // Add a z-index so that the modal displays above the drawer
        zIndex={1000}
      />
      <BetterDrawer
        position='right'
        size='lg'
        opened={opened}
        onClose={() => {
          smartPhraseForm.reset()
          onClose()
        }}
      >
        <DrawerHeader onClose={onClose}>{isEditing ? 'Edit' : 'Add'} smart phrase</DrawerHeader>
        <DrawerContent>
          <Stack p='md'>
            <TextInput label='Smart phrase' {...smartPhraseForm.getInputProps('name')} />
            <Select
              label='Category (optional)'
              data={smartPhraseCategoriesSelectData}
              onSearchChange={(value: string) => searchSmartPhraseCategories(value)}
              icon={<SearchIcon />}
              searchable
              clearable
              disabled={smartPhraseCategoriesQuery.isLoading}
              placeholder='Search or create new...'
              {...smartPhraseForm.getInputProps('categoryId')}
              itemComponent={SmartPhraseCategorySelectItem}
              onChange={(value: string) => {
                if (value === NEW_CATEGORY_VALUE) {
                  toggleAddOrEditCategoryModal(true)
                  setSelectedCategory(null)
                  return
                }

                smartPhraseForm.setValues({ categoryId: value })
              }}
            />
            <Textarea
              label='Text'
              {...smartPhraseForm.getInputProps('text')}
              minRows={5}
              maxRows={32}
              autosize
            />
          </Stack>
        </DrawerContent>
        <DrawerFooter>
          <Group position='right'>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            <PrimaryButton
              loading={addSmartPhraseMutation.isLoading || editSmartPhraseMutation.isLoading}
              leftIcon={<SaveIcon />}
              onClick={addOrEditSmartPhrase}
            >
              Save
            </PrimaryButton>
          </Group>
        </DrawerFooter>
      </BetterDrawer>
    </>
  )
}
