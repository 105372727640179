import { useForm } from '@mantine/form'
import {
  ArrowLeftCircleIcon,
  ChevronRightIcon,
  DatePicker,
  Group,
  Modal,
  PrimaryButton,
  Select,
  SelectItem,
  skipIfOtherField,
  Stack,
  TertiaryButton,
  TitleThree,
  validateWith,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useMemo } from 'react'
import { isRequired } from '../../../../utils/formValidation'
import {
  getRelativeTimeUnitData,
  RelativeTimeSelectItem,
} from '../../../core/inputs/RelativeTimeSelectItem'
import { TaskProgress } from '../TaskProgress'

type Props = {
  onClose: () => void
  onBack: () => void
  next: () => void
  step: { current: number; total: number }
  form: ReturnType<typeof useSnoozeForm>['form']
}

const CUSTOM = 'custom'

const getSnoozeUntilData = (): SelectItem[] => {
  const BASE_DATE = dayjs().tz('America/New_York')
  const FIFTEEN_MINUTES = BASE_DATE.add(15, 'minutes')
  const THIRTY_MINUTES = BASE_DATE.add(30, 'minutes')
  const TWO_HOURS = BASE_DATE.add(2, 'hours')
  const TOMORROW = BASE_DATE.add(1, 'day')
  const NEXT_MONDAY = BASE_DATE.add(1, 'week').day(1)
  const ONE_WEEK = BASE_DATE.add(1, 'week')

  return [
    {
      label: '15 minutes',
      description: FIFTEEN_MINUTES.format('h:mma z'),
      value: FIFTEEN_MINUTES.toISOString(),
    },
    {
      label: '30 minutes',
      description: THIRTY_MINUTES.format('h:mma z'),
      value: THIRTY_MINUTES.toISOString(),
    },
    {
      label: 'Two hours',
      description: TWO_HOURS.format('h:mma z'),
      value: TWO_HOURS.toISOString(),
    },
    {
      label: 'Tomorrow',
      description: TOMORROW.format('dddd, MMMM D, YYYY hh:mma z'),
      value: TOMORROW.toISOString(),
    },
    {
      label: 'Next Monday',
      description: NEXT_MONDAY.format('dddd, MMMM D, YYYY hh:mma z'),
      value: NEXT_MONDAY.toISOString(),
    },
    {
      label: 'One week',
      description: ONE_WEEK.format('dddd, MMMM D, YYYY hh:mma z'),
      value: ONE_WEEK.toISOString(),
    },
    {
      label: 'Custom date & time',
      description: <ChevronRightIcon />,
      value: CUSTOM,
    },
  ]
}

export const useSnoozeForm = () => {
  const form = useForm<{
    remindAt: string
    remindAtDate: string
    remindAtCustom: string
  }>({
    initialValues: {
      remindAt: '',
      remindAtDate: '',
      remindAtCustom: '',
    },
    validate: {
      // the validation is mututally exclusive between these two fields
      remindAt: validateWith(isRequired),
      remindAtDate: validateWith(skipIfOtherField('remindAt', 'not', CUSTOM), isRequired),
      remindAtCustom: validateWith(skipIfOtherField('remindAt', 'not', CUSTOM), isRequired),
    },
  })

  return {
    form,
    remindAt: form.values.remindAt === CUSTOM ? form.values.remindAtCustom : form.values.remindAt,
  }
}

export const SnoozeTaskUntilForm = ({ form, onClose, onBack, next, step }: Props) => {
  const showCustomDatePicker = form.values.remindAt === CUSTOM
  const showCustomTimeSelect = Boolean(form.values.remindAtDate)
  const customSnoozeUntilData = useMemo(() => {
    return getRelativeTimeUnitData(form.values.remindAtDate)
  }, [form.values.remindAtDate])

  return (
    <Modal
      opened
      onClose={onClose}
      title={
        <Group align='center'>
          <TertiaryButton ml='xs' leftIcon={<ArrowLeftCircleIcon styled />} onClick={onBack} />
          <TitleThree>Snooze issue</TitleThree>
        </Group>
      }
      footer={
        <Group position='right'>
          <PrimaryButton
            onClick={() => {
              if (!form.validate().hasErrors) {
                next()
              }
            }}
            rightIcon={<ChevronRightIcon />}
          >
            Next
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <TaskProgress step={step.current} total={step.total} />
        <Select
          label='Snooze until'
          placeholder='Select one...'
          itemComponent={RelativeTimeSelectItem}
          data={useMemo(getSnoozeUntilData, [])}
          {...form.getInputProps('remindAt')}
        />
        {showCustomDatePicker && (
          <DatePicker
            minDate={dayjs().toDate()}
            maxDate={dayjs().add(1, 'year').toDate()}
            placeholder='Select a date...'
            {...form.getInputProps('remindAtDate')}
          />
        )}
        {showCustomDatePicker && showCustomTimeSelect && (
          <Select
            placeholder='Select a time...'
            itemComponent={RelativeTimeSelectItem}
            data={customSnoozeUntilData}
            {...form.getInputProps('remindAtCustom')}
          />
        )}
      </Stack>
    </Modal>
  )
}
