import {
  CalendarIcon,
  DateInput,
  Drawer,
  GlobeIcon,
  Group,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  Text,
  TextInput,
  UserIcon,
} from '@shared/components'
import { Day, OcpClinician, OcpOverride, StateAbbr } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { ocpApi } from '../../../api'

export type OverrideDrawerProps = {
  overrideInfoOnDate: {
    date: Date
    dayOfWeek: Day
    state: StateAbbr
    scheduledProviderName: string
    scheduledProviderId: string
  }
  onCallProviders: OcpClinician[]
  isOpen: boolean
  onClose: () => void
  existingOverride: OcpOverride | undefined
}

const OverrideDrawer = ({
  overrideInfoOnDate,
  onCallProviders,
  isOpen,
  onClose,
  existingOverride,
}: OverrideDrawerProps) => {
  const [selectedOverrideProvider, setSelectedOverrideProvider] = useState<string | null>(null)
  const { date, dayOfWeek, state, scheduledProviderName, scheduledProviderId } = overrideInfoOnDate
  const eligibleOnCallProviders = onCallProviders.filter(
    employee => employee.oid !== scheduledProviderId && employee.licensedStates?.includes(state),
  )

  const deleteOverride = useMutation(ocpApi.deleteOverride, {
    onSuccess: () => {
      void queryClient.invalidateQueries('ocpApi.getRecurring')
      void queryClient.invalidateQueries('ocpApi.getOverrides')
      onClose()
    },
  })
  const queryClient = useQueryClient()
  const saveOverride = useMutation(ocpApi.createOverride, {
    onSuccess: () => {
      deleteOverride.mutate({ overrideId: existingOverride?.oid || '' })
      void queryClient.invalidateQueries('ocpApi.getRecurring')
      void queryClient.invalidateQueries('ocpApi.getOverrides')
      setSelectedOverrideProvider(null)
      onClose()
    },
  })

  return (
    <Drawer
      size='lg'
      opened={isOpen}
      onClose={onClose}
      title='Edit on call provider'
      position='right'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() =>
              selectedOverrideProvider &&
              saveOverride.mutate({
                state,
                dayOfWeek,
                overrideDate: dayjs(date).format('MM/DD/YYYY'),
                employeeId: selectedOverrideProvider,
                createdAt: new Date().toISOString(),
                deleted: false,
              })
            }
            disabled={!selectedOverrideProvider}
          >
            Save
          </PrimaryButton>
        </Group>
      }
    >
      <Stack p='md'>
        <TextInput icon={<GlobeIcon />} label={<Text bold>State</Text>} value={state} disabled />
        <DateInput
          icon={<CalendarIcon />}
          label={<Text bold>Date</Text>}
          value={dayjs(date).format('MM/DD/YYYY')}
          disabled
        />
        <TextInput
          icon={<UserIcon />}
          label={<Text bold>Scheduled Provider</Text>}
          value={scheduledProviderName}
          disabled
        />
        <Select
          label={<Text bold>Override Provider</Text>}
          placeholder='Select a provider'
          data={eligibleOnCallProviders.map(provider => ({
            value: provider.oid,
            label: provider.name,
          }))}
          value={selectedOverrideProvider}
          onChange={setSelectedOverrideProvider}
          disabled={eligibleOnCallProviders.length === 0}
        />
        {Boolean(existingOverride) && (
          <PrimaryButton
            onClick={() => deleteOverride.mutate({ overrideId: existingOverride?.oid || '' })}
            fullWidth
          >
            Set original provider as OCP
          </PrimaryButton>
        )}
      </Stack>
    </Drawer>
  )
}

export default OverrideDrawer
