import { Appointment, Employee, getVisitTypeName, isClinician } from '@shared/types'
import { isLocationURLPortalRedirect, template, zoom } from '@shared/utils'

export const getVisitTypeLabel = (appointment: Appointment) => {
  return template(['{visitType}', '({duration} min)'], {
    visitType: getVisitTypeName(appointment.type),
    duration: appointment.type === 'Follow-Up Visit' ? appointment?.duration : undefined,
  })
}

export const getZoomUrl = (currentUser: Employee, appointment: Appointment | null) => {
  const meetingCanceled = appointment?.canceled

  /**
   * We expose the raw meetingId and passcode for the Zoom meeting
   * so coordinators can copy these values if needed for patients who
   * are onboarding offline (e.g. BCCF referrals).
   */
  const meetingId = appointment?.metadata?.virtualAppointmentDetails?.id
  const passcode = appointment?.metadata?.virtualAppointmentDetails?.password

  /**
   * This url is unique, as it sends patients through the portal first
   * before redirecting them to the Zoom meeting. This allows patients to
   * complete any necessary tasks before joining their appointment.
   */
  const urlToSendToPatient = zoom(
    appointment?.metadata?.virtualAppointmentDetails?.patientRedirectUrl,
  ).zoomUrl

  /**
   * If patientJoinUrl is unavailable for some reason, use the location (see comment below about location url changes over time)
   */
  const patientJoinUrl = zoom(
    appointment?.metadata?.virtualAppointmentDetails?.patientJoinUrl ?? appointment?.location,
  ).zoomUrl

  /**
   * The appointment.location url is now the the portal redirect link,
   * but it used to be the regular zoom join url
   */
  const isLocationPortalRedirect = isLocationURLPortalRedirect(appointment?.location)

  let { zoomUrl } = zoom(appointment?.metadata?.virtualAppointmentDetails?.generalJoinUrl)

  if (isClinician(currentUser)) {
    /**
     * If the current user is the employee associated with the appointment,
     * show the clinicianJoinUrl as the zoom url. This url includes the Zoom
     * host's Zoom token, and "starts" the meeting.
     */
    if (currentUser.oid === appointment?.employeeId) {
      /**
       * Clinicians should never see the portal redirect link in the EMR
       * We should still show the location url as a fallback in the EMR
       * for very old appointments where the location is still just the
       * regular zoom join url
       */
      zoomUrl = isLocationPortalRedirect
        ? zoom(appointment?.metadata?.virtualAppointmentDetails?.clinicianJoinUrl).zoomUrl
        : zoom(
            appointment?.metadata?.virtualAppointmentDetails?.clinicianJoinUrl ??
              appointment?.location,
          ).zoomUrl
    }
  }

  return {
    meetingCanceled,
    zoomUrl,
    patientJoinUrl,
    urlToSendToPatient,
    meetingId,
    passcode,
  }
}
