import { BetterDrawer, DrawerContent, DrawerHeader, useBanner } from '@shared/components'
import {
  Appointment,
  AppointmentTypeString,
  CalendarColumnData,
  CalendarColumnHeader,
  isCoordinator,
  isPrimaryClinician,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect, useState } from 'react'
import { useAuth } from '../../context/auth'
import OVisits from '../calendar/OVisits'
import { useAppointments, useBlocks, useWorkingHours } from '../calendar/hooks'
import { getWeekRange } from '../calendar/utils'
import { usePatient } from './PPatientContext'
import { useInitialBhcm } from './use-initial-bhcm'

export type ScheduleVisitDrawerProps = {
  onClose: () => void
  rescheduleAppointment?: Appointment | undefined
  visitTypesAllowed?: AppointmentTypeString[]
}

const ScheduleVisitContent = ({
  onClose,
  rescheduleAppointment,
  visitTypesAllowed,
}: ScheduleVisitDrawerProps) => {
  const { primaryClinicianQuery } = usePatient()

  const { currentUser } = useAuth()

  const primaryClinician = primaryClinicianQuery?.data

  const { shouldScheduleInitialBhcmVisit, ccmCalendarId } = useInitialBhcm()

  const getClinicianCalendarId = () => {
    if (shouldScheduleInitialBhcmVisit) {
      return ccmCalendarId
    } else if (rescheduleAppointment) {
      return rescheduleAppointment.calendarID.toString()
    } else if (isPrimaryClinician(currentUser) && currentUser && currentUser.calendarId) {
      return currentUser.calendarId.toString()
    } else if (isCoordinator(currentUser) && primaryClinician && primaryClinician.calendarId) {
      return primaryClinician.calendarId.toString()
    }
    return primaryClinician?.calendarId.toString() || ''
  }

  const [date, setDate] = useState<string>(
    rescheduleAppointment
      ? dayjs(rescheduleAppointment.datetime).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  )

  const [calendarId, setCalendarId] = useState<string>(getClinicianCalendarId())

  const { dates, minDate, maxDate } = getWeekRange(date)

  const { appointments, isLoading: appointmentsLoading } = useAppointments({
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
    calendarID: calendarId,
  })

  const { blocks, isLoading: blocksLoading } = useBlocks({
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
    calendarID: calendarId,
  })

  const { workingHours, isLoading: workingHoursLoading } = useWorkingHours({
    calendarId,
    minDate: minDate.toISOString(),
    maxDate: maxDate.toISOString(),
  })

  const isLoading = appointmentsLoading || blocksLoading || workingHoursLoading

  const columns: CalendarColumnHeader[] = dates.map(date => {
    return {
      id: date.day().toString(),
      date: date.format('YYYY-MM-DD'),
      name: date.format('ddd'),
      day: date.format('DD'),
    }
  })

  const data: CalendarColumnData[] = isLoading
    ? []
    : dates.map(day => {
        const dayAppointments = (appointments || []).filter(appointment =>
          day.isSame(dayjs(appointment.datetime).tz(appointment.timezone), 'day'),
        )
        const dayBlocks = (blocks || []).filter(block => day.isSame(block.start, 'day'))

        return {
          id: day.format('YYYY-MM-DD'),
          date: day.format('YYYY-MM-DD'),
          visits: dayAppointments,
          blocks: dayBlocks,
          workingHours: workingHours ? workingHours[day.format('YYYY-MM-DD')] : undefined,
        }
      })

  useEffect(() => {
    if (primaryClinicianQuery?.data) {
      // Re-run the clinician calendar ID logic if the primary clinician query data changes
      setCalendarId(getClinicianCalendarId())
    }
  }, [primaryClinicianQuery?.data])

  // If the primary clinician query is enabled and loading, let it finish before rendering the drawer
  if (primaryClinicianQuery?.isLoading) {
    return null
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>
        {rescheduleAppointment ? 'Reschedule visit' : 'Schedule visit'}
      </DrawerHeader>
      <DrawerContent>
        <OVisits
          columns={columns}
          data={data}
          date={date}
          setDate={setDate}
          calendarId={calendarId}
          setCalendarId={setCalendarId}
          isLoading={isLoading}
          drawerStyling
          rescheduleAppointment={rescheduleAppointment}
          onCloseDrawer={onClose}
          visitTypesAllowed={visitTypesAllowed}
        />
      </DrawerContent>
    </>
  )
}

export const ScheduleVisitDrawer = ({
  opened,
  onClose,
  ...props
}: ScheduleVisitDrawerProps & { opened: boolean }) => {
  const { cleanBanner } = useBanner()

  const onCloseDrawer = () => {
    cleanBanner()
    onClose()
  }

  return (
    <BetterDrawer opened={opened} onClose={onCloseDrawer} size='80%' position='right'>
      <ScheduleVisitContent {...props} onClose={onCloseDrawer} />
    </BetterDrawer>
  )
}
