import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import {
  Alert,
  DatePicker,
  Grid,
  InfoIcon,
  Select,
  Stack,
  TextInput,
  TitleThree,
  validateWith,
} from '@shared/components'
import {
  FormattedStripeSubscription,
  MAX_ISO_STRING,
  Patient,
  dischargeReasonToSubscriptionPauseBehavior,
  getOpheliaHttpError,
  hasRole,
  isAutoPayEnabled,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { isConditionallyRequired, isRequired } from '../../../utils/formValidation'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const AUTO_PAY_STATUSES = ['On', 'Pause', 'Off'] as const
type AutoPayStatus = (typeof AUTO_PAY_STATUSES)[number]

const getPaymentMethod = (patient?: Patient) => {
  return {
    pausedUntil: patient?.autoPayment?.pausedUntil ?? ('' as const),
    pausedReason: patient?.autoPayment?.pausedReason ?? '',
  }
}

const getAutoPayStatus = (patient?: Patient): AutoPayStatus => {
  // If pauseUntil is set to the maximum ISO string, then auto payments are effectively turned off.
  if (patient?.autoPayment?.pausedUntil === MAX_ISO_STRING) {
    return 'Off'
  }

  // If the pauseUntil date exists and is in the future, then auto payments are paused.
  if (
    patient?.autoPayment?.pausedUntil &&
    dayjs(patient.autoPayment.pausedUntil).isAfter(dayjs())
  ) {
    return 'Pause'
  }

  // If the pausedUntil date does not exist or the pauseUntil date exists and is in the past, then auto payments are effectively turned on.
  return 'On'
}

export const PatientProfilePaymentMethod = () => {
  const { patientId, patientQuery } = usePatient()
  const { currentUser } = useAuth()
  const patient = patientQuery?.data
  const [autoPayStatus, setAutoPayStatus] = useState<AutoPayStatus>(getAutoPayStatus(patient))
  const queryClient = useQueryClient()
  const form = useForm<ReturnType<typeof getPaymentMethod>>({
    initialValues: getPaymentMethod(patient),
    validate: {
      pausedUntil: validateWith(isConditionallyRequired(autoPayStatus === 'Pause'), isRequired),
    },
  })

  const initializeForm = (patient?: Patient) => {
    setAutoPayStatus(getAutoPayStatus(patient))
    form.setValues(getPaymentMethod(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const canEdit = hasRole(currentUser, 'financialCounselor', 'engineer')

  const [isEditing, toggleIsEditing] = useToggle()

  const payloads: Record<AutoPayStatus, Patient['autoPayment']> = {
    On: {
      // If autoPayStatus is 'on', then we want to clear the pausedUntil, pausedReason, and pausedAt fields.
      pausedUntil: null,
      pausedReason: null,
      pausedAt: null,
    },
    Pause: {
      pausedUntil: form.values.pausedUntil || null,
      pausedReason: form.values.pausedReason,
      pausedAt: dayjs().toISOString(),
    },
    Off: {
      pausedUntil: MAX_ISO_STRING,
      pausedReason: null,
      pausedAt: dayjs().toISOString(),
    },
  }

  const [subscriptionQueryKey, subscriptionQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/subscription',
    { params: { patientId } },
  )

  const subscriptionQuery = useQuery(subscriptionQueryKey, subscriptionQueryFn, {
    enabled: Boolean(patient) && !patient?.statuses?.insuranceAllowList,
  })

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await updatePatient.mutateAsync({
      params: {
        patientId,
      },
      data: {
        autoPayment: payloads[autoPayStatus],
      },
    })

    if (subscriptionQuery?.data) {
      // Optimistically update the subscription query data to reflect the new pauseBehavior. Necessary since the subscription is updated via subscriber.
      queryClient.setQueryData<FormattedStripeSubscription | undefined>(
        subscriptionQueryKey,
        oldData => {
          if (oldData) {
            return {
              ...oldData,
              // If the patient is currently paused, compute the new pauseBehavior based on the discharge reason. Otherwise, if the patient will be paused, set to 'keep_as_draft'. Otherwise, patient won't be paused, so set to null.
              pauseBehavior:
                (patient?.statuses.patient === 'paused' &&
                  dischargeReasonToSubscriptionPauseBehavior(patient?.discharge?.reason)) ||
                (!isAutoPayEnabled({ autoPayment: payloads[autoPayStatus] }) && 'keep_as_draft') ||
                null,
            }
          }
        },
      )
    }
    void patientQuery?.refetch()

    toggleIsEditing()
  }

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const getAlertText = () => {
    const autoPayStatusChanged =
      patient &&
      isAutoPayEnabled(patient) !== isAutoPayEnabled({ autoPayment: payloads[autoPayStatus] })

    // Show the alert if patient is on cash pay, autoPay status has changed, and the patient is not paused.
    const showPauseSubscriptonAlert =
      isEditing &&
      !patient?.statuses.insuranceAllowList &&
      autoPayStatusChanged &&
      subscriptionQuery.data

    if (!showPauseSubscriptonAlert) {
      return null
    }

    const subscriptionPauseBehavior = subscriptionQuery?.data?.pauseBehavior
    const isSubscriptionCurrentlyPaused = Boolean(subscriptionQuery?.data?.pauseBehavior)
    const willSubscriptionBePaused =
      !isAutoPayEnabled({ autoPayment: payloads[autoPayStatus] }) ||
      patient?.statuses?.patient === 'paused'

    if (isSubscriptionCurrentlyPaused && willSubscriptionBePaused) {
      return subscriptionPauseBehavior === 'void'
        ? `${patient?.personalData?.firstName}'s subscription will remain paused in Stripe and invoices will be voided since patient is currently paused due to ${patient?.discharge?.reason}.`
        : `${patient?.personalData?.firstName}'s subscription will remain paused in Stripe and invoices will continue to be generated in Draft status.`
    }

    if (!isSubscriptionCurrentlyPaused && !willSubscriptionBePaused) {
      return `${patient?.personalData?.firstName}'s subscription will remain active in Stripe.`
    }

    if (willSubscriptionBePaused) {
      return subscriptionPauseBehavior === 'void'
        ? `${patient?.personalData?.firstName}'s subscription will automatically pause in Stripe and invoices will be voided`
        : `${patient?.personalData?.firstName}'s subscription will automatically be paused in Stripe and invoices will continue to be generated in Draft status.`
    }

    return `${patient?.personalData?.firstName}'s subscription will automatically resume in Stripe.`
  }

  const alertText = getAlertText()

  return (
    <EditableSection
      isLoading={patientQuery?.isLoading ?? false}
      title={<TitleThree>Payment method</TitleThree>}
      isEditing={isEditing}
      onEdit={canEdit ? () => toggleIsEditing() : null}
      isSaving={updatePatient.isLoading}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      onSave={() => onSave()}
      error={getOpheliaHttpError(updatePatient.error, `Error updating patient's payment method`)}
    >
      <Stack>
        <Grid>
          <EditableCol
            label='Automatic payments'
            span={4}
            isEditing={isEditing}
            text={autoPayStatus}
          >
            <Select
              value={autoPayStatus}
              onChange={status => {
                if (status) {
                  setAutoPayStatus(status as AutoPayStatus)
                  /*
                   * If selecting 'Pause' when patient was previously NOT on autopay (ie, "Off"),
                   * then set default `pausedUntil` of one week in future. This prevents `MAX_ISO_STRING`
                   * from being applied to the date picker, which causes errors.
                   */
                  if (status === 'Pause' && form.values.pausedUntil === MAX_ISO_STRING) {
                    form.setValues({
                      pausedUntil: dayjs().add(1, 'week').toISOString(),
                    })
                  }
                }
              }}
              data={AUTO_PAY_STATUSES}
            />
          </EditableCol>
          {/* These additional fields are only relevant if automatic payments are paused. */}
          {autoPayStatus === 'Pause' && (
            <>
              <EditableCol
                label='Resume on'
                span={4}
                isEditing={isEditing}
                text={dayjs(form.values.pausedUntil).format('MM/DD/YYYY')}
              >
                <DatePicker
                  minDate={dayjs().add(1, 'day').toDate()}
                  maxDate={dayjs().add(1, 'year').toDate()}
                  placeholder='Select a date...'
                  {...form.getInputProps('pausedUntil')}
                />
              </EditableCol>
              <EditableCol
                label='Reason (optional)'
                span={4}
                isEditing={isEditing}
                text={form.values.pausedReason}
              >
                <TextInput {...form.getInputProps('pausedReason')} />
              </EditableCol>
            </>
          )}
        </Grid>
        {alertText && (
          <Alert icon={<InfoIcon />} variant='primary'>
            {alertText}
          </Alert>
        )}
      </Stack>
    </EditableSection>
  )
}
