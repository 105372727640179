import React from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation } from 'react-query'
import { visitNotesApi } from '../../../api'
import AFormGroup from '../../../components/atoms/AFormGroup'
import ALabel from '../../../components/atoms/ALabel'
import ODSInput from '../../../components/ods/ODSInput'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import TModal from '../../../components/templates/TModal'

const UnlockNoteModal = ({
  patientId,
  appointmentId,
  closeModal,
}: {
  patientId: string
  appointmentId: string
  closeModal: () => void
}) => {
  const { register, handleSubmit } = useForm<{
    verifyNeed: boolean
    canceledVist: boolean
    deleteEncounter: boolean
  }>()

  const unlockVisitNote = useMutation(visitNotesApi.unlockVisitNote, {
    onSuccess: () => {
      closeModal()
      window.location.reload()
    },
  })

  const onSubmit = handleSubmit(() => {
    unlockVisitNote.mutate({
      patientId,
      visitId: appointmentId,
    })
  })

  return (
    <TModal closeModal={closeModal}>
      <div className='w-full flex flex-col'>
        <span className='font-semibold text-xl mb-3'>Unlock note (engineers only)</span>
        <form onSubmit={onSubmit}>
          <AFormGroup className='flex flex-row'>
            <ODSInput
              {...register('verifyNeed', { required: { value: true, message: 'Required' } })}
              type='checkbox'
              className='md:w-4 h-4 mr-2 mt-1'
            />
            <ALabel colon={false}>
              I have verified that the need cannot be fulfilled by adding an addendum.
            </ALabel>
          </AFormGroup>
          <AFormGroup className='flex flex-row'>
            <ODSInput
              {...register('canceledVist', { required: { value: true, message: 'Required' } })}
              type='checkbox'
              className='md:w-4 h-4 mr-2 mt-1'
            />
            <ALabel colon={false}>If this visit was canceled, I have deleted the encounter.</ALabel>
          </AFormGroup>
          <AFormGroup className='flex flex-row'>
            <ODSInput
              {...register('deleteEncounter', { required: { value: true, message: 'Required' } })}
              type='checkbox'
              className='md:w-4 h-4 mr-2 mt-1'
            />
            <ALabel colon={false}>
              If this patient is on insurance pay (allow list), I have checked if the encounter was
              already submitted. If it was not submitted, I have deleted it. If it was submitted, I
              have spoken to the billing team about the best course of action.
            </ALabel>
          </AFormGroup>
          <div className='flex flex-col'>
            <span className='font-semibold'></span>
            <span className='mt-1 max-w-sm'>
              <ODSPrimaryButton
                type='submit'
                message='Unlock note'
                loading={unlockVisitNote.isLoading}
              />
            </span>
            {unlockVisitNote.error ? (
              <span className='pt-4 text-error font-semibold'>Unlocking visit note failed.</span>
            ) : null}
          </div>
        </form>
      </div>
    </TModal>
  )
}

export default UnlockNoteModal
