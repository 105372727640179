import { InputWrapper, Pill } from '@shared/components'
import { usePatient } from '../../../PPatientContext'
import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteReadOnlyText } from '../visitNoteForm/PatientVisitNoteReadOnlyText'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextarea } from '../visitNoteForm/PatientVisitNoteTextarea'

export const MotivationsForTreatmentSection = ({ form }: { form: OnboardingVisitNoteForm }) => {
  const { patientQuery } = usePatient()

  const patient = patientQuery?.data

  return (
    <PatientVisitNoteSection title='Motivations for Treatment'>
      {patient?.motivationsForTreatment?.motivationsDescription ? (
        <PatientVisitNoteReadOnlyText
          label={`Patient's "why"`}
          value={patient.motivationsForTreatment.motivationsDescription}
        />
      ) : (
        <Pill variant='filled' status='warning'>
          None submitted
        </Pill>
      )}
      <InputWrapper label='Other relevant information'>
        <PatientVisitNoteTextarea
          placeholder='Type here...'
          {...form?.getInputProps('motivations_for_treatment_more_details')}
        />
      </InputWrapper>

      <PatientVisitNoteNotDiscussedCheckbox
        field='motivations_for_treatment_more_details'
        form={form}
      />
    </PatientVisitNoteSection>
  )
}
