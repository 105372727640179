import {
  Alert,
  ArrayDownIcon,
  Divider,
  Group,
  InfoIcon,
  RadioGroup,
  Text,
} from '@shared/components'
import { InsuranceQueue, InsuranceReviewQueueItem } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import { useEffect } from 'react'
import IRQListElement from './IRQListElement'

export type IRQListProps = {
  selectedItem?: InsuranceReviewQueueItem
  setSelectedItem: (id: InsuranceReviewQueueItem) => void
  queue: InsuranceReviewQueueItem[]
  isLoading: boolean
  activeTab: InsuranceQueue
}

const IRQList = ({ selectedItem, setSelectedItem, queue, isLoading }: IRQListProps) => {
  const candidates = queue
    .filter(item => item.statuses.patient === 'candidate')
    .sort((itemA, itemB) => {
      if (itemA.upcomingIntake && itemB.upcomingIntake) {
        return (
          dayjs(itemA.upcomingIntake.datetime).valueOf() -
          dayjs(itemB.upcomingIntake.datetime).valueOf()
        )
      }
      return 0
    })
  const inTreatment = queue
    .filter(item => item.statuses.patient === 'in treatment')
    .sort(sortBy({ key: 'createdAt' }))

  const others = queue
    .filter(
      item => item.statuses.patient !== 'candidate' && item.statuses.patient !== 'in treatment',
    )
    .sort(sortBy({ key: 'createdAt' }))

  // This useEffect will run when the queue is refetched. It will set the selected item to the top of the queue, or will set it to the most up to date version of the selected item if it is still in the queue.
  useEffect(() => {
    const selectedItemInQueue = queue.find(item => item.id === selectedItem?.id)

    if (selectedItemInQueue) {
      return setSelectedItem(selectedItemInQueue)
    }

    if (candidates.length) {
      return setSelectedItem(candidates[0]!)
    }

    if (inTreatment.length) {
      return setSelectedItem(inTreatment[0]!)
    }

    if (others.length) {
      return setSelectedItem(others[0]!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, candidates, inTreatment, others])

  if (isLoading) {
    return <Text>Loading...</Text>
  }

  if (!queue.length && !isLoading) {
    return <Text>No patients on the queue.</Text>
  }

  return (
    <>
      {candidates.length > 0 && (
        <>
          <Group position='apart' mb='md'>
            <Text bold>Candidates</Text>
            <Group spacing='xs'>
              <Text>Time to intake visit</Text>
              <ArrayDownIcon />
            </Group>
          </Group>
          <RadioGroup
            value={selectedItem?.id}
            onChange={id => setSelectedItem(queue.filter(elem => elem.id === id)[0]!)}
          >
            {candidates.map(item => (
              <IRQListElement item={item} key={item.id} />
            ))}
          </RadioGroup>
          <Divider my='md' />
        </>
      )}
      {inTreatment.length > 0 && (
        <>
          <Group position='apart' mb='md'>
            <Text bold>In treatment</Text>
            <Group spacing='xs'>
              <Text>Time added to queue</Text>
              <ArrayDownIcon />
            </Group>
          </Group>
          <RadioGroup
            value={selectedItem?.id}
            onChange={id => setSelectedItem(queue.filter(elem => elem.id === id)[0]!)}
          >
            {inTreatment.map(item => (
              <IRQListElement item={item} key={item.id} />
            ))}
          </RadioGroup>
          <Divider my='md' />
        </>
      )}
      {others.length > 0 && (
        <>
          <Group position='apart' mb='md'>
            <Text bold>Other statuses</Text>
            <Group spacing='xs'>
              <Text>Time added to queue</Text>
              <ArrayDownIcon />
            </Group>
          </Group>
          <RadioGroup
            value={selectedItem?.id}
            onChange={id => setSelectedItem(queue.filter(elem => elem.id === id)[0]!)}
          >
            {others.map(item => (
              <IRQListElement item={item} key={item.id} />
            ))}
          </RadioGroup>
          <Divider my='md' />
        </>
      )}
      <Alert variant='primary' icon={<InfoIcon />}>
        In order to be on the IRQ the patient must have financial agreement signed, birthday and
        insurance cards on file.
      </Alert>
    </>
  )
}

export default IRQList
