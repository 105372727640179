import { useToggle } from '@mantine/hooks'
import {
  AccordionTimelineCard,
  ArchiveIcon,
  ClockIcon,
  Pill,
  RichTextEditor,
  TertiaryButton,
  Text,
  TimelineCardTitle,
  Tooltip,
  UserIcon,
} from '@shared/components'
import { CaseReviewNote, Patient } from '@shared/types'
import { dayjs, template } from '@shared/utils'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../api'
import { LockedCaseReviewDrawer } from '../../../registry/caseReview/LockedCaseReviewDrawer'
import { JourneyItemContent, usePatient } from '../../PPatientContext'
import { FieldSkeleton, FooterContentSection } from './SharedContent'

export const CaseReviewContent = ({
  caseReview,
}: {
  caseReview: JourneyItemContent<'case-review-note'>
}) => {
  const [expand, toggleExpand] = useToggle()
  const { patientQuery } = usePatient()
  const [openLockedCaseReviewDrawer, setOpenLockedCaseReviewDrawer] = useState(false)
  const datetime = dayjs(caseReview.createdAt)
  const attestedBy = caseReview.attestation?.attestedBy

  const attestedByQuery = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: {
        employeeId: attestedBy as string,
      },
    }),
    { enabled: Boolean(attestedBy) && expand },
  )

  return (
    <AccordionTimelineCard
      onExpand={value => toggleExpand(value)}
      leftSide={
        <TimelineCardTitle title='Case review'>
          <CaseReviewPill caseReview={caseReview} />
        </TimelineCardTitle>
      }
    >
      <RichTextEditor value={caseReview.content?.summary ?? ''} editable={false} />
      <FooterContentSection
        right={
          <TertiaryButton
            onClick={() => setOpenLockedCaseReviewDrawer(true)}
            leftIcon={<ArchiveIcon />}
          >
            View
          </TertiaryButton>
        }
      >
        <FooterContentSection.Item>
          <UserIcon color={({ text }) => text[1]} />
          <FieldSkeleton isLoading={attestedByQuery.isLoading} isError={attestedByQuery.isError}>
            <Text size='xs' style={{ whiteSpace: 'nowrap' }} color={({ text }) => text[1]}>
              {attestedByQuery.data?.name ?? attestedBy}
            </Text>
          </FieldSkeleton>
        </FooterContentSection.Item>
        <FooterContentSection.Item>
          <ClockIcon color={({ text }) => text[1]} />
          <Text size='xs' style={{ whiteSpace: 'nowrap' }} color={({ text }) => text[1]}>
            {datetime.format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
      </FooterContentSection>
      <LockedCaseReviewDrawer
        showName={false}
        opened={openLockedCaseReviewDrawer}
        onClose={() => setOpenLockedCaseReviewDrawer(false)}
        patient={patientQuery?.data as Patient}
        caseReviewNote={caseReview}
      />
    </AccordionTimelineCard>
  )
}

const CaseReviewPill = ({ caseReview }: { caseReview: CaseReviewNote }) => {
  const [isHovered, setIsHovered] = useState(false)

  const { data: employee } = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: { employeeId: caseReview.lockedBy as string },
    }),
    { enabled: Boolean(caseReview.lockedBy) && isHovered },
  )

  if (caseReview.isLocked) {
    return (
      <Tooltip
        label={template(['Locked', 'by {lockedBy}', 'at {datetime}'], {
          lockedBy: employee?.name ?? caseReview.lockedBy,
          datetime: dayjs(caseReview.lockedAt).format('MM/DD/YYYY h:mma z'),
        })}
        onMouseEnter={() => setIsHovered(true)}
        position='top'
      >
        <Pill variant='filled' status='success'>
          Locked
        </Pill>
      </Tooltip>
    )
  }

  return (
    <Pill variant='filled' status='error'>
      Open
    </Pill>
  )
}
