import { Group, Modal, PrimaryButton, SecondaryButton } from '@shared/components'
import { DecoratedCaseReviewItem, DecoratedCocmItem } from '@shared/types'
import { name } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { caseReviewNoteApi, cocmRegistryApi, emrApi } from '../../../api'

type RemovePatientCaseReviewModalProps = {
  patientToRemove: DecoratedCocmItem | DecoratedCaseReviewItem | null
  onCloseRemovePatientModal: () => void
}

const RemovePatientCaseReviewModal = ({
  patientToRemove,
  onCloseRemovePatientModal,
}: RemovePatientCaseReviewModalProps) => {
  const queryClient = useQueryClient()

  const updateRegistryItem = useMutation(cocmRegistryApi.update)
  const deleteCaseReviewNote = useMutation(caseReviewNoteApi.delete)
  const getCaseReviewNotes = useMutation(
    emrApi.getMutation('GET /caseReviewNote/patient/:patientId'),
  )

  const onRemovePatient = async () => {
    if (patientToRemove) {
      await updateRegistryItem.mutateAsync({
        patientId: patientToRemove.patientId,
        data: { needsReview: false },
      })
      const openNote = await getCaseReviewNotes.mutateAsync({
        params: { patientId: patientToRemove.patientId },
        query: {
          limit: '1',
          isLocked: 'false',
          isAttested: 'false',
        },
      })
      if (openNote.length > 0) {
        await deleteCaseReviewNote.mutateAsync(openNote[0]!.oid)
      }
      void queryClient.invalidateQueries('GET /caseReview')
      void queryClient.invalidateQueries('GET /cocmRegistry')
      onCloseRemovePatientModal()
    }
  }

  return (
    <Modal
      opened={Boolean(patientToRemove)}
      onClose={onCloseRemovePatientModal}
      title={`Remove ${name({
        first: patientToRemove?.firstName,
        last: patientToRemove?.lastName,
      }).full()} from case review`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onCloseRemovePatientModal}>
            No, don&apos;t remove
          </SecondaryButton>
          <PrimaryButton onClick={onRemovePatient}>Yes, remove</PrimaryButton>
        </Group>
      }
    >
      By removing{' '}
      {name({
        first: patientToRemove?.firstName,
        last: patientToRemove?.lastName,
      }).full()}
      , you will lose any unlocked notes you have already added to the case review note and any time
      tracking associated with this note. Are you sure you want to proceed?
    </Modal>
  )
}

export default RemovePatientCaseReviewModal
