import { UseFormReturnType } from '@mantine/form'
import { useToggle, useViewportSize } from '@mantine/hooks'
import {
  Alert,
  Drawer,
  DrawerProps,
  Group,
  InfoIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ProgressDots,
  Select,
  Stack,
  Text,
  TextInput,
} from '@shared/components'
import { Appointment, AppointmentTypes, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { ReactNode, useEffect } from 'react'
import { YesOrNoRadio } from '../../components/forms/YesOrNoRadio'
import { mapIdToName } from '../../utils/utils'
import { CcmTransitionForm } from './ScheduleVisit'
import { ClinicianOption, SelectClinicianItem, TOGGLE_CLINICIANS_TEXT } from './SelectClinician'
import { useCareCoordinatorScheduling } from './use-care-coordinator-scheduling'

const calendarDrawerTitle = ({
  appointmentTypes,
  appointmentTypeId,
  rescheduleAppointment,
}: CalendarDrawerProps) => {
  if (appointmentTypes) {
    const appointmentTypeName = mapIdToName(Number(appointmentTypeId), appointmentTypes)
    if (rescheduleAppointment) {
      return (
        <Group position='apart' align='baseline'>
          {appointmentTypeName}
          <Text size='xs' color={({ text }) => text[1]}>
            {`Reschedule from ${dayjs(rescheduleAppointment.datetime).format(
              'MM/DD/YYYY',
            )} at ${dayjs(rescheduleAppointment.datetime).format('h:mma z')}`}
          </Text>
        </Group>
      )
    }
    return appointmentTypeName
  }

  return 'Schedule a visit'
}

type CalendarDrawerProps = {
  appointmentTypes: AppointmentTypes | undefined
  appointmentTypeId: string
  rescheduleAppointment: Appointment | undefined
  button?: ReactNode
}

export const CalendarDrawer = ({
  appointmentTypes,
  appointmentTypeId,
  rescheduleAppointment,
  button,
  onBack,
  ...props
}: Omit<DrawerProps, 'title' | 'position' | 'size' | 'opened' | 'footer'> &
  CalendarDrawerProps) => (
  <Drawer
    {...props}
    title={calendarDrawerTitle({
      appointmentTypes,
      appointmentTypeId,
      rescheduleAppointment,
    })}
    size='xl'
    opened
    position='right'
    onBack={rescheduleAppointment ? undefined : onBack}
    footer={
      <Group position='apart'>
        {rescheduleAppointment ? <span /> : <ProgressDots steps={2} currentStep={1} />}
        {button}
      </Group>
    }
  />
)

export const SelectEmployee = ({
  recommendedOptions,
  otherOptions,
  value,
  error,
  onChange,
  appointmentTypes,
  appointmentTypeId,
  disabled = false,
  explanation,
}: {
  recommendedOptions: ClinicianOption[]
  otherOptions: ClinicianOption[]
  value: string
  error?: string
  onChange: (value: string) => void
  appointmentTypes: AppointmentTypes
  appointmentTypeId: string
  disabled?: boolean
  explanation?: string | null
}) => {
  const [displayAllClinicians, toggleAllClinicians] = useToggle()
  const { height } = useViewportSize()

  /*
   * If the current user is a care coordinator, we're going to display an explanation detailing why
   * we defaulted to a certain clinician. If the CC chooses a different clinician, we will display
   * a warning state on the clinician selection dropdown
   */
  const careCoordinatorSchedulingInfo = useCareCoordinatorScheduling()

  if (recommendedOptions.length === 0 && otherOptions.length === 0) {
    return (
      <Alert variant='secondary' icon={<InfoIcon />}>
        There are no options available, please contact clinical-product-support.
      </Alert>
    )
  }

  const isEnrollmentCall = [
    appointmentTypes.CONSULTATION_VISIT_ID,
    appointmentTypes.CONSULTATION_QUEUE_CALENDAR_ID,
    appointmentTypes.ENROLLMENT_SUPPORT_CALL_VISIT_ID,
    appointmentTypes.REENROLLMENT_VISIT_ID,
  ].includes(Number(appointmentTypeId))

  const toggleCliniciansButtonInfo: SelectClinicianItem<'button'> = {
    buttonLabel: `Show ${displayAllClinicians ? 'less' : 'more'}`,
    buttonIcon: displayAllClinicians ? <MinusCircleIcon /> : <PlusCircleIcon />,
    onMouseDown: () => toggleAllClinicians(),
    type: 'button',
    value: TOGGLE_CLINICIANS_TEXT,
  }

  let options: SelectClinicianItem[]

  const recommendedOptionSelectItems = recommendedOptions.map(option => ({
    ...option,
    type: 'selectItem' as const,
  }))

  const otherOptionSelectItems = otherOptions.map(option => ({
    ...option,
    type: 'selectItem' as const,
  }))

  // If reccomended options and other options are both provided, show reccomended options first and hide other options behind a toggle.
  if (recommendedOptions.length && otherOptions.length) {
    options = [
      ...recommendedOptionSelectItems,
      toggleCliniciansButtonInfo,
      ...(displayAllClinicians ? otherOptionSelectItems : []),
    ]
  } else {
    // Otherwise, show whichever options are provided.
    options = [...recommendedOptionSelectItems, ...otherOptionSelectItems]
  }

  let selectExplanation = 'When possible, choose from the top of the list.'

  /*
   * If the current user is a CC and careCoordinatorSchedulingInfo is defined, the value will include
   * text that we'd like to display as the explanation for the clinician selection dropdown.
   *
   * For now, the explanation text from the careCoordinatorSchedulingInfo will take precedence over
   * the explanation passed in to this component. Eventually, we'll clean up all the scheduling logic.
   */
  if (careCoordinatorSchedulingInfo) {
    selectExplanation = careCoordinatorSchedulingInfo.clinicianSelectionExplanation
  } else if (explanation) {
    selectExplanation = explanation
  } else if (isEnrollmentCall) {
    selectExplanation = ''
  }

  // Display warning state if the current user is a CC and the selected clinician is not the default clinician
  const isWarning =
    careCoordinatorSchedulingInfo &&
    `${careCoordinatorSchedulingInfo?.defaultClinician.oid}` !== value

  return (
    <Select
      withinPortal
      searchable
      filter={(inputValue, item) => {
        // if no search input, show all options
        if (!inputValue) {
          return true
        }
        if (!item?.label) {
          return false
        }
        return item?.label?.toLowerCase().includes(inputValue.toLowerCase())
      }}
      label={isEnrollmentCall ? 'Select an enrollment coordinator' : 'Select a clinician'}
      explanation={selectExplanation}
      data={options}
      value={value}
      error={error}
      onChange={(value: string) => {
        if (value === TOGGLE_CLINICIANS_TEXT) {
          toggleAllClinicians()
          return
        }
        onChange(value)
      }}
      itemComponent={SelectClinicianItem}
      placeholder='Select one...'
      // eslint-disable-next-line no-magic-numbers
      maxDropdownHeight={height / 2.5}
      disabled={disabled}
      warning={isWarning}
    />
  )
}

export const REASONS_FOR_NO_CCM_TRANSITION = [
  'Mental health issues',
  'OUD unstable',
  'Patient refusal',
  'Other',
]

export const CcmTransitionSection = ({
  ccmTransitionForm,
  patient,
}: {
  patient: Patient
  ccmTransitionForm: UseFormReturnType<CcmTransitionForm>
}) => {
  useEffect(() => {
    if (ccmTransitionForm.values?.readyForCcmTransition === 'yes') {
      ccmTransitionForm.setFieldValue('reasonForNoCcmTransition', null)
    }
  }, [ccmTransitionForm.values?.readyForCcmTransition])

  return (
    <Stack>
      <YesOrNoRadio
        {...ccmTransitionForm.getInputProps('readyForCcmTransition')}
        label={`Can ${patient.personalData.firstName} see a CCM for appointments?`}
        isEditing
      />
      {ccmTransitionForm.values?.readyForCcmTransition === 'yes' && (
        <Alert variant='primary' icon={<InfoIcon />}>
          This visit may be automatically rescheduled if a similar slot becomes available on a CCM
          calendar.
        </Alert>
      )}
      {ccmTransitionForm.values?.readyForCcmTransition === 'no' && (
        <Select
          label='Reason'
          data={REASONS_FOR_NO_CCM_TRANSITION.map(reason => ({
            label: reason,
            value: reason,
          }))}
          {...ccmTransitionForm.getInputProps('reasonForNoCcmTransition')}
        />
      )}
      {ccmTransitionForm.values?.readyForCcmTransition === 'no' &&
        ccmTransitionForm.values?.reasonForNoCcmTransition === 'Other' && (
          <TextInput
            label='Other reason'
            placeholder='Enter other reason...'
            {...ccmTransitionForm.getInputProps('reasonForNoCcmTransitionOther')}
          />
        )}
    </Stack>
  )
}
