import { UseFormReturnType } from '@mantine/form'
import { Divider, Grid, Group, Select, SelectItem, Stack, Text } from '@shared/components'
import { OcpClinician, OcpRecurringModel, StateAbbr, states, stateTimeZones } from '@shared/types'
import { dayjs } from '@shared/utils'

const TOTAL_SHIFT_VALUES = 48
const TWELVE_HOURS = 12
const FIRST_TWO_DIGIT_NUMBER = 10
const MINUTES_VALUES = ['00', '30']
const MERIDIEM_LABELS = ['AM', 'PM']
const MONTH_SHIFT = 30

/**
 * this code will generate human-readable time labels and server-readable hour:minute values
 * separated by 30 minute intervals, in order from midnight to 11:30 PM in the following shape:
 * [{label: '12:00 AM', value: '00:00'}, {label: '12:30 AM', value: '00:30'}, ..., { label: '11:30 PM', value: '23:30'}]
 */
function getShiftTimes(tzString: string): SelectItem[] {
  return Array.from(Array(TOTAL_SHIFT_VALUES).keys()).map(n => {
    const isHalfHour = n % 2
    const hourValueIn24HourTime = (n - isHalfHour) / 2
    const hourValueIn12HourTime = hourValueIn24HourTime % TWELVE_HOURS
    const meridiem = n < TOTAL_SHIFT_VALUES / 2 ? MERIDIEM_LABELS[0] : MERIDIEM_LABELS[1]
    return {
      label: `${hourValueIn12HourTime === 0 ? TWELVE_HOURS : hourValueIn12HourTime}:${
        MINUTES_VALUES[isHalfHour]
      } ${meridiem} ${tzString}`,
      value: `${
        hourValueIn24HourTime < FIRST_TWO_DIGIT_NUMBER ? '0' : ''
      }${hourValueIn24HourTime}:${MINUTES_VALUES[isHalfHour]}`,
    }
  })
}

function getProviderSelectData(clinicians: OcpClinician[]): SelectItem[] {
  return [{ label: 'No scheduled provider', value: '' }].concat(
    clinicians.map(employee => ({
      label: employee.name,
      value: employee.oid,
    })),
  )
}

function getTimezoneString(state: StateAbbr | undefined) {
  return state ? dayjs().tz(stateTimeZones.get(state)).format('z') : ''
}

const shiftDurationsByState: Map<StateAbbr, number> = new Map([
  ['AZ', MONTH_SHIFT],
  ['CA', MONTH_SHIFT],
  ['CT', MONTH_SHIFT],
  ['FL', 2 * MONTH_SHIFT],
  ['ME', MONTH_SHIFT],
  ['MD', MONTH_SHIFT],
  ['MT', MONTH_SHIFT],
  ['NJ', MONTH_SHIFT],
  ['NY', 2 * MONTH_SHIFT],
  ['PA', 2 * MONTH_SHIFT],
  ['TX', MONTH_SHIFT],
  ['VT', MONTH_SHIFT],
  ['VA', MONTH_SHIFT],
  ['WA', MONTH_SHIFT],
])

export type OcpRecurringFormData = {
  state?: StateAbbr
  weekdayShiftStart: string
  weekdayShiftEnd: string
  weekendShiftStart: string
  weekendShiftEnd: string
  employeeIdByDay: {
    sunday: string
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
  }
}

export type RecurringScheduleFormProps = {
  form: UseFormReturnType<OcpRecurringFormData>
  existingStates?: StateAbbr[]
  clinicians: OcpClinician[]
}

export const convertFormDataToModel: (
  form: UseFormReturnType<OcpRecurringFormData>,
) => OcpRecurringModel | undefined = (form: UseFormReturnType<OcpRecurringFormData>) => {
  const data = form.values

  if (
    !data.state ||
    !data.weekdayShiftStart ||
    !data.weekdayShiftEnd ||
    !data.weekendShiftStart ||
    !data.weekendShiftEnd
  ) {
    form.setErrors({
      message: 'Missing schedule data. Please fill out all shift times and enter a state',
    })
    return
  }

  const timezone = stateTimeZones.get(data.state) as string
  return {
    state: data.state,
    timezone,
    weekdayShiftStart: data.weekdayShiftStart,
    weekdayShiftEnd: data.weekdayShiftEnd,
    weekendShiftStart: data.weekendShiftStart,
    weekendShiftEnd: data.weekendShiftEnd,
    employeeIdByDay: data.employeeIdByDay,
    shiftDuration: shiftDurationsByState.get(data.state) as number,
    // seriesStartTime and createdAt will always be duplicate fields as designed
    seriesStartTime: new Date().toISOString(),
    seriesEndTime: null,
    createdAt: new Date().toISOString(),
    active: true,
  }
}

const RecurringScheduleForm = ({
  form,
  existingStates,
  clinicians,
}: RecurringScheduleFormProps) => {
  return (
    <Stack p='md'>
      <Select
        placeholder='Select one...'
        {...form.getInputProps('state')}
        label='State'
        data={states
          .filter(state => state.active)
          .map(state => ({ label: state.state, value: state.abbr }))
          .filter(datum =>
            existingStates
              ? !existingStates.includes(datum.value)
              : datum.value === form.values.state,
          )}
        disabled={!existingStates}
      />
      <Grid mt='md'>
        <Grid.Col span={4}>
          <Text bold>
            Weekdays
            {form.values.state && ` (${getTimezoneString(form.values.state)})`}
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Group noWrap>
            <Select
              {...form.getInputProps('weekdayShiftStart')}
              data={getShiftTimes(getTimezoneString(form.values.state))}
              placeholder='Select one...'
            />
            <Text>-</Text>
            <Select
              {...form.getInputProps('weekdayShiftEnd')}
              data={getShiftTimes(getTimezoneString(form.values.state))}
              placeholder='Select one...'
            />
          </Group>
        </Grid.Col>
      </Grid>
      {clinicians && (
        <Stack mt='md'>
          <Group position='apart'>
            <Text>Monday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.monday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
          <Group position='apart'>
            <Text>Tuesday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.tuesday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
          <Group position='apart'>
            <Text>Wednesday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.wednesday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
          <Group position='apart'>
            <Text>Thursday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.thursday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
          <Group position='apart'>
            <Text>Friday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.friday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
        </Stack>
      )}
      <Divider />
      <Grid mt='md'>
        <Grid.Col span={4}>
          <Text bold>
            Weekends
            {form.values.state && ` (${getTimezoneString(form.values.state)})`}
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Group noWrap>
            <Select
              {...form.getInputProps('weekendShiftStart')}
              data={getShiftTimes(getTimezoneString(form.values.state))}
              placeholder='Select one...'
            />
            <Text>-</Text>
            <Select
              {...form.getInputProps('weekendShiftEnd')}
              data={getShiftTimes(getTimezoneString(form.values.state))}
              placeholder='Select end time'
            />
          </Group>
        </Grid.Col>
      </Grid>
      {clinicians && (
        <Stack mt='md'>
          <Group position='apart'>
            <Text>Saturday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.saturday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
          <Group position='apart'>
            <Text>Sunday</Text>
            <Select
              {...form.getInputProps('employeeIdByDay.sunday')}
              data={getProviderSelectData(clinicians)}
            />
          </Group>
        </Stack>
      )}
    </Stack>
  )
}

export default RecurringScheduleForm
