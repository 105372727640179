import { useForm } from '@mantine/form'
import {
  Alert,
  ArrowRightIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  isAnySelected,
  LifeBuoyIcon,
  NumberInput,
  PrimaryButton,
  RadioGroup,
  RoundRadio,
  SaveIcon,
  Select,
  showNotification,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import {
  EMR_TASK_PRIORITIES,
  EMRTaskPod,
  EMRTaskPriority,
  EMRTaskStatus,
  EMRTaskType,
  EMRTaskTypeMap,
  EMRTaskTypeMapType,
  getPodOptions,
  hasGroupRole,
  hasRole,
  isClinician,
  OutageTaskPriority,
  Patient,
  TaskType,
} from '@shared/types'
import { name } from '@shared/utils'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { EmployeeMentionNoteInput } from '../../../components/forms/EmployeeMentionNoteInput'
import { SelectPatient } from '../../../components/forms/SelectPatient'
import { useAuth } from '../../../context/auth'
import { isConditionallyRequired, isRequired } from '../../../utils/formValidation'
import { useEmrQuery, useOnCallEngineer, usePatientOcp } from '../../../utils/hooks'
import { useTasksCache } from '../../../utils/hooks/use-tasks-cache'
import { AssigneeForm, AssigneeFormProvider, useAssigneeForm } from './AssigneeForm'
import { SelectIssueType } from './SelectIssueType'

export type CreateTaskForm = {
  description: string
  patientId: string | null
  priority: EMRTaskPriority | OutageTaskPriority | null
  type: EMRTaskType | null
  minutesTriaging: number | ''
  searchValue: string
}

const bridgePrescriptionTemplate = `
Total daily dose: <br/>
Units remaining: [X units of (Suboxone/Bupe-naloxone/Zubsolv) Y-mg (films/tablets)] <br/>
How many days have you used opioids in the past week? <br/>
Has had a dose of buprenorphine since last use? [Yes/No] <br/>
Next visit date: [mm/dd/yyyy] <br/>
Pharmacy confirmed: [Yes/No] 
`

export const AddIssueDrawerContent = ({
  onBack,
  patient,
  onClose,
  counts,
  onClickAlert,
  isTrackingTriageMinutes,
  defaultTaskType,
}: {
  onBack: (() => void) | undefined
  patient: Patient | undefined
  onClose: () => void
  counts: Record<EMRTaskStatus, number> | undefined
  onClickAlert: () => void
  isTrackingTriageMinutes: boolean
  defaultTaskType?: TaskType
}) => {
  const { currentUser } = useAuth()
  const showAllFields = !isClinician(currentUser) || hasRole(currentUser, 'stn', 'tn', 'ncm_tn')

  const form = useForm<CreateTaskForm>({
    initialValues: {
      description: defaultTaskType === 'bridge_prescription' ? bridgePrescriptionTemplate : '',
      patientId: patient?.oid || null,
      priority: null,
      type: defaultTaskType || null,
      minutesTriaging: '',
      searchValue: '',
    },
    validate: {
      patientId: validateWith(isRequired),
      description: validateWith(isRequired),
      type: validateWith(isRequired),
      priority: validateWith(isRequired),
      minutesTriaging: validateWith(isConditionallyRequired(isTrackingTriageMinutes)),
    },
  })
  const tasksCache = useTasksCache()

  const assigneeForm = useAssigneeForm({
    initialValues: {
      pod: '',
      employeeId: '',
    },
    validate: {
      pod: validateWith(isAnySelected(getPodOptions(), 'Required')),
    },
  })

  const createTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2'), {
    onSettled: () => {
      tasksCache.invalidate(patient?.oid)
    },
  })

  const selectedPatient = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: { patientId: form.values.patientId ?? '' },
    },
    {
      enabled: Boolean(form.values.patientId),
    },
  )

  const { data: primaryClinician, isLoading: isPrimaryClinicianLoading } = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: { employeeId: selectedPatient.data?.primaryClinician?.id ?? '' },
    },
    {
      enabled: Boolean(selectedPatient.data?.primaryClinician?.id),
    },
  )

  const {
    onCallEngineerQuery: { data: onCallEngineer, isLoading: isOnCallEngineerLoading },
  } = useOnCallEngineer()

  const {
    ocpQuery: { data: currentOcp, isLoading: isCurrentOcpLoading },
  } = usePatientOcp(form.values.patientId)

  const getAssigneeId = () => {
    if (assigneeForm.values.pod === 'ocp') {
      return currentOcp?.employeeId || ''
    }

    if (assigneeForm.values.pod === 'engineer' && !assigneeForm.values.employeeId) {
      return onCallEngineer?.oid || ''
    }
    return assigneeForm.values.employeeId
  }

  const setDefaultAssignee = (emrTask: EMRTaskTypeMapType) => {
    if (emrTask.defaultPod === 'supervisor' || emrTask.defaultPod === 'medical_supervisor') {
      // Since UDS is medical, the default is the supervising clinician
      const nonMedicalDefault = hasGroupRole(currentUser, 'enrollmentCoordinator') ? 'lec' : 'lcc'
      const defaultPod = emrTask.defaultPod === 'medical_supervisor' ? 'spc' : nonMedicalDefault

      // For clinicians, data change requests are sent to their SC
      const assignToSC = hasGroupRole(currentUser, 'clinician') || defaultPod === 'spc'
      if (assignToSC) {
        // Satisfy the typechecker to get the spc
        const employeeId = hasGroupRole(primaryClinician, 'clinician') ? primaryClinician.spc : ''

        return assigneeForm.setValues({
          pod: 'spc',
          employeeId: employeeId || '',
        })
      }
      // For CCs and as a default, data change requests are sent to the leads
      return assigneeForm.setValues({
        pod: defaultPod,
        employeeId: '',
      })
    }

    if (emrTask.defaultPod) {
      return assigneeForm.setValues({ pod: emrTask.defaultPod })
    }

    // If there is no default pod for the task, clear the assignee form
    assigneeForm.setValues({ pod: '', employeeId: '' })
  }

  const fillDefaultValues = (value: EMRTaskType) => {
    const emrTask = EMRTaskTypeMap.find(type => type.value === value)

    if (emrTask) {
      form.setValues({
        type: emrTask.value,
        priority: emrTask.priority,
      })
      setDefaultAssignee(emrTask)
    }
  }

  useEffect(() => {
    if (defaultTaskType) {
      fillDefaultValues(defaultTaskType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTaskType])

  const onSubmit = async () => {
    const formHasErrors = form.validate().hasErrors
    const assigneeFormHasErrors = assigneeForm.validate().hasErrors
    if (formHasErrors || assigneeFormHasErrors) {
      return
    }

    await createTaskMutation.mutateAsync({
      data: {
        type: form.values.type as TaskType,
        priority: form.values.priority as EMRTaskPriority,
        description: form.values.description,
        patientId: form.values.patientId || '',
        employeeId: getAssigneeId(),
        pod: assigneeForm.values.pod as EMRTaskPod,
        minutesTriaging: Number(form.values.minutesTriaging),
      },
    })

    onClose()

    showNotification({
      message: 'Successfully added issue to the to-do list',
      variant: 'success',
    })
  }

  return (
    <>
      <DrawerHeader onBack={onBack} onClose={onClose}>
        Add issue
      </DrawerHeader>
      <DrawerContent trapFocus={false}>
        <Stack p='md'>
          <Text>By adding an issue, you&apos;ll add an item to the open list.</Text>
          {patient ? (
            <Select
              label='Which patient is having the issue?'
              placeholder={name({
                first: patient.personalData.firstName,
                last: patient.personalData.lastName,
              }).lastCommaFirst()}
              data={[]}
              disabled
            />
          ) : (
            <SelectPatient
              patientId={form.values.patientId || undefined}
              label='Which patient is having the issue?'
              placeholder='Search by patient name...'
              explanation='Search by full first name and/or last name'
              onSearchValueChange={value => form.setFieldValue('searchValue', value)}
              initialSearchValue={form.values.searchValue}
              {...form.getInputProps('patientId')}
            />
          )}
          <IssueAlert counts={counts} onClick={onClickAlert} />
          <SelectIssueType
            {...form.getInputProps('type')}
            onChange={(value: EMRTaskType) => fillDefaultValues(value)}
          />
          {showAllFields && (
            <RadioGroup
              label='Set priority'
              orientation='horizontal'
              {...form.getInputProps('priority')}
              onChange={value => {
                // the radio selector returns a string, but the form expects a number
                form.setValues({ priority: Number(value) as EMRTaskPriority })
              }}
            >
              {EMR_TASK_PRIORITIES.map(priority => (
                <RoundRadio key={priority} value={priority}>
                  {priority}
                </RoundRadio>
              ))}
            </RadioGroup>
          )}
          <EmployeeMentionNoteInput
            label='Issue description'
            placeholder='Provide a brief overview of the issue'
            {...form.getInputProps('description')}
          />
          {isTrackingTriageMinutes && (
            <NumberInput
              label='Minutes spent triaging issue'
              explanation='Time spent on pre-work and communicating with the patient'
              placeholder='Total minutes'
              {...form.getInputProps('minutesTriaging')}
            />
          )}
          <AssigneeFormProvider form={assigneeForm}>
            <AssigneeForm
              currentOcp={currentOcp}
              onCallEngineer={onCallEngineer}
              isLoading={isOnCallEngineerLoading || isCurrentOcpLoading}
            />
          </AssigneeFormProvider>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={onSubmit}
            leftIcon={<SaveIcon />}
            loading={createTaskMutation.isLoading}
            disabled={isOnCallEngineerLoading || isCurrentOcpLoading || isPrimaryClinicianLoading}
          >
            Save task
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

const IssueAlert = ({
  counts,
  onClick,
}: {
  counts: Record<EMRTaskStatus, number> | undefined
  onClick: () => void
}) => {
  if (counts?.open || counts?.snoozed) {
    return (
      <Alert
        variant='secondary'
        title='Review issues'
        icon={<LifeBuoyIcon />}
        actionIcon={<ArrowRightIcon color={({ actions }) => actions[0]} />}
        onClick={onClick}
      >
        This patient has{' '}
        {[
          counts?.open && `${counts?.open} open issues`,
          counts?.snoozed && `${counts?.snoozed} snoozed issues`,
        ]
          .filter(Boolean)
          .join(' and ')}
        . Double check to make sure you’re not creating a duplicate issue.
      </Alert>
    )
  }

  return null
}
