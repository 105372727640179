import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'
import { PatientVisitNoteTextarea } from '../visitNoteForm/PatientVisitNoteTextarea'

export const MedicalHistorySection = ({ form }: { form: OnboardingVisitNoteForm }) => {
  return (
    <PatientVisitNoteSection title='Medical history'>
      <PatientVisitNoteSelect
        label='Source of suboxone (if previously exposed)'
        placeholder='Select one...'
        data={[
          'Transferring Suboxone treatment from prescriber',
          'Transferring Suboxone treatment from illicit source',
          'Transferring Suboxone treatment from higher level of care',
          'Tried Suboxone in the past',
          'Never tried Suboxone',
          'Other',
        ]}
        {...form?.getInputProps('past_buprenorphone_exposure')}
      />
      <PatientVisitNoteTextarea
        label='Other relevant information (optional)'
        placeholder='Type here...'
        {...form?.getInputProps('past_buprenorphine_exposure_more_details')}
      />
      <PatientVisitNoteNotDiscussedCheckbox field='past_buprenorphone_exposure' form={form} />
    </PatientVisitNoteSection>
  )
}
