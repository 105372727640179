export const Config = Object.freeze({
  ENV: process.env.REACT_APP_ENV || 'development',
  RS_WRITE_KEY: process.env.REACT_APP_RS_WRITE_KEY || '',
  RS_DATA_PLANE_URL: process.env.REACT_APP_RS_DATA_PLANE_URL || '',
  TWILIO_FLEX_URL: process.env.REACT_APP_TWILIO_FLEX_URL || '',
  LAUNCH_DARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
  FONT_CDN_BASE_URL: process.env.REACT_APP_FONT_CDN_BASE_URL || '',
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL || '',
  LUNA_BASE_URL: process.env.LUNA_BASE_URL || '',
  FIREBASE_API_KEY: process.env.REACT_APP_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: process.env.REACT_APP_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET || '',
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID || '',
  FIREBASE_APP_ID: process.env.REACT_APP_APP_ID || '',
  GOOGLE_AUTH_KEY: process.env.REACT_APP_GOOGLE_AUTH_KEY || '',
  ACUITY_OWNER_ID: process.env.REACT_APP_ACUITY_OWNER_ID || '',
  CONSULTATION_CALL_QUEUE_CALENDAR_ID:
    process.env.REACT_APP_CONSULTATION_CALL_QUEUE_CALENDAR_ID || '',
  ALGOLIA_APPLICATION_ID: process.env.REACT_APP_ALGOLIA_APPLICATION_ID || '',
  ALGOLIA_SEARCH_KEY: process.env.REACT_APP_ALGOLIA_SEARCH_KEY || '',
  FULLSTORY_ORG_ID: process.env.REACT_APP_FULLSTORY_ORG_ID || '',
  GITHUB_SHA: process.env.REACT_APP_GITHUB_SHA || '',
  ANONYMOUS_PROFILE_IMAGE_URL: process.env.REACT_APP_ANONYMOUS_PROFILE_IMAGE_URL || '',
  STORAGE_BUCKET_PUBLIC_URL: process.env.REACT_APP_STORAGE_BUCKET_PUBLIC_URL || '',
  REFERRAL_PARTNER_ASSETS_BASE_URL: process.env.REFERRAL_PARTNER_ASSETS_BASE_URL || '',
  PATIENT_PORTAL_BASE_URL: process.env.REACT_APP_PATIENT_PORTAL_BASE_URL || '',
  TWILIO_ACCOUNT_SID: process.env.REACT_APP_TWILIO_ACCOUNT_SID || '',
  TWILIO_TWIML_APP_SID: process.env.REACT_APP_TWILIO_TWIML_APP_SID || '',
  TWILIO_TWIML_APP_PHONE_NUMBER: process.env.REACT_APP_TWILIO_TWIML_APP_PHONE_NUMBER || '',
  CUSTOMER_IO_SITE_ID: process.env.REACT_APP_CUSTOMER_IO_SITE_ID || '',
})
