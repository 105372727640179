import ANoteTextArea from '../../../components/atoms/ANoteTextArea'

// TODO: Add type when addendum is typed
export type AddendumsSectionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addendums: Record<string, any>
}

const AddendumsSection = ({ addendums }: AddendumsSectionProps) => {
  return (
    <>
      {addendums.length > 0 &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        addendums.map((addendum: Record<string, any>) => (
          <div
            key={addendum.id}
            className='mb-2 rounded bg-white shadow flex-grow-1 flex-shrink-0 w-full overflow-y-auto'
          >
            <div className='p-4'>
              <div className='flex justify-start items-center w-full h-4 mb-2'>
                <p className='text-xs text-gray-500'>
                  Addendum added on{' '}
                  {addendum.locked_at.slice(0, addendum.locked_at.lastIndexOf(','))} at{' '}
                  {addendum.locked_at.slice(addendum.locked_at.lastIndexOf(',') + 1)} by{' '}
                  {addendum.locked_by_name}
                </p>
              </div>
              <div className='bg-white border-t border-gray-100 leading-normal pt-2'>
                <ANoteTextArea disabled defaultValue={addendum.content} borderless />
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

export default AddendumsSection
