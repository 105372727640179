import { AppointmentWithNoteDetails, CalendarColumnData, CalendarColumnHeader } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { analytics } from '../../analytics'
import { Page } from '../../components/templates/TDefault'
import { useAuth } from '../../context/auth'
import OVisits from './OVisits'
import { useCalendarDate } from './PCalendar'
import { useAppointments } from './hooks'

const calculateVisits = (
  date: string,
  appointments: AppointmentWithNoteDetails[],
): [CalendarColumnHeader[], CalendarColumnData[]] => {
  const visitsData: { [key: string]: { [key: string]: AppointmentWithNoteDetails[] } } = {}

  for (const appointment of appointments) {
    if (date in visitsData) {
      if (appointment.calendarID in visitsData[date]!) {
        visitsData?.[date]?.[appointment.calendarID]?.push(appointment)
      } else {
        visitsData[date]![appointment.calendarID] = [appointment]
      }
    } else {
      visitsData[date] = {}
      visitsData[date]![appointment.calendarID] = [appointment]
    }
  }

  const visits: CalendarColumnData[] = []
  const clinicians: CalendarColumnHeader[] = []

  for (const calendarId of Object.keys(visitsData[date]!)) {
    const { calendar } = visitsData[date]![calendarId]![0]!

    if (
      !(
        calendar.includes('NP') ||
        calendar.includes('MD') ||
        calendar.includes('RN') ||
        calendar.includes('PA') ||
        calendar.includes('DO')
      )
    ) {
      continue
    }

    const id = [calendarId, date].join(' ')

    const appointments = visitsData?.[date]?.[calendarId] ?? []
    visits.push({
      id,
      date,
      visits: appointments,
      blocks: [],
    })

    clinicians.push({
      id: id.toString(),
      date,
      name: calendar,
      day: '',
    })
  }

  visits.sort((a, b) => {
    const aCalendar = a.visits[0]?.calendar
    const bCalendar = b.visits[0]?.calendar
    if (!aCalendar || !bCalendar) {
      return 0
    }
    if (aCalendar < bCalendar) {
      return -1
    }
    if (aCalendar > bCalendar) {
      return 1
    }
    return 0
  })

  clinicians.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
  return [clinicians, visits]
}

export const PVisitsByDate = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [date, setDate] = useCalendarDate()

  const { appointments, isLoading } = useAppointments({
    minDate: dayjs(date).startOf('day').toISOString(),
    maxDate: dayjs(date).endOf('day').toISOString(),
  })

  const [columns, visitsToDisplayByDate] = useMemo(() => {
    if (appointments?.length) {
      return calculateVisits(date, appointments)
    }

    return [[], []]
  }, [date, appointments])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!currentUser) {
      return
    }

    analytics.page({
      name: 'Visits By Date',
      properties: {
        userId: currentUser.oid,
        userName: currentUser.name,
        date,
      },
    })
  }, [currentUser, date])

  return (
    <Page title='Calendar' test-id='page:date-calendar'>
      <OVisits
        columns={columns}
        data={visitsToDisplayByDate}
        date={date}
        setDate={setDate}
        setCalendarId={(calendarId: string) => {
          if (calendarId === 'all') {
            navigate(`/visits/all`)
          } else {
            navigate(`/visits/${calendarId}`)
          }
        }}
        isLoading={isLoading}
      />
    </Page>
  )
}

export default PVisitsByDate
