import { VisitNote } from '@shared/types'
import { useParams } from 'react-router-dom'
import { PatientVisitNoteContent } from '../PVisitNote'
import { PatientVisitNote } from './PatientVisitNote'
import { PatientVisitNoteProvider, usePatientVisitNote } from './PatientVisitNoteContext'
import { PatientVisitNoteLoader } from './visitNoteForm/PatientVisitNoteLoader'

// This component simply routes traffic to the new Visit Note content UX if the visit note type is already migrated
const PatientVisitNoteMigrationRouter = () => {
  const { visitNoteQuery } = usePatientVisitNote()

  const isVisitNoteMigratedToMantine = (visitNote?: VisitNote | null) => {
    switch (visitNote?.type) {
      case 'consultation':
      case 'reenrollment':
        return true
      default:
        break
    }

    return false
  }

  if (visitNoteQuery?.isLoading) {
    return <PatientVisitNoteLoader />
  }

  return isVisitNoteMigratedToMantine(visitNoteQuery?.data) ? (
    <PatientVisitNote />
  ) : (
    <PatientVisitNoteContent />
  )
}

export const VisitNoteContent = () => {
  const { visitID = '' } = useParams<{ visitID: string }>()

  return (
    <PatientVisitNoteProvider visitNoteId={visitID} isSidebar={false}>
      <PatientVisitNoteMigrationRouter />
    </PatientVisitNoteProvider>
  )
}
