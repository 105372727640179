import { getOpheliaHttpError } from '@shared/types'
import { useEmrQuery } from './use-emr-query'

const isPharmacyInactive = (error: unknown) => getOpheliaHttpError(error)?.includes('is inactive')

export const useGetPharmacy = ({
  pharmacyId,
  patientId,
}: {
  pharmacyId: string | number | undefined
  patientId: string
}) => {
  const getPharmacyQuery = useEmrQuery(
    'GET /pharmacy/:pharmacyId',
    {
      params: { pharmacyId: String(pharmacyId) },
      /*
       * @dosespotV2Migration
       * Need to send the patientId so that we know whether to use the v1 or v2 API
       */
      query: { patientId },
    },
    {
      enabled: Boolean(pharmacyId),
      retry: (count, error) => {
        if (count === 3) {
          return false
        }

        if (isPharmacyInactive(error)) {
          return false
        }

        return true
      },
    },
  )

  return { getPharmacyQuery, isPharmacyInactive: isPharmacyInactive(getPharmacyQuery.error) }
}
