import { Stack, Text } from '@shared/components'
import { DischargeNoteModel } from '@shared/types'

export const AdditionalNotesSection = ({
  dischargeNote,
}: {
  dischargeNote: DischargeNoteModel
}) => {
  return dischargeNote.additional_notes ? (
    <Stack spacing='xs'>
      <Text bold>Additional notes</Text>
      <Text>{dischargeNote.additional_notes}</Text>
    </Stack>
  ) : null
}
