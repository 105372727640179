import {
  BellIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ClipboardIcon,
  ClockIcon,
  CreditCardIcon,
  DollarSignIcon,
  DownloadIcon,
  DropletIcon,
  GridIcon,
  HeartIcon,
  LifeBuoyIcon,
  ListIcon,
  LogInIcon,
  LogOutIcon,
  Menu,
  MonitorIcon,
  PlusIcon,
  PrimaryButton,
  ShieldIcon,
} from '@shared/components'
import {
  InsuranceQueueStatus,
  PSYCH_DIAGNOSIS_CODES,
  Patient,
  PsychDiagnosisCode,
  hasGroupRole,
  hasRole,
  hasStatus,
  isAdmin,
  isClinician,
  isCoordinator,
  isLeadCoordinator,
} from '@shared/types'
import { KEYSTONE_FIRST_PAYER_ID } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import { useFlags, usePatientInsurances } from '../../utils/hooks'
import { useAccess } from '../../utils/hooks/use-access'
import { useSidePane } from '../../utils/hooks/use-side-pane'
import { patientHasInsurance } from '../../utils/utils'
import { IssueDrawer } from '../care_team/tasks/IssueDrawer'
import AddPatientRegistryModal from '../registry/AddPatientRegistryModal'
import CocmIneligibleModal from '../registry/CocmIneligibleModal'
import { ModalStateType, usePatient } from './PPatientContext'
import AddDischargeNoteDrawer from './sidebar/addDischargeNote/AddDischargeNoteDrawer'

const ScheduleVisitMenuItems = ({ setModal }: { setModal: (modal: ModalStateType) => void }) => {
  const { visitHoldsV2 } = useFlags()
  const { canScheduleVisitHolds } = useAccess()

  if (visitHoldsV2 && canScheduleVisitHolds) {
    return (
      <>
        <Menu.Item
          icon={<CalendarIcon color={colors => colors.actions[0]} />}
          onClick={() => {
            setModal({ type: 'scheduling-flow', visitType: 'Follow-Up Visit' })
          }}
        >
          Schedule follow-up
        </Menu.Item>

        <Menu.Item
          icon={<CalendarIcon color={colors => colors.actions[0]} />}
          onClick={() =>
            setModal({
              type: 'scheduling-flow',
              visitType: ['Check-In Call', 'UDS Visit'],
            })
          }
        >
          Schedule check-in or UDS
        </Menu.Item>
      </>
    )
  }

  return (
    <Menu.Item
      icon={<CalendarIcon color={colors => colors.actions[0]} />}
      onClick={() => {
        setModal({ type: 'scheduling-flow', visitType: null })
      }}
    >
      Schedule visit
    </Menu.Item>
  )
}

const ActionsDropdown = () => {
  const { currentUser } = useAuth()
  const { setModal, patientQuery, problemsListQuery } = usePatient()
  const [openAddDischargeNoteDrawer, setOpenAddDischargeNoteDrawer] = useState(false)
  const { presentPane, hidePane } = useSidePane()

  const patient = patientQuery?.data
  const patientInsurancesQuery = usePatientInsurances(patient)
  const { primaryInsurance } = patientInsurancesQuery.data ?? {}
  const [patientToAddToRegistry, setPatientToAddToRegistry] = useState<Patient | null>(null)

  const { isPaymentPlanDrawerEnabled } = useFlags()

  const updatePatientMutation = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const [cocmQueryKey, cocmQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    { params: { patientId: patient?.oid ?? '' } },
  )

  const patientOnCocmRegistry = useQuery(cocmQueryKey, cocmQueryFunction, {
    enabled: Boolean(patient?.oid),
  })

  const cocmLoading = patientOnCocmRegistry.isLoading || patientOnCocmRegistry.isFetching

  const patientInInsuranceReview =
    patient?.insuranceData?.insuranceQueueData &&
    ![InsuranceQueueStatus.OutOfNetwork, InsuranceQueueStatus.InNetwork].includes(
      patient.insuranceData.insuranceQueueData.status,
    )

  const hasCocmDiagnosis = () => {
    if (
      patient?.homeData?.state === 'New York' ||
      primaryInsurance?.basicInsuranceData?.eligiblePayerId === KEYSTONE_FIRST_PAYER_ID
    ) {
      const problemList = problemsListQuery?.data ?? []
      return Boolean(
        problemList.find(problem => {
          return PSYCH_DIAGNOSIS_CODES.includes(problem.code as PsychDiagnosisCode)
        }),
      )
    }
    return true
  }

  const [consentsQueryKey, consentsQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/consents',
    { params: { patientId: patient?.oid ?? '' } },
  )

  const consentQuery = useQuery(consentsQueryKey, consentsQueryFunction, {
    enabled: Boolean(patient?.oid),
  })

  const requiredConsentsSigned =
    consentQuery?.data?.some(consent => consent.type === 'treatment') &&
    consentQuery?.data?.some(consent => consent.type === 'financial' && consent.version === 3)

  const isCocmEligible =
    patient?.statuses?.insuranceAllowList &&
    ['Pennsylvania', 'New York'].includes(patient?.homeData?.state ?? '') &&
    hasCocmDiagnosis() &&
    Boolean(requiredConsentsSigned)

  const currentUserHasDosespotId = 'doseSpotId' in currentUser && Boolean(currentUser.doseSpotId)

  const canSetInsurancePay =
    isAdmin(currentUser) ||
    isLeadCoordinator(currentUser) ||
    hasRole(currentUser, 'financialCounselor')

  const canAddToInsuranceQueue = isAdmin(currentUser) || isCoordinator(currentUser)
  const canExportVisitNotes = isAdmin(currentUser) || isCoordinator(currentUser)

  return (
    <>
      {isCocmEligible ? (
        <AddPatientRegistryModal
          patient={patientToAddToRegistry}
          onCloseAddPatientModal={() => setPatientToAddToRegistry(null)}
        />
      ) : (
        <CocmIneligibleModal
          patient={patientToAddToRegistry}
          onClose={() => setPatientToAddToRegistry(null)}
          hasCocmDiagnosis={hasCocmDiagnosis()}
          consentsSigned={Boolean(requiredConsentsSigned)}
        />
      )}

      <Menu shadow='md' width={200}>
        <Menu.Target>
          <PrimaryButton rightIcon={<ChevronDownIcon />}>Actions</PrimaryButton>
        </Menu.Target>
        {/* 37rem should be visible for 13+ inch screens */}
        <Menu.Dropdown mah='37rem' style={{ overflowY: 'auto' }}>
          <ScheduleVisitMenuItems setModal={setModal} />
          {hasRole(currentUser, 'engineer', 'admin') && (
            <>
              <Menu.Item
                icon={<CalendarIcon color={colors => colors.actions[0]} />}
                onClick={() => setModal({ type: 'schedule-visit' })}
              >
                Schedule visit (coordinator)
              </Menu.Item>
              <Menu.Item
                icon={<CalendarIcon color={colors => colors.actions[0]} />}
                onClick={() => setModal({ type: 'schedule-visit-calendar' })}
              >
                Schedule visit (clinician)
              </Menu.Item>
            </>
          )}
          {patient?.statuses.patient === 'candidate' && (
            <Menu.Item
              icon={<BellIcon color={colors => colors.actions[0]} />}
              onClick={async () => {
                await updatePatientMutation.mutateAsync({
                  params: {
                    patientId: patient?.oid,
                  },
                  data: {
                    account: {
                      settings: {
                        notifications: {
                          standbyList: {
                            initialVisit: {
                              optIn:
                                !patient?.account?.settings?.notifications?.standbyList
                                  ?.initialVisit?.optIn,
                            },
                          },
                        },
                      },
                    },
                  },
                })
                location.reload()
              }}
            >
              {patient?.account?.settings?.notifications?.standbyList?.initialVisit?.optIn
                ? 'Opt out of standby list'
                : 'Opt in to standby list'}
            </Menu.Item>
          )}
          <Menu.Item
            icon={<ClipboardIcon color={colors => colors.actions[0]} />}
            onClick={() => setModal({ type: 'add-non-visit-event' })}
          >
            Add non-visit event
          </Menu.Item>
          <Menu.Item
            icon={<DropletIcon color={colors => colors.actions[0]} />}
            onClick={() => setModal({ type: 'record-drug-screen' })}
          >
            Add drug screen
          </Menu.Item>
          <Menu.Item
            icon={<CheckIcon color={colors => colors.actions[0]} />}
            onClick={() => setModal({ type: 'add-prior-authorization' })}
          >
            Add prior authorization
          </Menu.Item>
          <Menu.Item
            icon={<LogOutIcon color={colors => colors.actions[0]} />}
            onClick={() => setOpenAddDischargeNoteDrawer(true)}
          >
            Initiate discharge
          </Menu.Item>
          {!isClinician(currentUser) && (
            <Menu.Item
              icon={<LogInIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'add-reengagement-note' })}
            >
              Initiate re-engagement
            </Menu.Item>
          )}
          {hasStatus(patient, 'lead', 'candidate', 'discharged') && (
            <Menu.Item
              icon={<CreditCardIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'delay-subscription' })}
            >
              Set subscription delay
            </Menu.Item>
          )}
          {canSetInsurancePay && patient?.statuses?.insuranceAllowList && (
            <Menu.Item
              icon={<ShieldIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'insurance-allowlist-remove' })}
            >
              Remove from insurance pay
            </Menu.Item>
          )}
          {canSetInsurancePay &&
            !patient?.statuses?.insuranceAllowList &&
            patientHasInsurance(patient) && (
              <Menu.Item
                icon={<ShieldIcon color={colors => colors.actions[0]} />}
                onClick={() => setModal({ type: 'insurance-allowlist-add' })}
              >
                Move to insurance pay
              </Menu.Item>
            )}
          {canAddToInsuranceQueue && patient?.insuranceData && !patientInInsuranceReview && (
            <Menu.Item
              icon={<ListIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'add-to-insurance-queue' })}
            >
              Add to insurance queue
            </Menu.Item>
          )}
          {hasGroupRole(currentUser, 'engineer', 'enrollmentCoordinator') && (
            <Menu.Item
              icon={<MonitorIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'open-patient-app-iframe' })}
            >
              {`Log in as ${patient?.personalData.firstName}`}
            </Menu.Item>
          )}
          {(hasRole(currentUser, 'spc') ||
            (hasGroupRole(currentUser, 'admin') && currentUserHasDosespotId)) && (
            <Menu.Item
              icon={<PlusIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'create-dosespot-patient' })}
            >
              Create DoseSpot patient
            </Menu.Item>
          )}
          {hasGroupRole(currentUser, 'admin') &&
            !patientOnCocmRegistry?.data &&
            patient &&
            !cocmLoading && (
              <Menu.Item
                icon={<GridIcon color={colors => colors.actions[0]} />}
                onClick={() => setPatientToAddToRegistry(patient)}
              >
                Add to CoCM registry
              </Menu.Item>
            )}
          {(hasRole(currentUser, 'sncm', 'ncm', 'ncm_tn', 'financialCounselor') ||
            hasGroupRole(currentUser, 'admin')) &&
            patientOnCocmRegistry?.data &&
            !cocmLoading && (
              <Menu.Item
                icon={<ClockIcon color={colors => colors.actions[0]} />}
                onClick={() => setModal({ type: 'time-tracking' })}
              >
                Time tracking
              </Menu.Item>
            )}

          <Menu.Item
            icon={<LifeBuoyIcon color={colors => colors.actions[0]} />}
            onClick={() => {
              presentPane({
                key: `issue-view-${patient?.oid}`,
                content: <IssueDrawer step='view' patient={patient} onClose={hidePane} />,
              })
            }}
          >
            Review issues
          </Menu.Item>
          {hasRole(currentUser, 'engineer', 'financialCounselor') && isPaymentPlanDrawerEnabled && (
            <Menu.Item
              icon={<DollarSignIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'create-payment-plan' })}
            >
              Create payment plan
            </Menu.Item>
          )}
          {hasGroupRole(currentUser, 'clinician', 'engineer') && (
            <Menu.Item
              icon={<HeartIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'send-patient-referral-link' })}
            >
              Send patient referral link
            </Menu.Item>
          )}
          {hasGroupRole(currentUser, 'admin', 'enrollmentCoordinator') && (
            <Menu.Item
              icon={<ClockIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'create-call-me-now-welcome-call' })}
            >
              Create Call me now
            </Menu.Item>
          )}
          {canExportVisitNotes && (
            <Menu.Item
              icon={<DownloadIcon color={colors => colors.actions[0]} />}
              onClick={() => setModal({ type: 'export-visit-notes' })}
            >
              Export visit notes
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      {patient && (
        <AddDischargeNoteDrawer
          opened={openAddDischargeNoteDrawer}
          patient={patient}
          onClose={() => setOpenAddDischargeNoteDrawer(false)}
        />
      )}
    </>
  )
}

export default ActionsDropdown
