import { ISOString, PaymentPlanFrequency, SubscriptionInterval } from '@shared/types'
import { dayjs } from '@shared/utils'

export const getFinalPaymentDate = ({
  firstPaymentDate,
  numPayments,
  paymentFrequency,
}: {
  firstPaymentDate: ISOString
  numPayments: `${number}`
  paymentFrequency: PaymentPlanFrequency
}) => {
  const weeklyPaymentFrequencies: SubscriptionInterval[] = ['weekly', 'biweekly']
  const isMeasuredInWeeks = weeklyPaymentFrequencies.includes(paymentFrequency)
  const frequency = isMeasuredInWeeks ? 'weeks' : 'months'

  const shouldMultiplyPeriodsByTwo = paymentFrequency === 'biweekly'

  /*
   * Need to subtract the number of payments by 1 to find the correct final payment date
   * because the first payment is made on the firstPaymentDate
   */
  const numPeriods = (Number(numPayments) - 1) * (shouldMultiplyPeriodsByTwo ? 2 : 1)

  const finalPaymentDate = dayjs(firstPaymentDate).add(numPeriods, frequency).toISOString()

  return finalPaymentDate
}
