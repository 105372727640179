import { useToggle } from '@mantine/hooks'
import {
  ActivityIcon,
  Box,
  Group,
  Menu,
  Modal,
  MoreVerticalIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Switch,
  TertiaryButton,
  Text,
  Tooltip,
  TrashCanIcon,
  TrendingUpIcon,
} from '@shared/components'
import { ProblemListProblem } from '@shared/types'

export type DiagnosisItemProps = {
  diagnosis: ProblemListProblem
  updateDiagnosis: ({
    diagnosis,
    allowDiagnosisToBeRemoved,
  }: {
    diagnosis: ProblemListProblem
    allowDiagnosisToBeRemoved?: boolean | undefined
  }) => void
  removeDiagnosis: (diagnosis: ProblemListProblem) => void
  editable: boolean
  isAddressed: boolean
}

export const DiagnosisItem = ({
  diagnosis,
  updateDiagnosis,
  removeDiagnosis,
  editable,
  isAddressed,
}: DiagnosisItemProps) => {
  const [showModal, toggleModal] = useToggle()
  const { code, full_description, chronicity, progression } = diagnosis

  const changeChronicity = (problem: ProblemListProblem) => {
    const temp = { ...problem, chronicity: problem.chronicity === 'Chronic' ? 'Acute' : 'Chronic' }
    updateDiagnosis({ diagnosis: temp })
  }

  const changeProgression = (problem: ProblemListProblem) => {
    const temp = {
      ...problem,
      progression: problem.progression === 'Exacerbating' ? 'Stable' : 'Exacerbating',
    }
    updateDiagnosis({ diagnosis: temp })
  }

  const toggleTreating = (problem: ProblemListProblem) => {
    const temp = {
      ...problem,
      treating: !problem.treating,
    }
    updateDiagnosis({ diagnosis: temp, allowDiagnosisToBeRemoved: true })
  }

  const DISABLED_OPACITY = 0.7

  return (
    <>
      <Modal
        opened={showModal}
        onClose={() => toggleModal(false)}
        title={`Remove ${diagnosis.full_description.toLowerCase()}`}
        footer={
          <Group position='right'>
            <SecondaryButton onClick={() => toggleModal(false)}>No, go back</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                removeDiagnosis(diagnosis)
                toggleModal(false)
              }}
            >
              Yes, remove diagnosis
            </PrimaryButton>
          </Group>
        }
      >
        <Stack spacing='md'>
          <Text>This will also remove the diagnosis from the patient&apos;s chart.</Text>
          <Text>Are you sure you want to proceed?</Text>
        </Stack>
      </Modal>
      <Tooltip label={`${chronicity} | ${progression} | ${code}`} disabled={isAddressed}>
        <Box
          sx={theme => ({
            background: theme.other.colors.background[1],
            padding: theme.other.sizes.padding.lg,
            boxShadow: theme.shadows.sm,
          })}
        >
          <Group position='apart' noWrap>
            <Group noWrap>
              <Switch
                size='sm'
                checked={isAddressed}
                onChange={() => toggleTreating(diagnosis)}
                disabled={!editable}
                style={{
                  opacity: editable ? 1 : DISABLED_OPACITY,
                }}
              />
              <Stack spacing='xs'>
                <Text bold>{full_description}</Text>
                {isAddressed && (
                  <Text size='xs'>
                    {chronicity} - {progression} - {code}
                  </Text>
                )}
              </Stack>
            </Group>
            {editable && (
              <Menu position='bottom-start'>
                <Menu.Target>
                  <TertiaryButton size='sm' leftIcon={<MoreVerticalIcon />} />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => changeChronicity(diagnosis)}
                    icon={<TrendingUpIcon color={colors => colors.actions[0]} />}
                  >{`Change to ${chronicity === 'Acute' ? 'chronic' : 'acute'}`}</Menu.Item>
                  <Menu.Item
                    onClick={() => changeProgression(diagnosis)}
                    icon={<ActivityIcon color={colors => colors.actions[0]} />}
                  >{`Change to ${progression === 'Stable' ? 'exacerbating' : 'stable'}`}</Menu.Item>
                  <Menu.Item
                    onClick={() => toggleModal(true)}
                    icon={<TrashCanIcon color={colors => colors.actions[0]} />}
                  >
                    Remove
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        </Box>
      </Tooltip>
    </>
  )
}
