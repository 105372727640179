import { Drawer } from '@shared/components'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { useEmrQuery } from '../../../utils/hooks'
import { LockedCaseReviewDrawer } from '../../registry/caseReview/LockedCaseReviewDrawer'
import { UnlockedCaseReviewDrawer } from '../../registry/caseReview/UnlockedCaseReviewDrawer'
import { usePatient } from '../PPatientContext'

export const VisitNoteCaseReviewDrawer = ({
  opened,
  onClose,
  visitId,
}: {
  opened: boolean
  onClose: () => void
  visitId: `${number}`
}) => {
  const { patientQuery } = usePatient()

  const patientCocmItemQuery = useEmrQuery(
    'GET /cocmRegistry/patient/:patientId',
    {
      params: {
        patientId: patientQuery?.data?.oid ?? '',
      },
    },
    {
      enabled: Boolean(patientQuery?.data?.oid),
    },
  )

  const caseReviewNoteQuery = useEmrQuery('GET /caseReviewNote/visit/:visitId', {
    params: {
      visitId,
    },
  })

  const isLoading =
    patientCocmItemQuery?.isLoading || caseReviewNoteQuery?.isLoading || patientQuery?.isLoading

  const isLockedCaseReviewDrawerOpened = Boolean(
    caseReviewNoteQuery?.data?.isLocked && !isLoading && opened,
  )

  const isUnlockedCaseReviewDrawerOpened =
    !caseReviewNoteQuery?.data?.isLocked && !isLoading && opened

  return (
    <>
      {isLoading && (
        <Drawer
          title='Case review note'
          opened={opened && isLoading}
          onClose={onClose}
          position='right'
          size='lg'
        >
          <ALoadingSpinner />
        </Drawer>
      )}
      {patientCocmItemQuery?.data && patientQuery?.data && caseReviewNoteQuery?.data && (
        <LockedCaseReviewDrawer
          showName={false}
          opened={isLockedCaseReviewDrawerOpened}
          onClose={onClose}
          caseReviewNote={caseReviewNoteQuery?.data}
          patient={patientQuery?.data}
        />
      )}
      {patientQuery?.data && caseReviewNoteQuery.data && patientCocmItemQuery.data && (
        <UnlockedCaseReviewDrawer
          showName={false}
          opened={isUnlockedCaseReviewDrawerOpened}
          onClose={onClose}
          patientCocmItem={patientCocmItemQuery.data}
          patientName={{
            firstName: patientQuery.data?.personalData?.firstName ?? '',
            lastName: patientQuery.data?.personalData?.lastName ?? '',
          }}
          caseReviewNote={caseReviewNoteQuery.data}
        />
      )}
    </>
  )
}
