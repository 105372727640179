import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Space,
  Text,
} from '@shared/components'
import { PrescriptionCancelationReason } from '@shared/types'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { prescriptionsApi } from '../../api'

type PrescriptionCancelModalProps = {
  onClose: () => void
  patientId: string
  prescriptionId: number
  cancelationReason: PrescriptionCancelationReason
  prescriptionCancelAllowed: boolean
  setPrescriptionBanner: (message: string, type: 'success' | 'warning' | 'error') => void
}

const PrescriptionCancelContent = ({
  onClose,
  patientId,
  prescriptionId,
  cancelationReason,
  setPrescriptionBanner,
  prescriptionCancelAllowed,
}: PrescriptionCancelModalProps) => {
  const queryClient = useQueryClient()

  const cancelPrescription = useMutation(prescriptionsApi.cancelPrescription, {
    onSuccess: () => {
      void queryClient.invalidateQueries()
      setPrescriptionBanner('Prescription successfully canceled', 'success')
      onClose()
    },
    onError: (error: AxiosError<{ message: 'string' }>) => {
      const errorMessage = error.response?.data.message
      void queryClient.invalidateQueries()
      setPrescriptionBanner(
        `Failed to cancel prescription. Message from DoseSpot: ${errorMessage}`,
        'error',
      )
      onClose()
    },
  })

  if (!prescriptionCancelAllowed) {
    return (
      <>
        <ModalHeader onClose={onClose}>Cannot cancel prescription</ModalHeader>
        <ModalContent>
          <Text>
            Prescriptions cannot be canceled before they have been received by the pharmacy.
          </Text>
          <Space h='md' />
          <Text>
            Try again once the prescription status has changed to &quot;Received by pharmacy&quot;.
          </Text>
        </ModalContent>
        <ModalFooter>
          <Group position='right'>
            <PrimaryButton onClick={onClose}>Ok, got it</PrimaryButton>
          </Group>
        </ModalFooter>
      </>
    )
  }

  return (
    <>
      <ModalHeader onClose={onClose}>Cancel prescription</ModalHeader>
      <ModalContent>
        <Text>
          You are about to cancel this prescription on behalf of the prescribing clinician. Are you
          sure you want to proceed?
        </Text>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don’t cancel</SecondaryButton>
          <PrimaryButton
            loading={cancelPrescription.isLoading}
            onClick={() =>
              cancelPrescription.mutate({
                patientId,
                prescriptionId: prescriptionId.toString(),
                cancelationReason,
              })
            }
          >
            Yes, cancel
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const PrescriptionCancelModal = ({
  opened,
  ...props
}: PrescriptionCancelModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <PrescriptionCancelContent {...props} />
    </BetterModal>
  )
}
