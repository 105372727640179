import {
  AccordionTimelineCard,
  ClockIcon,
  RichTextEditor,
  TertiaryButton,
  Text,
  TimelineCardTitle,
  TrashCanIcon,
  UserIcon,
} from '@shared/components'
import { NonVisitEventTitle, isAdmin, isLeadCoordinator } from '@shared/types'
import { dayjs } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { useAuth } from '../../../../context/auth'
import { DeleteNonVisitEventModal } from '../../DeleteNonVisitEventModal'
import { JourneyItemContent, usePatient } from '../../PPatientContext'
import { FooterContentSection } from './SharedContent'

const mapTitleToAbbreviation = (title: NonVisitEventTitle) => {
  if (title === 'Miscellaneous Event') {
    return 'Misc. event'
  }

  if (title === 'Medical/Psych Event') {
    return 'Med/psych event'
  }

  if (title === 'Care Coordination Event') {
    return 'CC event'
  }

  if (title === 'CoCM treatment guidance' || title === 'CoCM discharge') {
    return title
  }

  return capitalize(title)
}

export const NonVisitEventContent = ({
  nonVisitEvent,
}: {
  nonVisitEvent: JourneyItemContent<'non-visit-event'>
}) => {
  const { patientId } = usePatient()
  const [openDeleteNonVisitEventModal, setOpenDeleteNonVisitEventModal] = useState(false)

  const { currentUser } = useAuth()
  const datetime = dayjs(nonVisitEvent.createdAt)

  const canDeleteNonVisitEvent = isAdmin(currentUser) || isLeadCoordinator(currentUser)

  const getTitle = () => {
    if (nonVisitEvent.taskId) {
      return nonVisitEvent.title
    }

    const titleAbbrev = mapTitleToAbbreviation(nonVisitEvent.title)

    if (nonVisitEvent.titleFreeform) {
      return `${nonVisitEvent.titleFreeform} (${titleAbbrev})`
    }

    return `${titleAbbrev}`
  }

  return (
    <AccordionTimelineCard leftSide={<TimelineCardTitle title={getTitle()} />}>
      <RichTextEditor value={nonVisitEvent.content} editable={false} />
      <FooterContentSection
        right={
          canDeleteNonVisitEvent && (
            <TertiaryButton
              leftIcon={<TrashCanIcon />}
              onClick={() => setOpenDeleteNonVisitEventModal(true)}
            >
              Delete
            </TertiaryButton>
          )
        }
      >
        <FooterContentSection.Item>
          <UserIcon color={({ text }) => text[1]} />
          <Text size='xs' style={{ whiteSpace: 'nowrap' }} color={({ text }) => text[1]}>
            {nonVisitEvent.author.name}
          </Text>
        </FooterContentSection.Item>
        <FooterContentSection.Item>
          <ClockIcon color={({ text }) => text[1]} />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs' color={({ text }) => text[1]}>
            {datetime.format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
      </FooterContentSection>
      <DeleteNonVisitEventModal
        opened={openDeleteNonVisitEventModal}
        onClose={() => setOpenDeleteNonVisitEventModal(false)}
        patientId={patientId}
        nonVisitEventId={nonVisitEvent.oid}
      />
    </AccordionTimelineCard>
  )
}
