import { toTime } from '@shared/utils'
import axios from 'axios'
import * as Fullstory from '../utils/fullstory'

const axiosInstance = axios.create({
  timeout: toTime('1 min').ms(),
})

axios.interceptors.response.use(undefined, error => {
  Fullstory.log('error', JSON.stringify({ error, api: 'https://storage.googleapis.com' }))
  return Promise.reject(error)
})

export class StorageApi {
  addFile = (arg: { signedUrl: string; file: File }) => {
    return axiosInstance({
      method: 'PUT',
      url: arg.signedUrl,
      data: arg.file,
      headers: { 'Content-Type': arg.file.type },
    })
  }

  getFile = async ({ url, fileName }: { url: string; fileName: string }) => {
    const response = await axiosInstance({
      method: 'GET',
      url,
      responseType: 'blob',
    })

    const type = response.headers['content-type']
    if (!type) {
      throw new Error(`File has no content type!`)
    }

    return new File([response.data], fileName, { type })
  }
}
