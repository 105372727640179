import { ActionItem } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'
export class ActionItemsApi extends BaseApi {
  getOpenActionItems = ({ employeeId }: { employeeId: string }) => {
    return this.json<ActionItem[]>({
      method: 'GET',
      url: `/employee/${employeeId}/actionItems`,
      params: { open: 'true' },
    })
  }

  getOpenActionItemsCount = ({ employeeId }: { employeeId: string }) => {
    return this.json<number>({
      method: 'GET',
      url: `/employee/${employeeId}/actionItems/count`,
      params: { open: 'true' },
    })
  }

  deleteActionItem = ({
    actionItemId,
    employeeId,
  }: {
    actionItemId: string
    employeeId: string
  }) => {
    return this.json<Res>({
      method: 'DELETE',
      url: `/employee/${employeeId}/actionItems/${actionItemId}`,
    })
  }
}
