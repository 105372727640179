import {
  Accordion,
  CircleWithText,
  Divider,
  Group,
  MinusCircleIcon,
  useMantineTheme,
} from '@shared/components'
import { ActionItem } from '@shared/types'
import React, { useEffect } from 'react'
import MActionItem from './MActionItem'
import MActionItemEmptyState from './MActionItemEmptyState'

type MActionItemGroupProps = {
  sortedBy: string
  groupName: string
  actionItems: ActionItem[]
  currentAccordionValues: string[]
  setCurrentAccordionValues: React.Dispatch<React.SetStateAction<string[]>>
  refetch: () => void
}

const MActionItemGroup = ({
  sortedBy,
  groupName,
  actionItems,
  currentAccordionValues,
  setCurrentAccordionValues,
  refetch,
}: MActionItemGroupProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const groupHasActionItems = actionItems.length > 0
  useEffect(() => {
    if (groupHasActionItems && !currentAccordionValues.includes(groupName)) {
      setCurrentAccordionValues(v => [...v, groupName])
    }
  }, [])
  const accordionIsOpen = currentAccordionValues.includes(groupName)

  return (
    <Accordion.Item
      value={groupName}
      sx={{
        backgroundColor: colors.background[1],
        borderTop: `${sizes.border.md} solid`,
        borderBottom: `${sizes.border.md} solid`,
        borderColor: colors.background[2],
      }}
    >
      <Accordion.Control
        chevron={accordionIsOpen && <MinusCircleIcon color={colors.actions[1]} size='md' />}
        sx={{
          paddingLeft: sizes.padding.lg,
        }}
      >
        <Group>
          {groupName}
          <CircleWithText>{actionItems.length}</CircleWithText>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        {groupHasActionItems ? (
          actionItems.map((actionItem, index) => (
            <>
              {index !== 0 && <Divider />}
              <MActionItem key={actionItem.oid} actionItem={actionItem} refetch={refetch} />
            </>
          ))
        ) : (
          <MActionItemEmptyState
            allEmployeeActionsCompleted={false}
            sortedBy={sortedBy}
            groupName={groupName}
          ></MActionItemEmptyState>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default MActionItemGroup
