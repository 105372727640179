import { Page } from '../../../components/templates/TDefault'
import AdminHeaderContent from '../AdminHeaderContentProps'
import { AdminPage } from '../PAdmin'
import { EmployeeTables } from './EmployeeTable'

export const PWorkingHours = () => {
  return (
    <Page
      title='Admin'
      headerContent={<AdminHeaderContent selectedPage={AdminPage.WorkingHours} />}
      pushHeaderContent={false}
    >
      <EmployeeTables />
    </Page>
  )
}
