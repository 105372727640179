import { Group, Stack, TertiaryButton, TextInput, TrashIcon } from '@shared/components'

/**
 * This is the section of the drawer that allows the user to map an eligible payer to a candid payer.
 */
export const PayerMapSection = (props: {
  canRemove: boolean
  candidPayerIdError?: string
  eligiblePayerIdError?: string
  values: {
    eligiblePayerId: string
    candidPayerId: string
  }
  setEligiblePayerId: (value: string) => void
  setCandidPayerId: (value: string) => void
  removePayerMap: () => void
}) => {
  return (
    <Stack spacing='md'>
      <Group grow>
        <TextInput
          label='Eligible payer ID'
          onChange={value => {
            props.setEligiblePayerId(value)
          }}
          value={props.values.eligiblePayerId}
          error={props.eligiblePayerIdError}
        />
        <TextInput
          label='Candid Payer ID'
          error={props.candidPayerIdError}
          value={props.values.candidPayerId}
          onChange={value => {
            props.setCandidPayerId(value)
          }}
        />
      </Group>
      {props.canRemove && (
        <TertiaryButton
          onClick={() => {
            props.removePayerMap()
          }}
          leftIcon={<TrashIcon />}
        >
          Remove Eligible payer
        </TertiaryButton>
      )}
    </Stack>
  )
}
