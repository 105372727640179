const dictionary = {
  required: 'This field is required',
  requiredChoice: 'This choice is required',
  zipCodeFormat: 'Invalid format: ZIP code consists of 5 digits',
  zipCodePlusFour: 'Invalid format: ZIP code plus four includes 4 digits',
  phoneFormat: 'Invalid format: Phone number consists of 10 digts',
  number: 'This field must be a number',
  date: 'This field must be a date of format MM/DD/YYYY',
  alternativeDate: 'This field must be a date of format YYYY-MM-DD',
  state: 'This field must be in a two letter state format, with no spaces',
}

export const {
  required,
  requiredChoice,
  zipCodeFormat,
  phoneFormat,
  number,
  date,
  alternativeDate,
  zipCodePlusFour,
  state,
} = dictionary
