import { Box, BoxProps, ExternalLinkText, Image, useMantineTheme } from '@shared/components'
import { getTwilioUrl } from '../../../../../utils/twilio'

type MessageImageProps = {
  url: string
} & BoxProps

export const MessageImage = ({ url, ...props }: MessageImageProps) => {
  const { radius } = useMantineTheme()

  const twilioUrl = getTwilioUrl(url)

  return (
    <Box
      w='50%'
      sx={{
        borderRadius: radius.sm,
      }}
      {...props}
    >
      <ExternalLinkText href={twilioUrl} target='_blank'>
        <Image mr='auto' radius='md' src={twilioUrl} fit='contain' />
      </ExternalLinkText>
    </Box>
  )
}
