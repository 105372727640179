import React from 'react'

export type ODSInputProps = {
  autoComplete?: string
  autoFocus?: boolean
  className?: string
  defaultValue?: string | number
  disabled?: boolean
  error?: boolean
  maxLength?: number
  name?: string
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onKeyPress?: (e: React.FormEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.FormEvent<HTMLInputElement>) => void
  pattern?: string
  placeholder?: string
  transparent?: boolean
  type?: string
  value?: string | number
}

const ODSInput = React.forwardRef<HTMLInputElement, ODSInputProps>(
  (
    {
      autoComplete = 'off',
      autoFocus,
      className,
      defaultValue,
      disabled,
      error,
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyPress,
      onKeyUp,
      pattern,
      placeholder,
      transparent,
      type,
      value,
      ...props
    },
    ref,
  ) => {
    let stateSpecificClassNames = transparent ? '' : 'focus:shadow-focus'

    if (error) {
      stateSpecificClassNames = `${
        transparent ? '' : 'focus:shadow-focus-error'
      } text-red border-red-light`
    }

    return (
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={`block w-full shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-daybreak-actions1 focus:border-daybreak-actions1 sm:text-sm border-daybreak-actions0 rounded-md ${
          transparent ? '' : 'border shadow-sm'
        } ${stateSpecificClassNames} ${disabled ? 'opacity-40 cursor-not-allowed' : ''} ${
          className ?? ''
        }`}
        disabled={disabled}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        pattern={pattern}
        placeholder={placeholder}
        ref={ref}
        type={type || 'text'}
        value={value}
        defaultValue={defaultValue}
        {...props}
      />
    )
  },
)

ODSInput.displayName = 'ODSInput'

export default ODSInput
