import { MdAddAlert } from '@react-icons/all-files/md/MdAddAlert'
import { ProblemAddressed, ProblemListProblem } from '@shared/types'
import algoliasearch from 'algoliasearch'
import { Fragment, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import IconCollapse from '../../../components/icons/IconCollapse'
import { required } from '../../../components/molecules/MDictionary'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import { Config } from '../../../config'
import { commonProblems } from '../../../utils/utils'
import MProblemsAddressedDetails from '../MProblemsAddressedDetails'

const client = algoliasearch(Config.ALGOLIA_APPLICATION_ID, Config.ALGOLIA_SEARCH_KEY)

const index = client.initIndex('icd10Codes')

const ALoadingList = () => {
  return (
    <ul className='relative z-0 divide-y divide-gray-200'>
      <li className='bg-white'>
        <div className='relative px-6 py-5 space-y-1'>
          <div className='h-5 w-52 rounded-md bg-gray-400 animate-pulse' />
          <div className='h-4 w-20 rounded-md bg-gray-400 animate-pulse' />
        </div>
      </li>
      <li className='bg-white'>
        <div className='relative px-6 py-5 space-y-1'>
          <div className='h-5 w-52 rounded-md bg-gray-400 animate-pulse' />
          <div className='h-4 w-20 rounded-md bg-gray-400 animate-pulse' />
        </div>
      </li>
      <li className='bg-white'>
        <div className='relative px-6 py-5 space-y-1'>
          <div className='h-5 w-52 rounded-md bg-gray-400 animate-pulse' />
          <div className='h-4 w-20 rounded-md bg-gray-400 animate-pulse' />
        </div>
      </li>
    </ul>
  )
}

type OAddProblemListModalProps = {
  closeModal: () => void
  setDiagnoses?: (diagnoses: ProblemListProblem[]) => void
  saveDiagnoses: ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => Promise<void>
}

const OAddProblemListModal = ({
  closeModal,
  setDiagnoses,
  saveDiagnoses,
}: OAddProblemListModalProps) => {
  const { register, handleSubmit, watch, errors, triggerValidation } = useForm()

  const [problemSearchQuery, setProblemSearchQuery] = useState('')
  const [searchingProblemList, setSearchingProblemList] = useState(false)
  const [problemSearchList, setProblemSearchList] = useState<
    { code: string; full_description: string }[]
  >([])
  const [step, setStep] = useState(1)
  const [selectedProblem, setSelectedProblem] = useState<{
    code: string
    full_description: string
    status?: 'addressed'
  } | null>(null)

  const progression = watch('progression')
  const chronicity = watch('chronicity')
  const masterWatcher = watch()

  useEffect(() => {
    const fetchData = async () => {
      setSearchingProblemList(true)

      const searchResult: { hits: { code: string; full_description: string }[] } =
        await index.search(problemSearchQuery, { hitsPerPage: 100, filters: 'NOT validity:I' })
      setProblemSearchList(searchResult.hits)

      setSearchingProblemList(false)
    }

    if (problemSearchQuery) {
      void fetchData()
    } else {
      setProblemSearchList([])
    }
  }, [problemSearchQuery])

  const onProblemSelected = (problem: { code: string; full_description: string }) => {
    setSelectedProblem({ ...problem, status: 'addressed' })
    setStep(3)
  }

  const onProblemSubmitted = async (data: FieldValues) => {
    const result = await triggerValidation(['progression', 'chronicity'])
    if (!result) {
      return
    }
    const problemToSave = {
      code: selectedProblem?.code,
      full_description: selectedProblem?.full_description,
      chronicity: data.chronicity,
      progression: data.progression,
      status: 'addressed',
      treating: false,
    } as ProblemAddressed
    void saveDiagnoses({
      diagnoses: [problemToSave],
    })
    setDiagnoses?.([problemToSave])
    closeModal()
  }

  const onMultipleProblemsSubmitted = async () => {
    const result = await triggerValidation()
    if (!result) {
      return
    }
    const newProblems: ProblemListProblem[] = []
    Object.entries(masterWatcher).forEach(([key, value]) => {
      if (typeof value === 'boolean' && value) {
        newProblems.push({
          code: '',
          full_description: '',
          chronicity: masterWatcher[`${key}_chronicity`],
          progression: masterWatcher[`${key}_progression`],
          status: 'addressed',
          treating: false,
          ...commonProblems.find(problem => problem.code.replace('.', '') === key),
        })
      }
    })
    void saveDiagnoses({
      diagnoses: newProblems,
    })
    setDiagnoses?.(newProblems)
    closeModal()
  }

  return (
    <Fragment>
      {step > 1 && (
        <div className='absolute top-0 left-0'>
          <button
            className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 m-2'
            onClick={() => setStep(step - 1)}
          >
            <IconCollapse />
          </button>
        </div>
      )}
      <div className='w-full'>
        {step === 1 && (
          <form>
            <div className='flex flex-col items-center justify-start m-2'>
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <p className='font-semibold my-3 text-lg'>Add Problems to the Problem List</p>
                  <div className='text-sm ml-3'>
                    <button
                      type='button'
                      onClick={e => {
                        e.stopPropagation()
                        setStep(2)
                      }}
                      className='z-50 font-medium text-daybreak-text3 underline focus:outline-none'
                    >
                      Other <span aria-hidden='true'>&rarr;</span>
                    </button>
                  </div>
                </div>
                <div className='h-96 relative overflow-y-auto rounded-lg w-full'>
                  <ul className='relative z-0 divide-y divide-gray-200'>
                    {commonProblems.map(problem => (
                      <li
                        key={problem.code}
                        className='flex flex-col bg-white p-4 items-start justify-start'
                      >
                        <div className='flex justify-start items-center'>
                          <input
                            ref={register()}
                            name={problem.code.replace('.', '')}
                            id={problem.code.replace('.', '')}
                            type='checkbox'
                            className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300 rounded'
                          />
                          <div className='flex flex-col justify-center items-start min-w-0 ml-4'>
                            <p className='text-sm font-medium text-gray-900 text-left'>
                              {problem.full_description}
                            </p>
                            <p className='text-xs text-gray-500 truncate mt-0.5'>{problem.code}</p>
                          </div>
                        </div>
                        {masterWatcher[problem.code.replace('.', '')] && (
                          <MProblemsAddressedDetails
                            register={register}
                            code={problem.code.replace('.', '')}
                            masterWatcher={masterWatcher}
                            errors={errors}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <ODSPrimaryButton
                message='Add Problems'
                className='w-full inline-flex justify-center'
                type='button'
                onClick={() => onMultipleProblemsSubmitted()}
              />
            </div>
          </form>
        )}
        {step === 2 && (
          <form>
            <div className='flex flex-col items-center justify-start m-2'>
              <div className='w-full'>
                <div className='flex justify-betwee'>
                  <p className='font-semibold my-3 text-lg'>Search for a problem</p>
                </div>
                <div className='relative flex items-stretch flex-grow focus-within:z-10 w-full'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <MdAddAlert className='h-5 w-5 text-gray-400' />
                  </div>
                  <input
                    onChange={e => setProblemSearchQuery(e.target.value)}
                    autoComplete='off'
                    type='text'
                    name='query'
                    id='query'
                    ref={register()}
                    className='focus:ring-indigo-600 focus:border-indigo-600 block w-full rounded-none rounded-md pl-10 sm:text-sm border-gray-300'
                  />
                </div>
                <div className='h-80 relative overflow-y-auto rounded-lg w-full'>
                  {problemSearchList && problemSearchList.length === 0 && !searchingProblemList && (
                    <div className='flex items-center justify-center h-40'>
                      <p className='text-gray-500'>Start typing to search for a problem</p>
                    </div>
                  )}
                  {problemSearchList && problemSearchList.length > 0 && (
                    <ul className='relative z-0 divide-y divide-gray-200'>
                      {problemSearchList.map(problem => (
                        <li key={problem.code} className='bg-white'>
                          <button
                            type='button'
                            onClick={e => {
                              e.stopPropagation()
                              onProblemSelected(problem)
                            }}
                            className='relative px-6 py-5 flex items-center justify-between space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 focus:outline-none w-full'
                          >
                            <div className='flex flex-col justify-center items-start min-w-0'>
                              <p className='text-sm font-medium text-gray-900 text-left'>
                                {problem.full_description}
                              </p>
                              <p className='text-xs text-gray-500 truncate mt-1'>{problem.code}</p>
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                  {searchingProblemList && problemSearchList && problemSearchList.length === 0 && (
                    <ALoadingList />
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
        {step === 3 && selectedProblem && (
          <form onSubmit={handleSubmit(onProblemSubmitted)}>
            <div className='flex flex-col items-center justify-start m-2'>
              <div className='w-full space-y-4'>
                <p className='font-semibold text-lg'>Select Specifications of the Problem</p>
                <div className='flex flex-col justify-center items-start min-w-0'>
                  <p className='font-semibold text-gray-900 text-left'>
                    {selectedProblem.full_description}
                  </p>
                  <p className='text-xs text-gray-500 truncate mt-1'>{selectedProblem.code}</p>
                </div>
                <fieldset>
                  <span className='font-medium mb-2'>
                    Chronicity{' '}
                    <span className='font-normal text-red-800 ml-4'>{`${
                      errors.chronicity ? errors.chronicity.message : ''
                    }`}</span>
                  </span>
                  <div className='bg-white rounded-md -space-y-px'>
                    <label
                      className={`${
                        chronicity === 'Chronic'
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200'
                      } rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer`}
                    >
                      <input
                        ref={register({ required: { value: true, message: required } })}
                        type='radio'
                        name='chronicity'
                        value='Chronic'
                        className='h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
                      />
                      <div className='ml-3 flex flex-col'>
                        <span
                          id='chronicity-0-label'
                          className={`${
                            chronicity === 'Chronic' ? 'text-indigo-900' : 'text-gray-900'
                          } block text-sm`}
                        >
                          Chronic
                        </span>
                      </div>
                    </label>

                    <label
                      className={`${
                        chronicity === 'Acute'
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200'
                      } rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer`}
                    >
                      <input
                        ref={register({ required: { value: true, message: required } })}
                        type='radio'
                        name='chronicity'
                        value='Acute'
                        className='h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
                      />
                      <div className='ml-3 flex flex-col'>
                        <span
                          id='chronicity-1-label'
                          className={`${
                            chronicity === 'Acute' ? 'text-indigo-900' : 'text-gray-900'
                          } block text-sm`}
                        >
                          Acute
                        </span>
                      </div>
                    </label>
                  </div>
                </fieldset>
                <fieldset>
                  <span className='font-medium mb-2'>
                    Progression{' '}
                    <span className='font-normal text-red-800 ml-4'>{`${
                      errors.progression ? errors.progression.message : ''
                    }`}</span>
                  </span>
                  <div className='bg-white rounded-md -space-y-px'>
                    <label
                      className={`${
                        progression === 'Exacerbating'
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200'
                      } rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer`}
                    >
                      <input
                        ref={register({ required: { value: true, message: required } })}
                        type='radio'
                        name='progression'
                        value='Exacerbating'
                        className='h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
                      />
                      <div className='ml-3 flex flex-col'>
                        <span
                          id='progression-0-label'
                          className={`${
                            chronicity === 'Exacerbating' ? 'text-indigo-900' : 'text-gray-900'
                          } block text-sm`}
                        >
                          Exacerbating
                        </span>
                      </div>
                    </label>

                    <label
                      className={`${
                        progression === 'Stable'
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200'
                      } rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer`}
                    >
                      <input
                        ref={register({ required: { value: true, message: required } })}
                        type='radio'
                        name='progression'
                        value='Stable'
                        className='h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500'
                      />
                      <div className='ml-3 flex flex-col'>
                        <span
                          id='progression-1-label'
                          className={`${
                            chronicity === 'Stable' ? 'text-indigo-900' : 'text-gray-900'
                          } block text-sm`}
                        >
                          Stable
                        </span>
                      </div>
                    </label>
                  </div>
                </fieldset>
                <ODSPrimaryButton
                  message='Add Problem'
                  className='w-full inline-flex justify-center'
                  type='button'
                  onClick={() => onProblemSubmitted({ chronicity, progression })}
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </Fragment>
  )
}

export default OAddProblemListModal
