import { useQuery } from 'react-query'
import { actionItemsApi } from '../../api'

export const useAlertsCount = ({ employeeId = '' }: { employeeId: string }) => {
  const actionItemsCount = useQuery(
    ['actionItemsApi.getOpenActionItemsCount', employeeId],
    () => {
      return actionItemsApi.getOpenActionItemsCount({ employeeId })
    },
    { enabled: Boolean(employeeId) },
  )

  const refresh = () => {
    void actionItemsCount.refetch()
  }

  return {
    isLoading: actionItemsCount.isLoading,
    actionItemsCount: actionItemsCount.data || 0,
    refreshAlertsCount: refresh,
  }
}
