import {
  AlertIcon,
  CheckCircleIcon,
  Group,
  HoverCard,
  HoverCardProps,
  Loader,
  Stack,
  Text,
} from '@shared/components'
import { EmrApi } from '@shared/types'
import { useMemo } from 'react'

const TasksIcon = ({
  isRequiredTasksComplete,
  isOptionalTasksComplete,
}: {
  isRequiredTasksComplete: boolean
  isOptionalTasksComplete: boolean
}) => {
  if (isRequiredTasksComplete) {
    if (isOptionalTasksComplete) {
      return <CheckCircleIcon color={colors => colors.success[0]} />
    }
    return <AlertIcon color={colors => colors.success[0]} />
  }
  return <AlertIcon color={colors => colors.warning[0]} />
}
export const PatientTasksPopover = ({
  data,
  isLoading,
  isError,
  position,
  forTable,
}: {
  forTable?: boolean
  data: EmrApi['GET /patient/:patientId/tasks']['res'] | undefined
  isLoading: boolean
  isError: boolean
  position?: HoverCardProps['position']
}) => {
  const applicableTasks = data?.filter(task => task.isApplicable) || []
  const requiredTasks = applicableTasks.filter(task => task.requiredFor.length)
  const optionalTasks = applicableTasks.filter(task => !task.requiredFor.length && !task.isComplete)
  const requiredOpenTasks = requiredTasks.filter(task => !task.isComplete).length
  const optionalOpenTasks = optionalTasks.filter(task => !task.isComplete).length
  const isLoadingOrError = isLoading || isError

  const isRequiredTasksComplete = requiredOpenTasks === 0
  const isOptionalTasksComplete = forTable || optionalOpenTasks === 0

  const label = useMemo(() => {
    if (forTable) {
      if (requiredOpenTasks) {
        return `${requiredOpenTasks} open`
      }

      return `Complete`
    }

    return 'Patient tasks'
  }, [forTable, requiredOpenTasks])

  return (
    <HoverCard openDelay={500} withinPortal disabled={isLoadingOrError} position={position}>
      <HoverCard.Target>
        <Group spacing='xs'>
          {isLoadingOrError ? (
            <Loader size='xs' />
          ) : (
            <TasksIcon
              isRequiredTasksComplete={isRequiredTasksComplete}
              isOptionalTasksComplete={isOptionalTasksComplete}
            />
          )}
          <Text>{label}</Text>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack spacing='sm'>
          {Boolean(requiredTasks.length) && (
            <>
              <Text color={({ text }) => text[1]} size='xs'>
                Required
              </Text>
              {requiredTasks.map(task => (
                <Group key={task.type} spacing='sm'>
                  {task.isComplete ? (
                    <CheckCircleIcon color={colors => colors.success[0]} />
                  ) : (
                    <AlertIcon color={colors => colors.warning[0]} />
                  )}
                  <Text>{task.title}</Text>
                </Group>
              ))}
            </>
          )}
          {Boolean(optionalTasks.length) && (
            <>
              <Text color={({ text }) => text[1]} size='xs'>
                Optional
              </Text>
              {optionalTasks.map(task => (
                <Group key={task.type} spacing='sm'>
                  {task.isComplete ? (
                    <CheckCircleIcon color={colors => colors.success[0]} />
                  ) : (
                    <AlertIcon color={colors => colors.background[4]} />
                  )}
                  <Text>{task.title}</Text>
                </Group>
              ))}
            </>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
