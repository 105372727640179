import {
  AlertIcon,
  Box,
  Group,
  Switch,
  Text,
  Tooltip,
  TooltipLabel,
  useMantineTheme,
} from '@shared/components'
import { ObjectiveMeasureResponse, ObjectiveMeasuresTitle } from '@shared/types'
import { dayjs } from '@shared/utils'
import maxBy from 'lodash/maxBy'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { usePatient } from '../PPatientContext'
import TimeframeSelectorButton from './TimeframeSelectorButton'

const lastMeasureText = (dateString?: string) => {
  if (!dateString) {
    return null
  }

  const date = dayjs(dateString)
  return [
    'Last measure',
    date.isSame(dayjs(), 'day') ? 'today' : `on ${date.format('MMM D, YYYY')}`,
    `at ${date.format('h:mma z')}`,
  ].join(' ')
}

const tooltipText = (title: ObjectiveMeasuresTitle) => {
  if (title === 'PHQ-9') {
    return 'Depression'
  } else if (title === 'GAD-7') {
    return 'Anxiety'
  } else if (title === 'BARC-10') {
    return 'Substance use'
  } else if (title === 'ASQ') {
    return 'Suicide risk'
  }
}

type ObjectiveMeasuresChartHeaderProps = {
  title: ObjectiveMeasuresTitle
  data: ObjectiveMeasureResponse[] | undefined
  monthsOfData: number | undefined
  handleClick: (value: number | undefined) => void
  isLoading: boolean
  openModal: () => void
}

export const ObjectiveMeasuresChartHeader = ({
  title,
  data,
  monthsOfData,
  handleClick,
  isLoading,
  openModal,
}: ObjectiveMeasuresChartHeaderProps) => {
  const { patientQuery } = usePatient()
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const patient = patientQuery?.data

  const mostRecentResult = maxBy(data, d => d.completedAt)

  const formsWithSwitches: ObjectiveMeasuresTitle[] = ['PHQ-9', 'GAD-7', 'PHQ-8']
  const displaySwitch = formsWithSwitches.includes(title)

  const { phq9: phq9CollectionEnabled, gad7: gad7CollectionEnabled } = patient?.formsToCollect || {
    phq9: false,
    gad7: false,
  }

  const formCollectionEnabled =
    ((title === 'PHQ-9' || title === 'PHQ-8') && phq9CollectionEnabled) ||
    (title === 'GAD-7' && gad7CollectionEnabled)

  const stateWithMbcRequirement = ['new york']
  const patientLivesInStateWithMbcRequirement = stateWithMbcRequirement.includes(
    patient?.homeData?.state?.toLowerCase() || '',
  )

  const mustCollectMbcFromPatient =
    patientLivesInStateWithMbcRequirement &&
    formCollectionEnabled &&
    [phq9CollectionEnabled, gad7CollectionEnabled].filter(Boolean).length === 1

  const toggleTooltipText = 'Since this patient lives in NY, either PHQ-9 or GAD-7 must remain on'

  const isToggleDataLoading = patientQuery?.isFetching

  return (
    <Group p='sm' position='apart' sx={{ backgroundColor: colors.background[2] }}>
      <Group>
        {displaySwitch &&
          (isToggleDataLoading ? (
            <Box>
              <ALoadingSpinner />
            </Box>
          ) : (
            <Tooltip
              label={toggleTooltipText}
              color={colors.background[6]}
              disabled={!mustCollectMbcFromPatient}
            >
              <Group>
                <Switch
                  checked={formCollectionEnabled}
                  onChange={openModal}
                  disabled={mustCollectMbcFromPatient}
                />
              </Group>
            </Tooltip>
          ))}
        <TooltipLabel label={tooltipText(title)} color={colors.background[6]}>
          <Text bold>{title}</Text>
        </TooltipLabel>
        <Text size='xs' color={colors.text[1]}>
          {lastMeasureText(mostRecentResult?.completedAt || '')}
        </Text>
      </Group>
      {!data?.length && !isLoading ? (
        <Group spacing='sm' color={colors.text[1]}>
          <AlertIcon color={colors.text[1]} strokeWidth={sizes.border.md} />
          <Text color={colors.text[1]}>None found</Text>
        </Group>
      ) : (
        <Group spacing='xs'>
          <TimeframeSelectorButton
            text='3m'
            onClick={handleClick}
            active={monthsOfData === 3}
            numMonths={3}
          />
          <TimeframeSelectorButton
            text='6m'
            onClick={handleClick}
            active={monthsOfData === 6}
            numMonths={6}
          />
          <TimeframeSelectorButton
            text='12m'
            onClick={handleClick}
            active={monthsOfData === 12}
            numMonths={12}
          />
          <TimeframeSelectorButton
            text='All'
            onClick={handleClick}
            active={monthsOfData === undefined}
            numMonths={undefined}
          />
        </Group>
      )}
    </Group>
  )
}
