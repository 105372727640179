import { UseFormReturnType } from '@mantine/form'
import { Select, Stack, Textarea } from '@shared/components'

const priorAuthRationaleCategories = [
  'No prior authorization required',
  'Dose increase',
  'Formulation change',
  'Adverse reaction',
  'Pregnancy',
  'Insurance change',
  'Previous PA expired',
  'Other',
] as const
type PriorAuthRationaleCategory = (typeof priorAuthRationaleCategories)[number]

export const priorAuthRationaleSubCategories: Record<PriorAuthRationaleCategory, string[]> = {
  'No prior authorization required': [
    'Cash / GoodRx used for prescription pickup',
    'Medicaid used for prescription pickup',
    'Insurance not listed in EMR used for prescription pickup',
  ],
  'Dose increase': [
    "Above insurance's quantity limit",
    'Cravings',
    'Unstable at current dose',
    'Temporary',
    'Medical (surgery, pain, etc)',
  ],
  'Formulation change': ['Brand vs. generic', 'Film vs. tablet'],
  'Adverse reaction': ['Taste', 'Allergy', 'Difficulty swallowing', 'Nausea/vomiting'],
  Pregnancy: [],
  'Insurance change': [],
  'Previous PA expired': [],
  Other: [],
}

type PriorAuthRationaleSubCategory =
  (typeof priorAuthRationaleSubCategories)[PriorAuthRationaleCategory][number]

export type PriorAuthRationale = {
  category: PriorAuthRationaleCategory
  subCategory: PriorAuthRationaleSubCategory
  additionalInfo: string
}

export const PriorAuthRationale = ({
  priorAuthRationaleForm,
}: {
  priorAuthRationaleForm: UseFormReturnType<PriorAuthRationale>
}) => {
  return (
    <Stack>
      <Select
        {...priorAuthRationaleForm.getInputProps('category')}
        label='Clinical rationale for prior auth'
        data={priorAuthRationaleCategories}
        placeholder='Select one...'
      />
      {priorAuthRationaleSubCategories[priorAuthRationaleForm.values.category]?.length > 0 && (
        <Select
          {...priorAuthRationaleForm.getInputProps('subCategory')}
          data={priorAuthRationaleSubCategories[priorAuthRationaleForm.values.category]}
          placeholder='Select one...'
        />
      )}
      {Boolean(priorAuthRationaleForm.values.category) && (
        <Textarea
          {...priorAuthRationaleForm.getInputProps('additionalInfo')}
          label='Additional info (optional)'
          placeholder='Share any additional info to help clarify your clinical rationale'
        />
      )}
    </Stack>
  )
}
