import { useForm } from '@mantine/form'
import {
  Checkbox,
  Group,
  InputWrapper,
  SaveIcon,
  Skeleton,
  Stack,
  TertiaryButton,
} from '@shared/components'
import { InsuranceData, InsurancePlanId, Patient, hasStatus } from '@shared/types'
import { getInsurancePlanId, getPayerIdAndProvider, isPayerInNetwork } from '@shared/utils'
import { useMutation, useQuery } from 'react-query'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'
import { InsuranceSelect } from '../../components/forms/InsuranceSelect'
import { useInvalidateQuery } from '../../utils/hooks'
import { usePatient } from './PPatientContext'

/*
 * This sidebar component is only to be shown for an insurance empty state.
 * It is mainly here to be used during the Welcome Call to get a preliminary
 * payer ID on file for the patient before booking an intake
 */
export const InsuranceSidebar = ({
  onClose,
  onSubmit,
}: {
  onClose?: () => void
  onSubmit?: () => void
}) => {
  const { patientId, patientQuery } = usePatient()

  const primaryInsuranceQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurance/:insuranceId', {
      params: {
        patientId,
        insuranceId: patientQuery?.data?.insuranceData?.primaryInsuranceId ?? '',
      },
    }),
    {
      enabled: Boolean(
        patientQuery?.data?.insuranceData?.hasInsurance &&
          patientQuery?.data?.insuranceData?.primaryInsuranceId,
      ),
    },
  )

  const patient = patientQuery?.data
  const primaryInsurance = primaryInsuranceQuery?.data?.basicInsuranceData

  if (primaryInsuranceQuery.isLoading || !patient) {
    return <Skeleton h='2rem' />
  }

  return (
    <InsuranceSelection
      onClose={onClose}
      onSubmit={onSubmit}
      primaryInsurance={primaryInsurance}
      patient={patient}
      patientId={patientId}
    />
  )
}

const InsuranceSelection = ({
  primaryInsurance,
  patient,
  patientId,
  onClose,
  onSubmit,
}: {
  primaryInsurance: InsuranceData | null | undefined
  patient: Patient
  patientId: string
  onClose?: () => void
  onSubmit?: () => void
}) => {
  const invalidate = useInvalidateQuery()
  const { getInputProps, values, isDirty } = useForm<{
    insurancePayer: InsurancePlanId | null
    hasMedicaid: boolean
  }>({
    initialValues: {
      insurancePayer:
        getInsurancePlanId(primaryInsurance?.eligiblePayerId, primaryInsurance?.provider) || null,
      hasMedicaid: primaryInsurance?.planType === 'MC',
    },
  })

  const submitInsuranceDataMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/insurance/update'),
    {},
  )

  const save = async () => {
    const { eligiblePayerId, provider } = getPayerIdAndProvider(values.insurancePayer ?? '')
    await submitInsuranceDataMutation.mutateAsync({
      params: { patientId },
      data: {
        eligiblePayerId,
        provider,
        insuranceType: 'primary',
        planType: values.hasMedicaid ? 'MC' : null,
      },
    })

    await invalidate('GET /patient/:patientId')
    await invalidate('GET /patient/:patientId/insurances')

    const primaryInsuranceId = patient.insuranceData?.primaryInsuranceId
    if (primaryInsuranceId) {
      await invalidate('GET /patient/:patientId/insurance/:insuranceId', {
        params: {
          patientId,
          insuranceId: primaryInsuranceId,
        },
      })
    }

    if (!hasStatus(patient, 'lead')) {
      analytics.track('Insurance provider collected during Welcome Call', {
        patientId,
        eligiblePayerId,
        provider,
        hasMedicaid: values.hasMedicaid,
        isCurrentlyInNetwork: isPayerInNetwork({
          payerId: eligiblePayerId,
          state: patient.homeData?.state,
        }),
      })
    }

    onSubmit?.()
    onClose?.()
  }

  // Only allow to change plan type if empty or medicaid
  const showMedicaidCheckbox = primaryInsurance?.planType === 'MC' || !primaryInsurance?.planType

  return (
    <Stack spacing='sm'>
      <InsuranceSelect
        patientId={patientId}
        {...getInputProps('insurancePayer')}
        placeholder='Insurance provider (optional)...'
      />
      {showMedicaidCheckbox && (
        <InputWrapper explanation='Select if primary insurance is Medicaid'>
          <Checkbox {...getInputProps('hasMedicaid', { type: 'checkbox' })} label='Medicaid' />
        </InputWrapper>
      )}
      <Group position='right' noWrap>
        {onClose && <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>}
        <TertiaryButton
          leftIcon={<SaveIcon />}
          disabled={submitInsuranceDataMutation.isLoading || !isDirty()}
          loading={submitInsuranceDataMutation.isLoading}
          onClick={save}
        >
          Save
        </TertiaryButton>
      </Group>
    </Stack>
  )
}
