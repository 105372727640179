import { Configs } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class ConsultationQueueApi extends BaseApi {
  getConfig = () => {
    return this.json<Configs['consultationQueue']>({
      method: 'GET',
      url: '/consultations/queue/config',
    })
  }

  updateConfig = (data: { available: boolean }) => {
    return this.json<void>({
      method: 'PUT',
      url: '/consultations/queue/config/availability/update',
      data,
    })
  }

  removeAppointment = (data: { appointmentId: string }) => {
    return this.json<void>({
      method: 'PUT',
      url: '/consultations/queue/remove',
      data,
    })
  }
}
