import { Patient } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'

export function usePatientInsurances(patient?: Patient) {
  const insurancesQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurances', {
      params: { patientId: patient?.oid || '' },
    }),
    {
      enabled: Boolean(patient),
      select: insurances => {
        const primaryInsurance = insurances.find(
          insurance => patient?.insuranceData?.primaryInsuranceId === insurance.oid,
        )
        const secondaryInsurance = insurances.find(
          insurance => patient?.insuranceData?.secondaryInsuranceId === insurance.oid,
        )
        return {
          primaryInsurance,
          secondaryInsurance,
        }
      },
    },
  )

  return insurancesQuery
}
