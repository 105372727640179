import { Appointment, ClosedNoteDetails } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'

type OLegacyVisitFormProps = {
  patientID: string
  visitID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: Record<string, any> | null
  editMode: boolean
  sidebar: boolean
  closedNoteDetails: ClosedNoteDetails | undefined | null
  visit: Appointment | null
  setCloseNoteModal: (closeNoteModal: boolean) => void
}

const OLegacyVisitForm = ({
  patientID,
  visitID,
  notes,
  editMode,
  sidebar,
  closedNoteDetails,
  visit,
  setCloseNoteModal,
}: OLegacyVisitFormProps) => {
  const { register, handleSubmit, setValue, watch } = useForm()

  const [savedAt, setSavedAt] = useState('')
  const [saving, setSaving] = useState(false)
  const [contentsToSave, setContentsToSave] = useState<FieldValues | null>(null)

  const masterWatcher = watch()

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  useEffect(() => {
    if (!notes) {
      return
    }

    for (const [key, value] of Object.entries(notes)) {
      setValue(key, value)
    }
  }, [notes, setValue])

  const save = useCallback(
    async (data: FieldValues) => {
      const content = { ...data }

      const body = {
        type: 'legacy' as const,
        content,
      }

      await updateVisitNote.mutateAsync(
        {
          params: {
            patientId: patientID,
            visitId: visitID,
          },
          data: body,
        },
        {
          onSuccess: () => {
            setSavedAt(dayjs().format('MMMM Do YYYY, h:mm:ss a'))
            setContentsToSave(data)
          },
        },
      )
    },
    [editMode, patientID, visitID, visit],
  )

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (!editMode) {
        return
      }
      if (isEmpty(masterWatcher)) {
        return
      }
      if (isEqual(masterWatcher, contentsToSave)) {
        return
      }

      await save(masterWatcher)
    }, toTime('3 sec').ms())

    return () => clearTimeout(interval)
  }, [masterWatcher, editMode, save, contentsToSave])

  const saveNote = async () => {
    setSaving(true)
    await save(masterWatcher)
  }

  const onSubmit = async () => {
    setCloseNoteModal(true)
    setSaving(true)
    await save(masterWatcher)
  }

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  const dateSigned = closedNoteDetails?.locked_at ? dayjs(closedNoteDetails.locked_at) : undefined

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      {!sidebar && (
        <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-50'>
          <div className='flex flex-row h-full'>
            <div className='flex justify-start items-center flex-grow-1 flex-shrink-0'>
              {savedAt && editMode && (
                <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
              )}
              {!editMode && closedNoteDetails && (
                <p className='text-xs text-gray-500'>
                  Signed on
                  {dateSigned?.isValid()
                    ? dateSigned.format(' MMMM D, YYYY [at] h:mm a ')
                    : ` ${closedNoteDetails.locked_at} `}
                  by {closedNoteDetails.locked_by_name}
                </p>
              )}
            </div>
            {editMode && (
              <MVisitNoteSavingHeader
                dateTime={visit?.datetime}
                saveNote={saveNote}
                saving={saving}
                editMode={editMode}
              />
            )}
          </div>
        </div>
      )}
      <ANoteSection hideBorder>
        <ANoteHeader text='Content from the EMR' />
        <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
          <ANoteTextArea
            name='content'
            id='content'
            minRows={3}
            ref={register()}
            disabled={!editMode}
            sidebar={sidebar}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Content Copied from Acuity' />
        <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
          <ANoteTextArea
            name='acuity_content'
            id='acuity_content'
            minRows={3}
            ref={register()}
            disabled={!editMode}
            sidebar={sidebar}
          />
        </ANoteSectionContent>
      </ANoteSection>
    </form>
  )
}

export default OLegacyVisitForm
