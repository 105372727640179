import { useForm } from '@mantine/form'
import {
  Box,
  EditIcon,
  Grid,
  Group,
  Stack,
  TertiaryButton,
  TrashIcon,
  validateWith,
} from '@shared/components'
import { ServiceLine } from '@shared/types'
import { useEffect } from 'react'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { isNumber, isRequired } from '../../../../utils/formValidation'
import { CardTextInput } from './CardTextInput'
import { LabeledData } from './LabeledData'

export type ServiceLineItemProps = {
  serviceLine?: ServiceLine
  isEditing?: boolean
  placeOfServiceCode: string
  showEditButton?: boolean
  onSave: (serviceLine: ServiceLine) => void
  onCancel: () => void
  editOnClick?: (serviceLine: ServiceLine) => void
  removeOnClick?: (serviceLine: ServiceLine) => void
}

export const ServiceLineItem = ({
  serviceLine,
  placeOfServiceCode,
  isEditing = false,
  showEditButton,
  onSave,
  onCancel,
  editOnClick,
  removeOnClick,
}: ServiceLineItemProps) => {
  type ServiceLineForm = Omit<ServiceLine, 'diagnosis_pointers' | 'charge_amount_cents'> & {
    diagnosis_pointers: string
    charge_amount_cents: string
  }
  const form = useForm<ServiceLineForm>({
    initialValues: {
      procedure_code: serviceLine?.procedure_code || '',
      quantity: serviceLine?.quantity || '',
      units: serviceLine?.units || '',
      charge_amount_cents: String(serviceLine?.charge_amount_cents ?? ''),
      diagnosis_pointers: String(serviceLine?.diagnosis_pointers?.[0] ?? ''),
    },
    validate: {
      procedure_code: validateWith(isRequired),
      quantity: validateWith(isRequired, isNumber),
      charge_amount_cents: validateWith(isRequired, isNumber),
      diagnosis_pointers: validateWith(isRequired, isNumber),
      units: validateWith(isRequired),
    },
  })

  useEffect(() => {
    form.validate()
  }, [form.values])

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.other.colors.background[2],
        borderRadius: theme.radius.md,
      })}
      p='md'
    >
      <Stack>
        <Grid columns={12}>
          <Grid.Col span={4}>
            <CardTextInput
              label='Procedure code'
              {...form.getInputProps('procedure_code')}
              editable={isEditing}
              placeholder='99340'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Charge amount (cents)'
              {...form.getInputProps('charge_amount_cents')}
              editable={isEditing}
              placeholder='4671'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Quantity'
              {...form.getInputProps('quantity')}
              editable={isEditing}
              placeholder='1'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Units'
              {...form.getInputProps('units')}
              editable={isEditing}
              placeholder='UN'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Diagnosis pointers'
              {...form.getInputProps('diagnosis_pointers')}
              editable={isEditing}
              placeholder='0'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData label='Place of service code' data={placeOfServiceCode} />
          </Grid.Col>
        </Grid>
        {isEditing ? (
          <EditableSectionFooter
            onSave={form.onSubmit(() =>
              onSave({
                ...form.values,
                diagnosis_pointers:
                  form.values.diagnosis_pointers === ''
                    ? []
                    : [Number(form.values.diagnosis_pointers)],
                charge_amount_cents: Number(form.values.charge_amount_cents),
              }),
            )}
            onCancel={() => onCancel()}
            isSaving={false}
          />
        ) : (
          <Group>
            {showEditButton ? (
              <TertiaryButton
                rightIcon={<EditIcon color={colors => colors.actions[1]} />}
                onClick={() => {
                  if (editOnClick && serviceLine) {
                    editOnClick(serviceLine)
                  }
                }}
              >
                Edit
              </TertiaryButton>
            ) : null}
            <TertiaryButton
              rightIcon={<TrashIcon color={colors => colors.actions[1]} />}
              onClick={() => {
                if (removeOnClick && serviceLine) {
                  removeOnClick(serviceLine)
                }
              }}
            >
              Remove
            </TertiaryButton>
          </Group>
        )}
      </Stack>
    </Box>
  )
}
