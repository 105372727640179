import { Alert, Banner, Select, ShieldIcon, Skeleton } from '@shared/components'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

export const EligibilitySelect = ({ onChange }: { onChange?(value: string | null): void }) => {
  const { patientId } = usePatient()

  const [eligibilitiesQueryKey, eligibilitiesQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/dosespotEligibilities',
    { params: { patientId } },
  )

  const eligibilitiesQuery = useQuery(eligibilitiesQueryKey, eligibilitiesQueryFunction)

  if (eligibilitiesQuery.isLoading) {
    return <Skeleton w='400px' />
  }

  if (eligibilitiesQuery.isError) {
    return <Banner w='400px' type='error' label='Error fetching patient eligbilities' />
  }

  if (!eligibilitiesQuery.data || eligibilitiesQuery.data?.Items.length === 0) {
    return (
      <Alert w='400px' mt='30px' h='18px' icon={<ShieldIcon />} variant='warning'>
        Patient has no active coverage
      </Alert>
    )
  }

  const eligbilities = eligibilitiesQuery.data.Items

  const eligbilitiesData = eligbilities.map(e => ({
    label: `${e.PayerName}${e.PlanName ? ` - ${e.PlanName}` : ''}`,
    value: String(e.PatientEligibilityId),
  }))

  return <Select w='400px' data={eligbilitiesData} onChange={onChange} label='Patient insurance' />
}
