import React from 'react'

export type ANoteSectionContentProps = {
  children: React.ReactNode
  className?: string
}

const ANoteSectionContent = ({ children, className }: ANoteSectionContentProps) => (
  <div className={`${className || ''} mt-2`}>{children}</div>
)

export default ANoteSectionContent
