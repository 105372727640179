import { useToggle } from '@mantine/hooks'
import {
  Box,
  Container,
  Drawer,
  Grid,
  Group,
  Pill,
  Stack,
  TertiaryButton,
  Text,
  TitleFour,
} from '@shared/components'
import { EligibleInsuranceData, PrimaryInsurancePlanType } from '@shared/types'
import { dayjs, jsonFormat } from '@shared/utils'
import { LabeledData } from '../components/LabeledData'

export type BenefitsSectionProps = {
  eligibleData?: EligibleInsuranceData
}

const getTermDatesString = (dates?: { date_value: string; date_type: string }[]) => {
  if (!dates) {
    return undefined
  }
  const startDate = dates.find(date => date.date_type === 'plan_begin')
  const endDate = dates.find(date => date.date_type === 'plan_end')
  const startDateString = startDate ? dayjs(startDate.date_value).format('MM/DD/YYYY') : 'Unknown'
  const endDateString = endDate ? dayjs(endDate.date_value).format('MM/DD/YYYY') : 'Unknown'
  return `${startDateString} - ${endDateString}`
}
export const BenefitsSection = ({ eligibleData }: BenefitsSectionProps) => {
  const [showEligibleDataDrawer, toggleEligibleDataDrawer] = useToggle()
  return (
    <>
      <Drawer
        opened={showEligibleDataDrawer}
        onClose={() => {
          toggleEligibleDataDrawer(false)
        }}
        title='Eligible data'
        size='lg'
        position='right'
      >
        <Container p='md' pb='lg'>
          <Text style={{ whiteSpace: 'pre-wrap' }}>{jsonFormat(eligibleData)}</Text>
        </Container>
      </Drawer>
      <Stack>
        <Box
          sx={theme => ({
            borderBottomColor: theme.other.colors.background[3],
            borderBottomWidth: theme.other.sizes.border.md,
            paddingBottom: theme.other.sizes.padding.md,
          })}
        >
          <Group position='apart'>
            <Group>
              <TitleFour>Eligible data</TitleFour>
              <Pill status='none' variant='filled'>
                Not sent to Candid
              </Pill>
            </Group>
            {eligibleData ? (
              <TertiaryButton
                onClick={() => {
                  toggleEligibleDataDrawer(true)
                }}
              >
                View Eligible data
              </TertiaryButton>
            ) : null}
          </Group>
        </Box>
        <Grid columns={12}>
          <Grid.Col span={4}>
            <LabeledData
              label='Benefits last verified'
              data={
                eligibleData?.created_at
                  ? dayjs(eligibleData?.created_at).format('MM/DD/YYYY')
                  : undefined
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData label='Coverage status' data={eligibleData?.plan?.coverage_status_label} />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData label='Term dates' data={getTermDatesString(eligibleData?.plan?.dates)} />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData label='Payer ID' data={eligibleData?.insurance?.id} />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData label='Plan name' data={eligibleData?.plan?.plan_name} />
          </Grid.Col>
          <Grid.Col span={4}>
            <LabeledData
              label='Plan type'
              data={
                PrimaryInsurancePlanType[
                  eligibleData?.plan?.plan_type as keyof typeof PrimaryInsurancePlanType
                ]
              }
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  )
}
