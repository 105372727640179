import { EMRTaskPod } from '@shared/types'
import { useFlags } from './use-flags'

export type PodFilterValue = EMRTaskPod | '' | 'assigned_to_me' | 'opened_by_me' | 'communications'

export const usePodFilterOptions = () => {
  const { communicationsPodFilter } = useFlags()

  const POD_FILTER_OPTIONS: { value: PodFilterValue; label: string }[] = [
    // An empty string is used to represent the 'View all' option
    { value: '', label: 'View all' },
    { value: 'assigned_to_me', label: 'View assigned to me' },
    { value: 'opened_by_me', label: 'View opened by me' },

    { value: 'pes', label: 'View PES' },
    { value: 'pss', label: 'View PSS' },
    { value: 'ec', label: 'View EC' },
    { value: 'lec', label: 'View lead EC' },
    { value: 'fc', label: 'View FC' },
    { value: 'lcc', label: 'View LCC' },
    { value: 'scc', label: 'View SCC' },
    { value: 'tn', label: 'View triage nurses' },
    { value: 'rn', label: 'View resource navigation' },
    { value: 'legal', label: 'View legal' },
    { value: 'engineer', label: 'View engineering' },
    { value: 'product', label: 'View product' },
    { value: 'ocp', label: 'View OCP' },
  ]

  if (communicationsPodFilter) {
    POD_FILTER_OPTIONS.push({ value: 'communications', label: 'View communications' })
  }

  return POD_FILTER_OPTIONS
}
