import { useScrollIntoView } from '@mantine/hooks'
import { Stack, Text, useMantineTheme } from '@shared/components'
import { Patient } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import { useEffect, useRef, useState } from 'react'
import { useLunaQuery } from '../../../../../utils/hooks'
import { CommunicationsDatePill } from '../CommunicationsDatePill'
import { CommunicationsLog } from '../CommunicationsLog'
import { CommunicationsMessage } from './CommunicationsMessage'
import { OutboundSmsBlock } from './OuboundSmsBlock'

type MessageLogProps = {
  patient: Patient
}

export const MessageLog = ({ patient }: MessageLogProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const [hasInitialData, setHasInitialData] = useState(false)
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>({
    cancelable: false,
  })

  // By default, fetch messages from the past month
  const startDatetimeRef = useRef(dayjs().subtract(1, 'month').startOf('day').toISOString())

  const communicationsQuery = useLunaQuery(
    'GET /communications/sms',
    {
      query: {
        patientId: patient?.oid,
        startDatetime: startDatetimeRef.current,
      },
    },
    {
      enabled: Boolean(patient.oid),
      refetchInterval: toTime('3 sec').ms(),
      refetchIntervalInBackground: false,
    },
  )

  const onRefresh = async () => {
    await refetch()
    scrollIntoView()
  }

  const { data, isLoading, isFetching, refetch, dataUpdatedAt } = communicationsQuery
  const messages = data?.data?.messages
  const lastLoadedTimestamp = dayjs(dataUpdatedAt).toISOString()

  useEffect(() => {
    if (!hasInitialData && data) {
      scrollIntoView()
      setHasInitialData(true)
    }
  }, [hasInitialData, data, scrollIntoView])

  let lastDate: string | null | undefined = undefined

  return (
    <CommunicationsLog
      patient={patient}
      isLoading={isLoading}
      scrollableRef={scrollableRef}
      targetRef={targetRef}
      footer={
        <OutboundSmsBlock
          isFetching={isFetching}
          patientId={patient.oid}
          onRefresh={onRefresh}
          lastLoadedTimestamp={lastLoadedTimestamp}
        />
      }
    >
      <>
        {messages?.length && messages?.length > 0 ? (
          // Filter for messages that contain either message body or media
          messages
            .filter(message => message?.body !== '' || (message?.media?.length ?? 0) > 0)
            .map(message => {
              // Show date pill for all unique dates
              const isNewDate =
                !lastDate || dayjs(lastDate).date() !== dayjs(message.datetime).date()
              lastDate = message.datetime

              return (
                <Stack spacing='md' key={message.externalId}>
                  {isNewDate && message.datetime && (
                    <CommunicationsDatePill datetime={message.datetime} />
                  )}
                  <CommunicationsMessage
                    key={message.externalId}
                    externalId={message.externalId}
                    direction={message.direction}
                    datetime={message.datetime}
                    body={message.body}
                    status={message.status}
                    attachments={message?.media}
                  />
                </Stack>
              )
            })
        ) : (
          <Text color={colors.text[1]}>No messages found from the past month.</Text>
        )}
      </>
    </CommunicationsLog>
  )
}
