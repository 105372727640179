import { useFocusTrap } from '@mantine/hooks'
import {
  Banner,
  Drawer,
  Group,
  MinusCircleIcon,
  PlusCircleIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  UnstyledButton,
} from '@shared/components'
import { useFiltersFormContext } from './filterFormHelpers'
import { IssueTypeFilter } from './IssueTypeFilter'
import { MAX_TYPES_TO_INCLUDE } from './PTasks'

export const AccordionHeader = ({
  isSelected,
  onClick,
  title,
}: {
  isSelected: boolean
  onClick: () => void
  title: string
}) => {
  return (
    <UnstyledButton onClick={onClick}>
      <Group spacing='sm'>
        {isSelected ? <MinusCircleIcon styled /> : <PlusCircleIcon styled />}
        <Text bold>{title}</Text>
      </Group>
    </UnstyledButton>
  )
}

type FilterTaskDrawerProps = {
  opened: boolean
  onClose: () => void
}

export const FilterTaskDrawer = ({ opened, onClose }: FilterTaskDrawerProps) => {
  const filters = useFiltersFormContext()
  // useFocusTrap allows us to remove the focus from the `Issue type` accordion header
  const focusTrapRef = useFocusTrap()

  const { types } = filters.values

  const displayErrorBanner = types && types.length >= MAX_TYPES_TO_INCLUDE

  const handleClearingFilters = () => {
    filters.setValues({
      types: undefined,
    })

    onClose()
  }

  return (
    <Drawer
      title='Filters'
      opened={opened}
      onClose={onClose}
      position='right'
      size='lg'
      footer={
        <Group position='right' ref={focusTrapRef}>
          <SecondaryButton onClick={() => handleClearingFilters()}>
            Clear all filters
          </SecondaryButton>
          <PrimaryButton onClick={() => onClose()} data-autofocus>
            Done
          </PrimaryButton>
        </Group>
      }
      // The action item icon was displaying on top of the drawer
      style={{ zIndex: 999 }}
    >
      <Stack p='md'>
        {displayErrorBanner && (
          <Banner
            type='warning'
            label={`You can only filter by up to ${MAX_TYPES_TO_INCLUDE} issue types`}
          />
        )}
        <IssueTypeFilter />
      </Stack>
    </Drawer>
  )
}
