import React, { useRef, useState } from 'react'
import ATypeaheadOption from './ATypeaheadOption'

const ATypeahead = ({
  values,
  name,
  selectedValue,
  setSelectedValue,
  defaultValue,
  disabled,
  register,
  className,
}) => {
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [ignoreBlur, setIgnoreBlur] = useState(false)

  const inputRef = useRef(register())

  const onChange = event => {
    if (!event.target.value) {
      setResults([])
      setShowResults(false)
    }
    if (event.target.value) {
      let filteredResults = values.filter(value =>
        value.toLowerCase().includes(event.target.value.toLowerCase()),
      )
      filteredResults = filteredResults.sort((a, b) => {
        return a.length - b.length
      })
      /* eslint-disable-next-line no-magic-numbers */
      setResults(filteredResults.slice(0, 9))
      if (filteredResults.length > 0) {
        setShowResults(true)
      } else {
        setShowResults(false)
      }
    }
  }

  const onValueClick = ({ value }) => {
    inputRef.current.value = value
    setSelectedValue(value)
    setShowResults(false)
  }

  const onBlur = () => {
    if (ignoreBlur) {
      return
    }
    inputRef.current.value = selectedValue
    setShowResults(false)
  }

  return (
    <div className={`${className ?? ''} flex flex-col`}>
      <input
        name={name}
        type='text'
        ref={inputRef}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        className={`block w-full shadow-sm focus:ring-daybreak-actions1 focus:border-daybreak-actions1 sm:text-sm border-daybreak-actions0 focus:ring-2 focus:ring-offset-2 rounded-md border shadow-sm focus:shadow-focus ${
          disabled ? 'opacity-40 cursor-not-allowed' : ''
        }`}
        disabled={disabled}
        autoComplete='off'
      />
      {showResults && (
        <div className='relative'>
          <div className='z-40 absolute top-0 bg-white overflow-y-scroll max-h-40 w-full shadow rounded-b'>
            <ul>
              {results.map(value => (
                <ATypeaheadOption
                  key={value}
                  value={value}
                  setIgnoreBlur={setIgnoreBlur}
                  onValueClick={onValueClick}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default ATypeahead
