import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  Group,
  isAllSelected,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  Textarea,
} from '@shared/components'
import { Attestation, ClosedNoteDetails } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'

type OAddAttestationModalProps = {
  closeModal: () => void
  closedNoteDetails: ClosedNoteDetails | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: Record<string, any> | null
  patientID: string
  visitID: string
  setClosedNoteDetails: (closedNoteDetails: ClosedNoteDetails) => void
}

const OAddAttestationModal = ({
  closeModal,
  closedNoteDetails,
  notes,
  patientID,
  visitID,
  setClosedNoteDetails,
}: OAddAttestationModalProps) => {
  const { currentUser } = useAuth()

  const ATTESTATION_CONFIRMED = 'attestation_confirmed'

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const form = useForm<{ attestation_notes?: string; attested: string[] }>({
    validate: { attested: isAllSelected([ATTESTATION_CONFIRMED], 'Required') },
  })

  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    const timezone = notes?.timezone ? notes.timezone : 'America/New_York'
    const attestation: Attestation = {
      attested_by_user_id: currentUser.oid,
      attested_by_display_name: currentUser.name,
      attested_at: dayjs().tz(timezone).format('MMMM Do YYYY, h:mm a'),
    }
    if (form.values.attestation_notes) {
      attestation.attestation_notes = form.values.attestation_notes
    }
    const body = {
      type: 'maintenance' as const,
      content: { attestation },
    }
    await updateVisitNote.mutateAsync(
      {
        params: {
          patientId: patientID,
          visitId: visitID,
        },
        data: body,
      },
      {
        onSuccess: () => {
          if (closedNoteDetails) {
            closedNoteDetails.attestation = attestation
            setClosedNoteDetails(closedNoteDetails)
          }
          closeModal()
        },
      },
    )
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Attest note'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>No, go back</SecondaryButton>
          <PrimaryButton loading={updateVisitNote.isLoading} onClick={onSubmit}>
            Yes, attest note
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <CheckboxGroup {...form.getInputProps('attested')}>
          <Checkbox
            label={`I have reviewed ${closedNoteDetails?.locked_by_name}'s note and agree with the assessment and plan, unless noted below`}
            value={ATTESTATION_CONFIRMED}
          />
        </CheckboxGroup>
        <Textarea
          {...form.getInputProps('attestation_notes')}
          minRows={4}
          label='Additional comments (optional)'
          placeholder='Type here...'
          autosize
        />
        <Text size='xs' color={colors => colors.text[1]}>
          Are you sure you want to proceed?
        </Text>
      </Stack>
    </Modal>
  )
}

export default OAddAttestationModal
