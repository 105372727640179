import {
  Box,
  Card,
  Divider,
  EditIcon,
  Group,
  Pill,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Insurance } from '@shared/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { InsuranceSidebar } from '../InsuranceSidebar'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

const VerificationPill = ({ insurance }: { insurance: Insurance | undefined }) => {
  const eligibleVerified = insurance?.eligibleData
  const manuallyVerified = insurance?.manuallyVerifiedData

  if (eligibleVerified) {
    return (
      <Pill variant='filled' status='success'>
        Eligible verified
      </Pill>
    )
  }
  if (manuallyVerified) {
    return (
      <Pill variant='filled' status='success'>
        Manually verified
      </Pill>
    )
  }
  return (
    <Pill variant='filled' status='warning'>
      Not verified
    </Pill>
  )
}

export const PrimaryInsuranceContent = ({ active }: { active: boolean }) => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data
  const [edit, setEdit] = useState(false)

  const primaryInsuranceQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurance/:insuranceId', {
      params: {
        patientId,
        insuranceId: patientQuery?.data?.insuranceData?.primaryInsuranceId ?? '',
      },
    }),
    {
      enabled: Boolean(
        active &&
          patientQuery?.data?.insuranceData?.hasInsurance &&
          patientQuery?.data?.insuranceData?.primaryInsuranceId,
      ),
    },
  )

  const onSubmit = () => {
    void primaryInsuranceQuery?.refetch()
    void patientQuery?.refetch()
  }

  if (!patient?.insuranceData?.hasInsurance) {
    return (
      <SidebarEmptyState
        pillMessage='No insurance'
        textMessage={`${
          patient?.personalData.preferredName || patient?.personalData.firstName
        }'s insurance will appear here`}
        content={
          <Stack w='100%' spacing='sm'>
            <Divider color={colors => colors.background[2]} />
            <InsuranceSidebar onSubmit={onSubmit} />
          </Stack>
        }
      />
    )
  }

  if (primaryInsuranceQuery.isLoading || primaryInsuranceQuery.isError) {
    return <Skeleton h='5rem' />
  }

  const primaryInsurance = primaryInsuranceQuery?.data

  return (
    <Stack>
      <Group position='apart' spacing='sm'>
        <Text size='xs' color={colors => colors.text[1]}>
          Primary insurance
        </Text>
        <TertiaryButton
          sx={{ visibility: edit ? 'hidden' : 'visible' }}
          onClick={() => setEdit(true)}
          leftIcon={<EditIcon styled />}
        >
          Edit
        </TertiaryButton>
      </Group>
      <Card>
        {edit ? (
          <InsuranceSidebar onSubmit={onSubmit} onClose={() => setEdit(false)} />
        ) : (
          <Group spacing='sm' position='apart'>
            <Text size='xs' bold>
              {primaryInsurance?.basicInsuranceData?.provider || 'None selected'}
            </Text>
            <Group spacing='sm' noWrap>
              <VerificationPill insurance={primaryInsurance} />
              {primaryInsurance?.basicInsuranceData?.planType === 'MC' && (
                <Pill variant='filled' status='success'>
                  Medicaid
                </Pill>
              )}
            </Group>
          </Group>
        )}
      </Card>
    </Stack>
  )
}

export const SecondaryInsuranceContent = ({ active }: { active: boolean }) => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const secondaryInsuranceQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurance/:insuranceId', {
      params: {
        patientId,
        insuranceId: patientQuery?.data?.insuranceData?.secondaryInsuranceId ?? '',
      },
    }),
    {
      enabled: Boolean(
        patientQuery?.data?.insuranceData?.hasSecondaryInsurance &&
          patientQuery?.data?.insuranceData?.secondaryInsuranceId &&
          active,
      ),
    },
  )

  if (!patient?.insuranceData?.hasSecondaryInsurance) {
    return null
  }

  if (secondaryInsuranceQuery.isLoading || secondaryInsuranceQuery.isError) {
    return (
      <Skeleton
        h='5rem'
        // We want to ignore the query if no secondary insurance
      />
    )
  }

  const secondaryInsurance = secondaryInsuranceQuery?.data

  return (
    <Stack>
      <Text size='xs' color={colors => colors.text[1]}>
        Secondary insurance
      </Text>
      <Box
        p='sm'
        sx={({ other: { colors }, shadows, radius }) => ({
          backgroundColor: colors.background[0],
          borderRadius: radius.sm,
          boxShadow: shadows.xs,
        })}
      >
        <Group noWrap position='apart'>
          <Text size='xs' bold>
            {secondaryInsurance?.basicInsuranceData?.provider || 'Non selected'}
          </Text>
          <VerificationPill insurance={secondaryInsurance} />
        </Group>
      </Box>
    </Stack>
  )
}

export const BillingNotesContent = () => {
  const { patientQuery } = usePatient()
  const billingTeamNotes = patientQuery?.data?.insuranceData?.freeformData

  if (patientQuery?.isLoading || patientQuery?.isError) {
    return <Skeleton h='5rem' />
  }

  if (!billingTeamNotes) {
    return null
  }

  return (
    <Stack>
      <Text size='xs' color={colors => colors.text[1]}>
        Billing team notes
      </Text>
      <Card>
        <Text>{billingTeamNotes}</Text>
      </Card>
    </Stack>
  )
}
