import { List, ListItem, Stack, Text } from '@shared/components'
import { DischargeNoteModel, dischargeNoteMapping } from '@shared/types'

export const BuprenorphineMedicationPlanSection = ({
  dischargeNote,
}: {
  dischargeNote: DischargeNoteModel
}) => {
  const buprenorphineMedicationPlans: (keyof DischargeNoteModel)[] = [
    'thirty_day_prescription_current_maintenance_dose',
    'thirty_day_prescription_taper',
    'naloxone_prescribed',
  ]

  const selectedPlans = buprenorphineMedicationPlans.filter(plan => {
    return Boolean(dischargeNote[plan])
  })

  return selectedPlans.length ? (
    <Stack spacing='xs'>
      <Text bold>Buprenorphine medication plan</Text>
      <List listStyleType='disc'>
        {selectedPlans.map(plan => (
          <ListItem key={plan}>{dischargeNoteMapping[plan]}</ListItem>
        ))}
        {dischargeNote.medication_plan_other && (
          <ListItem key='medication_plan_other'>
            {dischargeNote.medication_plan_explanation
              ? `Other: ${dischargeNote.medication_plan_explanation}`
              : 'Other'}
          </ListItem>
        )}
      </List>
    </Stack>
  ) : null
}
