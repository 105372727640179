import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  ArrowRightIcon,
  Banner,
  Box,
  Checkbox,
  Divider,
  Grid,
  Group,
  Modal,
  Pill,
  PlusCircleIcon,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleFour,
  TrashIcon,
  UnstyledButton,
  isAnySelected,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import {
  DeepPartialObject,
  FSInsuranceData,
  FileCategory,
  InsuranceData,
  InsurancePlanId,
  InsuranceType,
  PrimaryInsurancePlanType,
  getOpheliaHttpError,
} from '@shared/types'
import { getInsurancePlanId, getPayerIdAndProvider } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../../api'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'
import { InsuranceSelect } from '../../../components/forms/InsuranceSelect'
import { Photo } from '../../../components/forms/PreviewPhoto'
import { UploadPhoto } from '../../../components/forms/UploadPhoto'
import { getPlaceholderText } from '../../../utils/formUtils'
import { isDate, isRequired } from '../../../utils/formValidation'
import { useLunaQuery } from '../../../utils/hooks'
import { InsuranceVerificationDrawer } from '../../care_team/irq/InsuranceVerificationDrawer'
import { getDateLastVerified } from '../../care_team/irq/utils'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'
import { NoInsuranceSection } from './NoInsuranceSection'

type InsuranceDataForm = {
  firstName: string
  lastName: string
  cardholderBirthday: string
  provider: InsurancePlanId
  memberId: string
  groupId: string
  rxbin: string
  rxPcn: string
  rxGroup: string
  planName: string
  planType?: keyof typeof PrimaryInsurancePlanType | null
  patientIsPrimarySubscriber: 'yes' | 'no'
  primarySubscriberBirthday: string
  primarySubscriberFirstName: string
  primarySubscriberLastName: string
  insuranceCardFrontId: string
  insuranceCardBackId: string
  medicaidInfo: {
    memberId: string
  }
  excludeFromBundle: boolean
}

const convertBasicInsuranceDataToFormData = (basicInsuranceData: InsuranceData) => {
  const {
    firstName,
    lastName,
    cardholderBirthday,
    provider,
    eligiblePayerId,
    memberId,
    rxGroup,
    rxbin,
    rxPcn,
    groupId,
    planType,
    planName,
    patientIsPrimarySubscriber,
    primarySubscriberFirstName,
    primarySubscriberLastName,
    primarySubscriberBirthday,
    medicaidInfo,
    excludeFromBundle,
  } = basicInsuranceData
  const patientIsPrimarySubString = patientIsPrimarySubscriber ? 'yes' : 'no'
  const formData: Omit<InsuranceDataForm, 'insuranceCardFrontId' | 'insuranceCardBackId'> = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    cardholderBirthday: cardholderBirthday ?? '',
    provider: getInsurancePlanId(eligiblePayerId, provider),
    memberId: memberId ?? '',
    medicaidInfo: {
      memberId: medicaidInfo?.memberId ?? '',
    },
    rxGroup: rxGroup ?? '',
    rxPcn: rxPcn ?? '',
    groupId: groupId ?? '',
    rxbin: rxbin ?? '',
    planType,
    planName: planName ?? '',
    patientIsPrimarySubscriber: patientIsPrimarySubString,
    primarySubscriberFirstName: primarySubscriberFirstName ?? '',
    primarySubscriberLastName: primarySubscriberLastName ?? '',
    primarySubscriberBirthday: primarySubscriberBirthday ?? '',
    excludeFromBundle: excludeFromBundle ?? false,
  }
  return formData
}
const getExpandedPhotoTitle = (category: FileCategory | undefined) => {
  if (category === 'INSURANCE_FRONT' || category === 'SECONDARY_INSURANCE_FRONT') {
    return 'Front of insurance card'
  }
  if (category === 'INSURANCE_BACK' || category === 'SECONDARY_INSURANCE_BACK') {
    return 'Back of insurance card'
  }
  return 'Image'
}

export type InsuranceSubSectionProps = {
  insuranceId?: string
  insuranceType: InsuranceType
  isInsuranceActive: boolean
  /*
   * We use this UI to display the patient's historical insurances in addition to their existing primary
   * and secondary insurances. The behavior differs slightly for historical insurances (ie. we won't
   * ask FCs to verify insurance details), so we're adding this flag
   */
  isPastInsurance?: boolean
}

export const InsuranceSubSection = ({
  insuranceId,
  insuranceType,
  isInsuranceActive,
  isPastInsurance = false,
}: InsuranceSubSectionProps) => {
  const { patientId, patientQuery } = usePatient()
  const [isEditing, toggleEditing] = useToggle([false, true])
  const [fileToExpand, setFileToExpand] = useState<{ category?: FileCategory; oid: string } | null>(
    null,
  )

  const [isVerificationDrawerOpened, setIsVerificationDrawerOpened] = useState(false)

  const [isRemoveInsuranceModalOpened, setIsRemoveInsuranceModalOpened] = useState(false)

  const form = useForm<InsuranceDataForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      cardholderBirthday: '',
      provider: '',
      memberId: '',
      groupId: '',
      rxbin: '',
      rxPcn: '',
      rxGroup: '',
      planName: '',
      planType: null,
      patientIsPrimarySubscriber: 'no',
      primarySubscriberBirthday: '',
      primarySubscriberFirstName: '',
      primarySubscriberLastName: '',
      insuranceCardFrontId: '',
      insuranceCardBackId: '',
      medicaidInfo: {
        memberId: '',
      },
      excludeFromBundle: false,
    },
    validate: {
      firstName: isRequired,
      lastName: isRequired,
      cardholderBirthday: validateWith(isRequired, isDate),
      provider: isRequired,
      memberId: isRequired,
      planType: isRequired,
      patientIsPrimarySubscriber: isAnySelected(['yes', 'no'], 'Required'),
      primarySubscriberBirthday: validateWith(
        skipIfOtherField('patientIsPrimarySubscriber', 'is', 'yes'),
        isRequired,
        isDate,
      ),
      primarySubscriberFirstName: validateWith(
        skipIfOtherField('patientIsPrimarySubscriber', 'is', 'yes'),
        isRequired,
      ),
      primarySubscriberLastName: validateWith(
        skipIfOtherField('patientIsPrimarySubscriber', 'is', 'yes'),
        isRequired,
      ),
    },
  })

  const { provider: providerName, eligiblePayerId } = getPayerIdAndProvider(form.values?.provider)

  /*
   * If we're displaying a historical insurance, we'll set the insuranceString to the name of the insurance plan.
   * Otherwise, we'll display `Primary insurance` or `Secondary insurance`
   */
  const currentInsuranceString =
    insuranceType === 'primary' ? 'Primary insurance' : 'Secondary insurance'
  const pastInsuranceString = providerName
  const insuranceString = isPastInsurance ? pastInsuranceString : currentInsuranceString

  const payerDetailsQuery = useLunaQuery(
    'GET /insurance-payers/:id',
    {
      params: {
        id: eligiblePayerId,
      },
      query: {
        planType: form.values?.planType as string | undefined,
      },
    },
    {
      enabled: Boolean(eligiblePayerId),
    },
  )

  const isPatientOnBundlePlan = payerDetailsQuery.data?.data?.isBundlePlan

  const [insuranceQueryKey, insuranceQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/insurance/:insuranceId',
    {
      params: {
        patientId,
        insuranceId: insuranceId ?? '',
      },
    },
  )

  const insuranceQuery = useQuery(insuranceQueryKey, insuranceQueryFunction, {
    enabled: Boolean(insuranceId),
  })

  const insuranceCardFrontQuery = useLunaQuery(
    'GET /patients/:patientId/files/:fileId',
    {
      params: {
        patientId,
        fileId: insuranceQuery?.data?.basicInsuranceData?.insuranceCardFrontId || '',
      },
    },
    {
      enabled: Boolean(insuranceQuery?.data?.basicInsuranceData?.insuranceCardFrontId),
      onSuccess: ({ data }) => {
        form.setValues({ insuranceCardFrontId: data?.oid || '' })
      },
    },
  )

  const insuranceCardBackQuery = useLunaQuery(
    'GET /patients/:patientId/files/:fileId',
    {
      params: {
        patientId,
        fileId: insuranceQuery?.data?.basicInsuranceData?.insuranceCardBackId || '',
      },
    },
    {
      enabled: Boolean(insuranceQuery?.data?.basicInsuranceData?.insuranceCardBackId),
      onSuccess: ({ data }) => {
        form.setValues({ insuranceCardBackId: data?.oid || '' })
      },
    },
  )

  useEffect(() => {
    if (insuranceQuery?.data?.basicInsuranceData) {
      const formData = convertBasicInsuranceDataToFormData(insuranceQuery.data.basicInsuranceData)
      form.setValues(formData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceQuery.data?.basicInsuranceData])

  const insuranceCardFront = insuranceCardFrontQuery.data?.data

  const insuranceCardBack = insuranceCardBackQuery.data?.data

  const submitInsuranceDataMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/insurance/update'),
    {
      onSuccess: () => {
        toggleEditing(false)
        void insuranceCardFrontQuery.refetch()
        void insuranceCardBackQuery.refetch()
        void insuranceQuery?.refetch()
      },
    },
  )

  const onSave = () => {
    if (form.validate().hasErrors) {
      return
    }

    const { patientIsPrimarySubscriber, provider, ...values } = form.values
    const { eligiblePayerId, provider: providerName } = getPayerIdAndProvider(provider)

    if (patientIsPrimarySubscriber === 'yes') {
      values.primarySubscriberFirstName = values.firstName
      values.primarySubscriberLastName = values.lastName
      values.primarySubscriberBirthday = values.cardholderBirthday
    }
    submitInsuranceDataMutation.mutate({
      params: { patientId },
      data: {
        ...values,
        patientIsPrimarySubscriber: patientIsPrimarySubscriber === 'yes',
        provider: providerName,
        // Set to false if patient is not on a bundle plan
        excludeFromBundle: isPatientOnBundlePlan ? values.excludeFromBundle : false,
        medicaidInfo: {
          memberId: values.medicaidInfo.memberId,
        },
        eligiblePayerId,
        insuranceType,
        insuranceId,
      },
    })
  }

  const formDataFromDb: Partial<InsuranceDataForm> = useMemo(() => {
    let formData: Partial<InsuranceDataForm> = {}
    if (insuranceQuery.data?.basicInsuranceData) {
      formData = convertBasicInsuranceDataToFormData(insuranceQuery.data.basicInsuranceData)
    }
    if (insuranceCardFront) {
      formData.insuranceCardFrontId = insuranceCardFront.oid
    }
    if (insuranceCardBack) {
      formData.insuranceCardBackId = insuranceCardBack.oid
    }
    return formData
  }, [insuranceCardFront, insuranceCardBack, insuranceQuery.data?.basicInsuranceData])

  const onCancel = () => {
    form.setValues(formDataFromDb)
    toggleEditing()
  }

  const updatePatientMutation = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const patientHasInsurance = isInsuranceActive

  const insuranceVerifiedDate = getDateLastVerified({
    eligibleInsuranceData: insuranceQuery.data?.eligibleData,
    manualInsuranceData: insuranceQuery.data?.manuallyVerifiedData,
  })

  const getVerifiedBannerProps = () => {
    if (insuranceVerifiedDate) {
      return {
        label: `Insurance verified on ${insuranceVerifiedDate?.format('MM/DD/YYYY')}`,
        type: 'success' as const,
      }
    }
    return { label: 'Insurance not verified', type: 'warning' as const }
  }

  const onAddInsurance = () => {
    let insuranceData: DeepPartialObject<FSInsuranceData>
    if (insuranceType === 'primary') {
      insuranceData = { hasInsurance: true }
    } else {
      insuranceData = { hasSecondaryInsurance: true }
    }
    updatePatientMutation.mutate(
      {
        params: { patientId },
        data: { insuranceData },
      },
      {
        onSuccess: async () => {
          await patientQuery?.refetch()
          toggleEditing(true)
        },
      },
    )
  }
  const removeInsurance = () => {
    let insuranceData: DeepPartialObject<FSInsuranceData>
    if (insuranceType === 'primary') {
      insuranceData = {
        hasInsurance: false,
        primaryInsuranceId: '',
      }
    } else {
      insuranceData = {
        hasSecondaryInsurance: false,
        secondaryInsuranceId: '',
      }
    }

    updatePatientMutation.mutate(
      {
        params: { patientId },
        data: { insuranceData },
      },
      {
        onSuccess: () => {
          void patientQuery?.refetch()

          setIsRemoveInsuranceModalOpened(false)
        },
      },
    )
  }

  const onRemoveInsurance = () => {
    setIsRemoveInsuranceModalOpened(true)
  }

  return (
    <>
      <Modal
        opened={Boolean(fileToExpand)}
        onClose={() => {
          setFileToExpand(null)
        }}
        size='64rem'
        title={getExpandedPhotoTitle(fileToExpand?.category)}
      >
        <Photo
          label={getExpandedPhotoTitle(fileToExpand?.category)}
          value={fileToExpand?.oid}
          patientId={patientId}
          ratio={1}
        />
      </Modal>
      <Modal
        title={`Remove ${insuranceString}`}
        opened={isRemoveInsuranceModalOpened}
        onClose={() => setIsRemoveInsuranceModalOpened(false)}
        footer={
          <Group position='right'>
            <SecondaryButton onClick={() => setIsRemoveInsuranceModalOpened(false)}>
              {`No, don't remove`}
            </SecondaryButton>
            <PrimaryButton loading={updatePatientMutation.isLoading} onClick={removeInsurance}>
              Yes, remove
            </PrimaryButton>
          </Group>
        }
      >
        <Stack>
          <Text>
            Remove insurance if {patientQuery?.data?.personalData.firstName} no longer has insurance
            or is not interested in using insurance.
          </Text>
          <Text>Are you sure you want to proceed?</Text>
        </Stack>
      </Modal>

      {insuranceQuery?.data && (
        <InsuranceVerificationDrawer
          opened={isVerificationDrawerOpened}
          patientId={patientId}
          insuranceType={insuranceType}
          insuranceData={insuranceQuery?.data?.basicInsuranceData}
          manualInsuranceData={insuranceQuery?.data?.manuallyVerifiedData}
          frontFileId={insuranceCardFront?.oid}
          backFileId={insuranceCardBack?.oid}
          onClose={() => setIsVerificationDrawerOpened(false)}
          onSave={() => {
            setIsVerificationDrawerOpened(false)
            void insuranceQuery.refetch()
            void patientQuery?.refetch()
          }}
        />
      )}
      <Stack>
        {patientHasInsurance || isPastInsurance || insuranceQuery.isLoading ? (
          <EditableSection
            title={<TitleFour>{insuranceString}</TitleFour>}
            onEdit={toggleEditing}
            onSave={onSave}
            onCancel={onCancel}
            isEditing={isEditing}
            isSaving={submitInsuranceDataMutation.isLoading}
            isLoading={
              insuranceQuery.isLoading ||
              insuranceCardFrontQuery.isLoading ||
              insuranceCardBackQuery.isLoading ||
              payerDetailsQuery.isLoading
            }
            moreActions={
              <TertiaryButton onClick={onRemoveInsurance} rightIcon={<TrashIcon />}>
                Remove
              </TertiaryButton>
            }
            error={getOpheliaHttpError(
              submitInsuranceDataMutation.error,
              `Error updating patient's insurance information`,
            )}
          >
            <Stack mt='md'>
              {/* We do not need to verify historical insurances */}
              {!isPastInsurance && (
                <Banner
                  {...getVerifiedBannerProps()}
                  rightIcon={
                    <TertiaryButton
                      onClick={() => {
                        setIsVerificationDrawerOpened(true)
                      }}
                      rightIcon={<ArrowRightIcon />}
                    >
                      Verify
                    </TertiaryButton>
                  }
                  variant='action'
                />
              )}
              <Grid columns={12}>
                <Grid.Col span={12}>
                  {isEditing ? (
                    <InsuranceSelect
                      patientId={patientId}
                      {...form.getInputProps('provider')}
                      placeholder={getPlaceholderText('provider')}
                      creatable
                    />
                  ) : (
                    <Box>
                      <Text bold>Insurance</Text>
                      {providerName ? (
                        <Group spacing='sm'>
                          <Text>{providerName}</Text>
                          {isPatientOnBundlePlan && !form.values.excludeFromBundle && (
                            <Pill status='none' variant='filled'>
                              Bundle
                            </Pill>
                          )}
                        </Group>
                      ) : (
                        <EmptyDataCell />
                      )}
                    </Box>
                  )}
                </Grid.Col>
                {isEditing && isPatientOnBundlePlan && (
                  <Grid.Col span={12}>
                    <Checkbox
                      label='Exclude from bundle'
                      {...form.getInputProps('excludeFromBundle', { type: 'checkbox' })}
                    />
                  </Grid.Col>
                )}
                <EditableCol
                  text={form.values.firstName}
                  isEditing={isEditing}
                  label="Cardholder's first name"
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('firstName')}
                    placeholder={getPlaceholderText('firstName')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.lastName}
                  isEditing={isEditing}
                  label="Cardholder's last name"
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('lastName')}
                    placeholder={getPlaceholderText('lastName')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.cardholderBirthday}
                  isEditing={isEditing}
                  label="Cardholder's birthday"
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('cardholderBirthday')}
                    placeholder={getPlaceholderText('dob')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.memberId}
                  isEditing={isEditing}
                  label='Member ID'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('memberId')}
                    placeholder={getPlaceholderText('memberId')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.groupId}
                  isEditing={isEditing}
                  label='Group ID (optional)'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('groupId')}
                    placeholder={getPlaceholderText('groupId')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.rxbin}
                  isEditing={isEditing}
                  label='Rx Bin (optional)'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('rxbin')}
                    placeholder={getPlaceholderText('rxBin')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.rxPcn}
                  isEditing={isEditing}
                  label='Rx PCN (optional)'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('rxPcn')}
                    placeholder={getPlaceholderText('rxPcn')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.rxGroup}
                  isEditing={isEditing}
                  label='Rx Group (optional)'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('rxGroup')}
                    placeholder={getPlaceholderText('rxGroup')}
                  />
                </EditableCol>
                <EditableCol
                  text={form.values.planName}
                  isEditing={isEditing}
                  label='Plan name (optional)'
                  span={4}
                >
                  <TextInput
                    {...form.getInputProps('planName')}
                    placeholder={getPlaceholderText('planName')}
                  />
                </EditableCol>
                <EditableCol
                  text={
                    form.values.planType
                      ? PrimaryInsurancePlanType[form.values.planType]
                      : undefined
                  }
                  isEditing={isEditing}
                  label='Plan type'
                  span={4}
                >
                  <Select
                    data={Object.keys(PrimaryInsurancePlanType).map(planType => ({
                      label:
                        PrimaryInsurancePlanType[planType as keyof typeof PrimaryInsurancePlanType],
                      value: planType,
                    }))}
                    {...form.getInputProps('planType')}
                  />
                </EditableCol>
                <EditableCol
                  text={capitalize(form.values.patientIsPrimarySubscriber)}
                  isEditing={isEditing}
                  label='Is patient primary subscriber'
                  span={4}
                >
                  <Select
                    {...form.getInputProps('patientIsPrimarySubscriber')}
                    data={[
                      { value: 'yes', label: 'Yes' },
                      { value: 'no', label: 'No' },
                    ]}
                  />
                </EditableCol>
                {form.values.patientIsPrimarySubscriber === 'no' && (
                  <>
                    <EditableCol
                      text={form.values.primarySubscriberFirstName}
                      isEditing={isEditing}
                      label='Primary subscriber first name'
                      span={4}
                    >
                      <TextInput
                        {...form.getInputProps('primarySubscriberFirstName')}
                        placeholder={getPlaceholderText('firstName')}
                      />
                    </EditableCol>
                    <EditableCol
                      text={form.values.primarySubscriberLastName}
                      isEditing={isEditing}
                      label='Primary subscriber last name'
                      span={4}
                    >
                      <TextInput
                        {...form.getInputProps('primarySubscriberLastName')}
                        placeholder={getPlaceholderText('lastName')}
                      />
                    </EditableCol>
                    <EditableCol
                      text={form.values.primarySubscriberBirthday}
                      isEditing={isEditing}
                      label='Primary subscriber birthday'
                      span={4}
                    >
                      <TextInput
                        {...form.getInputProps('primarySubscriberBirthday')}
                        placeholder={getPlaceholderText('dob')}
                      />
                    </EditableCol>
                  </>
                )}
                {form.values.planType === 'MC' && (
                  <EditableCol
                    text={form.values.medicaidInfo.memberId}
                    isEditing={isEditing}
                    label='Medicaid member ID (optional)'
                    span={4}
                  >
                    <TextInput
                      {...form.getInputProps('medicaidInfo.memberId')}
                      placeholder={getPlaceholderText('memberId')}
                    />
                  </EditableCol>
                )}
              </Grid>
              <Group position='apart'>
                <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                  {isEditing ? (
                    <UploadPhoto
                      label='Front of insurance card'
                      {...form.getInputProps('insuranceCardFrontId')}
                      setError={error => form.setFieldError('insuranceCardFrontId', error)}
                      patientId={patientId}
                    />
                  ) : (
                    <Stack spacing='sm'>
                      <Group position='apart'>
                        <Text bold>Front of insurance card</Text>
                        {insuranceCardFront && (
                          <TertiaryButton
                            leftIcon={<PlusCircleIcon />}
                            onClick={() => {
                              setFileToExpand(insuranceCardFront)
                            }}
                          >
                            Expand
                          </TertiaryButton>
                        )}
                      </Group>
                      <UnstyledButton
                        onClick={() => {
                          if (insuranceCardFront) {
                            setFileToExpand(insuranceCardFront)
                          }
                        }}
                      >
                        <Photo
                          value={form.values.insuranceCardFrontId}
                          patientId={patientId}
                          label='Front of insurance card'
                        />
                      </UnstyledButton>
                    </Stack>
                  )}
                </Box>
                <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                  {isEditing ? (
                    <UploadPhoto
                      label='Back of insurance card'
                      {...form.getInputProps('insuranceCardBackId')}
                      setError={error => form.setFieldError('insuranceCardBackId', error)}
                      patientId={patientId}
                    />
                  ) : (
                    <Stack spacing='sm'>
                      <Group position='apart'>
                        <Text bold>Back of insurance card</Text>
                        {insuranceCardBack && (
                          <TertiaryButton
                            leftIcon={<PlusCircleIcon />}
                            onClick={() => {
                              setFileToExpand(insuranceCardBack)
                            }}
                          >
                            Expand
                          </TertiaryButton>
                        )}
                      </Group>
                      <UnstyledButton
                        onClick={() => {
                          if (insuranceCardBack) {
                            setFileToExpand(insuranceCardBack)
                          }
                        }}
                      >
                        <Photo
                          value={form.values.insuranceCardBackId}
                          patientId={patientId}
                          label={`${insuranceString} back`}
                        />
                      </UnstyledButton>
                    </Stack>
                  )}
                </Box>
              </Group>
            </Stack>
          </EditableSection>
        ) : (
          <Stack spacing='sm'>
            <TitleFour>{insuranceString}</TitleFour>
            <Divider />
            <NoInsuranceSection
              addInsuranceOnClick={onAddInsurance}
              insuranceType={insuranceType}
            />
          </Stack>
        )}
      </Stack>
    </>
  )
}
