import { FiPlus } from '@react-icons/all-files/fi/FiPlus'
import { TitleThree } from '@shared/components'
import { Employee, isAdmin, roleName } from '@shared/types'
import head from 'lodash/head'
import omit from 'lodash/omit'
import range from 'lodash/range'
import { useState } from 'react'
import Confetti from 'react-confetti'
import AOption from '../../components/atoms/AOption'
import ASelect from '../../components/atoms/ASelect'
import AFakeEmployeeRow from '../../components/atoms/fakes/AFakeEmployeeRow'
import { useAuth } from '../../context/auth'
import { useEmployees, useTypedHistory } from '../../utils/hooks'
import SearchBar from '../care_team/irq/SearchBar'
import AddEmployeeModal from './AddEmployeeModal'
import EmployeeSidebarRow from './EmployeeSidebarRow'

export type OEmployeeSidebarProps = {
  disabled: boolean
}

const OEmployeeSidebar = ({ disabled }: OEmployeeSidebarProps) => {
  const [selectedRole, setSelectedRole] = useState<Employee['role'] | undefined>()
  const [newEmployeeModal, showNewEmployeeModal] = useState(false)

  const [query, setQuery] = useState('')

  const ncmOrTnRoles: Employee['role'][] = ['sncm', 'ncm', 'tn']
  const employeesQuery = useEmployees({
    status: 'currentEmployee',
    role:
      selectedRole && ncmOrTnRoles.includes(selectedRole) ? [selectedRole, 'ncm_tn'] : selectedRole,
  })

  const employees = employeesQuery.data || []
  const queryLowerCase = query.toLowerCase()
  const directory = employees
    .filter(
      a =>
        a.name?.toLowerCase().includes(queryLowerCase) ||
        a.legalFirstName?.toLowerCase().includes(queryLowerCase) ||
        a.legalLastName?.toLowerCase().includes(queryLowerCase),
    )
    .reduce(
      (dir, employee) => {
        const firstLetter = head(employee?.name) ?? 'Unknown'
        const dirEmployeesForLetter = dir[firstLetter] || []
        dir[firstLetter] = [...dirEmployeesForLetter, employee]
        return dir
      },
      {} as Record<string, Employee[]>,
    )

  const { currentUser } = useAuth()
  const { replace } = useTypedHistory()

  const handleSelectedRole: React.ChangeEventHandler<HTMLSelectElement> = event => {
    /*
     * If the selected role is a valid employee role, set the selected role
     * Otherwise, set the selected role to undefined
     */
    if (Object.keys(roleName).includes(event.target.value)) {
      setSelectedRole(event.target.value as Employee['role'])
    } else {
      setSelectedRole(undefined)
    }
  }

  return (
    <div className='xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200'>
      {query === '1000' && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      {query === '3000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={3000} />
      )}
      <div className='px-6 pt-6 pb-4'>
        <div className='flex flex-row justify-between'>
          <h2 className='text-lg font-medium text-gray-900'>Employees</h2>
          {isAdmin(currentUser) && (
            <button
              className='text-sm ml-3 z-50 h-6 w-6 rounded-md border-2 border-gray-400 focus:outline-none content-center'
              onClick={() => showNewEmployeeModal(true)}
            >
              <FiPlus className='w-5 h-5 text-gray-500 hover:text-gray-400' />
            </button>
          )}
        </div>
        <p className='mt-1 text-sm text-gray-600'>
          {employeesQuery.isSuccess
            ? `Search directory of ${employees.length} ${
                employees.length === 1 ? 'employee' : 'employees'
              }`
            : 'Updating directory...'}
        </p>
        <div className='mt-2'>
          <SearchBar
            placeholder='Search for an employee...'
            value={query}
            onChange={setQuery}
            onClear={() => {
              setQuery('')
            }}
          />
        </div>
        <div className='mt-2'>
          <ASelect onChange={handleSelectedRole} value={selectedRole}>
            <AOption value=''>All Roles</AOption>
            {Object.entries(omit(roleName, 'ncm_tn'))
              .sort()
              .map(([role, roleName]) => (
                <AOption key={role} value={role}>
                  {roleName}
                </AOption>
              ))}
          </ASelect>
        </div>
      </div>
      <nav className='flex-1 min-h-0 overflow-y-auto' aria-label='Directory'>
        {employeesQuery.isLoading && range(0, 10).map(i => <AFakeEmployeeRow key={i} />)}
        {directory &&
          Object.keys(directory).map(letter => (
            <div key={letter} className='relative'>
              <div className='z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500'>
                <TitleThree>{letter}</TitleThree>
              </div>
              <ul className='relative z-0 divide-y divide-gray-200'>
                {directory[letter]?.map((employee: Employee) => (
                  <EmployeeSidebarRow
                    key={employee.oid}
                    employee={employee}
                    disabled={disabled}
                    onClick={() =>
                      replace<'/employees/:employeeID'>({
                        pathname: `/employees/${employee.oid}`,
                      })
                    }
                  />
                ))}
              </ul>
            </div>
          ))}
        {newEmployeeModal && (
          <AddEmployeeModal
            closeModal={() => {
              showNewEmployeeModal(false)
            }}
          />
        )}
      </nav>
    </div>
  )
}

export default OEmployeeSidebar
