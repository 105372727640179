import { EmrApi, OpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { emrApi } from '../../api'

export const useEmrMutation = <OpheliaRoute extends keyof EmrApi>(
  route: OpheliaRoute,
  options?: Omit<
    UseMutationOptions<
      EmrApi[OpheliaRoute]['res'],
      AxiosError<OpheliaHttpError>,
      Partial<EmrApi[OpheliaRoute]['req']>
    >,
    'mutationFn'
  >,
) => {
  return useMutation<
    EmrApi[OpheliaRoute]['res'],
    AxiosError<OpheliaHttpError>,
    Partial<EmrApi[OpheliaRoute]['req']>
  >(emrApi.getMutation(route), options)
}
