import { Box, Sx } from '@shared/components'
import { CalendarEventForRender } from '@shared/types'
import { AppointmentEvent } from './AppointmentEvent'
import { BlockEvent } from './BlockEvent'
import { OOOEvent } from './OOOEvent'
import { HOURS_SHOWN, HOUR_HEIGHT } from './OVisits'
import { SlotEvent } from './SlotEvent'
import { UnusedTimeEvent } from './UnusedTimeEvent'

export type ColumnDataProps = {
  hasAvailableSlots: boolean
  patientId?: string | undefined
  blocks: CalendarEventForRender[]
  weekView: boolean
  bar?: number
}

export const ColumnData = ({
  hasAvailableSlots,
  patientId,
  blocks,
  weekView,
  bar,
}: ColumnDataProps) => {
  const widthStyles: Sx = weekView
    ? {
        width: '14%',
        minWidth: '6.25rem',
      }
    : {
        width: '100%',
        minWidth: '16rem',
        maxWidth: '24rem',
      }

  return (
    <Box sx={{ display: 'grid', position: 'relative', ...widthStyles }}>
      <Box sx={{ gridRowStart: 1, gridColumnStart: 1 }}>
        {[...Array(HOURS_SHOWN * 3)].map((e, i) => (
          // Draw 20 minute mark lines
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            sx={{ height: `${HOUR_HEIGHT / 3}rem` }}
          >
            <Box
              sx={theme => ({ backgroundColor: theme.other.colors.background[2], height: '1px' })}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ gridRowStart: 1, gridColumnStart: 1 }}>
        {[...Array(HOURS_SHOWN)].map((e, i) => (
          // Draw hour lines
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            sx={{ height: `${HOUR_HEIGHT}rem` }}
          >
            <Box
              sx={theme => ({ backgroundColor: theme.other.colors.background[4], height: '1px' })}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ gridRowStart: 1, gridColumnStart: 1 }}>
        {blocks.map((block, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            sx={{
              position: 'absolute',
              zIndex: block.type === 'slot' && block.event.selected ? 1 : 0,
              width: `calc(100% - ${block.indent * 2}rem)`,
              top: `${block.top}rem`,
              marginLeft: `${block.indent * 2}rem`,
            }}
          >
            {block.type === 'appointment' && (
              <AppointmentEvent hasAvailableSlots={hasAvailableSlots} block={block} />
            )}
            {block.type === 'block' && <BlockEvent block={block} />}
            {block.type === 'slot' && <SlotEvent patientId={patientId} block={block} />}
            {block.type === 'ooo' && <OOOEvent block={block} />}
            {block.type === 'unused' && (
              <UnusedTimeEvent hasAvailableSlots={hasAvailableSlots} block={block} />
            )}
          </Box>
        ))}
        {bar && (
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              top: `${bar}rem`,
            }}
          >
            <Box sx={theme => ({ backgroundColor: theme.colors.red[0], height: '2px' })} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
