import { useClipboard, useToggle } from '@mantine/hooks'
import {
  CopyIcon,
  Group,
  PrimaryButton,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  showNotification,
} from '@shared/components'
import { EncounterStatuses } from '@shared/types'
import { toTime } from '@shared/utils'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLunaMutation } from '../../../../utils/hooks'

export type EncounterFooterButtonsProps = {
  updateEncounterStatus: ({
    status,
    moveToAllowList,
  }: {
    status: EncounterStatuses
    moveToAllowList?: boolean
  }) => Promise<void>
  currentStatus: EncounterStatuses
  submitToCandid: () => Promise<void>
  submitToCandidButtonDisabled: boolean
  isFutureEncounter: boolean
}

export const EncounterFooterButtons = ({
  currentStatus,
  submitToCandidButtonDisabled,
  updateEncounterStatus,
  submitToCandid,
  isFutureEncounter,
}: EncounterFooterButtonsProps) => {
  const [flagButtonLoading, setFlagButtonLoading] = useState(false)
  const [notBillableButtonLoading, setNotBillableButtonLoading] = useState(false)
  const [submitToCandidButtonLoading, setSubmitToCandidButtonLoading] = useState(false)
  const [moveToReviewButtonLoading, setMoveToReviewButtonLoading] = useState(false)

  const { encounterId } = useParams()
  const cloneAndEditMutation = useLunaMutation('POST /encounters/:encounterId/clone')

  const { copy } = useClipboard({ timeout: 1000 })
  const [copied, toggleCoppied] = useToggle()
  const copyOnClick = (value: string) => {
    copy(value)
    toggleCoppied(true)
  }

  const flagUnflagButtonOnClick = async () => {
    setFlagButtonLoading(true)
    try {
      const newStatus =
        currentStatus === EncounterStatuses.Unsubmitted
          ? EncounterStatuses.Flagged
          : EncounterStatuses.Unsubmitted
      await updateEncounterStatus({ status: newStatus })
    } finally {
      setFlagButtonLoading(false)
    }
  }

  const notBillableButtonOnClick = async () => {
    setNotBillableButtonLoading(true)
    try {
      await updateEncounterStatus({ status: EncounterStatuses.NotBillable })
    } finally {
      setNotBillableButtonLoading(false)
    }
  }

  const submitToCandidButtonOnClick = async () => {
    setSubmitToCandidButtonLoading(true)
    try {
      if (isFutureEncounter) {
        // Candid does not accept encounters with future dates, so they will be moved to our auto-submitting queue where they will be submitted at a later time.
        await updateEncounterStatus({ status: EncounterStatuses.AutoSubmitting })
      } else {
        await submitToCandid()
      }
    } finally {
      setSubmitToCandidButtonLoading(false)
    }
  }

  const moveToReviewButtonOnClick = async () => {
    setMoveToReviewButtonLoading(true)
    try {
      await updateEncounterStatus({ status: EncounterStatuses.Unsubmitted, moveToAllowList: true })
    } finally {
      setMoveToReviewButtonLoading(false)
    }
  }

  const cloneAndEditButtonOnClick = async () => {
    const res = await cloneAndEditMutation.mutateAsync({
      params: {
        encounterId: encounterId || '',
      },
    })

    const { patientId } = res.data

    showNotification({
      id: patientId,
      title: <Text bold>Encounter duplicated successfully in the unsubmitted queue</Text>,
      message: (
        <Stack align='end' spacing='xs'>
          <TertiaryButton leftIcon={<CopyIcon />} onClick={() => copyOnClick(patientId)}>
            {copied ? 'Copied' : 'Copy patient ID'}
          </TertiaryButton>
        </Stack>
      ),
      autoClose: toTime('1 min').ms(),
      variant: 'success',
    })
  }

  const statusChangeInProgress =
    flagButtonLoading ||
    notBillableButtonLoading ||
    submitToCandidButtonLoading ||
    moveToReviewButtonLoading

  if (
    currentStatus === EncounterStatuses.Unsubmitted ||
    currentStatus === EncounterStatuses.Flagged
  ) {
    return (
      <Group position='right'>
        <TertiaryButton
          loading={flagButtonLoading}
          disabled={statusChangeInProgress}
          onClick={flagUnflagButtonOnClick}
        >
          {currentStatus === EncounterStatuses.Unsubmitted ? 'Flag' : 'Unflag'}
        </TertiaryButton>
        <SecondaryButton
          loading={notBillableButtonLoading}
          disabled={statusChangeInProgress}
          onClick={notBillableButtonOnClick}
        >
          Mark as not billable
        </SecondaryButton>
        <PrimaryButton
          loading={submitToCandidButtonLoading}
          disabled={submitToCandidButtonDisabled || statusChangeInProgress}
          onClick={submitToCandidButtonOnClick}
        >
          {isFutureEncounter ? 'Move to auto submitting' : 'Submit to Candid'}
        </PrimaryButton>
      </Group>
    )
  }

  if (
    currentStatus === EncounterStatuses.NotBillable ||
    currentStatus === EncounterStatuses.NotOnAllowList ||
    currentStatus === EncounterStatuses.AutoSubmitting
  ) {
    return (
      <Group position='right'>
        <PrimaryButton
          disabled={statusChangeInProgress}
          loading={moveToReviewButtonLoading}
          onClick={moveToReviewButtonOnClick}
        >
          Move to review
        </PrimaryButton>
      </Group>
    )
  }

  if (currentStatus === EncounterStatuses.Submitted) {
    return (
      <Group position='right'>
        <PrimaryButton
          disabled={statusChangeInProgress}
          loading={cloneAndEditMutation.isLoading}
          onClick={cloneAndEditButtonOnClick}
        >
          Duplicate & modify
        </PrimaryButton>
      </Group>
    )
  }

  return null
}
