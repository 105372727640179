import {
  Alert,
  CheckIcon,
  Group,
  LinkText,
  PrimaryButton,
  SecondaryButton,
  SlashIcon,
  Stack,
  Text,
  TrashCanIcon,
} from '@shared/components'
import { useState } from 'react'
import { useLunaMutation } from '../../../../../utils/hooks'
import { useInvalidateCollections } from '../../use-invalidate-collections'

type QuickMergeStatusProps = {
  fromPatientId: string
  toPatientId: string
  onClose: () => void
}

type QuickMergeArchiveStatus = {
  type?: 'success' | 'error'
  errorMessage?: string
}

export const QuickMergeArchiveStep = ({
  fromPatientId,
  toPatientId,
  onClose,
}: QuickMergeStatusProps) => {
  const archivePatientChart = useLunaMutation('POST /patients/:patientId/archive')
  const invalidate = useInvalidateCollections()
  const [archiveStatus, setArchiveStatus] = useState<QuickMergeArchiveStatus>()

  const onDelete = async () => {
    await archivePatientChart.mutateAsync(
      {
        params: { patientId: fromPatientId },
        data: { toPatientId, type: 'quick_merge' },
      },
      {
        onSuccess: () => {
          invalidate({ patientId: fromPatientId })
          invalidate({ patientId: toPatientId })
          setArchiveStatus({ type: 'success' })
        },
        onError: error => {
          setArchiveStatus({ type: 'error', errorMessage: error?.response?.data?.message })
        },
      },
    )
  }

  return (
    <Stack>
      {archiveStatus?.type === 'success' ? (
        <Alert variant='success' icon={<CheckIcon />}>
          Chart has been saved in a subcollection for
          <LinkText
            component='a'
            href={`/patients/${toPatientId}/admin-tools`}
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            {` ${toPatientId}`}
          </LinkText>
        </Alert>
      ) : (
        <Stack>
          <Text>Would you like to archive and delete this patient chart?</Text>
          <Text>{`This will save this patient's chart in the receiving patient's mergedCharts subcollection.`}</Text>
        </Stack>
      )}
      {archiveStatus?.type === 'error' && (
        <Alert variant='error' icon={<SlashIcon />}>
          {`An error occured: ${archiveStatus?.errorMessage}`}
        </Alert>
      )}
      <Group position='right'>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        {archiveStatus?.type !== 'success' && (
          <PrimaryButton
            loading={archivePatientChart.isLoading}
            leftIcon={<TrashCanIcon />}
            onClick={onDelete}
          >
            Archive
          </PrimaryButton>
        )}
      </Group>
    </Stack>
  )
}
