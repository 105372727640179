import { useForm } from '@mantine/form'
import { useDidUpdate, useShallowEffect, useToggle } from '@mantine/hooks'
import {
  Accordion,
  Alert,
  Banner,
  Box,
  CheckCircleIcon,
  Checkbox,
  ClockIcon,
  DatePicker,
  Divider,
  Group,
  InfoIcon,
  PrimaryButton,
  ScrollArea,
  Select,
  ShieldIcon,
  Stack,
  Sx,
  TertiaryButton,
  Text,
  TextInput,
  TimeInput,
  TitleThree,
  Tooltip,
  XIcon,
  showNotification,
  skipIfOtherField,
  useBanner,
  validateWith,
} from '@shared/components'
import {
  AcuityTimeSlot,
  Appointment,
  AppointmentTypeString,
  AppointmentTypes,
  Employee,
  LevelOfCareStatus,
  Patient,
  ReasonForVisitWithCcm,
  ReasonForVisitWithPc,
  SlotEvent,
  dischargeStatuses,
  getOpheliaHttpError,
  hasGroupRole,
  hasLevelOfCare,
  hasRole,
  hasStatus,
  isAdmin,
  isCareCoordinator,
  isCcm,
  isClinician,
  isPrimaryClinician,
  reasonsForVisitWithCcm,
  reasonsForVisitWithPc,
  stringifyNumber,
} from '@shared/types'
import { name, sortBy } from '@shared/utils'
import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { appointmentsApi, emrApi } from '../../api'
import { DischargeAlert } from '../../components/banners/DischargeAlert'
import { SelectPatient } from '../../components/forms/SelectPatient'
import { useAuth } from '../../context/auth'
import { isRequired } from '../../utils/formValidation'
import {
  PatientAvailabilityForm,
  useEmployees,
  useEmrQuery,
  useFlags,
  usePatientAvailability,
  usePatientInsurances,
} from '../../utils/hooks'
import { mapIdToName } from '../../utils/utils'
import { AccordionHeader } from '../care_team/tasks/FilterTaskDrawer'
import { usePatient } from '../patient/PPatientContext'
import { useInitialBhcm } from '../patient/use-initial-bhcm'
import { useCarePathway } from '../patient/visits/carePathways'
import { CcmTransitionSection, SelectEmployee } from './CalendarDrawer'
import { LatestVisitCard } from './LatestVisitCard'
import { useAvailableEmployees } from './SelectClinician'
import { useCareCoordinatorScheduling } from './use-care-coordinator-scheduling'
import { useVisitTypes } from './useVisitTypes'

type Form = {
  patientId: string
  visitType: string
  selectedTime: string
  customDate?: string
  customTime?: string
  reasonForVisitWithPc?: ReasonForVisitWithPc
  reasonForVisitWithCcm?: ReasonForVisitWithCcm
  reasonForReserveVisit?: string
  reasonForVisitWithCcmOther?: string
  reasonForVisitWithPcOther?: string
  shouldSendUdsReminder: boolean
}

export type CcmTransitionForm = Partial<Patient['ccmTransition']>

export type ScheduleVisitContentProps = {
  setSelectedPatient: (patientId: string) => void
  calendarId: string
  setCalendarId: (calendarId: string) => void
  date: string
  setCustomSlot: (slot: SlotEvent[]) => void
  setSlots: (slots: SlotEvent[]) => void
  onCloseDrawer: () => void
  setDate: (date: string) => void
  rescheduleAppointment?: Appointment | undefined
  visitTypesAllowed?: AppointmentTypeString | AppointmentTypeString[] | null
}

export type ScheduleVisitProps = ScheduleVisitContentProps & { showTitle: boolean }

const DEFAULT_DURATION = 20

const calculateWeekText = (datetime: string) => {
  const thisWeek = dayjs().startOf('week')
  const appointmentWeek = dayjs(datetime).startOf('week')
  const weeks = appointmentWeek.diff(thisWeek, 'weeks')
  if (weeks === 0) {
    return 'This week'
  }
  if (weeks === 1) {
    return 'Next week'
  }
  return `In ${weeks} ${pluralize('week', weeks)}`
}

const getAutoNavAlertMessage = ({
  datetime,
  patient,
  autoNav,
}: {
  datetime: string
  patient: Patient
  autoNav: number
}) => {
  const thisWeek = dayjs().startOf('week')
  const appointmentWeek = dayjs(datetime).startOf('week')
  const weeks = appointmentWeek.diff(thisWeek, 'weeks')

  let message = `${patient?.personalData.firstName} ${patient?.personalData.lastName} is ${patient?.statuses.levelOfCare}`

  if (autoNav === 1) {
    message += `, so we navigated your calendar to `
    if (weeks === 1) {
      return `${message} next week`
    }
    if (weeks === 2) {
      return `${message} two weeks from now`
    }
    if (weeks === 4) {
      return `${message} four weeks from now`
    }
  }
  return message
}

/*
 * Note: we want to check whether we can reschedule the patient's visit from a PC to a CCM if:
 * - The patient is a biweekly or monthly patient
 * - The appointment is a follow-up visit
 * - The clinician is a PC
 * - The user scheduling the visit is a PC
 */
const isEligibleForCcmTransitionQuestions = ({
  patient,
  visitType,
  appointmentTypes,
  selectedEmployee,
}: {
  patient: Patient | undefined
  visitType: string
  appointmentTypes: AppointmentTypes | undefined
  selectedEmployee: Employee | undefined
}) => {
  if (!patient?.statuses?.levelOfCare) {
    return false
  }

  if (!selectedEmployee) {
    return false
  }

  const isEligibleLevelOfCare = hasLevelOfCare(patient, 'monthly', 'biweekly')

  if (!isEligibleLevelOfCare) {
    return false
  }

  const isFollowUp = appointmentTypes?.followUpTypes.find(type => type.id.toString() === visitType)

  if (!isFollowUp) {
    return false
  }

  const isSchedulingWithPc = isPrimaryClinician(selectedEmployee)

  return isSchedulingWithPc
}

// Ask whether the patient is ready to be transitioned to a CCM if the current user is a CCM
const shouldDisplayCcmTransitionSection = ({
  patient,
  visitType,
  appointmentTypes,
  currentUser,
  selectedEmployee,
}: {
  patient: Patient | undefined
  visitType: string
  appointmentTypes: AppointmentTypes | undefined
  currentUser: Employee
  selectedEmployee: Employee | undefined
}) => {
  const isEligibleAppointment = isEligibleForCcmTransitionQuestions({
    patient,
    visitType,
    appointmentTypes,
    selectedEmployee,
  })

  if (!isEligibleAppointment) {
    return false
  }

  const isCurrentUserPc = isPrimaryClinician(currentUser)

  return isCurrentUserPc
}

// Ask why patient is being scheduled with a PC if the current user is a PC
const shouldAskWhyPatientIsScheduledWithPc = ({
  patient,
  visitType,
  appointmentTypes,
  currentUser,
  selectedEmployee,
}: {
  patient: Patient | undefined
  visitType: string
  appointmentTypes: AppointmentTypes | undefined
  currentUser: Employee
  selectedEmployee: Employee | undefined
}) => {
  const isEligibleAppointment = isEligibleForCcmTransitionQuestions({
    patient,
    visitType,
    appointmentTypes,
    selectedEmployee,
  })

  if (!isEligibleAppointment) {
    return false
  }

  const isCurrentUserCcm = isCcm(currentUser)

  return isCurrentUserCcm
}

const OCCUPANCY_THRESHOLD = 0.85

export const ScheduleVisitHeader = ({
  currentUser,
  showTitle,
  onCloseDrawer,
}: {
  currentUser: Employee
  showTitle: boolean
  onCloseDrawer: () => void
}) => {
  if (!showTitle) {
    return null
  }

  if (hasGroupRole(currentUser, 'enrollmentCoordinator')) {
    return (
      <>
        <TitleThree px='md'>Schedule visit</TitleThree>
        <Divider px='md' />
      </>
    )
  }

  return (
    <>
      <Group position='apart' px='md'>
        <TitleThree>Schedule visit</TitleThree>
        <TertiaryButton leftIcon={<XIcon />} onClick={onCloseDrawer} />
      </Group>
      <Divider px='md' />
    </>
  )
}

export const ScheduleVisit = ({
  setSelectedPatient,
  calendarId,
  setCalendarId,
  date,
  setCustomSlot,
  setSlots,
  onCloseDrawer = () => ({}),
  showTitle = true,
  rescheduleAppointment = undefined,
  setDate = () => ({}),
  visitTypesAllowed,
}: ScheduleVisitProps) => {
  const { patientQuery } = usePatient()
  const carePathway = useCarePathway()
  const { currentUser } = useAuth()
  const patient = patientQuery?.data
  const patientInsurancesQuery = usePatientInsurances(patient)
  const [autoNav, setAutoNav] = useState<number>(0)
  const { canScheduleCustomTime } = useFlags()

  const form = useForm<Form>({
    initialValues: {
      patientId: patient?.oid ?? '',
      // The default visit type is set in the appointment type query below.
      visitType: '',
      selectedTime: '',
      customDate: '',
      customTime: '',
      reasonForVisitWithPc: undefined,
      reasonForVisitWithCcm: undefined,
      reasonForReserveVisit: '',
      reasonForVisitWithCcmOther: '',
      reasonForVisitWithPcOther: '',
      shouldSendUdsReminder: rescheduleAppointment?.metadata?.shouldSendUdsReminder || false,
    },
  })

  const { visitTypes } = useVisitTypes({
    visitTypesAllowed,
    patientId: form.values.patientId,
  })

  const defaultAppointmentTypeQuery = useEmrQuery(
    'GET /patients/:patientId/appointment-types',
    {
      query: {
        appointmentIdToReschedule: rescheduleAppointment
          ? stringifyNumber(rescheduleAppointment.appointmentTypeID)
          : undefined,
      },
    },
    {
      enabled: Boolean(form.values.patientId),
    },
  )

  // Sets the default visit type
  useEffect(() => {
    // Only set the default visit type if the user has not already selected one
    if (form.values.visitType) {
      return
    }

    const defaultAppointmentString = defaultAppointmentTypeQuery.data
      ? stringifyNumber(defaultAppointmentTypeQuery.data?.defaultAppointmentTypeId)
      : ''

    if (visitTypes.some(type => type.value === defaultAppointmentString)) {
      return form.setValues({
        visitType: defaultAppointmentString,
      })
    }

    form.setValues({ visitType: visitTypes[0]?.value })
  }, [visitTypes, defaultAppointmentTypeQuery.data, form.values.visitType])

  useDidUpdate(() => {
    /**
     * If the date is updated once, we have auto navigated them
     * If the date is updated again, the user has navigated themselves
     */
    setAutoNav(() => autoNav + 1)
  }, [date])

  const queryClient = useQueryClient()
  const { showBanner, cleanBanner } = useBanner()

  const {
    shouldScheduleInitialBhcmVisit,
    ccmCalendarId,
    initialBhcmVisitTypeId,
    initialBhcmVisitTypeName,
  } = useInitialBhcm()

  useEffect(() => {
    if (shouldScheduleInitialBhcmVisit && !rescheduleAppointment) {
      form.setValues({ visitType: initialBhcmVisitTypeId })
      setCalendarId(ccmCalendarId)
    }
  }, [shouldScheduleInitialBhcmVisit, initialBhcmVisitTypeId])

  const calculateSlots = (slots: AcuityTimeSlot[], duration: number): SlotEvent[] => {
    const appointmentType = visitTypes.find(v => v.value === form.values.visitType)?.value ?? ''

    return slots.map(slot => ({
      selected: slot.time === form.values.selectedTime,
      onSelect: () => form.setValues({ selectedTime: slot.time }),
      duration,
      appointmentType,
      ...slot,
    }))
  }

  const appointmentTypesQuery = useQuery(['appointmentsApi.types'], appointmentsApi.types)
  const appointmentTypes = appointmentTypesQuery.data

  const isFollowUp = appointmentTypes?.followUpTypes.find(
    type => type.id.toString() === form.values.visitType,
  )

  // RULE: Cannot schedule monthly patients in a follow up reserve slot.
  const isFollowUpReserve = Boolean(isFollowUp?.reserve)
  const followUpReserveWarningMessage =
    hasLevelOfCare(patient, 'monthly') &&
    isFollowUpReserve &&
    'Monthly patients are restricted from using this visit type. Schedule patient for a regular follow-up visit.'

  const [appointmentTypeQueryKey, appointmentTypeQueryFn] = emrApi.getQuery(
    'GET /appointments/type',
    {
      query: {
        appointmentTypeId: form.values.visitType,
      },
    },
  )
  const appointmentTypeQuery = useQuery(appointmentTypeQueryKey, appointmentTypeQueryFn, {
    enabled: Boolean(form.values.visitType),
  })
  const duration = appointmentTypeQuery.data?.duration || DEFAULT_DURATION

  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const employees = employeesQuery.data || []
  const selectedEmployee = employees.find(
    employee => employee.calendarId?.toString() === calendarId,
  )
  const employeeId = selectedEmployee?.oid || ''

  const [slotsQueryKey, slotsQueryFn] = emrApi.getQuery('GET /appointments/slots', {
    query: {
      minDate: dayjs(date).startOf('isoWeek').toISOString(),
      maxDate: dayjs(date).endOf('isoWeek').toISOString(),
      timezone: dayjs.tz.guess(),
      calendarId,
      appointmentTypeId: form.values.visitType,
      patientId: form.values.patientId,
    },
  })

  const occupancyRateQuery = useEmrQuery(
    'GET /appointments/follow-up-occupancy-rate',
    {
      query: {
        startDate: dayjs(date).startOf('isoWeek').format('MM/DD/YYYY'),
        endDate: dayjs(date).endOf('isoWeek').format('MM/DD/YYYY'),
        calendarId: calendarId as `${number}`,
      },
    },
    {
      enabled:
        Boolean(isFollowUpReserve) &&
        /**
         * This warning message is the first check against allowing the user to schedule a follow up reserve.
         * When that message is not present, we can check the occupancy rate to see if that warning should be shown.
         */
        !followUpReserveWarningMessage,
    },
  )

  const isLowOccupancyRate =
    occupancyRateQuery.data && isFollowUpReserve
      ? occupancyRateQuery.data.occupancyRate < OCCUPANCY_THRESHOLD
      : null
  const occupancyRateWarningMessage = isLowOccupancyRate
    ? `Reserved slots are only available during weeks with limited follow-up availability. Try another week or try scheduling a regular follow up this week.`
    : ''

  const slotsQuery = useQuery(slotsQueryKey, slotsQueryFn, {
    enabled: Boolean(form.values.visitType) && Boolean(duration) && Boolean(form.values.patientId),
    onSuccess: slots => {
      form.setValues({ selectedTime: '' })

      if (slots.length === 0) {
        showBanner({
          type: 'warning',
          label:
            'There are no slots available for this visit type for this week. Try another week.',
          dismissable: true,
        })
        setSlots([])
      } else {
        setSlots(calculateSlots(slots, duration))
      }
    },
  })

  const {
    recommendedOptions,
    otherOptions,
    appointmentTypes: acuityApointmentTypes,
  } = useAvailableEmployees({
    patientId: form.values.patientId,
    appointmentTypeId: form.values.visitType,
  })

  const isInitialVisitSelected =
    visitTypes.find(type => type.value === form.values.visitType)?.label === 'Initial visit'
  const isLoadingAllSlots =
    !patientInsurancesQuery?.isLoading &&
    !patientInsurancesQuery?.data?.primaryInsurance?.basicInsuranceData?.eligiblePayerId
  const showInsuranceFilterWarning = isInitialVisitSelected && isLoadingAllSlots

  const [listAppointmentsQueryKey] = emrApi.getQuery('GET /appointments')

  const dateAndTimeString = `${form.values.customDate} ${form.values.customTime}`

  const rescheduleAppointmentMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/appointments/:appointmentId/reschedule'),
    {
      onSuccess: () => {
        if (appointmentTypes) {
          const appointmentType = mapIdToName(Number(form.values.visitType), appointmentTypes)
          const date = dayjs(form.values.selectedTime || dateAndTimeString).format('MM/DD/YYYY')
          const time = dayjs(form.values.selectedTime || dateAndTimeString).format('h:mma z')

          showNotification({
            title: 'Successfully rescheduled',
            message: `${appointmentType} successfully rescheduled for ${date} at ${time}.`,
            variant: 'success',
          })
        }

        void queryClient.invalidateQueries(listAppointmentsQueryKey)
        form.reset()
      },
      onError: err => {
        showBanner({
          label: getOpheliaHttpError(err, 'Something went wrong. Refresh the page and try again.'),
          type: 'error',
          dismissable: false,
        })
      },
    },
  )

  const createAppointmentMutation = useMutation(appointmentsApi.createAppointment, {
    onSuccess: () => {
      if (appointmentTypes) {
        const appointmentType = mapIdToName(Number(form.values.visitType), appointmentTypes)
        const date = dayjs(form.values.selectedTime || dateAndTimeString).format('MM/DD/YYYY')
        const time = dayjs(form.values.selectedTime || dateAndTimeString).format('h:mma z')

        showNotification({
          title: 'Successfully scheduled',
          message: `${appointmentType} successfully scheduled for ${date} at ${time}.`,
          variant: 'success',
        })
      }

      void queryClient.invalidateQueries(listAppointmentsQueryKey)
      form.reset()
    },
    onError: () => {
      showBanner({
        type: 'error',
        label: 'Something went wrong. Refresh the page and try again.',
        dismissable: false,
      })
    },
  })
  const [patientAppointmentsKey, patientAppointmentsFn] = emrApi.getQuery(
    'GET /patient/:patientId/appointments',
    {
      params: {
        patientId: form.values.patientId,
      },
    },
  )
  const patientAppointmentsQuery = useQuery(patientAppointmentsKey, patientAppointmentsFn, {
    enabled: Boolean(form.values.patientId),
  })
  const patientAppointments = patientAppointmentsQuery.data
  const latestAppointments =
    patientAppointments
      ?.filter(appointment => dayjs(appointment.datetime).isBefore(dayjs().endOf('day')))
      .sort(sortBy({ key: 'datetime', order: 'DESC' }))
      .slice(0, 3) ?? []

  const [isCustomTimeAccordionOpen, setCustomTimeAccordion] = useState<boolean>(false)
  const [latestAppointmentsAccordion, toggleLatestAppointmentsAccordion] = useToggle()

  const hasCustomDatetime = form.values.customDate && form.values.customTime

  useEffect(() => {
    /**
     * When we scheduling a new follow up appointment, we auto navigate
     * the calendar 1, 2, or 4 weeks, depending on the patients level of care.
     * Once the user navigates to a different week, we will no longer show the
     * message stating that we have auto navigated them
     */

    // Only auto navigate for clinicians, since CCs tend to schedule in the current week
    if (!isClinician(currentUser)) {
      return
    }

    if (!rescheduleAppointment && appointmentTypes?.followUpTypes && form.values.visitType) {
      if (isFollowUp) {
        const today = dayjs()
        if (hasLevelOfCare(patient, 'weekly')) {
          const nextWeek = today.add(1, 'week')
          const newDate = dayjs(nextWeek).format('YYYY-MM-DD')
          // Reset autonav to 0, which we will then increment by one when we set the date
          setAutoNav(0)
          /**
           * When autonav === 1, we have auto navigated them.
           * When it is any number greater than one, the user has navigated.
           */
          setDate(newDate)
        } else if (hasLevelOfCare(patient, 'biweekly')) {
          const inTwoWeeks = today.add(2, 'weeks')
          const newDate = dayjs(inTwoWeeks).format('YYYY-MM-DD')
          setAutoNav(0)
          setDate(newDate)
        } else if (hasLevelOfCare(patient, 'monthly')) {
          const inOneMonth = today.add(4, 'weeks')
          const newDate = dayjs(inOneMonth).format('YYYY-MM-DD')
          setAutoNav(0)
          setDate(newDate)
        }
      } else {
        setDate(dayjs().format('YYYY-MM-DD'))
        setAutoNav(2)
      }
    }
  }, [form.values.visitType, appointmentTypes, rescheduleAppointment, patient])

  useEffect(() => {
    setSlots(calculateSlots(slotsQuery.data || [], duration))
  }, [form.values.selectedTime])

  useEffect(() => {
    cleanBanner()
    form.setValues({
      reasonForReserveVisit: '',
    })
  }, [date, form.values.patientId, form.values.visitType, calendarId])

  useEffect(() => {
    if (hasCustomDatetime && duration) {
      setCustomSlot([
        {
          selected: true,
          onSelect: () => ({}),
          duration,
          appointmentType: form.values.visitType,
          time: dayjs(dateAndTimeString).toISOString(),
          slotsAvailable: 1,
        },
      ])
    } else {
      setCustomSlot([])
    }
  }, [form.values.customDate, form.values.customTime, duration])

  useEffect(() => {
    if (form.values.selectedTime) {
      form.setFieldValue('customDate', '')
      form.setFieldValue('customTime', '')
      setCustomTimeAccordion(false)
      return
    }
    if (form.values.customDate || form.values.customTime) {
      form.setFieldValue('selectedTime', '')
      setCustomTimeAccordion(true)
    }
  }, [form.values.selectedTime, form.values.customDate, form.values.customTime])

  useEffect(() => {
    setSelectedPatient(form.values.patientId)
  }, [form.values.patientId])

  /**
   * Only SCCs have the power to schedule appointments at a custom time
   */
  const canCustomSchedule = canScheduleCustomTime || hasRole(currentUser, 'engineer')

  // Display the ccm transition section if the current user is a pc
  const displayCcmTransitionSection = shouldDisplayCcmTransitionSection({
    patient,
    visitType: form.values.visitType,
    appointmentTypes,
    currentUser,
    selectedEmployee,
  })

  const skipIfShouldNotDisplayCcmTransitionSection = () => !displayCcmTransitionSection

  const ccmTransitionForm = useForm<CcmTransitionForm>({
    initialValues: {
      readyForCcmTransition: null,
      reasonForNoCcmTransition: null,
      reasonForNoCcmTransitionOther: '',
    },
    validate: {
      readyForCcmTransition: validateWith(skipIfShouldNotDisplayCcmTransitionSection, isRequired),
      reasonForNoCcmTransition: validateWith(
        skipIfShouldNotDisplayCcmTransitionSection,
        skipIfOtherField('readyForCcmTransition', 'is', 'yes'),
        isRequired,
      ),
      reasonForNoCcmTransitionOther: validateWith(
        skipIfShouldNotDisplayCcmTransitionSection,
        skipIfOtherField('reasonForNoCcmTransition', 'not', 'Other'),
        isRequired,
      ),
    },
  })

  const updatePatientMutation = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const askWhyPatientIsScheduledWithPc = shouldAskWhyPatientIsScheduledWithPc({
    patient,
    visitType: form.values.visitType,
    appointmentTypes,
    currentUser,
    selectedEmployee,
  })

  const askWhyPatientIsScheduledWithCcm =
    isFollowUp &&
    carePathway.data?.nextClinicianExplanation === 'Stable pathway - CCM visit' &&
    carePathway.data?.nextClinicianId !== selectedEmployee?.oid

  /*
   * If the patient meets the criteria for CCM transition, and doesn't need an initial BHCM visit,
   * then default to scheduling them with their CCM
   */
  useEffect(() => {
    if (!shouldScheduleInitialBhcmVisit && !carePathway.data) {
      const eligibleLevelsOfCare: LevelOfCareStatus[] = ['monthly', 'biweekly']
      const isEligible = eligibleLevelsOfCare.some(levelOfCare =>
        hasLevelOfCare(patient, levelOfCare),
      )

      if (isEligible) {
        const patientCcmId = patient?.nurseCareManager?.id

        const ccm = employees.find(employee => employee.oid === patientCcmId)

        if (ccm?.calendarId) {
          setCalendarId(`${ccm.calendarId}`)
        }
      }
    }
  }, [shouldScheduleInitialBhcmVisit, patient, employees, carePathway.data])

  // If the current user is a care coordinator, we want to default to scheduling the patient with a specific clinician
  const careCoordinatorSchedulingInfo = useCareCoordinatorScheduling()
  /*
   * Note: we're using useShallowEffect here to ensure that the calendarId is set only once. If we use a normal useEffect,
   * choosing a clinician from the dropdown that is different from the default clinician will cause the calendarId to be
   * set back to the default clinician
   */
  useShallowEffect(() => {
    // There will only be a defaultClinician value if all conditions are met, including the current user being a CC
    if (careCoordinatorSchedulingInfo?.defaultClinician) {
      const { calendarId } = careCoordinatorSchedulingInfo.defaultClinician
      setCalendarId(`${calendarId}`)
    }
  }, [careCoordinatorSchedulingInfo])

  useEffect(() => {
    if (carePathway.data?.nextClinicianCalendarId) {
      setCalendarId(`${carePathway.data.nextClinicianCalendarId}`)
    }
  }, [carePathway.data, setCalendarId])

  const selectedPatientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId: form.values.patientId,
      },
    },
    {
      enabled: Boolean(form.values.patientId),
    },
  )
  const selectedPatient = selectedPatientQuery.data

  const dischargeDate = selectedPatient?.discharge?.date
    ? dayjs(selectedPatient.discharge.date)
    : null

  const checkIfScheduledDateIsAllowed = ():
    | { result: true }
    | { result: false; reason: string } => {
    const DISCHARGE_WINDOW_IN_DAYS = 2

    if (!dischargeDate || !hasStatus(patient, ...dischargeStatuses)) {
      return { result: true }
    }

    const scheduledDate = hasCustomDatetime ? dateAndTimeString : form.values.selectedTime

    if (scheduledDate && dayjs(scheduledDate).isAfter(dischargeDate.endOf('day'))) {
      return {
        result: false,
        reason: `Selected date is after discharge date of ${dischargeDate.format('MM/DD/YYYY')}`,
      }
    }

    if (
      scheduledDate &&
      dayjs(scheduledDate).isAfter(
        dischargeDate.subtract(DISCHARGE_WINDOW_IN_DAYS, 'days').endOf('day'),
      )
    ) {
      return {
        result: false,
        reason: `Selected date is within ${DISCHARGE_WINDOW_IN_DAYS} days of discharge date of ${dischargeDate.format(
          'MM/DD/YYYY',
        )}`,
      }
    }

    return { result: true }
  }

  const { patientAvailabilityForm, submitPatientAvailability } = usePatientAvailability({
    patientId: form.values.patientId,
  })

  // Note: we will remove nullish values from the metadata object before storing the responses in the db, so no need to sanitize here
  const appointmentMetadata: Appointment['metadata'] = {
    shouldSendUdsReminder: form.values.shouldSendUdsReminder,
    reasonForVisitWithPc: form.values.reasonForVisitWithPc,
    reasonForVisitWithCcm: form.values.reasonForVisitWithCcm,
    reasonForVisitWithCcmOther: form.values.reasonForVisitWithCcmOther,
    reasonForVisitWithPcOther: form.values.reasonForVisitWithPcOther,
    reasonForReserveVisit: form.values.reasonForReserveVisit,
    isCustomDatetime: Boolean(hasCustomDatetime),
  }

  const isScheduledDateAllowed = checkIfScheduledDateIsAllowed()

  return (
    <Stack justify='space-between' w='100%' h='100%' sx={{ minHeight: '0px' }}>
      <Stack sx={{ overflow: 'hidden' }}>
        <ScheduleVisitHeader
          currentUser={currentUser}
          showTitle={showTitle}
          onCloseDrawer={onCloseDrawer}
        />
        <Box mx='md'>
          <DischargeAlert patient={selectedPatient} />
        </Box>
        <ScrollArea>
          <Stack px='md' pb='md'>
            {patient ? (
              <Select
                label='Select patient'
                placeholder={name({
                  first: patient.personalData.firstName,
                  last: patient.personalData.lastName,
                }).lastCommaFirst()}
                data={[]}
                disabled
              />
            ) : (
              <SelectPatient
                label='Select patient'
                placeholder='Search by patient name...'
                explanation='Search by full first name and/or last name'
                {...form.getInputProps('patientId')}
              />
            )}
            {latestAppointments?.length > 0 && (
              <AccordionHeader
                isSelected={latestAppointmentsAccordion}
                onClick={() => toggleLatestAppointmentsAccordion(!latestAppointmentsAccordion)}
                title='Latest visits'
              />
            )}
            {latestAppointmentsAccordion &&
              latestAppointments.map(appointment => (
                <LatestVisitCard key={appointment.oid} appointment={appointment} />
              ))}
            {autoNav > 0 && patient && (
              <Alert variant='primary' title={calculateWeekText(date)} icon={<InfoIcon />}>
                {getAutoNavAlertMessage({ datetime: date, patient, autoNav })}
              </Alert>
            )}
            {form.values.patientId && visitTypes && (
              <Stack spacing='xs'>
                <Select
                  label='Select visit type'
                  placeholder='Select a visit type...'
                  data={visitTypes}
                  warning={followUpReserveWarningMessage || occupancyRateWarningMessage}
                  disabled={Boolean(rescheduleAppointment) || slotsQuery.isLoading}
                  {...form.getInputProps('visitType')}
                />
                {isFollowUp && (
                  <Checkbox
                    label='Send UDS reminder'
                    {...form.getInputProps('shouldSendUdsReminder', { type: 'checkbox' })}
                  />
                )}
                {isFollowUpReserve && (
                  <TextInput
                    label={
                      <Tooltip
                        position='top'
                        multiline
                        label='Note if the patient no-showed, canceled late, is low on medication, unstable, or if the clinician has no other availability'
                      >
                        <Group spacing='xs'>
                          <Text>Reason for reserve visit</Text>
                          <InfoIcon />
                        </Group>
                      </Tooltip>
                    }
                    placeholder='Please be specific...'
                    {...form.getInputProps('reasonForReserveVisit')}
                  />
                )}
              </Stack>
            )}
            {showInsuranceFilterWarning && (
              <Alert variant='warning' icon={<ShieldIcon />}>
                Clinician credentials are disregarded in the slots shown since patient does not have
                insurance.
              </Alert>
            )}
            {form.values.patientId && form.values.visitType && acuityApointmentTypes && (
              <SelectEmployee
                value={employeeId}
                onChange={(employeeId: string) => {
                  const employee = employees.find(e => e.oid === employeeId)
                  setCalendarId(employee?.calendarId?.toString() || '')
                }}
                appointmentTypes={acuityApointmentTypes}
                appointmentTypeId={form.values.visitType}
                recommendedOptions={recommendedOptions}
                otherOptions={otherOptions}
                disabled={appointmentTypeQuery.isLoading || slotsQuery.isLoading}
                explanation={carePathway.data?.nextClinicianExplanation}
              />
            )}
            {shouldScheduleInitialBhcmVisit && (
              <Banner
                label={`Schedule ${patient?.personalData.firstName} for a ${initialBhcmVisitTypeName} with their CCM for improved billing`}
                type='warning'
              />
            )}
            {displayCcmTransitionSection && patient && (
              <CcmTransitionSection ccmTransitionForm={ccmTransitionForm} patient={patient} />
            )}
            {/* The `Reason for visit with CCM?` section is meant to be mutually exclusive from the `CcmTransitionSection` section */}
            {!displayCcmTransitionSection && askWhyPatientIsScheduledWithCcm && (
              <>
                <Select
                  label='Reason for visit with CCM?'
                  placeholder='Select a reason...'
                  data={reasonsForVisitWithCcm.map(reason => ({ label: reason, value: reason }))}
                  {...form.getInputProps('reasonForVisitWithCcm')}
                />
                {form.values.reasonForVisitWithCcm === 'Other' && (
                  <TextInput
                    label='Other reason'
                    placeholder='Enter other reason...'
                    {...form.getInputProps('reasonForVisitWithCcmOther')}
                  />
                )}
              </>
            )}
            {askWhyPatientIsScheduledWithPc && (
              <>
                <Select
                  label='Reason for visit with PC?'
                  placeholder='Select a reason...'
                  data={reasonsForVisitWithPc.map(reason => ({ label: reason, value: reason }))}
                  {...form.getInputProps('reasonForVisitWithPc')}
                />
                {form.values.reasonForVisitWithPc === 'No CCM availability' && (
                  <Alert variant='primary' icon={<InfoIcon />}>
                    This visit may be automatically rescheduled if a similar slot becomes available
                    on CCM calendar.
                  </Alert>
                )}
                {form.values.reasonForVisitWithPc === 'Other' && (
                  <TextInput
                    label='Other reason'
                    placeholder='Enter other reason...'
                    {...form.getInputProps('reasonForVisitWithPcOther')}
                  />
                )}
              </>
            )}
            {rescheduleAppointment && (
              <Alert variant='primary' icon={<InfoIcon />} title='Reschedule'>
                {`Select a new time for ${patient?.personalData.firstName} ${patient?.personalData
                  .lastName}’s visit currently scheduled for ${dayjs(
                  rescheduleAppointment.datetime,
                ).format('MM/DD/YYYY')} at ${dayjs(rescheduleAppointment.datetime).format(
                  'h:mma z',
                )}`}
              </Alert>
            )}
            {canCustomSchedule && (
              <AccordionHeader
                isSelected={isCustomTimeAccordionOpen}
                onClick={() => setCustomTimeAccordion(!isCustomTimeAccordionOpen)}
                title='Custom time'
              />
            )}
            {canCustomSchedule && isCustomTimeAccordionOpen && (
              <Stack>
                <DatePicker
                  /**
                   * CCs need to be able to schedule an appointment up
                   * to 3 days in the past so PCs can add visit notes in
                   * the event an attended appointment is accidentally
                   * canceled.
                   */
                  minDate={dayjs().subtract(3, 'days').toDate()}
                  /**
                   * We don't allow scheduling more than 60 days
                   * in the future.
                   */
                  maxDate={dayjs().add(60, 'days').toDate()}
                  clearable
                  label='Schedule a date for the visit'
                  {...form.getInputProps('customDate')}
                  placeholder='Select date...'
                  onChange={value => {
                    // clear time on date change
                    if (form.values.customTime) {
                      if (value) {
                        form.setFieldValue('customDate', dayjs(value).format('YYYY-MM-DD'))
                      } else {
                        form.setFieldValue('customDate', value)
                      }
                      form.setFieldValue('customTime', '')
                      return
                    }
                    if (form.values.selectedTime) {
                      if (value) {
                        form.setFieldValue('customDate', dayjs(value).format('YYYY-MM-DD'))
                        form.getInputProps('customDate').onChange(dayjs(value).format('YYYY-MM-DD'))
                      } else {
                        form.setFieldValue('customDate', value)
                      }
                      form.setFieldValue('selectedTime', '')
                      return
                    }
                    if (!value) {
                      form.setFieldValue('customTime', '')
                      form.setFieldValue('selectedTime', '')
                      form.setFieldValue('customDate', value)
                      return
                    }
                    form.setFieldValue('customDate', dayjs(value).format('YYYY-MM-DD'))
                  }}
                  onBlur={evt => {
                    if (!evt.target.value) {
                      setDate(dayjs().format('YYYY-MM-DD'))
                      setCustomSlot([])
                      return
                    }

                    const dateValue = dayjs(evt.target.value)

                    if (!form.values.customTime) {
                      setDate(dateValue.format('YYYY-MM-DD'))
                      setCustomSlot([])
                      return
                    }

                    setDate(dayjs(dateValue).format('YYYY-MM-DD'))
                  }}
                />
                <TimeInput
                  icon={<ClockIcon />}
                  label='Select time'
                  {...form.getInputProps('customTime')}
                />
              </Stack>
            )}
            {(isAdmin(currentUser) || isCareCoordinator(currentUser)) && (
              <Accordion>
                <Accordion.Item value='availability'>
                  <Accordion.Control px='0'>
                    <Group position='left' spacing='sm'>
                      <Text bold>Availability</Text>
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Box
                      style={{
                        // The accordion panel adds a different word-break style that we need to override
                        wordBreak: 'keep-all',
                      }}
                    >
                      <PatientAvailabilityForm form={patientAvailabilityForm} />
                    </Box>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            <Divider />
            {(form.values.selectedTime || hasCustomDatetime) && (
              <Stack spacing='sm'>
                <Stack
                  p='sm'
                  spacing='sm'
                  sx={theme => {
                    const styles: Sx = {
                      background: theme.other.colors.background[2],
                      borderColor: theme.other.colors.error[0],
                    }

                    if (!isScheduledDateAllowed.result) {
                      styles.borderWidth = theme.other.sizes.border.md
                      styles.borderRadius = theme.radius.sm
                    }
                    return styles
                  }}
                >
                  <Group position='left' spacing='sm'>
                    <CheckCircleIcon color={colors => colors.success[0]} />
                    <Text>
                      {dayjs(form.values.selectedTime || dateAndTimeString).format(
                        'MM/DD/YYYY | h:mma',
                      )}
                    </Text>
                  </Group>
                  <Text>{calculateWeekText(form.values.selectedTime || dateAndTimeString)}</Text>
                </Stack>
                {!isScheduledDateAllowed.result && (
                  <Text color={colors => colors.error[0]} size='xs'>
                    {isScheduledDateAllowed.reason}
                  </Text>
                )}
              </Stack>
            )}
          </Stack>
        </ScrollArea>
      </Stack>
      <Group position='right' px='md'>
        <PrimaryButton
          disabled={
            (!form.values.selectedTime && !hasCustomDatetime) ||
            !isScheduledDateAllowed.result ||
            selectedPatientQuery.isLoading ||
            Boolean(followUpReserveWarningMessage) ||
            Boolean(occupancyRateWarningMessage) ||
            Boolean(
              // Must provide a reason for reserve visit if scheduling a reserve visit
              isFollowUpReserve && !form.values.reasonForReserveVisit,
            )
          }
          loading={createAppointmentMutation.isLoading || rescheduleAppointmentMutation.isLoading}
          onClick={() => {
            submitPatientAvailability()

            if (displayCcmTransitionSection) {
              if (ccmTransitionForm.validate().hasErrors) {
                return
              }

              updatePatientMutation.mutate({
                params: {
                  patientId: patient?.oid || '',
                },
                data: {
                  ccmTransition: { ...ccmTransitionForm.values, updatedAt: dayjs().toISOString() },
                },
              })
            }

            if (rescheduleAppointment) {
              if (form.values.customDate && form.values.customTime) {
                const customDatetime = dayjs(dateAndTimeString).toISOString()

                rescheduleAppointmentMutation.mutate(
                  {
                    params: {
                      patientId: rescheduleAppointment.userId,
                      appointmentId: rescheduleAppointment.oid,
                    },
                    data: {
                      calendarId: Number(calendarId),
                      datetime: customDatetime,
                      timezone: rescheduleAppointment.timezone,
                      metadata: appointmentMetadata,
                    },
                  },
                  {
                    onSuccess: () => {
                      void queryClient.invalidateQueries(patientAppointmentsKey)
                      onCloseDrawer()
                    },
                  },
                )
              } else {
                rescheduleAppointmentMutation.mutate(
                  {
                    params: {
                      patientId: rescheduleAppointment.userId,
                      appointmentId: rescheduleAppointment.oid,
                    },
                    data: {
                      calendarId: Number(calendarId),
                      datetime: form.values.selectedTime,
                      timezone: rescheduleAppointment.timezone,
                      metadata: appointmentMetadata,
                    },
                  },
                  {
                    onSuccess: () => {
                      void queryClient.invalidateQueries(patientAppointmentsKey)
                      onCloseDrawer()
                    },
                  },
                )
              }
            } else if (hasCustomDatetime) {
              const customDatetime = dayjs(dateAndTimeString).toISOString()
              createAppointmentMutation.mutate(
                {
                  patientId: form.values.patientId,
                  type: form.values.visitType,
                  calendarId: Number(calendarId),
                  datetime: customDatetime,
                  metadata: appointmentMetadata,
                  admin: true,
                },
                {
                  onSuccess: () => {
                    void queryClient.invalidateQueries(patientAppointmentsKey)
                    onCloseDrawer()
                  },
                },
              )
            } else if (form.values.selectedTime) {
              const selectedTimeslot = slotsQuery.data?.find(
                slot => slot.time === form.values.selectedTime,
              )

              createAppointmentMutation.mutate(
                {
                  patientId: form.values.patientId,
                  type: form.values.visitType,
                  calendarId: selectedTimeslot?.calendarIDs[0],
                  datetime: form.values.selectedTime,
                  metadata: appointmentMetadata,
                },
                {
                  onSuccess: () => {
                    void queryClient.invalidateQueries(patientAppointmentsKey)
                    onCloseDrawer()
                  },
                },
              )
            }
          }}
        >
          {rescheduleAppointment ? 'Confirm & reschedule visit' : 'Confirm & schedule visit'}
        </PrimaryButton>
      </Group>
    </Stack>
  )
}
