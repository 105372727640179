import { Skeleton, Table } from '@shared/components'
import range from 'lodash/range'
import { INVOICE_TABLE_COLUMNS } from './PInvoices'

const NUMBER_OF_LOADING_ROWS = 11

export const LoadingTable = () => {
  return (
    <Table striped>
      <tbody className='mantine'>
        {range(0, NUMBER_OF_LOADING_ROWS).map(i => (
          <tr key={i}>
            {INVOICE_TABLE_COLUMNS.map(column => (
              <td key={column.name}>
                <Skeleton height={20} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
