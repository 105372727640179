import { Tabs } from '@shared/components'
import { EMRTaskDecorated, Patient } from '@shared/types'
import { FixedDrawerScrollArea } from '../FixedDrawerScrollArea'
import { MessageLog } from '../communications/sms/MessageLog'
import { NotesLog } from './NotesLog'

export enum NotesTab {
  Notes = 'Notes',
  SMS = 'SMS',
}

type SMSNotesContentProps = {
  task?: EMRTaskDecorated
  taskPatient?: Patient
}

export const SMSNotesContent = ({ task, taskPatient }: SMSNotesContentProps) => {
  /*
   * A scrollbar within many divs in a fixed height drawer is very finicky,
   * so be cautious with the CSS
   */
  return (
    <Tabs
      defaultValue={NotesTab.Notes}
      keepMounted={false}
      style={{ height: '100%', overflow: 'hidden' }}
    >
      <Tabs.List
        sx={theme => ({
          paddingLeft: theme.spacing.md,
        })}
      >
        <Tabs.Tab value={NotesTab.Notes} px='md'>
          {NotesTab.Notes}
        </Tabs.Tab>
        <Tabs.Tab value={NotesTab.SMS} px='md' disabled={!taskPatient}>
          {NotesTab.SMS}
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={NotesTab.Notes} style={{ height: 'auto', overflow: 'hidden' }}>
        {task && (
          <FixedDrawerScrollArea>
            <NotesLog task={task} />
          </FixedDrawerScrollArea>
        )}
      </Tabs.Panel>
      <Tabs.Panel value={NotesTab.SMS} style={{ height: 'auto', overflow: 'hidden' }}>
        {taskPatient && <MessageLog patient={taskPatient} />}
      </Tabs.Panel>
    </Tabs>
  )
}
