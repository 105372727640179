import { Box, Group, Text } from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import { chartMetrics } from './ObjectiveMeasuresChartContainer'
import { determineFillAndStrokeColors } from './chartUtils'

type TotalScoreProps = {
  title: ObjectiveMeasuresTitle
  score: number
}

const TotalScore = ({ title, score }: TotalScoreProps) => {
  const { goodResultThreshold, badResultThreshold, higherResultIsBetter } = chartMetrics[title]

  return (
    <Group sx={({ other: { colors } }) => ({ backgroundColor: colors.background[1] })}>
      <Text bold>Total score</Text>
      <Box
        sx={({ other: { colors, sizes }, radius }) => {
          const { fillColor: borderColor, strokeColor: backgroundColor } =
            determineFillAndStrokeColors({
              value: score,
              goodResultThreshold,
              badResultThreshold,
              higherResultIsBetter,
              colors,
            })

          return {
            minWidth: sizes.icon.lg,
            height: sizes.icon.lg,
            borderWidth: sizes.border.md,
            borderRadius: radius.sm,
            textAlign: 'center',
            backgroundColor,
            borderColor,
          }
        }}
      >
        <Text bold>{score}</Text>
      </Box>
    </Group>
  )
}

export default TotalScore
