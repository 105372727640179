import { Drawer, Text } from '@shared/components'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { useEmrQuery } from '../../../utils/hooks'
import { LockedCaseReviewDrawer } from './LockedCaseReviewDrawer'
import { UnlockedCaseReviewDrawer } from './UnlockedCaseReviewDrawer'

export const CaseReviewDrawer = ({
  onClose,
  patientId,
}: {
  onClose: () => void
  patientId: string | null
}) => {
  const patientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId: patientId ?? '',
      },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const patientCocmItemQuery = useEmrQuery(
    'GET /caseReview/:patientId',
    {
      params: {
        patientId: patientId ?? '',
      },
    },
    { enabled: Boolean(patientId) },
  )

  const caseReviewNote = patientCocmItemQuery.data?.mostRecentCaseReviewNote

  const openLockedCaseReviewDrawer = Boolean(caseReviewNote?.isLocked) && Boolean(patientId)
  const openUnlockedCaseReviewDrawer = !caseReviewNote?.isLocked && Boolean(patientId)

  const isLoading = patientQuery.isLoading || patientCocmItemQuery.isLoading

  if (isLoading) {
    return (
      <Drawer
        title='Case review note'
        opened={isLoading && Boolean(patientId)}
        onClose={onClose}
        position='right'
        size='lg'
      >
        <ALoadingSpinner />
      </Drawer>
    )
  }

  if (!patientQuery.data || !patientCocmItemQuery.data || !caseReviewNote) {
    return (
      <Drawer
        title='Case review note'
        opened={Boolean(patientId)}
        onClose={onClose}
        position='right'
        size='lg'
      >
        <Text>Unable to load case review note</Text>
      </Drawer>
    )
  }

  return (
    <>
      <LockedCaseReviewDrawer
        showName
        opened={openLockedCaseReviewDrawer}
        onClose={onClose}
        caseReviewNote={caseReviewNote}
        patient={patientQuery.data}
      />
      <UnlockedCaseReviewDrawer
        showName
        opened={openUnlockedCaseReviewDrawer}
        onClose={onClose}
        patientCocmItem={patientCocmItemQuery.data}
        patientName={{
          firstName: patientQuery.data?.personalData?.firstName ?? '',
          lastName: patientQuery.data?.personalData?.lastName ?? '',
        }}
        caseReviewNote={caseReviewNote}
      />
    </>
  )
}
