import {
  Center,
  Divider,
  OpheliaWordmark,
  PrimaryButton,
  Space,
  Stack,
  Text,
  TitleTwo,
} from '@shared/components'

export const Fallback = () => {
  return (
    <Center sx={theme => ({ minHeight: '100vh', color: theme.white })}>
      <FallbackContent />
    </Center>
  )
}

const FallbackContent = () => {
  const onReload = () => {
    window.location.reload()
  }

  return (
    <Stack spacing='lg' p='lg'>
      <Center sx={theme => ({ color: theme.other.colors.actions[0], height: theme.spacing.xl })}>
        <OpheliaWordmark />
      </Center>
      <Center>
        <TitleTwo color='black'>Oops, something went wrong.</TitleTwo>
      </Center>
      <Divider />
      <Center>
        <Stack align='center' spacing='xs'>
          <Text>This error has been reported to our engineering team.</Text>
          <Text>We are working on resolving this as quickly as possible.</Text>
          <Space h='lg' />
          <Text>Pressing &quot;Reload page&quot; below may help your issue.</Text>
          <Text>Thank you for your patience.</Text>
        </Stack>
      </Center>
      <Center>
        <PrimaryButton onClick={onReload}>Reload page</PrimaryButton>
      </Center>
    </Stack>
  )
}
