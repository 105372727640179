import { Alert, InfoIcon, Radio, RadioGroup, Stack, TextInput } from '@shared/components'
import { prescribingClinicianPreferenceMapping } from '@shared/types'

import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const EligibleToReturnSection = ({
  form,
  optional,
}: {
  form: DischargeNoteForm
  optional: boolean
}) => {
  return (
    <Stack spacing='md'>
      <RadioGroup
        {...form.getInputProps('eligible_to_return')}
        label={formLabel('Eligible to return to Ophelia?', optional)}
        orientation='horizontal'
      >
        <Radio
          label='Yes'
          value='yes'
          sx={{
            flexGrow: 1,
          }}
        />
        <Radio
          label='No'
          value='no'
          sx={{
            flexGrow: 1,
          }}
        />
      </RadioGroup>
      {form.values.eligible_to_return === 'yes' && (
        <Stack>
          <RadioGroup
            {...form.getInputProps('prescribing_clinician_preference')}
            label={formLabel('Prescribing clinician preference', optional)}
            spacing='sm'
          >
            <Radio
              label={prescribingClinicianPreferenceMapping.same_provider}
              value='same_provider'
            />
            <Radio
              label={prescribingClinicianPreferenceMapping.any_provider}
              value='any_provider'
            />
            <Radio
              label={prescribingClinicianPreferenceMapping.different_provider}
              value='different_provider'
            />
          </RadioGroup>
          <Alert variant='secondary' icon={<InfoIcon />}>
            CCs will try to accomodate preferences, but choices are not guaranteed
          </Alert>
          <RadioGroup
            {...form.getInputProps('eligible_to_return_review_within_30_days')}
            label={formLabel(
              'Review with previous PC if patient re-enrolls <30 days after discharge?',
              optional,
            )}
            orientation='horizontal'
          >
            <Radio
              label='Yes'
              value='yes'
              sx={{
                flexGrow: 1,
              }}
            />
            <Radio
              label='No'
              value='no'
              sx={{
                flexGrow: 1,
              }}
            />
          </RadioGroup>
        </Stack>
      )}
      {form.values.eligible_to_return === 'no' && (
        <Stack>
          <Alert variant='secondary' icon={<InfoIcon />}>
            Patients not eligible should be reviewed with supervising clinicians
          </Alert>
          <TextInput
            placeholder='Additional details...'
            {...form.getInputProps('eligible_to_return_additional_details')}
          />
        </Stack>
      )}
    </Stack>
  )
}
