import React from 'react'
import ODSErrorIcon from './ODSErrorIcon'
import ODSRadioButton from './ODSRadioButton'

export type ODSYesNoRadioListProps = {
  label?: string
  disabled?: boolean
  error?: string
  direction?: 'horizontal' | 'vertical'
  yesValue?: string
  noValue?: string
}

const ODSYesNoRadioList = React.forwardRef<HTMLInputElement, ODSYesNoRadioListProps>(
  (
    {
      label,
      disabled = false,
      error,
      direction = 'vertical',
      yesValue = 'yes',
      noValue = 'no',
      ...props
    },
    ref,
  ) => (
    <div className={`w-full ${direction === 'horizontal' && 'flex flex-row mt-3'}`}>
      {label && (
        <div>
          <p className={`${disabled && 'text-gray-400'} ${direction === 'horizontal' && 'mr-5'}`}>
            {label}
          </p>
        </div>
      )}
      <div className={`flex ${label && direction === 'vertical' && 'mt-1 ml-2'}`}>
        <ODSRadioButton
          buttonLabel='Yes'
          buttonValue={yesValue}
          disabled={disabled}
          ref={ref}
          {...props}
        />
      </div>
      <div className={`flex ${direction === 'horizontal' ? 'ml-5' : 'mt-2'} ${label && 'ml-2'}`}>
        <ODSRadioButton
          buttonLabel='No'
          buttonValue={noValue}
          disabled={disabled}
          ref={ref}
          {...props}
        />
      </div>
      {error && (
        <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
          <ODSErrorIcon />
          {error}
        </p>
      )}
    </div>
  ),
)

ODSYesNoRadioList.displayName = 'ODSYesNoRadioList'

export default ODSYesNoRadioList
