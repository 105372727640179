import React from 'react'

export type ANoteHeaderProps = {
  className?: string
  text?: string
  description?: string
}

const ANoteHeader = ({ text, className = '' }: ANoteHeaderProps) => {
  return <h3 className={`${className} text-lg leading-6 font-medium text-gray-900`}>{text}</h3>
}

export default ANoteHeader
