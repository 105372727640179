import { Grid, Group, Stack } from '@shared/components'
import { BarriersToTreatment } from '@shared/types'
import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteCheckbox } from '../visitNoteForm/PatientVisitNoteCheckbox'
import { PatientVisitNoteSwitch } from '../visitNoteForm/PatientVisitNoteSwitch'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const BarrierToTreatmentCheckboxSection = ({
  barrierKey,
  label,
  form,
}: {
  barrierKey: keyof BarriersToTreatment
  label: string
  form: OnboardingVisitNoteForm
}) => {
  return (
    <Stack>
      <PatientVisitNoteCheckbox
        w='100%'
        label={label}
        checked={form.values[barrierKey] === 'yes'}
        {...form.getInputProps(barrierKey)}
        onChange={e => {
          const newValue = e.currentTarget.checked ? 'yes' : 'no'
          form.setFieldValue(barrierKey, newValue)

          if (newValue === 'no') {
            form.setFieldValue('primary_barrier_to_treatment', undefined)
          }

          if (newValue === 'yes' && !form.values.primary_barrier_to_treatment) {
            form.setFieldValue('primary_barrier_to_treatment', barrierKey)
          }
        }}
      />

      {form.values?.[barrierKey] === 'yes' && (
        <Grid align='center'>
          <Grid.Col span={9}>
            <PatientVisitNoteTextInput
              placeholder='Additional details...'
              {...form.getInputProps(`${barrierKey}_additional_details`)}
            />
          </Grid.Col>
          <Grid.Col span={3} sx={{ alignItems: 'end' }}>
            <Group w='100%' position='right'>
              <PatientVisitNoteSwitch
                size='sm'
                label='Primary'
                checked={form.values.primary_barrier_to_treatment === barrierKey}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    form.setFieldValue('primary_barrier_to_treatment', barrierKey)
                  } else {
                    form.setFieldValue('primary_barrier_to_treatment', undefined)
                  }
                }}
              />
            </Group>
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  )
}
