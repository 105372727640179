import { BaseApi, BaseApiConfig, toTime } from '@shared/utils'
import { User } from 'firebase/auth'
import { analytics } from '../analytics'
import * as FullStory from '../utils/fullstory'

export class FirebaseAuthApi extends BaseApi {
  authTokenTtl: number
  issuedAtTime: number | null
  firebaseUser: User | null

  constructor(config: BaseApiConfig) {
    super(config)
    this.authTokenTtl = toTime('1 min').ms()
    this.firebaseUser = null
    this.issuedAtTime = null
    this.axiosInstance.interceptors.request.use(async config => {
      const token = await this.getIdToken()

      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })
  }

  getIdToken = async () => {
    let forceRefresh = false

    if (this.issuedAtTime) {
      forceRefresh = new Date().getTime() - this.issuedAtTime > this.authTokenTtl
    }

    const idTokenResult = await this.firebaseUser?.getIdTokenResult(forceRefresh)

    if (!idTokenResult) {
      return null
    }

    this.issuedAtTime = new Date(idTokenResult.issuedAtTime).getTime()
    return idTokenResult.token
  }

  setUser = (firebaseUser: User | null) => {
    if (firebaseUser) {
      const userContext: { id: string; email?: string; displayName?: string } = {
        id: firebaseUser.uid,
      }

      if (firebaseUser.email) {
        userContext.email = firebaseUser.email
      }

      if (firebaseUser.displayName) {
        userContext.displayName = firebaseUser.displayName
      }

      FullStory.identify(firebaseUser.uid)
      FullStory.setUserVars(userContext)
      analytics.identify(firebaseUser.uid)
    }

    this.firebaseUser = firebaseUser
  }
}
