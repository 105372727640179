import { ArchiveIcon, Card, Group, Stack, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
export type ManuallyRecordedMedication = {
  updatedAt?: string
  medication_name: string
  medication_strength: string
  medication_quantity: string
  medication_days_supply: string
}

export type ManuallyRecordedMedicationRowProps = {
  medication: ManuallyRecordedMedication
}

const getMedicationAsString = (medication: ManuallyRecordedMedication) => {
  const lastDash = medication.medication_name.lastIndexOf('-')
  const dispenseUnit = `${medication.medication_name.slice(lastDash + 1)?.split(')')?.[0]}s`
  const updatedAtString = medication.updatedAt
    ? `${dayjs(medication.updatedAt).format('MM/DD/YYYY')}`
    : ''
  const medicationNameString = ` | ${medication.medication_name}`
  const medicationStrengthString = medication.medication_strength
    ? ` | ${medication.medication_strength}`
    : ''
  const medicationQuantityString = medication.medication_quantity
    ? ` - ${medication.medication_quantity} ${dispenseUnit || 'units'}`
    : ''
  const medicationDaysSupplyString = medication.medication_days_supply
    ? ` - ${medication.medication_days_supply} days`
    : ''
  return `${updatedAtString}${medicationNameString}${medicationStrengthString}${medicationQuantityString}${medicationDaysSupplyString}`
}

const ManuallyRecordedMedicationRow = ({ medication }: ManuallyRecordedMedicationRowProps) => (
  <Card styles={({ background }) => ({ backgroundColor: background[1] })}>
    <Stack pt={4} pl={6} pr={10} pb={3} spacing='sm'>
      <Group spacing='sm'>
        <ArchiveIcon />
        <Text bold>Manual entry</Text>
      </Group>
      <Group>
        <Text>{getMedicationAsString(medication)}</Text>
      </Group>
    </Stack>
  </Card>
)

export default ManuallyRecordedMedicationRow
