import { ReenrollmentCallVisitNoteForm } from '../ReenrollmentCallVisitNote'
import { PatientVisitIssueButton } from '../visitNoteForm/PatientVisitIssueButton'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'
import { PatientVisitNoteTextarea } from '../visitNoteForm/PatientVisitNoteTextarea'

export const BalanceSection = ({ form }: { form: ReenrollmentCallVisitNoteForm }) => {
  return (
    <PatientVisitNoteSection title='Balance'>
      <PatientVisitNoteSelect
        label="What is the payment status of the patient's outstanding balance?"
        placeholder='Select one...'
        data={[
          'Fully paid off',
          'Half paid off',
          'Not yet paid',
          'No outstanding balance',
          'Other',
        ]}
        {...form?.getInputProps('outstanding_balance')}
      />
      <PatientVisitNoteTextarea
        label='Other relevant information (optional)'
        placeholder='Type here...'
        {...form?.getInputProps('outstanding_balance_additional_details')}
      />
      <PatientVisitNoteNotDiscussedCheckbox field='outstanding_balance' form={form} />
      <PatientVisitIssueButton issueName='Outstanding balance' issueType='outstanding_balance' />
    </PatientVisitNoteSection>
  )
}
