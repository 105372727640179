import { useToggle } from '@mantine/hooks'
import {
  AlertIcon,
  CheckCircleIcon,
  DollarSignIcon,
  DownloadIcon,
  EditIcon,
  ExternalLinkText,
  Group,
  Menu,
  MinusCircleIcon,
  MoreVerticalIcon,
  PlusCircleIcon,
  RotateCwwIcon,
  SecondaryButton,
  Skeleton,
  SlashIcon,
  Stack,
  TertiaryButton,
  Text,
  Tooltip,
  XCircleIcon,
  useMantineTheme,
} from '@shared/components'
import {
  EmrApi,
  InvoiceListItem,
  InvoicePaymentStatus,
  InvoicePaymentStatusMapping,
  getOpheliaHttpError,
} from '@shared/types'
import { dayjs, formatDollarAmount } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'
import { Config } from '../../../config'
import { useEmrMutation, useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { downloadFileFromUrl } from '../files/utils'
import { AddInvoicePaymentDrawer } from './AddInvoicePaymentDrawer'
import { AddInvoiceTypeModal } from './AddInvoiceTypeModal'
import { INVOICE_TABLE_COLUMNS } from './PInvoices'
import { VoidInvoiceDrawer } from './VoidInvoiceDrawer'

export const getStripeDashboardUrl = ({
  pathname,
  search,
}: {
  pathname: `/${string}`
  search?: Record<string, string>
}) => {
  const url = new URL(
    Config.ENV === 'production' ? pathname : `test${pathname}`,
    'https://dashboard.stripe.com',
  )

  if (search) {
    url.search = new URLSearchParams(search).toString()
  }

  return url
}

const InvoiceStatusIcon = ({ paymentStatus }: { paymentStatus: InvoicePaymentStatus }) => {
  switch (paymentStatus) {
    case 'paid':
      return <CheckCircleIcon color={colors => colors.success[0]} />
    case 'partiallyPaid':
    case 'unpaid':
      return <AlertIcon color={colors => colors.warning[0]} />
    case 'pastDue':
      return <AlertIcon color={colors => colors.error[0]} />
    default:
      return <XCircleIcon color={colors => colors.background[3]} />
  }
}

type PaymentStatus = 'succeeded' | 'failed' | 'pending' | 'refunded' | 'partial refund' | 'unknown'

const PaymentStatusIcon = ({ status }: { status: PaymentStatus }) => {
  switch (status) {
    case 'succeeded':
      return <CheckCircleIcon color={colors => colors.success[0]} />
    case 'pending':
      return <AlertIcon color={colors => colors.warning[0]} />
    case 'failed':
      return <AlertIcon color={colors => colors.error[0]} />
    case 'refunded':
      return <RotateCwwIcon color={colors => colors.actions[2]} />
    case 'partial refund':
      return <RotateCwwIcon color={colors => colors.actions[2]} />
    default:
      return <XCircleIcon color={colors => colors.background[3]} />
  }
}

const InvoiceTableData = ({
  invoice,
  onTogglePayments,
  isShowingPayments,
}: {
  invoice: InvoiceListItem
  onTogglePayments: () => void
  isShowingPayments: boolean
}) => {
  const [isVoidInvoiceDrawerOpened, setVoidInvoiceDrawerOpened] = useState(false)
  const [isAddInvoicePaymentDrawerOpened, setAddInvoicePaymentDrawerOpened] = useState(false)
  const [isUpdateInvoiceTypeModalOpened, setUpdateInvoiceTypeModalOpened] = useState(false)
  const { patientId } = usePatient()
  const createInvoicePdf = useEmrMutation('POST /invoices/pdf')

  return (
    <>
      {INVOICE_TABLE_COLUMNS.map(column => {
        const { name } = column
        switch (name) {
          case 'Toggle row': {
            const hasPayments = (invoice.stripePaymentIntents || []).length > 0
            return (
              <td key={name}>
                {hasPayments && (
                  <Stack align='center'>
                    <TertiaryButton
                      onClick={() => onTogglePayments()}
                      leftIcon={
                        isShowingPayments ? <MinusCircleIcon styled /> : <PlusCircleIcon styled />
                      }
                    />
                  </Stack>
                )}
              </td>
            )
          }

          case 'ID': {
            // Note: we will only link to Stripe invoices, so display Chargebee invoice IDs as `Text`
            if (invoice.platform === 'chargebee') {
              return (
                <td key={name}>
                  <Text>{`CB_${invoice.externalId}`}</Text>
                </td>
              )
            }

            const NUM_CHARACTERS_TO_DISPLAY = 5

            return (
              <td key={name}>
                <ExternalLinkText
                  href={getStripeDashboardUrl({ pathname: `/invoices/${invoice.externalId}` }).href}
                >
                  <TertiaryButton>
                    {`...${invoice.externalId.slice(-NUM_CHARACTERS_TO_DISPLAY)}`}
                  </TertiaryButton>
                </ExternalLinkText>
              </td>
            )
          }
          case 'Description':
            return (
              <td key={name}>
                <Text bold>{invoice.description}</Text>
              </td>
            )
          case 'Status':
            return (
              <td key={name}>
                <Group spacing='xs'>
                  <InvoiceStatusIcon paymentStatus={invoice.paymentStatus} />
                  <Text>{InvoicePaymentStatusMapping[invoice.paymentStatus]}</Text>
                </Group>
              </td>
            )
          case 'Date':
            return (
              <td key={name}>
                <Text>{dayjs(invoice.invoiceDate).format('MM/DD/YYYY')}</Text>
              </td>
            )
          case 'Due': {
            const invoiceStatusesWithoutDueDates: InvoicePaymentStatus[] = ['void', 'writtenOff']
            if (
              !invoice.dueDate ||
              invoiceStatusesWithoutDueDates.includes(invoice.paymentStatus)
            ) {
              return <td key={name}></td>
            }
            return (
              <td key={name}>
                <Text>{dayjs(invoice.dueDate).format('MM/DD/YYYY')}</Text>
              </td>
            )
          }
          case 'Amount':
            return (
              <td key={name}>
                <Text>
                  {formatDollarAmount({
                    amount: invoice.totalInCents,
                    unit: 'cents',
                  })}
                </Text>
              </td>
            )
          case 'Balance': {
            const invoiceStatusesWithoutAmountDue: InvoicePaymentStatus[] = [
              'void',
              'writtenOff',
              'paid',
            ]

            const balance = invoiceStatusesWithoutAmountDue.includes(invoice.paymentStatus)
              ? 0
              : invoice.amountRemaining
            return (
              <td key={name}>
                <Text>{formatDollarAmount({ amount: balance })}</Text>
              </td>
            )
          }
          case 'Actions':
            return (
              <td key={name}>
                <Stack align='center'>
                  <Menu position='bottom-end'>
                    <Menu.Target>
                      <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        onClick={() => {
                          createInvoicePdf.mutate(
                            {
                              data: {
                                patientId,
                                invoiceId: invoice.oid,
                              },
                            },
                            {
                              onSuccess: file => {
                                downloadFileFromUrl(file)
                              },
                            },
                          )
                        }}
                      >
                        <Group spacing='xs'>
                          <DownloadIcon />
                          <Text>Download invoice</Text>
                        </Group>
                      </Menu.Item>
                      {invoice.paymentStatus !== 'paid' && invoice.platform === 'stripe' && (
                        <Menu.Item onClick={() => setVoidInvoiceDrawerOpened(true)}>
                          <Group spacing='xs'>
                            <SlashIcon />
                            <Text>Void invoice</Text>
                          </Group>
                        </Menu.Item>
                      )}
                      {/* if invoice is not paid yet, provide option to make payment
                       furthermore, only allow payment on stripe-based invoices, as that is the only possible gateway!  */}
                      {invoice.paymentStatus !== 'paid' && invoice.platform === 'stripe' && (
                        <Menu.Item onClick={() => setAddInvoicePaymentDrawerOpened(true)}>
                          <Group spacing='xs'>
                            <PlusCircleIcon />
                            <Text>Add payment</Text>
                          </Group>
                        </Menu.Item>
                      )}
                      {/* If the invoice is from Stripe and does not have a type, allow FCs to add a type */}
                      {invoice.platform === 'stripe' && !invoice.type && (
                        <Menu.Item onClick={() => setUpdateInvoiceTypeModalOpened(true)}>
                          <Group spacing='xs'>
                            <EditIcon />
                            <Text>Add type</Text>
                          </Group>
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Stack>
              </td>
            )

          default:
            return <td key={name}></td>
        }
      })}
      <AddInvoicePaymentDrawer
        invoice={invoice}
        opened={isAddInvoicePaymentDrawerOpened}
        onClose={() => setAddInvoicePaymentDrawerOpened(false)}
      />
      <VoidInvoiceDrawer
        invoice={invoice}
        opened={isVoidInvoiceDrawerOpened}
        onClose={() => setVoidInvoiceDrawerOpened(false)}
      />
      <AddInvoiceTypeModal
        invoice={invoice}
        opened={isUpdateInvoiceTypeModalOpened}
        onClose={() => setUpdateInvoiceTypeModalOpened(false)}
      />
    </>
  )
}

const PaymentTableData = ({
  payment,
}: {
  payment: EmrApi['GET /invoices/:invoiceId/payments']['res'][number]
}) => {
  return (
    <>
      {INVOICE_TABLE_COLUMNS.map(column => {
        const { name } = column

        switch (name) {
          case 'Toggle row': {
            return <td key={name} style={{ border: 'unset' }}></td>
          }
          case 'ID': {
            return <td key={name} style={{ border: 'unset' }}></td>
          }
          case 'Description': {
            const brand = payment.latestCharge?.paymentMethodDetails.card?.brand || 'n/a'
            const last4 = payment.latestCharge?.paymentMethodDetails.card?.last4 || 'n/a'
            return (
              <td key={name} style={{ border: 'unset' }}>
                <Text>{`Payment ${capitalize(brand)} *${last4}`}</Text>
              </td>
            )
          }
          case 'Status': {
            let paymentStatus: PaymentStatus = payment.latestCharge?.status || 'unknown'
            const amountRefunded = payment.latestCharge?.amountRefundedInCents || 0

            if (amountRefunded > 0) {
              if (amountRefunded === payment.latestCharge?.amountInCents) {
                paymentStatus = 'refunded'
              } else {
                paymentStatus = 'partial refund'
              }
            }

            return (
              <td key={name} style={{ border: 'unset' }}>
                <Tooltip
                  label={`Amount refunded ${formatDollarAmount({
                    amount: amountRefunded,
                    unit: 'cents',
                  })}`}
                  disabled={
                    // only display tooltip if there is a partial refund
                    paymentStatus !== 'partial refund'
                  }
                >
                  <Group spacing='xs'>
                    <PaymentStatusIcon status={paymentStatus} />
                    <Text>{capitalize(paymentStatus)}</Text>
                  </Group>
                </Tooltip>
              </td>
            )
          }
          case 'Date': {
            return (
              <td key={name} style={{ border: 'unset' }}>
                <Text>{dayjs(payment.latestCharge?.createdAt).format('MM/DD/YYYY')}</Text>
              </td>
            )
          }
          case 'Due': {
            return <td key={name} style={{ border: 'unset' }}></td>
          }
          case 'Amount': {
            return (
              <td key={name} style={{ border: 'unset' }}>
                <Text>
                  {formatDollarAmount({
                    amount: payment.latestCharge?.amountInCents || 0,
                    unit: 'cents',
                  })}
                </Text>
              </td>
            )
          }
          case 'Balance': {
            return <td key={name} style={{ border: 'unset' }}></td>
          }
          case 'Actions': {
            return (
              <td key={name} style={{ border: 'unset' }}>
                <Stack align='center'>
                  <Menu position='bottom-end'>
                    <Menu.Target>
                      <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        onClick={() => {
                          const url = getStripeDashboardUrl({
                            pathname: `/payments/${payment.paymentIntentId}`,
                          })
                          window.open(url.href, '_blank')
                        }}
                      >
                        <Group spacing='xs'>
                          <DollarSignIcon />
                          <Text>View in Stripe</Text>
                        </Group>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Stack>
              </td>
            )
          }
          default: {
            return <td key={name} style={{ border: 'unset' }}></td>
          }
        }
      })}
    </>
  )
}

export const InvoiceRow = ({
  invoice,
  isFilled,
}: {
  invoice: InvoiceListItem
  isFilled: boolean
}) => {
  const [showPayments, togglePayments] = useToggle()
  const theme = useMantineTheme()

  const paymentsQuery = useEmrQuery(
    'GET /invoices/:invoiceId/payments',
    {
      params: {
        invoiceId: invoice.oid,
      },
    },
    {
      enabled: showPayments,
    },
  )

  const payments = (paymentsQuery.data || []).filter(payment => {
    /*
     * Filter out payments that were created but not yet attempted.
     * The toggle will be enabled for showing payments in these cases,
     * however, we do not have any data to show for these payments.
     */
    return Boolean(payment.latestCharge)
  })

  const hasAttemptedPayments = payments.length > 0

  const isManuallyMarkedAsPaid = invoice.paymentStatus === 'paid' && invoice.amountRemaining > 0

  const { isLoading, isError } = paymentsQuery

  const backgroundColor = isFilled ? theme.other.colors.background[1] : 'unset'

  if (showPayments) {
    return (
      <>
        <tr style={{ backgroundColor }}>
          <InvoiceTableData
            invoice={invoice}
            onTogglePayments={togglePayments}
            isShowingPayments={showPayments}
          />
        </tr>
        {/* loading state */}
        {isLoading && (
          <tr style={{ backgroundColor }}>
            <td colSpan={INVOICE_TABLE_COLUMNS.length} style={{ border: 'unset' }}>
              <Skeleton height={20} />
            </td>
          </tr>
        )}
        {/* error state */}
        {!isLoading && isError && (
          <tr style={{ backgroundColor }}>
            <td colSpan={INVOICE_TABLE_COLUMNS.length} style={{ border: 'unset' }}>
              <Text color={c => c.error[0]}>
                {getOpheliaHttpError(paymentsQuery.error, 'Unable to retrieve payment data')}
              </Text>
            </td>
          </tr>
        )}
        {/* empty state */}
        {!isLoading && !isError && !hasAttemptedPayments && !isManuallyMarkedAsPaid && (
          <tr style={{ backgroundColor }}>
            <td colSpan={INVOICE_TABLE_COLUMNS.length} style={{ border: 'unset' }}>
              <Text>Payment was created but not yet attempted</Text>
            </td>
          </tr>
        )}
        {/* empty state - paid externally */}
        {!isLoading && !isError && isManuallyMarkedAsPaid && (
          <tr style={{ backgroundColor }}>
            <td colSpan={INVOICE_TABLE_COLUMNS.length} style={{ border: 'unset' }}>
              <Text>Invoice was manually marked as &apos;Paid&apos; in Stripe</Text>
            </td>
          </tr>
        )}
        {/* active state */}
        {!isLoading &&
          !isError &&
          hasAttemptedPayments &&
          payments.map(payment => {
            return (
              <tr key={payment.paymentIntentId} style={{ backgroundColor }}>
                <PaymentTableData payment={payment} />
              </tr>
            )
          })}
      </>
    )
  }

  return (
    <tr style={{ backgroundColor }}>
      <InvoiceTableData
        invoice={invoice}
        onTogglePayments={togglePayments}
        isShowingPayments={showPayments}
      />
    </tr>
  )
}
