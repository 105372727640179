import { useQuery } from 'react-query'
import { usePatient } from '../PPatientContext'
import { emrApi } from '../../../api'

export const useValidatePatientAuth = () => {
  const { patientQuery } = usePatient()

  return useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/validate-auth', {
      params: {
        patientId: patientQuery?.data?.oid as string,
      },
      query: {
        phone: patientQuery?.data?.personalData.phone as string,
        email: patientQuery?.data?.personalData.email as string,
      },
    }),
    { enabled: Boolean(patientQuery?.data) },
  )
}
