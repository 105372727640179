import { useForm } from 'react-hook-form'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'
import { useAuth } from '../../context/auth'
import { useEmrMutation } from '../../utils/hooks'
import MIneligibleNote from './visits/MIneligibleNote'

export type OIneligibleNoteModalProps = {
  closeModal: () => void
  patientID: string
}

const OIneligibleNoteModal = ({ patientID }: OIneligibleNoteModalProps) => {
  const { register, handleSubmit, watch } = useForm()

  const { currentUser } = useAuth()

  const createIneligibleNote = useEmrMutation('POST /ineligibleNotes')

  const submit = async (data: Record<string, string | boolean>) => {
    const note = {
      ...data,
      patientId: patientID,
      employeeId: currentUser.oid,
      timestamp: new Date().toISOString(),
    }

    await createIneligibleNote.mutateAsync({
      data: note,
    })

    window.location.reload()
  }

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(submit)}>
        <div className='flex flex-col'>
          <div className='sticky top-0 bg-white w-full z-30 py-6 border-b border-gray-200'>
            <div className='flex justify-between items-center'>
              <div className='flex flex-col justify-start'>
                <p className='text-xl leading-4 w-full text-left mb-4'>Hold on!</p>
                <p className='text-gray-500 text-sm text-left'>
                  We need a few more details about why this patient was ineligible.
                </p>
              </div>
              <div className='flex space-x-2 h-3/4'>
                <ODSPrimaryButton
                  message='Submit'
                  type='submit'
                  loading={createIneligibleNote.isLoading}
                />
              </div>
            </div>
          </div>
          <MIneligibleNote watch={watch} register={register} />
        </div>
      </form>
    </div>
  )
}

export default OIneligibleNoteModal
