import { useToggle } from '@mantine/hooks'
import {
  Box,
  ButtonGroup,
  Image,
  PrimaryButton,
  Skeleton,
  useMantineTheme,
} from '@shared/components'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { patientsApi } from '../../../api'

const useGetFile = ({
  patientId,
  fileId,
}: {
  patientId: string
  fileId: string | undefined | null
}) => {
  const { data: file, isLoading: isFileLoading } = useQuery(
    ['patientsApi.getFile', fileId],
    () => patientsApi.getFile({ patientId, fileId: fileId || '' }),
    { enabled: Boolean(fileId) },
  )

  return {
    url: file?.signedUrl,
    isFileLoading,
  }
}

const IRQCardViewer = ({
  patientId,
  frontFileId,
  backFileId,
}: {
  patientId: string
  frontFileId: string | undefined | null
  backFileId: string | undefined | null
}) => {
  const [currentSide, toggle] = useToggle<'front' | 'back'>(['front', 'back'])

  useEffect(() => {
    toggle('front')
  }, [patientId])

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const { url: frontPhotoUrl, isFileLoading: isFrontFileLoading } = useGetFile({
    patientId,
    fileId: frontFileId,
  })

  const { url: backPhotoUrl, isFileLoading: isBackFileLoading } = useGetFile({
    patientId,
    fileId: backFileId,
  })

  if (isFrontFileLoading || isBackFileLoading) {
    return <Skeleton height='90%' width='90%' radius='sm' />
  }

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.colors.dark[1],
        borderRadius: theme.radius.md,
        padding: theme.spacing.lg,
        position: 'relative',
      })}
    >
      {!frontPhotoUrl && !backPhotoUrl ? (
        'No photos found'
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: sizes.padding.xl,
            right: sizes.padding.xl,
            'z-index': 1,
          }}
        >
          <ButtonGroup>
            <PrimaryButton
              style={{
                backgroundColor: currentSide === 'front' ? colors.actions[0] : colors.background[3],
              }}
              onClick={() => toggle()}
            >
              Front
            </PrimaryButton>
            <PrimaryButton
              style={{
                backgroundColor: currentSide === 'back' ? colors.actions[0] : colors.background[3],
              }}
              onClick={() => toggle()}
            >
              Back
            </PrimaryButton>
          </ButtonGroup>
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image
          sx={{
            maxWidth: '800px',
            display: currentSide === 'front' ? 'block' : 'none',
          }}
          styles={{ image: { maxHeight: '600px' } }}
          fit='contain'
          src={frontPhotoUrl}
        />
        <Image
          sx={{
            maxWidth: '800px',
            display: currentSide === 'back' ? 'block' : 'none',
          }}
          styles={{ image: { maxHeight: '600px' } }}
          fit='contain'
          src={backPhotoUrl}
        />
      </Box>
    </Box>
  )
}

export default IRQCardViewer
