import { PlusIcon, PrimaryButton } from '@shared/components'
import { TaskType } from '@shared/types'
import { useSidePane } from '../../../../../utils/hooks/use-side-pane'
import { IssueDrawer } from '../../../../care_team/tasks/IssueDrawer'
import { usePatient } from '../../../PPatientContext'
import { usePatientVisitNote } from '../PatientVisitNoteContext'

type PatientVisitIssueButtonProps = {
  issueName: string
  issueType: TaskType
}

export const PatientVisitIssueButton = ({ issueName, issueType }: PatientVisitIssueButtonProps) => {
  const { canEdit } = usePatientVisitNote()
  const { presentPane, hidePane } = useSidePane()

  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  if (!canEdit) {
    return null
  }

  return (
    <PrimaryButton
      size='sm'
      leftIcon={<PlusIcon />}
      onClick={() => {
        presentPane({
          key: `add-issue-${patient?.oid}`,
          content: (
            <IssueDrawer step='add' issueType={issueType} patient={patient} onClose={hidePane} />
          ),
        })
      }}
    >
      {`${issueName} issue`}
    </PrimaryButton>
  )
}
