import { Box, Text, useMantineTheme } from '@shared/components'

type TimeframeSelectorButtonProps = {
  text: string
  numMonths: number | undefined
  onClick: (value: number | undefined) => void
  active: boolean
}

const TimeframeSelectorButton = ({
  text,
  onClick,
  active,
  numMonths,
}: TimeframeSelectorButtonProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <Box
      p='xs'
      sx={{
        cursor: 'pointer',
        borderRadius: 5,
        boxShadow: `${active ? `0 0 0 ${sizes.border.md} ${colors.actions[0]} inset` : 'none'}`,
        ':hover': {
          backgroundColor: colors.background[3],
        },
      }}
      onClick={() => onClick(numMonths)}
    >
      <Text bold>{text}</Text>
    </Box>
  )
}

export default TimeframeSelectorButton
