import { RemoteConfigEntries } from '@shared/types'
import { toTime } from '@shared/utils'
import { FirebaseError, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'
import { Config } from '../config'

export const app = initializeApp({
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
})

export const auth = getAuth(app)

/**
 * Remote config setup
 */

const remoteConfig = getRemoteConfig(app)

if (Config.ENV === 'production') {
  // There are potential rate limiting concerns and would like to avoid fetching more than 5x per hour, per client
  remoteConfig.settings.minimumFetchIntervalMillis = toTime('12 minutes').ms()
} else {
  // In dev, we want to be able to test remote config changes more quickly
  remoteConfig.settings.minimumFetchIntervalMillis = toTime('5 minutes').ms()
}

export const fetchAndActivateRemoteConfig = () => fetchAndActivate(remoteConfig)

export const getRemoteConfigValue = (key: keyof RemoteConfigEntries) => {
  return getValue(remoteConfig, key)
}

// https://firebase.google.com/docs/reference/node/firebase.FirebaseError
export const isFirebaseError = (error: unknown): error is FirebaseError => {
  return error instanceof Error && error.name === 'FirebaseError'
}
