import { RadioGroup } from '@shared/components'
import { WelcomeCallVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteRadio } from '../visitNoteForm/PatientVisitNoteRadio'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const ZoomFamiliaritySection = ({ form }: { form: WelcomeCallVisitNoteForm }) => {
  return (
    <PatientVisitNoteSection title='Zoom familiarity'>
      <RadioGroup
        label='Is the patient familiar with Zoom?'
        orientation='horizontal'
        {...form?.getInputProps('zoom_familiarity')}
      >
        <PatientVisitNoteRadio value='yes' label='Yes' />
        <PatientVisitNoteRadio value='no' label='No' />
      </RadioGroup>
      {Boolean(form.values.zoom_familiarity) && (
        <PatientVisitNoteTextInput
          placeholder='Share any helpful info...'
          {...form?.getInputProps('zoom_familiarity_additional_details')}
        />
      )}
      <PatientVisitNoteNotDiscussedCheckbox field='zoom_familiarity' form={form} />
    </PatientVisitNoteSection>
  )
}
