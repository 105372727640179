import { Box, Divider, Space, TitleTwo } from '@shared/components'

export type VisitNoteSectionProps = {
  title: string
  children: React.ReactNode
}

export const VisitNoteSection = ({ title, children }: VisitNoteSectionProps) => (
  <Box
    p='md'
    sx={theme => ({
      borderWidth: theme.other.sizes.border.md,
      borderStyle: 'solid',
      borderColor: theme.other.colors.background[2],
      borderRadius: '4px',
    })}
  >
    <TitleTwo>{title}</TitleTwo>
    <Divider />
    <Space h='md' />
    {children}
  </Box>
)
