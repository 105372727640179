import { Group, Skeleton } from '@shared/components'

const AFakeOcpScheduleRow = () => {
  const WEEK_DAYS = 7

  return (
    <tr>
      <td>
        <Group
          position='center'
          sx={theme => ({
            paddingTop: theme.other.sizes.padding.lg,
            paddingBottom: theme.other.sizes.padding.lg,
          })}
        >
          <Skeleton height={10} width={100} radius='xl' />
        </Group>
      </td>
      {Array.from(Array(WEEK_DAYS).keys()).map(i => (
        <td key={i}>
          <Group position='center' spacing='sm'>
            <Skeleton height={7.5} circle />
            <Skeleton height={10} width={100} radius='xl' />
          </Group>
        </td>
      ))}
      <td>
        <Group position='center' spacing='xs'>
          <Skeleton height={5} circle />
          <Skeleton height={5} circle />
          <Skeleton height={5} circle />
        </Group>
      </td>
    </tr>
  )
}

export default AFakeOcpScheduleRow
