import { useForm } from '@mantine/form'
import { isLength, Stack, validateWith } from '@shared/components'
import { Appointment, hasGroupRole, ProblemListProblem, VisitNoteContent } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { DiagnosisSection } from '../../../components/forms/DiagnosisSection'
import { VisitNoteTextarea } from '../../../components/forms/VisitNoteTextArea'
import { useAuth } from '../../../context/auth'
import { isRequired } from '../../../utils/formValidation'
import { useMbcSession } from '../../../utils/hooks/use-mbc-session'
import { isNotAbleToWriteToNote } from '../../../utils/utils'
import { ObjectiveMeasuresSection } from '../../registry/objectiveMeasures/ObjectiveMeasuresSection'
import ObjectiveMeasuresFormCollectionDrawer from '../forms/ObjectiveMeasuresFormCollectionDrawer'
import { usePatient } from '../PPatientContext'
import { VisitNoteHeader } from './VisitNoteHeaderFooter'

export type InitialWellnessVisitFormData = {
  symptoms: string
  objective_measures: string
  problems_addressed: ProblemListProblem[]
}

export type InitialWellnessVisitFormProps = {
  patientID: string
  visitID?: string
  visit?: Appointment
  notes: VisitNoteContent | null
  editMode: boolean
  setCloseNoteModal: (bool: boolean) => void
  setUnlockNoteModal: (bool: boolean) => void
  sidebar: boolean
  meetingDetails?: { patientTime: number; clinicianTime: number }
}

export const InitialWellnessVisitForm = ({
  patientID,
  visit,
  visitID,
  notes,
  editMode,
  setCloseNoteModal,
  setUnlockNoteModal,
  sidebar,
  meetingDetails,
}: InitialWellnessVisitFormProps) => {
  const [savedAt, setSavedAt] = useState<dayjs.Dayjs>()
  const [savedContent, setSavedContent] = useState<InitialWellnessVisitFormData>()
  const { currentUser } = useAuth()
  const { problemsListQuery, patientId } = usePatient()
  const initialValues = {
    symptoms: '',
    objective_measures: '',
    problems_addressed: [],
  }
  const form = useForm<InitialWellnessVisitFormData>({
    initialValues,
    validate: {
      symptoms: validateWith(isRequired),
      problems_addressed: validateWith(isLength({ length: 1, op: '>=' }, 'Must add a diagnosis')),
    },
  })

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const saveNote = () => {
    if (isNotAbleToWriteToNote(currentUser.calendarId, visit)) {
      return
    }

    if (!visitID) {
      return
    }

    const body = { type: 'initialBhcm' as const, content: form.values }
    updateVisitNote.mutate(
      { params: { patientId: patientID, visitId: visitID }, data: body },
      {
        onSuccess: () => {
          setSavedContent(form.values)
          setSavedAt(dayjs())
        },
      },
    )
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      if (!editMode) {
        return
      }

      if (isEqual(initialValues, form.values)) {
        return
      }

      if (isEqual(form.values, savedContent)) {
        return
      }

      saveNote()
    }, toTime('3 sec').ms())
    return () => clearTimeout(interval)
  }, [form.values, editMode, saveNote, savedContent])

  const lockNote = () => {
    setCloseNoteModal(true)
    saveNote()
  }

  const {
    phq8Responses,
    gad7Responses,
    barc10Responses,
    completedMeasures,
    sessionInfoQueriesAreLoading,
    objectiveMeasure,
    workflowState,
    workflowType,
    measuresQueriesAreLoading,
    workflowIsLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  } = useMbcSession({
    patientID,
    visitID,
    notes,
    noteType: 'initialBhcm',
  })

  const saveDiagnoses = useMutation(emrApi.getMutation('POST /patient/:patientId/problemsList'))
  const handleSavingDiagnoses = async ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => {
    await saveDiagnoses.mutateAsync({
      params: { patientId },
      data: { problems: diagnoses },
    })

    void problemsListQuery?.refetch()
  }

  const updateDiagnosis = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/diagnosis/:diagnosisId/problemsList'),
  )
  const handleDeactivatingDiagnosis = async ({ diagnosis }: { diagnosis: ProblemListProblem }) => {
    const deactivatedDiagnosis = {
      ...diagnosis,
      status: 'inactive',
      deactivatedAt: dayjs().toISOString(),
    } as ProblemListProblem
    await updateDiagnosis.mutateAsync({
      params: { patientId, diagnosisId: diagnosis.oid || '' },
      data: { diagnosis: deactivatedDiagnosis },
    })

    void problemsListQuery?.refetch()
  }

  return (
    <>
      <form className='w-full' onSubmit={form.onSubmit(lockNote)}>
        <VisitNoteHeader
          sidebar={sidebar}
          onSubmit={() => void 0}
          datetime={visit?.datetime}
          save={saveNote}
          isSaving={updateVisitNote.isLoading}
          editMode={editMode}
          savedAt={savedAt}
          meetingDetails={meetingDetails}
          lockedAt={notes?.locked_at}
          lockedBy={notes?.locked_by_name}
          setUnlockNoteModal={setUnlockNoteModal}
          canUnlockNote={hasGroupRole(currentUser, 'engineer')}
        />
        <Stack mt='md' spacing='lg'>
          <DiagnosisSection
            {...form.getInputProps('diagnoses')}
            editable={editMode}
            saveDiagnoses={handleSavingDiagnoses}
            deactivateDiagnosis={handleDeactivatingDiagnosis}
          />
          <ObjectiveMeasuresSection
            showCaseReviewAlert={false}
            isLoading={measuresQueriesAreLoading || sessionInfoQueriesAreLoading}
            phqResponses={phq8Responses ?? []}
            gad7Responses={gad7Responses ?? []}
            barc10Responses={barc10Responses ?? []}
            completedMeasures={completedMeasures}
            editMode={editMode}
            lockedAt={notes?.locked_at}
            addMeasureOnClick={measure => createWorkflowSessionAndOpenDrawer(measure)}
            patientId={patientId}
          />
          <VisitNoteTextarea
            label='Brief assessment and care plan'
            placeholder={`Describe issues that are relevant to the patient's behavioral and mental health and the plan for addressing them`}
            {...form.getInputProps('symptoms')}
            isEditing={editMode}
          />
        </Stack>
      </form>
      <ObjectiveMeasuresFormCollectionDrawer
        patientId={patientID}
        measure={objectiveMeasure}
        workflowState={workflowState}
        workflowType={workflowType}
        isLoading={workflowIsLoading}
        onClose={onCloseDrawer}
        setRecentlySubmittedScore={setRecentlySubmittedScore}
      />
    </>
  )
}
