import { Group, Stack, Text } from '@shared/components'
import { Form, IntakeJotformSubmission } from '@shared/types'
import AIntakeLabels from '../../../components/atoms/AIntakeLabels'

export type OIntakeFormSidebarProps = {
  intakeForm: Form
}

const OIntakeFormSidebar = ({ intakeForm }: OIntakeFormSidebarProps) => {
  const doNotShowIntakeArray = [
    'name',
    'phoneNumber',
    'preferredPharmacy',
    'emergencyContact',
    'idSubmission',
    'email',
  ]

  return (
    <Stack mr='md'>
      <Group p='sm'>
        {intakeForm &&
          intakeForm.metadata.source === 'jotform' &&
          Object.entries(intakeForm.submission as IntakeJotformSubmission)
            .filter(a => !doNotShowIntakeArray.includes(a[0]))
            .map(pair => (
              <Stack m='xs' spacing='xs' key={`${pair[0]}`}>
                <Text bold>{AIntakeLabels[pair[0]]}</Text>
                {Array.isArray(pair[1]) &&
                  (pair[0] === 'dateOfBirth' ? (
                    <Text>{pair[1].join('-')}</Text>
                  ) : (
                    <Text>{pair[1].join(', ')}</Text>
                  ))}
                {typeof pair[1] === 'string' && (
                  <Text>{pair[1].replace('jotform_untitled_markdown', '')}</Text>
                )}
              </Stack>
            ))}
        {intakeForm &&
          intakeForm.metadata.source === 'internal' &&
          intakeForm.submission.answers
            ?.filter(answer => Array.isArray(answer.value) || typeof answer.value === 'string')
            .map(answer => (
              <Stack m='xs' spacing='xs' key={answer.key}>
                <Text bold>{answer.description}</Text>
                {Array.isArray(answer.value) && <Text>{answer.value.join(', ')}</Text>}
                {typeof answer.value === 'string' && <Text>{answer.value}</Text>}
              </Stack>
            ))}
      </Group>
    </Stack>
  )
}

export default OIntakeFormSidebar
