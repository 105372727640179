import { PlusCircleIcon, Stack, TertiaryButton, Text, TitleFour } from '@shared/components'
import { INIT_SERVICE_LINE } from '.'
import { ServiceLine } from './ServiceLine'

const getPathForServiceLine = ({
  planType,
  type,
  index,
  key,
}: {
  planType: 'medicare' | 'medicaid' | 'commercial'
  type: ServiceLineTypeValue
  index?: number
  key?: string
}) => {
  return ['serviceLines', planType, type, index, key]
    .filter(d => d !== undefined)
    .map(String)
    .join('.')
}

type ServiceLineType = 'visits' | 'months'
export type ServiceLineTypeValue = 'intake' | 'subsequent' | '1_to_3_months' | '4_months'

export const ServiceLineTypeMap: Record<
  ServiceLineType,
  { label: string; value: ServiceLineTypeValue }[]
> = {
  visits: [
    { label: 'Intake', value: 'intake' },
    { label: 'Subsequent', value: 'subsequent' },
  ],
  months: [
    { label: '1 to 3 months', value: '1_to_3_months' },
    { label: '4 months', value: '4_months' },
  ],
}

const getLabelForServiceLineType = (type: ServiceLineType, value: ServiceLineTypeValue) => {
  return ServiceLineTypeMap[type].find(t => t.value === value)!.label
}

export const ServiceLinesSection = ({
  type,
  planType,
  values,
  setFieldValue,
  removeListItem,
}: {
  type: ServiceLineType
  planType: 'medicare' | 'medicaid' | 'commercial'
  values: {
    [key in ServiceLineTypeValue]: {
      code: string
      amount: string
      modifier: string
    }[]
  }
  setFieldValue: (path: string, value: string | object) => void
  removeListItem: (path: string, index: number) => void
}) => {
  const startType: ServiceLineTypeValue = type === 'visits' ? 'intake' : '1_to_3_months'
  const endType: ServiceLineTypeValue = type === 'visits' ? 'subsequent' : '4_months'

  const startValues = values[startType]
  const endValues = values[endType]

  const onChange = ({
    type,
    index,
    key,
    value,
  }: {
    type: ServiceLineTypeValue
    index: number
    key: string
    value: string
  }) => {
    // If no list items yet in parent form, must create them first
    const path = getPathForServiceLine({
      planType,
      type,
      index,
      key,
    })
    setFieldValue(path, value)
  }

  return (
    <Stack>
      <TitleFour>{planType}</TitleFour>
      {/*
       * START VALUES SECTION
       */}
      <Stack px='md'>
        <Text bold>{getLabelForServiceLineType(type, startType)}</Text>
        {startValues.map((sl, index) => {
          return (
            <ServiceLine
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              values={sl}
              index={index}
              type={startType}
              onChange={onChange}
              onRemove={() => {
                return removeListItem(
                  getPathForServiceLine({
                    planType,
                    type: startType,
                  }),
                  index,
                )
              }}
            />
          )
        })}
        {/* ADD CODE */}
        <TertiaryButton
          onClick={() => {
            const path = getPathForServiceLine({
              planType,
              type: startType,
              index: startValues.length,
            })
            setFieldValue(path, INIT_SERVICE_LINE)
          }}
          leftIcon={<PlusCircleIcon />}
        >
          Code
        </TertiaryButton>
      </Stack>
      {/*
       * END VALUES SECTION
       */}
      <Stack px='md'>
        <Text bold>{getLabelForServiceLineType(type, endType)}</Text>
        {endValues.map((sl, index) => {
          return (
            <ServiceLine
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              values={sl}
              index={index}
              type={endType}
              onChange={onChange}
              onRemove={() => {
                return removeListItem(
                  getPathForServiceLine({
                    planType,
                    type: endType,
                  }),
                  index,
                )
              }}
            />
          )
        })}
        {/* ADD CODE */}
        <TertiaryButton
          onClick={() => {
            const path = getPathForServiceLine({
              planType,
              type: endType,
              index: endType.length,
            })
            setFieldValue(path, INIT_SERVICE_LINE)
          }}
          leftIcon={<PlusCircleIcon />}
        >
          Code
        </TertiaryButton>
      </Stack>
    </Stack>
  )
}
