import { Stack, Text } from '@shared/components'
import { DischargeNoteModel, prescribingClinicianPreferenceMapping } from '@shared/types'

export const EligibleToReturnSection = ({
  dischargeNote,
}: {
  dischargeNote: DischargeNoteModel
}) => {
  return dischargeNote.eligible_to_return ? (
    <>
      <Stack spacing='xs'>
        <Text bold>Eligible to return to Ophelia?</Text>
        <Text>{dischargeNote.eligible_to_return === 'yes' ? 'Yes' : 'No'}</Text>
        {dischargeNote.eligible_to_return_additional_details && (
          <Text>{`Additional details: ${dischargeNote.eligible_to_return_additional_details}`}</Text>
        )}
      </Stack>

      {dischargeNote.prescribing_clinician_preference && (
        <Stack spacing='xs'>
          <Text bold>Prescribing clinician preference</Text>
          <Text>
            {prescribingClinicianPreferenceMapping[dischargeNote.prescribing_clinician_preference]}
          </Text>
        </Stack>
      )}

      {dischargeNote.eligible_to_return_review_within_30_days && (
        <Stack spacing='xs'>
          <Text
            bold
          >{`Review with previous PC if patient re-enrolls <30 days after discharge?`}</Text>
          <Text>{dischargeNote.eligible_to_return_review_within_30_days ? 'Yes' : 'No'}</Text>
        </Stack>
      )}
    </>
  ) : null
}
