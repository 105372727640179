import { createFormContext } from '@mantine/form'
import { EncounterSubscriber, InsuranceCard, InsuranceData } from '@shared/types'
import { dayjs } from '@shared/utils'

export type InsuranceCardForm = Omit<InsuranceCard, 'image_url_front' | 'image_url_back'>
export const [InsuranceCardFormProvider, useInsuranceCardFormContext, useInsuranceCardForm] =
  createFormContext<InsuranceCardForm>()

export type PrimarySubscriberForm = Omit<EncounterSubscriber, 'insurance_card'>
export const [
  PrimarySubscriberFormProvider,
  usePrimarySubscriberFormContext,
  usePrimarySubscriberForm,
] = createFormContext<PrimarySubscriberForm>()

export const validDateOfBirth = (value?: string) => {
  if (!value) {
    return 'Required'
  }

  if (!dayjs(value, 'YYYY-MM-DD', true).isValid() || !value.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return 'Invalid date'
  }
}

export type EncounterSection =
  | 'header'
  | 'billing notes'
  | 'patient'
  | 'primary subscriber'
  | 'insurance card'
  | 'secondary primary subscriber'
  | 'secondary insurance card'
  | 'service lines'
  | 'billing provider'
  | 'rendering provider'
  | 'service facility'
  | 'miscellaneous'

export const formatInsuranceCardInfoAsPatientProvidedInsurance = (
  data: Partial<InsuranceCard>,
): Partial<InsuranceData> => {
  const { member_id, group_number, plan_name, plan_type, rx_bin, rx_pcn, medicaidInfo } = data

  /*
   * Note: We are purposely not including the payer_name and payer_id fields here.
   * The encounter uses the payer_id/ payer_name from Change Healthcare, not eligible.
   */
  return {
    memberId: member_id,
    groupId: group_number,
    planName: plan_name,
    planType: plan_type,
    rxbin: rx_bin,
    rxPcn: rx_pcn,
    medicaidInfo: {
      memberId: medicaidInfo?.member_id,
    },
  }
}
