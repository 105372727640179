import { AppointmentWithNoteDetails, EmrApi } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'
import { useEmrQuery } from '../../utils/hooks'

export const useAppointments = (
  props: Omit<EmrApi['GET /appointments']['req']['query'], 'withNoteDetails'>,
) => {
  const [listAppointmentsQueryKey, listAppointmentsQueryFunction] = emrApi.getQuery(
    'GET /appointments',
    {
      query: {
        withNoteDetails: 'yes',
        ...props,
      },
    },
  )

  // Cast as AppointmentWithNoteDetails with given the 'yes' above
  const {
    data,
    isLoading,
  }: { data: AppointmentWithNoteDetails[] | undefined; isLoading: boolean } = useQuery(
    listAppointmentsQueryKey,
    listAppointmentsQueryFunction,
  )

  return {
    appointments: data,
    isLoading,
  }
}

export const useBlocks = (props: EmrApi['GET /appointments/blocks']['req']['query']) => {
  const { data, isLoading } = useEmrQuery('GET /appointments/blocks', { query: props })
  return { blocks: data, isLoading }
}

export const useWorkingHours = (
  props: EmrApi['GET /appointments/working-hours']['req']['query'],
) => {
  const [workinghoursQueryKey, workingHoursQueryFunction] = emrApi.getQuery(
    'GET /appointments/working-hours',
    {
      query: props,
    },
  )
  const { data, isLoading } = useQuery(workinghoursQueryKey, workingHoursQueryFunction)

  return {
    workingHours: data,
    isLoading,
  }
}
