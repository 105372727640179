import { useForm } from '@mantine/form'
import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  useBanner,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { validCharacters } from '@shared/utils'
import { useNavigate } from 'react-router-dom'
import { useLunaMutation } from '../../../../utils/hooks'
import { useInvalidateCollections } from '../use-invalidate-collections'

export const ArchivePatientChartModal = ({
  opened,
  onClose,
  onSubmit,
  patientId,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  onSubmit: () => void
}) => {
  const navigate = useNavigate()
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const { getInputProps, values, validate } = useForm({
    initialValues: { toPatientId: '' },
    validate: {
      toPatientId: value => {
        if (!value) {
          return 'Required'
        }
        if (value === patientId) {
          return 'Patient IDs must not be the same'
        }
      },
    },
  })

  const archivePatientChart = useLunaMutation('POST /patients/:patientId/archive')

  return (
    <Modal
      title='Archive patient chart'
      opened={opened}
      onClose={() => {
        if (!archivePatientChart.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={archivePatientChart.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={archivePatientChart.isLoading}
            onClick={async () => {
              if (!validate().hasErrors) {
                await archivePatientChart.mutateAsync(
                  {
                    params: { patientId },
                    data: { toPatientId: values.toPatientId, type: 'manual_archive' },
                  },
                  {
                    onError: error => {
                      showBanner({
                        type: 'error',
                        dismissable: true,
                        label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                      })
                    },
                    onSuccess: () => {
                      navigate({ pathname: `/patients/${values.toPatientId}` })
                    },
                  },
                )

                invalidate({ patientId })
                invalidate({ patientId: values.toPatientId })

                onClose()
                onSubmit()
              }
            }}
          >
            Archive
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>Please enter the Patient ID to archive this account under.</Text>
        <TextInput
          label='Patient ID'
          placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
          formatter={validCharacters.id}
          {...getInputProps('toPatientId')}
        />
      </Stack>
    </Modal>
  )
}
