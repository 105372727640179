type ListItemProps = {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLLIElement>
  focused: boolean
}

const ListItem = ({ children, onClick, focused }: ListItemProps) => {
  return (
    <li
      onClick={onClick}
      className={`${
        focused && 'bg-daybreak-bg2 rounded-md shadow-sm'
      } hover:bg-daybreak-bg2 relative pl-4 pr-6 py-3 sm:pl-6 cursor-pointer`}
    >
      <div className='flex items-center justify-between space-x-4 flex-shrink-0'>
        <div className='min-w-0 space-y-1 flex-1'>{children}</div>
      </div>
    </li>
  )
}

export default ListItem
