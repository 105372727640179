import { Group, TertiaryButton, UserIcon, ZapIcon } from '@shared/components'
import { EMRTaskNoteDecorated } from '@shared/types'
import { Link } from 'react-router-dom'
export const NoteCreatedBy = ({ note }: { note: EMRTaskNoteDecorated }) => {
  const isSystemGenerated = note.employeeId === 'system'
  const Icon = isSystemGenerated ? ZapIcon : UserIcon
  const createdBy = isSystemGenerated ? 'System generated' : note.metadata.employeeName

  return (
    <Group spacing='xs'>
      <Icon color={({ text }) => text[1]} size='sm' />
      {isSystemGenerated ? (
        createdBy
      ) : (
        <TertiaryButton component={Link} to={`/employees/${note.employeeId}`} target='_blank'>
          {createdBy}
        </TertiaryButton>
      )}
    </Group>
  )
}
