import {
  Card,
  CheckCircleIcon,
  EditIcon,
  Group,
  Pill,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { getOpheliaHttpError, isAutoPayEnabled } from '@shared/types'
import { sortBy } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'
import { getStripeDashboardUrl } from '../../invoices/InvoiceRow'

export const PaymentCardsSection = () => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data
  const stripeCustomerId = patient?.stripeCustomerId

  const paymentMethodsQuery = useEmrQuery('GET /patient/:patientId/payment/methods', {
    params: {
      patientId,
    },
  })

  if (paymentMethodsQuery.isLoading) {
    return <Skeleton height={60} />
  }

  if (paymentMethodsQuery.error) {
    return (
      <Text size='xs' color={colors => colors.error[0]}>
        {getOpheliaHttpError(paymentMethodsQuery.error, 'Error loading payment methods')}
      </Text>
    )
  }

  const paymentMethods = paymentMethodsQuery.data || []

  if (paymentMethods.length === 0) {
    return <Text size='xs'>No payment methods on file</Text>
  }

  return (
    <Stack spacing='sm'>
      <Group position='apart'>
        <Text size='xs' color={colors => colors.text[1]}>
          Payment cards
        </Text>
        <TertiaryButton
          leftIcon={<EditIcon />}
          onClick={() => {
            const url = getStripeDashboardUrl({
              pathname: `/customers/${stripeCustomerId}`,
            })
            window.open(url.href, '_blank')
          }}
        >
          Edit
        </TertiaryButton>
      </Group>
      {paymentMethods?.sort(sortBy({ key: 'isDefault', order: 'DESC' })).map(paymentMethod => (
        <Card key={paymentMethod.id}>
          <Stack spacing='sm'>
            <Group position='apart'>
              <Text bold size='xs'>{`${capitalize(paymentMethod.brand)} ****${
                paymentMethod.last4
              }`}</Text>
              {paymentMethod.isDefault && (
                <Pill variant='filled' status='success'>
                  Default
                </Pill>
              )}
            </Group>
            <Text size='xs'>
              Exp {`${paymentMethod.expMonth.toString().padStart(2, '0')}/${paymentMethod.expYear}`}
            </Text>
            {paymentMethod.isDefault && (
              <Group spacing='xs'>
                {isAutoPayEnabled(patient) && (
                  <CheckCircleIcon color={colors => colors.success[0]} />
                )}
                <Text size='xs'>Auto pay {isAutoPayEnabled(patient) ? 'on' : 'off'}</Text>
              </Group>
            )}
          </Stack>
        </Card>
      ))}
    </Stack>
  )
}
