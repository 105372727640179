import { Box, SearchIcon, TextInput, TextInputProps, XIcon } from '@shared/components'
import React from 'react'

type SearchBarProps = TextInputProps & {
  onClear: () => void
  onEnter?: () => void
}

const SearchBar = ({ onEnter, onClear, ...props }: SearchBarProps) => {
  return (
    <TextInput
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEnter?.()
        }
      }}
      icon={<SearchIcon />}
      rightSection={
        <Box
          onClick={onClear}
          sx={{
            cursor: 'pointer',
          }}
        >
          <XIcon />
        </Box>
      }
      {...props}
    />
  )
}

export default SearchBar
