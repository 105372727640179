import { useForm } from '@mantine/form'
import { useFocusTrap } from '@mantine/hooks'
import {
  Banner,
  Checkbox,
  DatePicker,
  Drawer,
  Group,
  PrimaryButton,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { DrugScreen, DrugScreenResults, drugScreenInfo } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { isRequired } from '../../../utils/formValidation'
import { usePatient } from '../PPatientContext'

type EditDrugScreenDrawerProps = {
  drugScreen: DrugScreen | null
  opened: boolean
  onClose: () => void
}
export type DrugScreenForm = {
  administeredAt: string
  results: DrugScreenResults
}

export const EditDrugScreenDrawer = ({
  drugScreen,
  opened,
  onClose,
}: EditDrugScreenDrawerProps) => {
  const { patientId } = usePatient()
  const queryClient = useQueryClient()
  const focusTrapRef = useFocusTrap()

  const drugScreenForm = useForm<DrugScreenForm>({
    validate: {
      administeredAt: validateWith(isRequired),
    },
    clearInputErrorOnChange: false,
  })

  const [drugScreensQueryKey] = emrApi.getQuery('GET /patient/:patientId/drugScreens', {
    params: { patientId },
  })

  const updateDrugScreen = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/drugScreens/:id'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(drugScreensQueryKey)
        drugScreenForm.reset()
        onClose()
      },
    },
  )

  const submitDrugScreen = () => {
    if (!drugScreen || drugScreenForm.validate().hasErrors) {
      return
    }

    // DatePicker set administeredAt as a Date object, so we need to convert it to ISO string
    drugScreenForm.values.administeredAt = dayjs(drugScreenForm.values.administeredAt).toISOString()

    updateDrugScreen.mutate({
      params: {
        patientId,
        id: drugScreen.oid,
      },
      data: {
        ...drugScreenForm.values,
      },
    })
  }

  useEffect(() => {
    if (drugScreen) {
      drugScreenForm.setValues(drugScreen)
    }
  }, [drugScreen])

  return (
    <Drawer
      title={`Edit drug screen from ${dayjs(drugScreen?.administeredAt).format('MM/DD/YYYY')}`}
      size='lg'
      opened={opened}
      position='right'
      onClose={() => {
        onClose()
        drugScreenForm.reset()
      }}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={submitDrugScreen} loading={false}>
            Submit
          </PrimaryButton>
        </Group>
      }
      ref={focusTrapRef}
    >
      <Stack p='md'>
        {false && <Banner type='error' label='Something went wrong, please try again' />}
        <DatePicker
          label='Date administered'
          maxDate={dayjs().toDate()}
          placeholder='Select day...'
          defaultValue={dayjs().toDate()}
          autoFocus={false}
          {...drugScreenForm.getInputProps('administeredAt')}
        />
        <Stack spacing='sm' data-autofocus>
          <Text>Please mark which substances were positive</Text>
          {Object.keys(drugScreenInfo).map(drugScreenOption => {
            const drugScreenOptionInfo =
              drugScreenInfo[drugScreenOption as keyof typeof drugScreenInfo]
            const label = `${drugScreenOptionInfo.name} (${drugScreenOptionInfo.abbreviation})`
            return (
              <Checkbox
                label={label}
                key={drugScreenOption}
                {...drugScreenForm.getInputProps(`results.${drugScreenOption}`, {
                  type: 'checkbox',
                })}
              />
            )
          })}
        </Stack>
      </Stack>
    </Drawer>
  )
}
