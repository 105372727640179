import { useCounter, useToggle } from '@mantine/hooks'
import {
  BannerPortal,
  Box,
  CircleWithText,
  EM_DASH,
  FilterIcon,
  Flex,
  Group,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  Tabs,
  isLength,
  validateWith,
} from '@shared/components'
import {
  EMRTaskPod,
  EMRTaskStatus,
  InboundCommunicationsTaskType,
  hasGroupRole,
  hasRole,
} from '@shared/types'
import { toTime } from '@shared/utils'
import first from 'lodash/first'
import last from 'lodash/last'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { emrApi } from '../../../api'
import { SelectPatient } from '../../../components/forms/SelectPatient'
import { Page } from '../../../components/templates/TDefault'
import { useAuth } from '../../../context/auth'
import { usePodFilterOptions } from '../../../utils/hooks/use-pod-filters-options'
import { useSidePane } from '../../../utils/hooks/use-side-pane'
import { useTasksCache } from '../../../utils/hooks/use-tasks-cache'
import { AppliedFilters } from './AppliedFilters'
import { FilterTaskDrawer } from './FilterTaskDrawer'
import { IssueDrawer } from './IssueDrawer'
import { Pagination } from './Pagination'
import { TaskTable } from './TaskTable'
import {
  FiltersFormProvider,
  OrderBy,
  getDefaultPod,
  getInitialFilters,
  useFiltersForm,
} from './filterFormHelpers'

export const useTaskParams = () => {
  return useParams<{ queue: EMRTaskStatus }>()
}

const REFETCH_INTERVAL = toTime('30 sec').ms()

const PAGE_SIZE = 50

// Firestore doesn't allow queries to use arrays with more than 10 values
export const MAX_TYPES_TO_INCLUDE = 10

export const PTasks = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const params = useTaskParams()
  const tasksCache = useTasksCache()
  const [pageNumber, pageNumberActions] = useCounter(1)
  const { presentPane, hidePane } = useSidePane()

  const podFilterOptions = usePodFilterOptions()

  const initialFilters = getInitialFilters({
    pod: getDefaultPod(currentUser),
    status: params.queue,
    orderBy: hasGroupRole(currentUser, 'engineer') ? 'createdAt' : 'priority',
  })

  const [isFilterTaskDrawerOpened, toggleFilterTaskDrawer] = useToggle()
  const filters = useFiltersForm({
    initialValues: initialFilters,
    validate: {
      types: validateWith(
        isLength({ length: MAX_TYPES_TO_INCLUDE, op: '<=' }, 'Too many types selected'),
      ),
    },
  })

  const employeeIdQuery = filters.values.pod === 'assigned_to_me' ? currentUser.oid : undefined

  const createdByEmployeeIdQuery =
    filters.values.pod === 'opened_by_me' ? currentUser.oid : undefined

  const podQuery = ['assigned_to_me', 'opened_by_me', 'communications'].includes(
    filters.values.pod || '',
  )
    ? undefined
    : (filters.values.pod as EMRTaskPod) || undefined

  const communicationTypes: InboundCommunicationsTaskType[] =
    filters.values.pod === 'communications' ? ['inbound_sms'] : []

  const [tasksQueryKey, tasksQueryFn] = emrApi.getQuery('GET /tasks/v2', {
    query: {
      pod: podQuery,
      employeeId: employeeIdQuery,
      createdByEmployeeId: createdByEmployeeIdQuery,
      patientId: filters.values.patientId,
      status: filters.values.status,
      startAfter: filters.values.startAfter,
      endBefore: filters.values.endBefore,
      orderBy: filters.values.orderBy,
      orderByDirection: filters.values.orderByDirection,
      limit: `${PAGE_SIZE}`,
      types: filters.values.types,
      communicationTypes,
    },
  })

  const setOrderBy = (orderBy: OrderBy) => {
    filters.setValues({
      ...orderBy,
      // Reset pagination when changing order
      startAfter: undefined,
      endBefore: undefined,
    })
    pageNumberActions.reset()
  }

  const orderBy: OrderBy = {
    orderBy: filters.values.orderBy,
    orderByDirection: filters.values.orderByDirection,
  }

  const tasksQuery = useQuery(tasksQueryKey, tasksQueryFn, {
    refetchOnWindowFocus: true,
    refetchInterval: filters.values.pod === 'ec' ? toTime('5 sec').ms() : REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
  })

  const [tasksCountsQueryKey, tasksCountsQueryFn] = emrApi.getQuery('GET /tasks/v2/counts', {
    query: {
      pod: podQuery,
      patientId: filters.values.patientId,
      employeeId: employeeIdQuery,
      createdByEmployeeId: createdByEmployeeIdQuery,
      // Do not fetch closed count unless looking at closed queue - otherwise unncessary and costly
      statuses: params.queue === 'closed' ? ['open', 'snoozed', 'closed'] : ['open', 'snoozed'],
      types: filters.values.types,
    },
  })

  const tasksCountQuery = useQuery(tasksCountsQueryKey, tasksCountsQueryFn, {
    refetchOnWindowFocus: true,
    refetchInterval: filters.values.pod === 'ec' ? toTime('5 sec').ms() : REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    // Good for UX - prevents the counts from flickering 0 as the next counts are being fetched
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
  })

  const tasks = tasksQuery?.data || []
  const counts = tasksCountQuery?.data
  const countForCurrentQueue = params.queue ? counts?.[params.queue] : 0

  const shouldShowScheduledForColumn =
    filters.values.pod === 'ec' ||
    (filters.values.pod === 'assigned_to_me' && hasGroupRole(currentUser, 'enrollmentCoordinator'))

  const onTabChange = (status: EMRTaskStatus) => {
    // Reset page number when switching tabs
    pageNumberActions.reset()
    filters.setValues(
      getInitialFilters({
        status,
        patientId: filters.values.patientId,
        types: filters.values.types,
      }),
    )
    navigate(`/issues/${status}`)
  }

  /**
   * For enrollment coordinators we show an additional table at the bottom of the
   * screen with all unassigned when they're viewing "Assigned to me" - this should
   * always be a small list and so we are not concerned with pagination. We still
   * add a limit as a backup.
   */
  const shouldShowUnassigned =
    filters.values.status === 'open' &&
    filters.values.pod === 'assigned_to_me' &&
    hasGroupRole(currentUser, 'enrollmentCoordinator')
  const [unAssignedTasksQueryKey, unAssignedTasksQueryFn] = emrApi.getQuery('GET /tasks/v2', {
    query: {
      pod: 'ec',
      employeeId: '',
      createdByEmployeeId: createdByEmployeeIdQuery,
      patientId: filters.values.patientId,
      status: filters.values.status,
      startAfter: filters.values.startAfter,
      endBefore: filters.values.endBefore,
      orderBy: filters.values.orderBy,
      orderByDirection: filters.values.orderByDirection,
      types: filters.values.types,
      limit: '25',
    },
  })
  const unassignedTasksQuery = useQuery(unAssignedTasksQueryKey, unAssignedTasksQueryFn, {
    enabled: shouldShowUnassigned,
    refetchOnWindowFocus: true,
    refetchInterval: filters.values.pod === 'ec' ? toTime('5 sec').ms() : REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
  })
  const unassignedTasks = unassignedTasksQuery.data || []
  const showReferrals =
    filters.values.pod === 'ec' ||
    hasGroupRole(currentUser, 'enrollmentCoordinator', 'leadEnrollmentCoordinator')

  return (
    <Page title='Issue tracker'>
      <FiltersFormProvider form={filters}>
        <FilterTaskDrawer
          opened={isFilterTaskDrawerOpened}
          onClose={() => {
            toggleFilterTaskDrawer(false)
            tasksCache.invalidate()
          }}
        />

        <BannerPortal />
        <Stack spacing='lg' py='lg' sx={{ flex: 1 }}>
          <Stack>
            <Group position='apart' px='lg'>
              <Box sx={{ width: 300 }}>
                <SelectPatient
                  placeholder='Search by patient name...'
                  onPatientSelected={patient => {
                    if (patient) {
                      /**
                       * To avoid having to create a mass of Firestore indexes, we only allow filtering by patient ID without
                       * any other filters. This is a reasonable assumption as a user will not want to filter by pod/employee.
                       */
                      filters.setValues({
                        pod: '',
                        employeeId: '',
                      })
                    } else {
                      // Reset filters when clearing patient
                      filters.setValues(initialFilters)
                    }
                    // Reset page number when filtering or clearing patient
                    pageNumberActions.reset()
                  }}
                  {...filters.getInputProps('patientId')}
                />
              </Box>
              <Group>
                <SecondaryButton onClick={() => toggleFilterTaskDrawer()} leftIcon={<FilterIcon />}>
                  Filter
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    presentPane({
                      key: 'add-issue',
                      content: <IssueDrawer step='add' onClose={hidePane} />,
                    })
                  }}
                  leftIcon={<PlusIcon />}
                >
                  Issue
                </PrimaryButton>
                <Select
                  /**
                   * Disable these filters when patient is selected to avoid explosion of Firestore indexes
                   * It is reasonable to assume that a user will not want to filter by pod/employee when
                   * a single patient is selected.
                   */
                  disabled={Boolean(filters.values.patientId)}
                  maxDropdownHeight={600}
                  data={
                    hasRole(currentUser, 'pc', 'spc')
                      ? podFilterOptions.filter(option =>
                          ['ocp', 'assigned_to_me', 'opened_by_me'].includes(option.value),
                        )
                      : podFilterOptions
                  }
                  {...filters.getInputProps('pod')}
                />
              </Group>
            </Group>
            <AppliedFilters />
          </Stack>
          <Tabs
            sx={{ flex: 1 }}
            keepMounted={false}
            value={filters.values.status}
            onTabChange={onTabChange}
          >
            <Tabs.List px='lg'>
              <Tabs.Tab
                value={'open' as EMRTaskStatus}
                rightSection={
                  <CircleWithText variant='error'>{counts?.open ?? EM_DASH}</CircleWithText>
                }
              >
                Open
              </Tabs.Tab>
              <Tabs.Tab
                value={'snoozed' as EMRTaskStatus}
                rightSection={
                  <CircleWithText variant='error'>{counts?.snoozed ?? EM_DASH}</CircleWithText>
                }
              >
                Snoozed
              </Tabs.Tab>
              <Tabs.Tab value='closed'>Closed</Tabs.Tab>
            </Tabs.List>
            {shouldShowUnassigned && (
              <Tabs.Panel value={'open' as EMRTaskStatus}>
                <TaskTable
                  status='open'
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  tasks={unassignedTasks}
                  isLoading={unassignedTasksQuery.isLoading}
                  showReferrals={showReferrals}
                  columns={[
                    'Pri',
                    'Patient name',
                    'First opened',
                    'Issue type',
                    'Sub-category',
                    'Scheduled for',
                    'Notes',
                    'Assignee',
                  ]}
                />
              </Tabs.Panel>
            )}
            <Tabs.Panel value={'open' as EMRTaskStatus}>
              <TaskTable
                status='open'
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                tasks={tasks}
                isLoading={tasksQuery.isLoading}
                showReferrals={showReferrals}
                columns={
                  shouldShowScheduledForColumn
                    ? [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Issue type',
                        'Sub-category',
                        'Scheduled for',
                        'Notes',
                        'Assignee',
                        'Action',
                      ]
                    : [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Issue type',
                        'Sub-category',
                        'Notes',
                        'Assignee',
                        'Action',
                      ]
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value={'snoozed' as EMRTaskStatus}>
              <TaskTable
                status='snoozed'
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                tasks={tasks}
                isLoading={tasksQuery.isLoading}
                showReferrals={showReferrals}
                columns={
                  shouldShowScheduledForColumn
                    ? [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Snoozed until',
                        'Issue type',
                        'Sub-category',
                        'Scheduled for',
                        'Notes',
                        'Assignee',
                        'Action',
                      ]
                    : [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Snoozed until',
                        'Issue type',
                        'Sub-category',
                        'Notes',
                        'Assignee',
                        'Action',
                      ]
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value={'closed' as EMRTaskStatus}>
              <TaskTable
                status='closed'
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                tasks={tasks}
                isLoading={tasksQuery.isLoading}
                showReferrals={showReferrals}
                columns={
                  shouldShowScheduledForColumn
                    ? [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Issue type',
                        'Sub-category',
                        'Scheduled for',
                        'Notes',
                        'Assignee',
                      ]
                    : [
                        'Pri',
                        'Patient name',
                        'First opened',
                        'Issue type',
                        'Sub-category',
                        'Notes',
                        'Assignee',
                      ]
                }
              />
            </Tabs.Panel>
          </Tabs>
          {countForCurrentQueue !== undefined && (
            <Flex mx='lg' justify='flex-end'>
              <Pagination
                key={params.queue}
                page={pageNumber}
                pageSize={PAGE_SIZE}
                totalItems={countForCurrentQueue}
                onDecrement={() => {
                  pageNumberActions.decrement()
                  filters.setValues({
                    endBefore: first(tasks)?.oid,
                    startAfter: undefined,
                  })
                }}
                onIncrement={() => {
                  pageNumberActions.increment()
                  filters.setValues({
                    startAfter: last(tasks)?.oid,
                    endBefore: undefined,
                  })
                }}
              />
            </Flex>
          )}
        </Stack>
      </FiltersFormProvider>
    </Page>
  )
}
