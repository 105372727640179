import { useForm } from '@mantine/form'
import {
  DatePicker,
  SaveIcon,
  SecondaryButton,
  Select,
  Td,
  TertiaryButton,
  Tooltip,
} from '@shared/components'
import { YYYYMMDD } from '@shared/types'
import { dayjs, isTruthy, name } from '@shared/utils'
import uniqBy from 'lodash/uniqBy'
import { Link } from 'react-router-dom'
import { useLunaMutation, useLunaQuery } from '../../../utils/hooks'
import { Hold } from './CalendarHoldsDrawer'

type HoldForm = {
  holdId: string
  intendedDay: YYYYMMDD
  // The form only allows this value to be a string, but we need to convert it to a number when we send it to the server
  calendarId: string
}

export const CalendarHoldReviewRow = ({ hold, onUpdate }: { hold: Hold; onUpdate: () => void }) => {
  const form = useForm<HoldForm>({
    initialValues: {
      holdId: hold.holdId,
      intendedDay: hold.intendedDay,
      calendarId: `${hold.calendarId}`,
    },
  })

  // Get the patient's assigned PC and CCM, since we should be able to move the hold to either one
  const patientCliniciansQuery = useLunaQuery('GET /patients/:patientId/clinicians', {
    params: {
      patientId: hold.patientId,
    },
  })

  const patientClinicians = patientCliniciansQuery.data?.data || { pc: null, ccm: null }

  const patientCliniciansSelectOptions = uniqBy(
    [
      /*
       * We'll include the hold clinician in the list of options, though it should be a duplicate value of the patient's PC or CCM
       * since we should only be assigning the hold to the patient's PC or CCM. We'll remove duplicates to ensure that we don't
       * list the same clinician twice.
       */
      {
        value: `${hold.calendarId}`,
        label: hold.clinicianName,
      },
      {
        value: `${patientClinicians?.pc?.calendarId}`,
        label: patientClinicians?.pc?.name || '',
      },
      {
        value: `${patientClinicians?.ccm?.calendarId}`,
        label: patientClinicians?.ccm?.name || '',
      },
    ].filter(selectOption => isTruthy(selectOption.value) && isTruthy(selectOption.label)),
    clinician => clinician.value,
  )

  const updateHoldMutation = useLunaMutation('PUT /scheduling/holds-details/:id', {
    onSuccess: () => {
      onUpdate()
    },
  })

  const submitChanges = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await updateHoldMutation.mutateAsync({
      params: {
        id: hold.holdId,
      },
      data: {
        intendedDay: dayjs(form.values.intendedDay).format('YYYY-MM-DD'),
        calendarId: Number(form.values.calendarId),
      },
    })
  }

  // We'll only allow changes to be saved if changes were made
  const hasUpdatedCalendarId = form.values.calendarId !== `${hold.calendarId}`
  const hasUpdatedIntendedDay = form.values.intendedDay !== hold.intendedDay
  const hasChangedValues = hasUpdatedIntendedDay || hasUpdatedCalendarId

  return (
    <tr key={hold.patientId} className='mantine'>
      <Td className='mantine'>
        <TertiaryButton component={Link} to={`/patients/${hold.patientId}`}>
          {name({
            first: hold.firstName,
            last: hold.lastName,
          }).lastCommaFirst()}
        </TertiaryButton>
      </Td>
      <Td className='mantine'>
        <Tooltip label='Change intended day'>
          <DatePicker
            placeholder='MM/DD/YYYY'
            minDate={dayjs().toDate()}
            {...form.getInputProps('intendedDay')}
          />
        </Tooltip>
      </Td>
      <Td className='mantine'>
        <Tooltip label='Change clinician' disabled={patientCliniciansSelectOptions.length <= 1}>
          <Select
            data={patientCliniciansSelectOptions}
            disabled={patientCliniciansSelectOptions.length <= 1}
            {...form.getInputProps('calendarId')}
          />
        </Tooltip>
      </Td>
      <Td className='mantine'>
        <SecondaryButton
          onClick={submitChanges}
          leftIcon={<SaveIcon />}
          disabled={!hasChangedValues}
          loading={updateHoldMutation.isLoading}
        />
      </Td>
    </tr>
  )
}
