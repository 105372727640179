import { Alert, Skeleton, SlashIcon, Stack, Switch } from '@shared/components'
import { useLunaMutation, useLunaQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'

export const CampaignsSection = () => {
  const { patientId } = usePatient()
  const patientDataQuery = useLunaQuery('GET /patients/:patientId/campaigns/account', {
    params: { patientId },
  })
  const subscribeMutation = useLunaMutation('PUT /patients/:patientId/campaigns/subscribe')

  const accountData = patientDataQuery?.data?.data

  const toggleSubscriptionStatus = () => {
    if (accountData?.subscribed !== undefined) {
      accountData.subscribed = !accountData?.subscribed
      subscribeMutation.mutate({
        params: {
          patientId,
        },
        data: {
          subscribed: accountData?.subscribed,
        },
      })
    }
  }

  if (patientDataQuery.isError) {
    return (
      <Alert variant='error' icon={<SlashIcon />}>
        Error: Unable to retrieve campaign subscription status for patient.
      </Alert>
    )
  }

  return (
    <Stack>
      <Skeleton visible={patientDataQuery.isLoading}>
        <Stack spacing='md'>
          <Switch
            size='sm'
            label='Subscribed to text/email campaigns'
            checked={accountData?.subscribed}
            onChange={toggleSubscriptionStatus}
          />
        </Stack>
      </Skeleton>
    </Stack>
  )
}
