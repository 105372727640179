import {
  Alert,
  CheckIcon,
  Group,
  List,
  ListItem,
  PrimaryButton,
  SecondaryButton,
  SlashIcon,
  SmileIcon,
  Stack,
  Text,
} from '@shared/components'
import { useState } from 'react'
import ALoadingSpinner from '../../../../../components/atoms/ALoadingSpinner'
import { useEmrMutation, useEmrQuery, useInvalidateQuery } from '../../../../../utils/hooks'
import { useInvalidateCollections } from '../../use-invalidate-collections'
import { useInvalidateSubcollections } from '../../use-invalidate-subcollections'

type QuickMergeActionStatusType = 'in-progress' | 'done' | 'failed' | 'not-applicable'

type QuickMergeActionStatuses = {
  deleteAuthStatus?: QuickMergeActionStatusType
  transferCollectionsStatus?: QuickMergeActionStatusType
  transferSubcollectionsStatus?: QuickMergeActionStatusType
}

type QuickMergeActionKey = keyof QuickMergeActionStatuses

type QuickMergeActionStatus = {
  type: QuickMergeActionStatusType
  errorCode?: number
  errorMessage?: string
}

type QuickMergeStatusContentProps = {
  fromPatientId: string
  toPatientId: string
  onBack: () => void
  onNext: () => void
}

export const QuickMergeStatusContent = ({
  fromPatientId,
  toPatientId,
  onBack,
  onNext,
}: QuickMergeStatusContentProps) => {
  const authQuery = useEmrQuery('GET /patient/:patientId/auth', {
    params: { patientId: fromPatientId },
  })
  const authUserExists = authQuery?.data?.exists

  const deleteAuth = useEmrMutation('DELETE /patient/:patientId/auth')

  // All of the collections that the patient can transfer
  const collectionsQuery = useEmrQuery('GET /patient/:patientId/collections', {
    params: { patientId: fromPatientId },
  })
  const collectionsToTransfer = (collectionsQuery.data as string[]) ?? []
  const transferCollections = useEmrMutation('PUT /patient/:patientId/collections/transfer')

  // All of the subcollections that the patient can transfer
  const subCollectionsQuery = useEmrQuery('GET /patient/:patientId/subcollections', {
    params: { patientId: fromPatientId },
  })
  const allSubcollections = (subCollectionsQuery.data as string[]) ?? []
  // By default, we don't want to transfer historicalChanges
  const subcollectionsToTransfer = allSubcollections.filter(col => col !== 'historicalChanges')
  const transferSubcollections = useEmrMutation('PUT /patient/:patientId/subcollections/transfer')

  const invalidate = useInvalidateQuery()
  const invalidateCollections = useInvalidateCollections()
  const invalidateSubcollections = useInvalidateSubcollections()

  const canDeleteAuth = authUserExists
  const canTransferCollections = collectionsToTransfer.length
  const canTransferSubcollections = subcollectionsToTransfer.length

  const [transferButtonClicked, setTransferButtonClicked] = useState<boolean>(false)

  const [deleteAuthStatus, setDeleteAuthStatus] = useState<QuickMergeActionStatus>({
    type: 'in-progress',
  })
  const [transferCollectionsStatus, setTransferCollectionsStatus] =
    useState<QuickMergeActionStatus>({
      type: 'in-progress',
    })
  const [transferSubcollectionsStatus, setTransferSubcollectionsStatus] =
    useState<QuickMergeActionStatus>({
      type: 'in-progress',
    })

  const onDeleteAuth = () => {
    deleteAuth.mutate(
      { params: { patientId: fromPatientId } },
      {
        onSuccess: () => {
          void invalidate('GET /patient/:patientId/validate-auth', {
            params: { patientId: fromPatientId },
          })
          void invalidate('GET /patient/:patientId/auth', {
            params: { patientId: fromPatientId },
          })
          setDeleteAuthStatus({ type: 'done' })
        },
        onError: error => {
          setDeleteAuthStatus({
            type: 'failed',
            errorMessage: error?.response?.data?.message,
            errorCode: error.response?.status,
          })
        },
      },
    )
  }

  const onTransferCollections = () => {
    if (toPatientId) {
      transferCollections.mutate(
        {
          params: { patientId: fromPatientId },
          data: { toPatientId, collections: collectionsToTransfer },
        },
        {
          onSuccess: () => {
            setTransferCollectionsStatus({ type: 'done' })

            invalidateCollections({ patientId: fromPatientId, collections: collectionsToTransfer })
            invalidateCollections({ patientId: toPatientId, collections: collectionsToTransfer })
          },
          onError: error => {
            setTransferCollectionsStatus({
              type: 'failed',
              errorMessage: error?.response?.data?.message,
              errorCode: error.response?.status,
            })
          },
        },
      )
    }
  }

  const onTransferSubcollections = () => {
    if (toPatientId) {
      transferSubcollections.mutate(
        {
          params: { patientId: fromPatientId },
          data: { toPatientId, subcollections: subcollectionsToTransfer },
        },
        {
          onSuccess: () => {
            setTransferSubcollectionsStatus({ type: 'done' })

            invalidateSubcollections({
              patientId: fromPatientId,
              subcollections: collectionsToTransfer,
            })
            invalidateSubcollections({
              patientId: toPatientId,
              subcollections: collectionsToTransfer,
            })
          },
          onError: error => {
            setTransferSubcollectionsStatus({
              type: 'failed',
              errorMessage: error?.response?.data?.message,
              errorCode: error.response?.status,
            })
          },
        },
      )
    }
  }

  const onSubmit = () => {
    setTransferButtonClicked(true)

    if (canDeleteAuth) {
      onDeleteAuth()
    } else {
      setDeleteAuthStatus({ type: 'not-applicable' })
    }

    if (canTransferCollections) {
      onTransferCollections()
    } else {
      setTransferCollectionsStatus({ type: 'not-applicable' })
    }

    if (canTransferSubcollections) {
      onTransferSubcollections()
    } else {
      setTransferSubcollectionsStatus({ type: 'not-applicable' })
    }
  }

  const anyActionsToPerform = canDeleteAuth || canTransferCollections || canTransferSubcollections

  const allActions = [deleteAuthStatus, transferCollectionsStatus, transferSubcollectionsStatus]
  const anyActionInProgress =
    transferButtonClicked && allActions.some(action => action.type === 'in-progress')

  return (
    <Stack>
      {transferButtonClicked ? (
        <Stack>
          <ActionStatus
            actionName='Delete authentication'
            action='deleteAuthStatus'
            status={deleteAuthStatus}
            fromPatientId={fromPatientId}
            toPatientId={toPatientId}
          />
          <ActionStatus
            actionName='Transfer collections'
            action='transferCollectionsStatus'
            status={transferCollectionsStatus}
            fromPatientId={fromPatientId}
            toPatientId={toPatientId}
          />
          <ActionStatus
            actionName='Transfer subcollections'
            action='transferSubcollectionsStatus'
            status={transferSubcollectionsStatus}
            fromPatientId={fromPatientId}
            toPatientId={toPatientId}
          />
          <Group position='right'>
            <SecondaryButton disabled={anyActionInProgress} onClick={onBack}>
              Back
            </SecondaryButton>
            <PrimaryButton disabled={anyActionInProgress} onClick={onNext}>
              Next
            </PrimaryButton>
          </Group>
        </Stack>
      ) : (
        <Stack>
          <Text>The following actions will be performed:</Text>
          <List>
            <ListItem>
              <Text strikethrough={!canDeleteAuth}>Delete authentication</Text>
            </ListItem>
            <ListItem>
              {canTransferCollections ? (
                <Text>{`Transfer collections (${collectionsToTransfer.join(', ')})`}</Text>
              ) : (
                <Text strikethrough>Transfer collections</Text>
              )}
            </ListItem>
            <ListItem>
              {canTransferSubcollections ? (
                <Text>{`Transfer subcollections (${subcollectionsToTransfer.join(', ')})`}</Text>
              ) : (
                <Text strikethrough>Transfer subcollections</Text>
              )}
            </ListItem>
          </List>
          {anyActionsToPerform ? (
            <Group position='right'>
              <SecondaryButton disabled={anyActionInProgress} onClick={onBack}>
                Back
              </SecondaryButton>
              <PrimaryButton
                onClick={onSubmit}
                disabled={!anyActionsToPerform || anyActionInProgress}
              >
                Merge
              </PrimaryButton>
            </Group>
          ) : (
            <>
              <Alert variant='primary' icon={<SmileIcon />}>
                No actions to perform
              </Alert>
              <Group position='right'>
                <SecondaryButton disabled={anyActionInProgress} onClick={onBack}>
                  Back
                </SecondaryButton>
                <PrimaryButton onClick={onNext}>Next</PrimaryButton>
              </Group>
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}

type ActionStatusProps = {
  actionName: string
  action: QuickMergeActionKey
  status: QuickMergeActionStatus
  fromPatientId: string
  toPatientId: string
}

const ActionStatus = ({
  actionName,
  action,
  status,
  fromPatientId,
  toPatientId,
}: ActionStatusProps) => {
  const innerText = getQuickMergeActionText({
    action,
    status,
    fromPatientId,
    toPatientId,
  })
  return (
    <Stack spacing='xs'>
      <Text bold>{`${actionName}:`} </Text>
      <ActionStatusAlert status={status} innerText={innerText} />
    </Stack>
  )
}

type ActionStatusAlertProps = {
  status: QuickMergeActionStatus
  innerText: string
}

const ActionStatusAlert = ({ innerText, status }: ActionStatusAlertProps) => {
  switch (status.type) {
    case 'done':
      return (
        <Alert icon={<CheckIcon />} title='Done' variant='success'>
          {innerText}
        </Alert>
      )
    case 'not-applicable':
      return (
        <Alert icon={<SmileIcon />} title='Not applicable' variant='primary'>
          {innerText}
        </Alert>
      )
    case 'failed':
      return (
        <Stack>
          <Alert icon={<SlashIcon />} title='Failed' variant='error'>
            {innerText}
            <Text bold size='xs'>
              {status?.errorCode && `Error ${status.errorCode}: `}
              {status?.errorMessage && status.errorMessage}
            </Text>
          </Alert>
        </Stack>
      )

    case 'in-progress':
    default:
      return (
        <Alert icon={<ALoadingSpinner />} title='In progress' variant='secondary'>
          {innerText}
        </Alert>
      )
  }
}

type QuickMergeActionText = {
  [key in QuickMergeActionStatusType]: string
}

const getQuickMergeActionText = ({
  action,
  status,
  fromPatientId,
  toPatientId,
}: {
  action: QuickMergeActionKey
  status: QuickMergeActionStatus
  fromPatientId: string
  toPatientId: string
}) => {
  const quickMergeActionTextMapping: Record<QuickMergeActionKey, QuickMergeActionText> = {
    deleteAuthStatus: {
      'in-progress': `Deleting auth for ${fromPatientId}...`,
      done: `Deleted auth for ${fromPatientId}.`,
      failed: `Could not delete auth for ${fromPatientId}.`,
      'not-applicable': `No auth found for ${fromPatientId}.`,
    },
    transferCollectionsStatus: {
      'in-progress': `Transferring collections from ${fromPatientId} to ${toPatientId}...`,
      done: `Transferred collections from ${fromPatientId} to ${toPatientId}.`,
      failed: `Could not transfer collections from ${fromPatientId} to ${toPatientId}.`,
      'not-applicable': `No transferrable collections found for ${fromPatientId}.`,
    },
    transferSubcollectionsStatus: {
      'in-progress': `Transferring subcollections from ${fromPatientId} to ${toPatientId}...`,
      done: `Transferred subcollections from ${fromPatientId} to ${toPatientId}.`,
      failed: `Could not transfer subcollections from ${fromPatientId} to ${toPatientId}.`,
      'not-applicable': `No transferrable subcollections found for ${fromPatientId}.`,
    },
  }

  return quickMergeActionTextMapping[action][status.type] ?? ''
}
