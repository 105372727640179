import { useQueryClient } from 'react-query'
import { emrApi } from '../../api'

export const useTasksCache = () => {
  const queryClient = useQueryClient()

  const invalidate = (patientId?: string) => {
    const [tasksQueryKey] = emrApi.getQuery('GET /tasks/v2')
    const [countsQueryKey] = emrApi.getQuery('GET /tasks/v2/counts')
    void queryClient.invalidateQueries(tasksQueryKey)
    void queryClient.invalidateQueries(countsQueryKey)

    if (patientId) {
      const [issueQueryKey] = emrApi.getQuery('GET /patient/:patientId/issues')
      void queryClient.invalidateQueries(issueQueryKey)
    }
  }

  return {
    invalidate,
  }
}
