import {
  Card,
  Group,
  PrimaryButton,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Th,
  useMantineTheme,
} from '@shared/components'
import { DrugScreen, DrugScreenResults, drugScreenInfo } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import IconShipment from '../../../components/icons/IconShipment'
import { usePatient } from '../PPatientContext'
import { DrugScreenRowIcon } from '../labs/DrugScreenRowIcon'
import { SidebarEmptyState } from './SidebarEmptyState'

type DrugScreenRowProps = {
  drugScreen: DrugScreen
  positiveResults: string[]
}

const DrugScreenRow = ({ drugScreen, positiveResults }: DrugScreenRowProps) => {
  const { results } = drugScreen

  return (
    <tr key={drugScreen.oid}>
      {positiveResults.map(drugScreenOption => {
        const resultIsPositive = results[drugScreenOption as keyof DrugScreenResults]

        return (
          <td key={drugScreenOption}>
            <DrugScreenRowIcon
              isBupe={drugScreenOption === 'drug_screen_buprenorphine_result'}
              resultIsPositive={resultIsPositive}
              sideBarStyling
            />
          </td>
        )
      })}
    </tr>
  )
}

export const LabsContent = ({ active }: { active: boolean }) => {
  const { patientQuery, patientId, setModal } = usePatient()
  const patient = patientQuery?.data
  const {
    other: { colors },
  } = useMantineTheme()

  const drugScreensQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/drugScreens', { params: { patientId } }),
    {
      enabled: active,
    },
  )

  if (drugScreensQuery.isLoading || drugScreensQuery.isError) {
    return (
      <Stack spacing='sm'>
        <Skeleton h='5rem' />
        <Skeleton h='5rem' />
        <Skeleton h='5rem' />
      </Stack>
    )
  }

  const drugScreens =
    drugScreensQuery?.data?.sort(sortBy({ key: 'createdAt', order: 'DESC' })).slice(0, 3) ?? []

  if (drugScreens.length === 0) {
    return (
      <SidebarEmptyState
        pillMessage='No labs'
        textMessage={`${
          patient?.personalData.preferredName || patient?.personalData.firstName
        }'s urine drug screens will appear here`}
      />
    )
  }

  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]}>
        Showing all BUP and positive results
      </Text>
      {drugScreens.map(drugScreen => {
        const positiveDrugScreenResults = Object.keys(drugScreen.results)
          .filter(
            resultKey =>
              resultKey === 'drug_screen_buprenorphine_result' ||
              drugScreen.results[resultKey as keyof typeof drugScreenInfo],
          )
          .sort((a, _) => (a === 'drug_screen_buprenorphine_result' ? -1 : 1))
        return (
          <Card key={drugScreen.oid}>
            <Stack spacing='xs'>
              <Text bold size='xs' style={{ whiteSpace: 'nowrap' }}>
                {dayjs(drugScreen.administeredAt || drugScreen.createdAt).format('MM/DD/YYYY')}
              </Text>
              <ScrollArea scrollbarSize='0.25rem' type='hover'>
                <Table
                  verticalSpacing='sm'
                  horizontalSpacing='xs'
                  withBorder
                  sx={{
                    tableLayout: 'auto',
                    maxWidth: `${positiveDrugScreenResults.length * 4}ch`,
                    backgroundColor: colors.background[0],
                  }}
                >
                  <thead className='mantine'>
                    <tr className='mantine'>
                      {positiveDrugScreenResults.map(result => {
                        const drugScreenResult =
                          drugScreenInfo[result as keyof typeof drugScreenInfo]

                        return (
                          <Th
                            sortable={false}
                            style={{
                              width: `${drugScreenResult.abbreviation.length}ch`,
                              backgroundColor: colors.background[1],
                            }}
                            key={result}
                          >
                            <Text bold size='xs'>
                              {drugScreenResult.abbreviation}
                            </Text>
                          </Th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <DrugScreenRow
                      positiveResults={positiveDrugScreenResults}
                      key={drugScreen.oid}
                      drugScreen={drugScreen}
                    />
                  </tbody>
                </Table>
              </ScrollArea>
            </Stack>
          </Card>
        )
      })}
      <Group position='right'>
        <PrimaryButton
          leftIcon={<IconShipment />}
          size='xs'
          onClick={() => setModal({ type: 'order-uds-cup' })}
        >
          Order UDS cup
        </PrimaryButton>
      </Group>
    </Stack>
  )
}
