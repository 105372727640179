import { useClipboard } from '@mantine/hooks'
import {
  Accordion,
  Box,
  CircleWithText,
  Group,
  ScrollArea,
  Stack,
  Text,
  TitleFour,
  Tooltip,
} from '@shared/components'
import { Appointment, getVisitTypeName } from '@shared/types'
import { dayjs, sortBy, template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { default as uniq } from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { Visit } from '../../../api/types'
import { VisitPillStatus } from '../../../components/VisitPillStatus'
import { usePatient } from '../PPatientContext'

const ONE_THOUSAND = 1000

const readableTimestamp = (createdAt: number) => {
  const date = dayjs(createdAt * ONE_THOUSAND).format('MM/DD/YYYY')
  const time = dayjs(createdAt * ONE_THOUSAND).format('h:mma z')
  return `${date} ${time}`
}

type AppointmentHistoryProps = {
  appointment: Visit
  setHistoryLength: (historyLength: number) => void
  setMeetingIds: (meetingIds: string[]) => void
  meetingIds: string[]
}

const AppointmentHistoryDetails = ({ apptHistory }: { apptHistory: Appointment }) => {
  return (
    <Box
      sx={({ other: { colors }, radius }) => ({
        backgroundColor: colors.background[2],
        borderRadius: radius.sm,
      })}
    >
      <Accordion>
        <Accordion.Item
          value={`zoomDetails_${apptHistory.oid}_${apptHistory.timestamps.createdAt}`}
        >
          <Accordion.Control>
            <Text bold>
              View Zoom meeting details from appointment updated at{' '}
              {readableTimestamp(apptHistory.timestamps.updatedAt)}
            </Text>
          </Accordion.Control>
          <Accordion.Panel
            mx='md'
            sx={({ other: { colors }, radius }) => ({
              backgroundColor: colors.background[0],
              borderRadius: radius.sm,
            })}
          >
            <JsonStringified
              details={{
                location: apptHistory.location,
                ...apptHistory.metadata?.virtualAppointmentDetails,
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item
          value={`zoomDetails_${apptHistory.oid}_${apptHistory.timestamps.createdAt}`}
        >
          <Accordion.Control>
            <Text bold>
              View appointment details from {readableTimestamp(apptHistory.timestamps.updatedAt)}
            </Text>
          </Accordion.Control>
          <Accordion.Panel
            mx='md'
            sx={({ other: { colors }, radius }) => ({
              backgroundColor: colors.background[0],
              borderRadius: radius.sm,
            })}
          >
            <JsonStringified details={apptHistory} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}

const AppointmentHistory = ({
  appointment,
  setHistoryLength,
  setMeetingIds,
  meetingIds,
}: AppointmentHistoryProps) => {
  const appointmentScheduleHistoryQuery = useQuery(
    ...emrApi.getQuery('GET /appointments/:appointmentId/history', {
      params: {
        appointmentId: appointment.oid,
      },
    }),
    {
      onSuccess: history => {
        if (history && history?.length > 0) {
          setHistoryLength(history.length)
          history.forEach(h => {
            if (h.metadata?.virtualAppointmentDetails?.id) {
              setMeetingIds([String(h.metadata.virtualAppointmentDetails.id), ...meetingIds])
            }
            if (h.metadata?.virtualAppointmentDetails?.patientJoinUrl) {
              const meetingId = h.metadata.virtualAppointmentDetails.patientJoinUrl
                .split('/w/')[1]
                ?.split('?tk=')[0]
              setMeetingIds([String(meetingId), ...meetingIds])
            }
            if (h.metadata?.virtualAppointmentDetails?.clinicianJoinUrl) {
              const meetingId = h.metadata.virtualAppointmentDetails.clinicianJoinUrl
                .split('/s/')[1]
                ?.split('?zak=')[0]
              setMeetingIds([String(meetingId), ...meetingIds])
            }
            if (h.metadata?.virtualAppointmentDetails?.generalJoinUrl) {
              const meetingId = h.metadata.virtualAppointmentDetails.generalJoinUrl
                .split('/j/')[1]
                ?.split('?pwd=')[0]
              setMeetingIds([String(meetingId), ...meetingIds])
            }
            if (h.location?.includes('Meeting ID:')) {
              const meetingId = h.location.split('Meeting ID: ')[1]?.split(' ')[0] as string
              setMeetingIds([meetingId, ...meetingIds])
            } else if (h.location?.includes('ophelia.zoom.us/w/')) {
              const meetingId = h.location
                .split('ophelia.zoom.us/w/')[1]
                ?.split('?tk=')[0] as string
              setMeetingIds([meetingId, ...meetingIds])
            }
          })
        }
      },
    },
  )
  const history = appointmentScheduleHistoryQuery.data

  return (
    <Stack>
      {history
        ?.sort((apptA, apptB) => apptB.timestamps.updatedAt - apptA.timestamps.updatedAt)
        .map(apptHistory => (
          <AppointmentHistoryDetails
            key={`${apptHistory.oid}_${apptHistory.timestamps.updatedAt}`}
            apptHistory={apptHistory}
          />
        ))}
    </Stack>
  )
}

const AppointmentDetailsHeader = ({ appointment }: { appointment: Visit }) => {
  const clipboard = useClipboard()
  const appointmentType = template('{visitType} ({duration} min)', {
    visitType: getVisitTypeName(appointment.type),
    duration: appointment?.duration,
  })
  return (
    <Stack spacing='xs'>
      <Group position='apart'>
        <Group position='left'>
          <Tooltip label={clipboard.copied ? 'Copied!' : `Copy appointmentId ${appointment.oid}`}>
            <TitleFour
              style={{ cursor: 'pointer' }}
              onClick={() => clipboard.copy(appointment.oid)}
            >
              {appointmentType}
            </TitleFour>
          </Tooltip>
          <Text bold>{dayjs(appointment.datetime).format('MM/DD/YYYY h:mma z')}</Text>
        </Group>
        <Group position='right'>
          <Tooltip
            label={clipboard.copied ? 'Copied!' : `Copy employeeId ${appointment.employeeId}`}
          >
            <Text
              inherit
              span
              bold
              style={{ cursor: 'pointer' }}
              onClick={() => clipboard.copy(appointment.employeeId)}
            >
              {appointment.calendar}
            </Text>
          </Tooltip>
          <VisitPillStatus visit={appointment} />
        </Group>
      </Group>
      <Group position='apart'>
        <Group noWrap position='left'></Group>
      </Group>
      <Group>
        <Text size='xs' bold>
          Created at: {readableTimestamp(appointment.timestamps.createdAt)}
        </Text>
        <Text size='xs' bold>
          Last updated at: {readableTimestamp(appointment.timestamps.updatedAt)}
        </Text>
      </Group>
    </Stack>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const JsonStringified = ({ details }: { details: any }) => {
  return (
    <ScrollArea mx='md' mb='md' pl='lg'>
      <pre>
        <code style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(details, null, 4)}
        </code>
      </pre>
    </ScrollArea>
  )
}

const ZoomWebhookEvents = ({ meetingId }: { meetingId: string }) => {
  const zoomWebhookEventsQuery = useQuery(
    ...emrApi.getQuery('GET /zoomWebhookEvents/:meetingId', {
      params: { meetingId },
    }),
    { enabled: Boolean(meetingId) },
  )
  const zoomWebhookEvents = zoomWebhookEventsQuery?.data || []

  const uniqueZoomEvents = uniqBy(zoomWebhookEvents, 'event_ts')
  return (
    <Accordion.Item value={meetingId}>
      <Accordion.Control>
        <Text bold>Zoom Meeting ID: {meetingId}</Text>
      </Accordion.Control>
      <Accordion.Panel
        mx='md'
        sx={({ other: { colors }, radius }) => ({
          backgroundColor: colors.background[0],
          borderRadius: radius.sm,
        })}
      >
        {uniqueZoomEvents.sort(sortBy({ key: 'event_ts', order: 'DESC' })).map(zoomWebhookEvent => {
          const eventType = zoomWebhookEvent.event.split('.')[1]?.replaceAll('_', ' ')
          const datetime = dayjs(zoomWebhookEvent.event_ts).format('MM/DD/YYYY h:mma z')

          return (
            <Accordion key={zoomWebhookEvent.oid}>
              <Accordion.Item value={zoomWebhookEvent.oid}>
                <Accordion.Control>
                  <Group position='apart'>
                    <Text bold>{capitalize(eventType)}</Text>
                    <Text>{datetime}</Text>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel
                  mx='md'
                  sx={({ other: { colors }, radius }) => ({
                    backgroundColor: colors.background[0],
                    borderRadius: radius.sm,
                  })}
                >
                  <JsonStringified details={zoomWebhookEvent} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )
        })}
        {uniqueZoomEvents.length === 0 && <Text>No Zoom webhook events found.</Text>}
      </Accordion.Panel>
    </Accordion.Item>
  )
}

const AppointmentDetails = ({ appointment }: { appointment: Visit }) => {
  const [historyLength, setHistoryLength] = useState<number>(0)

  const [meetingIds, setMeetingIds] = useState<string[]>([])

  useEffect(() => {
    if (appointment) {
      if (appointment.metadata?.virtualAppointmentDetails?.id) {
        setMeetingIds(
          uniq([String(appointment.metadata.virtualAppointmentDetails.id), ...meetingIds]),
        )
      }
      if (appointment.metadata?.virtualAppointmentDetails?.patientJoinUrl) {
        const meetingId = appointment.metadata.virtualAppointmentDetails.patientJoinUrl
          .split('/w/')[1]
          ?.split('?tk=')[0]
        setMeetingIds([String(meetingId), ...meetingIds])
      }
      if (appointment.metadata?.virtualAppointmentDetails?.clinicianJoinUrl) {
        const meetingId = appointment.metadata.virtualAppointmentDetails.clinicianJoinUrl
          .split('/s/')[1]
          ?.split('?zak=')[0]
        setMeetingIds([String(meetingId), ...meetingIds])
      }
      if (appointment.metadata?.virtualAppointmentDetails?.generalJoinUrl) {
        const meetingId = appointment.metadata.virtualAppointmentDetails.generalJoinUrl
          .split('/j/')[1]
          ?.split('?pwd=')[0]
        setMeetingIds([String(meetingId), ...meetingIds])
      }
      if (appointment.location?.includes('Meeting ID:')) {
        const meetingId = appointment.location.split('Meeting ID: ')[1]?.split(' ')[0] ?? ''
        setMeetingIds([meetingId, ...meetingIds])
      } else if (appointment.location?.includes('ophelia.zoom.us/w/')) {
        const meetingId =
          appointment.location.split('ophelia.zoom.us/w/')[1]?.split('?tk=')[0] ?? ''
        setMeetingIds([meetingId, ...meetingIds])
      }
    }
  }, [appointment])

  return (
    <Box
      p='md'
      sx={({ other: { colors }, radius }) => ({
        backgroundColor: colors.background[1],
        borderRadius: radius.sm,
        maxWidth: '70vw',
      })}
    >
      <AppointmentDetailsHeader appointment={appointment} />
      <Accordion multiple mt='md'>
        {uniq(meetingIds).map(meetingId => {
          return <ZoomWebhookEvents key={meetingId} meetingId={meetingId} />
        })}

        <Accordion.Item value='zoom-details'>
          <Accordion.Control>
            <Text bold>View Zoom meeting details</Text>
          </Accordion.Control>
          <Accordion.Panel
            mx='md'
            sx={({ other: { colors }, radius }) => ({
              backgroundColor: colors.background[0],
              borderRadius: radius.sm,
            })}
          >
            <JsonStringified
              details={{
                location: appointment.location,
                ...appointment.metadata?.virtualAppointmentDetails,
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='appointment-details'>
          <Accordion.Control>
            <Text bold>View full appointment details</Text>
          </Accordion.Control>
          <Accordion.Panel
            mx='md'
            sx={({ other: { colors }, radius }) => ({
              backgroundColor: colors.background[0],
              borderRadius: radius.sm,
            })}
          >
            <JsonStringified details={appointment} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='history'>
          <Accordion.Control>
            <Group>
              <Text bold>View appointment history</Text>
              <CircleWithText>{historyLength}</CircleWithText>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Box mx='md' pl='md'>
              <AppointmentHistory
                appointment={appointment}
                setHistoryLength={setHistoryLength}
                setMeetingIds={setMeetingIds}
                meetingIds={meetingIds}
              />
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}
export const ZoomEventsSection = () => {
  const { patientId } = usePatient()

  const appointmentsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/appointments', {
      params: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )
  const appointments = appointmentsQuery.data

  return (
    <Stack>
      {appointments
        ?.sort(sortBy({ key: 'datetime', order: 'DESC' }))
        ?.map(appointment => (
          <AppointmentDetails key={appointment.oid} appointment={appointment} />
        ))}
    </Stack>
  )
}
