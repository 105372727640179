import React from 'react'

export type ANoteComponentGroupProps = {
  children: React.ReactNode
  groupTitle: string
  className: string
}

const ANoteComponentGroup = ({ children, groupTitle, className }: ANoteComponentGroupProps) => {
  return (
    <div className={`${className ?? ''} col-span-6 grid gap-y-2 gap-x-4 grid-cols-6`}>
      <p className='block text-sm font-medium leading-5 text-gray-700 col-span-6'>{groupTitle}</p>
      {children}
    </div>
  )
}

export default ANoteComponentGroup
