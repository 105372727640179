import {
  DrawerContent,
  DrawerHeader,
  PlusIcon,
  PrimaryButton,
  Stack,
  Table,
  TertiaryButton,
  Text,
  Th,
  useMantineTheme,
} from '@shared/components'
import { Patient } from '@shared/types'
import { name } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-router-dom'
import NoDataRow from '../../components/atoms/NoDataRow'
import { useIdentifyDuplicatePatients } from '../../utils/hooks/use-identify-duplicate-patients'
import { useSidePane } from '../../utils/hooks/use-side-pane'
import { IssueDrawer } from '../care_team/tasks/IssueDrawer'

export const KnownDuplicateAccountsDrawer = ({
  patient,
  onClose,
}: {
  patient: Patient
  onClose: () => void
}) => {
  const { allDuplicates } = useIdentifyDuplicatePatients({ patient })
  const { presentPane, hidePane } = useSidePane()
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <>
      <DrawerHeader onClose={onClose}>Known duplicate accounts</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          <Text>List of patients with a matching phone number or email:</Text>
          <Table
            striped
            withBorder
            verticalSpacing='sm'
            horizontalSpacing='sm'
            sx={{
              tableLayout: 'auto',
              backgroundColor: colors.background[0],
              borderWidth: sizes.border.md,
              borderStyle: 'solid',
              borderColor: colors.background[3],
            }}
          >
            <thead className='mantine'>
              <tr className='mantine'>
                <Th sortable={false} key='patientName'>
                  Patient name
                </Th>
                <Th sortable={false} key='status'>
                  Status
                </Th>
                <Th sortable={false} key='phone'>
                  Phone
                </Th>
                <Th sortable={false} key='email'>
                  Email
                </Th>
              </tr>
            </thead>
            <tbody>
              {allDuplicates.length === 0 && <NoDataRow message='No files' headersLength={4} />}
              {allDuplicates.length > 0 &&
                allDuplicates.map(dupePatient => (
                  <tr key={dupePatient?.oid} style={{ wordBreak: 'break-word' }}>
                    <td>
                      <TertiaryButton
                        component={Link}
                        to={`/patients/${dupePatient?.oid}`}
                        target='_blank'
                      >
                        {name({
                          first: dupePatient?.personalData?.firstName,
                          last: dupePatient?.personalData?.lastName,
                        }).full() || 'N/A'}
                      </TertiaryButton>
                    </td>
                    <td style={{ wordBreak: 'normal' }}>
                      {capitalize(dupePatient?.statuses?.patient)}
                    </td>
                    <td style={{ wordBreak: 'normal' }}>{dupePatient?.personalData?.phone}</td>
                    <td>{dupePatient?.personalData?.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <PrimaryButton
            leftIcon={<PlusIcon />}
            onClick={() => {
              presentPane({
                key: `add-issue-${patient?.oid}`,
                content: (
                  <IssueDrawer
                    step='add'
                    issueType='duplicate_accounts'
                    patient={patient}
                    onClose={hidePane}
                  />
                ),
              })
            }}
          >
            Add issue
          </PrimaryButton>
        </Stack>
      </DrawerContent>
    </>
  )
}
