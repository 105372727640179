import { useQueries } from 'react-query'
import { emrApi } from '../../api'

export type StartAfter = {
  'PHQ-9'?: string
  'PHQ-8'?: string
  'GAD-7'?: string
  'BARC-10'?: string
  ASQ?: string
}

type UseMbcResponsesProps = {
  patientId: string
  startAfter: StartAfter
}

export function useMbcResponses({ patientId, startAfter }: UseMbcResponsesProps) {
  const [phq8ResponsesQueryKey, phq8ResponsesQueryFunction] = emrApi.getQuery(
    `GET /patients/:patientId/phq8Responses`,
    {
      params: {
        patientId,
      },
      query: {
        startAfter: startAfter['PHQ-8'] || '',
      },
    },
  )

  const [gad7ResponsesQueryKey, gad7ResponsesQueryFunction] = emrApi.getQuery(
    `GET /patients/:patientId/gad7Responses`,
    {
      params: {
        patientId,
      },
      query: {
        startAfter: startAfter['GAD-7'] || '',
      },
    },
  )

  const [barc10ResponsesQueryKey, barc10ResponsesQueryFunction] = emrApi.getQuery(
    `GET /patients/:patientId/barc10Responses`,
    {
      params: {
        patientId,
      },
      query: {
        startAfter: startAfter['BARC-10'] || '',
      },
    },
  )

  const [asqResponsesQueryKey, asqResponsesQueryFunction] = emrApi.getQuery(
    `GET /patients/:patientId/asqResponses`,
    {
      params: {
        patientId,
      },
      query: {
        startAfter: startAfter.ASQ || '',
      },
    },
  )

  const [gad7ResponsesQuery, barc10ResponsesQuery, asqResponsesQuery, phq8ResponsesQuery] =
    useQueries([
      {
        queryKey: gad7ResponsesQueryKey,
        queryFn: gad7ResponsesQueryFunction,
      },
      {
        queryKey: barc10ResponsesQueryKey,
        queryFn: barc10ResponsesQueryFunction,
      },
      {
        queryKey: asqResponsesQueryKey,
        queryFn: asqResponsesQueryFunction,
      },
      {
        queryKey: phq8ResponsesQueryKey,
        queryFn: phq8ResponsesQueryFunction,
      },
    ])

  return {
    gad7ResponsesQuery,
    barc10ResponsesQuery,
    asqResponsesQuery,
    phq8ResponsesQuery,
  }
}
