import {
  AlertIcon,
  Banner,
  CheckCircleIcon,
  Group,
  SecondaryButton,
  Stack,
  Text,
  TitleFour,
  useMantineTheme,
} from '@shared/components'
import { InsuranceReviewQueueItem, InsuranceType } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { InsuranceVerificationDrawer } from './InsuranceVerificationDrawer'
import { getDateLastVerified, isInsuranceActive } from './utils'
export type IRQInsuranceVerificationSectionProps = {
  item: InsuranceReviewQueueItem
  insuranceType: InsuranceType
  refreshQueue: () => void
}

const IRQInsuranceVerificationSection = ({
  item,
  insuranceType,
  refreshQueue,
}: IRQInsuranceVerificationSectionProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const insuranceData = insuranceType === 'primary' ? item.insurance : item.secondaryInsurance
  const eligibleInsuranceData =
    insuranceType === 'primary' ? item.eligibleInsuranceData : item.secondaryEligibleInsuranceData

  const manualInsuranceData =
    insuranceType === 'primary' ? item.manualInsuranceData : item.secondaryManualInsuranceData

  const isVerified = Boolean(eligibleInsuranceData || manualInsuranceData)
  const verificationDate = getDateLastVerified({
    eligibleInsuranceData,
    manualInsuranceData,
  })

  const verificationStatusType = isVerified ? 'success' : 'error'
  const VerificationStatusIcon = isVerified ? CheckCircleIcon : AlertIcon

  const isActive = isInsuranceActive({
    eligibleInsuranceData,
    manualInsuranceData,
  })
  const activeStatusBannerType = isActive ? 'success' : 'warning'
  const ActiveStatusBannerIcon = isActive ? CheckCircleIcon : AlertIcon

  const frontFile =
    insuranceType === 'primary'
      ? item.files.primaryInsuranceFront
      : item.files.secondaryInsuranceFront
  const backFile =
    insuranceType === 'primary'
      ? item.files.primaryInsuranceBack
      : item.files.secondaryInsuranceBack

  const frontFileId = frontFile?.oid
  const backFileId = backFile?.oid

  return (
    <Stack>
      <InsuranceVerificationDrawer
        key={`insurance_verification_drawer_${item.userId}`}
        patientId={item.userId}
        opened={isDrawerOpen}
        insuranceData={insuranceData ?? undefined}
        manualInsuranceData={manualInsuranceData}
        insuranceType={insuranceType}
        frontFileId={frontFileId}
        backFileId={backFileId}
        onClose={() => {
          setIsDrawerOpen(false)
        }}
        onSave={() => {
          setIsDrawerOpen(false)
          refreshQueue()
        }}
      />
      {isVerified && (
        <Banner
          type={activeStatusBannerType}
          label={`Insurance is ${isActive ? 'active' : 'inactive'}`}
          key={`${item.userId}__active_status_banner`}
          icon={<ActiveStatusBannerIcon color={colors[activeStatusBannerType][0]} />}
          variant='action'
          rightIcon={
            <Text>{verificationDate && dayjs(verificationDate).format('MMM D, YYYY')}</Text>
          }
        />
      )}
      <Group position='apart'>
        <Group spacing='sm'>
          <TitleFour>Insurance verification</TitleFour>
          <Group spacing='xs'>
            <VerificationStatusIcon color={colors[verificationStatusType][0]} size='sm' />
            <Text>{isVerified ? 'verified' : 'not verified'}</Text>
          </Group>
        </Group>
        <SecondaryButton onClick={() => setIsDrawerOpen(true)}>
          {`Edit & ${isVerified ? 're-verify' : 'verify'}`}
        </SecondaryButton>
      </Group>
    </Stack>
  )
}

export default IRQInsuranceVerificationSection
