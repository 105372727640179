import { Alert, Banner, ShieldIcon, Skeleton, Stack, Table, Th } from '@shared/components'
import {
  DoseSpotPayerDemographic,
  DoseSpotPayerDemographicGender,
  DoseSpotPharmacyType,
} from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

export const EligibilityContent = () => {
  const { patientId } = usePatient()

  const [eligibilitiesQueryKey, eligibilitiesQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/dosespotEligibilities',
    { params: { patientId } },
  )

  const eligibilitiesQuery = useQuery(eligibilitiesQueryKey, eligibilitiesQueryFunction)

  if (eligibilitiesQuery.isLoading) {
    return <Skeleton w='100%' h='100px' />
  }

  if (!eligibilitiesQuery.data?.Items) {
    return <Banner type='error' label='Error fetching patient eligbilities' />
  }

  if (eligibilitiesQuery.data?.Items.length === 0) {
    return (
      <Alert icon={<ShieldIcon />} variant='warning'>
        Patient has no active coverage
      </Alert>
    )
  }

  const eligbilities = eligibilitiesQuery.data.Items
  const headers = [
    'DoseSpot Eligibility ID',
    'Payer ID',
    'Payer Name',
    'Plan Name',
    'Member ID',
    'Pharmacy Eligibilities',
    'Demographics',
  ]

  const demographicsString = (demographics: DoseSpotPayerDemographic) => {
    return [
      `Name: ${demographics.FirstName} ${demographics.MiddleName ?? ''} ${demographics.LastName} ${
        demographics.Suffix ?? ''
      }`,
      `DOB: ${demographics.DateOfBirth}`,
      `Gender: ${DoseSpotPayerDemographicGender[demographics.Gender]}`,
      `Address:\n\t${demographics.Address.AddressLine1} ${demographics.Address.AddressLine2 ?? ''}`,
      `\t${demographics.Address.City}, ${demographics.Address.State} ${demographics.Address.ZipCode}`,
    ].join('\n')
  }

  const data = eligbilities?.map(e => [
    e.PatientEligibilityId,
    e.PayerId,
    e.PayerName,
    e.PlanName,
    e.MemberId,
    e.PharmacyEligibilities
      // EligibilityStatus: 1 == 'Covered'
      .filter(pe => pe.EligibilityStatus === 1)
      .map(pe => `${DoseSpotPharmacyType[pe.PharmacyType]}`)
      .join('\n'),
    demographicsString(e.Demographics),
  ])

  return (
    <Stack pt='sm' maw='100%' style={{ overflow: 'scroll' }}>
      <Table striped>
        <thead>
          <tr>
            {headers.map(key => (
              <Th key={key} sortable={false}>
                {key}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(entry => (
            <tr key={JSON.stringify(entry)}>
              {entry.map(e => (
                <td
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre',
                    maxWidth: '10%',
                  }}
                  key={e}
                >
                  {e}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  )
}
