import { useForm } from '@mantine/form'
import {
  CheckCircleIcon,
  ChevronRightIcon,
  Group,
  Modal,
  NumberInput,
  PrimaryButton,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { EMRTaskDecorated, EMRTaskTypeMap } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { isPositiveNumber, isRequired } from '../../../utils/formValidation'
import { TaskProgress } from './TaskProgress'

export const useTimeSpentForm = () =>
  useForm<{ minutes: number | ''; isBillableCoCM: boolean }>({
    initialValues: { minutes: '', isBillableCoCM: false },
    validate: { minutes: validateWith(isRequired, isPositiveNumber) },
  })

export type Props = {
  form: ReturnType<typeof useTimeSpentForm>
  onClose: () => void
  step: { current: number; total: number }
  title: string
  task: EMRTaskDecorated
  next: () => void
}

const BILLABLE_TASKS = EMRTaskTypeMap.filter(taskType => taskType.cocm_billable)

export const TimeSpentForm = ({ form, onClose, step, title, task, next }: Props) => {
  const [cocmQueryKey, cocmQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    { params: { patientId: task.patientId || '' } },
  )

  const patientCocmRecordQuery = useQuery(cocmQueryKey, cocmQueryFunction, {
    onSuccess: patientCocmRecord => {
      form.setFieldValue('isBillableCoCM', Boolean(patientCocmRecord))
    },
    enabled: Boolean(task.patientId),
  })

  const billableTask = BILLABLE_TASKS.find(billableTasks => billableTasks.value === task.type)
  const billableTaskLabel = billableTask?.label || ''
  const isBillableTask = Boolean(billableTask)

  return (
    <Modal
      opened
      onClose={onClose}
      title={title}
      footer={
        <Group position='right'>
          <PrimaryButton
            // Do not allow users to proceed until cocm billable status is known
            disabled={patientCocmRecordQuery.isLoading}
            onClick={() => {
              if (!form.validate().hasErrors) {
                next()
              }
            }}
            rightIcon={<ChevronRightIcon />}
          >
            Next
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <TaskProgress step={step.current} total={step.total} />
        <Text>
          We need you to estimate how much you&apos;ve spent on this issue since you picked it up.
        </Text>
        {form.values.isBillableCoCM && isBillableTask && (
          <>
            <Group spacing='sm' noWrap align='center'>
              <CheckCircleIcon color={colors => colors.success[0]} />
              <Text>Patient is participating in CoCM</Text>
            </Group>
            <Group spacing='sm' noWrap align='center'>
              <CheckCircleIcon color={colors => colors.success[0]} />
              <Text>{billableTaskLabel} is a qualifying CoCM activity</Text>
            </Group>
          </>
        )}
        <NumberInput
          label={`Minutes spent ${billableTaskLabel.toLowerCase()}`}
          placeholder='Total minutes'
          {...form.getInputProps('minutes')}
        />
        <Text size='xs' color={colors => colors.text[1]}>
          This estimate is true and accurate to the best of my knowledge. I understand that I am
          being asked for this estimate for insurance billing purposes.
        </Text>
      </Stack>
    </Modal>
  )
}
