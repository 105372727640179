import { ArrowRightIcon, Box, Group, Pill, Stack, TertiaryButton, Text } from '@shared/components'
import { ShipmentResult } from '@shared/types'
import { dayjs } from '@shared/utils'

type LabOrderRowProps = {
  labOrder: ShipmentResult
  allowRetry: boolean
  retryOnClick: () => void
  allowCancelation: boolean
  cancelOnClick: () => void
}

const StatusPill = ({
  shipped,
  failed,
  canceled,
}: {
  shipped: boolean
  failed: boolean | undefined
  canceled: boolean | undefined
}) => {
  let text
  let status: 'success' | 'error' | 'warning'

  if (shipped) {
    text = 'Shipped'
    status = 'success'
  } else {
    text = 'Initiated'
    status = 'warning'
  }

  if (failed) {
    text = 'Failed'
    status = 'error'
  }

  if (canceled) {
    text = 'Canceled'
    status = 'error'
  }

  return (
    <Pill status={status} variant='filled'>
      <Text bold>{text}</Text>
    </Pill>
  )
}

const LabOrderRow = ({
  labOrder,
  allowRetry,
  retryOnClick,
  allowCancelation,
  cancelOnClick,
}: LabOrderRowProps) => {
  const { timestamp, provider, rationale, shipped, failed, canceled, public_tracking_url } =
    labOrder

  function handleClick() {
    window.open(public_tracking_url, '_blank')
  }

  return (
    <Box
      sx={({ other: { colors } }) => ({
        backgroundColor: colors.background[1],
        ':hover': {
          cursor: shipped ? 'pointer' : 'default',
        },
      })}
      p='md'
      onClick={() => {
        if (shipped) {
          handleClick()
        }
      }}
    >
      <Stack spacing='sm'>
        <Group position='apart'>
          <Text bold>{rationale}</Text>
          <StatusPill shipped={shipped} failed={failed} canceled={canceled} />
        </Group>
        <Group position='apart'>
          <Group spacing='xs'>
            <Text>{dayjs(timestamp).format('MM/DD/YYYY')}</Text>
            <Text>|</Text>
            <Text>{provider}</Text>
          </Group>
          {allowRetry && (
            <TertiaryButton
              rightIcon={<ArrowRightIcon color={colors => colors.actions[0]} />}
              onClick={retryOnClick}
            >
              Retry
            </TertiaryButton>
          )}
          {allowCancelation && <TertiaryButton onClick={cancelOnClick}>Cancel</TertiaryButton>}
        </Group>
      </Stack>
    </Box>
  )
}

export default LabOrderRow
