import {
  ColumnsIcon,
  Group,
  TertiaryButton,
  Text,
  hideNotification,
  showNotification,
} from '@shared/components'
import {
  EMRTaskDecorated,
  EMRTaskPod,
  EMRTaskType,
  TaskTypeMap,
  hasGroupRole,
  isClinician,
} from '@shared/types'
import { toTime } from '@shared/utils'
import differenceBy from 'lodash/differenceBy'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import { getDefaultPod } from '../../pages/care_team/tasks/filterFormHelpers'

export const useOpenIssues = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [previousIssues, setPreviousIssues] = useState<EMRTaskDecorated[] | null>()
  const shouldShowToastNotifications = hasGroupRole(
    currentUser,
    'engineer',
    'enrollmentCoordinator',
  )

  const defaultPod = getDefaultPod(currentUser)
  const podQuery = ['assigned_to_me', 'opened_by_me'].includes(defaultPod || '')
    ? undefined
    : (defaultPod as EMRTaskPod) || undefined

  const [assignedToMeTasksQueryKey, assignedToMeTasksQueryFn] = emrApi.getQuery('GET /tasks/v2', {
    query: {
      pod: podQuery,
      status: 'open',
      employeeId: currentUser.oid,
    },
  })
  const [unassignedTasksQueryKey, unassignedTasksQueryFn] = emrApi.getQuery('GET /tasks/v2', {
    query: {
      pod: podQuery,
      status: 'open',
      employeeId: '',
    },
  })

  const onClick = ({ notificationId, url }: { notificationId: string; url: string }) => {
    hideNotification(notificationId)
    navigate(url)
  }

  const detectNewIssueAndTrigger = (latestIssues: EMRTaskDecorated[]) => {
    if (!shouldShowToastNotifications) {
      return
    }

    if (!previousIssues) {
      setPreviousIssues(latestIssues)
      return
    }
    if (!isEqual(new Set(previousIssues), new Set(latestIssues))) {
      /*
       * Never spam an EMR user's notification list - if for some reason a bunch come in, truncate it
       * to the first 3. This should only really happen if a user changes their EMR user role
       */
      const notifiableIssueTypes: EMRTaskType[] = ['call_me_now']
      const newIssues = differenceBy(latestIssues, previousIssues, 'oid')
        .filter(issue => notifiableIssueTypes.includes(issue.type))
        .slice(0, 3)
      newIssues.forEach(issue => {
        showNotification({
          id: issue.oid,
          title: <Text bold>New issue in your team&apos;s queue</Text>,
          message: (
            <Group position='apart'>
              <Text>{TaskTypeMap.find(taskType => taskType.value === issue.type)?.label}</Text>
              <TertiaryButton
                leftIcon={<ColumnsIcon />}
                onClick={() =>
                  onClick({ notificationId: issue.oid, url: `/issues/open?taskId=${issue.oid}` })
                }
              >
                Issue
              </TertiaryButton>
            </Group>
          ),
          autoClose: toTime('1 min').ms(),
          variant: 'neutral',
        })
      })

      setPreviousIssues(latestIssues)
    }
  }

  const queries = isClinician(currentUser)
    ? [assignedToMeTasksQueryFn()]
    : [assignedToMeTasksQueryFn(), unassignedTasksQueryFn()]

  const taskQuery = useQuery(
    `${assignedToMeTasksQueryKey}_${unassignedTasksQueryKey}`,
    () => Promise.all(queries),
    {
      enabled: Boolean(currentUser.oid) && shouldShowToastNotifications,
      refetchInterval: toTime('10 sec').ms(),
      onSuccess(data) {
        detectNewIssueAndTrigger(data.flat())
      },
    },
  )

  return {
    count: taskQuery.data?.flat().length ?? 0,
  }
}
