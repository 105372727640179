import { Banner, Grid, Stack } from '@shared/components'
import {
  Prompt as PromptType,
  PromptContentResponse,
  PromptResponse as PromptResponseType,
} from '@shared/types'
import { PromptHeader } from './PromptHeader'
import { PromptResponse } from './PromptResponse'

export type PromptProps = {
  prompt: PromptType
  response?: PromptResponseType
}
export const Prompt = ({ prompt }: PromptProps) => {
  const responseBlocks = prompt.content.filter(
    item => item.contentType === 'response',
  ) as PromptContentResponse[]
  const isShort = responseBlocks.every(item => item.responseType === 'short_input')
  const HALF_GRID_WIDTH_COLSPAN = 6
  const FULL_GRID_WIDTH_COLSPAN = 12
  return (
    <Grid.Col span={isShort ? HALF_GRID_WIDTH_COLSPAN : FULL_GRID_WIDTH_COLSPAN}>
      <Stack spacing='sm'>
        {prompt.content.map(contentItem => {
          switch (contentItem.contentType) {
            case 'header':
              return <PromptHeader {...contentItem} key={`${prompt.contextKey}_header`} />
            case 'response':
              return (
                <PromptResponse
                  {...contentItem}
                  promptContextKey={prompt.contextKey}
                  key={`${prompt.contextKey}_response`}
                />
              )
            case 'banner':
              return (
                <Banner
                  type={contentItem.type}
                  label={contentItem.label}
                  key={`${prompt.contextKey}_banner`}
                />
              )
            default:
              return null
          }
        })}
      </Stack>
    </Grid.Col>
  )
}
