import { useForm } from '@mantine/form'
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  Stack,
  Text,
  Textarea,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PatientReleaseOfInformation, ReleaseOfInformationStatus } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { releaseOfInformationApi } from '../../../../api'
import { isRequired } from '../../../../utils/formValidation'
import { usePatient } from '../../PPatientContext'

type Form = {
  reviewStatus: ReleaseOfInformationStatus | null
  changeRequest?: string
}

export const ReleaseOfInformationDrawer = ({
  opened,
  onClose,
  roi,
}: {
  opened: boolean
  onClose: () => void
  roi: PatientReleaseOfInformation
}): JSX.Element => {
  const { patientID: patientId, patientQuery } = usePatient()

  const { getInputProps, values, validate, reset } = useForm<Form>({
    validateInputOnBlur: true,
    initialValues: {
      reviewStatus: roi.status ?? null,
      changeRequest:
        roi.changeRequests?.sort(sortBy({ key: 'date', order: 'DESC' }))?.[0]?.changesRequested ??
        '',
    },
    validate: {
      reviewStatus: validateWith(isRequired),
      changeRequest: (value?: string, values?: Form) =>
        values?.reviewStatus === 'Needs changes' && !value ? 'Required' : null,
    },
  })

  const queryClient = useQueryClient()
  const onSuccess = () => {
    void queryClient.invalidateQueries([
      releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY,
      patientId,
    ])
    onClose()
    reset()
  }

  const { mutate: approveRoi, isLoading: isApprovingRoi } = useMutation(
    () =>
      releaseOfInformationApi.updateReleaseOfInformation({
        patientId,
        roiId: roi.oid,
        data: { status: 'Active', activeDate: dayjs().toISOString() },
      }),
    {
      onSuccess,
    },
  )

  const { mutate: addRoiChangeRequest, isLoading: isAddingChangeRoiRequest } = useMutation(
    () =>
      releaseOfInformationApi.addReleaseOfInformationChangeRequest({
        patientId,
        roiId: roi.oid,
        // Validation will ensure this is defined
        data: { changesRequested: values.changeRequest ?? '' },
      }),
    {
      onSuccess,
    },
  )

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }
    if (values.reviewStatus === 'Active') {
      approveRoi()
      return
    }
    addRoiChangeRequest()
  }

  const isReadOnly = roi.status === 'Needs changes'

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title='Needs review'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>{isReadOnly ? 'Close' : 'Cancel'}</SecondaryButton>
          {!isReadOnly && (
            <PrimaryButton loading={isApprovingRoi || isAddingChangeRoiRequest} onClick={onSubmit}>
              Send
            </PrimaryButton>
          )}
        </Group>
      }
      position='right'
      size='lg'
    >
      <Stack p='md'>
        <TitleTwo>Release of information</TitleTwo>
        <Text>
          {[
            'I,',
            patientQuery?.data?.personalData.firstName,
            `${patientQuery?.data?.personalData.lastName},`,
            'born on',
            `${dayjs(patientQuery?.data?.personalData.birthday ?? '')
              .format('MMM D YYYY')
              .toUpperCase()},`,
            'authorize Ophelia Health, Inc. to disclose information from my patient record to:',
          ].join(' ')}
        </Text>
        <Radio
          checked
          disabled
          value={roi.recipientType}
          label={roi.recipientType === 'Organization' ? 'An organization' : 'A person'}
        />
        <Stack spacing='xs'>
          <Text bold>{`${roi.recipientType} name`}</Text>
          <Text>{roi.recipient}</Text>
        </Stack>
        {roi.recipientType === 'Organization' && roi.organizationContact && (
          <Stack>
            <Text bold>
              Is there a specific person or department at that organization information should be
              given directly to?
            </Text>
            <Text>{roi.organizationContact}</Text>
          </Stack>
        )}
        <Divider />
        <Text>Contact information for the organization:</Text>
        <Stack spacing='xs'>
          <Text bold>Address</Text>
          <Box>
            <Text>
              {[roi.contactInfo.address, roi.contactInfo.address2].filter(Boolean).join(', ')}
            </Text>
            <Text>
              {[`${roi.contactInfo.city},`, roi.contactInfo.state, roi.contactInfo.zipCode].join(
                ' ',
              )}
            </Text>
          </Box>
        </Stack>
        <Stack spacing='xs'>
          <Text bold>Phone number</Text>
          <Text>{roi.contactInfo.phone}</Text>
        </Stack>
        <Divider />
        {roi.recipientType !== 'Emergency contact' && (
          <>
            <Text>How should we send the information?</Text>
            {roi.canContactPhone && <Radio checked disabled value='' label='Phone' />}
            {roi.fax && <Radio checked disabled value='' label='Fax' />}
            {roi.email && <Radio checked disabled value='' label='Email' />}
            {roi.canContactPhone && (
              <Stack spacing='xs'>
                <Text bold>Phone number</Text>
                <Text>{roi.contactInfo.phone}</Text>
              </Stack>
            )}
            {roi.fax && (
              <Stack spacing='xs'>
                <Text bold>Fax number</Text>
                <Text>{roi.fax}</Text>
              </Stack>
            )}
            {roi.email && (
              <Stack spacing='xs'>
                <Text bold>Email</Text>
                <Text>{roi.email}</Text>
              </Stack>
            )}
          </>
        )}
        <Divider />
        <Text>
          Please include the following information from my Patient Record (chose all that apply):
        </Text>
        {roi.infoTypes.map((type, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Checkbox key={i} checked disabled label={type} />
        ))}
        <Divider />
        <Text>The purpose of disclosure (the reason the records are needed) is for:</Text>
        {roi.disclosurePurposes.map((purpose, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Checkbox key={i} checked disabled label={purpose} />
        ))}
        <Divider />
        <Text>Patient Records(s) to be released only for these dates of service:</Text>
        <Stack spacing='xs'>
          <Text bold>From</Text>
          <Text>{dayjs(roi.datesOfService.start).format('MMM D YYYY').toUpperCase()}</Text>
        </Stack>
        <Stack spacing='xs'>
          <Text bold>End</Text>
          <Text>{dayjs(roi.datesOfService.end).format('MMM D YYYY').toUpperCase()}</Text>
        </Stack>
        <Divider />
        <Text>
          This authorization expires on the following date or whenever Ophelia Health, Inc. is no
          longer providing me with services:
        </Text>
        <Stack spacing='xs'>
          <Text bold>Expiration date</Text>
          <Text>{dayjs(roi.expirationDate).format('MMM D YYYY').toUpperCase()}</Text>
        </Stack>
        <Divider />
        <Checkbox
          checked
          disabled
          label='I understand that federal and state regulations (e.g. HIPAA, 42 CFR Part 2) protect my privacy and confidentiality. I can revoke this Authorization in writing at any time as long as Ophelia has not already taken action in reliance on it. I recognize that the re-disclosure or further sharing or exchange of my Patient Record(s) as shown above may occur without my written consent by someone who receives my Patient Record(s) under this Authorization. I know that I have the right to request an accounting of the disclosures of my Patient Records. I understand also that Ophelia will not condition my treatment on signing this authorization except as permitted by law. I have read, understand and agree with this Authorization and freely authorize the use and disclosure of my Patient Record(s) as shown above.'
        />
        <Stack spacing='xs'>
          <Text bold>Signed by</Text>
          <Text>{roi.signature}</Text>
        </Stack>
        <Stack spacing='xs'>
          <Text bold>Signed on</Text>
          <Text>{dayjs(roi.signedOnDate).format('MMM D YYYY').toUpperCase()}</Text>
        </Stack>
        <Divider />
        <RadioGroup {...getInputProps('reviewStatus')}>
          <Radio value='Active' label='Approve' disabled={isReadOnly} />
          <Radio value='Needs changes' label='Needs changes' disabled={isReadOnly} />
        </RadioGroup>
        {values.reviewStatus === 'Needs changes' && (
          <Textarea
            disabled={isReadOnly}
            placeholder='Type here...'
            {...getInputProps('changeRequest')}
          />
        )}
      </Stack>
    </Drawer>
  )
}
