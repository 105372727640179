import { Stack, Text } from '@shared/components'
import {
  DischargeNoteModel,
  alternativeProviderStatusMapping,
  hlocCategoryMapping,
  hlocMedicationMapping,
  hlocSubcategoryMapping,
} from '@shared/types'

export const TransferToHLOCSection = ({
  dischargeNote,
  isPAMedicaidCoe,
}: {
  dischargeNote: DischargeNoteModel
  isPAMedicaidCoe: boolean
}) => {
  function hlocText() {
    if (dischargeNote.needs_transfer_hloc === 'yes') {
      if (dischargeNote.recommended_hloc_subcategory) {
        return hlocSubcategoryMapping[dischargeNote.recommended_hloc_subcategory]
      }
      if (dischargeNote.recommended_hloc) {
        return hlocCategoryMapping[dischargeNote.recommended_hloc]
      }
      return 'Yes'
    }
    return 'No'
  }

  return dischargeNote.needs_transfer_hloc ? (
    <Stack spacing='xs'>
      <Text bold>
        {isPAMedicaidCoe
          ? 'Needs to transfer to alternate higher level of care program?'
          : 'Needs to transfer to alternate program?'}
      </Text>
      <Text>{hlocText()}</Text>
      {dischargeNote.alternative_provider_status && (
        <Stack>
          <Text bold>Transfer status</Text>
          <Text>{alternativeProviderStatusMapping[dischargeNote.alternative_provider_status]}</Text>
          {dischargeNote.transferring_to_outpatient_or_mat_buprenorphine && (
            <Text>{hlocMedicationMapping.transferring_to_outpatient_or_mat_buprenorphine}</Text>
          )}
          {dischargeNote.transferring_to_outpatient_or_mat_methadone && (
            <Text>{hlocMedicationMapping.transferring_to_outpatient_or_mat_methadone}</Text>
          )}
          {dischargeNote.transferring_to_outpatient_or_mat_xr_naltrexone && (
            <Text>{hlocMedicationMapping.transferring_to_outpatient_or_mat_xr_naltrexone}</Text>
          )}
          {dischargeNote.alternative_provider_other_explanation && (
            <Text>{`Explanation: ${dischargeNote.alternative_provider_other_explanation}`}</Text>
          )}
        </Stack>
      )}
    </Stack>
  ) : null
}
