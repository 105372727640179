import { Group, Modal, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { patientsApi } from '../../api'

type AddToInsurancePayModalProps = {
  closeModal: () => void
  userId: string
  firstName: string
}

const AddToInsurancePayModal = ({ userId, closeModal, firstName }: AddToInsurancePayModalProps) => {
  const queryClient = useQueryClient()

  const addPatient = useMutation(patientsApi.addToInsuranceAllowList, {
    onSuccess: () => {
      void queryClient.invalidateQueries()
      closeModal()
    },
  })

  return (
    <Modal
      opened
      onClose={closeModal}
      title={`Add ${firstName} to insurance pay`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>{`No, don't add`}</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              addPatient.mutate({ userId })
            }}
          >
            Yes, add patient to insurance pay
          </PrimaryButton>
        </Group>
      }
    >
      <Text> Are you sure you want to add this patient to insurance pay?</Text>
    </Modal>
  )
}

export default AddToInsurancePayModal
