import { Loader, MultiSelect, Stack, Text } from '@shared/components'
import { useLunaMutation, useLunaQuery } from '../../utils/hooks'

export const IntakeStatesMultiSelect = ({
  employeeId,
  isEditing,
  refetchEmployee,
}: {
  employeeId: string
  isEditing: boolean
  /**
   * NOTE: must refetch the employee after every update.
   * Else, the changes from this mutation will be overwritten.
   * That is bc the larger update triggered by the Provider tab
   * uses a different employee query, which is passed in wholesale
   * to that update mutation.
   */
  refetchEmployee: () => void
}) => {
  const employeeQuery = useLunaQuery('GET /employees/:employeeId', {
    params: {
      employeeId,
    },
  })

  const employee = employeeQuery.data?.data

  const updateEmployeeMutation = useLunaMutation('PUT /employees/:employeeId')

  // The intake states are a subset of the licensed states
  const licensedStates = (employeeQuery.data?.data?.providerInfo?.licenses || []).map(d => {
    return d.state
  })

  const intakeStates = employeeQuery.data?.data?.intakeStates || []

  const isLoading = employeeQuery.isFetching || updateEmployeeMutation.isLoading

  if (isEditing) {
    return (
      <MultiSelect
        disabled={isLoading}
        icon={isLoading ? <Loader size='xs' /> : null}
        value={intakeStates as string[]}
        onChange={nextIntakeStates => {
          updateEmployeeMutation.mutate(
            {
              params: {
                employeeId: employee?.oid || '',
              },
              data: {
                intakeStates: nextIntakeStates as typeof intakeStates,
              },
            },
            {
              onSuccess: () => {
                void employeeQuery.refetch()
                refetchEmployee()
              },
            },
          )
        }}
        label='Intake states'
        data={licensedStates}
      />
    )
  }

  return (
    <Stack spacing='xs'>
      <Text bold>Intake states</Text>
      <Text>{intakeStates.length > 0 ? intakeStates.join(', ') : 'n/a'}</Text>
    </Stack>
  )
}
