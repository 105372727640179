import { List, ListItem, Pill, Stack, Text } from '@shared/components'
import { DischargeNoteModel, patientSelfDeterminesToStopReasonMapping } from '@shared/types'

export const SelfDeterminesToStopReasonSection = ({
  dischargeNote,
}: {
  dischargeNote: DischargeNoteModel
}) => {
  return (
    <Stack spacing='xs'>
      {dischargeNote.patient_self_determines_to_stop_reasons &&
        dischargeNote.patient_self_determines_to_stop_reasons.length > 0 && (
          <Stack spacing='xs'>
            <Text bold>Reasons for patient determining to stop treatment</Text>
            <List spacing='xs' listStyleType='disc'>
              {dischargeNote.patient_self_determines_to_stop_reasons.map(reason => (
                <ListItem key={reason}>
                  {patientSelfDeterminesToStopReasonMapping?.[reason]}
                  {reason === dischargeNote.patient_self_determines_to_stop_primary_reason && (
                    <Text bold span>
                      {` (primary)`}
                    </Text>
                  )}
                </ListItem>
              ))}
            </List>
            {dischargeNote.patient_self_determines_to_stop_discussed_with_clinician !== undefined &&
              (dischargeNote.patient_self_determines_to_stop_discussed_with_clinician ? (
                <Pill variant='filled' status='none'>
                  Discussed with clinician
                </Pill>
              ) : (
                <Pill variant='filled' status='warning'>
                  Not discussed with clinician
                </Pill>
              ))}
          </Stack>
        )}
    </Stack>
  )
}
