import {
  Appointment,
  AppointmentType,
  AppointmentTypes,
  DrugScreenResults,
  ISOString,
  NOT_ENTERING_INDUCTION_VALUE,
  Patient,
  PrescriptionResponse,
  ProblemListProblem,
  VisitNote,
} from '@shared/types'
import { dayjs, getVisitNoteLockedAtString, toTime } from '@shared/utils'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces'
import { Config } from '../config'

export const postFlexChartViewedMessage = (phone: string | null) => {
  window.parent.postMessage({ type: 'chart_viewed', phone }, Config.TWILIO_FLEX_URL)
}

export const mapIdToName = (id: number, appointmentTypes: AppointmentTypes) => {
  let label = 'N/A'
  appointmentTypes.followUpTypes?.forEach(type => {
    if (type.id === id) {
      const reserve = type.reserve ? ', reserve' : ''
      const staggered = type.staggered ? ', staggered' : ''
      const overbooked = type.overbooked ? ', double booked' : ''
      label = `Follow-up visit (${type.length} min${reserve}${staggered}${overbooked})`
    }
  })

  appointmentTypes.bhcmVisitTypes?.forEach(type => {
    if (type.id === id) {
      label = `Wellness visit (${type.length} min)`
    }
  })

  if (id === appointmentTypes.INITIAL_WELLNESS_VISIT_ID) {
    label = 'Initial wellness visit'
  }

  if (
    Object.keys(appointmentTypes.initialVisitTypes).find(
      key => appointmentTypes.initialVisitTypes[key] === id,
    )
  ) {
    label = 'Initial visit'
  } else if (id === appointmentTypes.CHECK_IN_CALL_VISIT_ID) {
    label = 'Check-in call'
  } else if (id === appointmentTypes.UDS_VISIT_ID) {
    label = 'UDS visit'
  } else if (id === appointmentTypes.PEER_COACHING_INITIAL_VISIT_ID) {
    label = 'Peer coaching initial visit'
  } else if (id === appointmentTypes.PEER_COACHING_FOLLOWUP_VISIT_ID) {
    label = 'Peer coaching follow-up visit'
  } else if (id === appointmentTypes.CONSULTATION_VISIT_ID) {
    label = 'Welcome call'
  } else if (id === appointmentTypes.REENROLLMENT_VISIT_ID) {
    label = 'Welcome back call'
  } else if (id === appointmentTypes.ENROLLMENT_SUPPORT_CALL_VISIT_ID) {
    label = 'Enrollment support call'
  }
  return label
}

export const isNotAbleToWriteToNote = (calendarId?: number, visit?: Appointment | null) => {
  // CAN write to note if non-production environment
  if (Config.ENV !== 'production') {
    return false
  }

  // CANNOT write to note if calendarId is not set
  if (!calendarId) {
    return true
  }

  // CANNOT write to note if visit is missing
  if (!visit) {
    return true
  }

  // CANNOT write to note if calendarId does not match visit.calendarID
  if (Number(calendarId) !== Number(visit.calendarID)) {
    /**
     * Carve out an exception for Rachel French when viewing Erica Lyon's notes.
     * This it to enable the drop-in clinic, wherein clinicians work together.
     */
    if (calendarId === 8673378 && Number(visit.calendarID) === 5760296) {
      return false
    }

    return true
  }

  // CAN write to note if calendarId matches visit.calendarID
  return false
}

type DurationStringProps = { clinicianTime: number; patientTime: number }

export const renderDurationString = ({
  clinicianTime,
  patientTime,
}: DurationStringProps): string => {
  if (patientTime === 0) {
    return ''
  }
  return `Duration: ${
    patientTime + clinicianTime
  } mins (${patientTime} mins patient-facing, ${clinicianTime} mins non patient-facing)`
}

export const medicationName2Strength = (medicationName: string | undefined) => {
  switch (medicationName) {
    case 'Suboxone (sublingual - film)':
      return ['12 mg-3 mg', '2 mg-0.5 mg', '4 mg-1 mg', '8 mg-2 mg']
    case 'Buprenorphine-Naloxone (sublingual - film)':
      return ['12 mg-3 mg', '2 mg-0.5 mg', '4 mg-1 mg', '8 mg-2 mg']
    case 'Buprenorphine-Naloxone (sublingual - tablet)':
      return ['2 mg-0.5 mg', '8 mg-2 mg']
    case 'Zubsolv (sublingual - tablet)':
      return [
        '0.7 mg-0.18 mg',
        '1.4 mg-0.36 mg',
        '2.9 mg-0.71 mg',
        '5.7 mg-1.4 mg',
        '8.6 mg-2.1 mg',
        '11.4 mg-2.9 mg',
      ]
    case 'Buprenorphine (sublingual - tablet)':
      return ['2 mg', '8 mg']
    case 'Bunavail (buccal - film)':
      return ['2.1 mg-0.3 mg', '4.2 mg-0.7 mg', '6.3 mg-1 mg']
    default:
      return []
  }
}

export const medicationNames = () => [
  'Suboxone (sublingual - film)',
  'Buprenorphine-Naloxone (sublingual - film)',
  'Buprenorphine-Naloxone (sublingual - tablet)',
  'Zubsolv (sublingual - tablet)',
  'Buprenorphine (sublingual - tablet)',
  'Bunavail (buccal - film)',
]

export const inductionEmailOptions = [
  {
    value: 'LA#1 (8mg film, 1mg start, MDD 24-32mg)',
    label: 'LA #1',
    description: '8mg film, 1mg start, MDD 24-32mg',
  },
  {
    value: 'LA#2 (8mg film, 0.5mg start, MDD 24-32mg)',
    label: 'LA #2',
    description: '8mg film, 0.5mg start, MDD 24-32mg',
  },
  {
    value: 'LA#3 Tablets (2mg and 8mg tablets; 1mg start, MDD  24-32mg)',
    label: 'LA #3 (Tablets)',
    description: '2mg & 8mg tablets; 1mg start, MDD 24-32mg',
  },
  {
    value: 'SA#1 (8mg film, 2mg start, MDD 8mg)',
    label: 'SA #1',
    description: '8mg film, 2mg start, MDD 8mg',
  },
  {
    value: 'SA#2 (8mg film, 2mg start, MDD 16mg)',
    label: 'SA #2',
    description: '8mg film, 2mg start, MDD 16mg',
  },
  { value: NOT_ENTERING_INDUCTION_VALUE, label: 'Patient not entering induction' },
  { value: 'Custom induction', label: 'Custom induction' },
]

export const drugTestVariables = [
  'drug_screen_tramadol_result',
  'drug_screen_marijuana_result',
  'drug_screen_all_else_negative',
  'drug_screen_buprenorphine_result',
  'drug_screen_phencyclidine_result',
  'drug_screen_oxycodone_result',
  'drug_screen_opiates_result',
  'drug_screen_methadone_result',
  'drug_screen_methylenedioxymethamphetamine_result',
  'drug_screen_methamphetamine_result',
  'drug_screen_synthetic_cannabinoid_result',
  'drug_screen_fentanyl_result',
  'drug_screen_alcohol_result',
  'drug_screen_cocaine_result',
  'drug_screen_amphetamines_result',
  'drug_screen_barbiturates_result',
  'drug_screen_benzodiazepines_result',
]
export const medicationVariables = [
  'medication_name',
  'medication_strength',
  'medication_quantity',
  'medication_days_supply',
]

export type DrugScreenResultAbbreviation = {
  resultTitle: keyof DrugScreenResults
  abbreviation: string
}
export const getDrugScreenOrder = (): DrugScreenResultAbbreviation[] => {
  return [
    { resultTitle: 'drug_screen_alcohol_result', abbreviation: 'ETG' },
    { resultTitle: 'drug_screen_amphetamines_result', abbreviation: 'AMP' },
    { resultTitle: 'drug_screen_barbiturates_result', abbreviation: 'BAR' },
    { resultTitle: 'drug_screen_benzodiazepines_result', abbreviation: 'BZO' },
    { resultTitle: 'drug_screen_buprenorphine_result', abbreviation: 'BUP' },
    { resultTitle: 'drug_screen_cocaine_result', abbreviation: 'COC' },
    { resultTitle: 'drug_screen_fentanyl_result', abbreviation: 'FEN' },
    { resultTitle: 'drug_screen_marijuana_result', abbreviation: 'THC' },
    { resultTitle: 'drug_screen_methadone_result', abbreviation: 'MTD' },
    { resultTitle: 'drug_screen_methamphetamine_result', abbreviation: 'mAMP' },
    { resultTitle: 'drug_screen_methylenedioxymethamphetamine_result', abbreviation: 'MDMA' },
    { resultTitle: 'drug_screen_opiates_result', abbreviation: 'OPI' },
    { resultTitle: 'drug_screen_oxycodone_result', abbreviation: 'OXY' },
    { resultTitle: 'drug_screen_phencyclidine_result', abbreviation: 'PCP' },
    { resultTitle: 'drug_screen_synthetic_cannabinoid_result', abbreviation: 'K2' },
    { resultTitle: 'drug_screen_tramadol_result', abbreviation: 'TRAM' },
  ]
}

export const listOfStatesToString = (stateList: Record<string, string>) => {
  let displayedStates = ''
  Object.keys(stateList).forEach(state => {
    if (stateList[state]) {
      displayedStates += `${state}, `
    }
  })
  if (displayedStates.length > 2) {
    displayedStates = displayedStates.substring(0, displayedStates.length - 2)
  }
  return displayedStates
}

export const getOldestAppointmentDataDate = () => {
  return '2021-02-08'
}

export const objectValuesToString = (obj?: Record<string, string>) => {
  if (obj) {
    let str = ''
    Object.values(obj).forEach(element => {
      str = `${str + element}, `
    })
    return str.replace(/(^\s*,)|(,\s*$)/g, '')
  }
  return ''
}

export const getTimeSinceAndTimeUnit = (timestampCreated: string) => {
  if (dayjs().diff(timestampCreated, 'minutes') < toTime('1 hour').min()) {
    return {
      timeSince: dayjs().diff(timestampCreated, 'minutes').toString(),
      timeUnit: 'minutes',
    }
  } else if (dayjs().diff(timestampCreated, 'hours') < toTime('24 hour').hour()) {
    return {
      timeSince: dayjs().diff(timestampCreated, 'hours').toString(),
      timeUnit: 'hours',
    }
  }
  return {
    timeSince: dayjs().diff(timestampCreated, 'days').toString(),
    timeUnit: 'days',
  }
}

const initialVisitSections = [
  {
    header: 'History of Present Illness',
    fields: [{ key: 'history_of_present_illness' }],
  },
  {
    header: 'Past Medical History',
    fields: [
      { key: 'medical_pmh', value: 'Medical' },
      { key: 'psychiatric_pmh', value: 'Psychiatric' },
      { key: 'hospitalizations_pmh', value: 'Hospitalizations' },
      { key: 'active_medications_pmh', value: 'Active Medications' },
      { key: 'allergies_and_reactions_pmh', value: 'Allergies and Reactions' },
      { key: 'social_and_family_pmh', value: 'Social and Family' },
    ],
  },
  {
    header: 'Opioid Use History',
    fields: [
      { key: 'opioid_history', value: 'Use History' },
      { key: 'opioid_treatment_history', value: 'Treatment History' },
      { key: 'overdose_history', value: 'Overdose History' },
      { key: 'overdose_history_comments', value: 'Overdose History Comments' },
      { key: 'witnessed_overdose', value: 'Witnessed Overdose' },
      { key: 'witnessed_overdose_comments', value: 'Witnessed Overdose Comments' },
      { key: 'naloxone_supply', value: 'Does the patient have naloxone?' },
    ],
  },
  {
    header: 'Other Substance Use History',
    fields: [
      { key: 'alcohol_use', value: 'Alcohol Use' },
      { key: 'alcohol_use_comments', value: 'Alcohol Use Comments' },
      { key: 'tobacco_use', value: 'Tobacco Use' },
      { key: 'tobacco_use_comments', value: 'Tobacco Use Comments' },
      { key: 'marijuana_use', value: 'Marijuana Use' },
      { key: 'marijuana_use_comments', value: 'Marijuana Use Comments' },
      { key: 'benzodiazepines_use', value: 'Benzodiazepines Use' },
      { key: 'benzodiazepines_use_comments', value: 'Benzodiazepines Use Comments' },
      { key: 'amphetamine_use', value: 'Amphetamine Use' },
      { key: 'amphetamine_use_comments', value: 'Amphetamine Use Comments' },
      { key: 'cocaine_use', value: 'Cocaine Use' },
      { key: 'cocaine_use_comments', value: 'Cocaine Use Comments' },
      { key: 'other_use', value: 'Other Use' },
      { key: 'other_use_comments', value: 'Other Use Comments' },
    ],
  },
  {
    header: 'Counseling and Recovery Services',
    fields: [
      { key: 'participating_in_counseling', value: 'Participating in Counseling' },
      {
        key: 'participating_in_counseling_comments',
        value: 'Participating in Counseling Comments',
      },
      { key: 'participating_in_recovery_services', value: 'Participating in Recovery Services' },
      {
        key: 'participating_in_recovery_services_comments',
        value: 'Participating in Recovery Services Comments',
      },
    ],
  },
  {
    header: 'Observation and Examination',
    fields: [{ key: 'observed_and_examination' }],
  },
  {
    header: 'Assessment',
    fields: [
      { key: 'assessment' },
      { key: 'pdmp_consistent_with_history', value: 'PDMP Consistent with History' },
      {
        key: 'pdmp_consistent_with_history_comments',
        value: 'PDMP Consistent with History Comments',
      },
    ],
  },
  {
    header: 'Treatment Goals',
    fields: [{ key: 'treatment_goals' }],
  },
]

const followUpVisitSections = [
  {
    header: 'Modality',
    fields: [
      { key: 'modality', value: 'What modality was the visit?' },
      { key: 'modality_justification', value: 'Modality Justification' },
    ],
  },
  {
    header: 'History of Present Illness',
    fields: [{ key: 'history_of_present_illness' }],
  },
  {
    header: 'Past Medical History',
    fields: [
      { key: 'medical_pmh', value: 'Medical' },
      { key: 'psychiatric_pmh', value: 'Psychiatric' },
      { key: 'hospitalizations_pmh', value: 'Hospitalizations' },
      { key: 'surgical_pmh', value: 'Surgical' },
      { key: 'active_medications_pmh', value: 'Active Medications' },
      { key: 'allergies_and_reactions_pmh', value: 'Allergies and Reactions' },
      { key: 'social_and_family_pmh', value: 'Social and Family' },
    ],
  },
  {
    header: 'Response to Treatment',
    fields: [
      { key: 'patient_perception_of_effectiveness', value: 'Patient Perception of Effectiveness' },
      {
        key: 'patient_perception_of_effectiveness_comments',
        value: 'Patient Perception of Effectiveness Comments',
      },
      { key: 'adherent', value: 'Adherent' },
      { key: 'adherent_comments', value: 'Adherent Comments' },
      { key: 'patient_desire_of_dose_change', value: 'Patient Desire of Dose Change' },
      {
        key: 'patient_desire_of_dose_change_comments',
        value: 'Patient Desire of Dose Change Comments',
      },
      { key: 'cravings_or_withdrawal', value: 'Cravings or Withdrawal' },
      { key: 'cravings_or_withdrawal_comments', value: 'Cravings or Withdrawal Comments' },
      { key: 'concerning_behavior', value: 'Concerning Behavior' },
      { key: 'concerning_behavior_comments', value: 'Concerning Behavior Comments' },
    ],
  },
  {
    header: 'Counseling and Recovery Services',
    fields: [
      { key: 'participating_in_counseling', value: 'Participating in Counseling' },
      {
        key: 'participating_in_counseling_comments',
        value: 'Participating in Counseling Comments',
      },
      { key: 'participating_in_recovery_services', value: 'Participating in Recovery Services' },
      {
        key: 'participating_in_recovery_services_comments',
        value: 'Participating in Recovery Services Comments',
      },
    ],
  },
  {
    header: 'Observation and Examination',
    fields: [{ key: 'observed_and_examination' }],
  },
  {
    header: 'Urine Drug Assessment',
    fields: [
      { key: 'drug_screen_administered', value: 'Was a drug screen administered?' },
      { key: 'drug_screen_alcohol_result', value: 'Alcohol Result' },
      { key: 'drug_screen_amphetamines_result', value: 'Amphetamines Result' },
      { key: 'drug_screen_barbiturates_result', value: 'Barbiturates Result' },
      { key: 'drug_screen_benzodiazepines_result', value: 'Benzodiazepines Result' },
      { key: 'drug_screen_buprenorphine_result', value: 'Buprenorphine Result' },
      { key: 'drug_screen_cocaine_result', value: 'Cocaine Result' },
      { key: 'drug_screen_fentanyl_result', value: 'Fentanyl Result' },
      { key: 'drug_screen_marijuana_result', value: 'Marijuana Result' },
      { key: 'drug_screen_methadone_result', value: 'Methadone Result' },
      { key: 'drug_screen_methamphetamine_result', value: 'Methamphetamine Result' },
      {
        key: 'drug_screen_methylenedioxymethamphetamine_result',
        value: 'Methylenedioxymethamphetamine Result',
      },
      { key: 'drug_screen_opiates_result', value: 'Opiates Result' },
      { key: 'drug_screen_oxycodone_result', value: 'Oxycodone Result' },
      { key: 'drug_screen_phencyclidine_result', value: 'Phencyclidine Result' },
      { key: 'drug_screen_synthetic_cannabinoid_result', value: 'Synthetic Cannabinoid Result' },
      { key: 'drug_screen_tramadol_result', value: 'Tramadol Result' },
      { key: 'drug_screen_comments', value: 'Drug Screen Comments' },
    ],
  },
  {
    header: 'Assessment',
    fields: [
      { key: 'assessment' },
      { key: 'pdmp_consistent_with_history', value: 'PDMP Consistent with History' },
      {
        key: 'pdmp_consistent_with_history_comments',
        value: 'PDMP Consistent with History Comments',
      },
    ],
  },
  {
    header: 'Treatment Goals',
    fields: [{ key: 'treatment_goals' }],
  },
]

type ExportToPdfParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterWatcher?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: any
  type: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  visitType?: string
  userId: string
  datetime: string
  lockedByName?: string
  lockedAtTime?: string
  patientTime?: number
  clinicianTime?: number
  dosespotMedications?: PrescriptionResponse[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addendums?: Record<string, any>
  visitNoteContentOnly?: boolean
}

const getPdfContentForVisitNote = ({
  masterWatcher,
  notes,
  type,
  firstName,
  lastName,
  dateOfBirth,
  visitType,
  userId,
  datetime,
  lockedByName,
  lockedAtTime,
  patientTime = 0,
  clinicianTime = 0,
  dosespotMedications = [],
  addendums = {},
  visitNoteContentOnly = false,
}: ExportToPdfParams): Content[] => {
  if (!masterWatcher && !visitNoteContentOnly) {
    return []
  }

  let data = masterWatcher ? { ...masterWatcher } : notes

  if (data.drug_screen_administered === 'no') {
    for (const key of drugTestVariables) {
      data = omit(data, key)
    }
  }

  if (data.medication_prescribed === 'no') {
    for (const key of medicationVariables) {
      data = omit(data, key)
    }
  }

  if (data.drug_screen_administered === 'yes') {
    for (const key of drugTestVariables) {
      /*
       * When doing bulk exports, the UDS results are already resolved to 'positive' / 'negative,
       * so there's no need to convert them
       */
      if (!['positive', 'negative'].includes(data[key])) {
        data[key] = data[key] ? 'positive' : 'negative'
      }
    }
  }

  const content: Content = []
  content.push({
    text: `${firstName} ${lastName} - DOB: ${dateOfBirth}`,
    style: 'header',
  })
  content.push({
    text: `MRN: ${userId}`,
    style: 'text',
  })
  content.push({
    text: visitType || 'visit',
    style: 'text',
  })
  content.push({
    text: datetime,
    style: 'text',
  })
  if (patientTime + clinicianTime > 0) {
    content.push({
      text: renderDurationString({ clinicianTime, patientTime }),
      style: 'text',
    })
  }
  if (lockedByName && lockedAtTime) {
    content.push({
      text: `Signed by ${lockedByName} on ${lockedAtTime}`,
      style: 'text',
    })
  }
  content.push({
    text: '\n\n',
    style: 'text',
  })

  if (type === 'maintenance') {
    followUpVisitSections.forEach(section => {
      // If this section is going to be empty, just skip it
      if (!section.fields.find(field => data[field.key])) {
        return
      }

      content.push({
        text: section.header,
        style: 'header',
      })
      section.fields.forEach(field => {
        if (!data[field.key]) {
          return
        }
        content.push({
          text: field.value || '',
          style: 'subheader',
        })
        content.push({
          text: data[field.key],
          style: 'text',
        })
      })
      content.push({
        text: '\n\n',
        style: 'text',
      })
    })
  } else if (type === 'initial visit') {
    initialVisitSections.forEach(section => {
      // If this section is going to be empty, just skip it
      if (!section.fields.find(field => data[field.key])) {
        return
      }

      content.push({
        text: section.header,
        style: 'header',
      })
      section.fields.forEach(field => {
        if (!data[field.key]) {
          return
        }
        content.push({
          text: field.value || '',
          style: 'subheader',
        })
        content.push({
          text: data[field.key],
          style: 'text',
        })
      })
      content.push({
        text: '\n\n',
        style: 'text',
      })
    })
  }

  if (notes.problems_addressed?.length) {
    content.push({
      text: 'Assessment and Plan',
      style: 'header',
    })
    notes.problems_addressed.forEach((problemAddressed: ProblemListProblem) => {
      const key = `plan_${problemAddressed.full_description
        .toLowerCase()
        .replace(/[[\].$#/]+/g, '')
        .replace(/ /g, '_')}`
      content.push({
        text: `${problemAddressed.code} - ${problemAddressed.full_description}`,
        style: 'subheader',
      })
      content.push({
        text: notes[key],
        style: 'text',
      })
      content.push({
        text: '\n',
        style: 'text',
      })
    })
    content.push({
      text: '\n\n',
      style: 'text',
    })
  }

  if (dosespotMedications.length) {
    content.push({
      text: 'Medications',
      style: 'header',
    })
    const medicationFields = [
      { key: 'medication_name', value: 'Medication Name' },
      { key: 'medication_strength', value: 'Medication Strength' },
      { key: 'medication_days_supply', value: 'Medication Days Supply' },
      { key: 'medication_quantity', value: 'Medication Quantity' },
    ] as const
    dosespotMedications.forEach(medication => {
      medicationFields.forEach(field => {
        content.push({
          text: field.value,
          style: 'subheader',
        })
        content.push({
          text: medication[field.key] || '',
          style: 'text',
        })
      })
      content.push({
        text: '\n\n',
        style: 'text',
      })
    })
  }

  if (Object.keys(addendums).length) {
    content.push({
      text: 'Addendums',
      style: 'header',
    })
    addendums.forEach(
      (addendum: { content: string; locked_by_name: string; locked_at: string }, idx: number) => {
        content.push({
          text: `Addendum #${idx + 1} Content`,
          style: 'subheader',
        })
        content.push({
          text: addendum.content,
          style: 'text',
        })
        content.push({
          text: '\nLocked by',
          style: 'subheader',
        })
        content.push({
          text: addendum.locked_by_name,
          style: 'text',
        })
        content.push({
          text: '\nLocked at',
          style: 'subheader',
        })
        content.push({
          text: addendum.locked_at,
          style: 'text',
        })
        content.push({
          text: '\n\n',
          style: 'text',
        })
      },
    )
  }

  return content
}

export const exportToPdf = async (params: ExportToPdfParams) => {
  const content = getPdfContentForVisitNote(params)

  const docDefinition: TDocumentDefinitions = {
    content,
    footer: {
      text: 'The information contained in this transmission may contain privileged and confidential information, including patient information protected by federal and state privacy laws. It is intended only for the use of the person(s) named above. If you are not the intended recipient, you are hereby notified that any review, dissemination, distribution, or duplication of this communication is strictly prohibited. If you are not the intended recipient, please contact the sender by reply email and destroy all copies of the original message.',
      fontSize: 7,
      color: 'red',
      margin: [5, 5, 5, 5],
    },
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      subheader: {
        fontSize: 12,
        bold: true,
      },
      text: {
        fontSize: 11,
      },
    },
  }

  const pdfMake = await import('pdfmake/build/pdfmake')
  const pdfFonts = await import('pdfmake/build/vfs_fonts')
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  pdfMake
    .createPdf(docDefinition)
    .download(`${params.lastName}_${params.firstName}_${params.userId}.pdf`)
}

export const bulkExportToPdf = async (
  patient: Patient,
  allExports: { appointment: Appointment; note: VisitNote }[],
  range: { startDate: ISOString; endDate: ISOString },
) => {
  if (allExports.length === 0) {
    return
  }

  const sortedExports = sortBy(allExports, 'appointment.datetime')

  const content = sortedExports
    .map(({ appointment, note }, idx) => {
      const noteContent = getPdfContentForVisitNote({
        notes: note.content,
        type: note.type,
        firstName: patient.personalData.firstName,
        lastName: patient.personalData.lastName,
        dateOfBirth: patient?.personalData.birthday,
        userId: patient.oid,
        datetime: dayjs(appointment.datetime)
          .tz(appointment.timezone)
          .format('dddd, MMMM D, YYYY hh:mmA'),
        visitType: appointment.type,
        lockedByName: note.content.locked_by_name,
        lockedAtTime: getVisitNoteLockedAtString({
          lockedAt: note.content.locked_at,
          timezone: appointment.timezone,
        }),
        patientTime: appointment.meetingDetails?.patientTime,
        clinicianTime: appointment.meetingDetails?.clinicianTime,
        // TODO: Add dosespot medication decoration on backend (if needed)
        dosespotMedications: [],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        addendums: (note as Record<string, any>).addendum || {},
        visitNoteContentOnly: true,
      })

      const isLastVisitInBulkExport = idx === sortedExports.length - 1
      if (!isLastVisitInBulkExport) {
        noteContent.push({
          text: '',
          style: 'text',
          pageBreak: 'after',
        })
      }

      return noteContent
    })
    .filter(Boolean)
    .flat()

  const docDefinition: TDocumentDefinitions = {
    content,
    footer: {
      text: 'The information contained in this transmission may contain privileged and confidential information, including patient information protected by federal and state privacy laws. It is intended only for the use of the person(s) named above. If you are not the intended recipient, you are hereby notified that any review, dissemination, distribution, or duplication of this communication is strictly prohibited. If you are not the intended recipient, please contact the sender by reply email and destroy all copies of the original message.',
      fontSize: 7,
      color: 'red',
      margin: [5, 5, 5, 5],
    },
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
      subheader: {
        fontSize: 12,
        bold: true,
      },
      text: {
        fontSize: 11,
      },
    },
  }

  const pdfMake = await import('pdfmake/build/pdfmake')
  const pdfFonts = await import('pdfmake/build/vfs_fonts')
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const exportFileName = [
    patient.personalData.lastName,
    patient.personalData.firstName,
    patient.oid,
    dayjs(range.startDate).format('YYYY-MM-DD'),
    dayjs(range.endDate).format('YYYY-MM-DD'),
  ].join('_')

  pdfMake.createPdf(docDefinition).download(`${exportFileName}.pdf`)
}

export const anonymousEmployeeImageUrl = Config.ANONYMOUS_PROFILE_IMAGE_URL

export const usStatesList = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const cleanAppointmentTypes = (
  appointmentTypes: AppointmentTypes,
): Record<string, AppointmentType> => {
  const types = {
    [appointmentTypes.CHECK_IN_CALL_VISIT_ID]: {
      id: appointmentTypes.CHECK_IN_CALL_VISIT_ID,
      name: 'Check-In Call',
      length: 15,
    },
  }

  appointmentTypes.followUpTypes.forEach(followUpType => {
    types[followUpType.id] = {
      id: followUpType.id,
      name: `Follow-Up Visit ${followUpType.length}-Min`,
      length: followUpType.length,
    }
  })
  return types
}

export const commonProblems = [
  {
    code: 'F11.20',
    full_description: 'Opioid dependence, uncomplicated',
  },
  {
    code: 'F11.220',
    full_description: 'Opioid dependence with intoxication, uncomplicated',
  },
  {
    code: 'F11.23',
    full_description: 'Opioid dependence with withdrawal',
  },
  { code: 'F17.210', full_description: 'Nicotine dependence, cigarettes, uncomplicated' },
  { code: 'F17.211', full_description: 'Nicotine dependence, cigarettes, in remission' },
  {
    code: 'F17.219',
    full_description:
      'Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders',
  },
  {
    code: 'F17.290',
    full_description: 'Nicotine dependence, other tobacco product, uncomplicated',
  },
  { code: 'F17.291', full_description: 'Nicotine dependence, other tobacco product, in remission' },
  {
    code: 'F10.20',
    full_description: 'Alcohol dependence, uncomplicated',
  },
  {
    code: 'F10.930',
    full_description: 'Alcohol use, unspecified with withdrawal, uncomplicated',
  },
  {
    code: 'F12.20',
    full_description: 'Cannabis dependence, uncomplicated',
  },
  {
    code: 'F12.90',
    full_description: 'Cannabis use, unspecified, uncomplicated',
  },
  {
    code: 'F14.20',
    full_description: 'Cocaine dependence, uncomplicated',
  },
  {
    code: 'F14.90',
    full_description: 'Cocaine use, unspecified, uncomplicated',
  },
  {
    code: 'F15.20',
    full_description: 'Other stimulant dependence, uncomplicated',
  },
  {
    code: 'F15.90',
    full_description: 'Other stimulant use, unspecified, uncomplicated',
  },
  {
    code: 'F13.20',
    full_description: 'Sedative, hypnotic or anxiolytic dependence, uncomplicated',
  },
  {
    code: 'F13.90',
    full_description: 'Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated',
  },
  {
    code: 'B19.20',
    full_description: 'Unspecified viral hepatitis C without hepatic coma',
  },
  {
    code: 'B20',
    full_description: 'Human immunodeficiency virus [HIV] disease',
  },
  { code: 'F41.1', full_description: 'Generalized anxiety disorder' },
  { code: 'F41.9', full_description: 'Anxiety disorder, unspecified' },
  {
    code: 'F43.10',
    full_description: 'Post-traumatic stress disorder, unspecified',
  },
  {
    code: 'F41.0',
    full_description: 'Panic disorder [episodic paroxysmal anxiety]',
  },
  {
    code: 'F32.0',
    full_description: 'Major depressive disorder, single episode, mild',
  },
  {
    code: 'F32.1',
    full_description: 'Major depressive disorder, single episode, moderate',
  },
  {
    code: 'F32.2',
    full_description:
      'Major depressive disorder, single episode, severe without psychotic features',
  },
  {
    code: 'F32.3',
    full_description: 'Major depressive disorder, single episode, severe with psychotic features',
  },
  {
    code: 'F32.9',
    full_description: 'Major depressive disorder, single episode, unspecified',
  },
  {
    code: 'F33.0',
    full_description: 'Major depressive disorder, recurrent, mild',
  },
  {
    code: 'F33.1',
    full_description: 'Major depressive disorder, recurrent, moderate',
  },
  {
    code: 'F33.2',
    full_description: 'Major depressive disorder, recurrent severe without psychotic features',
  },
  {
    code: 'F33.3',
    full_description: 'Major depressive disorder, recurrent, severe with psychotic symptoms',
  },
  { code: 'F53.0', full_description: 'Postpartum depression' },
  {
    code: 'F42.2',
    full_description: 'Mixed obsessional thoughts and acts',
  },
  { code: 'F42.3', full_description: 'Hoarding disorder' },
  {
    code: 'F42.4',
    full_description: 'Excoriation (skin-picking) disorder',
  },
  {
    code: 'F42.9',
    full_description: 'Obsessive-compulsive disorder, unspecified',
  },
  {
    code: 'F31.0',
    full_description: 'Bipolar disorder, current episode hypomanic',
  },
  {
    code: 'F31.10',
    full_description:
      'Bipolar disorder, current episode manic without psychotic features, unspecified',
  },
  {
    code: 'F31.2',
    full_description: 'Bipolar disorder, current episode manic severe with psychotic features',
  },
  {
    code: 'F31.30',
    full_description:
      'Bipolar disorder, current episode depressed, mild or moderate severity, unspecified',
  },
  {
    code: 'F31.4',
    full_description:
      'Bipolar disorder, current episode depressed, severe, without psychotic features',
  },
  {
    code: 'F31.5',
    full_description:
      'Bipolar disorder, current episode depressed, severe, with psychotic features',
  },
  {
    code: 'F31.81',
    full_description: 'Bipolar II disorder',
  },
  {
    code: 'F43.20',
    full_description: 'Adjustment disorder, unspecified',
  },
  {
    code: 'F43.21',
    full_description: 'Adjustment disorder with depressed mood',
  },
  { code: 'F34.1', full_description: 'Dysthymic disorder' },
  {
    code: 'F60.9',
    full_description: 'Personality disorder, unspecified',
  },
  {
    code: 'F90.0',
    full_description: 'Attention-deficit hyperactivity disorder, predominantly inattentive type',
  },
  {
    code: 'F90.1',
    full_description: 'Attention-deficit hyperactivity disorder, predominantly hyperactive type',
  },
  {
    code: 'F90.2',
    full_description: 'Attention-deficit hyperactivity disorder, combined type',
  },
  { code: 'F20.9', full_description: 'Schizophrenia, unspecified' },
  {
    code: 'Z72.51',
    full_description: 'High risk heterosexual behavior',
  },
  { code: 'Z72.52', full_description: 'High risk homosexual behavior' },
  { code: 'Z72.53', full_description: 'High risk bisexual behavior' },
  {
    code: 'F25.0',
    full_description: 'Schizoaffective disorder, bipolar type',
  },
  {
    code: 'F25.1',
    full_description: 'Schizoaffective disorder, depressive type',
  },
  {
    code: 'F25.9',
    full_description: 'Schizoaffective disorder, unspecified',
  },
  { code: 'Z72.6', full_description: 'Gambling and betting' },
  { code: 'Z72.3', full_description: 'Lack of physical exercise' },
  {
    code: 'F52.8',
    full_description:
      'Other sexual dysfunction not due to a substance or known physiological condition',
  },
]

export const FORMS_ENUM = {
  intake: 'intake',
  screening: 'screening',
}

export const patientHasInsurance = (patient: Patient | undefined) => {
  return patient?.insuranceData?.hasInsurance
}

export const opheliaEmailRegex =
  // eslint-disable-next-line
  /([a-zA-Z]+)\@ophelia.com$/i

export const convertYesNoToBoolean = (val: 'yes' | 'no'): boolean => {
  if (val === 'yes') {
    return true
  }
  return false
}
