import { useEmrQuery } from './use-emr-query'

export const useOnCallEngineer = () => {
  const { data: configs } = useEmrQuery('GET /configs')
  const onCallEngineerQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: { employeeId: configs?.onCallEngineer ?? '' },
    },
    { enabled: Boolean(configs?.onCallEngineer) },
  )

  return {
    onCallEngineerQuery,
  }
}
