import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Group,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
} from '@shared/components'
import dayjs from 'dayjs'

export type DateLabels = {
  previous: string
  previousDate: string
  current: string
  next: string
  nextDate: string
}

export type DateNavigatorProps = {
  labels: DateLabels
  setDate: (value: string) => void
}

const DATE_FORMAT = 'YYYY-MM-DD'

export const getDisplayDate = (date: string) =>
  dayjs(date).startOf('isoWeek').format('MM/DD/YYYY').toString()

export const DateNavigator = ({ labels, setDate }: DateNavigatorProps) => (
  <Group spacing='sm'>
    <Tooltip label={labels.previous} position='bottom'>
      <SecondaryButton
        leftIcon={<ChevronLeftIcon />}
        onClick={() => setDate(labels.previousDate)}
      />
    </Tooltip>
    <PrimaryButton onClick={() => setDate(dayjs().format(DATE_FORMAT))}>
      {labels.current}
    </PrimaryButton>
    <Tooltip label={labels.next} position='bottom'>
      <SecondaryButton leftIcon={<ChevronRightIcon />} onClick={() => setDate(labels.nextDate)} />
    </Tooltip>
  </Group>
)

export type DateNavigatorImplProps = {
  date: string
  setDate: (value: string) => void
}

export const DayNavigator = ({ date, setDate }: DateNavigatorImplProps) => {
  const LABELS_BY_DATE: DateLabels = {
    previous: 'Previous day',
    previousDate: dayjs(date).subtract(1, 'day').format(DATE_FORMAT),
    current: 'Today',
    next: 'Tomorrow',
    nextDate: dayjs(date).add(1, 'day').format(DATE_FORMAT),
  }
  return <DateNavigator labels={LABELS_BY_DATE} setDate={setDate} />
}

export const WeekNavigator = ({ date, setDate }: DateNavigatorImplProps) => {
  const LABELS_BY_PROVIDER: DateLabels = {
    previous: 'Previous week',
    previousDate: dayjs(date).subtract(1, 'week').format(DATE_FORMAT),
    current: 'This week',
    next: 'Next week',
    nextDate: dayjs(date).add(1, 'week').format(DATE_FORMAT),
  }
  return <DateNavigator labels={LABELS_BY_PROVIDER} setDate={setDate} />
}
