import { useForm } from '@mantine/form'
import {
  Drawer,
  Group,
  PhoneInput,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  Text,
  TextInput,
  isAnySelected,
  useBanner,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import { EmrApi, OpheliaHttpError, PATIENT_SOURCE, StateName, stateNames } from '@shared/types'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { isPhone, isRequired } from '../../utils/formValidation'

type Form = EmrApi['POST /patient']['req']['data']

export const AddPatientDrawer = ({ opened, onClose }: { opened: boolean; onClose: () => void }) => {
  const { getInputProps, validate, values, reset, isTouched, resetTouched } = useForm<Form>({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      state: '' as StateName,
    },
    validate: {
      firstName: validateWith(isRequired),
      lastName: validateWith(isRequired),
      phone: validateWith(isRequired, isPhone),
      state: isAnySelected(stateNames, 'Required'),
    },
    validateInputOnBlur: true,
  })
  // Use to keep track of if form was touched since last submit
  const wasTouched = isTouched()

  const { showBanner } = useBanner()

  const [submitError, setSubmitError] = useState<string>()

  const { mutate: createPatient, isLoading: isCreatingPatient } = useMutation(
    emrApi.getMutation('POST /patient'),
    {
      onSuccess: () => {
        onClose()
        showBanner({
          type: 'success',
          label: `Successfully created patient ${values.firstName} ${values.lastName}`,
          dismissable: true,
        })
        reset()
      },
      onError: (err: OpheliaHttpError) => {
        resetTouched()
        setSubmitError(err.message)
      },
    },
  )

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }
    createPatient({ data: values })
  }

  // Clear submit error if form was touched since last submit
  useEffect(() => {
    if (submitError && wasTouched) {
      setSubmitError(undefined)
    }
  }, [submitError, wasTouched])

  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title='Add patient'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onSubmit} loading={isCreatingPatient}>
            Add
          </PrimaryButton>
        </Group>
      }
      position='right'
      size='lg'
    >
      <Stack p='md'>
        <TextInput label='First name' placeholder='John' {...getInputProps('firstName')} />
        <TextInput label='Last name' placeholder='Smith' {...getInputProps('lastName')} />
        <PhoneInput label='Phone' {...getInputProps('phone')} />
        <Select
          searchable
          clearable
          label='State'
          placeholder='Select one...'
          data={stateNames}
          {...getInputProps('state')}
        />
        <Select
          searchable
          clearable
          label='Source (optional)'
          placeholder='Select one...'
          data={PATIENT_SOURCE}
          {...getInputProps('source')}
        />
        {submitError && (
          <Text bold color={colors.error[0]}>
            {submitError}
          </Text>
        )}
      </Stack>
    </Drawer>
  )
}
