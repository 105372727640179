import AScreeningFormQuestionAnswer from './AScreeningFormQuestionAnswer'

export type ScreeningFormForm = {
  answers: {
    description?: string
    key: string
    value: string | boolean | Record<string, string>
  }[]
}

export type OScreeningFormSidebarProps = {
  form: ScreeningFormForm
}

const OScreeningFormSidebar = ({ form }: OScreeningFormSidebarProps) => {
  return (
    <div className='border-gray-200 border-l hidden lg:flex xl:flex flex-shrink-0 z-10 mr-4'>
      <div className='w-full flex flex-col'>
        <div className='sticky top-0 h-16 w-full bg-near-white z-10' />
        <div className='flex-grow w-full flex flex-col items-center pb-2'>
          <div className='divide-y divide-gray-200 flex-grow-1 flex-shrink-0 w-full h-full overflow-y-scroll'>
            <div className='px-4 py-5 sm:px-0 sm:py-0'>
              {form.answers &&
                Object.values(form.answers).map(element => (
                  <div key={element.key} className='sm:flex sm:flex-col sm:px-6 py-2'>
                    <AScreeningFormQuestionAnswer
                      question={element.description || element.key}
                      answer={element.value}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OScreeningFormSidebar
