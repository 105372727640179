import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import IconDropdown from '../icons/IconDropdown'

export type MDropdownProps = {
  label: string | React.ReactNode
  hoverIcon?: React.ReactNode

  align?: 'left' | 'right'
  children: React.ReactNode
}

const MDropdown = ({ label, hoverIcon = null, align = 'right', children }: MDropdownProps) => {
  const [hover, setHover] = useState(false)

  return (
    <Menu as='div' className={`relative inline-block text-${align}`}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className='focus:outline-none flex items-center justify-center px-1 whitespace-nowrap capitalize'
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {label || <IconDropdown />}
              {hoverIcon && (
                <span className={`opacity-${hover ? '100' : '0'} pl-2 pb-1`}>{hoverIcon}</span>
              )}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              static
              className='origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50'
            >
              <div className='py-1'>{children}</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default MDropdown
