import { useForm } from '@mantine/form'
import {
  Alert,
  ArrowRightIcon,
  BookmarkIcon,
  Divider,
  Drawer,
  Group,
  PrimaryButton,
  SecondaryButton,
  Select,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  Textarea,
  TitleTwo,
  skipIfOtherField,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import { COCMRegistryItem, CaseReviewNote, CaseReviewNoteContent } from '@shared/types'
import { useEffect, useState } from 'react'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { caseReviewNoteApi, emrApi } from '../../../api'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'
import { useAuth } from '../../../context/auth'
import { isRequired } from '../../../utils/formValidation'
import * as FullStory from '../../../utils/fullstory'
import { useEmployees, useEmrQuery, useFlags } from '../../../utils/hooks'
import { PrescriptionReorderDrawer } from '../../patient/prescriptions/PrescriptionReorderDrawer'
import PrescriptionRow from '../../patient/prescriptions/PrescriptionRow'
import { ObjectiveMeasuresSection } from '../objectiveMeasures/ObjectiveMeasuresSection'
import LockCaseReviewModal from './LockCaseReviewModal'

type CaseReviewNoteForm = {
  summary: string
  assessmentAndRecommendations: string
  preWorkNotes: string
  preWorkMinutes: string
  psychConsultantId: string
  assessment: string
  recommendations: string
}
const getNoteContent = (noteContent?: CaseReviewNoteContent): CaseReviewNoteForm => {
  return {
    summary: noteContent?.summary || '',
    recommendations: noteContent?.recommendations || '',
    assessment: noteContent?.assessment || '',
    preWorkMinutes: noteContent?.preWorkMinutes || '',
    preWorkNotes: noteContent?.preWorkNotes || '',
    psychConsultantId: noteContent?.psychConsultantId || '',
    assessmentAndRecommendations: noteContent?.assessmentAndRecommendations || '',
  }
}

export const UnlockedCaseReviewDrawer = ({
  opened,
  onClose,
  showName,
  patientCocmItem,
  patientName,
  caseReviewNote,
}: {
  showName: boolean
  opened: boolean
  onClose: () => void
  patientCocmItem: COCMRegistryItem
  patientName: { firstName: string; lastName: string }
  caseReviewNote: CaseReviewNote
}) => {
  const patientId = patientCocmItem?.patientId
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const [openLockModal, setOpenLockModal] = useState<boolean>(false)
  const { revampedCaseReviewNotes } = useFlags()

  const showCombinedAssessmentSection =
    revampedCaseReviewNotes &&
    // In case there is an in progress, unlocked note before the switch to the combined view
    !caseReviewNote?.content?.assessment &&
    !caseReviewNote?.content?.recommendations

  const updateCaseReviewNote = useMutation(caseReviewNoteApi.update, {
    onSuccess: () => {
      void queryClient.invalidateQueries('GET /caseReview')
      if (caseReviewNote.visitId) {
        const [queryKey] = emrApi.getQuery('GET /caseReviewNote/visit/:visitId', {
          params: {
            visitId: `${caseReviewNote.visitId}`,
          },
        })
        void queryClient.invalidateQueries(queryKey)
      }
    },
  })

  const [isSendPrescriptionDrawerOpened, setIsSendPrescriptionDrawerOpened] = useState(false)

  const phq8ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/phq8Responses',
    {
      params: { patientId: patientId || '' },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const gad7ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/gad7Responses',
    {
      params: {
        patientId: patientId || '',
      },
    },
    { enabled: Boolean(patientId) },
  )

  const barc10ResponsesQuery = useEmrQuery(
    'GET /patients/:patientId/barc10Responses',
    {
      params: {
        patientId: patientId || '',
      },
    },
    { enabled: Boolean(patientId) },
  )

  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const psychConsultants = employeesQuery?.data?.filter(employee => employee.isPsychConsultant)

  // without this, there's a race condition somewhere that notes are on occasion improperly copied from one patient to another
  useEffect(() => {
    reset()
  }, [patientId])

  useEffect(() => {
    if (opened) {
      FullStory.event('Viewed case review note')
    }
  }, [opened])

  const skipIfCombinedAssessmentShown = () => {
    return Boolean(showCombinedAssessmentSection)
  }

  const prescriptionQueries = useQueries(
    caseReviewNote?.prescriptionIds?.map(id => {
      const [queryKey, queryFn] = emrApi.getQuery(
        'GET /patient/:patientId/legacyPrescription/:prescriptionId',
        {
          params: {
            prescriptionId: String(id),
            patientId: patientId || '',
          },
        },
      )

      return {
        queryKey,
        queryFn,
        enabled: Boolean(patientId),
      }
    }) ?? [],
  )

  const { getInputProps, values, validate, setValues, reset } = useForm<CaseReviewNoteForm>({
    initialValues: getNoteContent(),
    validate: {
      summary: validateWith(isRequired),
      assessment: validateWith(skipIfCombinedAssessmentShown, isRequired),
      recommendations: validateWith(skipIfCombinedAssessmentShown, isRequired),
      assessmentAndRecommendations: validateWith(
        () => !skipIfCombinedAssessmentShown(),
        skipIfOtherField('assessment', 'not', ''),
        isRequired,
      ),
      psychConsultantId: validateWith(isRequired),
    },
  })

  useEffect(() => {
    if (caseReviewNote?.content) {
      setValues(getNoteContent(caseReviewNote.content))
    }
  }, [caseReviewNote])

  const {
    other: { colors },
  } = useMantineTheme()

  if (!patientCocmItem || !patientId) {
    return null
  }

  const onSubmit = async () => {
    if (validate().hasErrors) {
      return
    }
    await onSave()
    setOpenLockModal(true)
  }

  const onSave = async () => {
    await updateCaseReviewNote.mutateAsync({
      oid: caseReviewNote.oid,
      data: { content: values },
    })
  }

  const disabled = currentUser.oid !== patientCocmItem?.employeeId

  const exitAndSave = async () => {
    if (!disabled) {
      await onSave()
    }
    onClose()
  }

  const queuePrescriptionOnClick = async () => {
    // Save the note when the user opens the prescription drawer
    await onSave()
    setIsSendPrescriptionDrawerOpened(true)
  }

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => (caseReviewNote?.isLocked ? onClose() : exitAndSave())}
        title='Case review note'
        footer={
          <Group position='right'>
            <SecondaryButton onClick={onSave} disabled={disabled}>
              Save
            </SecondaryButton>
            <PrimaryButton onClick={onSubmit} disabled={disabled}>
              Sign and lock
            </PrimaryButton>
          </Group>
        }
        position='right'
        size='lg'
      >
        <Stack p='md'>
          {showName && (
            <Group>
              <TitleTwo>{`${patientName.lastName}, ${patientName.firstName}`}</TitleTwo>
              <TertiaryButton
                component={Link}
                to={`/patients/${patientId}`}
                rightIcon={<ArrowRightIcon color={colors.actions[0]} />}
              >
                Go to chart
              </TertiaryButton>
            </Group>
          )}
          {disabled && (
            <Alert variant='warning' icon={<BookmarkIcon />} sx={{ fontColor: colors.text[0] }}>
              You are not permitted to write to this note
            </Alert>
          )}
          {!revampedCaseReviewNotes && (
            <>
              <Textarea
                label='Pre-work notes (optional)'
                {...getInputProps('preWorkNotes')}
                minRows={4}
                autosize
                placeholder='Type pre-work notes here...'
                disabled={disabled}
              />
              <TextInput
                {...getInputProps('preWorkMinutes')}
                label='Total minutes spent on pre-work'
                placeholder='Minutes'
                disabled={disabled}
              />
              <Divider />
            </>
          )}
          <Stack spacing='lg'>
            {psychConsultants && (
              <Select
                label='Psychiatric consultant'
                placeholder='Select'
                data={psychConsultants?.map(employee => ({
                  label: employee.name,
                  value: employee.oid,
                }))}
                disabled={disabled}
                {...getInputProps('psychConsultantId')}
              />
            )}
            <Textarea
              disabled={disabled}
              label='Summary'
              autosize
              {...getInputProps('summary')}
              minRows={6}
              placeholder='In 3 to 5 sentences, describe any relevant symtpoms, past treamtents, current substance use, and suicidality indicators that were discussed during case review'
            />
            <Stack>
              <Text color={colors => colors.text[0]}>Objective measures</Text>
              <ObjectiveMeasuresSection
                showCaseReviewAlert={false}
                isLoading={
                  gad7ResponsesQuery.isLoading ||
                  barc10ResponsesQuery.isLoading ||
                  phq8ResponsesQuery.isLoading
                }
                phqResponses={phq8ResponsesQuery?.data ?? []}
                gad7Responses={gad7ResponsesQuery?.data ?? []}
                barc10Responses={barc10ResponsesQuery?.data ?? []}
                editMode={false}
                patientId={patientId}
              />
            </Stack>
            {showCombinedAssessmentSection ? (
              <Textarea
                disabled={disabled}
                label='Assessment and recommendations'
                autosize
                {...getInputProps('assessmentAndRecommendations')}
                minRows={6}
                placeholder='Describe the recommendations discussed with the psychiatric consultant'
              />
            ) : (
              <>
                <Textarea
                  disabled={disabled}
                  label='Assessment'
                  autosize
                  {...getInputProps('assessment')}
                  minRows={6}
                  placeholder='Provide your assessment of this patient'
                />
                <Textarea
                  disabled={disabled}
                  label='Recommendations'
                  autosize
                  {...getInputProps('recommendations')}
                  minRows={6}
                  placeholder='Provide case review recommendations for this patient'
                />
              </>
            )}
            {revampedCaseReviewNotes && (
              <Stack>
                <Text color={colors => (disabled ? colors.text[1] : colors.text[0])}>
                  Medication
                </Text>
                {caseReviewNote.prescriptionIds ? (
                  prescriptionQueries.map((query, index) => {
                    if (query.data) {
                      const prescription = query.data
                      return (
                        <PrescriptionRow
                          key={query.data?.prescription_id}
                          prescription={prescription}
                          patientId={patientId}
                        />
                      )
                    }
                    if (query.isLoading) {
                      return (
                        <Skeleton
                          width='100%'
                          height={100}
                          key={`prescription-skeleton-${caseReviewNote.prescriptionIds?.[index]}`}
                        />
                      )
                    }
                    return null
                  })
                ) : (
                  <EmptyDataCell message='No medication queued' />
                )}
                {!disabled && (
                  <PrimaryButton
                    onClick={queuePrescriptionOnClick}
                    loading={updateCaseReviewNote.isLoading}
                    disabled={updateCaseReviewNote.isLoading}
                  >
                    Queue prescription
                  </PrimaryButton>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Drawer>
      <PrescriptionReorderDrawer
        patientId={patientId}
        opened={isSendPrescriptionDrawerOpened}
        onClose={() => setIsSendPrescriptionDrawerOpened(false)}
        setPrescriptionBanner={() => null}
        caseReviewNoteId={caseReviewNote.oid}
      />
      <LockCaseReviewModal
        closeDrawer={onClose}
        noteId={caseReviewNote?.oid || ''}
        visitId={caseReviewNote?.visitId}
        isLoading={false}
        preWorkMinutes={values?.preWorkMinutes}
        psychConsultantName={
          psychConsultants?.find(employee => employee.oid === values.psychConsultantId)?.name || ''
        }
        closeModal={() => setOpenLockModal(false)}
        open={openLockModal}
      />
    </>
  )
}
