import {
  BannerProvider,
  createEmotionCache,
  DAYBREAK_FONT_FAMILIES,
  DAYBREAK_FONT_FILE_NAMES,
  DAYBREAK_THEME,
  Global,
  MantineProvider,
  Notifications,
} from '@shared/components'
import { isAxiosError } from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { firebaseAuthApi } from './api'
import App from './App'
import { LaunchDarklyProvider } from './components/LaunchDarklyProvider'
import { Config } from './config'
import { AuthProvider } from './context/auth'

const { FONT_CDN_BASE_URL } = Config
const { regular, bold, pro } = DAYBREAK_FONT_FILE_NAMES

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: firebaseAuthApi.authTokenTtl,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isAxiosError(error)) {
          const errorStatus = error.response?.status
          // Only retry 5xx errors
          if (errorStatus && errorStatus < 500) {
            return false
          }
        }

        return failureCount < 3
      },
    },
  },
})

const appendCache = createEmotionCache({ key: 'mantine', prepend: false })

export const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={DAYBREAK_THEME} emotionCache={appendCache}>
        <Notifications position='bottom-left' />
        <Global
          styles={[
            {
              '@font-face': {
                fontFamily: DAYBREAK_FONT_FAMILIES.regular,
                src: `url(${new URL(`/fonts/${regular}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontWeight: 400,
                fontStyle: 'normal',
              },
            },
            {
              '@font-face': {
                fontFamily: DAYBREAK_FONT_FAMILIES.bold,
                src: `url(${new URL(`/fonts/${bold}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontWeight: 700,
                fontStyle: 'normal',
              },
            },
            {
              '@font-face': {
                src: `url(${new URL(`/fonts/${pro}`, FONT_CDN_BASE_URL)}) format('woff2')`,
                fontFamily: DAYBREAK_FONT_FAMILIES.pro,
                fontWeight: 900,
                fontStyle: 'normal',
              },
            },
          ]}
        />
        <BrowserRouter>
          <AuthProvider>
            <LaunchDarklyProvider>
              <BannerProvider>
                <App />
              </BannerProvider>
            </LaunchDarklyProvider>
          </AuthProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
      </MantineProvider>
    </QueryClientProvider>
  )
}
