import {
  DoseSpotIdResponse,
  DoseSpotMedication,
  DoseSpotMedicationSearchResult,
  PrescriptionCancelationReason,
  PrescriptionRequest,
  RefillRequestSummary,
  SendPrescriptionData,
  SendPrescriptionsData,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'
export class PrescriptionsApi extends BaseApi {
  search = (query: string) => {
    return this.json<DoseSpotMedicationSearchResult[]>({
      method: 'GET',
      url: `/medication/search?query=${query}`,
    })
  }

  sendPrescription = (data: SendPrescriptionData) => {
    return this.json<DoseSpotIdResponse>({
      method: 'POST',
      url: `/prescription/${data.prescriptionId}/send`,
      data,
    })
  }

  sendPrescriptions = (data: SendPrescriptionsData) => {
    return this.json<DoseSpotIdResponse[]>({
      method: 'POST',
      url: `/prescriptions/send`,
      data,
    })
  }

  editPrescription = ({
    data,
    prescriptionId,
  }: {
    data: PrescriptionRequest
    prescriptionId: number
  }) => {
    return this.json<DoseSpotIdResponse>({
      method: 'POST',
      url: `/prescription/${prescriptionId}/edit`,
      data,
    })
  }

  deletePrescription = ({
    patientId,
    prescriptionId,
  }: {
    patientId: string
    prescriptionId: string
  }) => {
    return this.json<Res>({
      method: 'DELETE',
      url: `patient/${patientId}/prescriptions/${prescriptionId}/delete`,
    })
  }

  cancelPrescription = ({
    patientId,
    prescriptionId,
    cancelationReason,
  }: {
    patientId: string
    prescriptionId: string
    cancelationReason: PrescriptionCancelationReason
  }) => {
    return this.json<Res>({
      method: 'DELETE',
      url: `patient/${patientId}/prescriptions/${prescriptionId}`,
      data: { cancelationReason },
    })
  }

  getMedication = async ({
    /*
     * @dosespotV2Migration
     * To get medication information in v2, we only need to send the dispensavbleDrugId.
     * Once we fully migrate to v2 we will deprecate the medication and medication_strength fields.
     */
    medication,
    medication_strength,
    dispensableDrugId,
  }: {
    medication: string
    medication_strength: string
    dispensableDrugId?: number
  }) => {
    const medicationResponse = await this.json<DoseSpotMedication>({
      method: 'GET',
      url: '/medication',
      params: {
        name: medication,
        strength: medication_strength,
        dispensableDrugId,
      },
    })
    if (medicationResponse) {
      return Promise.resolve(medicationResponse)
    }
    return Promise.reject(
      `No dosespot medication found with name: ${medication} and strength: ${medication_strength}`,
    )
  }

  getMedicationByRxCUI = (rxCui: string) => {
    return this.json<DoseSpotMedication>({
      method: 'GET',
      url: `/medications/select?rxCUI=${rxCui}`,
    })
  }

  getPatientRefillRequestsCount = (patientId: string) => {
    return this.json<{ count: number }>({
      method: 'GET',
      url: `/patient/${patientId}/prescription/refills/count`,
    })
  }

  getPatientRefillRequests = (patientId: string) => {
    return this.json<RefillRequestSummary[]>({
      method: 'GET',
      url: `/patient/${patientId}/prescription/refills`,
    })
  }

  getClinicianRefillRequestsCount = (patientId: string) => {
    return this.json<{ count: number }>({
      method: 'GET',
      url: `/patient/${patientId}/prescription/refills/count`,
    })
  }

  getClinicianRefillRequests = (patientId: string) => {
    return this.json<RefillRequestSummary[]>({
      method: 'GET',
      url: `/patient/${patientId}/prescription/refills`,
    })
  }

  approveRefillRequest = ({
    patientId,
    refillId,
    data,
  }: {
    patientId: string
    refillId: string
    data: { refills: number }
  }) => {
    return this.json<RefillRequestSummary[]>({
      method: 'POST',
      url: `/patient/${patientId}/prescription/refills/${refillId}/approve`,
      data,
    })
  }

  denyRefillRequest = ({
    patientId,
    refillId,
    data,
  }: {
    patientId: string
    refillId: string
    data: { denialReason: number }
  }) => {
    return this.json<RefillRequestSummary[]>({
      method: 'POST',
      url: `/patient/${patientId}/prescription/refills/${refillId}/deny`,
      data,
    })
  }
}
