import { Divider, Group, PrimaryButton, Stack, TitleFour, Tooltip } from '@shared/components'
import { ShipmentResult, isAdmin, isLeadCoordinator } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import maxBy from 'lodash/maxBy'
import range from 'lodash/range'
import { useState } from 'react'
import { useAuth } from '../../../context/auth'
import { usePatient } from '../PPatientContext'
import AFakeLabOrderRow from './AFakeLabOrderRow'
import { LabOrderCancelModal } from './LabOrderCancelModal'
import LabOrdersEmptyState from './LabOrderEmptyState'
import LabOrderRow from './LabOrderRow'

export type LabOrderSectionProps = { labOrders: ShipmentResult[] | undefined; isLoading: boolean }

const allowCancelation = (labOrder: ShipmentResult) => {
  const shippingPartnerAllowsCancelations = labOrder.shipping_partner === 'shipfusion'
  const shipmentIsInitiated = !labOrder.shipped && !labOrder.failed && !labOrder.canceled
  return shipmentIsInitiated && shippingPartnerAllowsCancelations
}

const LabOrderSection = ({ labOrders, isLoading }: LabOrderSectionProps) => {
  const { currentUser } = useAuth()
  const [labOrderToCancel, setLabOrderToCancel] = useState<ShipmentResult | null>(null)

  const { setModal } = usePatient()

  const displayEmptyState = !isLoading && (!labOrders || labOrders.length === 0)

  const successfulShipments = labOrders?.filter(labOrder => !labOrder.canceled && !labOrder.failed)
  const mostRecentSuccessfulShipment = maxBy(successfulShipments, d => d.timestamp)

  const notAdminOrLeadCoordinator = !(isAdmin(currentUser) || isLeadCoordinator(currentUser))

  const disableOrdering =
    notAdminOrLeadCoordinator &&
    mostRecentSuccessfulShipment &&
    dayjs(mostRecentSuccessfulShipment?.timestamp).isAfter(dayjs().subtract(1, 'day'))

  return (
    <Stack>
      {displayEmptyState ? (
        <LabOrdersEmptyState onClick={() => setModal({ type: 'order-uds-cup' })} />
      ) : (
        <>
          <Group position='apart'>
            <TitleFour>UDS Cup Orders</TitleFour>
            <Tooltip
              label='A lab has been ordered for this patient in the last 24 hours'
              disabled={!disableOrdering}
            >
              <PrimaryButton
                onClick={() => setModal({ type: 'order-uds-cup' })}
                disabled={disableOrdering}
              >
                Order UDS cup
              </PrimaryButton>
            </Tooltip>
          </Group>
          <Divider />
          {isLoading && range(0, 5).map(i => <AFakeLabOrderRow key={i} />)}
          {labOrders?.sort(sortBy({ key: 'timestamp', order: 'DESC' })).map((labOrder, index) => {
            const isMostRecentOrder = index === 0
            return (
              <LabOrderRow
                key={labOrder.id}
                labOrder={labOrder}
                allowRetry={isMostRecentOrder && Boolean(labOrder.failed)}
                retryOnClick={() => setModal({ type: 'order-uds-cup' })}
                allowCancelation={allowCancelation(labOrder)}
                cancelOnClick={() => setLabOrderToCancel(labOrder)}
              />
            )
          })}
        </>
      )}
      <LabOrderCancelModal
        opened={Boolean(labOrderToCancel)}
        orderBeingCanceled={labOrderToCancel}
        onClose={() => setLabOrderToCancel(null)}
      />
    </Stack>
  )
}

export default LabOrderSection
