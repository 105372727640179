import { Select, SelectProps } from '@shared/components'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitNoteReadOnlyText } from './PatientVisitNoteReadOnlyText'

export const PatientVisitNoteSelect = (props: SelectProps) => {
  const { canEdit } = usePatientVisitNote()

  if (!canEdit) {
    return <PatientVisitNoteReadOnlyText label={props.label} value={props.value} />
  }

  return <Select {...props} />
}
