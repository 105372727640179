import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import { Grid, Select, TextInput, TitleThree, skipIfEmpty, validateWith } from '@shared/components'
import {
  AddressData,
  DOSESPOT_ADDRESS_CHARACTER_LIMIT,
  Patient,
  getOpheliaHttpError,
  stateNames,
} from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import {
  inputCharacterLimitExceeded,
  isAddress,
  isRequired,
  isZipCode,
} from '../../../utils/formValidation'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const getHomeData = (patient?: Patient): AddressData => {
  return {
    address: patient?.homeData?.address ?? '',
    city: patient?.homeData?.city ?? '',
    state: patient?.homeData?.state ?? '',
    zip: patient?.homeData?.zip ?? '',
    aptSuite: patient?.homeData?.aptSuite ?? '',
  }
}

export const PatientProfileHomeAddress = ({ open }: { open: boolean }) => {
  const { patientId, patientQuery } = usePatient()
  const patient = patientQuery?.data

  const form = useForm<ReturnType<typeof getHomeData>>({
    initialValues: getHomeData(patient),
    validate: {
      address: validateWith(
        isRequired,
        isAddress,
        inputCharacterLimitExceeded({ characterLimit: DOSESPOT_ADDRESS_CHARACTER_LIMIT }),
      ),
      aptSuite: validateWith(skipIfEmpty, isAddress),
      zip: validateWith(isRequired, isZipCode),
      city: validateWith(isRequired),
      state: validateWith(isRequired),
    },
  })

  const initializeForm = (patient?: Patient) => {
    form.setValues(getHomeData(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const [isEditing, toggleIsEditing] = useToggle([open, !open])

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: {
          homeData: form.values,
        },
      })
    }

    void patientQuery?.refetch()
    toggleIsEditing()
  }

  return (
    <EditableSection
      title={<TitleThree>Home address</TitleThree>}
      isLoading={patientQuery?.isLoading ?? false}
      isEditing={isEditing}
      onEdit={() => toggleIsEditing()}
      isSaving={updatePatient.isLoading}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      onSave={() => onSave()}
      error={getOpheliaHttpError(updatePatient.error, 'Error updating home address information')}
    >
      <Grid>
        <EditableCol
          span={4}
          isEditing={isEditing}
          label='Address line 1'
          text={form.values.address}
        >
          <TextInput
            placeholder='Address line 1'
            {...form.getInputProps('address')}
            autoFocus={open}
          />
        </EditableCol>
        <EditableCol
          span={4}
          isEditing={isEditing}
          label='Address line 2 (optional)'
          text={form.values.aptSuite}
        >
          <TextInput placeholder='Address line 2 (optional)' {...form.getInputProps('aptSuite')} />
        </EditableCol>
        <EditableCol span={4} isEditing={isEditing} label='City' text={form.values.city}>
          <TextInput placeholder='City' {...form.getInputProps('city')} />
        </EditableCol>
        <EditableCol span={4} isEditing={isEditing} label='State' text={form.values.state}>
          <Select
            label='State'
            placeholder='Select one...'
            searchable
            data={stateNames}
            filterDataOnExactSearchMatch
            {...form.getInputProps('state')}
          />
        </EditableCol>
        <EditableCol span={4} isEditing={isEditing} label='Zip code' text={form.values.zip}>
          <TextInput placeholder='Zip code' {...form.getInputProps('zip')} />
        </EditableCol>
      </Grid>
    </EditableSection>
  )
}
