import { Fragment } from 'react'
import { FieldError, FieldValues, NestDataObject } from 'react-hook-form'
import { required } from '../../components/molecules/MDictionary'
import { ReactHookRegister } from '../../utils/reactHookForm'

export type MProblemsAddressedDetailsProps = {
  register: ReactHookRegister
  code: string
  errors: NestDataObject<FieldValues, FieldError>
  masterWatcher: FieldValues
}

const MProblemsAddressedDetails = ({
  register,
  code,
  errors,
  masterWatcher,
}: MProblemsAddressedDetailsProps) => {
  return (
    <Fragment>
      <fieldset className='ml-8 my-4 inline-flex items-center'>
        <div className='w-24'>
          <span className='font-medium'>
            Chronicity{' '}
            <span className='font-normal text-error'>{`${
              errors[`${code}_chronicity`] ? errors[`${code}_chronicity`].message : ''
            }`}</span>
          </span>
        </div>
        <div className='relative inline-flex bg-white rounded-md shadow-sm'>
          <label
            className={`${
              masterWatcher[`${code}_chronicity`] === 'Chronic'
                ? 'bg-daybreak-bg2 border-daybreak-actions1 z-10'
                : 'border-gray-200'
            } rounded-l-md relative border p-2 flex cursor-pointer`}
          >
            <input
              ref={register({ required: { value: true, message: required } })}
              type='radio'
              name={`${code}_chronicity`}
              value='Chronic'
              className='h-4 w-4 mt-0.5 cursor-pointer text-daybreak-actions1 border-gray-300 focus:ring-daybreak-actions1'
            />
            <div className='ml-3 flex flex-col'>
              <span
                id='chronicity-0-label'
                className={`${
                  masterWatcher[`${code}_chronicity`] === 'Chronic'
                    ? 'text-daybreak-text3'
                    : 'text-gray-900'
                } block text-sm`}
              >
                Chronic
              </span>
            </div>
          </label>
          <label
            className={`${
              masterWatcher[`${code}_chronicity`] === 'Acute'
                ? 'bg-daybreak-bg2 border-daybreak-actions1 z-10'
                : 'border-gray-200'
            } rounded-r-md relative border p-2 flex cursor-pointer -ml-px`}
          >
            <input
              ref={register({ required: { value: true, message: required } })}
              type='radio'
              name={`${code}_chronicity`}
              value='Acute'
              className='h-4 w-4 mt-0.5 cursor-pointer text-daybreak-actions1 border-gray-300 focus:ring-daybreak-actions1'
            />
            <div className='ml-3 flex flex-col'>
              <span
                id='chronicity-1-label'
                className={`${
                  masterWatcher[`${code}_chronicity`] === 'Acute'
                    ? 'text-daybreak-text3'
                    : 'text-gray-900'
                } block text-sm`}
              >
                Acute
              </span>
            </div>
          </label>
        </div>
      </fieldset>
      <fieldset className='ml-8 inline-flex items-center'>
        <div className='w-24'>
          <span className='font-medium'>
            Progression{' '}
            <span className='font-normal text-error'>{`${
              errors[`${code}_progression`] ? errors[`${code}_progression`].message : ''
            }`}</span>
          </span>
        </div>
        <div className='relative inline-flex bg-white rounded-md shadow-sm'>
          <label
            className={`${
              masterWatcher[`${code}_progression`] === 'Exacerbating'
                ? 'bg-daybreak-bg2 border-daybreak-actions1 z-10'
                : 'border-gray-200'
            } rounded-l-md relative border p-2 flex cursor-pointer`}
          >
            <input
              ref={register({ required: { value: true, message: required } })}
              type='radio'
              name={`${code}_progression`}
              value='Exacerbating'
              className='h-4 w-4 mt-0.5 cursor-pointer text-daybreak-actions1 border-gray-300 focus:ring-daybreak-actions1'
            />
            <div className='ml-3 flex flex-col'>
              <span
                id='progression-0-label'
                className={`${
                  masterWatcher[`${code}_progression`] === 'Exacerbating'
                    ? 'text-daybreak-text3'
                    : 'text-gray-900'
                } block text-sm`}
              >
                Exacerbating
              </span>
            </div>
          </label>
          <label
            className={`${
              masterWatcher[`${code}_progression`] === 'Stable'
                ? 'bg-daybreak-bg2 border-daybreak-actions1 z-10'
                : 'border-gray-200'
            } rounded-r-md relative border p-2 flex cursor-pointer -ml-px`}
          >
            <input
              ref={register({ required: { value: true, message: required } })}
              type='radio'
              name={`${code}_progression`}
              value='Stable'
              className='h-4 w-4 mt-0.5 cursor-pointer text-daybreak-actions1 border-gray-300 focus:ring-daybreak-actions1'
            />
            <div className='ml-3 flex flex-col'>
              <span
                id='progression-1-label'
                className={`${
                  masterWatcher[`${code}_progression`] === 'Stable'
                    ? 'text-daybreak-text3'
                    : 'text-gray-900'
                } block text-sm`}
              >
                Stable
              </span>
            </div>
          </label>
        </div>
      </fieldset>
    </Fragment>
  )
}

export default MProblemsAddressedDetails
