import { Alert, FlagIcon, List, ListItem } from '@shared/components'

export const HighRiskPatientAlert = () => {
  return (
    <Alert
      icon={<FlagIcon />}
      title='Consider for high risk patient induction pilot'
      variant='secondary'
    >
      <List
        sx={({ other: { colors }, fontSizes }) => ({
          color: colors.text[2],
          fontSize: fontSizes.xs,
        })}
      >
        <ListItem>Patient uses heroin or fentanyl on a regular basis</ListItem>
        <ListItem>Patient has not taken Suboxone in the last week</ListItem>
      </List>
    </Alert>
  )
}
