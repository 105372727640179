import { useForm } from '@mantine/form'
import {
  DatePicker,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
} from '@shared/components'
import { ISOString, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { bulkExportToPdf } from '../../utils/utils'

type BulkExportVisitNotesModalProps = {
  closeModal: () => void
  patient: Patient
}

const BulkExportVisitNotesModal = ({ closeModal, patient }: BulkExportVisitNotesModalProps) => {
  const [exportError, setExportError] = useState<null | string>(null)
  const form = useForm<{
    startDate: ISOString
    endDate: ISOString
  }>({
    initialValues: {
      startDate: dayjs().subtract(1, 'week').startOf('day').toISOString(),
      endDate: dayjs().endOf('day').toISOString(),
    },
    validate: {
      startDate: (startDate, values) => {
        return dayjs(startDate).isSameOrAfter(values.endDate)
          ? 'Start date must be before end date'
          : undefined
      },
    },
    transformValues: values => ({
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    }),
  })

  const bulkExportMutation = useMutation(
    emrApi.getMutation('GET /patients/:patientId/visits/export'),
  )

  const handleExport = async () => {
    if (form.validate().hasErrors) {
      return
    }

    const dateRange = form.getTransformedValues()

    const allNotes = await bulkExportMutation.mutateAsync({
      params: { patientId: patient.oid },
      query: dateRange,
    })

    if (!allNotes?.length) {
      setExportError('No locked visit notes found for this date range')
      return
    }

    await bulkExportToPdf(patient, allNotes, dateRange)
    closeModal()
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Export visit notes'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          <PrimaryButton loading={bulkExportMutation.isLoading} onClick={handleExport}>
            Start export
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Stack spacing='xs'>
          <Text>Select the date range to export visit notes for:</Text>
        </Stack>
        <Group noWrap align='start'>
          <DatePicker label='Start date' {...form.getInputProps('startDate')} />
          <DatePicker label='End date' {...form.getInputProps('endDate')} />
        </Group>
        {exportError && <Text c='red'>{exportError}</Text>}
        <Text>Only locked visit notes will be exported.</Text>
      </Stack>
    </Modal>
  )
}

export default BulkExportVisitNotesModal
