import { Pill } from '@shared/components'
import { EMRTaskPriority, EMRTaskType, OUTAGE_TASKS, OutageTaskPriority } from '@shared/types'

const getPriorityStatus = (priority: EMRTaskPriority) => {
  switch (priority) {
    case 5:
      return 'error'
    case 4:
      return 'warning'
    default:
      return 'none'
  }
}

export const PriorityIcon = ({
  type,
  priority,
}: {
  type: EMRTaskType
  priority: EMRTaskPriority | OutageTaskPriority
}) => {
  if (type === 'outage') {
    return (
      <Pill status='error' variant='filled'>
        {OUTAGE_TASKS[priority as OutageTaskPriority].shortLabel}
      </Pill>
    )
  }

  return (
    <Pill status={getPriorityStatus(priority as EMRTaskPriority)} variant='filled'>
      {priority}
    </Pill>
  )
}
