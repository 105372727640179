import { Stack } from '@shared/components'
import { BalanceTableSection } from './BalanceTableSection'
import { PaymentCardsSection } from './PaymentCardsSection'
import { PaymentPlanSection } from './PaymentPlanSection'
import { SubscriptionInfoSection } from './SubscriptionInfoSection'

export const BillingContent = (props: { active: boolean }) => {
  if (!props.active) {
    return null
  }

  return (
    <Stack>
      <BalanceTableSection />
      <SubscriptionInfoSection />
      <PaymentPlanSection />
      <PaymentCardsSection />
    </Stack>
  )
}
