import React from 'react'

export type AFormGroupProps = {
  className?: string
  children?: React.ReactNode
}

const AFormGroup = ({ children, className = '' }: AFormGroupProps) => (
  <div className={`w-full mb-4 ${className}`}>{children}</div>
)

export default AFormGroup
