import {
  PatientReleaseOfInformation,
  PatientReleaseOfInformationAddChangeRequestBody,
  PatientReleaseOfInformationModel,
  RequireAtLeastOne,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

export class ReleaseOfInformationApi extends BaseApi {
  GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY = 'releaseOfInformationApi.getAllReleasesOfInformation'

  getAllReleasesOfInformation = (patientId: string) =>
    this.json<PatientReleaseOfInformation[], null>({
      method: 'GET',
      url: `/patient/${patientId}/release-of-information`,
    })

  updateReleaseOfInformation = ({
    patientId,
    roiId,
    data,
  }: {
    patientId: string
    roiId: string
    data: RequireAtLeastOne<PatientReleaseOfInformationModel>
  }): Promise<void> =>
    this.json({ method: 'PUT', url: `/patient/${patientId}/release-of-information/${roiId}`, data })

  addReleaseOfInformationChangeRequest = ({
    patientId,
    roiId,
    data,
  }: {
    patientId: string
    roiId: string
    data: PatientReleaseOfInformationAddChangeRequestBody
  }): Promise<void> =>
    this.json({
      method: 'PUT',
      url: `/patient/${patientId}/release-of-information/${roiId}/change-request`,
      data,
    })
}
