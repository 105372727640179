import { useToggle } from '@mantine/hooks'
import { Checkbox, Divider, Stack } from '@shared/components'
import { TaskType, TaskTypeMap, TaskTypeMapType } from '@shared/types'
import { ChangeEvent } from 'react'
import { AccordionHeader } from './FilterTaskDrawer'
import { MAX_TYPES_TO_INCLUDE } from './PTasks'
import { SelectIssueType } from './SelectIssueType'
import { useFiltersFormContext } from './filterFormHelpers'

const ISSUE_TYPE_DEFAULT_OPTIONS: Partial<TaskTypeMapType>[] = [
  {
    value: 'bridge_prescription',
    label: 'Bridge prescription',
  },
  {
    value: 'documentation_of_care',
    label: 'Documentation of care',
  },
  {
    value: 'medical_concern',
    label: 'Medical concern',
  },
  {
    value: 'prescription_investigation',
    label: 'Prescription investigation',
  },
  {
    value: 'reschedule_follow_up',
    label: 'Reschedule follow up',
  },
  {
    value: 'resend_prescription',
    label: 'Resend prescription',
  },
  {
    value: 'resource_request',
    label: 'Resource request',
  },
  {
    value: 'schedule_follow_up',
    label: 'Schedule follow up',
  },
  {
    value: 'support_plan',
    label: 'Support plan',
  },
]

const separateSelectedTypes = ({ types }: { types: TaskType[] | undefined }) => {
  const presetTypes =
    types?.filter(value =>
      ISSUE_TYPE_DEFAULT_OPTIONS.map(option => option.value).includes(value),
    ) || []
  const selectedTypes = types?.filter(value => !presetTypes?.includes(value)) || []

  return {
    presetTypes,
    selectedTypes,
  }
}

export const IssueTypeFilter = () => {
  const filters = useFiltersFormContext()
  const { types } = filters.values

  const { presetTypes, selectedTypes } = separateSelectedTypes({ types })

  // Specifying the array as `[true, false]` defaults to opening the accordion, since it uses the first value as the default
  const [isIssueTypeAccordionSelected, toggleIssueTypeAccordion] = useToggle([true, false])

  const selectedMaximumTypes = types && types.length >= MAX_TYPES_TO_INCLUDE

  const onChange = (type: TaskType) => {
    filters.setValues({
      types: types?.includes(type) ? types?.filter(t => t !== type) : [...(types || []), type],
    })
  }

  return (
    <Stack>
      <AccordionHeader
        isSelected={isIssueTypeAccordionSelected}
        onClick={() => toggleIssueTypeAccordion()}
        title='Issue type'
      />
      {isIssueTypeAccordionSelected && (
        <Stack spacing='sm'>
          {ISSUE_TYPE_DEFAULT_OPTIONS.map(issueType => {
            const checked = presetTypes.some(p => p === issueType.value)
            return (
              <Checkbox
                key={issueType.value}
                value={issueType.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChange(event.target.value as TaskType)
                }
                label={issueType.label}
                checked={checked}
                disabled={selectedMaximumTypes && !checked}
              />
            )
          })}
          {selectedTypes.length > 0 && (
            <>
              <Divider />
              {selectedTypes.map(issue => {
                return (
                  <Checkbox
                    key={issue}
                    value={issue}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onChange(event.target.value as TaskType)
                    }
                    label={TaskTypeMap.find(type => type.value === issue)?.label}
                    defaultChecked
                  />
                )
              })}
            </>
          )}
          <SelectIssueType
            value=''
            onChange={(type: TaskType) => onChange(type)}
            disabled={selectedMaximumTypes}
            filterValues={types}
          />
        </Stack>
      )}
    </Stack>
  )
}
