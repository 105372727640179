import { useForm } from '@mantine/form'
import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { inputNumberBetween, isRequired } from '../../utils/formValidation'
import { usePatient } from './PPatientContext'

type AddSubscriptionStartDateModalProps = {
  closeModal: () => void
}

const AddSubscriptionStartDateModal = ({ closeModal }: AddSubscriptionStartDateModalProps) => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data
  const form = useForm<{ paymentDelay: string }>({
    initialValues: { paymentDelay: String(patient?.subscription?.metadata?.paymentDelay ?? '') },
    validate: { paymentDelay: validateWith(isRequired, inputNumberBetween({ start: 0, end: 7 })) },
  })

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }
    updatePatient.mutate(
      {
        params: {
          patientId: patient?.oid || '',
        },
        data: {
          subscription: {
            metadata: {
              paymentDelay: form.values.paymentDelay,
              updatedAt: dayjs().toISOString(),
            },
          },
        },
      },
      {
        onSuccess: () => {
          void patientQuery?.refetch()
          closeModal()
        },
      },
    )
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Set subscription delay'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>No, cancel</SecondaryButton>
          <PrimaryButton loading={updatePatient.isLoading} onClick={onSubmit}>
            Yes, set subscription delay
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <TextInput
          {...form.getInputProps('paymentDelay')}
          label={`How many days after the patient's intake visit should their subscription begin?`}
          placeholder='0-7'
        />
        <Text size='xs' color={colors => colors.text[1]}>
          Are you sure you want to proceed?
        </Text>
      </Stack>
    </Modal>
  )
}

export default AddSubscriptionStartDateModal
