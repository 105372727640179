import {
  CheckCircleIcon,
  Colors,
  ExternalLinkText,
  Group,
  List,
  ListItem,
  Modal,
  PrimaryButton,
  SlashIcon,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Patient } from '@shared/types'
import { KEYSTONE_FIRST_PAYER_ID } from '@shared/utils'
import { usePatientInsurances } from '../../utils/hooks'

type CocmIneligibleModalProps = {
  patient: Patient | null
  onClose: () => void
  hasCocmDiagnosis: boolean
  consentsSigned: boolean
}

const CocmIneligibleModal = ({
  patient,
  onClose,
  hasCocmDiagnosis,
  consentsSigned,
}: CocmIneligibleModalProps) => {
  const stateCheck = ['Pennsylvania', 'New York'].includes(patient?.homeData?.state ?? '')
  const successIcon = <CheckCircleIcon size='md' color={(colors: Colors) => colors.success[0]} />
  const failIcon = <SlashIcon size='md' color={(colors: Colors) => colors.error[0]} />
  const patientInsurances = usePatientInsurances(patient ?? undefined)
  const { primaryInsurance } = patientInsurances.data ?? {}
  const additionalRequirement =
    patient?.homeData?.state === 'New York'
      ? 'NY'
      : primaryInsurance?.basicInsuranceData?.eligiblePayerId === KEYSTONE_FIRST_PAYER_ID
      ? 'Keystone First insurance'
      : ''

  return (
    <Modal
      opened={Boolean(patient)}
      onClose={onClose}
      title={`${patient?.personalData.firstName} is not eligible for CoCM`}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={onClose}>Got it</PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Stack spacing='sm'>
          <Text>They must meet the following criteria:</Text>
          <List center spacing='sm'>
            <ListItem icon={stateCheck ? successIcon : failIcon}>Live in PA or NY</ListItem>
            <ListItem icon={patient?.statuses.insuranceAllowList ? successIcon : failIcon}>
              On insurance pay
            </ListItem>
            <ListItem icon={consentsSigned ? successIcon : failIcon}>
              Required consents signed
            </ListItem>
          </List>
        </Stack>
        {Boolean(additionalRequirement) && (
          <Stack spacing='sm'>
            <Text>{`Additional ${additionalRequirement} requirement`}</Text>
            <List center spacing='sm'>
              <ListItem icon={hasCocmDiagnosis ? successIcon : failIcon}>
                Diagnosed with a psychiatric diagnosis code from{' '}
                <ExternalLinkText href='https://coda.io/d/Clinical-Team_d6-Hc4Zptr0/NYS-CoCM-Patient-Diagnoses_suek5'>
                  <TertiaryButton>this</TertiaryButton>
                </ExternalLinkText>{' '}
                list
              </ListItem>
            </List>
          </Stack>
        )}
      </Stack>
    </Modal>
  )
}

export default CocmIneligibleModal
