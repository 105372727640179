import { useForm } from '@mantine/form'
import { Group, Modal, PrimaryButton, SecondaryButton, Textarea } from '@shared/components'
import { useParams } from 'react-router-dom'
import { useEmrMutation } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'

export type CreateAddendumModalProps = {
  onClose: () => void
  opened: boolean
}
export const CreateAddendumModal = ({ onClose, opened }: CreateAddendumModalProps) => {
  const { patientId } = usePatient()
  const { visitNoteId } = useParams()
  const form = useForm<{ addendum: string }>()
  const addAddendumMutation = useEmrMutation(
    'POST /patients/:patientId/visit-notes/:visitNoteId/addendums',
  )
  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    // TODO: handle scenario where visitNoteId or patientId is undefined
    if (!visitNoteId) {
      return
    }

    if (!patientId) {
      return
    }

    addAddendumMutation.mutate({
      params: { patientId, visitNoteId },
      data: { content: form.values.addendum },
    })
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Add an addendum'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onSubmit}>Sign and lock</PrimaryButton>
        </Group>
      }
    >
      <Textarea
        {...form.getInputProps('addendum')}
        minRows={4}
        placeholder='Type here...'
        autosize
      />
    </Modal>
  )
}
