import {
  Checkbox,
  Drawer,
  Group,
  LoadingOverlay,
  PrimaryButton,
  ScrollAreaAutosize,
  SearchIcon,
  Stack,
  Tabs,
  Text,
  TextInput,
} from '@shared/components'
import { ABF_CODE_TYPE, Diagnosis } from '@shared/types'
import { toTime } from '@shared/utils'
import algoliasearch from 'algoliasearch'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { Config } from '../../../../config'
import { commonProblems } from '../../../../utils/utils'

const client = algoliasearch(Config.ALGOLIA_APPLICATION_ID, Config.ALGOLIA_SEARCH_KEY)
const index = client.initIndex('icd10Codes')

export type AddDiagnosesDrawerProps = {
  diagnoses: Diagnosis[]
  encounterId: string
  opened: boolean
  onClose: () => void
}

export const AddDiagnosesDrawer = ({
  opened,
  onClose,
  diagnoses,
  encounterId,
}: AddDiagnosesDrawerProps) => {
  const queryClient = useQueryClient()
  const [filteredCommonProblems, setFilteredCommonProblems] = useState(commonProblems)
  const [allProblemsSearchTerm, setAllProblemsSearchTerm] = useState('')
  const [problemsListQuery, setProblemsListQuery] = useState<string>('')
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<
    {
      code: string
      full_description: string
    }[]
  >([])

  const [problemsSearchList, setProblemsSearchList] = useState<
    {
      code: string
      full_description: string
    }[]
  >([])

  const debouncedQueryUpdate = useCallback(
    debounce(value => {
      setProblemsListQuery(value)
    }, toTime('0.3 sec').ms()),
    [],
  )

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  useEffect(() => {
    const searchForDiagnoses = async () => {
      const results: { hits: { code: string; full_description: string }[] } = await index.search(
        problemsListQuery,
        { hitsPerPage: 100, filters: 'NOT validity:I' },
      )
      setProblemsSearchList(results.hits)
    }
    if (problemsListQuery) {
      void searchForDiagnoses()
    }
  }, [problemsListQuery])

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  return (
    <Drawer
      opened={opened}
      onClose={() => {
        onClose()
        setSelectedDiagnoses([])
      }}
      title='Add diagnoses'
      position='right'
      size='lg'
      footer={
        <Group position='right'>
          <PrimaryButton
            disabled={updateEncounter.isLoading || selectedDiagnoses.length === 0}
            onClick={() => {
              updateEncounter.mutate(
                {
                  data: {
                    id: encounterId,
                    encounter: {
                      diagnoses: uniqBy(
                        [
                          ...diagnoses,
                          ...selectedDiagnoses.map(diagnosis => ({
                            code: diagnosis.code,
                            code_type: ABF_CODE_TYPE,
                            name: diagnosis.full_description,
                          })),
                        ],
                        d => d.code,
                      ),
                    },
                  },
                },
                {
                  onSuccess: () => {
                    setSelectedDiagnoses([])
                    onClose()
                    void queryClient.invalidateQueries(encounterQueryKey)
                  },
                },
              )
            }}
          >{`Add ${selectedDiagnoses.length} diagnoses`}</PrimaryButton>
        </Group>
      }
    >
      <LoadingOverlay visible={updateEncounter.isLoading} />
      <Tabs defaultValue='common'>
        <Tabs.List
          sx={theme => ({
            paddingLeft: theme.spacing.md,
          })}
        >
          <Tabs.Tab value='common' px='md'>
            Common diagnoses
          </Tabs.Tab>
          <Tabs.Tab value='all' px='md'>
            All
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='common'>
          <Stack>
            <TextInput
              px='md'
              pt='md'
              placeholder='Search common diagnoses'
              icon={<SearchIcon />}
              onChange={value => {
                setFilteredCommonProblems(
                  commonProblems.filter(problem =>
                    problem.full_description.toLowerCase().includes(value.toLowerCase()),
                  ),
                )
              }}
            />
            <ScrollAreaAutosize maxHeight='80vh' type='always'>
              <Stack spacing='sm' px='md' pt='md'>
                {filteredCommonProblems.map(problem => {
                  return (
                    <Checkbox
                      key={problem.code}
                      checked={Boolean(
                        selectedDiagnoses.find(diagnosis => diagnosis.code === problem.code),
                      )}
                      onChange={event => {
                        if (event.target.checked) {
                          setSelectedDiagnoses([...selectedDiagnoses, problem])
                        } else {
                          setSelectedDiagnoses(
                            selectedDiagnoses.filter(diagnosis => diagnosis.code !== problem.code),
                          )
                        }
                      }}
                      label={
                        <Group position='apart' noWrap>
                          <Text>{problem.full_description}</Text>
                          <Text size='xs' color={colors => colors.text[1]}>
                            {problem.code}
                          </Text>
                        </Group>
                      }
                    />
                  )
                })}
              </Stack>
            </ScrollAreaAutosize>
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value='all'>
          <Stack>
            <TextInput
              px='md'
              pt='md'
              placeholder='Search all diagnoses'
              icon={<SearchIcon />}
              value={allProblemsSearchTerm}
              onChange={value => {
                debouncedQueryUpdate(value)
                setAllProblemsSearchTerm(value)
              }}
            />
            <ScrollAreaAutosize maxHeight='80vh' type='always'>
              <Stack spacing='md' px='md' pt='md'>
                {problemsSearchList.map(problem => {
                  return (
                    <Checkbox
                      key={problem.code}
                      checked={Boolean(
                        selectedDiagnoses.find(diagnosis => diagnosis.code === problem.code),
                      )}
                      onChange={event => {
                        if (event.target.checked) {
                          setSelectedDiagnoses([...selectedDiagnoses, problem])
                        } else {
                          setSelectedDiagnoses(
                            selectedDiagnoses.filter(diagnosis => diagnosis.code !== problem.code),
                          )
                        }
                      }}
                      label={
                        <Group position='apart' noWrap>
                          <Text>{problem.full_description}</Text>
                          <Text size='xs' color={colors => colors.text[1]}>
                            {problem.code}
                          </Text>
                        </Group>
                      }
                    />
                  )
                })}
              </Stack>
            </ScrollAreaAutosize>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </Drawer>
  )
}
