import { createFormContext } from '@mantine/form'
import {
  Barc10Prompts,
  FullWorkflowState,
  Gad7Prompts,
  Phq8Prompts,
  Phq9Prompts,
  PromptContentResponse,
  PromptContextKey,
  PromptResponseContextKey,
  PromptResponseData,
  PromptResponsePayload,
} from '@shared/types'
const COCM_WORKFLOW_TYPES = ['phq_8_form', 'phq_9_form', 'gad_7_form', 'barc_10_form'] as const
type CocmWorkflowTypeTuple = typeof COCM_WORKFLOW_TYPES
type CocmWorkflowType = CocmWorkflowTypeTuple[number]

export type WorkflowFormType = Record<string, string | string[]>
// Untyped createFormContext since we don't know the exact type until we pull the workflow from the backend
export const [WorkflowFormProvider, useWorkflowFormContext, useWorkflowForm] =
  createFormContext<WorkflowFormType>()

export const getWorkflowValidationRules = ({
  fullWorkflowState,
  allFieldsOptional = false,
}: {
  fullWorkflowState?: FullWorkflowState
  allFieldsOptional?: boolean
}) => {
  const contextKeys = fullWorkflowState?.promptsAndResponses.map(item => {
    return item.prompt.contextKey
  })

  const validationRules: {
    [key: string]: (value: string | string[]) => undefined | string
  } = {}

  if (contextKeys && !allFieldsOptional) {
    for (const key of contextKeys) {
      validationRules[key] = value => (value ? undefined : 'Required')
    }
  }

  return validationRules
}

export const buildResponseSubmissionPayload = (
  workflowState: FullWorkflowState,
  formValues: WorkflowFormType,
) => {
  const responses: { promptId: string; promptResponse: PromptResponsePayload }[] = []
  for (const key of Object.keys(formValues)) {
    if (!formValues[key]) {
      continue
    }
    const prompt = workflowState.promptsAndResponses.find(item => item.prompt.contextKey === key)
      ?.prompt
    const promptResponseBlock = prompt?.content.find(
      contentBlock => contentBlock.contentType === 'response',
    ) as PromptContentResponse
    const contextKey = ['multiple_choice', 'single_selection', 'likert'].includes(
      promptResponseBlock?.responseType,
    )
      ? formValues[key]
      : 'custom'

    const responseData: PromptResponsePayload = {
      value: {
        contextKey: contextKey as PromptResponseContextKey[],
        value: contextKey === 'custom' ? (formValues[key] as string) : undefined,
      },
    }
    responses.push({ promptId: prompt?.oid || '', promptResponse: responseData })
  }
  return responses
}

export const convertFullWorkflowStateToFormValues = (workflowState: FullWorkflowState) => {
  return workflowState.promptsAndResponses.reduce((prev, current) => {
    const key = current.prompt.contextKey
    const valueContextKey = current.response?.payload.value.contextKey
    const value =
      valueContextKey === 'custom' ? current.response?.payload.value.value : valueContextKey
    if (!value) {
      prev[key] = ''
    } else if (typeof value === 'object') {
      prev[key] = value
    } else {
      prev[key] = String(value) ?? ''
    }
    return prev
  }, {} as WorkflowFormType)
}

export const sortResponseValueForType = ({
  a,
  b,
  workflowType,
}: {
  a: PromptResponseData
  b: PromptResponseData
  workflowType: CocmWorkflowType
}) => {
  switch (workflowType) {
    case 'phq_9_form':
      return (
        Phq9Prompts[a.contextKey as PromptContextKey<'phq_9_form'>] -
        Phq9Prompts[b.contextKey as PromptContextKey<'phq_9_form'>]
      )
    case 'phq_8_form':
      return (
        Phq8Prompts[a.contextKey as PromptContextKey<'phq_8_form'>] -
        Phq8Prompts[b.contextKey as PromptContextKey<'phq_8_form'>]
      )
    case 'gad_7_form':
      return (
        Gad7Prompts[a.contextKey as PromptContextKey<'gad_7_form'>] -
        Gad7Prompts[b.contextKey as PromptContextKey<'gad_7_form'>]
      )
    case 'barc_10_form':
      return (
        Barc10Prompts[a.contextKey as PromptContextKey<'barc_10_form'>] -
        Barc10Prompts[b.contextKey as PromptContextKey<'barc_10_form'>]
      )
  }
}

export const convertToResponseCollectionDataForType = ({
  response,
  workflowType,
}: {
  response: PromptResponseData
  workflowType: CocmWorkflowType
}) => {
  switch (workflowType) {
    case 'phq_9_form': {
      return {
        key: response.contextKey as PromptResponseContextKey<'phq_9_form'>,
        score: response.value ?? 0,
      }
    }
    case 'phq_8_form': {
      return {
        key: response.contextKey as PromptResponseContextKey<'phq_8_form'>,
        score: response.value ?? 0,
      }
    }
    case 'gad_7_form': {
      return {
        key: response.contextKey as PromptResponseContextKey<'gad_7_form'>,
        score: response.value ?? 0,
      }
    }
    case 'barc_10_form': {
      return {
        key: response.contextKey as PromptResponseContextKey<'barc_10_form'>,
        score: response.value ?? 0,
      }
    }
  }
}
