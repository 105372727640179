import { CheckCircleIcon, Stack, Text, TitleTwo, useMantineTheme } from '@shared/components'
import { ActionItemSortByOptions } from '@shared/types'

type MActionItemEmptyStateProps = {
  allEmployeeActionsCompleted: boolean
  sortedBy?: string
  groupName?: string
}

const MActionItemEmptyState = ({
  allEmployeeActionsCompleted,
  sortedBy,
  groupName,
}: MActionItemEmptyStateProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const emptyStateText =
    !allEmployeeActionsCompleted && sortedBy && groupName
      ? ActionItemSortByOptions[sortedBy]?.groups?.[groupName]?.emptyStateText
      : 'All caught up'

  const checkCircleIconSize = allEmployeeActionsCompleted ? 'xl' : 'sm'
  const secondaryTextSize = allEmployeeActionsCompleted ? 'md' : 'xs'
  const topAndBottomPadding = allEmployeeActionsCompleted ? 0 : sizes.padding.lg

  return (
    <Stack
      spacing={sizes.gap.md}
      align='center'
      justify='center'
      sx={{
        backgroundColor: colors.background[0],
        paddingTop: topAndBottomPadding,
        paddingBottom: topAndBottomPadding,
        textAlign: 'center',
      }}
    >
      <CheckCircleIcon size={checkCircleIconSize} color={colors.actions[1]} />
      <Stack spacing={sizes.gap.sm}>
        {allEmployeeActionsCompleted && (
          <TitleTwo color={colors.text[1]}>{emptyStateText}</TitleTwo>
        )}
        {!allEmployeeActionsCompleted && (
          <Text bold size='md' color={colors.text[0]}>
            {emptyStateText}
          </Text>
        )}
        <Text size={secondaryTextSize} color={colors.text[1]}>
          Nice work!
        </Text>
      </Stack>
    </Stack>
  )
}

export default MActionItemEmptyState
