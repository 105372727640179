/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormReturnType } from '@mantine/form'
import { EmrApi, ISOString, VisitNoteModel, hasAppointmentType } from '@shared/types'
import { dayjs, getVisitNoteLockedAtString, isTruthy } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import { createContext, useContext, useRef } from 'react'
import { UseQueryResult, useQueries } from 'react-query'
import { emrApi } from '../../../../api'
import { useAuth } from '../../../../context/auth'
import { useEmrMutation, useEmrQuery } from '../../../../utils/hooks'
import { exportToPdf, isNotAbleToWriteToNote } from '../../../../utils/utils'
import { usePatient } from '../../PPatientContext'

export type PatientVisitNoteContextType<
  NoteContent extends VisitNoteModel['content'] = VisitNoteModel['content'],
> = {
  patientId: string
  visitNoteId: string
  visitNoteQuery: UseQueryResult<
    EmrApi['GET /patients/:patientId/visit-notes/:visitNoteId']['res']
  > | null
  appointmentQuery: UseQueryResult<
    EmrApi['GET /patient/:patientId/appointments/:appointmentId']['res']
  > | null
  isLocked: boolean
  lockedAt: ISOString | null
  lockedByName: string | null
  canEdit: boolean
  canCurrentUserEdit: boolean
  // TODO: fix the type error with updateVisitNoteMutation

  // updateVisitNoteMutation: ReturnType<typeof useEmrMutation> | null
  saveVisitNote: (() => void) | null
  saveVisitNoteIsLoading: boolean
  saveVisitNoteError: Error | null
  lastSavedAt: ISOString | null
  isSidebar: boolean
  setFormRef: ((form: UseFormReturnType<NoteContent>) => void) | null
  form: UseFormReturnType<NoteContent> | null
  exportVisitNoteToPdf: () => Promise<void>
}

const initialContext = {
  patientId: '',
  visitNoteId: '',
  visitNoteQuery: null,
  appointmentQuery: null,
  isLocked: false,
  lockedAt: null,
  lockedByName: null,
  canEdit: false,
  canCurrentUserEdit: false,
  // updateVisitNoteMutation: null,
  saveVisitNote: null,
  saveVisitNoteIsLoading: false,
  saveVisitNoteError: null,
  lastSavedAt: null,
  isSidebar: false,
  setFormRef: null,
  form: null,
  exportVisitNoteToPdf: async () => {},
}

export const PatientVisitNoteContext = createContext<PatientVisitNoteContextType>(initialContext)

export const usePatientVisitNote = <NoteContent extends VisitNoteModel['content']>() => {
  return useContext(
    PatientVisitNoteContext as React.Context<PatientVisitNoteContextType<NoteContent>>,
  )
}

export const PatientVisitNoteProvider = ({
  visitNoteId,
  isSidebar,
  children,
}: {
  visitNoteId: string
  isSidebar: boolean
  children: React.ReactNode
}) => {
  const { currentUser } = useAuth()
  const { patientId, patientQuery } = usePatient()
  const patient = patientQuery?.data
  const formRef = useRef<any>(null)

  // visit note
  const visitNoteQuery = useEmrQuery(
    'GET /patients/:patientId/visit-notes/:visitNoteId',
    {
      params: {
        patientId,
        visitNoteId: visitNoteId || '',
      },
    },
    {
      // TODO, // set smart cache time
      onSuccess: data => {
        // Sets the form values if the form is initialized before the query finishes
        if (data && formRef.current && isEmpty(formRef.current?.values)) {
          formRef.current.setValues(data.content)
        }
      },
    },
  )

  // appointment
  const appointmentQuery = useEmrQuery(
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      params: {
        patientId,
        appointmentId: visitNoteId || '',
      },
    },
    {
      // TODO set smart cache time
    },
  )
  const visit = appointmentQuery?.data

  const updateVisitNoteMutation = useEmrMutation('PUT /patient/:patientId/visits/:visitId/notes')

  const lockedAt = visitNoteQuery.data?.content?.locked_at
  const isLocked = Boolean(lockedAt)
  const lockedByName = visitNoteQuery.data?.content?.locked_by_name
  const lastSavedAt = visitNoteQuery.data?.updatedAt
    ? dayjs(visitNoteQuery.data?.updatedAt).toISOString()
    : null

  const isWelcomeCall = hasAppointmentType(
    visit,
    'Free Consultation Call',
    'Returning welcome call',
  )

  // Welcome Calls are non-clinical appointments. We let anyone lock these.
  const canCurrentUserEdit = !isNotAbleToWriteToNote(currentUser.calendarId, visit) || isWelcomeCall
  const canEdit = !isLocked && canCurrentUserEdit && !isSidebar

  const saveVisitNote = async () => {
    if (!visitNoteQuery.data || !canEdit) {
      return
    }

    await updateVisitNoteMutation.mutateAsync({
      params: {
        patientId,
        visitId: visitNoteId || '',
      },
      data: { type: visitNoteQuery.data.type, content: formRef.current.values },
    })

    void visitNoteQuery?.refetch()
  }

  const prescriptionIds = visitNoteQuery?.data?.doseSpotPrescriptionIds ?? []

  const prescriptionDataQueries = useQueries(
    prescriptionIds.map(id => {
      const [queryKey, queryFn] = emrApi.getQuery(
        'GET /patient/:patientId/legacyPrescription/:prescriptionId',
        {
          params: {
            prescriptionId: String(id),
            patientId,
          },
        },
      )

      return {
        queryKey,
        queryFn,
        enabled: Boolean(patientId),
      }
    }),
  )

  const dosespotMedications = prescriptionDataQueries.map(query => query.data).filter(isTruthy)

  const addendumsQuery = useEmrQuery(
    'GET /patients/:patientId/visit-notes/:visitNoteId/addendums',
    {
      params: {
        patientId,
        visitNoteId: visitNoteId ?? '',
      },
    },
    {
      // TODO: Enable this once endpoint is implemented
      enabled: false,
    },
  )

  const addendums = addendumsQuery.data ?? []

  const exportVisitNoteToPdf = () =>
    exportToPdf({
      masterWatcher: visitNoteQuery?.data?.content,
      notes: visitNoteQuery?.data?.content,
      type: 'initial visit',
      firstName: patient?.personalData.firstName,
      lastName: patient?.personalData.lastName,
      dateOfBirth: patient?.personalData.birthday,
      userId: patientId,
      datetime: dayjs(visit?.datetime)
        .tz(visit?.timezone)
        .format('dddd, MMMM D, YYYY hh:mma z'),
      visitType: visit?.type,
      lockedByName: visitNoteQuery?.data?.content?.locked_by_name,
      lockedAtTime: getVisitNoteLockedAtString({
        lockedAt: visitNoteQuery?.data?.content?.locked_at,
        timezone: visit?.timezone,
      }),
      patientTime: visit?.patientTime,
      clinicianTime: visit?.clinicianTime,
      dosespotMedications,
      addendums,
    })

  const context = {
    patientId,
    visitNoteId,
    visitNoteQuery,
    appointmentQuery,
    isLocked,
    lockedAt,
    lockedByName,
    canEdit,
    canCurrentUserEdit,
    // updateVisitNoteMutation,
    saveVisitNote,
    saveVisitNoteIsLoading: updateVisitNoteMutation.isLoading,
    saveVisitNoteError: updateVisitNoteMutation.error,
    lastSavedAt,
    isSidebar,
    setFormRef: (form: UseFormReturnType<any>) => (formRef.current = form),
    form: formRef.current,
    exportVisitNoteToPdf,
  }

  return (
    <PatientVisitNoteContext.Provider value={context}>{children}</PatientVisitNoteContext.Provider>
  )
}
