import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { storageApi } from '../../../../../api'
import { useLunaMutation } from '../../../../../utils/hooks'

export const useSmsAttachment = () => {
  const prepareAttachment = useLunaMutation('POST /communications/sms/attachment')
  const addFileToStorage = useMutation(storageApi.addFile, { retry: 1 })
  const resetAttachmentButtonRef = useRef<() => void>(null)
  const [file, setFile] = useState<File | null>(null)
  const [attachmentFilePath, setAttachmentFilePath] = useState<string | null>(null)

  const selectAttachment = async (file: File) => {
    setFile(file)
    const { uploadUrl, filePath } = (await prepareAttachment.mutateAsync({})).data
    await addFileToStorage.mutateAsync({ signedUrl: uploadUrl, file })
    setAttachmentFilePath(filePath)
  }

  const resetAttachment = () => {
    resetAttachmentButtonRef.current?.()
    setFile(null)
    setAttachmentFilePath(null)
  }

  return {
    file,
    isLoading: prepareAttachment.isLoading || addFileToStorage.isLoading,
    attachmentFilePath,
    selectAttachment,
    resetAttachment,
    resetAttachmentButtonRef,
  }
}
