import { useForm, UseFormReturnType } from '@mantine/form'
import { Drawer, Group, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import { StateAbbr } from '@shared/types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { employeesApi, emrApi } from '../../../api'
import RecurringScheduleForm, {
  convertFormDataToModel,
  OcpRecurringFormData,
} from './RecurringScheduleForm'

export type CreateStateDrawerProps = {
  isOpen: boolean
  existingStates: StateAbbr[]
  onClose: () => void
}

const CreateStateDrawer = ({ isOpen, existingStates, onClose }: CreateStateDrawerProps) => {
  const form = useForm<OcpRecurringFormData>({
    initialValues: {
      weekdayShiftStart: '',
      weekdayShiftEnd: '',
      weekendShiftStart: '',
      weekendShiftEnd: '',
      employeeIdByDay: {
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
      },
    },
  })

  const queryClient = useQueryClient()

  const clinicianQuery = useQuery(['employeesApi.ocpClinicians'], employeesApi.ocpClinicians)

  const clinicians = clinicianQuery.data

  const createRecurring = useMutation(emrApi.getMutation('POST /ocp/recurring'), {
    onSuccess: () => {
      void queryClient.invalidateQueries('ocpApi.getRecurring')
      void queryClient.invalidateQueries('ocpApi.getOverrides')
      onClose()
    },
  })

  const handleSubmit = (form: UseFormReturnType<OcpRecurringFormData>) => {
    form.setErrors({})
    const newModel = convertFormDataToModel(form)
    if (newModel) {
      createRecurring.mutate({ data: newModel })
    }
  }

  const footer = (
    <Group position='right'>
      <SecondaryButton onClick={() => onClose()}>Cancel</SecondaryButton>
      <PrimaryButton onClick={() => handleSubmit(form)}>Save</PrimaryButton>
    </Group>
  )

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title='Add state'
      position='right'
      size='lg'
      footer={footer}
    >
      {clinicians && (
        <RecurringScheduleForm
          clinicians={clinicians}
          form={form}
          existingStates={existingStates}
        />
      )}
      <Text color='red' m='md'>
        {Object.keys(form.errors).length > 0 && Object.keys(form.errors).map(k => form.errors[k])}
      </Text>
    </Drawer>
  )
}

export default CreateStateDrawer
