import React from 'react'

export type ATypeaheadOptionProps = {
  value: string
  setIgnoreBlur: (value: boolean) => void
  onValueClick: ({ value }: { value: string }) => void
}

const ATypeaheadOption = ({ value, setIgnoreBlur, onValueClick }: ATypeaheadOptionProps) => {
  return (
    <li key={value}>
      <div
        onMouseUp={() => setIgnoreBlur(false)}
        onMouseDown={() => setIgnoreBlur(true)}
        onClick={() => onValueClick({ value })}
        className='flex flex-grow items-center px-4 py-2 min-h-10 bg-white hover:bg-indigo-100 hover:shadow'
      >
        <p>{value}</p>
      </div>
    </li>
  )
}

export default ATypeaheadOption
