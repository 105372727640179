import { Employee, hasStatus, isCareCoordinator } from '@shared/types'
import { useAuth } from '../../context/auth'
import { useEmployees, useEmrQuery } from '../../utils/hooks'
import { usePatient } from '../patient/PPatientContext'

type ClinicianSelectionExplanation =
  | 'Quarterly PC visit'
  | 'Eligible for CCM'
  | 'Not eligible for CCM'

/*
 * This hook will determine whether we default the scheduling drawer to display the patient's PC or CCM.
 * We will also return an explanation for why the patient is being scheduled with a PC or CCM. The explanation
 * text will be displayed beneath the clinician selection dropdown in the scheduling drawer to explain
 * why the clinician was chosen as the default option.
 *
 * Here's a link to the Figma document outlining the logic:
 * https://www.figma.com/file/b9bHfxHN1khfuL9fm5z7Jz/Billable-clinician-capacity?type=design&node-id=114-276&mode=design&t=64Yk7v11mNqN15aV-0
 */

type CareCoordinatorSchedulingInfo = {
  defaultClinician: Employee
  clinicianSelectionExplanation: ClinicianSelectionExplanation
}

export const useCareCoordinatorScheduling = (): CareCoordinatorSchedulingInfo | undefined => {
  const { patientQuery } = usePatient()
  const { currentUser } = useAuth()
  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const patient = patientQuery?.data

  const employees = employeesQuery.data || []

  // Check if the patient's next visit is their quarterly PC visit
  const isQuarterlyPcVisitQuery = useEmrQuery('GET /patient/:patientId/quarterly-pc-visit', {
    params: {
      patientId: patient?.oid || '',
    },
  })
  const isQuarterlyPcVisit = isQuarterlyPcVisitQuery?.data?.isQuarterlyPcVisit

  /*
   * This code only handles the logic for when care coordinators are scheduling a patient.
   * If the current user is not a care coordinator, exit early
   */
  const isCC = isCareCoordinator(currentUser)

  if (!isCC) {
    return
  }

  // There should always be patient data, but exit early if there isn't
  if (!patient) {
    return
  }

  // If the patient is not in treatment, exit early
  if (!hasStatus(patient, 'in treatment')) {
    return
  }

  // Each in treatment patient should have a PC assigned to them, but exit early if there isn't
  const pc = employees.find(employee => employee.oid === patient.primaryClinician?.id)
  if (!pc) {
    return
  }

  /*
   * A patient can have their follow up scheduled with their CCM if all of the following is true:
   * - The patient has a CCM assigned to them
   * - The patient is marked as eligible to see their CCM
   * - The patient's next visit is not their quarterly PC visit
   */
  const { ccmTransition } = patient

  const ccm = employees.find(employee => employee.oid === patient.nurseCareManager?.id)

  const isEligibleForCcm = ccmTransition?.readyForCcmTransition === 'yes'

  // If the next visit is the patient's quarterly PC visit, we will default the scheduling drawer to display the PC
  if (isQuarterlyPcVisit) {
    return {
      defaultClinician: pc,
      clinicianSelectionExplanation: 'Quarterly PC visit',
    }
  }

  // If the patient is eligible for CCM, we will default the scheduling drawer to display the CCM
  if (ccm && isEligibleForCcm) {
    return {
      defaultClinician: ccm,
      clinicianSelectionExplanation: 'Eligible for CCM',
    }
  }

  // As a fallback, we will default the scheduling drawer to display the PC
  return {
    defaultClinician: pc,
    clinicianSelectionExplanation: 'Not eligible for CCM',
  }
}
