import { Flex, Group, Stack } from '@shared/components'
import { Redirect } from '../../../../components/Redirect'
import { FollowUpVisitNote } from './FollowUpVisitNote'
import { usePatientVisitNote } from './PatientVisitNoteContext'
import { PatientVisitNoteHeader } from './PatientVisitNoteHeader'
import { PatientVisitNoteSidebar } from './PatientVisitNoteSidebar'
import { ReenrollmentCallVisitNote } from './ReenrollmentCallVisitNote'
import { WelcomeCallVisitNote } from './WelcomeCallVisitNote'
import { PatientVisitNoteLoader } from './visitNoteForm/PatientVisitNoteLoader'

const PatientVisitNoteMainContent = () => {
  const { visitNoteQuery, appointmentQuery } = usePatientVisitNote()

  if (visitNoteQuery?.isLoading || appointmentQuery?.isLoading) {
    return <PatientVisitNoteLoader />
  }

  const appointment = appointmentQuery?.data

  if (!appointment) {
    throw new Error('Appointment not found')
  }

  switch (visitNoteQuery?.data?.type) {
    case 'consultation':
      return <WelcomeCallVisitNote appointmentId={appointment.oid} />
    case 'reenrollment':
      return <ReenrollmentCallVisitNote />
    case 'maintenance':
      return <FollowUpVisitNote />
    default:
      return (
        <Redirect
          path={({ patientID, visitNoteId }) => `/patients/${patientID}/notes/${visitNoteId}`}
        />
      )
  }
}

export const PatientVisitNoteSidebarContent = () => {}

export const PatientVisitNote = () => {
  return (
    <Flex w='100%' align='stretch' mx='md' gap='md'>
      <Stack sx={{ flexGrow: 1 }} mt='md' spacing={0}>
        <PatientVisitNoteHeader />
        <Group position='center' sx={{ flexGrow: 1, overflowY: 'scroll' }}>
          <PatientVisitNoteMainContent />
        </Group>
      </Stack>

      <Group my='md' sx={{ overflowY: 'scroll' }} position='right'>
        <PatientVisitNoteSidebar />
      </Group>
    </Flex>
  )
}
