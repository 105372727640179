import { Group, TertiaryButton, Text, XIcon } from '@shared/components'
import { TaskType, TaskTypeMap } from '@shared/types'
import { useFiltersFormContext } from './filterFormHelpers'

export const AppliedFilters = () => {
  const filters = useFiltersFormContext()

  const { types } = filters.values

  const onClick = (type: TaskType) => {
    const filteredTypes = types?.filter(t => t !== type)
    filters.setValues({
      types: filteredTypes,
    })
  }

  const filtersAreApplied = types && types.length > 0

  if (!filtersAreApplied) {
    return null
  }

  return (
    <Group position='right' spacing='sm' px='lg'>
      <Text>Filters applied:</Text>
      {types.map(type => (
        <TertiaryButton
          key={type}
          onClick={() => onClick(type)}
          rightIcon={<XIcon color={colors => colors.actions[0]} />}
        >
          {TaskTypeMap.find(taskType => taskType.value === type)?.label || ''}
        </TertiaryButton>
      ))}
    </Group>
  )
}
