import { dayjs } from '@shared/utils'

export enum CommunicationsType {
  SMS = 'sms',
  Calls = 'calls',
  Emails = 'emails',
}

export const formatDurationFromSeconds = (seconds: number) => {
  const dayjsDuration = dayjs.duration(seconds, 'seconds')
  const hours = dayjsDuration.hours()
  const remainingMinutes = dayjsDuration.minutes()
  const remainingSeconds = dayjsDuration.seconds()

  let result = ''

  if (hours > 0) {
    result += `${hours} hr`
  }

  if (remainingMinutes > 0) {
    if (hours > 0) {
      result += ' '
    }
    result += `${remainingMinutes} min`
  }

  if (remainingSeconds > 0) {
    if (remainingMinutes > 0) {
      result += ' '
    }
    result += `${remainingSeconds} sec`
  }

  return result || '0 sec'
}

export const getCommunicationsTypeString = (communicationsType: CommunicationsType) => {
  switch (communicationsType) {
    case CommunicationsType.SMS:
      return 'messages'
    case CommunicationsType.Calls:
      return 'calls'
    case CommunicationsType.Emails:
      return 'emails'
    default:
      throw new Error(`Communication type not recognized!`)
  }
}
