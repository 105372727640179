import { useForm } from '@mantine/form'
import {
  Alert,
  Banner,
  BetterDrawer,
  DatePicker,
  DollarSignIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  InfoIcon,
  LoadingOverlay,
  NumberInput,
  PrimaryButton,
  Select,
  Stack,
  isInRange,
  showNotification,
  validateWith,
} from '@shared/components'
import { ISOString, PaymentPlanFrequency, SubscriptionInterval } from '@shared/types'
import { CENTS_PER_DOLLAR, dayjs, formatDollarAmount } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import multiply from 'lodash/multiply'
import range from 'lodash/range'
import round from 'lodash/round'
import { useEffect } from 'react'
import { useAuth } from '../../../context/auth'
import { isNumber, isRequired } from '../../../utils/formValidation'
import { useEmrMutation, useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { getFinalPaymentDate } from './paymentPlanUtils'

const MIN_PAYMENT_AMOUNT_IN_DOLLARS = 1

// Ensure that we do not create a payment plan with more than 10 payments
const MAX_FIRESTORE_ARRAY_LENGTH = 10

const paymentFrequencyOptions: PaymentPlanFrequency[] = ['weekly', 'biweekly', 'monthly']

const today = dayjs().startOf('day')

type PaymentPlanForm = {
  totalPaymentPlanAmountInDollars: number
  numPayments: `${number}`
  paymentFrequency: SubscriptionInterval
  firstPaymentDate: ISOString
}

type PaymentPlanDrawerProps = {
  opened: boolean
  onClose: () => void
}

export const PaymentPlanDrawerContent = ({ onClose }: PaymentPlanDrawerProps) => {
  const { patientQuery, patientId } = usePatient()
  const { currentUser } = useAuth()

  const { data, isLoading: isInvoiceDataLoading } = useEmrQuery('GET /invoices', {
    query: {
      patientId,
    },
  })

  const aggregateInvoiceInfo = data?.aggregateInvoiceInfo || {
    overdueBalanceInDollars: 0,
    totalBalanceDueInDollars: 0,
  }

  const { overdueBalanceInDollars, totalBalanceDueInDollars } = aggregateInvoiceInfo

  const paymentPlanForm = useForm<PaymentPlanForm>({
    initialValues: {
      totalPaymentPlanAmountInDollars: overdueBalanceInDollars,
      numPayments: '1',
      paymentFrequency: 'monthly',
      firstPaymentDate: today.toISOString(),
    },
    validate: {
      totalPaymentPlanAmountInDollars: validateWith(
        isRequired,
        isNumber,
        isInRange(
          {
            // Must be at least $1
            min: MIN_PAYMENT_AMOUNT_IN_DOLLARS,
            // Must be less than or equal to the patient's total balance due
            max: round(totalBalanceDueInDollars, 2),
          },
          `Must be between ${formatDollarAmount({
            amount: MIN_PAYMENT_AMOUNT_IN_DOLLARS,
          })} and ${formatDollarAmount({ amount: totalBalanceDueInDollars })}`,
        ),
      ),
      numPayments: validateWith(isRequired),
      paymentFrequency: validateWith(isRequired),
      firstPaymentDate: validateWith(isRequired),
    },
  })

  const {
    values: { totalPaymentPlanAmountInDollars, numPayments, paymentFrequency, firstPaymentDate },
  } = paymentPlanForm

  const finalPaymentDate = getFinalPaymentDate({
    firstPaymentDate,
    numPayments,
    paymentFrequency,
  })

  const { refetch: refetchPaymentPlans } = useEmrQuery('GET /patient/:patientId/payment-plans', {
    params: {
      patientId,
    },
  })

  const createPaymentPLan = useEmrMutation('POST /patient/:patientId/payment-plan')

  const onSubmit = async () => {
    if (paymentPlanForm.validate().hasErrors) {
      return
    }

    await createPaymentPLan.mutateAsync({
      params: {
        patientId,
      },
      data: {
        /*
         * In JavaScript, 72.50 * 100 = 7284.999999999999
         * Therefore, must round. Else, Stripe will yell about an invalid integer.
         */
        totalAmountInCents: round(
          multiply(Number(totalPaymentPlanAmountInDollars), CENTS_PER_DOLLAR),
        ),
        numPayments: Number(numPayments),
        paymentFrequency,
        firstPaymentDate,
        employeeId: currentUser.oid,
      },
    })

    const patientFirstName = patientQuery?.data?.personalData?.firstName || ''

    showNotification({
      message: `Successfully created a payment plan for ${patientFirstName}`,
      variant: 'success',
    })

    paymentPlanForm.reset()

    // Refetch payment plans
    void refetchPaymentPlans()

    onClose()
  }

  // Ensure that the totalPaymentPlanAmountInDollars defaults to the overdueBalanceInDollars
  useEffect(() => {
    paymentPlanForm.setValues({
      totalPaymentPlanAmountInDollars: overdueBalanceInDollars,
    })
  }, [overdueBalanceInDollars])

  // Validate the totalPaymentPlanAmountInDollars field as it gets updated
  useEffect(() => {
    paymentPlanForm.validateField('totalPaymentPlanAmountInDollars')
  }, [totalPaymentPlanAmountInDollars])

  if (isInvoiceDataLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>Create payment plan</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          {createPaymentPLan.isError && (
            <Banner
              type='error'
              label='There was an error creating the payment plan, please try again.'
            />
          )}
          <NumberInput
            label='Total ABP amount'
            icon={<DollarSignIcon />}
            precision={2}
            explanation={`Patient's total balance is ${formatDollarAmount({
              amount: totalBalanceDueInDollars,
            })} and overdue balance is ${formatDollarAmount({
              amount: overdueBalanceInDollars,
            })}`}
            {...paymentPlanForm.getInputProps('totalPaymentPlanAmountInDollars')}
          />
          <Select
            label='Payment frequency'
            data={paymentFrequencyOptions.map(frequency => ({
              label: capitalize(frequency),
              value: frequency,
            }))}
            {...paymentPlanForm.getInputProps('paymentFrequency')}
          />
          <Select
            label='Number of payments'
            data={range(1, MAX_FIRESTORE_ARRAY_LENGTH).map(number => ({
              label: `${number}`,
              value: `${number}`,
            }))}
            explanation={
              totalPaymentPlanAmountInDollars && numPayments && paymentFrequency
                ? `${formatDollarAmount({
                    amount: totalPaymentPlanAmountInDollars / Number(numPayments),
                  })} / ${paymentFrequency}`
                : undefined
            }
            {...paymentPlanForm.getInputProps('numPayments')}
          />
          <DatePicker
            label='First payment date'
            explanation={
              finalPaymentDate && totalPaymentPlanAmountInDollars && numPayments && paymentFrequency
                ? `Last payment date - ${dayjs(finalPaymentDate).format('MMM D')}`
                : undefined
            }
            minDate={today.toDate()}
            {...paymentPlanForm.getInputProps('firstPaymentDate')}
          />
          <Alert variant='primary' icon={<InfoIcon />}>
            Payments will be first applied to any past due balances
          </Alert>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={() => onSubmit()}
            loading={createPaymentPLan.isLoading}
            disabled={totalBalanceDueInDollars === 0}
          >
            Create payment plan
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const PaymentPlanDrawer = (props: PaymentPlanDrawerProps) => {
  return (
    <BetterDrawer size='lg' position='right' onClose={props.onClose} opened={props.opened}>
      <PaymentPlanDrawerContent {...props} />
    </BetterDrawer>
  )
}
