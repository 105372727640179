import { hasGroupRole } from '@shared/types'
import { Route, Routes } from 'react-router-dom'
import { Redirect } from '../../components/Redirect'
import { useAuth } from '../../context/auth'
import { PatientProfileContent } from './PatientProfile'
import { PatientShell, PatientShellScroll } from './PatientShell'
import { PrescriptionBenefitsContent } from './PrescriptionBenefits'
import { AdminToolsPatientContent } from './admin'
import { DebugContent } from './debug/Debug'
import { PatientFilesContent } from './files/PFiles'
import { PatientMeasuresContent } from './forms/PMeasures'
import { PatientInvoicesContent } from './invoices/PInvoices'
import { PatientJourney } from './journey/PatientJourney'
import { PatientLabsContent } from './labs/PLabs'
import { VisitNoteContent } from './visits/updatedVisitNotes/VisitNoteMigrationRouter'

export const PatientChartPage = () => {
  const { currentUser } = useAuth()

  return (
    <PatientShell>
      <Routes>
        <Route path='/notes/:visitID' element={<VisitNoteContent />} />
        <Route
          path='/notes'
          element={<Redirect path={({ patientID }) => `/patients/${patientID}`} />}
        />
        <Route
          path='/edit/*'
          element={
            <PatientShellScroll>
              <PatientProfileContent />
            </PatientShellScroll>
          }
        />
        <Route
          path='/labs/*'
          element={
            <PatientShellScroll>
              <PatientLabsContent />
            </PatientShellScroll>
          }
        />
        <Route
          path='/files/*'
          element={
            <PatientShellScroll>
              <PatientFilesContent />
            </PatientShellScroll>
          }
        />
        <Route
          path='/measures/*'
          element={
            <PatientShellScroll>
              <PatientMeasuresContent />
            </PatientShellScroll>
          }
        />
        <Route path='/invoices/*' element={<PatientInvoicesContent />} />
        {hasGroupRole(currentUser, 'engineer') && (
          <>
            <Route
              path='/debug/*'
              element={
                <PatientShellScroll>
                  <DebugContent />
                </PatientShellScroll>
              }
            />
            <Route
              path='/prescription-benefits/*'
              element={
                <PatientShellScroll>
                  <PrescriptionBenefitsContent />
                </PatientShellScroll>
              }
            />
            <Route
              path='/admin-tools/*'
              element={
                <PatientShellScroll>
                  <AdminToolsPatientContent />
                </PatientShellScroll>
              }
            />
          </>
        )}
        <Route path='*' element={<PatientJourney />} />
      </Routes>
    </PatientShell>
  )
}

export default PatientChartPage
