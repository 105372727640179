import { ActionItem, ActionItemSortByOptions, ActionItemType } from '@shared/types'
import { dayjs } from '@shared/utils'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import values from 'lodash/values'
import { CASE_REVIEW_QUERY_PARAM_KEY } from '../pages/patient/visits/PVisitNote'

export type ActionItemGroup = { group: string; actionItems: ActionItem[] }
export type ActionItemGroupOption = 'type' | 'patient' | 'dueDate'

export const getActionItemLink = (actionItem: ActionItem) => {
  switch (actionItem.type) {
    case ActionItemType.AttestCaseReviewNote: {
      if (!actionItem.visitId) {
        return `/registry/case-review?patientId=${actionItem.patientId}`
      }
      return `/patients/${actionItem.patientId}/notes/${actionItem.visitId}?${CASE_REVIEW_QUERY_PARAM_KEY}=true`
    }
    case ActionItemType.ScheduleFollowUpAppointment:
    case ActionItemType.SchedulePostInTakeCheckInAppointment: {
      return `/patients/${actionItem.patientId}`
    }
    default: {
      let link = `/patients/${actionItem.patientId}`
      if (actionItem.visitId) {
        link += `/notes/${actionItem.visitId}`
      }
      return link
    }
  }
}

export const sortActionItemsByType = (actionItems: ActionItem[]) => {
  return reduce(
    ActionItemSortByOptions.type?.groups,
    (res: ActionItemGroup[], groupInfo, groupName) => {
      const actionItemsInGroup = actionItems.filter(
        actionItem => groupInfo.types?.includes(actionItem.type),
      )
      const actionItemGroup: ActionItemGroup = {
        group: groupName,
        actionItems: actionItemsInGroup,
      }
      return [...res, actionItemGroup]
    },
    [],
  )
}

export const sortActionItemsByPatient = (actionItems: ActionItem[] | undefined) => {
  /*
   * We want to first group by action items by patient id, and then replace the patient id
   * with the patient name. Patient names can be duplicated, so grouping in this way
   * ensures that all action items refer to the same patient
   */
  const groupPatientsById = groupBy(actionItems, (actionItem: ActionItem) => actionItem.patientId)
  const groupPatientsByName = map(
    groupPatientsById,
    (actionItems: ActionItem[], patientId: string) => {
      const patientName: string = actionItems[0]?.patientName || patientId
      return { group: patientName, actionItems }
    },
  )
  return values(groupPatientsByName)
}

export function getActionItemDueDateGroup(actionItem: ActionItem) {
  const today = dayjs().startOf('day')
  const tomorrow = dayjs().add(1, 'days')
  const dueDate = dayjs(actionItem.dueAt)
  if (dueDate.isBefore(today)) {
    return 'Past due'
  }
  if (dueDate.isSame(today, 'day')) {
    return 'Today'
  }
  if (dueDate.isSame(tomorrow, 'day')) {
    return 'Tomorrow'
  }
  return 'Later'
}

export const sortActionItemsByDueDate = (actionItems: ActionItem[]) => {
  return reduce(
    ActionItemSortByOptions.dueDate?.groups,
    (res: ActionItemGroup[], _groupInfo, groupName) => {
      const actionItemsInGroup = actionItems.filter(
        actionItem => getActionItemDueDateGroup(actionItem) === groupName,
      )
      const actionItemGroup: ActionItemGroup = {
        group: groupName,
        actionItems: actionItemsInGroup,
      }
      return [...res, actionItemGroup]
    },
    [],
  )
}

export const getSortedActionItems = ({
  actionItems,
  group,
}: {
  actionItems: ActionItem[]
  group: ActionItemGroupOption
}) => {
  if (group === 'type') {
    return sortActionItemsByType(actionItems)
  } else if (group === 'patient') {
    return sortActionItemsByPatient(actionItems)
  } else if (group === 'dueDate') {
    return sortActionItemsByDueDate(actionItems)
  }
}
