import React from 'react'
import { ErrorMessage } from 'react-hook-form'
import { ReactHookFormErrorsObject } from '../../utils/reactHookForm'
import ANote from '../atoms/ANote'

export type MErrorProps = {
  checkbox?: boolean
  className?: string
  errors?: ReactHookFormErrorsObject
  margin?: boolean
  message?: string
  name: string
}

const MError = ({ checkbox, className, errors, margin, name }: MErrorProps) => (
  <ErrorMessage errors={errors} name={name}>
    {({ message }) => (
      <div className={`flex ${margin ? 'mb-5' : 'mt-1'} ${className || ''}`}>
        {checkbox && <div className='w-radio-checkbox mr-2'></div>}
        <ANote error>{message}</ANote>
      </div>
    )}
  </ErrorMessage>
)

export default MError
