import { useForm } from '@mantine/form'
import {
  Accordion,
  Box,
  Divider,
  Flex,
  MinusCircleIcon,
  NumberInput,
  Select,
  Skeleton,
  Space,
  Stack,
  Text,
  TextInput,
} from '@shared/components'
import {
  DispenseUnitType,
  DoseSpotCopay,
  DoseSpotCoverageAlternative,
  DoseSpotCoverageRestrictions,
  DoseSpotPatientSpecificPricingCoverage,
  DoseSpotRealTimeFormularyRequestStatus,
  DoseSpotRTPBPharmacySpecialtyType,
} from '@shared/types'
import { toTime } from '@shared/utils'
import { isAxiosError } from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi, pharmaciesApi } from '../../../api'
import { usePatient } from '../PPatientContext'
import { MedicationSearchBar } from '../prescriptions/MedicationSearchBar'
import { EligibilitySelect } from './EligibilitySelect'

type RTPBForm = {
  patientEligibilityId?: string
  ndc: string
  strengths: {
    Strength: string
    NDC: string
  }[]
  pharmacyId: string
  quantity: string
  daysSupply: string
  dispenseUnitTypeID: string
}

const getSummaryString = (
  obj:
    | DoseSpotCopay
    | DoseSpotCoverageRestrictions
    | DoseSpotCoverageAlternative
    | Record<string, string>,
) => {
  return Object.entries(obj).map(([key, value]) => (value ? `${key}: ${value}\n` : ''))
}

const PrescriptionPharmacyCoverageInfo = ({
  patientSpecificPricingCoverages,
}: {
  patientSpecificPricingCoverages: DoseSpotPatientSpecificPricingCoverage[]
}) => {
  return (
    <Flex direction='row'>
      {patientSpecificPricingCoverages?.map((coverage, idx) => (
        <Stack
          key={coverage.Pharmacy?.PharmacyId}
          maw='30%'
          bg={idx % 2 === 0 ? 'white' : '#E2E2E2'}
          p='sm'
        >
          <Text bold>DS Pharmacy ID: {coverage.Pharmacy.PharmacyId}</Text>
          <Text bold>Identifications:</Text>
          <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
            {getSummaryString(coverage.Pharmacy.Identifications)}
          </Text>
          <Text bold>Pharmacy Type(s):</Text>
          <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
            {coverage.Pharmacy.PharmacySpecialties.join('\n')}
          </Text>
          <Text bold>Pharmacy Name:</Text>
          <Text>{coverage.Pharmacy.StoreName}</Text>
          <Text bold>Pharmacy Type:</Text>
          <Text>{DoseSpotRTPBPharmacySpecialtyType[coverage.Pharmacy.PharmacyType]}</Text>
          <Text bold>Pharmacy Address:</Text>
          <Text>
            {coverage.Pharmacy.Address1} {coverage.Pharmacy.Address2}
            {coverage.Pharmacy.City}, {coverage.Pharmacy.State} {coverage.Pharmacy.ZipCode}
          </Text>
          <Divider />
          <Text bold>Coverage</Text>
          <Text>Drug Status Code: {coverage.PricingCoverage.DrugStatusCode}</Text>
          <Text>Drug Status Text: {coverage.PricingCoverage.DrugStatus ?? 'n/a'}</Text>
          <Text>Formulary Status: {coverage.PricingCoverage.FormularyStatus ?? 'n/a'}</Text>
          <Text>PA Required: {coverage.PricingCoverage.PriorAuthRequired ? 'Yes' : 'No'}</Text>
          <Text>Quantity Priced: {coverage.PricingCoverage.QuantityPricedValue ?? 'n/a'}</Text>
          <Text>
            Quantity Unit of Measure:{' '}
            {coverage.PricingCoverage.QuantityPricedUnitOfMeasureType ?? 'n/a'}
          </Text>
          <Text>Days Supply Priced: {coverage.PricingCoverage.DaysSupplyPriced ?? 'n/a'}</Text>
          <Text>Plan Pay Amount: {coverage.PricingCoverage.PlanPayAmount ?? 'n/a'}</Text>
          <Text>
            Estimated Patient Pay Amount:{' '}
            {coverage.PricingCoverage.EstimatedPatientPayAmount ?? 'n/a'}
          </Text>
          <Text>
            Out of Pocket Applied Amount: {coverage.PricingCoverage.OOPAppliedAmount ?? 'n/a'}
          </Text>
          <Text>
            Out of Pocket Remaining Amount: {coverage.PricingCoverage.OOPRemainingAmount ?? 'n/a'}
          </Text>
          <Text>
            Deductible Applied Amount: {coverage.PricingCoverage.DeductibleAppliedAmount ?? 'n/a'}
          </Text>
          <Text>
            Deductible Remaining Amount:{' '}
            {coverage.PricingCoverage.DeductibleRemainingAmount ?? 'n/a'}
          </Text>
          <Text>
            Coverage Alert(s):{' '}
            {coverage.PricingCoverage.CoverageAlerts.map(ca => ca.ReferenceText).join(', ') ??
              'n/a'}
          </Text>
        </Stack>
      ))}
    </Flex>
  )
}

// RTPB = RealTime Prescription Benefits
export const RTPBContent = () => {
  const { patientId } = usePatient()
  const [currentAlternativeValue, setCurrentAlternativeValue] = useState<string | null>(null)
  const [surescriptsError, setSurescriptsError] = useState('')

  const form = useForm<RTPBForm>({
    initialValues: {
      ndc: '',
      strengths: [],
      pharmacyId: '',
      quantity: '',
      daysSupply: '',
      dispenseUnitTypeID: '35',
    },
  })

  const { strengths, patientEligibilityId, ...queryParameters } = form.values

  // Query for coverage
  const [rtpbQueryKey, rtpbQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/dosespotRealtimePrescriptionBenefits',
    {
      params: { patientId },
      query: patientEligibilityId ? { ...queryParameters, patientEligibilityId } : queryParameters,
    },
  )

  const rtpbQuery = useQuery(rtpbQueryKey, rtpbQueryFunction, {
    enabled: Object.values(queryParameters).every(Boolean) && !surescriptsError,
    // This endpoint queues up a request to SureScripts, so we have to retry until DS has loaded the result
    refetchInterval: data => {
      if (!data) {
        return 500
      }
      return DoseSpotRealTimeFormularyRequestStatus[data?.RealTimeFormularyRequestStatus] ===
        'Complete'
        ? false
        : toTime('1 sec').ms()
    },
    retry(_failureCount, error) {
      if (isAxiosError(error)) {
        setSurescriptsError(error.response?.data.message)
      }
      return !error
    },
  })

  useQuery(['pharmaciesApi.retrieve', patientId], () => pharmaciesApi.retrieve(patientId), {
    onSuccess(data) {
      form.setFieldValue('pharmacyId', String(data?.PharmacyId ?? ''))
    },
  })

  // Show loading state until we've received the data after the fetch interval
  const isLoading =
    rtpbQuery.isLoading ||
    DoseSpotRealTimeFormularyRequestStatus[rtpbQuery.data?.RealTimeFormularyRequestStatus ?? 0] !==
      'Complete'

  const rtpb = rtpbQuery.data

  return (
    <Stack>
      <Flex p='xs' maw='100%' gap='lg'>
        <EligibilitySelect {...form.getInputProps('patientEligibilityId')} />
        <Box w='400px'>
          <MedicationSearchBar
            onChange={item => {
              form.setFieldValue('ndc', '')
              form.setFieldValue('strengths', item?.Strength ?? [])
            }}
          />
        </Box>
        <Select
          w='400px'
          label='Medication strength'
          {...form.getInputProps('ndc')}
          data={strengths?.map(strength => ({ value: strength.NDC, label: strength.Strength }))}
          disabled={strengths.length === 0}
        />
        <TextInput label='NDC' {...form.getInputProps('ndc')} />
      </Flex>
      <Flex p='xs' maw='100%' gap='lg'>
        <TextInput label='DS Pharmacy ID' {...form.getInputProps('pharmacyId')} />
        <NumberInput label='Quantity' {...form.getInputProps('quantity')} />
        <NumberInput label='Days supply' {...form.getInputProps('daysSupply')} />
        <Select
          w='400px'
          label='Dispense unit'
          {...form.getInputProps('dispenseUnitTypeID')}
          data={Object.entries(DispenseUnitType).map(([value, label]) => ({ value, label }))}
        />
      </Flex>

      {!rtpbQuery.isFetched && (
        <Text>Fill out the form above to load RealTime Prescription Benefits</Text>
      )}
      {isLoading && rtpbQuery.isFetching && <Skeleton w='400px' h='200px' />}
      {!rtpbQuery.isLoading && surescriptsError && <Text>RTPB Results: {surescriptsError}</Text>}
      {!isLoading && rtpb && (
        <Stack m='xs'>
          <Text>RTPB Results</Text>
          <Stack
            w='1200px'
            sx={theme => ({
              borderWidth: theme.other.sizes.border.lg,
              borderStyle: 'solid',
              borderColor: theme.other.colors.actions[0],
              borderRadius: theme.radius.sm,
              padding: theme.other.sizes.padding.lg,
            })}
          >
            <Text bold>
              Pharmacy Pricing Coverages: {!rtpb.PatientSpecificPricingCoverages && 'n/a'}
            </Text>
            <PrescriptionPharmacyCoverageInfo
              patientSpecificPricingCoverages={rtpb.PatientSpecificPricingCoverages ?? []}
            />
            <Space h='md' />
            <Text bold>Alternatives: {!rtpb.Alternatives && 'n/a'}</Text>

            <Accordion value={currentAlternativeValue} onChange={setCurrentAlternativeValue}>
              {rtpb.Alternatives?.map(alternative => (
                <Accordion.Item key={alternative.Ndc} value={alternative.Ndc}>
                  <Accordion.Control
                    chevron={currentAlternativeValue === 'item-1' && <MinusCircleIcon styled />}
                  >
                    {alternative.DisplayName}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Box m='sm'>
                      <Text>NDC: {alternative.Ndc}</Text>
                      <Text>RxNorm: {alternative.RxCUI}</Text>
                    </Box>
                    <PrescriptionPharmacyCoverageInfo
                      patientSpecificPricingCoverages={alternative.PatientSpecificPricingCoverages}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
