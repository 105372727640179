import { ClockIcon, Group, Text } from '@shared/components'
import { renderDurationString } from '../../../utils/utils'

export type DurationTextProps = {
  clinicianTime?: number
  preWorkTime?: number
  patientTime?: number
  postCaseReviewTime?: number
  caseReviewTime?: number
}

export const DurationText = ({
  clinicianTime,
  preWorkTime,
  patientTime,
  postCaseReviewTime,
  caseReviewTime,
}: DurationTextProps) => {
  const caseReview = preWorkTime || postCaseReviewTime || caseReviewTime
  const visitNote = patientTime && clinicianTime
  if (visitNote || caseReview) {
    return (
      <Group spacing='xs'>
        <ClockIcon color={colors => colors.text[1]} />
        <Text size='xs' color={colors => colors.text[1]}>
          {visitNote
            ? renderDurationString({ patientTime, clinicianTime })
            : renderCaseReviewString({ preWorkTime, postCaseReviewTime, caseReviewTime })}
        </Text>
      </Group>
    )
  }
  return (
    <Text size='xs' color={colors => colors.text[1]}>
      No recorded time found
    </Text>
  )
}
const renderCaseReviewString = ({
  preWorkTime = 0,
  postCaseReviewTime = 0,
  caseReviewTime = 0,
}: {
  postCaseReviewTime?: number
  preWorkTime?: number
  caseReviewTime?: number
}) => {
  let durationString = ''
  if (preWorkTime) {
    durationString = `${preWorkTime} mins pre-work, `
  }
  return durationString.concat(
    `${caseReviewTime} mins case review, ${postCaseReviewTime} mins post case review`,
  )
}
