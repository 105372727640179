import { useForm } from '@mantine/form'
import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Textarea,
  validateWith,
} from '@shared/components'
import { isRequired } from '../../../utils/formValidation'

const VisitAddendumModal = ({
  closeModal,
  setNewAddendumContent,
}: {
  closeModal: () => void
  setNewAddendumContent: (content: string) => void
}) => {
  const form = useForm<{ addendum: string }>({
    validate: { addendum: validateWith(isRequired) },
  })

  const onSubmit = () => {
    if (!form.validate().hasErrors) {
      setNewAddendumContent(form.values.addendum)
    }
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Add an addendum'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          <PrimaryButton onClick={onSubmit}>Sign and lock</PrimaryButton>
        </Group>
      }
    >
      <Textarea
        {...form.getInputProps('addendum')}
        minRows={4}
        placeholder='Type here...'
        autosize
      />
    </Modal>
  )
}

export default VisitAddendumModal
