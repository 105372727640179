import { Phq9FormResponse } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class BehavioralHealthFormsApi extends BaseApi {
  listPhq9 = ({ patientId }: { patientId: string }) => {
    return this.json<Phq9FormResponse[]>({
      method: 'GET',
      url: `/patients/${patientId}/behavioralHealthForms/phq9`,
    })
  }
}
