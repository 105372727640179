import { Group, PencilIcon, SecondaryButton, Text, TrashIcon } from '@shared/components'
import { DrugScreen, DrugScreenResults, drugScreenInfo, hasRole } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useAuth } from '../../../context/auth'
import { DrugScreenRowIcon } from './DrugScreenRowIcon'

type DrugScreenRowProps = {
  drugScreen: DrugScreen
  onEdit: () => void
  onDelete: () => void
  readOnly?: boolean
  showDateColumn?: boolean
}

const DrugScreenRow = ({
  drugScreen,
  onEdit,
  onDelete,
  readOnly = false,
  showDateColumn = true,
}: DrugScreenRowProps) => {
  const { currentUser } = useAuth()
  const { administeredAt, results } = drugScreen

  // Drug screens are deletable within 12 hours by anyone, and after 12 hours just by supervising clincians
  const allowDeletionTime = dayjs().subtract(12, 'hour')
  const canDelete =
    (dayjs(administeredAt).isAfter(allowDeletionTime) || hasRole(currentUser, 'spc')) && !readOnly

  const canEdit = hasRole(currentUser, 'spc') && !readOnly

  return (
    <tr key={drugScreen.oid}>
      {showDateColumn && (
        <td>
          <Group position='apart' pr='md'>
            <Text>{dayjs(administeredAt).format('MM/DD/YYYY')}</Text>
            <Group>
              {canEdit && <SecondaryButton size='xs' leftIcon={<PencilIcon />} onClick={onEdit} />}
              {canDelete && (
                <SecondaryButton size='xs' leftIcon={<TrashIcon />} onClick={onDelete} />
              )}
            </Group>
          </Group>
        </td>
      )}
      {Object.keys(drugScreenInfo).map(drugScreenOption => {
        const resultIsPositive = results[drugScreenOption as keyof DrugScreenResults]

        return (
          <td key={drugScreenOption}>
            <DrugScreenRowIcon
              isBupe={drugScreenOption === 'drug_screen_buprenorphine_result'}
              resultIsPositive={resultIsPositive}
            />
          </td>
        )
      })}
    </tr>
  )
}

export default DrugScreenRow
