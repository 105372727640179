import { Divider, Skeleton, Stack, TitleThree } from '@shared/components'
import { usePatient } from '../PPatientContext'
import { InsuranceSubSection } from './InsuranceSubSection'
import { PastInsurancesSection } from './PastInsurancesSection'

export const PatientProfileInsurance = () => {
  const { patientQuery } = usePatient()

  return (
    <Stack>
      <TitleThree>Insurance</TitleThree>
      <Divider />
      <Skeleton visible={patientQuery?.isLoading ?? true}>
        <InsuranceSubSection
          insuranceId={patientQuery?.data?.insuranceData?.primaryInsuranceId}
          isInsuranceActive={patientQuery?.data?.insuranceData?.hasInsurance ?? false}
          insuranceType='primary'
        />

        <InsuranceSubSection
          insuranceId={patientQuery?.data?.insuranceData?.secondaryInsuranceId}
          isInsuranceActive={patientQuery?.data?.insuranceData?.hasSecondaryInsurance ?? false}
          insuranceType='other'
        />
      </Skeleton>
      <PastInsurancesSection />
    </Stack>
  )
}
