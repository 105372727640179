import { Patient } from '@shared/types'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import ANoteYesNoRadioInput from '../../../components/atoms/ANoteYesNoRadioInput'
import { alternativeDate } from '../../../components/molecules/MDictionary'
import MError from '../../../components/molecules/MError'
import ODSInput from '../../../components/ods/ODSInput'
import {
  ReactHookFormErrorsObject,
  ReactHookRegister,
  ReactHookWatch,
} from '../../../utils/reactHookForm'

export type MReengagementNoteProps = {
  watch: ReactHookWatch
  register: ReactHookRegister
  sidePanel?: boolean
  errors: ReactHookFormErrorsObject
  patient: Patient | undefined
  dateOfDischarge: string
}

const MReengagementNote = ({
  register,
  watch,
  sidePanel = false,
  errors,
  patient,
  dateOfDischarge = '',
}: MReengagementNoteProps) => {
  const resume_with_prior_clinical_team = watch('resume_with_prior_clinical_team')

  return (
    <div
      className={`${
        sidePanel ? 'px-4 border-t border-gray-200' : ''
      } flex flex-col space-y-6 overflow-y-auto text-left pb-6`}
    >
      <ANoteSection hideBorder>
        <ANoteHeader text='Background' />
        <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
          <p className='block text-sm leading-5 text-gray-700'>
            <span className='font-medium'>Prior Prescribing Clinician: </span>
            {`${patient?.primaryClinician?.name ?? 'Unknown'}`}
          </p>
          <p className='block text-sm leading-5 text-gray-700'>
            <span className='font-medium'>Prior Clinical Care Manager: </span>
            {`${patient?.nurseCareManager?.name ?? 'Unknown'}`}
          </p>
          <p className='block text-sm leading-5 text-gray-700'>
            <span className='font-medium'>Date of Discharge: </span>
            {dateOfDischarge}
          </p>
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Rationale for Re-Engagement' />
        <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
          <div className='flex flex-col items-start justify-start'>
            <p className='block text-sm font-medium leading-5 text-gray-700'>
              Proposed date of re-engagement
            </p>
            <div className='mb-2'>
              <ODSInput
                name='reengagementDate'
                placeholder='YYYY-MM-DD'
                ref={register({
                  pattern: {
                    value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                    message: alternativeDate,
                  },
                })}
                error={Boolean(errors.reengagementDate)}
                disabled={sidePanel}
              />
              <MError errors={errors} name='reengagementDate' />
            </div>
          </div>
          <ANoteTextArea
            label='Reason for re-engagement'
            name='reason_for_re_engagement'
            id='reason_for_re_engagement'
            minRows={2}
            ref={register()}
            disabled={sidePanel}
          />
          <ANoteYesNoRadioInput
            name='eligible_for_re_engagement'
            id='eligible_for_re_engagement'
            ref={register()}
            label='Is the patient eligible for re-engagement?'
            disabled={sidePanel}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <ANoteSection>
        <ANoteHeader text='Re-Engagement Plan' />
        <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
          <ANoteTextArea
            name='re_engagement_plan'
            id='re_engagement_plan'
            minRows={2}
            ref={register()}
            disabled={sidePanel}
          />
          <ANoteYesNoRadioInput
            name='resume_with_prior_clinical_team'
            id='resume_with_prior_clinical_team'
            ref={register()}
            label='Will the patient resume with the prior PC and NCM?'
            className={`${resume_with_prior_clinical_team === 'no' ? '' : 'my-4'}`}
            disabled={sidePanel}
          />
          <ANoteTextArea
            name='why_not_resume_with_prior_clinical_team'
            id='why_not_resume_with_prior_clinical_team'
            label='Why not?'
            minRows={1}
            ref={register()}
            className={`${resume_with_prior_clinical_team === 'no' ? '' : 'hidden'} my-4 ml-4`}
            disabled={sidePanel}
          />
        </ANoteSectionContent>
      </ANoteSection>
    </div>
  )
}

export default MReengagementNote
