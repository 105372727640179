import { useForm } from '@mantine/form'
import {
  Alert,
  BetterDrawer,
  BookmarkIcon,
  DatePicker,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  SlashIcon,
  Stack,
  Text,
  TextInput,
  TitleFour,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { DayOfWeek, PatientModel, arrayIncludes, hasRole } from '@shared/types'
import { dayjs } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'
import { useAuth } from '../../context/auth'
import { isRequired } from '../../utils/formValidation'
import * as FullStory from '../../utils/fullstory'
import {
  ALL_WEEK_AVAILABILITY,
  AvailabilitySelector,
  useEmrMutation,
  useEmrQuery,
  useInvalidateQuery,
  useLunaMutation,
  useLunaQuery,
} from '../../utils/hooks'
import { useInvalidateLunaQuery } from '../../utils/hooks/use-invalidate-luna-query'
import { mapIdToName } from '../../utils/utils'
import { JourneyItemContent, usePatient } from '../patient/PPatientContext'
import { REASONS_FOR_NO_CCM_TRANSITION } from './CalendarDrawer'

type ScheduleVisitHoldDrawerProps = {
  opened: boolean
  visitHold?: JourneyItemContent<'visit-hold'>
  onClose: () => void
}

const schedulePatientWeeksInFuture = [2, 3, 4, 5, 6] as const

const getInitialValues = (visitHold?: JourneyItemContent<'visit-hold'>, patient?: PatientModel) => {
  let numWeeks: number = 4
  let sendUdsReminder: 'yes' | 'no' | '' = ''
  let appointmentTypeId: `${number}` | '' = ''
  let readyForCcmTransition: 'yes' | 'no' | '' = ''

  if (visitHold) {
    // Normalize the dates to the start of the day
    const intendedDay = dayjs(visitHold.intendedDay).startOf('day')
    const scheduledAt = dayjs(visitHold.scheduledAt).startOf('day')
    // compute the originally selected number of weeks
    numWeeks = dayjs(intendedDay).diff(scheduledAt, 'weeks')

    sendUdsReminder = visitHold.sendUdsReminder ? 'yes' : 'no'
    appointmentTypeId = `${visitHold.appointmentTypeId}`
    // When editing a visit hold, prefill this field
    readyForCcmTransition = (visitHold && patient?.ccmTransition?.readyForCcmTransition) || ''
  }

  return {
    availabilityType: '' as const,
    appointmentType: appointmentTypeId,
    sendUdsReminder,
    readyForCcmTransition,
    reasonForNoCcmTransition: '',
    reasonForNoCcmTransitionOther: '',
    numWeeks: `${numWeeks}`,
    morning: [],
    afternoon: [],
    evening: [],
    specificDate: null,
    specificTime: '',
  }
}

const ScheduleVisitHoldDrawerContent = (props: ScheduleVisitHoldDrawerProps) => {
  const { patientId, patientQuery } = usePatient()
  const { currentUser } = useAuth()

  const isEditing = Boolean(props.visitHold)

  // This is either the scheduledAt date of an edited visit hold or today's date for a new hold
  const dateHoldScheduled = props.visitHold
    ? dayjs(props.visitHold.scheduledAt).startOf('day')
    : dayjs().startOf('day')

  const form = useForm<{
    appointmentType: `${number}` | ''
    sendUdsReminder: 'yes' | 'no' | ''
    readyForCcmTransition: 'yes' | 'no' | ''
    reasonForNoCcmTransition: string
    reasonForNoCcmTransitionOther: string
    numWeeks: string
    // availabilities
    morning: DayOfWeek[]
    afternoon: DayOfWeek[]
    evening: DayOfWeek[]
    availabilityType: 'anytime' | 'preferred' | 'specific' | ''
    specificDate: Date | null
    specificTime: string
  }>({
    initialValues: getInitialValues(props.visitHold, patientQuery?.data),
    validate: {
      appointmentType: isRequired,
      availabilityType: isRequired,
      sendUdsReminder: isRequired,
      readyForCcmTransition: value => {
        if (isCcmTransitionSectionVisible) {
          return isRequired(value)
        }
      },
      reasonForNoCcmTransition: validateWith(
        skipIfOtherField('readyForCcmTransition', 'is', 'yes'),
        skipIfOtherField('readyForCcmTransition', 'is', ''),
        isRequired,
      ),
      reasonForNoCcmTransitionOther: validateWith(
        skipIfOtherField('reasonForNoCcmTransition', 'not', 'Other'),
        skipIfOtherField('readyForCcmTransition', 'is', 'yes'),
        isRequired,
      ),
      numWeeks: isRequired,
      specificTime: validateWith(
        skipIfOtherField('availabilityType', 'not', 'specific'),
        isRequired,
      ),
    },
  })

  const appointmentTypesQuery = useEmrQuery('GET /appointmentTypes')
  const primaryClinicianQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: {
        employeeId: patientQuery?.data?.primaryClinician?.id || '',
      },
    },
    { enabled: Boolean(patientQuery?.data?.primaryClinician?.id) },
  )

  const nextCarePathwayQuery = useEmrQuery(
    'GET /care-pathways/next',
    {
      query: {
        patientId,
        nextAppointmentDate: form.values.numWeeks
          ? dateHoldScheduled
              .add(Number(form.values.numWeeks), 'weeks')
              .startOf('day')
              .format('YYYY-MM-DD')
          : undefined,
      },
    },
    {
      /**
       * Limit to just 1 retry to minimize the cumulative time spent waiting for query to succeed.
       * We expect this query to fail if the patient is missing an intake visit and we do not
       * want to block clinicians from scheduling as a result.
       */
      retry: 1,
    },
  )

  const availabilityQuery = useLunaQuery(
    'GET /patients/:patientId/settings/availability',
    {
      params: {
        patientId,
      },
    },
    {
      enabled: Boolean(patientId),
      // Seed the form with the patient's availability data
      onSuccess: data => {
        const availabilityData = data?.data?.availability

        // No need to seed the form if the patient has no availability set previously
        if (!availabilityData) {
          return
        }

        // If the patient has all-week availability, we don't need to set the specific times
        const availabilityType = isEqual(availabilityData, ALL_WEEK_AVAILABILITY)
          ? 'anytime'
          : 'preferred'

        form.setValues({
          availabilityType,
          ...availabilityData,
        })
      },
    },
  )

  // These are all of the queries that need to finish before the user can start filling out the form
  const prerequisiteDataIsLoading =
    nextCarePathwayQuery.isLoading ||
    appointmentTypesQuery.isLoading ||
    primaryClinicianQuery.isLoading ||
    availabilityQuery.isLoading

  const invalidateQuery = useInvalidateQuery()
  const invalidateLunaQuery = useInvalidateLunaQuery()

  const appointmentSelectItems = useMemo(
    () =>
      appointmentTypesQuery.data?.followUpTypes
        .filter(type => !type.reserve && !type.overbooked && !type.staggered && !type.inactive)
        .map(type => ({
          length: type.length,
          label: mapIdToName(type.id, appointmentTypesQuery.data),
          value: `${type.id}` as const,
        })) || [],
    [appointmentTypesQuery?.data],
  )

  const schedulePatientSelectItems = schedulePatientWeeksInFuture.map(option => ({
    label: `in ${option} weeks`,
    value: `${option}`,
  }))

  const isCcmTransitionSectionVisible = hasRole(currentUser, 'spc', 'pc', 'admin', 'engineer')

  useEffect(() => {
    if (!form.values.appointmentType && appointmentSelectItems.length) {
      // Default to the 20 minute follow-up type if it exists, otherwise default to the first item
      const twentyMinuteFollowupType = appointmentSelectItems.find(item => item.length === 20)
      const appointmentType =
        twentyMinuteFollowupType?.value || appointmentSelectItems[0]?.value || ''
      form.setValues({ appointmentType })
    }
  }, [appointmentSelectItems, form])

  const minDate = form.values.numWeeks
    ? dateHoldScheduled
        .add(Number(form.values.numWeeks), 'weeks')
        .subtract(3, 'days')
        .startOf('day')
    : null

  const maxDate = minDate?.add(6, 'days').endOf('day')

  const dateRange = `${minDate ? minDate.format('MMM D') : ''} - ${
    maxDate ? maxDate.format('MMM D') : ''
  }`

  /*
   * If the patient is eligible for CCM visits, we determine the calendar ID based on their care pathway.
   * If not, we use the primary clinician's calendar ID. If no value for readyForCcmTransition is set, use the care pathway calendar.
   *
   * Also, if the nextCarePathway query fails, we default to scheduling with the PC. The common reason for the query failing is that
   * patients are missing an intake visit. However, in most of these cases, the patient has actually had an intake visit except that
   * the visit was incorrectly cancelled.
   */
  const { calendarId, clinicianName, clinicianId } =
    form.values.readyForCcmTransition === 'no' || nextCarePathwayQuery.isError
      ? {
          calendarId: primaryClinicianQuery?.data?.calendarId,
          clinicianName: primaryClinicianQuery?.data?.name,
          clinicianId: primaryClinicianQuery?.data?.oid,
        }
      : {
          calendarId: nextCarePathwayQuery.data?.nextClinicianCalendarId,
          clinicianName: nextCarePathwayQuery.data?.nextClinicianName,
          clinicianId: nextCarePathwayQuery.data?.nextClinicianId,
        }

  const capacityQuery = useLunaQuery(
    'GET /scheduling/employees/:employeeId/capacity',
    {
      params: {
        // Look up capacity for the patient's PC if the PC is selected, or the patient's CCM if the `Eligible for CCM visits` is set to 'yes'
        employeeId: clinicianId || '',
      },
      query: {
        startDate: dayjs(minDate).format('YYYY-MM-DD'),
        endDate: dayjs(maxDate).format('YYYY-MM-DD'),
        appointmentTypeId: form.values.appointmentType,
      },
    },
    {
      enabled: Boolean(clinicianId && minDate && maxDate && form.values.appointmentType),
    },
  )

  const isOverCapacity = capacityQuery.data?.data.hasCapacityForHold === false

  // Send a FullStory event if the clinician is over capacity
  useEffect(() => {
    if (isOverCapacity) {
      FullStory.event('Clinician is over capacity')
    }
  }, [isOverCapacity])

  const patientTimezone = patientQuery?.data?.account?.timezone

  const slotsQuery = useEmrQuery(
    'GET /appointments/slots',
    {
      query: {
        minDate: minDate?.toISOString() || '',
        maxDate: maxDate?.toISOString() || '',
        timezone: patientTimezone || 'America/New_York',
        patientId,
        appointmentTypeId: form.values.appointmentType,
        calendarId: `${calendarId}` || '',
      },
    },

    {
      enabled: Boolean(minDate && maxDate && form.values.appointmentType && calendarId),
    },
  )

  // Restricts date picker to only show dates with available slots
  const excludeDateFunc = (date: Date) => {
    if (!slotsQuery.data) {
      return true
    }

    return !(
      slotsQuery.data.some(slot => dayjs(slot.time).isSame(date, 'day')) &&
      dayjs(date).isBetween(minDate, maxDate, 'day', '[]')
    )
  }

  const timeSlots = slotsQuery.data
    ?.filter(
      slot =>
        dayjs(slot.time).format('YYYY-MM-DD') ===
        dayjs(form.values.specificDate).format('YYYY-MM-DD'),
    )
    .map(slot => ({
      label: dayjs(slot.time)
        .tz(patientTimezone || 'America/New_York')
        .format('h:mm A z'),
      value: slot.time,
    }))
    .sort((a, b) => dayjs(a.value).diff(dayjs(b.value)))

  // Resets time if the selected time is not available on the selected date
  useEffect(() => {
    if (
      form.values.availabilityType === 'specific' &&
      form.values.specificDate &&
      !(
        timeSlots?.find(slot => dayjs(slot.value).isSame(form.values.specificTime)) ||
        slotsQuery.isLoading
      ) &&
      form.values.specificTime
    ) {
      form.setValues({ specificTime: '' })
    }
  }, [
    form.values.availabilityType,
    form.values.specificDate,
    form.values.specificTime,
    timeSlots,
    slotsQuery.isLoading,
  ])

  // Resets the date to the same day of the week as today if numWeeks changes
  useEffect(() => {
    if (
      minDate &&
      form.values.availabilityType === 'specific' &&
      (!form.values.specificDate ||
        !dayjs(form.values.specificDate).isBetween(minDate, maxDate, 'day', '[]'))
    ) {
      const specificDate = dateHoldScheduled.add(Number(form.values.numWeeks), 'weeks').toDate()
      form.setValues({ specificDate })
    }
  }, [
    form.values.numWeeks,
    form.values.availabilityType,
    form.values.specificDate,
    minDate,
    maxDate,
  ])

  const createCalendarHoldMutation = useLunaMutation('POST /scheduling/holds')
  const updateCalendarHoldMutation = useLunaMutation('PUT /scheduling/holds/:id')

  const createAppointmentMutation = useLunaMutation('POST /scheduling/appointment')

  const updatePatientMutation = useEmrMutation('PUT /patient/:patientId')

  const onSubmit = () => {
    if (form.validate().hasErrors || !form.values.appointmentType || !calendarId) {
      return
    }

    if (isCcmTransitionSectionVisible && form.values.readyForCcmTransition) {
      updatePatientMutation.mutate(
        {
          params: { patientId },
          data: {
            ccmTransition: {
              readyForCcmTransition: form.values.readyForCcmTransition,
              reasonForNoCcmTransition: form.values.reasonForNoCcmTransition,
              reasonForNoCcmTransitionOther: form.values.reasonForNoCcmTransitionOther,
            },
          },
        },
        {
          onSuccess: async () => {
            await invalidateQuery('GET /patient/:patientId')
          },
        },
      )
    }

    // Schedule a normal appointment if the patient requested specific availability
    if (form.values.availabilityType === 'specific') {
      return createAppointmentMutation.mutate(
        {
          data: {
            appointmentType: form.values.appointmentType,
            patientId,
            calendarId: `${calendarId}`,
            // specificTime holds the entire iso string, so we don't need to send the date separately
            datetime: dayjs(form.values.specificTime).toISOString(),
            metadata: {
              shouldSendUdsReminder: form.values.sendUdsReminder === 'yes',
              // Add metadata specifying that the patient is eligible for a hold but requested a specific appointment time
              patientEligibleForHoldRequestedSpecificAppointmentTime: true,
            },
          },
        },
        {
          onSuccess: async () => {
            await invalidateQuery('GET /patient/:patientId/appointments')
            props.onClose()
          },
        },
      )
    }

    if (!minDate || !maxDate) {
      return
    }

    // Editing a visit hold
    if (props.visitHold?.oid) {
      return updateCalendarHoldMutation.mutate(
        {
          params: {
            id: props.visitHold.oid,
          },
          data: {
            patientId,
            calendarId,
            numWeeks: Number(form.values.numWeeks),
            appointmentTypeId: Number(form.values.appointmentType),
            sendUdsReminder: form.values.sendUdsReminder === 'yes',
            availability:
              form.values.availabilityType === 'anytime'
                ? ALL_WEEK_AVAILABILITY
                : {
                    morning: form.values.morning,
                    afternoon: form.values.afternoon,
                    evening: form.values.evening,
                  },
          },
        },

        {
          onSuccess: () => {
            void invalidateLunaQuery('GET /scheduling/patients/:patientId/holds')
            void invalidateLunaQuery('GET /scheduling/calendars/:calendarId/holds')
            void invalidateLunaQuery('GET /patients/:patientId/settings/availability')
            props.onClose()
          },
        },
      )
    }

    return createCalendarHoldMutation.mutate(
      {
        data: {
          patientId,
          calendarId,
          numWeeks: Number(form.values.numWeeks),
          appointmentTypeId: Number(form.values.appointmentType),
          sendUdsReminder: form.values.sendUdsReminder === 'yes',
          availability:
            form.values.availabilityType === 'anytime'
              ? ALL_WEEK_AVAILABILITY
              : {
                  morning: form.values.morning,
                  afternoon: form.values.afternoon,
                  evening: form.values.evening,
                },
        },
      },

      {
        onSuccess: () => {
          void invalidateLunaQuery('GET /scheduling/patients/:patientId/holds')
          void invalidateLunaQuery('GET /scheduling/calendars/:calendarId/holds')
          void invalidateLunaQuery('GET /patients/:patientId/settings/availability')
          props.onClose()
        },
      },
    )
  }

  if (prerequisiteDataIsLoading) {
    return (
      <>
        <DrawerHeader onClose={props.onClose}>Hold follow-up visit</DrawerHeader>
        <DrawerContent>
          <Stack p='md' spacing='md'>
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
          </Stack>
        </DrawerContent>
      </>
    )
  }

  return (
    <>
      <DrawerHeader onClose={props.onClose}>
        {isEditing ? 'Edit follow-up visit hold' : 'Hold follow-up visit'}
      </DrawerHeader>
      <DrawerContent>
        {!clinicianId && (
          /**
           * In some cases, patients do not have a clinician assigned to them,
           * or the clinician does not have a calendar ID. In these cases, we
           * should notify the user that creating a follow-up visit hold
           * will not be possible.
           */
          <Alert mx='md' variant='error'>
            Unable to schedule a follow-up visit hold because the patient does not have an assigned
            clinician.
          </Alert>
        )}
        {!calendarId && (
          <Alert mx='md' variant='error'>
            Unable to schedule a follow-up visit hold because the clinician does not have an active
            calendar.
          </Alert>
        )}
        <Stack p='md'>
          <Select
            data={appointmentSelectItems}
            label='Visit type'
            {...form.getInputProps('appointmentType')}
          />
          <RadioGroup
            orientation='horizontal'
            label='UDS reminder'
            {...form.getInputProps('sendUdsReminder')}
          >
            <Radio
              value='yes'
              label='Yes'
              sx={{
                flexGrow: 1,
              }}
            />
            <Radio
              value='no'
              label='No'
              sx={{
                flexGrow: 1,
              }}
            />
          </RadioGroup>
          {isCcmTransitionSectionVisible && (
            <>
              <RadioGroup
                orientation='horizontal'
                label='Eligible for CCM visits'
                {...form.getInputProps('readyForCcmTransition')}
              >
                <Radio
                  value='yes'
                  label='Yes'
                  sx={{
                    flexGrow: 1,
                  }}
                />
                <Radio
                  value='no'
                  label='No'
                  sx={{
                    flexGrow: 1,
                  }}
                />
              </RadioGroup>
              {form.values?.readyForCcmTransition === 'no' && (
                <Select
                  label='Reason'
                  data={REASONS_FOR_NO_CCM_TRANSITION.map(reason => ({
                    label: reason,
                    value: reason,
                  }))}
                  {...form.getInputProps('reasonForNoCcmTransition')}
                />
              )}
              {form.values?.readyForCcmTransition === 'no' &&
                form.values?.reasonForNoCcmTransition === 'Other' && (
                  <TextInput
                    label='Other reason'
                    placeholder='Enter other reason...'
                    {...form.getInputProps('reasonForNoCcmTransitionOther')}
                  />
                )}
            </>
          )}
          <Select
            data={schedulePatientSelectItems}
            label='Schedule patient'
            {...form.getInputProps('numWeeks')}
          />
          <RadioGroup
            label={<TitleFour>{dateRange}</TitleFour>}
            {...form.getInputProps('availabilityType')}
          >
            <Radio value='anytime' label='Anytime in the week' />
            {isOverCapacity && form.values.availabilityType === 'anytime' && (
              <Alert
                variant='error'
                icon={<SlashIcon />}
                title={<Text bold>Select another week</Text>}
              >
                <Text>{`${clinicianName} has no availability left this week`}</Text>
              </Alert>
            )}
            <Radio value='preferred' label='Within their preferred times' />
            {isOverCapacity && form.values.availabilityType === 'preferred' && (
              <Alert
                variant='error'
                icon={<SlashIcon />}
                title={<Text bold>Select another week</Text>}
              >
                <Text>{`${clinicianName} has no availability left this week`}</Text>
              </Alert>
            )}
            {form.values.availabilityType === 'preferred' && <AvailabilitySelector {...form} />}
            <Radio value='specific' label='On a specific date/time' />
            {isOverCapacity && form.values.availabilityType === 'specific' && (
              <Alert variant='error' icon={<SlashIcon />}>
                <Text>{`${clinicianName} has no availability left this week`}</Text>
              </Alert>
            )}
            {form.values.availabilityType === 'specific' && (
              <Stack pt='sm'>
                <Alert
                  variant='warning'
                  icon={<BookmarkIcon />}
                  title={<Text bold>{`With ${clinicianName}`}</Text>}
                >
                  <Text>{`Only use this option if the patient's schedule is inflexible`}</Text>
                </Alert>
                <Stack spacing='sm'>
                  <DatePicker
                    disabled={slotsQuery.isLoading}
                    label='Select date'
                    placeholder='Select date'
                    excludeDate={excludeDateFunc}
                    {...form.getInputProps('specificDate')}
                  />
                  <Select
                    label='Select time'
                    data={timeSlots || []}
                    explanation={`Patient time zone: ${
                      patientTimezone ? dayjs().tz(patientTimezone).format('z') : 'Unknown'
                    }`}
                    {...form.getInputProps('specificTime')}
                  />
                </Stack>
              </Stack>
            )}
          </RadioGroup>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={onSubmit}
            disabled={
              createAppointmentMutation.isLoading ||
              createCalendarHoldMutation.isLoading ||
              updateCalendarHoldMutation.isLoading ||
              /*
               * Block holds from being created if the clinician is over capacity and the selected availabilityType
               * is 'anytime' or 'preferred'
               */
              (isOverCapacity &&
                arrayIncludes(['anytime', 'preferred'], form.values.availabilityType))
            }
            loading={
              createAppointmentMutation.isLoading ||
              createCalendarHoldMutation.isLoading ||
              updateCalendarHoldMutation.isLoading
            }
          >
            Confirm and hold visit
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const ScheduleVisitHoldDrawer = (props: ScheduleVisitHoldDrawerProps) => {
  return (
    <BetterDrawer position='right' size='sm' opened={props.opened} onClose={props.onClose}>
      <ScheduleVisitHoldDrawerContent {...props} />
    </BetterDrawer>
  )
}
