import { Radio, RadioGroup } from '@shared/components'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const DischargeVisitSection = ({ form }: { form: DischargeNoteForm }) => {
  return (
    <RadioGroup
      label='Discharge visit'
      spacing='sm'
      {...form.getInputProps('discharge_visit_status')}
    >
      <Radio label='Yes, scheduled and attended' value='scheduled_and_attended' />
      <Radio label='No, scheduled and not attended' value='scheduled_and_not_attended' />
      <Radio label='No, offered and patient declined' value='offered_and_declined' />
    </RadioGroup>
  )
}
