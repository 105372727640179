import {
  ArrowRightIcon,
  FeatherIcon,
  Group,
  SecondaryButton,
  Stack,
  Switch,
  Table,
  TertiaryButton,
  Text,
  Tooltip,
  TrashIcon,
  UnlockIcon,
  useMantineTheme,
} from '@shared/components'
import { DecoratedCaseReviewItem, hasRole } from '@shared/types'
import { name } from '@shared/utils'
import range from 'lodash/range'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { emrApi } from '../../../api'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { useAuth } from '../../../context/auth'
import { useFlags } from '../../../utils/hooks'
import { CASE_REVIEW_QUERY_PARAM_KEY } from '../../patient/visits/PVisitNote'
import CocmMins from '../patientPanel/CocmMins'
import { CaseReviewDrawer } from './CaseReviewDrawer'
import RemovePatientCaseReviewModal from './RemovePatientCaseReviewModal'

const caseReviewHeaders = [
  'Patient name',
  'Case review note',
  'Note status',
  'CCM',
  'Last case review',
  'Mins',
]

const numSkeletonRows = 10

const PATIENT_ID_QUERY_PARAM_KEY = 'patientId'
const CaseReviewContent = () => {
  const { revampedCaseReviewNotes } = useFlags()
  const { currentUser } = useAuth()
  const [selectedProviderID, setSelectedProviderID] = useState(
    hasRole(currentUser, 'sncm', 'ncm', 'ncm_tn') ? currentUser.oid : '',
  )
  const [patientToRemove, setPatientToRemove] = useState<DecoratedCaseReviewItem | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()
  // NOTE: The following code triggers if a clinician navigates to the case review tab from an action item
  const patientIdForCaseReview = searchParams.get(PATIENT_ID_QUERY_PARAM_KEY)

  // Adds the case review query param to the URL
  const setPatientIdForCaseReview = (patientId: string) => {
    setSearchParams(
      params => {
        if (params.has(PATIENT_ID_QUERY_PARAM_KEY)) {
          params.set(PATIENT_ID_QUERY_PARAM_KEY, patientId)
        } else {
          params.append(PATIENT_ID_QUERY_PARAM_KEY, patientId)
        }
        return params
      },
      { replace: true },
    )
  }

  // Removes the case review query param from the URL
  const resetPatientSearchParam = () => {
    setSearchParams(
      params => {
        if (params.has(PATIENT_ID_QUERY_PARAM_KEY)) {
          params.delete(PATIENT_ID_QUERY_PARAM_KEY)
        }
        return params
      },
      { replace: true },
    )
  }

  const onCloseRemovePatientModal = () => {
    setPatientToRemove(null)
  }

  const [caseReviewQueryKey, caseReviewQueryFunction] = emrApi.getQuery('GET /caseReview', {
    query: {
      employeeId: selectedProviderID,
      needsReview: 'true',
    },
  })

  const caseReviewPatientsQuery = useQuery(caseReviewQueryKey, caseReviewQueryFunction)

  const isLoading = caseReviewPatientsQuery.isLoading || caseReviewPatientsQuery.isFetching

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <>
      <CaseReviewDrawer onClose={resetPatientSearchParam} patientId={patientIdForCaseReview} />
      <RemovePatientCaseReviewModal
        patientToRemove={patientToRemove}
        onCloseRemovePatientModal={onCloseRemovePatientModal}
      />
      <Stack mx='lg' my='sm' px='0'>
        <Group position='right'>
          {hasRole(currentUser, 'sncm', 'ncm', 'ncm_tn') && (
            <>
              <Switch
                checked={selectedProviderID === ''}
                onChange={() => {
                  setSelectedProviderID(selectedProviderID =>
                    selectedProviderID ? '' : currentUser.oid,
                  )
                }}
              />
              <Text>Show full patient panel</Text>
            </>
          )}
        </Group>
        <Table
          striped
          sx={{
            tableLayout: 'fixed',
            backgroundColor: colors.text[3],
            borderWidth: sizes.border.md,
            borderStyle: 'solid',
            borderColor: colors.background[3],
          }}
        >
          <thead>
            <tr>
              {caseReviewHeaders.map(header => (
                <th colSpan={1} key={header}>
                  <Text size='xs' bold>
                    {header}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isLoading && caseReviewPatientsQuery.data?.length === 0 && (
              <NoDataRow message='No patient data found' headersLength={caseReviewHeaders.length} />
            )}
            {!isLoading &&
              caseReviewPatientsQuery.data?.map(item => {
                return (
                  <tr key={item.oid}>
                    <td>
                      <Text bold px={sizes.padding.sm}>
                        {name({ first: item.firstName, last: item.lastName }).lastCommaFirst()}
                      </Text>
                    </td>
                    <td>
                      {item?.mostRecentCaseReviewNote?.visitId && revampedCaseReviewNotes ? (
                        <TertiaryButton
                          component={Link}
                          to={`/patients/${item.patientId}/notes/${item.mostRecentCaseReviewNote.visitId}?${CASE_REVIEW_QUERY_PARAM_KEY}=true`}
                          // opens in new tab
                          target='_blank'
                          rightIcon={<ArrowRightIcon color={colors.actions[0]} />}
                        >
                          Case review note
                        </TertiaryButton>
                      ) : (
                        <TertiaryButton
                          onClick={() => setPatientIdForCaseReview(item.patientId)}
                          rightIcon={<ArrowRightIcon color={colors.actions[0]} />}
                        >
                          Case review note
                        </TertiaryButton>
                      )}
                    </td>
                    <td>
                      <Tooltip
                        position='top-start'
                        label={
                          item.mostRecentCaseReviewNote?.isLocked
                            ? 'Locked and signed, awaiting attestation'
                            : 'Unlocked'
                        }
                      >
                        <Group>
                          {item.mostRecentCaseReviewNote?.isLocked ? (
                            <FeatherIcon color={colors.actions[3]} />
                          ) : (
                            <UnlockIcon color={colors.actions[3]} />
                          )}
                        </Group>
                      </Tooltip>
                    </td>
                    <td>
                      <Text>{item.assignedTnName}</Text>
                    </td>
                    <td>
                      <Text>
                        {item.daysSinceLastReview
                          ? `${item.daysSinceLastReview} days`
                          : 'Not yet reviewed'}
                      </Text>
                    </td>
                    <td>
                      <Group position='apart' pr='sm'>
                        <CocmMins
                          treatmentTime={item.timeThisMonth ?? 0}
                          dateAdded={item.dateAdded}
                        />
                        <SecondaryButton
                          onClick={() => setPatientToRemove(item)}
                          leftIcon={<TrashIcon />}
                        />
                      </Group>
                    </td>
                  </tr>
                )
              })}
            {isLoading &&
              range(0, numSkeletonRows).map(i => (
                <LoadingRow key={i} headersLength={caseReviewHeaders.length} />
              ))}
          </tbody>
        </Table>
      </Stack>
    </>
  )
}

export default CaseReviewContent
