import { tryit } from 'radash'
import * as FullStory from './fullstory'

type SimpleLoggerMethod = (msg: string, ctx?: object) => void

type SimpleLogger = {
  log: SimpleLoggerMethod
  warn: SimpleLoggerMethod
  debug: SimpleLoggerMethod
  info: SimpleLoggerMethod
  error: SimpleLoggerMethod
}

const logMessage = (msg: string, ctx: object, level: FullStory.LogLevel) => {
  const safeStringify = tryit(JSON.stringify)
  const [error, stringifiedCtx] = safeStringify({ ...ctx, message: msg })
  FullStory.log(level, error ? msg : stringifiedCtx)
  // eslint-disable-next-line no-console
  console[level](msg, ctx)
}

export const logger: SimpleLogger = {
  log: (msg, ctx) => {
    logMessage(msg, { level: 'log', ...ctx }, 'log')
  },
  warn: (msg, ctx) => {
    logMessage(msg, { level: 'warning', ...ctx }, 'warn')
  },
  debug: (msg, ctx) => {
    logMessage(msg, { level: 'debug', ...ctx }, 'debug')
  },
  info: (msg, ctx) => {
    logMessage(msg, { level: 'info', ...ctx }, 'info')
  },
  error: (msg, ctx) => {
    logMessage(msg, { level: 'error', ...ctx }, 'error')
  },
}
