import {
  Center,
  ClockIcon,
  DownloadIcon,
  EyeIcon,
  Group,
  Menu,
  Modal,
  MoreVerticalIcon,
  SecondaryButton,
  Table,
  Text,
  Th,
  Tooltip,
  TrashCanIcon,
  useMantineTheme,
} from '@shared/components'
import { EMRTaskDecorated, PatientFile, hasRole, isAdmin, isLeadCoordinator } from '@shared/types'
import { IANAZone, dayjs } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi, patientsApi } from '../../../../api'
import NoDataRow from '../../../../components/atoms/NoDataRow'
import { Photo } from '../../../../components/forms/PreviewPhoto'
import { useAuth } from '../../../../context/auth'
import { downloadFileFromUrl } from '../../../patient/files/utils'

export type AttachmentsTableProps = {
  task: EMRTaskDecorated
  files: (PatientFile & { metadata: { employeeName: string } })[]
}

export const AttachmentsTable = ({ task, files }: AttachmentsTableProps) => {
  const { currentUser } = useAuth()
  const { patientId } = task

  const canDeleteFile =
    isAdmin(currentUser) ||
    isLeadCoordinator(currentUser) ||
    hasRole(currentUser, 'stn', 'spc', 'sncm')

  const queryClient = useQueryClient()
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const [filesQueryKey] = emrApi.getQuery('GET /tasks/v2/:taskId/files', {
    params: {
      taskId: task.oid,
    },
  })

  const previewFile = useMutation(patientsApi.getFile)
  const downloadFile = useMutation(patientsApi.getFile, {
    onSuccess: file => {
      downloadFileFromUrl(file)
      downloadFile.reset()
    },
  })
  const deleteFile = useMutation(patientsApi.deleteFile, {
    onSuccess: () => {
      void queryClient.invalidateQueries(filesQueryKey)
    },
  })

  return (
    <>
      <Modal opened={Boolean(previewFile.data)} onClose={previewFile.reset}>
        {task.patientId ? (
          <Photo
            label={previewFile.data?.name || ''}
            value={previewFile.variables?.fileId}
            patientId={task.patientId}
            ratio={1}
          />
        ) : (
          <Text>File uploads on EMR tech issues not supported yet.</Text>
        )}
      </Modal>
      <Table
        striped
        withBorder
        verticalSpacing='sm'
        sx={{
          tableLayout: 'auto',
          backgroundColor: colors.background[0],
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          borderColor: colors.background[3],
        }}
      >
        <thead className='mantine'>
          <tr className='mantine'>
            <Th sortable={false} key='uploadedOn' style={{ width: `14ch` }}>
              Uploaded on
            </Th>
            <Th sortable={false} key='uploadedBy'>
              Uploaded by
            </Th>
            <Th sortable={false} key='fileName'>
              File name
            </Th>
            <Th sortable={false} key='actions' style={{ width: `7ch` }}>
              Action
            </Th>
          </tr>
        </thead>
        <tbody>
          {files.length === 0 && <NoDataRow message='No files' headersLength={4} />}
          {files.length > 0 &&
            files.map(file => (
              <tr key={file.oid}>
                <td>
                  <Group spacing={sizes.gap.sm} noWrap>
                    <Text>{dayjs(file.createdAt).format('MM/DD/YYYY')}</Text>
                    <Tooltip
                      label={
                        <Text bold size='xs' color='white' p='xs'>
                          {dayjs(file.createdAt).tz(IANAZone.Eastern).format('h:mma z')}
                        </Text>
                      }
                    >
                      <ClockIcon color={colors.actions[0]} />
                    </Tooltip>
                  </Group>
                </td>
                <td>
                  <Text>{file.metadata.employeeName}</Text>
                </td>
                <td>
                  <Text>{file.name}</Text>
                </td>
                <td>
                  {patientId ? (
                    <Menu position='bottom-end'>
                      <Center>
                        <Menu.Target>
                          <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
                        </Menu.Target>
                      </Center>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() =>
                            previewFile.mutate({
                              patientId,
                              fileId: file.oid,
                            })
                          }
                          icon={<EyeIcon color={colors => colors.actions[0]} />}
                        >
                          Preview
                        </Menu.Item>

                        <Menu.Item
                          onClick={() =>
                            downloadFile.mutate({
                              patientId,
                              fileId: file.oid,
                            })
                          }
                          icon={<DownloadIcon color={colors => colors.actions[0]} />}
                        >
                          Download
                        </Menu.Item>
                        {canDeleteFile && (
                          <Menu.Item
                            onClick={() => deleteFile.mutate({ patientId, fileId: file.oid })}
                            icon={<TrashCanIcon color={colors => colors.actions[0]} />}
                          >
                            Remove
                          </Menu.Item>
                        )}
                      </Menu.Dropdown>
                    </Menu>
                  ) : (
                    <Text>File uploads on EMR tech issues not supported yet.</Text>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  )
}
