import { Box, px, useMantineTheme } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import {
  CartesianGrid as RechartsCartesianGrid,
  Line as RechartsLine,
  LineChart as RechartsLineChart,
  ReferenceLine as RechartsReferenceLine,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
} from 'recharts'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import ActiveDot from './ActiveDot'
import Dot from './Dot'
import PrimaryTooltip from './PrimaryTooltip'
import { formatData, formatDate, generateXAxisDates, generateYAxisValues } from './chartUtils'

type ObjectiveMeasuresChartProps = {
  data: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  isLoading: boolean
  goodResultThreshold: number
  badResultThreshold: number
  maxChartValue: number
  higherResultIsBetter: boolean
  numMonths: number
  setDrawerContentIndex: React.Dispatch<React.SetStateAction<number | null>>
  title: ObjectiveMeasuresTitle
}

const ObjectiveMeasuresChart = ({
  data,
  isLoading,
  goodResultThreshold,
  badResultThreshold,
  maxChartValue,
  higherResultIsBetter,
  numMonths,
  setDrawerContentIndex,
  title,
}: ObjectiveMeasuresChartProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const formattedData = formatData(data)

  const xAxisDates = generateXAxisDates(numMonths)

  const yAxisValues = generateYAxisValues(maxChartValue, title === 'ASQ' ? 1 : 3)

  return (
    <Box sx={{ height: 300 }}>
      {isLoading ? (
        <ALoadingSpinner />
      ) : (
        <RechartsResponsiveContainer>
          {/* NOTE: the following margins make the chart fit correctly in the box */}
          <RechartsLineChart data={formattedData} margin={{ left: -20, right: 10 }}>
            <RechartsCartesianGrid
              stroke={colors.background[2]}
              vertical={false}
              strokeWidth={sizes.outline.md}
            />
            <RechartsYAxis
              ticks={yAxisValues}
              domain={[0, maxChartValue]}
              tickLine={false}
              style={{ color: colors.text[1], fontSize: 12 }}
            />
            <RechartsXAxis
              dataKey='unixTimestamp'
              ticks={xAxisDates}
              tickFormatter={formatDate}
              type='number'
              domain={[xAxisDates[0]!, xAxisDates[xAxisDates.length - 1]!]}
              padding={{ left: 30, right: 30 }}
              tickLine={false}
              style={{ color: colors.text[1], fontSize: 12 }}
            />
            <RechartsReferenceLine
              y={goodResultThreshold}
              stroke={colors.background[4]}
              strokeWidth={sizes.outline.md}
              strokeDasharray='8 8'
            />
            <RechartsTooltip
              content={<PrimaryTooltip colors={colors} title={title} />}
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
            />
            <RechartsLine
              type='linear'
              dataKey='compositeScore'
              stroke={colors.background[4]}
              dot={
                <Dot
                  goodResultThreshold={goodResultThreshold}
                  badResultThreshold={badResultThreshold}
                  higherResultIsBetter={higherResultIsBetter}
                  colors={colors}
                  strokeWidth={px(sizes.outline.lg)}
                  data={data}
                  title={title}
                  iconSize={px(sizes.icon.sm)}
                />
              }
              activeDot={
                <ActiveDot
                  goodResultThreshold={goodResultThreshold}
                  badResultThreshold={badResultThreshold}
                  higherResultIsBetter={higherResultIsBetter}
                  colors={colors}
                  internalStrokeWidth={px(sizes.outline.lg)}
                  externalStrokeWidth={px(sizes.outline.sm)}
                  onDotClick={setDrawerContentIndex}
                />
              }
              strokeWidth={sizes.outline.md}
            />
          </RechartsLineChart>
        </RechartsResponsiveContainer>
      )}
    </Box>
  )
}

export default ObjectiveMeasuresChart
