import {
  Box,
  ChevronRightIcon,
  Drawer,
  Group,
  LoadingOverlay,
  PrimaryButton,
} from '@shared/components'
import { DoseSpotPharmacy, Patient } from '@shared/types'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi, pharmaciesApi } from '../../../api'
import MEditPharmacyForm from './MEditPharmacyForm'

export type OPharmacyModalProps = {
  patient: Patient
  closeModal: () => void
  opened: boolean
}

const OPharmacyModal = ({ patient, closeModal, opened }: OPharmacyModalProps) => {
  const queryClient = useQueryClient()
  const setDosespotPharmacy = Boolean(patient.doseSpotId)
  const [selectedPharmacy, setSelectedPharmacy] = useState<DoseSpotPharmacy>()

  const updateDefaultPharmacy = useMutation(pharmaciesApi.update, {
    onSuccess: () => {
      void queryClient.invalidateQueries('pharmaciesApi.retrieve')
      closeModal()
    },
  })

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'), {
    onSuccess: () => {
      closeModal()
      window.location.reload()
    },
  })

  const saveButtonOnClick = () => {
    if (!selectedPharmacy) {
      return
    }
    if (setDosespotPharmacy) {
      updateDefaultPharmacy.mutate({
        patientId: patient.oid,
        pharmacyId: selectedPharmacy.PharmacyId,
      })
    } else {
      updatePatient.mutate({
        params: { patientId: patient.oid },
        data: {
          pharmacy: {
            name: selectedPharmacy.StoreName,
            city: selectedPharmacy.City,
            address: `${selectedPharmacy.Address1} ${selectedPharmacy.Address2}`,
            state: selectedPharmacy.State,
            zip: selectedPharmacy.ZipCode,
            phone: selectedPharmacy.PrimaryPhone,
            pharmacyId: selectedPharmacy.PharmacyId,
          },
        },
      })
    }
  }

  return (
    <Drawer
      position='right'
      size='lg'
      title='Update pharmacy'
      opened={opened}
      onClose={closeModal}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={saveButtonOnClick} rightIcon={<ChevronRightIcon />}>
            Save pharmacy
          </PrimaryButton>
        </Group>
      }
    >
      <LoadingOverlay visible={updatePatient.isLoading || updateDefaultPharmacy.isLoading} />
      <Box
        sx={theme => ({
          padding: theme.other.sizes.gap.xl,
          overflowY: 'auto',
        })}
      >
        <MEditPharmacyForm
          title="Set the patient's preferred pharmacy"
          patientId={patient.oid}
          selectedPharmacy={selectedPharmacy}
          onSelectPharmacy={pharmacy => {
            setSelectedPharmacy(pharmacy)
          }}
          alwaysSetDefault
        />
      </Box>
    </Drawer>
  )
}

export default OPharmacyModal
