import { useToggle } from '@mantine/hooks'
import { Group, PlusIcon, PrimaryButton, Stack } from '@shared/components'
import { useEffect, useState } from 'react'
import SearchBar from '../care_team/irq/SearchBar'
import { SmartPhraseTable } from '../patient/visits/smartPhrases/SmartPhraseTable'
import { SmartPhraseAddOrEditDrawer } from './SmartPhraseAddOrEditDrawer'
import { useSmartPhrases } from './use-smart-phrases'

// The following object matches the data that is returned from the backend
export type SmartPhrase = {
  oid: string
  name: string
  categoryName: string | null
  updatedAt: string
  text: string
}

export const searchSmartPhrases = ({
  smartPhrases,
  query,
}: {
  smartPhrases: SmartPhrase[]
  query: string
}) => {
  return smartPhrases.filter(
    // Filter by name, category name, or text
    smartPhrase =>
      smartPhrase?.name.toLowerCase().includes(query.toLowerCase()) ||
      // category name can be null, so only filter by category name if it exists
      smartPhrase?.categoryName?.toLowerCase().includes(query.toLowerCase()) ||
      smartPhrase?.text.toLowerCase().includes(query.toLowerCase()),
  )
}

export const MEmployeeSmartPhrasesTab = () => {
  const [isDisplayingAddOrEditDrawer, toggleAddOrEditDrawer] = useToggle()
  const [filteredSmartPhrases, setFilteredSmartPhrases] = useState<SmartPhrase[]>([])

  const { smartPhrases, isLoading: isLoadingSmartPhrases } = useSmartPhrases()
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    setFilteredSmartPhrases(searchSmartPhrases({ smartPhrases, query: searchValue }))
  }, [smartPhrases, searchValue])

  return (
    <Stack>
      <SmartPhraseAddOrEditDrawer
        opened={isDisplayingAddOrEditDrawer}
        onClose={() => toggleAddOrEditDrawer(false)}
      />
      <Group position='apart'>
        <SearchBar
          placeholder='Search all smart phrases'
          onChange={setSearchValue}
          value={searchValue}
          disabled={isLoadingSmartPhrases || smartPhrases.length === 0}
          onClear={() => {
            setSearchValue('')
          }}
        />
        <PrimaryButton leftIcon={<PlusIcon />} onClick={() => toggleAddOrEditDrawer(true)}>
          Smart phrase
        </PrimaryButton>
      </Group>
      <SmartPhraseTable smartPhrases={filteredSmartPhrases} variation='editor' />
    </Stack>
  )
}
