import { VisitNote } from '@shared/types'
import React from 'react'
import { useQuery } from 'react-query'
import { visitNotesApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'

const VersionHistoryModal = ({ patientID, visitID }: { patientID: string; visitID: string }) => {
  const versionHistory = useQuery(['visitNotesApi.getVersionHistory', patientID, visitID], () =>
    visitNotesApi.getVersionHistory(patientID, visitID),
  )

  const sortedHistory = versionHistory.data?.sort((a: VisitNote, b: VisitNote) => {
    if (a.createdAt < b.createdAt) {
      return 1
    }
    if (a.createdAt > b.createdAt) {
      return -1
    }
    return 0
  })

  return (
    <div className='w-full flex flex-col '>
      {versionHistory.isLoading && <ALoadingSpinner />}
      {versionHistory.data && (
        <>
          <div className='pb-4'>
            <span className='font-semibold text-xl pb-4'>Visit Note Change History</span>{' '}
            <span className='text-sm'>(sorted by timestamp, most recent first)</span>
          </div>
          {sortedHistory?.map((note: VisitNote) => (
            <div key={note.createdAt} className='w-full flex flex-col py-2 border-b-2'>
              <span className='pb-2 font-semibold'>{note.createdAt}</span>
              <span className='w-full'>
                <pre>{JSON.stringify(note, null, '\t')}</pre>
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default VersionHistoryModal
