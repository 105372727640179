import { PrimaryButton, TextInput } from '@shared/components'
import { usePatientVisitNote } from './PatientVisitNoteContext'

export const FollowUpVisitNote = () => {
  const { saveVisitNote } = usePatientVisitNote()

  return (
    <>
      <TextInput />
      <PrimaryButton onClick={() => saveVisitNote?.()}>Submit</PrimaryButton>
    </>
  )
}
