import { HiInformationCircle } from '@react-icons/all-files/hi/HiInformationCircle'
import { Tooltip } from '@shared/components'
import React from 'react'
import { FieldError, FieldValues, NestDataObject } from 'react-hook-form'

export type ANoteDropdownProps = {
  label: string
  id?: string
  name: string
  options: Record<string, string> | string[]
  disabled?: boolean
  naValue?: boolean
  naValueLabel?: string
  error?: NestDataObject<FieldValues, FieldError>
  className?: string
  defaultValue?: string
  info?: string
}

const ANoteDropdown = React.forwardRef<HTMLSelectElement, ANoteDropdownProps>(
  (
    {
      label,
      id,
      name,
      options,
      disabled = false,
      naValue = false,
      naValueLabel,
      error,
      className = '',
      defaultValue,
      info = '',
      ...props
    },
    ref,
  ) => {
    let stateSpecificClassNames = ''
    if (error?.[name]) {
      stateSpecificClassNames = 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
    }

    const path =
      'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'

    return (
      <div className={`${className ?? ''} col-span-3`}>
        <div className='flex justify-between'>
          <label
            htmlFor={name}
            className={`block text-sm font-medium leading-5 ${
              disabled ? 'text-gray-400' : 'text-gray-700'
            }`}
          >
            {label}
          </label>
          {info && (
            <Tooltip label={info} position='top'>
              <div className='mt-auto'>
                <HiInformationCircle />
              </div>
            </Tooltip>
          )}
        </div>
        <div className='mt-1'>
          <select
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            className={`block w-full pl-3 pr-10 py-2 text-base border-daybreak-actions0 focus:outline-none focus:ring-daybreak-actions1 focus:border-daybreak-actions1 sm:text-sm rounded-md ${
              stateSpecificClassNames ?? ''
            } ${disabled ? 'text-gray-400' : ''}`}
            {...props}
          >
            {naValue && (
              <option value=''>{naValueLabel === undefined ? 'N/A' : naValueLabel}</option>
            )}
            {Array.isArray(options)
              ? options.map(option => (
                  <option key={String(option)} value={String(option)}>
                    {option}
                  </option>
                ))
              : Object.entries(options).map(([label, value]) => (
                  <option key={String(value)} value={String(value)}>
                    {label}
                  </option>
                ))}
          </select>
          {error?.[name] && (
            <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
              <svg viewBox='0 0 20 20' className='mt-px w-4 h-4 mr-1 flex-shrink-0 fill-current'>
                <path fillRule='evenodd' d={path} clipRule='evenodd'></path>
              </svg>
              {error[name].message}
            </p>
          )}
        </div>
      </div>
    )
  },
)

ANoteDropdown.displayName = 'ANoteDropdown'

export default ANoteDropdown
