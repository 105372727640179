import { Group, Text, useMantineTheme } from '@shared/components'

type NoDataRowProps = {
  message: string
  headersLength: number
}

const NoDataRow = ({ message, headersLength }: NoDataRowProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <tr>
      <td
        colSpan={headersLength}
        style={{
          backgroundColor: colors.text[3],
        }}
      >
        <Group spacing='sm' position='center'>
          <Text>{message}</Text>
        </Group>
      </td>
    </tr>
  )
}

export default NoDataRow
