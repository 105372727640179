import { BaseApi } from '@shared/utils'

export class FormsApi extends BaseApi {
  getForms = (patientId: string, query: { formType: string }) => {
    // TODO: Add type here when we type forms
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.json<Record<string, any>[]>({
      method: 'GET',
      url: `/patient/${patientId}/forms`,
      params: query,
    })
  }
}
