import { Alert, BookmarkIcon, InputWrapper, Text } from '@shared/components'
import { OnboardingVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextarea } from '../visitNoteForm/PatientVisitNoteTextarea'

export const CareTransferReasonForWelcomeCallSection = ({
  form,
  isCareTransfer,
}: {
  form: OnboardingVisitNoteForm
  isCareTransfer?: boolean
}) => {
  return (
    <PatientVisitNoteSection title='Reason for welcome call'>
      {isCareTransfer && (
        <Alert icon={<BookmarkIcon />} variant='primary'>
          <Text color={colors => colors.text[3]}>Care transfer patient opted for Welcome call</Text>
        </Alert>
      )}
      <InputWrapper label='What concerns did the patient have?'>
        <PatientVisitNoteTextarea
          placeholder='Reason for welcome call'
          {...form?.getInputProps('care_transfer_reason_for_welcome_call')}
        />
      </InputWrapper>
    </PatientVisitNoteSection>
  )
}
