import { Box, Divider, Group, Pill, SecondaryButton, Stack, TitleFour } from '@shared/components'
import IconShipment from '../../../components/icons/IconShipment'

export type LabOrderEmptyStateProps = {
  onClick: () => void
}

const LabOrderEmptyState = ({ onClick }: LabOrderEmptyStateProps) => {
  return (
    <Stack>
      <Group position='left'>
        <TitleFour>UDS Cup Orders</TitleFour>
      </Group>
      <Divider />
      <Box
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
        })}
        p='lg'
      >
        <Stack align='center'>
          <Pill variant='filled' status='none'>
            No UDS cups ordered yet
          </Pill>
          <SecondaryButton onClick={onClick} leftIcon={<IconShipment />}>
            Order UDS cup
          </SecondaryButton>
        </Stack>
      </Box>
    </Stack>
  )
}

export default LabOrderEmptyState
