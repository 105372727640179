import { ChevronDownIcon, Menu, ScrollAreaAutosize, TertiaryButton } from '@shared/components'
import { isClinician, isCoordinator } from '@shared/types'
import { useEmployees } from '../../utils/hooks'

export type CalendarSelectProps = {
  calendarId?: string
  setCalendarId: (calendarId: string) => void
}

export const CalendarSelect = ({ calendarId, setCalendarId }: CalendarSelectProps) => {
  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const employees = employeesQuery.data

  const eligibleEmployees = (employees || []).filter(employee => Boolean(employee.calendarId))
  const medicalProviders = eligibleEmployees.filter(isClinician)
  const nonMedicalProviders = eligibleEmployees.filter(isCoordinator)

  const selectedProviderName = calendarId
    ? employees
        ?.filter(employee => employee.calendarId)
        .find(employee => employee.calendarId.toString() === calendarId)?.name || ''
    : 'All clinicians'

  return (
    <Menu>
      <Menu.Target>
        <TertiaryButton rightIcon={<ChevronDownIcon />}>{selectedProviderName}</TertiaryButton>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollAreaAutosize maxHeight={256}>
          <Menu.Item onClick={() => setCalendarId('all')} key='all'>
            View all
          </Menu.Item>

          <Menu.Label>Medical personnel</Menu.Label>
          {medicalProviders.map(employee => {
            return (
              <Menu.Item
                onClick={() => setCalendarId(employee.calendarId.toString())}
                key={employee.oid}
              >
                {employee.name}
              </Menu.Item>
            )
          })}

          <Menu.Label>Non-medical personnel</Menu.Label>
          {nonMedicalProviders.map(employee => {
            return (
              <Menu.Item
                onClick={() => setCalendarId(employee.calendarId.toString())}
                key={employee.oid}
              >
                {employee.name}
              </Menu.Item>
            )
          })}
        </ScrollAreaAutosize>
      </Menu.Dropdown>
    </Menu>
  )
}
