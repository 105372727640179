import { RadioGroup } from '@shared/components'
import { ReenrollmentCallVisitNoteForm } from '../ReenrollmentCallVisitNote'
import { PatientVisitIssueButton } from '../visitNoteForm/PatientVisitIssueButton'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteRadio } from '../visitNoteForm/PatientVisitNoteRadio'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const BridgePrescriptionSection = ({ form }: { form: ReenrollmentCallVisitNoteForm }) => {
  return (
    <PatientVisitNoteSection title='Bridge prescription'>
      <RadioGroup
        label='Is the patient eligible for a bridge prescription?'
        orientation='horizontal'
        {...form?.getInputProps('bridge_prescription_eligibility')}
      >
        <PatientVisitNoteRadio value='yes' label='Yes' />
        <PatientVisitNoteRadio value='no' label='No' />
      </RadioGroup>
      <PatientVisitNoteTextInput
        placeholder='Additional details...'
        {...form?.getInputProps('bridge_prescription_eligibility_additional_details')}
      />
      <PatientVisitNoteNotDiscussedCheckbox field='bridge_prescription_eligibility' form={form} />
      <PatientVisitIssueButton issueName='Bridge prescription' issueType='bridge_prescription' />
    </PatientVisitNoteSection>
  )
}
