import { List, ListItem, Stack, Text } from '@shared/components'
import { DischargeNoteModel, dischargeNoteMapping } from '@shared/types'

export const ReferralSection = ({ dischargeNote }: { dischargeNote: DischargeNoteModel }) => {
  const referralOptions: (keyof DischargeNoteModel)[] = [
    'referral_request_inpatient_program',
    'referral_request_residential_program',
    'referral_request_intensive_outpatient_program',
    'referral_request_methadone_program',
    'referral_request_mental_health_psychiatric',
    'referral_request_pcp',
    'referral_request_buprenorphine_clinic',
  ]

  const selectedReferralOptions = referralOptions.filter(option => {
    return Boolean(dischargeNote[option])
  })

  function referralText() {
    if (dischargeNote.referral_request === 'yes') {
      return (
        <>
          <Text>Yes</Text>
          <List spacing='xs' listStyleType='disc'>
            {selectedReferralOptions.map(referralOption => (
              <ListItem key={referralOption}>{dischargeNoteMapping[referralOption]}</ListItem>
            ))}
            {dischargeNote.referral_request_other && (
              <ListItem key='referral_request_other'>
                {dischargeNote.referral_request_additional_details
                  ? `Other: ${dischargeNote.referral_request_additional_details}`
                  : 'Other'}
              </ListItem>
            )}
          </List>
        </>
      )
    }

    if (dischargeNote.no_referral_request_explanation) {
      return <Text>{`No: ${dischargeNote.no_referral_request_explanation}`}</Text>
    }
    return <Text>No</Text>
  }

  return ['yes', 'no'].includes(dischargeNote.referral_request) ? (
    <Stack spacing='xs'>
      <Text bold>Patient interested in referrals?</Text>
      {referralText()}
      {dischargeNote.referral_request_urgent && (
        <>
          <Text bold>Is the referral request urgent?</Text>
          <Text>{dischargeNote.referral_request_urgent === 'yes' ? 'Yes' : 'No'}</Text>
        </>
      )}
    </Stack>
  ) : null
}
