import {
  FilePlusIcon,
  Group,
  Menu,
  MoreVerticalIcon,
  SecondaryButton,
  Stack,
  Text,
} from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import { SendMbcButton } from './SendMbcButton'
import { useOrderedMbcFormsToSend } from './use-ordered-mbc-forms-to-send'

type AdministerMbcSectionProps = {
  patientId: string
  addMeasureOnClick?: (measure: ObjectiveMeasuresTitle) => void
  completedMeasures?: ObjectiveMeasuresTitle[]
}

export const AdministerMbcSection = ({
  patientId,
  addMeasureOnClick,
  completedMeasures,
}: AdministerMbcSectionProps) => {
  const formsToSend = useOrderedMbcFormsToSend()
  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]}>
        Administer an objective measure
      </Text>
      <Group noWrap>
        {formsToSend.map(formToSend => {
          return (
            <SendMbcButton key={formToSend.form} patientId={patientId} formToSend={formToSend} />
          )
        })}
        {addMeasureOnClick && (
          <Menu>
            <Menu.Target>
              <SecondaryButton size='sm' leftIcon={<MoreVerticalIcon />} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => addMeasureOnClick('PHQ-8')} icon={<FilePlusIcon />}>
                {`${completedMeasures?.includes('PHQ-8') ? 'Edit' : 'Administer'} PHQ-8`}
              </Menu.Item>
              <Menu.Item onClick={() => addMeasureOnClick('GAD-7')} icon={<FilePlusIcon />}>
                {`${completedMeasures?.includes('GAD-7') ? 'Edit' : 'Administer'} GAD-7`}
              </Menu.Item>
              <Menu.Item onClick={() => addMeasureOnClick('BARC-10')} icon={<FilePlusIcon />}>
                {`${completedMeasures?.includes('BARC-10') ? 'Edit' : 'Administer'} BARC-10`}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
    </Stack>
  )
}
