import { Tooltip } from '@shared/components'
import React from 'react'

export type ODSIconButtonProps = {
  icon?: JSX.Element
  onClick: React.MouseEventHandler<HTMLButtonElement>
  tooltipText?: string
  className?: string
  disabled?: boolean
}

const ODSIconButton = ({
  icon,
  onClick,
  tooltipText,
  className = '',
  disabled = false,
}: ODSIconButtonProps) => {
  return (
    <Tooltip label={tooltipText} position='bottom' offset={5} disabled={false}>
      <button
        type='button'
        className={`${className} inline-flex items-center px-3 py-2 border border-daybreak-actions0 text-sm leading-5 font-medium rounded-md text-daybreak-text3 bg-white focus:outline-none active:ring-daybreak-actions1 hover:border-daybreak-actions1 active:ring-2 active:ring-offset-2`}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </button>
    </Tooltip>
  )
}

export default ODSIconButton
