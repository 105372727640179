import { Stack } from '@shared/components'
import { useEffect } from 'react'
import { analytics } from '../../../analytics'
import { useAuth } from '../../../context/auth'
import { usePatient } from '../PPatientContext'
import { PatientFileDirectory } from './PatientFileDirectory'
import { ReleaseOfInformationTable } from './releaseOfInformation'

export const PatientFilesContent = () => {
  const { currentUser } = useAuth()

  const { patientID } = usePatient()

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!patientID || !currentUser) {
      return
    }

    analytics.page({
      name: 'Files',
      properties: {
        patientId: patientID,
        userId: currentUser.oid,
        userName: currentUser.name,
      },
    })
  }, [patientID, currentUser])

  return (
    <Stack p='md'>
      <PatientFileDirectory />
      <ReleaseOfInformationTable />
    </Stack>
  )
}

export const PFiles = () => {
  return <PatientFilesContent />
}
