import { EMRTaskDecorated, getOpheliaHttpError } from '@shared/types'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { NoteForm, NoteFormProps } from './NoteForm'
import { TimeSpentForm, useTimeSpentForm } from './TimeSpentForm'

export type ReopenTaskModalProps = {
  task: EMRTaskDecorated
  onClose: () => void
}

export const ReopenTaskModal = ({ task, onClose }: ReopenTaskModalProps) => {
  const [step, setStep] = useState<'timeSpent' | 'note'>('timeSpent')

  const timeSpentForm = useTimeSpentForm()

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))

  const reopenTask = (noteForm: NoteFormProps) => {
    updateTaskMutation.mutate(
      {
        params: {
          taskId: task.oid,
        },
        data: {
          task: { status: 'open', employeeId: noteForm.assignee ?? '' },
          note: noteForm.note ? { text: noteForm.note } : undefined,
          timeEntry: {
            isBillableCoCM: Boolean(timeSpentForm.values.isBillableCoCM),
            minutes: Number(timeSpentForm.values.minutes),
          },
        },
      },
      {
        onSuccess: onClose,
      },
    )
  }

  if (step === 'timeSpent') {
    return (
      <TimeSpentForm
        task={task}
        title='Reopen issue'
        step={{ current: 1, total: 2 }}
        onClose={onClose}
        next={() => setStep('note')}
        form={timeSpentForm}
      />
    )
  }

  if (step === 'note') {
    return (
      <NoteForm
        onBack={() => setStep('timeSpent')}
        step={{ current: 2, total: 2 }}
        initialValues={{ assignee: task.employeeId ?? '', note: '' }}
        onClose={onClose}
        isSubmitting={updateTaskMutation.isLoading}
        onSubmit={reopenTask}
        currentStatus={task.status}
        error={getOpheliaHttpError(
          updateTaskMutation.error,
          'Something went wrong, try again later.',
        )}
      />
    )
  }

  return null
}
