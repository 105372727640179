import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  Alert,
  DatePicker,
  Group,
  InfoIcon,
  PrimaryButton,
  Radio,
  RadioGroup,
  ScrollArea,
  Select,
  Stack,
  TextInput,
  TimeInput,
  showNotification,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { CallOutReason } from '@shared/types'
import { dayjs } from '@shared/utils'
import { isRequired } from '../../utils/formValidation'
import { useLunaMutation } from '../../utils/hooks'
import { OutOfOfficeModal } from './OutOfOfficeModal'

const getDateWithTime = ({
  date,
  time,
  isAllDay,
}: {
  date: Date
  time: string
  isAllDay: boolean
}) => {
  if (isAllDay) {
    return dayjs(date).startOf('day')
  }

  return dayjs(date)
    .set('hour', Number(time.split(':')[0]))
    .set('minute', Number(time.split(':')[1]))
}

export type OutOfOfficeFormProps = {
  calendarId: string
  onClose: () => void
}

export type OutOfOfficeFormValues = {
  type: CallOutReason | ''
  reason: string
  startDate: Date
  startTime: `${number}:${number}` | ''
  endDate: Date
  endTime: `${number}:${number}` | ''
  allDay: boolean
  couldBeOutLonger: 'yes' | 'no' | ''
}

export const OutOfOfficeForm = (props: OutOfOfficeFormProps) => {
  const [isConfirmationModalOpen, toggleConfirmationModal] = useToggle()
  const form = useForm<OutOfOfficeFormValues>({
    initialValues: {
      // NOTE: Default to 'emergency', since we are only allowing clinicians to call out for emergencies for now
      type: 'emergency',
      reason: '',
      startDate: dayjs().toDate(),
      startTime: '',
      endDate: dayjs().toDate(),
      endTime: '',
      allDay: false,
      couldBeOutLonger: '',
    },
    validate: {
      type: isRequired,
      startDate: isRequired,
      startTime: (value, values) => {
        if (values.allDay) {
          return
        }

        const startDate = getDateWithTime({
          date: values.startDate,
          time: value,
          isAllDay: values.allDay,
        })

        if (startDate.isBefore(dayjs())) {
          return 'Start time cannot be in the past'
        }
      },
      endDate: (value, values) => {
        if (dayjs(value).isBefore(dayjs(values?.startDate), 'day')) {
          return 'End date can not be before start date'
        }
      },
      endTime: (value, values) => {
        if (values.allDay) {
          return
        }

        const startDate = getDateWithTime({
          date: values.startDate,
          time: values.startTime,
          isAllDay: values.allDay,
        })

        const endDate = getDateWithTime({
          date: values.endDate,
          time: value,
          isAllDay: values.allDay,
        })

        if (endDate.isBefore(startDate)) {
          return 'End time can not be before start time'
        }

        if (endDate.isBefore(dayjs())) {
          return 'End time can not be in the past'
        }
      },
      reason: validateWith(skipIfOtherField('type', 'not', 'emergency'), isRequired),
      couldBeOutLonger: validateWith(skipIfOtherField('type', 'not', 'emergency'), isRequired),
    },
  })

  const callOutMutation = useLunaMutation('POST /scheduling/call-out')

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    const startDate = getDateWithTime({
      date: form.values.startDate,
      time: form.values.startTime,
      isAllDay: form.values.allDay,
    })

    const endDate = getDateWithTime({
      date: form.values.endDate,
      time: form.values.endTime,
      isAllDay: form.values.allDay,
    })

    // couldBeOutLonger needs to be sent to the backend as a string
    const couldBeOutLonger =
      form.values.type === 'emergency' && form.values.couldBeOutLonger
        ? form.values.couldBeOutLonger
        : 'no'

    callOutMutation.mutate(
      {
        data: {
          calendarId: props.calendarId,
          reason: form.values.type as CallOutReason,
          outFrom: startDate.toISOString(),
          outTo: endDate.toISOString(),
          couldBeOutLonger,
          reasonFreeText: form.values.type === 'emergency' ? form.values.reason : '',
        },
      },
      {
        onSuccess: () => {
          showNotification({
            title: `Emergency call-out block was successfully added on ${dayjs(startDate).format(
              'MM/DD/YYYY',
            )}`,
            message:
              'Please add your request to Sequoia. It may take a few minutes for your visits to be automatically rescheduled.',
            variant: 'success',
          })
          return props.onClose()
        },
      },
    )

    toggleConfirmationModal(false)
  }

  return (
    <>
      <OutOfOfficeModal
        opened={isConfirmationModalOpen}
        onClose={() => toggleConfirmationModal(false)}
        onSubmit={onSubmit}
        isLoading={callOutMutation.isLoading}
        calendarId={props.calendarId}
        formValues={form.values}
      />
      <Stack justify='space-between' w='100%' h='100%' sx={{ minHeight: '0px' }}>
        <Stack sx={{ overflow: 'hidden' }}>
          <ScrollArea>
            <Stack px='md' pb='md'>
              {/* NOTE: eventually, we will allow clinicians to use this form to call out for PTO. For now,
            we are only allowing clinicians to use this form for emergency call outs */}
              <Select
                data={[{ label: 'Emergency call-out', value: 'emergency' }]}
                label='Type'
                placeholder='Select type'
                disabled
                {...form.getInputProps('type')}
              />
              {form.values.type === 'emergency' && (
                <TextInput
                  label='Reason'
                  placeholder='Describe in a brief sentence'
                  {...form.getInputProps('reason')}
                />
              )}
              <DatePicker
                label='Start date'
                placeholder='MM/DD/YYYY'
                minDate={dayjs().toDate()}
                {...form.getInputProps('startDate')}
              />
              {!form.values.allDay && (
                <TimeInput
                  label='Start time'
                  placeholder='HH:MM'
                  {...form.getInputProps('startTime')}
                />
              )}
              <DatePicker
                label='End date'
                placeholder='MM/DD/YYYY'
                minDate={form.values.startDate || dayjs().toDate()}
                {...form.getInputProps('endDate')}
              />
              {!form.values.allDay && (
                <TimeInput
                  label='End time'
                  placeholder='HH:MM'
                  {...form.getInputProps('endTime')}
                />
              )}
              {/* NOTE: in the future, we may allow clinicians to select the `all day` option, but
            for now they'll have to inpute the start and end times */}
              <Alert variant='secondary' icon={<InfoIcon />}>
                All visits scheduled during this period will be rescheduled automatically.
              </Alert>
              {form.values.type === 'emergency' && (
                <RadioGroup
                  {...form.getInputProps('couldBeOutLonger')}
                  label='Are you likely to call out on the following workday?'
                  orientation='horizontal'
                  explanation='If yes, visits will be rescheduled a few days later.'
                >
                  <Radio value='yes' label='Yes' sx={{ flexGrow: 1 }} />
                  <Radio value='no' label='No' sx={{ flexGrow: 1 }} />
                </RadioGroup>
              )}
            </Stack>
          </ScrollArea>
        </Stack>
        <Group position='right' px='md'>
          <PrimaryButton
            disabled={callOutMutation.isLoading}
            loading={callOutMutation.isLoading}
            onClick={() => {
              if (form.validate().hasErrors) {
                return
              }

              toggleConfirmationModal(true)
            }}
          >
            Confirm & block calendar
          </PrimaryButton>
        </Group>
      </Stack>
    </>
  )
}
