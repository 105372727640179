import { Stack, Text } from '@shared/components'
import { useEmrQuery } from '../../../../../utils/hooks'
import { usePatient } from '../../../PPatientContext'

export const OutstandingBalanceSection = () => {
  const { patientQuery } = usePatient()
  const patientId = patientQuery?.data?.oid
  const { data } = useEmrQuery('GET /invoices', {
    query: {
      patientId: patientId || '',
    },
  })

  const aggregateInvoiceInfo = data?.aggregateInvoiceInfo || {
    totalBalanceDueInDollars: 0,
  }

  const { totalBalanceDueInDollars } = aggregateInvoiceInfo

  return (
    <Stack spacing='xs'>
      <Text bold>Outstanding balance</Text>
      <Text>{`$${totalBalanceDueInDollars}`}</Text>
    </Stack>
  )
}
