import { Th } from '@shared/components'
import { INVOICE_TABLE_COLUMNS, OrderByColumn, OrderByDirection } from './PInvoices'

type InvoiceTableHeaderProps = {
  orderByColumn: OrderByColumn
  orderByDirection: OrderByDirection
  handleSortingChange: (columnKey: OrderByColumn | undefined) => void
}

export const InvoicesTableHeader = ({
  orderByColumn,
  orderByDirection,
  handleSortingChange,
}: InvoiceTableHeaderProps) => {
  return (
    <thead className='mantine'>
      <tr className='mantine'>
        {INVOICE_TABLE_COLUMNS.map(header => {
          const { name, isSortable, sortingColumn } = header
          /*
           * The 'Toggle row' column does not have a header text.
           * It is merely a column of buttons.
           */
          if (name === 'Toggle row') {
            return (
              <Th key={name} sortable={false}>
                {' '}
              </Th>
            )
          }

          /*
           * The 'Actions' column does not have a header text.
           * It is merely a column with a menu in it.
           */
          if (name === 'Actions') {
            return (
              <Th key={name} sortable={false}>
                {' '}
              </Th>
            )
          }

          const isSortingByColumn = orderByColumn === sortingColumn
          const isReversed = orderByDirection === 'desc'

          return (
            <Th
              sortable={isSortable}
              sorted={isSortingByColumn}
              reversed={isReversed}
              onSort={() => {
                if (!sortingColumn) {
                  return
                }
                handleSortingChange(sortingColumn)
              }}
              key={name}
            >
              {name}
            </Th>
          )
        })}
      </tr>
    </thead>
  )
}
