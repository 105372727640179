import { BoxIcon, GridIcon, Tabs } from '@shared/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Page } from '../../components/templates/TDefault'
import CaseReviewContent from './caseReview/CaseReviewContent'
import CocmPatientPanel from './patientPanel/CocmPatientPanel'

type TabTitle = 'patient-panel' | 'case-review'

const RegistryContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const activeTab = location.pathname.split('registry/')[1] as TabTitle

  const onClickTab = (value: TabTitle) => {
    navigate({ pathname: `/registry/${value}` })
  }

  return (
    <Page title='Registry'>
      <Tabs pt='md' pb='lg' value={activeTab} onTabChange={(value: TabTitle) => onClickTab(value)}>
        <Tabs.List pl='lg'>
          <Tabs.Tab value='case-review' icon={<BoxIcon />}>
            Case review
          </Tabs.Tab>
          <Tabs.Tab value='patient-panel' icon={<GridIcon />}>
            Patient panel
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='patient-panel'>
          <CocmPatientPanel />
        </Tabs.Panel>
        <Tabs.Panel value='case-review'>
          <CaseReviewContent />
        </Tabs.Panel>
      </Tabs>
    </Page>
  )
}

export default RegistryContent
