import { Group, Stack, TitleThree } from '@shared/components'
import { ReactNode } from 'react'

export const SectionHeader = ({
  sectionTitle,
  button = null,
}: {
  sectionTitle: string
  button?: ReactNode | null
}) => {
  return (
    <Stack>
      <Group position='apart'>
        <TitleThree>{sectionTitle}</TitleThree>
        {button}
      </Group>
    </Stack>
  )
}
