import { useDebouncedValue } from '@mantine/hooks'
import { Group, SearchIcon, Select, SelectProps, Text } from '@shared/components'
import { Patient, PatientStatus, states } from '@shared/types'
import { name, toTime } from '@shared/utils'
import { forwardRef, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'

export type SelectPatientItemProps = {
  value: string
  label: string
  birthday: string
  state: string
}

export const SelectPatientItem = forwardRef<HTMLDivElement, SelectPatientItemProps>(
  ({ label, birthday, state, ...others }: SelectPatientItemProps, ref) => {
    const stateAbbr = states.find(stateInfo => stateInfo.state === state)?.abbr
    const secondaryText = [stateAbbr, birthday].filter(Boolean).join(' | ')

    return (
      <div ref={ref} {...others}>
        <Group position='apart'>
          <Text>{label}</Text>
          <Text color={({ text }) => text[1]}>{secondaryText}</Text>
        </Group>
      </div>
    )
  },
)

SelectPatientItem.displayName = 'SelectPatientItem'

export type SelectPatientProps = Pick<
  SelectProps,
  'label' | 'placeholder' | 'explanation' | 'disabled' | 'onChange' | 'value'
> & {
  onPatientSelected?: (patient: Patient | null) => void
  patientStatus?: PatientStatus[]
  patientId?: string
  initialSearchValue?: string
  onSearchValueChange?: (value: string) => void
}

export const SelectPatient = ({
  onChange,
  onPatientSelected,
  patientId,
  initialSearchValue = '',
  onSearchValueChange,
  ...props
}: SelectPatientProps) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [debouncedSearchValue] = useDebouncedValue(searchValue, toTime('400 ms').ms())

  const patientsQuery = useQuery(
    ...emrApi.getQuery('GET /patients', {
      query: {
        q: debouncedSearchValue,
        limit: '25',
        orderBy: 'name',
        patientStatus: props.patientStatus,
      },
    }),
    {
      keepPreviousData: debouncedSearchValue?.length > 2,
      enabled: debouncedSearchValue?.length > 2,
    },
  )

  const patients = patientsQuery.data?.items || []

  const patientsData: SelectPatientItemProps[] = patients.map(p => ({
    value: p.oid,
    label: name({
      first: p.personalData.firstName,
      last: p.personalData.lastName,
    }).lastCommaFirst(),
    birthday: p.personalData.birthday || '',
    state: p.homeData?.state || '',
  }))

  return (
    <Select
      defaultValue={patientId}
      searchable
      icon={<SearchIcon />}
      clearable
      hideChevron
      allowDeselect={false}
      data={patientsData}
      itemComponent={SelectPatientItem}
      // Overrides the default filter, as we're controlling all of this manually on the network request level
      filter={() => true}
      // Allows users to hit enter to select the first item in the list
      hoverOnSearchChange
      searchValue={searchValue}
      onSearchChange={nextSearchValue => {
        setSearchValue(nextSearchValue)
        onSearchValueChange?.(nextSearchValue)
      }}
      onChange={patientId => {
        onChange?.(patientId)
        const selectedPatient = patients.find(p => p.oid === patientId)
        onPatientSelected?.(selectedPatient || null)
        // Clear the search value if the user clears the select
        if (!patientId) {
          setSearchValue('')
        }
      }}
      {...props}
    />
  )
}
