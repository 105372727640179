import { Box, createStyles, keyframes, MantineTheme, Sx, Tooltip } from '@shared/components'
import { Link } from 'react-router-dom'
import { minToTopRem } from './OVisits'

export type CalendarEventProps = {
  color: string
  highlightColor?: string
  borderColor?: string
  duration: number
  children: React.ReactNode
  link?: string
  tooltip?: React.ReactNode
  shadow?: boolean
  pulse?: boolean
  fullWidth?: boolean
}

// Value in rem to buffer between blocked visits
const BETWEEN_VISIT_BUFFER = 0.375

export const getPulseAnimation = (theme: MantineTheme) =>
  keyframes('pulse', {
    '0%': {
      boxShadow: `0 0 0 ${theme.fn.rgba(theme.other.colors.actions[0], 0.5)}`,
    },
    '70%': {
      boxShadow: `0 0 ${theme.spacing.sm} ${theme.spacing.sm} ${theme.fn.rgba(
        theme.other.colors.actions[0],
        0.5,
      )}`,
    },
    '100%': { boxShadow: `0 0 0 ${theme.fn.rgba(theme.other.colors.actions[0], 0.5)}` },
  })

const useStyles = createStyles(theme => ({
  pulse: {
    boxShadow: `0 0 0 ${theme.fn.rgba(theme.other.colors.actions[0], 0.5)}`,
    animation: `${getPulseAnimation(theme)} 2s infinite`,
    borderRadius: '0.375rem',
  },
}))

export const CalendarEvent = ({
  color,
  highlightColor,
  borderColor,
  duration,
  children,
  link,
  tooltip,
  shadow,
  pulse,
  fullWidth,
}: CalendarEventProps) => {
  const { classes, cx } = useStyles()

  const highlightBorder: Sx = highlightColor
    ? {
        borderLeftColor: highlightColor,
        borderLeftWidth: '.25rem',
      }
    : {}

  const outlineBorder: Sx = shadow
    ? {
        outlineStyle: 'solid',
        outlineWidth: '.125rem',
        outlineColor: borderColor || color,
      }
    : {
        borderWidth: '.125rem',
        borderColor: borderColor || color,
      }

  const width: Sx = fullWidth
    ? {
        minWidth: '100%',
      }
    : {
        // Intentionally empty
      }

  let event = (
    <Box
      mx='xs'
      sx={{
        background: color,
        height: `${(minToTopRem(duration) - BETWEEN_VISIT_BUFFER).toString()}rem`,
        borderRadius: '0.375rem',
        ...outlineBorder,
        ...highlightBorder,
        ...width,
      }}
      className={cx({ [classes.pulse]: pulse })}
    >
      {children}
    </Box>
  )

  if (link) {
    event = (
      <Link to={link} className='no-underline w-full'>
        {event}
      </Link>
    )
  }

  if (tooltip) {
    return <Tooltip label={tooltip}>{event}</Tooltip>
  }
  return event
}
