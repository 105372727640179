import { Colors, Stack, Text } from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import { dayjs } from '@shared/utils'
import { TooltipProps as RechartsTooltipProps } from 'recharts'
import { didPatientIndicateSuicidality } from './chartUtils'

type PrimaryTooltipProps = {
  colors: Colors
  title: ObjectiveMeasuresTitle
} & RechartsTooltipProps<number, string>

const PrimaryTooltip = ({ colors, title, active, payload }: PrimaryTooltipProps) => {
  if (!active || !payload) {
    return null
  }

  const patientIndicatedSuicidality = didPatientIndicateSuicidality({
    response: payload[0]?.payload,
    title,
  })

  const completedByText =
    payload[0]?.payload.completedBy === 'patient'
      ? 'Completed by patient'
      : `Administered by ${payload[0]?.payload.employeeName}`

  const completedAtText: string = `${payload[0]?.value} — ${dayjs(
    payload[0]?.payload.completedAt,
  ).format('MMM DD YYYY')}`

  return (
    <Stack
      p='sm'
      spacing='xs'
      align='center'
      sx={{ backgroundColor: colors.background[6], color: colors.text[3] }}
    >
      <Text bold color={colors.text[3]}>
        {completedAtText.toUpperCase()}
      </Text>
      {patientIndicatedSuicidality && (
        <Text color={colors.text[3]} bold>
          Suicidality risk
        </Text>
      )}
      <Text bold color={colors.text[3]}>
        {completedByText}
      </Text>
    </Stack>
  )
}

export default PrimaryTooltip
