import {
  Box,
  CardButton,
  Colors,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import { ObjectiveMeasure, ObjectiveMeasuresTitle } from '@shared/types'
import { dayjs } from '@shared/utils'
import { ObjectiveMeasureTrendPercentage } from './ObjectiveMeasureTrendPercentage'

export type ObjectiveMeasureScoreProps = ObjectiveMeasure & {
  measure: ObjectiveMeasuresTitle
  scoreToCompare?: number
  onClick: () => void
}

const calculateScoreColorsAndText = (
  score: number,
  measure: ObjectiveMeasuresTitle,
  colors: Colors,
) => {
  const GREEN = {
    background: colors.success[1],
    borderColor: colors.success[0],
  }
  const GREEN_YELLOW = {
    background: `linear-gradient(to bottom right,${colors.success[1]} 50%, ${colors.warning[1]} 50%)`,
    borderLeftColor: colors.success[0],
    borderTopColor: colors.success[0],
    borderRightColor: colors.warning[0],
    borderBottomColor: colors.warning[0],
  }

  const YELLOW = {
    background: colors.warning[1],
    borderColor: colors.warning[0],
  }

  const YELLOW_RED = {
    background: `linear-gradient(to bottom right, ${colors.warning[1]} 50%, ${colors.error[1]} 50%)`,
    borderLeftColor: colors.warning[0],
    borderTopColor: colors.warning[0],
    borderRightColor: colors.error[0],
    borderBottomColor: colors.error[0],
  }

  const RED = {
    background: colors.error[1],
    borderColor: colors.error[0],
  }

  switch (measure) {
    case 'PHQ-8':
    case 'PHQ-9':
      if (score <= 4) {
        return { color: GREEN, severity: 'Minimal' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 9) {
        return { color: GREEN_YELLOW, severity: 'Mild' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 14) {
        return { color: YELLOW, severity: 'Moderate' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 19) {
        return { color: YELLOW_RED, severity: 'Moderately severe' }
      }
      return { color: RED, severity: 'Severe' }

    case 'GAD-7':
      if (score <= 4) {
        return { color: GREEN, severity: 'Minimal' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 9) {
        return { color: YELLOW, severity: 'Mild' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 14) {
        return { color: YELLOW_RED, severity: 'Moderate' }
      }
      return { color: RED, severity: 'Severe' }

    case 'BARC-10':
      // eslint-disable-next-line no-magic-numbers
      if (score <= 23) {
        return { color: RED, severity: '' }
        // eslint-disable-next-line no-magic-numbers
      } else if (score <= 46) {
        return { color: YELLOW, severity: '' }
      }
      return { color: GREEN, severity: '' }
    case 'ASQ':
      return { color: GREEN, severity: '' }
  }
}

export const ObjectiveMeasureScore = ({
  measure,
  completedAt,
  compositeScore,
  scoreToCompare,
  onClick,
  ...decoration
}: ObjectiveMeasureScoreProps) => {
  const {
    other: { colors, sizes },
    radius,
  } = useMantineTheme()

  if (measure === 'ASQ') {
    return null
  }

  const generateTooltip = () => {
    switch (decoration.completedBy) {
      case 'patient':
        return `Completed by patient`
      case 'clinician':
        return `Administered by ${decoration.employeeName}`
    }
  }

  const { color, severity } = calculateScoreColorsAndText(compositeScore, measure, colors)

  return (
    <Tooltip label={generateTooltip()}>
      <CardButton onClick={onClick}>
        <Stack spacing='sm'>
          <Group spacing='sm'>
            <Text bold>{measure}</Text>
            <Box
              sx={{
                width: sizes.icon.lg,
                height: sizes.icon.lg,
                borderWidth: sizes.border.md,
                borderRadius: radius.sm,
                textAlign: 'center',
                ...color,
              }}
            >
              <Text bold>{compositeScore}</Text>
            </Box>
            <Text size='xs'>{severity}</Text>
          </Group>
          <Group spacing='xs'>
            {scoreToCompare && (
              <ObjectiveMeasureTrendPercentage
                measure={measure}
                compositeScore={compositeScore}
                scoreToCompare={scoreToCompare}
              />
            )}
            {scoreToCompare && <Text size='xs'> | </Text>}
            <Text size='xs'>{dayjs(completedAt).format('MM/DD/YYYY').toUpperCase()}</Text>
          </Group>
        </Stack>
      </CardButton>
    </Tooltip>
  )
}
