import {
  ArrayDownIcon,
  ArrayUpIcon,
  ArrowRightIcon,
  Group,
  Text,
  useMantineTheme,
} from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import floor from 'lodash/floor'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'

export type ObjectiveMeasureTrendPercentageProps = {
  measure?: ObjectiveMeasuresTitle
  compositeScore?: number
  scoreToCompare?: number
}

export const ObjectiveMeasureTrendPercentage = ({
  measure,
  compositeScore,
  scoreToCompare,
}: ObjectiveMeasureTrendPercentageProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const calculateTrendPercentage = () => {
    if (compositeScore && scoreToCompare) {
      const difference = compositeScore - scoreToCompare
      const percentage = (difference / scoreToCompare) * 100
      return floor(percentage)
    }
    return null
  }

  const trendPercentage = calculateTrendPercentage()

  const trendString = trendPercentage === null ? `${compositeScore}` : `${trendPercentage}%`

  const higherResultIsBetter = measure === 'BARC-10'

  const getArrowIcon = (percentage: number) => {
    const goodResult =
      (percentage > 0 && higherResultIsBetter) || (percentage < 0 && !higherResultIsBetter)
    let color = colors.error[0]

    if (percentage === 0) {
      color = colors.actions[3]
    }

    if (goodResult) {
      color = colors.success[0]
    }

    if (percentage > 0) {
      return <ArrayUpIcon color={color} />
    }

    if (percentage === 0) {
      return <ArrowRightIcon color={color} />
    }
    return <ArrayDownIcon color={color} />
  }

  return (
    <>
      {compositeScore ? (
        <Group noWrap spacing='sm'>
          {trendPercentage !== null && getArrowIcon(trendPercentage)}{' '}
          <Text size='xs'>{trendString}</Text>
        </Group>
      ) : (
        <EmptyDataCell />
      )}
    </>
  )
}
