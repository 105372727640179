import {
  Group,
  List,
  ListItem,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  useBanner,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useEmrMutation } from '../../../../utils/hooks'

export const DeleteCollectionsModal = ({
  opened,
  onClose,
  patientId,
  collections,
  onSubmit,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  collections: string[]
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const deleteCollections = useEmrMutation('DELETE /patient/:patientId/collections')

  return (
    <Modal
      title='Delete collections'
      opened={opened}
      onClose={() => {
        if (!deleteCollections.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={deleteCollections.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={deleteCollections.isLoading}
            onClick={async () => {
              await deleteCollections.mutateAsync(
                {
                  params: { patientId },
                  data: { collections },
                },
                {
                  onError: error => {
                    showBanner({
                      type: 'error',
                      dismissable: true,
                      label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                    })
                  },
                },
              )

              invalidate({ patientId, collections })

              onClose()
              onSubmit()
            }}
          >
            Delete
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>The following collection(s) will be deleted:</Text>
        <List>
          {collections.map(value => (
            <ListItem key={value}>{value}</ListItem>
          ))}
        </List>
      </Stack>
    </Modal>
  )
}
