import { Drawer, Group, PrimaryButton, ProgressDots, Stack, Text } from '@shared/components'

type ConfirmNoInsuranceProps = {
  closeModal: () => void
  onNext: (args: { appointmentTypeId: string }) => void
  appointmentTypeId: string
}

export const ConfirmNoInsurance = ({
  closeModal,
  onNext,
  appointmentTypeId,
}: ConfirmNoInsuranceProps) => {
  return (
    <Drawer
      title='Does the patient have insurance?'
      size='xl'
      opened
      position='right'
      onClose={closeModal}
      footer={
        <Group position='apart'>
          <ProgressDots steps={2} currentStep={0} />
          <PrimaryButton onClick={() => onNext({ appointmentTypeId })}>
            Continue scheduling as cash pay
          </PrimaryButton>
        </Group>
      }
    >
      <Stack p='md'>
        <Text>
          If so, close this drawer and add the patient’s insurance in the left sidebar before
          proceeding.
        </Text>
      </Stack>
    </Drawer>
  )
}
