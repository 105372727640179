import {
  ArrowRightIcon,
  Banner,
  Checkbox,
  Divider,
  ExternalLinkText,
  Group,
  SearchIcon,
  SecondaryButton,
  Select,
  Skeleton,
  Stack,
  Text,
} from '@shared/components'
import {
  DiagnosisChronicity,
  DiagnosisProgression,
  ICD10Code,
  PsychDiagnosisCode,
} from '@shared/types'
import { isTruthy } from '@shared/utils'
import range from 'lodash/range'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../api'
import { MentalHealthEvalFormDiagnosis, useMentalHealthEvaluationFormContext } from '../formHelpers'
import { MentalHealthDiagnosesReadOnlySection } from './MentalHealthDiagnosesReadOnlySection'
import { MentalHealthDiagnosisItem } from './MentalHealthDiagnosisItem'

/*
 * This is a subset of psych diagnosis codes. These are the codes that we display.
 * Other psych diagnosis codes will need to be searched for.
 */
const RECOMMENDED_CODES: PsychDiagnosisCode[] = [
  'F43.0',
  'F41.9',
  'F41.1',
  'F32.A',
  'F33.9',
  'F32.0',
  'F32.1',
  'O99.340',
  'F41.0',
  'R45.851',
]

const DiagnosisSectionHeader = ({ editMode }: { editMode: boolean }) => (
  <Stack spacing='xs' mt='sm'>
    <Text color={colors => (editMode ? colors.text[0] : colors.text[1])}>
      Please indicate if patient meets criteria for any of the following DSM-5 diagnoses.
    </Text>
    <Text color={colors => colors.text[1]} size='xs'>
      {`These diagnoses may be based on self-report, being treated by an outside provider, or are new based on your evaluation. This diagnosis will be added to the patient's Diagnoses List, but doesn't change the level of support the patient receives.`}
    </Text>
  </Stack>
)

const filterCodeNotInRecommendedCodes = (record: ICD10Code) =>
  RECOMMENDED_CODES.every(code => code !== record.code)

const filterCodeNotInSelectedDiagnoses = (selectedDiagnoses: MentalHealthEvalFormDiagnosis[]) => {
  return (record: ICD10Code) => selectedDiagnoses.every(diagnosis => diagnosis.code !== record.code)
}

// ICD-10 codes that have a validity of 'I' are incomplete codes and are unbillable, so we should filter them out
const filterCodeValid = (record: ICD10Code) => record.validity !== 'I'

export type MentalHealthDiagnosesSectionProps = {
  editMode: boolean
  onDiagnosisCompleted: (diagnosis: {
    code: string
    full_description: string
    chronicity: DiagnosisChronicity
    progression: DiagnosisProgression
  }) => void
}
export const MentalHealthDiagnosesSection = ({
  editMode,
  onDiagnosisCompleted,
}: MentalHealthDiagnosesSectionProps) => {
  const form = useMentalHealthEvaluationFormContext()
  const selectedDiagnoses = form.values.diagnoses
  const [behavioralHealthCodesQueryKey, behavioralHealthCodesQueryFn] = emrApi.getQuery(
    'GET /icd-10-codes',
    {
      query: {
        type: 'behavioralHealth',
      },
    },
  )
  const behavioralHealthCodesQuery = useQuery(
    behavioralHealthCodesQueryKey,
    behavioralHealthCodesQueryFn,
  )

  const [icdRecordSearchValue, setIcdRecordSearchValue] = useState('')

  const recommendedIcd10Records: { code: string; full_description: string }[] = useMemo(() => {
    return RECOMMENDED_CODES.map(code => {
      const record = behavioralHealthCodesQuery.data?.find(
        (record: ICD10Code) => record.code === code,
      )
      if (!record) {
        return {
          code,
          full_description: code,
        }
      }
      return {
        code: record.code,
        full_description: record.full_description,
      }
    })
  }, [behavioralHealthCodesQuery.data])

  const otherICD10RecordSelectItems = behavioralHealthCodesQuery.data
    ?.filter(filterCodeNotInRecommendedCodes)
    .filter(filterCodeNotInSelectedDiagnoses(selectedDiagnoses))
    .filter(filterCodeValid)
    .map(record => ({
      label: record.full_description,
      value: record.code,
    }))

  const shortListProps = recommendedIcd10Records?.map(record => {
    const index = selectedDiagnoses.findIndex(diagnosis => diagnosis.code === record.code)
    return {
      code: record.code,
      fullDescription: record.full_description,
      checked: index !== -1,
      path: index === -1 ? ('' as const) : (`diagnoses.${index}` as const),
    }
  })

  // Map used instead of filter to preserve indexes
  const otherSelectedDiagnoses = selectedDiagnoses.map(diagnosis =>
    RECOMMENDED_CODES.some(code => code === diagnosis.code) ? null : diagnosis,
  )

  const otherSelectedDiagnosesProps = otherSelectedDiagnoses
    .map((diagnosis, index) => {
      const icd10Record = behavioralHealthCodesQuery.data?.find(
        (record: ICD10Code) => record.code === diagnosis?.code,
      )

      if (diagnosis === null) {
        return null
      }
      return {
        code: diagnosis.code,
        fullDescription: icd10Record?.full_description ?? diagnosis.code,
        path: `diagnoses.${index}` as const,
        checked: true,
      }
    })
    .filter(isTruthy)

  /*
   * We want to show this readonly section if we aren't in editMode, reguardless of the isEvaluated value.
   * If isEvaluated is 'no', we just show this section with no diagnoses.
   */
  if (!editMode) {
    return (
      <>
        <DiagnosisSectionHeader editMode={editMode} />
        <MentalHealthDiagnosesReadOnlySection
          diagnoses={form.values.isEvaluated === 'yes' ? form.values.diagnoses : []}
        />
      </>
    )
  }

  // In editMode, if isEvaluated is 'no', we don't show this section at all.
  if (form.values.isEvaluated === 'no') {
    return null
  }

  if (behavioralHealthCodesQuery.isLoading) {
    return (
      <>
        <DiagnosisSectionHeader editMode={editMode} />
        <Text bold>Common diagnoses</Text>
        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        {range(0, 10).map(value => (
          <Skeleton height={30} key={value} />
        ))}
      </>
    )
  }

  if (behavioralHealthCodesQuery.isError) {
    return (
      <>
        <DiagnosisSectionHeader editMode={editMode} />
        <Banner label='Unable to fetch behavioral health codes' type='error' />
      </>
    )
  }

  return (
    <Stack>
      <DiagnosisSectionHeader editMode={editMode} />
      <Stack maw='500px'>
        {/* This checkbox deliberately does nothing and just gives clinicians something to click on.*/}
        <Checkbox label='No diagnosis to add' />
        <Stack spacing='sm'>
          <Group position='apart'>
            <Text bold>Common diagnoses</Text>
            <ExternalLinkText
              href='https://coda.io/d/Clinical-Team_d6-Hc4Zptr0/DSM-5-Criteria-for-common-psych-conditions_su3hi#_lusVX'
              w='fit-content'
            >
              <SecondaryButton rightIcon={<ArrowRightIcon />} size='sm'>
                DSM-5 criteria
              </SecondaryButton>
            </ExternalLinkText>
          </Group>

          {shortListProps?.map(item => (
            <MentalHealthDiagnosisItem
              {...item}
              key={`mental_health_eval_diagnosis_${item.code}`}
              checkboxOnChange={checked => {
                if (checked) {
                  form.insertListItem('diagnoses', {
                    progression: '',
                    chronicity: '',
                    comment: '',
                    code: item.code,
                  })
                } else {
                  const index = form.values.diagnoses.findIndex(
                    diagnosis => diagnosis.code === item.code,
                  )
                  form.removeListItem('diagnoses', index)
                }
              }}
              onDiagnosisCompleted={onDiagnosisCompleted}
            />
          ))}
        </Stack>
        {Boolean(otherSelectedDiagnosesProps.length) && (
          <>
            <Divider />
            <Stack spacing='sm'>
              {otherSelectedDiagnosesProps?.map(item => (
                <MentalHealthDiagnosisItem
                  {...item}
                  key={`mental_health_eval_diagnosis_${item.code}`}
                  checkboxOnChange={checked => {
                    if (checked) {
                      form.insertListItem('diagnoses', {
                        progression: '',
                        chronicity: '',
                        comment: '',
                        code: item.code,
                      })
                    } else {
                      const index = form.values.diagnoses.findIndex(
                        diagnosis => diagnosis.code === item.code,
                      )
                      form.removeListItem('diagnoses', index)
                    }
                  }}
                  onDiagnosisCompleted={onDiagnosisCompleted}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
      <Select
        data={otherICD10RecordSelectItems ?? []}
        searchable
        searchValue={icdRecordSearchValue}
        onSearchChange={value => setIcdRecordSearchValue(value)}
        value={null}
        icon={<SearchIcon />}
        placeholder='Search all mental health diagnoses'
        onChange={value => {
          if (value) {
            form.insertListItem('diagnoses', {
              progression: '',
              chronicity: '',
              comment: '',
              code: value,
            })
            setIcdRecordSearchValue('')
          }
        }}
      />
    </Stack>
  )
}
