import { AlertIcon, Pill, Select, Stack } from '@shared/components'
import { EncounterStatuses, EncounterWithErrors } from '@shared/types'
import { dayjs } from '@shared/utils'
import cn from 'classnames'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi, encountersApi } from '../../../api'
import AGeneralTablePaginationFooter from '../../../components/atoms/AGeneralTablePaginationFooter'
import { useTypedHistory, useTypedParams } from '../../../utils/hooks'
import ListItem from '../../care_team/irq/ListItem'
import SearchBar from '../../care_team/irq/SearchBar'
import { reviewableStatuses } from './BillingEncounters'

type EncounterListProps = {
  count: number
  encountersPerPage: number
  setPage: (input: number) => void
  setIndex: (input: number) => void
  page: number
  encounters: EncounterWithErrors[]
  setEncounters: (encounters: EncounterWithErrors[]) => void
  setCursors: Dispatch<SetStateAction<string[]>>
  payerId: string
  setPayerId: (id: string) => void
}

const EncounterList = ({
  count,
  encountersPerPage,
  setPage,
  page,
  setIndex,
  encounters,
  setEncounters,
  setCursors,
  payerId,
  setPayerId,
}: EncounterListProps) => {
  const queryClient = useQueryClient()
  const { replace } = useTypedHistory()
  const {
    pathParams: { status = '', encounterId = '' },
  } = useTypedParams('/billing/encounters/:status/:encounterId')
  const [searching, setSearching] = useState(false)
  const [query, setQuery] = useState('')
  const searchLimit = 1000

  useEffect(() => {
    setQuery('')
    setSearching(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const searchEncounters = useMutation(encountersApi.getEncounters)

  const onSubmit = async () => {
    if (query) {
      const result = await searchEncounters.mutateAsync({
        filter: status,
        patientId: query,
        order: reviewableStatuses.includes(status as EncounterStatuses) ? 'asc' : 'desc',
        per: searchLimit,
        startAfter: undefined,
        payerId,
      })
      setSearching(true)
      setEncounters(result)
    }
  }

  return (
    <>
      <Stack spacing='sm' mb='sm'>
        <Select
          searchable
          defaultValue={payerId}
          data={[
            { label: 'All plans', value: '' },
            { label: 'Amerihealth', value: '22248' },
            { label: 'Capital Blue Cross', value: '23045' },
            { label: 'CT Blue Shield', value: '00060' },
            { label: 'Empire', value: '00803' },
            { label: 'Fidelis', value: '11315' },
            { label: 'Geisinger', value: '75273' },
            { label: 'Highmark', value: '54771' },
            { label: 'Humana', value: '61101' },
            { label: 'Independence', value: '54704' },
            { label: 'Keystone First', value: '23284' },
            { label: 'ME Blue Shield', value: '00680' },
            { label: 'United Healthcare', value: '87726' },
            { label: 'VA Blue Shield', value: '00423' },
          ]}
          placeholder='Filter by plan name'
          onChange={value => {
            setPayerId(value ?? '')
            setIndex(0)
            setPage(0)
            setCursors([])
            void queryClient.invalidateQueries('encountersApi.getEncounters')
          }}
        />
        <SearchBar
          placeholder='Search by patient ID'
          onEnter={onSubmit}
          onChange={setQuery}
          value={query}
          onClear={() => {
            setQuery('')
            setSearching(false)
            setPage(0)
            setCursors([])
            void queryClient.invalidateQueries('encountersApi.getEncounters')
          }}
        />
      </Stack>
      <ul className='relative z-0 divide-y divide-gray-200 border-gray-200 mb-6 col-span-2'>
        {encounters.map((encounter: EncounterWithErrors) => (
          <ListItem
            key={encounter.oid}
            onClick={e => {
              const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
                params: { encounterId: encounter.oid },
              })
              queryClient.setQueryData(
                encounterQueryKey,
                (cachedEncounterData?: EncounterWithErrors) => {
                  if (cachedEncounterData) {
                    return cachedEncounterData
                  }
                  return encounter
                },
              )
              /*
               * when an encounter status is changed, it'll no longer exist on the list,
               * so this allows for immediately opening the next encounter
               */
              setIndex(encounters.indexOf(encounter))
              replace(`/billing/encounters/${status as EncounterStatuses}/${encounter.oid}`)
              e.stopPropagation()
            }}
            focused={encounterId === encounter.oid}
          >
            <div className='flex row justify-between pb-2'>
              <p className='flex row'>
                <span
                  className={cn('flex row items-center', {
                    'font-semibold': encounterId === encounter.oid,
                  })}
                >
                  {encounter.patient.last_name}, {encounter.patient.first_name}
                </span>
              </p>
              <p className='relative group flex items-center'>
                <span className='text-xs font-md flex row space-x-2'>
                  {encounter.appointment_type === 'Collaborative Care Management' && (
                    <Pill
                      status='none'
                      variant='filled'
                    >{`CoCM - ${encounter.patient.address?.state}`}</Pill>
                  )}
                  {encounter.errors.length > 0 && (
                    <Pill status='error' variant='filled'>
                      <AlertIcon />
                    </Pill>
                  )}
                </span>
              </p>
            </div>
            <div className='hidden sm:flex flex-row flex-shrink-0 text-xs justify-between'>
              <div className='flex items-center'>
                {dayjs(encounter.date_of_service).add(1, 'week').isBefore(dayjs()) &&
                  reviewableStatuses.includes(status as EncounterStatuses) && (
                    <AlertIcon color={colors => colors.warning[0]} />
                  )}
                <div className='ml-1'>{dayjs(encounter.date_of_service).format('MM/DD/YYYY')}</div>
              </div>
              {encounter.subscriber_primary?.insurance_card?.payer_name}
            </div>
          </ListItem>
        ))}
      </ul>
      <AGeneralTablePaginationFooter
        offset={page * encountersPerPage}
        pageSize={searching ? searchLimit : encountersPerPage}
        total={searching ? encounters.length : count}
        onNextClick={() => {
          const lastEncounterOnPage = encounters[encounters.length - 1] as EncounterWithErrors
          setCursors(prevCursors => prevCursors.concat(lastEncounterOnPage.oid))
          setPage(++page)
          setIndex(0)
        }}
        onPreviousClick={() => {
          setCursors(prevCursors => prevCursors.slice(0, -1))
          setPage(--page)
          setIndex(0)
        }}
      />
    </>
  )
}

export default EncounterList
