import { EM_DASH, Table, Td, TertiaryButton, Text, Th } from '@shared/components'
import { dayjs } from '@shared/utils'
import orderBy from 'lodash/orderBy'
import range from 'lodash/range'
import { useState } from 'react'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { Config } from '../../../config'
import { useEmrQuery } from '../../../utils/hooks'
import { PriorityIcon } from '../../care_team/tasks/PriorityIcon'
import { EmptyState } from '../EmptyState'
import { InsuranceCellContent } from '../InsuranceCellContent'
import { OrderBy, OrderByKey } from '../types'

const NUMBER_OF_LOADING_ROWS = 7
const TABLE_COLUMNS = [
  'Pri',
  'Patient name',
  'State',
  'Last attended WC',
  'Insurance',
  'Contacts',
  'Last winback call',
] as const

type TableColumn = (typeof TABLE_COLUMNS)[number]

const SORT_KEYS: Record<TableColumn, OrderByKey> = {
  Pri: 'priority',
  'Patient name': 'patientName',
  State: 'patientState',
  'Last attended WC': 'patientLastWelcomeCallDate',
  Insurance: 'patientInsurancePlanName',
  Contacts: 'patientContactCount',
  'Last winback call': 'patientLastWinbackCallDate',
}

const FIXED_WIDTH_COLUMNS: TableColumn[] = ['Contacts']

export const EnrollmentPanelTable = () => {
  const [orderByKey, setOrderByKey] = useState<OrderByKey>('priority')
  const [orderByDirection, setOrderByDirection] = useState<OrderBy['direction']>('DESC')

  const enrollmentPanelQuery = useEmrQuery('GET /enrollment-panel/patients', undefined, {
    enabled: Config.ENV === 'production',
  })

  const setOrderBy = (updatedOrderBy: OrderBy) => {
    setOrderByKey(updatedOrderBy.key)
    setOrderByDirection(updatedOrderBy.direction)
  }

  const rows = orderBy(
    enrollmentPanelQuery?.data || [],
    [orderByKey],
    orderByDirection
      ? [orderByDirection.toLowerCase() as Lowercase<OrderBy['direction']>]
      : undefined,
  )

  return (
    <>
      {!enrollmentPanelQuery.isLoading && rows.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          striped
          withBorder
          verticalSpacing='sm'
          sx={({ radius, other: { sizes, colors } }) => ({
            tableLayout: 'auto',
            backgroundColor: colors.background[0],
            borderWidth: sizes.border.lg,
            borderRadius: radius.sm,
            borderCollapse: 'separate',
            borderStyle: 'solid',
            borderSpacing: '0',
            borderColor: colors.background[2],
          })}
        >
          <thead className='mantine'>
            <tr className='mantine'>
              {TABLE_COLUMNS.map(header => {
                const isCurrentColumn = orderByKey === SORT_KEYS[header]
                const isReversed = orderByDirection === 'ASC'
                const toggledOrder = isReversed ? 'DESC' : 'ASC'
                return (
                  <Th
                    sortable={header in SORT_KEYS}
                    sorted={isCurrentColumn}
                    reversed={isReversed}
                    onSort={() =>
                      setOrderBy({
                        key: SORT_KEYS[header],
                        direction: isCurrentColumn ? toggledOrder : 'DESC',
                      })
                    }
                    key={header}
                    style={
                      FIXED_WIDTH_COLUMNS.includes(header)
                        ? { width: `${header.length}ch` }
                        : undefined
                    }
                  >
                    {header}
                  </Th>
                )
              })}
            </tr>
          </thead>
          <tbody className='mantine'>
            {enrollmentPanelQuery.isLoading &&
              range(0, NUMBER_OF_LOADING_ROWS + 1).map(i => (
                <LoadingRow key={i} headersLength={TABLE_COLUMNS.length} />
              ))}
            {!enrollmentPanelQuery.isLoading && rows.length === 0 && (
              <NoDataRow message='No winback patients found' headersLength={TABLE_COLUMNS.length} />
            )}
            {!enrollmentPanelQuery.isLoading &&
              rows.length > 0 &&
              rows.map(row => {
                return (
                  <tr key={row.patientId} style={{ opacity: row.claimed ? 0.3 : 1 }}>
                    {TABLE_COLUMNS.map(col => {
                      switch (col) {
                        case 'Pri':
                          return (
                            <Td key={col}>
                              <PriorityIcon type='winback_call' priority={row.priority || 1} />
                            </Td>
                          )
                        case 'Patient name':
                          return (
                            <Td key={col}>
                              <TertiaryButton
                                component='a'
                                target='_blank'
                                href={`/patients/${row.patientId}`}
                              >
                                {row.patientName || EM_DASH}
                              </TertiaryButton>
                            </Td>
                          )
                        case 'State':
                          return (
                            <Td key={col}>
                              <Text>{row.patientState}</Text>
                            </Td>
                          )
                        case 'Last attended WC':
                          return (
                            <Td key={col}>
                              <Text>
                                {row.patientLastWelcomeCallDate
                                  ? dayjs(row.patientLastWelcomeCallDate).format('MM/DD/YYYY')
                                  : EM_DASH}
                              </Text>
                            </Td>
                          )
                        case 'Insurance':
                          return (
                            <Td key={col}>
                              <InsuranceCellContent row={row} />
                            </Td>
                          )
                        case 'Contacts':
                          return (
                            <Td key={col}>
                              <Text align='center'>{row.patientContactCount || EM_DASH}</Text>
                            </Td>
                          )
                        case 'Last winback call':
                          return (
                            <Td key={col}>
                              <Text>
                                {row.patientLastWinbackCallDate
                                  ? dayjs(row.patientLastWinbackCallDate).format('MM/DD/YYYY')
                                  : EM_DASH}
                              </Text>
                            </Td>
                          )
                        default:
                          return <Td key={col} />
                      }
                    })}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      )}
    </>
  )
}
