import { useForm } from '@mantine/form'
import { Grid, skipIfEmpty, Stack, validateWith } from '@shared/components'
import { ServiceFacility, stateAbbreviations } from '@shared/types'
import { useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import {
  isAbbreviatedUsState,
  isFourNumbers,
  isRequired,
  isZipCode,
} from '../../../../utils/formValidation'
import { CardHeader } from '../components/CardHeader'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'

export type ServiceFacilitySectionProps = {
  serviceFacility?: ServiceFacility
  encounterId: string
  isEditing: boolean
  editButtonEnabled: boolean
  setEditing: (bool: boolean) => void
}

export const ServiceFacilitySection = ({
  serviceFacility,
  encounterId,
  setEditing,
  editButtonEnabled,
  isEditing,
}: ServiceFacilitySectionProps) => {
  const queryClient = useQueryClient()

  const formattedServiceFacilityData = useMemo(() => {
    return {
      organization_name: serviceFacility?.organization_name || '',
      address: {
        address1: serviceFacility?.address?.address1 || '',
        address2: serviceFacility?.address?.address2 || '',
        city: serviceFacility?.address?.city || '',
        state: serviceFacility?.address?.state || '',
        zip_code: serviceFacility?.address?.zip_code || '',
        zip_plus_four_code: serviceFacility?.address?.zip_plus_four_code || '',
      },
    }
  }, [serviceFacility])

  const form = useForm<ServiceFacility>({
    initialValues: formattedServiceFacilityData,
    validate: {
      organization_name: validateWith(isRequired),
      address: {
        address1: validateWith(isRequired),
        city: validateWith(isRequired),
        state: validateWith(isRequired, isAbbreviatedUsState),
        zip_code: validateWith(isRequired, isZipCode),
        zip_plus_four_code: validateWith(skipIfEmpty, isFourNumbers),
      },
    },
    clearInputErrorOnChange: false,
  })

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  const saveServiceFacilityData = (data: ServiceFacility) => {
    updateEncounter.mutate(
      {
        data: {
          encounter: {
            service_facility: data,
          },
          id: encounterId,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(encounterQueryKey)
          setEditing(false)
        },
      },
    )
  }

  useEffect(() => {
    form.setValues(formattedServiceFacilityData)
  }, [formattedServiceFacilityData])

  useEffect(() => {
    form.validate()
  }, [form.values])

  return (
    <Stack>
      <CardHeader
        title='Service facility'
        buttonLabel='Edit'
        buttonOnClick={() => setEditing(true)}
        showButton={editButtonEnabled}
        errorCount={Object.keys(form.errors).length}
      />
      <Grid columns={12}>
        <Grid.Col span={4}>
          <CardTextInput
            label='Organization name'
            editable={isEditing}
            {...form.getInputProps('organization_name')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 1'
            editable={isEditing}
            {...form.getInputProps('address.address1')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 2 (optional)'
            editable={isEditing}
            {...form.getInputProps('address.address2')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='City'
            editable={isEditing}
            {...form.getInputProps('address.city')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            data={stateAbbreviations}
            searchable
            label='State'
            {...form.getInputProps('address.state')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip'
            editable={isEditing}
            {...form.getInputProps('address.zip_code')}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip plus four (optional)'
            editable={isEditing}
            {...form.getInputProps('address.zip_plus_four_code')}
          />
        </Grid.Col>
      </Grid>
      {isEditing ? (
        <EditableSectionFooter
          onCancel={() => {
            setEditing(false)
            form.setValues(formattedServiceFacilityData)
          }}
          onSave={form.onSubmit(() => {
            saveServiceFacilityData(form.values)
          })}
          isSaving={updateEncounter.isLoading}
        />
      ) : null}
    </Stack>
  )
}
