import { Center, Pill } from '@shared/components'
import { dayjs } from '@shared/utils'

type CommunicationsDatePillProps = {
  datetime: string
}

export const CommunicationsDatePill = ({ datetime }: CommunicationsDatePillProps) => {
  const formattedDate: string = dayjs(datetime).format('MMM DD YYYY')
  return (
    <Center mb='md'>
      <Pill variant='filled' status='none'>
        {formattedDate.toUpperCase()}
      </Pill>
    </Center>
  )
}
