import { Checkbox, PrimaryButton, Stack, Text } from '@shared/components'
import { PrescriptionResponse, isClinician } from '@shared/types'
import { useEffect, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import { emrApi, visitNotesApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { PRESCRIPTION_QUEUER_ROLES, sortPrescriptions } from '../../../utils/prescriptionUtils'
import { PrescriptionReorderDrawer } from '../prescriptions/PrescriptionReorderDrawer'
import PrescriptionRow from '../prescriptions/PrescriptionRow'
import ManuallyRecordedMedicationRow, {
  ManuallyRecordedMedication,
} from './ManuallyRecordedMedicationRow'

export type MVisitNotePrescriptionSectionProps = {
  openPrescriptionDrawer?: (bool: boolean) => void
  patientId: string
  visitId: number
  isLocked: boolean
  setMedicationPrescribed?: (bool: boolean) => void
  recordedMedication?: ManuallyRecordedMedication
  medicationSentViaDosespot: boolean
  setMedicationSentViaDosespot: (bool: boolean) => void
  setDosespotMedications?: (medications: PrescriptionResponse[]) => void
  editMode: boolean
  setPrescriptionBanner?: (message: string, type: 'success' | 'warning' | 'error') => void
}

const MVisitNotePrescriptionSection = ({
  patientId,
  visitId,
  isLocked,
  setMedicationPrescribed,
  recordedMedication,
  medicationSentViaDosespot,
  setMedicationSentViaDosespot,
  setDosespotMedications,
  setPrescriptionBanner,
}: MVisitNotePrescriptionSectionProps) => {
  const { currentUser } = useAuth()

  const [prescriptionDrawerOpen, setPrescriptionDrawerOpen] = useState(false)
  const prescriptionIdQuery = useQuery(
    ['visitNotesApi.getVisitNotePrescriptions', visitId],
    () => visitNotesApi.getVisitNotePrescriptions({ visitId }),
    {
      enabled: Boolean(visitId),
      onSuccess: ids => {
        if (ids.length) {
          setMedicationPrescribed?.(true)
        }
      },
    },
  )

  const prescriptionIds = prescriptionIdQuery?.data ?? []

  const prescriptionDataQuery = useQueries(
    prescriptionIds.map(id => {
      const [queryKey, queryFn] = emrApi.getQuery(
        'GET /patient/:patientId/legacyPrescription/:prescriptionId',
        {
          params: {
            prescriptionId: String(id),
            patientId,
          },
        },
      )

      return {
        queryKey,
        queryFn,
        enabled: Boolean(patientId),
      }
    }),
  )

  const isLoading = prescriptionDataQuery.some(result => result.isLoading)
  const sortedPrescriptions = sortPrescriptions(
    prescriptionDataQuery.map(prescription => prescription?.data as PrescriptionResponse),
  )

  useEffect(() => {
    if (!isLoading && sortedPrescriptions.length && setDosespotMedications) {
      setDosespotMedications(sortedPrescriptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedPrescriptions.length])

  if (!visitId || !patientId) {
    return null
  }

  const buttonMessage = () => {
    if (isLoading) {
      return 'Loading prescriptions'
    }
    if (PRESCRIPTION_QUEUER_ROLES.includes(currentUser.role)) {
      if (prescriptionIdQuery.data && prescriptionIdQuery.data?.length > 0) {
        return 'Queue another prescription'
      }
      return 'Queue prescription'
    }
    if (prescriptionIdQuery.data && prescriptionIdQuery.data?.length > 0) {
      return 'Send another prescription'
    }
    return 'Send prescription'
  }

  const renderPrescription = (prescription?: PrescriptionResponse) => {
    if (!prescription?.prescription_id) {
      return
    }
    const id = prescription.prescription_id

    return <PrescriptionRow key={id} prescription={prescription} patientId={patientId} />
  }

  return (
    <>
      <Stack spacing='sm'>
        {!isLocked && (
          <>
            <Text>
              {isClinician(currentUser)
                ? 'If you’d like to prescribe medication, click the button below. The prescription details will be automatically logged in this note and in the patient’s chart.'
                : 'Only clinicians may prescribe medication.'}
            </Text>
            <PrimaryButton
              loading={isLoading}
              onClick={() => setPrescriptionDrawerOpen(true)}
              disabled={!isClinician(currentUser) || isLoading}
            >
              {buttonMessage()}
            </PrimaryButton>
          </>
        )}
        {recordedMedication && (
          <ManuallyRecordedMedicationRow key='attestation' medication={recordedMedication} />
        )}
        {sortedPrescriptions.length > 0 &&
          sortedPrescriptions.map(prescription => renderPrescription(prescription))}
        {!isLocked && (
          <Checkbox
            label='Medication sent through DoseSpot'
            checked={medicationSentViaDosespot}
            onChange={event => setMedicationSentViaDosespot(event.target.checked)}
          />
        )}
      </Stack>
      <PrescriptionReorderDrawer
        patientId={patientId}
        setPrescriptionBanner={setPrescriptionBanner}
        opened={prescriptionDrawerOpen}
        onClose={() => setPrescriptionDrawerOpen(false)}
        visitId={String(visitId)}
      />
    </>
  )
}

export default MVisitNotePrescriptionSection
