import { IoBookmarkOutline } from '@react-icons/all-files/io5/IoBookmarkOutline'
import { Divider, Stack, Text, useMantineTheme } from '@shared/components'
import { PrescriptionFavorite } from '@shared/types'
import sortBy from 'lodash/sortBy'
import QuickApplyListItem from './QuickApplyListItem'
export type FavoritesTabContentProps = {
  favoritesList?: PrescriptionFavorite[]
  favoriteOnClick: (fav: PrescriptionFavorite) => void
  deleteOnClick: (fav: PrescriptionFavorite) => void
  editOnClick: (fav: PrescriptionFavorite) => void
}

const FavoritesTabContent = ({
  favoritesList,
  favoriteOnClick,
  deleteOnClick,
  editOnClick,
}: FavoritesTabContentProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  if (favoritesList?.length) {
    const sortedFavoritesList = sortBy(favoritesList, obj => {
      return obj.favoriteName.toLowerCase()
    })
    return (
      <Stack
        spacing='xs'
        sx={() => ({
          overflowY: 'scroll',
          maxHeight: '320px',
          paddingLeft: '0px',
          background: colors.background[1],
        })}
      >
        {sortedFavoritesList.map((fav, index) => {
          return (
            <>
              <QuickApplyListItem
                key={fav.oid}
                favoriteName={fav.favoriteName}
                medicationName={fav.medicationName}
                quantity={fav.quantity}
                daysSupply={fav.daysSupply}
                doseForm={fav.doseForm}
                strength={fav.strength}
                onClick={() => favoriteOnClick(fav)}
                onDelete={() => deleteOnClick(fav)}
                onEdit={() => editOnClick(fav)}
              />
              {index !== favoritesList.length - 1 && <Divider />}
            </>
          )
        })}
      </Stack>
    )
  }
  return (
    <Stack
      align='center'
      justify='center'
      spacing='xs'
      sx={theme => ({ padding: theme.spacing.lg, background: colors.background[1] })}
    >
      <IoBookmarkOutline size='25px' />
      <Text bold>No favorites</Text>
      <Text size='xs'>Start adding favorites by selecting “Save as favorite” link below</Text>
    </Stack>
  )
}

export default FavoritesTabContent
