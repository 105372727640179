import { DatePicker, GetInputProps, Group, InputWrapper, Select, Stack } from '@shared/components'
import { EMRTask, EMRTaskType, WINBACK_CALL_TASK_OUTCOMES } from '@shared/types'
import { dayjs } from '@shared/utils'
import {
  RelativeTimeSelectItem,
  getRelativeTimeUnitData,
} from '../../core/inputs/RelativeTimeSelectItem'

export type CloseTaskNoteFormProps = {
  note: string
}

type Props<T extends EMRTaskType = EMRTaskType> = {
  task: EMRTask<T>
  getInputProps: GetInputProps<CloseTaskNoteFormProps>
}

const WinbackCallCloseTaskResultFields = (props: Props<'winback_call'>) => {
  return (
    <Stack>
      {props.task.results?.callOutcome && (
        <Select
          label={props.task.results?.callOutcome.label}
          {...props.getInputProps('results.callOutcome.value')}
          placeholder='Select one...'
          data={WINBACK_CALL_TASK_OUTCOMES}
        />
      )}
      {props.task.results?.nextWinbackCallDatetime && (
        <InputWrapper label={props.task.results?.nextWinbackCallDatetime.label}>
          <Group noWrap>
            <DatePicker
              placeholder='Select a date...'
              minDate={dayjs().toDate()}
              maxDate={dayjs().add(3, 'months').toDate()}
              {...props.getInputProps('results.nextWinbackCallDatetime.value')}
              onChange={value =>
                props
                  .getInputProps('results.nextWinbackCallDatetime.value')
                  .onChange(dayjs(value).toISOString())
              }
            />
            <Select
              itemComponent={RelativeTimeSelectItem}
              data={getRelativeTimeUnitData(
                props.getInputProps('results.nextWinbackCallDatetime.value').value ?? '',
              )}
              placeholder='Select a time...'
              {...props.getInputProps('results.nextWinbackCallDatetime.value')}
            />
          </Group>
        </InputWrapper>
      )}
    </Stack>
  )
}

export const CloseTaskResultFields = (props: Props) => {
  // Note: to prevent errors, we'll make sure the task has results before rendering this component
  if (!props.task?.results) {
    return null
  }

  switch (props.task.type) {
    case 'winback_call':
      return (
        <WinbackCallCloseTaskResultFields {...props} task={props.task as EMRTask<'winback_call'>} />
      )
    default:
      return null
  }
}
