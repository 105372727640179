import {
  Box,
  Flex,
  LinkText,
  List,
  ListItem,
  Select,
  Skeleton,
  Stack,
  Text,
} from '@shared/components'
import {
  DoseSpotCoverageGenderRestriction,
  DoseSpotPharmacyType,
  DoseSpotQuanitityLimitRestrictionsAmountUnit,
  DoseSpotQuanitityLimitRestrictionsTimeUnit,
  DoseSpotRealTimeFormularyRequestStatus,
} from '@shared/types'
import { toTime } from '@shared/utils'
import { isAxiosError } from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'
import { MedicationSearchBar } from '../prescriptions/MedicationSearchBar'
import { EligibilitySelect } from './EligibilitySelect'

export const FormularyContent = () => {
  const { patientId } = usePatient()
  const [surescriptsError, setSurescriptsError] = useState('')

  const [patientEligibilityId, setPatientEligibilityId] = useState<string>('')
  const [strengths, setStrengths] = useState<
    {
      Strength: string
      NDC: string
    }[]
  >([])
  const [ndc, setNdc] = useState<string>('')
  const oneHundredPercent = 100

  // Query for coverage
  const [coverageQueryKey, coverageQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/dosespotCoverage',
    { params: { patientId }, query: { patientEligibilityId, ndc } },
  )

  const coverageQuery = useQuery(coverageQueryKey, coverageQueryFunction, {
    enabled: Boolean(patientEligibilityId) && Boolean(ndc) && !surescriptsError,
    // This endpoint queues up a request to SureScripts, so we have to retry until DS has loaded the result
    refetchInterval: data => {
      if (!data) {
        return 500
      }
      return DoseSpotRealTimeFormularyRequestStatus[data?.RealTimeFormularyRequestStatus] ===
        'Complete'
        ? false
        : toTime('1 sec').ms()
    },
    retry(_failureCount, error) {
      if (isAxiosError(error)) {
        setSurescriptsError(error.response?.data.message)
      }
      return !error
    },
  })

  // Show loading state until we've received the data after the fetch interval
  const isLoading =
    coverageQuery.isLoading ||
    DoseSpotRealTimeFormularyRequestStatus[
      coverageQuery.data?.RealTimeFormularyRequestStatus ?? 0
    ] !== 'Complete'

  const coverage = coverageQuery.data

  return (
    <Stack>
      <Flex p='xs' maw='100%' gap='lg'>
        <EligibilitySelect onChange={value => setPatientEligibilityId(value ?? '')} />
        <Box w='400px'>
          <MedicationSearchBar
            onChange={item => {
              setNdc('')
              setStrengths(item?.Strength ?? [])
            }}
          />
        </Box>
        <Select
          w='400px'
          label='Medication strength'
          value={ndc}
          onChange={value => setNdc(value ?? '')}
          data={strengths.map(strength => ({ value: strength.NDC, label: strength.Strength }))}
          disabled={strengths.length === 0}
        />
      </Flex>

      {!coverageQuery.isFetched && (
        <Text>Fill out the form above to load Formulary coverage data</Text>
      )}
      {isLoading && coverageQuery.isFetching && <Skeleton w='400px' h='200px' />}
      {!coverageQuery.isLoading && surescriptsError && (
        <Text>Formulary Results: {surescriptsError}</Text>
      )}
      {!isLoading && coverage && (
        <Stack>
          <Text>Formulary Results</Text>
          <Stack
            w='800px'
            sx={theme => ({
              borderWidth: theme.other.sizes.border.lg,
              borderStyle: 'solid',
              borderColor: theme.other.colors.actions[0],
              borderRadius: theme.radius.sm,
              padding: theme.other.sizes.padding.lg,
            })}
          >
            <Text bold>Formulary Status: {coverage.FormularyStatus}</Text>
            <Text bold>Brand name? {coverage.Brand ? 'yes' : 'no'}</Text>
            <Text bold>OTC? {coverage.Otc ? 'yes' : 'no'}</Text>
            <Text bold>Copays:{!coverage.Copays && ' n/a'}</Text>
            <List>
              {coverage.Copays?.map(copay => (
                <ListItem key={JSON.stringify(copay)}>
                  <Text>Drug Specific: {copay.IsDrugSpecific ? 'yes' : 'no'}</Text>
                  <Text>Pharmacy Type: {DoseSpotPharmacyType[copay.PharmacyType]}</Text>
                  <Text>Days Supply: {copay.DaysSupply}</Text>
                  <Text>Copay Tier: {copay.CopayTier ?? 'n/a'}</Text>
                  <Text>
                    Flat Copay Amount: {copay.FlatCopayAmount ? `$${copay.FlatCopayAmount}` : 'n/a'}
                  </Text>
                  <Text>
                    Flat Copay Amount First:{' '}
                    {copay.FlatCopayAmountFirst ? `$${copay.FlatCopayAmountFirst}` : 'n/a'}
                  </Text>
                  <Text>Maximum Copay Tier: {copay.MaximumCopayTier ?? 'n/a'}</Text>
                  <Text>
                    Maximum Copay Amount:{' '}
                    {copay.MaximumCopayAmount ? `$${copay.MaximumCopayAmount}` : 'n/a'}
                  </Text>
                  <Text>
                    Minimum Copay Amount:{' '}
                    {copay.MinimumCopayAmount ? `$${copay.MinimumCopayAmount}` : 'n/a'}
                  </Text>
                  <Text>
                    Out of Pocket Maximum:{' '}
                    {copay.OutOfPocketMaximum ? `$${copay.OutOfPocketMaximum}` : 'n/a'}
                  </Text>
                  <Text>
                    Out of Pocket Minimum:{' '}
                    {copay.OutOfPocketMinimum ? `$${copay.OutOfPocketMinimum}` : 'n/a'}
                  </Text>
                  <Text>
                    % Copay Amount:
                    {copay.PercentCopayAmount
                      ? ` ${copay.PercentCopayAmount * oneHundredPercent}%`
                      : ' n/a'}
                  </Text>
                </ListItem>
              )) ?? 'n/a'}
            </List>
            <Text bold>Coverage Factors:{!coverage.Restrictions && ' n/a'}</Text>
            {coverage.Restrictions && (
              <List>
                <ListItem>Age Limit: {coverage.Restrictions.HasAgeLimit ? 'yes' : 'no'}</ListItem>
                <ListItem>
                  Age Limit Restrictions:{' '}
                  {coverage?.Restrictions?.AgeLimitRestrictions?.map(
                    ageLimit =>
                      `${ageLimit.MinimumAge} ${
                        ageLimit.MinimumAgeUnit === 1 ? 'days' : 'years'
                      } to ${ageLimit.MaximumAge} ${
                        ageLimit.MaximumAgeUnit === 1 ? 'days' : 'years'
                      } `,
                  ).join(', ') ?? 'n/a'}
                </ListItem>
                <ListItem>
                  Gender Restriction:{' '}
                  {DoseSpotCoverageGenderRestriction[coverage.Restrictions.GenderRestriction] ??
                    'n/a'}
                </ListItem>
                <ListItem>
                  Has Quantity Limit: {coverage.Restrictions.HasQuantityLimit ? 'yes' : 'no'}
                </ListItem>
                <ListItem>
                  Quantity Limit Restrictions:{' '}
                  {coverage?.Restrictions?.QuantityLimitRestrictions?.map(
                    qualityLimit =>
                      `Maximum ${qualityLimit.MaximumAmount} ${[
                        DoseSpotQuanitityLimitRestrictionsAmountUnit[qualityLimit.AmountUnit],
                      ]} per ${
                        DoseSpotQuanitityLimitRestrictionsTimeUnit[qualityLimit.TimePeriodUnit]
                      }`,
                  ).join(', ') ?? 'n/a'}
                </ListItem>
                <ListItem>
                  Has Medical Necessity: {coverage.Restrictions.HasMedicalNecessity ? 'yes' : 'no'}
                </ListItem>
                <ListItem>
                  Has Prior Authorization:{' '}
                  {coverage?.Restrictions.HasPriorAuthorization ? 'yes' : 'no'}
                </ListItem>{' '}
                <ListItem>
                  Has Step Therapy: {coverage.Restrictions.HasStepTherapy ? 'yes' : 'no'}
                </ListItem>
                <ListItem>
                  Step Medication Restrictions:{' '}
                  {coverage?.Restrictions?.StepMedicationRestrictions?.map(
                    stepLimit => stepLimit.DisplayName,
                  ).join(' > ') ?? 'n/a'}
                </ListItem>
                <ListItem>Is Excluded: {coverage?.Restrictions.IsExcluded ? 'yes' : 'no'}</ListItem>
                <ListItem>
                  <Text>Messages:</Text>
                  <List>
                    {coverage.Restrictions?.Messages?.map(m => (
                      <ListItem key={m.Message}>{m.Message}</ListItem>
                    )) ?? 'n/a'}
                  </List>
                </ListItem>
                <ListItem>
                  <Text>Drug Resources:</Text>
                  <List>
                    {coverage.Restrictions?.DrugResources?.map(r => (
                      <ListItem key={r.Url}>
                        <LinkText component='a' href={r.Url}>
                          {r.Url}
                        </LinkText>
                      </ListItem>
                    )) ?? 'n/a'}
                  </List>
                </ListItem>
                <ListItem>
                  <Text>Summary Resources:</Text>
                  <List>
                    {coverage.Restrictions?.SummaryResources?.map(r => (
                      <ListItem key={r.Url}>
                        <LinkText component='a' href={r.Url}>
                          {r.Url}
                        </LinkText>
                      </ListItem>
                    )) ?? 'n/a'}
                  </List>
                </ListItem>
              </List>
            )}
            <Text bold>Alternatives:{!coverage.Alternatives && ' n/a'}</Text>
            <List>
              {coverage?.Alternatives?.map(alternative => (
                <ListItem key={alternative.Ndc} mb='xl'>
                  <Stack>
                    <Text>Name: {alternative.DisplayName}</Text>
                    <Text>
                      Formulary Status:{' '}
                      {alternative.RepresentativeFormularyCoverage?.FormularyStatusText}
                    </Text>
                    {alternative.RepresentativeFormularyCoverage?.CopayFactors?.map(
                      (copay, idx) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Stack key={idx} spacing='xs'>
                            <Text>Drug Specific: {copay.IsDrugSpecific ? 'yes' : 'no'}</Text>
                            <Text>Pharmacy Type: {DoseSpotPharmacyType[copay.PharmacyType]}</Text>
                            <Text>Days Supply: {copay.DaysSupply}</Text>
                            <Text>Copay Tier: {copay.CopayTier ?? 'n/a'}</Text>
                            <Text>
                              Flat Copay Amount:{' '}
                              {copay.FlatCopayAmount ? `$${copay.FlatCopayAmount}` : 'n/a'}
                            </Text>
                            <Text>
                              Flat Copay Amount First:{' '}
                              {copay.FlatCopayAmountFirst
                                ? `$${copay.FlatCopayAmountFirst}`
                                : 'n/a'}
                            </Text>
                            <Text>Maximum Copay Tier: {copay.MaximumCopayTier ?? 'n/a'}</Text>
                            <Text>
                              Maximum Copay Amount:{' '}
                              {copay.MaximumCopayAmount ? `$${copay.MaximumCopayAmount}` : 'n/a'}
                            </Text>
                            <Text>
                              Minimum Copay Amount:{' '}
                              {copay.MinimumCopayAmount ? `$${copay.MinimumCopayAmount}` : 'n/a'}
                            </Text>
                            <Text>
                              Out of Pocket Maximum:{' '}
                              {copay.OutOfPocketMaximum ? `$${copay.OutOfPocketMaximum}` : 'n/a'}
                            </Text>
                            <Text>
                              Out of Pocket Minimum:{' '}
                              {copay.OutOfPocketMinimum ? `$${copay.OutOfPocketMinimum}` : 'n/a'}
                            </Text>
                            <Text>
                              % Copay Amount:
                              {copay.PercentCopayAmount
                                ? ` ${copay.PercentCopayAmount * oneHundredPercent}%`
                                : ' n/a'}
                            </Text>
                          </Stack>
                        )
                      },
                    )}
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
