import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { patientsApi } from '../../../api'
import AHeading from '../../../components/atoms/AHeading'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'

export type ODeleteFileModalProps = {
  fileId: string
  fileName: string
  closeModal: () => void
}

const ODeleteFileModal = ({ fileId, fileName, closeModal }: ODeleteFileModalProps) => {
  const queryClient = useQueryClient()
  const { patientID = '' } = useParams<{ patientID: string }>()

  const deleteFile = useMutation(patientsApi.deleteFile)

  const onSubmit = async () => {
    await deleteFile.mutateAsync({ patientId: patientID, fileId })
    void queryClient.invalidateQueries()
    closeModal()
  }

  return (
    <div className='w-full'>
      <div className='flex flex-col'>
        {!deleteFile.isError && (
          <div className='flex flex-col'>
            <div className='w-full flex flex-row mb-4 items-center justify-center'>
              <AHeading>Delete File</AHeading>
            </div>
            <div className='w-full flex flex-row mb-4'>
              {`Are you sure you want to delete "${fileName}"?`}
            </div>
            <ODSPrimaryButton
              type='submit'
              message='Delete'
              className='inline-flex justify-center w-full mt-6'
              onClick={() => onSubmit()}
              loading={deleteFile.isLoading}
            />
          </div>
        )}
        {deleteFile.isError && (
          <div className='flex flex-col'>
            <div className='w-full flex flex-row mb-8 items-center justify-center'>
              <AHeading>Delete Error</AHeading>
            </div>
            <div className='flex flex-col items-start justify-start w-full mb-8'>
              <p className='text-base leading-8'>
                Unfortunately this file could not be deleted at this time, please send a message to
                the{' '}
                <a href='slack://channel?team=TRN06CLEM&id=C036LF9E5FX'>#patient-product-support</a>{' '}
                channel in Slack.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ODeleteFileModal
