import { PropsWithChildren } from 'react'
import { FieldValues } from 'react-hook-form'
import { Path, UseFormRegister } from 'react-hook-form-latest'
import AOption from '../../components/atoms/AOption'
import ASelect from '../../components/atoms/ASelect'
import ODSInput from '../../components/ods/ODSInput'

export type AProfileItemEditProps<T extends FieldValues> = {
  register: UseFormRegister<T>
  field: Path<T>
} & (
  | {
      type: 'text'
    }
  | {
      type: 'selection'
      options: {
        name: string
        value: string
        display?: boolean
      }[]
    }
)

export type AProfileItemProps = {
  name: string
  value: string | undefined | null
  displayEdit?: boolean
}

export type AProfileSectionProps = {
  title: string | JSX.Element | undefined
  content?: JSX.Element | undefined
}

export function AProfileItemEdit<T extends FieldValues>(props: AProfileItemEditProps<T>) {
  if (props.type === 'text') {
    const { register, field } = props
    return <ODSInput {...register(field)} />
  } else if (props.type === 'selection') {
    const { options, register, field } = props
    return (
      <ASelect {...register(field)}>
        {options.map(({ name, value, display = true }) =>
          display ? (
            <AOption key={value} value={value}>
              {name}
            </AOption>
          ) : null,
        )}
      </ASelect>
    )
  }

  return null
}

export const AProfileItem = ({
  name,
  value,
  displayEdit,
  children,
}: PropsWithChildren<AProfileItemProps>) => {
  return (
    <div key={name} className='sm:col-span-1'>
      <dt className='text-sm font-medium text-gray-500'>{name}</dt>
      {displayEdit ? (
        <>{children}</>
      ) : (
        <dd className={`mt-1 text-sm ${value ? 'text-gray-900' : 'text-gray-300'}`}>
          {value || 'N/A'}
        </dd>
      )}
    </div>
  )
}

const AProfileSection = ({ title, content, children }: PropsWithChildren<AProfileSectionProps>) => {
  return (
    <>
      {Boolean(title) && (
        <div className='pb-4'>
          <div className='border-b-2 pb-2 text-sm font-medium text-gray-500'>{title}</div>
        </div>
      )}
      {content}
      <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>{children}</dl>
      <div className='pt-8' />
    </>
  )
}

export default AProfileSection
