import {
  Checkbox,
  CheckboxGroup,
  Grid,
  Group,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from '@shared/components'
import {
  PatientSelfDeterminesToStopReason,
  patientSelfDeterminesToStopReasonMapping,
} from '@shared/types'
import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

const PatientSelfDeterminesToStopCheckbox = ({
  reasonValue,
  label,
  form,
}: {
  reasonValue: PatientSelfDeterminesToStopReason
  label: string
  form: DischargeNoteForm
}) => {
  const isReasonChecked = form.values.patient_self_determines_to_stop_reasons?.includes(reasonValue)
  const reasonCheckbox = (
    <Checkbox
      label={label}
      value={reasonValue}
      checked={isReasonChecked}
      onClick={e => {
        if (!e.currentTarget.checked) {
          form.setFieldValue('patient_self_determines_to_stop_primary_reason', undefined)
        }

        if (
          e.currentTarget.checked &&
          !form.values.patient_self_determines_to_stop_primary_reason
        ) {
          form.setFieldValue('patient_self_determines_to_stop_primary_reason', reasonValue)
        }
      }}
    />
  )

  return isReasonChecked ? (
    <Grid align='center'>
      <Grid.Col span={9}>{reasonCheckbox}</Grid.Col>
      <Grid.Col span={3} sx={{ alignItems: 'end' }}>
        <Group w='100%' position='right'>
          <Switch
            size='sm'
            label='Primary'
            checked={form.values.patient_self_determines_to_stop_primary_reason === reasonValue}
            onChange={e => {
              if (e.currentTarget.checked) {
                form.setFieldValue('patient_self_determines_to_stop_primary_reason', reasonValue)
              } else {
                form.setFieldValue('patient_self_determines_to_stop_primary_reason', undefined)
              }
            }}
          />
        </Group>
      </Grid.Col>
    </Grid>
  ) : (
    reasonCheckbox
  )
}

export const PatientSelfDeterminesToStopSection = ({
  form,
  optional,
}: {
  form: DischargeNoteForm
  optional: boolean
}) => {
  return form.values.reasons_for_discharge?.includes('patient_self_determines_to_stop') ? (
    <Stack spacing='md'>
      <CheckboxGroup
        {...form.getInputProps('patient_self_determines_to_stop_reasons')}
        label={formLabel('Reasons for self-determining end of care', optional)}
        spacing='sm'
      >
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.financial}
          reasonValue='financial'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.incarceration}
          reasonValue='incarceration'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.discontinue_medication}
          reasonValue='discontinue_medication'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.dissatisfaction_with_services}
          reasonValue='dissatisfaction_with_services'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.platform_barriers}
          reasonValue='platform_barriers'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.accessibility_barriers}
          reasonValue='accessibility_barriers'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.transfer_to_hloc}
          reasonValue='transfer_to_hloc'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.hospitalization}
          reasonValue='hospitalization'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.relocation_out_of_state}
          reasonValue='relocation_out_of_state'
          form={form}
        />
        <PatientSelfDeterminesToStopCheckbox
          label={patientSelfDeterminesToStopReasonMapping.completed_medication}
          reasonValue='completed_medication'
          form={form}
        />
      </CheckboxGroup>

      <RadioGroup
        {...form.getInputProps('patient_self_determines_to_stop_discussed_with_clinician')}
        label={formLabel('Discussed reasons with clinician?', optional)}
        orientation='horizontal'
      >
        <Radio
          label='Yes'
          value='yes'
          sx={{
            flexGrow: 1,
          }}
        />
        <Radio
          label='No'
          value='no'
          sx={{
            flexGrow: 1,
          }}
        />
      </RadioGroup>
    </Stack>
  ) : null
}
