import { useForm } from '@mantine/form'
import {
  Banner,
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  Stack,
  validateWith,
} from '@shared/components'
import { Invoice, InvoiceType } from '@shared/types'
import { isRequired } from '../../../utils/formValidation'
import { useEmrMutation, useInvalidateQuery } from '../../../utils/hooks'

type InvoiceTypeForm = {
  type: InvoiceType | null
}

type AddInvoiceTypeModalProps = {
  invoice: Invoice
  opened: boolean
  onClose: () => void
}

export const AddInvoiceTypeModal = ({ invoice, opened, onClose }: AddInvoiceTypeModalProps) => {
  const invalidateQuery = useInvalidateQuery()

  const form = useForm<InvoiceTypeForm>({
    initialValues: {
      type: null,
    },
    validate: {
      type: validateWith(isRequired),
    },
  })

  const updateInvoiceMutation = useEmrMutation('PUT /invoices/:invoiceId')

  const updateInvoice = async () => {
    if (form.validate().hasErrors) {
      return
    }

    // Adding the following check to get the type checker to pass
    if (!form.values.type) {
      return
    }

    await updateInvoiceMutation.mutateAsync(
      {
        params: { invoiceId: invoice.oid },
        data: {
          type: form.values.type,
        },
      },
      {
        onSuccess: () => {
          form.reset()
          void invalidateQuery('GET /invoices', {
            query: {
              patientId: invoice.patientId || '',
            },
          })
          onClose()
        },
      },
    )
  }

  return (
    <BetterModal
      opened={opened}
      onClose={() => {
        onClose()
        form.reset()
      }}
    >
      <ModalHeader
        onClose={() => {
          onClose()
          form.reset()
        }}
      >
        Add type to invoice
      </ModalHeader>
      <ModalContent>
        <Stack>
          {updateInvoiceMutation.isError && (
            <Banner
              type='error'
              label='Could not update the invoice type, please try again later.'
            />
          )}
          <RadioGroup {...form.getInputProps('type')}>
            <Radio label='Copay' value='copay' />
            <Radio label='Subscription' value='subscription' />
            <Radio label='No show' value='no-show' />
          </RadioGroup>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton
            onClick={() => {
              onClose()
              form.reset()
            }}
          >
            Cancel, go back
          </SecondaryButton>
          <PrimaryButton onClick={updateInvoice} loading={updateInvoiceMutation.isLoading}>
            Add type
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}
