import { Navigate, Route, Routes } from 'react-router-dom'
import { useAccess } from '../../utils/hooks/use-access'
import OnCallScheduleContent from './schedule/OnCallScheduleContent'
import { PWorkingHours } from './workingHours/PWorkingHours'

export enum AdminPage {
  Schedule,
  WorkingHours,
}

const PAdmin = () => {
  const hasAccess = useAccess()

  return (
    <Routes>
      <Route path='/schedule' element={<OnCallScheduleContent />} />
      <Route
        path='/working-hours'
        element={
          hasAccess.workingHours ? <PWorkingHours /> : <Navigate replace to='/admin/schedule' />
        }
      />
      <Route path='*' element={<Navigate replace to='/admin/schedule' />} />
    </Routes>
  )
}

export default PAdmin
