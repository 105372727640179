import React, { PropsWithChildren } from 'react'

export type AOptionType = {
  value: string | number
  disabled?: boolean
}

const AOption = ({ children, value, disabled }: PropsWithChildren<AOptionType>) => (
  <option disabled={disabled} value={value}>
    {children}
  </option>
)

export default AOption
