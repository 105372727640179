import {
  ArrowLeftCircleIcon,
  Group,
  Modal,
  PrimaryButton,
  Stack,
  TertiaryButton,
  TitleThree,
  isAnySelected,
  showNotification,
  useCarousel,
  validateWith,
} from '@shared/components'
import {
  EMRTaskDecorated,
  EMRTaskPod,
  EMRTaskTypeMap,
  getOpheliaHttpError,
  getPodOptions,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { useEmployees, useOnCallEngineer, usePatientOcp } from '../../../utils/hooks'
import { AssigneeForm, AssigneeFormProvider, useAssigneeForm } from './AssigneeForm'
import { TaskNoteForm, useNoteForm } from './TaskNoteForm'
import { TaskProgress } from './TaskProgress'
import { TimeSpentForm, useTimeSpentForm } from './TimeSpentForm'

export type EditAssigneeModalProps = {
  task: EMRTaskDecorated
  onClose: () => void
}

const getModalTitle = (task: EMRTaskDecorated) => {
  const firstName = task.metadata.patientFirstName
  return `Edit assignee for ${firstName}'s issue`
}

export const EditAssigneeModal = ({ task, onClose }: EditAssigneeModalProps) => {
  const { next, prev, current, step, total } = useCarousel(
    task.employeeId ? ['time-spent', 'assign', 'note'] : ['assign', 'note'],
  )

  const timeSpentForm = useTimeSpentForm()
  const assignForm = useAssigneeForm({
    initialValues: { pod: task.pod ?? '', employeeId: task.employeeId ?? '' },
    validate: {
      pod: validateWith(isAnySelected(getPodOptions(), 'Required')),
    },
  })
  const noteForm = useNoteForm()

  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))
  const {
    onCallEngineerQuery: { data: onCallEngineer, isLoading: isOnCallEngineerLoading },
  } = useOnCallEngineer()

  const {
    ocpQuery: { data: currentOcp, isLoading: isCurrentOcpLoading },
  } = usePatientOcp(task.patientId)

  const getAssigneeId = () => {
    if (assignForm.values.pod === 'ocp') {
      return currentOcp?.employeeId
    }

    if (assignForm.values.pod === 'engineer' && !assignForm.values.employeeId) {
      return onCallEngineer?.oid
    }
    return assignForm.values.employeeId
  }

  const onSubmit = () => {
    if (!assignForm.validate().hasErrors) {
      updateTaskMutation.mutate(
        {
          params: { taskId: task.oid },
          data: {
            task: {
              pod: assignForm.values.pod as EMRTaskPod,
              employeeId: getAssigneeId(),
            },
            note: noteForm.values.note ? { text: noteForm.values.note } : undefined,
            timeEntry:
              timeSpentForm.values.minutes === ''
                ? undefined
                : {
                    isBillableCoCM: Boolean(timeSpentForm.values.isBillableCoCM),
                    minutes: timeSpentForm.values.minutes,
                  },
          },
        },
        {
          onSuccess: () => {
            const firstName = task.metadata.patientFirstName
            const employeeName =
              employeesQuery.data?.find(employee => employee.oid === assignForm.values.employeeId)
                ?.name || ''
            const taskLabel =
              EMRTaskTypeMap.find(type => type.value === task.type)?.label || 'issue'
            const createdAt = dayjs(task.createdAt).format('MM/DD/YYYY')

            const message = employeeName
              ? `${employeeName} has been assigned to ${firstName}'s ${taskLabel.toLowerCase()} issue from ${createdAt}`
              : `${firstName}'s ${taskLabel.toLowerCase()} issue from ${createdAt} has been reassigned`

            showNotification({
              message,
              variant: 'success',
            })

            onClose()
          },
        },
      )
    }
  }

  const isLoading =
    employeesQuery.isLoading ||
    updateTaskMutation.isLoading ||
    isOnCallEngineerLoading ||
    isCurrentOcpLoading

  const title = getModalTitle(task)

  if (current === 'time-spent') {
    return (
      <TimeSpentForm
        form={timeSpentForm}
        task={task}
        title={title}
        step={{ current: step, total }}
        onClose={onClose}
        next={next}
      />
    )
  }

  if (current === 'note') {
    return (
      <TaskNoteForm
        title={title}
        task={task}
        step={{ current: step, total }}
        onBack={prev}
        onClose={onClose}
        form={noteForm}
        error={getOpheliaHttpError(
          updateTaskMutation.error,
          'Something went wrong, try again later.',
        )}
        footer={
          <PrimaryButton loading={updateTaskMutation.isLoading} onClick={onSubmit}>
            Submit
          </PrimaryButton>
        }
      />
    )
  }

  const isNotFirstStep = step > 1

  return (
    <Modal
      opened
      onClose={onClose}
      title={
        <Group>
          {isNotFirstStep && (
            <TertiaryButton ml='xs' leftIcon={<ArrowLeftCircleIcon styled />} onClick={prev} />
          )}
          <TitleThree>{title}</TitleThree>
        </Group>
      }
      trapFocus={!task.pod}
      footer={
        <Group position='right'>
          <PrimaryButton
            loading={isLoading}
            onClick={() => {
              if (!assignForm.validate().hasErrors) {
                next()
              }
            }}
          >
            Next
          </PrimaryButton>
        </Group>
      }
    >
      {employeesQuery.isLoading ? (
        <div>Loading...</div>
      ) : (
        <Stack>
          <TaskProgress step={step} total={total} />
          <AssigneeFormProvider form={assignForm}>
            <AssigneeForm
              onCallEngineer={onCallEngineer}
              currentOcp={currentOcp}
              isLoading={isLoading}
            />
          </AssigneeFormProvider>
        </Stack>
      )}
    </Modal>
  )
}
