import {
  Checkbox,
  Grid,
  Select,
  Text,
  Textarea,
  TextInput,
  TitleTwo,
  TooltipLabel,
} from '@shared/components'
import {
  DispenseUnitType,
  DOSESPOT_DIRECTIONS_CHAR_LIMIT,
  DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT,
  DoseSpotMedication,
  DoseSpotMedicationSearchResult,
} from '@shared/types'
import { isControlledSubstance } from '@shared/utils'
import { useQuery } from 'react-query'
import { prescriptionsApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { useFavoriteFormContext } from './formHelpers'
import { MedicationSearchBar } from './MedicationSearchBar'

export type SaveFavoriteFormData = {
  favoriteName: string
  medication?: DoseSpotMedicationSearchResult
  full_medication?: DoseSpotMedication
  medication_strength: string
  medication_quantity: string
  medication_days_supply: string
  medication_refills: string
  substitutionAllowed: boolean
  pharmacyId?: number
  directions: string
  notes: string
  dispensableDrugId?: number
}

export type SaveFavoriteFormProps = {
  type: 'edit' | 'save'
  error: string
  loading: boolean
}

export const SaveFavoriteForm = ({ type, error, loading }: SaveFavoriteFormProps) => {
  const form = useFavoriteFormContext()

  const { full_medication } = form.values
  const { medication } = form.values
  const { medication_strength } = form.values

  useQuery(
    ['prescriptionsApi.getMedication', medication],
    () => {
      return prescriptionsApi.getMedication({
        medication: medication?.Name || '',
        medication_strength,
        dispensableDrugId: medication?.DispensableDrugId,
      })
    },
    {
      onSuccess: (fullMedication: DoseSpotMedication) => {
        if (isControlledSubstance(fullMedication.Schedule)) {
          form.setFieldValue('medication_refills', '0')
        }
        form.setFieldValue('full_medication', fullMedication)
      },
      enabled: Boolean(medication) && Boolean(medication_strength),
    },
  )

  if (loading) {
    return <ALoadingSpinner />
  }

  return (
    <>
      <TitleTwo>{type === 'save' ? 'Save as favorite' : 'Edit favorite'}</TitleTwo>
      <Grid>
        <Grid.Col span={12}>
          <TextInput {...form.getInputProps('favoriteName')} label='Name' />
        </Grid.Col>
        <Grid.Col span={12}>
          <MedicationSearchBar
            {...form.getInputProps('medication')}
            value={medication}
            onChange={item => {
              form.setFieldValue('medication', item)
              form.setFieldValue('medication_strength', item?.Strength[0]?.Strength || '')
              if (!item) {
                form.setFieldValue('medication_refills', '')
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            {...form.getInputProps('medication_strength')}
            label='Strength'
            data={medication ? medication.Strength.map(strength => strength.Strength) : []}
            disabled={!medication}
            placeholder='Select one...'
            defaultValue={medication_strength}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_refills', {})}
            label={
              <TooltipLabel
                label={
                  isControlledSubstance(full_medication?.Schedule)
                    ? `Schedule ${full_medication?.Schedule} drugs may not enter a refill value.`
                    : undefined
                }
              >
                Refills
              </TooltipLabel>
            }
            disabled={!full_medication || isControlledSubstance(full_medication.Schedule)}
            placeholder={isControlledSubstance(full_medication?.Schedule) ? '0' : '0, 1, or 2'}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_quantity')}
            label={`Dispense quantity ${
              full_medication?.DispenseUnitId
                ? ` (${DispenseUnitType[full_medication.DispenseUnitId]})`
                : ''
            }`}
            disabled={!medication}
            placeholder='e.g. 28'
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            {...form.getInputProps('medication_days_supply')}
            label='Days supply'
            disabled={!medication}
            placeholder='e.g. 7'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            {...form.getInputProps('substitutionAllowed')}
            label='Allow substitution'
            checked={form.values.substitutionAllowed}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            {...form.getInputProps('directions')}
            minRows={2}
            autosize
            maxLength={DOSESPOT_DIRECTIONS_CHAR_LIMIT}
            label='Directions'
            placeholder='e.g. Place one and a half (1.5) films under tongue daily as instructed'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            label='Pharmacy notes'
            {...form.getInputProps('notes')}
            minRows={4}
            maxLength={DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT}
            placeholder='Justification for early refill request, justification for change in dose, stating if you are a licensed out of state prescriber, etc.'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          {/* TODO: Move this to an error banner -Jacob*/}
          <Text color={({ error }) => error[0]}>{error}</Text>
        </Grid.Col>
      </Grid>
    </>
  )
}
