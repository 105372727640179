import { skipIfEmpty, Stack, validateWith } from '@shared/components'
import { EligibleInsuranceData, EncounterSubscriber, InsuranceType } from '@shared/types'
import { useEffect, useMemo } from 'react'
import {
  EncounterSection,
  InsuranceCardFormProvider,
  PrimarySubscriberForm,
  PrimarySubscriberFormProvider,
  useInsuranceCardForm,
  usePrimarySubscriberForm,
  validDateOfBirth,
} from '../../../../utils/encounterUtils'
import {
  isAbbreviatedUsState,
  isFourNumbers,
  isRequired,
  isZipCode,
} from '../../../../utils/formValidation'
import { CardHeader } from '../components/CardHeader'
import { BenefitsSection } from './BenefitsSection'
import { InsuranceCardSection } from './InsuranceCardSection'
import { PrimarySubscriberSection } from './PrimarySubscriberSection'

export type InsuranceSectionProps = {
  insuranceType: InsuranceType
  insuranceData?: EncounterSubscriber | null
  onSave: (primarySubscriberData: Partial<EncounterSubscriber>) => Promise<void>
  saving: boolean
  eligibleData?: EligibleInsuranceData
  setEditingSection: (
    section:
      | 'insurance card'
      | 'primary subscriber'
      | 'secondary insurance card'
      | 'secondary primary subscriber'
      | null,
  ) => void
  editingSection: EncounterSection | null
  editSectionButtonsEnabled: boolean
}

export const InsuranceSection = ({
  insuranceType,
  insuranceData,
  onSave,
  saving,
  eligibleData,
  setEditingSection,
  editingSection,
  editSectionButtonsEnabled,
}: InsuranceSectionProps) => {
  const isEditingInsuranceCardSection =
    (insuranceType === 'primary' && editingSection === 'insurance card') ||
    (insuranceType === 'other' && editingSection === 'secondary insurance card')
  const isEditingPrimarySubscriberSection =
    (insuranceType === 'primary' && editingSection === 'primary subscriber') ||
    (insuranceType === 'other' && editingSection === 'secondary primary subscriber')

  const insuranceCardData = insuranceData?.insurance_card

  const formattedInsuranceCardData = useMemo(() => {
    return {
      member_id: insuranceCardData?.member_id || '',
      payer_name: insuranceCardData?.payer_name || '',
      payer_id: insuranceCardData?.payer_id || '',
      group_number: insuranceCardData?.group_number || '',
      plan_name: insuranceCardData?.plan_name || '',
      plan_type: insuranceCardData?.plan_type,
      rx_bin: insuranceCardData?.rx_bin || '',
      rx_pcn: insuranceCardData?.rx_pcn || '',
      useMedicaidInfo: insuranceCardData?.useMedicaidInfo ?? false,
      medicaidInfo: {
        member_id: insuranceCardData?.medicaidInfo?.member_id ?? '',
        payer_id: insuranceCardData?.medicaidInfo?.payer_id ?? '',
        payer_name: insuranceCardData?.medicaidInfo?.payer_name ?? '',
      },
    }
  }, [insuranceCardData])

  const formattedPrimarySubscriberData: PrimarySubscriberForm = useMemo(() => {
    return {
      first_name: insuranceData?.first_name || '',
      last_name: insuranceData?.last_name || '',
      gender: insuranceData?.gender || '',
      date_of_birth: insuranceData?.date_of_birth || '',
      address: {
        address1: insuranceData?.address?.address1 || '',
        address2: insuranceData?.address?.address2 || '',
        city: insuranceData?.address?.city || '',
        state: insuranceData?.address?.state || '',
        zip_code: insuranceData?.address?.zip_code || '',
        zip_plus_four_code: insuranceData?.address?.zip_plus_four_code || '',
      },
      patient_relationship_to_subscriber_code:
        insuranceData?.patient_relationship_to_subscriber_code || '',
    }
  }, [insuranceData])

  const insuranceCardForm = useInsuranceCardForm({
    initialValues: formattedInsuranceCardData,
    validate: {
      member_id: validateWith(isRequired),
      payer_name: validateWith(isRequired),
      payer_id: validateWith(isRequired),
      plan_type: validateWith(isRequired),
    },
    clearInputErrorOnChange: false,
  })

  const primarySubscriberForm = usePrimarySubscriberForm({
    initialValues: formattedPrimarySubscriberData,
    validate: {
      first_name: validateWith(isRequired),
      last_name: validateWith(isRequired),
      gender: validateWith(isRequired),
      date_of_birth: validDateOfBirth,
      address: {
        address1: validateWith(isRequired),
        city: validateWith(isRequired),
        state: validateWith(isRequired, isAbbreviatedUsState),
        zip_code: validateWith(isRequired, isZipCode),
        zip_plus_four_code: validateWith(skipIfEmpty, isFourNumbers),
      },
      patient_relationship_to_subscriber_code: (value?: string) => {
        if (!value) {
          return 'Required'
        }
      },
    },
    clearInputErrorOnChange: false,
  })

  useEffect(() => {
    insuranceCardForm.setValues(formattedInsuranceCardData)
  }, [formattedInsuranceCardData])

  useEffect(() => {
    primarySubscriberForm.setValues(formattedPrimarySubscriberData)
  }, [formattedPrimarySubscriberData])

  useEffect(() => {
    insuranceCardForm.validate()
  }, [insuranceCardForm.values])

  useEffect(() => {
    primarySubscriberForm.validate()
  }, [primarySubscriberForm.values])

  return (
    <Stack spacing='lg'>
      <Stack>
        <CardHeader
          title={insuranceType === 'primary' ? 'Primary insurance' : 'Secondary insurance'}
          showButton={editSectionButtonsEnabled}
          buttonOnClick={() => {
            if (insuranceType === 'primary') {
              setEditingSection('insurance card')
            } else {
              setEditingSection('secondary insurance card')
            }
          }}
          errorCount={
            Object.keys(insuranceCardForm.errors).length +
            Object.keys(primarySubscriberForm.errors).length
          }
          buttonLabel='Edit'
        />
        <InsuranceCardFormProvider form={insuranceCardForm}>
          <InsuranceCardSection
            isEditing={isEditingInsuranceCardSection}
            onSave={insuranceCardForm.onSubmit(async () => {
              let { useMedicaidInfo } = insuranceCardForm.values
              if (insuranceCardForm.values.plan_type !== 'MC') {
                useMedicaidInfo = false
              }
              await onSave({ insurance_card: { ...insuranceCardForm.values, useMedicaidInfo } })
              setEditingSection(null)
            })}
            onCancel={() => {
              setEditingSection(null)
              insuranceCardForm.setValues(formattedInsuranceCardData)
              insuranceCardForm.validate()
            }}
            saving={saving}
          />
        </InsuranceCardFormProvider>
      </Stack>
      <BenefitsSection eligibleData={eligibleData} />
      <PrimarySubscriberFormProvider form={primarySubscriberForm}>
        <PrimarySubscriberSection
          showEditButton={editSectionButtonsEnabled}
          isEditing={isEditingPrimarySubscriberSection}
          onSave={primarySubscriberForm.onSubmit(async () => {
            await onSave(primarySubscriberForm.values)
            setEditingSection(null)
          })}
          onCancel={() => {
            setEditingSection(null)
            primarySubscriberForm.setValues(formattedPrimarySubscriberData)
          }}
          editOnClick={() => {
            if (insuranceType === 'primary') {
              setEditingSection('primary subscriber')
            } else {
              setEditingSection('secondary primary subscriber')
            }
          }}
          saving={saving}
        />
      </PrimarySubscriberFormProvider>
    </Stack>
  )
}
