import { useForm } from '@mantine/form'
import {
  Alert,
  AlertIcon,
  ChevronRightIcon,
  Group,
  InputWrapper,
  Modal,
  PrimaryButton,
  Stack,
  Text,
  isLength,
} from '@shared/components'
import { EMRTaskDecorated, EMRTaskResults } from '@shared/types'
import { EmployeeMentionNoteInput } from '../../../components/forms/EmployeeMentionNoteInput'
import { CloseTaskResultFields } from './CloseTaskResultFields'
import { TaskProgress } from './TaskProgress'

export type CloseTaskNoteFormProps = {
  note: string
  results: EMRTaskResults | null
}

type Props = {
  onClose: () => void
  onSubmit: (data: CloseTaskNoteFormProps) => void
  step: { current: number; total: number }
  task: EMRTaskDecorated
  isSubmitting: boolean
  error?: string
}

export const CloseTaskNoteForm = (props: Props) => {
  const { values, getInputProps, validate } = useForm<CloseTaskNoteFormProps>({
    initialValues: { note: '', results: props.task.results || null },
    validate: {
      note: isLength({ op: '>=', length: 1 }, 'Required'),
      results: results => {
        if (!results) {
          return
        }
        for (const result of Object.values(results)) {
          if (!result.value && !result.optional) {
            return 'Required'
          }
        }
      },
    },
  })

  return (
    <Modal
      opened
      onClose={() => {
        props.onClose()
      }}
      title='Close issue'
      footer={
        <Group position='right'>
          <PrimaryButton
            loading={props.isSubmitting}
            onClick={() => {
              if (!validate().hasErrors) {
                props.onSubmit(values)
              }
            }}
            rightIcon={<ChevronRightIcon />}
          >
            Yes, close
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <TaskProgress step={props.step.current} total={props.step.total} />
        <InputWrapper {...getInputProps('results')}>
          <CloseTaskResultFields task={props.task} getInputProps={getInputProps} />
        </InputWrapper>
        <EmployeeMentionNoteInput
          label='Wrap-up description'
          placeholder='1-2 sentences'
          explanation='Briefly summarize the issue and how it was resolved. This wrap-up description will appear in the patient care journey.'
          {...getInputProps('note')}
        />
        <Text size='xs' color={colors => colors.text[1]}>
          By closing this task, you acknowledge that the issue has been resolved and the task has
          been completed. Are you sure you want to proceed?
        </Text>
        {props.error && (
          <Alert icon={<AlertIcon />} variant='error'>
            {props.error}
          </Alert>
        )}
      </Stack>
    </Modal>
  )
}
