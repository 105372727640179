import { useForm } from '@mantine/form'
import { DateTimePicker, Group, Modal, PrimaryButton } from '@shared/components'
import { EMRTask, EMRTaskDecorated, EMRTaskModel } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'

export type CloseOutageTaskModalProps = {
  task: EMRTaskDecorated
  onClose: () => void
}

type Form = {
  endTime: string
}

export const CloseOutageTaskModal = ({ task, onClose }: CloseOutageTaskModalProps) => {
  // Need to do some type manipulation to pull an outage task from the decorated task
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { metadata, ...rest } = task
  const baseTask = rest as EMRTask<'outage'>

  const form = useForm<Form>({
    initialValues: {
      endTime: dayjs().toISOString(),
    },
  })

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))
  const closeTask = () => {
    const updatedTask: EMRTaskModel<'outage'> = {
      patientId: baseTask.patientId,
      employeeId: baseTask.employeeId,
      createdAt: baseTask.createdAt,
      createdBy: baseTask.createdBy,
      updatedAt: baseTask.updatedAt,
      priority: baseTask.priority,
      type: baseTask.type,
      status: 'closed',
      details: {
        ...baseTask.details,
        endTime: form.values.endTime,
      },
    }
    updateTaskMutation.mutate(
      {
        params: { taskId: task.oid },
        data: { task: updatedTask },
      },
      { onSuccess: onClose },
    )
  }

  return (
    <Modal
      opened
      onClose={onClose}
      title='Close outage'
      footer={
        <Group position='right'>
          <PrimaryButton onClick={closeTask}>Close</PrimaryButton>
        </Group>
      }
    >
      <DateTimePicker
        label='End time'
        placeholder='Select end time'
        explanation='When did this outage end?'
        {...form.getInputProps('endTime')}
      />
    </Modal>
  )
}
