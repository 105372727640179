import {
  Flex,
  Radio,
  RadioGroup,
  ScrollArea,
  Loader,
  Center,
  Stack,
  Group,
  Tooltip,
  SecondaryButton,
  TrashCanIcon,
  PrimaryButton,
  PencilIcon,
  CheckboxGroup,
  PackageIcon,
  SlashIcon,
  Text,
} from '@shared/components'
import { useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from '@mantine/core'

export const DocumentsColumn = ({
  list,
  selectedCollectionItem,
  setSelectedCollectionItem,
  editing,
  toggleIsEditing,
  onDelete,
  onTransfer,
  isLoading,
  isError,
}: {
  list: string[] | undefined
  selectedCollectionItem: string
  setSelectedCollectionItem: (value: string) => void
  editing: 'collections' | 'documents' | 'json' | null
  toggleIsEditing: () => void
  onDelete: (list: string[]) => void
  onTransfer: (list: string[]) => void
  isLoading: boolean
  isError: boolean
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const onClickEdit = () => {
    toggleIsEditing()
    setSelectedItems([])
  }

  const isItemsSelected = selectedItems.length > 0

  return (
    <Flex
      sx={({ other: { colors }, radius }) => ({
        flex: 8,
        backgroundColor: colors.background[1],
        borderRadius: radius.sm,
        overflow: 'hidden',
      })}
    >
      {isLoading || isError ? (
        <Center w='100%'>
          <Loader />
        </Center>
      ) : (
        <Stack w='100%' spacing='sm'>
          <Group m='sm' position='apart'>
            <Text bold>Documents</Text>
            <Group spacing='sm'>
              {editing === 'documents' ? (
                <>
                  <Tooltip label='Delete'>
                    <SecondaryButton
                      onClick={() => onDelete(selectedItems)}
                      disabled={!isItemsSelected}
                      leftIcon={<TrashCanIcon />}
                      size='xs'
                    />
                  </Tooltip>
                  <Tooltip label='Transfer'>
                    <SecondaryButton
                      onClick={() => onTransfer(selectedItems)}
                      disabled={!isItemsSelected}
                      leftIcon={<PackageIcon />}
                      size='xs'
                    />
                  </Tooltip>
                  <Tooltip label='Cancel edit'>
                    <PrimaryButton onClick={onClickEdit} leftIcon={<SlashIcon />} size='xs' />
                  </Tooltip>
                </>
              ) : (
                <Tooltip label='Edit'>
                  <PrimaryButton
                    disabled={editing !== null}
                    onClick={onClickEdit}
                    leftIcon={<PencilIcon />}
                    size='xs'
                  />
                </Tooltip>
              )}
            </Group>
          </Group>
          <ScrollArea mx='xs' scrollbarSize='0.25rem'>
            <RadioGroup px='xs' value={selectedCollectionItem} onChange={setSelectedCollectionItem}>
              <CheckboxGroup spacing='xs' value={selectedItems} onChange={setSelectedItems}>
                {list?.map(value => (
                  <Radio
                    disabled={editing === 'json'}
                    iconless
                    key={value}
                    value={value}
                    label={
                      <Group spacing='sm' noWrap>
                        {editing === 'documents' && (
                          <Checkbox color='dark' sx={{ pointerEvents: 'all' }} value={value} />
                        )}
                        {value}
                      </Group>
                    }
                  />
                ))}
              </CheckboxGroup>
            </RadioGroup>
          </ScrollArea>
        </Stack>
      )}
    </Flex>
  )
}
