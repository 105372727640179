import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  useBanner,
} from '@shared/components'
import { useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useEmrMutation } from '../../../utils/hooks'

type DeleteDrugScreenModalProps = {
  onClose: () => void
  drugScreenId: string
  patientId: string
}

const DeleteDrugScreenContent = ({
  onClose,
  drugScreenId,
  patientId,
}: DeleteDrugScreenModalProps) => {
  const queryClient = useQueryClient()
  const deleteUDS = useEmrMutation('DELETE /patient/:patientId/drugScreens/:id')
  const { showBanner } = useBanner()

  const onSubmit = () => {
    deleteUDS.mutate(
      { params: { patientId, id: drugScreenId } },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(
            emrApi.getQuery('GET /patient/:patientId/drugScreens', {
              params: { patientId },
            })[0],
          )
          onClose()
        },
        onError: () => {
          showBanner({
            type: 'error',
            dismissable: true,
            label: 'Something went wrong, unable to delete drug screen',
          })
        },
      },
    )
  }

  return (
    <>
      <ModalHeader onClose={onClose}>Delete drug screen</ModalHeader>
      <ModalContent>Are you sure you want to delete this drug screen?</ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don&apos;t delete</SecondaryButton>
          <PrimaryButton loading={deleteUDS.isLoading} onClick={onSubmit}>
            Yes, delete
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const DeleteDrugScreenModal = ({
  opened,
  ...props
}: DeleteDrugScreenModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <DeleteDrugScreenContent {...props} />
    </BetterModal>
  )
}
