import {
  Box,
  EditIcon,
  Group,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TrashIcon,
  useMantineTheme,
} from '@shared/components'
import { PrescriptionFavorite } from '@shared/types'
import { dayjs } from '@shared/utils'
import pluralize from 'pluralize'
import { PrescriptionRecent } from './PatientRecentsTabContent'

export type QuickApplyListItemProps = {
  medicationName: PrescriptionFavorite['medicationName']
  quantity: PrescriptionFavorite['quantity']
  doseForm?: PrescriptionFavorite['doseForm']
  strength: PrescriptionFavorite['strength']
  daysSupply: PrescriptionFavorite['daysSupply']
  favoriteName: PrescriptionFavorite['favoriteName']
  onClick: () => void
  onDelete?: () => void
  onEdit?: () => void
  writtenDate?: PrescriptionRecent['writtenDate']
}
const QuickApplyListItem = ({
  medicationName,
  quantity,
  doseForm,
  strength,
  daysSupply,
  favoriteName,
  onClick,
  onDelete,
  onEdit,
  writtenDate,
}: QuickApplyListItemProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const defaultDoseForm = 'units'
  let formattedDoseForm
  if (doseForm) {
    const dose = doseForm?.split(',')[0]
    formattedDoseForm = pluralize(dose ?? '', quantity)
  } else {
    formattedDoseForm = defaultDoseForm
  }

  const convertWrittenDate = (date: Date) => {
    return dayjs(date).format('MMM D YYYY').toUpperCase()
  }

  return (
    <Group
      sx={theme => ({
        paddingRight: theme.spacing.md,
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        borderColor: theme.other.colors.actions[0],
        borderRadius: '4px',
        cursor: 'pointer',
        flexWrap: 'nowrap',
        '&:hover': { backgroundColor: theme.other.colors.background[2] },
      })}
    >
      <Box
        sx={theme => ({
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.sm,
          paddingLeft: theme.spacing.md,
          paddingRight: theme.spacing.md,
          cursor: 'pointer',
          flexGrow: 1,
          marginRight: 'auto',
          overflow: 'hidden',
        })}
      >
        <Stack spacing={0} onClick={onClick}>
          <Text bold lineClamp={1}>
            {favoriteName}
          </Text>
          <Text size='xs' component='p' lineClamp={1}>
            {medicationName} {strength}{' '}
            {quantity && doseForm ? `– ${quantity} ${formattedDoseForm} ` : ' '}
            {daysSupply ? `– ${daysSupply} ${pluralize('day', daysSupply)}` : ''}
          </Text>
        </Stack>
      </Box>
      <Group noWrap spacing='sm'>
        {onDelete && <SecondaryButton size='sm' onClick={onDelete} leftIcon={<TrashIcon />} />}
        {onEdit && <PrimaryButton size='sm' onClick={onEdit} leftIcon={<EditIcon />} />}
      </Group>
      {writtenDate && (
        <Text size='xs' bold color={colors.text[1]}>
          {convertWrittenDate(writtenDate)}
        </Text>
      )}
    </Group>
  )
}
export default QuickApplyListItem
