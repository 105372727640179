import {
  AlertIcon,
  ArchiveIcon,
  DrawerHeader,
  Flex,
  Group,
  InboxIcon,
  MoonIcon,
  RepeatIcon,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  UserIcon,
  showNotification,
} from '@shared/components'
import { EMRTaskDecorated, EMRTaskTypeMap } from '@shared/types'
import { dayjs, name, template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { emrApi } from '../../../../api'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatientGlobal } from '../../../../utils/hooks/use-patient-global'
import { useSidePane } from '../../../../utils/hooks/use-side-pane'
import { useTasksCache } from '../../../../utils/hooks/use-tasks-cache'
import { FixedDrawerScrollArea } from '../FixedDrawerScrollArea'
import { CurrentTaskOperation } from '../TaskTable'
import { Actions } from '../actions/Actions'
import { NotesLog } from './NotesLog'
import { TaskStatusPill } from './NotesNoteAddedEntry'
import { SMSNotesContent } from './SMSNotesContent'

export type NotesDrawerProps = {
  taskId: string
  onBack?: () => void
  onClose: () => void
}

const getTaskTitle = (task: EMRTaskDecorated) => {
  const type = EMRTaskTypeMap.find(type => type.value === task.type)?.label?.toLowerCase()
  const createdAt = dayjs(task.createdAt).format('MM/DD/YYYY')

  return task.metadata.patientFirstName === 'n/a'
    ? template('{type} issue from {createdAt}', {
        type: capitalize(type),
        createdAt,
      })
    : template("{patientFirstName}'s {type} issue from {createdAt}", {
        patientFirstName: capitalize(task.metadata.patientFirstName),
        type,
        createdAt,
      })
}

export const NotesDrawerContent = ({ taskId, onBack, onClose }: NotesDrawerProps) => {
  const tasksCache = useTasksCache()
  const [operation, setOperation] = useState<CurrentTaskOperation | undefined>()
  const { hidePane } = useSidePane()

  const { patientId: currentlyViewedPatientId } = usePatientGlobal()

  const [taskQueryKey, taskQueryFn] = emrApi.getQuery('GET /tasks/v2/:taskId', {
    params: {
      taskId: taskId || '',
    },
  })

  const taskQuery = useQuery(taskQueryKey, taskQueryFn, {
    enabled: Boolean(taskId),
  })

  const task = taskQuery.data

  useEffect(() => {
    // If we are looking at a patient's issue and navigate to a different patient's chart, close the issue pane
    if (currentlyViewedPatientId && task && currentlyViewedPatientId !== task.patientId) {
      hidePane()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyViewedPatientId])

  const [notesQueryKey, notesQueryFn] = emrApi.getQuery('GET /tasks/v2/:taskId/notes', {
    params: {
      taskId: taskId || '',
    },
  })
  const notesQuery = useQuery(notesQueryKey, notesQueryFn, {
    enabled: Boolean(taskId),
  })
  const taskPatientId = task?.patientId

  const patientQuery = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: {
        patientId: taskPatientId ?? '',
      },
    },
    {
      enabled: Boolean(taskPatientId),
    },
  )

  const taskPatient = patientQuery?.data
  const shouldDisplayTask = !taskQuery.isLoading && task
  const isSmsIssue = task?.type === 'inbound_sms'

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))

  const resetIssue = () => {
    // Reset for this drawer
    void taskQuery.refetch()
    void notesQuery.refetch()

    // Reset for the table
    if (task?.patientId) {
      tasksCache.invalidate(task?.patientId)
    }
  }

  const onCloseIssue = () => {
    if (task) {
      if (isSmsIssue) {
        updateTaskMutation.mutate(
          {
            params: { taskId: task.oid },
            data: {
              task: { status: 'closed', employeeId: task.employeeId },
              note: undefined,
              results: undefined,
              timeEntry: {
                isBillableCoCM: false,
                minutes: 0,
              },
            },
          },
          {
            onSuccess: () => {
              resetIssue()
              showNotification({
                variant: 'success',
                message: 'Issue has been closed',
              })
            },
          },
        )
      } else {
        setOperation({ operation: 'close', task })
      }
    }
  }

  return (
    <Flex direction='column' style={{ overflow: 'hidden', height: '100%' }}>
      <DrawerHeader onClose={onClose} onBack={onBack}>
        {task ? getTaskTitle(task) : <Skeleton h={16} w={200} />}
      </DrawerHeader>
      <Stack
        style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100%' }}
      >
        <Stack style={{ flexShrink: 0 }}>
          <Actions
            action={operation}
            onClose={() => {
              setOperation(undefined)
              resetIssue()
            }}
          />
          {taskQuery.isLoading && (
            <Stack spacing='md' px='md'>
              <Skeleton h={24} />
              <Skeleton h={48} />
            </Stack>
          )}
          {shouldDisplayTask && (
            <Stack
              sx={theme => ({
                paddingTop: theme.other.sizes.gap.xl,
                paddingBottom: theme.other.sizes.gap.xs,
                height: 'auto',
                overflow: 'hidden',
                flexShrink: 0,
              })}
            >
              <Group position='apart' mr='lg' ml='md'>
                <Stack spacing='xs' style={{ overflow: 'auto' }}>
                  <Text size='xs' color={({ text }) => text[1]}>
                    Patient chart
                  </Text>
                  <TertiaryButton
                    component={Link}
                    to={`/patients/${task.patientId}`}
                    target='_blank'
                    leftIcon={<UserIcon color={colors => colors.actions[0]} />}
                  >
                    {name({
                      first: task.metadata.patientFirstName,
                      last: task.metadata.patientLastName,
                    }).full()}
                  </TertiaryButton>
                </Stack>
                <Stack spacing='xs'>
                  <Text size='xs' color={({ text }) => text[1]}>
                    Assignee
                  </Text>
                  <TertiaryButton
                    onClick={() => setOperation({ operation: 'edit', task })}
                    leftIcon={
                      task.employeeId ? (
                        <RepeatIcon color={colors => colors.actions[0]} />
                      ) : (
                        <AlertIcon color={colors => colors.error[0]} />
                      )
                    }
                  >
                    {task.employeeId && task.metadata.employeeName
                      ? task.metadata.employeeName
                      : 'Unassigned'}
                  </TertiaryButton>
                </Stack>
                <Stack spacing='xs'>
                  <Group spacing='sm'>
                    <Text size='xs' color={({ text }) => text[1]}>
                      Status
                    </Text>
                    <TaskStatusPill status={task.status} />
                  </Group>
                  <Group>
                    {task.status === 'open' && (
                      <Group>
                        <TertiaryButton
                          onClick={() => setOperation({ operation: 'snooze', task })}
                          leftIcon={<MoonIcon color={colors => colors.actions[0]} />}
                        >
                          Snooze
                        </TertiaryButton>
                        <TertiaryButton
                          onClick={onCloseIssue}
                          leftIcon={<ArchiveIcon color={colors => colors.actions[0]} />}
                        >
                          Close
                        </TertiaryButton>
                      </Group>
                    )}
                    {task.status === 'snoozed' && (
                      <Group>
                        <TertiaryButton
                          onClick={() => setOperation({ operation: 'reopen', task })}
                          leftIcon={<InboxIcon color={colors => colors.actions[0]} />}
                        >
                          Open
                        </TertiaryButton>
                        <TertiaryButton
                          onClick={() => setOperation({ operation: 'close', task })}
                          leftIcon={<ArchiveIcon color={colors => colors.actions[0]} />}
                        >
                          Close
                        </TertiaryButton>
                      </Group>
                    )}
                    {task.status === 'closed' && (
                      <TertiaryButton
                        onClick={() => setOperation({ operation: 'reopen', task })}
                        leftIcon={<InboxIcon color={colors => colors.actions[0]} />}
                      >
                        Open
                      </TertiaryButton>
                    )}
                  </Group>
                </Stack>
              </Group>
            </Stack>
          )}
        </Stack>
        {isSmsIssue ? (
          <SMSNotesContent task={task} taskPatient={taskPatient} />
        ) : (
          task && (
            <FixedDrawerScrollArea>
              <NotesLog task={task} />
            </FixedDrawerScrollArea>
          )
        )}
      </Stack>
    </Flex>
  )
}
