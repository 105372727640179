import {
  ClockIcon,
  FileButton,
  Group,
  PaperClipIcon,
  PrimaryButton,
  SecondaryButton,
  SendIcon,
  Stack,
  TertiaryButton,
  Tooltip,
} from '@shared/components'
import React from 'react'
import { useFlags } from '../../../../../utils/hooks'

type OutboundSmsButtonGroupProps = {
  handleSubmit: () => void
  handleSelectAttachment: (file: File) => void
  handleTriggerShortcut: () => void
  resetAttachmentButtonRef: React.RefObject<() => void>
  disabled: boolean
  isLoading: boolean
}

export const OutboundSmsButtonGroup = React.forwardRef<HTMLDivElement, OutboundSmsButtonGroupProps>(
  (
    {
      handleSubmit,
      handleSelectAttachment,
      resetAttachmentButtonRef,
      handleTriggerShortcut,
      disabled,
      isLoading,
      ..._rest
    },
    sendButtonRef,
  ) => {
    const { scheduledOutboundSms } = useFlags()

    return (
      <Stack
        spacing={0}
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
        })}
        p='md'
      >
        <Group position='apart' w='auto'>
          <FileButton
            onChange={handleSelectAttachment}
            resetRef={resetAttachmentButtonRef}
            disabled={disabled}
            accept='image/png,image/jpeg,image/jpg,image/gif,text/vcard,text/x-vcard,application/vcard,application/pdf'
          >
            {props => (
              <TertiaryButton leftIcon={<PaperClipIcon />} {...props}>
                Attach
              </TertiaryButton>
            )}
          </FileButton>

          <Group position='right'>
            {scheduledOutboundSms && (
              <SecondaryButton leftIcon={<ClockIcon />} disabled={disabled}>
                Schedule
              </SecondaryButton>
            )}
            <Tooltip label='Shortcuts'>
              <SecondaryButton onClick={handleTriggerShortcut}>#</SecondaryButton>
            </Tooltip>

            <PrimaryButton
              ref={sendButtonRef}
              leftIcon={<SendIcon />}
              onClick={handleSubmit}
              loading={isLoading}
              disabled={disabled}
            >
              Send SMS
            </PrimaryButton>
          </Group>
        </Group>
      </Stack>
    )
  },
)

OutboundSmsButtonGroup.displayName = 'OutboundSmsButtonGroup'
