import { JsonInput, Loader } from '@shared/components'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

export const DosespotSection = () => {
  const { patientId } = usePatient()
  const dosespotQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/dosespot', { params: { patientId } }),
  )

  if (dosespotQuery.isLoading) {
    return <Loader />
  }

  return (
    <JsonInput
      w='100%'
      autosize
      value={JSON.stringify(dosespotQuery.data || dosespotQuery.error, undefined, 4)}
      variant='unstyled'
    />
  )
}
