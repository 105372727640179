import { Stack, Tabs, TitleThree } from '@shared/components'
import { useState } from 'react'
import { EligibilityContent } from './EligibilityContent'
import { FormularyContent } from './FormularyContent'
import { MedHistoryContent } from './MedHistoryContent'
import { RTPBContent } from './RTPBContent'

const prescriptionBenefitsHeaderTabs = ['eligbility', 'formulary', 'rtpb', 'med-history']

type PrescriptionBenefitsHeaderTabString = (typeof prescriptionBenefitsHeaderTabs)[number]

const PrescriptionBenefitsHeaderTab = ({
  value,
  children,
}: {
  value: PrescriptionBenefitsHeaderTabString
  children: JSX.Element | string
}) => <Tabs.Tab value={value}>{children}</Tabs.Tab>

export const PrescriptionBenefitsContent = () => {
  const [currentTab, setCurrentTab] = useState<PrescriptionBenefitsHeaderTabString>('eligbilities')

  const content = () => {
    switch (currentTab) {
      case 'eligbilities':
        return <EligibilityContent />
      case 'formulary':
        return <FormularyContent />
      case 'rtpb':
        return <RTPBContent />
      case 'med-history':
        return <MedHistoryContent />
    }
  }

  return (
    <Stack m='md'>
      <TitleThree>Prescription Benefits</TitleThree>
      <Tabs
        mt='md'
        keepMounted={false}
        value={currentTab}
        onTabChange={(value: string) => {
          setCurrentTab(value)
        }}
      >
        <Tabs.List>
          <PrescriptionBenefitsHeaderTab value='eligbilities'>
            Eligbilities
          </PrescriptionBenefitsHeaderTab>
          <PrescriptionBenefitsHeaderTab value='formulary'>Formulary</PrescriptionBenefitsHeaderTab>
          <PrescriptionBenefitsHeaderTab value='rtpb'>
            Realtime Rx Benefits
          </PrescriptionBenefitsHeaderTab>
          <PrescriptionBenefitsHeaderTab value='med-history'>
            Medication History
          </PrescriptionBenefitsHeaderTab>
        </Tabs.List>
      </Tabs>
      {content()}
    </Stack>
  )
}
