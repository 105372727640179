import { Box, Group, SecondaryButton, TextInput, TrashCanIcon } from '@shared/components'
import { ServiceLineTypeValue } from './ServiceLinesSection'

export const ServiceLine = ({
  index,
  onChange,
  type,
  values,
  onRemove,
}: {
  index: number
  onChange: (args: {
    type: ServiceLineTypeValue
    index: number
    key: string
    value: string
  }) => void
  onRemove: () => void
  type: ServiceLineTypeValue
  values: {
    code: string
    amount: string
    modifier: string
  }
}) => {
  return (
    <Group>
      <TextInput
        style={{ flex: 1 }}
        label='Code'
        value={values.code}
        onChange={value => {
          onChange({ type, index, key: 'code', value })
        }}
      />
      <TextInput
        style={{ flex: 1 }}
        label='Amount'
        value={values.amount}
        onChange={value => {
          onChange({ type, index, key: 'amount', value })
        }}
      />
      <TextInput
        style={{ flex: 1 }}
        label='Modifier'
        value={values.modifier}
        onChange={value => {
          onChange({ type, index, key: 'modifier', value })
        }}
      />
      {index === 0 ? (
        // match width of delete button to keep alignment
        <Box w={38} />
      ) : (
        // can only delete if there is more than one service line
        <SecondaryButton
          onClick={onRemove}
          style={{ alignSelf: 'flex-end' }}
          leftIcon={<TrashCanIcon />}
        />
      )}
    </Group>
  )
}
