import { Card, Pill, Stack, Text } from '@shared/components'
import { ReactNode } from 'react'

type SidebarEmptyStateProps = {
  pillMessage: string
  textMessage: string
  content?: ReactNode | undefined
}
export const SidebarEmptyState = ({
  pillMessage,
  textMessage,
  content,
}: SidebarEmptyStateProps) => {
  return (
    <Card>
      <Stack align='center' spacing='xs'>
        <Pill variant='filled' status='none'>
          {pillMessage}
        </Pill>
        <Text style={{ textAlign: 'center' }} size='xs' color={colors => colors.text[0]}>
          {textMessage}
        </Text>
        {content}
      </Stack>
    </Card>
  )
}
