import {
  Group,
  List,
  ListItem,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  useBanner,
} from '@shared/components'
import { useForm } from '@mantine/form'
import { isRequired } from '../../../../utils/formValidation'
import { validCharacters } from '@shared/utils'
import { getOpheliaHttpError } from '@shared/types'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useEmrMutation } from '../../../../utils/hooks'

export const TransferCollectionsModal = ({
  opened,
  onClose,
  onSubmit,
  patientId,
  collections,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  collections: string[]
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const { getInputProps, values, validate } = useForm({
    initialValues: { toPatientId: '' },
    validate: { toPatientId: isRequired },
  })

  const transferCollections = useEmrMutation('PUT /patient/:patientId/collections/transfer')

  return (
    <Modal
      title='Transfer collections'
      opened={opened}
      onClose={() => {
        if (!transferCollections.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={transferCollections.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={transferCollections.isLoading}
            onClick={async () => {
              if (!validate().hasErrors) {
                await transferCollections.mutateAsync(
                  {
                    params: { patientId },
                    data: { toPatientId: values.toPatientId, collections },
                  },
                  {
                    onError: error => {
                      showBanner({
                        type: 'error',
                        dismissable: true,
                        label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                      })
                    },
                  },
                )

                invalidate({ patientId, collections })
                invalidate({ patientId: values.toPatientId, collections })

                onClose()
                onSubmit()
              }
            }}
          >
            Transfer
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>The following collection(s) will be transfered:</Text>
        <List>
          {collections.map(value => (
            <ListItem key={value}>{value}</ListItem>
          ))}
        </List>
        <TextInput
          label='Patient ID'
          placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
          explanation='Patient to transfer selected collections'
          formatter={validCharacters.id}
          {...getInputProps('toPatientId')}
        />
      </Stack>
    </Modal>
  )
}
