import { Shipment } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class ShipmentsApi extends BaseApi {
  list = () => {
    return this.json<Shipment[]>({ method: 'GET', url: '/shipments/review-queue' })
  }

  send = (shipmentId: string) => {
    return this.json<Res>({ method: 'POST', url: `/shipments/review-queue/${shipmentId}` })
  }

  remove = (shipmentId: string) => {
    return this.json<Res>({ method: 'DELETE', url: `/shipments/review-queue/${shipmentId}` })
  }
}
