import { useForm } from '@mantine/form'
import {
  Colors,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  Textarea,
} from '@shared/components'
import { NonVisitEventModel, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { patientsApi } from '../../api'
import { useAuth } from '../../context/auth'
import { useAddToRegistryMutation } from '../../utils/hooks'

type AddPatientRegistryModalProps = {
  patient: Patient | null
  onCloseAddPatientModal: () => void
  setShowCocmBanner?: (bool: boolean) => void
}

const AddPatientRegistryModal = ({
  patient,
  onCloseAddPatientModal,
  setShowCocmBanner,
}: AddPatientRegistryModalProps) => {
  const form = useForm<{ note: string }>()
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  const addPatientToRegistry = useAddToRegistryMutation({ patient: patient ?? undefined })

  const createNonVisitEvent = useMutation(patientsApi.createNonVisitEvent, {
    onSuccess: () => {
      void queryClient.invalidateQueries('patientsApi.listNonVisitEvents')
      form.reset()
    },
  })

  const onSubmit = () => {
    if (!patient) {
      return
    }
    addPatientToRegistry.mutate(
      {
        data: { patientId: patient.oid },
      },
      {
        onSuccess: () => {
          if (form.values.note) {
            const nonVisitEvent: NonVisitEventModel = {
              title: 'CoCM treatment guidance',
              content: form.values.note,
              patientId: patient?.oid ?? '',
              authorId: currentUser?.oid ?? '',
              createdAt: dayjs().toISOString(),
            }
            createNonVisitEvent.mutate(nonVisitEvent)
          }
          onCloseAddPatientModal()
          if (setShowCocmBanner) {
            setShowCocmBanner(false)
          }
        },
      },
    )
  }

  return (
    <Modal
      opened={Boolean(patient)}
      onClose={onCloseAddPatientModal}
      title={`Add ${patient?.personalData.firstName} to CoCM registry`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onCloseAddPatientModal}>No, don&apos;t add</SecondaryButton>
          <PrimaryButton onClick={onSubmit}>Yes, add to registry</PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Textarea
          {...form.getInputProps('note')}
          minRows={4}
          label='Treatment guidance for TN (optional)'
          placeholder="Describe any issues that are relevant to the patient's behavioral and mental health"
          autosize
        />
        <Text size='xs' color={(colors: Colors) => colors.text[1]}>
          Are you sure you want to proceed?
        </Text>
      </Stack>
    </Modal>
  )
}

export default AddPatientRegistryModal
