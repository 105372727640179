import { SearchIcon, Select, SelectProps } from '@shared/components'
import { EMRTaskType, EMRTaskTypeMap, hasRole, isClinician } from '@shared/types'
import { matchesSearch } from '@shared/utils'
import { useMemo } from 'react'
import { useAuth } from '../../../context/auth'
import { SelectTypeItem } from './SelectTypeItem'

export const SelectIssueType = ({
  filterValues = [],
  ...props
}: Omit<SelectProps, 'data' | 'label' | 'icon' | 'searchable' | 'itemComponent' | 'filter'> & {
  filterValues?: EMRTaskType[]
}) => {
  const { currentUser } = useAuth()

  const showAll = !isClinician(currentUser) || hasRole(currentUser, 'stn', 'tn', 'ncm_tn')

  return (
    <Select
      searchable
      icon={<SearchIcon />}
      placeholder='Search by category, sub-category, or type...'
      label='Issue type'
      data={useMemo(
        () =>
          EMRTaskTypeMap.filter(task => {
            if (filterValues.includes(task.value)) {
              // Don't show tasks that are being filtered via the props
              return false
            }

            if (!task.active) {
              // Don't show tasks that are not currently allowed to be created
              return false
            }

            if (task.value === 'outage') {
              // Only show the outage task to engineers and admins
              return hasRole(currentUser, 'engineer', 'admin')
            }

            // Show all, or clinicians can only see clinician_visible tasks
            return showAll || task.clinician_visible
          }),
        [showAll, filterValues],
      )}
      itemComponent={SelectTypeItem}
      filter={(inputValue, item) =>
        matchesSearch({ searchValue: inputValue, itemValue: [item.label, item.category] })
      }
      {...props}
    />
  )
}
