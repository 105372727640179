import { EmrApi, EmrLunaApi, OpheliaClient } from '@shared/types'
import { BaseApiConfig, FormattedAxiosError, addAxiosInterceptors, toTime } from '@shared/utils'
import axios, { AxiosInstance } from 'axios'
import { Config } from '../config'
import * as FullStory from '../utils/fullstory'
import { ActionItemsApi } from './actionItems'
import { AppointmentsApi } from './appointments'
import { BehavioralHealthFormsApi } from './behavioralHealthForms'
import { CaseReviewNoteApi } from './caseReviewNote'
import { CocmRegistryApi } from './cocmRegistry'
import { ConsultationQueueApi } from './consultationQueue'
import { EmployeesApi } from './employees'
import { EncountersApi } from './encounters'
import { FirebaseAuthApi } from './firebaseAuth'
import { FormsApi } from './forms'
import { InsuranceApi } from './insurance'
import { OcpApi } from './ocp'
import { PatientsApi } from './patients'
import { PharmaciesApi } from './pharmacies'
import { PrescriptionFavoritesApi } from './prescriptionFavorites'
import { PrescriptionsApi } from './prescriptions'
import { ReleaseOfInformationApi } from './releaseOfInformation'
import { ServiceLinesApi } from './serviceLines'
import { ShipmentsApi } from './shipments'
import { StorageApi } from './storage'
import { TasksApi } from './tasks'
import { VisitNotesApi } from './visitNotes'
import { WorkflowsApi } from './workflows'

const apiAxiosInstance = axios.create({
  baseURL: Config.BACKEND_BASE_URL || '',
  headers: { 'Content-Type': 'application/json' },
  timeout: toTime('1 min').ms(),
})

const lunaAxiosInstance = axios.create({
  baseURL: Config.LUNA_BASE_URL || '',
  headers: { 'Content-Type': 'application/json' },
  timeout: toTime('1 min').ms(),
})

const addEmrAxiosInterceptors = (axiosInstance: AxiosInstance) => {
  addAxiosInterceptors(axiosInstance, {
    onResponseRejected: ({ errorMessage, ...extra }: FormattedAxiosError) => {
      FullStory.log(
        'error',
        JSON.stringify({
          errorMessage,
          errorName: extra.errorName,
          errorSummary: extra.errorSummary,
        }),
      )
    },
    responseRejection: { omit: ['request'] },
    headers: {
      'client-location-pathname': () => window.location.pathname,
      'fullstory-session-url': () => FullStory.getCurrentSessionURL(true),
    },
  })
}

addEmrAxiosInterceptors(apiAxiosInstance)
addEmrAxiosInterceptors(lunaAxiosInstance)

const apiConfig: BaseApiConfig = {
  axiosInstance: apiAxiosInstance,
}

const lunaApiConfig: BaseApiConfig = {
  axiosInstance: lunaAxiosInstance,
}

export const firebaseAuthApi = new FirebaseAuthApi(apiConfig)
export const lunafirebaseAuthApi = new FirebaseAuthApi(lunaApiConfig)
export const actionItemsApi = new ActionItemsApi(apiConfig)
export const appointmentsApi = new AppointmentsApi(apiConfig)
export const behavioralHealthFormsApi = new BehavioralHealthFormsApi(apiConfig)
export const consultationQueueApi = new ConsultationQueueApi(apiConfig)
export const employeesApi = new EmployeesApi(apiConfig)
export const prescriptionFavoritesApi = new PrescriptionFavoritesApi(apiConfig)
export const encountersApi = new EncountersApi(apiConfig)
export const insuranceApi = new InsuranceApi(apiConfig)
export const patientsApi = new PatientsApi(apiConfig)
export const pharmaciesApi = new PharmaciesApi(apiConfig)
export const ocpApi = new OcpApi(apiConfig)
export const shipmentsApi = new ShipmentsApi(apiConfig)
export const prescriptionsApi = new PrescriptionsApi(apiConfig)
export const visitNotesApi = new VisitNotesApi(apiConfig)
export const formsApi = new FormsApi(apiConfig)
export const serviceLinesApi = new ServiceLinesApi(apiConfig)
export const tasksApi = new TasksApi(apiConfig)
export const releaseOfInformationApi = new ReleaseOfInformationApi(apiConfig)
export const cocmRegistryApi = new CocmRegistryApi(apiConfig)
export const caseReviewNoteApi = new CaseReviewNoteApi(apiConfig)
export const workflowsApi = new WorkflowsApi(apiConfig)
export const emrApi = new OpheliaClient<EmrApi>(apiConfig)
export const emrLunaApi = new OpheliaClient<EmrLunaApi>(lunaApiConfig)

// Communicates directly with GCP, therefore no Ophelia api config required.
export const storageApi = new StorageApi()
