import React from 'react'
import { objectValuesToString } from '../../../utils/utils'

export type AScreeningFormQuestionAnswerProps = {
  question: string
  answer: boolean | string | Record<string, string>
}

const AScreeningFormQuestionAnswer = ({ question, answer }: AScreeningFormQuestionAnswerProps) => (
  <div className='max-w-md'>
    <p className='text-gray-700 font-medium'>{question}</p>
    <p className='pb-2 text-sm'>
      {typeof answer === 'boolean' && answer && <span>Yes</span>}
      {typeof answer === 'boolean' && !answer && <span>No</span>}
      {(typeof answer === 'string' || typeof answer === 'number') && <span>{answer}</span>}
      {typeof answer !== 'string' && typeof answer !== 'boolean' && (
        <span>{objectValuesToString(answer)}</span>
      )}
    </p>
  </div>
)

export default AScreeningFormQuestionAnswer
