import React from 'react'

export type ODSPrimaryButtonProps = {
  icon?: JSX.Element
  type?: 'button' | 'submit' | 'reset'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  message: string
  className?: string
  loading?: boolean
  disabled?: boolean
}

const ODSPrimaryButton = ({
  icon,
  type = 'submit',
  onClick = undefined,
  message,
  className = '',
  loading = false,
  disabled = false,
}: ODSPrimaryButtonProps) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={loading || disabled}
      className={`${className ?? ''} ${loading ? 'animate-pulse' : ''} ${
        disabled && !loading ? 'opacity-40 cursor-not-allowed' : ''
      } inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-daybreak-text3 bg-daybreak-actions0 hover:bg-daybreak-actions1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-daybreak-actions1 `}
    >
      {icon}
      {message}
    </button>
  )
}

export default ODSPrimaryButton
