import { useClipboard, useHover } from '@mantine/hooks'
import {
  Card,
  EditIcon,
  Group,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  Tooltip,
  UnstyledButton,
} from '@shared/components'
import { template } from '@shared/utils'
import { useNavigate } from 'react-router-dom'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

export const AddressContent = ({ addressType }: { addressType: 'home' | 'shipping' }) => {
  const clipboard = useClipboard({ timeout: 2000 })
  const navigate = useNavigate()
  const { hovered: editButtonHovered, ref: editButtonRef } = useHover()
  const { hovered: clickToCopyHovered, ref: clickToCopyRef } = useHover()
  const { patientId, patientQuery } = usePatient()
  const patient = patientQuery?.data
  const patientAddress = addressType === 'home' ? patient?.homeData : patient?.shippingData

  if (patientQuery?.isLoading || patientQuery?.isError) {
    return <Skeleton h='5rem' />
  }

  const handleClickToCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (patientAddress) {
      const apt = patientAddress.aptSuite

      // fields that don't have a value will be omitted automatically, and spaces are added between every string

      const addressToCopy = template([`{address}{apt},`, `{city},`, `{state}`, `{zip}`], {
        address: patientAddress.address,
        // Make sure to not add an empty space if apt is undefined
        apt: apt ? ` ${apt}` : '',
        city: patientAddress.city,
        state: patientAddress.state,
        zip: patientAddress.zip,
      })

      clipboard.copy(addressToCopy)
    }
  }

  if (!patientAddress?.address) {
    return (
      <SidebarEmptyState
        pillMessage={`No ${addressType} address`}
        textMessage={`As soon as ${
          patient?.personalData.preferredName || patient?.personalData.firstName
        } adds their ${addressType} address in the patient portal, it'll appear here`}
      />
    )
  }

  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]}>
        {addressType === 'home' ? 'Home' : 'Shipping'} address
      </Text>
      <Tooltip
        opened={clickToCopyHovered && Boolean(patientAddress?.address) && !editButtonHovered}
        label={clipboard.copied ? 'Copied!' : 'Click to copy'}
      >
        <UnstyledButton
          w='100%'
          onClick={e => handleClickToCopy(e)}
          sx={{ cursor: patientAddress?.address ? 'pointer' : 'unset' }}
        >
          <Card ref={clickToCopyRef}>
            <Group noWrap position='apart' align='flex-start'>
              {patientAddress?.address ? (
                <Stack spacing={0}>
                  <Text size='xs' lineClamp={1}>
                    {patientAddress?.address}
                  </Text>
                  {patientAddress?.aptSuite && (
                    <Text size='xs' lineClamp={1}>
                      {patientAddress?.aptSuite}
                    </Text>
                  )}
                  <Text size='xs' lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
                    {patientAddress?.city}, {patientAddress?.state} {patientAddress?.zip}
                  </Text>
                </Stack>
              ) : (
                <SidebarEmptyState
                  pillMessage={`No ${addressType} address`}
                  textMessage={`As soon as ${
                    patient?.personalData.preferredName || patient?.personalData.firstName
                  } adds their ${addressType} address in the patient portal, it'll appear here`}
                />
              )}

              <TertiaryButton
                ref={editButtonRef}
                leftIcon={<EditIcon color={colors => colors.actions[0]} />}
                onClick={e => {
                  e.stopPropagation()
                  navigate(`/patients/${patientId}/edit`, {
                    state: `${addressType}`,
                  })
                }}
              >
                Edit
              </TertiaryButton>
            </Group>
          </Card>
        </UnstyledButton>
      </Tooltip>
    </Stack>
  )
}
