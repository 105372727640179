import { ClockIcon, Group, Pill, RichTextEditor, Stack, Text } from '@shared/components'
import { EMRTaskNoteDecorated, EMRTaskStatus } from '@shared/types'
import { dayjs } from '@shared/utils'
import { NoteCreatedBy } from './NoteCreatedBy'

export type NotesNoteAddedEntryProps = {
  note: EMRTaskNoteDecorated<'note_added'>
}

export const TaskStatusPill = ({ status }: { status: EMRTaskStatus }) => {
  if (status === 'open') {
    return (
      <Pill variant='filled' status='error'>
        Open
      </Pill>
    )
  }

  if (status === 'snoozed') {
    return (
      <Pill variant='filled' status='warning'>
        Snoozed
      </Pill>
    )
  }

  if (status === 'closed') {
    return (
      <Pill variant='filled' status='success'>
        Closed
      </Pill>
    )
  }

  return null
}

export const NotesNoteAddedEntry = ({ note }: NotesNoteAddedEntryProps) => {
  return (
    <Stack spacing='sm'>
      <TaskStatusPill status={note.status} />
      <Group>
        <Text size='xs'>
          <NoteCreatedBy note={note} />
        </Text>
        <Text size='xs'>
          <Group spacing='xs'>
            <ClockIcon color={({ text }) => text[1]} size='sm' />
            {dayjs(note.createdAt).format('MM/DD/YYYY')} | {dayjs(note.createdAt).format('h:mma z')}
          </Group>
        </Text>
      </Group>
      <RichTextEditor value={note.text} editable={false} />
    </Stack>
  )
}
