import { Card, Text, useMantineTheme } from '@shared/components'
import { Addendum } from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const formatDate = (input: Date) => {
  return dayjs(input).tz(dayjs.tz.guess()).format('MM/DD/YY | hh:mma z')
}

const AddendumsSectionSidebar = ({ addendums }: { addendums: Addendum[] }) => {
  // convert to date objects
  const addendumsWithDates = addendums.map((addendum: Addendum) => ({
    ...addendum,
    parsedDate: dayjs(addendum.locked_at, 'MMMM D, YYYY, h:mm a').toDate(),
  }))
  // sort by date
  addendumsWithDates.sort(sortBy({ key: 'parsedDate', order: 'DESC' }))
  // format date
  const formattedAddendums: Addendum[] = addendumsWithDates.map(
    ({ parsedDate, ...rest }: Addendum & { parsedDate: Date }) => ({
      ...rest,
      locked_at: formatDate(parsedDate),
    }),
  )

  const theme = useMantineTheme()

  return (
    <>
      {formattedAddendums.length > 0 &&
        formattedAddendums.map((addendum: Addendum) => (
          <Card
            key={addendum.id}
            padding='md'
            mb='md'
            w='100%'
            bg={theme.other.colors.background[2]}
          >
            <Text bold>{addendum.locked_by_name}</Text>
            <Text size='xs' color={({ text }) => text[1]}>
              {addendum.locked_at}
            </Text>
            <Text size='md' mt='sm'>
              {addendum.content}
            </Text>
          </Card>
        ))}
    </>
  )
}

export default AddendumsSectionSidebar
