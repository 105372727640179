import { Box, Grid, Text } from '@shared/components'
import { cloneElement } from 'react'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'

export const EditableCol = (props: {
  isEditing: boolean
  label?: string
  text: string | number | undefined
  span?: number
  children: JSX.Element
}) => {
  let content = (
    <Box>
      <Text bold>{props.label}</Text>
      {props.text ? (
        <Text
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {props.text}
        </Text>
      ) : (
        <EmptyDataCell />
      )}
    </Box>
  )

  if (props.isEditing) {
    content = cloneElement(props.children, {
      ...props.children.props,
      label: props.label,
    })
  }

  return <Grid.Col span={props.span || 4}>{content}</Grid.Col>
}
