import {
  Center,
  ClockIcon,
  DownloadIcon,
  EyeIcon,
  Group,
  Menu,
  Modal,
  MoreVerticalIcon,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  Stack,
  Table,
  Text,
  Th,
  Tooltip,
  TrashCanIcon,
} from '@shared/components'
import {
  FILE_CATEGORIES_MAP,
  PatientFile,
  hasRole,
  isEngineer,
  isLeadCoordinator,
  isPatientOnboardingOffline,
} from '@shared/types'
import { IANAZone, dayjs } from '@shared/utils'
import times from 'lodash/times'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { emrApi, patientsApi } from '../../../api'
import LoadingRow from '../../../components/atoms/LoadingRow'
import { Photo } from '../../../components/forms/PreviewPhoto'
import { useAuth } from '../../../context/auth'
import { usePatient } from '../PPatientContext'
import { ConsentFormSection } from './ConsentFormSection'
import { FilesEmptyState } from './FilesEmptyState'
import ODeleteFileModal from './ODeleteFileModal'
import { SectionHeader } from './SectionHeader'
import { downloadFileFromUrl } from './utils'

type FileTableRowProps = {
  handleDelete: () => void
  handlePreview: () => void
  handleDownload: () => void
  file: PatientFile & { signedUrl?: string | undefined }
  patientId: string
  readOnly: boolean
}
const FileTableRow = ({
  handleDelete,
  handlePreview,
  handleDownload,
  file,
  readOnly,
}: FileTableRowProps) => {
  const employeeQuery = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: { employeeId: file.createdBy ?? '' },
    }),
    {
      enabled: Boolean(file.createdBy),
    },
  )
  const employee = employeeQuery?.data

  return (
    <tr className='mantine'>
      <td className='mantine'>
        <Group spacing='sm' noWrap>
          <Text size='xs'>{dayjs(file.createdAt).format('MM/DD/YYYY')}</Text>
          <Tooltip
            label={
              <Text bold size='xs' color='white' p='xs'>
                {dayjs(file.createdAt).tz(IANAZone.Eastern).format('h:mma z')}
              </Text>
            }
          >
            <ClockIcon color={colors => colors.actions[0]} />
          </Tooltip>
        </Group>
      </td>
      <td className='mantine'>
        <Text size='xs' lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
          {employeeQuery.isLoading ? <Skeleton /> : employee?.name}
        </Text>
      </td>
      <td className='mantine'>
        <Text size='xs' lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
          {file.category ? FILE_CATEGORIES_MAP?.[file.category] : 'n/a'}
        </Text>
      </td>
      <td className='mantine'>
        <Text size='xs' lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
          {file.name}
        </Text>
      </td>
      <td className='mantine'>
        <Menu position='bottom-end'>
          <Center>
            <Menu.Target>
              <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
            </Menu.Target>
          </Center>
          <Menu.Dropdown>
            <Menu.Item
              onClick={handlePreview}
              icon={<EyeIcon color={colors => colors.actions[0]} />}
            >
              Preview
            </Menu.Item>

            <Menu.Item
              onClick={handleDownload}
              icon={<DownloadIcon color={colors => colors.actions[0]} />}
            >
              Download
            </Menu.Item>

            {!readOnly && (
              <Menu.Item
                onClick={handleDelete}
                icon={<TrashCanIcon color={colors => colors.actions[0]} />}
              >
                Remove
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  )
}

export const PatientFileDirectory = () => {
  const { patientId, patientQuery, setModal } = usePatient()
  const patient = patientQuery?.data
  const { currentUser } = useAuth()

  const [errorMessage, setErrorMessage] = useState('')
  const [deleteFile, setDeleteFile] = useState<{ fileId: string; fileName: string }>()

  const [filesQueryKey, filesQueryFunction] = emrApi.getQuery('GET /patient/:patientId/files', {
    params: { patientId },
  })

  const filesQuery = useQuery(filesQueryKey, filesQueryFunction, { enabled: Boolean(patientId) })
  const files = filesQuery.data || {}
  const hasFiles = Object.keys(files).length > 0
  const readOnly = !(
    isEngineer(currentUser) ||
    isLeadCoordinator(currentUser) ||
    hasRole(currentUser, 'spc')
  )

  const previewFile = useMutation(patientsApi.getFile)
  const downloadFile = useMutation(patientsApi.getFile, {
    onSuccess: file => {
      downloadFileFromUrl(file)
      downloadFile.reset()
    },
  })

  const patientFiles = Object.entries(files)
    .reverse()
    .filter(([, file]) => file?.category !== 'ROI')

  return (
    <>
      <Modal opened={Boolean(previewFile.data)} onClose={previewFile.reset}>
        <Photo
          label={previewFile.data?.name || ''}
          value={previewFile.variables?.fileId}
          patientId={patientId}
          ratio={1}
        />
      </Modal>
      <Modal opened={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
        {errorMessage}
      </Modal>
      <Modal opened={Boolean(deleteFile && hasFiles)} onClose={() => setDeleteFile(undefined)}>
        {deleteFile && hasFiles && (
          <ODeleteFileModal
            fileId={deleteFile.fileId}
            fileName={deleteFile.fileName}
            closeModal={() => setDeleteFile(undefined)}
          />
        )}
      </Modal>
      {!filesQuery.isLoading && !hasFiles && (
        <FilesEmptyState
          pillText='No files uploaded yet'
          buttonText='Upload file'
          displayHeader
          headerTitle='Patient files'
        />
      )}

      {filesQuery.isLoading ||
        (hasFiles && (
          <Stack>
            <SectionHeader
              sectionTitle='Patient files'
              button={
                <PrimaryButton
                  onClick={() => setModal({ type: 'upload-file' })}
                  leftIcon={<PlusIcon />}
                >
                  Upload file
                </PrimaryButton>
              }
            />
            <Table
              striped
              withBorder
              verticalSpacing='sm'
              sx={({ other: { colors, sizes } }) => ({
                tableLayout: 'fixed',
                backgroundColor: colors.background[0],
                borderWidth: sizes.border.md,
                borderStyle: 'solid',
                borderColor: colors.background[3],
              })}
            >
              <thead className='mantine'>
                <tr className='mantine'>
                  <Th sortable={false} style={{ width: `14ch` }}>
                    Uploaded on
                  </Th>
                  <Th sortable={false}>Uploaded by</Th>
                  <Th sortable={false}>Category</Th>
                  <Th sortable={false}>File name</Th>
                  <Th sortable={false} style={{ width: `7ch` }}>
                    Action
                  </Th>
                </tr>
              </thead>

              <tbody className='mantine'>
                {filesQuery.isLoading &&
                  /* eslint-disable-next-line no-magic-numbers */
                  times(8, index => <LoadingRow key={index} headersLength={6} />)}
                {patientFiles.map(([_, file]) => (
                  <FileTableRow
                    readOnly={readOnly}
                    key={file.oid}
                    handleDelete={() => setDeleteFile({ fileId: file.oid, fileName: file.name })}
                    handleDownload={() => downloadFile.mutate({ patientId, fileId: file.oid })}
                    handlePreview={() => previewFile.mutate({ patientId, fileId: file.oid })}
                    file={file}
                    patientId={patientId}
                  />
                ))}
              </tbody>
            </Table>
            {patient && isPatientOnboardingOffline(patient) && (
              <ConsentFormSection patient={patient} />
            )}
          </Stack>
        ))}
    </>
  )
}
