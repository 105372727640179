import { ClockIcon, Group, Pill, Stack, Text } from '@shared/components'
import { EMRTaskNoteDecorated } from '@shared/types'
import { dayjs } from '@shared/utils'
import { NoteCreatedBy } from './NoteCreatedBy'

export type NotesStatusChangedEntryProps = {
  note: EMRTaskNoteDecorated<'status_changed'>
}

export const NotesStatusChangedEntry = ({ note }: NotesStatusChangedEntryProps) => {
  return (
    <Stack spacing='sm'>
      <Pill variant='filled' status='none'>
        Status changed
      </Pill>
      <Group>
        <Text size='xs'>
          <NoteCreatedBy note={note} />
        </Text>
        <Text size='xs'>
          <Group spacing='xs'>
            <ClockIcon color={({ text }) => text[1]} size='sm' />
            {dayjs(note.createdAt).format('MM/DD/YYYY')} | {dayjs(note.createdAt).format('h:mma z')}
          </Group>
        </Text>
      </Group>
      <Text>{`${note.metadata.employeeName} changed the status from ${note.statusBefore} to ${note.statusAfter}`}</Text>
    </Stack>
  )
}
