import {
  FileRejection,
  Group,
  InputError,
  TertiaryButton,
  Text,
  TrashIcon,
  Upload,
} from '@shared/components'
import { ReactNode, useCallback } from 'react'
import { useMutation } from 'react-query'
import { emrApi, storageApi } from '../../api'
import { useGcsFile } from '../../utils/hooks'
import { PreviewPhoto } from './PreviewPhoto'

export type UploadPhotoProps = {
  label?: string
  footer?: string | undefined
  value: string | undefined
  setError: (error?: ReactNode) => void
  onChange: (fileId?: string) => void
  disabled?: boolean
  patientId: string
  'test-id'?: string
  loading?: boolean
  onUpload?: ({ fileId, file }: { fileId: string; file: File }) => void
  primaryText?: string
} & InputError

export const UploadPhoto = ({
  onChange,
  setError,
  patientId,
  label,
  value,
  disabled = false,
  error,
  warning,
  success,
  loading = false,
  onUpload,
  primaryText = 'Upload photo',
  ...rest
}: UploadPhotoProps) => {
  const { file, setFile, isFileMissing } = useGcsFile({
    fileId: value === 'loading' ? undefined : value,
    patientId,
    onError: () => setError(`Unable to load your file, please try again later.`),
  })

  const writeFile = useMutation(emrApi.getMutation('GET /patient/:patientId/file/write'))
  const addFileToStorage = useMutation(storageApi.addFile, { retry: 1 })
  const deleteFile = useMutation(emrApi.getMutation('DELETE /patient/:patientId/tempFile/:fileId'))

  const onDrop = useCallback(
    async ([file]: File[]) => {
      if (!file) {
        return
      }
      setError()
      onChange('loading')
      setFile(file)
      try {
        const { fileId, signedUrl } = await writeFile.mutateAsync({ params: { patientId } })
        await addFileToStorage.mutateAsync({ signedUrl, file })

        onChange(fileId)
        if (onUpload) {
          onUpload({ fileId, file })
          setFile(undefined)
        }
      } catch {
        onChange()
        setFile(undefined)
        setError(`Failed to upload image, please try again.`)
      }
    },
    [setError, setFile, onChange, addFileToStorage, writeFile, patientId],
  )

  const onReject = useCallback(
    (files: FileRejection[]) => setError(files[0]?.errors[0]?.message),
    [setError],
  )

  const isLoading = loading || isFileMissing || writeFile.isLoading || addFileToStorage.isLoading

  const onRemove = useCallback(() => {
    if (isLoading) {
      return
    }

    if (value) {
      deleteFile.mutate({ params: { fileId: value, patientId } })
    }

    setFile(undefined)
    onChange()
    setError()
  }, [setError, setFile, onChange, value, deleteFile, isLoading, patientId])

  return (
    <Upload
      primaryText={primaryText}
      test-id={rest['test-id']}
      subText='JPEG, PNG or PDF under 20MB'
      accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.pdf'] }}
      maxSizeMB={20}
      onDrop={onDrop}
      error={error}
      warning={warning}
      success={success}
      loading={isLoading}
      disabled={disabled}
      onReject={onReject}
      label={
        label ? (
          <Group noWrap position='apart'>
            <Text>{label}</Text>
            {!isLoading && file && (
              <TertiaryButton
                test-id='button:remove'
                onClick={onRemove}
                disabled={disabled}
                rightIcon={<TrashIcon />}
              >
                Remove
              </TertiaryButton>
            )}
          </Group>
        ) : null
      }
    >
      {file && <PreviewPhoto file={file} />}
    </Upload>
  )
}
