import { StateAbbr, states } from '@shared/types'
import { useEmrQuery } from '.'

export const usePatientOcp = (patientId?: string | null) => {
  const { data: patient } = useEmrQuery(
    'GET /patient/:patientId',
    {
      params: { patientId: patientId ?? '' },
    },
    { enabled: Boolean(patientId) },
  )

  const patientState = patient && (patient.homeData?.state || patient.shippingData?.state)
  const stateAbbreviation = states.find(stateObj => stateObj.state === patientState)
    ?.abbr as StateAbbr

  const ocpQuery = useEmrQuery(
    'GET /ocp/provider/:state',
    {
      params: { state: stateAbbreviation },
    },
    {
      enabled: Boolean(stateAbbreviation),
    },
  )

  return {
    ocpQuery,
  }
}
