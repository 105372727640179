import { Radio, RadioGroup, Stack } from '@shared/components'
import { dischargeNoteMapping, hlocCategoryMapping, hlocSubcategoryMapping } from '@shared/types'
import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const NeedsTransferToHLOCSection = ({
  form,
  optional,
  isPAMedicaidCoe,
}: {
  form: DischargeNoteForm
  optional: boolean
  isPAMedicaidCoe: boolean
}) => {
  return (
    <Stack spacing='sm'>
      <RadioGroup
        w='100%'
        {...form.getInputProps('needs_transfer_hloc')}
        label={formLabel(
          isPAMedicaidCoe
            ? 'Needs to transfer to alternate higher level of care program?'
            : 'Needs to transfer to alternate program?',
          optional,
        )}
        orientation='horizontal'
      >
        <Radio
          label='Yes'
          value='yes'
          sx={{
            flexGrow: 1,
          }}
        />
        <Radio
          label='No'
          value='no'
          sx={{
            flexGrow: 1,
          }}
        />
      </RadioGroup>

      {isPAMedicaidCoe && form.values.needs_transfer_hloc === 'yes' && (
        <Stack>
          <RadioGroup
            label={formLabel('Recommended higher level of care', optional)}
            {...form.getInputProps('recommended_hloc')}
            spacing='sm'
          >
            <Radio label={hlocCategoryMapping.inpatient} value='inpatient' />
            <Radio label={hlocCategoryMapping.residential} value='residential' />

            {form.values.recommended_hloc === 'residential' && (
              <RadioGroup
                sx={{ marginLeft: '2rem' }}
                label={dischargeNoteMapping.recommended_hloc_subcategory}
                {...form.getInputProps('recommended_hloc_subcategory')}
              >
                <Radio
                  label={hlocSubcategoryMapping.medically_managed_residential}
                  value='medically_managed_residential'
                />
                <Radio
                  label={hlocSubcategoryMapping.clinicially_managed_high_intensity_residential}
                  value='clinicially_managed_high_intensity_residential'
                />
                <Radio
                  label={hlocSubcategoryMapping.clinically_managed_low_intensity_residential}
                  value='clinically_managed_low_intensity_residential'
                />
              </RadioGroup>
            )}
            <Radio label={hlocCategoryMapping.intensive_outpatient} value='intensive_outpatient' />
            {form.values.recommended_hloc === 'intensive_outpatient' && (
              <RadioGroup
                sx={{ marginLeft: '2rem' }}
                label={dischargeNoteMapping.recommended_hloc_subcategory}
                {...form.getInputProps('recommended_hloc_subcategory')}
              >
                <Radio
                  label={hlocSubcategoryMapping.high_intensity_outpatient}
                  value='high_intensity_outpatient'
                />
                <Radio
                  label={hlocSubcategoryMapping.medically_managed_intensive_outpatient}
                  value='medically_managed_intensive_outpatient'
                />
                <Radio
                  label={hlocSubcategoryMapping.intensive_outpatient}
                  value='intensive_outpatient'
                />
              </RadioGroup>
            )}
          </RadioGroup>
        </Stack>
      )}
    </Stack>
  )
}
