import { Combobox } from '@headlessui/react'
import { Patient } from '@shared/types'
import cn from 'classnames'
import { HiChevronRight } from '@react-icons/all-files/hi/HiChevronRight'

const CommandPalettePatientRow = ({ person }: { person: Patient }) => {
  return (
    <Combobox.Option
      as='div'
      key={person.oid}
      value={person}
      className={({ active }) =>
        cn('flex select-none items-center rounded-md p-2 cursor-pointer', {
          'bg-gray-100 text-gray-900': active,
        })
      }
    >
      {({ active }) => (
        <>
          <span className='flex-auto truncate'>{`${person.personalData?.firstName} ${person.personalData?.lastName}`}</span>
          {active && (
            <HiChevronRight className='ml-3 h-5 w-5 flex-none text-gray-400' aria-hidden='true' />
          )}
        </>
      )}
    </Combobox.Option>
  )
}

export default CommandPalettePatientRow
