import { Group, Text } from '@shared/components'
import { forwardRef } from 'react'

type SelectItemProps = {
  value: string
  description: string
  label: string
}

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(({ ...others }, ref) => {
  const { description, label, ...props } = others

  return (
    <div {...props} ref={ref}>
      <Group position='apart' noWrap sx={{ width: '100%' }}>
        <Text>{label}</Text>
        <Text size='xs' color={color => color.text[1]}>
          {description}
        </Text>
      </Group>
    </div>
  )
})

SelectItem.displayName = 'SelectItem'
