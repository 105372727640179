import {
  Card,
  ClipboardIcon,
  ClockIcon,
  Group,
  MantineColor,
  Stack,
  TertiaryButton,
  Text,
  colorToHex,
  useMantineTheme,
} from '@shared/components'
import { EMRTask, EMRTaskTypeMap } from '@shared/types'
import dayjs from 'dayjs'
import { PriorityIcon } from './PriorityIcon'

export type TaskEntryProps = {
  task: EMRTask
  openNotes: (taskId: string) => void
  background: MantineColor
}

export const TaskEntry = ({ task, openNotes, background }: TaskEntryProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <Card styles={{ backgroundColor: colorToHex(background, colors) }} p='sm'>
      <Stack spacing='sm'>
        <Group position='apart'>
          <Text color={colors.text[0]}>
            {EMRTaskTypeMap.find(type => type.value === task.type)?.label}
          </Text>
          <PriorityIcon priority={task.priority} type={task.type} />
        </Group>
        <Group position='apart'>
          <Group spacing='xs'>
            <ClockIcon size='sm' color={colors.text[1]} />
            <Text size='xs' color={colors.text[1]}>
              Opened on {dayjs(task.createdAt).format('MM/DD/YYYY')}
            </Text>
          </Group>
          <TertiaryButton
            leftIcon={<ClipboardIcon color={colors.actions[0]} />}
            onClick={() => openNotes(task.oid)}
          >
            Notes
          </TertiaryButton>
        </Group>
      </Stack>
    </Card>
  )
}
