import {
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  useBanner,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { useInvalidateCollections } from '../use-invalidate-collections'
import { useEmrMutation } from '../../../../utils/hooks'

export const DeleteCollectionDocumentsModal = ({
  opened,
  onClose,
  patientId,
  collection,
  documentIds,
  onSubmit,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  collection: string
  documentIds: string[]
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateCollections()

  const deleteCollectionDocuments = useEmrMutation(
    'DELETE /patient/:patientId/collections/:collection',
  )

  return (
    <Modal
      title='Delete collection documents'
      opened={opened}
      onClose={() => {
        if (!deleteCollectionDocuments.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={deleteCollectionDocuments.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={deleteCollectionDocuments.isLoading}
            onClick={async () => {
              await deleteCollectionDocuments.mutateAsync(
                {
                  params: { patientId, collection },
                  data: { documentIds },
                },
                {
                  onError: error => {
                    showBanner({
                      type: 'error',
                      dismissable: true,
                      label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                    })
                  },
                },
              )

              invalidate({
                patientId,
                collections: [collection],
                documents: documentIds.map(documentId => ({ documentId, collection })),
              })

              onClose()
              onSubmit()
            }}
          >
            Delete
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>
          {`Are you sure you want to delete ${documentIds.length} document(s) from the collection `}
          <Text bold span>
            {collection}
          </Text>
          ?
        </Text>
      </Stack>
    </Modal>
  )
}
