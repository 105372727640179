import { useForm } from '@mantine/form'
import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  Radio,
  RadioGroup,
} from '@shared/components'
import { LevelOfCareStatus } from '@shared/types'
import { isRequired } from '../../utils/formValidation'
import { useEmrMutation } from '../../utils/hooks'
import { usePatient } from '../patient/PPatientContext'

export const LevelOfCareModalContent = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void
  onSubmit: (levelOfCare: LevelOfCareStatus) => void
}) => {
  const { patientId, patientQuery } = usePatient()

  const locForm = useForm<{ levelOfCare: LevelOfCareStatus | undefined }>({
    validate: {
      levelOfCare: isRequired,
    },
  })

  const updateLevelOfCareMutation = useEmrMutation('PUT /patient/:patientId')

  const nextOnClick = async () => {
    const { levelOfCare } = locForm.values
    if (!levelOfCare) {
      return
    }

    await updateLevelOfCareMutation.mutateAsync({
      data: {
        statuses: { levelOfCare },
      },
      params: { patientId },
    })

    await patientQuery?.refetch()
    onSubmit(levelOfCare)
  }

  return (
    <>
      <ModalHeader onClose={onClose}> Select level of care </ModalHeader>
      <ModalContent>
        <RadioGroup
          label={`What is the patient's level of care?`}
          {...locForm.getInputProps('levelOfCare')}
        >
          <Radio label='Weekly' value='weekly' />
          <Radio label='Biweekly' value='biweekly' />
          <Radio label='Monthly' value='monthly' />
        </RadioGroup>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <PrimaryButton onClick={nextOnClick} loading={updateLevelOfCareMutation.isLoading}>
            Next
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const LevelOfCareModal = ({
  opened,
  onClose,
  onSubmit,
}: {
  opened: boolean
  onClose: () => void
  onSubmit: (levelOfCare: LevelOfCareStatus) => void
}) => {
  return (
    <BetterModal opened={opened} onClose={onClose}>
      <LevelOfCareModalContent onClose={onClose} onSubmit={onSubmit} />
    </BetterModal>
  )
}
