import { IoMdCheckmark } from '@react-icons/all-files/io/IoMdCheckmark'
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import { EligibleInsuranceData, InsuranceReviewQueueItem, ManualInsuranceData } from '@shared/types'
import { dayjs } from '@shared/utils'
import max from 'lodash/max'

import { ELIGIBLE_ACTIVE_PLAN_CODES } from '@shared/types'

export const isInsuranceActive = ({
  eligibleInsuranceData,
  manualInsuranceData,
}: {
  eligibleInsuranceData?: EligibleInsuranceData | null
  manualInsuranceData?: ManualInsuranceData | null
}) => {
  if (!eligibleInsuranceData && !manualInsuranceData) {
    return false
  }
  const coverageStatus = eligibleInsuranceData?.plan?.coverage_status || ''

  const eligibleIsActive = ELIGIBLE_ACTIVE_PLAN_CODES.includes(coverageStatus)
  const manualIsActive = Boolean(manualInsuranceData?.isActive)

  return eligibleIsActive || manualIsActive
}

export const getDateLastVerified = ({
  eligibleInsuranceData,
  manualInsuranceData,
}: {
  eligibleInsuranceData?: EligibleInsuranceData | null
  manualInsuranceData?: ManualInsuranceData | null
}) => {
  const lastVerifiedDate = max([eligibleInsuranceData?.created_at, manualInsuranceData?.created_at])
  if (lastVerifiedDate) {
    return dayjs(lastVerifiedDate)
  }
}

export const areInsuranceCards = (queueItem: InsuranceReviewQueueItem) => {
  const { files } = queueItem
  const insuranceCardFront = Boolean(files.primaryInsuranceFront)
  const insuranceCardBack = Boolean(files.primaryInsuranceBack)
  const secondaryFront = Boolean(files.secondaryInsuranceFront)
  const secondaryBack = Boolean(files.secondaryInsuranceBack)

  if (queueItem.hasSecondaryInsurance) {
    return insuranceCardFront && insuranceCardBack && secondaryFront && secondaryBack
  }

  return insuranceCardFront && insuranceCardBack
}

export const renderCompletionBadge = (
  icon: JSX.Element,
  completed: boolean,
  successColor = 'text-gray-300',
  failureColor = 'text-warn',
) => {
  return (
    <span
      className={`inline-flex items-center px-1 text-xs font-medium ${
        completed ? successColor : failureColor
      }`}
    >
      {completed ? (
        <IoMdCheckmark className='h-3 w-3 mr-0.5' />
      ) : (
        <IoMdClose className='h-3 w-3 mr-0.5' />
      )}
      {icon}
    </span>
  )
}
