import { OScheduleVisitModal } from '../../../calendar/OScheduleVisitModal'
import { TimeTrackingDrawer } from '../../../registry/patientPanel/TimeTrackingDrawer'
import { CloseTaskModal } from '../CloseTaskModal'
import { EditAssigneeModal } from '../EditAssigneeModal'
import { ReopenTaskModal } from '../ReopenTaskModal'
import { CurrentTaskOperation, TaskOperation } from '../TaskTable'
import { SnoozeTaskModal } from '../snooze/SnoozeTaskModal'

export type ActionsProps = {
  action: CurrentTaskOperation | undefined
  onClose: () => void
}

export const Actions = ({ action, onClose }: ActionsProps) => {
  const { task, operation } = action || {}

  const schedulingOperations: TaskOperation[] = ['schedule_wellness_visit', 'clinical_contact']

  return (
    <>
      {/* MODALS */}
      {task && operation === 'close' && <CloseTaskModal task={task} onClose={onClose} />}
      {task && operation === 'snooze' && <SnoozeTaskModal task={task} onClose={onClose} />}
      {task && operation === 'reopen' && <ReopenTaskModal task={task} onClose={onClose} />}
      {task && operation === 'edit' && <EditAssigneeModal task={task} onClose={onClose} />}
      {task && task.patientId && operation && schedulingOperations.includes(operation) && (
        <OScheduleVisitModal patientId={task.patientId} closeModal={onClose} />
      )}
      {/* DRAWERS */}
      {task?.patientId && (
        <TimeTrackingDrawer
          opened={operation === 'review_time'}
          patientId={task?.patientId}
          onClose={onClose}
        />
      )}
    </>
  )
}
