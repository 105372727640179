import { UseFormReturnType, useForm } from '@mantine/form'
import { useFocusTrap } from '@mantine/hooks'
import {
  Drawer,
  Group,
  PrimaryButton,
  Stack,
  showNotification,
  useBanner,
  validateWith,
} from '@shared/components'
import { DischargeNoteModel, Patient, isClinician } from '@shared/types'
import { useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { useAuth } from '../../../../context/auth'
import { isRequired } from '../../../../utils/formValidation'
import { useEmrMutation } from '../../../../utils/hooks'
import { usePatientPAMedicaidCoe } from '../../../../utils/hooks/use-patient-medicaid-coe'
import { AdditionalNotesSection } from './AdditionalNotesSection'
import { BuprenorphineMedicationPlanSection } from './BuprenorphineMedicationPlanSection'
import { DischargeVisitSection } from './DischargeVisitSection'
import { EligibleToReturnSection } from './EligibleToReturnSection'
import { NeedsTransferToHLOCSection } from './NeedsTransferToHLOCSection'
import { PatientSelfDeterminesToStopSection } from './PatientSelfDeterminesToStopSection'
import { ReasonsForDischargeSection } from './ReasonsForDischargeSection'
import { ReferralSection } from './ReferralSection'
import { TransferStatusSection } from './TransferStatusSection'

type AddDischargeNoteDrawerProps = {
  patient: Patient
  opened: boolean
  onClose: () => void
}

type DischargeNoteFormData = DischargeNoteModel & {
  reasons_for_discharge: string[]
  medication_plans: string[]
}

export type DischargeNoteForm = UseFormReturnType<
  DischargeNoteFormData,
  (values: DischargeNoteFormData) => DischargeNoteFormData
>

const AddDischargeNoteDrawer = ({ patient, opened, onClose }: AddDischargeNoteDrawerProps) => {
  const { currentUser } = useAuth()
  const patientId = patient.oid

  const { isPAMedicaidCoe } = usePatientPAMedicaidCoe(patient)
  const focusTrapRef = useFocusTrap()
  const skipIfUserIsNotClinician = () => !isClinician(currentUser)

  const form = useForm<DischargeNoteFormData>({
    initialValues: undefined,
    validate: {
      reasons_for_discharge: validateWith(isRequired),
      eligible_to_return: validateWith(skipIfUserIsNotClinician, isRequired),
      referral_request: validateWith(skipIfUserIsNotClinician, isRequired),
      medication_plans: validateWith(skipIfUserIsNotClinician, isRequired),
      discharge_visit_status: validateWith(isRequired),
    },
    // Transform Mantine CheckboxGroup arrays to old discharge note model fields
    transformValues: values => ({
      ...values,
      failure_to_pay: values.reasons_for_discharge?.includes('failure_to_pay'),
      administrative_non_compliance_aggressive_threatening: values.reasons_for_discharge?.includes(
        'administrative_non_compliance_aggressive_threatening',
      ),
      not_responding: values.reasons_for_discharge?.includes('not_responding') ? 'Yes' : 'No',
      death: values.reasons_for_discharge?.includes('death'),
      patient_self_determines_to_stop: values.reasons_for_discharge?.includes(
        'patient_self_determines_to_stop',
      ),
      medical_or_psychiatric_worsening: values.reasons_for_discharge?.includes(
        'medical_or_psychiatric_worsening',
      ),
      reason_for_discharge_other: values.reasons_for_discharge?.includes(
        'reason_for_discharge_other',
      ),
      thirty_day_prescription_current_maintenance_dose: values.medication_plans?.includes(
        'thirty_day_prescription_current_maintenance_dose',
      ),
      thirty_day_prescription_taper: values.medication_plans?.includes(
        'thirty_day_prescription_taper',
      ),
      naloxone_prescribed: values.medication_plans?.includes('naloxone_prescribed'),
      medication_plan_other: values.medication_plans?.includes('medication_plan_other'),
    }),
    clearInputErrorOnChange: false,
  })

  const queryClient = useQueryClient()
  const { showBanner } = useBanner()

  const createDischargeNote = useEmrMutation('POST /dischargeNotes')

  const handleSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    const transformedValues = form.getTransformedValues()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { reasons_for_discharge, medication_plans, ...remainingValues } = transformedValues

    const note = {
      ...remainingValues,
      patientId,
      employeeId: currentUser.oid,
      timestamp: new Date().toISOString(),
    } as DischargeNoteModel

    const [dischargeNotesQueryKey] = emrApi.getQuery('GET /dischargeNotes', {
      params: { patientId },
    })

    await createDischargeNote.mutateAsync(
      {
        data: note,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(dischargeNotesQueryKey)
          showNotification({
            message: 'Discharge note successfully added',
            variant: 'success',
          })
          form.reset()
          onClose()
        },
        onError: () => {
          showBanner({
            type: 'error',
            dismissable: true,
            label: 'Sorry, something went wrong',
          })
        },
      },
    )
  }

  return (
    <Drawer
      title='Initiate discharge'
      size='lg'
      opened={opened}
      position='right'
      onClose={() => {
        onClose()
        form.reset()
      }}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={handleSubmit}>Add discharge note</PrimaryButton>
        </Group>
      }
      ref={focusTrapRef}
    >
      <Stack p='md' spacing='lg'>
        <ReasonsForDischargeSection form={form} />
        <PatientSelfDeterminesToStopSection form={form} optional />
        <EligibleToReturnSection form={form} optional={!isClinician(currentUser)} />
        <NeedsTransferToHLOCSection
          form={form}
          optional={!isClinician(currentUser)}
          isPAMedicaidCoe={isPAMedicaidCoe}
        />
        <TransferStatusSection form={form} optional />
        <ReferralSection
          form={form}
          isPAMedicaidCoe={isPAMedicaidCoe}
          optional={!isClinician(currentUser)}
        />
        <BuprenorphineMedicationPlanSection form={form} optional={!isClinician(currentUser)} />
        <DischargeVisitSection form={form} />
        <AdditionalNotesSection form={form} optional />
      </Stack>
    </Drawer>
  )
}

export default AddDischargeNoteDrawer
