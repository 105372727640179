import { COCMRegistryItem, DecoratedCocmItem } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class CocmRegistryApi extends BaseApi {
  create = ({ patientId }: { patientId: string }) => {
    return this.json<Res>({ method: 'POST', url: '/cocmRegistry', data: { patientId } })
  }

  list = (params: {
    limit?: string
    endBefore?: string
    startAfter?: string
    search?: string
    employeeId?: string
    needsReview?: boolean
  }) => {
    return this.json<DecoratedCocmItem[]>({ method: 'GET', url: '/cocmRegistry', params })
  }

  update = ({ patientId, data }: { patientId: string; data: Partial<COCMRegistryItem> }) => {
    return this.json<COCMRegistryItem[]>({ method: 'PUT', url: `/cocmRegistry/${patientId}`, data })
  }

  delete = (oid: string) => {
    return this.json<Res>({ method: 'DELETE', url: `/cocmRegistry/${oid}` })
  }
}
