import { Card, Skeleton, Stack, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'

export const SubscriptionInfoSection = () => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const { data: subscription, isLoading: isSubscriptionInfoLoading } = useEmrQuery(
    'GET /patient/:patientId/subscription',
    {
      params: {
        patientId: patient?.oid || '',
      },
    },
    { enabled: Boolean(patient) },
  )

  if (isSubscriptionInfoLoading) {
    return <Skeleton height={60} />
  }

  if (!subscription) {
    return null
  }

  const { intervalString } = subscription

  return (
    <Card>
      <Stack spacing='sm'>
        <Text bold size='xs'>
          Subscription pay - {intervalString}
        </Text>
        <Text size='xs'>
          Next billing date - {dayjs(subscription.nextDueDate).format('MMMM D, YYYY')}
        </Text>
      </Stack>
    </Card>
  )
}
