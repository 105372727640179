import { useDisclosure } from '@mantine/hooks'
import {
  EditIcon,
  Group,
  Modal,
  Pill,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Text,
  TimelineCard,
  TimelineCardTitle,
  XIcon,
  useMantineTheme,
} from '@shared/components'
import { dayjs, getNumWeeksFromNow, template } from '@shared/utils'
import pluralize from 'pluralize'
import { useEmrQuery, useInvalidateLunaQuery, useLunaMutation } from '../../../../utils/hooks'
import { useAccess } from '../../../../utils/hooks/use-access'
import { mapIdToName } from '../../../../utils/utils'
import { ScheduleVisitHoldDrawer } from '../../../calendar/ScheduleVisitHoldDrawer'
import { JourneyItemContent } from '../../PPatientContext'
import { FooterContentSection } from './SharedContent'

const CancelHoldConfirmationModal = ({
  opened,
  onClose,
  visitHold,
}: {
  opened: boolean
  onClose: () => void
  visitHold: JourneyItemContent<'visit-hold'>
}) => {
  const invalidateQuery = useInvalidateLunaQuery()
  const cancelHoldMutation = useLunaMutation('DELETE /scheduling/holds/:id')

  const cancelOnClick = () => {
    cancelHoldMutation.mutate(
      { params: { id: visitHold.oid } },
      {
        onSuccess: async () => {
          onClose()
          return invalidateQuery('GET /scheduling/patients/:patientId/holds')
        },
      },
    )
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Cancel visit hold'
      footer={
        <Group position='right'>
          <SecondaryButton disabled={cancelHoldMutation.isLoading} onClick={onClose}>
            No, go back
          </SecondaryButton>
          <PrimaryButton
            disabled={cancelHoldMutation.isLoading}
            loading={cancelHoldMutation.isLoading}
            onClick={cancelOnClick}
          >
            Yes, cancel hold
          </PrimaryButton>
        </Group>
      }
    >
      <Text>
        This will remove the visit hold for the patient. Are you sure you want to proceed?
      </Text>
    </Modal>
  )
}

export const VisitHoldContent = ({
  visitHold,
}: {
  visitHold: JourneyItemContent<'visit-hold'>
}) => {
  const { data } = useEmrQuery('GET /appointmentTypes')
  const [isConfirmationModalOpen, confirmationModalHandlers] = useDisclosure()
  const [isEditDrawerOpen, editDrawerHandlers] = useDisclosure()

  const theme = useMantineTheme()

  // Users who can schedule visit holds can also cancel them
  const { canScheduleVisitHolds: canManageVisitHolds } = useAccess()

  const { intendedDay } = visitHold

  if (!data || !intendedDay) {
    return null
  }

  const numOfWeeksFromNow = getNumWeeksFromNow(intendedDay)

  const isThisWeek = numOfWeeksFromNow === 0
  const isFuture = numOfWeeksFromNow > 0
  const isPast = numOfWeeksFromNow < 0

  return (
    <>
      <TimelineCard
        styles={({ background }) => ({
          backgroundColor: background[6],
        })}
        leftSide={
          <TimelineCardTitle
            color={({ text }) => text[3]}
            title={mapIdToName(Number(visitHold.appointmentTypeId), data)}
          >
            <Pill variant='filled' status='none'>
              On hold
            </Pill>
          </TimelineCardTitle>
        }
      >
        <FooterContentSection
          right={
            <Group spacing='xs'>
              {canManageVisitHolds && (
                <>
                  <TertiaryButton
                    textColor={theme.other.colors.text[2]}
                    backgroundColor={theme.other.colors.background[6]}
                    size='sm'
                    onClick={editDrawerHandlers.open}
                    leftIcon={<EditIcon color={colors => colors.actions[0]} />}
                  >
                    Edit
                  </TertiaryButton>
                  <TertiaryButton
                    textColor={theme.other.colors.text[2]}
                    backgroundColor={theme.other.colors.background[6]}
                    size='sm'
                    onClick={confirmationModalHandlers.open}
                    leftIcon={<XIcon color={colors => colors.actions[0]} />}
                  >
                    Cancel
                  </TertiaryButton>
                </>
              )}
            </Group>
          }
        >
          <Text color={({ text }) => text[3]}>
            {isThisWeek && `This week with ${visitHold?.clinicianName}`}
            {isPast && `Week of ${dayjs(visitHold.intendedDay).format('YYYYMMDD')}`}
            {isFuture &&
              template('In {numOfWeeksFromNow} {weeks} with {clinicianName}', {
                numOfWeeksFromNow,
                weeks: pluralize('week', numOfWeeksFromNow),
                clinicianName: visitHold?.clinicianName,
              })}
          </Text>
        </FooterContentSection>
      </TimelineCard>
      <CancelHoldConfirmationModal
        visitHold={visitHold}
        opened={isConfirmationModalOpen}
        onClose={confirmationModalHandlers.close}
      />
      <ScheduleVisitHoldDrawer
        visitHold={visitHold}
        opened={isEditDrawerOpen}
        onClose={editDrawerHandlers.close}
      />
    </>
  )
}
