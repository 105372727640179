import { useForm } from '@mantine/form'
import {
  Group,
  Modal,
  NumberInput,
  PrimaryButton,
  SecondaryButton,
  Text,
  validateWith,
} from '@shared/components'
import {
  AddressData,
  Employee,
  employmentStatusLabel,
  hasGroupRole,
  isAdmin,
  roleName,
} from '@shared/types'
import { useCallback, useEffect, useState } from 'react'
import { UseFormRegister } from 'react-hook-form-latest'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import { inputNumberBetween, isNumber, isRequired } from '../../utils/formValidation'
import { useFlags, useLunaMutation, useLunaQuery } from '../../utils/hooks'
import AProfileSection, {
  AProfileItem,
  AProfileItemEdit,
  AProfileItemEditProps,
} from './AProfileSection'

type EmployeeFields = keyof Employee
type WorkAddressFields = keyof AddressData

const fieldToLabel = {
  name: 'Display Name',
  email: 'Ophelia Email',
  role: 'Role',
  legalFirstName: 'Legal First Name',
  legalLastName: 'Legal Last Name',
  personalEmail: 'Personal Email',
  phone: 'Phone Number',
  dob: 'Date of Birth',
  startDate: 'Start Date',
  employmentStatus: 'Employment Status',
  calendarId: 'Acuity Calendar Id',
  address: 'Address',
  aptSuite: 'Address 2 (optional)',
  city: 'City',
  state: 'State',
  zip: 'Zip code',
} as Record<EmployeeFields | WorkAddressFields, string>

const profileSections: {
  label?: string
  fields: (EmployeeFields | WorkAddressFields)[]
}[] = [
  {
    fields: ['name', 'role', 'legalFirstName', 'dob', 'legalLastName'],
  },
  {
    label: 'Contact Info',
    fields: ['email', 'phone', 'personalEmail'],
  },
  {
    label: 'Work address',
    fields: ['address', 'aptSuite', 'city', 'state', 'zip'],
  },
  {
    label: 'Ophelia Info',
    fields: ['startDate', 'employmentStatus', 'calendarId'],
  },
]

export type MEmployeeProfileTabProps = {
  employee: Employee
  editMode: boolean
  register: UseFormRegister<Employee>
}

const MEmployeeProfileTab = ({ employee, editMode, register }: MEmployeeProfileTabProps) => {
  const { patientAndClinicianLocations } = useFlags()
  const { currentUser } = useAuth()
  const [isOnCallModalOpen, setOnCallModalOpen] = useState(false)
  const [updateQueueSizeLimitModalOpen, setUpdateQueueSizeLimitModalOpen] = useState(false)
  const { data: configs, refetch: refetchConfigs } = useQuery(...emrApi.getQuery('GET /configs'))

  const { data: sizeLimitQuery, refetch: refetchQueueSizeConfigs } = useLunaQuery(
    'GET /consultation-queue/size',
    {
      enabled:
        employee.role === 'consultationCallQueue' &&
        hasGroupRole(
          currentUser,
          'engineer',
          'admin',
          'enrollmentCoordinator',
          'leadEnrollmentCoordinator',
        ),
    },
  )

  const updateConsultationQueueSizeLimitMutation = useLunaMutation('PUT /consultation-queue/size')

  const consultationQueueSizeLimitForm = useForm({
    initialValues: {
      sizeLimit: Number(sizeLimitQuery?.data.sizeLimit),
    },
    validate: {
      sizeLimit: validateWith(isRequired, isNumber, inputNumberBetween({ start: 3, end: 30 })),
    },
  })

  const updateConsultationQueueSizeLimit = () => {
    const validation = consultationQueueSizeLimitForm.validate()
    if (validation.hasErrors) {
      return
    }

    updateConsultationQueueSizeLimitMutation.mutate(
      {
        data: {
          sizeLimit: Number(consultationQueueSizeLimitForm?.values?.sizeLimit) || 3,
        },
      },
      {
        onSuccess: () => {
          void refetchQueueSizeConfigs()
          setUpdateQueueSizeLimitModalOpen(false)
        },
      },
    )
  }

  useEffect(() => {
    if (sizeLimitQuery?.data?.sizeLimit) {
      consultationQueueSizeLimitForm.setFieldValue('sizeLimit', sizeLimitQuery?.data?.sizeLimit)
    }
  }, [sizeLimitQuery?.data?.sizeLimit])

  const employeeIsOnCallEngineer = employee.oid === configs?.onCallEngineer

  const updateConfigsMutation = useMutation(emrApi.getMutation('PUT /configs'))

  const updateOnCallEngineer = () => {
    updateConfigsMutation.mutate(
      {
        data: {
          onCallEngineer: employee.oid,
        },
      },
      {
        onSuccess: () => {
          void refetchConfigs()
          setOnCallModalOpen(false)
        },
      },
    )
  }

  const getFieldValue = useCallback(
    (
      employee: Employee,
      field: EmployeeFields | WorkAddressFields,
      isWorkAddressField: boolean,
    ) => {
      switch (field) {
        case 'role':
          return roleName[employee[field]]
        case 'employmentStatus':
          return employmentStatusLabel[employee[field]]
        default:
          if (isWorkAddressField) {
            return employee.workAddress?.[field as WorkAddressFields]
          }
          return employee[field as EmployeeFields]
      }
    },
    [],
  )

  const editEmployeeField = useCallback(
    (
      employee: Employee,
      field: EmployeeFields | WorkAddressFields,
      isWorkAddressField: boolean,
    ): AProfileItemEditProps<Employee> => {
      switch (field) {
        case 'role':
          return {
            register,
            field,
            type: 'selection',
            options: Object.entries(roleName).map(([role, roleName]) => ({
              name: roleName,
              value: role,
              display: role !== 'admin' || isAdmin(employee),
            })),
          }
        case 'employmentStatus':
          return {
            register,
            field,
            type: 'selection',
            options: [
              {
                name: 'N/A',
                value: '',
                display: !employee?.employmentStatus,
              },
              {
                name: 'Employee',
                value: 'employee',
              },
              {
                name: 'Contractor',
                value: 'contractor',
              },
            ],
          }
        default:
          if (isWorkAddressField) {
            return {
              register,
              field: `workAddress.${field as WorkAddressFields}`,
              type: 'text',
            }
          }
          return {
            register,
            field: field as EmployeeFields,
            type: 'text',
          }
      }
    },
    [],
  )

  return (
    <>
      {profileSections.map(({ label, fields }) => {
        const isWorkAddressField = label === 'Work address'
        if (isWorkAddressField && !patientAndClinicianLocations) {
          return null
        }
        return (
          <AProfileSection title={label} key={label}>
            {fields.map(field => {
              return (
                <AProfileItem
                  key={`${label}-${field}`}
                  name={fieldToLabel[field]}
                  value={getFieldValue(employee, field, isWorkAddressField) as string}
                  displayEdit={editMode}
                >
                  <AProfileItemEdit {...editEmployeeField(employee, field, isWorkAddressField)} />
                </AProfileItem>
              )
            })}
          </AProfileSection>
        )
      })}
      {sizeLimitQuery &&
        hasGroupRole(
          currentUser,
          'admin',
          'engineer',
          'enrollmentCoordinator',
          'leadEnrollmentCoordinator',
        ) && (
          <>
            <AProfileSection title='Queue Size limit' key='sizeLimit'>
              <AProfileItem
                key='QueueSizeLimit'
                name='Queue Size Limit'
                value={`${sizeLimitQuery?.data.sizeLimit}`}
              ></AProfileItem>
              <PrimaryButton
                onClick={() => {
                  setUpdateQueueSizeLimitModalOpen(true)
                }}
              >
                Update queue size limit
              </PrimaryButton>
            </AProfileSection>
            <Modal
              title='Update consultation call queue size limit'
              opened={updateQueueSizeLimitModalOpen}
              onClose={() => {
                setUpdateQueueSizeLimitModalOpen(false)
              }}
              footer={
                <Group position='right'>
                  <SecondaryButton
                    onClick={() => {
                      setUpdateQueueSizeLimitModalOpen(false)
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton onClick={updateConsultationQueueSizeLimit}>Submit</PrimaryButton>
                </Group>
              }
            >
              <NumberInput {...consultationQueueSizeLimitForm.getInputProps('sizeLimit')} />
            </Modal>
          </>
        )}
      {hasGroupRole(employee, 'engineer') && (
        <>
          <AProfileSection title='Engineering' key='engineer'>
            <AProfileItem
              key='On-call'
              name='On-call'
              value={employeeIsOnCallEngineer ? 'Yes' : 'No'}
            ></AProfileItem>
            {hasGroupRole(currentUser, 'engineer') && !employeeIsOnCallEngineer && (
              <PrimaryButton
                onClick={() => {
                  setOnCallModalOpen(true)
                }}
              >
                Set as on-call engineer
              </PrimaryButton>
            )}
          </AProfileSection>
          <Modal
            title='Update on-call engineer'
            opened={isOnCallModalOpen}
            onClose={() => {
              setOnCallModalOpen(false)
            }}
            footer={
              <Group position='right'>
                <SecondaryButton
                  onClick={() => {
                    setOnCallModalOpen(false)
                  }}
                >
                  No, cancel
                </SecondaryButton>
                <PrimaryButton onClick={updateOnCallEngineer}>Yes, continue</PrimaryButton>
              </Group>
            }
          >
            <Text>
              Are you sure you want to set {employee.name} as the current on-call engineer?
            </Text>
          </Modal>
        </>
      )}
    </>
  )
}

export default MEmployeeProfileTab
