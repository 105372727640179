import { useToggle } from '@mantine/hooks'
import {
  Card,
  ColorSwatch,
  Divider,
  EditIcon,
  Group,
  Progress,
  SecondaryButton,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  useMantineTheme,
} from '@shared/components'
import { PaymentPlan } from '@shared/types'
import { formatDollarAmount, sortBy } from '@shared/utils'
import sumBy from 'lodash/sumBy'
import pluralize from 'pluralize'
import { useState } from 'react'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'
import { AbpPaymentRow } from './AbpPaymentRow'
import { EditPaymentPlanModal } from './EditPaymentPlanModal'

export const PaymentPlanSection = () => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const [isEditModalOpened, toggleEditModal] = useToggle()

  const { data: paymentPlansData, isLoading: isPaymentPlanInfoLoading } = useEmrQuery(
    'GET /patient/:patientId/payment-plans',
    {
      params: {
        patientId: patient?.oid || '',
      },
    },
    { enabled: Boolean(patient) },
  )

  const [page, setPage] = useState<number>(0)

  const unsortedPaymentPlans = (paymentPlansData as PaymentPlan[]) || []
  const paymentPlans = unsortedPaymentPlans.sort(sortBy({ key: 'createdAt', order: 'DESC' }))

  const numPaymentPlans = paymentPlans?.length
  const currentPaymentPlan = paymentPlans?.[page]

  const { data: paymentsData, isLoading: arePaymentsLoading } = useEmrQuery(
    'GET /payment-plan/payments',
    {
      query: {
        paymentPlanId: currentPaymentPlan?.oid || '',
      },
    },
    { enabled: Boolean(currentPaymentPlan) },
  )
  const payments = paymentsData || []

  if (!paymentPlans || !currentPaymentPlan) {
    return null
  }

  const { totalAmountInCents, paymentDates, paymentFrequency } = currentPaymentPlan

  const numPayments = paymentDates.length
  const amountPerPaymentInCents = totalAmountInCents / numPayments

  const amountPaidInCents = sumBy(
    payments.filter(payment => payment.latestCharge?.isPaid),
    payment => payment.latestCharge?.amountInCents || 0,
  )

  const ONE_HUNDRED = 100
  const percentPaid = (amountPaidInCents / totalAmountInCents) * ONE_HUNDRED

  if (isPaymentPlanInfoLoading || arePaymentsLoading) {
    return <Skeleton height={60} />
  }

  const canIncrementPage = page < numPaymentPlans - 1
  const canDecrementPage = page > 0

  const incrementPage = () => {
    if (canIncrementPage) {
      setPage(page + 1)
    }
  }

  const decrementPage = () => {
    if (canDecrementPage) {
      setPage(page - 1)
    }
  }

  return (
    <>
      <EditPaymentPlanModal
        opened={isEditModalOpened}
        onClose={() => toggleEditModal(false)}
        paymentPlan={currentPaymentPlan}
      />
      <Stack spacing='sm'>
        <Group position='apart'>
          <Text size='xs' color={colors => colors.text[1]}>
            {pluralize('Payment plan', numPaymentPlans)}
          </Text>
        </Group>
        <Card>
          <Stack>
            {paymentPlans.length > 1 && (
              <Group position='apart'>
                <SecondaryButton size='xs' onClick={decrementPage} disabled={!canDecrementPage}>
                  {`<`}
                </SecondaryButton>
                <Text size='xs' color={colors => colors.text[1]}>
                  {`${page + 1} of ${numPaymentPlans}`}
                </Text>
                <SecondaryButton size='xs' onClick={incrementPage} disabled={!canIncrementPage}>
                  {`>`}
                </SecondaryButton>
              </Group>
            )}
            <Group position='apart'>
              <Text>Total ABP amount</Text>
              <Text bold>
                {formatDollarAmount({
                  amount: totalAmountInCents,
                  unit: 'cents',
                })}
              </Text>
            </Group>
            <Progress value={percentPaid} />
            <Stack spacing='xs'>
              <Group position='apart'>
                <Group spacing='xs'>
                  <ColorSwatch
                    color={colors.actions[0]}
                    radius='sm'
                    size={sizes.icon.sm}
                    withShadow={false}
                  />
                  <Text>Paid</Text>
                </Group>
                <Text bold>
                  {formatDollarAmount({
                    amount: amountPaidInCents,
                    unit: 'cents',
                  })}
                </Text>
              </Group>
              <Group position='apart'>
                <Group spacing='xs'>
                  <ColorSwatch
                    color={colors.background[2]}
                    radius='sm'
                    size={sizes.icon.sm}
                    withShadow={false}
                  />
                  <Text>Balance</Text>
                </Group>
                <Text bold>
                  {formatDollarAmount({
                    amount: totalAmountInCents - amountPaidInCents,
                    unit: 'cents',
                  })}
                </Text>
              </Group>
            </Stack>
            <Divider />
            <Stack spacing='sm'>
              <Group position='apart'>
                <Text bold>
                  {`${formatDollarAmount({
                    amount: amountPerPaymentInCents,
                    unit: 'cents',
                  })}/${paymentFrequency}`}
                </Text>
                <TertiaryButton leftIcon={<EditIcon />} onClick={() => toggleEditModal(true)}>
                  Edit
                </TertiaryButton>
              </Group>
              <Text>{`${numPayments} ${pluralize('payment', numPayments)}`}</Text>
              <Stack spacing='sm'>
                {paymentDates.map(paymentDate => (
                  <AbpPaymentRow
                    key={paymentDate}
                    paymentPlan={currentPaymentPlan}
                    paymentDate={paymentDate}
                    payments={payments}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </>
  )
}
