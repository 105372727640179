import { Skeleton } from '@shared/components'
import range from 'lodash/range'

type LoadingRowProps = {
  headersLength: number
}

const LoadingRow = ({ headersLength }: LoadingRowProps) => {
  return (
    <tr>
      {range(0, headersLength).map(i => (
        <td key={i}>
          <Skeleton height={20} radius='xl' />
        </td>
      ))}
    </tr>
  )
}

export default LoadingRow
