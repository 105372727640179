import {
  Banner,
  Group,
  PrimaryButton,
  SecondaryButton,
  Stack,
  TertiaryButton,
} from '@shared/components'
import {
  InsuranceQueue,
  InsuranceQueueStatus,
  InsuranceReviewQueueItem,
  getOpheliaHttpError,
} from '@shared/types'
import { useState } from 'react'
import { useEmrMutation } from '../../../utils/hooks'

export type IRQBillingTeamActionSectionProps = {
  item: InsuranceReviewQueueItem
  currentQueue: InsuranceQueue
  refreshQueue: () => void
}
export const IRQBillingTeamActionSection = ({
  item,
  currentQueue,
  refreshQueue,
}: IRQBillingTeamActionSectionProps) => {
  // These are broken up into separate mutations so we can keep track of the disabled state of each
  const moveToSubscriptionPayMutation = useEmrMutation('PUT /patient/:patientId')
  const updateQueueMutation = useEmrMutation('PUT /patient/:patientId')
  const readyForSubmissionMutation = useEmrMutation('POST /insurance/review-queue/billing-team')

  const [error, setError] = useState<string | undefined>()

  const onError = (err: unknown) => {
    setError(getOpheliaHttpError(err))
  }

  const flagButtonOnClick = () => {
    updateQueueMutation.mutate(
      {
        params: { patientId: item.userId },
        data: {
          insuranceData: {
            insuranceQueueData: {
              queue: currentQueue === 'flagged' ? 'payors' : 'flagged',
            },
          },
        },
      },
      {
        onSuccess: () => {
          refreshQueue()
        },
        onError,
      },
    )
  }

  const moveToSubscriptionPayButtonOnClick = () => {
    moveToSubscriptionPayMutation.mutate(
      {
        params: { patientId: item.userId },
        data: {
          statuses: {
            insuranceAllowList: false,
          },
          insuranceData: {
            insuranceQueueData: {
              status: InsuranceQueueStatus.OutOfNetwork,
              // Set the queue to null so that the patient is removed from the review queue
              queue: null,
            },
          },
        },
      },
      {
        onSuccess: () => {
          refreshQueue()
        },
        onError,
      },
    )
  }

  const submitButtonOnClick = () => {
    readyForSubmissionMutation.mutate(
      {
        data: {
          patientId: item.userId,
        },
      },
      {
        onSuccess: () => {
          refreshQueue()
        },
        onError,
      },
    )
  }

  const mutationsLoading =
    moveToSubscriptionPayMutation.isLoading ||
    updateQueueMutation.isLoading ||
    readyForSubmissionMutation.isLoading

  return (
    <Stack>
      <Group position='right'>
        <TertiaryButton
          loading={updateQueueMutation.isLoading}
          disabled={mutationsLoading}
          onClick={flagButtonOnClick}
        >
          {currentQueue === 'flagged' ? 'Unflag' : 'Flag'}
        </TertiaryButton>
        <SecondaryButton
          loading={moveToSubscriptionPayMutation.isLoading}
          disabled={mutationsLoading}
          onClick={moveToSubscriptionPayButtonOnClick}
        >
          Move to subscription pay
        </SecondaryButton>
        <PrimaryButton
          loading={readyForSubmissionMutation.isLoading}
          disabled={mutationsLoading}
          onClick={submitButtonOnClick}
        >
          Ready for submission
        </PrimaryButton>
      </Group>
      {error && <Banner label={error} type='error' />}
    </Stack>
  )
}
