import { Group, Text } from '@shared/components'
import { getFormattedTaskCategory } from '@shared/types'
import { forwardRef } from 'react'

export type SelectTypeItemProps = {
  label: string
  category: string
}

export const SelectTypeItem = forwardRef<HTMLDivElement, SelectTypeItemProps>(
  ({ label, category, ...others }: SelectTypeItemProps, ref) => {
    const secondaryText = getFormattedTaskCategory(category)

    return (
      <div ref={ref} {...others}>
        <Group position='apart'>
          <Text>{label}</Text>
          <Text size='xs' color={({ text }) => text[1]}>
            {secondaryText}
          </Text>
        </Group>
      </div>
    )
  },
)

SelectTypeItem.displayName = 'SelectTypeItem'
