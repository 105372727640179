import { UseFormReturnType, isNotEmpty } from '@mantine/form'
import {
  FormValuePredicate,
  isAddressFormat,
  isAmountWords,
  isAnySelected,
  isBirthdayFormat,
  isCreditCardNumberFormat,
  isCvvCodeFormat,
  isDateFormat,
  isDateRelativeRange,
  isDigitCodeLength,
  isEmailFormat,
  isInRange,
  isLength,
  isMonthFormat,
  isNumberFormat,
  isPhoneFormat,
  isYearFormat,
  skipIfEmpty,
  validateWith,
} from '@shared/components'
import { FormFieldValue, stateNames } from '@shared/types'
import { usStatesList } from './utils'

export const prescriptionPINInvalid = isDigitCodeLength(4, 'PIN must be four numbers')
export const isAbbreviatedUsState = isAnySelected(
  usStatesList,
  'Invalid state. Please use a valid two letter state abbreviation.',
)
export const isAtleastOneWord = isAmountWords({ amount: 1, op: '>=' }, 'Invalid')
export const isNumber = isNumberFormat('Input must be a number')
export const isDate = isDateFormat('Invalid date')
export const isRequired = isNotEmpty('Required')
export const isConditionallyRequired = (predicate?: boolean) =>
  predicate ? isRequired : skipIfEmpty
export const isEmail = isEmailFormat('Invalid email')
export const isPhone = isPhoneFormat('Invalid phone')
export const isBirthday = isBirthdayFormat('Invalid birthday')
export const isAddress = isAddressFormat('Invalid address')
export const isZipCode = isDigitCodeLength(5, 'ZIP code must have 5 numbers')
export const isExpiryMonth = isMonthFormat('Invalid expiration month')
export const isExpiryYear = isYearFormat('Invalid expiration year')
export const isCvvCode = isCvvCodeFormat('Invalid CVV code')
export const isCreditCardNumber = isCreditCardNumberFormat('Invalid credit card number')
export const isTodayOrLater = isDateRelativeRange(
  { min: { value: 0, unit: 'day' } },
  'Date must be in the future',
)
export const isFourNumbers = isDigitCodeLength(4, 'Must be exactly four numbers')
export const inputCharacterLimitExceeded = ({ characterLimit }: { characterLimit: number }) =>
  isLength({ length: characterLimit, op: '<=' }, `Must be fewer than ${characterLimit} characters`)

export const isPositiveNumber = isInRange({ min: 0 }, 'Input must be zero or greater')

export const inputNumberBetween = ({ start, end }: { start: number; end: number }) =>
  isInRange({ min: start, max: end }, `Input must be a number between ${start} and ${end}`)

export const formAddressRules: Record<
  keyof FormFieldValue<'address'>,
  FormValuePredicate<FormFieldValue<'address'>[keyof FormFieldValue<'address'>]>
> = {
  address: validateWith(isRequired, isAddress),
  addressTwo: validateWith(skipIfEmpty, isAddress),
  city: validateWith(isRequired, isAddress),
  state: isAnySelected(stateNames, 'Required'),
  zipCode: validateWith(isRequired, isZipCode),
}
export const isYesOrNoSelected = isAnySelected(['yes', 'no'], 'Required')

/**
 * Returns the value of a field if it is dirty, otherwise undefined.
 * This hinges on the assumption that the API will ignore undefined values.
 */
export const getDirtyValue = <Values, Field extends keyof Values>(
  form: UseFormReturnType<Values>,
  field: Field,
) => {
  return form.isDirty(field) ? form.values[field] : undefined
}
