import {
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  NumberInput,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TextInput,
} from '@shared/components'
import { PromptContentResponse } from '@shared/types'
import { useWorkflowFormContext } from '../../utils/workflowUtils'

export type PromptResponseProps = PromptContentResponse & { promptContextKey: string }
export const PromptResponse = (props: PromptResponseProps) => {
  const form = useWorkflowFormContext()
  switch (props.responseType) {
    case 'single_selection':
      return (
        <RadioGroup {...form.getInputProps(props.promptContextKey)}>
          {props.options.map(option => (
            <Radio
              key={option.contextKey}
              value={option.contextKey}
              label={
                <>
                  {option.primaryText && <Text bold>{option.primaryText}</Text>}
                  {option.secondaryText && <Text>{option.secondaryText}</Text>}
                </>
              }
            />
          ))}
        </RadioGroup>
      )
    case 'number_input':
      return <NumberInput {...form.getInputProps(props.promptContextKey)} />
    case 'short_input':
      return <TextInput {...form.getInputProps(props.promptContextKey)} />
    case 'long_input':
      return <TextInput {...form.getInputProps(props.promptContextKey)} />
    case 'multiple_choice':
      return (
        <CheckboxGroup {...form.getInputProps(props.promptContextKey)}>
          {props.options.map(option => (
            <Checkbox
              key={option.contextKey}
              value={option.contextKey}
              label={
                <>
                  {option.primaryText && <Text bold>{option.primaryText}</Text>}
                  {option.secondaryText && <Text>{option.secondaryText}</Text>}
                </>
              }
            />
          ))}
        </CheckboxGroup>
      )
    case 'likert':
      return (
        <Box sx={{ width: 'fit-content' }}>
          <Stack spacing='sm'>
            <RadioGroup {...form.getInputProps(props.promptContextKey)}>
              <Group>
                {props.options.map(option => (
                  <Radio
                    iconless
                    w={36}
                    h={36}
                    radius='xl'
                    key={option.contextKey}
                    value={option.contextKey}
                    label={<Text align='center'>{option.text}</Text>}
                  />
                ))}
              </Group>
            </RadioGroup>
            <Group position='apart'>
              <Text size='xs'>{props.leftText}</Text>
              <Text size='xs'>{props.rightText}</Text>
            </Group>
          </Stack>
        </Box>
      )
    case 'widget':
      return null
  }
}
