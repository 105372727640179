import React from 'react'
import { useQuery } from 'react-query'
import { employeesApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import TModal from '../../../components/templates/TModal'

export type ODoseSpotModalProps = {
  closeModal: () => void
}

const ODoseSpotModal = ({ closeModal }: ODoseSpotModalProps) => {
  const url = useQuery(['employeesApi.getDoseSpotUrl'], employeesApi.getDoseSpotUrl)

  return (
    <TModal width='w-10/12' height='h-full' closeModal={closeModal} style={{ maxHeight: '90vh' }}>
      <div className='w-full h-full'>
        {url.isFetching && <ALoadingSpinner />}
        {!url.isFetching && url.data && (
          <iframe
            frameBorder='0'
            className='overflow-hidden h-full w-full'
            src={url.data.url}
          ></iframe>
        )}
      </div>
    </TModal>
  )
}

export default ODoseSpotModal
