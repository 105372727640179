import {
  Divider,
  Group,
  PlusIcon,
  PrimaryButton,
  ScrollArea,
  Stack,
  Table,
  Text,
  Th,
  TitleFour,
} from '@shared/components'
import { DrugScreen, drugScreenInfo } from '@shared/types'
import { sortBy } from '@shared/utils'
import range from 'lodash/range'
import { useState } from 'react'
import LoadingRow from '../../../components/atoms/LoadingRow'
import { usePatient } from '../PPatientContext'
import { DeleteDrugScreenModal } from './DeleteDrugScreenModal'
import DrugScreenEmptyState from './DrugScreenEmptyState'
import DrugScreenRow from './DrugScreenRow'
import { EditDrugScreenDrawer } from './EditDrugScreenDrawer'

export type DrugScreenSectionProps = {
  drugScreens: DrugScreen[] | undefined
  isLoading: boolean
  readOnly?: boolean
}

const DrugScreenSection = ({
  drugScreens,
  isLoading,
  readOnly = false,
}: DrugScreenSectionProps) => {
  const { setModal, patientId } = usePatient()
  const [drugScreenToEdit, setDrugScreenToEdit] = useState<DrugScreen | null>(null)
  const [drugScreenToDelete, setDrugScreenToDelete] = useState<DrugScreen | null>(null)

  const displayEmptyState = !isLoading && (!drugScreens || drugScreens.length === 0)

  return (
    <>
      {displayEmptyState ? (
        <DrugScreenEmptyState
          onClick={() => setModal({ type: 'add-drug-screen' })}
          displayHeader={!readOnly}
        />
      ) : (
        <Stack sx={{ overflow: 'hidden' }}>
          {!readOnly && (
            <>
              <Group position='apart'>
                <TitleFour>Drug screens</TitleFour>
                <PrimaryButton
                  onClick={() => setModal({ type: 'add-drug-screen' })}
                  leftIcon={<PlusIcon />}
                >
                  Drug screen
                </PrimaryButton>
              </Group>
              <Divider />
            </>
          )}
          <ScrollArea>
            <Table verticalSpacing='sm' horizontalSpacing='xs' withBorder>
              <thead className='mantine'>
                <tr className='mantine'>
                  <Th sortable={false} style={{ width: '26ch' }}>
                    <Text bold>Date</Text>
                  </Th>
                  {Object.keys(drugScreenInfo).map(drugScreen => {
                    return (
                      <Th sortable={false} center style={{ width: '4ch' }} key={drugScreen}>
                        <Text bold size='xs'>
                          {drugScreenInfo[drugScreen as keyof typeof drugScreenInfo].abbreviation}
                        </Text>
                      </Th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {isLoading &&
                  range(0, 3).map(i => (
                    <LoadingRow headersLength={Object.keys(drugScreenInfo).length + 1} key={i} />
                  ))}
                {drugScreens
                  ?.sort(sortBy({ key: 'administeredAt', order: 'DESC' }))
                  .map(drugScreen => {
                    return (
                      <DrugScreenRow
                        key={drugScreen.oid}
                        drugScreen={drugScreen}
                        readOnly={readOnly}
                        onEdit={() => setDrugScreenToEdit(drugScreen)}
                        onDelete={() => setDrugScreenToDelete(drugScreen)}
                      />
                    )
                  })}
              </tbody>
            </Table>
          </ScrollArea>
        </Stack>
      )}
      <EditDrugScreenDrawer
        drugScreen={drugScreenToEdit}
        opened={Boolean(drugScreenToEdit)}
        onClose={() => setDrugScreenToEdit(null)}
      />
      <DeleteDrugScreenModal
        onClose={() => setDrugScreenToDelete(null)}
        opened={Boolean(drugScreenToDelete)}
        drugScreenId={drugScreenToDelete?.oid ?? ''}
        patientId={patientId}
      />
    </>
  )
}

export default DrugScreenSection
