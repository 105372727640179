import React from 'react'

const AFakePatientRow = () => (
  <tr className='group select-none hover:bg-white hover:shadow h-10 border-b border-separator'>
    <td className='pl-2 group-hover:bg-white name'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer text-near-white-readable group-hover:text-color'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer text-near-white-readable group-hover:text-color'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
    <td className='group-hover:bg-hover group-hover:cursor-pointer text-near-white-readable group-hover:text-color'>
      <div className='h-4 bg-gray-400 rounded w-3/4'></div>
    </td>
  </tr>
)

export default AFakePatientRow
