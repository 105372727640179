import { Colors, Group, Pill, Stack, Text, Tooltip } from '@shared/components'
import { Appointment, getVisitTypeName } from '@shared/types'
import { dayjs } from '@shared/utils'

const StatusPill = ({
  noShow,
  canceled,
  date,
}: {
  noShow: boolean
  canceled: boolean
  date: string
}) => {
  if (dayjs(date).isAfter(dayjs())) {
    return (
      <Pill status='none' variant='filled'>
        Today
      </Pill>
    )
  }
  if (noShow) {
    return (
      <Pill status='error' variant='filled'>
        No show
      </Pill>
    )
  }
  if (canceled) {
    return (
      <Pill status='warning' variant='filled'>
        Canceled
      </Pill>
    )
  }

  return (
    <Pill status='success' variant='filled'>
      Attended
    </Pill>
  )
}

export const LatestVisitCard = ({ appointment }: { appointment: Appointment }) => {
  const startTime = dayjs(appointment.datetime).format('h:mma')
  const endTime = dayjs(
    dayjs(appointment.datetime).add(Number(appointment.duration), 'minutes'),
  ).format('h:mma z')

  const label = `${getVisitTypeName(
    appointment.type,
  )} ${`(${appointment?.duration?.toString()} min)`}`

  const date = dayjs(appointment.datetime).format('MM/DD/YYYY')
  return (
    <Tooltip position='top-start' label={appointment.confirmed ? 'Confirmed' : 'Not confirmed'}>
      <Stack
        sx={({ other: { colors, sizes } }) => ({
          backgroundColor: colors.background[1],
          padding: sizes.gap.lg,
        })}
        spacing='sm'
      >
        <Group position='apart' noWrap>
          <Text bold color={({ text }) => text[0]}>
            {label}
          </Text>
          <Group spacing='sm'>
            <StatusPill
              date={appointment.datetime}
              canceled={appointment.canceled}
              noShow={Boolean(appointment.noShow)}
            />
          </Group>
        </Group>
        <Stack spacing='xs'>
          <Text color={(colors: Colors) => colors.text[1]}>
            {startTime} - {endTime}
          </Text>
          <Text color={(colors: Colors) => colors.text[1]}>
            {appointment.calendar} | {date}
          </Text>
        </Stack>
      </Stack>
    </Tooltip>
  )
}
