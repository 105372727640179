import { useDidUpdate, useToggle } from '@mantine/hooks'
import {
  Alert,
  AlertIcon,
  Card,
  Checkbox,
  PlusCircleIcon,
  SecondaryButton,
  Stack,
  useBanner,
} from '@shared/components'
import {
  EncounterAppointmentData,
  EncounterPatientData,
  EncounterStatuses,
  EncounterSubscriber,
  EncounterWithErrors,
  InsuranceCard,
  InsuranceType,
  getOpheliaHttpError,
} from '@shared/types'
import { dayjs, name } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import {
  EncounterSection,
  formatInsuranceCardInfoAsPatientProvidedInsurance,
} from '../../../utils/encounterUtils'
import { useTypedParams } from '../../../utils/hooks'
import { LoadingListCardLayout } from '../../core/loading/LoadingComponents'
import { EncounterFooterButtons } from './components/EncounterFooterButtons'
import { BillingProviderSection } from './sections/BillingProviderSection'
import { BillingTeamNotesSection } from './sections/BillingTeamNotesSection'
import { EncounterDiagnosisSection } from './sections/EncounterDiagnosisSection'
import { EncounterHeaderSection } from './sections/EncounterHeaderSection'
import { InsuranceSection } from './sections/InsuranceSection'
import { MiscellaneousSection } from './sections/MiscellaneousSection'
import { PatientSection } from './sections/PatientSection'
import { RenderingProviderSection } from './sections/RenderingProviderSection'
import { ServiceFacilitySection } from './sections/ServiceFacilitySection'
import { ServiceLinesSection } from './sections/ServiceLinesSection'

const encounterHasErrors = (encounter: EncounterWithErrors, includeSecondary: boolean) => {
  if (!encounter) {
    return true
  }

  // Ignore secondary insurance errors if the encounter isn't submitted with secondary insurance
  const errors = includeSecondary
    ? encounter.errors
    : encounter?.errors?.filter(error => !error?.path.includes('subscriber_secondary'))
  return Boolean(errors?.length)
}

export const EncounterCard = () => {
  const { showBanner } = useBanner()
  const queryClient = useQueryClient()
  const {
    pathParams: { status, encounterId },
  } = useTypedParams('/billing/encounters/:status/:encounterId')

  const [encounterQueryKey, encounterQueryFunction] = emrApi.getQuery(
    'GET /encounters/:encounterId',
    { params: { encounterId: encounterId || '' } },
  )
  const encounterQuery = useQuery(encounterQueryKey, encounterQueryFunction, {
    enabled: Boolean(encounterId),
  })

  const encounter = encounterQuery.data
  const encounterHasSecondaryInsurance = Boolean(encounter?.subscriber_secondary)

  // Use toggle takes an array of values with the first value being the default value. This should be initially checked if the patient has secondary insurance.
  const [isSubmitSecondaryChecked, toggleSubmitSecondaryChecked] = useToggle(
    encounterHasSecondaryInsurance ? [true, false] : [false, true],
  )

  useDidUpdate(() => {
    toggleSubmitSecondaryChecked(encounterHasSecondaryInsurance)
  }, [encounterHasSecondaryInsurance])

  const patientId = encounter?.patient.external_id ?? ''

  const [editingSection, setEditingSection] = useState<EncounterSection | null>(null)

  const [freeformDataQueryKey, freeformDataQueryFunction] = emrApi.getQuery(
    'GET /insurance/freeformData/:patientId',
    { params: { patientId } },
  )

  const freeformDataQuery = useQuery(freeformDataQueryKey, freeformDataQueryFunction, {
    enabled: Boolean(patientId),
  })

  const updateInsuranceData = useMutation(emrApi.getMutation('PUT /insurance/:patientId/update'))

  const updatePatientProvidedInsuranceData = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/insurance/update'),
  )

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  // Duplicate of updateEncounter so that we can track loading state separately
  const addSecondaryInsuranceToEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  const submitToCandid = useMutation(emrApi.getMutation('POST /candid'), {
    onSuccess: () => {
      void queryClient.invalidateQueries('GET /encounters/count')
      void queryClient.invalidateQueries('encountersApi.getEncounters')
      showBanner({
        label: 'Successfully submitted encounter to Candid.',
        type: 'success',
        dismissable: true,
      })
    },
    onError: (error: unknown) => {
      const body = document.querySelector('body')
      if (body) {
        body.scrollTo({ top: 0, behavior: 'smooth' })
      }

      const errorMessage = getOpheliaHttpError(
        error,
        'Unknown Candid error. Please contact engineering.',
      )
      showBanner({
        label: errorMessage,
        type: 'error',
        dismissable: true,
      })
    },
  })

  const [patientQueryKey, patientQueryFunction] = emrApi.getQuery('GET /patient/:patientId', {
    params: { patientId },
  })

  const patientQuery = useQuery(patientQueryKey, patientQueryFunction, {
    enabled: Boolean(patientId),
  })

  const [insurancesQueryKey, insurancesQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/insurances',
    {
      params: {
        patientId,
      },
    },
  )

  const insuranceQuery = useQuery(insurancesQueryKey, insurancesQueryFunction, {
    enabled: Boolean(patientId),
  })

  const primaryInsurance = insuranceQuery.data?.find(
    insurance => insurance.oid === patientQuery?.data?.insuranceData?.primaryInsuranceId,
  )

  const secondaryInsurance = insuranceQuery.data?.find(
    insurance => insurance.oid === patientQuery?.data?.insuranceData?.secondaryInsuranceId,
  )

  const saveEncounter = async (encounterData: Partial<EncounterWithErrors>) => {
    await updateEncounter.mutateAsync(
      {
        data: {
          encounter: encounterData,
          id: encounterId || '',
        },
      },
      {
        onSuccess: () => {
          void encounterQuery.refetch()
        },
      },
    )
  }

  const savePatientInsuranceInformation = async (
    data: Partial<InsuranceCard>,
    insuranceType: InsuranceType,
  ) => {
    const patientProvided = formatInsuranceCardInfoAsPatientProvidedInsurance(data)
    await updatePatientProvidedInsuranceData.mutateAsync({
      params: { patientId },
      data: { ...patientProvided, insuranceType },
    })
  }

  const reviewerId = useAuth().currentUser.oid
  const reviewedDate = dayjs().toISOString()

  const updateEncounterStatus = async ({
    status,
    moveToAllowList,
  }: {
    status: EncounterStatuses
    moveToAllowList?: boolean
  }) => {
    const encounterData = moveToAllowList
      ? { status, reviewedDate, reviewerId, onAllowList: true }
      : { status, reviewedDate, reviewerId }

    await updateEncounter.mutateAsync(
      {
        data: {
          encounter: encounterData,
          id: encounterId || '',
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries('GET /encounters/count')
          void queryClient.invalidateQueries('encountersApi.getEncounters')
        },
      },
    )
  }

  const submitEncounterToCandid = async () => {
    await submitToCandid.mutateAsync({
      data: {
        encounterId: encounterId || '',
        includeSecondaryInsurance: isSubmitSecondaryChecked,
        reviewedDate,
        reviewerId,
      },
    })
  }

  const addSecondaryInsuranceOnClick = () => {
    addSecondaryInsuranceToEncounter.mutate(
      {
        data: {
          id: encounterId ?? '',
          encounter: {
            // Just need to write any data to subscriber_secondary so it is no longer null
            subscriber_secondary: {
              first_name: '',
            },
          },
        },
      },
      {
        onSuccess: () => {
          void encounterQuery.refetch()
        },
      },
    )
  }

  const lastUpdatedId = encounter?.reviewerId

  // Use isFetching, as there are forms downstream that rely on the data being present on mount
  if (encounterQuery.isFetching) {
    return <LoadingListCardLayout cards={10} />
  }

  if (!encounter) {
    return null
  }

  const editSectionButtonsEnabled =
    editingSection === null &&
    [EncounterStatuses.Flagged, EncounterStatuses.Unsubmitted].includes(status as EncounterStatuses)

  const showAddSecondaryInsuranceButton = [
    EncounterStatuses.Flagged,
    EncounterStatuses.Unsubmitted,
  ].includes(status as EncounterStatuses)

  const isFutureEncounter = Boolean(
    dayjs().format('YYYY-MM-DD') < encounter.date_of_service ||
      (encounter.end_date_of_service &&
        dayjs().format('YYYY-MM-DD') < encounter.end_date_of_service),
  )

  return (
    <Card p='lg'>
      <Stack spacing='lg'>
        {encounter.unsubmitted_reason && (
          <Alert variant='warning' title='Encounter not auto-submitted' icon={<AlertIcon />}>
            {encounter.unsubmitted_reason}
          </Alert>
        )}
        <EncounterHeaderSection
          isEditing={editingSection === 'header'}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'header' : null)}
          onSave={(appointmentData: EncounterAppointmentData) =>
            saveEncounter({
              appointment_type: appointmentData.appointment_type,
              date_of_service: appointmentData.date_of_service,
              end_date_of_service: appointmentData.end_date_of_service,
            })
          }
          saving={updateEncounter.isLoading}
          lastUpdatedById={lastUpdatedId}
          appointmentType={encounter.appointment_type}
          dateOfService={encounter.date_of_service}
          endDateOfService={encounter.end_date_of_service}
          encounterId={encounter.oid}
          encounterStatus={status}
          patientName={name({
            first: encounter.patient.first_name,
            last: encounter.patient.last_name,
          }).full()}
          patientId={patientId}
          visitId={encounter.appointmentId}
          patientTime={encounter.patientTime}
        />
        <BillingTeamNotesSection
          isEditing={editingSection === 'billing notes'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'billing notes' : null)}
          notes={freeformDataQuery.data ?? ''}
          onSave={async (notes: string) => {
            await updateInsuranceData.mutateAsync({
              params: { patientId },
              data: {
                freeformData: notes,
              },
            })
            void freeformDataQuery.refetch()
          }}
          saving={updateInsuranceData.isLoading}
          loading={freeformDataQuery.isLoading}
        />
        <PatientSection
          isEditing={editingSection === 'patient'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'patient' : null)}
          patientData={encounter.patient}
          onSave={(patientData: EncounterPatientData) => saveEncounter({ patient: patientData })}
          saving={updateEncounter.isLoading}
        />
        <InsuranceSection
          insuranceType='primary'
          setEditingSection={setEditingSection}
          editingSection={editingSection}
          editSectionButtonsEnabled={editSectionButtonsEnabled}
          insuranceData={encounter.subscriber_primary}
          eligibleData={primaryInsurance?.eligibleData ?? undefined}
          onSave={async (insuranceData: Partial<EncounterSubscriber>) => {
            await saveEncounter({ subscriber_primary: insuranceData })
            if (insuranceData.insurance_card) {
              await savePatientInsuranceInformation(insuranceData.insurance_card, 'primary')
            }
          }}
          saving={updateEncounter.isLoading}
        />
        {showAddSecondaryInsuranceButton ? (
          encounter.subscriber_secondary ? (
            <Checkbox
              label={`Submit patient's secondary insurance to Candid`}
              checked={isSubmitSecondaryChecked}
              onChange={() => toggleSubmitSecondaryChecked()}
            />
          ) : (
            <SecondaryButton
              leftIcon={<PlusCircleIcon />}
              onClick={addSecondaryInsuranceOnClick}
              loading={addSecondaryInsuranceToEncounter.isLoading}
              disabled={addSecondaryInsuranceToEncounter.isLoading}
            >
              Secondary insurance
            </SecondaryButton>
          )
        ) : null}
        {isSubmitSecondaryChecked && (
          <InsuranceSection
            insuranceType='other'
            setEditingSection={setEditingSection}
            editingSection={editingSection}
            editSectionButtonsEnabled={editSectionButtonsEnabled}
            insuranceData={encounter.subscriber_secondary}
            eligibleData={secondaryInsurance?.eligibleData ?? undefined}
            onSave={async (insuranceData: Partial<EncounterSubscriber>) => {
              await saveEncounter({ subscriber_secondary: insuranceData })
              if (insuranceData.insurance_card) {
                await savePatientInsuranceInformation(insuranceData.insurance_card, 'other')
              }
            }}
            saving={updateEncounter.isLoading}
          />
        )}
        <EncounterDiagnosisSection
          showAddButton={editSectionButtonsEnabled}
          diagnoses={encounter.diagnoses}
          encounterId={encounterId || ''}
        />
        <ServiceLinesSection
          setEditing={(bool: boolean) => setEditingSection(bool ? 'service lines' : null)}
          editingEnabled={editSectionButtonsEnabled}
          serviceLines={encounter.service_lines}
          encounterId={encounterId || ''}
          placeOfServiceCode={encounter.place_of_service_code || ''}
        />
        <RenderingProviderSection
          isEditing={editingSection === 'rendering provider'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'rendering provider' : null)}
          renderingProvider={encounter.rendering_provider}
          encounterId={encounterId || ''}
        />

        <BillingProviderSection
          isEditing={editingSection === 'billing provider'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'billing provider' : null)}
          billingProvider={encounter.billing_provider}
          encounterId={encounterId || ''}
        />
        <ServiceFacilitySection
          encounterId={encounterId || ''}
          serviceFacility={encounter.service_facility}
          isEditing={editingSection === 'service facility'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'service facility' : null)}
        />
        <MiscellaneousSection
          encounterId={encounterId || ''}
          miscellaneousData={{
            prior_authorization_number: encounter.prior_authorization_number || '',
          }}
          isEditing={editingSection === 'miscellaneous'}
          editButtonEnabled={editSectionButtonsEnabled}
          setEditing={(bool: boolean) => setEditingSection(bool ? 'miscellaneous' : null)}
        />
        <EncounterFooterButtons
          currentStatus={status as EncounterStatuses}
          updateEncounterStatus={updateEncounterStatus}
          submitToCandid={submitEncounterToCandid}
          submitToCandidButtonDisabled={
            encounterHasErrors(encounter, isSubmitSecondaryChecked) || editingSection !== null
          }
          isFutureEncounter={isFutureEncounter}
        />
      </Stack>
    </Card>
  )
}
