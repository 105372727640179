import { SmartPhrase } from '@shared/components'
import { toTime } from '@shared/utils'
import { useLunaQuery } from '../../utils/hooks'

export const useSmartPhrases = () => {
  const smartPhrasesQuery = useLunaQuery('GET /smart-phrases', {
    // Smart phrases don't change often, so we can cache them for a while
    cacheTime: toTime('5 minutes').ms(),
    staleTime: toTime('5 minutes').ms(),
  })

  const smartPhrases = smartPhrasesQuery.data?.data || []

  // To use smart phrases in the RichTextEditor, we need to convert the data to the format that the RichTextEditor expects
  const rteFormattedSmartPhrases: SmartPhrase[] = smartPhrases.map(smartPhrase => ({
    id: smartPhrase.oid,
    // Include the category name in the label if the categoryName is not null or an empty string
    label: `${smartPhrase.name}${smartPhrase.categoryName ? ` (${smartPhrase.categoryName})` : ''}`,
    text: smartPhrase.text,
  }))

  return {
    smartPhrases,
    rteFormattedSmartPhrases,
    isLoading: smartPhrasesQuery.isLoading,
  }
}
