import { px, useMantineTheme } from '@shared/components'
import {
  AsqScoreMapping,
  Barc10ScoreMapping,
  ObjectiveMeasuresTitle,
  Phq9AndGad7ScoreMapping,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import minBy from 'lodash/minBy'
import {
  CartesianGrid as RechartsCartesianGrid,
  Line as RechartsLine,
  LineChart as RechartsLineChart,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
} from 'recharts'
import ActiveDot from './ActiveDot'
import Dot from './Dot'
import DrawerTooltip from './DrawerTooltip'
import { ChartResponse } from './ObjectiveMeasuresChartDrawer'
import { formatData, formatDate, generateXAxisDates, generateYAxisValues } from './chartUtils'

const formSpecificData = {
  'PHQ-9': {
    goodResultThreshold: 0,
    badResultThreshold: 2,
    maxChartValue: 3,
    higherResultIsBetter: false,
    scoreMapping: Phq9AndGad7ScoreMapping,
  },
  'PHQ-8': {
    goodResultThreshold: 0,
    badResultThreshold: 2,
    maxChartValue: 3,
    higherResultIsBetter: false,
    scoreMapping: Phq9AndGad7ScoreMapping,
  },
  'GAD-7': {
    goodResultThreshold: 0,
    badResultThreshold: 2,
    maxChartValue: 3,
    higherResultIsBetter: false,
    scoreMapping: Phq9AndGad7ScoreMapping,
  },
  'BARC-10': {
    goodResultThreshold: 5,
    badResultThreshold: 2,
    maxChartValue: 6,
    higherResultIsBetter: true,
    scoreMapping: Barc10ScoreMapping,
  },
  ASQ: {
    goodResultThreshold: 0,
    badResultThreshold: 1,
    maxChartValue: 1,
    higherResultIsBetter: false,
    scoreMapping: AsqScoreMapping,
  },
}

type DrawerChartProps = {
  data: ChartResponse[]
  title: ObjectiveMeasuresTitle
}

const DrawerChart = ({ data, title }: DrawerChartProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const formattedData = formatData(data)

  const earliestResponseDate = minBy(formattedData, 'completedAt')?.completedAt
  const monthsSinceEarliestResponse = dayjs().diff(dayjs(earliestResponseDate), 'month') + 1
  const xAxisDates = generateXAxisDates(monthsSinceEarliestResponse)

  const {
    goodResultThreshold,
    badResultThreshold,
    maxChartValue,
    higherResultIsBetter,
    scoreMapping,
  } = formSpecificData[title]

  const yAxisValues = generateYAxisValues(maxChartValue, 1)

  return (
    <RechartsResponsiveContainer width='100%' height={200}>
      <RechartsLineChart data={formattedData}>
        <RechartsCartesianGrid
          stroke={colors.background[2]}
          vertical={false}
          strokeWidth={sizes.outline.md}
        />
        <RechartsYAxis
          ticks={yAxisValues}
          domain={[0, maxChartValue]}
          tickLine={false}
          style={{ color: colors.text[1], fontSize: 12 }}
        />
        <RechartsXAxis
          dataKey='unixTimestamp'
          ticks={xAxisDates}
          tickFormatter={formatDate}
          type='number'
          domain={[xAxisDates[0]!, xAxisDates[xAxisDates.length - 1]!]}
          padding={{ left: 30, right: 30 }}
          tickLine={false}
          style={{ color: colors.text[1], fontSize: 12 }}
        />
        <RechartsTooltip
          content={<DrawerTooltip colors={colors} scoreMapping={scoreMapping} />}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
        <RechartsLine
          type='linear'
          dataKey='score'
          stroke={colors.background[4]}
          dot={
            <Dot
              goodResultThreshold={goodResultThreshold}
              badResultThreshold={badResultThreshold}
              higherResultIsBetter={higherResultIsBetter}
              colors={colors}
              strokeWidth={px(sizes.outline.lg)}
            />
          }
          activeDot={
            <ActiveDot
              goodResultThreshold={goodResultThreshold}
              badResultThreshold={badResultThreshold}
              higherResultIsBetter={higherResultIsBetter}
              colors={colors}
              internalStrokeWidth={px(sizes.outline.lg)}
              externalStrokeWidth={px(sizes.outline.sm)}
            />
          }
          strokeWidth={sizes.outline.md}
        />
      </RechartsLineChart>
    </RechartsResponsiveContainer>
  )
}

export default DrawerChart
