import { Checkbox, Radio, RadioGroup, Stack, TextInput } from '@shared/components'
import { dischargeNoteMapping } from '@shared/types'

import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const ReferralSection = ({
  form,
  optional,
  isPAMedicaidCoe,
}: {
  form: DischargeNoteForm
  optional: boolean
  isPAMedicaidCoe: boolean
}) => {
  return (
    <Stack>
      <RadioGroup
        {...form.getInputProps('referral_request')}
        label={formLabel('Patient interested in referrals?', optional)}
        orientation='horizontal'
      >
        <Radio
          label='Yes'
          value='yes'
          sx={{
            flexGrow: 1,
          }}
        />
        <Radio
          label='No'
          value='no'
          sx={{
            flexGrow: 1,
          }}
        />
      </RadioGroup>
      {form.values.referral_request === 'yes' && (
        <Stack spacing='sm'>
          <Checkbox
            label={dischargeNoteMapping.referral_request_inpatient_program}
            key='referral_request_inpatient_program'
            {...form.getInputProps('referral_request_inpatient_program')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_residential_program}
            key='referral_request_residential_program'
            {...form.getInputProps('referral_request_residential_program')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_intensive_outpatient_program}
            key='referral_request_intensive_outpatient_program'
            {...form.getInputProps('referral_request_intensive_outpatient_program')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_methadone_program}
            key='referral_request_methadone_program'
            {...form.getInputProps('referral_request_methadone_program')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_mental_health_psychiatric}
            key='referral_request_mental_health_psychiatric'
            {...form.getInputProps('referral_request_mental_health_psychiatric')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_pcp}
            key='referral_request_pcp'
            {...form.getInputProps('referral_request_pcp')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_buprenorphine_clinic}
            key='referral_request_buprenorphine_clinic'
            {...form.getInputProps('referral_request_buprenorphine_clinic')}
          />
          <Checkbox
            label={dischargeNoteMapping.referral_request_other}
            key='referral_request_other'
            {...form.getInputProps('referral_request_other')}
          />
          {form.values.referral_request_other && (
            <TextInput
              placeholder='Additional details...'
              {...form.getInputProps('referral_request_additional_details')}
            />
          )}
          {isPAMedicaidCoe && (
            <RadioGroup
              {...form.getInputProps('referral_request_urgent')}
              label={formLabel('Is the referral request urgent?', optional)}
              orientation='horizontal'
            >
              <Radio
                label='Yes'
                value='yes'
                sx={{
                  flexGrow: 1,
                }}
              />
              <Radio
                label='No'
                value='no'
                sx={{
                  flexGrow: 1,
                }}
              />
            </RadioGroup>
          )}
        </Stack>
      )}
      {form.values.referral_request === 'no' && (
        <TextInput
          placeholder='Additional details...'
          {...form.getInputProps('no_referral_request_explanation')}
        />
      )}
    </Stack>
  )
}
