import {
  Alert,
  CheckCircleIcon,
  Group,
  InfoIcon,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  Stack,
  Table,
  Text,
  useMantineTheme,
  XCircleIcon,
} from '@shared/components'
import { InsuranceQueueStatus } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { useEmrQuery, usePatientInsurances } from '../../utils/hooks'
import { usePatient } from './PPatientContext'

export const IRQRequirementsModal = ({ close }: { close: () => void }) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const { patientQuery, patientID } = usePatient()
  const insurancesQuery = usePatientInsurances(patientQuery?.data)
  const primaryInsurance = insurancesQuery?.data?.primaryInsurance

  const patient = patientQuery?.data

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'), {
    onSuccess: () => {
      void patientQuery?.refetch()
      close()
    },
  })

  const { data: consents, isLoading: isConsentsLoading } = useEmrQuery(
    'GET /patient/:patientId/consents',
    {
      params: { patientId: patientID },
    },
  )

  const financialAgreementSigned = consents?.some(consent => consent.type === 'financial')
  const eligibleForIRQ = Boolean(
    financialAgreementSigned &&
      patient?.personalData.birthday &&
      primaryInsurance?.basicInsuranceData?.insuranceCardFrontId &&
      primaryInsurance?.basicInsuranceData?.insuranceCardBackId,
  )

  const addToQueue = async () => {
    await updatePatient.mutateAsync({
      params: { patientId: patientID },
      data: {
        insuranceData: {
          insuranceQueueData: {
            queue: 'enrollment',
            timestamp: new Date().toISOString(),
            status: InsuranceQueueStatus.New,
          },
        },
      },
    })
  }
  const isLoading = insurancesQuery?.isLoading || isConsentsLoading

  return (
    <Modal
      opened
      onClose={close}
      title={`Add ${patient?.personalData?.firstName} to insurance queue`}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={close}>No, don&apos;t add</SecondaryButton>
          <PrimaryButton onClick={addToQueue} loading={updatePatient.isLoading}>
            Yes, add patient to insurance queue
          </PrimaryButton>
        </Group>
      }
    >
      {isLoading && (
        <Stack spacing='sm'>
          <Skeleton height={15} />
          <Skeleton height={15} />
          <Skeleton height={15} />
          <Skeleton height={15} />
        </Stack>
      )}
      {!isLoading && eligibleForIRQ && (
        <Text> Are you sure you want to add this patient to the insurance review queue?</Text>
      )}
      {!isLoading && !eligibleForIRQ && (
        <Stack spacing='md'>
          <Alert variant='secondary' icon={<InfoIcon />}>
            The following items are required to add {patient?.personalData?.firstName} to the
            insurance review queue
          </Alert>
          <Table striped withBorder>
            <tbody>
              <tr>
                <td>
                  <Group spacing='sm'>
                    {financialAgreementSigned ? (
                      <CheckCircleIcon color={colors.success[0]} />
                    ) : (
                      <XCircleIcon color={colors.error[0]} />
                    )}
                    <Text>Financial agreement signed</Text>
                  </Group>
                </td>
              </tr>
              <tr>
                <td>
                  <Group spacing='sm'>
                    {patient?.personalData.birthday ? (
                      <CheckCircleIcon color={colors.success[0]} />
                    ) : (
                      <XCircleIcon color={colors.error[0]} />
                    )}
                    <Text>Cardholder birthday on file</Text>
                  </Group>
                </td>
              </tr>
              <tr>
                <td>
                  <Group spacing='sm'>
                    {primaryInsurance?.basicInsuranceData?.insuranceCardFrontId &&
                    primaryInsurance?.basicInsuranceData?.insuranceCardBackId ? (
                      <CheckCircleIcon color={colors.success[0]} />
                    ) : (
                      <XCircleIcon color={colors.error[0]} />
                    )}
                    <Text>Insurance card photos uploaded</Text>
                  </Group>
                </td>
              </tr>
            </tbody>
          </Table>
          <Text size='xs' color={colors => colors.text[1]}>
            Are you sure you want to proceed?
          </Text>
        </Stack>
      )}
    </Modal>
  )
}
