import { useToggle } from '@mantine/hooks'
import { Alert, Banner, Flex, ScrollArea, ShieldIcon, Stack, useBanner } from '@shared/components'
import {
  Appointment,
  ClosedNoteDetails,
  Form,
  PSYCH_DIAGNOSIS_CODES,
  ProblemListProblem,
  PsychDiagnosisCode,
  SMOKING_CESSATION_DIAGNOSIS_CODES,
  VisitNoteType,
  hasAppointmentType,
  hasHomeState,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import sampleSize from 'lodash/sampleSize'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { analytics } from '../../../analytics'
import { emrApi, formsApi, patientsApi, visitNotesApi } from '../../../api'
import AAlertBanner from '../../../components/atoms/AAlertBanner'
import ANoteLoading from '../../../components/atoms/ANoteLoading'
import MErrorModal from '../../../components/molecules/MErrorModal'
import TModal from '../../../components/templates/TModal'
import { useAuth } from '../../../context/auth'
import * as Fullstory from '../../../utils/fullstory'
import { usePageTrace } from '../../../utils/hooks'
import { FORMS_ENUM, isNotAbleToWriteToNote } from '../../../utils/utils'
import CancelVisitModal from '../../calendar/CancelVisitModal'
import MPatientSubheader from '../MPatientSubheader'
import { SchedulingFlowVisitTypes, usePatient } from '../PPatientContext'
import AddendumsSection from './AddendumsSection'
import AttestationSection from './AttestationSection'
import { DischargeVisitDrawer, mustCompleteDischargeNote } from './DischargeVisitDrawer'
import { InductionVisitForm } from './InductionVisitForm'
import { InitialWellnessVisitForm } from './InitialWellnessVisitForm'
import MCloseConsultationNoteModal from './MCloseConsultationNoteModal'
import MCloseNoteModal from './MCloseNoteModal'
import { MentalHealthEvalActionAlert } from './MentalHealthEvalActionAlert'
import OAddAttestationModal from './OAddAttestationModal'
import OCheckInCallVisitForm from './OCheckInCallVisitForm'
import OLegacyVisitForm from './OLegacyVisitForm'
import OMaintenanceVisitForm from './OMaintenanceVisitForm'
import OScreeningFormSidebar, { ScreeningFormForm } from './OScreeningFormSidebar'
import OUdsVisitForm from './OUdsVisitForm'
import OVisitNotesSidebar from './OVisitNotesSidebar'
import PastEventsAlertSection from './PastEventsAlertSection'
import PeerCheckinForm from './PeerCheckinForm'
import RestoreVisitModal from './RestoreVisitModal'
import UnlockNoteModal from './UnlockNoteModal'
import VersionHistoryModal from './VersionHistoryModal'
import VisitAddendumModal from './VisitAddendumModal'
import { VisitNoteCaseReviewDrawer } from './VisitNoteCaseReviewDrawer'
import { WellnessVisitForm } from './WellnessVisitForm'

// This offset approximately represents the height of the patient header + visit note section headers
export const VISIT_NOTE_PAGE_OFFSET = 260

export const CASE_REVIEW_QUERY_PARAM_KEY = 'caseReview'
export const PatientVisitNoteContent = () => {
  const { currentUser } = useAuth()

  const { setModal, patientQuery, patientID, problemsListQuery } = usePatient()
  const queryClient = useQueryClient()
  const { visitID = '' } = useParams<{ visitID: string }>()
  const [saving, setSaving] = useState(false)
  const [prescriptionBanner, setPrescriptionBanner] = useState('')
  const [isDischargeNotesDrawerOpened, setDischargeNotesDrawer] = useState(false)
  const [visit, setVisit] = useState<Appointment | null>(null)
  const [intakeForm, setIntakeForm] = useState<Form | null>(null)
  const [closeNoteModal, setCloseNoteModal] = useState(false)
  const [unlockNoteModal, setUnlockNoteModal] = useState(false)
  const [closedNoteDetails, setClosedNoteDetails] = useState<ClosedNoteDetails>()
  const [errorMessage, setErrorMessage] = useState('')
  const [addAddendum, setAddAddendum] = useState(false)
  const [versionHistory, setVersionHistory] = useState(false)
  const [cancelVisit, setCancelVisit] = useState(false)
  const [restoreVisit, setRestoreVisit] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [notes, setNotes] = useState<Record<string, any> | null>({})
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [addendums, setAddendums] = useState<Record<string, any>[]>([])
  const [newAddendumContent, setNewAddendumContent] = useState('')
  const [lastAppointmentDate, setLastAppointmentDate] = useState('')
  const [showAttestationModal, setShowAttestationModal] = useState(false)
  const [medicationPrescribed, setMedicationPrescribed] = useState(false)
  const [isTobaccoUseDiagnosisPresent, setTobaccoUseDiagnosis] = useState(false)
  const [displayBehavioralAssessmentBanner, setDisplayBehavioralAssessmentBanner] = useState(false)
  const [display15MinuteVisitAlert, toggle15MinuteVisitAlert] = useToggle()
  const { showBanner } = useBanner()
  const pageTrace = usePageTrace({ startOnMount: true })

  const [searchParams, setSearchParams] = useSearchParams()

  const isCaseReviewDrawerOpened = Boolean(searchParams.get(CASE_REVIEW_QUERY_PARAM_KEY) === 'true')

  const isNotLocked = !closedNoteDetails || Object.keys(closedNoteDetails).length === 0

  const [appointmentsQueryKey, appointmentsQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/appointments',
    {
      params: {
        patientId: patientID,
      },
    },
  )

  const appointments = useQuery(appointmentsQueryKey, appointmentsQueryFn, {
    enabled: !hasAppointmentType(
      visit,
      'Initial Visit',
      'Free Consultation Call',
      'Returning welcome call',
    ),
  })

  const addAddendumQuery = useMutation(visitNotesApi.updateVisitNoteAddendum)

  const intakeFormQuery = useMutation(patientsApi.getIntakeForm, {
    onSuccess: data => {
      setIntakeForm(data)
    },
  })

  const visitNoteQuery = useQuery(
    ['visitNotesApi.getVisitNote', patientID, visitID],
    () => visitNotesApi.getVisitNote({ patientID, visitID }),
    {
      cacheTime: 0,
      onSuccess: data => {
        if (!data) {
          setNotes({})
          return
        }

        const note =
          data.induction ||
          data.maintenance ||
          data.uds ||
          data.blank ||
          data.consultation ||
          data.peercheckin ||
          data.legacy ||
          data.bhcm ||
          data.initialBhcm
        setNotes(note)

        if (note?.locked_at) {
          const { locked_at, locked_by_name, locked_by_user_id, locked_by_calendar_id } = note
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const detailsToSet: ClosedNoteDetails & { attestation?: Record<string, any> } = {
            locked_at,
            locked_by_name,
            locked_by_user_id,
            locked_by_calendar_id,
          }
          if (note.attestation) {
            detailsToSet.attestation = note.attestation
          }
          setClosedNoteDetails(detailsToSet)
        }

        if (note.addendum) {
          if (note.type === 'legacy') {
            const addendumsWithIds = []
            for (const [key, value] of Object.entries(note.addendum)) {
              addendumsWithIds.unshift({
                id: key,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(value as Record<string, any>),
              })
            }
            setAddendums(addendumsWithIds)
          } else {
            setAddendums(note.addendum)
          }
        }
      },
    },
  )

  const lockVisitNoteQuery = useMutation(visitNotesApi.lockVisitNote, {
    onSuccess: () => {
      void visitNoteQuery.refetch()
    },
  })
  const [getAppointmentQueryKey, getAppointmentQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      params: {
        patientId: patientID,
        appointmentId: visitID,
      },
    },
  )
  const appointmentQuery = useQuery(getAppointmentQueryKey, getAppointmentQueryFn, {
    onSuccess: data => {
      setVisit(data)
      intakeFormQuery.mutate(patientID)
    },
  })

  const type = useMemo<VisitNoteType | undefined>(() => {
    const visitNote = visitNoteQuery.data
    const appointmentType = appointmentQuery.data?.type

    if (visitNote && 'legacy' in visitNote) {
      return 'legacy'
    } else if (appointmentType === 'Initial Visit') {
      return 'induction'
    } else if (appointmentType === 'Follow-Up Visit') {
      return 'maintenance'
    } else if (appointmentType === 'Check-In Call') {
      return 'blank'
    } else if (appointmentType?.startsWith('Peer')) {
      return 'peercheckin'
    } else if (appointmentType === 'Free Consultation Call') {
      return 'consultation'
    } else if (appointmentType === 'Returning welcome call') {
      return 'reenrollment'
    } else if (appointmentType === 'UDS Visit') {
      return 'uds'
    } else if (appointmentType === 'Wellness Visit') {
      return 'bhcm'
    } else if (appointmentType === 'Initial Wellness Visit') {
      return 'initialBhcm'
    } else if (appointmentType) {
      return 'legacy'
    }
  }, [appointmentQuery.data?.type, visitNoteQuery.data])

  const canWriteToNote = Boolean(
    visit &&
      type &&
      (!isNotAbleToWriteToNote(currentUser.calendarId, visit) || type === 'consultation'),
  )

  const editMode = canWriteToNote && isNotLocked

  const patient = patientQuery?.data

  const formsQuery = useQuery(['formsApi.getForms', patientID], () =>
    formsApi.getForms(patientID, { formType: FORMS_ENUM.screening }),
  )
  const forms = formsQuery.data || []

  const pastAppointments = (appointments.data || [])
    .sort((a, b) => {
      return dayjs(b.datetime).unix() - dayjs(a.datetime).unix()
    })
    .filter(appointment => {
      return (
        appointment.id !== parseInt(visitID) &&
        !appointment.canceled &&
        dayjs(appointment.datetime).isBefore(dayjs())
      )
    })

  const priorIntake = hasAppointmentType(visit, 'Initial Visit')
    ? pastAppointments.find(appointment => hasAppointmentType(appointment, 'Initial Visit'))
    : undefined

  const events = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/nonVisitEvents', {
      params: { patientId: patientID },
    }),
    {
      enabled: !hasAppointmentType(
        visit,
        'Initial Visit',
        'Free Consultation Call',
        'Returning welcome call',
      ),
    },
  )

  const pastEvents = (events.data || [])
    .sort((a, b) => {
      return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()
    })
    .filter(event => {
      return dayjs(event.createdAt).isAfter(dayjs(lastAppointmentDate))
    })

  const channel = useMemo(() => {
    if (window.BroadcastChannel) {
      return new BroadcastChannel(`ophelia_notes_${patientID}_${visitID}`)
    }
    return null
  }, [patientID, visitID])

  useEffect(() => {
    if (pastAppointments.length) {
      setLastAppointmentDate(pastAppointments[0]!.datetime)
    }
  }, [pastAppointments])

  useEffect(() => {
    return () => {
      if (channel) {
        channel.close()
      }
    }
  }, [channel])

  const signNote = async () => {
    if (!type) {
      setCloseNoteModal(false)
      setErrorMessage(
        `There seems to be an error with this note. Please send a message to
        the ${(
          <a href='slack://channel?team=TRN06CLEM&id=C01083KPYJX'>#clinical-product-support</a>
        )} channel in
        Slack.`,
      )
      return
    }
    if (!currentUser || (!currentUser.oid && !currentUser.name)) {
      setCloseNoteModal(false)
      setErrorMessage(
        `Unfortunately this note could not be signed at this time. Please send a message to the ${(
          <a href='slack://channel?team=TRN06CLEM&id=C01083KPYJX'>#clinical-product-support</a>
        )} channel in Slack.`,
      )
      return
    }

    const body = {
      type,
      clinicianCalendarId: currentUser.calendarId,
      clinicianName: currentUser.name,
      clinicianUserId: currentUser.oid,
    }
    await lockVisitNoteQuery.mutateAsync({ patientID, visitID, data: body })

    setClosedNoteDetails({
      locked_at: dayjs().format('MMMM Do, YYYY, h:mm a'),
      locked_by_name: currentUser.name,
      locked_by_calendar_id: currentUser.calendarId,
      locked_by_user_id: currentUser.oid,
    })
    if (
      hasAppointmentType(visit, 'Initial Visit', 'Free Consultation Call', 'Returning welcome call')
    ) {
      Fullstory.event('Consultation Call or Intake Visit Note Signed')
    }

    showBanner({ label: 'Note successfully signed.', type: 'success', dismissable: true })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copyNote = (notesToCopy: Record<string, any> | null) => {
    const copiedNotes = {
      ...notesToCopy,
      medication_name: undefined,
      medication_strength: undefined,
      medication_quantity: undefined,
      medication_days_supply: undefined,
      locked_at: undefined,
      locked_by_calender_id: undefined,
      locked_by_name: undefined,
      locked_by_user_id: undefined,
      phq9FormSessionId: notes?.phq9FormSessionId,
      gad7FormSessionId: notes?.gad7FormSessionId,
      barc10FormSessionId: notes?.barc10FormSessionId,
      substanceUse: undefined,
    }

    setNotes(copiedNotes)
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!patientID || !currentUser) {
      return
    }

    analytics.page({
      name: 'Visit Note',
      properties: {
        patientId: patientID,
        visitId: visitID,
        userId: currentUser.oid,
        userName: currentUser.name,
      },
    })
  }, [patientID, currentUser, visitID])

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  useEffect(() => {
    if (editMode) {
      return
    }
    if (!newAddendumContent) {
      return
    }
    if (!type) {
      return
    }

    const saveAddendum = () => {
      const timestamp = dayjs().format('MMMM Do, YYYY, h:mm a')

      const body = {
        content: newAddendumContent,
        clinicianCalendarId: currentUser.calendarId,
        clinicianName: currentUser.name,
        timestamp,
        clinicianUserId: currentUser.oid,
        type,
      }

      addAddendumQuery.mutate({ patientID, visitID, data: body })

      setAddendums(addendums => [
        {
          /* eslint-disable-next-line no-magic-numbers */
          id: sampleSize('abcdef0123456789', 6).join(''),
          content: newAddendumContent,
          locked_by_name: currentUser.name,
          locked_by_user_id: currentUser.oid,
          locked_at: timestamp,
          locked_by_calendar_id: currentUser.calendarId,
        },
        ...addendums,
      ])

      showBanner({ label: 'Addendum successfully added.', type: 'success', dismissable: true })
      setNewAddendumContent('')
      setAddAddendum(false)
    }

    saveAddendum()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID, currentUser, visitID, newAddendumContent, editMode, visit, type])

  const [patientRegistryQueryKey, patientRegistryQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    {
      params: {
        patientId: patientID,
      },
    },
  )

  const patientRegistryEntryQuery = useQuery(patientRegistryQueryKey, patientRegistryQueryFunction)

  const [mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/mental-health-evals',
    {
      params: {
        patientId: patientID,
      },
      query: { isEvaluated: 'yes' },
    },
  )

  const mentalHealthEvalQuery = useQuery(mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction)

  const problemsList = (problemsListQuery?.data || []) as ProblemListProblem[]
  const patientHasQualifyingDiagnosisForCocm = problemsList.some(problem => {
    if (!problem.code) {
      return false
    }
    return PSYCH_DIAGNOSIS_CODES.includes(problem.code as PsychDiagnosisCode)
  })

  const patientHasHadPreviousMentalHealthEval = mentalHealthEvalQuery?.data?.some(
    evals => evals.visitId !== Number(visitID),
  )

  const patientNeedsMentalHealthEval =
    !patientHasQualifyingDiagnosisForCocm && !patientHasHadPreviousMentalHealthEval

  const patientInCocm =
    !patientRegistryEntryQuery.isLoading && Boolean(patientRegistryEntryQuery?.data)

  const patientQualifiesForBehavioralAssessmentBanner = patientNeedsMentalHealthEval

  useEffect(() => {
    setDisplayBehavioralAssessmentBanner(patientQualifiesForBehavioralAssessmentBanner)
  }, [patientQualifiesForBehavioralAssessmentBanner])

  const shouldShowVisitNote = Boolean(
    visit && notes && patient && type && !visitNoteQuery.isLoading,
  )

  const handleTobaccoUseDiagnosis = (diagnoses: ProblemListProblem[]) => {
    const isTobaccoUseDiagnosisPresent = diagnoses.some(diagnosis =>
      SMOKING_CESSATION_DIAGNOSIS_CODES.some(code => diagnosis.code === code),
    )

    setTobaccoUseDiagnosis(isTobaccoUseDiagnosisPresent)
  }

  // Adds the case review query param to the URL
  const setShowCaseReviewNote = () => {
    setSearchParams(
      params => {
        if (!params.has(CASE_REVIEW_QUERY_PARAM_KEY)) {
          params.append(CASE_REVIEW_QUERY_PARAM_KEY, 'true')
        }
        return params
      },
      { replace: true },
    )
  }

  // Removes the case review query param from the URL
  const resetCaseReviewSearchParam = () => {
    setSearchParams(
      params => {
        if (params.has(CASE_REVIEW_QUERY_PARAM_KEY)) {
          params.delete(CASE_REVIEW_QUERY_PARAM_KEY)
        }
        return params
      },
      { replace: true },
    )
  }

  let visitNoteArea = <div />
  if (patient && visit && shouldShowVisitNote) {
    const coreProps = {
      logOut: () => ({}),
      visitID,
      patientID,
      notes,
      editMode,
      closedNoteDetails,
      visit,
      setCloseNoteModal,
      patient,
      currentUser,
      sidebar: false,
      handleTobaccoUseDiagnosis,
    }

    switch (type) {
      case 'maintenance':
        visitNoteArea = (
          <OMaintenanceVisitForm
            setShowCaseReviewNote={setShowCaseReviewNote}
            setUnlockNoteModal={setUnlockNoteModal}
            setShowAttestationModal={setShowAttestationModal}
            openDischargeNotesDrawer={() => setDischargeNotesDrawer(true)}
            appointment={appointmentQuery.data}
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
            setMedicationPrescribed={setMedicationPrescribed}
            patientNeedsMentalHealthEval={patientNeedsMentalHealthEval}
            refetchMentalHealthEvals={() =>
              void queryClient.invalidateQueries(mentalHealthEvalQueryKey)
            }
            addendums={addendums}
            endPageTrace={pageTrace.end}
            {...coreProps}
          />
        )
        break
      case 'uds':
        visitNoteArea = (
          <OUdsVisitForm
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
            {...coreProps}
          />
        )
        break
      case 'induction':
        visitNoteArea = (
          <InductionVisitForm
            setPrescriptionBanner={setPrescriptionBanner}
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
            setMedicationPrescribed={setMedicationPrescribed}
            setUnlockNoteModal={setUnlockNoteModal}
            patientNeedsMentalHealthEval={patientNeedsMentalHealthEval}
            refetchMentalHealthEvals={() =>
              void queryClient.invalidateQueries(mentalHealthEvalQueryKey)
            }
            addendums={addendums}
            {...coreProps}
          />
        )
        break
      case 'blank':
        visitNoteArea = (
          <OCheckInCallVisitForm
            {...coreProps}
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
          />
        )
        break
      case 'peercheckin':
        visitNoteArea = <PeerCheckinForm {...coreProps} />
        break
      case 'legacy':
        visitNoteArea = <OLegacyVisitForm {...coreProps} />
        break
      case 'bhcm':
        visitNoteArea = (
          <WellnessVisitForm
            setUnlockNoteModal={setUnlockNoteModal}
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
            {...coreProps}
          />
        )
        break
      case 'initialBhcm':
        visitNoteArea = (
          <InitialWellnessVisitForm
            setUnlockNoteModal={setUnlockNoteModal}
            meetingDetails={{
              patientTime: visit?.patientTime ?? 0,
              clinicianTime: visit?.clinicianTime ?? 0,
            }}
            {...coreProps}
          />
        )
        break
      case 'consultation':
      case 'reenrollment':
      case undefined:
    }
  }

  useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/appointments', {
      params: {
        patientId: patientID || '',
      },
      query: {
        start: dayjs().startOf('month').subtract(2, 'months').toISOString(),
        end: dayjs().endOf('day').toISOString(),
        types: ['Wellness Visit', 'Initial Wellness Visit', 'Initial Visit', 'Follow-Up Visit'],
        canceled: 'no',
      },
    }),
    {
      onSuccess: (videoVisitsData: Appointment[]) => {
        const FIFTEEN_MINUTES = 15
        const hasVideoVisitsOver15Minutes = videoVisitsData.some(
          appointment => (appointment?.patientTime ?? 0) >= FIFTEEN_MINUTES,
        )
        if (
          editMode &&
          !hasVideoVisitsOver15Minutes &&
          patientInCocm &&
          hasHomeState(patient, 'New York') &&
          hasAppointmentType(visit, 'Follow-Up Visit')
        ) {
          toggle15MinuteVisitAlert(true)
        }
      },
      enabled: Boolean(patientID),
    },
  )

  useEffect(() => {
    if (patient && notes && appointmentQuery.data) {
      if (
        mustCompleteDischargeNote({
          patient,
          visitNoteContent: notes,
          appointment: appointmentQuery.data,
        })
      ) {
        setDischargeNotesDrawer(true)
      }
    }
  }, [patient, notes, appointmentQuery.data])

  return (
    <>
      <VisitNoteCaseReviewDrawer
        visitId={visitID as `${number}`}
        opened={isCaseReviewDrawerOpened}
        onClose={() => resetCaseReviewSearchParam()}
      />
      {type && (
        <DischargeVisitDrawer
          visitId={visitID}
          visitNoteType={type}
          patientId={patientID}
          onClose={() => setDischargeNotesDrawer(false)}
          opened={isDischargeNotesDrawerOpened}
        />
      )}
      {visit &&
        !hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call') &&
        patient && (
          <MCloseNoteModal
            openIneligibleNoteModal={() => setModal({ type: 'add-ineligible-note' })}
            patient={patient}
            patientID={patientID}
            visitID={visitID}
            type={visit.type}
            open={closeNoteModal}
            currentUser={currentUser}
            closeModal={() => setCloseNoteModal(false)}
            signNote={signNote}
            medicationPrescribed={medicationPrescribed}
            isTobaccoUseDiagnosisPresent={isTobaccoUseDiagnosisPresent}
          />
        )}
      {unlockNoteModal && (
        <UnlockNoteModal
          patientId={patientID}
          appointmentId={visitID}
          closeModal={() => setUnlockNoteModal(false)}
        />
      )}
      {visit &&
        hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call') &&
        patient &&
        closeNoteModal && (
          <MCloseConsultationNoteModal
            patient={patient}
            patientID={patientID}
            visitID={visitID}
            open={closeNoteModal}
            currentUser={currentUser}
            closeModal={() => setCloseNoteModal(false)}
            signNote={signNote}
            setAlert={({ message, type }) =>
              showBanner({ label: message, type: type ?? 'success', dismissable: true })
            }
          />
        )}

      {errorMessage && (
        <MErrorModal message={errorMessage} closeModal={() => setErrorMessage('')} />
      )}

      {addAddendum && (
        <VisitAddendumModal
          closeModal={() => setAddAddendum(false)}
          setNewAddendumContent={setNewAddendumContent}
        />
      )}
      {versionHistory && (
        <TModal
          width='max-w-2xl'
          style={{ maxHeight: '70%' }}
          closeModal={() => setVersionHistory(false)}
        >
          <VersionHistoryModal patientID={patientID} visitID={visitID} />
        </TModal>
      )}
      {visitID && cancelVisit && (
        <CancelVisitModal
          appointmentTime={appointmentQuery?.data?.datetime}
          setAlert={({ message, type }) =>
            showBanner({ label: message, type: type ?? 'success', dismissable: true })
          }
          patientID={patientID}
          appointmentID={visitID}
          closeModal={() => setCancelVisit(false)}
        />
      )}
      {visitID && restoreVisit && (
        <RestoreVisitModal
          patientId={patientID}
          appointmentId={visitID}
          closeModal={() => setRestoreVisit(false)}
        />
      )}
      {showAttestationModal && (
        <OAddAttestationModal
          closeModal={() => setShowAttestationModal(false)}
          closedNoteDetails={closedNoteDetails}
          notes={notes}
          patientID={patientID}
          visitID={visitID}
          setClosedNoteDetails={setClosedNoteDetails}
        />
      )}
      <Stack spacing={0} sx={{ flexGrow: 1, height: '100%', display: 'flex' }}>
        {prescriptionBanner && (
          <Banner
            label={prescriptionBanner}
            type='success'
            onClick={() => setPrescriptionBanner('')}
          />
        )}
        {patient && (
          <MPatientSubheader
            setAddAddendum={setAddAddendum}
            setVersionHistory={setVersionHistory}
            patient={patient}
            setScheduleVisit={(visitType: SchedulingFlowVisitTypes) => {
              setModal({
                type: 'scheduling-flow',
                visitType,
              })
            }}
            setCancelVisit={setCancelVisit}
            setRestoreVisit={setRestoreVisit}
            patientID={patientID}
            appointmentLoading={appointmentQuery.isLoading}
            visit={visit}
            editMode={editMode}
          />
        )}
        <Flex>
          <ScrollArea
            styles={{ root: { flex: 2, height: `calc(100vh - ${VISIT_NOTE_PAGE_OFFSET}px)` } }}
            type='auto'
            offsetScrollbars
          >
            <Stack p='md' spacing='xs'>
              {displayBehavioralAssessmentBanner && editMode && patient && (
                <MentalHealthEvalActionAlert
                  patient={patient}
                  patientInCocm={patientInCocm}
                  onClose={() => setDisplayBehavioralAssessmentBanner(false)}
                />
              )}
              {!editMode && (
                <AAlertBanner
                  message={
                    isNotLocked
                      ? 'You are not permitted to write to this visit note.'
                      : 'Locked visit notes cannot be edited.'
                  }
                  type='error'
                />
              )}
              {display15MinuteVisitAlert && (
                <Alert
                  variant='secondary'
                  icon={<ShieldIcon />}
                  withCloseButton
                  onClose={() => toggle15MinuteVisitAlert(false)}
                >
                  In order to ensure we&apos;re providing high-quality care and able to bill
                  appropriately, please attempt to spend at least 15 minutes with{' '}
                  {patient?.personalData.firstName} during your visit today. Recent visits had
                  shorter durations of patient-facing time.
                </Alert>
              )}
              <PastEventsAlertSection pastEvents={pastEvents} />
              <AddendumsSection addendums={addendums} />

              {closedNoteDetails?.attestation && (
                <AttestationSection attestation={closedNoteDetails.attestation} />
              )}
              {shouldShowVisitNote ? visitNoteArea : <ANoteLoading />}
            </Stack>
          </ScrollArea>
          <ScrollArea
            styles={({ breakpoints }) => ({
              root: {
                flex: 1,
                height: `calc(100vh - ${VISIT_NOTE_PAGE_OFFSET}px)`,
                [`@media (max-width: ${breakpoints.md})`]: { display: 'none' },
              },
            })}
            type='auto'
          >
            {visit &&
              !hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call') && (
                <OVisitNotesSidebar
                  intakeForm={intakeForm && !isEmpty(intakeForm) ? intakeForm : null}
                  priorIntake={priorIntake}
                  visit={visit}
                  editMode={editMode}
                  pastAppointments={pastAppointments}
                  patientID={patientID}
                  isLoading={intakeFormQuery.isLoading}
                  copyNote={copyNote}
                />
              )}
            {visit &&
              hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call') &&
              forms.length > 0 && (
                <OScreeningFormSidebar form={forms[forms.length - 1] as ScreeningFormForm} />
              )}
          </ScrollArea>
        </Flex>
      </Stack>
    </>
  )
}
