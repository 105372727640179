import { Avatar, Center, MinusIcon, PlusIcon, useMantineTheme } from '@shared/components'

type DrugScreenRowIconProps = {
  resultIsPositive: boolean
  isBupe: boolean
  sideBarStyling?: boolean
}
export const DrugScreenRowIcon = ({
  resultIsPositive,
  isBupe,
  sideBarStyling = false,
}: DrugScreenRowIconProps) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const positiveResultBorderColor = isBupe ? colors.success[0] : colors.warning[0]
  const positiveResultBackgroundColor = isBupe ? colors.success[1] : colors.warning[1]

  const minusIconColor = () => {
    if (sideBarStyling) {
      return colors.warning[0]
    }
    return resultIsPositive ? positiveResultBorderColor : colors.background[3]
  }
  const backgroundColor = () => {
    if (sideBarStyling) {
      return resultIsPositive ? positiveResultBackgroundColor : colors.warning[1]
    }
    return resultIsPositive ? positiveResultBackgroundColor : colors.background[0]
  }

  const borderColor = () => {
    if (sideBarStyling) {
      return resultIsPositive ? positiveResultBorderColor : colors.warning[0]
    }
    return resultIsPositive ? positiveResultBorderColor : colors.background[3]
  }

  return (
    <Center>
      <Avatar
        size='xs'
        iconColor={colors.background[7]}
        backgroundColor={backgroundColor()}
        style={{
          borderColor: borderColor(),
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
        }}
      >
        {resultIsPositive ? (
          <PlusIcon
            style={{ height: sizes.padding.lg, width: sizes.padding.lg }}
            color={borderColor()}
          />
        ) : (
          <MinusIcon
            style={{ height: sizes.padding.lg, width: sizes.padding.lg }}
            color={minusIconColor()}
          />
        )}
      </Avatar>
    </Center>
  )
}
