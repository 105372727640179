import { VerifiablePayerPlan } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class InsuranceApi extends BaseApi {
  listVerifiablePayers = () => {
    return this.json<VerifiablePayerPlan[]>({
      method: 'GET',
      url: '/insurance/payers/verifiable',
    })
  }

  updateVerifiablePayer = (arg: { id: string; eligibleIds: string[] }) => {
    return this.json<void>({
      method: 'PUT',
      url: `/insurance/payers/verifiable/${arg.id}`,
      data: { eligibleIds: arg.eligibleIds },
    })
  }
}
