import { Divider, Stack, TitleFour, useMantineTheme } from '@shared/components'
import { PrescriptionFavorite } from '@shared/types'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import QuickApplyListItem from './QuickApplyListItem'

export type OpheliaTemplatesTabContentProps = {
  opheliaTemplatesList: PrescriptionFavorite[]
  templateOnClick: (template: PrescriptionFavorite) => void
}
const CATEGORY_ORDER = [
  'Narcan',
  'Comfort meds',
  'Induction templates',
  'Maintenance templates',
  'Smoking cessation',
]
const OpheliaTemplatesTabContent = ({
  opheliaTemplatesList,
  templateOnClick,
}: OpheliaTemplatesTabContentProps) => {
  const groups = groupBy(opheliaTemplatesList, d => d.category)
  const {
    other: { colors },
  } = useMantineTheme()
  return (
    <Stack
      spacing='sm'
      sx={() => ({
        overflowY: 'scroll',
        maxHeight: '320px',
        paddingLeft: '0px',
        background: colors.background[1],
      })}
    >
      {CATEGORY_ORDER.map((category, index) => {
        const orderedTemplateList = sortBy(groups[category], obj => {
          return obj.favoriteName.toLowerCase()
        })
        return (
          <Stack
            key={category}
            spacing='xs'
            sx={theme => ({
              paddingTop: theme.spacing.sm,
              paddingBottom: theme.spacing.sm,
              paddingLeft: theme.spacing.md,
              paddingRight: theme.spacing.md,
            })}
          >
            {index !== 0 && <Divider />}
            <TitleFour>
              {category === 'Smoking cessation' ? 'Nicotine cessation' : category}
            </TitleFour>

            {orderedTemplateList.map(fav => (
              <>
                <Divider />
                <QuickApplyListItem
                  key={fav.oid}
                  favoriteName={fav.favoriteName}
                  medicationName={fav.medicationName}
                  quantity={fav.quantity}
                  daysSupply={fav.daysSupply}
                  doseForm={fav.doseForm}
                  strength={fav.strength}
                  onClick={() => templateOnClick(fav)}
                />
              </>
            ))}
          </Stack>
        )
      })}
    </Stack>
  )
}
export default OpheliaTemplatesTabContent
