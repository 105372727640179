import React from 'react'

export type ALabelType = {
  children: JSX.Element | string
  className?: string
  colon?: boolean
  required?: boolean
  optional?: boolean
}

const ALabel = ({ children, className, colon, optional, required }: ALabelType) => (
  <label className={`leading-5 ${className ?? ''}`}>
    {children}
    {colon === false ? '' : ':'}{' '}
    {required ? (
      <span className='text-red' title='Required'>
        *
      </span>
    ) : (
      ''
    )}
    {optional && <span className='text-gray-500'>(optional)</span>}
  </label>
)

export default ALabel
