import {
  Banner,
  Divider,
  Drawer,
  Grid,
  Group,
  MinusCircleIcon,
  PlusCircleIcon,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import {
  AsqPromptStrings,
  AsqScoreMapping,
  Barc10PromptStrings,
  Barc10ScoreMapping,
  DecoratedObjectiveMeasureResponse,
  Gad7PromptStrings,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
  Phq8PromptStrings,
  Phq9AndGad7ScoreMapping,
  Phq9PromptStrings,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import DrawerChart from './DrawerChart'
import DataComparison from './DrawerComparisonData'
import TotalScore from './TotalScore'
import { generateDrawerComparisonData } from './chartUtils'

export const drawerChartContext = {
  'PHQ-8': {
    scoreMapping: Phq9AndGad7ScoreMapping,
    promptStrings: Phq8PromptStrings,
  },
  'PHQ-9': {
    scoreMapping: Phq9AndGad7ScoreMapping,
    promptStrings: Phq9PromptStrings,
  },
  'GAD-7': {
    scoreMapping: Phq9AndGad7ScoreMapping,
    promptStrings: Gad7PromptStrings,
  },
  'BARC-10': {
    scoreMapping: Barc10ScoreMapping,
    promptStrings: Barc10PromptStrings,
  },
  ASQ: {
    scoreMapping: AsqScoreMapping,
    promptStrings: AsqPromptStrings,
  },
}

export type ChartResponse = {
  completedAt: string
  score: number
  key: string
}

type ObjectiveMeasuresChartDrawerProps = {
  data: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  drawerContentIndex: number | null
  onClose: () => void
  title: ObjectiveMeasuresTitle
  patientName: string
}

const ObjectiveMeasuresChartDrawer = ({
  data,
  drawerContentIndex,
  onClose,
  title,
  patientName,
}: ObjectiveMeasuresChartDrawerProps) => {
  const [selectedPrompt, setSelectedPrompt] = useState<number | null>(null)
  const [chartData, setChartData] = useState<ChartResponse[] | null>(null)

  if (drawerContentIndex === null) {
    return null
  }

  const {
    selectedData,
    baselineData,
    displayBaselineData,
    secondaryComparisonData,
    displaySecondaryComparisonData,
    promptStrings,
  } = generateDrawerComparisonData({ data, drawerContentIndex, title })

  const handleClick = (index: number | null) => {
    setSelectedPrompt(index)
    if (index !== null) {
      const chartData = data.map(response => {
        const responseValues = response.data[index]!
        return {
          ...responseValues,
          completedAt: response.completedAt || '',
        }
      })
      setChartData(chartData)
    }
  }

  const { scoreMapping } = drawerChartContext[title]

  const subheaderText = (title: ObjectiveMeasuresTitle): string => {
    switch (title) {
      case 'PHQ-9':
      case 'GAD-7':
      case 'PHQ-8':
        return 'Over the last 2 weeks, how often have you been bothered by any of the following problems?'
      case 'BARC-10':
        return 'On a scale of 1 to 6, please indicate your level of agreement with the following statement:'
      case 'ASQ':
        return 'In the past few weeks...'
    }
  }

  return (
    <Drawer
      title={`${title} for ${patientName} from ${dayjs(selectedData?.completedAt).format(
        'MMM DD, YYYY',
      )}`}
      opened
      onClose={onClose}
      position='right'
      size='lg'
      footer={<TotalScore title={title} score={selectedData.compositeScore} />}
    >
      <Stack>
        <Stack px='md' pt='md'>
          {title === 'ASQ' && selectedData.compositeScore > 0 && (
            <Banner type='warning' label='Suicidal ideation risk' />
          )}
          <Text color={colors => colors.text[1]}>{subheaderText(title)}</Text>
          <Grid justify='flex-end'>
            {displayBaselineData && (
              <Grid.Col span={2}>
                <Text color={colors => colors.text[1]}>Baseline</Text>
              </Grid.Col>
            )}
            {displaySecondaryComparisonData && secondaryComparisonData && (
              <Grid.Col span={2}>
                <Text color={colors => colors.text[1]}>
                  {dayjs(secondaryComparisonData?.completedAt).format("MMM 'YY")}
                </Text>
              </Grid.Col>
            )}
          </Grid>
        </Stack>
        {selectedData?.data.map((i, index) => {
          const isSelected = selectedPrompt === index
          return (
            <Stack key={i.key} px='md' py='xs'>
              <Divider />
              <Text>{promptStrings[index]}</Text>
              <Grid>
                <Grid.Col span='auto'>
                  <Group spacing='sm'>
                    {isSelected ? (
                      <TertiaryButton
                        size='sm'
                        onClick={() => handleClick(null)}
                        leftIcon={<MinusCircleIcon styled />}
                      />
                    ) : (
                      <TertiaryButton
                        size='sm'
                        onClick={() => handleClick(index)}
                        leftIcon={<PlusCircleIcon styled />}
                      />
                    )}
                    <Text bold>{scoreMapping[i.score]}</Text>
                  </Group>
                </Grid.Col>
                {displayBaselineData && baselineData && (
                  <Grid.Col span={2}>
                    <DataComparison
                      selectedData={selectedData}
                      comparisonData={baselineData}
                      index={index}
                      title={title}
                    />
                  </Grid.Col>
                )}
                {displaySecondaryComparisonData && secondaryComparisonData && (
                  <Grid.Col span={2}>
                    <DataComparison
                      selectedData={selectedData}
                      comparisonData={secondaryComparisonData}
                      index={index}
                      title={title}
                    />
                  </Grid.Col>
                )}
              </Grid>
              {isSelected && chartData && <DrawerChart data={chartData} title={title} />}
            </Stack>
          )
        })}
      </Stack>
    </Drawer>
  )
}

export default ObjectiveMeasuresChartDrawer
