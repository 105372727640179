import { useToggle } from '@mantine/hooks'
import {
  AccordionTimelineCard,
  ClockIcon,
  Text,
  TimelineCardTitle,
  UserIcon,
} from '@shared/components'
import { DischargeNoteModel } from '@shared/types'
import { dayjs } from '@shared/utils'
import { usePatientPAMedicaidCoe } from '../../../../../utils/hooks/use-patient-medicaid-coe'
import { JourneyItemContent, usePatient } from '../../../PPatientContext'
import { FooterContentSection } from '../SharedContent'
import { AdditionalNotesSection } from './AdditionalNotesSection'
import { BuprenorphineMedicationPlanSection } from './BuprenorphineMedicationPlanSection'
import { DischargeVisitSection } from './DischargeVisitSection'
import { EligibleToReturnSection } from './EligibleToReturnSection'
import { OutstandingBalanceSection } from './OutstandingBalanceSection'
import { ReasonsForDischargeSection } from './ReasonsForDischargeSection'
import { ReferralSection } from './ReferralSection'
import { SelfDeterminesToStopReasonSection } from './SelfDeterminesToStopReasonSection'
import { TransferToHLOCSection } from './TransferToHLOCSection'

export const FullDischargeNote = ({ dischargeNote }: { dischargeNote: DischargeNoteModel }) => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data
  const { isPAMedicaidCoe } = usePatientPAMedicaidCoe(patient)

  return (
    <>
      <ReasonsForDischargeSection dischargeNote={dischargeNote} />
      <SelfDeterminesToStopReasonSection dischargeNote={dischargeNote} />
      <ReferralSection dischargeNote={dischargeNote} />
      <EligibleToReturnSection dischargeNote={dischargeNote} />
      <TransferToHLOCSection dischargeNote={dischargeNote} isPAMedicaidCoe={isPAMedicaidCoe} />
      <BuprenorphineMedicationPlanSection dischargeNote={dischargeNote} />
      <DischargeVisitSection dischargeNote={dischargeNote} />
      <AdditionalNotesSection dischargeNote={dischargeNote} />
      <OutstandingBalanceSection />
    </>
  )
}

export const DischargeDate = ({ dischargeNote }: { dischargeNote: DischargeNoteModel }) => {
  return (
    <>
      <Text bold>Discharge date</Text>
      <Text>
        {dischargeNote.timestamp ? dayjs(dischargeNote.timestamp).format('MM/DD/YYYY') : 'N/A'}
      </Text>
    </>
  )
}

export const DischargeNoteContent = ({
  dischargeNote,
}: {
  dischargeNote: JourneyItemContent<'discharge-note'>
}) => {
  const [_, toggleExpand] = useToggle()
  const datetime = dayjs(dischargeNote.timestamp)

  return (
    <AccordionTimelineCard
      onExpand={value => toggleExpand(value)}
      leftSide={<TimelineCardTitle title='Discharge note' />}
    >
      <FullDischargeNote dischargeNote={dischargeNote} />
      <FooterContentSection right={null}>
        <FooterContentSection.Item>
          <UserIcon />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs'>
            {dischargeNote.employeeName}
          </Text>
        </FooterContentSection.Item>
        <FooterContentSection.Item>
          <ClockIcon />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs'>
            {datetime.format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
      </FooterContentSection>
    </AccordionTimelineCard>
  )
}
