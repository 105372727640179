import { useToggle } from '@mantine/hooks'
import {
  Box,
  MoreHorizontalIcon,
  SolidCircleIcon,
  TertiaryButton,
  Text,
  useMantineTheme,
} from '@shared/components'
import { Day, OcpClinician, OcpOverride, OcpRecurring, StateAbbr } from '@shared/types'
import { isSameDay } from 'date-fns'
import { useState } from 'react'
import { QueryObserverSuccessResult } from 'react-query'
import { WeekDateTuple } from './OnCallScheduleContent'
import OverrideDrawer from './OverrideDrawer'
import { daysOfTheWeek } from './ScheduleTableHeader'

type ScheduleTableRowProps = {
  stateData: OcpRecurring
  overrideData: QueryObserverSuccessResult<OcpOverride[], unknown>
  dates: WeekDateTuple
  onCallProviders: OcpClinician[]
  editState: () => void
  userCanEdit: boolean
}

const ScheduleTableRow = ({
  stateData,
  overrideData,
  dates,
  onCallProviders,
  editState,
  userCanEdit,
}: ScheduleTableRowProps) => {
  const [createOverride, toggleCreateOverride] = useToggle()
  const [overrideInfoOnDate, setOverrideInfoOnDate] = useState<{
    date: Date
    dayOfWeek: Day
    state: StateAbbr
    scheduledProviderName: string
    scheduledProviderId: string
  }>()
  const [existingOverrideOnDate, setExistingOverrideOnDate] = useState<OcpOverride | undefined>()

  const fetchOverrideDataOnDate = ({ date, state }: { date: Date; state: string }) => {
    return overrideData.data.find(
      override =>
        isSameDay(
          new Date(override.overrideDate),
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
        ) &&
        override.state === state &&
        !override.deleted,
    )
  }
  const fetchOnCallEmployeeId = ({
    date,
    state,
    scheduledEmployeeId,
  }: {
    date: Date
    state: string
    scheduledEmployeeId: string
  }) => {
    const overrideDataOnDate = fetchOverrideDataOnDate({ date, state })
    const isOverrideOnDate = Boolean(overrideDataOnDate)
    return isOverrideOnDate ? overrideDataOnDate?.employeeId : scheduledEmployeeId
  }

  const fetchProviderOnDate = ({
    date,
    state,
    scheduledEmployeeId,
  }: {
    date: Date
    state: string
    scheduledEmployeeId: string
  }) => {
    const employeeId = fetchOnCallEmployeeId({ date, state, scheduledEmployeeId }) || ''
    const employeeName = onCallProviders.find(provider => provider.oid === employeeId)?.name || ''
    return { providerNameOnDate: employeeName, providerIdOnDate: employeeId }
  }

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <>
      {overrideInfoOnDate && (
        <OverrideDrawer
          overrideInfoOnDate={overrideInfoOnDate}
          onCallProviders={onCallProviders || []}
          isOpen={createOverride}
          onClose={() => toggleCreateOverride(false)}
          existingOverride={existingOverrideOnDate}
        />
      )}
      <tr>
        <td>
          <Text bold>{stateData.state}</Text>
        </td>
        {Object.values(daysOfTheWeek).map(({ day }: { day: Day; abbreviation: string }, index) => {
          const { providerNameOnDate, providerIdOnDate } = fetchProviderOnDate({
            date: dates[index] as Date,
            state: stateData.state,
            scheduledEmployeeId: stateData.employeeIdByDay[day],
          })
          const overrideDataOnDate = fetchOverrideDataOnDate({
            date: dates[index] as Date,
            state: stateData.state,
          })
          const noProviderSelectedText = userCanEdit ? 'Add provider...' : 'No provider'

          const employeeNameText =
            overrideDataOnDate && stateData.employeeIdByDay[day] !== providerIdOnDate
              ? providerNameOnDate
              : providerNameOnDate || noProviderSelectedText

          const leftSide =
            overrideDataOnDate && stateData.employeeIdByDay[day] !== providerIdOnDate ? (
              <SolidCircleIcon
                color={colors.error[1]}
                style={{
                  width: `calc(${sizes.border.md} * 5)`,
                  height: `calc(${sizes.border.md} * 5)`,
                }}
              />
            ) : undefined

          return (
            <td key={day}>
              {userCanEdit ? (
                <TertiaryButton
                  m={8}
                  onClick={() => {
                    setOverrideInfoOnDate({
                      date: new Date(dates[index] as Date),
                      dayOfWeek: day,
                      state: stateData.state,
                      scheduledProviderName: providerNameOnDate,
                      scheduledProviderId: providerIdOnDate,
                    })
                    setExistingOverrideOnDate(overrideDataOnDate)
                    toggleCreateOverride()
                  }}
                  leftIcon={leftSide}
                >
                  {employeeNameText}
                </TertiaryButton>
              ) : (
                <Box p='sm'>{employeeNameText}</Box>
              )}
            </td>
          )
        })}
        <td>
          {userCanEdit && (
            <TertiaryButton
              onClick={() => editState()}
              leftIcon={<MoreHorizontalIcon color={colors.actions[1]} />}
            />
          )}
        </td>
      </tr>
    </>
  )
}

export default ScheduleTableRow
