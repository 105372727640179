import { useCallback } from 'react'
import { useInvalidateQuery } from '../../../utils/hooks'

export const useInvalidateCollections = () => {
  const invalidate = useInvalidateQuery()

  return useCallback(
    ({
      patientId,
      collections,
      documents,
    }: {
      patientId: string
      collections?: string[]
      documents?: { collection: string; documentId: string }[]
    }) => {
      void invalidate('GET /patient/:patientId/collections', {
        params: { patientId },
      })

      if (collections) {
        for (const collection of collections) {
          void invalidate('GET /patient/:patientId/collections/:collection', {
            params: { patientId, collection },
          })
        }
      }

      if (documents) {
        for (const { collection, documentId } of documents) {
          void invalidate('GET /patient/:patientId/collections/:collection/:documentId', {
            params: { patientId, collection, documentId },
          })
        }
      }

      void invalidate('GET /patient/:patientId/operations', {
        params: { patientId },
      })
    },
    [invalidate],
  )
}
