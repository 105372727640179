import { Accordion, Stack, TitleFour } from '@shared/components'
import { DosespotSection } from './DosespotSection'
import { RSEventsSection } from './RSEventsSection'
import { TwilioSection } from './TwilioSection'
import { ZoomEventsSection } from './ZoomEventsSection'

export const DebugContent = () => {
  return (
    <Stack m='md'>
      <Accordion multiple variant='separated'>
        <Accordion.Item value='dosespot'>
          <Accordion.Control>
            <TitleFour>DoseSpot</TitleFour>
          </Accordion.Control>
          <Accordion.Panel mt='sm'>
            <DosespotSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='events'>
          <Accordion.Control>
            <TitleFour>RS Events</TitleFour>
          </Accordion.Control>
          <Accordion.Panel mt='sm'>
            <RSEventsSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='twilio'>
          <Accordion.Control>
            <TitleFour>Twilio</TitleFour>
          </Accordion.Control>
          <Accordion.Panel mt='sm'>
            <TwilioSection />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='zoom'>
          <Accordion.Control>
            <TitleFour>Zoom</TitleFour>
          </Accordion.Control>
          <Accordion.Panel mt='sm'>
            <ZoomEventsSection />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  )
}
