import { Checkbox, CheckboxGroup, TextInput } from '@shared/components'
import { dischargeNoteMapping } from '@shared/types'

import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

export const BuprenorphineMedicationPlanSection = ({
  form,
  optional,
}: {
  form: DischargeNoteForm
  optional: boolean
}) => {
  return (
    <CheckboxGroup
      label={formLabel('Buprenorphine medication plan', optional)}
      spacing='sm'
      {...form.getInputProps('medication_plans')}
    >
      <Checkbox
        label={dischargeNoteMapping.thirty_day_prescription_current_maintenance_dose}
        value='thirty_day_prescription_current_maintenance_dose'
      />
      <Checkbox
        label={dischargeNoteMapping.thirty_day_prescription_taper}
        value='thirty_day_prescription_taper'
      />
      <Checkbox label={dischargeNoteMapping.naloxone_prescribed} value='naloxone_prescribed' />
      <Checkbox label={dischargeNoteMapping.medication_plan_other} value='medication_plan_other' />
      {form.values.medication_plans?.includes('medication_plan_other') && (
        <TextInput
          placeholder='Additional details...'
          {...form.getInputProps('medication_plan_explanation')}
        />
      )}
    </CheckboxGroup>
  )
}
