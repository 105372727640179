import {
  Card,
  CompassIcon,
  EditIcon,
  Group,
  Pill,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { DoseSpotPharmacy, Patient } from '@shared/types'
import { phone, template } from '@shared/utils'
import { useQuery } from 'react-query'
import { pharmaciesApi } from '../../../api'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

type PharmacyContentProps =
  | {
      pharmacy: Patient['pharmacy'] | undefined
      type: 'patient'
    }
  | {
      pharmacy: DoseSpotPharmacy | undefined
      type: 'dosespot'
    }

const toTitleCase = (str: string) => {
  if (str === null || str === '') {
    return ''
  }
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

const PharmacyPill = ({ type }: Pick<PharmacyContentProps, 'type'>) => {
  if (type === 'dosespot') {
    return (
      <Pill variant='filled' status='success'>
        DoseSpot verified
      </Pill>
    )
  }
  return (
    <Pill variant='filled' status='warning'>
      Patient provided
    </Pill>
  )
}

export const PharmacyContent = ({ active }: { active: boolean }) => {
  const { patientQuery, setModal, patientId } = usePatient()
  const patient = patientQuery?.data

  const doseSpotPharmacyQuery = useQuery(
    ['pharmaciesApi.retrieve', patientId],
    () => pharmaciesApi.retrieve(patientId),
    {
      enabled: active,
    },
  )

  const patientPharmacy = patient?.pharmacy
  const doseSpotPharmacy = doseSpotPharmacyQuery?.data
  const type = doseSpotPharmacy ? 'dosespot' : 'patient'

  if (
    doseSpotPharmacyQuery.isLoading ||
    doseSpotPharmacyQuery.isError ||
    Boolean(patientQuery?.isLoading) ||
    Boolean(patientQuery?.isError)
  ) {
    return <Skeleton h='5rem' />
  }

  const storeName = type === 'dosespot' ? doseSpotPharmacy?.StoreName : patientPharmacy?.name
  const address = type === 'dosespot' ? doseSpotPharmacy?.Address1 : patientPharmacy?.address
  const cityStateZip = template('{city}, {state} {zip}', {
    city: type === `dosespot` ? toTitleCase(doseSpotPharmacy?.City ?? '') : patientPharmacy?.city,
    state:
      type === 'dosespot' ? toTitleCase(doseSpotPharmacy?.State ?? '') : patientPharmacy?.state,
    zip: type === 'dosespot' ? toTitleCase(doseSpotPharmacy?.ZipCode ?? '') : patientPharmacy?.zip,
  })

  const pharmacyPhone = phone(
    type === 'dosespot' ? doseSpotPharmacy?.PrimaryPhone : patientPharmacy?.phone,
  ).formatted

  if (!doseSpotPharmacy && !patientPharmacy) {
    return (
      <Stack spacing='sm'>
        <SidebarEmptyState
          pillMessage='No pharmacy'
          textMessage={`As soon as ${
            patient?.personalData.preferredName || patient?.personalData.firstName
          } adds their preferred pharmacy in the patient portal, it'll appear here`}
        />
        <TertiaryButton
          onClick={() => setModal({ type: 'set-pharmacy' })}
          leftIcon={<EditIcon color={colors => colors.actions[0]} />}
        >
          Edit
        </TertiaryButton>
      </Stack>
    )
  }

  return (
    <Stack spacing='xs'>
      <Card>
        <Stack spacing='sm'>
          <Group spacing='sm' position='apart'>
            <Text size='xs' bold>
              {storeName}
            </Text>
            <PharmacyPill type={type} />
          </Group>
          <Stack spacing={0}>
            <Text size='xs'>{address}</Text>
            <Text size='xs'>{cityStateZip}</Text>
            <Text size='xs'>{pharmacyPhone}</Text>
          </Stack>
          <Group position='right'>
            {type === 'patient' ? (
              <TertiaryButton
                onClick={() => setModal({ type: 'set-pharmacy' })}
                leftIcon={<CompassIcon color={colors => colors.actions[0]} />}
              >
                Verify in DoseSpot
              </TertiaryButton>
            ) : (
              <TertiaryButton
                onClick={() => setModal({ type: 'set-pharmacy' })}
                leftIcon={<EditIcon color={colors => colors.actions[0]} />}
              >
                Edit
              </TertiaryButton>
            )}
          </Group>
        </Stack>
      </Card>
    </Stack>
  )
}
