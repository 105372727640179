import {
  ChevronDownIcon,
  CreditCardIcon,
  Loader,
  MailIcon,
  Menu,
  MessageCircleIcon,
  MonitorIcon,
  SearchIcon,
  SecondaryButton,
  SendIcon,
} from '@shared/components'
import { hasGroupRole } from '@shared/types'
import { phone } from '@shared/utils'
import React from 'react'
import { analytics } from '../../analytics'
import { Config } from '../../config'
import { useAuth } from '../../context/auth'
import { useEmrMutation } from '../../utils/hooks'
import { usePatient } from './PPatientContext'
import { getStripeDashboardUrl } from './invoices/InvoiceRow'

const LinksDropdown = () => {
  const { currentUser } = useAuth()

  const getFullStoryUrl = useEmrMutation('GET /fullstory-url/:uid')
  const fullStoryAppUrl = getFullStoryUrl.data?.appUrl

  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const openDoseSpot: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    analytics.track('DoseSpot opened via patient header')

    window.open('https://my.dosespot.com/', '_blank')
  }

  const openPDMP: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    if (!patient?.homeData?.state && !patient?.shippingData?.state) {
      return
    }

    const state = (patient.homeData?.state || patient.shippingData?.state) ?? ''

    let url
    if (state === 'New York') {
      url = 'https://commerce.health.state.ny.us'
    } else if (state === 'Pennsylvania') {
      url = 'https://pennsylvania.pmpaware.net'
    } else if (state === 'Connecticut') {
      url = 'https://connecticut.pmpaware.net/login'
    } else if (state === 'Vermont') {
      url = 'https://vermont.pmpaware.net/login'
    }

    analytics.track('PDMP opened via patient header', {
      state,
    })

    if (!url) {
      return
    }

    window.open(url, '_blank')
  }

  const clickCustomerIo: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    analytics.track('Customer.io opened via patient header')

    window.open(
      `https://fly.customer.io/env/92129/people?email=${patient?.personalData.email}`,
      '_blank',
    )
  }

  const clickFlex: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    analytics.track('Flex opened via patient header')

    window.open(
      `${Config.TWILIO_FLEX_URL}/conversations?search=${
        phone(patient?.personalData.phone).normalized
      }`,
      '_blank',
    )
  }

  const stripeCustomerId = patient?.stripeCustomerId
  const hasStripeId = Boolean(stripeCustomerId)
  const shouldShowStripe = hasStripeId && !hasGroupRole(currentUser, 'clinician')

  const clickStripe: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    analytics.track('Stripe opened via patient header')
    window.open(
      getStripeDashboardUrl({ pathname: `/customers/${stripeCustomerId}` }).href,
      '_blank',
    )
  }

  const canViewFullStory = hasGroupRole(currentUser, 'admin', 'enrollmentCoordinator')

  return (
    <Menu
      shadow='md'
      width={150}
      onOpen={() => {
        if (!canViewFullStory) {
          return
        }

        // Fetch the FullStory URL when the menu is opened to avoid unncessary FullStory API requests
        getFullStoryUrl.mutate({
          params: {
            uid: patientId,
          },
        })
      }}
    >
      <Menu.Target>
        <SecondaryButton rightIcon={<ChevronDownIcon />}>Links</SecondaryButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item icon={<SearchIcon color={colors => colors.actions[0]} />} onClick={openPDMP}>
          PDMP
        </Menu.Item>
        <Menu.Item icon={<SendIcon color={colors => colors.actions[0]} />} onClick={openDoseSpot}>
          DoseSpot
        </Menu.Item>
        {shouldShowStripe && (
          <Menu.Item
            icon={<CreditCardIcon color={colors => colors.actions[0]} />}
            onClick={clickStripe}
          >
            Stripe
          </Menu.Item>
        )}
        {patient?.personalData.email && (
          <Menu.Item
            icon={<MailIcon color={colors => colors.actions[0]} />}
            onClick={clickCustomerIo}
          >
            Customer.io
          </Menu.Item>
        )}
        <Menu.Item
          icon={<MessageCircleIcon color={colors => colors.actions[0]} />}
          onClick={clickFlex}
        >
          Flex
        </Menu.Item>
        {canViewFullStory && (
          <Menu.Item
            icon={
              getFullStoryUrl.isLoading ? (
                <Loader size='xs' />
              ) : (
                <MonitorIcon color={colors => colors.actions[0]} />
              )
            }
            disabled={!fullStoryAppUrl}
            onClick={() => {
              window.open(fullStoryAppUrl || '', '_blank')
            }}
          >
            FullStory
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}

export default LinksDropdown
