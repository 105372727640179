import { EligibleCCM, Employee, EmployeeModel, OcpClinician } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class EmployeesApi extends BaseApi {
  ocpClinicians = () => {
    return this.json<OcpClinician[]>({ method: 'GET', url: '/employees/ocp-clinicians' })
  }

  getByDoseSpotId = <T extends Employee = Employee>(doseSpotId: string) => {
    return this.json<T>({ method: 'GET', url: `/employee/doseSpotId/${doseSpotId}` })
  }

  update = (data: EmployeeModel & { employeeId: string }) => {
    return this.json<Res>({ method: 'PUT', url: `/employee/${data.employeeId}/update`, data })
  }

  updateTemporaryAccess = ({
    employeeId,
    data,
  }: {
    employeeId: string
    data: { patientId: string }
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/employee/${employeeId}/temporaryAccess`,
      data,
    })
  }

  getDoseSpotUrl = () => {
    return this.json<{ url: string }>({
      method: 'GET',
      url: '/employee/doseSpotSsoUrl',
    })
  }

  getDSNotificationCount = () => {
    return this.json<{ count: number }>({
      method: 'GET',
      url: '/employee/doseSpotNotifications',
    })
  }

  getMatchingCCMs = (patientId: string) => {
    return this.json<EligibleCCM[]>({
      method: 'GET',
      url: `/patients/${patientId}/ccmMatching`,
    })
  }
}
