import { Loader, Stack } from '@shared/components'
import { Page } from '../../../components/templates/TDefault'
import { useLunaQuery } from '../../../utils/hooks'
import BillingHeaderContent from '../BillingHeaderContent'
import { PayerConfigsTable } from './PayerConfigsTable'

export const PayerConfigs = () => {
  const payerConfigsQuery = useLunaQuery('GET /payer-configs')
  const payerConfigs = payerConfigsQuery.data?.data

  return (
    <Page
      title='Billing'
      headerContent={<BillingHeaderContent selectedPage='Bundle configuration' />}
      pushHeaderContent={false}
    >
      {payerConfigsQuery.isLoading && (
        <Stack justify='center' align='center' h='25vh'>
          <Loader />
        </Stack>
      )}
      <Stack p='lg'>{payerConfigs && <PayerConfigsTable payerConfigs={payerConfigs} />}</Stack>
    </Page>
  )
}
