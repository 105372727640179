import { CheckIcon, PlusIcon, PrimaryButton } from '@shared/components'
import { SegmentEventName } from '@shared/types'
import { useState } from 'react'
import { analytics } from '../../../../../analytics'
import { usePatient } from '../../../PPatientContext'
import { usePatientVisitNote } from '../PatientVisitNoteContext'

type PatientVisitNoteRSButtonProps = {
  label: string
  event: SegmentEventName
  additionalData?: Record<string, string | number | boolean | undefined>
}

export const PatientVisitNoteRSButton = ({
  label,
  event,
  additionalData,
}: PatientVisitNoteRSButtonProps) => {
  const { canEdit } = usePatientVisitNote()
  const [sent, setSent] = useState(false)

  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  if (!canEdit || !patient) {
    return null
  }

  const sendEvent = () => {
    analytics.track(event, {
      patientId: patient.oid,
      phoneNumber: patient.personalData.phone,
      ...additionalData,
    })
    setSent(true)
  }

  return (
    <PrimaryButton
      size='sm'
      leftIcon={sent ? <CheckIcon /> : <PlusIcon />}
      disabled={sent}
      onClick={sendEvent}
    >
      {sent ? 'Sent' : label}
    </PrimaryButton>
  )
}
