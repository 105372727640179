import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import ODSErrorIcon from './ODSErrorIcon'

export type ODSTextAreaProps = {
  className?: string
  disabled?: boolean
  error?: string
  placeholder?: string
  minRows?: number
  maxRows?: number
  label?: string
}

const ODSTextArea = React.forwardRef<HTMLTextAreaElement, ODSTextAreaProps>(
  ({ minRows, maxRows, label, className, disabled, placeholder, error, ...props }, ref) => (
    <div className={`${className || ''} col-span-6`}>
      {label && (
        <label className='block text-sm font-medium leading-5 text-gray-700'>{label}</label>
      )}
      <div className='mt-1'>
        <TextareaAutosize
          placeholder={placeholder}
          spellCheck
          disabled={disabled}
          ref={ref}
          minRows={minRows}
          maxRows={maxRows}
          className={`shadow-sm block w-full border border-daybreak-actions0 focus:ring-daybreak-actions1 focus:border-daybreak-actions1 focus:ring-2 focus:ring-offset-2 sm:text-sm rounded-md ${
            error && 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
          }`}
          {...props}
        />
        {error && (
          <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
            <ODSErrorIcon />
            {error}
          </p>
        )}
      </div>
    </div>
  ),
)

ODSTextArea.displayName = 'ODSTextArea'

export default ODSTextArea
