import { CircleWithText, Grid, LayoutIcon, Tabs } from '@shared/components'
import { InsuranceQueue, InsuranceReviewQueueItem } from '@shared/types'
import { useState } from 'react'
import { Page } from '../../../components/templates/TDefault'
import { useEmrQuery } from '../../../utils/hooks'
import BillingHeaderContent from '../../billing/BillingHeaderContent'
import IRQItem from './IRQItem'
import IRQList from './IRQList'

const QUEUE_SIZE_LIMIT = 50

const PIRQ = () => {
  const [activeTab, setActiveTab] = useState<InsuranceQueue>('enrollment')
  const [selectedItem, setSelectedItem] = useState<InsuranceReviewQueueItem | undefined>()

  const queueQuery = useEmrQuery('GET /insurance/review-queue', {
    query: {
      limit: `${QUEUE_SIZE_LIMIT}`,
      queue: activeTab,
    },
  })

  const { irqItems: queue, counts } = queueQuery.data ?? {}

  return (
    <Page
      title='Billing'
      headerContent={<BillingHeaderContent selectedPage='Insurance review' />}
      pushHeaderContent={false}
    >
      <Tabs
        pt='md'
        pb='lg'
        value={activeTab}
        onTabChange={tab => {
          setSelectedItem(undefined)
          setActiveTab(tab as InsuranceQueue)
        }}
      >
        <Tabs.List pl='lg'>
          <Tabs.Tab
            value='enrollment'
            icon={<LayoutIcon />}
            rightSection={
              Boolean(counts?.enrollmentQueue) && (
                <CircleWithText>{counts?.enrollmentQueue}</CircleWithText>
              )
            }
          >
            Enrollment team
          </Tabs.Tab>
          <Tabs.Tab
            value='payors'
            icon={<LayoutIcon />}
            rightSection={
              Boolean(counts?.payorsQueue) && <CircleWithText>{counts?.payorsQueue}</CircleWithText>
            }
          >
            Payors team
          </Tabs.Tab>
          <Tabs.Tab
            value='flagged'
            icon={<LayoutIcon />}
            rightSection={
              Boolean(counts?.flaggedQueue) && (
                <CircleWithText>{counts?.flaggedQueue}</CircleWithText>
              )
            }
          >
            Flagged
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Grid px='lg'>
        <Grid.Col span={3}>
          <IRQList
            queue={queue ?? []}
            isLoading={queueQuery.isLoading}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            activeTab={activeTab}
          />
        </Grid.Col>
        <Grid.Col span={9} sx={theme => ({ backgroundColor: theme.colors.dark[0] })}>
          {selectedItem && (
            <IRQItem
              item={selectedItem}
              refreshQueue={() => queueQuery.refetch()}
              activeTab={activeTab}
            />
          )}
        </Grid.Col>
      </Grid>
    </Page>
  )
}

export default PIRQ
