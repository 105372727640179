import { useInterval } from '@mantine/hooks'
import { Group, Modal, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import { toTime } from '@shared/utils'
import { useEffect, useState } from 'react'
import { IIdleTimer } from 'react-idle-timer'

export const IdlePromptModal = (props: {
  opened: boolean
  onClose: () => void
  onLogout: () => void
  idleTimer: IIdleTimer
}) => {
  const [remainingTime, setRemainingTime] = useState('')
  const interval = useInterval(() => {
    const remainingTime = props.idleTimer.getRemainingTime()
    // convert to remainingTime (in milliseconds) to `minutes:seconds` format
    const minutes = Math.floor(remainingTime / toTime('1 minute').ms())
    const seconds = Math.floor((remainingTime % toTime('1 minute').ms()) / toTime('1 second').ms())
    setRemainingTime(`${minutes}:${seconds.toString().padStart(2, '0')}`)
  }, toTime('1 second').ms())

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [])

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title='Are you still there?'
      footer={
        <Group>
          <PrimaryButton onClick={props.idleTimer.activate}>Yes, I&apos;m here</PrimaryButton>
          <SecondaryButton onClick={props.onLogout}>No, sign me out</SecondaryButton>
        </Group>
      }
    >
      <Text>If not, we&apos;ll sign you out in {remainingTime}</Text>
    </Modal>
  )
}
