import { UseFormReturnType } from '@mantine/form'
import { Alert, InfoIcon } from '@shared/components'
import { Employee, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { ChooseClinicianScheduler } from './ChooseClinicianScheduler'
import { NextAvailableScheduler } from './NextAvailableScheduler'
import { CalendarDaysDateslot, ScheduleView, SchedulingFormData } from './SchedulerDrawer'
import { CalendarSlotsData } from './use-calendar'

export type ScheduleSlotsProps = {
  patient: Patient
  schedulingForm: UseFormReturnType<SchedulingFormData>
  today: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  isLoading: boolean
  availableCalendarDates?: CalendarDaysDateslot[]
  availableAppointments: CalendarSlotsData[]
  hasInsurance: boolean
  scheduleView: ScheduleView
  employee: Employee
}

export const SchedulerSlots = ({
  patient,
  schedulingForm,
  today,
  maxDate,
  isLoading,
  availableCalendarDates,
  availableAppointments,
  hasInsurance,
  employee,
  scheduleView,
}: ScheduleSlotsProps) => {
  if (!availableCalendarDates?.length) {
    return (
      <Alert variant='secondary' icon={<InfoIcon />}>
        {scheduleView === 'choose-clinician' && employee
          ? 'The selected clinician has no availability.'
          : 'No availability found.'}
      </Alert>
    )
  }

  return (
    <>
      {scheduleView === 'choose-clinician' ? (
        <ChooseClinicianScheduler
          patient={patient}
          schedulingForm={schedulingForm}
          today={today}
          maxDate={maxDate}
          isLoading={isLoading}
          availableCalendarDates={availableCalendarDates}
          availableAppointments={availableAppointments}
        />
      ) : (
        <NextAvailableScheduler
          schedulingForm={schedulingForm}
          today={today}
          maxDate={maxDate}
          isLoading={isLoading}
          availableCalendarDates={availableCalendarDates}
          availableAppointments={availableAppointments}
          hasInsurance={hasInsurance}
        />
      )}
    </>
  )
}
