import { useToggle } from '@mantine/hooks'
import {
  Alert,
  Checkbox,
  CheckboxGroup,
  PrimaryButton,
  ShieldIcon,
  Skeleton,
  Stack,
  Table,
  Th,
} from '@shared/components'
import { SurescriptsHistoryMedication } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

export const MedHistoryContent = () => {
  const { patientId } = usePatient()
  const [consent, toggleConsent] = useToggle()

  const medicationHistoryConsentMutation = useMutation(
    emrApi.getMutation('POST /patient/:patientId/medicationHistory/consent'),
  )

  const [medicationHistoryQueryKey, medicationHistoryQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/medicationHistory',
    {
      params: { patientId },
    },
  )

  const medicationHistoryQuery = useQuery(
    medicationHistoryQueryKey,
    medicationHistoryQueryFunction,
    {
      enabled: medicationHistoryConsentMutation.isSuccess,
      refetchInterval: data => {
        if (!data?.Items) {
          return 500
        }

        return false
      },
    },
  )

  if (!medicationHistoryConsentMutation.isSuccess) {
    return (
      <Stack>
        <CheckboxGroup
          w='1200px'
          explanation='Disclaimer: Certain information may not be available or accurate in this report, including items that the patient asked not be disclosed due to patient privacy concerns, over-the-counter medications, low cost prescriptions, prescriptions paid for by the patient or non-participating sources, or errors in insurance claims information. The provider should independently verify medication history with the patient.'
          onChange={() => toggleConsent()}
        >
          <Checkbox
            value='ophelia'
            label='Patient has given consent to retrieve medication history'
          />
        </CheckboxGroup>
        <PrimaryButton
          disabled={!consent}
          onClick={() => {
            medicationHistoryConsentMutation.mutate({
              params: {
                patientId,
              },
            })
          }}
        >
          Show medication history
        </PrimaryButton>
      </Stack>
    )
  }

  if (medicationHistoryQuery.isLoading || !medicationHistoryQuery.data?.Items) {
    return <Skeleton w='100%' h='100px' />
  }

  if (medicationHistoryQuery.data?.Items.length === 0) {
    return (
      <Alert icon={<ShieldIcon />} variant='warning'>
        Patient has no medication history
      </Alert>
    )
  }

  const medicationHistory = medicationHistoryQuery.data.Items
  const headers = [
    'ID',
    'Duplicate?',
    'Medication Name',
    'NDC',
    'Lexi Identifiers',
    'Diagnosis Code',
    'Payer',
    'Quantity',
    'Days Supply',
    'Refills',
    'RxCUI',
    'OTC',
    'Compound Ingredients',
    'Directions',
    'DoseForm',
    'Substitutions?',
    'Written Date',
    'Last Fill Date',
    'Pharmacy Notes',
  ]

  const isDuplicate = (medication: SurescriptsHistoryMedication) => {
    return medicationHistory.some(otherMedication => {
      return (
        otherMedication.PatientMedicationHistoryId !== medication.PatientMedicationHistoryId &&
        otherMedication.NDC === medication.NDC &&
        otherMedication.WrittenDate === medication.WrittenDate
      )
    })
  }

  const data = medicationHistory?.map(m => [
    m.PatientMedicationHistoryId,
    isDuplicate(m) ? 'Yes' : 'No',
    [`Display: ${m.DisplayName ?? 'n/a'}`, `Generic: ${m.GenericProductName ?? 'n/a'}`].join('\n'),
    m.NDC,
    [
      `Gen Drug: ${m.LexiGenDrugId ?? 'n/a'}`,
      `Gen Product: ${m.LexiGenProductId ?? 'n/a'}`,
      `Drug Syn: ${m.LexiDrugSynId ?? 'n/a'}`,
      `Synonym Type: ${m.LexiSynonymTypeId ?? 'n/a'}`,
    ].join('\n'),
    m.DiagnosisCode,
    m.Payer,
    `${m.Quantity} ${m.DispenseUnitDescription ?? ''}`,
    m.DaysSupply,
    m.Refills,
    m.RxCUI,
    m.OTC ? 'Yes' : 'No',
    m.CompoundingIngredients?.map(ci =>
      Object.entries(ci).map(([key, value]) => `${key}: ${value ?? 'n/a'}`),
    ).join('\n-'),
    m.Directions,
    m.DoseForm,
    m.NoSubstitutions ? 'No' : 'Yes',
    dayjs(m.WrittenDate).format('MM/DD/YYYY'),
    dayjs(m.LastFillDate).format('MM/DD/YYYY'),
    m.PharmacyNotes,
  ])

  return (
    <Stack pt='sm' maw='100%' style={{ overflow: 'scroll' }}>
      <Table striped>
        <thead>
          <tr>
            {headers.map(key => (
              <Th key={key} sortable={false}>
                {key}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(entry => (
            <tr key={JSON.stringify(entry)}>
              {entry.map((e, idx) => (
                <td
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre',
                    maxWidth: '10%',
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${entry[0]}_${idx}`}
                >
                  {e}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  )
}
