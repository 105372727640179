import {
  ArrowDownRightIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
  Colors,
  Group,
  Text,
} from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'

type DrawerComparisonProps = {
  selectedData:
    | DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
    | ObjectiveMeasureResponse
  comparisonData:
    | DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
    | ObjectiveMeasureResponse
  index: number
  title: ObjectiveMeasuresTitle
}

const DataComparison = ({ selectedData, comparisonData, index, title }: DrawerComparisonProps) => {
  const selectedDataValue = selectedData.data[index]?.score as number
  const comparisonDataValue = comparisonData.data[index]?.score as number
  const difference = selectedDataValue - comparisonDataValue

  const positiveDifferenceIsBetter = title === 'BARC-10'

  const icon = (difference: number) => {
    if (difference === 0) {
      return <ArrowRightIcon color={(colors: Colors) => colors.text[1]} />
    }
    if (difference > 0) {
      return (
        <ArrowUpRightIcon
          color={(colors: Colors) =>
            positiveDifferenceIsBetter ? colors.success[0] : colors.error[0]
          }
        />
      )
    }
    return (
      <ArrowDownRightIcon
        color={(colors: Colors) =>
          positiveDifferenceIsBetter ? colors.error[0] : colors.success[0]
        }
      />
    )
  }

  return (
    <Group spacing='sm'>
      {icon(difference)}
      <Text color={(colors: Colors) => colors.text[1]}>{`${Math.abs(difference)} ${
        Math.abs(difference) === 1 ? 'pt' : 'pts'
      }`}</Text>
    </Group>
  )
}

export default DataComparison
