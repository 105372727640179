import { ArrowLeftIcon, Navbar as MantineNavbar, PrimaryButton, Stack } from '@shared/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { postFlexChartViewedMessage } from '../../utils/utils'

export const IframeNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const iframeNavigationParam = params.get('iframeNavigation')

  // Only show this back button navigation on patient charts, and only if the iframeNavigation param is not set to 'no'
  const patientChartRegex = /^\/patients\/(?!search$)[^/]+$/
  if (iframeNavigationParam === 'no' || !patientChartRegex.test(location.pathname)) {
    return null
  }

  return (
    <MantineNavbar width={{ base: 60 }}>
      <Stack align='center' justify='flex-start' mt='14px'>
        <PrimaryButton
          onClick={() => {
            // This message is needed to tell Flex to clear the current context when the back button has been hit
            postFlexChartViewedMessage(null)
            return navigate('/patients')
          }}
          leftIcon={<ArrowLeftIcon />}
        />
      </Stack>
    </MantineNavbar>
  )
}
