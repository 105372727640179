import { useForm } from '@mantine/form'
import { useDidUpdate } from '@mantine/hooks'
import {
  Checkbox,
  DateInput,
  Divider,
  Drawer,
  FormSkipPredicate,
  Group,
  isAnySelected,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import {
  ActiveStatus,
  ActiveStatusVerificationMethod,
  InsuranceData,
  InsuranceType,
  ManualInsuranceData,
} from '@shared/types'
import { getInsurancePlanId } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useMutation } from 'react-query'
import { emrApi, patientsApi } from '../../../api'
import { InsuranceSelect } from '../../../components/forms/InsuranceSelect'
import { isAtleastOneWord, isBirthday, isRequired } from '../../../utils/formValidation'
import IRQCardViewer from './IRQCardViewer'
type Form = {
  provider: string
  firstName: string
  lastName: string
  birthday: string
  memberId: string
  isActive: ActiveStatus.No | ActiveStatus.Yes | undefined
  activeVerificationMethod:
    | ActiveStatusVerificationMethod.Calling
    | ActiveStatusVerificationMethod.ExternalSoftware
    | undefined
  patientIsPrimarySubscriber: boolean
  primarySubscriberFirstName: string | undefined
  primarySubscriberLastName: string | undefined
  primarySubscriberBirthday: string | undefined
}

export type IRQManualVerificationDrawerProps = {
  opened: boolean
  patientId: string
  insuranceData?: InsuranceData | null
  manualInsuranceData?: ManualInsuranceData | null
  insuranceType: InsuranceType
  frontFileId?: string
  backFileId?: string
  verifyMethodRadioGroup: React.ReactNode
  onClose: () => void
  onSave: () => void
}

const IRQManualVerificationDrawer = ({
  opened,
  insuranceData,
  manualInsuranceData,
  patientId,
  insuranceType,
  frontFileId,
  backFileId,
  onClose,
  onSave,
  verifyMethodRadioGroup,
}: IRQManualVerificationDrawerProps) => {
  /**
   * getInsurancePlanId returns a unique identifier for a given
   * insurance payer which consists of both the eligiblePayerId
   * and the name of a the payer in a single formatted string,
   * e.g. 12345__Humana
   */
  const uniqueProviderId =
    getInsurancePlanId(insuranceData?.eligiblePayerId, insuranceData?.provider) ?? ''

  const skipIfIsPrimarySubscriber: FormSkipPredicate<string, Form> = (_, values) =>
    Boolean(values?.patientIsPrimarySubscriber)

  const primaryInsuranceValidation = {
    provider: isRequired,
    firstName: validateWith(isRequired, isAtleastOneWord),
    lastName: validateWith(isRequired, isAtleastOneWord),
    birthday: validateWith(isRequired, isBirthday),
    memberId: validateWith(isRequired),
    isActive: isAnySelected(['yes', 'no'], 'Required'),
    primarySubscriberFirstName: validateWith(
      skipIfIsPrimarySubscriber,
      isRequired,
      isAtleastOneWord,
    ),
    primarySubscriberLastName: validateWith(
      skipIfIsPrimarySubscriber,
      isRequired,
      isAtleastOneWord,
    ),
    primarySubscriberBirthday: validateWith(skipIfIsPrimarySubscriber, isRequired, isBirthday),
  }

  const getActiveStatus = () => {
    if (manualInsuranceData?.isActive === undefined) {
      return
    }
    return manualInsuranceData?.isActive ? ActiveStatus.Yes : ActiveStatus.No
  }

  const initialFormValues = {
    provider: uniqueProviderId,
    firstName: manualInsuranceData?.first_name ?? insuranceData?.firstName ?? '',
    lastName: manualInsuranceData?.last_name ?? insuranceData?.lastName ?? '',
    birthday: manualInsuranceData?.birthday ?? insuranceData?.cardholderBirthday ?? '',
    memberId: insuranceData?.memberId ?? manualInsuranceData?.insurance_card?.member_id ?? '',
    isActive: getActiveStatus(),
    activeVerificationMethod: manualInsuranceData?.activeVerificationMethod,
    patientIsPrimarySubscriber: insuranceData?.patientIsPrimarySubscriber ?? false,
    primarySubscriberFirstName: insuranceData?.primarySubscriberFirstName ?? '',
    primarySubscriberLastName: insuranceData?.primarySubscriberLastName ?? '',
    primarySubscriberBirthday: insuranceData?.primarySubscriberBirthday ?? '',
  }

  const { values, validate, getInputProps, setValues } = useForm<Form>({
    initialValues: initialFormValues,
    validate: primaryInsuranceValidation,
  })

  const initializeForm = (
    insuranceData?: InsuranceData | null,
    manualInsuranceData?: ManualInsuranceData | null,
  ) => {
    setValues({
      provider: uniqueProviderId,
      firstName: manualInsuranceData?.first_name ?? insuranceData?.firstName ?? '',
      lastName: manualInsuranceData?.last_name ?? insuranceData?.lastName ?? '',
      birthday: manualInsuranceData?.birthday ?? insuranceData?.cardholderBirthday ?? '',
      memberId: insuranceData?.memberId ?? manualInsuranceData?.insurance_card?.member_id ?? '',
      isActive: getActiveStatus(),
      activeVerificationMethod: manualInsuranceData?.activeVerificationMethod,
      patientIsPrimarySubscriber: insuranceData?.patientIsPrimarySubscriber ?? false,
      primarySubscriberFirstName: insuranceData?.primarySubscriberFirstName ?? '',
      primarySubscriberLastName: insuranceData?.primarySubscriberLastName ?? '',
      primarySubscriberBirthday: insuranceData?.primarySubscriberBirthday ?? '',
    })
  }

  useDidUpdate(
    () => initializeForm(insuranceData, manualInsuranceData),
    [insuranceData, manualInsuranceData],
  )

  const updateInsuranceData = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/insurance/update'),
  )

  const updateManualInsuranceData = useMutation(
    ['patientsApi.updateManualInsuranceData'],
    patientsApi.updateManualInsuranceData,
  )

  const verify = async () => {
    if (validate().hasErrors) {
      return
    }

    /**
     * The value of the selected provider is a formatted string which includes
     * the eligiblePayerId and the name of a given insurance payer, e.g. 12345__Humana.
     *
     * We have to split the provider string on the double underscore
     * in order to capture both the payer name and eligiblePayerId
     * of the selected insurance provider separately.
     */
    const [eligiblePayerId, provider] = values.provider?.split('__') || ['', '']

    const updatedManualInsuranceData: ManualInsuranceData & { insuranceType: InsuranceType } = {
      first_name: values.firstName ?? '',
      last_name: values.lastName ?? '',
      birthday: values.birthday ?? '',
      isActive:
        values.isActive === undefined ? values.isActive : values.isActive === ActiveStatus.Yes,
      activeVerificationMethod:
        (values.activeVerificationMethod === ActiveStatusVerificationMethod.Calling &&
          ActiveStatusVerificationMethod.Calling) ||
        (values.activeVerificationMethod === ActiveStatusVerificationMethod.ExternalSoftware &&
          ActiveStatusVerificationMethod.ExternalSoftware) ||
        undefined,
      gender: manualInsuranceData?.gender ?? '',
      patient_relationship_to_subscriber_code:
        manualInsuranceData?.patient_relationship_to_subscriber_code ?? '',
      insurance_card: {
        payer_id: eligiblePayerId ?? '',
        payer_name: provider ?? '',
        member_id: values.memberId ?? '',
        group_number: manualInsuranceData?.insurance_card?.group_number ?? '',
        plan_name: manualInsuranceData?.insurance_card?.plan_name ?? '',
        plan_type: manualInsuranceData?.insurance_card?.plan_type,
        rx_bin: manualInsuranceData?.insurance_card?.rx_bin ?? '',
        rx_pcn: manualInsuranceData?.insurance_card?.rx_pcn ?? '',
      },
      insuranceType,
    }

    await updateManualInsuranceData.mutateAsync({
      patientId,
      data: updatedManualInsuranceData,
    })

    const updatedPatientProvidedInsuranceData: Partial<InsuranceData> & {
      insuranceType: InsuranceType
    } = {
      eligiblePayerId,
      provider,
      memberId: values.memberId ?? '',
      firstName: values.firstName ?? '',
      lastName: values.lastName ?? '',
      cardholderBirthday: values.birthday ?? '',
      patientIsPrimarySubscriber: values.patientIsPrimarySubscriber ?? false,
      primarySubscriberFirstName: values.patientIsPrimarySubscriber
        ? ''
        : values.primarySubscriberFirstName,
      primarySubscriberLastName: values.patientIsPrimarySubscriber
        ? ''
        : values.primarySubscriberLastName,
      primarySubscriberBirthday: values.patientIsPrimarySubscriber
        ? ''
        : values.primarySubscriberBirthday,
      insuranceType,
    }

    await updateInsuranceData.mutateAsync({
      params: { patientId },
      data: updatedPatientProvidedInsuranceData,
    })

    onSave()
  }

  const loading = updateInsuranceData.isLoading || updateManualInsuranceData.isLoading
  const saving = updateInsuranceData.isLoading || updateManualInsuranceData.isLoading

  return (
    <Drawer
      title='Manual Insurance Verification'
      size='lg'
      opened={opened}
      position='right'
      onClose={onClose}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={verify} disabled={loading} loading={saving}>
            Edit & verify
          </PrimaryButton>
        </Group>
      }
    >
      <Stack
        sx={theme => ({
          padding: theme.other.sizes.gap.xl,
        })}
      >
        {verifyMethodRadioGroup}
        <IRQCardViewer patientId={patientId} frontFileId={frontFileId} backFileId={backFileId} />
        <InsuranceSelect
          patientId={patientId}
          label='Insurance plan'
          disabled={saving || loading}
          {...getInputProps('provider')}
        />
        <TextInput
          label='Cardholder first name'
          placeholder='e.g., John'
          maxLength={64}
          disabled={saving || loading}
          {...getInputProps('firstName')}
        />
        <TextInput
          label='Cardholder last name'
          placeholder='e.g., Smith'
          maxLength={64}
          disabled={saving || loading}
          {...getInputProps('lastName')}
        />
        <DateInput
          label='Cardholder birthday'
          maxLength={12}
          disabled={saving || loading}
          {...getInputProps('birthday')}
        />
        <TextInput
          label='Member ID'
          placeholder='Member ID'
          maxLength={64}
          disabled={saving || loading}
          {...getInputProps('memberId')}
        />
        <Checkbox
          label='Patient is the primary subscriber'
          disabled={saving || loading}
          {...getInputProps('patientIsPrimarySubscriber', { type: 'checkbox' })}
        />
        {!values.patientIsPrimarySubscriber && (
          <>
            <TextInput
              label='Primary subscriber first name'
              placeholder='e.g., John'
              maxLength={64}
              disabled={saving}
              {...getInputProps('primarySubscriberFirstName')}
            />
            <TextInput
              label='Primary subscriber last name'
              placeholder='e.g., Smith'
              maxLength={64}
              disabled={saving}
              {...getInputProps('primarySubscriberLastName')}
            />
            <DateInput
              label='Primary subscriber birthday'
              maxLength={12}
              disabled={saving}
              {...getInputProps('primarySubscriberBirthday')}
            />
          </>
        )}
        <Divider />
        <Stack spacing='sm'>
          <Text bold>Is the insurance active?</Text>
          <RadioGroup {...getInputProps('isActive')}>
            <Radio
              value={ActiveStatus.Yes}
              label={capitalize(ActiveStatus.Yes)}
              disabled={saving}
            />
            <Radio value={ActiveStatus.No} label={capitalize(ActiveStatus.No)} disabled={saving} />
          </RadioGroup>
        </Stack>
        <Stack spacing='sm'>
          <Text bold>How did you verify? (Optional)</Text>
          <RadioGroup {...getInputProps('activeVerificationMethod')}>
            <Radio
              value={ActiveStatusVerificationMethod.Calling}
              label={capitalize(ActiveStatusVerificationMethod.Calling)}
              disabled={saving}
            />
            <Radio
              value={ActiveStatusVerificationMethod.ExternalSoftware}
              label={capitalize(ActiveStatusVerificationMethod.ExternalSoftware)}
              disabled={saving}
            />
          </RadioGroup>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default IRQManualVerificationDrawer
