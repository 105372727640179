import {
  Alert,
  ArrowRightIcon,
  BetterDrawer,
  Divider,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  InfoIcon,
  PrimaryButton,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useMantineTheme,
  UserCheckIcon,
} from '@shared/components'
import { CaseReviewNote, Patient } from '@shared/types'
import { dayjs, name } from '@shared/utils'
import { useEffect, useState } from 'react'
import { useQueries } from 'react-query'
import { Link } from 'react-router-dom'
import { emrApi } from '../../../api'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'
import { useAuth } from '../../../context/auth'
import * as FullStory from '../../../utils/fullstory'
import { useEmrQuery, useFlags } from '../../../utils/hooks'
import PrescriptionRow from '../../patient/prescriptions/PrescriptionRow'
import { LockedCaseReviewNoteInfoSection } from '../../patient/visits/LockedNoteInfoSection'
import { ObjectiveMeasuresSection } from '../objectiveMeasures/ObjectiveMeasuresSection'
import { CaseReviewAttestationContent } from './CaseReviewAttestationDrawer'

type LockedCaseReviewDrawerProps = {
  onClose: () => void
  caseReviewNote: CaseReviewNote
  patient: Patient
  showName: boolean
}
const AssessmentAndRecommendationSection = ({
  assessment,
  recommendations,
  assessmentAndRecommendations,
  isCombinedFieldEnabled,
}: {
  assessment?: string
  recommendations?: string
  assessmentAndRecommendations?: string
  isCombinedFieldEnabled: boolean
}) => {
  if (assessment || recommendations || !isCombinedFieldEnabled) {
    return (
      <>
        <Stack spacing='sm'>
          <Text color={colors => colors.text[1]}> Assessment </Text>
          <Text style={{ whiteSpace: 'pre-wrap' }} component='p'>
            {assessment}
          </Text>
        </Stack>
        <Stack spacing='sm'>
          <Text color={colors => colors.text[1]}> Recommendation </Text>
          <Text style={{ whiteSpace: 'pre-wrap' }} component='p'>
            {recommendations}
          </Text>
        </Stack>
      </>
    )
  }
  return (
    <Stack spacing='sm'>
      <Text color={colors => colors.text[1]}> Assessment and recommendations</Text>
      <Text style={{ whiteSpace: 'pre-wrap' }} component='p'>
        {assessmentAndRecommendations}
      </Text>
    </Stack>
  )
}

const LockedCaseReviewContent = ({
  onClose,
  caseReviewNote,
  patient,
  showName,
}: LockedCaseReviewDrawerProps) => {
  const { currentUser } = useAuth()
  const { revampedCaseReviewNotes } = useFlags()
  const {
    other: { colors },
  } = useMantineTheme()

  const lockedByQuery = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: caseReviewNote.lockedBy || '' } },
    { enabled: Boolean(caseReviewNote.lockedBy) },
  )

  const lockedBy = lockedByQuery.data?.name ?? caseReviewNote.lockedBy ?? 'Unknown'

  const [attestationDrawerOpened, toggleAttestationDrawer] = useState<boolean>(false)

  const phq9ResponsesQuery = useEmrQuery('GET /patients/:patientId/phq9Responses', {
    params: { patientId: patient.oid },
  })

  const phq8ResponsesQuery = useEmrQuery('GET /patients/:patientId/phq8Responses', {
    params: { patientId: patient.oid },
  })

  const gad7ResponsesQuery = useEmrQuery('GET /patients/:patientId/gad7Responses', {
    params: { patientId: patient.oid },
  })

  const barc10ResponsesQuery = useEmrQuery('GET /patients/:patientId/barc10Responses', {
    params: { patientId: patient.oid },
  })

  const prescriptionQueries = useQueries(
    caseReviewNote?.prescriptionIds?.map(id => {
      const [queryKey, queryFn] = emrApi.getQuery(
        'GET /patient/:patientId/legacyPrescription/:prescriptionId',
        {
          params: {
            prescriptionId: String(id),
            patientId: caseReviewNote.patientId,
          },
        },
      )

      return {
        queryKey,
        queryFn,
      }
    }) ?? [],
  )

  //
  const currentUserCanAttestNote =
    (currentUser.isPsychConsultant && !caseReviewNote.isAttested) ||
    (patient.primaryClinician?.id === currentUser.oid && !caseReviewNote.isAttestedByPc)

  const noteCanBeAttested = currentUserCanAttestNote

  if (attestationDrawerOpened) {
    return (
      <CaseReviewAttestationContent
        onBack={() => toggleAttestationDrawer(false)}
        onClose={onClose}
        lockedBy={lockedBy}
        note={caseReviewNote}
      />
    )
  }

  const isFullyAttested =
    (revampedCaseReviewNotes && caseReviewNote.isAttested && caseReviewNote.isAttestedByPc) ||
    (!revampedCaseReviewNotes && caseReviewNote.isAttested)

  return (
    <>
      <DrawerHeader onClose={onClose}>Case review note</DrawerHeader>
      <DrawerContent>
        <Stack p='md' spacing='lg'>
          {showName && (
            <>
              <Group>
                <TitleTwo>
                  {name({
                    first: patient.personalData.firstName,
                    last: patient.personalData.lastName,
                  }).lastCommaFirst()}
                </TitleTwo>
                <TertiaryButton
                  component={Link}
                  to={`/patients/${patient.oid}`}
                  rightIcon={<ArrowRightIcon color={colors.actions[0]} />}
                >
                  Go to chart
                </TertiaryButton>
              </Group>
              <Divider />
            </>
          )}
          <Stack>
            <Alert
              variant='secondary'
              title={
                isFullyAttested
                  ? 'Locked, signed, and attested'
                  : 'Locked and signed, awaiting attestation'
              }
              icon={<InfoIcon />}
              sx={{ fontColor: colors.text[1] }}
              color={colors.text[3]}
            >
              Locked visit notes cannot be edited
            </Alert>
            <LockedCaseReviewNoteInfoSection
              postCaseReviewTime={Number(caseReviewNote.postCaseReviewTime || 0)}
              preWorkTime={Number(caseReviewNote.content?.preWorkMinutes || 0)}
              caseReviewTime={Number(caseReviewNote.caseReviewTime || 0)}
              lockedBy={lockedBy}
              pcAttestationInfo={{
                attestedBy: caseReviewNote.pcAttestation?.attestedBy,
                comments: caseReviewNote.pcAttestation?.attestationComments,
              }}
              psychConsultantAttestationInfo={{
                attestedBy: caseReviewNote.attestation?.attestedBy,
                comments: caseReviewNote.attestation?.attestationComments,
              }}
            />
          </Stack>

          <Stack spacing='sm'>
            <Text color={colors.text[1]}> Summary </Text>
            <Text style={{ whiteSpace: 'pre-wrap' }} component='p'>
              {caseReviewNote.content?.summary}
            </Text>
          </Stack>
          <Stack>
            <Text color={colors => colors.text[0]}>Objective measures</Text>
            <ObjectiveMeasuresSection
              showCaseReviewAlert={false}
              isLoading={
                phq9ResponsesQuery.isLoading ||
                gad7ResponsesQuery.isLoading ||
                barc10ResponsesQuery.isLoading
              }
              phqResponses={phq8ResponsesQuery.data ?? []}
              gad7Responses={gad7ResponsesQuery.data ?? []}
              barc10Responses={barc10ResponsesQuery.data ?? []}
              editMode={false}
              lockedAt={caseReviewNote.lockedAt}
              patientId={patient.oid}
            />
          </Stack>
          <AssessmentAndRecommendationSection
            assessment={caseReviewNote.content?.assessment}
            recommendations={caseReviewNote.content?.recommendations}
            assessmentAndRecommendations={caseReviewNote.content?.assessmentAndRecommendations}
            isCombinedFieldEnabled={Boolean(revampedCaseReviewNotes)}
          />

          {revampedCaseReviewNotes && (
            <Stack>
              <Text color={colors => colors.text[1]}>Medication</Text>
              {caseReviewNote?.prescriptionIds?.length ? (
                prescriptionQueries.map((query, index) => {
                  if (query.data) {
                    const prescription = query.data
                    return (
                      <PrescriptionRow
                        key={query.data?.prescription_id}
                        prescription={prescription}
                        patientId={caseReviewNote.patientId}
                      />
                    )
                  }
                  if (query.isLoading) {
                    return (
                      <Skeleton
                        key={`prescription-skeleton-${caseReviewNote.prescriptionIds?.[index]}`}
                        width='100%'
                        height={100}
                      />
                    )
                  }
                  return null
                })
              ) : (
                <EmptyDataCell message='No medication queued' />
              )}
            </Stack>
          )}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='apart'>
          <Text color={colors.text[1]} size='xs'>
            Locked on{' '}
            {dayjs(caseReviewNote.lockedAt).tz(dayjs.tz.guess()).format('MMMM D, YYYY h:mmA z')}
          </Text>
          {noteCanBeAttested && (
            <PrimaryButton
              leftIcon={<UserCheckIcon />}
              onClick={() => toggleAttestationDrawer(true)}
            >
              Attest note
            </PrimaryButton>
          )}
        </Group>
      </DrawerFooter>
    </>
  )
}

export const LockedCaseReviewDrawer = ({
  opened,
  ...props
}: LockedCaseReviewDrawerProps & { opened: boolean }) => {
  useEffect(() => {
    if (opened) {
      FullStory.event('Viewed case review note')
    }
  }, [opened])

  return (
    <BetterDrawer opened={opened} onClose={props.onClose} size='lg' position='right'>
      <LockedCaseReviewContent {...props} />
    </BetterDrawer>
  )
}
