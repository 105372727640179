import { FaIdCard } from '@react-icons/all-files/fa/FaIdCard'
import { HiCamera } from '@react-icons/all-files/hi/HiCamera'
import { HiCurrencyDollar } from '@react-icons/all-files/hi/HiCurrencyDollar'
import { MdCake } from '@react-icons/all-files/md/MdCake'
import { AlertIcon, Colors, Group, Radio, Stack, Text, useMantineTheme } from '@shared/components'
import { InsuranceReviewQueueItem } from '@shared/types'
import { dayjs, name } from '@shared/utils'
import { areInsuranceCards, renderCompletionBadge } from './utils'

export type IRQListElementProps = {
  item: InsuranceReviewQueueItem
}

const IRQListElement = ({ item }: IRQListElementProps) => {
  const now = dayjs()
  const time =
    item.statuses.patient === 'candidate' && item.upcomingIntake
      ? dayjs(item.upcomingIntake.datetime)
      : dayjs(item.createdAt)

  const displayTime =
    item.statuses.patient === 'candidate' && !item.upcomingIntake
      ? 'No upcoming intake'
      : time.fromNow()

  const diffInDays = time.diff(now, 'days', true)
  const showIcon = time.isAfter(now) && diffInDays <= 3
  const status: keyof Colors = diffInDays > 0 && diffInDays <= 2 ? 'error' : 'warning'

  const {
    other: { colors },
  } = useMantineTheme()

  const financialAgreementSigned = item.consents.some(consent => consent.type === 'financial')
  return (
    <Radio
      label={
        <Stack sx={{ width: '100%' }}>
          <Group sx={{ width: '100%' }} position='apart'>
            <Text>
              {name({ first: item.firstName, last: item.lastName }).full()}
              {!['candidate', 'in treatment'].includes(item.statuses.patient) &&
                ` (${item.statuses.patient})`}
            </Text>
            <Group spacing='xs'>
              <Text>{displayTime}</Text>
              {showIcon && <AlertIcon color={colors[status][0]} />}
            </Group>
          </Group>
          <Group spacing='xs'>
            {renderCompletionBadge(
              <HiCurrencyDollar className='h-4 w-4' />,
              financialAgreementSigned,
            )}
            {renderCompletionBadge(
              <FaIdCard className='h-4 w-4' />,
              Boolean(
                item.eligibleInsuranceData?.plan.coverage_status_label === 'Active Coverage' ||
                  item.manualInsuranceData,
              ),
              undefined,
              item.eligibleInsuranceData?.plan.coverage_status_label === 'Inactive'
                ? 'text-error'
                : undefined,
            )}
            {renderCompletionBadge(<MdCake className='h-4 w-4' />, Boolean(item.birthday))}
            {renderCompletionBadge(<HiCamera className='h-4 w-4' />, areInsuranceCards(item))}
          </Group>
        </Stack>
      }
      value={item.id}
    />
  )
}

export default IRQListElement
