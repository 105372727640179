import { AppointmentTypeString, NOT_ENTERING_INDUCTION_VALUE, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'
import { VisitTypeEntry, useVisitTypes } from '../calendar/useVisitTypes'
import { usePatient } from './PPatientContext'

const FOLLOW_UP_VISIT: AppointmentTypeString = 'Follow-Up Visit'
const FOLLOW_UP_VISIT_LENGTH = 20
const FOLLOW_UP_VISIT_STRING = `${FOLLOW_UP_VISIT.toLowerCase()} (${FOLLOW_UP_VISIT_LENGTH} min)`

const CHECK_IN_VISIT: AppointmentTypeString = 'Check-In Call'
const CHECK_IN_VISIT_STRING = CHECK_IN_VISIT.toLowerCase()

const getVisitTypeInfo = ({ visitTypes }: { visitTypes: VisitTypeEntry[] }) => {
  const FOLLOW_UP_VISIT_ID =
    visitTypes.find(vt => vt.label.toLowerCase() === FOLLOW_UP_VISIT_STRING)?.value || ''

  const CHECK_IN_VISIT_ID =
    visitTypes.find(vt => vt.label.toLowerCase() === CHECK_IN_VISIT_STRING)?.value || ''

  const followUpVisit = {
    initialBhcmVisitTypeId: FOLLOW_UP_VISIT_ID,
    initialBhcmVisitTypeName: FOLLOW_UP_VISIT_STRING,
  }

  const checkInVisit = {
    initialBhcmVisitTypeId: CHECK_IN_VISIT_ID,
    initialBhcmVisitTypeName: CHECK_IN_VISIT_STRING,
  }

  const noInitialBhcmVisit = {
    initialBhcmVisitTypeId: undefined,
    initialBhcmVisitTypeName: undefined,
  }

  return {
    followUpVisit,
    checkInVisit,
    noInitialBhcmVisit,
  }
}

const getInitialBhcmVisitInfo = ({
  visitTypes,
  hasIntakeToday,
  isInductionPatient,
  followUpAppointmentCount,
  levelOfCare,
}: {
  visitTypes: VisitTypeEntry[]
  hasIntakeToday: boolean
  isInductionPatient: boolean
  followUpAppointmentCount: number
  levelOfCare: Patient['statuses']['levelOfCare']
}) => {
  const { followUpVisit, checkInVisit, noInitialBhcmVisit } = getVisitTypeInfo({ visitTypes })

  switch (hasIntakeToday) {
    case true:
      switch (isInductionPatient) {
        case true:
          return checkInVisit
        // If the patient has an intake visit today but isn't an induction patient, we don't want to schedule a bhcm visit
        default:
          return noInitialBhcmVisit
      }
    case false:
      switch (levelOfCare) {
        case 'monthly':
          return followUpVisit
        default: {
          // If this will be the patient's 3rd follow-up visit, schedule a follow-up. Otherwise, schedule a check-in
          if (followUpAppointmentCount === 2) {
            return followUpVisit
          }
          return checkInVisit
        }
      }
  }
}

// The logic for scheduling Initial BHCM visits for patients comes from this Whimsical: https://whimsical.com/cocm-patient-flow-AhvuJUWWKfNKv5nYBkiHHi
export function useInitialBhcm() {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data as Patient

  const { visitTypes } = useVisitTypes({
    patientId: patient?.oid || '',
  })

  let shouldScheduleInitialBhcmVisit = false
  let ccmCalendarId = ''

  const enabled = Boolean(patient?.nurseCareManager?.id)

  const isAlreadyOnCocmRegistryQuery = useQuery(
    ...emrApi.getQuery('GET /cocmRegistry/patient/:patientId', {
      params: {
        patientId: patient?.oid || '',
      },
    }),
    {
      enabled,
    },
  )

  const isAlreadyOnCocmRegistry = Boolean(isAlreadyOnCocmRegistryQuery?.data)

  const isEligibleForCocmQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/is-eligible-for-cocm', {
      params: {
        patientId: patient?.oid || '',
      },
    }),
    {
      enabled,
    },
  )

  const isEligibleForCocm = isEligibleForCocmQuery?.data || isAlreadyOnCocmRegistry

  const ccmQuery = useQuery(
    ...emrApi.getQuery('GET /employee/:employeeId', {
      params: {
        employeeId: patient?.nurseCareManager?.id ?? '',
      },
    }),
    {
      enabled,
    },
  )
  const ccm = ccmQuery?.data

  const hasAttendedBhcmVisitQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/has-attended-initial-bhcm-visit', {
      params: {
        patientId: patient?.oid || '',
      },
      query: {
        includeScheduledVisits: 'yes',
      },
    }),
    {
      enabled,
    },
  )
  const hasAttendedBhcmVisit = hasAttendedBhcmVisitQuery?.data

  const appointmentsTodayQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/appointments', {
      params: {
        patientId: patient?.oid || '',
      },
      query: {
        start: dayjs().startOf('day').toISOString(),
        end: dayjs().endOf('day').toISOString(),
      },
    }),
    {
      enabled,
    },
  )
  const appointmentsToday = appointmentsTodayQuery?.data || []
  const intakeToday = appointmentsToday.filter(appt => appt.type === 'Initial Visit')[0]
  const hasIntakeToday = Boolean(intakeToday)

  const intakeVisitNoteContentQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/visits/:visitId/notes', {
      params: {
        patientId: patient?.oid || '',
        visitId: `${intakeToday?.id}`,
      },
    }),
    {
      enabled: hasIntakeToday,
    },
  )
  const intakeVisitNoteContent = intakeVisitNoteContentQuery?.data?.induction
  const isInductionPatient =
    intakeVisitNoteContent?.induction_email !== NOT_ENTERING_INDUCTION_VALUE

  const followUpAppointmentCountQuery = useQuery(
    ...emrApi.getQuery('GET /patients/:patientId/appointment-counts', {
      params: {
        patientId: patient?.oid || '',
      },
      query: {
        appointmentTypes: ['Follow-Up Visit'],
      },
    }),
    {
      enabled,
    },
  )
  const followUpAppointmentCount = followUpAppointmentCountQuery?.data || 0

  const { initialBhcmVisitTypeId, initialBhcmVisitTypeName } = getInitialBhcmVisitInfo({
    visitTypes,
    levelOfCare: patient?.statuses?.levelOfCare,
    hasIntakeToday,
    isInductionPatient,
    followUpAppointmentCount,
  })

  const ccmCalendar = ccm?.calendarId

  if (
    isEligibleForCocm &&
    ccmCalendar &&
    !hasAttendedBhcmVisit &&
    initialBhcmVisitTypeId &&
    initialBhcmVisitTypeName
  ) {
    shouldScheduleInitialBhcmVisit = true
    ccmCalendarId = ccmCalendar.toString()
  }

  return {
    shouldScheduleInitialBhcmVisit,
    ccmCalendarId,
    initialBhcmVisitTypeId,
    initialBhcmVisitTypeName,
    ccmId: ccm?.oid,
    isEligibleForCocm,
  }
}
