import { useForm } from '@mantine/form'
import { useClipboard } from '@mantine/hooks'
import { Group, Modal, PrimaryButton, Stack, Textarea, validateWith } from '@shared/components'
import { Patient } from '@shared/types'
import { name } from '@shared/utils'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { patientsApi } from '../../api'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'
import ODSSecondaryButton from '../../components/ods/ODSSecondaryButton'
import TModal from '../../components/templates/TModal'
import { Config } from '../../config'
import { isRequired } from '../../utils/formValidation'

export type OPatientAppIFrameProps = {
  patient: Patient
  closeModal: () => void
}

const OPatientAppIFrame = ({ patient, closeModal }: OPatientAppIFrameProps) => {
  const [impersonationToken, setImpersonationToken] = useState('')

  const getImpersonationToken = useMutation(patientsApi.getImpersonationToken, {
    onSuccess: (data: string) => {
      setImpersonationToken(data)
    },
  })
  const form = useForm<{ reason: string }>({ validate: { reason: validateWith(isRequired) } })

  const clipboard = useClipboard()

  const onSubmitForm = async () => {
    if (form.validate().hasErrors) {
      return
    }
    await getImpersonationToken.mutateAsync({ patientId: patient.oid, reason: form.values.reason })
  }

  const getUrl = () => {
    const url = new URL(Config.PATIENT_PORTAL_BASE_URL)
    url.searchParams.append('impersonationToken', impersonationToken)
    return url.toString()
  }

  return (
    <>
      {!impersonationToken && (
        <Modal
          onClose={closeModal}
          opened
          title={`Log in as ${patient.personalData.firstName}`}
          footer={
            <Group position='right'>
              <PrimaryButton loading={getImpersonationToken.isLoading} onClick={onSubmitForm}>
                Submit access request
              </PrimaryButton>
            </Group>
          }
        >
          <Stack spacing='sm'>
            <Textarea
              {...form.getInputProps('reason')}
              minRows={1}
              label='Reason for accessing patient app'
              placeholder='I need to help the patient onboard'
              autosize
            />
          </Stack>
        </Modal>
      )}
      {impersonationToken && !getImpersonationToken.isLoading && (
        <TModal
          padding='m-8'
          style={{
            height: '100%',
            maxWidth: '90%',
            maxHeight: '90%',
            minWidth: '60%',
            minHeight: '60%',
          }}
          height='h-full'
          closeButton={false}
          closeModal={closeModal}
        >
          <div className='handle cursor-move w-full flex items-center justify-between bg-white border-b-2 border-indigo-500 px-4 py-2'>
            <div>
              <span>{`Acting on behalf of ${name({
                first: patient.personalData.firstName,
                last: patient.personalData.lastName,
              }).full()}`}</span>
              <div className='mt-2'>
                <ODSSecondaryButton
                  message='Open in new tab'
                  onClick={() => window?.open(getUrl(), '_blank')?.focus()}
                />
                <ODSSecondaryButton
                  className='ml-4'
                  message='Reload window'
                  onClick={() => {
                    const iframe = document.getElementById(
                      'patient-app-iframe',
                    ) as HTMLIFrameElement
                    if (iframe) {
                      // eslint-disable-next-line no-self-assign
                      iframe.src = iframe.src
                    }
                  }}
                />
                <ODSSecondaryButton
                  className='ml-4'
                  message='Copy URL'
                  onClick={() => {
                    clipboard.copy(getUrl())
                  }}
                />
                <ODSPrimaryButton className='mx-4' message='Done' onClick={closeModal} />
              </div>
            </div>
          </div>
          <iframe
            id='patient-app-iframe'
            frameBorder='0'
            className='w-full flex-grow overflow-hidden'
            sandbox='allow-scripts allow-same-origin'
            src={getUrl()}
            allow='identity-credentials-get'
          ></iframe>
        </TModal>
      )}
    </>
  )
}

export default OPatientAppIFrame
