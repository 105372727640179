import { useForm } from '@mantine/form'
import { Grid, skipIfEmpty, Stack, validateWith } from '@shared/components'
import { BillingProvider, stateAbbreviations } from '@shared/types'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import {
  isAbbreviatedUsState,
  isFourNumbers,
  isRequired,
  isZipCode,
} from '../../../../utils/formValidation'
import { CardHeader } from '../components/CardHeader'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'

export type BillingProviderSectionProps = {
  billingProvider?: BillingProvider
  encounterId: string
  isEditing: boolean
  editButtonEnabled: boolean
  setEditing: (bool: boolean) => void
}

export const BillingProviderSection = ({
  billingProvider,
  encounterId,
  setEditing,
  editButtonEnabled,
  isEditing,
}: BillingProviderSectionProps) => {
  const queryClient = useQueryClient()
  const formattedBillingProviderData = {
    npi: billingProvider?.npi || '',
    tax_id: billingProvider?.tax_id || '',
    organization_name: billingProvider?.organization_name || '',
    address: {
      address1: billingProvider?.address?.address1 || '',
      address2: billingProvider?.address?.address2 || '',
      city: billingProvider?.address?.city || '',
      state: billingProvider?.address?.state || '',
      zip_code: billingProvider?.address?.zip_code || '',
      zip_plus_four_code: billingProvider?.address?.zip_plus_four_code || '',
    },
  }

  const form = useForm<BillingProvider>({
    initialValues: formattedBillingProviderData,
    validate: {
      npi: validateWith(isRequired),
      tax_id: validateWith(isRequired),
      organization_name: validateWith(isRequired),
      address: {
        address1: validateWith(isRequired),
        city: validateWith(isRequired),
        state: validateWith(isRequired, isAbbreviatedUsState),
        zip_code: validateWith(isRequired, isZipCode),
        zip_plus_four_code: validateWith(skipIfEmpty, isFourNumbers),
      },
    },
    clearInputErrorOnChange: false,
  })

  useEffect(() => {
    form.validate()
  }, [form.values])

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))
  const saveBillingProviderData = (billingProviderData: BillingProvider) => {
    updateEncounter.mutate(
      {
        data: {
          encounter: { billing_provider: billingProviderData },
          id: encounterId || '',
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(encounterQueryKey)
          setEditing(false)
        },
      },
    )
  }

  return (
    <Stack>
      <CardHeader
        title='Billing provider'
        showButton={editButtonEnabled}
        buttonLabel='Edit'
        buttonOnClick={() => setEditing(true)}
        errorCount={Object.keys(form.errors).length}
      />
      <Stack>
        <Grid columns={12}>
          <Grid.Col span={12}>
            <CardTextInput
              label='Organization name'
              editable={isEditing}
              {...form.getInputProps('organization_name')}
              placeholder='Ophelia Medical Group, P.C.'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Tax ID'
              editable={isEditing}
              {...form.getInputProps('tax_id')}
              placeholder='8442627111'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='NPI'
              editable={isEditing}
              {...form.getInputProps('npi')}
              placeholder='1234567890'
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Address line 1'
              editable={isEditing}
              {...form.getInputProps('address.address1')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Address line 2 (optional)'
              editable={isEditing}
              {...form.getInputProps('address.address2')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='City'
              editable={isEditing}
              {...form.getInputProps('address.city')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardSelect
              searchable
              data={stateAbbreviations}
              label='State'
              editable={isEditing}
              {...form.getInputProps('address.state')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Zip'
              editable={isEditing}
              {...form.getInputProps('address.zip_code')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardTextInput
              label='Zip plus four (optional)'
              editable={isEditing}
              {...form.getInputProps('address.zip_plus_four_code')}
            />
          </Grid.Col>
        </Grid>
        {isEditing ? (
          <EditableSectionFooter
            onCancel={() => {
              setEditing(false)
              form.setValues(formattedBillingProviderData)
            }}
            onSave={form.onSubmit(() => {
              saveBillingProviderData(form.values)
            })}
            isSaving={updateEncounter.isLoading}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}
