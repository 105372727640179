import { Box, Pill, PlusCircleIcon, SecondaryButton, Stack, Text } from '@shared/components'
import { InsuranceType } from '@shared/types'

export type NoInsuranceSectionProps = {
  addInsuranceOnClick: () => void
  insuranceType: InsuranceType
}
export const NoInsuranceSection = ({
  addInsuranceOnClick,
  insuranceType,
}: NoInsuranceSectionProps) => {
  const buttonLabel =
    insuranceType === 'primary' ? 'Add primary insurance' : 'Add secondary insurance'
  return (
    <Box
      sx={theme => ({
        background: theme.other.colors.background[1],
        padding: theme.spacing.md,
      })}
    >
      <Stack align='center'>
        <Pill status='none' variant='filled'>
          No insurance added yet
        </Pill>
        <Text>Add insurance if patient has insurance and are interested in using it</Text>
        <SecondaryButton leftIcon={<PlusCircleIcon />} onClick={addInsuranceOnClick}>
          {buttonLabel}
        </SecondaryButton>
      </Stack>
    </Box>
  )
}
