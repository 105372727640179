import { AppointmentTypeString, hasGroupRole, hasStatus } from '@shared/types'
import TModal from '../../components/templates/TModal'
import { useAuth } from '../../context/auth'
import { useFlags } from '../../utils/hooks'
import { useAccess } from '../../utils/hooks/use-access'
import { LevelOfCareModal } from '../calendar/LevelOfCareModal'
import { OScheduleVisitModal } from '../calendar/OScheduleVisitModal'
import { ScheduleVisitHoldDrawer } from '../calendar/ScheduleVisitHoldDrawer'
import { TimeTrackingDrawer } from '../registry/patientPanel/TimeTrackingDrawer'
import AddSubscriptionStartDateModal from './AddSubscriptionStartDateModal'
import AddToInsurancePayModal from './AddToInsurancePayModal'
import BulkExportVisitNotesModal from './BulkExportVisitNotesModal'
import CreateCallMeNowWelcomeCallModal from './CreateCallMeNowWelcomeCallModal'
import { CreateDoseSpotPatientModal } from './CreateDoseSpotPatientModal'
import { IRQRequirementsModal } from './IRQRequirementsModal'
import OAddNonVisitEventModal from './OAddNonVisitEventModal'
import OAddPriorAuthorizationModal from './OAddPriorAuthorizationModal'
import OIneligibleNoteModal from './OIneligibleNoteModal'
import OPatientAppIFrame from './OPatientAppIFrame'
import { ActionModal, CalendarModalType, usePatient } from './PPatientContext'
import RemoveFromInsurancePayModal from './RemoveFromInsurancePayModal'
import { ScheduleVisitDrawer } from './ScheduleVisitDrawer'
import SendPatientReferralLinkModal from './SendPatientReferralLinkModal'
import OUploadFileModal from './files/OUploadFileModal'
import { PaymentPlanDrawer } from './invoices/PaymentPlanDrawer'
import DrugScreenDrawer from './labs/DrugScreenDrawer'
import LabOrderDrawer from './labs/LabOrderDrawer'
import OPharmacyModal from './prescriptions/OPharmacyModal'
import { DelaySchedulingModal } from './visits/DelaySchedulingModal'
import OReengagementNoteModal from './visits/OReengagementNoteModal'
import { CarePathwayModal, useCarePathway } from './visits/carePathways'

const OActionModals = () => {
  const { patientQuery, daysSinceLastVisitWithPcQuery, modal, setModal } = usePatient()
  const { currentUser } = useAuth()
  const carePathway = useCarePathway()
  const { visitHoldsV2, udsCheckInSchedulingV2 } = useFlags()
  const { canScheduleVisitHolds } = useAccess()

  const patient = patientQuery?.data

  if (!patient) {
    return null
  }

  const onClose = () => setModal(null)

  /**
   * Get the action modal to display based on the current modal state.
   * If the modal type is scheduling-flow, it will return the appropriate action modal based on the visit type and the user's role.
   */
  const getActionModal = ():
    | CalendarModalType
    | { type: ActionModal; props?: undefined }
    | null => {
    if (modal === null) {
      return null
    }

    if (modal?.type !== 'scheduling-flow') {
      return modal
    }

    // Scheduling flow modals.
    if (modal.visitType === 'Follow-Up Visit') {
      // When selecting a follow up visit and care pathways enabled, show the care pathway modal.
      if (carePathway.isEnabled) {
        /**
         * Do not show the care pathway modal if scheduling the first follow up visit.
         * The care pathway can only be determined after the first follow up visit, at
         * which point the clinician will either move the PT to stable care (ie monthly)
         * or move to induction or intermediate care.
         */
        if (carePathway?.currentVisitType === 'Initial Visit') {
          return {
            type: 'schedule-visit-calendar',
          }
        }

        return {
          type: 'care-pathway-modal',
        }
      }

      // Visit holds require a level of care to be selected, so that's shown first
      if (visitHoldsV2 && canScheduleVisitHolds) {
        return { type: 'level-of-care' }
      }
    }

    /*
     * Enrollment coordinators will see the schedule visit modal for intake scheduling.
     * it is also used for scheduling UDS visits and check-in calls without having to select
     * a specific clinician
     */
    const isUdsCheckInSchedulingV2Enabled =
      (modal.visitType?.includes('UDS Visit') || modal.visitType?.includes('Check-In Call')) &&
      udsCheckInSchedulingV2
    const isClinicianAgnosticScheduling =
      hasGroupRole(currentUser, 'enrollmentCoordinator') || isUdsCheckInSchedulingV2Enabled
    if (isClinicianAgnosticScheduling) {
      return {
        type: 'schedule-visit',
        props: {
          visitTypesAllowed: modal.visitType as AppointmentTypeString[],
        },
      }
    }

    let visitTypesAllowed: AppointmentTypeString[] | undefined
    if (!modal.visitType) {
      visitTypesAllowed = undefined
    } else if (Array.isArray(modal.visitType)) {
      visitTypesAllowed = modal.visitType
    } else {
      visitTypesAllowed = [modal.visitType]
    }

    // Everyone else will see the schedule visit calendar modal.
    return {
      type: 'schedule-visit-calendar',
      props: {
        visitTypesAllowed,
      },
    }
  }

  const actionModal = getActionModal()

  return (
    <>
      {actionModal?.type === 'add-non-visit-event' && (
        <OAddNonVisitEventModal patient={patient} closeModal={onClose} />
      )}
      {actionModal?.type === 'add-prior-authorization' && (
        <TModal closeModal={onClose} width='sm:max-w-xl' padding='px-6 pb-4' closeButton={false}>
          <OAddPriorAuthorizationModal patientID={patient.oid} closeModal={onClose} />
        </TModal>
      )}
      {actionModal?.type === 'schedule-visit' && (
        <OScheduleVisitModal
          patientId={patient.oid}
          closeModal={onClose}
          daysSinceLastVisitWithPc={daysSinceLastVisitWithPcQuery?.data}
          {...actionModal.props}
        />
      )}
      <LevelOfCareModal
        opened={actionModal?.type === 'level-of-care'}
        onClose={onClose}
        onSubmit={levelOfCare => {
          if (levelOfCare === 'monthly' && hasStatus(patient, 'in treatment')) {
            return setModal({ type: 'schedule-visit-hold' })
          }

          setModal({ type: 'schedule-visit-calendar' })
        }}
      />
      <CarePathwayModal opened={actionModal?.type === 'care-pathway-modal'} onClose={onClose} />
      <ScheduleVisitDrawer
        opened={actionModal?.type === 'schedule-visit-calendar'}
        onClose={onClose}
        {...actionModal?.props}
      />
      {actionModal?.type === 'add-ineligible-note' && patient && (
        <TModal
          closeModal={onClose}
          width='sm:max-w-4xl'
          height='h-5/6'
          padding='px-6 pb-4'
          closeButton={false}
        >
          <OIneligibleNoteModal closeModal={onClose} patientID={patient.oid} />
        </TModal>
      )}
      {actionModal?.type === 'add-reengagement-note' && patient && (
        <TModal
          closeModal={onClose}
          width='sm:max-w-4xl'
          height='h-5/6'
          padding='px-6 pb-4'
          closeButton={false}
        >
          <OReengagementNoteModal closeModal={onClose} patient={patient} patientID={patient.oid} />
        </TModal>
      )}
      {actionModal?.type === 'record-drug-screen' && (
        <DrugScreenDrawer patient={patient} opened={Boolean(patient)} onClose={onClose} />
      )}
      {actionModal?.type === 'upload-file' && (
        <TModal closeModal={onClose}>
          <OUploadFileModal closeModal={onClose} />
        </TModal>
      )}
      {actionModal?.type === 'delay-subscription' && (
        <AddSubscriptionStartDateModal closeModal={onClose} />
      )}
      {actionModal?.type === 'insurance-allowlist-remove' && (
        <RemoveFromInsurancePayModal
          closeModal={onClose}
          userId={patient.oid}
          firstName={patient.personalData.firstName ?? ''}
        />
      )}
      {actionModal?.type === 'insurance-allowlist-add' && (
        <AddToInsurancePayModal
          closeModal={onClose}
          userId={patient.oid}
          firstName={patient.personalData.firstName ?? ''}
        />
      )}
      {actionModal?.type === 'open-patient-app-iframe' && (
        <OPatientAppIFrame patient={patient} closeModal={onClose} />
      )}
      {actionModal?.type === 'send-patient-referral-link' && (
        <SendPatientReferralLinkModal patient={patient} closeModal={onClose} />
      )}
      {actionModal?.type === 'create-call-me-now-welcome-call' && (
        <CreateCallMeNowWelcomeCallModal patient={patient} closeModal={onClose} />
      )}
      {actionModal?.type === 'export-visit-notes' && (
        <BulkExportVisitNotesModal patient={patient} closeModal={onClose} />
      )}
      <OPharmacyModal
        patient={patient}
        closeModal={onClose}
        opened={actionModal?.type === 'set-pharmacy'}
      />
      {actionModal?.type === 'create-dosespot-patient' && (
        <CreateDoseSpotPatientModal closeModal={onClose} />
      )}
      {actionModal?.type === 'add-to-insurance-queue' && <IRQRequirementsModal close={onClose} />}
      <TimeTrackingDrawer
        opened={actionModal?.type === 'time-tracking'}
        patientId={patient?.oid ?? ''}
        onClose={onClose}
      />
      <DrugScreenDrawer
        patient={patient}
        opened={actionModal?.type === 'add-drug-screen'}
        onClose={onClose}
      />
      <LabOrderDrawer
        patient={patient}
        opened={actionModal?.type === 'order-uds-cup'}
        onClose={onClose}
      />
      <PaymentPlanDrawer opened={actionModal?.type === 'create-payment-plan'} onClose={onClose} />
      <DelaySchedulingModal opened={actionModal?.type === 'delay-scheduling'} onClose={onClose} />
      <ScheduleVisitHoldDrawer
        opened={actionModal?.type === 'schedule-visit-hold'}
        onClose={onClose}
      />
    </>
  )
}

export default OActionModals
