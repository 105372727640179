import { useForm, UseFormReturnType } from '@mantine/form'
import { GetInputProps, SetValues } from '@mantine/form/lib/types'
import {
  DaysSelector,
  Divider,
  Group,
  Radio,
  RadioGroup,
  Stack,
  TertiaryButton,
  Text,
  validateWith,
} from '@shared/components'
import { DAYS as ALL_WEEK_DAYS, BUSINESS_DAYS, DayOfWeek, ValueOf } from '@shared/types'
import isEqual from 'lodash/isEqual'
import { isRequired } from '../formValidation'
import { useLunaMutation } from './use-luna-mutation'
import { useLunaQuery } from './use-luna-query'

const AVAILABILITY_TYPE = {
  AllWeek: 'all_week' as const,
  SpecificTimes: 'specific_times' as const,
}

type Availability = {
  morning: DayOfWeek[]
  afternoon: DayOfWeek[]
  evening: DayOfWeek[]
}

const INITIAL_AVAILABILITY: Availability = {
  morning: [],
  afternoon: [],
  evening: [],
}

export const ALL_WEEK_AVAILABILITY: Availability = {
  morning: ALL_WEEK_DAYS,
  afternoon: ALL_WEEK_DAYS,
  evening: BUSINESS_DAYS,
}

type AvailabilityType = ValueOf<typeof AVAILABILITY_TYPE>

export const AvailabilitySelector = (form: {
  values: Availability
  setValues: SetValues<Availability>
  getInputProps: GetInputProps<Availability>
}) => {
  const isAllMorningsSelected = isEqual(form.values.morning, ALL_WEEK_DAYS)
  const isAllAfternoonsSelected = isEqual(form.values.afternoon, ALL_WEEK_DAYS)
  const isAllEveningsSelected = isEqual(form.values.evening, BUSINESS_DAYS)

  return (
    <>
      <Stack pt='md'>
        {/* MORNINGS */}
        <Group position='apart'>
          <Group spacing='sm'>
            <Text bold>MORNINGS</Text>
            <Text color={c => c.background[5]}>8am-Noon</Text>
          </Group>
          <TertiaryButton
            onClick={() => {
              form.setValues({
                morning: isAllMorningsSelected ? [] : ALL_WEEK_DAYS,
              })
            }}
          >
            {isAllMorningsSelected ? 'De-select all' : 'Select all'}
          </TertiaryButton>
        </Group>
        <DaysSelector {...form.getInputProps('morning')} />
      </Stack>
      <Divider />
      {/* AFTERNOONS */}
      <Stack>
        <Group position='apart'>
          <Group spacing='sm'>
            <Text bold>AFTERNOONS</Text>
            <Text color={c => c.background[5]}>Noon-5pm</Text>
          </Group>
          <TertiaryButton
            onClick={() => {
              form.setValues({
                afternoon: isAllAfternoonsSelected ? [] : ALL_WEEK_DAYS,
              })
            }}
          >
            {isAllAfternoonsSelected ? 'De-select all' : 'Select all'}
          </TertiaryButton>
        </Group>
        <DaysSelector {...form.getInputProps('afternoon')} />
      </Stack>
      <Divider />
      {/* EVENINGS */}
      <Stack>
        <Group position='apart'>
          <Group spacing='sm'>
            <Text bold>EVENINGS</Text>
            <Text color={c => c.background[5]}>5-9pm</Text>
          </Group>
          <TertiaryButton
            onClick={() => {
              form.setValues({
                evening: isAllEveningsSelected ? [] : BUSINESS_DAYS,
              })
            }}
          >
            {isAllEveningsSelected ? 'De-select all' : 'Select all'}
          </TertiaryButton>
        </Group>
        <DaysSelector {...form.getInputProps('evening')} disabledDays={['saturday', 'sunday']} />
      </Stack>
    </>
  )
}

export const PatientAvailabilityForm = ({
  form,
}: {
  form: UseFormReturnType<Availability & { availabilityType: AvailabilityType | null }>
}) => {
  return (
    <Stack spacing='sm'>
      <RadioGroup {...form.getInputProps('availabilityType')}>
        <Radio
          test-id='availability:all-week'
          value={AVAILABILITY_TYPE.AllWeek}
          label='Anytime in the week'
        />
        <Radio value={AVAILABILITY_TYPE.SpecificTimes} label='Select days and times' />
      </RadioGroup>
      {form.values.availabilityType === AVAILABILITY_TYPE.SpecificTimes && (
        <AvailabilitySelector
          values={form.values}
          setValues={form.setValues}
          getInputProps={form.getInputProps}
        />
      )}
    </Stack>
  )
}

export const usePatientAvailability = ({ patientId }: { patientId: string }) => {
  const form = useForm<Availability & { availabilityType: AvailabilityType | null }>({
    initialValues: {
      availabilityType: null,
      ...INITIAL_AVAILABILITY,
    },
    validate: {
      availabilityType: validateWith(isRequired),
    },
  })

  const hasAvailability =
    form.values.availabilityType === AVAILABILITY_TYPE.AllWeek ||
    form.values.morning.length > 0 ||
    form.values.afternoon.length > 0 ||
    form.values.evening.length > 0

  useLunaQuery(
    'GET /patients/:patientId/settings/availability',
    {
      params: {
        patientId,
      },
    },
    {
      enabled: Boolean(patientId),
      // Seed the form with the patient's availability data
      onSuccess: data => {
        const availabilityData = data?.data?.availability

        // No need to seed the form if the patient has no availability set previously
        if (!availabilityData) {
          return
        }

        // If the patient has all-week availability, we don't need to set the specific times
        const availabilityType = isEqual(availabilityData, ALL_WEEK_AVAILABILITY)
          ? AVAILABILITY_TYPE.AllWeek
          : AVAILABILITY_TYPE.SpecificTimes

        form.setValues({
          availabilityType,
          ...availabilityData,
        })
      },
    },
  )

  const availabilityMutation = useLunaMutation('POST /patients/:patientId/settings/availability')

  const onSubmit = () => {
    // If form has not been updated, don't submit
    if (!form.isDirty) {
      return
    }

    // If the form is not valid, don't submit
    if (!form.isValid) {
      return
    }

    // If the form has no availability set, don't submit
    if (!hasAvailability) {
      return
    }

    const availability =
      form.values.availabilityType === AVAILABILITY_TYPE.AllWeek
        ? ALL_WEEK_AVAILABILITY
        : {
            morning: form.values.morning,
            afternoon: form.values.afternoon,
            evening: form.values.evening,
          }

    availabilityMutation.mutate({
      params: {
        patientId,
      },
      data: {
        availability,
      },
    })
  }

  return {
    patientAvailabilityForm: form,
    submitPatientAvailability: onSubmit,
  }
}
