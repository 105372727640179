import { Patient, hasHomeState } from '@shared/types'
import { isTruthy } from '@shared/utils'
import { useFlags } from './use-flags'
import { usePatientInsurances } from './use-patient-insurances'

export const usePatientPAMedicaidCoe = (patient?: Patient) => {
  const isInPA = hasHomeState(patient, 'Pennsylvania')

  const { data: patientInsurancesData } = usePatientInsurances(patient) || {}
  const { primaryInsurance, secondaryInsurance } = patientInsurancesData || {}

  const patientInsurances = [primaryInsurance, secondaryInsurance].filter(isTruthy)
  const isMedicaid = patientInsurances.some(
    insurance => insurance?.basicInsuranceData?.planType === 'MC',
  )

  const { paCoe } = useFlags()

  const isPAMedicaidCoe = Boolean(isInPA && isMedicaid && paCoe)

  return {
    isInPA,
    isMedicaid,
    paCoe,
    isPAMedicaidCoe,
  }
}
