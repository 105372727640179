import { GlobeIcon, Group, Pill, TertiaryButton, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import pluralize from 'pluralize'
import { useState } from 'react'
import { usePatient } from '../../patient/PPatientContext'
import { getNumberOfWeeksOut } from './CalendarHeader'
import { CalendarHoldsDrawer } from './CalendarHoldsDrawer'
import { getDisplayDate } from './DateNavigator'
import { useCalendarHolds } from './use-calendar-holds'

export type InfoBarProps = {
  date: string
  calendarId?: string
  visitCount: number
}

export const InfoBar = ({ date, calendarId, visitCount }: InfoBarProps) => {
  const [isCalendarHoldsOpened, setCalendarHoldsOpened] = useState(false)
  // This will only work when in the drawer, but that's fine
  const { patientQuery } = usePatient()
  const patientTimezone = patientQuery?.data?.account?.timezone

  const dateLabel = calendarId
    ? `Week of ${getDisplayDate(date)} ${getNumberOfWeeksOut(date)}`
    : `${dayjs(date).format('dddd, MMMM D, YYYY')} ${getNumberOfWeeksOut(date)}`

  const timezone = dayjs().tz(dayjs.tz.guess()).format('z')

  const { holds, isEnabled, refetchHolds } = useCalendarHolds({
    calendarId,
    // Monday through Sunday
    start: dayjs(date).startOf('week').add(1, 'day').format('YYYY-MM-DD'),
    end: dayjs(date).endOf('week').add(1, 'day').format('YYYY-MM-DD'),
  })

  return (
    <>
      <CalendarHoldsDrawer
        holds={holds}
        opened={isCalendarHoldsOpened}
        onClose={() => setCalendarHoldsOpened(false)}
        refetchHolds={() => refetchHolds()}
      />
      <Group>
        <Group spacing='xs'>
          <Text test-id='label:calendar-date'>{dateLabel}</Text>
          <Text>|</Text>
          <Text test-id='label:calendar-visit-count'>
            {visitCount} {pluralize('visit', visitCount)}
          </Text>
          {isEnabled && (
            <>
              <Text>|</Text>
              {holds.length > 0 ? (
                <TertiaryButton onClick={() => setCalendarHoldsOpened(true)}>
                  {`${holds.length} ${pluralize('visit', holds.length)} on hold`}
                </TertiaryButton>
              ) : (
                <Text>0 visits on hold</Text>
              )}
            </>
          )}
        </Group>
        <Group spacing='xs'>
          <GlobeIcon color={colors => colors.text[1]} />
          <Text color={colors => colors.text[1]}>{timezone}</Text>
        </Group>
        {patientTimezone && (
          <Pill variant='filled' status='warning'>
            Patient time zone: {dayjs().tz(patientTimezone).format('z')}
          </Pill>
        )}
      </Group>
    </>
  )
}
