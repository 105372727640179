import { useToggle } from '@mantine/hooks'
import {
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Group,
  SecondaryButton,
  Select,
  Stack,
  Table,
  TitleFour,
  TitleTwo,
} from '@shared/components'
import { OcpRecurring, hasGroupRole } from '@shared/types'
import { addDays, differenceInWeeks, format, startOfWeek } from 'date-fns'
import range from 'lodash/range'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { employeesApi, emrApi, ocpApi } from '../../../api'
import AFakeOcpScheduleRow from '../../../components/atoms/fakes/AFakeOcpScheduleRow'
import { Page } from '../../../components/templates/TDefault'
import { useAuth } from '../../../context/auth'
import { useFlags } from '../../../utils/hooks'
import AdminHeaderContent from '../AdminHeaderContentProps'
import { AdminPage } from '../PAdmin'
import CreateStateDrawer from './CreateStateDrawer'
import EditStateDrawer from './EditStateDrawer'
import ScheduleTableHeader from './ScheduleTableHeader'
import ScheduleTableRow from './ScheduleTableRow'

// The dates correspond to days of the week, so it's always a tuple of 7 dates
export type WeekDateTuple = [Date, Date, Date, Date, Date, Date, Date]

const OnCallScheduleContent = () => {
  const { canUpdateCCM } = useFlags()
  const { currentUser } = useAuth()
  const userCanEdit = hasGroupRole(currentUser, 'admin') || Boolean(canUpdateCCM)

  const [createState, toggleCreateState] = useToggle()
  const [stateToEdit, editStateSchedule] = useState<OcpRecurring | undefined>()

  const [datesToDisplay, setDatesToDisplay] = useState<WeekDateTuple>(
    range(0, 7).map((i: number) =>
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), i),
    ) as WeekDateTuple,
  )
  const handleToggleWeek = (weeksToAdd: number) => {
    setDatesToDisplay(
      datesToDisplay =>
        range(0, 7).map((i: number) =>
          addDays(addDays(datesToDisplay[0], weeksToAdd * 7), i),
        ) as WeekDateTuple,
    )
  }

  const monthsToDisplay = (dates: WeekDateTuple) => {
    const datesIncludeMultipleMonths = dates[0].getMonth() !== dates[6].getMonth()
    const datesIncludeMultipleYears = dates[0].getFullYear() !== dates[6].getFullYear()
    if (datesIncludeMultipleMonths && datesIncludeMultipleYears) {
      return `${format(dates[0], 'MMM y')} — ${format(dates[6], 'MMM y')}`
    } else if (datesIncludeMultipleMonths) {
      return `${format(dates[0], 'MMM')} — ${format(dates[6], 'MMM y')}`
    }
    return format(dates[0], 'MMM y')
  }

  // Fetch schedule data
  const scheduleData = useQuery(['ocpApi.getRecurring'], () => ocpApi.getRecurring())

  const [selectedState, setSelectedState] = useState<string | null>('All states')
  const selectStateOptions =
    scheduleData.data?.map((stateData: OcpRecurring) => stateData.state) || []

  // Fetch override data
  const [overrideDataKey, overrideDataFn] = emrApi.getQuery('GET /ocp/override', {
    query: {
      date: datesToDisplay[0].toDateString(),
    },
  })
  const overrideData = useQuery(overrideDataKey, overrideDataFn)

  // Fetch active employees who can be on call providers
  const onCallProviders = useQuery(['employeesApi.ocpClinicians'], () =>
    employeesApi.ocpClinicians(),
  )

  // Filter scheduleData if a date is selected
  const scheduleDataToDisplay =
    scheduleData.data && selectedState !== 'All states'
      ? scheduleData.data?.filter(data => {
          return data.state === selectedState
        })
      : scheduleData.data

  return (
    <Page
      title='Admin'
      headerContent={<AdminHeaderContent selectedPage={AdminPage.Schedule} />}
      pushHeaderContent={false}
    >
      <CreateStateDrawer
        isOpen={createState}
        existingStates={selectStateOptions}
        onClose={() => toggleCreateState(false)}
      ></CreateStateDrawer>
      {stateToEdit && (
        <EditStateDrawer
          isOpen={stateToEdit !== undefined}
          schedule={stateToEdit}
          onClose={() => editStateSchedule(undefined)}
        ></EditStateDrawer>
      )}

      <Box
        sx={({ other: { sizes } }) => ({
          padding: sizes.gap.xl,
        })}
      >
        <Stack>
          <TitleTwo>On call provider schedule</TitleTwo>
          <Group position='apart'>
            <Group>
              <Select
                data={['All states', ...selectStateOptions]}
                value={selectedState}
                onChange={setSelectedState}
                placeholder='All states'
              />
              {userCanEdit && (
                <SecondaryButton onClick={() => toggleCreateState()}>Add new state</SecondaryButton>
              )}
            </Group>
            <Group>
              <SecondaryButton
                onClick={() =>
                  handleToggleWeek(
                    differenceInWeeks(
                      startOfWeek(new Date(), { weekStartsOn: 1 }),
                      datesToDisplay[0],
                    ),
                  )
                }
              >
                Today
              </SecondaryButton>
              <TitleFour>{monthsToDisplay(datesToDisplay)}</TitleFour>
              <Group spacing={0}>
                <SecondaryButton
                  onClick={() => handleToggleWeek(-1)}
                  leftIcon={<ChevronLeftIcon />}
                />
                <SecondaryButton
                  onClick={() => handleToggleWeek(1)}
                  leftIcon={<ChevronRightIcon />}
                />
              </Group>
            </Group>
          </Group>
          <Table sx={{ tableLayout: 'fixed', textAlign: 'center' }}>
            <ScheduleTableHeader dates={datesToDisplay} />
            <tbody>
              {scheduleData.isSuccess &&
                overrideData.isSuccess &&
                scheduleDataToDisplay?.map((stateData: OcpRecurring) => {
                  return (
                    <ScheduleTableRow
                      key={stateData.oid}
                      stateData={stateData}
                      overrideData={overrideData}
                      dates={datesToDisplay}
                      onCallProviders={onCallProviders.data || []}
                      editState={() => editStateSchedule(stateData)}
                      userCanEdit={userCanEdit}
                    />
                  )
                })}
              {(!scheduleData.isSuccess || !overrideData.isSuccess) &&
                range(0, 14).map(i => <AFakeOcpScheduleRow key={i} />)}
            </tbody>
          </Table>
        </Stack>
      </Box>
    </Page>
  )
}

export default OnCallScheduleContent
