import {
  LoadingContent,
  LoadingContentSize,
  LoadingContentType,
  LoadingParagraph,
} from './LoadingContent'

const LoadingInsuranceQueueListItem = () => {
  return (
    <li className='relative pl-4 pr-6 py-2'>
      <div className='flex justify-between'>
        <div className='w-full pr-8'>
          <LoadingContent
            type={LoadingContentType.Line}
            size={LoadingContentSize.Medium}
          ></LoadingContent>
          <div className='w-48 my-4 flex justify-around'>
            <LoadingContent
              type={LoadingContentType.Icon}
              size={LoadingContentSize.Small}
            ></LoadingContent>
            <LoadingContent
              type={LoadingContentType.Icon}
              size={LoadingContentSize.Small}
            ></LoadingContent>
            <LoadingContent
              type={LoadingContentType.Icon}
              size={LoadingContentSize.Small}
            ></LoadingContent>
            <LoadingContent
              type={LoadingContentType.Icon}
              size={LoadingContentSize.Small}
            ></LoadingContent>
          </div>
        </div>
        <div className='mt-2'>
          <LoadingContent type={LoadingContentType.Pill}></LoadingContent>
        </div>
      </div>
    </li>
  )
}

const LoadingEncountersListItem = () => {
  return (
    <li className='relative pl-4 pr-6 py-2'>
      <div className='flex justify-between'>
        <div className='w-full pr-8 flex flex-col justify-between h-18'>
          <LoadingContent
            type={LoadingContentType.Line}
            size={LoadingContentSize.Medium}
          ></LoadingContent>
          <LoadingContent
            type={LoadingContentType.Line}
            size={LoadingContentSize.Small}
          ></LoadingContent>
        </div>
        <div className='mt-2'>
          <LoadingContent type={LoadingContentType.Pill}></LoadingContent>
        </div>
      </div>
    </li>
  )
}

export const LoadingInsuranceQueueList = ({ length }: { length: number }) => {
  return (
    <ul className='relative z-0 divide-y divide-gray-200 border-gray-200 mb-6 col-span-2'>
      {[...Array(length)].map((_, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          <LoadingInsuranceQueueListItem></LoadingInsuranceQueueListItem>
        </div>
      ))}
    </ul>
  )
}

export const LoadingEncountersList = ({ length }: { length: number }) => {
  return (
    <ul className='relative z-0 divide-y divide-gray-200 border-gray-200 mb-6 col-span-2'>
      {[...Array(length)].map((e, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          <LoadingEncountersListItem></LoadingEncountersListItem>
        </div>
      ))}
    </ul>
  )
}

export const LoadingListCardLayout = ({ cards }: { cards: number }) => {
  return (
    <div className='bg-white rounded-md shadow-md border-b border-gray-200 relative z-0 w-full'>
      <div className='relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6 '>
        {[...Array(cards)].map((e, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <LoadingContent
              type={LoadingContentType.Line}
              size={LoadingContentSize.Medium}
            ></LoadingContent>
            <LoadingParagraph wordCount={3} sizes={[LoadingContentSize.Large]}></LoadingParagraph>
            <LoadingParagraph wordCount={3} sizes={[LoadingContentSize.Large]}></LoadingParagraph>
          </div>
        ))}
      </div>
    </div>
  )
}
