import { Grid } from '@shared/components'
import { FullWorkflowState } from '@shared/types'
import { Prompt } from './Prompt'

export const Workflow = ({ workflowState }: { workflowState: FullWorkflowState }) => {
  return (
    <Grid columns={12}>
      {workflowState.promptsAndResponses.map(item => (
        <Prompt prompt={item.prompt} key={item.prompt.oid} />
      ))}
    </Grid>
  )
}
