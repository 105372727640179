import {
  Card,
  CheckCircleIcon,
  Divider,
  EditIcon,
  Flex,
  Group,
  Pill,
  SlashIcon,
  Stack,
  TertiaryButton,
  Text,
  useMantineTheme,
} from '@shared/components'
import {
  DispenseUnitType,
  DoseSpotPrescription,
  Employee,
  Patient,
  getOpheliaHttpError,
} from '@shared/types'
import { dayjs, phone } from '@shared/utils'
import React from 'react'
import { useQuery } from 'react-query'
import { employeesApi } from '../../../api'
import IconWarning from '../../../components/icons/IconWarning'
import { useGetPharmacy } from '../../../utils/hooks/use-get-pharmacy'

type PrescriptionPreview = Pick<
  DoseSpotPrescription,
  | 'DisplayName'
  | 'Strength'
  | 'Quantity'
  | 'DaysSupply'
  | 'NoSubstitutions'
  | 'Directions'
  | 'PharmacyNotes'
  | 'NonDoseSpotPrescriptionId'
  | 'EffectiveDate'
> &
  Partial<DoseSpotPrescription>

export type MPrescriptionPreviewProps = {
  employee?: Employee
  patient?: Patient
  prescription: PrescriptionPreview
  followUpScheduled?: boolean
  pdmpReviewed?: boolean
  showPriorAuthWarning?: boolean
  onEdit?: () => void
}

export const TableElement = ({
  noBorder = false,
  value,
  children,
}: {
  noBorder?: boolean
  value?: string | number
  children: React.ReactNode
}) => (
  <div className={`${!noBorder && 'border-b border-gray-300'} py-2 flex`}>
    <Text className={`pl-2 ${value && 'w-32'}`}>{children}</Text>
    <Text>{value}</Text>
  </div>
)

const calculateAge = (birthday: string | undefined) => {
  // calculate the age of the patient based on the patient's birthday and today's date
  if (birthday) {
    const birthDate = dayjs(birthday, 'MM/DD/YYYY')
    const today = dayjs()
    return today.diff(birthDate, 'years')
  }
}

const MPrescriptionPreview = ({
  employee,
  patient,
  prescription,
  showPriorAuthWarning,
  onEdit,
}: MPrescriptionPreviewProps) => {
  const { getPharmacyQuery } = useGetPharmacy({
    pharmacyId: prescription.PharmacyId,
    /*
     * @dosespotV2Migration
     * Need to send the patientId so that we know whether to use the v1 or v2 API
     */
    patientId: patient?.oid || '',
  })

  const queuedBy = useQuery(
    ['employeesApi.getByDoseSpotId', prescription.PrescriberAgentId],
    () => employeesApi.getByDoseSpotId((prescription?.PrescriberAgentId ?? '').toString()),
    { enabled: Boolean(prescription.PrescriberAgentId) },
  )

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  // Find the employee's current DEA registration number. We need to display that number in the prescription preview drawer.
  const activeDeaNumber = (employee as Employee<'clinician'>)?.providerInfo?.deaRegistrations
    .filter(d => d.results.active)
    .filter(d => dayjs(d.results.expirationDate).isAfter(dayjs()))
    .map(d => d.registrationNumber)
    .at(0)

  return (
    <Stack>
      <Flex gap='lg'>
        <Flex sx={{ flex: 1 }}>
          {getPharmacyQuery.isFetching && <Text>Loading pharmacy information...</Text>}
          {!getPharmacyQuery.isFetching && getPharmacyQuery.isError && (
            <Text size='xs' color={colors => colors.error[0]}>
              {getOpheliaHttpError(getPharmacyQuery.error, 'Error loading pharmacy details.')}
            </Text>
          )}
          {!getPharmacyQuery.isFetching && getPharmacyQuery.data && (
            <Stack spacing='sm'>
              <Text color={colors.text[1]} size='xs' bold>
                Pharmacy
              </Text>
              <Text bold>{getPharmacyQuery.data.StoreName}</Text>
              <Stack spacing={0}>
                <Text>{getPharmacyQuery.data.Address1}</Text>
                {getPharmacyQuery.data.Address2 && <Text>{getPharmacyQuery.data.Address2}</Text>}
                <Text>
                  {getPharmacyQuery.data.City}, {getPharmacyQuery.data.State}{' '}
                  {getPharmacyQuery.data.ZipCode}
                </Text>
                <Text>{phone(getPharmacyQuery.data.PrimaryPhone).formatted}</Text>
              </Stack>
            </Stack>
          )}
          {/* NOTE - below is rendered when a pharmacy with the ID provided isn't found via dosespot.
            This occurs when a pharmacy in dosespot's system has been deleted.*/}
          {!getPharmacyQuery.isFetching && !getPharmacyQuery.data && prescription.pharmacy && (
            <Stack spacing='sm'>
              <Text color={colors.text[1]} size='xs' bold>
                Pharmacy
              </Text>
              <Text bold>{prescription.pharmacy.name}</Text>
              <Stack spacing={0}>
                <Text>{prescription.pharmacy.address}</Text>
                <Text>
                  {prescription.pharmacy.city}, {prescription.pharmacy.state}{' '}
                  {prescription.pharmacy.zip}
                </Text>
                <Text>{phone(prescription.pharmacy.phone).formatted}</Text>
              </Stack>
            </Stack>
          )}
        </Flex>
        <Flex sx={{ flex: 1 }}>
          <Stack spacing='sm'>
            <Text color={colors.text[1]} size='xs' bold>
              Prescribing clinician
            </Text>
            {employee ? (
              <Stack spacing='sm'>
                <Text bold>{employee.name}</Text>
                <Stack spacing={0}>
                  {activeDeaNumber && <Text>DEA# {activeDeaNumber}</Text>}
                  {/* We need to display the clinician's address to pass our biannual EPCS audit.
                  It's difficult to get the clinic address for the state that the prescription is being prescribed in,
                  and we don't want to show the clinician's personal address, so we're just going to show the Ophelia
                  main address. */}
                  <Text>228 Park Ave S, Suite 15314</Text>
                  <Text>New York, NY 10003</Text>
                  <Text>{phone(employee.phone).formatted}</Text>
                </Stack>
              </Stack>
            ) : (
              <>
                <IconWarning />
                Couldn&apos;t find employee information
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
      <Card
        styles={{
          backgroundColor: colors.background[1],
          borderColor: colors.background[3],
        }}
      >
        <Stack spacing='sm'>
          {showPriorAuthWarning && (
            <Pill status='warning' variant='filled'>
              Requires PA
            </Pill>
          )}
          <Group position='apart'>
            <Text bold>
              {`${patient?.personalData.lastName}, ${patient?.personalData.firstName} (${patient?.personalData.sex})`}
            </Text>
            {onEdit && (
              <TertiaryButton onClick={onEdit} leftIcon={<EditIcon styled />}>
                Edit
              </TertiaryButton>
            )}
          </Group>
          <div>
            <Text>{patient?.shippingData?.address}</Text>
            <Text>{`${patient?.shippingData?.city}, ${patient?.shippingData?.state} ${patient?.shippingData?.zip}`}</Text>
            <Text>{phone(patient?.personalData.phone).formatted}</Text>
            <Text>
              Date of birth:{' '}
              {`${patient?.personalData.birthday} (${calculateAge(
                patient?.personalData?.birthday,
              )} yo)`}
            </Text>
          </div>
          <Divider
            sx={{
              size: sizes.border.md,
            }}
          />
          <Group spacing='sm'>
            {prescription.NonDoseSpotPrescriptionId === 'bridge' && (
              <Pill variant='filled' status='warning'>
                Bridge
              </Pill>
            )}
            {queuedBy?.data?.name && (
              <Pill variant='filled' status='warning'>
                {`Queued by ${queuedBy.data.name}`}
              </Pill>
            )}
          </Group>
          <Text>
            {new Date(prescription.EffectiveDate ?? prescription.WrittenDate).toLocaleDateString()}
          </Text>
          <Text bold>{prescription.DisplayName}</Text>
          <div className='border border-gray-300 rounded-md'>
            <TableElement value={prescription.Strength}>Strength</TableElement>
            <TableElement value={prescription.Directions}>Directions</TableElement>
            <TableElement
              value={`${prescription.Quantity} (${
                prescription.DispenseUnitId
                  ? DispenseUnitType[prescription.DispenseUnitId]
                  : 'units'
              })`}
            >
              Dispense quantity
            </TableElement>
            <TableElement value={prescription.DaysSupply || 'N/A'}>Days supply</TableElement>
            <TableElement value={prescription.Refills || '0'} noBorder>
              Refills
            </TableElement>
          </div>
          {prescription.NoSubstitutions ? (
            <Group>
              <SlashIcon color={colors.error[0]} size='sm' />
              <Text bold>Do not allow substitution</Text>
            </Group>
          ) : (
            <Group spacing='sm'>
              <CheckCircleIcon color={colors.success[0]} size='sm' />
              <Text bold>Allow substitution</Text>
            </Group>
          )}
          {prescription.PharmacyNotes && (
            <Stack spacing='xs'>
              <Divider
                sx={{
                  size: sizes.border.md,
                }}
              />
              <Text bold>Pharmacy notes</Text>
              <Text>{prescription.PharmacyNotes}</Text>
            </Stack>
          )}
        </Stack>
      </Card>
    </Stack>
  )
}

export default MPrescriptionPreview
