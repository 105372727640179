import { dayjs } from '@shared/utils'
import { Navigate, Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import PVisitsByDate from './PVisitsByDate'
import PVisitsByProvider from './PVisitsByProvider'

export const DATE_PARAM = 'date'
export const useCalendarDate = (): [string, (date: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams({
    [DATE_PARAM]: dayjs().format('YYYY-MM-DD'),
  })

  const date = searchParams.get(DATE_PARAM) || dayjs().format('YYYY-MM-DD')
  const setDate = (date: string) => setSearchParams({ [DATE_PARAM]: date })
  return [date, setDate]
}

export const PCalendar = () => {
  const { currentUser } = useAuth()
  const { providerID } = useParams()

  const showAll = providerID === 'all'
  const element = showAll ? <PVisitsByDate /> : <PVisitsByProvider />

  if (!providerID) {
    return <Navigate replace to={`/visits/${currentUser.calendarId || 'all'}`} />
  }

  return (
    <Routes>
      <Route path='/' element={element} />
    </Routes>
  )
}
