import {
  RichTextEditor,
  RichTextEditorProps,
  Stack,
  Text,
  Textarea,
  TextareaProps,
} from '@shared/components'
import { useSmartPhrases } from '../../pages/employees/use-smart-phrases'
import * as FullStory from '../../utils/fullstory'
import { useFlags } from '../../utils/hooks'

export type VisitNoteTextareaProps = TextareaProps & { isEditing: boolean }

export const VisitNoteTextarea = ({
  isEditing,
  autosize = true,
  ...props
}: VisitNoteTextareaProps) => {
  const { smartPhrases, smartPhrasesRte } = useFlags()

  const { rteFormattedSmartPhrases, isLoading: isLoadingSmartPhrases } = useSmartPhrases()

  if (isEditing) {
    // If smart phrases are enabled, use the RichTextEditor
    if (smartPhrases) {
      /*
       * We're currently fixing a bug where the RichTextEditor doesn't preserve newlines, so we're
       * going to hide the RichTextEditor until the bug is fixed
       */
      if (smartPhrasesRte) {
        return (
          <RichTextEditor
            // We need to add a key so that the component re-renders when the smart phrases are updated
            key={`${isLoadingSmartPhrases}`}
            // 10rem is effectively 5 rows
            mih='10rem'
            // 64rem is effectively 32 rows
            mah='64rem'
            placeholder='Type here...'
            // Need to type cast to RichTextEditorProps
            {...(props as RichTextEditorProps)}
            plainText
            disabled={isLoadingSmartPhrases}
            smartPhrasesData={rteFormattedSmartPhrases}
            // Allow users to filter smart phrases by label and text
            smartPhraseValuesToFilterBy={['label', 'text']}
            onSmartPhraseSelected={(smartPhraseId: string) => {
              FullStory.event('Smart Phrase Used', {
                // Including the smartPhraseId in case we want to track how commonly smart phrases are used
                smartPhraseId,
              })
            }}
          />
        )
      }
    }

    // If smart phrases are not enabled, use the legacy input
    return <Textarea autosize={autosize} minRows={5} maxRows={32} {...props} />
  }

  return (
    <Stack spacing='xs' sx={{ overflowWrap: 'anywhere', whiteSpace: 'break-spaces' }}>
      {props.label && <Text color={colors => colors.text[1]}>{props.label}</Text>}
      <Text component='p'>{props.value}</Text>
    </Stack>
  )
}
