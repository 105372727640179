import { useClipboard } from '@mantine/hooks'
import { CopyIcon, Grid, Stack, TertiaryButton, Text, TitleFour } from '@shared/components'
import {
  EligibleInsuranceData,
  InsuranceData,
  InsuranceType,
  ManualInsuranceData,
  RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS,
} from '@shared/types'
import { name } from '@shared/utils'
import { useState } from 'react'

export type IRQItemPersonalProps = {
  insurance: InsuranceData
  eligibleData: EligibleInsuranceData | null
  manualData: ManualInsuranceData | null
  insuranceType: InsuranceType
}

const InfoItem = ({
  label,
  value,
  clickToCopy = false,
}: {
  label: string
  value?: string | null
  clickToCopy?: boolean
}) => {
  const { copy } = useClipboard()
  const [copied, setCopied] = useState(false)
  const copyOnClick = () => {
    copy(value)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }
  return (
    <>
      <Grid.Col span={4}>
        <Text>{label}</Text>
      </Grid.Col>
      <Grid.Col span={8}>
        {clickToCopy && value ? (
          <TertiaryButton onClick={copyOnClick} rightIcon={<CopyIcon />}>
            {copied ? 'Copied' : value}
          </TertiaryButton>
        ) : (
          <Text px='xs'> {value || '-'}</Text>
        )}
      </Grid.Col>
    </>
  )
}

const IRQItemPersonal = ({
  insurance,
  eligibleData,
  manualData,
  insuranceType,
}: IRQItemPersonalProps) => {
  const relationshipCode =
    eligibleData?.demographics?.dependent?.relationship_code ??
    manualData?.patient_relationship_to_subscriber_code

  const relationship =
    RELATIONSHIP_TO_SUBSCRIBER_CODE_OPTIONS.find(item => item.value === relationshipCode)?.label ||
    (insurance.patientIsPrimarySubscriber ? 'Self' : '')

  return (
    <Stack>
      <Grid maw='50%' gutter='sm'>
        <InfoItem label='Type' value={insuranceType === 'primary' ? 'Primary' : 'Secondary'} />
        <InfoItem label='Plan' value={insurance.provider} clickToCopy />
        <InfoItem label='Member' value={insurance.memberId} clickToCopy />
        <InfoItem label='Plan Type' value={insurance.planType} />
        <InfoItem label='Group #' value={insurance.groupId} />
        <InfoItem label='Rx bin' value={insurance.rxbin} />
        <InfoItem label='Rx PCN' value={insurance.rxPcn} />
        <InfoItem label='Rx group' value={insurance.rxGroup} />
      </Grid>
      <TitleFour>Subscriber</TitleFour>
      <Grid maw='50%' gutter='sm'>
        <InfoItem
          label='Name'
          value={name({
            first: insurance.patientIsPrimarySubscriber
              ? insurance.firstName
              : insurance.primarySubscriberFirstName,
            last: insurance.patientIsPrimarySubscriber
              ? insurance.lastName
              : insurance.primarySubscriberLastName,
          }).full()}
        />
        <InfoItem
          label='Birthdate'
          value={
            eligibleData?.demographics?.subscriber?.dob ??
            manualData?.birthday ??
            insurance?.primarySubscriberBirthday
          }
          clickToCopy
        />
        <InfoItem
          label='Gender'
          value={eligibleData?.demographics?.subscriber?.gender ?? manualData?.gender}
        />
        <InfoItem label='Relationship' value={relationship} />
      </Grid>
    </Stack>
  )
}

export default IRQItemPersonal
