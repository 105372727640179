import { useForm } from '@mantine/form'
import {
  BetterDrawer,
  Checkbox,
  DatePicker,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  SaveIcon,
  SearchIcon,
  Select,
  Stack,
  TextInput,
  showNotification,
} from '@shared/components'
import { StateAbbr, YYYYMMDD } from '@shared/types'
import { dayjs } from '@shared/utils'
import { isRequired } from '../../utils/formValidation'
import { useEmployees, useInvalidateLunaQuery, useLunaMutation } from '../../utils/hooks'
import { DropInClinic } from './ClinicPage'

const states: StateAbbr[] = ['NY', 'PA']

type AddClinicDrawerProps = {
  opened: boolean
  onClose: () => void
  /**
   * If a clinic gets passed in, we're editing it. If null, we're adding a new clinic.
   */
  clinic: DropInClinic | null
}

type ClinicForm = {
  clinicianId: string
  date: YYYYMMDD
  startTime: string
  endTime: string
  timezone: string
  zoomLink: string
  isActive: boolean
  states: StateAbbr[]
}

const DEFAULT_TIMEZONE = 'America/New_York'

/**
 * This util generates a list of times between
 * start and end hours with a given increment.
 */
const getTimes = () => {
  const START_HOUR = 8
  const END_HOUR = 18
  const INCREMENT_MINUTES = 30
  const startTime = dayjs().set('hour', START_HOUR).set('minute', 0)
  const endTime = dayjs().set('hour', END_HOUR).set('minute', 0)

  const startTimes = []
  const endTimes = []

  let currentTime = startTime.clone()
  while (currentTime.isSameOrBefore(endTime)) {
    startTimes.push(currentTime.format('h:mma'))
    endTimes.push(currentTime.format('h:mma'))
    currentTime = currentTime.add(INCREMENT_MINUTES, 'minutes')
  }

  return {
    startTimes,
    endTimes,
  }
}

/**
 * Convert the form data to an ISO string in the given timezone.
 * Useful for submitting to the server or for comparison.
 */
const getDatetime = (arg: { date: YYYYMMDD; time: string; timezone: string }) => {
  return dayjs.tz(`${arg.date} ${arg.time}`, 'YYYY-MM-DD h:mma', arg.timezone).toISOString()
}

const convertClinicToForm = (clinic: DropInClinic): ClinicForm => {
  return {
    clinicianId: clinic.clinicianId,
    date: dayjs(clinic.datetimeStart).tz(clinic.timezone).format('YYYY-MM-DD'),
    startTime: dayjs(clinic.datetimeStart).tz(clinic.timezone).format('h:mma'),
    endTime: dayjs(clinic.datetimeEnd).tz(clinic.timezone).format('h:mma'),
    timezone: clinic.timezone,
    zoomLink: clinic.zoomLink,
    isActive: clinic.isActive,
    states: clinic.states,
  }
}

export const AddOrEditClinicDrawerContent = (props: AddClinicDrawerProps) => {
  // If we have a clinic prop, it means we're editing
  const isEditing = Boolean(props.clinic)

  const cliniciansQuery = useEmployees({ status: 'currentEmployee', role: ['pc', 'spc', 'ncm'] })
  const clinicians = cliniciansQuery.data || []

  const form = useForm<ClinicForm>({
    initialValues: props.clinic
      ? convertClinicToForm(props.clinic)
      : {
          clinicianId: '',
          date: '' as YYYYMMDD,
          startTime: '',
          endTime: '',
          timezone: DEFAULT_TIMEZONE,
          zoomLink: '',
          isActive: true,
          states: [],
        },
    validate: {
      clinicianId: isRequired,
      date: isRequired,
      startTime: isRequired,
      zoomLink: isRequired,
      states: states => {
        if (states.length === 0) {
          return 'At least one state is required'
        }
      },
      endTime: (endTime, values) => {
        const { date, startTime } = values

        if (!date || !startTime) {
          return
        }

        if (!endTime) {
          return 'End time is required'
        }
        const startDateTime = getDatetime({
          date: values.date,
          time: startTime,
          timezone: values.timezone,
        })
        const endDateTime = getDatetime({
          date: values.date,
          time: endTime,
          timezone: values.timezone,
        })
        if (dayjs(endDateTime).isSameOrBefore(startDateTime)) {
          return 'End time must be after start time'
        }
      },
    },
  })

  // Mutations
  const onMutationSuccess = () => {
    // show success notification
    const actionVerb = isEditing ? 'updated' : 'added'
    showNotification({
      title: `Clinic ${actionVerb}`,
      message: `Clinic successfully ${actionVerb}`,
      variant: 'success',
      autoClose: true,
    })
    // clear cache for the list of clinics
    void invalidateLunaQuery('GET /drop-in-clinics')
    // clear cache for the clinic we just edited
    if (isEditing) {
      void invalidateLunaQuery('GET /drop-in-clinics/:clinicId', {
        params: {
          clinicId: props.clinic?.oid || '',
        },
      })
    }
    // close the drawer
    props.onClose()
  }
  const invalidateLunaQuery = useInvalidateLunaQuery()
  const createClinic = useLunaMutation('POST /drop-in-clinics', {
    onSuccess: onMutationSuccess,
  })
  const updateClinic = useLunaMutation('PUT /drop-in-clinics/:clinicId', {
    onSuccess: onMutationSuccess,
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    const datetimeStart = getDatetime({
      date: form.values.date,
      time: form.values.startTime,
      timezone: form.values.timezone,
    })

    const datetimeEnd = getDatetime({
      date: form.values.date,
      time: form.values.endTime,
      timezone: form.values.timezone,
    })

    if (props.clinic) {
      // Update clinic
      updateClinic.mutate({
        params: {
          clinicId: props.clinic.oid,
        },
        data: {
          datetimeStart,
          datetimeEnd,
          clinicianId: form.values.clinicianId,
          zoomLink: form.values.zoomLink,
          isActive: form.values.isActive,
        },
      })
    } else {
      // Add clinic
      createClinic.mutate({
        data: {
          datetimeStart,
          datetimeEnd,
          clinicianId: form.values.clinicianId,
          timezone: form.values.timezone,
          zoomLink: form.values.zoomLink,
          isActive: form.values.isActive,
          states: form.values.states,
          secondaryClinicianId: null,
          greeterId: null,
        },
      })
    }
  }

  const header = isEditing ? 'Edit clinic' : 'Add clinic'

  const { startTimes, endTimes } = getTimes()

  const isLoading = createClinic.isLoading || updateClinic.isLoading

  return (
    <>
      <DrawerHeader onClose={props.onClose}>{header}</DrawerHeader>
      <DrawerContent>
        <Stack spacing='md' p='md'>
          <Select
            searchable
            label='Clinician'
            placeholder='Select clinician'
            icon={<SearchIcon />}
            data={clinicians.map(clinician => ({
              value: clinician.oid,
              label: clinician.name,
            }))}
            {...form.getInputProps('clinicianId')}
          />
          <RadioGroup
            // This prevents the Select component from autofocusing, which is annoying when opening the drawer
            data-autofocus
            {...form.getInputProps('states')}
            /**
             * This is a list type because in the future we might want
             * to support multiple states.
             */
            value={form.values.states[0]}
            onChange={value => {
              form.setValues({
                states: [value as StateAbbr],
              })
            }}
            label='State'
          >
            {states.map(state => (
              <Radio
                /**
                 * Should not edit the state if we're editing the clinic.
                 * Once patients have been enrolled, the state should not be changed.
                 */
                disabled={isEditing}
                key={state}
                value={state}
                label={state}
              />
            ))}
          </RadioGroup>
          <Group>
            <DatePicker
              /**
               * Should not edit the date if we're editing the clinic.
               * Once patients have been enrolled, the date should not be changed.
               */
              disabled={isEditing}
              style={{ flex: 1 }}
              {...form.getInputProps('date')}
              onChange={date => {
                form.setValues({
                  date: dayjs(date).format('YYYY-MM-DD'),
                })
              }}
              label='Date'
              clearable={false}
              minDate={dayjs().toDate()}
              maxDate={dayjs().add(1, 'year').toDate()}
              placeholder='Select date'
            />
            <Select
              style={{ flex: 1 }}
              disabled
              {...form.getInputProps('timezone')}
              data={[DEFAULT_TIMEZONE]}
              label='Timezone'
              placeholder='Select timezone'
            />
          </Group>
          <Group>
            <Select
              style={{ flex: 1 }}
              {...form.getInputProps('startTime')}
              data={startTimes}
              label='Start time'
              placeholder='Select start time'
            />
            <Select
              style={{ flex: 1 }}
              {...form.getInputProps('endTime')}
              data={endTimes}
              label='End time'
              placeholder='Select end time'
            />
          </Group>
          <Checkbox
            {...form.getInputProps('isActive', { type: 'checkbox' })}
            label='Enrolling patients?'
            description='If unchecked, the clinic will not enroll new patients.'
          />
          <TextInput
            {...form.getInputProps('zoomLink')}
            label='Zoom link'
            placeholder='Zoom link'
          />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton loading={isLoading} leftIcon={<SaveIcon />} onClick={onSubmit}>
            {isEditing ? 'Save changes' : 'Add clinic'}
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const AddOrEditClinicDrawer = (props: AddClinicDrawerProps) => {
  return (
    <BetterDrawer position='right' size='lg' opened={props.opened} onClose={props.onClose}>
      <AddOrEditClinicDrawerContent {...props} />
    </BetterDrawer>
  )
}
