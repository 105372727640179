import { Group, PrimaryButton, SecondaryButton } from '@shared/components'

export type EditableSectionFooterProps = {
  onCancel: () => void
  onSave: () => void
  isSaving: boolean
}

export const EditableSectionFooter = ({
  onCancel,
  onSave,
  isSaving,
}: EditableSectionFooterProps) => {
  return (
    <Group>
      <SecondaryButton onClick={onCancel} disabled={isSaving}>
        Cancel
      </SecondaryButton>
      <PrimaryButton onClick={onSave} loading={isSaving} disabled={isSaving}>
        Save
      </PrimaryButton>
    </Group>
  )
}
