import {
  Alert,
  AlertIcon,
  Box,
  PrimaryButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  TertiaryButton,
  Text,
  Th,
  TitleThree,
  TrashIcon,
} from '@shared/components'
import { dayjs, sortBy, toTime } from '@shared/utils'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useOrphanedConversation } from '../../../utils/hooks/use-orphaned-conversation'
import { usePatient } from '../PPatientContext'

export const OrphanedConversation = () => {
  const { isOrphanedConversation, isLoading, handleCloseConversation } = useOrphanedConversation()

  if (isOrphanedConversation) {
    return (
      <Stack maw='40rem'>
        <Alert icon={<AlertIcon />} title='Orphaned conversation detected' variant='error'>
          This patient has an active conversation, but no corresponding active TaskRouter task.
          Press the button below to automatically resolve.
        </Alert>
        <PrimaryButton onClick={handleCloseConversation} loading={isLoading}>
          Close orphaned conversation
        </PrimaryButton>
      </Stack>
    )
  }

  return null
}

const ActiveConversationMessages = () => {
  const { patientId } = usePatient()

  const conversationsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/conversations', {
      params: {
        patientId,
      },
    }),
  )
  const activeConversationSid = conversationsQuery?.data?.find(
    c => c.conversationState === 'active',
  )?.conversationSid

  const conversationMessagesQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/conversations/:conversationId/messages', {
      params: {
        patientId,
        conversationId: activeConversationSid ?? '',
      },
    }),
    {
      enabled: Boolean(activeConversationSid),
    },
  )

  if (conversationsQuery.isLoading || conversationMessagesQuery.isLoading) {
    return <Skeleton w='100%' h='40rem' />
  }

  const content = conversationMessagesQuery.data?.length ? (
    <Table striped>
      <thead>
        <tr>
          <Th sortable={false}>Sent at</Th>
          <Th sortable={false}>Author</Th>
          <Th sortable={false}>Body</Th>
        </tr>
      </thead>
      <tbody>
        {(conversationMessagesQuery.data || [])
          .sort(sortBy({ key: 'dateCreated', order: 'DESC' }))
          .map(message => (
            <tr key={message.sid}>
              <td>{dayjs(message.dateCreated).tz().format('MM/DD/YYYY h:mma z')}</td>
              <td>{message.author}</td>
              <td>
                {message.body ?? <Text bold>{`${message.media.length} media attachments`}</Text>}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : (
    <Text>No active conversation messages</Text>
  )

  return (
    <Stack>
      <TitleThree>Active conversation messages</TitleThree>
      <Box w='100%' mah='40rem' sx={{ overflowY: 'scroll' }}>
        {content}
      </Box>
    </Stack>
  )
}

const Calls = () => {
  const { patientId } = usePatient()

  const callsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/calls', {
      params: {
        patientId,
      },
    }),
  )

  if (callsQuery.isLoading) {
    return <Skeleton w='100%' h='40rem' />
  }

  const content = callsQuery.data?.length ? (
    <Table striped>
      <thead>
        <tr>
          <Th sortable={false}>ID</Th>
          <Th sortable={false}>Direction</Th>
          <Th sortable={false}>Duration (minutes)</Th>
          <Th sortable={false}>Status</Th>
        </tr>
      </thead>
      <tbody>
        {(callsQuery.data || []).sort(sortBy({ key: 'dateCreated', order: 'DESC' })).map(call => (
          <tr key={call.sid}>
            <td>{call.sid}</td>
            <td>{call.direction}</td>
            <td>
              {toTime(`${Number(call.duration)} sec`)
                .minutes()
                .toFixed(0)}
              :{`${Number(call.duration) % toTime('1 min').seconds()}`.padStart(2, '0')}
            </td>
            <td>{call.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <Text>No calls</Text>
  )

  return (
    <Stack>
      <TitleThree>Call log</TitleThree>
      <Box w='100%' mah='40rem' sx={{ overflowY: 'scroll', overflowX: 'clip' }}>
        {content}
      </Box>
    </Stack>
  )
}

const Tasks = () => {
  const { patientId } = usePatient()
  const queryClient = useQueryClient()

  const deleteTaskMutation = useMutation(
    emrApi.getMutation('DELETE /patient/:patientId/twilio/tasks/:taskId'),
  )

  const [tasksQueryCacheKey, tasksQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/twilio/tasks',
    {
      params: {
        patientId,
      },
    },
  )
  const tasksQuery = useQuery(tasksQueryCacheKey, tasksQueryFn)

  const taskReservationsQueries = useQueries(
    (tasksQuery.data || []).map(task => ({
      queryKey: emrApi.getQuery('GET /patient/:patientId/twilio/tasks/:taskId/reservations', {
        params: {
          patientId,
          taskId: task.sid,
        },
      })[0],
      queryFn: emrApi.getQuery('GET /patient/:patientId/twilio/tasks/:taskId/reservations', {
        params: {
          patientId,
          taskId: task.sid,
        },
      })[1],
      enabled: Boolean(tasksQuery.data?.length),
    })),
  )

  const handleDeleteTask = async (taskSid: string) => {
    if (confirm(`You are about to delete TaskRouter task ${taskSid}. Do you want to proceed?`)) {
      await deleteTaskMutation.mutateAsync({
        params: {
          patientId,
          taskId: taskSid,
        },
      })
      void queryClient.invalidateQueries(tasksQueryCacheKey)
    }
  }

  if (tasksQuery.isLoading) {
    return <Skeleton w='100%' h='40rem' />
  }

  const content = tasksQuery.data?.length ? (
    <Table striped>
      <thead>
        <tr>
          <Th sortable={false}>ID</Th>
          <Th sortable={false}>Channel</Th>
          <Th sortable={false}>Queue</Th>
          <Th sortable={false}>Assignee</Th>
          <Th sortable={false}>&nbsp;</Th>
        </tr>
      </thead>
      <tbody>
        {(tasksQuery.data || [])
          .sort(sortBy({ key: 'dateCreated', order: 'DESC' }))
          .map((task, idx) => (
            <tr key={task.sid}>
              <td>{task.sid}</td>
              <td>{task.taskChannelUniqueName}</td>
              <td>{task.taskQueueFriendlyName}</td>
              <td>
                {
                  taskReservationsQueries[idx]?.data?.find(r =>
                    ['accepted', 'wrapping', 'completed'].includes(r.reservationStatus),
                  )?.workerName
                }
              </td>
              <td>
                <TertiaryButton
                  leftIcon={<TrashIcon />}
                  onClick={() => handleDeleteTask(task.sid)}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  ) : (
    <Text>No active Flex tasks</Text>
  )

  return (
    <Stack>
      <TitleThree>Active Flex tasks</TitleThree>
      <Box w='100%' mah='40rem' sx={{ overflowY: 'scroll', overflowX: 'clip' }}>
        {content}
      </Box>
    </Stack>
  )
}

const Conversations = () => {
  const { patientId } = usePatient()

  const conversationsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/twilio/conversations', {
      params: {
        patientId,
      },
    }),
  )

  if (conversationsQuery.isLoading) {
    return <Skeleton w='100%' h='40rem' />
  }

  const content = conversationsQuery.data?.length ? (
    <Box w='100%' mah='40rem' sx={{ overflowY: 'scroll', overflowX: 'clip' }}>
      <Table striped>
        <thead>
          <tr>
            <Th sortable={false}>ID</Th>
            <Th sortable={false}>Date created</Th>
            <Th sortable={false}>State</Th>
          </tr>
        </thead>
        <tbody>
          {(conversationsQuery.data || [])
            .sort(sortBy({ key: 'conversationDateCreated', order: 'DESC' }))
            .map(conversation => (
              <tr key={conversation.conversationSid}>
                <td>{conversation.conversationSid}</td>
                <td>{String(conversation.conversationDateCreated)}</td>
                <td>{conversation.conversationState}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Box>
  ) : (
    <Text>No conversations</Text>
  )

  return (
    <Stack>
      <TitleThree>Conversation log</TitleThree>
      {content}
    </Stack>
  )
}

export const TwilioSection = () => {
  return (
    <Stack spacing='lg'>
      <OrphanedConversation />
      <SimpleGrid cols={2} verticalSpacing='lg'>
        <ActiveConversationMessages />
        <Tasks />
        <Calls />
        <Conversations />
      </SimpleGrid>
    </Stack>
  )
}
