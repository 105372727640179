import { ReactHookFormErrorsObject } from '../../utils/reactHookForm'

export type ANoteDateInputProps = {
  name: string
  id: string
  inputRef?: (instance: HTMLInputElement | null) => void
  label?: string
  disabled?: boolean
  error?: ReactHookFormErrorsObject
  sidebar?: boolean
  className?: string
  placeholder?: string
  span?: string
}

const ANoteDateInput = ({
  name,
  inputRef,
  id,
  label,
  disabled,
  error,
  sidebar,
  className,
  placeholder,
  span = 'col-span-3',
}: ANoteDateInputProps) => {
  let stateSpecificClassNames = ''
  if (error?.[name]) {
    stateSpecificClassNames = 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
  }

  const path =
    'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'

  return (
    <div className={`${className ?? ''} ${span}`}>
      <label htmlFor={name} className='block text-sm font-medium leading-5 text-gray-700'>
        {label}
      </label>
      <div className={`${label ? 'mt-1' : ''}`}>
        <input
          autoComplete='off'
          type='date'
          disabled={disabled}
          id={id}
          name={name}
          ref={inputRef ?? null}
          placeholder={placeholder}
          className={`block w-full shadow-sm border-daybreak-actions0 focus:ring-daybreak-actions1 focus:ring-2 focus:ring-offset-2 focus:border-daybreak-actions1 sm:text-sm border-gray-300 rounded-md ${
            stateSpecificClassNames ?? ''
          } ${sidebar ? 'bg-near-white' : ''}`}
        />
        {error?.[name] && (
          <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
            <svg
              fill='currentColor'
              viewBox='0 0 20 20'
              className='mt-px w-4 h-4 mr-1 flex-shrink-0'
            >
              <path fillRule='evenodd' d={path} clipRule='evenodd'></path>
            </svg>
            {error[name]?.message}
          </p>
        )}
      </div>
    </div>
  )
}

export default ANoteDateInput
