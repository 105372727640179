import { BaseApi } from '@shared/utils'

export class ServiceLinesApi extends BaseApi {
  list = (params: { appointmentType: string }) => {
    return this.json<
      {
        procedure_code: string
        quantity: string
        units: string
        charge_amount_cents: number
        long_description: string
        consumer_description: string
      }[]
    >({ method: 'GET', url: '/serviceLines', params })
  }
}
