import { AlertIcon, Banner, Grid, Group, Skeleton, Stack, Text } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasure,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import { dayjs, sortBy } from '@shared/utils'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import ObjectiveMeasuresChartDrawer from '../../patient/forms/ObjectiveMeasuresChartDrawer'
import { CaseReviewRecommendationAlert } from '../caseReview/CaseReviewRecommendationAlert'
import { AdministerMbcSection } from './AdministerMbcSection'
import { ObjectiveMeasureScore } from './ObjectiveMeasureScore'

export type ObjectiveMeasuresSectionProps = {
  phqResponses: ObjectiveMeasure[]
  gad7Responses: ObjectiveMeasure[]
  barc10Responses: ObjectiveMeasure[]
  completedMeasures?: ObjectiveMeasuresTitle[]
  showCaseReviewAlert: boolean
  isLoading: boolean
  editMode: boolean
  lockedAt?: string
  patientId: string
  addMeasureOnClick?: (measure: ObjectiveMeasuresTitle) => void
}

export const getMostRecentResponses = ({
  responses,
  lockedAt,
}: {
  responses: ObjectiveMeasure[]
  lockedAt?: string
}) => {
  const dateCutoff = lockedAt ? dayjs(lockedAt) : dayjs()

  const responsesBeforeCutoff = responses?.filter(response => {
    return dayjs(response.completedAt).isBefore(dateCutoff)
  })

  const [mostRecentResponse, secondMostRecentResponse, thirdMostRecentResponse] =
    responsesBeforeCutoff.sort(sortBy({ key: 'completedAt', order: 'DESC' }))

  return { mostRecentResponse, secondMostRecentResponse, thirdMostRecentResponse }
}

export const ObjectiveMeasuresSection = ({
  phqResponses,
  gad7Responses,
  barc10Responses,
  completedMeasures,
  showCaseReviewAlert,
  isLoading,
  editMode,
  lockedAt,
  patientId,
  addMeasureOnClick,
}: ObjectiveMeasuresSectionProps) => {
  const patientQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId', { params: { patientId } }),
  )

  const patient = patientQuery?.data
  const patientName = `${patient?.personalData.firstName} ${patient?.personalData.lastName}`

  const [measuresData, setMeasuresData] = useState<
    DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  >([])

  const [drawerContentIndex, setDrawerContentIndex] = useState<number | null>(null)
  const [selectedMeasuresTitle, setSelectedMeasuresTitle] = useState<ObjectiveMeasuresTitle>()

  const handleMeasuresClick = ({
    allMeasureData,
    selectedMeasureData,
    title,
  }: {
    allMeasureData: ObjectiveMeasure[] | null
    selectedMeasureData: ObjectiveMeasure | null
    title: ObjectiveMeasuresTitle
  }) => {
    if (allMeasureData === null || selectedMeasureData === null) {
      return
    }
    const index = allMeasureData.findIndex(i => i.completedAt === selectedMeasureData.completedAt)
    setMeasuresData(allMeasureData as DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[])
    setDrawerContentIndex(index)
    setSelectedMeasuresTitle(title)
  }

  const {
    mostRecentResponse: mostRecentPhqResponse,
    secondMostRecentResponse: secondMostRecentPhqResponse,
    thirdMostRecentResponse: thirdMostRecentPhqResponse,
  } = getMostRecentResponses({ responses: phqResponses, lockedAt })

  const {
    mostRecentResponse: mostRecentGad7Response,
    secondMostRecentResponse: secondMostRecentGad7Response,
    thirdMostRecentResponse: thirdMostRecentGad7Response,
  } = getMostRecentResponses({ responses: gad7Responses, lockedAt })

  const {
    mostRecentResponse: mostRecentBarc10Response,
    secondMostRecentResponse: secondMostRecentBarc10Response,
    thirdMostRecentResponse: thirdMostRecentBarc10Response,
  } = getMostRecentResponses({ responses: barc10Responses, lockedAt })

  // check that an objective measure has been completed either within the past 7 days, or in the current month
  const now = dayjs()
  const OBJECTIVE_MEASURES_TIMEFRAME = 7

  const checkForResponseInTimeframe = (response: ObjectiveMeasure | undefined) => {
    if (!response) {
      return false
    }

    const responseInLast7Days = dayjs(response.completedAt).isAfter(
      now.subtract(OBJECTIVE_MEASURES_TIMEFRAME, 'day'),
    )
    const responseInCurrentMonth = dayjs(response.completedAt).isSame(now, 'month')
    return responseInLast7Days || responseInCurrentMonth
  }

  const anyMeasuresConductedWithinTimeframe = [
    mostRecentPhqResponse,
    mostRecentGad7Response,
    mostRecentBarc10Response,
  ].some(response => checkForResponseInTimeframe(response))

  const anyOriginalScoresExist = [
    mostRecentPhqResponse,
    mostRecentGad7Response,
    mostRecentBarc10Response,
  ].some(item => Boolean(item))

  return (
    <>
      {selectedMeasuresTitle && (
        <ObjectiveMeasuresChartDrawer
          data={measuresData}
          drawerContentIndex={drawerContentIndex}
          onClose={() => setDrawerContentIndex(null)}
          title={selectedMeasuresTitle}
          patientName={patientName}
        />
      )}
      <Stack>
        {showCaseReviewAlert && patient && (
          <CaseReviewRecommendationAlert
            location='Visit Note'
            patientFirstName={patient?.personalData?.firstName}
          />
        )}
        <Stack spacing='sm'>
          <Text size='xs' color={colors => colors.text[1]}>
            Previous scores
          </Text>
          {isLoading && (
            <Group>
              <Skeleton width={200} height={50} />
              <Skeleton width={200} height={50} />
              <Skeleton width={200} height={50} />
            </Group>
          )}
          <Grid>
            {!isLoading && secondMostRecentPhqResponse && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='PHQ-8'
                  {...secondMostRecentPhqResponse}
                  scoreToCompare={thirdMostRecentPhqResponse?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: phqResponses,
                      selectedMeasureData: secondMostRecentPhqResponse,
                      title: 'PHQ-8',
                    })
                  }
                />
              </Grid.Col>
            )}
            {!isLoading && secondMostRecentGad7Response && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='GAD-7'
                  {...secondMostRecentGad7Response}
                  scoreToCompare={thirdMostRecentGad7Response?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: gad7Responses,
                      selectedMeasureData: secondMostRecentGad7Response,
                      title: 'GAD-7',
                    })
                  }
                />
              </Grid.Col>
            )}
            {!isLoading && secondMostRecentBarc10Response && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='BARC-10'
                  {...secondMostRecentBarc10Response}
                  scoreToCompare={thirdMostRecentBarc10Response?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: barc10Responses,
                      selectedMeasureData: secondMostRecentBarc10Response,
                      title: 'BARC-10',
                    })
                  }
                />
              </Grid.Col>
            )}
          </Grid>
          {!isLoading && !anyOriginalScoresExist && (
            <Group pt='xs' spacing='xs'>
              <AlertIcon color={colors => colors.text[1]} />
              <Text size='xs' color={colors => colors.text[1]}>
                None found
              </Text>
            </Group>
          )}
        </Stack>
        <Stack spacing='sm'>
          <Text size='xs' color={colors => colors.text[1]}>
            Latest scores
          </Text>
          {isLoading && (
            <Group>
              <Skeleton width={200} height={50} />
              <Skeleton width={200} height={50} />
              <Skeleton width={200} height={50} />
            </Group>
          )}
          <Grid>
            {!isLoading && mostRecentPhqResponse && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='PHQ-8'
                  {...mostRecentPhqResponse}
                  scoreToCompare={secondMostRecentPhqResponse?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: phqResponses,
                      selectedMeasureData: mostRecentPhqResponse,
                      title: 'PHQ-8',
                    })
                  }
                />
              </Grid.Col>
            )}
            {!isLoading && mostRecentGad7Response && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='GAD-7'
                  {...mostRecentGad7Response}
                  scoreToCompare={secondMostRecentGad7Response?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: gad7Responses,
                      selectedMeasureData: mostRecentGad7Response,
                      title: 'GAD-7',
                    })
                  }
                />
              </Grid.Col>
            )}
            {!isLoading && mostRecentBarc10Response && (
              <Grid.Col span={4}>
                <ObjectiveMeasureScore
                  measure='BARC-10'
                  {...mostRecentBarc10Response}
                  scoreToCompare={secondMostRecentBarc10Response?.compositeScore}
                  onClick={() =>
                    handleMeasuresClick({
                      allMeasureData: barc10Responses,
                      selectedMeasureData: mostRecentBarc10Response,
                      title: 'BARC-10',
                    })
                  }
                />
              </Grid.Col>
            )}
          </Grid>
          {!isLoading &&
            !mostRecentPhqResponse &&
            !mostRecentGad7Response &&
            !mostRecentBarc10Response && (
              <Group pt='xs' spacing='xs'>
                <AlertIcon color={colors => colors.text[1]} />
                <Text size='xs' color={colors => colors.text[1]}>
                  None found
                </Text>
              </Group>
            )}
        </Stack>
        {editMode && !isLoading && (
          <Stack spacing='sm'>
            {!anyMeasuresConductedWithinTimeframe && editMode && (
              <Banner type='warning' label='No PHQ-9, GAD-7 or BARC-10 completed this month' />
            )}
            <AdministerMbcSection
              patientId={patientId}
              addMeasureOnClick={addMeasureOnClick}
              completedMeasures={completedMeasures}
            />
          </Stack>
        )}
      </Stack>
    </>
  )
}
