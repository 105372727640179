import { List, ListItem, PrimaryButton, Stack, Text } from '@shared/components'

import { useState } from 'react'
import { usePatient } from '../PPatientContext'
import { QuickMergeModal } from './modals/quickMerge/QuickMergeModal'

export const QuickMergeSection = () => {
  const { patientId, patientQuery } = usePatient()
  const [showModal, setShowModal] = useState<boolean>(false)

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Stack>
      <Stack spacing='md'>
        <Stack spacing='xs'>
          <Text>Quick merge will perform the following actions:</Text>
          <List>
            <ListItem>{`Delete this account's authentication`}</ListItem>
            <ListItem>Transfer all collections to a different account</ListItem>
            <ListItem>
              Transfer all subcollections (except historicalChanges) to a different account
            </ListItem>
          </List>
        </Stack>
        <Text>You will also have the option to archive/delete the patient chart.</Text>
      </Stack>
      <PrimaryButton size='md' disabled={!patientQuery?.data} onClick={() => setShowModal(true)}>
        Quick merge
      </PrimaryButton>
      <QuickMergeModal
        opened={showModal}
        onClose={onCloseModal}
        fromPatientId={patientId}
        step='merge-form'
      />
    </Stack>
  )
}
