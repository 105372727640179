import { hasHomeState } from '@shared/types'
import { isTruthy } from '@shared/utils'
import ANoteCheckboxPositiveInput from '../../components/atoms/ANoteCheckboxPositiveInput'
import ANoteHeader from '../../components/atoms/ANoteHeader'
import ANoteSection from '../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../components/atoms/ANoteTextArea'
import ANoteYesNoRadioInput from '../../components/atoms/ANoteYesNoRadioInput'
import { useFlags, usePatientInsurances } from '../../utils/hooks'
import { ReactHookRegister, ReactHookWatch } from '../../utils/reactHookForm'
import { usePatient } from './PPatientContext'

export type MReferralNoteSectionProps = {
  register: ReactHookRegister
  watch: ReactHookWatch
  referral_request: boolean
  no_referral_request: boolean
  sidePanel?: boolean
}

const MReferralNoteSection = ({
  register,
  watch,
  sidePanel = false,
}: MReferralNoteSectionProps) => {
  const referral_request = watch('referral_request')
  const needs_transfer_hloc = watch('needs_transfer_hloc')
  const recommended_hloc = watch('recommended_hloc')

  const { paCoe } = useFlags()

  /*
   * Note: for PA medicaid patients, we need to ask if the patient needs to be transferred to a
   * higher level of care program
   */
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const isInPA = hasHomeState(patient, 'Pennsylvania')

  const { data: patientInsurancesData } = usePatientInsurances(patient) || {}
  const { primaryInsurance, secondaryInsurance } = patientInsurancesData || {}

  const patientInsurances = [primaryInsurance, secondaryInsurance].filter(isTruthy)
  const isMedicaid = patientInsurances.some(
    insurance => insurance?.basicInsuranceData?.planType === 'MC',
  )

  const isPAMedicaid = isInPA && isMedicaid

  return (
    <ANoteSection>
      <ANoteHeader text='Referral and Transfer of Care Plan' />
      <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
        {isPAMedicaid && paCoe && (
          <>
            <ANoteYesNoRadioInput
              name='needs_transfer_hloc'
              id='needs_transfer_hloc'
              ref={register()}
              label='Needs transfer to alternate higher level of care program?'
              disabled={sidePanel}
            />
            <div className={`${needs_transfer_hloc === 'yes' ? 'ml-2 space-y-2' : 'hidden'}`}>
              <p className='text-sm font-medium leading-5 text-gray-700'>
                Recommended higher level of care
              </p>
              <div className='flex'>
                <input
                  value='inpatient'
                  name='recommended_hloc'
                  type='radio'
                  ref={register()}
                  className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                />
                <label className='ml-1'>
                  <span className='block text-sm leading-5 font-medium text-gray-700'>
                    Level 4 - Inpatient
                  </span>
                </label>
              </div>
              <div className='flex'>
                <input
                  value='residential'
                  name='recommended_hloc'
                  type='radio'
                  ref={register()}
                  className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                />
                <label className='ml-1'>
                  <span className='block text-sm leading-5 font-medium text-gray-700'>
                    Level 3 - Residential
                  </span>
                </label>
              </div>
              <div
                className={`${recommended_hloc === 'residential' ? 'ml-2 space-y-2' : 'hidden'}`}
              >
                <div className='flex'>
                  <input
                    value='clinically_managed_low_intensity_residential'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      3.1 - Clinically managed low-intensity residential
                    </span>
                  </label>
                </div>
                <div className='flex'>
                  <input
                    value='clinicially_managed_high_intensity_residential'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      3.5 - Clinically managed high-intensity residential
                    </span>
                  </label>
                </div>
                <div className='flex'>
                  <input
                    value='medically_managed_residential'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      3.7 - Medically manged residential
                    </span>
                  </label>
                </div>
              </div>
              <div className='flex'>
                <input
                  value='intensive_outpatient'
                  name='recommended_hloc'
                  type='radio'
                  ref={register()}
                  className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                />
                <label className='ml-1'>
                  <span className='block text-sm leading-5 font-medium text-gray-700'>
                    Level 2 - Intensive outpatient (IOP)/High intensity outpatient (HIOP)
                  </span>
                </label>
              </div>
              <div
                className={`${
                  recommended_hloc === 'intensive_outpatient' ? 'ml-2 space-y-2' : 'hidden'
                }`}
              >
                <div className='flex'>
                  <input
                    value='intensive_outpatient'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      2.1 - Intensive outpatient (IOP)
                    </span>
                  </label>
                </div>
                <div className='flex'>
                  <input
                    value='high_intensity_outpatient'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      2.5 - High-intensity outpatient (HIOP)
                    </span>
                  </label>
                </div>
                <div className='flex'>
                  <input
                    value='medically_managed_intensive_outpatient'
                    name='recommended_hloc_subcategory'
                    type='radio'
                    ref={register()}
                    className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                  />
                  <label className='ml-1'>
                    <span className='block text-sm leading-5 font-medium text-gray-700'>
                      2.7 - Medically managed intensive outpatient
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <ANoteYesNoRadioInput
          name='referral_request'
          id='referral_request'
          ref={register()}
          label={isPAMedicaid && paCoe ? 'Patient interested in referrals?' : 'Referral Request?'}
          disabled={sidePanel}
        />
        <div className={`${referral_request === 'yes' ? 'ml-2 space-y-2' : 'hidden'}`}>
          <ANoteCheckboxPositiveInput
            name='referral_request_inpatient_program'
            id='referral_request_inpatient_program'
            label='Inpatient program'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_residential_program'
            id='referral_request_residential_program'
            label='Residential program'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_intensive_outpatient_program'
            id='referral_request_intensive_outpatient_program'
            label='Intensive outpatient program'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_methadone_program'
            id='referral_request_methadone_program'
            label='Methadone program (OTP)'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_mental_health_psychiatric'
            id='referral_request_mental_health_psychiatric'
            label='Mental health/psychiatric clinic/services'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_pcp'
            id='referral_request_pcp'
            label='PCP'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_buprenorphine_clinic'
            id='referral_request_buprenorphine_clinic'
            label='Other buprenorphine clinic'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          <ANoteCheckboxPositiveInput
            name='referral_request_other'
            id='referral_request_other'
            label='Other'
            ref={register()}
            labelFont='font-light'
            textSize='text-sm'
            disabled={sidePanel}
            className={`${referral_request === 'yes' ? '' : 'hidden'}`}
          />
          {paCoe && (
            <ANoteYesNoRadioInput
              name='referral_request_urgent'
              id='referral_request_urgent'
              ref={register()}
              label='Is the referral request urgent?'
              disabled={sidePanel}
            />
          )}
        </div>
        <div className={`${referral_request === 'no' ? 'ml-2' : 'hidden'}`}>
          <ANoteTextArea
            label='Explanation'
            name='no_referral_request_explanation'
            id='no_referral_request_explanation'
            minRows={2}
            ref={register()}
            disabled={sidePanel}
            className={`${referral_request === 'no' ? '' : 'hidden'}`}
          />
        </div>
      </ANoteSectionContent>
    </ANoteSection>
  )
}

export default MReferralNoteSection
