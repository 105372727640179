import {
  GlobeIcon,
  Group,
  Text,
  TimelineCardButton,
  TimelineCardTitle,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { DischargeVisitPill } from '../../../../components/DischargeVisitPill'
import { HighRiskVisitPillStatus } from '../../../../components/HighRiskVisitPillStatus'
import { VisitPillStatus } from '../../../../components/VisitPillStatus'
import { getVisitTypeLabel } from '../../../../utils/visits'
import { JourneyItemContent, usePatient } from '../../PPatientContext'

export const AppointmentContent = ({
  appointment,
}: {
  appointment: JourneyItemContent<'appointment'>
}) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const edgeColor = useMemo(() => {
    if (appointment.status === 'late-cancellation' || appointment.status === 'no-show') {
      return colors.error[0]
    }

    if (appointment.status === 'canceled') {
      return colors.warning[0]
    }

    return colors.success[0]
  }, [appointment.status, colors.error, colors.success, colors.warning])

  const { patientQuery } = usePatient()
  const patientTimezone = patientQuery?.data?.account?.timezone
  const time = dayjs(appointment.datetime)

  return (
    <TimelineCardButton
      styles={{
        backgroundColor: colors.background[6],
        activeBackgroundColor: colors.background[7],
        edgeColor,
      }}
      component={Link}
      to={`/patients/${appointment.userId}/notes/${appointment.id}`}
      leftSide={
        <TimelineCardTitle
          strikethrough={
            appointment.status === 'no-show' || appointment.status === 'late-cancellation'
          }
          color={colors.text[3]}
          title={getVisitTypeLabel(appointment)}
        >
          <Group spacing='xs' noWrap>
            <Text color={({ text }) => text[3]} style={{ whiteSpace: 'nowrap' }}>
              {time.format('h:mma z')}
            </Text>
            <Tooltip
              label={
                patientTimezone
                  ? `Patient time: ${dayjs(appointment.datetime)
                      .tz(patientTimezone)
                      .format('h:mma z')}`
                  : 'Missing time zone'
              }
            >
              <GlobeIcon color={colors => colors.background[5]} />
            </Tooltip>
          </Group>
          <VisitPillStatus visit={appointment} />
          <DischargeVisitPill visit={appointment} />
          <HighRiskVisitPillStatus visit={appointment} />
        </TimelineCardTitle>
      }
      rightSide={
        <Text color={colors.text[3]} lineClamp={1}>
          {appointment.calendar}
        </Text>
      }
    />
  )
}
