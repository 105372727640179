import { Banner, Stack } from '@shared/components'
import { DiagnosisChronicity, DiagnosisProgression, StateName } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { SelectItem } from '../../../components/forms/SelectItem'
import { VisitNoteSelect } from '../../../components/forms/VisitNoteSelect'
import { VisitNoteTextarea } from '../../../components/forms/VisitNoteTextArea'
import { YesOrNoRadio } from '../../../components/forms/YesOrNoRadio'
import { usePatient } from '../PPatientContext'
import { VisitNoteSection } from './VisitNoteSection'
import { reasonsForNoEvalSelectOptions, useMentalHealthEvaluationFormContext } from './formHelpers'
import { MentalHealthDiagnosesSection } from './mentalHealthEvaluation/MentalHealthDiagnosesSection'

export type MentalHealthEvalSectionProps = {
  editMode: boolean
  onDiagnosisCompleted: (diagnosis: {
    code: string
    full_description: string
    chronicity: DiagnosisChronicity
    progression: DiagnosisProgression
  }) => void
}
const BANNER_STATES: StateName[] = ['New York']
export const MentalHealthEvalSection = ({
  editMode,
  onDiagnosisCompleted,
}: MentalHealthEvalSectionProps) => {
  const mentalHealthEvalForm = useMentalHealthEvaluationFormContext()
  const { patientQuery, patientId } = usePatient()
  const [patientRegistryQueryKey, patientRegistryQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    {
      params: {
        patientId,
      },
    },
  )

  const { data: patientCocmData } = useQuery(patientRegistryQueryKey, patientRegistryQueryFunction)

  const showBanner =
    BANNER_STATES.some(state => state === patientQuery?.data?.homeData?.state) &&
    Boolean(patientCocmData)

  return (
    <VisitNoteSection title='Mental health eval'>
      <Stack spacing='md'>
        {showBanner && (
          <Banner
            label={`${patientQuery?.data?.personalData.firstName} is almost eligible for CoCM billing.`}
            type='warning'
          />
        )}
        <Stack spacing='sm'>
          <YesOrNoRadio
            label="Did you assess this patient's mental health during this visit?"
            isEditing={editMode}
            {...mentalHealthEvalForm.getInputProps('isEvaluated')}
          />
          {mentalHealthEvalForm.values.isEvaluated === 'no' && (
            <>
              <VisitNoteSelect
                {...mentalHealthEvalForm.getInputProps('reasonForNoEvaluation')}
                label={!editMode && 'Reason for no evaluation'}
                isEditing={editMode}
                placeholder='Select reason'
                data={reasonsForNoEvalSelectOptions}
                itemComponent={SelectItem}
              />
              {mentalHealthEvalForm.values.reasonForNoEvaluation === 'Other' && (
                <VisitNoteTextarea
                  {...mentalHealthEvalForm.getInputProps('reasonForNoEvaluationOther')}
                  isEditing={editMode}
                  placeholder='Please explain'
                  minRows={1}
                />
              )}
            </>
          )}
          <MentalHealthDiagnosesSection
            editMode={editMode}
            onDiagnosisCompleted={onDiagnosisCompleted}
          />
        </Stack>
      </Stack>
    </VisitNoteSection>
  )
}
