import {
  Accordion,
  BookmarkIcon,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Group,
  List,
  ListItem,
  Text,
} from '@shared/components'
import { InsuranceReviewQueueItem } from '@shared/types'

type IRQMissingInfoSectionProps = {
  item: InsuranceReviewQueueItem
}

const getMissingFileInfo = ({ hasFront, hasBack }: { hasFront: boolean; hasBack: boolean }) => {
  if (!hasFront && !hasBack) {
    return 'front and back'
  }

  if (!hasFront) {
    return 'front'
  }

  if (!hasBack) {
    return 'back'
  }

  return null
}

const getMissingInfo = (item: InsuranceReviewQueueItem) => {
  const { files, insurance, secondaryInsurance, consents } = item

  const missingInsuranceCardInfo = getMissingFileInfo({
    hasFront: Boolean(files.primaryInsuranceFront),
    hasBack: Boolean(files.primaryInsuranceBack),
  })
  const missingSecondaryInsuranceCardInfo = getMissingFileInfo({
    hasFront: Boolean(files.secondaryInsuranceFront),
    hasBack: Boolean(files.secondaryInsuranceBack),
  })

  const missingInfoStrings: string[] = []

  if (!consents?.some(consent => consent.type === 'financial')) {
    missingInfoStrings.push('Financial agreement not signed')
  }

  if (!insurance?.cardholderBirthday) {
    missingInfoStrings.push(
      `Cardholder birthday not provided for ${
        insurance?.provider ?? `patient's primary insurance`
      }`,
    )
  }

  if (!secondaryInsurance?.cardholderBirthday && item.hasSecondaryInsurance) {
    missingInfoStrings.push(
      `Cardholder birthday not provided for ${
        secondaryInsurance?.provider ?? `patient's secondary insurance`
      }`,
    )
  }

  if (missingInsuranceCardInfo) {
    missingInfoStrings.push(
      `Insurance card (${missingInsuranceCardInfo}) not uploaded for ${
        insurance?.provider ?? `patient's primary insurance`
      }`,
    )
  }

  if (item.hasSecondaryInsurance && missingSecondaryInsuranceCardInfo) {
    missingInfoStrings.push(
      `Insurance card (${missingSecondaryInsuranceCardInfo}) not uploaded for ${
        secondaryInsurance?.provider ?? `patient's secondary insurance`
      }`,
    )
  }

  return missingInfoStrings
}

export const IRQMissingInfoSection = ({ item }: IRQMissingInfoSectionProps) => {
  const missingInfo = getMissingInfo(item)
  if (!missingInfo.length) {
    return null
  }
  return (
    <Box
      sx={theme => ({
        background: theme.other.colors.warning[1],
        borderRadius: theme.radius.sm,
      })}
    >
      <Accordion
        defaultValue='opened'
        chevronPosition='right'
        chevronActive={<ChevronUpIcon />}
        chevronInactive={<ChevronDownIcon />}
      >
        <Accordion.Item value='opened'>
          <Accordion.Control>
            <Group>
              <BookmarkIcon color={colors => colors.warning[0]} />
              <Text bold>Missing information</Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <List mx='md' mb='md'>
              {missingInfo.map(info => (
                <ListItem key={info}>{info}</ListItem>
              ))}
            </List>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}
