import { Box, Grid, Skeleton } from '@shared/components'

export const PatientShellHeaderLoading = () => (
  <Box p='lg'>
    <Grid>
      <Grid.Col span={12}>
        <Skeleton mb='sm' height={16} width={180} radius='sm' />
        <Skeleton height={16} width='100%' radius='sm' />
      </Grid.Col>
      <Grid.Col span={12}>
        <Skeleton mb='sm' height={16} width={180} radius='sm' />
        <Skeleton height={16} width='100%' radius='sm' />
      </Grid.Col>
    </Grid>
  </Box>
)
