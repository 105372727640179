import { useToggle } from '@mantine/hooks'
import {
  AlertIcon,
  CheckCircleIcon,
  ClockIcon,
  Colors,
  Group,
  RepeatIcon,
  TertiaryButton,
  Text,
  TrashIcon,
} from '@shared/components'
import { PaymentPlan, StripePayment, YYYYMMDD } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEmrMutation, useEmrQuery } from '../../../../utils/hooks'
import { AddAbpPaymentDrawer } from '../../invoices/AddAbpPaymentDrawer'
import { RemovePaymentPlanPaymentModal } from './RemovePaymentPlanPaymentModal'

export const isSuccessfulPayment = (payment?: StripePayment) =>
  payment?.latestCharge?.status === 'succeeded'

const PaymentStatusIcon = ({
  isSuccessfulPayment,
  hasPaymentBeenAttempted,
}: {
  isSuccessfulPayment: boolean
  hasPaymentBeenAttempted: boolean
}) => {
  if (!hasPaymentBeenAttempted) {
    return <ClockIcon color={(colors: Colors) => colors.actions[2]} />
  }

  if (isSuccessfulPayment) {
    return <CheckCircleIcon color={(colors: Colors) => colors.success[0]} />
  }

  return <AlertIcon color={(colors: Colors) => colors.error[0]} />
}

type AbpPaymentRowProps = {
  paymentPlan: PaymentPlan
  paymentDate: YYYYMMDD
  payments: StripePayment[]
}

export const AbpPaymentRow = ({ paymentPlan, paymentDate, payments }: AbpPaymentRowProps) => {
  const [isAbpPaymentDrawerOpened, toggleAbpPaymentDrawer] = useToggle()
  const [isDeletePaymentModalOpened, toggleDeletePaymentModal] = useToggle()

  const addPayment = useEmrMutation('POST /payment-plan/:paymentPlanId/retry-payment')

  const { data: invoiceQueryData } = useEmrQuery('GET /invoices', {
    query: {
      patientId: paymentPlan.patientId,
    },
  })

  const isPaymentDateInFuture = dayjs(paymentDate).isAfter(dayjs().endOf('day'))

  const matchingPayment = payments.find(payment =>
    dayjs(payment.latestCharge?.createdAt).isSame(dayjs(paymentDate), 'day'),
  )

  // Only allow payments to be retried if the initial payment failed and the patient has a past due balance
  const hasPaymentBeenAttempted = Boolean(matchingPayment)
  const wasPaymentSuccessful = isSuccessfulPayment(matchingPayment)

  const hasUnpaidInvoices =
    invoiceQueryData?.aggregateInvoiceInfo &&
    invoiceQueryData?.aggregateInvoiceInfo.unpaidInvoices.length > 0

  const isAbleToRetry = hasPaymentBeenAttempted && !wasPaymentSuccessful && hasUnpaidInvoices

  const isAbleToCancel = isPaymentDateInFuture || !wasPaymentSuccessful || isAbleToRetry

  return (
    <>
      <AddAbpPaymentDrawer
        opened={isAbpPaymentDrawerOpened}
        onClose={() => toggleAbpPaymentDrawer(false)}
        paymentPlan={paymentPlan}
        stripePaymentIntentIdToReplace={matchingPayment?.paymentIntentId || ''}
      />
      <RemovePaymentPlanPaymentModal
        opened={isDeletePaymentModalOpened}
        onClose={toggleDeletePaymentModal}
        paymentPlan={paymentPlan}
        paymentDate={paymentDate}
      />
      <Group position='apart' spacing='xs'>
        <Group spacing='xs'>
          <PaymentStatusIcon
            isSuccessfulPayment={wasPaymentSuccessful}
            hasPaymentBeenAttempted={hasPaymentBeenAttempted}
          />
          <Text key={paymentDate}>{dayjs(paymentDate).format('M/D/YYYY')}</Text>
        </Group>
        <Group spacing='xs'>
          {isAbleToRetry && (
            <TertiaryButton
              leftIcon={<RepeatIcon />}
              onClick={() => toggleAbpPaymentDrawer(true)}
              loading={addPayment.isLoading}
            >
              Retry
            </TertiaryButton>
          )}
          {isAbleToCancel && (
            <TertiaryButton leftIcon={<TrashIcon />} onClick={() => toggleDeletePaymentModal(true)}>
              Delete
            </TertiaryButton>
          )}
        </Group>
      </Group>
    </>
  )
}
