import { AlertIcon, Colors } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import { DotProps as RechartsDotProps } from 'recharts'
import { determineFillAndStrokeColors, didPatientIndicateSuicidality } from './chartUtils'

type DotProps = {
  value?: number
  index?: number
  goodResultThreshold: number
  badResultThreshold: number
  higherResultIsBetter: boolean
  colors: Colors
  strokeWidth: number
  data?: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  title?: ObjectiveMeasuresTitle
  iconSize?: number
} & RechartsDotProps

const Dot = ({
  cx,
  cy,
  value,
  index,
  goodResultThreshold,
  badResultThreshold,
  higherResultIsBetter,
  colors,
  strokeWidth,
  data,
  title,
  iconSize,
}: DotProps) => {
  if (value === undefined || index === undefined) {
    return null
  }

  const { fillColor, strokeColor } = determineFillAndStrokeColors({
    value,
    goodResultThreshold,
    badResultThreshold,
    higherResultIsBetter,
    colors,
  })

  const patientIndicatedSuicidality =
    data && title
      ? didPatientIndicateSuicidality({
          response: data[index]!,
          title,
        })
      : false

  const suicidalityIndicatorXOffset = 4
  const suicidalityIndicatorYOffset = -7

  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      {patientIndicatedSuicidality && cx && cy && (
        <AlertIcon
          height={iconSize}
          color={colors.error[0]}
          x={cx + suicidalityIndicatorXOffset}
          y={cy + suicidalityIndicatorYOffset}
        />
      )}
    </>
  )
}

export default Dot
