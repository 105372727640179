import { createFormContext } from '@mantine/form'
import {
  DiagnosisChronicity,
  DiagnosisProgression,
  MentalHealthEvaluation,
  MentalHealthEvaluationSaveRequest,
  ReasonForNoEvaluation,
} from '@shared/types'

import { isAnySelected, skipIfOtherField, validateWith } from '@shared/components'
import { isRequired, isYesOrNoSelected } from '../../../utils/formValidation'
export type MentalHealthEvalFormDiagnosis = {
  code: string
  chronicity: DiagnosisChronicity | ''
  progression: DiagnosisProgression | ''
  comment: string
}

export type MentalHealthEvalFormData = {
  diagnoses: MentalHealthEvalFormDiagnosis[]
  isEvaluated: 'yes' | 'no' | ''
  reasonForNoEvaluation?: ReasonForNoEvaluation | null
  reasonForNoEvaluationOther?: string | null
}

export const [
  MentalHealthEvaluationFormProvider,
  useMentalHealthEvaluationFormContext,
  useMentalHealthEvaluationForm,
] = createFormContext<MentalHealthEvalFormData>()

const reasonsForNoEval: ReasonForNoEvaluation[] = [
  'Insufficient time',
  'Forgot, will assess next visit',
  'Focused on other conditions for this visit',
  'Other',
]

export const reasonsForNoEvalSelectOptions = reasonsForNoEval.map(reason => ({
  value: reason,
  label: reason,
}))

const skipIfPatientIsBeingEvaluated = skipIfOtherField<MentalHealthEvalFormData, 'isEvaluated'>(
  'isEvaluated',
  'is',
  'yes',
)

const skipIfPatientIsNotBeingEvaluated = skipIfOtherField<MentalHealthEvalFormData, 'isEvaluated'>(
  'isEvaluated',
  'is',
  'no',
)
const skipIfIsEvaluationReasonIsNotOther = skipIfOtherField<
  MentalHealthEvalFormData,
  'reasonForNoEvaluation'
>('reasonForNoEvaluation', 'not', 'Other')

export const MENTAL_HEALTH_EVALUATION_FORM_INIT = {
  initialValues: {
    diagnoses: [],
    isEvaluated: '' as const,
  },
  validate: {
    diagnoses: {
      progression: validateWith(
        skipIfPatientIsNotBeingEvaluated,
        isAnySelected(['stable', 'exacerbating'], 'Required'),
      ),
      chronicity: validateWith(
        skipIfPatientIsNotBeingEvaluated,
        isAnySelected(['acute', 'chronic'], 'Required'),
      ),
    },
    isEvaluated: validateWith(isYesOrNoSelected),
    reasonForNoEvaluation: validateWith(skipIfPatientIsBeingEvaluated, isRequired),
    reasonForNoEvaluationOther: validateWith(
      skipIfPatientIsBeingEvaluated,
      skipIfIsEvaluationReasonIsNotOther,
      isRequired,
    ),
  },
}

export const formatMentalHealthEvalResponses = (
  data: MentalHealthEvalFormData,
): MentalHealthEvaluationSaveRequest => {
  const diagnoses = data.diagnoses.map(diagnosis => {
    return {
      ...diagnosis,
      chronicity: diagnosis.chronicity || null,
      progression: diagnosis.progression || null,
    }
  })
  const { isEvaluated } = data
  let { reasonForNoEvaluationOther, reasonForNoEvaluation } = data
  if (isEvaluated === 'yes') {
    reasonForNoEvaluation = null
    reasonForNoEvaluationOther = null
  }

  return {
    isEvaluated: isEvaluated === '' ? null : isEvaluated,
    reasonForNoEvaluation,
    reasonForNoEvaluationOther,
    diagnoses,
  }
}

export const mentalHealthEvalToFormData = (
  evaluation: MentalHealthEvaluation,
): MentalHealthEvalFormData => {
  const diagnoses = evaluation.diagnoses?.map(diagnosis => {
    return {
      ...diagnosis,
      chronicity: diagnosis.chronicity || ('' as const),
      progression: diagnosis.progression || ('' as const),
    }
  })

  return {
    isEvaluated: evaluation.isEvaluated ?? '',
    reasonForNoEvaluation: evaluation.reasonForNoEvaluation,
    reasonForNoEvaluationOther: evaluation.reasonForNoEvaluationOther ?? '',
    diagnoses: diagnoses || [],
  }
}
