import { EncounterStatuses, InsuranceQueueStatus } from '@shared/types'
import isNull from 'lodash/isNull'
import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'
import { useCallback, useMemo } from 'react'
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom'

type RouteParams = Record<
  '/employees/:employeeID',
  {
    pathParams: 'employeeID'
    queryParams: 'tab'
    path: `/employees/${string}`
  }
> &
  Record<
    '/care-team/insurance/:status',
    {
      pathParams: 'status'
      queryParams: 'index'
      path: `/care-team/insurance/${InsuranceQueueStatus}`
    }
  > &
  Record<
    '/billing/encounters/:status/:encounterId',
    {
      pathParams: 'status' | 'encounterId'
      queryParams: 'encounterId'
      path: `/billing/encounters/${EncounterStatuses}/${string}`
    }
  > &
  Record<
    '/billing/encounters/:status',
    {
      pathParams: 'status'
      queryParams: ''
      path: `/billing/encounters/${EncounterStatuses}`
    }
  >

export type Route = keyof RouteParams
type Path<R extends Route> = RouteParams[R]['path'] | `/care-team` | `/employees`
type QueryParam<R extends Route> = RouteParams[R]['queryParams']
type QueryParamRecord<R extends Route> = { [key in QueryParam<R>]?: string }
type PathParam<R extends Route> = RouteParams[R]['pathParams']
type PathParamRecord<R extends Route> = Record<PathParam<R>, string>

export function useTypedParams<R extends Route>(_r: R) {
  const navigate = useNavigate()

  const { pathname: currentPath, search } = useLocation()
  const pathParams = useParams<PathParamRecord<R>>()
  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const setQueryParams = useCallback(
    (replaceQueryParams: QueryParamRecord<R>, method: 'set' | 'replace' = 'set') => {
      if (method === 'set') {
        navigate(
          {
            pathname: currentPath,
            search: new URLSearchParams(omitBy(replaceQueryParams, isNull)).toString(),
          },
          { replace: true },
        )
      } else {
        navigate(
          {
            pathname: currentPath,
            search: new URLSearchParams(
              omitBy(merge(Object.fromEntries(queryParams), replaceQueryParams), isNull),
            ).toString(),
          },
          { replace: true },
        )
      }
    },
    [navigate, currentPath, queryParams],
  )

  return {
    queryParams: Object.fromEntries(queryParams) as QueryParamRecord<R>,
    pathParams,
    setQueryParams,
  }
}

type LocationDescriptor<R extends Route> =
  | Path<R>
  | {
      pathname: Path<R>
      search?: QueryParamRecord<R>
    }

type History<HistoryLocationState> = { navigate: NavigateFunction } & {
  push<R extends Route>(location: LocationDescriptor<R>): void
  push<R extends Route>(path: Path<R>, state?: HistoryLocationState): void
  replace<R extends Route>(location: LocationDescriptor<R>): void
  replace<R extends Route>(path: Path<R>, state?: HistoryLocationState): void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useTypedHistory<T = any>(): History<T> {
  const navigate = useNavigate()

  const push = useCallback(
    <R extends Route>(path: LocationDescriptor<R>, state?: T): void => {
      if (typeof path === 'string') {
        navigate(path, { state })
      } else {
        const { search, pathname } = path
        navigate(
          {
            pathname,
            search: new URLSearchParams(search as Record<QueryParam<R>, string>).toString(),
          },
          { state },
        )
      }
    },
    [navigate],
  )

  const replace = useCallback(
    <R extends Route>(path: LocationDescriptor<R>, state?: T): void => {
      if (typeof path === 'string') {
        navigate(path, { state, replace: true })
      } else {
        const { search, pathname } = path
        navigate(
          {
            pathname,
            search: new URLSearchParams(search as Record<QueryParam<R>, string>).toString(),
          },
          { state, replace: true },
        )
      }
    },
    [navigate],
  )

  return {
    navigate,
    push,
    replace,
  }
}
