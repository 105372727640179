import { BannerPortal, Tabs } from '@shared/components'
import { EncounterStatuses, EncounterWithErrors } from '@shared/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi, encountersApi } from '../../../api'
import AAlertBanner from '../../../components/atoms/AAlertBanner'
import { Page } from '../../../components/templates/TDefault'
import { useTypedHistory, useTypedParams } from '../../../utils/hooks'
import ListAndCardLayout from '../../care_team/irq/ListAndCardLayout'
import { LoadingEncountersList } from '../../core/loading/LoadingComponents'
import BillingHeaderContent from '../BillingHeaderContent'
import { EncounterCard } from './EncounterCard'
import EncounterList from './EncounterList'

export const reviewableStatuses = [EncounterStatuses.Unsubmitted, EncounterStatuses.Flagged]

const BillingEncounters = () => {
  const [cursors, setCursors] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const currentCursor = cursors[currentPage - 1]
  const [globalError, setGlobalError] = useState('')
  const [encounters, setEncounters] = useState<EncounterWithErrors[]>([])
  const [index, setIndex] = useState(0)
  const [payerId, setPayerId] = useState<string>('')
  const per = 20
  const { replace } = useTypedHistory()
  const { pathParams } = useTypedParams('/billing/encounters/:status/:encounterId')
  const status = pathParams.status as EncounterStatuses
  const { encounterId } = pathParams
  const [activeTab, setActiveTab] = useState<EncounterStatuses | null>(
    EncounterStatuses.Unsubmitted,
  )

  const encountersQuery = useQuery(
    ['encountersApi.getEncounters', status, currentCursor, per, payerId],
    () => {
      const order = reviewableStatuses.includes(status) ? 'asc' : 'desc'
      return encountersApi.getEncounters({
        order,
        per,
        filter: status,
        startAfter: currentCursor,
        payerId,
      })
    },
    {
      onSuccess: data => {
        setEncounters(data)
        if (data.length === 0) {
          replace(`/billing/encounters/${status}/`)
          return
        }
        let encounterUrlParam = encounterId
        const encounter = data?.find(encounter => encounter.oid === encounterUrlParam)
        if (!encounter && data.length > index) {
          encounterUrlParam = data[index]?.oid
        } else if (!encounter) {
          encounterUrlParam = data?.[index - 1]?.oid
        }
        replace(`/billing/encounters/${status}/${encounterUrlParam}`)
        const body = document.querySelector('body')
        if (body) {
          body.scrollTo(0, 0)
        }
      },
    },
  )

  const [encounterCountQueryKey, encounterCountQueryFunction] = emrApi.getQuery(
    'GET /encounters/count',
    { query: { payerId, status } },
  )

  const encounterCountQuery = useQuery(encounterCountQueryKey, encounterCountQueryFunction, {
    enabled: [EncounterStatuses.Flagged, EncounterStatuses.Unsubmitted].includes(status),
  })

  const onClickTab = (value: EncounterStatuses) => {
    setActiveTab(value)
    setIndex(0)
    setCurrentPage(0)
    setCursors([])
    setPayerId('')
    replace(`/billing/encounters/${value}`)
  }

  return (
    <Page
      title='Billing'
      headerContent={<BillingHeaderContent selectedPage='Encounters' />}
      pushHeaderContent={false}
    >
      <BannerPortal />
      <Tabs
        pt='md'
        pb='lg'
        value={activeTab}
        onTabChange={(value: EncounterStatuses) => onClickTab(value)}
      >
        <Tabs.List pl='lg'>
          <Tabs.Tab value={EncounterStatuses.Unsubmitted}>Unsubmitted</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.Flagged}>Flagged</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.Submitted}>Submitted</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.NotBillable}>Not billable</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.NotOnAllowList}>Not insurance pay</Tabs.Tab>
          <Tabs.Tab value={EncounterStatuses.AutoSubmitting}>Auto submitting</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <div className='grid grid-cols-3 mx-auto px-4 sm:px-6 lg:px-8 w-full'>
        <ListAndCardLayout
          list={
            encountersQuery.isFetching ? (
              <LoadingEncountersList length={8} />
            ) : (
              <EncounterList
                encounters={encounters}
                setEncounters={setEncounters}
                setIndex={setIndex}
                payerId={payerId}
                setPayerId={setPayerId}
                count={Number(encounterCountQuery.data)}
                encountersPerPage={per}
                setCursors={setCursors}
                setPage={setCurrentPage}
                page={currentPage}
              />
            )
          }
          emptyListMessage={
            !encountersQuery.isFetching && Number(encounterCountQuery.data) === 0 && !payerId
              ? 'There are currently no encounters.'
              : undefined
          }
          card={<EncounterCard />}
        />
      </div>

      {globalError && (
        <AAlertBanner onClose={() => setGlobalError('')} message={globalError} type='error' />
      )}
    </Page>
  )
}

export default BillingEncounters
