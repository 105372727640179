import { SecureInput } from '@shared/components'
import { useMedicationFormContext } from './formHelpers'

export type MPrescriptionPINFormProps = {
  requireTwoFac?: boolean
  error?: string
}

const MPrescriptionPINForm = ({ requireTwoFac = false, error }: MPrescriptionPINFormProps) => {
  const form = useMedicationFormContext()

  return (
    <div>
      <div className='my-4'>For your security, please enter the following information.</div>
      <SecureInput {...form.getInputProps('pin')} label='PIN' placeholder='4-digit PIN' />
      {requireTwoFac && (
        <SecureInput
          {...form.getInputProps('twoFacCode')}
          label='Two-Factor Code'
          placeholder='6-digit code'
        />
      )}
      {error && <div className='pt-8 text-error'>{error}</div>}
      {requireTwoFac && (
        <div className='pt-8 w-full border-b-2 mb-4 border-gray-200'>
          By completing the two-factor authentication protocol at this time, you are legally signing
          the prescription(s) and authorizing the transmission of the above information to the
          pharmacy for dispensing. The two-factor authentication protocol may only be completed by
          the practitioner whose name and DEA registration number appear above.
        </div>
      )}
    </div>
  )
}

export default MPrescriptionPINForm
