import cn from 'classnames'
import React, { PropsWithChildren } from 'react'

type Color = 'green' | 'red' | 'gray' | 'yellow'

type APillProps = {
  color: Color
}

const colorMap: Record<Color, string> = {
  green: cn('bg-daybreak-success1', 'text-black'),
  red: cn('bg-daybreak-error1', 'text-black'),
  gray: cn('bg-gray-100', 'text-gray-600'),
  yellow: cn('bg-daybreak-warning1', 'text-black'),
}

const APill = ({ children, color }: PropsWithChildren<APillProps>) => (
  <span
    className={cn(
      'px-2',
      'inline-flex',
      'text-xs',
      'leading-5',
      'font-semibold',
      'rounded-full',
      colorMap[color],
    )}
  >
    {children}
  </span>
)

export default APill
