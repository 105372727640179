import { Alert, Employee, Patient, PatientStatus } from '@shared/types'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form-latest'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { analytics } from '../../../analytics'
import { consultationQueueApi, emrApi, patientsApi } from '../../../api'
import ANote from '../../../components/atoms/ANote'
import IconClose from '../../../components/icons/IconClose'
import IconCollapse from '../../../components/icons/IconCollapse'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import ODSSecondaryButton from '../../../components/ods/ODSSecondaryButton'

export type MCloseConsultationNoteModalProps = {
  patientID: string
  visitID: string
  open: boolean
  closeModal: () => void
  patient: Patient | undefined
  currentUser: Employee
  setAlert: (alert: Alert) => void
  signNote: () => Promise<void>
}

type FormData = {
  noShow: boolean
}

const MCloseConsultationNoteModal = ({
  patientID,
  visitID,
  open,
  closeModal,
  patient,
  currentUser,
  setAlert,
  signNote,
}: MCloseConsultationNoteModalProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { register, handleSubmit } = useForm<FormData>()

  const [step, setStep] = useState(1)
  const [appointmentCompleted, setAppointmentCompleted] = useState(true)

  const cancelAppointment = useMutation(patientsApi.cancelAppointment)
  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  useEffect(() => {
    setStep(1)
  }, [open])

  const setPatientStatus = async (status: PatientStatus) => {
    await updatePatient.mutateAsync(
      {
        params: { patientId: patientID },
        data: {
          statuses: {
            patient: status,
          },
        },
      },
      {
        onSuccess: async () => {
          await signNote()
          setStep(step + 1)
          void queryClient.invalidateQueries(
            emrApi.getQuery('GET /patient/:patientId', { params: { patientId: patientID } })[0],
          )
        },
        onError: () => {
          setAlert({ message: 'Unable to set patient status.', type: 'error' })
        },
      },
    )
  }

  const openAcuity: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    analytics.track('Acuity opened to reschedule', {
      userId: currentUser.oid,
      userName: currentUser.name,
    })

    if (!patient?.personalData?.firstName || !patient?.personalData?.lastName) {
      window.open('https://secure.acuityscheduling.com/clients.php', '_blank')
    }

    window.open(
      `https://secure.acuityscheduling.com/clients.php?action=detail&firstName=${patient?.personalData?.firstName}&lastName=${patient?.personalData?.lastName}`,
      '_blank',
    )
  }

  const goToCalendar: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    if (currentUser.calendarId) {
      navigate(`/visits/${currentUser.calendarId}`)
    } else {
      navigate('/')
    }
  }

  const onCancelAppointment: SubmitHandler<FormData> = async data => {
    try {
      await cancelAppointment.mutateAsync({
        patientID,
        appointmentID: visitID,
        data: { noShow: data.noShow },
      })
      setAlert({ message: 'Successfully canceled this appointment.', type: 'success' })
      analytics.track('Appointment canceled via scheduling modal', {
        userId: currentUser.oid,
        userName: currentUser.name,
      })
      void queryClient.invalidateQueries(patientID)
      setStep(step + 1)
    } catch {
      setAlert({ message: 'Unable to cancel this appointment.', type: 'error' })
    }
  }

  const attemptToRemoveConsultationFromQueue = async () => {
    try {
      await consultationQueueApi.removeAppointment({ appointmentId: visitID })
    } catch (err) {
      // no-op - this is a mere attempt, hence non-2xx response codes are moot.
    }
  }

  const markCompleted: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    void attemptToRemoveConsultationFromQueue()
    setStep(step + 1)
  }

  const markNotCompleted: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    void attemptToRemoveConsultationFromQueue()
    void setAppointmentCompleted(false)
    setStep(step + 1)
  }

  const goBack: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    if (step === 2 && !appointmentCompleted) {
      setAppointmentCompleted(true)
    }
    setStep(step - 1)
  }

  return (
    <div className={`${open ? 'z-50' : 'z-0'} fixed inset-0`}>
      <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0'>
        {open && (
          <div className='fixed inset-0 transition-opacity'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </div>
        )}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>&#8203;
        {open && (
          <div
            className='w-64 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:p-6'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className='absolute top-0 right-0'>
              <button
                className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 m-1'
                onClick={closeModal}
              >
                <IconClose textColor='text-indigo-600' />
              </button>
            </div>
            {step > 1 && (
              <div className='absolute top-0 left-0'>
                <button
                  className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 m-1'
                  onClick={goBack}
                >
                  <IconCollapse />
                </button>
              </div>
            )}
            <div className='flex items-center justify-center space-x-8'>
              {step === 1 && (
                <ul className='flex items-center space-x-5'>
                  <li>
                    <div className='relative flex items-center justify-center'>
                      <span className='absolute w-5 h-5 p-px flex'>
                        <span className='w-full h-full rounded-full bg-indigo-200'></span>
                      </span>
                      <span className='relative block w-2.5 h-2.5 bg-indigo-600 rounded-full'></span>
                    </div>
                  </li>
                  <li className='block w-2.5 h-2.5 bg-gray-200 rounded-full' />
                  <li className='block w-2.5 h-2.5 bg-gray-200 rounded-full' />
                </ul>
              )}
              {step === 2 && (
                <ul className='flex items-center space-x-5'>
                  <li className='block w-2.5 h-2.5 bg-indigo-600 rounded-full' />
                  <li>
                    <div className='relative flex items-center justify-center'>
                      <span className='absolute w-5 h-5 p-px flex'>
                        <span className='w-full h-full rounded-full bg-indigo-200'></span>
                      </span>
                      <span className='relative block w-2.5 h-2.5 bg-indigo-600 rounded-full'></span>
                    </div>
                  </li>
                  <li className='block w-2.5 h-2.5 bg-gray-200 rounded-full' />
                </ul>
              )}
              {step === 3 && (
                <ul className='flex items-center space-x-5'>
                  <li className='block w-2.5 h-2.5 bg-indigo-600 rounded-full' />
                  <li className='block w-2.5 h-2.5 bg-indigo-600 rounded-full' />
                  <li>
                    <div className='relative flex items-center justify-center'>
                      <span className='absolute w-5 h-5 p-px flex'>
                        <span className='w-full h-full rounded-full bg-indigo-200'></span>
                      </span>
                      <span className='relative block w-2.5 h-2.5 bg-indigo-600 rounded-full'></span>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {step === 1 && (
              <div>
                <div className='flex items-center justify-center my-8'>
                  <p className='text-lg leading-7'>Did the visit happen?</p>
                </div>
                <div className='flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center w-full'>
                  <span className='flex mb-2 w-full rounded-md shadow-sm'>
                    <ODSSecondaryButton
                      onClick={markCompleted}
                      type='button'
                      message='Yes'
                      className='w-full justify-center'
                    />
                  </span>
                  <span className='flex mb-2 w-full rounded-md shadow-sm'>
                    <ODSSecondaryButton
                      onClick={markNotCompleted}
                      type='button'
                      message='No'
                      className='w-full justify-center'
                    />
                  </span>
                </div>
              </div>
            )}
            {step === 2 && appointmentCompleted && (
              <div>
                <div className='flex items-center justify-center my-8'>
                  <p className='text-lg leading-7'>What is the patient&apos;s status?</p>
                </div>
                <div className='flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center w-full'>
                  <span className='flex mb-2 w-full rounded-md shadow-sm'>
                    <ODSPrimaryButton
                      onClick={e => {
                        e.stopPropagation()
                        void setPatientStatus('candidate')
                        setStep(step + 1)
                      }}
                      type='button'
                      message='Candidate'
                      className='w-full justify-center'
                    />
                  </span>
                  <span className='flex mb-2 w-full rounded-md shadow-sm'>
                    <ODSSecondaryButton
                      onClick={e => {
                        e.stopPropagation()
                        void setPatientStatus('lead')
                        setStep(step + 1)
                      }}
                      type='button'
                      message='Lead'
                      className='w-full justify-center'
                    />
                  </span>
                  <span className='flex mb-2 w-full rounded-md shadow-sm'>
                    <ODSSecondaryButton
                      onClick={e => {
                        e.stopPropagation()
                        void setPatientStatus('not interested')
                        setStep(step + 1)
                      }}
                      type='button'
                      message='Not Interested'
                      className='w-full justify-center'
                    />
                  </span>
                  <span className='flex w-full rounded-md shadow-sm'>
                    <ODSSecondaryButton
                      onClick={e => {
                        e.stopPropagation()
                        void setPatientStatus('ineligible')
                        setStep(step + 1)
                      }}
                      type='button'
                      message='Ineligible'
                      className='w-full justify-center'
                    />
                  </span>
                  {patient?.statuses.patient === 'discharged' && (
                    <span className='flex w-full rounded-md shadow-sm'>
                      <ODSSecondaryButton
                        onClick={e => {
                          e.stopPropagation()
                          void setPatientStatus('discharged')
                          setStep(step + 1)
                        }}
                        type='button'
                        message='Discharged'
                        className='w-full justify-center'
                      />
                    </span>
                  )}
                </div>
              </div>
            )}
            {step === 2 && !appointmentCompleted && (
              <div className='flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center w-full'>
                <form onSubmit={handleSubmit(onCancelAppointment)}>
                  <div className='flex flex-col items-center justify-center'>
                    <div className='flex items-center justify-center mt-8 mb-4'>
                      <p className='text-lg leading-7'>Do you want to cancel this visit?</p>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='col-span-6'>
                      <ANote className='col-span-6 text-left mb-2'>
                        This will cancel the appointment and remove it from your calendar.
                      </ANote>
                      <div className='relative flex items-start'>
                        <div className='flex items-center h-5'>
                          <input
                            {...register('noShow')}
                            id='noShow'
                            type='checkbox'
                            className='focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                          />
                        </div>
                        <div className='w-full flex justify-start items-center ml-3 text-sm leading-5'>
                          <label htmlFor='noShow' className='font-medium text-gray-700'>
                            Mark as a no show.
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col items-center justify-center col-span-6'>
                      <ODSPrimaryButton
                        loading={cancelAppointment.isLoading}
                        className='inline-flex justify-center w-full'
                        message='Cancel'
                      />
                      <ODSSecondaryButton
                        type='button'
                        onClick={openAcuity}
                        className='inline-flex justify-center w-full mt-2'
                        message='Reschedule'
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {step === 3 && (
              <div>
                <div className='flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center w-full'>
                  <div className='flex flex-col items-center justify-center'>
                    <div className='flex items-center justify-center mt-8 mb-4'>
                      <p className='text-lg leading-7'>Great! You&apos;re all done.</p>
                    </div>
                  </div>

                  <span className='flex w-full rounded-md shadow-sm mt-4'>
                    <ODSSecondaryButton
                      onClick={goToCalendar}
                      type='button'
                      message='Go to the calendar'
                      className='w-full justify-center'
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MCloseConsultationNoteModal
