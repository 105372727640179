import { useEffect, useState } from 'react'

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)
  // If pressed key is our target key then set to true
  function downHandler({ key }: { key: string }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])
  return keyPressed
}

export const useKeyCombo = (keys: string[], callback: (arg0: boolean) => void) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const keyHooks = keys.map(key => useKeyPress(key))

  useEffect(() => {
    if (
      keyHooks.every(v => {
        return v
      })
    ) {
      callback(true)
    }
  }, keyHooks)
}
