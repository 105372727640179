import { Box, Center, Group, Loader, ScrollAreaAutosize, Stack } from '@shared/components'
import { Patient } from '@shared/types'
import { ForwardedRef, PropsWithChildren } from 'react'

type CommunicationsLogProps = {
  patient: Patient
  footer?: JSX.Element
  isLoading: boolean
  scrollableRef: ForwardedRef<HTMLDivElement>
  targetRef: ForwardedRef<HTMLDivElement>
}

export const CommunicationsLog = ({
  children,
  footer,
  isLoading,
  scrollableRef,
  targetRef,
}: PropsWithChildren<CommunicationsLogProps>) => {
  if (isLoading) {
    return (
      <Center w='100%' h='100%'>
        <Loader size='sm' />
      </Center>
    )
  }

  /*
   * Summary of communications log UI:
   * Footer is always visible and does not change size
   * Scroll area fills in the remaining space, and scrolls if its content exceeds its height
   * The scroll area automatically scrolls to the bottom on render
   */

  return (
    <Stack
      spacing={0}
      h='100%'
      w='100%'
      style={{
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <ScrollAreaAutosize
        style={{ flexGrow: 1, overflowY: 'auto' }}
        type='always'
        viewportRef={scrollableRef}
      >
        <Stack p='lg'>
          {children}
          {/* This is a dummy box for scrolling to the bottom of the chatbox */}
          <Box ref={targetRef} />
        </Stack>
      </ScrollAreaAutosize>
      <Group p={0} mb='auto' style={{ flexShrink: 0 }}>
        {footer}
      </Group>
    </Stack>
  )
}
