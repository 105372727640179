import { Employee, getErrorMessage, hasEmployeeStatus, isClinician } from '@shared/types'
import {
  GoogleAuthProvider,
  User,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { firebaseAuthApi, lunafirebaseAuthApi } from '../api'
import { auth } from '../firebase/app'
import * as FullStory from '../utils/fullstory'
import { useEmrQuery, useLunaMutation } from '../utils/hooks'

type Logout = () => Promise<void>
const SIGN_IN_TOKEN_PARAM_NAME = 'token'

const logOut: Logout = async () => {
  await auth.signOut()
  /**
   * After sign out, reload the page so potentially stale clients
   * are forced to fetch the latest version of the app (ie, JS bundle)
   * before signing back in again on the same browser session.
   * The goal is to cut down on long running sessions of stale app code.
   */
  window.location.reload()
}

export type Context = {
  isLoading: boolean
  isAuthenticated: boolean
  isAuthorized: boolean
  logOut: Logout
  signInWithPopup: () => Promise<void>
  signInWithGoogleIdToken: SignInWithGoogleIdToken
  currentUser: Employee
  firebaseUser: User | null | undefined
}

type SignInWithGoogleIdToken = (idToken: string) => Promise<void>

const signInWithGoogleIdToken: SignInWithGoogleIdToken = async token => {
  const credential = GoogleAuthProvider.credential(token)
  await signInWithCredential(auth, credential)
}

const initialContext: Context = {
  isLoading: true,
  isAuthenticated: false,
  isAuthorized: false,
  currentUser: {} as Employee,
  logOut,
  signInWithPopup: () => Promise.resolve(),
  signInWithGoogleIdToken,
  firebaseUser: null,
}

const AuthContext = createContext<Context>(initialContext)

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const [oauthToken, setOauthToken] = useState<string | null>(null)
  const [firebaseUser, setFirebaseUser] = useState<User | null>()
  const uid = firebaseUser?.uid || ''
  const isAuthenticated = Boolean(firebaseUser)
  const loginToken = searchParams.get(SIGN_IN_TOKEN_PARAM_NAME)
  const signInFirebaseAuth = useMutation(({ token }: { token: string }) =>
    signInWithCustomToken(auth, token),
  )
  const navigate = useNavigate()
  const isProdOrigin = window.location.origin === 'https://emr.ophelia.com'

  const syncCalendar = useLunaMutation('POST /employees/:employeeId/sync-calendar', {
    onSuccess: () => {
      /**
       * To prevent the OAuth token from being submitted to the server
       * multiple times, we clear it after the first successful sync.
       */
      setOauthToken(null)
    },
  })

  useEffect(() => {
    // No reason to use custom sign in token at the production origin
    if (isProdOrigin) {
      return
    }

    if (signInFirebaseAuth.isLoading) {
      return
    }

    if (loginToken) {
      // Remove token search param and update URL
      searchParams.delete(SIGN_IN_TOKEN_PARAM_NAME)
      navigate(`/login?${searchParams.toString()}`)
      signInFirebaseAuth.mutate({ token: loginToken })
    }
  }, [navigate, loginToken, searchParams, signInFirebaseAuth, isProdOrigin])

  const employeeQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: {
        employeeId: uid,
      },
    },
    {
      enabled: isAuthenticated,
      onSuccess: data => {
        /**
         * After the authenticated user's employee document is fetched,
         * we check if they are a clinician and have an OAuth token.
         * If so, we send the OAuth token to the server to sync their
         * Google calendar with the Acuity calendar.
         */
        if (isClinician(data) && oauthToken) {
          syncCalendar.mutate({
            params: {
              employeeId: data.oid,
            },
            data: {
              oauthToken,
            },
          })
        }
      },
    },
  )

  const currentUser = employeeQuery.data

  const hasEmployeeDoc = Boolean(currentUser)
  const isAuthorized = hasEmployeeDoc && hasEmployeeStatus(currentUser, 'currentEmployee')

  const isLoading = firebaseUser === undefined || employeeQuery.isLoading

  const _signInWithGoogleIdToken = useCallback<SignInWithGoogleIdToken>(async idToken => {
    await signInWithGoogleIdToken(idToken)
  }, [])

  const _signInWithPopup = useCallback(async () => {
    const provider = new GoogleAuthProvider()

    /**
     * Request access to the user's calendar. This allows
     * us to create events on their behalf using the OAuth
     * token we receive from the Google Sign-In.
     */
    provider.addScope('https://www.googleapis.com/auth/calendar.events')

    try {
      const userCredential = await signInWithPopup(auth, provider)
      /**
       * This is a hack to get the access token from the
       * userCredential object. The Firebase SDK types don't
       * expose the access token directly, even though it's
       * available in the underlying object.
       */
      const typedUserCredential = userCredential as unknown as {
        _tokenResponse?: {
          oauthAccessToken?: string
        }
      }

      setOauthToken(typedUserCredential?._tokenResponse?.oauthAccessToken || null)
    } catch (err) {
      const errorMessage = getErrorMessage(err, 'Sign In With Popup Failed')
      FullStory.log('error', errorMessage)
    }
  }, [])

  // Enusre that the user is fundamentally auth'd
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(user => {
      if (user) {
        setFirebaseUser(user)
        firebaseAuthApi.setUser(user)
        lunafirebaseAuthApi.setUser(user)
      } else {
        setFirebaseUser(null)
        firebaseAuthApi.setUser(null)
        lunafirebaseAuthApi.setUser(null)
        queryClient.clear()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const context = {
    isLoading,
    isAuthenticated,
    isAuthorized,
    currentUser: currentUser || ({} as Employee),
    firebaseUser,
    logOut,
    signInWithGoogleIdToken: _signInWithGoogleIdToken,
    signInWithPopup: _signInWithPopup,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
