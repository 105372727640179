import { useBanner } from '@shared/components'
import { Patient } from '@shared/types'
import { name } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../api'
export const useAddToRegistryMutation = ({ patient }: { patient?: Patient }) => {
  const queryClient = useQueryClient()
  const { showBanner } = useBanner()
  return useMutation(emrApi.getMutation('POST /cocmRegistry'), {
    onSuccess: () => {
      showBanner({
        label: `${name({
          first: patient?.personalData.firstName,
          last: patient?.personalData.lastName,
        }).full()} was successfully added to the CoCM registry`,
        type: 'success',
        dismissable: true,
      })
      void queryClient.invalidateQueries('GET /cocmRegistry/patient/:patientId')
    },
    onError: () => {
      showBanner({
        label: `Error adding ${name({
          first: patient?.personalData.firstName,
          last: patient?.personalData.lastName,
        }).full()} to the CoCM registry.`,
        type: 'error',
        dismissable: true,
      })
      void queryClient.invalidateQueries('GET /cocmRegistry/patient/:patientId')
    },
  })
}
