import { Box, Group, Skeleton, Stack, useMantineTheme } from '@shared/components'

const AFakeActionItem = () => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <Box
      sx={{
        backgroundColor: colors.background[0],
        paddingLeft: sizes.gap.xl,
        paddingRight: sizes.gap.xl,
        paddingTop: sizes.padding.lg,
        paddingBottom: sizes.padding.lg,
        borderTop: `${sizes.border.md} solid ${colors.background[1]}`,
        borderBottom: `${sizes.border.md} solid ${colors.background[1]}`,
      }}
    >
      <Group position='apart' align='center'>
        <Stack spacing='sm'>
          <Skeleton height={10} width={100} radius='xl' />
          <Skeleton height={15} width={200} radius='xl' />
          <Group>
            <Skeleton height={15} circle />
            <Skeleton height={10} width={50} radius='xl' />
            <Skeleton height={15} circle />
            <Skeleton height={10} width={50} radius='xl' />
          </Group>
        </Stack>
        <Skeleton height={35} circle />
      </Group>
    </Box>
  )
}

export default AFakeActionItem
