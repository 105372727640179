import { LoadingOverlay, Modal, Stack, Text, TitleThree } from '@shared/components'
import orderBy from 'lodash/orderBy'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatientVisitNote } from './PatientVisitNoteContext'

type VersionHistoryModalProps = {
  opened: boolean
  onClose: () => void
}

// TODO: get updated design from Dhwani
export const VersionHistoryModal = ({ opened, onClose }: VersionHistoryModalProps) => {
  const { patientId, visitNoteId } = usePatientVisitNote()

  const { data: versionHistoryData, isLoading } = useEmrQuery(
    'GET /patient/:patientId/visits/:visitId/notes/history',
    {
      params: {
        patientId,
        visitId: `${visitNoteId}`,
      },
    },
  )

  if (!versionHistoryData?.length) {
    return null
  }

  const sortedHistory = orderBy(versionHistoryData, data => data.createdAt, ['desc'])

  return (
    <Modal opened={opened} onClose={onClose}>
      {isLoading && <LoadingOverlay visible />}
      <Stack>
        <TitleThree>Visit Note change history</TitleThree>
        <Text>Sorted by timestamp, most recent first</Text>
        {sortedHistory.map(note => (
          <Stack key={note.createdAt}>
            <Text>{note.createdAt}</Text>
            <Text>{JSON.stringify(note, null, 4)}</Text>
          </Stack>
        ))}
      </Stack>
    </Modal>
  )
}
