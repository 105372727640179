import { Radio, RadioGroup, Stack, Text } from '@shared/components'
import { InsuranceData, InsuranceType, ManualInsuranceData } from '@shared/types'
import { useState } from 'react'
import IRQEligibleVerificationDrawer from './IRQEligibleVerificationDrawer'
import IRQManualVerificationDrawer from './IRQManualVerificationDrawer'

type DrawerType = 'eligible' | 'manual'

const VerifyMethodRadioGroup = ({
  value,
  onChange,
}: {
  value: DrawerType
  onChange: (value: DrawerType) => void
}) => {
  return (
    <Stack spacing='sm'>
      <Text bold>Select a method</Text>
      <RadioGroup value={value} onChange={onChange}>
        <Radio value='eligible' label='Verify by Eligible.com' />
        <Radio value='manual' label='Verify manually' />
      </RadioGroup>
    </Stack>
  )
}

export type InsuranceVerificationDrawerProps = {
  opened: boolean
  patientId: string
  insuranceData?: InsuranceData | null
  manualInsuranceData?: ManualInsuranceData | null
  insuranceType: InsuranceType
  frontFileId?: string
  backFileId?: string
  onClose: () => void
  onSave: () => void
}

export const InsuranceVerificationDrawer = (props: InsuranceVerificationDrawerProps) => {
  const [drawerType, setDrawerType] = useState<DrawerType>('eligible')

  if (drawerType === 'eligible') {
    return (
      <IRQEligibleVerificationDrawer
        {...props}
        verifyMethodRadioGroup={
          <VerifyMethodRadioGroup value={drawerType} onChange={setDrawerType} />
        }
      />
    )
  }
  return (
    <IRQManualVerificationDrawer
      {...props}
      verifyMethodRadioGroup={
        <VerifyMethodRadioGroup value={drawerType} onChange={setDrawerType} />
      }
    />
  )
}
