import { Patient } from '@shared/types'
import { phone } from '@shared/utils'

const CommandPalettePreview = ({
  activeOption,
  onPatientSelect,
}: {
  activeOption: Patient
  onPatientSelect: (patient: Patient) => void
}) => {
  return (
    <div className='col-span-2 hidden h-96 flex-none flex-col overflow-y-auto sm:flex'>
      <div className='flex-none px-6 pt-6 text-left'>
        <h2 className='mt-3 font-semibold text-gray-900 text-xl'>{`${activeOption.personalData?.firstName} ${activeOption.personalData?.lastName}`}</h2>
      </div>
      <button
        onClick={() => onPatientSelect(activeOption)}
        className='absolute bottom-0 right-0 text-sm p-6 z-50 font-semibold text-indigo-600 hover:text-indigo-500 focus:outline-none content-right no-underline'
      >
        Go to chart <span aria-hidden='true'>&rarr;</span>
      </button>
      <div className='flex flex-auto flex-col justify-between p-6'>
        <dl className='grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700'>
          {activeOption.oid && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>User ID</dt>
              <dd>{activeOption.oid}</dd>
            </>
          )}
          {activeOption.personalData?.phone && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Phone</dt>
              <dd>{phone(String(activeOption.personalData?.phone)).formatted}</dd>
            </>
          )}
          {activeOption.personalData?.email && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Email</dt>
              <dd className='truncate'>
                <a
                  href={`mailto:${activeOption.personalData?.email}`}
                  className='text-indigo-600 underline hover:text-indigo-500 focus:outline-none'
                >
                  {activeOption.personalData?.email}
                </a>
              </dd>
            </>
          )}
          {activeOption.personalData?.birthday && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Date of birth</dt>
              <dd>{activeOption.personalData?.birthday}</dd>
            </>
          )}
          {activeOption.shippingData?.state && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>State</dt>
              <dd>{activeOption.shippingData?.state}</dd>
            </>
          )}
          {activeOption.statuses?.patient && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Patient status</dt>
              <dd className='capitalize'>{activeOption.statuses?.patient}</dd>
            </>
          )}
          {activeOption.primaryClinician?.name && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Prescribing clinician</dt>
              <dd>{activeOption.primaryClinician?.name}</dd>
            </>
          )}
          {activeOption.nurseCareManager?.name && (
            <>
              <dt className='col-end-1 font-semibold text-gray-900'>Clinical care manager</dt>
              <dd>{activeOption.nurseCareManager?.name}</dd>
            </>
          )}
        </dl>
      </div>
    </div>
  )
}

export default CommandPalettePreview
