import React from 'react'

export type ANoteSectionProps = {
  children?: React.ReactNode
  hideBorder?: boolean
  standoutBorder?: boolean
  className?: string
}

const ANoteSection = ({
  children,
  hideBorder,
  standoutBorder,
  className = '',
}: ANoteSectionProps) => {
  const borderStyle = hideBorder
    ? ''
    : `border-t mt-6 ${standoutBorder ? 'border-gray-600' : 'border-gray-200'}`

  return <div className={`pt-6 pb-12 ${borderStyle} ${className}`}>{children}</div>
}

export default ANoteSection
