import { useBanner } from '@shared/components'
import { Appointment, AppointmentTypeString, Patient } from '@shared/types'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import { useEmrQuery } from '../../utils/hooks'
import { useSidePane } from '../../utils/hooks/use-side-pane'
import { IssueDrawer } from '../care_team/tasks/IssueDrawer'
import { usePatient } from '../patient/PPatientContext'
import { ConfirmNoInsurance } from './ConfirmNoInsuranceStep'
import { PickScheduleTypeStep } from './PickScheduleTypeStep'
import { PickSlotStep } from './PickSlotStep'
import { PickVisitTypeStep } from './PickVisitTypeStep'

export type OScheduleVisitModalProps = {
  closeModal: () => void
  patientId: string
  rescheduleAppointment?: Appointment | undefined
  daysSinceLastVisitWithPc?: number | null
  visitTypesAllowed?: AppointmentTypeString[]
}

export const OScheduleVisitModal = ({
  closeModal,
  patientId,
  rescheduleAppointment,
  daysSinceLastVisitWithPc = -1,
  visitTypesAllowed,
}: OScheduleVisitModalProps) => {
  const { showBanner } = useBanner()
  const { presentPane, hidePane } = useSidePane()
  const { setModal } = usePatient()
  const [appointmentTypeId, setAppointmentTypeId] = useState<string>(
    rescheduleAppointment?.appointmentTypeID?.toString() || '',
  )

  const isUdsOrCheckIn =
    visitTypesAllowed?.length === 2 &&
    visitTypesAllowed.includes('Check-In Call') &&
    visitTypesAllowed.includes('UDS Visit')
  const getInitialStep = () => {
    if (rescheduleAppointment) {
      return 'schedule'
    }

    if (isUdsOrCheckIn) {
      return 'schedule type'
    }

    return 'visit'
  }

  // For rescheduling appointments, skip to visit step
  const [step, setStep] = useState<
    'schedule type' | 'visit' | 'confirm' | 'schedule' | 'close winback'
  >(getInitialStep())

  const { currentUser } = useAuth()

  const patientQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId', {
      params: {
        patientId,
      },
    }),
  )

  const patient = patientQuery.data

  const primaryInsuranceQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurance/:insuranceId', {
      params: {
        patientId,
        insuranceId: patient?.insuranceData?.primaryInsuranceId ?? '',
      },
    }),
    {
      enabled: Boolean(patient?.insuranceData?.primaryInsuranceId),
    },
  )

  const patientPrimaryInsurance = primaryInsuranceQuery?.data

  const issuesQuery = useEmrQuery(
    'GET /patient/:patientId/issues',
    {
      params: { patientId: patient?.oid as Patient['oid'] },
    },
    {
      enabled: Boolean(patient),
    },
  )
  const issuesData = issuesQuery.data || []

  const openWinbacks = issuesData.filter(
    issue =>
      issue.type === 'winback_call' && (issue.status === 'open' || issue.status === 'snoozed'),
  )

  useEffect(() => {
    if (step === 'close winback' && openWinbacks.length === 1) {
      presentPane({
        key: `close-winback-${openWinbacks[0]?.oid}`,
        content: (
          <IssueDrawer step='notes' taskId={openWinbacks[0]?.oid || ''} onClose={hidePane} />
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWinbacks, step])

  if (!patient) {
    return null
  }

  return (
    <>
      {step === 'schedule type' && (
        <PickScheduleTypeStep
          closeModal={closeModal}
          onNext={({ scheduleType }) => {
            if (scheduleType === 'any') {
              setStep('visit')
              return
            }

            closeModal()
            setModal({
              type: 'schedule-visit-calendar',
              props: {
                visitTypesAllowed,
              },
            })
          }}
          clinicianType='TN'
        />
      )}
      {step === 'visit' && (
        <PickVisitTypeStep
          firstName={patient.personalData.firstName}
          patientId={patient.oid}
          closeModal={closeModal}
          onNext={({ appointmentTypeId, appointmentType }) => {
            setAppointmentTypeId(appointmentTypeId)
            if (
              appointmentType === 'Initial visit' &&
              !patientPrimaryInsurance?.basicInsuranceData?.provider
            ) {
              setStep('confirm')
            } else {
              setStep('schedule')
            }
          }}
          onBack={isUdsOrCheckIn ? () => setStep('schedule type') : undefined}
          daysSinceLastVisitWithPc={daysSinceLastVisitWithPc}
          visitTypesAllowed={visitTypesAllowed}
        />
      )}
      {step === 'confirm' && (
        <ConfirmNoInsurance
          closeModal={closeModal}
          onNext={({ appointmentTypeId }) => {
            setAppointmentTypeId(appointmentTypeId)
            setStep('schedule')
          }}
          appointmentTypeId={appointmentTypeId}
        />
      )}
      {step === 'schedule' && (
        <PickSlotStep
          closeModal={closeModal}
          setStep={setStep}
          rescheduleAppointment={rescheduleAppointment}
          appointmentTypeId={appointmentTypeId}
          onBack={() => setStep('visit')}
          patientId={patient.oid}
          hasInsurance={Boolean(patientPrimaryInsurance?.basicInsuranceData?.provider)}
          openWinbacks={openWinbacks}
          onSuccess={({ datetime, employee, appointmentTypeName, appointmentTypeId }) => {
            analytics.track('Appointment scheduled via scheduling modal', {
              appointmentType: appointmentTypeId,
              calendar: employee?.calendarId ?? '',
              clientDate: datetime.format('MM/DD/YYYY'),
              clientTime: datetime.format('h:mma z'),
              userId: currentUser.oid,
              userName: currentUser.name,
            })

            showBanner({
              type: 'success',
              label: `${appointmentTypeName} successfully scheduled`,
              dismissable: true,
            })
            if (openWinbacks.length === 1) {
              setStep('close winback')
            }
          }}
        />
      )}
    </>
  )
}
