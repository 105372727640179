import { Stack } from '@shared/components'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { analytics } from '../../../analytics'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import DrugScreenSection from './DrugScreenSection'
import LabOrderSection from './LabOrderSection'
import { usePatient } from '../PPatientContext'

export const PatientLabsContent = () => {
  const { currentUser } = useAuth()
  const { patientID } = usePatient()

  const [labOrdersQueryKey, labOrdersQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/shipments',
    { params: { patientId: patientID } },
  )

  const labOrdersQuery = useQuery(labOrdersQueryKey, labOrdersQueryFunction, {
    enabled: Boolean(patientID),
  })

  const labOrders = labOrdersQuery.data

  const [drugScreensQueryKey, drugScreensQueryFunction] = emrApi.getQuery(
    'GET /patient/:patientId/drugScreens',
    { params: { patientId: patientID } },
  )

  const drugScreensQuery = useQuery(drugScreensQueryKey, drugScreensQueryFunction, {
    enabled: Boolean(patientID),
  })

  const drugScreens = drugScreensQuery.data

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!patientID || !currentUser) {
      return
    }

    analytics.page({
      name: 'Labs',
      properties: {
        patientId: patientID,
        userId: currentUser.oid,
        userName: currentUser.name,
      },
    })
  }, [patientID, currentUser])

  return (
    <Stack px='lg' py='md'>
      <DrugScreenSection drugScreens={drugScreens} isLoading={drugScreensQuery.isLoading} />
      <LabOrderSection labOrders={labOrders} isLoading={labOrdersQuery.isLoading} />
    </Stack>
  )
}
