import { useBanner, useLifecycle } from '@shared/components'
import { phone } from '@shared/utils'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { emrApi } from '../../api'
import { postFlexChartViewedMessage } from '../utils'

export const usePatientDirectLink = () => {
  const [params] = useSearchParams()
  const hasPhoneQueryParam = params.has('phoneNumber')
  const phoneQueryParam = params.get('phoneNumber')
  const iframeNavigationParam = params.get('iframeNavigation')
  const { showBanner, hideBanner } = useBanner()
  const navigate = useNavigate()

  const [getPatientIdQueryKey, getPatientIdQueryFunction] = emrApi.getQuery('GET /patient/id', {
    query: { phoneNumber: phoneQueryParam ?? '' },
  })

  const patientIdQuery = useQuery(getPatientIdQueryKey, getPatientIdQueryFunction, {
    enabled: hasPhoneQueryParam,
    onSuccess: data => {
      if (data.patientId) {
        return navigate(
          `/patients/${data.patientId}${
            iframeNavigationParam ? `?iframeNavigation=${iframeNavigationParam}` : ''
          }`,
        )
      }

      /*
       * This message keeps the Flex conversation link sharing working.
       * It uses the phone number in the URL to load the correct chart initially
       */
      postFlexChartViewedMessage(phoneQueryParam)
      showBanner({
        type: 'warning',
        label: `We don't have a patient with the phone number ${
          phone(phoneQueryParam ?? '').formatted
        }`,
        dismissable: true,
      })
    },
  })

  useLifecycle({
    onUnmount: hideBanner,
  })

  return { isLoading: patientIdQuery.isLoading }
}
