import { Radio, RadioGroup, Stack } from '@shared/components'
import { NameAndId, OnCallProvider, Patient, states } from '@shared/types'
import { useEffect, useState } from 'react'
import ClinicianSelect from './ClinicianSearchBar'

export type QueuedForIdSelectorProps = {
  pc: NameAndId
  ocp?: OnCallProvider | null
  patient: Patient
  onChange: (clinicianId: string) => void
  value: string
  error?: string
}
type ClinicianType = 'Alternate Clinician' | 'Patient PC' | 'OCP'

const QueuedForIdSelector = ({
  pc,
  ocp,
  patient,
  onChange,
  value,
  error,
}: QueuedForIdSelectorProps) => {
  const [selectedClinicianType, setSelectedClinicianType] = useState<ClinicianType>()
  const patientState = patient.homeData?.state || patient.shippingData?.state
  const stateAbbreviation = states.find(element => element.state === patientState)?.abbr

  // Sets the initial selected clinician type. This should only run on initial render and when the ocp loads
  useEffect(() => {
    if (value && value === ocp?.employeeId) {
      setSelectedClinicianType('OCP')
    } else if (value && value === pc.id) {
      setSelectedClinicianType('Patient PC')
    } else if (value) {
      setSelectedClinicianType('Alternate Clinician')
    }
  }, [ocp])

  return (
    <Stack>
      <RadioGroup
        label='Where should we queue this prescription?'
        onChange={(val: ClinicianType) => {
          if (val === 'Patient PC') {
            onChange(pc.id)
          } else if (val === 'OCP') {
            onChange(ocp?.employeeId || '')
          }
          setSelectedClinicianType(val)
        }}
        value={selectedClinicianType}
        error={selectedClinicianType === 'Alternate Clinician' ? '' : error}
      >
        <Radio label={`Patient's prescribing clinician: ${pc.name}`} value='Patient PC' />
        <Radio
          key={pc.id}
          label={`On-call provider: ${ocp?.employeeName || 'Not found'}`}
          value='OCP'
          disabled={!ocp}
        />
        <Radio label='Alternate prescribing clinician' value='Alternate Clinician' />
      </RadioGroup>
      {selectedClinicianType === 'Alternate Clinician' && (
        <ClinicianSelect
          label='Who would you like to queue this prescription for?'
          licensedState={stateAbbreviation}
          value={value === pc.id ? '' : value}
          onChange={clinicianId => {
            onChange(clinicianId)
          }}
          error={selectedClinicianType === 'Alternate Clinician' ? error : ''}
        />
      )}
    </Stack>
  )
}
export default QueuedForIdSelector
