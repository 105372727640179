import React from 'react'

export type ANoteSubheaderProps = {
  text: React.ReactNode
  className?: string
}

const ANoteSubheader = ({ text, className }: ANoteSubheaderProps) => {
  return <p className={`${className ?? ''} mt-1 text-sm leading-5 text-gray-500`}>{text}</p>
}

export default ANoteSubheader
