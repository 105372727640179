import {
  Group,
  List,
  ListItem,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  useBanner,
} from '@shared/components'
import { useForm } from '@mantine/form'
import { isRequired } from '../../../../utils/formValidation'
import { validCharacters } from '@shared/utils'
import { getOpheliaHttpError } from '@shared/types'
import { useInvalidateSubcollections } from '../use-invalidate-subcollections'
import { useEmrMutation } from '../../../../utils/hooks'

export const TransferSubcollectionsModal = ({
  opened,
  onClose,
  onSubmit,
  patientId,
  subcollections,
}: {
  opened: boolean
  onClose: () => void
  patientId: string
  subcollections: string[]
  onSubmit: () => void
}) => {
  const { showBanner } = useBanner()
  const invalidate = useInvalidateSubcollections()

  const { getInputProps, values, validate } = useForm({
    initialValues: { toPatientId: '' },
    validate: { toPatientId: isRequired },
  })

  const transferSubcollections = useEmrMutation('PUT /patient/:patientId/subcollections/transfer')

  return (
    <Modal
      title='Transfer subcollections'
      opened={opened}
      onClose={() => {
        if (!transferSubcollections.isLoading) {
          onClose()
        }
      }}
      footer={
        <Group position='right'>
          <SecondaryButton disabled={transferSubcollections.isLoading} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={transferSubcollections.isLoading}
            onClick={async () => {
              if (!validate().hasErrors) {
                await transferSubcollections.mutateAsync(
                  {
                    params: { patientId },
                    data: { toPatientId: values.toPatientId, subcollections },
                  },
                  {
                    onError: error => {
                      showBanner({
                        type: 'error',
                        dismissable: true,
                        label: getOpheliaHttpError(error, 'Sorry, something went wrong'),
                      })
                    },
                  },
                )

                invalidate({ patientId, subcollections })
                invalidate({ patientId: values.toPatientId, subcollections })

                onClose()
                onSubmit()
              }
            }}
          >
            Transfer
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='sm'>
        <Text>The following subcollection(s) will be transfered:</Text>
        <List>
          {subcollections.map(value => (
            <ListItem key={value}>{value}</ListItem>
          ))}
        </List>
        <TextInput
          label='Patient ID'
          placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
          explanation='Patient to transfer selected subcollections'
          formatter={validCharacters.id}
          {...getInputProps('toPatientId')}
        />
      </Stack>
    </Modal>
  )
}
