import { useForm } from '@mantine/form'
import { Grid, Stack } from '@shared/components'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { CardHeader } from '../components/CardHeader'
import { CardTextInput } from '../components/CardTextInput'

type MiscellaneousData = {
  prior_authorization_number?: string
}

export type MiscellaneousSectionProps = {
  isEditing: boolean
  setEditing: (bool: boolean) => void
  editButtonEnabled: boolean
  miscellaneousData: MiscellaneousData
  encounterId: string
}

export const MiscellaneousSection = ({
  isEditing,
  setEditing,
  editButtonEnabled,
  miscellaneousData,
  encounterId,
}: MiscellaneousSectionProps) => {
  const queryClient = useQueryClient()
  const form = useForm<MiscellaneousData>({
    initialValues: {
      prior_authorization_number: miscellaneousData.prior_authorization_number,
    },
    clearInputErrorOnChange: false,
  })
  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId },
  })

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  const saveMiscellaneousData = (data: MiscellaneousData) => {
    updateEncounter.mutate(
      {
        data: {
          encounter: {
            prior_authorization_number: data.prior_authorization_number,
          },
          id: encounterId,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(encounterQueryKey)
          setEditing(false)
        },
      },
    )
  }

  useEffect(() => {
    form.setValues(miscellaneousData)
  }, [miscellaneousData])

  return (
    <Stack>
      <CardHeader
        title='Miscellaneous'
        showButton={editButtonEnabled}
        buttonOnClick={() => setEditing(true)}
        buttonLabel='Edit'
      />
      <Grid columns={12}>
        <Grid.Col span={4}>
          <CardTextInput
            label='Prior authorization number'
            {...form.getInputProps('prior_authorization_number')}
            editable={isEditing}
            placeholder='1234716282920'
          />
        </Grid.Col>
      </Grid>
      {isEditing ? (
        <EditableSectionFooter
          onCancel={() => {
            setEditing(false)
            form.setValues(miscellaneousData)
          }}
          onSave={form.onSubmit(() => {
            saveMiscellaneousData(form.values)
          })}
          isSaving={updateEncounter.isLoading}
        />
      ) : null}
    </Stack>
  )
}
