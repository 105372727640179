import { AlertIcon, Box, Group, Stack, Text, UnstyledButton } from '@shared/components'
import { Employee, hasGroupRole, isClinician, roleName } from '@shared/types'
import { anonymousEmployeeImageUrl } from '../../utils/utils'

const calculateMissingFields = (employee: Employee) => {
  const fields = []
  if (isClinician(employee) && !employee.doseSpotId) {
    fields.push('DoseSpot Id')
  }
  if (hasGroupRole(employee, 'clinician') && !employee.calendarId) {
    fields.push('Acuity Calendar Id')
  }
  if (isClinician(employee) && (!employee.legalFirstName || !employee.legalLastName)) {
    fields.push('Legal name')
  }
  return fields
}

export type EmployeeSidebarRowProps = {
  employee: Employee
  disabled: boolean
  onClick: () => void
}

const EmployeeSidebarRow = ({ employee, disabled, onClick }: EmployeeSidebarRowProps) => {
  const missingFields = calculateMissingFields(employee)

  return (
    <li key={employee.oid}>
      <UnstyledButton
        sx={theme => ({
          width: '100%',
          cursor: disabled ? 'none' : 'pointer',
          '&:hover': {
            backgroundColor: theme.other.colors.background[2],
          },
        })}
        onClick={
          disabled
            ? () => {
                /* */
              }
            : onClick
        }
      >
        <Box p='md'>
          <Group>
            <img
              className='h-10 w-10 rounded-full'
              src={employee.profilePhotoURL ? employee.profilePhotoURL : anonymousEmployeeImageUrl}
              alt='employee profile'
            />
            <Stack spacing='xs'>
              <Text>{employee.name}</Text>
              <Text color={({ text }) => text[1]} size='xs'>
                {roleName[employee.role]}
              </Text>
            </Stack>
          </Group>
          {missingFields.length > 0 && (
            <Box pt='md' pl='sm'>
              {missingFields.map(field => (
                <Group key={field} spacing='xs'>
                  <AlertIcon color={colors => colors.error[0]} />
                  <Text pl='xs'>{field} is missing for this employee.</Text>
                </Group>
              ))}
            </Box>
          )}
        </Box>
      </UnstyledButton>
    </li>
  )
}

export default EmployeeSidebarRow
