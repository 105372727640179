import { Alert, ArchiveIcon, Flex, PrimaryButton, Skeleton, Stack, XIcon } from '@shared/components'
import { useState } from 'react'
import { useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { CollectionsColumn } from './collections/CollectionsColumn'
import { DocumentsColumn } from './collections/DocumentsColumn'
import { JsonColumn } from './collections/JsonColumn'
import { ArchivePatientChartModal } from './modals/ArchivePatientChartModal'
import { DeleteCollectionDocumentsModal } from './modals/DeleteCollectionDocumentsModal'
import { DeleteCollectionsModal } from './modals/DeleteCollectionsModal'
import { SaveCollectionDocumentModal } from './modals/SaveCollectionDocumentModal'
import { TransferCollectionDocumentsModal } from './modals/TransferCollectionDocumentsModal'
import { TransferCollectionsModal } from './modals/TransferCollectionsModal'

export const CollectionsSection = () => {
  const { patientId } = usePatient()
  const [selectedCollection, setSelectedCollection] = useState('')
  const [selectedCollectionItem, setSelectedCollectionItem] = useState('')
  const [editing, setEditing] = useState<'collections' | 'documents' | 'json' | null>(null)

  const [showModal, setShowModal] = useState<
    | {
        type: 'collection' | 'collection-documents'
        operation: 'delete' | 'transfer' | 'archive'
        list: string[]
      }
    | { type: 'document'; operation: 'save'; document: object }
    | null
  >(null)

  const collectionsQuery = useEmrQuery(
    'GET /patient/:patientId/collections',
    { params: { patientId } },
    {
      onSuccess: list => setSelectedCollection(list[0] ?? ''),
    },
  )

  const collectionItemsQuery = useEmrQuery(
    'GET /patient/:patientId/collections/:collection',
    {
      params: { patientId, collection: selectedCollection },
    },
    {
      enabled: Boolean(selectedCollection),
      onSuccess: list => setSelectedCollectionItem(list[0] ?? ''),
    },
  )

  const collectionDocumentQuery = useEmrQuery(
    'GET /patient/:patientId/collections/:collection/:documentId',
    {
      params: {
        patientId,
        collection: selectedCollection,
        documentId: selectedCollectionItem,
      },
    },
    { enabled: Boolean(selectedCollection) },
  )

  return (
    <Stack>
      <Skeleton visible={collectionsQuery.isLoading || collectionsQuery.isError}>
        <PrimaryButton
          size='xs'
          leftIcon={<ArchiveIcon />}
          onClick={() =>
            setShowModal({ type: 'collection', operation: 'archive', list: ['patients'] })
          }
        >
          Archive patient chart
        </PrimaryButton>
        {collectionsQuery.data?.length === 0 ? (
          <Alert variant='primary' icon={<XIcon />}>
            No collections found
          </Alert>
        ) : (
          <Flex h='32rem' sx={({ spacing }) => ({ gap: spacing.xs })}>
            <CollectionsColumn
              list={collectionsQuery.data}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
              editing={editing}
              toggleIsEditing={() =>
                setEditing(value => (value === 'collections' ? null : 'collections'))
              }
              onDelete={list => setShowModal({ type: 'collection', operation: 'delete', list })}
              onTransfer={list => setShowModal({ type: 'collection', operation: 'transfer', list })}
            />
            <DocumentsColumn
              list={collectionItemsQuery.data}
              selectedCollectionItem={selectedCollectionItem}
              setSelectedCollectionItem={setSelectedCollectionItem}
              editing={editing}
              toggleIsEditing={() =>
                setEditing(value => (value === 'documents' ? null : 'documents'))
              }
              onDelete={list =>
                setShowModal({ type: 'collection-documents', operation: 'delete', list })
              }
              onTransfer={list =>
                setShowModal({ type: 'collection-documents', operation: 'transfer', list })
              }
              isLoading={collectionItemsQuery.isLoading}
              isError={collectionItemsQuery.isError}
            />
            <JsonColumn
              documentId={selectedCollectionItem}
              documentData={collectionDocumentQuery.data}
              editing={editing}
              toggleIsEditing={() => setEditing(value => (value === 'json' ? null : 'json'))}
              isLoading={collectionDocumentQuery.isLoading}
              isError={collectionDocumentQuery.isError}
              onSave={document => setShowModal({ type: 'document', operation: 'save', document })}
            />
          </Flex>
        )}
      </Skeleton>
      <ArchivePatientChartModal
        opened={showModal?.type === 'collection' && showModal.operation === 'archive'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        onSubmit={() => setEditing(null)}
      />
      <TransferCollectionsModal
        opened={showModal?.type === 'collection' && showModal.operation === 'transfer'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        collections={showModal?.type === 'collection' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <DeleteCollectionsModal
        opened={showModal?.type === 'collection' && showModal.operation === 'delete'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        collections={showModal?.type === 'collection' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <TransferCollectionDocumentsModal
        opened={showModal?.type === 'collection-documents' && showModal.operation === 'transfer'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        collection={selectedCollection}
        documentIds={showModal?.type === 'collection-documents' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <DeleteCollectionDocumentsModal
        opened={showModal?.type === 'collection-documents' && showModal.operation === 'delete'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        collection={selectedCollection}
        documentIds={showModal?.type === 'collection-documents' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <SaveCollectionDocumentModal
        opened={showModal?.type === 'document' && showModal?.operation === 'save'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        collection={selectedCollection}
        documentId={selectedCollectionItem}
        onSubmit={() => setEditing(null)}
        documentData={collectionDocumentQuery.data ?? {}}
        updatedDocumentData={showModal?.type === 'document' ? showModal.document : {}}
      />
    </Stack>
  )
}
