import { Box, Group, Skeleton, Stack } from '@shared/components'

const AFakeEmployeeRow = () => {
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.other.colors.background[0],
        paddingLeft: theme.other.sizes.gap.xl,
        paddingRight: theme.other.sizes.gap.xl,
        paddingTop: theme.other.sizes.padding.lg,
        paddingBottom: theme.other.sizes.padding.lg,
        borderTop: `${theme.other.sizes.border.md} solid ${theme.other.colors.background[1]}`,
        borderBottom: `${theme.other.sizes.border.md} solid ${theme.other.colors.background[1]}`,
      })}
    >
      <Group align='center'>
        <Skeleton height={75} circle />
        <Stack>
          <Skeleton height={20} width={200} radius='xl' />
          <Skeleton height={20} width='80%' radius='xl' />
        </Stack>
      </Group>
    </Box>
  )
}

export default AFakeEmployeeRow
