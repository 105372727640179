import {
  Alert,
  Center,
  CheckIcon,
  ClipboardIcon,
  RotateCwwIcon,
  ScrollAreaAutosize,
  Skeleton,
  Stack,
  Table,
  TertiaryButton,
  Text,
  Th,
  Tooltip,
  XIcon,
} from '@shared/components'
import { useState } from 'react'
import { dayjs } from '@shared/utils'
import { PatientOperation, isPatientOperation } from '@shared/types'
import { PerformedByContent, ViewPatientOperationDrawer } from './modals/ViewPatientOperationDrawer'
import { usePatient } from '../PPatientContext'
import { useEmrQuery } from '../../../utils/hooks'

export const OperationsSection = () => {
  const { patientId } = usePatient()
  const [viewOperation, setViewOperation] = useState<PatientOperation | null>(null)
  const operationsQuery = useEmrQuery('GET /patient/:patientId/operations', {
    params: { patientId },
  })

  return (
    <Stack>
      {operationsQuery.isLoading || operationsQuery.data?.length ? (
        <Skeleton visible={operationsQuery.isLoading}>
          <ScrollAreaAutosize maxHeight='32rem'>
            <Table
              striped
              withBorder
              verticalSpacing='sm'
              sx={({ other: { colors, sizes } }) => ({
                tableLayout: 'auto',
                backgroundColor: colors.background[0],
                borderWidth: sizes.border.md,
                borderStyle: 'solid',
                borderColor: colors.background[3],
              })}
            >
              <thead className='mantine'>
                <tr className='mantine'>
                  <Th sortable={false} key='status' style={{ width: `4ch`, textAlign: 'center' }}>
                    Status
                  </Th>
                  <Th sortable={false} key='operation' style={{ width: `72ch` }}>
                    Operation
                  </Th>
                  <Th sortable={false} key='performedAt' style={{ width: `40ch` }}>
                    Performed at
                  </Th>
                  <Th sortable={false} key='performedBy' style={{ width: `32ch` }}>
                    Performed by
                  </Th>
                  <Th sortable={false} key='actions' style={{ width: `8ch`, textAlign: 'center' }}>
                    Actions
                  </Th>
                </tr>
              </thead>
              <tbody>
                {operationsQuery.data?.map(operation => (
                  <tr key={operation.oid}>
                    <td>
                      <Center>
                        <StatusRowContent operation={operation} />
                      </Center>
                    </td>
                    <td>
                      <OperationRowContent operation={operation} patientId={patientId} />
                    </td>
                    <td>
                      <Text>
                        {dayjs
                          .unix(operation.timestamps.createdAt)
                          .format('dddd, MMMM D, YYYY hh:mma z')}
                      </Text>
                    </td>
                    <td>
                      <PerformedByContent operation={operation} />
                    </td>
                    <td>
                      <Center>
                        <TertiaryButton
                          leftIcon={<ClipboardIcon />}
                          onClick={() => setViewOperation(operation)}
                        >
                          View
                        </TertiaryButton>
                      </Center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollAreaAutosize>
        </Skeleton>
      ) : (
        <Alert variant='primary' icon={<XIcon />}>
          No operations found
        </Alert>
      )}
      <ViewPatientOperationDrawer
        opened={Boolean(viewOperation)}
        operation={viewOperation}
        onClose={() => setViewOperation(null)}
      />
    </Stack>
  )
}

const StatusRowContent = ({ operation }: { operation: PatientOperation }) => {
  if (operation.reverted) {
    return (
      <Tooltip label='Reverted'>
        <Center>
          <RotateCwwIcon />
        </Center>
      </Tooltip>
    )
  }

  return (
    <Tooltip label='Applied'>
      <Center>
        <CheckIcon />
      </Center>
    </Tooltip>
  )
}

const OperationRowContent = ({
  operation,
  patientId,
}: {
  operation: PatientOperation
  patientId: string
}) => {
  if (isPatientOperation(operation, 'transfer-collection')) {
    return (
      <Text>
        {'Transfer collection '}
        <Text span bold>
          {operation.collection}
        </Text>
        {` (${Object.keys(operation.data).length})`}
        {operation.patientId === patientId ? (
          <>
            {' to '}
            <TertiaryButton
              component='a'
              target='_blank'
              href={`/patients/${operation.toPatientId}`}
            >
              {operation.toPatientId}
            </TertiaryButton>
          </>
        ) : (
          <>
            {' from '}
            <TertiaryButton component='a' target='_blank' href={`/patients/${operation.patientId}`}>
              {operation.patientId}
            </TertiaryButton>
          </>
        )}
      </Text>
    )
  }

  if (isPatientOperation(operation, 'transfer-subcollection')) {
    return (
      <Text>
        {'Transfer subcollection '}
        <Text span bold>
          {operation.subcollection}
        </Text>
        {` (${Object.keys(operation.data).length})`}
        {operation.patientId === patientId ? (
          <>
            {' to '}
            <TertiaryButton
              component='a'
              target='_blank'
              href={`/patients/${operation.toPatientId}`}
            >
              {operation.toPatientId}
            </TertiaryButton>
          </>
        ) : (
          <>
            {' from '}
            <TertiaryButton component='a' target='_blank' href={`/patients/${operation.patientId}`}>
              {operation.patientId}
            </TertiaryButton>
          </>
        )}
      </Text>
    )
  }

  if (isPatientOperation(operation, 'delete-collection')) {
    return (
      <Text>
        {'Delete collection '}
        <Text span bold>
          {operation.collection}
        </Text>
        {` (${Object.keys(operation.data).length})`}
      </Text>
    )
  }

  if (isPatientOperation(operation, 'delete-subcollection')) {
    return (
      <Text>
        {'Delete subcollection '}
        <Text span bold>
          {operation.subcollection}
        </Text>
        {` (${Object.keys(operation.data).length})`}
      </Text>
    )
  }

  if (isPatientOperation(operation, 'update-collection-document')) {
    return (
      <Text>
        {'Updated collection '}
        <Text span bold>
          {operation.collection}
        </Text>
        {' document '}
        <Text span bold>
          {operation.documentId}
        </Text>
      </Text>
    )
  }

  if (isPatientOperation(operation, 'update-subcollection-document')) {
    return (
      <Text>
        {'Updated subcollection '}
        <Text span bold>
          {operation.subcollection}
        </Text>
        {' document '}
        <Text span bold>
          {operation.documentId}
        </Text>
      </Text>
    )
  }

  return <Text>Unknown</Text>
}
