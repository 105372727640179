import { AiFillSmile } from '@react-icons/all-files/ai/AiFillSmile'
import { SlashIcon } from '@shared/components'

type ListAndCardLayoutProps = {
  list?: JSX.Element
  card?: JSX.Element
  emptyListMessage?: string
  errorMessage?: string
}

const ListAndCardLayout = ({
  list,
  card,
  emptyListMessage,
  errorMessage,
}: ListAndCardLayoutProps) => {
  if (errorMessage) {
    return (
      <div className='h-screen col-span-3 mx-auto w-full pr-6'>
        <div className='relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6 flex justify-center items-center'>
          <SlashIcon className='mr-1 h-5 w-5 text-daybreak-error0' />
          <p className='font-semibold text-daybreak-error0'>{errorMessage}</p>
        </div>
      </div>
    )
  }

  if (emptyListMessage) {
    return (
      <div className='h-screen col-span-3 mx-auto w-full pr-6'>
        <div className='relative pl-4 pr-6 py-5 sm:py-6 sm:pl-6 flex justify-center items-center'>
          <div className='h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center mr-2'>
            <AiFillSmile className='h-5 w-5 text-gray-500 rounded-full' />
          </div>
          <p className='text-sm font-semibold text-indigo-900'>{emptyListMessage}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      {list && <div className='col-span-1'>{list}</div>}
      {card && (
        <div className='h-screen col-span-2 mx-auto w-full pl-6'>
          <div className='bg-white rounded-md shadow-md border-b border-gray-200 relative z-0 w-full'>
            {card}
          </div>
        </div>
      )}
    </>
  )
}

export default ListAndCardLayout
