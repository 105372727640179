import React from 'react'
import { ReactHookFormErrorsObject } from '../../utils/reactHookForm'

export type ANoteYesNoRadioInputProps = {
  id?: string
  className?: string
  name: string
  label?: string
  sublabel?: React.ReactNode
  disabled?: boolean
  error?: ReactHookFormErrorsObject
}

const ANoteYesNoRadioInput = React.forwardRef<HTMLInputElement, ANoteYesNoRadioInputProps>(
  ({ id, name, label, sublabel, className, disabled, error, ...props }, ref) => {
    let stateSpecificClassNames = ''
    if (error?.[name]) {
      stateSpecificClassNames = 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
    }

    const path =
      'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'

    return (
      <div className={`${className || ''} w-full`}>
        <p className='text-sm font-medium leading-5 text-gray-700'>{label}</p>
        {sublabel && <p className='text-sm leading-5 text-gray-500'>{sublabel}</p>}
        <div className='ml-4 mt-4'>
          <div className='flex'>
            <input
              disabled={disabled}
              value='yes'
              id={`${id}-yes`}
              name={name}
              type='radio'
              ref={ref}
              className={`focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300 ${
                stateSpecificClassNames ?? ''
              }`}
              {...props}
            />
            <label htmlFor={name} className='ml-1'>
              <span className='block text-sm leading-5 font-medium text-gray-700'>Yes</span>
            </label>
          </div>
          <div className='flex mt-2'>
            <input
              disabled={disabled}
              value='no'
              id={`${id}-no`}
              name={name}
              type='radio'
              ref={ref}
              className={`focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300 ${
                stateSpecificClassNames ?? ''
              }`}
              {...props}
            />
            <label htmlFor={name} className='ml-1'>
              <span className='block text-sm leading-5 font-medium text-gray-700'>No</span>
            </label>
          </div>
        </div>
        {error?.[name] && (
          <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs'>
            <svg
              fill='currentColor'
              viewBox='0 0 20 20'
              className='mt-px w-4 h-4 mr-1 flex-shrink-0'
            >
              <path fillRule='evenodd' d={path} clipRule='evenodd'></path>
            </svg>
            {error[name]?.message}
          </p>
        )}
      </div>
    )
  },
)

ANoteYesNoRadioInput.displayName = 'ANoteYesNoRadioInput'

export default ANoteYesNoRadioInput
