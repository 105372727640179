import React from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

export type ANoteModalityInputProps = {
  id: string
  name: string
  label: string
  sublabel: string
  className: string
  disabled: boolean
  error: FieldErrors<FieldValues>
}

const ANoteModalityInput = React.forwardRef<HTMLInputElement, ANoteModalityInputProps>(
  ({ id, name, label, sublabel, className, disabled, error }, ref) => {
    let stateSpecificClassNames = ''
    if (error?.[name]) {
      stateSpecificClassNames = 'shadow ring-red focus:ring focus:border-red-200 focus:ring-red'
    }

    return (
      <div className={`${className || ''} w-full`}>
        <p className='text-sm font-medium leading-5 text-gray-700'>{label}</p>
        {sublabel && <p className='text-sm leading-5 text-gray-500'>{sublabel}</p>}
        <div className='ml-4 mt-4'>
          <div className='flex items-center'>
            <input
              disabled={disabled}
              value='video'
              id={`${id}-video`}
              name={name}
              type='radio'
              ref={ref}
              className={`focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300 ${
                stateSpecificClassNames ?? ''
              }`}
            />
            <label htmlFor={name} className='ml-1'>
              <span className='block text-sm leading-5 font-medium text-gray-700'>
                Video (Zoom)
              </span>
            </label>
            <input
              disabled={disabled}
              value='phone'
              id={`${id}-phone`}
              name={name}
              type='radio'
              ref={ref}
              className={`ml-3 focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300  ${
                stateSpecificClassNames ?? ''
              }`}
            />
            <label htmlFor={name} className='ml-1'>
              <span className='block text-sm leading-5 font-medium text-gray-700'>
                Phone (AirCall)
              </span>
            </label>
          </div>
        </div>
      </div>
    )
  },
)

ANoteModalityInput.displayName = 'ANoteModalityInput'

export default ANoteModalityInput
