import { VisitNote } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class VisitNotesApi extends BaseApi {
  getVersionHistory = (patientId: string, visitId: string) => {
    return this.json<VisitNote[]>({
      method: 'GET',
      url: `/patient/${patientId}/visits/${visitId}/notes/history`,
    })
  }

  // LINK server/functions/emr/router/index.ts#get-visit-notes
  getVisitNote = ({ patientID, visitID }: { patientID: string; visitID: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.json<any>({
      method: 'GET',
      url: `/patient/${patientID}/visits/${visitID}/notes`,
    })
  }

  getVisitNotePrescriptions = ({ visitId }: { visitId: number }) => {
    return this.json<number[]>({
      method: 'GET',
      url: `/visits/${visitId}/notes/prescriptions`,
    })
  }

  lockVisitNote = ({
    patientID,
    visitID,
    data,
  }: {
    patientID: string
    visitID: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.json<Res>({
      method: 'POST',
      url: `/patient/${patientID}/visits/${visitID}/notes/lock`,
      data,
    })
  }

  updateVisitNotePrescription = ({
    visitId,
    prescriptionId,
  }: {
    visitId: string
    prescriptionId: string
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/visits/${visitId}/notes/prescriptions/${prescriptionId}`,
    })
  }

  updateVisitNoteAddendum = ({
    patientID,
    visitID,
    data,
  }: {
    patientID: string
    visitID: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/patient/${patientID}/visits/${visitID}/notes/addendum`,
      data,
    })
  }

  unlockVisitNote = ({ patientId, visitId }: { patientId: string; visitId: string }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/patient/${patientId}/visits/${visitId}/notes/unlock`,
    })
  }
}
