import {
  Flex,
  Center,
  Loader,
  JsonInput,
  Stack,
  Group,
  Tooltip,
  SecondaryButton,
  PrimaryButton,
  PencilIcon,
  SaveIcon,
  SlashIcon,
  Text,
} from '@shared/components'
import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { databaseMetadataFields } from '@shared/types'
import { jsonFormat } from '@shared/utils'

export const JsonColumn = ({
  documentId,
  documentData,
  editing,
  toggleIsEditing,
  isLoading,
  isError,
  onSave,
}: {
  documentId: string
  documentData: object | undefined
  editing: 'collections' | 'documents' | 'json' | null
  toggleIsEditing: () => void
  isLoading: boolean
  isError: boolean
  onSave: (document: object) => void
}) => {
  const [jsonState, setJsonState] = useState('')

  useEffect(() => {
    setJsonState(jsonFormat(omit(documentData, databaseMetadataFields)))
  }, [documentData])

  const onClickEdit = () => {
    toggleIsEditing()
    setJsonState(jsonFormat(omit(documentData, databaseMetadataFields)))
  }

  let jsonIsInvalid = false
  let updatedDocument = {}
  try {
    updatedDocument = JSON.parse(jsonState)
  } catch {
    jsonIsInvalid = true
  }

  return (
    <Flex
      sx={({ other: { colors }, radius }) => ({
        flex: 20,
        backgroundColor: colors.background[1],
        borderRadius: radius.sm,
        overflow: 'hidden',
      })}
    >
      {isLoading || isError ? (
        <Center w='100%'>
          <Loader />
        </Center>
      ) : (
        <Stack m='0.5rem' w='calc(100% - 1rem)' spacing='sm'>
          <Group position='apart'>
            <Text bold>{documentId}</Text>
            <Group spacing='sm'>
              {editing === 'json' ? (
                <>
                  <Tooltip label={jsonIsInvalid ? 'Invalid JSON' : 'Save changes'}>
                    <SecondaryButton
                      onClick={() => onSave(updatedDocument)}
                      disabled={jsonIsInvalid || isEqual(documentData, updatedDocument)}
                      leftIcon={<SaveIcon />}
                      size='xs'
                    />
                  </Tooltip>
                  <Tooltip label='Cancel edit'>
                    <PrimaryButton onClick={onClickEdit} leftIcon={<SlashIcon />} size='xs' />
                  </Tooltip>
                </>
              ) : (
                <Tooltip label='Edit'>
                  <PrimaryButton
                    disabled={editing !== null}
                    onClick={onClickEdit}
                    leftIcon={<PencilIcon />}
                    size='xs'
                  />
                </Tooltip>
              )}
            </Group>
          </Group>
          <JsonInput
            minRows={29}
            validationError
            value={jsonState}
            onChange={value => {
              if (editing === 'json') {
                setJsonState(value)
              }
            }}
            formatOnBlur
          />
        </Stack>
      )}
    </Flex>
  )
}
