import { useForm } from '@mantine/form'
import { isLength, Stack, Text, validateWith } from '@shared/components'
import {
  Appointment,
  hasGroupRole,
  INVALID_CPT_CODE,
  ProblemListProblem,
  VisitNoteContent,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi, patientsApi } from '../../../api'
import { DiagnosisSection } from '../../../components/forms/DiagnosisSection'
import { VisitNoteTextarea } from '../../../components/forms/VisitNoteTextArea'
import { useAuth } from '../../../context/auth'
import { isRequired } from '../../../utils/formValidation'
import { useMbcSession } from '../../../utils/hooks/use-mbc-session'
import { isNotAbleToWriteToNote } from '../../../utils/utils'
import { ObjectiveMeasuresSection } from '../../registry/objectiveMeasures/ObjectiveMeasuresSection'
import ObjectiveMeasuresFormCollectionDrawer from '../forms/ObjectiveMeasuresFormCollectionDrawer'
import { usePatient } from '../PPatientContext'
import { VisitNoteHeader } from './VisitNoteHeaderFooter'
export type WellnessVisitFormData = {
  symptoms: string
  objective_measures: string
  treatments: string
  plan_updates: string
  problems_addressed: ProblemListProblem[]
  medical_pmh: string
  psychiatric_pmh: string
  hospitalizations_pmh: string
  surgical_pmh: string
  active_medications_pmh: string
  allergies_and_reactions_pmh: string
  social_and_family_pmh: string
  observed_and_examination: string
}

export type WellnessVisitFormProps = {
  patientID: string
  visitID?: string
  visit?: Appointment
  notes: VisitNoteContent | null
  editMode: boolean
  setCloseNoteModal: (bool: boolean) => void
  setUnlockNoteModal: (bool: boolean) => void
  sidebar: boolean
  meetingDetails?: { patientTime: number; clinicianTime: number }
}

export const WellnessVisitForm = ({
  patientID,
  visit,
  visitID,
  notes,
  editMode,
  setCloseNoteModal,
  setUnlockNoteModal,
  sidebar,
  meetingDetails,
}: WellnessVisitFormProps) => {
  const [savedAt, setSavedAt] = useState<dayjs.Dayjs>()
  const [savedContent, setSavedContent] = useState<WellnessVisitFormData>()
  const { currentUser } = useAuth()
  const { problemsListQuery, patientId } = usePatient()

  const initialValues = {
    symptoms: '',
    treatments: '',
    plan_updates: '',
    objective_measures: '',
    problems_addressed: [],
    medical_pmh: '',
    psychiatric_pmh: '',
    hospitalizations_pmh: '',
    surgical_pmh: '',
    active_medications_pmh: '',
    allergies_and_reactions_pmh: '',
    social_and_family_pmh: '',
    observed_and_examination: '',
  }
  const form = useForm<WellnessVisitFormData>({
    initialValues,
    validate: {
      symptoms: validateWith(isRequired),
      treatments: validateWith(isRequired),
      plan_updates: validateWith(isRequired),
      problems_addressed: validateWith(isLength({ length: 1, op: '>=' }, 'Must add a diagnosis')),
      medical_pmh: validateWith(isRequired),
      psychiatric_pmh: validateWith(isRequired),
      hospitalizations_pmh: validateWith(isRequired),
      surgical_pmh: validateWith(isRequired),
      active_medications_pmh: validateWith(isRequired),
      allergies_and_reactions_pmh: validateWith(isRequired),
      social_and_family_pmh: validateWith(isRequired),
      observed_and_examination: validateWith(isRequired),
    },
  })

  const updateVisitNote = useMutation(patientsApi.updateVisitNote)

  useEffect(() => {
    form.setValues({
      symptoms: notes?.symptoms || form.values.symptoms,
      objective_measures: notes?.objective_measures || form.values.objective_measures,
      treatments: notes?.treatments || form.values.treatments,
      plan_updates: notes?.plan_updates || form.values.plan_updates,
      problems_addressed: notes?.problems_addressed || form.values.problems_addressed,
      medical_pmh: notes?.medical_pmh || form.values.medical_pmh,
      psychiatric_pmh: notes?.psychiatric_pmh || form.values.psychiatric_pmh,
      hospitalizations_pmh: notes?.hospitalizations_pmh || form.values.hospitalizations_pmh,
      surgical_pmh: notes?.surgical_pmh || form.values.surgical_pmh,
      active_medications_pmh: notes?.active_medications_pmh || form.values.active_medications_pmh,
      allergies_and_reactions_pmh:
        notes?.allergies_and_reactions_pmh || form.values.allergies_and_reactions_pmh,
      social_and_family_pmh: notes?.social_and_family_pmh || form.values.social_and_family_pmh,
      observed_and_examination:
        notes?.observed_and_examination || form.values.observed_and_examination,
    })
  }, [notes])

  const saveNote = () => {
    if (isNotAbleToWriteToNote(currentUser.calendarId, visit)) {
      return
    }

    if (!visitID) {
      return
    }

    const body = { type: 'bhcm', content: form.values }
    updateVisitNote.mutate(
      { patientID, visitID, body },
      {
        onSuccess: () => {
          setSavedContent(form.values)
          setSavedAt(dayjs())
        },
      },
    )
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      if (!editMode) {
        return
      }

      if (isEqual(initialValues, form.values)) {
        return
      }

      if (isEqual(form.values, savedContent)) {
        return
      }

      saveNote()
    }, toTime('3 sec').ms())
    return () => clearTimeout(interval)
  }, [form.values, editMode, saveNote, savedContent])

  const lockNote = () => {
    setCloseNoteModal(true)
    saveNote()
  }

  const {
    phq8Responses,
    gad7Responses,
    barc10Responses,
    completedMeasures,
    sessionInfoQueriesAreLoading,
    objectiveMeasure,
    workflowState,
    workflowType,
    measuresQueriesAreLoading,
    workflowIsLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  } = useMbcSession({
    patientID,
    visitID,
    notes,
    noteType: 'bhcm',
  })

  // NOTE: We added this section to the visit note template, but want to hide it from visit notes missing this information
  const displayMedicalHistorySection = (notes: VisitNoteContent | null) => {
    let displayFields = true
    if (!notes?.locked_at) {
      return displayFields
    }

    const medicalHistoryFields = Object.keys(initialValues).filter(key => key.includes('_pmh'))
    medicalHistoryFields.forEach(field => {
      if (!notes[field]) {
        displayFields = false
      }
    })

    return displayFields
  }

  // NOTE: We added this section to the visit note template, but want to hide it from visit notes missing this information
  const displayObservationAndExaminationSection = (notes: VisitNoteContent | null) => {
    return !notes?.locked_at || notes?.observed_and_examination
  }

  // NOTE: We removed this section from the visit note template, but are still showing existing results retroactively
  const displayAdditionalMeasuresSection = (notes: VisitNoteContent | null) => {
    return Boolean(notes?.objective_measures)
  }

  const hasInvalidDiagnosis = form.values.problems_addressed.some(
    problem => problem.code === INVALID_CPT_CODE,
  )

  const saveDiagnoses = useMutation(emrApi.getMutation('POST /patient/:patientId/problemsList'))
  const handleSavingDiagnoses = async ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => {
    await saveDiagnoses.mutateAsync({
      params: { patientId },
      data: { problems: diagnoses },
    })

    void problemsListQuery?.refetch()
  }

  const updateDiagnosis = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/diagnosis/:diagnosisId/problemsList'),
  )
  const handleDeactivatingDiagnosis = async ({ diagnosis }: { diagnosis: ProblemListProblem }) => {
    const deactivatedDiagnosis = {
      ...diagnosis,
      status: 'inactive',
      deactivatedAt: dayjs().toISOString(),
    } as ProblemListProblem
    await updateDiagnosis.mutateAsync({
      params: { patientId, diagnosisId: diagnosis.oid || '' },
      data: { diagnosis: deactivatedDiagnosis },
    })

    void problemsListQuery?.refetch()
  }

  return (
    <>
      <form className='w-full' onSubmit={form.onSubmit(lockNote)}>
        <VisitNoteHeader
          onSubmit={() => void 0}
          sidebar={sidebar}
          datetime={visit?.datetime}
          save={saveNote}
          isSaving={updateVisitNote.isLoading}
          editMode={editMode}
          savedAt={savedAt}
          lockedAt={notes?.locked_at}
          lockedBy={notes?.locked_by_name}
          setUnlockNoteModal={setUnlockNoteModal}
          canUnlockNote={hasGroupRole(currentUser, 'engineer')}
          meetingDetails={meetingDetails}
          hasInvalidDiagnosis={hasInvalidDiagnosis}
        />
        <Stack mt='md' spacing='lg'>
          <DiagnosisSection
            {...form.getInputProps('diagnoses')}
            editable={editMode}
            saveDiagnoses={handleSavingDiagnoses}
            deactivateDiagnosis={handleDeactivatingDiagnosis}
          />
          <VisitNoteTextarea
            label='Brief assessment of symptoms'
            placeholder={`Describe symptoms that are relevant to the patient's behavioral and mental health.`}
            {...form.getInputProps('symptoms')}
            isEditing={editMode}
          />
          {displayMedicalHistorySection(notes) && (
            <Stack>
              <Text bold>Past medical history</Text>
              <VisitNoteTextarea
                label='Medical'
                placeholder='Describe any medical conditions'
                {...form.getInputProps('medical_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Psychiatric'
                placeholder='Describe any psychiatric diagnoses'
                {...form.getInputProps('psychiatric_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Hospitalizations'
                placeholder='Describe hospitalization history Including years and reasons'
                {...form.getInputProps('hospitalizations_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Surgical'
                placeholder='Describe surgical history Including years and reasons'
                {...form.getInputProps('surgical_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Active medications'
                placeholder='List all active medications including dosage and frequency'
                {...form.getInputProps('active_medications_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Allergies and reactions'
                placeholder='List any allergies and reactions'
                {...form.getInputProps('allergies_and_reactions_pmh')}
                isEditing={editMode}
              />
              <VisitNoteTextarea
                label='Social and family'
                placeholder='Describe problems related to social environment, housing, employment etc.'
                {...form.getInputProps('social_and_family_pmh')}
                isEditing={editMode}
              />
            </Stack>
          )}
          <ObjectiveMeasuresSection
            showCaseReviewAlert={false}
            isLoading={measuresQueriesAreLoading || sessionInfoQueriesAreLoading}
            phqResponses={phq8Responses ?? []}
            gad7Responses={gad7Responses ?? []}
            barc10Responses={barc10Responses ?? []}
            completedMeasures={completedMeasures}
            editMode={editMode}
            lockedAt={notes?.locked_at}
            addMeasureOnClick={measure => createWorkflowSessionAndOpenDrawer(measure)}
            patientId={patientId}
          />
          {displayAdditionalMeasuresSection(notes) && (
            <Stack>
              <VisitNoteTextarea
                label='Additional objective measures'
                placeholder='e.g. PHQ-9, BARC-10, GAD-7, etc.'
                {...form.getInputProps('objective_measures')}
                isEditing={editMode}
              />
              {editMode && (
                <Text bold>
                  If objective measures were conducted within the last calendar month, document the
                  score(s) from the registry. If there hasn&apos;t been an objective measure
                  administered within the last calendar month, conduct one within the current visit
                  and document the results.
                </Text>
              )}
            </Stack>
          )}
          {displayObservationAndExaminationSection(notes) && (
            <VisitNoteTextarea
              label='Observation and examination'
              placeholder='Physical and mental status exam'
              {...form.getInputProps('observed_and_examination')}
              isEditing={editMode}
            />
          )}
          <VisitNoteTextarea
            label='Evidence-based treatments provided during encounter'
            placeholder='Describe any behavioral interventions enacted during the visit.'
            {...form.getInputProps('treatments')}
            isEditing={editMode}
          />
          <VisitNoteTextarea
            label='Update(s) to the behavioral health care plan'
            placeholder='Describe the specific future behavioral health plan for the patient.'
            {...form.getInputProps('plan_updates')}
            isEditing={editMode}
          />
        </Stack>
      </form>
      <ObjectiveMeasuresFormCollectionDrawer
        patientId={patientID}
        measure={objectiveMeasure}
        workflowState={workflowState}
        workflowType={workflowType}
        isLoading={workflowIsLoading}
        onClose={onCloseDrawer}
        setRecentlySubmittedScore={setRecentlySubmittedScore}
      />
    </>
  )
}
