import { useForm } from '@mantine/form'
import {
  ArrowLeftCircleIcon,
  ChevronDownIcon,
  DatePicker,
  Drawer,
  EditIcon,
  Group,
  Menu,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  Table,
  TertiaryButton,
  Text,
  TextInput,
  TitleThree,
  Tooltip,
  TrashIcon,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import { CocmTimeTracking, qualifyingActivities } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { isNumber, isRequired } from '../../../utils/formValidation'
import CocmMins from './CocmMins'
import DeleteTimeTrackingItemModal from './DeleteTimeTrackingItemModal'

export const TimeTrackingDrawer = ({
  onClose,
  opened,
  patientId,
}: {
  onClose: () => void
  opened: boolean
  patientId: string | undefined
}) => {
  const queryClient = useQueryClient()
  const thisMonth = dayjs().startOf('month').toISOString()

  const [step, setStep] = useState<'view' | 'add' | 'edit'>('view')
  const [monthTime, setMonthTime] = useState<number>(0)
  const [itemToDelete, setItemToDelete] = useState<{ id: string; time: number } | undefined>()
  const [editItem, setEditItem] = useState<CocmTimeTracking | null>()
  const [selectedMonth, setSelectedMonth] = useState<string>(thisMonth)

  const [patientQueryKey, patientQueryFn] = emrApi.getQuery('GET /patient/:patientId', {
    params: { patientId: patientId || '' },
  })

  const patientQuery = useQuery(patientQueryKey, patientQueryFn, {
    enabled: Boolean(patientId),
  })

  const patient = patientQuery.data

  const [cocmQueryKey, cocmQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    {
      params: {
        patientId: patientId || '',
      },
    },
  )

  const patientOnCocmRegistry = useQuery(cocmQueryKey, cocmQueryFunction, {
    enabled: Boolean(patientId),
    onSuccess: data => {
      setMonthTime(data?.timeThisMonth ?? 0)
    },
  })

  const cocmPatientData = patientOnCocmRegistry.data
  const dateAdded = cocmPatientData?.dateAdded
  const totalTime = cocmPatientData?.timeThisMonth ?? 0

  const trackTime = useMutation(emrApi.getMutation('POST /timeTracking'), {
    onSuccess: () => {
      void queryClient.invalidateQueries(getTimeTrackingKey)
    },
  })
  const deleteEntry = useMutation(emrApi.getMutation('DELETE /timeTracking/:id'), {
    onSuccess: () => {
      void queryClient.invalidateQueries(getTimeTrackingKey)
    },
  })
  const updateEntry = useMutation(emrApi.getMutation('PUT /timeTracking/:id'), {
    onSuccess: () => {
      void queryClient.invalidateQueries(getTimeTrackingKey)
    },
  })

  const getMonthArray = () => {
    const months = dayjs(dateAdded).getBetween(dayjs(), 'months')
    return months.map(month => dayjs(month).format('MMMM YYYY')).reverse()
  }

  const {
    other: { colors, sizes },
    radius,
  } = useMantineTheme()

  const [getTimeTrackingKey, getTimeTrackingFunction] = emrApi.getQuery(
    'GET /patient/:patientId/timeTracking',
    {
      params: {
        patientId: patientId || '',
      },
      query: {
        date: selectedMonth,
      },
    },
  )

  const viewTimeTracking = useQuery(getTimeTrackingKey, getTimeTrackingFunction, {
    enabled: opened,
  })

  const { getInputProps, values, validate, reset, setFieldValue } = useForm<{
    activity: string
    minutes: string
    date: Date
  }>({
    initialValues: {
      activity: '',
      minutes: '',
      date: dayjs().toDate(),
    },
    validate: {
      minutes: validateWith(isRequired, isNumber),
      activity: validateWith(isRequired),
    },
  })

  let startDate = dayjs().startOf('month')
  if (dateAdded && startDate.toISOString() < dateAdded) {
    startDate = dayjs(dateAdded)
  }

  const onSubmit = async () => {
    if (validate().hasErrors) {
      return
    }
    const minutes = Number(values.minutes)
    await trackTime.mutateAsync({
      data: {
        patientId: patientId || '',
        date: dayjs(values.date).toISOString(),
        totalMinutes: minutes,
        activityType: values.activity,
      },
    })
    setMonthTime(monthTime + minutes)
    reset()
    setStep('view')
  }

  const onDelete = async () => {
    if (!itemToDelete) {
      return
    }
    await deleteEntry.mutateAsync({ params: { id: itemToDelete.id } })
    setMonthTime(monthTime - itemToDelete.time)
    setItemToDelete(undefined)
  }

  const onUpdate = async () => {
    if (!editItem) {
      return
    }
    await updateEntry.mutateAsync({
      params: { id: editItem.oid },
      data: {
        totalMinutes: Number(values.minutes),
        activityType: values.activity,
        date: dayjs(values.date).toISOString(),
      },
    })
    setMonthTime(monthTime - editItem.totalMinutes + Number(values.minutes))
    setEditItem(undefined)
    reset()
    setStep('view')
  }

  const closeDrawer = () => {
    // reloads the registry data if time tracking has changed for a patient
    if (totalTime !== monthTime) {
      void queryClient.invalidateQueries('GET /cocmRegistry')
      void queryClient.invalidateQueries('GET /cocmRegistry/patient/:patientId')
    }
    onClose()
  }
  const isLoading = viewTimeTracking.isLoading || viewTimeTracking.isFetching

  const getTitle = () => {
    if (step === 'view') {
      return 'Time tracking'
    }
    if (step === 'edit') {
      return 'Edit entry'
    }
    if (step === 'add') {
      return 'New entry'
    }
    return 'Error'
  }

  return (
    <Drawer
      opened={opened}
      onClose={closeDrawer}
      title={
        <Group>
          <TertiaryButton
            leftIcon={<ArrowLeftCircleIcon styled />}
            onClick={step === 'view' ? closeDrawer : () => setStep('view')}
          />
          <TitleThree>{getTitle()}</TitleThree>
        </Group>
      }
      footer={
        <Group position='right'>
          {step === 'view' ? (
            <PrimaryButton leftIcon={<PlusIcon />} onClick={() => setStep('add')}>
              New entry
            </PrimaryButton>
          ) : (
            <>
              <SecondaryButton onClick={() => setStep('view')}>Cancel</SecondaryButton>
              {step === 'add' ? (
                <PrimaryButton onClick={onSubmit}>Save entry</PrimaryButton>
              ) : (
                <PrimaryButton onClick={onUpdate}>Update entry</PrimaryButton>
              )}
            </>
          )}
        </Group>
      }
      position='right'
      size='lg'
    >
      <DeleteTimeTrackingItemModal
        opened={Boolean(itemToDelete)}
        onDeleteEntry={() => onDelete()}
        onClose={() => setItemToDelete(undefined)}
      />
      {isLoading && <ALoadingSpinner />}
      {!isLoading && step === 'view' && (
        <>
          <Stack p='md'>
            <Group position='right'>
              <Menu position='bottom-end'>
                <Menu.Target>
                  <TertiaryButton rightIcon={<ChevronDownIcon />}>
                    {selectedMonth === thisMonth
                      ? `This month`
                      : dayjs(selectedMonth).format('MMMM YYYY')}
                  </TertiaryButton>
                </Menu.Target>
                <Menu.Dropdown>
                  {getMonthArray().map(month => {
                    return (
                      <Menu.Item
                        onClick={() => setSelectedMonth(dayjs(month).toISOString())}
                        key={month}
                      >
                        {month}
                      </Menu.Item>
                    )
                  })}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Stack>
          <Stack p='md'>
            <Table
              striped
              sx={{
                backgroundColor: colors.text[3],
                borderWidth: sizes.border.md,
                borderStyle: 'solid',
                borderColor: colors.background[3],
                tableLayout: 'fixed',
                borderRadius: radius.sm,
                '& td': { verticalAlign: 'top' },
              }}
            >
              <tbody>
                <tr>
                  {['Date', 'Qualifying activity', 'Mins'].map(header => (
                    <td colSpan={1} key={header}>
                      <Text size='xs' bold>
                        {header}
                      </Text>
                    </td>
                  ))}
                </tr>
                {viewTimeTracking?.data?.map((data, index) => (
                  <tr
                    key={data.oid}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? colors.background[0] : colors.background[1],
                    }}
                  >
                    <td>
                      <Text>{dayjs(data.date).format('MM/DD/YYYY')} </Text>
                    </td>
                    <td>
                      <Tooltip label={data.employeeName}>
                        <Text>{data.activityType}</Text>
                      </Tooltip>
                    </td>
                    <td>
                      <Group position='apart'>
                        <Text>{data.totalMinutes}</Text>
                        {data.oid && selectedMonth === thisMonth && (
                          <Group spacing='sm' position='right'>
                            <SecondaryButton
                              size='sm'
                              leftIcon={<TrashIcon />}
                              onClick={() =>
                                setItemToDelete({ id: data.oid ?? '', time: data.totalMinutes })
                              }
                            />
                            <PrimaryButton
                              size='sm'
                              leftIcon={<EditIcon />}
                              onClick={() => {
                                setEditItem(data as CocmTimeTracking)
                                setFieldValue('activity', data.activityType)
                                setFieldValue('minutes', String(data.totalMinutes))
                                setFieldValue('date', dayjs(data.date).toDate())
                                setStep('edit')
                              }}
                            />
                          </Group>
                        )}
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Stack>
          {dateAdded && selectedMonth === thisMonth && (
            <Group position='center' spacing='sm'>
              <Text bold>So far, we&apos;ve spent</Text>
              <CocmMins treatmentTime={monthTime} dateAdded={dateAdded} />
              <Text bold>minutes with {patient?.personalData?.firstName} this month </Text>
            </Group>
          )}
        </>
      )}
      {!isLoading && (step === 'add' || step === 'edit') && (
        <Stack spacing='md' p='md'>
          <Select
            data={qualifyingActivities}
            {...getInputProps('activity')}
            label='Qualifying activity'
            placeholder='Select one...'
            searchable
            maxDropdownHeight={450}
          />
          <TextInput
            {...getInputProps('minutes')}
            label='Minutes spent on qualifying activity'
            placeholder='Total minutes'
          />
          <DatePicker
            {...getInputProps('date')}
            label='Date'
            clearable={false}
            minDate={startDate.toDate()}
            maxDate={dayjs().toDate()}
            placeholder='Select day...'
          />
          <Text size='xs'>
            This estimate is true and accurate to the best of my knowledge. I understand that I am
            being asked for this estimate for insurance billing purposes.
          </Text>
        </Stack>
      )}
    </Drawer>
  )
}
