import { AlertIcon, Container, Group, Skeleton, Stack, Text } from '@shared/components'
import { Encounter, ServiceLine } from '@shared/types'
import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { emrApi } from '../../../../api'
import { CardHeader } from '../components/CardHeader'
import { ServiceLineItem } from '../components/ServiceLineItem'

export type ServiceLinesSectionProps = {
  serviceLines: ServiceLine[]
  encounterId: string
  placeOfServiceCode: string
  editingEnabled: boolean
  setEditing: (editing: boolean) => void
}

export const ServiceLinesSection = ({
  serviceLines,
  encounterId,
  placeOfServiceCode,
  editingEnabled,
  setEditing,
}: ServiceLinesSectionProps) => {
  const queryClient = useQueryClient()
  const [editingServiceLineIndex, setEditingServiceLineIndex] = useState<number | null>(null)
  const [showNewServiceLine, setShowNewServiceLine] = useState(false)

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'), {
    onMutate: data => {
      queryClient.setQueryData<Encounter>(encounterQueryKey, (oldData: Encounter | undefined) => {
        return {
          ...oldData,
          service_lines: data?.data?.encounter?.service_lines,
        } as Encounter
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries(encounterQueryKey)
    },
  })

  return (
    <Stack>
      <CardHeader
        title='Service lines'
        buttonLabel='Add'
        buttonOnClick={() => {
          setShowNewServiceLine(true)
          setEditing(true)
        }}
        showButton={!showNewServiceLine && editingEnabled && editingServiceLineIndex === null}
      />

      <Stack>
        {serviceLines.length === 0 && !showNewServiceLine && (
          <Container>
            <Group spacing='sm'>
              <AlertIcon />
              <Text>No service lines found</Text>
            </Group>
          </Container>
        )}
        {serviceLines.map((serviceLine, index) => {
          return (
            <Skeleton visible={updateEncounter.isLoading} key={`service-line-skeleton-${uuidv4()}`}>
              <ServiceLineItem
                key={`service-line-${uuidv4()}`}
                serviceLine={serviceLine}
                placeOfServiceCode={placeOfServiceCode}
                showEditButton={
                  editingServiceLineIndex === null && !showNewServiceLine && editingEnabled
                }
                editOnClick={() => {
                  setEditingServiceLineIndex(index)
                  setEditing(true)
                }}
                removeOnClick={(selectedServiceLine: ServiceLine) => {
                  updateEncounter.mutate({
                    data: {
                      id: encounterId,
                      encounter: {
                        service_lines: serviceLines.filter(item => {
                          return !isEqual(item, selectedServiceLine)
                        }),
                      },
                    },
                  })
                }}
                isEditing={editingServiceLineIndex === index}
                onSave={(updatedServiceLine: ServiceLine) => {
                  updateEncounter.mutate(
                    {
                      data: {
                        id: encounterId,
                        encounter: {
                          service_lines: serviceLines.map(item => {
                            if (isEqual(item, serviceLine)) {
                              return updatedServiceLine
                            }
                            return item
                          }),
                        },
                      },
                    },
                    {
                      onSuccess: () => {
                        setEditingServiceLineIndex(null)
                        setEditing(false)
                      },
                    },
                  )
                }}
                onCancel={() => {
                  setEditingServiceLineIndex(null)
                  setEditing(false)
                }}
              />
            </Skeleton>
          )
        })}

        {showNewServiceLine ? (
          <Skeleton visible={updateEncounter.isLoading}>
            <ServiceLineItem
              placeOfServiceCode={placeOfServiceCode}
              key='new-service-line'
              isEditing
              onCancel={() => {
                setShowNewServiceLine(false)
                setEditing(false)
              }}
              onSave={(serviceLine: ServiceLine) =>
                updateEncounter.mutate(
                  {
                    data: {
                      id: encounterId,
                      encounter: {
                        service_lines: [...serviceLines, serviceLine],
                      },
                    },
                  },
                  {
                    onSuccess: () => {
                      setShowNewServiceLine(false)
                      setEditing(false)
                    },
                  },
                )
              }
            />
          </Skeleton>
        ) : null}
      </Stack>
    </Stack>
  )
}
