import { PrimaryButton, Stack, Table, Text, Th, TitleFour } from '@shared/components'
import { ConsentLabelMap, ConsentLabelMapKey, ConsentType, Patient } from '@shared/types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { LoadingTable } from '../invoices/LoadingTable'
import { ConsentFormModal } from './ConsentFormSectionModal'

export type ConsentFormSectionProps = {
  patient: Patient
}

export const ConsentFormSection = ({ patient }: ConsentFormSectionProps) => {
  const patientId = patient?.oid

  const patientConsentsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/consents', { params: { patientId } }),
  )

  const { isLoading, data: consentData } = patientConsentsQuery

  const [currentConsentModelType, setCurrentConsentModalType] = useState<ConsentType | null>(null)

  const consents: { type: ConsentType; status: boolean }[] = [
    {
      type: 'financial',
      status: Boolean(consentData?.some(consent => consent.type === 'financial')),
    },
    {
      type: 'tpo',
      status: Boolean(patient?.consents?.tpoConsent),
    },
    {
      type: 'treatment',
      status: Boolean(consentData?.some(consent => consent.type === 'treatment')),
    },
  ]

  const markConsentAsSigned = (consentType: ConsentLabelMapKey) => {
    setCurrentConsentModalType(consentType)
  }

  const closeModal = () => {
    setCurrentConsentModalType(null)
  }

  return (
    <Stack>
      <TitleFour>Patient consent forms</TitleFour>
      <Text>
        {`If a patient's consent forms are in a file that you have uploaded, you can mark them as
        signed here.`}
      </Text>
      <Stack>
        {isLoading ? (
          <LoadingTable />
        ) : (
          <Table striped withBorder verticalSpacing='sm'>
            <thead>
              <tr>
                <Th sortable={false}>Consent</Th>
                <Th sortable={false}>Status</Th>
                <Th sortable={false}>Action</Th>
              </tr>
            </thead>
            <tbody>
              {(consents || []).map(consent => {
                return (
                  <tr key={consent.type}>
                    <td>{ConsentLabelMap[consent.type]}</td>
                    <td>{consent.status ? 'Signed' : 'Not signed'}</td>
                    <td>
                      {!consent.status && (
                        <PrimaryButton size='sm' onClick={() => markConsentAsSigned(consent.type)}>
                          Mark as signed
                        </PrimaryButton>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </Stack>
      {currentConsentModelType && (
        <ConsentFormModal
          patient={patient}
          consentType={currentConsentModelType}
          closeModal={closeModal}
        />
      )}
    </Stack>
  )
}
