import { Colors } from '@shared/components'
import { DotProps as RechartsDotProps } from 'recharts'
import { determineFillAndStrokeColors } from './chartUtils'

type ActiveDotProps = {
  value?: number
  index?: number
  goodResultThreshold: number
  badResultThreshold: number
  higherResultIsBetter: boolean
  colors: Colors
  internalStrokeWidth: number
  externalStrokeWidth: number
  onDotClick?: React.Dispatch<React.SetStateAction<number | null>>
} & RechartsDotProps

const ActiveDot = ({
  cx,
  cy,
  value,
  index,
  goodResultThreshold,
  badResultThreshold,
  higherResultIsBetter,
  colors,
  internalStrokeWidth,
  externalStrokeWidth,
  onDotClick,
}: ActiveDotProps) => {
  if (value === undefined || index === undefined) {
    return null
  }
  const { fillColor, strokeColor } = determineFillAndStrokeColors({
    value,
    goodResultThreshold,
    badResultThreshold,
    higherResultIsBetter,
    colors,
  })

  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={internalStrokeWidth}
      />
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill='transparent'
        stroke={colors.actions[0]}
        strokeWidth={externalStrokeWidth}
        onClick={() => (onDotClick ? onDotClick(index) : null)}
        style={{ cursor: 'pointer' }}
      />
    </>
  )
}

export default ActiveDot
