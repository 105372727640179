import { Group } from '@shared/components'
import { useNavigate } from 'react-router-dom'
import { useAccess } from '../../utils/hooks/use-access'
import HeaderTab from '../care_team/irq/HeaderTab'
import { AdminPage } from './PAdmin'

export type AdminHeaderContentProps = {
  selectedPage: AdminPage
}

const AdminHeaderContent = ({ selectedPage }: AdminHeaderContentProps) => {
  const navigate = useNavigate()
  const hasAccess = useAccess()

  return (
    <Group px='sm' sx={{ flexGrow: 1 }}>
      <HeaderTab
        onClick={() => navigate('/admin/schedule')}
        selected={selectedPage === AdminPage.Schedule}
        label='Provider schedule'
      />
      {hasAccess.workingHours && (
        <HeaderTab
          onClick={() => navigate('/admin/working-hours')}
          selected={selectedPage === AdminPage.WorkingHours}
          label='Working hours'
        />
      )}
    </Group>
  )
}

export default AdminHeaderContent
