import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  BetterDrawer,
  Checkbox,
  ChevronRightIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  PrimaryButton,
  SendIcon,
  Stack,
  Text,
  TextInput,
  TooltipLabel,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import {
  DoseSpotMedication,
  DoseSpotMedicationSearchResult,
  DoseSpotPharmacy,
  PrescriptionFavorite,
  PrescriptionResponse,
  getOpheliaHttpError,
  getPodByTaskType,
  getProviderInfo,
  levelOfCareToDaysBetweenVisit,
} from '@shared/types'
import { dayjs, isControlledSubstance } from '@shared/utils'
import maxBy from 'lodash/maxBy'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { analytics } from '../../../analytics'
import {
  emrApi,
  pharmaciesApi,
  prescriptionFavoritesApi,
  prescriptionsApi,
  visitNotesApi,
} from '../../../api'
import { PharmacyInactiveAlert } from '../../../components/PharmacyInactive'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'
import { useAuth } from '../../../context/auth'
import {
  inputCharacterLimitExceeded,
  inputNumberBetween,
  isDate,
  isNumber,
  isPositiveNumber,
  isRequired,
  isTodayOrLater,
  prescriptionPINInvalid,
} from '../../../utils/formValidation'
import * as Fullstory from '../../../utils/fullstory'
import { useEmrMutation, useEmrQuery, useFlags, useRemoteConfig } from '../../../utils/hooks'
import { useGetPharmacy } from '../../../utils/hooks/use-get-pharmacy'
import { useLottieAnimation } from '../../../utils/hooks/use-lottie-animation'
import {
  PRESCRIPTION_QUEUER_ROLES,
  buildSearchString,
  getPharmacyNotesTemplate,
  prescriptionRequiresPriorAuthorization,
  searchForExactMedication,
} from '../../../utils/prescriptionUtils'
import { getDefaultLocationsData } from '../visits/PatientAndClinicianLocations'
import ACreateDoseSpotPatientButton from './ACreateDoseSpotPatientButton'
import MEditPharmacyForm from './MEditPharmacyForm'
import MPrescriptionForm from './MPrescriptionForm'
import MPrescriptionPINForm from './MPrescriptionPINForm'
import MPrescriptionPreview from './MPrescriptionPreview'
import { PriorAuthRationale, priorAuthRationaleSubCategories } from './PriorAuthRationale'
import { SaveFavoriteForm, SaveFavoriteFormData } from './SaveFavoriteForm'
import {
  FavoriteFormProvider,
  MAX_REFILLS,
  MAX_REFILLS_NARCAN,
  MIN_REFILLS,
  MedicationFormData,
  MedicationFormProvider,
  controlledSubstanceValidationBuilder,
  useFavoriteForm,
  useMedicationForm,
} from './formHelpers'

type PrescriptionFormStep =
  | 'Prescription Form'
  | 'Save Favorite'
  | 'Pharmacy Edit'
  | 'Preview'
  | 'Edit Favorite'

export type PrescriptionReorderDrawerProps = {
  caseReviewNoteId?: string
  patientId: string
  prescription?: PrescriptionResponse
  initialStep?: PrescriptionFormStep
  onClose: () => void
  visitId?: string
  setPrescriptionBanner?: (message: string, type: 'success' | 'warning' | 'error') => void
}

const PrescriptionReorderContent = ({
  patientId,
  setPrescriptionBanner,
  onClose,
  visitId,
  prescription,
  initialStep,
  caseReviewNoteId,
}: PrescriptionReorderDrawerProps) => {
  const { patientAndClinicianLocations } = useFlags()
  const [step, setStep] = useState<PrescriptionFormStep>(initialStep || 'Prescription Form')
  const { currentUser } = useAuth()
  const [error, setError] = useState('')
  const [favoriteError, setFavoriteError] = useState('')
  const [favoriteToEdit, setFavoriteToEdit] = useState<PrescriptionFavorite | undefined>()
  const [favoritesBanner, setFavoritesBanner] = useState('')
  const [tempSelectedPharmacy, settempSelectedPharmacy] = useState<DoseSpotPharmacy>()
  const [shouldSetPreferredPharmacy, setShouldSetPreferredPharmacy] = useState<boolean>(false)
  const [employeeIsAtWorkAddress, toggleEmployeeIsAtWorkAddress] = useToggle([true, false])
  const animation = useLottieAnimation('send-animation')
  const priorAuthWarningEmployeeIds = useRemoteConfig({
    key: 'erx_prior_authorization_warning_employee_ids',
  })

  const patient = useEmrQuery('GET /patient/:patientId', {
    params: {
      patientId,
    },
  })

  const shouldShowPriorAuthWarning = priorAuthWarningEmployeeIds.data?.includes(currentUser?.oid)

  const {
    other: { colors },
  } = useMantineTheme()

  const updateDefaultPharmacy = useMutation(pharmaciesApi.update, {
    onSuccess: () => {
      void queryClient.invalidateQueries('pharmaciesApi.retrieve')
    },
  })
  const setPatientDefaultPharmacy = (pharmacyId: number) => {
    updateDefaultPharmacy.mutate({ patientId, pharmacyId })
  }

  const getSubmit = async () => {
    if (step === 'Prescription Form') {
      if (medicationForm.validate().hasErrors) {
        return
      }
      setStep('Preview')
      return
    }
    if (step === 'Save Favorite') {
      if (favoriteForm.validate().hasErrors) {
        return
      }
      savePrescriptionAsFavorite(favoriteForm.values)
    }
    if (step === 'Edit Favorite') {
      if (favoriteForm.validate().hasErrors) {
        return
      }
      editPrescriptionFavorite(favoriteForm.values)
    }
    if (step === 'Pharmacy Edit') {
      if (!tempSelectedPharmacy) {
        return
      }
      if (shouldSetPreferredPharmacy) {
        setPatientDefaultPharmacy(tempSelectedPharmacy.PharmacyId)
      }
      setShouldSetPreferredPharmacy(false)
      medicationForm.setFieldValue('pharmacyId', tempSelectedPharmacy.PharmacyId)
      setStep('Prescription Form')
      return
    }
    if (step === 'Preview') {
      if (
        medicationForm.validateField('pin').hasError ||
        medicationForm.validateField('twoFacCode').hasError
      ) {
        return
      }

      if (showPriorAuthWarning && priorAuthRationaleForm.validate().hasErrors) {
        return
      }

      await onSubmit(medicationForm.values.pin, medicationForm.values.twoFacCode)
    }
  }

  const [employeeQueryKey, employeeQueryFunction] = emrApi.getQuery('GET /employee/:employeeId', {
    params: {
      employeeId: currentUser.oid,
    },
  })

  const employee = useQuery(employeeQueryKey, employeeQueryFunction)

  const isPrescriptionQueued = PRESCRIPTION_QUEUER_ROLES.includes(currentUser.role)

  const updateVisitNoteQuery = useMutation(visitNotesApi.updateVisitNotePrescription)

  const pc = patient?.data?.primaryClinician

  const skipIfLocationsSectionNotDisplayed = () => !patientAndClinicianLocations

  const { employee_address: defaultEmployeeAddress } = getDefaultLocationsData({
    employee: currentUser,
  })

  // We want to update the form value for the clinician's location as they toggle the checkbox indicating that they are at their work address
  useEffect(() => {
    medicationForm.setValues({
      employeeAddress: employeeIsAtWorkAddress ? defaultEmployeeAddress : '',
    })
  }, [employeeIsAtWorkAddress])

  const queryClient = useQueryClient()
  const medicationFormInitialValues = {
    medication_strength: '',
    medication_quantity: '',
    medication_days_supply: '',
    medication_refills: '',
    substitutionAllowed: true,
    bridge: false,
    directions: '',
    notes: '',
    queuedForId: undefined,
    effective_date: dayjs().format('MM/DD/YYYY'),
    employeeAddress: defaultEmployeeAddress,
  }

  const medicationForm = useMedicationForm({
    initialValues: medicationFormInitialValues,
    validate: {
      pin:
        isPrescriptionQueued || step !== 'Preview'
          ? undefined
          : validateWith(isRequired, prescriptionPINInvalid),
      twoFacCode:
        isPrescriptionQueued || step !== 'Preview'
          ? undefined
          : controlledSubstanceValidationBuilder({
              isControlled: [isRequired],
              isNotControlled: [],
            }),
      queuedForId: isPrescriptionQueued ? validateWith(isRequired) : undefined,
      effective_date: validateWith(isRequired, isDate, isTodayOrLater),
      medication_strength: validateWith(isRequired),
      medication_refills: controlledSubstanceValidationBuilder({
        isControlled: [inputNumberBetween({ start: 0, end: 0 })],
        isNotControlled: [isRequired, inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS })],
        isNarcan: [inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS_NARCAN })],
      }),
      medication_days_supply: controlledSubstanceValidationBuilder({
        isControlled: [isRequired, isNumber, isPositiveNumber],
        isNotControlled: [isNumber, isPositiveNumber],
      }),
      medication_quantity: validateWith(isRequired, isNumber, isPositiveNumber),
      pharmacyId: (val: MedicationFormData['pharmacyId']) => (val ? undefined : 'Required'),
      directions: validateWith(isRequired),
      notes: validateWith(inputCharacterLimitExceeded({ characterLimit: 210 })),
      employeeAddress: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
    },
  })

  const createPriorAuthTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2'))
  const priorAuthorizationsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/priorAuthorizations', {
      params: {
        patientId,
      },
    }),
  )
  const mostRecentPriorAuthorization = maxBy(
    Object.values(priorAuthorizationsQuery.data ?? {}),
    'endDate',
  )
  const daysBeforeNextVisit =
    levelOfCareToDaysBetweenVisit[patient?.data?.statuses?.levelOfCare ?? 'n/a']

  const needsNewPriorAuth =
    !priorAuthorizationsQuery.isLoading &&
    dayjs(mostRecentPriorAuthorization?.endDate).isBefore(dayjs().add(daysBeforeNextVisit, 'days'))

  const priorAuthorizationRequired = prescriptionRequiresPriorAuthorization({
    patient: patient?.data,
    ndc: medicationForm.values.medication?.Strength.find(
      s => s.Strength === medicationForm.values.medication_strength,
    )?.NDC,
  })

  const primaryInsuranceQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/insurance/:insuranceId', {
      params: {
        patientId,
        insuranceId: patient?.data?.insuranceData?.primaryInsuranceId ?? '',
      },
    }),
    {
      enabled: Boolean(
        patient?.data?.insuranceData?.hasInsurance &&
          patient?.data?.insuranceData?.primaryInsuranceId,
      ),
    },
  )

  const patientHasMedicaidInurance =
    primaryInsuranceQuery?.data?.basicInsuranceData?.planType === 'MC'

  const showPriorAuthWarning =
    shouldShowPriorAuthWarning &&
    priorAuthorizationRequired &&
    needsNewPriorAuth &&
    !patientHasMedicaidInurance

  const priorAuthRationaleForm = useForm<PriorAuthRationale>({
    validate: {
      category: validateWith(isRequired),
      subCategory: (value, values) => {
        if (!priorAuthRationaleSubCategories[values.category]?.length) {
          return null
        }
        return value ? null : 'Required'
      },
    },
  })

  const getPrescriptionButtonContent = () => {
    if (step === 'Prescription Form') {
      return 'Review'
    }
    if (step === 'Save Favorite') {
      return 'Save favorite'
    }
    if (step === 'Edit Favorite') {
      return 'Update favorite'
    }
    if (step === 'Pharmacy Edit') {
      return 'Save pharmacy'
    }
    if (step === 'Preview') {
      if (isPrescriptionQueued) {
        return 'Queue'
      }
      return showPriorAuthWarning ? 'Send & initiate prior auth' : 'Send'
    }
  }

  // We only want to autofill the nadea # in the pharmacy note if the prescription is not being queued
  let nadea: string | undefined
  if (!isPrescriptionQueued && employee.data) {
    const providerInfo = getProviderInfo(employee.data)
    nadea = providerInfo?.nadea
  }

  const favoriteForm = useFavoriteForm({
    initialValues: {
      favoriteName: '',
      medication_strength: '',
      medication_quantity: '',
      medication_days_supply: '',
      medication_refills: '',
      substitutionAllowed: false,
      directions: '',
      notes: getPharmacyNotesTemplate(nadea),
      dispensableDrugId: undefined,
    },
    validate: {
      favoriteName: validateWith(isRequired),
      medication_strength: validateWith(isRequired),
      medication_refills: controlledSubstanceValidationBuilder({
        isControlled: [inputNumberBetween({ start: 0, end: 0 })],
        isNotControlled: [inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS })],
        isNarcan: [inputNumberBetween({ start: MIN_REFILLS, end: MAX_REFILLS_NARCAN })],
      }),
      medication_days_supply: controlledSubstanceValidationBuilder({
        isControlled: [isNumber],
        isNotControlled: [isNumber],
      }),
      medication_quantity: validateWith(isNumber),
    },
  })

  const [queuedForQueryKey, queuedForQueryFunction] = emrApi.getQuery('GET /employee/:employeeId', {
    params: {
      employeeId: medicationForm.values.queuedForId || '',
    },
  })

  const queuedForQuery = useQuery(queuedForQueryKey, queuedForQueryFunction, {
    enabled: Boolean(medicationForm.values.queuedForId),
  })

  const queuedForOrSentByQuery = isPrescriptionQueued ? queuedForQuery : employee

  const prescribe = useEmrMutation('POST /patient/:patientId/prescriptions', {
    onSuccess: async data => {
      if (data.Id && visitId) {
        await updateVisitNoteQuery.mutateAsync({
          visitId: visitId?.toString() || '',
          prescriptionId: data.Id.toString(),
        })
      }

      void queryClient.invalidateQueries()
      setPrescriptionBanner?.('Prescription successfully initiated', 'success')
      onClose()
      setStep('Prescription Form')
      medicationForm.clearErrors()
      medicationForm.setValues({
        ...medicationFormInitialValues,
        queuedForId: isPrescriptionQueued ? pc?.id : undefined,
      })
    },
    onError: error => {
      setError(getOpheliaHttpError(error, 'Something went wrong'))
    },
  })

  const sendingPrescription = prescribe.isLoading

  const medicationInfoForReorderedPrescription = useQuery(
    ['prescriptionsApi.getMedication', 'searchForExactMedication', prescription],
    () => {
      if (prescription) {
        return Promise.all([
          prescriptionsApi.getMedication({
            medication: buildSearchString({
              displayName: prescription.medication_name || '',
              strength: prescription.medication_strength || '',
              route: prescription.medication_route,
            }),
            medication_strength: prescription.medication_strength || '',
            dispensableDrugId: prescription.dispensableDrugId,
          }),
          searchForExactMedication({
            medicationName: buildSearchString({
              displayName: prescription.medication_name || '',
              strength: prescription.medication_strength || '',
              route: prescription.medication_route,
            }),
            dispensableDrugId: prescription.dispensableDrugId,
          }),
          pharmaciesApi.retrieve(patientId),
        ])
      }
    },
    {
      onSuccess: ([fullMedication, searchResult, pharmacy]: [
        DoseSpotMedication,
        DoseSpotMedicationSearchResult,
        DoseSpotPharmacy,
      ]) => {
        if (prescription) {
          medicationForm.setValues({
            medication: searchResult,
            full_medication: fullMedication,
            medication_days_supply: prescription.medication_days_supply || '',
            substitutionAllowed: prescription.substitutionAllowed || false,
            directions: prescription.directions || '',
            notes: prescription.pharmacyNotes || '',
            medication_refills: prescription.refills || '',
            medication_quantity: prescription.medication_quantity,
            medication_strength: prescription.medication_strength || '',
            bridge: false,
            effective_date: dayjs().format('MM/DD/YYYY'),
            pharmacyId: pharmacy?.PharmacyId || undefined,
            queuedForId: isPrescriptionQueued ? pc?.id : undefined,
          })
        }
      },
      /*
       * onError: (err: string) => {
       *   //TODO: Add error banner: `Unable to reorder prescription: ${err}`
       * },
       */
      enabled: Boolean(prescription) && Boolean(pc),
    },
  )

  const medicationForFavoriteToEdit = useQuery(
    ['prescriptionsApi.search', favoriteToEdit],
    () => {
      favoriteForm.reset()
      return searchForExactMedication({
        medicationName: favoriteToEdit?.medicationName || '',
        dispensableDrugId: favoriteToEdit?.dispensableDrugId,
      })
    },
    {
      onSuccess: (searchResult: DoseSpotMedicationSearchResult) => {
        if (favoriteToEdit) {
          favoriteForm.setValues({
            favoriteName: favoriteToEdit.favoriteName,
            medication: searchResult,
            medication_strength: favoriteToEdit.strength,
            medication_days_supply: favoriteToEdit.daysSupply
              ? String(favoriteToEdit.daysSupply)
              : '',
            medication_refills: favoriteToEdit.refills ? String(favoriteToEdit.refills) : '',
            medication_quantity: favoriteToEdit.quantity ? String(favoriteToEdit.quantity) : '',
            substitutionAllowed: Boolean(favoriteToEdit.substitutionAllowed),
            directions: favoriteToEdit.directions || '',
            notes: favoriteToEdit.pharmacyNotes || '',
            dispensableDrugId: favoriteToEdit.dispensableDrugId,
          })
        }
      },
      onError: () => {
        // TODO: Add alert banner 'unable to edit favorite'
        setStep('Prescription Form')
      },
      enabled: Boolean(favoriteToEdit),
    },
  )

  const patientPharmacyQuery = useQuery(
    ['pharmaciesApi.retrieve', patientId],
    () => pharmaciesApi.retrieve(patientId),
    {
      onSuccess: data => {
        if (data) {
          medicationForm.setFieldValue('pharmacyId', data.PharmacyId)
        }
      },
    },
  )

  /*
   * @dosespotV2Migration
   * Need to send the patientId so that we know whether to use the v1 or v2 API
   */
  const { getPharmacyQuery, isPharmacyInactive } = useGetPharmacy({
    pharmacyId: medicationForm.values.pharmacyId,
    patientId,
  })

  const loading =
    employee.isFetching ||
    patient?.isFetching ||
    medicationForFavoriteToEdit.isLoading ||
    medicationInfoForReorderedPrescription.isLoading ||
    getPharmacyQuery.isLoading ||
    patientPharmacyQuery.isLoading

  const onSubmit = async (pinCode?: string, twoFacCode?: string) => {
    if (
      !medicationForm.values?.pharmacyId ||
      !medicationForm.values?.medication ||
      !medicationForm.values?.medication_strength ||
      !patientId
    ) {
      setError('An error has occurred.')
      return
    }

    prescribe.mutate({
      params: { patientId },
      data: {
        caseReviewNoteId,
        medication_name: medicationForm.values.medication.Name,
        medication_strength: medicationForm.values.medication_strength,
        medication_refills: Number(medicationForm.values.medication_refills),
        medication_quantity: Number(medicationForm.values.medication_quantity),
        medication_days_supply: medicationForm.values.medication_days_supply
          ? Number(medicationForm.values.medication_days_supply)
          : undefined,
        directions: medicationForm.values?.directions,
        pharmacyId: medicationForm.values.pharmacyId,
        pinCode,
        twoFacCode,
        notes: medicationForm.values.notes,
        substitutionAllowed: medicationForm.values.substitutionAllowed,
        bridge: medicationForm.values.bridge,
        queuedForId: medicationForm.values.queuedForId,
        type: isPrescriptionQueued ? 'queuing' : 'prescribing',
        effective_date: medicationForm.values?.effective_date,
        visitId: Number(visitId),
        employeeAddress: medicationForm.values?.employeeAddress,
        dispensableDrugId: medicationForm.values?.medication?.DispensableDrugId,
      },
    })

    if (priorAuthRationaleForm.values.category === 'No prior authorization required') {
      analytics.track('Prior authorization issue creation skipped due to clinician override', {
        reason: priorAuthRationaleForm.values.subCategory,
        additionalInfo: priorAuthRationaleForm.values.additionalInfo ?? '',
      })
      return
    }

    if (showPriorAuthWarning) {
      try {
        await createPriorAuthTaskMutation.mutateAsync({
          data: {
            type: 'coverage_investigation',
            priority: 4,
            description: [
              '<p>Clinical rationale for prior auth:</p>',
              `<p>· ${priorAuthRationaleForm.values.category}${
                priorAuthRationaleForm.values.subCategory
                  ? ` (${priorAuthRationaleForm.values.subCategory})`
                  : ''
              }</p>`,
              `${
                priorAuthRationaleForm.values.additionalInfo
                  ? `<p>· ${priorAuthRationaleForm.values.additionalInfo}</p>`
                  : ''
              }`,
            ].join(''),
            patientId,
            employeeId: '',
            setSystemGenerated: true,
            pod: getPodByTaskType('coverage_investigation'),
          },
        })
      } catch (error) {
        Fullstory.log('error', `Error auto-creating prior auth issue: ${JSON.stringify(error)}`)
      }
    }
  }

  const addPrescriptionFavoriteMutation = useMutation(prescriptionFavoritesApi.add, {
    onSuccess: () => {
      if (favoriteForm.values?.full_medication) {
        const newFormData = convertSaveFavoriteFormDataToFormData(favoriteForm.values)
        if (newFormData) {
          medicationForm.setValues(newFormData)
        }
      }
      setFavoritesBanner('success')
      setStep('Prescription Form')
    },
    onError: () => setFavoriteError('Error saving this prescription as a favorite.'),
  })

  const editPrescriptionFavoriteMutation = useMutation(prescriptionFavoritesApi.edit, {
    onSuccess: () => {
      setFavoriteToEdit(undefined)
      favoriteForm.reset()
      setFavoritesBanner('success')
      setStep('Prescription Form')
    },
    onError: () => setFavoriteError('Error editing this favorited prescription'),
  })

  const savePrescriptionAsFavorite = (data: SaveFavoriteFormData) => {
    if (data.full_medication && data.medication_strength && data.medication) {
      addPrescriptionFavoriteMutation.mutate({
        favoriteName: data.favoriteName,
        strength: data.medication_strength,
        medicationName: data.medication.Name,
        doseForm: data.full_medication.DoseForm,
        refills: data.medication_refills ? Number(data.medication_refills) : undefined,
        quantity: data.medication_quantity ? Number(data.medication_quantity) : undefined,
        daysSupply: data.medication_days_supply ? Number(data.medication_days_supply) : undefined,
        directions: data.directions,
        substitutionAllowed: data.substitutionAllowed,
        type: 'clinician',
        pharmacyNotes: data.notes,
        employeeId: currentUser.oid,
        dispensableDrugId: data.dispensableDrugId,
      })
    } else {
      setError('Error saving this prescription as a favorite.')
    }
  }

  const editPrescriptionFavorite = (data: SaveFavoriteFormData) => {
    if (
      data.full_medication &&
      data.medication_strength &&
      data.medication &&
      favoriteToEdit?.oid
    ) {
      editPrescriptionFavoriteMutation.mutate({
        id: favoriteToEdit?.oid,
        data: {
          favoriteName: data.favoriteName,
          strength: data.medication_strength,
          medicationName: data.medication.Name,
          doseForm: data.full_medication.DoseForm,
          refills: data.medication_refills ? Number(data.medication_refills) : undefined,
          quantity: data.medication_quantity ? Number(data.medication_quantity) : undefined,
          daysSupply: data.medication_days_supply ? Number(data.medication_days_supply) : undefined,
          directions: data.directions,
          substitutionAllowed: data.substitutionAllowed,
          type: 'clinician',
          pharmacyNotes: data.notes,
          employeeId: currentUser.oid,
        },
      })
    } else {
      setFavoriteError('Error editing this favorited prescription')
    }
  }

  const convertFormDataToSaveFavoriteFormData = (
    data: MedicationFormData | undefined,
  ): SaveFavoriteFormData | undefined => {
    if (!data) {
      return undefined
    }
    return {
      ...data,
      favoriteName: '',
      // @dosspotV2Migration
      dispensableDrugId: data.full_medication?.DispensableDrugId,
    }
  }

  const convertSaveFavoriteFormDataToFormData = (
    saveFavoriteData: SaveFavoriteFormData,
  ): MedicationFormData | undefined => {
    if (saveFavoriteData.full_medication) {
      return {
        ...saveFavoriteData,
        effective_date: dayjs().format('MM/DD/YYYY'),
        full_medication: saveFavoriteData.full_medication,
        bridge: false,
        employeeAddress: defaultEmployeeAddress,
      }
    }
    return undefined
  }

  const pharmacyUpdateOnClick = () => {
    setStep('Pharmacy Edit')
  }

  const onBack = () => {
    if (step === 'Edit Favorite') {
      setFavoriteToEdit(undefined)
      favoriteForm.reset()
    }
    setStep('Prescription Form')
  }

  return (
    <>
      <DrawerHeader onClose={onClose} onBack={step === 'Prescription Form' ? undefined : onBack}>
        {isPrescriptionQueued ? 'Queue prescription' : 'Send prescription'}
      </DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          {isPharmacyInactive && <PharmacyInactiveAlert />}
          {loading && <ALoadingSpinner />}
          {sendingPrescription && (
            <Stack align='center'>
              <Lottie style={{ height: 200 }} animationData={animation} />
              <Text
                bold
                style={{
                  color: colors.text[0],
                }}
              >
                {isPrescriptionQueued ? 'Queueing...' : 'Sending...'}
              </Text>
            </Stack>
          )}
          {!loading && !sendingPrescription && (
            <>
              {!patient?.data?.doseSpotId && (
                <div className='text-center my-4'>
                  <span className='text-error'>Patient does not have a DoseSpot ID on file.</span>
                  <ACreateDoseSpotPatientButton
                    patientId={patientId}
                    onSuccess={async () => {
                      await patient?.refetch()
                      return patientPharmacyQuery.refetch()
                    }}
                  />
                </div>
              )}
              {patient?.data?.doseSpotId && (
                <>
                  <MedicationFormProvider form={medicationForm}>
                    {step === 'Prescription Form' && (
                      <MPrescriptionForm
                        patient={patient?.data}
                        isPrescriptionQueued={isPrescriptionQueued}
                        favoritesBanner={favoritesBanner}
                        favoritesBannerOnClick={() => setFavoritesBanner('')}
                        saveFavoriteOnClick={() => {
                          const favoriteFormData = convertFormDataToSaveFavoriteFormData(
                            medicationForm.values,
                          )
                          if (favoriteFormData) {
                            favoriteForm.setValues(favoriteFormData)
                          }
                          setStep('Save Favorite')
                        }}
                        editFavoriteOnClick={template => {
                          setFavoriteToEdit(template)
                          setStep('Edit Favorite')
                        }}
                        pharmacyUpdateOnClick={pharmacyUpdateOnClick}
                        pharmacy={getPharmacyQuery.data}
                        pc={patient?.data?.primaryClinician}
                        nadea={nadea}
                        needsNewPriorAuth={shouldShowPriorAuthWarning && needsNewPriorAuth}
                      />
                    )}
                    {step === 'Preview' &&
                      queuedForOrSentByQuery.data &&
                      patient?.data &&
                      medicationForm.values && (
                        <Stack>
                          <MPrescriptionPreview
                            employee={queuedForOrSentByQuery.data}
                            patient={patient?.data}
                            onEdit={onBack}
                            prescription={{
                              PharmacyId: medicationForm.values.pharmacyId,
                              DisplayName: medicationForm.values?.medication?.Name || '',
                              Strength: medicationForm.values.medication_strength,
                              Quantity: medicationForm.values.medication_quantity,
                              DaysSupply: Number(medicationForm.values.medication_days_supply),
                              Refills: medicationForm.values.medication_refills,
                              NoSubstitutions: !medicationForm.values.substitutionAllowed,
                              Directions: medicationForm.values.directions,
                              PharmacyNotes: medicationForm.values.notes,
                              DispenseUnitId:
                                medicationForm.values?.full_medication?.DispenseUnitId,
                              NonDoseSpotPrescriptionId: medicationForm.values.bridge
                                ? 'bridge'
                                : null,
                              EffectiveDate: medicationForm.values.effective_date,
                            }}
                            showPriorAuthWarning={showPriorAuthWarning}
                          />
                          {patientAndClinicianLocations && !isPrescriptionQueued && (
                            <Stack spacing='md'>
                              <Checkbox
                                checked={employeeIsAtWorkAddress}
                                onChange={() => toggleEmployeeIsAtWorkAddress()}
                                label='I am at my work address'
                              />
                              {!employeeIsAtWorkAddress && (
                                <TextInput
                                  {...medicationForm.getInputProps('employeeAddress')}
                                  placeholder='Ex: 2 Main st, New York, NY 100001'
                                  label={
                                    <TooltipLabel
                                      sx={({ other: { colors } }) => ({
                                        backgroundColor: colors.background[6],
                                      })}
                                      label='Your address is protected and not shared with external sources including patients'
                                    >
                                      Your current address
                                    </TooltipLabel>
                                  }
                                />
                              )}
                            </Stack>
                          )}
                          {isPrescriptionQueued ? (
                            /* TODO: Make this a banner  -Jacob */
                            error && <div className='pt-8 text-error'>{error}</div>
                          ) : (
                            <MPrescriptionPINForm
                              requireTwoFac={isControlledSubstance(
                                medicationForm.values?.full_medication?.Schedule,
                              )}
                              error={error}
                            />
                          )}
                          {showPriorAuthWarning && (
                            <PriorAuthRationale priorAuthRationaleForm={priorAuthRationaleForm} />
                          )}
                        </Stack>
                      )}
                    {step === 'Pharmacy Edit' && (
                      <MEditPharmacyForm
                        title='Update pharmacy'
                        patientId={patientId}
                        onSelectPharmacy={pharmacy => {
                          settempSelectedPharmacy(pharmacy)
                        }}
                        shouldSetPreferredPharmacy={shouldSetPreferredPharmacy}
                        setShouldSetPreferredPharmacy={setShouldSetPreferredPharmacy}
                        selectedPharmacy={tempSelectedPharmacy}
                      />
                    )}
                  </MedicationFormProvider>
                  <FavoriteFormProvider form={favoriteForm}>
                    {(step === 'Save Favorite' || step === 'Edit Favorite') && (
                      <SaveFavoriteForm
                        loading={addPrescriptionFavoriteMutation.isLoading}
                        error={favoriteError}
                        type={step === 'Save Favorite' ? 'save' : 'edit'}
                      />
                    )}
                  </FavoriteFormProvider>
                </>
              )}
            </>
          )}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={getSubmit}
            disabled={sendingPrescription}
            rightIcon={step === 'Preview' ? <SendIcon /> : <ChevronRightIcon />}
          >
            {getPrescriptionButtonContent() as string}
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const PrescriptionReorderDrawer = ({
  opened,
  ...props
}: PrescriptionReorderDrawerProps & { opened: boolean }) => {
  return (
    <BetterDrawer opened={opened} onClose={props.onClose} position='right' size='lg'>
      <PrescriptionReorderContent {...props} />
    </BetterDrawer>
  )
}
