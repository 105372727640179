/**
 * NB: The mapping here has drifted from the jotform object, some prompts are missing!
 * This is used with a deprecated intake form source, and should not be touched until
 * Visit Notes is refactored entirely.
 */
const intakeLabels: Record<string, string> = {}

intakeLabels.usingOpioids = 'Are you currently using opioids?'
intakeLabels.usingOpioidsForHowLong = 'How long have you been using?'
intakeLabels.preferredRouteOfAdministration = 'What is your preferred route of administration?'
intakeLabels.usingHowFrequently = 'How frequently are you using?'
intakeLabels.spendingPerDay = 'How much are you spending per day?'
intakeLabels.substancesUsedInPastMonth = 'What substances have you used in the past month?'
intakeLabels.treatedForOUDBefore = 'Have you been treated for OUD before?'
intakeLabels.typeOfTreatmentInPast = 'What type of treatment(s) have you tried in the past?'
intakeLabels.lastTimeInTreatment = 'When was the last time you were in treatment?'
intakeLabels.takenMedicationToTreatOUD = 'Have you taken medication to treat OUD before?'
intakeLabels.interestedInPrescribedBup = 'Are you interested in being prescribed Buprenorphine?'
intakeLabels.drugOverdoseInPast = 'Have you overdosed in the past?'
intakeLabels.quantityOfDrugOverdoses = 'How many times have you overdosed?'
intakeLabels.lastDrugOverdose = 'When was your last overdose?'
intakeLabels.accessToNarcan = 'Do you have access to Narcan?'
intakeLabels.mentalHealthState = 'What is the state of your mental health?'
intakeLabels.medicalDiagnoses = 'Do you have any mental health conditions?'
intakeLabels.beenHospitalizedInPsychiatricUnit = 'Have you been hospitalized in a psychiatric unit?'
intakeLabels.howLongSincePsychiatricHospitalization =
  'How long has it been since you were hospitalized in a psychiatric unit?'
intakeLabels.medicalConditionsList = 'List of medical conditions'
intakeLabels.currentMedicationsList = 'List of medications'
intakeLabels.medicationAllergiesList = 'List of allergies to medications'
intakeLabels.recentHospitalizationsList = 'List of recent hospitalizations'
intakeLabels.emergencyContact = 'Emergency contact'
intakeLabels.dateOfBirth = 'Date of birth'
intakeLabels.gender = 'Gender'
intakeLabels.ethnicity = 'Ethnicity'
intakeLabels.preferredPharmacy = 'Preferred pharmacy'
intakeLabels.idSubmission = 'Submission ID'
intakeLabels.name = 'Name'
intakeLabels.email = 'Email'
intakeLabels.address = 'Address'
intakeLabels.city = 'City'
intakeLabels.state = 'State'
intakeLabels.zip = 'Zip code'
intakeLabels.phoneNumber = 'Phone'
intakeLabels.currentCity = 'City'
intakeLabels.currentState = 'State'

export default intakeLabels
