import cn from 'classnames'
import React from 'react'

export type ODSSecondaryButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
  type?: 'submit' | 'button' | 'reset'
  message?: string
  className?: string
  disabled?: boolean
  loading?: boolean
}

const ODSSecondaryButton = ({
  icon,
  type = 'submit',
  message,
  className,
  onClick,
  disabled,
  loading,
}: ODSSecondaryButtonProps) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      className={cn(
        {
          'animate-pulse': loading,
          'opacity-40 cursor-not-allowed': disabled && !loading,
        },
        'inline-flex items-center px-4 py-2 border border-daybreak-actions0 shadow-sm text-sm font-medium rounded-md text-daybreak-text3 bg-white hover:border-daybreak-actions1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-daybreak-actions1',
        className,
      )}
    >
      {icon}
      {message}
    </button>
  )
}

export default ODSSecondaryButton
