import { Stack } from '@shared/components'
import { useLocation } from 'react-router-dom'
import { PatientProfileAccountInformation } from './PatientProfileAccountInformation'
import { PatientProfileBillingTeamNotes } from './PatientProfileBillingTeamNotes'
import { PatientProfileClinicalAndCareInformation } from './PatientProfileClinicalAndCareInformation'
import { PatientProfileEmergencyContact } from './PatientProfileEmergencyContact'
import { PatientProfileHomeAddress } from './PatientProfileHomeAddress'
import { PatientProfileInsurance } from './PatientProfileInsurance'
import { PatientProfilePaymentMethod } from './PatientProfilePaymentMethod'
import { PatientProfilePersonalInformation } from './PatientProfilePersonalInformation'
import { PatientProfileShippingAddress } from './PatientProfileShippingAddress'

export const PatientProfileContent = () => {
  const location = useLocation()

  return (
    <Stack spacing='xl' p='lg' mx='auto' maw='640px'>
      <PatientProfilePersonalInformation />
      <PatientProfileAccountInformation />
      <PatientProfileClinicalAndCareInformation />
      <PatientProfilePaymentMethod />
      <PatientProfileBillingTeamNotes />
      <PatientProfileInsurance />
      <PatientProfileShippingAddress open={location.state === 'shipping'} />
      <PatientProfileHomeAddress open={location.state === 'home'} />
      <PatientProfileEmergencyContact />
    </Stack>
  )
}
