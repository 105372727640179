import {
  Box,
  Center,
  Group,
  Image,
  LogInIcon,
  OpheliaWordmark,
  PrimaryButton,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { activeStates } from '@shared/types'
import range from 'lodash/range'
import sample from 'lodash/sample'
import { analytics } from '../analytics'
import { useAuth } from '../context/auth'
import * as FullStory from '../utils/fullstory'

export const PLoginV2 = () => {
  const { signInWithPopup } = useAuth()

  const age = sample(range(18, 58))
  const state = sample(activeStates)!
  const descriptor = sample(['brother', 'sister', 'son', 'daughter', 'cousin', 'co-worker'])

  useLifecycle({
    onMount: () => {
      window.scrollTo(0, 0)
      analytics.page({ name: 'Login' })
    },
  })

  const onSignIn = async () => {
    try {
      await signInWithPopup()
    } catch (err) {
      FullStory.log('error', 'Login Attempt Failed')
    }
  }

  return (
    <Center sx={{ height: '80vh' }}>
      <Group spacing='lg'>
        <Image src={process.env.REACT_APP_LOGIN_PAGE_LOGO_URL} width={500} />
        <Stack>
          <Box
            sx={theme => ({
              color: theme.other.colors.actions[1],
              width: `calc(${theme.spacing.xl} * 2)`,
            })}
          >
            <OpheliaWordmark />
          </Box>
          <Stack spacing='xs'>
            <TitleTwo>
              Someone&apos;s {age}-year-old {descriptor} in {state.state}.
            </TitleTwo>
            <TitleTwo>Life saving treatment.</TitleTwo>
            <TitleTwo>Thanks to you.</TitleTwo>
            <Text>Keep up the good work.</Text>
          </Stack>
          <PrimaryButton rightIcon={<LogInIcon />} onClick={onSignIn}>
            Sign in
          </PrimaryButton>
        </Stack>
      </Group>
    </Center>
  )
}
