import { Navigate, Route, Routes } from 'react-router-dom'
import { Page } from '../../components/templates/TDefault'
import {
  EnrollmentPanelHeaderContent,
  EnrollmentPanelPage,
} from './EnrollmentPanelHeaderContentProps'
import { EnrollmentPanelTable } from './candidates/EnrollmentPanelTable'
import { ReferralLeadsTable } from './leads/ReferralLeadsTable'
import { BookedCandidatesTables } from './candidates/BookedCandidatesTable'
import { Box } from '@shared/components'

export const PEnrollmentPanel = () => {
  return (
    <Routes>
      <Route
        path='/booked-candidates'
        element={
          <Page
            title='Enrollment Panel'
            headerContent={
              <EnrollmentPanelHeaderContent
                selectedPage={EnrollmentPanelPage.CandidatesWithIntakes}
              />
            }
            pushHeaderContent={false}
          >
            <Box px='lg' py='md'>
              <BookedCandidatesTables />
            </Box>
          </Page>
        }
      />
      <Route
        path='/candidates'
        element={
          <Page
            title='Enrollment Panel'
            headerContent={
              <EnrollmentPanelHeaderContent
                selectedPage={EnrollmentPanelPage.CandidatesWithoutIntakes}
              />
            }
            pushHeaderContent={false}
          >
            <Box px='lg' py='md'>
              <EnrollmentPanelTable />
            </Box>
          </Page>
        }
      />
      <Route
        path='/leads'
        element={
          <Page
            title='Enrollment Panel'
            headerContent={
              <EnrollmentPanelHeaderContent selectedPage={EnrollmentPanelPage.Leads} />
            }
            pushHeaderContent={false}
          >
            <Box px='lg' py='md'>
              <ReferralLeadsTable />
            </Box>
          </Page>
        }
      />
      <Route path='*' element={<Navigate replace to='/enrollment-panel/candidates' />} />
    </Routes>
  )
}
