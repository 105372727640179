/* eslint-disable no-magic-numbers */

import { Box, Stack, Text, useMantineTheme } from '@shared/components'
import { UnusedTimeEventForRender } from '@shared/types'
import { CalendarEvent } from './CalendarEvent'

export type UnusedTimeEventProps = {
  block: UnusedTimeEventForRender
  hasAvailableSlots: boolean
}

export const UnusedTimeEvent = ({ block, hasAvailableSlots }: UnusedTimeEventProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const diagonalBackgroundGradient = `repeating-linear-gradient(45deg, ${colors.error[1]}, ${
    colors.error[1]
  } 3px, ${hasAvailableSlots ? 'transparent' : colors.error[0]} 3px, ${
    hasAvailableSlots ? 'transparent' : colors.error[0]
  } 6px)`

  return (
    <Stack mr='sm'>
      <CalendarEvent
        fullWidth
        color={diagonalBackgroundGradient}
        duration={block.duration}
        borderColor={colors.background[2]}
        tooltip={
          <Text size='xs' bold color={colors => colors.text[3]}>
            {block.duration} mins
          </Text>
        }
      >
        <Box></Box>
      </CalendarEvent>
    </Stack>
  )
}
