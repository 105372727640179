import {
  ArrowRightIcon,
  Box,
  Divider,
  Group,
  Space,
  Stack,
  Tabs,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import {
  InsuranceQueue,
  InsuranceReviewQueueItem,
  InsuranceType,
  isAdmin,
  isLeadCoordinator,
} from '@shared/types'
import { name } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/auth'
import IRQActionSection from './IRQActionSection'
import IRQAdminSection from './IRQAdminSection'
import IRQBillingNotesSection from './IRQBillingNotesSection'
import { IRQBillingTeamActionSection } from './IRQBillingTeamActionSection'
import IRQItemData from './IRQItemData'
import { IRQMissingInfoSection } from './IRQMissingInfoSection'

export type IRQItemProps = {
  item: InsuranceReviewQueueItem
  refreshQueue: () => void
  activeTab: InsuranceQueue
}

const IRQItem = ({ item, refreshQueue, activeTab }: IRQItemProps) => {
  const { currentUser } = useAuth()
  const canRefreshQueue = isAdmin(currentUser) || isLeadCoordinator(currentUser)

  const [activeInsuranceTab, setActiveInsuranceTab] = useState<InsuranceType>('primary')

  useEffect(() => {
    setActiveInsuranceTab('primary')
  }, [item])

  return (
    <Stack px='md' pt='md' spacing='md'>
      <>
        <Group position='apart'>
          <Group spacing='xs'>
            <TitleTwo>
              {name({ first: item?.firstName, last: item?.lastName }).lastCommaFirst()}
            </TitleTwo>
            <Text>&mdash; {capitalize(item.statuses.patient)}</Text>
          </Group>
          <TertiaryButton
            component={Link}
            to={`/patients/${item.id}`}
            rightIcon={<ArrowRightIcon />}
          >
            Patient chart
          </TertiaryButton>
        </Group>
        {activeTab === 'enrollment' && <IRQMissingInfoSection item={item} />}
      </>
      <Box pt='md'>
        <Tabs
          value={activeInsuranceTab}
          onTabChange={tab => setActiveInsuranceTab(tab as InsuranceType)}
        >
          <Tabs.List>
            <Tabs.Tab value='primary'>{item?.insurance?.provider ?? 'Primary insurance'}</Tabs.Tab>
            {item.hasSecondaryInsurance && (
              <Tabs.Tab value='other'>
                {item.secondaryInsurance?.provider ?? 'Secondary insurance'}
              </Tabs.Tab>
            )}
          </Tabs.List>
          <IRQItemData item={item} insuranceType={activeInsuranceTab} refreshQueue={refreshQueue} />
          <Space h='md' />
          {activeTab === 'enrollment' ? (
            <>
              <Divider my='lg' />
              <IRQActionSection item={item} refreshQueue={refreshQueue} />
            </>
          ) : (
            <>
              <IRQBillingNotesSection key={item.id} item={item} />
              <IRQBillingTeamActionSection
                item={item}
                refreshQueue={refreshQueue}
                currentQueue={activeTab}
              />
            </>
          )}
          {canRefreshQueue && (
            <>
              <Divider my='lg' />
              <IRQAdminSection item={item} refreshQueue={refreshQueue} />
            </>
          )}
        </Tabs>
      </Box>
    </Stack>
  )
}

export default IRQItem
