import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'

export type IconCloseProps = {
  textColor?: string
}

const IconClose = ({ textColor = '' }: IconCloseProps) => (
  <IoMdClose className={`${textColor} w-5 h-5`} />
)

export default IconClose
