import { ArchiveIcon, InboxIcon, Menu, UserPlusIcon } from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { CurrentTaskOperation } from '../../TaskTable'

export type SnoozedActionMenuItemsProps = {
  task: EMRTaskDecorated
  onClick: ({ operation, task }: CurrentTaskOperation) => void
}

export const SnoozedActionMenuItems = ({ task, onClick }: SnoozedActionMenuItemsProps) => {
  return (
    <>
      <Menu.Item
        onClick={() => onClick({ operation: 'reopen', task })}
        icon={<InboxIcon color={colors => colors.actions[0]} />}
      >
        Reopen
      </Menu.Item>
      <Menu.Item
        onClick={() => onClick({ operation: 'close', task })}
        icon={<ArchiveIcon color={colors => colors.actions[0]} />}
      >
        Close
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          onClick({
            operation: 'edit',
            task,
          })
        }
        icon={<UserPlusIcon color={colors => colors.actions[0]} />}
      >
        Add/edit assignee
      </Menu.Item>
    </>
  )
}
