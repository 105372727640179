import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useEmployees } from '../../utils/hooks'
import { OutOfOfficeFormValues } from './OutOfOfficeForm'

const getDateString = ({ formValues }: { formValues: OutOfOfficeFormValues }) => {
  const { startDate, startTime, endDate, endTime, allDay: isAllDay } = formValues

  const isCallingOutForOneDay = dayjs(startDate).isSame(dayjs(endDate), 'day')

  const startDateWithTime = isAllDay
    ? dayjs(startDate).startOf('day')
    : dayjs(startDate)
        .set('hour', Number(startTime.split(':')[0]))
        .set('minute', Number(startTime.split(':')[1]))

  const endDateWithTime = isAllDay
    ? dayjs(endDate).endOf('day')
    : dayjs(endDate)
        .set('hour', Number(endTime.split(':')[0]))
        .set('minute', Number(endTime.split(':')[1]))

  const formattedStartDate = startDateWithTime.format('MMM D YYYY').toUpperCase()
  const formattedStartTime = startDateWithTime.format('h:mmA')

  const formattedEndDate = endDateWithTime.format('MMM D YYYY').toUpperCase()
  const formattedEndTime = endDateWithTime.format('h:mmA')

  if (isAllDay) {
    if (isCallingOutForOneDay) {
      return `${formattedStartDate}`
    }

    return `${formattedStartDate} to ${formattedEndDate}`
  }

  if (isCallingOutForOneDay) {
    return `${formattedStartDate} from ${formattedStartTime} to ${formattedEndTime}`
  }

  return `${formattedStartDate} at ${formattedStartTime} to ${formattedEndDate} at ${formattedEndTime}`
}

export const OutOfOfficeModal = ({
  opened,
  onSubmit,
  onClose,
  isLoading,
  calendarId,
  formValues,
}: {
  opened: boolean
  onSubmit: () => void
  onClose: () => void
  isLoading: boolean
  calendarId: string
  formValues: OutOfOfficeFormValues
}) => {
  const dateString = getDateString({ formValues })

  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const employees = employeesQuery.data

  const selectedProviderName =
    employees
      ?.filter(employee => employee.calendarId)
      .find(employee => employee.calendarId.toString() === calendarId)?.name || ''

  return (
    <BetterModal opened={opened} onClose={onClose}>
      <ModalHeader onClose={onClose}>Confirm {formValues.type} call-out details</ModalHeader>
      <ModalContent>
        <Stack>
          <Stack spacing='xs'>
            <Text>
              You are adding an {formValues.type} call-out block for {selectedProviderName}:
            </Text>
            <Text>{dateString}</Text>
          </Stack>
          <Text>Are you sure you want to proceed?</Text>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Group position='right' noWrap>
          <SecondaryButton onClick={onClose}>No, go back</SecondaryButton>
          <PrimaryButton onClick={onSubmit} loading={isLoading} disabled={isLoading}>
            Yes, confirm & block calendar
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}
