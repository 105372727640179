/*
 * This component is essentially a duplicate of the same component in
 * client/patient/src/common/components/CityStateSelectGroup.tsx.
 * If you update one, you should likely update the other.
 */

import { GetInputProps } from '@shared/components'
import { CITY_STATE_OTHER, CITY_STATE_UNKNOWN, isCityOrStateKnown, toTime } from '@shared/utils'
import algoliasearch from 'algoliasearch'
import debounce from 'lodash/debounce'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Config } from '../../../../../config'
import { PatientVisitNoteSelect } from './PatientVisitNoteSelect'

export type CityStateForm = { state: string; city: string }

export type CityStateFormInputProps<T> = GetInputProps<T>

type StateObject = {
  state: string
  abbr: string
  active: boolean
  paused: boolean
}

type PatientVisitNoteCityStateSelectGroupProps = {
  states: readonly StateObject[]
  inputProps: CityStateFormInputProps<CityStateForm>
  cityPropName?: string
  statePropName?: string
}

// Returns dropdown for states, and dropdown of places for the state if state is selected
export const PatientVisitNoteCityStateSelectGroup = ({
  states,
  inputProps,
  // By default the city and state props are 'city' and 'state', but alternative names can be passed in
  cityPropName = 'city',
  statePropName = 'state',
}: PatientVisitNoteCityStateSelectGroupProps) => {
  const cityValue = inputProps(cityPropName).value
  const stateValue = inputProps(statePropName).value

  const [cityQuery, setCityQuery] = useState(cityValue || '')

  const client = algoliasearch(Config.ALGOLIA_APPLICATION_ID, Config.ALGOLIA_SEARCH_KEY)

  const index = client.initIndex('places')
  const showCityDropdown = Boolean(stateValue && isCityOrStateKnown(stateValue))

  const { data } = useQuery(
    // Call algolia when state is changed, or user types cityQuery
    ['places', stateValue, cityQuery],
    async () => {
      const res = (await index.search(cityQuery, {
        hitsPerPage: 50,
        filters: `state_id:${stateValue}`,
        attributesToRetrieve: ['city'],
      })) as { hits: { city: string; objectID: string }[] }
      return res.hits
    },
    {
      enabled: showCityDropdown,
    },
  )

  const places = data || []

  const debouncedQueryUpdate = debounce((value: string) => {
    // If state is known, use query value. Otherwise, use empty query.
    const query = isCityOrStateKnown(stateValue) ? value : ''
    setCityQuery(query)
  }, toTime('0.2 sec').ms())

  const resetCity = (value: string) => {
    inputProps(cityPropName).onChange('')
    setCityQuery('')

    inputProps(statePropName).onChange(value)
  }

  return (
    <>
      <PatientVisitNoteSelect
        searchable
        placeholder='Select one...'
        label='Provider state'
        data={[
          { value: CITY_STATE_UNKNOWN, label: 'N/A' },
          { value: CITY_STATE_OTHER, label: 'Other' },
          ...states.map((state: StateObject) => ({
            value: state.abbr,
            label: state.state,
          })),
        ]}
        {...inputProps(statePropName)}
        onChange={resetCity}
      />
      {showCityDropdown && (
        <PatientVisitNoteSelect
          searchable
          placeholder='Type to search...'
          clearable
          label='Provider city'
          data={[
            { value: CITY_STATE_UNKNOWN, label: 'N/A' },
            { value: CITY_STATE_OTHER, label: 'Other' },
            ...places
              .filter(city => city.city)
              .map(city => ({
                value: city.city,
                label: city.city,
              })),
          ]}
          {...inputProps(cityPropName)}
          onSearchChange={debouncedQueryUpdate}
        />
      )}
    </>
  )
}
