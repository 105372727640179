import { Textarea, TextareaProps } from '@shared/components'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitNoteReadOnlyText } from './PatientVisitNoteReadOnlyText'

export const PatientVisitNoteTextarea = (props: TextareaProps) => {
  const { canEdit } = usePatientVisitNote()

  if (!canEdit) {
    return <PatientVisitNoteReadOnlyText label={props.label} value={props.value} />
  }

  return <Textarea {...props} />
}
