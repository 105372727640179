import { EmrApi } from '@shared/types'
import { toTime } from '@shared/utils'
import { useEmrQuery } from './use-emr-query'

export const useEmployees = (query?: EmrApi['GET /employees/list']['req']['query']) => {
  return useEmrQuery(
    'GET /employees/list',
    { query },
    {
      // Do not refetch the employees list more than once every 5 minutes.
      staleTime: toTime('5 min').ms(),
      cacheTime: toTime('5 min').ms(),
    },
  )
}
