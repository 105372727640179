import { useForm } from '@mantine/form'
import {
  Group,
  Modal,
  PrimaryButton,
  showNotification,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import { useMutation, useQueryClient } from 'react-query'
import { caseReviewNoteApi, emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { isNumber, isRequired } from '../../../utils/formValidation'
const LockCaseReviewModal = ({
  closeModal,
  closeDrawer,
  noteId,
  visitId,
  isLoading,
  preWorkMinutes,
  open,
  psychConsultantName,
}: {
  open: boolean
  noteId: string
  visitId?: number
  closeModal: () => void
  closeDrawer: () => void
  isLoading: boolean
  preWorkMinutes: string | undefined
  psychConsultantName: string
}) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const updateCaseReviewNote = useMutation(caseReviewNoteApi.update, {
    onSuccess: () => {
      showNotification({
        message: 'Case review note has been locked successfully',
        variant: 'success',
      })
      if (visitId) {
        const [queryKey] = emrApi.getQuery('GET /caseReviewNote/visit/:visitId', {
          params: {
            visitId: `${visitId}`,
          },
        })
        void queryClient.invalidateQueries(queryKey)
      }
      void queryClient.invalidateQueries('GET /caseReview')
    },
  })

  const { getInputProps, values, validate, reset } = useForm<{
    postCaseReviewTime: string
    caseReviewTime: string
  }>({
    validate: {
      postCaseReviewTime: validateWith(isRequired, isNumber),
      caseReviewTime: validateWith(isRequired, isNumber),
    },
  })

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }
    updateCaseReviewNote.mutate({
      oid: noteId,
      data: {
        lockedAt: new Date().toISOString(),
        isLocked: true,
        lockedBy: currentUser.oid,
        caseReviewTime: values.caseReviewTime,
        postCaseReviewTime: values.postCaseReviewTime,
      },
    })

    closeModal()
    reset()
    closeDrawer()
  }

  return (
    <Modal
      opened={open}
      onClose={closeModal}
      title='Sign and lock note'
      footer={
        <Group position='right'>
          <PrimaryButton onClick={onSubmit}>Sign and lock note</PrimaryButton>
        </Group>
      }
    >
      {!isLoading && (
        <Stack spacing='md'>
          <Text>How much time did you spend on this case review?</Text>
          <TextInput label='Pre-work' placeholder='Not entered' disabled value={preWorkMinutes} />
          <TextInput
            {...getInputProps('caseReviewTime')}
            label={`With psychiatric consultant (${psychConsultantName})`}
            placeholder='Total minutes'
          />
          <TextInput
            {...getInputProps('postCaseReviewTime')}
            label='Post case review'
            placeholder='Total minutes'
          />
          <Text>
            This estimate is true and accurate to the best of my knowledge. I understand that I am
            being asked for this estimate for insurance billing purposes.
          </Text>
        </Stack>
      )}
    </Modal>
  )
}

export default LockCaseReviewModal
