import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { useAuthForCCPages } from '../../utils/hooks'

const PCareTeam = () => {
  const { currentUser } = useAuth()

  useAuthForCCPages(currentUser)

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/billing/irq' replace />} />
    </Routes>
  )
}

export default PCareTeam
