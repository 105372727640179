import { Select, SelectProps, Stack, Text } from '@shared/components'

export type VisitNoteSelectProps = SelectProps & { isEditing: boolean }

export const VisitNoteSelect = ({ isEditing, ...props }: VisitNoteSelectProps) => {
  if (isEditing) {
    return <Select {...props} />
  }

  const displayValue = getDisplayValue(props.data, props.value)

  return (
    <Stack spacing='xs' sx={{ overflowWrap: 'anywhere', whiteSpace: 'break-spaces' }}>
      {props.label && <Text color={colors => colors.text[1]}>{props.label}</Text>}
      <Text component='p'>{displayValue}</Text>
    </Stack>
  )
}

function getDisplayValue(data: SelectProps['data'], value: SelectProps['value']) {
  for (const item of data) {
    if (typeof item === 'object' && item.value === value) {
      return item.label
    }
  }

  return value || 'N/A'
}
