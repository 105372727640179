import {
  Box,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  Flex,
  PrimaryButton,
  ScrollArea,
  Tooltip,
} from '@shared/components'
import { useState } from 'react'
import { PatientLeftSidebar } from '../PatientLeftSidebar'

export const PatientSidebar = ({ canAddDiagnoses }: { canAddDiagnoses?: boolean }) => {
  const [visible, setVisible] = useState(true)

  return (
    <>
      <Flex
        my='md'
        ml='md'
        sx={({ other: { colors }, radius }) => ({
          backgroundColor: colors.background[1],
          display: visible ? 'flex' : 'none',
          position: 'relative',
          borderRadius: radius.sm,
        })}
      >
        <ScrollArea w='20rem' type='never'>
          <Box pb='xl'>
            <PatientLeftSidebar canAddDiagnoses={canAddDiagnoses} />
          </Box>
        </ScrollArea>
        <Tooltip native label='Hide info panel'>
          <PrimaryButton
            sx={({ spacing }) => ({
              position: 'absolute',
              bottom: spacing.md,
              right: spacing.md,
            })}
            radius='xl'
            leftIcon={<ChevronsLeftIcon />}
            onClick={() => setVisible(false)}
          />
        </Tooltip>
      </Flex>
      <Tooltip native label='Show info panel'>
        <PrimaryButton
          radius='xl'
          leftIcon={
            <Flex
              sx={({ other: { sizes }, spacing }) => ({
                paddingLeft: spacing.md,
                paddingRight: sizes.gap.lg,
              })}
            >
              <ChevronsRightIcon />
            </Flex>
          }
          onClick={() => setVisible(true)}
          sx={({ spacing }) => ({
            zIndex: 10,
            position: 'absolute',
            // Must equal padding added to the sidebar + padding added to the collapse button
            bottom: spacing.lg,
            left: 0,
            display: visible ? 'none' : 'flex',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            transition: 'all 0.4s ease',
            ':hover': { paddingLeft: spacing.md },
          })}
        />
      </Tooltip>
    </>
  )
}
