/* eslint-disable no-magic-numbers */

import { EM_DASH, Group, Stack, Text, useMantineTheme } from '@shared/components'
import { BlockEventForRender } from '@shared/types'
import { dayjs } from '@shared/utils'
import { CalendarEvent } from './CalendarEvent'

export type BlockEventProps = {
  block: BlockEventForRender
}

const parseNotes = (notes: string) => {
  // Notes are of the form `some text (creator@email.com)`, and we want to remove the email
  const tokens = notes.split(' ')
  return tokens.slice(0, tokens.length - 1).join(' ')
}

export const BlockEvent = ({ block }: BlockEventProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const diagonalBackgroundGradient = `repeating-linear-gradient(45deg, ${colors.background[3]}, ${colors.background[3]} 3px, ${colors.background[1]} 3px, ${colors.background[1]} 6px)`

  return (
    <CalendarEvent
      color={diagonalBackgroundGradient}
      duration={block.duration}
      tooltip={
        <>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {parseNotes(block.event.notes)}
          </Text>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {dayjs(block.start).format('h:mma')} {EM_DASH} {dayjs(block.event.end).format('h:mma')}
          </Text>
        </>
      }
    >
      <Stack spacing={0} px='xs'>
        <Text size={block.duration >= 15 ? 'md' : 'xs'} bold>
          Unavailable
        </Text>
        {block.duration >= 30 && (
          <Group position='apart' noWrap align='flex-start'>
            <Text size='xs' color={colors => colors.text[1]}>
              {parseNotes(block.event.notes)}
            </Text>
          </Group>
        )}
      </Stack>
    </CalendarEvent>
  )
}
