import { useForm } from '@mantine/form'
import {
  Banner,
  Group,
  isAnySelected,
  Pill,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  TitleThree,
} from '@shared/components'
import { getOpheliaHttpError, InsuranceReviewQueueItem } from '@shared/types'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { isInsuranceActive } from './utils'

export type IRQActionSectionProps = {
  item: InsuranceReviewQueueItem
  refreshQueue: () => void
}

type NetworkDecisionForm = {
  primaryInsuranceInNetwork: 'yes' | 'no' | undefined
  secondaryInsuranceInNetwork: 'yes' | 'no' | undefined
}

const IRQActionSection = ({ item, refreshQueue }: IRQActionSectionProps) => {
  const [error, setError] = useState('')
  const { mutateAsync: mutateUpdateIRQRecord, isLoading: isUpdateIRQRecordLoading } = useMutation(
    emrApi.getMutation('POST /insurance/review-queue'),
    {
      onSuccess: () => {
        reset()
        void refreshQueue()
      },
      onError: error => {
        setError(getOpheliaHttpError(error, `Failed to submit patient's insurance information`))
      },
    },
  )

  const isPrimaryInsuranceVerified = Boolean(item.eligibleInsuranceData || item.manualInsuranceData)
  const isSecondaryInsuranceVerified = Boolean(
    item.secondaryEligibleInsuranceData || item.secondaryManualInsuranceData,
  )

  const isPrimaryInsuranceInactive = isPrimaryInsuranceVerified
    ? !isInsuranceActive({
        eligibleInsuranceData: item.eligibleInsuranceData,
        manualInsuranceData: item.manualInsuranceData,
      })
    : false

  const isSecondaryInsuranceInactive = isSecondaryInsuranceVerified
    ? !isInsuranceActive({
        eligibleInsuranceData: item.secondaryEligibleInsuranceData,
        manualInsuranceData: item.secondaryManualInsuranceData,
      })
    : false

  const { reset, values, getInputProps, validate } = useForm<NetworkDecisionForm | undefined>({
    initialValues: { primaryInsuranceInNetwork: undefined, secondaryInsuranceInNetwork: undefined },
    validate: {
      primaryInsuranceInNetwork: isPrimaryInsuranceInactive
        ? undefined
        : isAnySelected(['yes', 'no'], 'Required'),
      secondaryInsuranceInNetwork:
        item.hasSecondaryInsurance && !isSecondaryInsuranceInactive
          ? isAnySelected(['yes', 'no'], 'Required')
          : undefined,
    },
  })

  const submit = async () => {
    if (validate().hasErrors) {
      return
    }

    await mutateUpdateIRQRecord({
      data: {
        primaryInNetwork: values?.primaryInsuranceInNetwork === 'yes',
        secondaryInNetwork: values?.secondaryInsuranceInNetwork === 'yes',
        patientId: item.userId,
      },
    })
  }

  return (
    <Stack>
      <RadioGroup
        key={`${item.id}_primary`}
        {...getInputProps('primaryInsuranceInNetwork')}
        label={
          <Group>
            <TitleThree>{item.insurance?.provider ?? 'Primary insurance'}</TitleThree>
            <Pill status='none' variant='filled'>
              Primary insurance
            </Pill>
            {isPrimaryInsuranceInactive && (
              <Pill status='warning' variant='filled'>
                Inactive
              </Pill>
            )}
          </Group>
        }
      >
        <Radio
          disabled={isUpdateIRQRecordLoading || isPrimaryInsuranceInactive}
          value='yes'
          label='Plan is in network'
        />
        <Radio
          value='no'
          disabled={isUpdateIRQRecordLoading || isPrimaryInsuranceInactive}
          label='Plan is out of network'
        />
      </RadioGroup>
      {item.hasSecondaryInsurance && (
        <RadioGroup
          key={`${item.id}_secondary`}
          {...getInputProps('secondaryInsuranceInNetwork')}
          label={
            <Group>
              <TitleThree>{item.secondaryInsurance?.provider ?? 'Secondary insurance'}</TitleThree>
              {isSecondaryInsuranceInactive && (
                <Pill status='warning' variant='filled'>
                  Inactive
                </Pill>
              )}
            </Group>
          }
        >
          <Radio
            disabled={isUpdateIRQRecordLoading || isSecondaryInsuranceInactive}
            value='yes'
            label='Plan is in network'
          />
          <Radio
            value='no'
            disabled={isUpdateIRQRecordLoading || isSecondaryInsuranceInactive}
            label='Plan is out of network'
          />
        </RadioGroup>
      )}
      <PrimaryButton loading={isUpdateIRQRecordLoading} onClick={submit}>
        Submit & remove from queue
      </PrimaryButton>
      {error && <Banner label={error} type='error' />}
    </Stack>
  )
}

export default IRQActionSection
