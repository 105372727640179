import {
  Box,
  Divider,
  Group,
  Pill,
  PlusIcon,
  SecondaryButton,
  Stack,
  TitleFour,
} from '@shared/components'

export type DrugScreenEmptyStateProps = {
  onClick: () => void
  displayHeader?: boolean
}

const DrugScreenEmptyState = ({ onClick, displayHeader = true }: DrugScreenEmptyStateProps) => {
  return (
    <Stack>
      {displayHeader && (
        <>
          <Group position='left'>
            <TitleFour>Drug screens</TitleFour>
          </Group>
          <Divider />
        </>
      )}
      <Box
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
        })}
        p='lg'
      >
        <Stack align='center'>
          <Pill variant='filled' status='none'>
            No drug screens added yet
          </Pill>
          <SecondaryButton onClick={onClick} leftIcon={<PlusIcon />}>
            Drug screen
          </SecondaryButton>
        </Stack>
      </Box>
    </Stack>
  )
}

export default DrugScreenEmptyState
