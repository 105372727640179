import { Alert, InfoIcon, SlashIcon } from '@shared/components'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../../api'
import { usePatient } from '../../../PPatientContext'
import {
  DischargeDate,
  FullDischargeNote,
} from '../../../journey/content/dischargeNote/DischargeNoteContent'
import { PatientVisitNoteLoader } from '../visitNoteForm/PatientVisitNoteLoader'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'

const DischargeNote = ({ patientId }: { patientId: string }) => {
  const dischargeNotesQuery = useQuery(
    ...emrApi.getQuery('GET /dischargeNotes', {
      query: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  if (dischargeNotesQuery.isLoading) {
    return <PatientVisitNoteLoader />
  } else if (dischargeNotesQuery.data?.length) {
    const lastDischargeNote = dischargeNotesQuery.data[0]
    if (lastDischargeNote) {
      return (
        <>
          <DischargeDate dischargeNote={lastDischargeNote} />
          <FullDischargeNote dischargeNote={lastDischargeNote} />
        </>
      )
    }
  } else if (!dischargeNotesQuery.isError && dischargeNotesQuery.data?.length === 0) {
    return (
      <Alert variant='primary' icon={<InfoIcon />} title='No discharge note'>
        The patient was discharged from care without a discharge note.
      </Alert>
    )
  }
  return (
    <Alert variant='error' icon={<SlashIcon />} title='Cannot find discharge note'>
      Please refresh the page to try again, or submit a bug report with the button in the main
      navigation bar on the left.
    </Alert>
  )
}

export const DischargeNoteSection = () => {
  const { patientId } = usePatient()

  return (
    <PatientVisitNoteSection title='Discharge note'>
      <DischargeNote patientId={patientId} />
    </PatientVisitNoteSection>
  )
}
