import {
  barc10ResponseContextKeyToScore,
  DecoratedCocmItem,
  gad7ResponseContextKeyToScore,
  phq9ResponseContextKeyToScore,
  PromptResponseContextKey,
  PromptResponsePayload,
} from '@shared/types'
import minBy from 'lodash/minBy'
import sum from 'lodash/sum'

export const getScoresFromResponses = (
  responses: { promptId: string; promptResponse: PromptResponsePayload }[],
  workflowType: 'phq_9_form' | 'gad_7_form' | 'barc_10_form' | 'phq_8_form',
) => {
  return responses.map(item => {
    const updatedItem = { ...item }
    const contextKey = item.promptResponse?.value.contextKey
    if (typeof contextKey === 'string' && contextKey) {
      if (workflowType === 'gad_7_form') {
        updatedItem.promptResponse.value.value =
          gad7ResponseContextKeyToScore(contextKey as PromptResponseContextKey<'gad_7_form'>) ?? 0
        return updatedItem
      } else if (workflowType === 'phq_9_form' || workflowType === 'phq_8_form') {
        updatedItem.promptResponse.value.value =
          phq9ResponseContextKeyToScore(contextKey as PromptResponseContextKey<'phq_9_form'>) ?? 0
        return updatedItem
      } else if (workflowType === 'barc_10_form') {
        updatedItem.promptResponse.value.value =
          barc10ResponseContextKeyToScore(contextKey as PromptResponseContextKey<'barc_10_form'>) ??
          0
        return updatedItem
      }
    }
    updatedItem.promptResponse.value.value = 0
    return updatedItem
  })
}

export const computeScoreFromWorkflowResponses = (
  responses: { promptId: string; promptResponse: PromptResponsePayload }[],
) => {
  const scores = responses.map(item => item.promptResponse.value.value)
  return sum(scores)
}

export const isPatientRecommendedForCaseReview = (cocmItem: DecoratedCocmItem) => {
  const SCORE_PERCENTAGE_DECREASE_THRESHOLD = 50
  const {
    decoratedPhqResponses,
    decoratedGad7Responses,
    mostRecentPhqResponse,
    mostRecentGad7Response,
  } = cocmItem

  const firstPhqResponse = minBy(decoratedPhqResponses, response => response.completedAt)
  const firstGad7Response = minBy(decoratedGad7Responses, response => response.completedAt)

  const getScorePercentageDecrease = ({
    mostRecentScore,
    firstScore,
  }: {
    mostRecentScore: number
    firstScore: number
  }) => {
    return ((firstScore - mostRecentScore) / firstScore) * 100
  }

  if (
    mostRecentPhqResponse &&
    firstPhqResponse &&
    // Ensure that the first PHQ response is not the same as the most recent PHQ response
    firstPhqResponse.workflowSessionId !== mostRecentPhqResponse.workflowSessionId &&
    // Only consider patients who entered treatment with a PHQ score of 9 or higher and have a most recent PHQ score of 9 or higher
    mostRecentPhqResponse.compositeScore >= 9 &&
    firstPhqResponse.compositeScore >= 9
  ) {
    if (
      // We would like to see at least a 50% decrease in the PHQ score. If the patient does not meet this threshold, they will be recommended for a case review.
      getScorePercentageDecrease({
        mostRecentScore: mostRecentPhqResponse.compositeScore,
        firstScore: firstPhqResponse.compositeScore,
      }) < SCORE_PERCENTAGE_DECREASE_THRESHOLD
    ) {
      return true
    }
  }

  if (
    mostRecentGad7Response &&
    firstGad7Response &&
    // Ensure that the first Gad7 response is not the same as the most recent Gad7 response
    firstGad7Response.workflowSessionId !== mostRecentGad7Response.workflowSessionId &&
    // Only consider patients who entered treatment with a GAD score of 10 or higher and have a most recent GAD score of 10 or higher
    mostRecentGad7Response.compositeScore >= 10 &&
    firstGad7Response.compositeScore >= 10
  ) {
    if (
      // We would like to see at least a 50% decrease in the GAD score. If the patient does not meet this threshold, they will be recommended for a case review.
      getScorePercentageDecrease({
        mostRecentScore: mostRecentGad7Response.compositeScore,
        firstScore: firstGad7Response.compositeScore,
      }) < SCORE_PERCENTAGE_DECREASE_THRESHOLD
    ) {
      return true
    }
  }

  return false
}
