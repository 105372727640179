import {
  BetterDrawer,
  DrawerContent,
  DrawerHeader,
  SearchIcon,
  Stack,
  TextInput,
} from '@shared/components'
import { useEffect, useState } from 'react'
import { SmartPhrase, searchSmartPhrases } from '../../employees/MEmployeesSmartPhrasesTab'
import { useSmartPhrases } from '../../employees/use-smart-phrases'
import { SmartPhraseTable } from './smartPhrases/SmartPhraseTable'

type SmartPhraseDrawerProps = {
  opened: boolean
  onClose: () => void
}

export const SmartPhraseDrawer = ({ opened, onClose }: SmartPhraseDrawerProps) => {
  const { smartPhrases, isLoading: isLoadingSmartPhrases } = useSmartPhrases()

  const [filteredSmartPhrases, setFilteredSmartPhrases] = useState<SmartPhrase[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    setFilteredSmartPhrases(searchSmartPhrases({ smartPhrases, query: searchValue }))
  }, [smartPhrases, searchValue])

  return (
    <BetterDrawer
      opened={opened}
      onClose={() => {
        setSearchValue('')
        onClose()
      }}
      size='xl'
    >
      <DrawerHeader onClose={onClose}>Smart phrases</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          <TextInput
            icon={<SearchIcon />}
            value={searchValue}
            onChange={setSearchValue}
            disabled={isLoadingSmartPhrases || smartPhrases.length === 0}
            placeholder='Search all smart phrases'
          />
          <SmartPhraseTable smartPhrases={filteredSmartPhrases} variation='copy-only' />
        </Stack>
      </DrawerContent>
    </BetterDrawer>
  )
}
