import { isNotEmpty, useForm } from '@mantine/form'
import {
  DatePicker,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  TextInput,
  emojiMap,
  showNotification,
  validateWith,
} from '@shared/components'
import {
  ConsentLabelMap,
  ConsentType,
  ConsentTypeWithConsentObject,
  ConsentTypeWithNoConsentObject,
  consentTypeIncludesConsentObject,
  getConsentVersions,
  getLatestConsentVersion,
} from '@shared/types'
import { dayjs, name } from '@shared/utils'
import { isRequired } from '../../../utils/formValidation'
import { useInvalidateQuery, useLunaMutation } from '../../../utils/hooks'
import { ConsentFormSectionProps } from './ConsentFormSection'

type ConsentFormData = {
  type: ConsentType
  consentDatetime: string
  signature: string
  version: string
}

type ConsentFormSectionModalProps = {
  consentType: ConsentType
  closeModal: () => void
} & ConsentFormSectionProps

export const ConsentFormModal = ({
  patient,
  consentType,
  closeModal,
}: ConsentFormSectionModalProps) => {
  const consentFormLabel = ConsentLabelMap[consentType]

  const patientFullName = name({
    first: patient.personalData.firstName,
    last: patient.personalData.lastName,
  }).full()

  const requireConsentObjectFields = consentTypeIncludesConsentObject(consentType)
  const skipIfConsentObjectFieldsNotRequired = () => !requireConsentObjectFields

  const consentForm = useForm<ConsentFormData>({
    initialValues: {
      type: consentType,
      consentDatetime: '',
      signature: patientFullName,
      version: getLatestConsentVersion({ consentType })?.toString(),
    },
    validate: {
      type: validateWith(isRequired),
      consentDatetime: validateWith(isRequired, isNotEmpty),
      signature: validateWith(skipIfConsentObjectFieldsNotRequired, isRequired),
      version: validateWith(skipIfConsentObjectFieldsNotRequired, isRequired),
    },
  })

  const createConsentMutation = useLunaMutation('POST /patients/:patientId/consent')
  const invalidateQuery = useInvalidateQuery()

  const submitForm = () => {
    if (consentForm.validate().hasErrors) {
      return
    }

    const { values } = consentForm

    const mutationData = requireConsentObjectFields
      ? {
          type: consentType as ConsentTypeWithConsentObject,
          consentDatetime: dayjs(values.consentDatetime).toISOString(),
          signature: values.signature,
          version: Number(values.version),
        }
      : {
          type: consentType as ConsentTypeWithNoConsentObject,
          consentDatetime: dayjs(values.consentDatetime).toISOString(),
        }

    createConsentMutation.mutate(
      {
        params: {
          patientId: patient?.oid || '',
        },
        data: mutationData,
      },
      {
        onSuccess: async () => {
          await invalidateQuery('GET /patient/:patientId/consents')
          await invalidateQuery('GET /patient/:patientId')

          showNotification({
            title: `Form signed ${emojiMap.pen}`,
            message: `${consentFormLabel} form has been signed for the patient.`,
            variant: 'success',
          })

          closeModal()
        },
        onError: () => {
          showNotification({
            message: `Unable to sign ${consentFormLabel} form for the patient.`,
            variant: 'error',
          })
        },
      },
    )
  }

  return (
    <Modal
      title={`${consentFormLabel} form`}
      opened={Boolean(consentType)}
      onClose={closeModal}
      footer={
        <Group position='apart'>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          <PrimaryButton onClick={submitForm}>
            I confirm this patient has signed this form
          </PrimaryButton>
        </Group>
      }
    >
      <Stack spacing='xs'>
        <DatePicker
          label='Signed on'
          clearable={false}
          maxDate={dayjs().toDate()}
          {...consentForm.getInputProps('consentDatetime')}
        />
        {requireConsentObjectFields && (
          <>
            <Select
              label='Version'
              data={getConsentVersions({ consentType }).map(version => version.toString())}
              {...consentForm.getInputProps('version')}
            />
            <TextInput label='Signature' {...consentForm.getInputProps('signature')} />
          </>
        )}
      </Stack>
    </Modal>
  )
}
