import { Alert, Flex, Skeleton, Stack, XIcon } from '@shared/components'
import { useState } from 'react'
import { useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { CollectionsColumn } from './collections/CollectionsColumn'
import { DocumentsColumn } from './collections/DocumentsColumn'
import { JsonColumn } from './collections/JsonColumn'
import { DeleteSubcollectionDocumentsModal } from './modals/DeleteSubcollectionDocumentsModal'
import { DeleteSubcollectionsModal } from './modals/DeleteSubcollectionsModal'
import { SaveSubcollectionDocumentModal } from './modals/SaveSubcollectionDocumentModal'
import { TransferSubcollectionDocumentsModal } from './modals/TransferSubcollectionDocumentsModal'
import { TransferSubcollectionsModal } from './modals/TransferSubcollectionsModal'

export const SubcollectionsSection = () => {
  const { patientId } = usePatient()
  const [selectedSubcollection, setSelectedSubcollection] = useState('')
  const [selectedSubcollectionItem, setSelectedSubcollectionItem] = useState('')
  const [editing, setEditing] = useState<'collections' | 'documents' | 'json' | null>(null)

  const [showModal, setShowModal] = useState<
    | {
        type: 'subcollection' | 'subcollection-documents'
        operation: 'delete' | 'transfer'
        list: string[]
      }
    | { type: 'document'; operation: 'save'; document: object }
    | null
  >(null)

  const subcollectionsQuery = useEmrQuery(
    'GET /patient/:patientId/subcollections',
    { params: { patientId } },
    { onSuccess: list => setSelectedSubcollection(list[0] ?? '') },
  )

  const subcollectionItemsQuery = useEmrQuery(
    'GET /patient/:patientId/subcollections/:subcollection',
    {
      params: { patientId, subcollection: selectedSubcollection },
    },
    {
      enabled: Boolean(selectedSubcollection),
      onSuccess: list => setSelectedSubcollectionItem(list[0] ?? ''),
    },
  )

  const subcollectionDocumentQuery = useEmrQuery(
    'GET /patient/:patientId/subcollections/:subcollection/:documentId',
    {
      params: {
        patientId,
        subcollection: selectedSubcollection,
        documentId: selectedSubcollectionItem,
      },
    },
    {
      enabled: Boolean(selectedSubcollection),
    },
  )

  return (
    <Stack>
      <Skeleton visible={subcollectionsQuery.isLoading || subcollectionsQuery.isError}>
        {subcollectionsQuery.data?.length === 0 ? (
          <Alert variant='primary' icon={<XIcon />}>
            No subcollections found
          </Alert>
        ) : (
          <Flex h='32rem' sx={({ spacing }) => ({ gap: spacing.xs })}>
            <CollectionsColumn
              list={subcollectionsQuery.data}
              selectedCollection={selectedSubcollection}
              setSelectedCollection={setSelectedSubcollection}
              editing={editing}
              toggleIsEditing={() =>
                setEditing(value => (value === 'collections' ? null : 'collections'))
              }
              onDelete={list => setShowModal({ type: 'subcollection', operation: 'delete', list })}
              onTransfer={list =>
                setShowModal({ type: 'subcollection', operation: 'transfer', list })
              }
            />
            <DocumentsColumn
              list={subcollectionItemsQuery.data}
              selectedCollectionItem={selectedSubcollectionItem}
              setSelectedCollectionItem={setSelectedSubcollectionItem}
              editing={editing}
              toggleIsEditing={() =>
                setEditing(value => (value === 'documents' ? null : 'documents'))
              }
              onDelete={list =>
                setShowModal({ type: 'subcollection-documents', operation: 'delete', list })
              }
              onTransfer={list =>
                setShowModal({ type: 'subcollection-documents', operation: 'transfer', list })
              }
              isLoading={subcollectionItemsQuery.isLoading}
              isError={subcollectionItemsQuery.isError}
            />
            <JsonColumn
              documentId={selectedSubcollectionItem}
              documentData={subcollectionDocumentQuery.data}
              editing={editing}
              toggleIsEditing={() => setEditing(value => (value === 'json' ? null : 'json'))}
              isLoading={subcollectionDocumentQuery.isLoading}
              isError={subcollectionDocumentQuery.isError}
              onSave={document => setShowModal({ type: 'document', operation: 'save', document })}
            />
          </Flex>
        )}
      </Skeleton>
      <TransferSubcollectionsModal
        opened={showModal?.type === 'subcollection' && showModal.operation === 'transfer'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        subcollections={showModal?.type === 'subcollection' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <DeleteSubcollectionsModal
        opened={showModal?.type === 'subcollection' && showModal.operation === 'delete'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        subcollections={showModal?.type === 'subcollection' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <TransferSubcollectionDocumentsModal
        opened={showModal?.type === 'subcollection-documents' && showModal.operation === 'transfer'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        subcollection={selectedSubcollection}
        documentIds={showModal?.type === 'subcollection-documents' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <DeleteSubcollectionDocumentsModal
        opened={showModal?.type === 'subcollection-documents' && showModal.operation === 'delete'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        subcollection={selectedSubcollection}
        documentIds={showModal?.type === 'subcollection-documents' ? showModal.list : []}
        onSubmit={() => setEditing(null)}
      />
      <SaveSubcollectionDocumentModal
        opened={showModal?.type === 'document' && showModal?.operation === 'save'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
        subcollection={selectedSubcollection}
        documentId={selectedSubcollectionItem}
        onSubmit={() => setEditing(null)}
        documentData={subcollectionDocumentQuery.data ?? {}}
        updatedDocumentData={showModal?.type === 'document' ? showModal.document : {}}
      />
    </Stack>
  )
}
