import { useToggle } from '@mantine/hooks'
import { Checkbox, Group, Modal, PrimaryButton, Switch } from '@shared/components'
import { useMutation } from 'react-query'
import { appointmentsApi } from '../../../api'
import { useEmrQuery } from '../../../utils/hooks'

const RestoreVisitModal = ({
  patientId,
  appointmentId,
  closeModal,
}: {
  patientId: string
  appointmentId: string
  closeModal: () => void
}) => {
  const [canceled, toggleCanceled] = useToggle([true, false])
  const [noShow, toggleNoShow] = useToggle([false, true])

  const appointmentQuery = useEmrQuery(
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      params: {
        patientId,
        appointmentId,
      },
    },
    {
      onSuccess: data => {
        toggleNoShow(Boolean(data.noShow))
      },
    },
  )

  const updateAppointment = useMutation(appointmentsApi.updateByVisitId, {
    onSuccess: () => {
      closeModal()
      window.location.reload()
    },
  })

  if (appointmentQuery.isLoading) {
    return null
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Update visit status'
      footer={
        <Group position='right'>
          <PrimaryButton
            loading={updateAppointment.isLoading}
            onClick={() => {
              updateAppointment.mutate({
                visitId: appointmentId,
                patientId,
                data: {
                  canceled,
                  noShow,
                },
              })
            }}
          >
            Update status
          </PrimaryButton>
        </Group>
      }
    >
      <Switch
        label='Canceled'
        checked={canceled}
        onChange={() => {
          toggleCanceled()
          toggleNoShow(false)
        }}
      />
      {canceled && (
        <Checkbox
          mt='md'
          label='Mark as a no show'
          checked={noShow}
          onChange={() => toggleNoShow()}
        />
      )}
    </Modal>
  )
}

export default RestoreVisitModal
