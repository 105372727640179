import { BetterDrawer, DrawerContent, DrawerHeader, Stack, Table, Th } from '@shared/components'
import { YYYYMMDD } from '@shared/types'
import { alphabetical } from 'radash'
import { useState } from 'react'
import { CalendarHoldReviewRow } from './CalendarHoldReviewRow'

export type Hold = {
  holdId: string
  firstName: string
  lastName: string
  patientId: string
  intendedDay: YYYYMMDD
  clinicianName: string
  calendarId: number
}

type CalendarHoldsDrawerProps = {
  opened: boolean
  onClose: () => void
  holds: Hold[]
  refetchHolds: () => void
}

export const CalendarHoldsDrawer = (props: CalendarHoldsDrawerProps) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const sortedHolds = alphabetical(props.holds, h => h.lastName, sortOrder)
  const onSort = () => setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'))

  return (
    <BetterDrawer position='right' size='lg' opened={props.opened} onClose={props.onClose}>
      <DrawerHeader onClose={props.onClose}>Visits on hold</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          <Table withBorder withColumnBorders striped>
            <thead className='mantine'>
              <tr className='mantine'>
                <Th sortable sorted reversed={sortOrder === 'asc'} onSort={onSort}>
                  Patient name
                </Th>
                <Th sortable sorted reversed={sortOrder === 'asc'} onSort={onSort}>
                  Intended day
                </Th>
                <Th sortable={false}>Clinician</Th>
                <Th sortable={false}>Update</Th>
              </tr>
            </thead>
            <tbody className='mantine'>
              {sortedHolds.map(hold => (
                <CalendarHoldReviewRow
                  key={hold.holdId}
                  hold={hold}
                  onUpdate={props.refetchHolds}
                />
              ))}
            </tbody>
          </Table>
        </Stack>
      </DrawerContent>
    </BetterDrawer>
  )
}
