import { RichTextEditor, RichTextEditorProps, Skeleton } from '@shared/components'
import { useEmployees } from '../../utils/hooks'

export const EmployeeMentionNoteInput = (props: RichTextEditorProps) => {
  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  if (employeesQuery.isLoading) {
    return <Skeleton />
  }

  const mentionData = (employeesQuery.data || []).map(employee => ({
    id: employee.oid,
    label: employee.name,
  }))

  return <RichTextEditor {...props} mentionData={mentionData} />
}
