/* eslint-disable no-magic-numbers */

import { Stack, Text, useMantineTheme } from '@shared/components'
import { OOOEventForRender } from '@shared/types'
import { CalendarEvent } from './CalendarEvent'

export type OOOEventProps = {
  block: OOOEventForRender
}

export const OOOEvent = ({ block }: OOOEventProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const color = colors.background[2]
  const borderColor = block.indent > 0 ? colors.text[3] : undefined

  return (
    <CalendarEvent color={color} borderColor={borderColor} duration={block.duration} fullWidth>
      <Stack spacing={0}>
        <Text size={block.duration >= 15 ? 'md' : 'xs'} bold>
          OOO
        </Text>
      </Stack>
    </CalendarEvent>
  )
}
