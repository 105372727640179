import { useDisclosure } from '@mantine/hooks'
import { GoPencil } from '@react-icons/all-files/go/GoPencil'
import { GoUnverified } from '@react-icons/all-files/go/GoUnverified'
import { IoIosCheckmarkCircle } from '@react-icons/all-files/io/IoIosCheckmarkCircle'
import { MdNotInterested } from '@react-icons/all-files/md/MdNotInterested'
import { BannerPortal, Group, PrimaryButton, Tooltip } from '@shared/components'
import { Patient, PatientStatus, activeStates, hasGroupRole, isClinician } from '@shared/types'
import { name } from '@shared/utils'
import sum from 'lodash/sum'
import values from 'lodash/values'
import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'
import AGeneralTablePaginationFooter from '../../components/atoms/AGeneralTablePaginationFooter'
import AMenuItem from '../../components/atoms/AMenuItem'
import AOption from '../../components/atoms/AOption'
import ASelect from '../../components/atoms/ASelect'
import ASubheading from '../../components/atoms/ASubheading'
import AFakePatientRow from '../../components/atoms/fakes/AFakePatientRow'
import IconDownward from '../../components/icons/IconDownward'
import IconPatientsNo from '../../components/icons/IconPatientsNo'
import IconUpward from '../../components/icons/IconUpward'
import MDropdown from '../../components/molecules/MDropdown'
import { Page } from '../../components/templates/TDefault'
import { useAuth } from '../../context/auth'
import { useEmployees, usePatientDirectLink } from '../../utils/hooks'
import { postFlexChartViewedMessage } from '../../utils/utils'
import SearchBar from '../care_team/irq/SearchBar'
import { AddPatientDrawer } from './AddPatientDrawer'

const PAGE_SIZE = 50

const slugify = (string: string) => {
  if (!string) {
    return string
  }

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  /* eslint-disable */
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
  /* eslint-enable */
}

/* eslint-disable no-magic-numbers */

type PatientTableLinkProps = {
  className: string
  href: string
  children: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
}

const PatientTableLink = ({
  className,
  href,
  children,
  onClick = e => e.preventDefault(),
}: PatientTableLinkProps) => (
  <a href={href} className={className} onClick={onClick}>
    {children}
  </a>
)

type PatientStatusOption = { value: PatientStatus; text: string }

const PATIENT_STATUS_OPTIONS: PatientStatusOption[] = [
  { value: 'in treatment', text: 'In Treatment' },
  { value: 'discharged', text: 'Discharged' },
  { value: 'paused', text: 'Paused' },
  { value: 'needs review', text: 'Needs Review' },
  { value: 'not responding', text: 'Not Responding' },
  { value: 'candidate', text: 'Candidate' },
  { value: 'lead', text: 'Lead' },
  { value: 'ineligible', text: 'Ineligible' },
]

const getPatientCountString = (patientCount: number): string => {
  if (patientCount === 0) {
    return 'No patients'
  }
  if (patientCount === 1) {
    return '1 patient'
  }
  return `${patientCount} patients`
}

const abbreviateState = (state: string) => {
  const stateInfo = activeStates.filter(i => i.state === state)[0]
  return stateInfo ? stateInfo.abbr : state
}
const paginationReducer = (
  state: { offset: number },
  action:
    | {
        type: 'NEXT_PAGE' | 'PREV_PAGE'
        token: string
      }
    | { type: 'RESET' },
): { nextPageToken?: string; prevPageToken?: string; offset: number } => {
  if (action.type === 'NEXT_PAGE') {
    return { nextPageToken: action.token, offset: state.offset + PAGE_SIZE }
  }
  if (action.type === 'PREV_PAGE') {
    return { prevPageToken: action.token, offset: state.offset - PAGE_SIZE }
  }
  if (action.type === 'RESET') {
    return { offset: 0 }
  }
  return state
}

const InsuranceStatusIcon = ({ status }: { status?: 'verified' | 'unverified' | 'none' }) => {
  switch (status) {
    case 'verified':
      return (
        <Tooltip label='Verified' offset={15}>
          <div className='whitespace-nowrap'>
            <IoIosCheckmarkCircle className='text-success' />
          </div>
        </Tooltip>
      )
    case 'unverified':
      return (
        <Tooltip label='Unverified' offset={15}>
          <div className='whitespace-nowrap'>
            <GoUnverified className='text-warn' />
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip label='No Info Uploaded' offset={15}>
          <div className='whitespace-nowrap'>
            <MdNotInterested />
          </div>
        </Tooltip>
      )
  }
}

const PPatients = () => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const searchQueryParam = params.get('q')
  const { isLoading: patientDirectLinkIsLoading } = usePatientDirectLink()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')

  const [selectedPatientsStatus, setSelectedPatientsStatus] = useState<'' | PatientStatus>(
    hasGroupRole(currentUser, 'enrollmentCoordinator') ? '' : 'in treatment',
  )
  const [paginationState, updatePagination] = useReducer(paginationReducer, { offset: 0 })
  const [sortField, setSortField] = useState('name')
  const [sortReverse, setSortReverse] = useState(false)
  const [selectedProviderID, setSelectedProviderID] = useState(
    isClinician(currentUser) ? currentUser.oid : '',
  )
  const allStates = 'All States'
  const [selectedState, setSelectedState] = useState<string>(allStates)
  const pageContainer = useRef(null)

  const employeesQuery = useEmployees({ status: 'currentEmployee' })
  const medicalProviders = isClinician(employeesQuery.data || [])

  const { offset, ...tokenParams } = paginationState
  const patientsQuery = useQuery(
    ...emrApi.getQuery('GET /patients', {
      query: {
        q: searchQueryParam ?? undefined,
        limit: `${PAGE_SIZE}`,
        employeeId: selectedProviderID,
        patientStatus: selectedPatientsStatus ? [selectedPatientsStatus] : [],
        orderBy: 'name',
        nextPageToken: tokenParams.nextPageToken,
        prevPageToken: tokenParams.prevPageToken,
        state: selectedState === allStates ? undefined : selectedState,
        withInsuranceStatus: 'yes',
      },
    }),
    {
      onSuccess(data) {
        if (!data.items.length) {
          /*
           * This message prompts Flex to go ahead and load the conversation of a phone number
           * that was just searched but had no patient results
           */
          postFlexChartViewedMessage(searchQueryParam ?? '')
        }
      },
    },
  )

  let patients = patientsQuery.data?.items || []

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  // Check location on mount and trigger search if present.
  useEffect(() => {
    if (searchQueryParam) {
      setQuery(searchQueryParam)
    }
  }, [searchQueryParam])

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }, [offset])

  const getProviderPatientCount = () => {
    const selectedProvider = medicalProviders.find(provider => provider.oid === selectedProviderID)
    if (selectedPatientsStatus) {
      return selectedProvider?.patientCounts?.[selectedPatientsStatus] || 0
    }
    return sum(values(selectedProvider?.patientCounts))
  }

  patients = patients
    .filter(patient => {
      if (!patient.personalData) {
        return false
      }

      if (selectedPatientsStatus) {
        return patient.statuses.patient === selectedPatientsStatus
      }

      return true
    })
    .sort((a, b) => {
      if (sortField === null) {
        if (slugify(a.personalData?.lastName) < slugify(b.personalData?.lastName)) {
          return sortReverse ? 1 : -1
        }

        if (slugify(a.personalData?.lastName) > slugify(b.personalData?.lastName)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }
      if (sortField === 'name') {
        if (slugify(a.personalData?.lastName) < slugify(b.personalData?.lastName)) {
          return sortReverse ? 1 : -1
        }

        if (slugify(a.personalData?.lastName) > slugify(b.personalData?.lastName)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }

      if (sortField === 'prescribingClinician') {
        if (a.primaryClinician && !b.primaryClinician) {
          return -1
        }
        if (!a.primaryClinician && b.primaryClinician) {
          return 1
        }

        if ((a.primaryClinician?.name || 0) < (b.primaryClinician?.name || 0)) {
          return sortReverse ? 1 : -1
        }

        if ((a.primaryClinician?.name || 0) > (b.primaryClinician?.name || 0)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }

      if (sortField === 'nurseCareManager') {
        if (a.nurseCareManager && !b.nurseCareManager) {
          return -1
        }
        if (!a.nurseCareManager && b.nurseCareManager) {
          return 1
        }

        if ((a.nurseCareManager?.name || 0) < (b.nurseCareManager?.name || 0)) {
          return sortReverse ? 1 : -1
        }

        if ((a.nurseCareManager?.name || 0) > (b.nurseCareManager?.name || 0)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }

      if (sortField === 'careLevel') {
        if (a.statuses?.levelOfCare && !b.statuses?.levelOfCare) {
          return -1
        }
        if (!a.statuses?.levelOfCare && b.statuses?.levelOfCare) {
          return 1
        }

        const aBigger = (a?.statuses?.levelOfCare || 0) > (b?.statuses?.levelOfCare || 0) ? 1 : -1
        return sortReverse ? aBigger * -1 : aBigger
      }

      if (sortField === 'status') {
        if (a.statuses?.patient && !b.statuses?.patient) {
          return -1
        }
        if (!a.statuses?.patient && b.statuses?.patient) {
          return 1
        }

        if (slugify(a.statuses?.patient) < slugify(b.statuses?.patient)) {
          return sortReverse ? 1 : -1
        }

        if (slugify(a.statuses?.patient) > slugify(b.statuses?.patient)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }

      if (sortField === 'state') {
        if (a.shippingData?.state && !b.shippingData?.state) {
          return -1
        }
        if (!a.shippingData?.state && b.shippingData?.state) {
          return 1
        }

        if ((a.shippingData?.state || 0) < (b.shippingData?.state || 0)) {
          return sortReverse ? 1 : -1
        }

        if ((a.shippingData?.state || 0) > (b.shippingData?.state || 0)) {
          return sortReverse ? -1 : 1
        }

        return 0
      }

      return 0
    })

  useEffect(() => {
    window.scrollTo(0, 0)

    if (!currentUser) {
      return
    }

    analytics.page({
      name: 'Patients',
      properties: {
        userId: currentUser.oid,
        userName: currentUser.name,
      },
    })
  }, [currentUser])

  const onCancel = () => {
    setQuery('')
    navigate({ pathname: '/patients' })
  }

  const onSubmit = () => {
    if (query) {
      navigate({
        pathname: '/patients/search',
        search: `?q=${query}`,
      })
    } else {
      navigate({ pathname: '/patients' })
    }
  }

  const changeOrder = (field: string) => {
    if (field === sortField) {
      if (sortReverse) {
        setSortField('name')
        setSortReverse(false)
      } else {
        setSortReverse(!sortReverse)
      }
    } else {
      setSortField(field)
      setSortReverse(false)
    }
  }

  const onPatientsStatusChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    setSelectedPatientsStatus(event.target.value as PatientStatus)
    updatePagination({ type: 'RESET' })
  }

  const onProviderStatusChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    setSelectedProviderID(event.target.value)
    updatePagination({ type: 'RESET' })
  }

  const onStateStatusChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    setSelectedState(event.target.value || allStates)
    updatePagination({ type: 'RESET' })
  }

  const updatePatientStatus = async (
    e: React.MouseEvent<HTMLButtonElement>,
    patient: Patient,
    status: PatientStatus,
  ) => {
    e.stopPropagation()
    const data = {
      ...patient,
      statuses: {
        ...patient.statuses,
        patient: status,
      },
    }
    await updatePatient.mutateAsync({ params: { patientId: patient.oid }, data })
    await patientsQuery.refetch()
  }

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <Page
      title='Patients'
      headerContent={
        <SearchBar
          value={query}
          onChange={val => setQuery(val)}
          onEnter={onSubmit}
          onClear={onCancel}
        />
      }
    >
      {query === '2000' && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      {query === '4000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={4000} />
      )}
      {query === '6000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={6000} />
      )}
      {query === '7000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={7000} />
      )}
      {query === '8000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={8000} />
      )}
      <BannerPortal />
      <AddPatientDrawer opened={opened} onClose={close} />
      <div className='min-h-screen relative px-2 pb-16 flex flex-col' ref={pageContainer}>
        <Group pt={16} px={8} pb={32} position='apart'>
          <Group>
            <div className='w-64'>
              <ASelect onChange={onProviderStatusChange} value={selectedProviderID}>
                <AOption value=''>All Clinicians</AOption>
                {medicalProviders.map(provider => (
                  <AOption key={provider.oid} value={provider.oid}>
                    {provider.name}
                  </AOption>
                ))}
              </ASelect>
            </div>
            <div className='w-64'>
              <ASelect onChange={onPatientsStatusChange} value={selectedPatientsStatus}>
                <AOption value=''>All Statuses</AOption>
                {PATIENT_STATUS_OPTIONS.map(option => {
                  return (
                    <AOption key={option.value} value={option.value}>
                      {option.text}
                    </AOption>
                  )
                })}
              </ASelect>
            </div>
            <div className='w-64'>
              <ASelect onChange={onStateStatusChange} value={selectedState}>
                <AOption value=''>{allStates}</AOption>
                {activeStates.map(option => (
                  <AOption key={option.state} value={option.state}>
                    {option.state}
                  </AOption>
                ))}
              </ASelect>
            </div>
            {selectedProviderID && (
              <span className='whitespace-nowrap text-near-white-readable group-hover:text-color no-underline'>
                {selectedState === allStates
                  ? getPatientCountString(getProviderPatientCount())
                  : null}
              </span>
            )}
          </Group>
          {hasGroupRole(currentUser, 'admin', 'engineer', 'enrollmentCoordinator') && (
            <PrimaryButton onClick={open}>Add patient</PrimaryButton>
          )}
        </Group>
        <table className='w-full max-w-screen-lg mx-auto relative'>
          <thead>
            <tr className='select-none'>
              <th className='name pl-2'>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('name')}
                >
                  Name {sortField === 'name' && !sortReverse && <IconDownward />}
                  {sortField === 'name' && sortReverse && <IconUpward />}
                </button>
              </th>
              <th className='whitespace-nowrap'>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('prescribingClinician')}
                >
                  Prescribing Clinician{' '}
                  <span className='w-6'>
                    {sortField === 'prescribingClinician' && !sortReverse && <IconDownward />}
                    {sortField === 'prescribingClinician' && sortReverse && <IconUpward />}
                  </span>
                </button>
              </th>
              <th className='whitespace-nowrap'>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('nurseCareManager')}
                >
                  Clinical Care Manager{' '}
                  <span className='w-6'>
                    {sortField === 'nurseCareManager' && !sortReverse && <IconDownward />}
                    {sortField === 'nurseCareManager' && sortReverse && <IconUpward />}
                  </span>
                </button>
              </th>
              <th className='whitespace-nowrap'>
                <button
                  className='focus:outline-none whitespaceno-wrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('careLevel')}
                >
                  Care Level{' '}
                  <span className='w-6'>
                    {sortField === 'careLevel' && !sortReverse && <IconDownward />}
                    {sortField === 'careLevel' && sortReverse && <IconUpward />}
                  </span>
                </button>
              </th>
              <th>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('status')}
                >
                  Status {sortField === 'status' && !sortReverse && <IconDownward />}
                  {sortField === 'status' && sortReverse && <IconUpward />}
                </button>
              </th>
              <th>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('insurance')}
                >
                  Insurance {sortField === 'insurance' && !sortReverse && <IconDownward />}
                  {sortField === 'insurance' && sortReverse && <IconUpward />}
                </button>
              </th>
              <th>
                <button
                  className='focus:outline-none whitespace-nowrap flex items-center h-8 hover:opacity-50'
                  onClick={() => changeOrder('state')}
                >
                  State {sortField === 'state' && !sortReverse && <IconDownward />}
                  {sortField === 'state' && sortReverse && <IconUpward />}
                </button>
              </th>
            </tr>
          </thead>
          {(patientsQuery.isLoading || patientDirectLinkIsLoading) && (
            <tbody>
              {[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
              ].map(val => (
                <AFakePatientRow key={val} />
              ))}
            </tbody>
          )}
          {patients.length > 0 && !patientsQuery.isLoading && (
            <tbody>
              {patients.map(patient => (
                <Fragment key={patient.oid}>
                  <tr
                    className='group select-none hover:bg-white hover:shadow h-10 border-b border-separator w-full'
                    onClick={event => {
                      const target = event.target as HTMLElement
                      if (
                        target.tagName !== 'path' &&
                        target.tagName !== 'svg' &&
                        target.tagName !== 'BUTTON'
                      ) {
                        navigate(`/patients/${patient.oid}`)
                      }
                    }}
                  >
                    <td className='pl-2 group-hover:cursor-pointer name'>
                      <PatientTableLink
                        href={`/patients/${patient.oid}`}
                        className='whitespace-nowrap font-semibold group-hover:text-color text-current no-underline'
                      >
                        {name({
                          first: patient.personalData?.firstName,
                          last: patient.personalData?.lastName,
                        }).lastCommaFirst()}
                      </PatientTableLink>
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
                      {patient.primaryClinician && (
                        <PatientTableLink
                          href={`/patients/${patient.oid}`}
                          className='whitespace-nowrap text-near-white-readable group-hover:text-color no-underline'
                        >
                          {patient.primaryClinician?.name}
                        </PatientTableLink>
                      )}
                      {!patient.primaryClinician && <Fragment>—</Fragment>}
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
                      {patient.nurseCareManager && (
                        <PatientTableLink
                          href={`/patients/${patient.oid}`}
                          className='whitespace-nowrap text-near-white-readable group-hover:text-color no-underline'
                        >
                          {patient.nurseCareManager?.name}
                        </PatientTableLink>
                      )}
                      {!patient.nurseCareManager && <Fragment>—</Fragment>}
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
                      <div className='whitespace-nowrap text-near-white-readable group-hover:text-color'>
                        {patient.statuses?.levelOfCare && (
                          <PatientTableLink
                            className='whitespace-nowrap capitalize text-current group-hover:text-color no-underline'
                            href={`/patients/${patient.oid}`}
                          >
                            {patient.statuses?.levelOfCare}
                          </PatientTableLink>
                        )}
                        {!patient.statuses?.levelOfCare && <Fragment>—</Fragment>}
                      </div>
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer text-near-white-readable group-hover:text-color'>
                      <MDropdown label={patient.statuses?.patient} hoverIcon={<GoPencil />}>
                        {PATIENT_STATUS_OPTIONS.map(option => (
                          <AMenuItem
                            key={option.value}
                            onClick={e => updatePatientStatus(e, patient, option.value)}
                          >
                            <span>{option.text}</span>
                          </AMenuItem>
                        ))}
                      </MDropdown>
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer text-near-white-readable group-hover:text-color'>
                      <InsuranceStatusIcon status={patient?.insuranceStatus} />
                    </td>
                    <td className='group-hover:bg-hover group-hover:cursor-pointer'>
                      {patient.shippingData?.state && (
                        <PatientTableLink
                          href={`/patients/${patient.oid}`}
                          className='whitespace-nowrap text-near-white-readable group-hover:text-color no-underline'
                        >
                          {abbreviateState(patient.homeData?.state || patient.shippingData.state)}
                        </PatientTableLink>
                      )}
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          )}
        </table>
        {!patients.length && !patientsQuery.isLoading && (
          <div className='flex-grow h-100 flex flex-col items-center justify-center text-readable-small-gray'>
            <IconPatientsNo />
            <ASubheading className='mt-2'>No Patients Found</ASubheading>
          </div>
        )}
        <AGeneralTablePaginationFooter
          offset={offset}
          pageSize={patientsQuery?.data?.pageCount ?? 0}
          total={patientsQuery.data?.totalCount}
          onNextClick={() => {
            updatePagination({
              type: 'NEXT_PAGE',
              token: patientsQuery.data?.nextPageToken ?? '',
            })
          }}
          onPreviousClick={() => {
            updatePagination({
              type: 'PREV_PAGE',
              token: patientsQuery.data?.prevPageToken ?? '',
            })
          }}
          isLoading={patientsQuery.isLoading}
        />
      </div>
    </Page>
  )
}

export default PPatients
