import { FILE_CATEGORIES_MAP, FileCategory } from '@shared/types'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { patientsApi, storageApi } from '../../../api'
import AHeading from '../../../components/atoms/AHeading'
import AOption from '../../../components/atoms/AOption'
import ASelect from '../../../components/atoms/ASelect'
import { required } from '../../../components/molecules/MDictionary'
import ODSInput from '../../../components/ods/ODSInput'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'

export type OUploadFileModalProps = {
  closeModal: (file: { name: string; type: string; category: UploadableFileCategory }) => void
  shouldReload?: boolean
  // Force a certain category to be selected
  forceCategory?: UploadableFileCategory
}

type FormData = {
  files: File[] | undefined
  fileName: string
  category: UploadableFileCategory
}

// No ATTACHMENT here, as we do not allow direct uploads of that type
export type UploadableFileCategory = Exclude<FileCategory, 'ATTACHMENT'>

const OUploadFileModal = ({
  closeModal,
  shouldReload = true,
  forceCategory,
}: OUploadFileModalProps) => {
  const queryClient = useQueryClient()
  const { patientID = '' } = useParams<{ patientID: string }>()

  const { register, handleSubmit, watch, reset, setValue } = useForm<FormData>()
  const hiddenFiles = watch('files', [])
  const inputRef = useRef<HTMLInputElement>()
  const { ref: hiddenFileInputRef, ...hiddenFileInputFields } = register('files', {
    required: { value: true, message: required },
  })

  const categories = Object.fromEntries(
    Object.entries(FILE_CATEGORIES_MAP).filter(([category]) => category !== 'TASK_ATTACHMENT'),
  ) as Record<UploadableFileCategory, string>

  const addFileToStorage = useMutation(storageApi.addFile, { retry: 1 })
  const addFile = useMutation(patientsApi.addFile)
  const deleteFile = useMutation(patientsApi.deleteFile)
  const isLoading = addFile.isLoading || addFileToStorage.isLoading
  const isError = addFile.isError || addFileToStorage.isError

  // Initialize fileName when a file has been uploaded.
  useEffect(() => {
    if (!hiddenFiles) {
      return
    }
    const [hiddenFile] = hiddenFiles
    if (hiddenFile) {
      setValue('fileName', hiddenFile.name)
    }
  }, [hiddenFiles?.length])

  const onRetry = () => {
    reset()
    addFile.reset()
    addFileToStorage.reset()
  }

  const onSubmit = handleSubmit(async data => {
    if (!data.files) {
      return
    }

    /*
     * The form data for files is technically a FileList.
     * Convert to an array before accessing it.
     */
    const [file] = Array.from(data.files) as [File]

    let newFileId = null

    try {
      const res = await addFile.mutateAsync({
        patientId: patientID,
        category: forceCategory || data.category,
        name: data.fileName,
        type: file.type,
      })

      newFileId = res.fileId

      await addFileToStorage.mutateAsync({ signedUrl: res.signedUrl, file })

      if (shouldReload) {
        void queryClient.invalidateQueries()
      }

      closeModal({
        category: forceCategory || data.category,
        name: data.fileName,
        type: file.type,
      })
    } catch (err) {
      // Clean up zombie upload if fileId exists but update operation fails.
      if (newFileId) {
        await deleteFile.mutateAsync({ patientId: patientID, fileId: newFileId })
      }
    }
  })

  return (
    <div className='w-full'>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col'>
          {!isError && (
            <div className='flex flex-col'>
              <div className='w-full flex flex-row mb-4 items-center justify-center'>
                <AHeading>Upload File</AHeading>
              </div>
              {hiddenFiles && hiddenFiles?.length > 0 ? (
                <div className='max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                  <div className='text-center'>
                    <p className='mt-1 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out'>
                      {hiddenFiles[0]?.name}
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    onClick={() => inputRef.current?.click()}
                    className='mt-2 sm:mt-0 sm:col-span-2 cursor-pointer'
                  >
                    <div className='max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                      <div className='text-center'>
                        <svg
                          className='mx-auto h-12 w-12 text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <p className='mt-1 text-sm text-gray-600'>
                          <button
                            type='button'
                            className='font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out'
                          >
                            Upload a file
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <input
                    data-testid='file-upload'
                    type='file'
                    className='hidden'
                    ref={instance => {
                      if (instance) {
                        hiddenFileInputRef(instance)
                        inputRef.current = instance
                      }
                    }}
                    {...hiddenFileInputFields}
                  />
                </>
              )}
              {categories && (
                <div className='pt-5'>
                  {forceCategory ? (
                    <ASelect name='category' value={forceCategory} disabled>
                      <AOption key={forceCategory} value={forceCategory}>
                        {categories[forceCategory]}
                      </AOption>
                    </ASelect>
                  ) : (
                    <ASelect
                      disabled={isLoading}
                      {...register('category', { required: { value: true, message: required } })}
                    >
                      {Object.entries(categories).map(([key, value]) => (
                        <AOption key={key} value={key}>
                          {value}
                        </AOption>
                      ))}
                    </ASelect>
                  )}
                </div>
              )}
              <div className='pt-5'>
                <ODSInput
                  placeholder='Enter file name...'
                  disabled={isLoading}
                  {...register('fileName', { required: { value: true, message: required } })}
                />
              </div>
              <ODSPrimaryButton
                type='submit'
                message='Submit'
                className='inline-flex justify-center w-full mt-6'
                loading={isLoading}
              />
            </div>
          )}
          {isError && (
            <div className='flex flex-col pt-5'>
              <div className='w-full flex flex-row mb-8 items-center justify-center'>
                <AHeading>Upload Error</AHeading>
              </div>
              <div className='flex flex-col items-start justify-start w-full mb-8'>
                <p className='text-base leading-8'>
                  Unfortunately this file could not be uploaded at this time, please try again or
                  send a message to the{' '}
                  <a href='slack://channel?team=TRN06CLEM&id=C036LF9E5FX'>
                    #patient-product-support
                  </a>{' '}
                  channel in Slack.
                </p>
              </div>
              <ODSPrimaryButton
                message='Retry'
                className='inline-flex justify-center w-full'
                loading={isLoading}
                onClick={onRetry}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default OUploadFileModal
