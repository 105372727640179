import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ACTIONS_MODALS, ActionModal, ModalStateType } from '../../pages/patient/PPatientContext'

export const useActionsState = () => {
  let initialActionModal = null
  const [searchParams] = useSearchParams()
  const actionModalFromSearchParam = searchParams.get('action_modal')

  if (
    actionModalFromSearchParam !== null &&
    ACTIONS_MODALS.includes(actionModalFromSearchParam as ActionModal)
  ) {
    initialActionModal = { type: actionModalFromSearchParam as ActionModal }
  }

  const [modal, setModal] = useState<ModalStateType>(initialActionModal)

  return {
    modal,
    setModal,
  }
}
