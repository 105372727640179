import {
  BetterDrawer,
  CircleWithText,
  Divider,
  DrawerContent,
  DrawerHeader,
  Group,
  Stack,
  TitleThree,
} from '@shared/components'
import { dayjs, toTime } from '@shared/utils'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { NotificationRow } from './NotificationRow'

type NotificationsDrawerTypes = {
  opened: boolean
  onClose: () => void
}

export const NotificationsDrawer = ({ opened, onClose }: NotificationsDrawerTypes) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const employeeId = currentUser.oid

  const [notificationsQueryKey, notificationsQueryFunction] = emrApi.getQuery(
    'GET /emrNotifications',
    {
      query: {
        employeeId,
      },
    },
  )

  const [unseenNotificationsCountQueryKey, unseenNotificationsCountQueryFunction] = emrApi.getQuery(
    'GET /emrNotifications/count',
    {
      query: {
        employeeId,
      },
    },
  )

  const [notificationsQuery, unseenNotificationsCountQuery] = useQueries([
    {
      queryKey: notificationsQueryKey,
      queryFn: notificationsQueryFunction,
      enabled: Boolean(employeeId),
      staleTime: toTime('30 sec').ms(),
    },
    {
      queryKey: unseenNotificationsCountQueryKey,
      queryFn: unseenNotificationsCountQueryFunction,
      enabled: Boolean(employeeId),
      staleTime: toTime('30 sec').ms(),
    },
  ])

  const notifications = notificationsQuery.data || []
  const notificationsCount = unseenNotificationsCountQuery.data || 0

  const markNotificationAsSeen = useMutation(
    emrApi.getMutation('PUT /emrNotifications/:notificationId'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(notificationsQueryKey)
        void queryClient.invalidateQueries(unseenNotificationsCountQueryKey)
      },
    },
  )

  const onClick = ({ notificationId, url }: { notificationId: string; url: string }) => {
    markNotificationAsSeen.mutate({
      params: { notificationId },
      data: { seenAt: dayjs().toISOString() },
    })
    navigate(url)
    onClose()
  }

  const hasNotifications = notificationsCount > 0

  return (
    <BetterDrawer opened={opened} onClose={onClose} size='lg'>
      <DrawerHeader onClose={onClose}>
        <Group spacing='sm'>
          <TitleThree>Notifications</TitleThree>
          <CircleWithText variant={hasNotifications ? 'error' : 'default'}>
            {notificationsCount}
          </CircleWithText>
        </Group>
      </DrawerHeader>
      <DrawerContent>
        <Stack spacing={0}>
          {notifications.map((notification, index) => {
            return (
              <Stack key={notification.oid} spacing={0}>
                {index > 0 && <Divider />}
                <NotificationRow notification={notification} onClick={onClick} />
              </Stack>
            )
          })}
        </Stack>
      </DrawerContent>
    </BetterDrawer>
  )
}
