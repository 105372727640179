import { Pill, Stack, Text } from '@shared/components'
import { usePatient } from '../PPatientContext'

export const EmptyInvoiceTable = () => {
  const { patientQuery } = usePatient()

  const patient = patientQuery?.data

  return (
    <Stack
      align='center'
      justify='center'
      p='md'
      mx='lg'
      my='md'
      sx={({ other: { colors } }) => ({
        backgroundColor: colors.background[1],
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
      })}
    >
      <Pill variant='filled' status='none'>
        No invoices
      </Pill>
      <Text>{patient?.personalData.firstName}&apos;s invoices will appear here</Text>
    </Stack>
  )
}
