import { SearchIcon, Select } from '@shared/components'
import { DoseSpotMedicationSearchResult } from '@shared/types'
import { toTime } from '@shared/utils'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { prescriptionsApi } from '../../../api'

const getMedicationLabel = (med: DoseSpotMedicationSearchResult) => {
  if (!med.DispensableDrugId || !med.Strength) {
    return med.Name
  }

  return `${med.Name} - ${med.Strength[0]?.Strength?.toLowerCase()}`
}

export type MedicationSearchBarProps = {
  onChange: (item?: DoseSpotMedicationSearchResult) => void
  value?: DoseSpotMedicationSearchResult
}

export const MedicationSearchBar = ({ onChange, value }: MedicationSearchBarProps) => {
  const [query, setQuery] = useState<string>('')
  const [medicationList, setMedicationList] = useState<DoseSpotMedicationSearchResult[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedQueryUpdate = useCallback(
    debounce(value => {
      setQuery(value)
    }, toTime('0.3 sec').ms()),
    [],
  )

  const medications = useQuery(
    ['prescriptionsApi.search', query],
    () => {
      if (query.length > 2) {
        return prescriptionsApi.search(query)
      }
      return []
    },
    {
      onSuccess: data => {
        if (data.length) {
          setMedicationList(data)
        }
      },
    },
  )

  useEffect(() => {
    return () => {
      debouncedQueryUpdate.cancel()
    }
  }, [])

  useEffect(() => {
    if (value) {
      setMedicationList([value])
    } else {
      setMedicationList([])
    }
  }, [value])

  const options = medicationList.map(med => ({
    key: med.Name,
    // @dosespotV2Migration - medication will include a DispensableDrugId when we're using the v2 API
    value: med.DispensableDrugId ? `${med.DispensableDrugId}` : med.Name,
    /*
     * @dosespotV2Migration
     * The v2 API will return multiple values for the same medication name, where
     * each value represents a different strength. To ensure that clinicians can differentiate between
     * the different strengths, we will append the strength to the medication name for v2 responses (which
     * we can identify by whether the medication includes a DispensableDrugId).
     *
     * Also, in v2, we can safely assume that the strength we're looking for is at index 0. This is because
     * the v2 API will return multiple values for the same medication name, where each value represents
     * a different strength. We manipulated the data on the backend to place the strength
     * in an array to match the output from v1
     */
    label: getMedicationLabel(med),
  }))

  const getNothingFoundString = () => {
    if (medications.isFetching) {
      return 'Searching...'
    }
    if (query.length > 2) {
      return 'No options'
    }
    return 'Keep typing to search for medication'
  }

  return (
    <Select
      icon={<SearchIcon />}
      label='Medication'
      placeholder='Start typing...'
      data={options}
      name='medication'
      searchable
      clearable
      // @dosespotV2Migration - use the DispensableDrugId when we're using the v2 API
      value={value?.DispensableDrugId ? `${value.DispensableDrugId}` : value?.Name}
      searchValue={searchValue}
      allowDeselect={false}
      onChange={(item: string) => {
        const medication = medicationList.find(obj => {
          // @dosespotV2Migration - use the DispensableDrugId when we're using the v2 API
          if (obj.DispensableDrugId) {
            return `${obj.DispensableDrugId}` === item
          }
          return obj.Name === item
        })
        onChange(medication)
        if (!medication) {
          setSearchValue('')
        }
      }}
      onSearchChange={val => {
        setSearchValue(val)
        debouncedQueryUpdate(val)
      }}
      nothingFound={getNothingFoundString()}
    />
  )
}
