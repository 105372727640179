import { Pill, Stack } from '@shared/components'
import { Config } from '../../config'

export const EmptyState = () => {
  return (
    <Stack
      align='center'
      justify='center'
      p='md'
      sx={({ other: { colors } }) => ({
        backgroundColor: colors.background[1],
        flex: 1,
        alignSelf: 'stretch',
      })}
    >
      <Pill status='none' variant='filled'>
        {Config.ENV === 'production'
          ? 'No winback patients found'
          : 'This table is disabled in staging'}
      </Pill>
    </Stack>
  )
}
