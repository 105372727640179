import { useForm } from '@mantine/form'
import { Drawer, Group, PrimaryButton, Stack, Text } from '@shared/components'
import { Day, Employee, HourRange, isClinician, isConsultationCallQueue } from '@shared/types'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from 'react-query'
import { employeesApi, emrApi } from '../../../api'
import { useEmrQuery } from '../../../utils/hooks'
import { EditWorkingHoursRange } from './EditWorkingHoursRange'

export type EditWorkingHoursDrawerProps = {
  employeeId: Employee['oid'] | undefined
  onClose: () => void
}

const days: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

type Form = {
  monday: HourRange[]
  tuesday: HourRange[]
  wednesday: HourRange[]
  thursday: HourRange[]
  friday: HourRange[]
  saturday: HourRange[]
  sunday: HourRange[]
}

const DEFAULT_FORM: Form = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
}

export const EditWorkingHoursDrawer = ({ employeeId, onClose }: EditWorkingHoursDrawerProps) => {
  const queryClient = useQueryClient()
  const form = useForm<Form>({ initialValues: DEFAULT_FORM })

  const [employeesKey] = emrApi.getQuery('GET /employees/list', {
    query: { status: 'currentEmployee' },
  })

  const employeeQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: { employeeId: employeeId || '' },
    },
    {
      enabled: Boolean(employeeId),
      onSuccess: employee => {
        if (isClinician(employee) || isConsultationCallQueue(employee)) {
          if (employee.workingHours) {
            form.setValues(employee.workingHours.settings)
          } else {
            form.setValues(DEFAULT_FORM)
          }
        }
      },
    },
  )

  const employee = employeeQuery.data as
    | Employee<'clinician' | 'consultation-call-queue'>
    | undefined

  const updateEmployee = useMutation(employeesApi.update, {
    onSuccess: () => {
      void queryClient.invalidateQueries(employeesKey)
      onClose()
    },
  })
  const onSubmit = () => {
    if (!employeeId || !employee) {
      // Satisfy the typechecker
      return
    }

    const update: Employee<'clinician' | 'consultation-call-queue'> = employee
    update.workingHours = {
      currentSince: dayjs().toISOString(),
      settings: form.values,
    }

    updateEmployee.mutate({
      employeeId,
      ...update,
    })
  }

  const loading = employeeQuery.isLoading
  const submitLoading = updateEmployee.isLoading

  return (
    <Drawer
      title={`Edit ${employee?.name ? `${employee?.name}'s` : ''} working hours`}
      opened={Boolean(employeeId)}
      onClose={onClose}
      position='right'
      size='sm'
      trapFocus={false}
      footer={
        <Group position='right'>
          <PrimaryButton loading={submitLoading} disabled={submitLoading} onClick={onSubmit}>
            Save changes
          </PrimaryButton>
        </Group>
      }
    >
      {!loading && (
        <Stack px='md' pb='lg' pt='sm' spacing='sm'>
          <Text size='xs' color={colors => colors.text[1]}>
            Current since {dayjs(employee?.workingHours?.currentSince).format('MM/DD/YYYY h:mma z')}
          </Text>
          <Stack spacing='lg'>
            {days.map(day => (
              <EditWorkingHoursRange
                key={day}
                day={day}
                showAlert={employee && isConsultationCallQueue(employee)}
                {...form.getInputProps(day)}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Drawer>
  )
}
