import { MoonIcon, PrimaryButton, showNotification, useCarousel } from '@shared/components'
import { EMRTaskDecorated, getOpheliaHttpError } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../../api'
import { TimeSpentForm, useTimeSpentForm } from '../TimeSpentForm'
import { TaskNoteForm, useNoteForm } from '../TaskNoteForm'
import { SnoozeTaskUntilForm, useSnoozeForm } from './SnoozeTaskUntilForm'

export type SnoozeTaskModalProps = {
  task: EMRTaskDecorated
  onClose: () => void
}

const SNOOZE_TASK_STEPS = [
  'TIME_SPENT_ON_TASK_FORM',
  'SNOOZE_TASK_UNTIL_FORM',
  'SNOOZE_TASK_NOTE_FORM',
] as const

export const SnoozeTaskModal = ({ task, onClose }: SnoozeTaskModalProps) => {
  const { current, next, prev } = useCarousel(SNOOZE_TASK_STEPS)

  const timeSpentForm = useTimeSpentForm()
  const { form: snoozeForm, remindAt } = useSnoozeForm()
  const noteForm = useNoteForm()

  const updateTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))

  const snoozeTask = () => {
    // If no assignee selected, clear out when snoozing
    const employeeId = noteForm.values.keepAssigned ? task.employeeId : ''

    updateTaskMutation.mutate(
      {
        params: { taskId: task.oid },
        data: {
          task: { status: 'snoozed', remindAt, employeeId },
          note: noteForm.values.note ? { text: noteForm.values.note } : undefined,
          timeEntry: {
            isBillableCoCM: Boolean(timeSpentForm.values.isBillableCoCM),
            minutes: Number(timeSpentForm.values.minutes),
          },
        },
      },
      {
        onSuccess: () => {
          showNotification({
            variant: 'success',
            // @TODO flesh out message with PT name + snooze date
            message: 'Issue has been snoozed',
          })

          onClose()
        },
      },
    )
  }

  if (current === 'TIME_SPENT_ON_TASK_FORM') {
    return (
      <TimeSpentForm
        form={timeSpentForm}
        task={task}
        title='Snooze issue'
        step={{ current: 1, total: 3 }}
        onClose={onClose}
        next={next}
      />
    )
  }

  if (current === 'SNOOZE_TASK_UNTIL_FORM') {
    return (
      <SnoozeTaskUntilForm
        step={{ current: 2, total: 3 }}
        onClose={onClose}
        onBack={prev}
        next={next}
        form={snoozeForm}
      />
    )
  }

  if (current === 'SNOOZE_TASK_NOTE_FORM') {
    return (
      <TaskNoteForm
        title='Snooze issue'
        task={task}
        step={{ current: 3, total: 3 }}
        onBack={prev}
        onClose={onClose}
        form={noteForm}
        allowUnassign
        error={getOpheliaHttpError(
          updateTaskMutation.error,
          'Something went wrong, try again later.',
        )}
        footer={
          <PrimaryButton
            loading={updateTaskMutation.isLoading}
            onClick={snoozeTask}
            leftIcon={<MoonIcon />}
          >
            Snooze
          </PrimaryButton>
        }
      />
    )
  }

  return null
}
