import React from 'react'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'

// TODO: Add type when attestation is type
export type AttestationSectionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attestation: Record<string, any>
}

const AttestationSection = ({ attestation }: AttestationSectionProps) => {
  return (
    <>
      {attestation?.attestation_notes && (
        <div className='mb-2 rounded bg-white shadow flex-grow-1 flex-shrink-0 w-full overflow-y-auto'>
          <div className='p-4'>
            <div className='flex justify-start items-center w-full h-4 mb-2'>
              <p className='text-xs text-gray-500'>
                Attested on{' '}
                {attestation.attested_at.slice(0, attestation.attested_at.lastIndexOf(','))} at{' '}
                {attestation.attested_at.slice(attestation.attested_at.lastIndexOf(',') + 1)} by{' '}
                {attestation.attested_by_display_name}
              </p>
            </div>
            <div className='bg-white border-t border-gray-100 leading-normal pt-2'>
              <ANoteTextArea disabled defaultValue={attestation.attestation_notes} borderless />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AttestationSection
