import { Box, Group, Skeleton, Stack } from '@shared/components'

const AFakeLabOrderRow = () => {
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.other.colors.background[0],
        paddingLeft: theme.other.sizes.gap.xl,
        paddingRight: theme.other.sizes.gap.xl,
        paddingTop: theme.other.sizes.padding.lg,
        paddingBottom: theme.other.sizes.padding.lg,
        borderTop: `${theme.other.sizes.border.md} solid ${theme.other.colors.background[1]}`,
        borderBottom: `${theme.other.sizes.border.md} solid ${theme.other.colors.background[1]}`,
      })}
    >
      <Stack>
        <Group position='apart'>
          <Skeleton height={10} width={150} radius='xl' />
          <Group>
            <Skeleton height={10} width={100} radius='xl' />
            <Skeleton height={10} width={50} radius='xl' />
          </Group>
        </Group>
        <Skeleton height={10} width={250} radius='xl' />
      </Stack>
    </Box>
  )
}

export default AFakeLabOrderRow
