import { createFormContext } from '@mantine/form'
import { FormPredicate, validateWith } from '@shared/components'
import {
  DoseSpotMedication,
  DoseSpotMedicationSearchResult,
  PrescriptionFavorite,
} from '@shared/types'
import { isControlledSubstance } from '@shared/utils'
import { SaveFavoriteFormData } from './SaveFavoriteForm'

export const MIN_REFILLS = 0
export const MAX_REFILLS = 2
export const MAX_REFILLS_NARCAN = 5
// The following dispensable IDs refer to all variations of Naloxone or Narcan medications
export const NARCAN_DISPENSABLE_IDS = [
  // Naloxone HCl Injection Solution 0.4 MG/ML
  79669,
  // Naloxone HCl Injection Solution Auto-injector 2 MG/0.4ML
  17255,
  // Naloxone HCl Injection Solution Prefilled Syringe 2 MG/2ML
  8803,
  // Naloxone HCl Nasal Liquid 4 MG/0.1ML
  8227,
  // Narcan Nasal Liquid 4 MG/0.1ML
  17231,
]
export type MedicationFormData = {
  medication?: DoseSpotMedicationSearchResult
  full_medication?: DoseSpotMedication
  medication_strength: string
  medication_quantity: string
  medication_days_supply: string
  medication_refills: string
  substitutionAllowed: boolean
  bridge: boolean
  pharmacyId?: number
  directions: string
  notes: string
  queuedForId?: string
  effective_date: string
  pin?: string
  twoFacCode?: string
  employeeAddress?: string
  dispensableDrugId?: number
}

/**
 * This function is used when the form contains a full_medication and the form has separate validations
 * when the medication is or is not a controlled substance. Ex: if a value is only required when the form's
 * full_medication is a controlled substance
 *
 * isControlled: A set of predicates to check when the full_medication is a controlled substance
 * isNarcan: A set of predicates to check when the full_medication is spefically Narcan to allow more refills
 * isNotControlled: A set of predicates to check when the full_medication is a controlled substance
 *
 * returns FormInputValidation (A function that returns a string if one of the predicates fails, 'Error' if full_medication is not provided
 *                              or undefined if all predicates pass)
 */
export type ControlledSubstanceValidationPredicates = {
  isControlled: FormPredicateType[]
  isNotControlled: FormPredicateType[]
  isNarcan?: FormPredicateType[]
}

type FormPredicateType = (
  value?: string,
  values?: { full_medication?: DoseSpotMedication },
) => unknown

export const controlledSubstanceValidationBuilder =
  ({
    isControlled,
    isNotControlled,
    isNarcan,
  }: ControlledSubstanceValidationPredicates): FormPredicate<
    string,
    { full_medication?: DoseSpotMedication }
  > =>
  (value?: string, values?: { full_medication?: DoseSpotMedication }) => {
    let validations: FormPredicateType[]
    if (!values?.full_medication) {
      return 'Error'
    }

    if (isControlledSubstance(values.full_medication.Schedule)) {
      validations = isControlled
    } else if (
      isNarcan &&
      values.full_medication.DispensableDrugId &&
      NARCAN_DISPENSABLE_IDS.includes(values.full_medication.DispensableDrugId)
    ) {
      validations = isNarcan
    } else {
      validations = isNotControlled
    }

    return validateWith(...validations)(value, values)
  }

export const convertPrescriptionFavoriteToSaveFavoriteFormData = (
  favorite: PrescriptionFavorite,
): SaveFavoriteFormData => {
  return {
    favoriteName: favorite.favoriteName,
    medication_strength: favorite.strength,
    medication_days_supply: favorite.daysSupply ? String(favorite.daysSupply) : '',
    medication_refills: favorite.refills ? String(favorite.refills) : '',
    medication_quantity: favorite.quantity ? String(favorite.quantity) : '',
    substitutionAllowed: Boolean(favorite.substitutionAllowed),
    directions: favorite.directions || '',
    notes: favorite.pharmacyNotes || '',
    dispensableDrugId: favorite?.dispensableDrugId,
  }
}

export const [MedicationFormProvider, useMedicationFormContext, useMedicationForm] =
  createFormContext<MedicationFormData>()

export const [FavoriteFormProvider, useFavoriteFormContext, useFavoriteForm] =
  createFormContext<SaveFavoriteFormData>()
