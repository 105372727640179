import { useForm } from '@mantine/form'
import {
  DatePicker,
  Group,
  InputWrapper,
  Loader,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Select,
  Stack,
  Text,
  useBanner,
  validateWith,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { isRequired } from '../../../utils/formValidation'
import { RelativeTimeSelectItem, getRelativeTimeUnitData } from '../inputs/RelativeTimeSelectItem'

export const PatientTaskExtensionModal = ({
  patientId,
  appointmentId,
  onClose,
}: {
  patientId: string
  appointmentId: string
  onClose: () => void
}) => {
  const { showBanner } = useBanner()
  const queryClient = useQueryClient()

  const [appointmentQueryKey, appointmentQueryFn] = emrApi.getQuery(
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      params: {
        patientId,
        appointmentId,
      },
    },
  )
  const appointmentQuery = useQuery(appointmentQueryKey, appointmentQueryFn)
  const appointment = appointmentQuery?.data
  const patientTaskExpirationDatetimeString = dayjs(
    appointment?.metadata?.patientTaskExpirationDatetime,
  )
    .tz('America/New_York')
    .format('dddd, MM/DD/YYYY | h:mma z')

  const form = useForm<{ patientTaskExpirationDatetime?: string }>({
    validate: {
      patientTaskExpirationDatetime: validateWith(isRequired),
    },
  })

  const updateExpirationMutation = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/appointments/:appointmentId/task-extension'),
  )

  const handleSave = async () => {
    if (form.validate().hasErrors || !form.values.patientTaskExpirationDatetime) {
      return
    }

    await updateExpirationMutation.mutateAsync({
      params: {
        patientId,
        appointmentId,
      },
      data: {
        patientTaskExpirationDatetime: form.values.patientTaskExpirationDatetime,
      },
    })
    void queryClient.invalidateQueries({
      queryKey: appointmentQueryKey,
    })
    showBanner({
      type: 'success',
      label: `Task window successfully extended to ${dayjs(
        form.values.patientTaskExpirationDatetime,
      )
        .tz('America/New_York')
        .format('dddd, MM/DD/YYYY | h:mma z')}`,
      dismissable: true,
    })
    onClose()
  }

  return (
    <Modal
      opened
      onClose={onClose}
      title='Extend task window'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            loading={appointmentQuery.isLoading && updateExpirationMutation.isLoading}
          >
            Extend task window
          </PrimaryButton>
        </Group>
      }
    >
      {appointmentQuery.isLoading || updateExpirationMutation.isLoading ? (
        <Group w='100%' position='center'>
          <Loader my='lg' />
        </Group>
      ) : (
        <Stack>
          <Text>
            This patient currently has until the following date to complete their intake tasks:
          </Text>
          <Text bold>{patientTaskExpirationDatetimeString}</Text>

          <InputWrapper label='To prevent their intake visit from being canceled, extend their task completion window to:'>
            <Group align='end' noWrap>
              <DatePicker
                placeholder='Select a date...'
                minDate={dayjs().toDate()}
                maxDate={dayjs(appointment?.datetime)
                  .subtract(1, 'day')
                  .toDate()}
                {...form.getInputProps('patientTaskExpirationDatetime')}
              />
              <Select
                defaultValue={form.values?.patientTaskExpirationDatetime}
                itemComponent={RelativeTimeSelectItem}
                data={getRelativeTimeUnitData(form.values?.patientTaskExpirationDatetime ?? '')}
                placeholder='Select a time...'
                {...form.getInputProps('patientTaskExpirationDatetime')}
              />
            </Group>
          </InputWrapper>
        </Stack>
      )}
    </Modal>
  )
}
