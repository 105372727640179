import { ClosedNoteDetails } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'

type FormData = {
  focus: string
  intervention: string
  response: string
  progress: string
  potentialNeeds: string
  plan: string
}

type PeerCheckinFormProps = {
  patientID: string
  visitID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visit?: any
  editMode: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: any
  sidebar: boolean
  closedNoteDetails?: ClosedNoteDetails
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCloseNoteModal?: any
}

const PeerCheckinForm = ({
  patientID,
  visitID,
  visit,
  editMode,
  sidebar,
  notes,
  closedNoteDetails,
  setCloseNoteModal,
}: PeerCheckinFormProps) => {
  const { register, handleSubmit, watch } = useForm<FormData>()
  const [errorMessage, setErrorMessage] = useState('')
  const [saving, setSaving] = useState(false)
  const [savedAt, setSavedAt] = useState('')

  const masterWatcher = watch()

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const save = async (data: FormData) => {
    const body = {
      type: 'peercheckin' as const,
      content: data,
    }

    await updateVisitNote.mutateAsync(
      {
        params: {
          patientId: patientID,
          visitId: visitID,
        },
        data: body,
      },
      {
        onSuccess: () => {
          setSavedAt(dayjs().format('YYYY-MM-DD, h:mm:ss a'))
          setSaving(false)
          setErrorMessage('')
        },
        onError: () => {
          setErrorMessage(
            'Failed to save your last edits. Please try to save again or contact the engineering team.',
          )
          setCloseNoteModal(false)
          setSaving(false)
        },
      },
    )
  }

  const onSubmit = async (data: FormData) => {
    setSaving(true)
    setCloseNoteModal(true)
    await save(data)
  }

  const saveNote = async () => {
    setSaving(true)
    await save(masterWatcher)
  }

  const dateSigned = closedNoteDetails?.locked_at ? dayjs(closedNoteDetails.locked_at) : undefined

  return (
    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
      {!sidebar && (
        <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-50'>
          <div className='flex flex-row h-full'>
            <div className='flex justify-start items-center flex-grow-1 flex-shrink-0 space-x-2'>
              {errorMessage && editMode && (
                <p className='text-xs text-error font-semibold'>{errorMessage}</p>
              )}
              {savedAt && editMode && (
                <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
              )}
              {!editMode && closedNoteDetails && (
                <p className='text-xs text-gray-500'>
                  Signed on
                  {dateSigned?.isValid()
                    ? dateSigned.format(' MMMM D, YYYY [at] h:mm a ')
                    : ` ${closedNoteDetails.locked_at} `}
                  by {closedNoteDetails.locked_by_name}
                </p>
              )}
            </div>
            {editMode && (
              <MVisitNoteSavingHeader
                dateTime={visit.datetime}
                saveNote={saveNote}
                saving={saving}
                editMode={editMode}
              />
            )}
          </div>
        </div>
      )}
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Focus</p>
        <ANoteTextArea
          id='focus'
          minRows={3}
          {...register('focus')}
          defaultValue={notes?.focus}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Intervention</p>
        <ANoteTextArea
          id='intervention'
          minRows={3}
          {...register('intervention')}
          defaultValue={notes?.intervention}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Response</p>
        <ANoteTextArea
          id='response'
          minRows={3}
          {...register('response')}
          defaultValue={notes?.response}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Progress</p>
        <ANoteTextArea
          id='progress'
          minRows={3}
          {...register('progress')}
          defaultValue={notes?.progress}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Potential Needs</p>
        <ANoteTextArea
          id='potentialNeeds'
          minRows={3}
          {...register('potentialNeeds')}
          defaultValue={notes?.potentialNeeds}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
      <ANoteSection hideBorder>
        <p className='text-sm font-medium leading-5 text-gray-700 mb-4 mt-4'>Plan</p>
        <ANoteTextArea
          id='plan'
          minRows={3}
          {...register('plan')}
          defaultValue={notes?.plan}
          disabled={!editMode}
          sidebar={sidebar}
        />
      </ANoteSection>
    </form>
  )
}

export default PeerCheckinForm
