import { Group, Modal, PrimaryButton, SecondaryButton, Stack, Text } from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

type ToggleFormCollectionModalProps = {
  title: ObjectiveMeasuresTitle
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}

const ToggleFormCollectionModal = ({
  title,
  opened,
  onClose,
  onSuccess,
}: ToggleFormCollectionModalProps) => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const phq9CollectionEnabled = patient?.formsToCollect?.phq9
  const gad7CollectionEnabled = patient?.formsToCollect?.gad7

  const isPhqTitle = title === 'PHQ-9' || title === 'PHQ-8'

  const modalStatus: 'Start' | 'Stop' =
    (isPhqTitle && phq9CollectionEnabled) || (title === 'GAD-7' && gad7CollectionEnabled)
      ? 'Stop'
      : 'Start'

  const startMessage = `${patient?.personalData.firstName} will be regularly prompted to complete the ${title} in the patient portal.`
  const stopMessage = `${patient?.personalData.firstName} will stop receiving ${
    isPhqTitle ? 'depression' : 'anxiety'
  } wellness check tasks in their portal. You will still be able to administer the task during a wellness visit.`

  const updatePatientData = useMutation(emrApi.getMutation('PUT /patient/:patientId'))
  const handleUpdate = async () => {
    if (!patient) {
      return
    }
    const updatedPhq9CollectionValue = isPhqTitle
      ? !patient.formsToCollect?.phq9
      : patient.formsToCollect?.phq9 || false

    const updatedGad7CollectionValue =
      title === 'GAD-7' ? !patient.formsToCollect?.gad7 : patient.formsToCollect?.gad7 || false

    const formsToCollect = {
      phq9: updatedPhq9CollectionValue,
      gad7: updatedGad7CollectionValue,
    }
    await updatePatientData.mutateAsync(
      {
        params: { patientId: patient.oid },
        data: {
          formsToCollect,
        },
      },
      {
        onSuccess: () => {
          onSuccess()
        },
      },
    )
    onClose()
  }

  return (
    <Modal
      title={`${modalStatus} collecting ${title} from ${patient?.personalData.firstName}`}
      opened={opened}
      onClose={onClose}
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>
            {modalStatus === 'Start' ? 'No, go back' : "No, don't stop"}
          </SecondaryButton>
          <PrimaryButton onClick={handleUpdate}>
            {modalStatus === 'Start' ? 'Yes, start collecting' : 'Yes, stop'}
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Text>{modalStatus === 'Start' ? startMessage : stopMessage}</Text>
        <Text>Are you sure you want to continue?</Text>
      </Stack>
    </Modal>
  )
}

export default ToggleFormCollectionModal
