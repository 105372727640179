import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import { Grid, Textarea, TitleThree } from '@shared/components'
import { Patient, getOpheliaHttpError } from '@shared/types'
import { useEmrMutation } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'

const getBillingTeamNotes = (patient?: Patient) => {
  return {
    freeformData: patient?.insuranceData?.freeformData ?? '',
  }
}

export const PatientProfileBillingTeamNotes = () => {
  const { patientId, patientQuery } = usePatient()
  const patient = patientQuery?.data

  const form = useForm<ReturnType<typeof getBillingTeamNotes>>({
    initialValues: getBillingTeamNotes(patient),
  })

  const initializeForm = (patient?: Patient) => {
    form.setValues(getBillingTeamNotes(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const [isEditing, toggleIsEditing] = useToggle()

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: {
          insuranceData: {
            freeformData: form.values.freeformData,
          },
        },
      })

      void patientQuery?.refetch()
    }

    toggleIsEditing()
  }

  const updatePatient = useEmrMutation('PUT /patient/:patientId')

  return (
    <EditableSection
      isLoading={patientQuery?.isLoading ?? false}
      title={<TitleThree>Billing team notes</TitleThree>}
      isEditing={isEditing}
      onEdit={() => toggleIsEditing()}
      isSaving={updatePatient.isLoading}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      onSave={() => onSave()}
      error={getOpheliaHttpError(updatePatient.error)}
    >
      <Grid>
        <EditableCol span={12} isEditing={isEditing} text={form.values.freeformData}>
          <Textarea placeholder='Notes' {...form.getInputProps('freeformData')} />
        </EditableCol>
      </Grid>
    </EditableSection>
  )
}
