import { Group, Skeleton, Stack } from '@shared/components'
import { PropsWithChildren, ReactNode } from 'react'

export const FieldSkeleton = ({
  isLoading,
  isError,
  children,
}: PropsWithChildren<{ isLoading: boolean; isError: boolean }>) => {
  if (isLoading || isError) {
    return (
      <Skeleton
        sx={({ spacing }) => ({
          height: spacing.md,
          width: `calc(${spacing.xl} * 2)`,
        })}
      />
    )
  }

  return <>{children}</>
}

export const ParagraphSkeleton = ({
  isLoading,
  isError,
  children,
}: PropsWithChildren<{ isLoading: boolean; isError: boolean }>) => {
  if (isLoading || isError) {
    return (
      <Stack spacing='xs'>
        <Skeleton sx={({ spacing }) => ({ height: spacing.md })} />
        <Skeleton sx={({ spacing }) => ({ height: spacing.md })} />
        <Skeleton sx={({ spacing }) => ({ height: spacing.md, width: '50%' })} />
      </Stack>
    )
  }

  return <>{children}</>
}

export const FooterContentSection = ({
  right,
  children,
}: PropsWithChildren<{ right: ReactNode }>) => (
  <Group position='apart' spacing='sm'>
    <Group spacing='sm'>{children}</Group>
    <Group sx={{ flex: 1 }} position='right'>
      {right}
    </Group>
  </Group>
)

// eslint-disable-next-line react/display-name
FooterContentSection.Item = ({ children }: PropsWithChildren) => (
  <Group spacing='xs' noWrap>
    {children}
  </Group>
)
