import { EmployeeModel, roleName } from '@shared/types'
import React from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../api'
import AFormGroup from '../../components/atoms/AFormGroup'
import ALabel from '../../components/atoms/ALabel'
import AOption from '../../components/atoms/AOption'
import ASelect from '../../components/atoms/ASelect'
import ODSInput from '../../components/ods/ODSInput'
import ODSLabeledTextInput from '../../components/ods/ODSLabeledTextInput'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'
import TModal from '../../components/templates/TModal'
import { opheliaEmailRegex } from '../../utils/utils'

const AddEmployeeModal = ({ closeModal }: { closeModal: () => void }) => {
  const queryClient = useQueryClient()
  const { register, handleSubmit, formState } = useForm<EmployeeModel & { compliance: boolean }>()

  const createEmployee = useMutation(emrApi.getMutation('POST /employees'))

  const onSubmit = handleSubmit(async data => {
    await createEmployee.mutateAsync({
      data: {
        name: data.name,
        role: data.role,
        email: data.email.trim().toLowerCase(),
      },
    })
    const [employeesListQueryKey] = emrApi.getQuery('GET /employees/list')
    void queryClient.invalidateQueries(employeesListQueryKey)
    closeModal()
  })

  return (
    <TModal style={{ maxHeight: '80%' }} closeModal={closeModal}>
      <div className='w-full'>
        <div className='flex flex-col'>
          <span className='font-semibold mb-3'>New Employee</span>
          <form onSubmit={onSubmit}>
            <AFormGroup className='box-border mr-3'>
              <ODSLabeledTextInput
                label='Display Name'
                {...register('name', { required: { value: true, message: 'Name required' } })}
                error={formState.errors.name?.message}
              />
            </AFormGroup>
            <AFormGroup className='box-border mr-3'>
              <ODSLabeledTextInput
                label='Email'
                {...register('email', {
                  pattern: {
                    value: opheliaEmailRegex,
                    message: 'Entered value does not match Ophelia email format',
                  },
                  required: { value: true, message: 'Email required' },
                })}
                error={formState.errors.email?.message}
              />
            </AFormGroup>
            <AFormGroup className='box-border mr-3'>
              <ALabel colon={false}>Role</ALabel>
              <ASelect disabled={false} {...register('role')}>
                {Object.entries(roleName).map(([role, roleName]) =>
                  role === 'admin' ? null : (
                    <AOption key={role} value={role}>
                      {roleName}
                    </AOption>
                  ),
                )}
              </ASelect>
            </AFormGroup>
            <AFormGroup className='flex flex-row'>
              <ODSInput
                {...register('compliance', { required: { value: true, message: 'Required' } })}
                type='checkbox'
                className='md:w-4 h-4 focus:ring-daybreak-actions1 text-daybreak-actions1 border-gray-300 rounded-md mr-2 mt-1'
              />
              <ALabel colon={false}>
                I attest that this employee has completed their compliance training
              </ALabel>
            </AFormGroup>
            <div className='flex flex-col'>
              <span className='font-semibold'></span>
              <span className='mt-1 max-w-sm'>
                <ODSPrimaryButton
                  type='submit'
                  message='Add employee'
                  loading={createEmployee.isLoading}
                />
              </span>
              {createEmployee.error ? (
                <span className='pt-4 text-error font-semibold'>
                  Adding employee failed. Does this employee already exist?
                </span>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </TModal>
  )
}

export default AddEmployeeModal
