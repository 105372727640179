import { useHover } from '@mantine/hooks'
import {
  Alert,
  AlertIcon,
  Group,
  MailIcon,
  PhoneIcon,
  PrimaryButton,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  Tooltip,
  TrashCanIcon,
  XCircleIcon,
} from '@shared/components'
import { ReactNode, useState } from 'react'
import { useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { DeleteAuthModal } from './modals/DeleteAuthModal'
import { RemovePhoneAuthModal } from './modals/RemovePhoneAuthModal'

export const AuthenticationSection = () => {
  const { patientId } = usePatient()
  const [showModal, setShowModal] = useState<'remove-phone' | 'delete-auth' | null>(null)

  const authQuery = useEmrQuery('GET /patient/:patientId/auth', { params: { patientId } })

  return (
    <Stack>
      {authQuery.isLoading || authQuery.data?.exists ? (
        <Skeleton visible={authQuery.isLoading}>
          <Stack spacing='sm'>
            <ButtonField
              title='Phone'
              icon={<PhoneIcon />}
              onClick={() => setShowModal('remove-phone')}
              tooltip='Remove phone'
            >
              {authQuery.data?.phone}
            </ButtonField>
            <ButtonField
              title='Email'
              icon={<MailIcon />}
              onClick={() => void 0}
              tooltip='Remove email'
            >
              {authQuery.data?.email}
            </ButtonField>
          </Stack>
        </Skeleton>
      ) : (
        <Alert variant='warning' icon={<AlertIcon />}>
          No authentication found
        </Alert>
      )}
      {authQuery.data?.exists && (
        <PrimaryButton
          size='xs'
          leftIcon={<TrashCanIcon />}
          onClick={() => setShowModal('delete-auth')}
        >
          Delete
        </PrimaryButton>
      )}
      <RemovePhoneAuthModal
        opened={showModal === 'remove-phone'}
        onClose={() => setShowModal(null)}
        phone={authQuery.data?.phone ?? ''}
        patientId={patientId}
      />
      <DeleteAuthModal
        opened={showModal === 'delete-auth'}
        onClose={() => setShowModal(null)}
        patientId={patientId}
      />
    </Stack>
  )
}

const ButtonField = ({
  title,
  children,
  onClick,
  icon,
  tooltip,
}: {
  title: string
  icon: ReactNode
  onClick: () => void
  children: string | undefined
  tooltip: string
}) => {
  const { ref, hovered } = useHover()

  return (
    <Group spacing='sm'>
      <Tooltip label={tooltip}>
        <TertiaryButton ref={ref} onClick={onClick} leftIcon={hovered ? <XCircleIcon /> : icon} />
      </Tooltip>
      <Text bold>{title}</Text>
      {children ? (
        <Text>{children}</Text>
      ) : (
        <Group spacing='sm' noWrap>
          <AlertIcon color={({ text }) => text[1]} />
          <Text color={({ text }) => text[1]}>No data</Text>
        </Group>
      )}
    </Group>
  )
}
