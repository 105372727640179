import { useForm } from '@mantine/form'
import { useFocusTrap } from '@mantine/hooks'
import {
  Banner,
  Checkbox,
  DatePicker,
  Drawer,
  Group,
  PrimaryButton,
  showNotification,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { drugScreenInfo, DrugScreenResults, Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { isRequired } from '../../../utils/formValidation'

type DrugScreenDrawerProps = {
  patient: Patient | undefined
  opened: boolean
  onClose: () => void
}
export type DrugScreenForm = {
  administeredAt: string
  authorId: string
  results: DrugScreenResults
}

const DrugScreenDrawer = ({ patient, opened, onClose }: DrugScreenDrawerProps) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const focusTrapRef = useFocusTrap()

  const drugScreenForm = useForm<DrugScreenForm>({
    initialValues: {
      administeredAt: dayjs().toISOString(),
      authorId: currentUser.oid,
      results: {
        drug_screen_alcohol_result: false,
        drug_screen_amphetamines_result: false,
        drug_screen_barbiturates_result: false,
        drug_screen_benzodiazepines_result: false,
        drug_screen_buprenorphine_result: false,
        drug_screen_cocaine_result: false,
        drug_screen_fentanyl_result: false,
        drug_screen_marijuana_result: false,
        drug_screen_methadone_result: false,
        drug_screen_methamphetamine_result: false,
        drug_screen_methylenedioxymethamphetamine_result: false,
        drug_screen_opiates_result: false,
        drug_screen_oxycodone_result: false,
        drug_screen_phencyclidine_result: false,
        drug_screen_synthetic_cannabinoid_result: false,
        drug_screen_tramadol_result: false,
      },
    },
    validate: {
      administeredAt: validateWith(isRequired),
      authorId: validateWith(isRequired),
    },
    clearInputErrorOnChange: false,
  })

  const [drugScreensQueryKey] = emrApi.getQuery('GET /patient/:patientId/drugScreens', {
    params: { patientId: patient?.oid || '' },
  })

  const addDrugScreen = useMutation(
    emrApi.getMutation('POST /patient/:patientId/drugScreens/create'),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(drugScreensQueryKey)
        showNotification({
          message: 'Drug screen successfully added',
          variant: 'success',
        })
        drugScreenForm.reset()
        onClose()
      },
    },
  )

  const submitDrugScreen = () => {
    if (drugScreenForm.validate().hasErrors) {
      return
    }

    // DatePicker set administeredAt as a Date object, so we need to convert it to ISO string
    drugScreenForm.values.administeredAt = dayjs(drugScreenForm.values.administeredAt).toISOString()

    addDrugScreen.mutate({
      params: { patientId: patient?.oid || '' },
      data: drugScreenForm.values,
    })
  }

  return (
    <Drawer
      title='Add drug screen'
      size='lg'
      opened={opened}
      position='right'
      onClose={() => {
        onClose()
        drugScreenForm.reset()
      }}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={submitDrugScreen} loading={addDrugScreen.isLoading}>
            Add drug screen
          </PrimaryButton>
        </Group>
      }
      ref={focusTrapRef}
    >
      <Stack p='md'>
        {addDrugScreen.isError && (
          <Banner type='error' label='Something went wrong, please try again' />
        )}
        <DatePicker
          label='Date administered'
          maxDate={dayjs().toDate()}
          placeholder='Select day...'
          defaultValue={dayjs().toDate()}
          autoFocus={false}
          {...drugScreenForm.getInputProps('administeredAt')}
        />
        <Stack spacing='sm' data-autofocus>
          <Text>Please mark which substances were positive</Text>
          {Object.keys(drugScreenInfo).map(drugScreenOption => {
            const drugScreenOptionInfo =
              drugScreenInfo[drugScreenOption as keyof typeof drugScreenInfo]
            const label = `${drugScreenOptionInfo.name} (${drugScreenOptionInfo.abbreviation})`
            return (
              <Checkbox
                label={label}
                key={drugScreenOption}
                {...drugScreenForm.getInputProps(`results.${drugScreenOption}`)}
              />
            )
          })}
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default DrugScreenDrawer
