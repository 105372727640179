import { Alert, BookmarkIcon, useLifecycle } from '@shared/components'
import * as FullStory from '../../../utils/fullstory'
export type CaseReviewRecommendationAlertProps = {
  patientFirstName: string
  // Only used to decide which fullstory event to trigger
  location: 'Visit Note' | 'Sign and Lock'
}
export const CaseReviewRecommendationAlert = ({
  patientFirstName,
  location,
}: CaseReviewRecommendationAlertProps) => {
  useLifecycle({
    onMount() {
      if (location === 'Visit Note') {
        FullStory.event('Case review recommendation shown from visit note')
      }
      FullStory.event('Case review recommendation shown from sign and lock')
    },
  })

  return (
    <Alert
      variant='secondary'
      icon={<BookmarkIcon />}
    >{`${patientFirstName} is a good candidate for case review`}</Alert>
  )
}
