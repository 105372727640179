import {
  Center,
  ClockIcon,
  EM_DASH,
  Group,
  Pill,
  PlusIcon,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  TimeInput,
  TitleFour,
  Tooltip,
  XIcon,
} from '@shared/components'
import { Day, HourRange, HourValue } from '@shared/types'

const DEFAULT_RANGE = { start: '09:00', end: '17:00' } as const

export type EditWorkingHoursRangeProps = {
  day: Day
  value: HourRange[]
  onChange: (value: HourRange[]) => void
  showAlert?: boolean
}

export type WorkingHoursRangeProps = {
  range?: HourRange | undefined
  onChange: (range: HourRange) => void
}

const WorkingHoursRange = ({ range, onChange }: WorkingHoursRangeProps) => {
  const { start, end } = range || DEFAULT_RANGE

  return (
    <Group>
      <TimeInput
        icon={<ClockIcon />}
        value={start}
        onChange={value => onChange({ start: value as HourValue, end })}
      />
      <Center>
        <Text>{EM_DASH}</Text>
      </Center>
      <TimeInput
        icon={<ClockIcon />}
        value={end}
        onChange={value => onChange({ start, end: value as HourValue })}
      />
    </Group>
  )
}

const HoverableWorkingHoursRange = ({
  onRemove,
  ...props
}: {
  onRemove: () => void
} & WorkingHoursRangeProps) => {
  return (
    <Group>
      <WorkingHoursRange {...props} />
      <Tooltip label='Remove this range'>
        <TertiaryButton
          leftIcon={<XIcon color={colors => colors.actions[1]} />}
          onClick={onRemove}
        />
      </Tooltip>
    </Group>
  )
}

const NoRanges = ({ onAdd, showAlert = false }: { onAdd: () => void; showAlert?: boolean }) => {
  return (
    <Group>
      {showAlert ? (
        <Pill variant='filled' status='warning'>
          No working hours this day
        </Pill>
      ) : (
        <Text>Not working this day.</Text>
      )}
      <Tooltip label='Add a range on this day'>
        <SecondaryButton size='sm' leftIcon={<PlusIcon />} onClick={onAdd} />
      </Tooltip>
    </Group>
  )
}

export const EditWorkingHoursRange = ({
  day,
  value,
  onChange,
  showAlert = false,
}: EditWorkingHoursRangeProps) => {
  return (
    <Stack spacing='sm'>
      <TitleFour>{day}</TitleFour>
      {!value.length && <NoRanges showAlert={showAlert} onAdd={() => onChange([DEFAULT_RANGE])} />}
      {value.length > 0 && (
        <Stack key={day} spacing='sm'>
          {value.map((range, i) => (
            <HoverableWorkingHoursRange
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              range={range}
              onChange={range => onChange(value.map((v, r) => (r === i ? range : v)))}
              onRemove={() => onChange(value.filter((_, r) => r !== i))}
            />
          ))}
          <TertiaryButton
            leftIcon={<PlusIcon />}
            onClick={() => onChange([...value, DEFAULT_RANGE])}
          >
            Range
          </TertiaryButton>
        </Stack>
      )}
    </Stack>
  )
}
