import { Patient } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { patientsApi } from '../../../api'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import ODSSecondaryButton from '../../../components/ods/ODSSecondaryButton'
import { useAuth } from '../../../context/auth'
import MReengagementNote from './MReengagementNote'
export type OReengagementNoteModalProps = {
  closeModal: () => void
  patient: Patient
  patientID: string
}

const OReengagementNoteModal = ({
  closeModal,
  patient,
  patientID,
}: OReengagementNoteModalProps) => {
  const { currentUser } = useAuth()
  const { errors, register, handleSubmit, watch, setValue } = useForm()
  const [dateOfDischarge, setDateOfDischarge] = useState('')

  useEffect(() => {
    const fetchPatientDischargeNotes = async () => {
      const discharges = await patientsApi.listDischargeNotes({ patientId: patientID })

      if (discharges && discharges.length > 0) {
        setDateOfDischarge(dayjs(discharges[discharges.length - 1]?.timestamp).format('YYYY-MM-DD'))
      }
    }

    void fetchPatientDischargeNotes()
  }, [patientID, setValue])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (data: Record<string, any>) => {
    const note = { ...data, employeeId: currentUser.oid, timestamp: new Date().toISOString() }
    try {
      await patientsApi.createReengagementNote({ patientId: patientID, note })
      window.location.reload()
    } catch (_err) {
      // no-op to keep modal open
    }
  }

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(submit)}>
        <div className='flex flex-col'>
          <div className='sticky top-0 bg-white w-full z-30 py-6 border-b border-gray-200'>
            <div className='flex justify-between items-center'>
              <div className='flex flex-col justify-start'>
                <p className='text-xl leading-4 w-full text-left mb-4'>Re-Engagement Note</p>
                <p className='text-gray-500 text-sm text-left'>
                  It&apos;s required that we document the following details for all patients trying
                  to re-enter our program.
                </p>
              </div>
              <div className='flex space-x-2 h-3/4'>
                <ODSPrimaryButton message='Submit' type='submit' />
                <ODSSecondaryButton type='button' message='Cancel' onClick={closeModal} />
              </div>
            </div>
          </div>
          <MReengagementNote
            errors={errors}
            patient={patient}
            register={register}
            watch={watch}
            dateOfDischarge={dateOfDischarge}
          />
        </div>
      </form>
    </div>
  )
}

export default OReengagementNoteModal
