import { JourneyItemType } from '../PPatientContext'

export const journeyLabels: Record<JourneyItemType | `${JourneyItemType}.${string}`, string> = {
  appointment: 'Visits',
  'appointment.canceled': 'Canceled visits',
  'case-review-note': 'Case review notes',
  'non-visit-event': 'Non-visit events',
  'patient-status': 'Patient statuses',
  prescription: 'Prescriptions',
  task: 'Issues',
  'ineligible-note': 'Ineligible notes',
  'discharge-note': 'Discharge notes',
  'drug-screen': 'Urine drug screens (UDS)',
  'uds-cup': 'UDS cup order',
  'visit-hold': 'Visit holds',
}
