import { TextInput, TextInputProps } from '@shared/components'
import { LabeledData } from './LabeledData'

export type CardTextInputProps = TextInputProps & { editable: boolean }

const getDefaultPlaceholderText = (label: string) => {
  const trimmedLabel = label.replace('(optional)', '').trim()
  const lowercaseLabel = label.toLowerCase().replace('(optional)', '').trim()
  switch (lowercaseLabel) {
    case 'first name':
      return 'Wesley'
    case 'last name':
      return 'Crusher'
    case 'address line 1':
      return '1000 1st Ave'
    case 'address line 2':
      return 'Apt 2'
    case 'city':
      return 'New York'
    case 'state':
      return 'NY'
    case 'date of birth':
      return 'YYYY-MM-DD'
    case 'zip':
      return '10001'
    case 'zip plus four':
      return '0000'
    default:
      return trimmedLabel
  }
}

export const CardTextInput = ({ editable, ...props }: CardTextInputProps) => {
  const defaultPlaceholderText =
    props.placeholder === undefined && typeof props.label === 'string'
      ? getDefaultPlaceholderText(props.label)
      : ''
  if (editable) {
    return <TextInput {...props} placeholder={props.placeholder ?? defaultPlaceholderText} />
  }

  return (
    <LabeledData
      label={props.label}
      data={props.value ? String(props.value) : null}
      hasError={Boolean(props.error)}
    />
  )
}
