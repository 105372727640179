import { Group } from '@shared/components'
import { useNavigate } from 'react-router-dom'
import HeaderTab from '../care_team/irq/HeaderTab'
import { useFlags } from '../../utils/hooks'

export type EnrollmentPanelHeaderContentProps = {
  selectedPage: EnrollmentPanelPage
}

export enum EnrollmentPanelPage {
  CandidatesWithIntakes,
  CandidatesWithoutIntakes,
  Leads,
}

export const EnrollmentPanelHeaderContent = ({
  selectedPage,
}: EnrollmentPanelHeaderContentProps) => {
  const navigate = useNavigate()
  const { bookedCandidates } = useFlags()

  return (
    <Group px='sm' sx={{ flexGrow: 1 }}>
      <HeaderTab
        onClick={() => navigate('/enrollment-panel/leads')}
        selected={selectedPage === EnrollmentPanelPage.Leads}
        label='Leads'
      />
      <HeaderTab
        onClick={() => navigate('/enrollment-panel/candidates')}
        selected={selectedPage === EnrollmentPanelPage.CandidatesWithoutIntakes}
        label='Candidates without intakes'
      />
      {bookedCandidates && (
        <HeaderTab
          onClick={() => navigate('/enrollment-panel/booked-candidates')}
          selected={selectedPage === EnrollmentPanelPage.CandidatesWithIntakes}
          label='Candidates with intakes'
        />
      )}
    </Group>
  )
}
