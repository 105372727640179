type MErrorModalProps = {
  message: string
  closeModal: () => void
}

const MErrorModal = ({ message, closeModal }: MErrorModalProps) => {
  return (
    <div className={`${message ? 'z-50' : 'z-0'} fixed inset-0`}>
      <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0'>
        {message && (
          <div className='fixed inset-0 transition-opacity'>
            <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
          </div>
        )}
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>&#8203;
        {message && (
          <div
            className='w-64 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:p-6'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className='flex flex-col items-center justify-center'>
              <div className='flex items-center justify-start my-4'>
                <p className='text-lg leading-7'>{message}</p>
              </div>
              <div className='flex flex-col flex-shrink-0 flex-grow-0 justify-center items-center w-full'>
                <span className='flex w-full rounded-md shadow-sm'>
                  <button
                    onClick={closeModal}
                    type='button'
                    className='inline-flex justify-center w-full rounded-md border border-gray-300 bg-white text-gray-700 hover:text-gray-500 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition sm:text-sm sm:leading-5'
                  >
                    Close
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MErrorModal
