import {
  Accordion,
  Divider,
  MinusCircleIcon,
  PlusCircleIcon,
  Stack,
  TitleThree,
} from '@shared/components'
import { useLunaQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { InsuranceSubSection } from './InsuranceSubSection'

export const PastInsurancesSection = () => {
  const { patientId } = usePatient()

  const { data: pastInsuranceIdsQuery } = useLunaQuery('GET /patients/:patientId/past-insurances', {
    params: { patientId },
  })

  const pastInsuranceIds = pastInsuranceIdsQuery?.data?.pastInsuranceIds ?? []

  if (pastInsuranceIds.length === 0) {
    return null
  }

  return (
    <Stack>
      <Accordion
        defaultValue='opened'
        chevronPosition='left'
        chevronActive={<MinusCircleIcon styled />}
        chevronInactive={<PlusCircleIcon styled />}
      >
        <Accordion.Item value='opened'>
          <Accordion.Control pl={0}>
            <TitleThree>Past insurances</TitleThree>
          </Accordion.Control>
          <Accordion.Panel>
            {pastInsuranceIds.map(pastInsuranceId => (
              <InsuranceSubSection
                key={pastInsuranceId}
                insuranceId={pastInsuranceId}
                isInsuranceActive={false}
                insuranceType='other'
                isPastInsurance
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Divider />
    </Stack>
  )
}
