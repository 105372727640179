import { Select, SelectProps } from '@shared/components'
import { getTimeZones } from '@shared/utils'

export const TimeZoneSelect = ({
  patientTimeZone,
  label,
  ...props
}: Omit<SelectProps, 'data' | 'placeholder' | 'searchable'> & {
  patientTimeZone?: string | undefined
}) => {
  return (
    <Select
      searchable
      placeholder='Select one...'
      label={label}
      data={getTimeZones().map(({ value, label, time }) => ({
        value,
        label: `${label} - ${time}${patientTimeZone === value ? ' (Patient time zone)' : ''}`,
      }))}
      {...props}
    />
  )
}
