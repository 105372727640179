import {
  Accordion,
  CircleWithText,
  Drawer,
  Group,
  Select,
  TitleThree,
  useMantineTheme,
} from '@shared/components'
import { ActionItemSortByOptions, Employee } from '@shared/types'
import range from 'lodash/range'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { actionItemsApi } from '../../../api'
import AFakeActionItem from '../../../components/atoms/fakes/AFakeActionItem'
import { ActionItemGroupOption, getSortedActionItems } from '../../../utils/actionItems'
import MActionItemEmptyState from './MActionItemEmptyState'
import MActionItemGroup from './MActionItemGroup'

type MAlertsDrawerProps = {
  currentUser: Employee
  isAlertsDrawerOpened: boolean
  setAlertsDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  refreshAlertsCount: () => void
}

const MAlertsDrawer = ({
  currentUser,
  isAlertsDrawerOpened,
  setAlertsDrawerOpened,
  refreshAlertsCount,
}: MAlertsDrawerProps) => {
  const [selectedSortBy, setSelectedSortBy] = useState<string | null>(
    Object.keys(ActionItemSortByOptions)[0] ?? null,
  )
  const actionItems = useQuery(['actionItemsApi.getOpenActionItems', currentUser.oid], () =>
    actionItemsApi.getOpenActionItems({ employeeId: currentUser.oid }),
  )
  const areActionItemsLoading = actionItems.isLoading
  const hasActionsToDisplay = actionItems.data && actionItems.data.length > 0

  const sortedActionItems =
    hasActionsToDisplay && selectedSortBy
      ? getSortedActionItems({
          actionItems: actionItems.data,
          group: selectedSortBy as ActionItemGroupOption,
        })
      : []

  const [currentAccordionValues, setCurrentAccordionValues] = useState<string[]>([])

  const selectOptions = Object.entries(ActionItemSortByOptions).map(([key]) => {
    return {
      value: key,
      label: `Sort by ${ActionItemSortByOptions[key]?.label}`,
    }
  })

  const {
    other: { sizes },
  } = useMantineTheme()

  const refetchAll = async () => {
    await actionItems.refetch()
    refreshAlertsCount()
  }

  const handleSelection = (selection: string) => {
    setSelectedSortBy(selection)
    // Reset which accordions are open
    setCurrentAccordionValues([])
  }

  return (
    <Drawer
      opened={isAlertsDrawerOpened}
      onClose={() => setAlertsDrawerOpened(false)}
      position='right'
      title={
        <Group
          sx={{
            alignItems: 'start',
          }}
          spacing='sm'
        >
          <TitleThree>Action items</TitleThree>
          {actionItems.data && (
            <CircleWithText
              variant='error'
              size={sizes.icon.sm}
              sx={{
                fontWeight: 700,
              }}
            >
              {actionItems.data.length}
            </CircleWithText>
          )}
        </Group>
      }
      size='lg'
    >
      {hasActionsToDisplay && (
        <Select
          data={selectOptions}
          value={selectedSortBy}
          onChange={handleSelection}
          placeholder={selectedSortBy ? `Sort by ${ActionItemSortByOptions[selectedSortBy]}` : ''}
          p='md'
        />
      )}
      {sortedActionItems && selectedSortBy && (
        <Accordion multiple value={currentAccordionValues} onChange={setCurrentAccordionValues}>
          {sortedActionItems.map(actionItemGroup => {
            return (
              <MActionItemGroup
                key={actionItemGroup.group}
                sortedBy={selectedSortBy}
                groupName={actionItemGroup.group}
                actionItems={actionItemGroup.actionItems}
                currentAccordionValues={currentAccordionValues}
                setCurrentAccordionValues={setCurrentAccordionValues}
                refetch={refetchAll}
              />
            )
          })}
        </Accordion>
      )}
      {!hasActionsToDisplay && !areActionItemsLoading && (
        <MActionItemEmptyState allEmployeeActionsCompleted />
      )}
      {areActionItemsLoading && range(0, 15).map(i => <AFakeActionItem key={i} />)}
    </Drawer>
  )
}

export default MAlertsDrawer
