import { useForm } from '@mantine/form'
import { Grid, skipIfEmpty, Stack, validateWith } from '@shared/components'
import { RenderingProvider, stateAbbreviations } from '@shared/types'
import { useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../../api'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import {
  isAbbreviatedUsState,
  isFourNumbers,
  isNumber,
  isRequired,
  isZipCode,
} from '../../../../utils/formValidation'
import { CardHeader } from '../components/CardHeader'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'
export type RenderingProviderSectionProps = {
  isEditing: boolean
  setEditing: (bool: boolean) => void
  editButtonEnabled: boolean
  renderingProvider?: RenderingProvider | null
  encounterId: string
}

export const RenderingProviderSection = ({
  isEditing,
  setEditing,
  editButtonEnabled,
  renderingProvider,
  encounterId,
}: RenderingProviderSectionProps) => {
  const queryClient = useQueryClient()
  const formattedRenderingProviderData = useMemo(() => {
    return {
      npi: String(renderingProvider?.npi || ''),
      first_name: renderingProvider?.first_name || '',
      last_name: renderingProvider?.last_name || '',
      address: {
        address1: renderingProvider?.address?.address1 || '',
        address2: renderingProvider?.address?.address2 || '',
        city: renderingProvider?.address?.city || '',
        state: renderingProvider?.address?.state || '',
        zip_code: renderingProvider?.address?.zip_code || '',
        zip_plus_four_code: renderingProvider?.address?.zip_plus_four_code || '',
      },
    }
  }, [renderingProvider])

  type RenderingProviderForm = Omit<RenderingProvider, 'npi'> & { npi: string }
  const form = useForm<RenderingProviderForm>({
    initialValues: formattedRenderingProviderData,
    validate: {
      npi: validateWith(isRequired, isNumber),
      first_name: validateWith(isRequired),
      last_name: validateWith(isRequired),
      address: {
        state: validateWith(isAbbreviatedUsState),
        zip_code: validateWith(isZipCode),
        zip_plus_four_code: validateWith(skipIfEmpty, isFourNumbers),
      },
    },
    clearInputErrorOnChange: false,
  })

  const [encounterQueryKey] = emrApi.getQuery('GET /encounters/:encounterId', {
    params: { encounterId: encounterId || '' },
  })

  const updateEncounter = useMutation(emrApi.getMutation('PUT /encounters'))

  const saveRenderingProviderData = (renderingProviderData: RenderingProviderForm) => {
    updateEncounter.mutate(
      {
        data: {
          encounter: {
            rendering_provider: {
              ...renderingProviderData,
              npi: renderingProviderData.npi ? Number(renderingProviderData.npi) : undefined,
            },
          },
          id: encounterId || '',
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(encounterQueryKey)
          setEditing(false)
        },
      },
    )
  }

  useEffect(() => {
    form.validate()
  }, [form.values])

  useEffect(() => {
    form.setValues(formattedRenderingProviderData)
  }, [formattedRenderingProviderData])

  return (
    <Stack>
      <CardHeader
        title='Rendering provider'
        showButton={editButtonEnabled}
        buttonOnClick={() => setEditing(true)}
        buttonLabel='Edit'
        errorCount={Object.keys(form.errors).length}
      />
      <Grid columns={12}>
        <Grid.Col span={4}>
          <CardTextInput
            label='First name'
            {...form.getInputProps('first_name')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Last name'
            {...form.getInputProps('last_name')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='NPI'
            {...form.getInputProps('npi')}
            editable={isEditing}
            placeholder='1234567890'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 1'
            {...form.getInputProps('address.address1')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Address line 2 (optional)'
            {...form.getInputProps('address.address2')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='City'
            {...form.getInputProps('address.city')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            data={stateAbbreviations}
            searchable
            label='State'
            {...form.getInputProps('address.state')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip'
            {...form.getInputProps('address.zip_code')}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            label='Zip plus four (optional)'
            {...form.getInputProps('address.zip_plus_four_code')}
            editable={isEditing}
          />
        </Grid.Col>
      </Grid>
      {isEditing ? (
        <EditableSectionFooter
          onCancel={() => {
            setEditing(false)
            form.setValues(formattedRenderingProviderData)
          }}
          onSave={form.onSubmit(() => {
            saveRenderingProviderData(form.values)
          })}
          isSaving={updateEncounter.isLoading}
        />
      ) : null}
    </Stack>
  )
}
