import { Pill } from '@shared/components'
import type { Visit } from '../api/types'

export const DischargeVisitPill = ({ visit }: { visit: Visit }) => {
  if (visit.isDischargeVisit) {
    return (
      <Pill variant='filled' status='none'>
        Discharge visit
      </Pill>
    )
  }

  return null
}
