import { BetterDrawer, DrawerHeader, Tabs } from '@shared/components'
import { Patient } from '@shared/types'
import { useState } from 'react'
import { CommunicationsType } from '../../../../utils/communications'
import { CallLog } from './calls/CallLog'
import { MessageLog } from './sms/MessageLog'

type CommunicationsDrawerProps = {
  patient: Patient
  onClose: () => void
}

export const CommunicationsDrawerContent = ({ onClose, patient }: CommunicationsDrawerProps) => {
  const [activeTab, setActiveTab] = useState<CommunicationsType | null>(CommunicationsType.SMS)

  const headerTitle = patient?.personalData?.firstName
    ? `Communications with ${patient?.personalData?.firstName}`
    : 'Communications'

  return (
    <>
      <DrawerHeader onClose={onClose}>{headerTitle}</DrawerHeader>
      <Tabs
        py='0'
        value={activeTab}
        onTabChange={(value: CommunicationsType) => setActiveTab(value)}
      >
        <Tabs.List pl='lg'>
          <Tabs.Tab value={CommunicationsType.SMS}>SMS</Tabs.Tab>
          <Tabs.Tab value={CommunicationsType.Calls}>Calls</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      {activeTab === CommunicationsType.SMS && <MessageLog patient={patient} />}
      {activeTab === CommunicationsType.Calls && <CallLog patient={patient} />}
    </>
  )
}

export const CommunicationsDrawer = ({
  opened,
  ...props
}: CommunicationsDrawerProps & { opened: boolean }) => {
  return (
    <BetterDrawer position='right' size='lg' opened={opened} onClose={props.onClose}>
      <CommunicationsDrawerContent {...props} />
    </BetterDrawer>
  )
}
