import { Box, Colors, Text } from '@shared/components'
import { dayjs } from '@shared/utils'

const determineColors = ({
  treatmentTime,
  isFirstMonth,
  colors,
}: {
  treatmentTime: number
  isFirstMonth: boolean
  colors: Colors
}) => {
  // since the billing threshold is 36 for the first month of CoCM treatment and 31 afterwards, we color these differently
  if ((treatmentTime >= 36 && isFirstMonth) || (treatmentTime >= 31 && !isFirstMonth)) {
    return {
      backgroundColor: colors.success[1],
      borderColor: colors.success[0],
    }
  }
  if ((treatmentTime >= 18 && isFirstMonth) || (treatmentTime >= 16 && !isFirstMonth)) {
    return {
      backgroundColor: colors.warning[1],
      borderColor: colors.warning[0],
    }
  }
  return {
    backgroundColor: colors.error[1],
    borderColor: colors.error[0],
  }
}

const CocmMins = ({ treatmentTime, dateAdded }: { treatmentTime: number; dateAdded: string }) => {
  const startOfMonth = dayjs().tz('America/New_York').startOf('month').toISOString()
  const isFirstMonth = dateAdded > startOfMonth

  return (
    <Box
      sx={({ other: { colors, sizes }, radius }) => {
        const { backgroundColor, borderColor } = determineColors({
          treatmentTime,
          isFirstMonth,
          colors,
        })
        return {
          minWidth: sizes.icon.lg,
          height: sizes.icon.lg,
          borderWidth: sizes.border.md,
          borderRadius: radius.sm,
          textAlign: 'center',
          backgroundColor,
          borderColor,
        }
      }}
    >
      <Text bold>{treatmentTime}</Text>
    </Box>
  )
}

export default CocmMins
