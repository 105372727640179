const PLACEHOLDER_MAP = {
  firstName: 'Wesley',
  lastName: 'Crusher',
  dob: 'MM/DD/YYYY',
  provider: 'United HealthCare',
  memberId: '123456789',
  groupId: '19191',
  rxBin: '98912',
  rxPcn: '12345',
  rxGroup: '19191',
  planName: 'KEYCARE PPO HSA',
} as const
type PlaceholderKeys = keyof typeof PLACEHOLDER_MAP
export const getPlaceholderText = (field: PlaceholderKeys) => {
  return PLACEHOLDER_MAP[field]
}
