import { Loader, SearchIcon, Select, SelectItem, SelectProps } from '@shared/components'
import { arrayIncludes } from '@shared/types'
import {
  INSURANCE_OTHER_OPTION,
  getInsurancePlanId,
  matchesSearch,
  sortBy,
  toTime,
} from '@shared/utils'
import { useMemo, useState } from 'react'
import { useEmrQuery, useLunaQuery } from '../../utils/hooks'

export type InsuranceSelectProps = Omit<
  SelectProps,
  'data' | 'icon' | 'searchable' | 'clearable' | 'filter'
> & {
  patientId: string
}

const IN_NETWORK = 'In network'
const OUT_OF_NETWORK = 'Out of network'

export const InsuranceSelect = (props: InsuranceSelectProps) => {
  const patientQuery = useEmrQuery('GET /patient/:patientId', {
    params: { patientId: props.patientId },
  })

  const [customInsurances, setCustomInsurances] = useState<SelectItem[]>([])
  const payersQuery = useLunaQuery('GET /insurance-payers', {
    staleTime: toTime('5 min').ms(),
  })

  const patientResidenceState = patientQuery?.data?.homeData?.state
  const insurances = useMemo(() => {
    const allInsurances = (payersQuery.data?.data || [])
      .map<SelectItem>(({ payerId, name, inNetworkStates }) => {
        const uniqueProviderId = getInsurancePlanId(payerId, name)
        return {
          label: name,
          value: uniqueProviderId,
          group:
            inNetworkStates === 'all' || arrayIncludes(inNetworkStates, patientResidenceState)
              ? IN_NETWORK
              : OUT_OF_NETWORK,
        }
      })
      .sort(
        sortBy({
          key: 'group',
          order: 'ASC',
        }),
      )

    return [...customInsurances, ...allInsurances]
  }, [patientResidenceState, payersQuery.data, customInsurances])

  return (
    <Select
      w='100%'
      my={0}
      data={insurances}
      icon={payersQuery.isLoading ? <Loader /> : <SearchIcon />}
      searchable
      clearable
      disabled={payersQuery.isLoading}
      filter={(value, selectItem) => {
        return (
          selectItem.value === INSURANCE_OTHER_OPTION.value ||
          matchesSearch({ searchValue: value, itemValue: selectItem.value })
        )
      }}
      placeholder='Search...'
      getCreateLabel={query => `+ Add custom payer name: ${query}`}
      onCreate={query => {
        const item = { value: `OTHER__${query}`, label: query }
        setCustomInsurances(current => [...current, item])
        return item
      }}
      {...props}
    />
  )
}
