import React from 'react'
import { LoadingContent, LoadingContentType } from '../../pages/core/loading/LoadingContent'
import ODSSecondaryButton from '../ods/ODSSecondaryButton'

export type AGeneralTablePaginationFooterProps = {
  offset: number
  pageSize: number
  total?: number
  onNextClick: () => void
  onPreviousClick: () => void
  isLoading?: boolean
}

const AGeneralTablePaginationFooter = ({
  offset,
  pageSize,
  total,
  onNextClick,
  onPreviousClick,
  isLoading = false,
}: AGeneralTablePaginationFooterProps) => {
  let numberOnPage = offset + pageSize
  const displayTotalFromQuery = total && offset + pageSize > total
  const fetchedAllResults = total && total > pageSize
  if (displayTotalFromQuery) {
    if (fetchedAllResults) {
      numberOnPage = total
    } else {
      numberOnPage = total + offset
    }
  }

  const pageNumberString = (
    <p className='text-sm text-gray-700'>
      {total === 0 ? (
        '0 Results'
      ) : (
        <>
          Showing <span className='font-medium'>{offset + 1}</span> to{' '}
          <span className='font-medium'>{numberOnPage}</span>
          {Boolean(total) && (
            <>
              {' '}
              of <span className='font-medium'>{fetchedAllResults ? total : numberOnPage}</span>
            </>
          )}
        </>
      )}
    </p>
  )

  return (
    <nav className='px-2 space-x-5 py-3 flex items-center justify-between' aria-label='Pagination'>
      <div className='hidden sm:block'>
        {isLoading ? <LoadingContent type={LoadingContentType.Word} /> : pageNumberString}
      </div>
      <div className='flex-1 flex justify-between sm:justify-end'>
        {offset !== 0 && (
          <ODSSecondaryButton onClick={onPreviousClick} message='Previous' disabled={isLoading} />
        )}
        {Boolean(!total || (total && offset + pageSize < total)) && (
          <ODSSecondaryButton onClick={onNextClick} message='Next' disabled={isLoading} />
        )}
      </div>
    </nav>
  )
}

export default AGeneralTablePaginationFooter
