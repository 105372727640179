import { useToggle } from '@mantine/hooks'
import {
  Accordion,
  Box,
  CircleWithText,
  Group,
  Loader,
  PlusIcon,
  ScrollAreaAutosize,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { ProblemListProblem } from '@shared/types'
import { toTime } from '@shared/utils'
import uniq from 'lodash/uniq'
import { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../api'
import TModal from '../../components/templates/TModal'
import { useFlags } from '../../utils/hooks'
import { usePatient } from './PPatientContext'
import { AddressContent } from './sidebar/AddressContent'
import { DiagnosisContent } from './sidebar/DiagnosisContent'
import { IdentificationContent } from './sidebar/IdentificationContent'
import {
  BillingNotesContent,
  PrimaryInsuranceContent,
  SecondaryInsuranceContent,
} from './sidebar/InsuranceContent'
import { InternalNotesContent } from './sidebar/InternalNotesContent'
import { IssuesContent } from './sidebar/IssuesContent'
import { LabsContent } from './sidebar/LabsContent'
import { MeasuresContent } from './sidebar/MeasuresContent'
import { PharmacyContent } from './sidebar/PharmacyContent'
import { PrescriptionsContent } from './sidebar/PrescriptionsContent'
import { PriorAuthContent } from './sidebar/PriorAuthContent'
import { BillingContent } from './sidebar/billing/BillingContent'
import OAddProblemListModal from './visits/OAddProblemListModal'

export const PatientLeftSidebar = ({ canAddDiagnoses = false }: { canAddDiagnoses?: boolean }) => {
  const { problemsListQuery, patientId } = usePatient()
  const flags = useFlags()
  const isVisitHoldsV2Enabled = Boolean(flags.visitHoldsV2)
  const [sections, setSection] = useState<string[]>([])

  const tasksQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/issues', {
      params: { patientId },
    }),
    {
      // Always fetch tasks to show notification
      enabled: Boolean(patientId),
      cacheTime: toTime('30 sec').ms(),
    },
  )

  const patientTasks = tasksQuery?.data

  const { open, snoozed } = useMemo(
    () =>
      patientTasks
        ? {
            open: patientTasks.filter(task => task.status === 'open'),
            snoozed: patientTasks.filter(task => task.status === 'snoozed'),
          }
        : { open: [], snoozed: [] },
    [patientTasks],
  )

  const tasksCount = open.length + snoozed.length

  const [problemListModal, setProblemListModal] = useToggle()

  const saveDiagnoses = useMutation(emrApi.getMutation('POST /patient/:patientId/problemsList'))

  const handleSavingDiagnoses = async ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => {
    await saveDiagnoses.mutateAsync({
      params: { patientId },
      data: { problems: diagnoses },
    })

    void problemsListQuery?.refetch()
  }

  return (
    <Box sx={{ width: `20rem` }}>
      {problemListModal && (
        <TModal closeModal={() => setProblemListModal(false)}>
          <OAddProblemListModal
            saveDiagnoses={handleSavingDiagnoses}
            closeModal={() => setProblemListModal(false)}
          />
        </TModal>
      )}

      <Accordion multiple value={[...sections]} onChange={setSection}>
        {isVisitHoldsV2Enabled && (
          <Accordion.Item value='internalNotes'>
            <Accordion.Control>
              <Text bold>Internal notes</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <InternalNotesContent
                active={sections.includes('internalNotes')}
                // The internal notes content can open itself when it has previous content
                open={() => setSection(prevSections => uniq([...prevSections, 'internalNotes']))}
              />
            </Accordion.Panel>
          </Accordion.Item>
        )}

        <Accordion.Item value='prescriptions'>
          <Accordion.Control>
            <Text bold>Recent prescriptions</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <ScrollAreaAutosize maxHeight='40vh' type='hover'>
              <PrescriptionsContent active={sections.includes('prescriptions')} />
            </ScrollAreaAutosize>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='labs'>
          <Accordion.Control>
            <Text bold>Latest 3 labs</Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <LabsContent active={sections.includes('labs')} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='measures'>
          <Accordion.Control>
            <Text bold>Measures</Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <MeasuresContent active={sections.includes('measures')} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='issues'>
          <Accordion.Control>
            <Group noWrap spacing='sm'>
              <Text bold>Issues</Text>
              {tasksQuery.isLoading || tasksQuery.isError ? (
                <Loader size='xs' />
              ) : (
                tasksCount > 0 && <CircleWithText variant='error'>{tasksCount}</CircleWithText>
              )}
            </Group>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <Skeleton h='5rem' visible={tasksQuery.isLoading || tasksQuery.isError}>
              <IssuesContent open={open} snoozed={snoozed} />
            </Skeleton>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='diagnoses'>
          <Accordion.Control>
            <Group noWrap position='apart'>
              <Text bold>Diagnoses</Text>
              {canAddDiagnoses && (
                <TertiaryButton
                  size='sm'
                  leftIcon={<PlusIcon color={colors => colors.actions[0]} />}
                  onClick={() => setProblemListModal(true)}
                >
                  Add
                </TertiaryButton>
              )}
            </Group>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <DiagnosisContent />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='pharmacy'>
          <Accordion.Control>
            <Text bold>Pharmacy</Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <PharmacyContent active={sections.includes('pharmacy')} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='address'>
          <Accordion.Control>
            <Text bold>Patient address</Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <Stack spacing='sm'>
              <AddressContent addressType='home' />
              <AddressContent addressType='shipping' />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='identification'>
          <Accordion.Control>
            <Text bold>Identification</Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <Stack spacing='sm'>
              <IdentificationContent side='front' />
              <IdentificationContent side='back' />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='insurance_prior_auths'>
          <Accordion.Control>
            <Text bold style={{ whiteSpace: 'nowrap' }}>
              Insurance & prior authorizations
            </Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <Stack spacing='sm'>
              <PrimaryInsuranceContent active={sections.includes('insurance_prior_auths')} />
              <SecondaryInsuranceContent active={sections.includes('insurance_prior_auths')} />
              <BillingNotesContent />
              <PriorAuthContent active={sections.includes('insurance_prior_auths')} />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='billing'>
          <Accordion.Control>
            <Text bold style={{ whiteSpace: 'nowrap' }}>
              Billing
            </Text>
          </Accordion.Control>
          <Accordion.Panel px='md'>
            <BillingContent active={sections.includes('billing')} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  )
}
