import { useForm } from '@mantine/form'
import { useDidUpdate, useToggle } from '@mantine/hooks'
import {
  Alert,
  BookmarkIcon,
  Grid,
  PhoneInput,
  Stack,
  TextInput,
  TitleThree,
  UnstyledButton,
  validateWith,
} from '@shared/components'
import {
  AuthReassignmentDuplicateInfo,
  DeepPartial,
  Patient,
  getOpheliaHttpError,
  getOpheliaHttpErrorAdditionalInfo,
} from '@shared/types'
import { phone } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import {
  isConditionallyRequired,
  isEmail,
  isPhone,
  isRequired,
} from '../../../utils/formValidation'
import { TimeZoneSelect } from '../../calendar/TimeZoneSelect'
import { usePatient } from '../PPatientContext'
import { ReassignPatientAuthModal } from '../ReassignPatientAuthModal'
import { EditableCol } from './EditableCol'
import { EditableSection } from './EditableSection'
import { useValidatePatientAuth } from './hooks'

const getAccountData = (patient?: Patient): DeepPartial<Patient> => {
  return {
    account: {
      timezone: patient?.account?.timezone ?? '',
    },
  }
}

const getAuthData = (patient?: Patient) => {
  return {
    phone: patient?.personalData?.phone ?? '',
    email: patient?.personalData?.email ?? '',
  }
}

export const PatientProfileAccountInformation = () => {
  const [authReassignmentDuplicateInfo, setAuthReassignmentDuplicateInfo] = useState<
    AuthReassignmentDuplicateInfo | undefined
  >()

  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data
  const queryClient = useQueryClient()

  const form = useForm<ReturnType<typeof getAccountData>>({
    initialValues: getAccountData(patient),
  })

  const initialValues = getAuthData(patient)
  const authForm = useForm<ReturnType<typeof getAuthData>>({
    initialValues,
    validate: {
      email: validateWith(isConditionallyRequired(Boolean(initialValues.email)), isEmail),
      phone: validateWith(isRequired, isPhone),
    },
  })

  const initializeForm = (patient?: Patient) => {
    authForm.setValues(getAuthData(patient))
    authForm.resetDirty()
    form.setValues(getAccountData(patient))
    form.resetDirty()
  }

  useDidUpdate(() => initializeForm(patient), [patient])

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))
  const updatePatientAuth = useMutation(emrApi.getMutation('PUT /patient/:patientId/auth'), {
    onSuccess: () => {
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /patient/:patientId/validate-auth', {
          params: { patientId },
        })[0],
      )
      void queryClient.invalidateQueries(
        emrApi.getQuery('GET /patient/:patientId/auth', {
          params: { patientId },
        })[0],
      )
    },
    onError: error => {
      setAuthReassignmentDuplicateInfo(
        getOpheliaHttpErrorAdditionalInfo(error) as AuthReassignmentDuplicateInfo,
      )
    },
  })

  const [isEditing, toggleIsEditing] = useToggle()

  const onSave = async () => {
    if (form.validate().hasErrors) {
      return
    }

    if (authForm.validate().hasErrors) {
      return
    }

    if (form.isDirty()) {
      await updatePatient.mutateAsync({
        params: {
          patientId,
        },
        data: form.values,
      })

      void patientQuery?.refetch()
    }

    // Update auth data after patient data because more likely to fail due to duplicates
    await updatePatientAuth.mutateAsync({
      params: {
        patientId,
      },
      data: authForm.values,
    })

    void patientQuery?.refetch()

    toggleIsEditing()
  }

  return (
    <EditableSection
      isLoading={patientQuery?.isLoading ?? false}
      isEditing={isEditing}
      onEdit={() => toggleIsEditing()}
      onSave={() => onSave()}
      onCancel={() => {
        toggleIsEditing()
        initializeForm(patient)
      }}
      isSaving={updatePatient.isLoading || updatePatientAuth.isLoading}
      title={<TitleThree>Account information</TitleThree>}
      error={
        updatePatientAuth.error || updatePatient.error
          ? getOpheliaHttpError(
              updatePatientAuth.error ?? updatePatient.error,
              'Error updating phone number and/or email.',
            )
          : undefined
      }
    >
      <Stack spacing='sm'>
        <AuthPhoneAlert />
        <AuthEmailAlert />
        <Grid>
          <EditableCol
            isEditing={isEditing}
            label='Phone'
            text={phone(authForm.values?.phone).formatted}
          >
            <PhoneInput {...authForm.getInputProps('phone')} />
          </EditableCol>

          <EditableCol isEditing={isEditing} label='Email' text={authForm.values?.email}>
            <TextInput placeholder='Email' {...authForm.getInputProps('email')} />
          </EditableCol>

          <EditableCol isEditing={isEditing} label='Time zone' text={form.values.account?.timezone}>
            <TimeZoneSelect {...form.getInputProps('account.timezone')} />
          </EditableCol>
        </Grid>
        <ReassignPatientAuthModal
          authReassignmentInfo={{
            newEmail: authForm.values?.email,
            newPhone: authForm.values?.phone,
            ...authReassignmentDuplicateInfo,
          }}
          opened={Boolean(authReassignmentDuplicateInfo)}
          onClose={() => setAuthReassignmentDuplicateInfo(undefined)}
          onSave={() => {
            void patientQuery?.refetch()
            void updatePatientAuth.reset()
            toggleIsEditing(false)
            setAuthReassignmentDuplicateInfo(undefined)
          }}
        />
      </Stack>
    </EditableSection>
  )
}

const AuthPhoneAlert = () => {
  const { data } = useValidatePatientAuth()

  if (!data?.phone || data.phone.isOwner) {
    return null
  }

  if (!data.phone.isOwner && data.phone.owner) {
    return (
      <UnstyledButton component='a' target='_blank' href={`/patients/${data.phone.owner}`}>
        <Alert variant='warning' icon={<BookmarkIcon />}>
          Phone number belongs to another patient
        </Alert>
      </UnstyledButton>
    )
  }

  return (
    <Alert variant='warning' icon={<BookmarkIcon />}>
      Phone number doesn&apos;t match login credentials
    </Alert>
  )
}

const AuthEmailAlert = () => {
  const { data } = useValidatePatientAuth()

  if (!data?.email || data.email.isOwner) {
    return null
  }

  if (!data.email.isOwner && data.email.owner) {
    return (
      <UnstyledButton component='a' target='_blank' href={`/patients/${data.email.owner}`}>
        <Alert variant='warning' icon={<BookmarkIcon />}>
          Email belongs to another patient
        </Alert>
      </UnstyledButton>
    )
  }

  return (
    <Alert variant='warning' icon={<BookmarkIcon />}>
      Email doesn&apos;t match login credentials
    </Alert>
  )
}
