import { useToggle } from '@mantine/hooks'
import { Stack, useMantineTheme } from '@shared/components'
import {
  AsqFormResponse,
  Barc10FormResponse,
  DecoratedObjectiveMeasureResponse,
  Gad7FormResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
  Phq9FormResponse,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { UseQueryResult } from 'react-query'
import { usePatient } from '../PPatientContext'
import ObjectiveMeasuresChart from './ObjectiveMeasuresChart'
import ObjectiveMeasuresChartDrawer from './ObjectiveMeasuresChartDrawer'
import { ObjectiveMeasuresChartHeader } from './ObjectiveMeasuresChartHeader'
import ToggleFormCollectionModal from './ToggleFormCollectionModal'

export const chartMetrics = {
  'PHQ-9': {
    goodResultThreshold: 4,
    badResultThreshold: 18,
    maxChartValue: 27,
    higherResultIsBetter: false,
  },
  'PHQ-8': {
    goodResultThreshold: 4,
    badResultThreshold: 18,
    maxChartValue: 24,
    higherResultIsBetter: false,
  },
  'GAD-7': {
    goodResultThreshold: 4,
    badResultThreshold: 15,
    maxChartValue: 21,
    higherResultIsBetter: false,
  },
  'BARC-10': {
    goodResultThreshold: 47,
    badResultThreshold: 23,
    maxChartValue: 60,
    higherResultIsBetter: true,
  },
  ASQ: {
    goodResultThreshold: 0,
    badResultThreshold: 1,
    maxChartValue: 5,
    higherResultIsBetter: false,
  },
}

type ResponseQueryType = UseQueryResult<
  DecoratedObjectiveMeasureResponse<
    Phq9FormResponse | Gad7FormResponse | Barc10FormResponse | AsqFormResponse
  >[],
  unknown
>

type ObjectiveMeasuresChartContainerProps = {
  title: ObjectiveMeasuresTitle
  responsesQuery: ResponseQueryType
  recentlySubmittedScore?: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse> | undefined
  updateStartAfter: ({ measure, date }: { measure: ObjectiveMeasuresTitle; date: string }) => void
}

const ObjectiveMeasuresChartContainer = ({
  title,
  responsesQuery,
  recentlySubmittedScore,
  updateStartAfter,
}: ObjectiveMeasuresChartContainerProps) => {
  const { patientQuery } = usePatient()
  const {
    other: { colors },
  } = useMantineTheme()

  const patient = patientQuery?.data

  const patientPreferredNameText = patient?.personalData.preferredName
    ? `(${patient?.personalData.preferredName}) `
    : ''
  const patientName = `${patient?.personalData.firstName} ${patientPreferredNameText}${patient?.personalData.lastName}`

  const [drawerContentIndex, setDrawerContentIndex] = useState<number | null>(null)
  const [displayFormCollectionModal, toggleFormCollectionModal] = useToggle()

  const [monthsOfData, setMonthsOfData] = useState<number | undefined>()

  const handleClickInHeader = (value: number | undefined) => {
    setMonthsOfData(value)
    const startAfterDate = value ? dayjs().subtract(value, 'month').toISOString() : ''
    updateStartAfter({
      measure: title,
      date: startAfterDate,
    })
  }

  const measuresResponseData = responsesQuery.data || []
  const dataWithRecentScore = recentlySubmittedScore
    ? [...measuresResponseData, recentlySubmittedScore]
    : measuresResponseData

  const { isLoading } = responsesQuery

  const { goodResultThreshold, badResultThreshold, maxChartValue, higherResultIsBetter } =
    chartMetrics[title]

  return (
    <>
      <ToggleFormCollectionModal
        title={title}
        opened={displayFormCollectionModal}
        onClose={() => {
          toggleFormCollectionModal(false)
        }}
        onSuccess={() => void patientQuery?.refetch()}
      />
      <ObjectiveMeasuresChartDrawer
        data={dataWithRecentScore}
        drawerContentIndex={drawerContentIndex}
        onClose={() => setDrawerContentIndex(null)}
        title={title}
        patientName={patientName}
      />
      <Stack
        sx={{
          backgroundColor: colors.background[1],
        }}
      >
        <ObjectiveMeasuresChartHeader
          title={title}
          data={dataWithRecentScore}
          monthsOfData={monthsOfData}
          handleClick={handleClickInHeader}
          isLoading={isLoading}
          openModal={() => toggleFormCollectionModal(true)}
        />
        <ObjectiveMeasuresChart
          data={dataWithRecentScore}
          isLoading={isLoading}
          goodResultThreshold={goodResultThreshold}
          badResultThreshold={badResultThreshold}
          maxChartValue={maxChartValue}
          higherResultIsBetter={higherResultIsBetter}
          numMonths={monthsOfData || 12}
          setDrawerContentIndex={setDrawerContentIndex}
          title={title}
        />
      </Stack>
    </>
  )
}

export default ObjectiveMeasuresChartContainer
