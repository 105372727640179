import { useToggle } from '@mantine/hooks'
import { Checkbox, Grid, Select, Stack, Text, TextInput, TooltipLabel } from '@shared/components'
import { Employee, Patient, StateAbbr, stateAbbreviations, states } from '@shared/types'
import { useEffect } from 'react'

type PatientAndClinicianLocationsProps = {
  editMode: boolean
  employeeAddress: {
    value: string
    defaultValue: string
    onChange: (value: string) => void
  }
  patientCity: {
    value: string
    onChange: (value: string) => void
  }
  patientState: {
    value: StateAbbr
    onChange: (value: StateAbbr) => void
  }
  verifiedPatientLocation: {
    value: string
    onChange: (value: boolean) => void
    checked?: boolean
  }
}

const convertStateToAbbreviation = (state: string) => {
  return states.find(s => s.state === state)?.abbr as StateAbbr
}

export const getDefaultLocationsData = ({
  patient,
  employee,
}: {
  patient?: Patient
  employee?: Employee
}) => {
  const defaultEmployeeAddress = employee
    ? `${employee?.workAddress?.address}${
        employee?.workAddress?.aptSuite ? `, ${employee?.workAddress?.aptSuite}` : ''
      }, ${employee?.workAddress?.city}, ${employee?.workAddress?.state} ${employee?.workAddress
        ?.zip}`
    : ''

  const defaultPatientCity = patient?.homeData?.city || ''
  const defaultPatientState = convertStateToAbbreviation(patient?.homeData?.state || '')

  return {
    employee_address: defaultEmployeeAddress,
    verified_patient_location: false,
    patient_city: defaultPatientCity,
    patient_state: defaultPatientState,
  }
}

export const PatientAndClinicianLocations = ({
  editMode,
  patientCity,
  patientState,
  employeeAddress,
  verifiedPatientLocation,
}: PatientAndClinicianLocationsProps) => {
  const [employeeIsAtWorkAddress, toggleEmployeeIsAtWorkAddress] = useToggle([true, false])

  // We want to update the form value for the clinician's location as they toggle the checkbox indicating that they are at their work address
  useEffect(() => {
    employeeAddress.onChange(employeeIsAtWorkAddress ? employeeAddress.defaultValue : '')
  }, [employeeIsAtWorkAddress])

  const disabled = !editMode

  const verified = Boolean(verifiedPatientLocation.value)

  return (
    <Stack pb='md'>
      <Stack spacing='sm'>
        <Text>Location</Text>
        <Text size='xs' color={colors => colors.text[1]}>
          Verified clinician and patient locations at the time of visit
        </Text>
        <Checkbox
          checked={employeeIsAtWorkAddress}
          onChange={() => toggleEmployeeIsAtWorkAddress()}
          label='I am at my work address'
          disabled={disabled}
        />
        {!employeeIsAtWorkAddress && (
          <TextInput
            {...employeeAddress}
            value={employeeAddress.value}
            onChange={employeeAddress.onChange}
            placeholder='Ex: 2 Main st, New York, NY 100001'
            label={
              <TooltipLabel
                sx={({ other: { colors } }) => ({ backgroundColor: colors.background[6] })}
                label='Your address is protected and not shared with external sources including patients'
              >
                Your current address
              </TooltipLabel>
            }
          />
        )}
      </Stack>
      <Stack spacing='sm'>
        <Text>Patient&apos;s city and state</Text>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              {...patientCity}
              value={patientCity.value}
              onChange={patientCity.onChange}
              placeholder='City'
              disabled={disabled || verified}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              {...patientState}
              value={patientState.value}
              onChange={(stateAbbr: StateAbbr) => patientState.onChange(stateAbbr)}
              searchable
              placeholder='State'
              data={stateAbbreviations}
              disabled={disabled || verified}
            />
          </Grid.Col>
        </Grid>
      </Stack>
      <Checkbox
        // Mantine allows us to specify that verifiedPatientLocation has a `type` of `checkbox`, but the validation doesn't work unless we handle this way
        {...verifiedPatientLocation}
        checked={verified}
        onChange={event => verifiedPatientLocation.onChange(event.target.checked)}
        label="Verified patient's location"
        disabled={disabled}
      />
    </Stack>
  )
}
