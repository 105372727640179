import { Checkbox, Radio, RadioGroup, Stack, TextInput } from '@shared/components'
import { alternativeProviderStatusMapping, hlocMedicationMapping } from '@shared/types'
import { formLabel } from '@shared/utils'
import { DischargeNoteForm } from './AddDischargeNoteDrawer'

const HLOCMedicationCheckboxes = ({ form }: { form: DischargeNoteForm }) => {
  return (
    <Stack ml='md' spacing='sm'>
      <Checkbox
        label={hlocMedicationMapping.transferring_to_outpatient_or_mat_buprenorphine}
        key='transferring_to_outpatient_or_mat_buprenorphine'
        {...form.getInputProps('transferring_to_outpatient_or_mat_buprenorphine')}
      />
      <Checkbox
        label={hlocMedicationMapping.transferring_to_outpatient_or_mat_methadone}
        key='transferring_to_outpatient_or_mat_methadone'
        {...form.getInputProps('transferring_to_outpatient_or_mat_methadone')}
      />
      <Checkbox
        label={hlocMedicationMapping.transferring_to_outpatient_or_mat_xr_naltrexone}
        key='transferring_to_outpatient_or_mat_xr_naltrexone'
        {...form.getInputProps('transferring_to_outpatient_or_mat_xr_naltrexone')}
      />
    </Stack>
  )
}

export const TransferStatusSection = ({
  form,
  optional,
}: {
  form: DischargeNoteForm
  optional: boolean
}) => {
  return (
    <Stack>
      <RadioGroup
        label={formLabel('Transfer status', optional)}
        spacing='sm'
        {...form.getInputProps('alternative_provider_status')}
      >
        <Radio
          label={alternativeProviderStatusMapping.alternative_provider_connected}
          value='alternative_provider_connected'
        />
        {form.values.alternative_provider_status === 'alternative_provider_connected' && (
          <HLOCMedicationCheckboxes form={form} />
        )}
        <Radio
          label='Patient is planning to connect to an alternative provider to continue treatment'
          value='alternative_provider_planning'
        />
        {form.values.alternative_provider_status === 'alternative_provider_planning' && (
          <HLOCMedicationCheckboxes form={form} />
        )}
        <Radio
          label='Patient is not planning to continue treatment with an alternative provider'
          value='alternative_provider_not_planning'
        />
        <Radio label='Other' value='other' />
      </RadioGroup>
      {form.values.alternative_provider_status === 'other' && (
        <TextInput
          placeholder='Additional details...'
          {...form.getInputProps('alternative_provider_other_explanation')}
        />
      )}
    </Stack>
  )
}
