import { Group, Pill, Text } from '@shared/components'
import { forwardRef } from 'react'

type PrescriptionStrengthItemProps = React.ComponentPropsWithoutRef<'div'> & {
  label: string
  priorAuthorizationRequired: boolean
}

export const PrescriptionStrengthItemComponent = forwardRef<
  HTMLDivElement,
  PrescriptionStrengthItemProps
>(({ label, priorAuthorizationRequired, ...others }: PrescriptionStrengthItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap position='apart'>
      <Text>{label}</Text>
      {priorAuthorizationRequired && (
        <Pill status='warning' variant='filled'>
          Requires PA
        </Pill>
      )}
    </Group>
  </div>
))
PrescriptionStrengthItemComponent.displayName = 'PrescriptionStrengthItemComponent'
