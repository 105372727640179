import { Text, TitleTwo } from '@shared/components'
import { Day } from '@shared/types'
import { format } from 'date-fns'
import { WeekDateTuple } from './OnCallScheduleContent'

type ScheduleTableHeaderProps = {
  dates: WeekDateTuple
}

export const daysOfTheWeek: { [key: number]: { day: Day; abbreviation: string } } = {
  0: {
    day: 'monday',
    abbreviation: 'MON',
  },
  1: {
    day: 'tuesday',
    abbreviation: 'TUE',
  },
  2: {
    day: 'wednesday',
    abbreviation: 'WED',
  },
  3: {
    day: 'thursday',
    abbreviation: 'THU',
  },
  4: {
    day: 'friday',
    abbreviation: 'FRI',
  },
  5: {
    day: 'saturday',
    abbreviation: 'SAT',
  },
  6: {
    day: 'sunday',
    abbreviation: 'SUN',
  },
}

const ScheduleTableHeader = ({ dates }: ScheduleTableHeaderProps) => {
  return (
    <thead>
      <tr>
        <th className='text-center'>
          <Text bold>State</Text>
        </th>
        {dates.map((date, index) => {
          const dayOfMonth = format(date, 'd')
          return (
            <th key={dayOfMonth} className='text-center'>
              <Text styles={{ textAlign: 'center' }}>{daysOfTheWeek[index]?.abbreviation}</Text>
              <TitleTwo>{dayOfMonth}</TitleTwo>
            </th>
          )
        })}
        {/* Empty table header. Menu button to edit state data will populate underneath */}
        <th></th>
      </tr>
    </thead>
  )
}

export default ScheduleTableHeader
