import { PrescriptionFavorite, PrescriptionFavoriteModel } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class PrescriptionFavoritesApi extends BaseApi {
  add = (data: PrescriptionFavoriteModel) => {
    return this.json<Res>({
      method: 'POST',
      url: `/prescriptionFavorites`,
      data,
    })
  }

  get = ({
    employeeId,
    type,
  }: {
    employeeId?: string
    type: PrescriptionFavoriteModel['type']
  }) => {
    return this.json<PrescriptionFavorite[]>({
      method: 'GET',
      url: `/prescriptionFavorites?type=${type}&employeeId=${employeeId}`,
    })
  }

  delete = (id: string) => {
    return this.json<Res>({
      method: 'DELETE',
      url: `/prescriptionFavorites/${id}`,
    })
  }

  edit = ({ id, data }: { id: string; data: PrescriptionFavoriteModel }) => {
    return this.json<PrescriptionFavorite>({
      method: 'PUT',
      url: `/prescriptionFavorites/${id}`,
      data,
    })
  }
}
