import {
  ArrowLeftCircleIcon,
  ChevronRightIcon,
  Divider,
  ExternalLinkText,
  Group,
  MinusCircleIcon,
  Modal,
  PlusCircleIcon,
  PrimaryButton,
  Radio,
  RadioGroup,
  SecondaryButton,
  Stack,
  Switch,
  TertiaryButton,
  Text,
} from '@shared/components'
import {
  AppointmentTypeString,
  DeepPartial,
  Employee,
  LevelOfCareStatus,
  Patient,
  PatientModel,
  PatientStatus,
} from '@shared/types'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation, useQuery } from 'react-query'
import { appointmentsApi, cocmRegistryApi, emrApi } from '../../../api'
import { date, number } from '../../../components/molecules/MDictionary'
import ODSInput from '../../../components/ods/ODSInput'
import ODSProgressTracker from '../../../components/ods/ODSProgressTracker'
import ODSTextArea from '../../../components/ods/ODSTextArea'
import { isPatientRecommendedForCaseReview } from '../../../utils/cocmUtils'
import { useEmrMutation, useFlags } from '../../../utils/hooks'
import { CaseReviewRecommendationAlert } from '../../registry/caseReview/CaseReviewRecommendationAlert'

export type MCloseNoteModalProps = {
  patientID: string
  visitID: string
  type: AppointmentTypeString
  open: boolean
  closeModal: () => void
  patient: Patient
  currentUser: Employee
  signNote: () => Promise<void>
  openIneligibleNoteModal: () => void
  medicationPrescribed: boolean
  isTobaccoUseDiagnosisPresent: boolean
}

type FormData = {
  clinicianTime?: string
  patientTime?: string
  inductionDate?: string
  paymentDelay?: string
  smokingCessationServiceTime?: number
  cocmCaseReviewNote?: string
}

const MCloseNoteModal = ({
  patientID,
  visitID,
  type,
  open,
  closeModal,
  patient,
  currentUser,
  signNote,
  openIneligibleNoteModal,
  medicationPrescribed,
  isTobaccoUseDiagnosisPresent,
}: MCloseNoteModalProps) => {
  const { revampedCaseReviewNotes, isCarePathwayModalEnabled } = useFlags()
  const [step, setStep] = useState(0)
  const [patientStatus, setPatientStatus] = useState<PatientStatus>()
  const [gad7, setGad7] = useState(true)
  const [phq9, setPhq9] = useState(true)
  const [addToCaseReview, setAddToCaseReview] = useState(false)
  const [levelOfCareStatus, setLevelOfCareStatus] = useState<LevelOfCareStatus>()
  const [error, setError] = useState(false)
  const [smokingCessationConfirmation, setSmokingCessationConfirmation] = useState(false)
  const [formData, setFormData] = useState<FormData>({})
  const [expandDetails, setExpandDetails] = useState(false)

  const VALID_NOTE_TYPES: AppointmentTypeString[] = [
    'Initial Visit',
    'Follow-Up Visit',
    'Wellness Visit',
    'Initial Wellness Visit',
    'Check-In Call',
    'UDS Visit',
  ]

  const onBack = () => {
    if (step === 2) {
      setSmokingCessationConfirmation(isTobaccoUseDiagnosisPresent)
    }
    setStep(step - 1)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  useEffect(() => {
    setSmokingCessationConfirmation(isTobaccoUseDiagnosisPresent)
  }, [open])

  useEffect(() => {
    setPhq9(patient?.formsToCollect?.phq9 ?? true)
    setGad7(patient?.formsToCollect?.gad7 ?? true)
  }, [patient?.formsToCollect])

  useEffect(() => {
    const skipPrescriptionWarningModal =
      medicationPrescribed || !['Initial Visit', 'Follow-Up Visit'].includes(type)
    if (skipPrescriptionWarningModal && step === 0) {
      setStep(1)
    }

    if (step === 2 && type === 'Initial Visit' && patientStatus !== 'in treatment') {
      setStep(1)
    }
  }, [medicationPrescribed, step, type, patientStatus])

  const generateBody = () => {
    const body: DeepPartial<PatientModel> = {
      formsToCollect: {
        gad7,
        phq9,
      },
    }

    if (formData.paymentDelay) {
      body.subscription = {
        metadata: {
          paymentDelay: formData.paymentDelay,
          updatedAt: new Date().toISOString(),
        },
      }
    }

    if (type === 'Initial Visit') {
      if (patientStatus) {
        body.statuses = { patient: patientStatus }

        if (patientStatus === 'in treatment') {
          body.statuses.levelOfCare = 'weekly'
        }
      }

      if (formData.inductionDate) {
        body.personalData = { startDate: formData.inductionDate }
      }
    } else if (levelOfCareStatus) {
      body.statuses = { levelOfCare: levelOfCareStatus }
    }

    return body
  }

  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))
  const updateAppointment = useMutation(appointmentsApi.updateByVisitId)
  const updateNeedsReview = useMutation(cocmRegistryApi.update)

  const [cocmQueryKey, cocmQueryFunction] = emrApi.getQuery(
    'GET /cocmRegistry/patient/:patientId',
    { params: { patientId: patientID ?? '' } },
  )

  const patientCocmRecord = useQuery(cocmQueryKey, cocmQueryFunction)

  const createCaseReviewNote = useEmrMutation('POST /caseReviewNote/patient/:patientId')

  const isCurrentUserPatientsBhcm = patientCocmRecord?.data?.employeeId === currentUser.oid

  const showCaseReviewAlert =
    revampedCaseReviewNotes &&
    isCurrentUserPatientsBhcm &&
    patientCocmRecord?.data &&
    isPatientRecommendedForCaseReview(patientCocmRecord.data)

  const completeSignNote = async () => {
    try {
      if (formData.clinicianTime || formData.patientTime || formData.smokingCessationServiceTime) {
        await updateAppointment.mutateAsync({
          visitId: visitID,
          patientId: patientID,
          data: {
            clinicianTime: Number(formData.clinicianTime),
            patientTime: Number(formData.patientTime),
            smokingCessationServiceTime: Number(formData.smokingCessationServiceTime),
          },
        })
      }

      if (isCurrentUserPatientsBhcm) {
        if (addToCaseReview && patientCocmRecord?.data?.oid) {
          await updateNeedsReview.mutateAsync({
            patientId: patientID,
            data: { needsReview: true },
          })
          await createCaseReviewNote.mutateAsync({
            params: { patientId: patientID },
            data: {
              visitId: Number(visitID),
              content: {
                preWorkNotes: formData.cocmCaseReviewNote,
              },
            },
          })
        }
      }

      /**
       * NOTE: This mutation updates the patient's status (among other things).
       * If this mutation were to happen before the sign note mutation, then the
       * care pathway that gets generated on the backend would not have the correct
       * patient status. Be careful when changing the order of these mutations.
       */
      await updatePatient.mutateAsync({
        params: {
          patientId: patientID,
        },
        data: generateBody(),
      })
    } catch (error) {
      setError(true)
      return
    }

    await signNote()
    closeModal()

    if (patientStatus === 'ineligible') {
      openIneligibleNoteModal()
    }
  }

  const totalSteps = () => {
    if (type === 'Initial Visit') {
      return 5
    }

    if (type === 'Follow-Up Visit') {
      if (isCarePathwayModalEnabled) {
        return 3
      }

      return 4
    }

    if (['Check-In Call', 'UDS Visit'].includes(type)) {
      return 3
    }

    return 0
  }

  const mbcToggleValidation = useMemo(() => {
    const isPatientInCocm = Boolean(patientCocmRecord.data)
    if (isPatientInCocm && patient.homeData?.state === 'New York') {
      const isValid = phq9 || gad7
      if (isValid) {
        return { isValid: true as const }
      }
      return {
        isValid: false as const,
        message:
          'New York patients enrolled in CoCM require the collection of either PHQ-9 or GAD-7 responses. Please select at least one.',
      }
    }
    return { isValid: true as const }
  }, [patientCocmRecord.data, patient.homeData?.state, gad7, phq9])

  if (!VALID_NOTE_TYPES.includes(type)) {
    return (
      <Modal
        opened={open}
        onClose={closeModal}
        title='Invalid note type'
        footer={
          <Group position='right'>
            <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
          </Group>
        }
      >
        <Group spacing='xs'>
          <Text>Note cannot be locked because </Text>
          <Text bold>{type}</Text>
          <Text>type is invalid.</Text>
        </Group>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal
        opened={open}
        onClose={closeModal}
        title='Sign and lock note'
        footer={
          <Group position='right'>
            <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
          </Group>
        }
      >
        <Text>
          Unfortunately this note could not be signed. Please refresh the page and retry, and if
          that doesn&apos;t fix the issue, please send a message to the{' '}
          <a href='slack://channel?team=TRN06CLEM&id=C01083KPYJX'>#clinical-product-support</a>{' '}
          channel in Slack.
        </Text>
      </Modal>
    )
  }

  return (
    <Modal
      opened={open}
      onClose={closeModal}
      title={
        <>
          {step > 1 && (
            <TertiaryButton onClick={onBack} size='sm' leftIcon={<ArrowLeftCircleIcon styled />} />
          )}
          Sign and lock note
        </>
      }
      footer={
        step === 0 && (
          <Group position='right'>
            <SecondaryButton onClick={closeModal}>No, go back</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setStep(1)
              }}
            >
              Yes, lock without prescribing
            </PrimaryButton>
          </Group>
        )
      }
    >
      <>
        {step === 0 && (
          <Text>
            You are about to sign and lock this note without prescribing any medication. Are you
            sure you want to proceed?
          </Text>
        )}
        <Group position='center' pb='md'>
          {step > 0 && <ODSProgressTracker current={step} total={totalSteps()} />}
        </Group>

        {step === 1 &&
          type === 'Follow-Up Visit' &&
          // If the care pathway modal is enabled, then the level of care is handled there.
          !isCarePathwayModalEnabled && (
            <Stack spacing='md'>
              <Text>What is the patient&apos;s level of care?</Text>
              <Stack spacing='sm'>
                <SecondaryButton
                  onClick={() => {
                    setLevelOfCareStatus('weekly')
                    setStep(step + 1)
                  }}
                  fullWidth
                >
                  Weekly
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    setLevelOfCareStatus('biweekly')
                    setStep(step + 1)
                  }}
                  fullWidth
                >
                  Bi-weekly
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    setLevelOfCareStatus('monthly')
                    setStep(step + 1)
                  }}
                  fullWidth
                >
                  Monthly
                </SecondaryButton>
              </Stack>
            </Stack>
          )}

        {step === 1 && type === 'Initial Visit' && (
          <Stack spacing='md'>
            <Text>Is the patient eligible for treatment with Ophelia?</Text>
            <Stack spacing='sm'>
              <SecondaryButton
                onClick={() => {
                  setPatientStatus('in treatment')
                  setStep(step + 1)
                }}
                fullWidth
              >
                Eligible
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  setPatientStatus('ineligible')
                  setStep(step + 2)
                }}
                fullWidth
              >
                Ineligible
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  setPatientStatus('needs review')
                  setStep(step + 2)
                }}
                fullWidth
              >
                Needs Review
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  setPatientStatus('not interested')
                  setStep(step + 2)
                }}
                fullWidth
              >
                Not interested
              </SecondaryButton>
            </Stack>
          </Stack>
        )}
        {step === 2 && type === 'Initial Visit' && patientStatus === 'in treatment' && (
          <Stack spacing='md'>
            <Stack spacing='sm'>
              <Text>
                How many days after the patient&apos;s intake visit should their subscription begin?
                (optional)
              </Text>
              <ODSInput
                placeholder='0-7'
                defaultValue={patient?.subscription?.metadata?.paymentDelay ?? undefined}
                {...register('paymentDelay', {
                  pattern: {
                    value: /^[0-7]$/,
                    message: 'This field must be a number between 0-7',
                  },
                })}
                error={Boolean(errors.paymentDelay)}
              />
            </Stack>
            <Stack spacing='sm'>
              <Text> Set patient&apos;s induction date (optional)</Text>
              <ODSInput
                placeholder='MM/DD/YYYY'
                {...register('inductionDate', {
                  pattern: {
                    value: /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/,
                    message: date,
                  },
                })}
                error={Boolean(errors.inductionDate)}
              />
            </Stack>
            <Group position='right'>
              <PrimaryButton
                onClick={handleSubmit((data: FormData) => {
                  setFormData(prevState => ({
                    ...prevState,
                    inductionDate: data.inductionDate,
                    paymentDelay: data.paymentDelay,
                  }))
                  setStep(step + 1)
                })}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </PrimaryButton>
            </Group>
          </Stack>
        )}
        {((step === 1 &&
          ['Wellness Visit', 'UDS Visit', 'Check-In Call', 'Initial Wellness Visit'].includes(
            type,
          )) ||
          (step === 1 && type === 'Follow-Up Visit' && isCarePathwayModalEnabled) ||
          (step === 2 && type === 'Follow-Up Visit' && !isCarePathwayModalEnabled) ||
          (step === 3 && type === 'Initial Visit')) && (
          <Stack>
            <Stack spacing='md'>
              <Text>How much time did you spend on this visit?</Text>
              <Stack spacing='sm'>
                <Text>Patient-facing (Zoom or Aircall)</Text>
                <ODSInput
                  placeholder='Total minutes'
                  {...register('patientTime', {
                    required: true,
                    pattern: {
                      value: /^\d+$/,
                      message: number,
                    },
                  })}
                  error={Boolean(errors.patientTime)}
                />
              </Stack>
              <Stack spacing='sm'>
                <Text>Non patient-facing activities related to this visit</Text>
                <ODSInput
                  placeholder='Total minutes'
                  {...register('clinicianTime', {
                    required: true,
                    pattern: {
                      value: /^\d+$/,
                      message: number,
                    },
                  })}
                  error={Boolean(errors.clinicianTime)}
                />
              </Stack>
            </Stack>
            <Stack>
              <TertiaryButton
                leftIcon={expandDetails ? <MinusCircleIcon styled /> : <PlusCircleIcon styled />}
                onClick={() => setExpandDetails(!expandDetails)}
              >
                What counts as a non patient-facing activity?
              </TertiaryButton>
              {expandDetails && (
                <Stack>
                  <ul className='text-xs text-left list-disc list-outside ml-4'>
                    <li className='pt-1'>
                      Preparing to see the patient (e.g. review of tests, reivew of past visit
                      notes, checking PDMP)
                    </li>
                    <li className='pt-1'>Obtaining and/or reviewing seprately obtained history</li>
                    <li className='pt-1'>Ordering medications, tests, or procedures</li>
                    <li className='pt-1'>
                      Referring and communicating with other healthcare professionals (when not
                      separately reported)
                    </li>
                    <li className='pt-1'>
                      Documenting clinical information in the electronic or other health record
                    </li>
                    <li className='pt-1'>
                      Independently interpreting results (not separately reported) and communicating
                      results to the patient/family/caregiver
                    </li>
                    <li className='pt-1'>Working with care coordination</li>
                  </ul>
                  <Group spacing='xs'>
                    <Text size='xs'>Still have questions? Reach out to </Text>
                    <ExternalLinkText href='mailto:franco@ophelia.com'>
                      <TertiaryButton size='sm'>franco@ophelia.com</TertiaryButton>
                    </ExternalLinkText>
                  </Group>
                </Stack>
              )}
            </Stack>
            {isTobaccoUseDiagnosisPresent &&
              ['Initial Visit', 'Follow-Up Visit'].includes(type) && (
                <Stack spacing='md'>
                  <Divider />
                  <Stack spacing='sm'>
                    <Text> Did you address nicotine cessation during this visit?</Text>
                    <p>
                      <label>
                        <input
                          onChange={() => setSmokingCessationConfirmation(true)}
                          type='radio'
                          name='smokingAddressed'
                          value='true'
                          className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                          defaultChecked
                        />
                        <span className='text-xs pl-2'>Yes</span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <input
                          onChange={() => setSmokingCessationConfirmation(false)}
                          type='radio'
                          name='smokingAddressed'
                          value='false'
                          className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300'
                        />
                        <span className='text-xs pl-2'>No</span>
                      </label>
                    </p>
                  </Stack>
                  {smokingCessationConfirmation && (
                    <Stack spacing='sm'>
                      <Text>
                        Time spent addressing nicotine cessation separate from addressing opioid use
                      </Text>
                      <ODSInput
                        placeholder='Total minutes'
                        {...register('smokingCessationServiceTime', {
                          required: true,
                          pattern: {
                            value: /^\d+$/,
                            message: number,
                          },
                        })}
                      />
                    </Stack>
                  )}
                </Stack>
              )}
            <Group position='right'>
              <Text size='xs'>
                These estimates are true and accurate to the best of my knowledge. I understand that
                I am being asked for these estimates for insurance billing purposes.
              </Text>
              <PrimaryButton
                onClick={handleSubmit((data: FormData) => {
                  setFormData(prevState => ({
                    ...prevState,
                    clinicianTime: data.clinicianTime,
                    patientTime: data.patientTime,
                    smokingCessationServiceTime: data.smokingCessationServiceTime,
                  }))
                  setStep(step + 1)
                })}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </PrimaryButton>
            </Group>
          </Stack>
        )}
        {((step === 2 &&
          ['Wellness Visit', 'Initial Wellness Visit', 'Check-In Call', 'UDS Visit'].includes(
            type,
          )) ||
          (step === 2 && type === 'Follow-Up Visit' && isCarePathwayModalEnabled) ||
          (step === 3 && type === 'Follow-Up Visit' && !isCarePathwayModalEnabled) ||
          (step === 4 && type === 'Initial Visit')) && (
          <Stack>
            {isCurrentUserPatientsBhcm && (
              <Stack spacing='md'>
                {showCaseReviewAlert && (
                  <CaseReviewRecommendationAlert
                    patientFirstName={patient.personalData.firstName}
                    location='Sign and Lock'
                  />
                )}
                <Text>Would you like to discuss this patient during your next case review?</Text>
                <RadioGroup
                  sx={{ width: '100%' }}
                  onChange={value => setAddToCaseReview(value === 'yes')}
                  spacing='sm'
                  value={addToCaseReview ? 'yes' : 'no'}
                >
                  <Radio name='caseReview' value='yes' label='Yes, add patient to case review' />
                  <Radio
                    name='caseReview'
                    value='no'
                    label={`No, don't add patient to case review`}
                  />
                </RadioGroup>
                {addToCaseReview && !revampedCaseReviewNotes && (
                  <Stack spacing='sm'>
                    <Text>
                      What would you like to discuss about this patient during case review?
                    </Text>
                    <ODSTextArea
                      {...register('cocmCaseReviewNote')}
                      placeholder='Type pre-work notes here...'
                      minRows={4}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            <Stack>
              <Text>Which measures would you like to continue collecting from this patient?</Text>
              <Group position='left'>
                <Switch
                  size='sm'
                  checked={phq9}
                  onChange={() => {
                    setPhq9(!phq9)
                  }}
                />
                <Text>Depression (PHQ-9)</Text>
              </Group>
              <Group position='left'>
                <Switch
                  size='sm'
                  checked={gad7}
                  onChange={() => {
                    setGad7(!gad7)
                  }}
                />
                <Text>Anxiety (GAD-7)</Text>
              </Group>
              {mbcToggleValidation.message && (
                <Text className='text-daybreak-error0 font-medium text-sm'>
                  {mbcToggleValidation.message}
                </Text>
              )}
            </Stack>
            <Group position='right'>
              <PrimaryButton
                onClick={handleSubmit((data: FormData) => {
                  setFormData(prevState => ({
                    ...prevState,
                    cocmCaseReviewNote: data.cocmCaseReviewNote,
                  }))
                  setStep(step + 1)
                })}
                rightIcon={<ChevronRightIcon />}
                disabled={!mbcToggleValidation.isValid}
              >
                Next
              </PrimaryButton>
            </Group>
          </Stack>
        )}
        {((step === 3 &&
          ['Wellness Visit', 'Initial Wellness Visit', 'Check-In Call', 'UDS Visit'].includes(
            type,
          )) ||
          (step === 3 && type === 'Follow-Up Visit' && isCarePathwayModalEnabled) ||
          (step === 4 && type === 'Follow-Up Visit' && !isCarePathwayModalEnabled) ||
          (step === 5 && type === 'Initial Visit')) && (
          <Stack>
            <Text>
              Once you sign and lock this note, you will no longer be able to modify it. Are you
              sure you want to proceed?
            </Text>
            <Group position='left' spacing='sm'>
              <Text>Signature:</Text>
              <Text bold> {currentUser.name}</Text>
            </Group>
            <Group position='right'>
              <PrimaryButton onClick={completeSignNote}>Yes, sign and lock note</PrimaryButton>
            </Group>
          </Stack>
        )}
      </>
    </Modal>
  )
}

export default MCloseNoteModal
