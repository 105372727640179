import { Alert, ShieldIcon, Text, useMantineTheme } from '@shared/components'
import { Patient } from '@shared/types'

export type MentalHealthEvalActionAlertProps = {
  patient: Patient
  patientInCocm: boolean
  onClose: () => void
}

export const MentalHealthEvalActionAlert = ({
  patient,
  patientInCocm,
  onClose,
}: MentalHealthEvalActionAlertProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  return (
    <Alert
      variant='secondary'
      icon={<ShieldIcon />}
      title={`${patient?.personalData.firstName} pending mental health eval`}
      withCloseButton
      onClose={onClose}
    >
      {patientInCocm && (
        <Text color={colors.text[2]} size='xs'>
          {patient.personalData.firstName} is almost eligible for CoCM billing.
        </Text>
      )}
    </Alert>
  )
}
