import { Colors } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import range from 'lodash/range'
import { ChartResponse, drawerChartContext } from './ObjectiveMeasuresChartDrawer'

export const determineFillAndStrokeColors = ({
  value,
  goodResultThreshold,
  badResultThreshold,
  higherResultIsBetter,
  colors,
}: {
  value: number
  goodResultThreshold: number
  badResultThreshold: number
  higherResultIsBetter: boolean
  colors: Colors
}) => {
  if (
    (higherResultIsBetter && value >= goodResultThreshold) ||
    (!higherResultIsBetter && value <= goodResultThreshold)
  ) {
    return {
      fillColor: colors.success[0],
      strokeColor: colors.success[1],
    }
  } else if (
    (higherResultIsBetter && value <= badResultThreshold) ||
    (!higherResultIsBetter && value >= badResultThreshold)
  ) {
    return {
      fillColor: colors.error[0],
      strokeColor: colors.error[1],
    }
  }
  return {
    fillColor: colors.warning[0],
    strokeColor: colors.warning[1],
  }
}

export const formatDate = (date: string) => {
  return dayjs(date).format("MMM 'YY").toUpperCase()
}

export const generateXAxisDates = (numMonths: number) => {
  return range(0, numMonths + 1).map(i => {
    return dayjs()
      .subtract(numMonths - i, 'month')
      .valueOf()
  })
}

export const generateYAxisValues = (maxChartValue: number, mod: number) => {
  return range(0, maxChartValue)
    .map(i => {
      return i + 1
    })
    .filter(i => i % mod === 0)
}

export const formatData = (
  data: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[] | ChartResponse[],
) => {
  // NOTE: to get the dates to display properly along the x-axis, we need to convert the dates to unix timestamps
  return data
    ? data
        .map(d => {
          return {
            ...d,
            unixTimestamp: dayjs(d.completedAt).valueOf(),
          }
        })
        .sort((a, b) => a.unixTimestamp - b.unixTimestamp)
    : []
}

export const didPatientIndicateSuicidality = ({
  response,
  title,
}: {
  response: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
  title: ObjectiveMeasuresTitle
}) => {
  const formsWithSuicidalityQuestion: ObjectiveMeasuresTitle[] = ['PHQ-9']
  if (!formsWithSuicidalityQuestion.includes(title) || !response.data) {
    return false
  }

  const phq9SuicidalityIndex = response.data.length - 1

  const suicidalityScore = response.data[phq9SuicidalityIndex]?.score ?? 0

  return suicidalityScore >= 1
}

type GenerateDrawerComparisonDataProps = {
  data: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]
  drawerContentIndex: number
  title: ObjectiveMeasuresTitle
}

export const generateDrawerComparisonData = ({
  data,
  drawerContentIndex,
  title,
}: GenerateDrawerComparisonDataProps) => {
  const selectedData = data[drawerContentIndex]!
  const baselineData = minBy(data, 'completedAt')
  const displayBaselineData = dayjs(selectedData.completedAt).isAfter(baselineData?.completedAt)
  const dataBeforeSelectedData = data.filter(d =>
    dayjs(d.completedAt).isBefore(selectedData.completedAt),
  )
  const secondaryComparisonData = dataBeforeSelectedData
    ? maxBy(dataBeforeSelectedData, 'completedAt')
    : undefined
  const displaySecondaryComparisonData = secondaryComparisonData
    ? dayjs(secondaryComparisonData?.completedAt).isBetween(
        dayjs(baselineData?.completedAt),
        dayjs(selectedData.completedAt),
      )
    : undefined
  const { promptStrings } = drawerChartContext[title]

  return {
    selectedData,
    baselineData,
    displayBaselineData,
    secondaryComparisonData,
    displaySecondaryComparisonData,
    promptStrings,
  }
}
