import { Group, Modal, PrimaryButton, SecondaryButton, Text } from '@shared/components'
import { Patient } from '@shared/types'
import { useMutation } from 'react-query'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'

type SendPatientReferralLinkModalProps = {
  closeModal: () => void
  patient: Patient
}

const SendPatientReferralLinkModal = ({
  closeModal,
  patient,
}: SendPatientReferralLinkModalProps) => {
  const patientFirstName = patient.personalData.preferredName || patient.personalData.firstName
  const { phone } = patient.personalData

  const createFriendReferral = useMutation(emrApi.getMutation('POST /referral/friend-or-family'))

  const sendPatientMessage = useMutation(
    emrApi.getMutation('POST /patient/:patientId/twilio/messages/send'),
  )

  const handleSendMessage = async () => {
    const { referralLink } = await createFriendReferral.mutateAsync({
      data: {
        referrerPatientId: patient.oid,
        anonymousId: analytics.getAnonymousId(),
      },
    })

    await sendPatientMessage.mutateAsync({
      params: {
        patientId: patient.oid,
      },
      data: {
        phone,
        message: referralLink,
      },
    })

    await sendPatientMessage.mutateAsync({
      params: {
        patientId: patient.oid,
      },
      data: {
        phone,
        message: 'Your clinician generated 👆this link👆 for you to forward along to your friend.',
      },
    })

    closeModal()
  }

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Send patient referral link'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>No, go back</SecondaryButton>
          <PrimaryButton loading={sendPatientMessage.isLoading} onClick={handleSendMessage}>
            Yes, text link
          </PrimaryButton>
        </Group>
      }
    >
      <Text>
        You&apos;re about to text {patientFirstName} a link that they can share with a friend who
        might benefit from starting treatment with Ophelia. Are you sure you want to proceed?
      </Text>
    </Modal>
  )
}

export default SendPatientReferralLinkModal
