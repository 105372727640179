import { Textarea, TextareaProps } from '@shared/components'
import { LabeledData } from './LabeledData'

export type CardTextareaProps = TextareaProps & { editable: boolean }
export const CardTextarea = ({ editable, ...props }: CardTextareaProps) => {
  if (editable) {
    return <Textarea {...props} minRows={2} autosize />
  }

  return <LabeledData label={props.label} data={String(props.value)} />
}
