import {
  Card,
  Group,
  InfoIcon,
  Pill,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
} from '@shared/components'
import { formatDollarAmount } from '@shared/utils'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'

export const BalanceTableSection = () => {
  const { patientQuery } = usePatient()
  const patient = patientQuery?.data

  const { data, isLoading: areInvoicesLoading } = useEmrQuery(
    'GET /invoices',
    {
      query: {
        patientId: patient?.oid || '',
      },
    },
    { enabled: Boolean(patient) },
  )

  if (!data) {
    return null
  }

  const {
    invoiceListItems,
    aggregateInvoiceInfo: {
      totalBalanceDueInDollars,
      overdueBalanceInDollars,
      noShowBalanceInDollars,
    },
  } = data

  if (areInvoicesLoading) {
    return (
      <Card>
        <Table>
          <tr>
            <td>
              <Skeleton height={24} />
            </td>
            <td>
              <Skeleton height={24} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton height={24} />
            </td>
            <td>
              <Skeleton height={24} />
            </td>
          </tr>
        </Table>
      </Card>
    )
  }

  if (!invoiceListItems) {
    return (
      <Card>
        <Stack align='center' p='sm' spacing='sm'>
          <Pill variant='filled' status='none'>
            No payments due
          </Pill>
          <Text>{patient?.personalData.firstName}&apos;s balance will appear here</Text>
        </Stack>
      </Card>
    )
  }

  return (
    <Card>
      <Stack spacing='xs'>
        <Table
          withBorder
          withColumnBorders
          sx={({ other: { colors, sizes } }) => ({
            borderWidth: sizes.border.md,
            borderStyle: 'solid',
            borderColor: colors.background[3],
          })}
        >
          <tbody>
            <tr>
              <td>
                <Group spacing='xs'>
                  <Text size='xs' color={colors => colors.text[0]}>
                    Total balance
                  </Text>
                  <Tooltip label='Excludes no show fees'>
                    <InfoIcon size='sm' color={colors => colors.actions[3]} />
                  </Tooltip>
                </Group>
              </td>
              <td>
                <Text bold size='xs' color={colors => colors.text[0]}>
                  {formatDollarAmount({ amount: totalBalanceDueInDollars })}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Group spacing='xs'>
                  <Text size='xs' color={colors => colors.text[0]}>
                    Overdue
                  </Text>
                  <Tooltip
                    label='Invoices past due 30 days (subscription) and 90 days (insurance). Excludes no show fees'
                    // The following props prevent the tooltip text from getting cut off
                    sx={{
                      maxWidth: '100%',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    <InfoIcon size='sm' color={colors => colors.actions[3]} />
                  </Tooltip>
                </Group>
              </td>
              <td>
                <Text
                  bold
                  size='xs'
                  color={colors => (overdueBalanceInDollars > 0 ? colors.error[0] : colors.text[0])}
                >
                  {formatDollarAmount({ amount: overdueBalanceInDollars })}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text size='xs' color={colors => colors.text[0]}>
                  No show balance
                </Text>
              </td>
              <td>
                <Text bold size='xs' color={colors => colors.text[0]}>
                  {formatDollarAmount({ amount: noShowBalanceInDollars })}
                </Text>
              </td>
            </tr>
          </tbody>
        </Table>
      </Stack>
    </Card>
  )
}
