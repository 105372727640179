import { Divider, Stack, TitleTwo } from '@shared/components'
import { PropsWithChildren } from 'react'

export const PatientVisitNoteSection = ({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) => {
  return (
    <Stack
      spacing='sm'
      p='md'
      align='stretch'
      sx={theme => ({
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.other.colors.background[2],
        borderRadius: '6px',
      })}
    >
      {title && <TitleTwo>{title}</TitleTwo>}
      <Divider />
      {children}
    </Stack>
  )
}
