import {
  Barc10FormResponse,
  DecoratedObjectiveMeasureResponse,
  FullWorkflowState,
  Gad7FormResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
  Phq8FormResponse,
  Phq9FormResponse,
  VisitNoteContent,
  VisitNoteType,
  WorkflowType,
} from '@shared/types'
import { useState } from 'react'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { emrApi, workflowsApi } from '../../api'
import { useMbcResponses } from './use-mbc-responses'

export function useMbcSession({
  patientID,
  visitID,
  notes,
  noteType,
}: {
  patientID: string
  visitID?: string
  notes?: VisitNoteContent | null
  noteType?: VisitNoteType
}) {
  const [inProgressSessionId, setInProgressSessionId] = useState<string | null>(null)
  const [workflowState, setWorkflowState] = useState<FullWorkflowState | null>(null)
  const [workflowType, setWorkflowType] = useState<
    'phq_9_form' | 'phq_8_form' | 'gad_7_form' | 'barc_10_form' | null
  >(null)
  const [recentlySubmittedPhq9Score, setRecentlySubmittedPhq9Score] =
    useState<DecoratedObjectiveMeasureResponse<Phq9FormResponse>>()

  const [recentlySubmittedPhq8Score, setRecentlySubmittedPhq8Score] =
    useState<DecoratedObjectiveMeasureResponse<Phq8FormResponse>>()
  const [recentlySubmittedGad7Score, setRecentlySubmittedGad7Score] =
    useState<DecoratedObjectiveMeasureResponse<Gad7FormResponse>>()
  const [recentlySubmittedBarc10Score, setRecentlySubmittedBarc10Score] =
    useState<DecoratedObjectiveMeasureResponse<Barc10FormResponse>>()
  const [objectiveMeasure, setObjectiveMeasure] = useState<ObjectiveMeasuresTitle | null>(null)
  const queryClient = useQueryClient()

  const createWorkflowSession = useMutation(workflowsApi.createSession)
  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const { gad7ResponsesQuery, barc10ResponsesQuery, asqResponsesQuery, phq8ResponsesQuery } =
    useMbcResponses({
      patientId: patientID,
      startAfter: {},
    })

  const asqResponses = asqResponsesQuery.data

  const [phq8WorkflowSessionInfoQueryKey, phq8WorkflowSessionInfoFunction] = emrApi.getQuery(
    'GET /workflow/session/:sessionId/info',
    {
      params: {
        sessionId: notes?.phq8FormSessionId || '',
      },
    },
  )

  const [gad7WorkflowSessionInfoQueryKey, gad7WorkflowSessionInfoFunction] = emrApi.getQuery(
    'GET /workflow/session/:sessionId/info',
    {
      params: {
        sessionId: notes?.gad7FormSessionId || '',
      },
    },
  )

  const [barc10WorkflowSessionInfoQueryKey, barc10WorkflowSessionInfoFunction] = emrApi.getQuery(
    'GET /workflow/session/:sessionId/info',
    {
      params: {
        sessionId: notes?.barc10FormSessionId || '',
      },
    },
  )

  const [
    gad7WorkflowSessionInfoQuery,
    barc10WorkflowSessionInfoQuery,
    phq8WorkflowSessionInfoQuery,
  ] = useQueries([
    {
      queryKey: gad7WorkflowSessionInfoQueryKey,
      queryFn: gad7WorkflowSessionInfoFunction,
      enabled: Boolean(notes?.gad7FormSessionId),
    },
    {
      queryKey: barc10WorkflowSessionInfoQueryKey,
      queryFn: barc10WorkflowSessionInfoFunction,
      enabled: Boolean(notes?.barc10FormSessionId),
    },
    {
      queryKey: phq8WorkflowSessionInfoQueryKey,
      queryFn: phq8WorkflowSessionInfoFunction,
      enabled: Boolean(notes?.phq8FormSessionId),
    },
  ])

  const [workflowSessionQueryKey, workflowSessionQueryFunction] = emrApi.getQuery(
    'GET /workflow/session/:sessionId',
    {
      params: {
        sessionId: inProgressSessionId || '',
      },
      query: {
        patientId: patientID,
      },
    },
  )

  const inProgressSessionQuery = useQuery(workflowSessionQueryKey, workflowSessionQueryFunction, {
    onSuccess: data => {
      setWorkflowState(data)
    },
    enabled: Boolean(inProgressSessionId),
  })

  const createWorkflowSessionAndOpenDrawer = async (measure: ObjectiveMeasuresTitle) => {
    setObjectiveMeasure(measure)

    if (measure === 'ASQ') {
      return
    }

    let workflowType: WorkflowType
    let sessionIdKey:
      | 'phq8FormSessionId'
      | 'phq9FormSessionId'
      | 'gad7FormSessionId'
      | 'barc10FormSessionId'

    switch (measure) {
      case 'PHQ-8':
        workflowType = 'phq_8_form'
        sessionIdKey = 'phq8FormSessionId'
        break
      case 'PHQ-9':
        workflowType = 'phq_9_form'
        sessionIdKey = 'phq9FormSessionId'
        break
      case 'GAD-7':
        workflowType = 'gad_7_form'
        sessionIdKey = 'gad7FormSessionId'
        break
      case 'BARC-10':
        workflowType = 'barc_10_form'
        sessionIdKey = 'barc10FormSessionId'
        break
    }

    setWorkflowType(workflowType)

    if (notes?.[sessionIdKey]) {
      setInProgressSessionId(notes[sessionIdKey])
      return
    }

    const workflowState = await createWorkflowSession.mutateAsync({
      workflowType,
      patientId: patientID,
    })

    setWorkflowState(workflowState)

    if (!visitID || !noteType) {
      return
    }

    updateVisitNote.mutate(
      {
        params: {
          patientId: patientID,
          visitId: visitID,
        },
        data: {
          content: { [sessionIdKey]: workflowState.workflowSession.oid },
          type: noteType,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries('visitNotesApi.getVisitNote')
        },
      },
    )
  }

  const setRecentlySubmittedScore = ({
    measure,
    tempScore,
  }: {
    measure: ObjectiveMeasuresTitle
    tempScore: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
  }) => {
    switch (measure) {
      case 'PHQ-9':
        setRecentlySubmittedPhq9Score(
          tempScore as DecoratedObjectiveMeasureResponse<Phq9FormResponse>,
        )
        break
      case 'GAD-7':
        setRecentlySubmittedGad7Score(
          tempScore as DecoratedObjectiveMeasureResponse<Gad7FormResponse>,
        )
        break
      case 'BARC-10':
        setRecentlySubmittedBarc10Score(
          tempScore as DecoratedObjectiveMeasureResponse<Barc10FormResponse>,
        )
        break
      case 'PHQ-8':
        setRecentlySubmittedPhq8Score(
          tempScore as DecoratedObjectiveMeasureResponse<Phq8FormResponse>,
        )
        break
      default:
        break
    }
  }

  const getCompletedMeasures = () => {
    const completedMeasures: ObjectiveMeasuresTitle[] = []
    if (gad7WorkflowSessionInfoQuery.data?.status === 'complete') {
      completedMeasures.push('GAD-7')
    }
    if (barc10WorkflowSessionInfoQuery.data?.status === 'complete') {
      completedMeasures.push('BARC-10')
    }
    if (phq8WorkflowSessionInfoQuery.data?.status === 'complete') {
      completedMeasures.push('PHQ-8')
    }
    return completedMeasures
  }

  const getResponses = <T extends ObjectiveMeasureResponse>(
    responses: DecoratedObjectiveMeasureResponse<T>[],
    recentlySubmittedResponse?: DecoratedObjectiveMeasureResponse<T>,
  ) => {
    if (recentlySubmittedResponse) {
      const copiedResponses = [...responses]
      const recentResponseIndex = copiedResponses.findIndex(
        response => response.workflowSessionId === recentlySubmittedResponse.workflowSessionId,
      )
      // Replace the old response if the recently submitted response is for the same workflow session
      if (recentResponseIndex !== -1) {
        copiedResponses[recentResponseIndex] = recentlySubmittedResponse
        return copiedResponses
      }

      return responses.concat(recentlySubmittedResponse)
    }
    return responses
  }

  const gad7Responses = getResponses(gad7ResponsesQuery.data ?? [], recentlySubmittedGad7Score)

  const barc10Responses = getResponses(
    barc10ResponsesQuery.data ?? [],
    recentlySubmittedBarc10Score,
  )

  const phq8Responses = getResponses(phq8ResponsesQuery.data ?? [], recentlySubmittedPhq8Score)

  const measuresQueriesAreLoading =
    gad7ResponsesQuery.isLoading || barc10ResponsesQuery.isLoading || phq8ResponsesQuery.isLoading

  const workflowIsLoading = inProgressSessionQuery.isFetching || createWorkflowSession.isLoading

  const sessionInfoQueriesAreLoading =
    gad7WorkflowSessionInfoQuery.isLoading ||
    barc10WorkflowSessionInfoQuery.isLoading ||
    phq8WorkflowSessionInfoQuery.isLoading

  const completedMeasures = getCompletedMeasures()

  const onCloseDrawer = () => {
    setWorkflowState(null)
    setInProgressSessionId(null)
    setObjectiveMeasure(null)
    setWorkflowType(null)
  }

  return {
    phq8Responses,
    gad7Responses,
    barc10Responses,
    asqResponses,
    recentlySubmittedPhq8Score,
    recentlySubmittedPhq9Score,
    recentlySubmittedGad7Score,
    recentlySubmittedBarc10Score,
    objectiveMeasure,
    workflowState,
    workflowType,
    measuresQueriesAreLoading,
    workflowIsLoading,
    completedMeasures,
    sessionInfoQueriesAreLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  }
}
