import { Navigate, useLocation } from 'react-router-dom'

type LocationState = { from?: { pathname?: string; search?: string } }

const PLanding = () => {
  const location = useLocation()
  const { from } = (location.state || { from: { pathname: '/' } }) as LocationState

  /*
   * When users land here with `from` state in tow that is something other than root,
   * redirect them accordingly (including search params).
   */
  if (from?.pathname && from.pathname !== '/') {
    return (
      <Navigate
        to={{
          pathname: from.pathname,
          search: from.search,
        }}
      />
    )
  }

  return <Navigate replace to='/visits' />
}

export default PLanding
