import { Appointment, AppointmentMetadata, AppointmentTypes } from '@shared/types'

import { BaseApi } from '@shared/utils'

type Res = 'OK'

type GetAllAppointmentsQuery = {
  minDate: string
  maxDate: string
  calendarID?: string
}

export class AppointmentsApi extends BaseApi {
  list = (query: GetAllAppointmentsQuery) => {
    /*
     * this return type is wrong. It should be TransformedAppointment, but that breaks other things
     * so leaving this for now
     */
    return this.json<Appointment[]>({
      method: 'GET',
      url: '/appointments',
      params: query,
    })
  }

  types = () => {
    return this.json<AppointmentTypes>({
      method: 'GET',
      url: '/appointmentTypes',
    })
  }

  updateByVisitId = ({
    patientId,
    visitId,
    data,
  }: {
    visitId: string
    patientId: string
    data: Partial<Appointment>
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/appointments/${patientId}/${visitId}`,
      data,
    })
  }

  confirm = ({
    patientId,
    appointmentId,
    confirmed,
  }: {
    patientId: string
    appointmentId: number
    confirmed: boolean
  }) => {
    return this.json<Res>({
      method: 'POST',
      url: `/patient/${patientId}/appointments/${appointmentId}/confirm`,
      data: {
        confirmed,
      },
    })
  }

  createAppointment = ({
    type,
    calendarId,
    datetime,
    patientId,
    metadata,
    admin,
  }: {
    patientId: string
    type: string
    calendarId?: number | undefined
    datetime: string
    metadata?: AppointmentMetadata | null
    admin?: boolean
  }) => {
    return this.json<void>({
      method: 'PUT',
      url: `/appointments/schedule/${type}`,
      data: {
        patientId,
        calendarId,
        datetime,
        metadata,
        admin,
      },
    })
  }

  getUnconfirmedAppointments = ({
    calendarId,
    date,
    appointmentTypeId,
  }: {
    calendarId?: number
    date: Date
    appointmentTypeId: number
  }) => {
    return this.json<Appointment[]>({
      method: 'GET',
      url: '/appointments/unconfirmed',
      params: { calendarId, date, appointmentTypeId },
    })
  }

  isAppointmentTimeAlreadyConfirmed = ({
    calendarId,
    datetime,
    appointmentTypeId,
  }: {
    calendarId?: number
    datetime: string
    appointmentTypeId: number
  }) => {
    return this.json<boolean>({
      method: 'GET',
      url: '/appointments/confirmed',
      params: { calendarId, datetime, appointmentTypeId },
    })
  }
}
