import { ClipboardIcon, LayoutIcon } from '@shared/components'
import { EMRNotificationResponse } from '@shared/types'
import { Config } from '../../../config'

export const NOTIFICATION_SOUND_URL = `${Config.STORAGE_BUCKET_PUBLIC_URL}/emrNotification.wav`

export type NotificationInfoType = {
  buttonIcon: React.FunctionComponent
  buttonText: string
}

export const getNotificationTitle = (notification: EMRNotificationResponse) => {
  switch (notification.type) {
    case 'discharged_patient':
      return 'Discharged patient'
    case 'tagged_in_note':
      return 'New issue note mention'
    case 'queued_bridge_prescription':
      return 'New queued bridge prescription request'
    case 'resend_prescription':
      return 'New prescription resend request'
    case 'general_refill_request':
      return 'New prescription refill request'
    case 'medical_concern':
      return 'New medical concern issue'
      break
    default:
      return null
  }
}

export const getNotificationInfo = (
  notification: EMRNotificationResponse,
): NotificationInfoType => {
  let buttonIcon = null
  let buttonText = ''
  switch (notification.type) {
    case 'discharged_patient':
      buttonIcon = LayoutIcon
      buttonText = 'Patient chart'
      break
    case 'tagged_in_note':
    case 'queued_bridge_prescription':
    case 'resend_prescription':
    case 'general_refill_request':
    case 'medical_concern':
      buttonIcon = ClipboardIcon
      buttonText = 'Notes'
      break
    default:
      buttonIcon = ClipboardIcon
      buttonText = ''
      break
  }

  return {
    buttonIcon,
    buttonText,
  }
}
