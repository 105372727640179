import React from 'react'

export type AMenuItemProps = {
  children: React.ReactNode
  icon?: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const AMenuItem = ({ children, icon, onClick }: AMenuItemProps) => (
  <button
    className='px-4 py-2 text-color hover:bg-near-white w-full text-left whitespace-nowrap flex items-center active:outline-none focus:outline-none'
    onClick={onClick}
  >
    <div className='text-readable-icon'>{icon}</div>
    <div>{children}</div>
  </button>
)

export default AMenuItem
