import { ObjectiveMeasuresTitle, WellnessCheckWorkflowType } from '@shared/types'
import { useEmrQuery } from '../../../utils/hooks'
import { usePatient } from '../../patient/PPatientContext'

type FormToSendStatus = 'primary' | 'other'
export type FormToSend = {
  form: WellnessCheckWorkflowType
  title: ObjectiveMeasuresTitle
  status: FormToSendStatus
}

export const useOrderedMbcFormsToSend = (): FormToSend[] => {
  const { patientId } = usePatient()

  const problemsListQuery = useEmrQuery('GET /patient/:patientId/problemsList', {
    params: { patientId },
  })
  const { data: phq9Icd10Codes } = useEmrQuery('GET /icd-10-codes', {
    query: { type: 'phq9Related' },
  })

  const { data: gad7Icd10Codes } = useEmrQuery('GET /icd-10-codes', {
    query: { type: 'gad7Related' },
  })

  const patientHasPhq9RelatedDiagnosis = problemsListQuery.data?.some(
    problem => phq9Icd10Codes?.some(code => code.code === problem.code),
  )

  const patientHasGad7RelatedDiagnosis = problemsListQuery.data?.some(
    problem => gad7Icd10Codes?.some(code => code.code === problem.code),
  )

  const phqFormToSend: FormToSend = {
    form: 'phq_8_form',
    title: 'PHQ-8',
    status: 'other',
  }

  const gad7FormToSend: FormToSend = {
    form: 'gad_7_form',
    title: 'GAD-7',
    status: 'other',
  }

  const barc10FormToSend: FormToSend = {
    form: 'barc_10_form',
    title: 'BARC-10',
    status: 'other',
  }

  if (patientHasPhq9RelatedDiagnosis) {
    phqFormToSend.status = 'primary'
    return [phqFormToSend, gad7FormToSend, barc10FormToSend]
  }

  if (patientHasGad7RelatedDiagnosis) {
    gad7FormToSend.status = 'primary'
    return [gad7FormToSend, phqFormToSend, barc10FormToSend]
  }

  barc10FormToSend.status = 'primary'
  return [barc10FormToSend, phqFormToSend, gad7FormToSend]
}
