import React from 'react'
import { useMutation } from 'react-query'
import { patientsApi } from '../../../api'
import ALoadingSpinner from '../../../components/atoms/ALoadingSpinner'

type ACreateDoseSpotPatientButtonProps = {
  patientId: string
  onSuccess: () => void
}

const ACreateDoseSpotPatientButton = ({
  onSuccess,
  patientId,
}: ACreateDoseSpotPatientButtonProps) => {
  const createDoseSpotPatient = useMutation(patientsApi.createDoseSpotPatient, {
    onSuccess,
  })

  const clickCreateDoseSpotPatient: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    createDoseSpotPatient.mutate(patientId)
  }

  return (
    <button
      onClick={clickCreateDoseSpotPatient}
      type='button'
      className={`mt-4 text-gray-700 ${createDoseSpotPatient.isSuccess && 'text-success'} ${
        createDoseSpotPatient.isError && 'text-error'
      } bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 w-72 border inline-flex items-center justify-center px-4 py-2 text-sm rounded-md`}
    >
      {createDoseSpotPatient.isLoading && <ALoadingSpinner />}
      {createDoseSpotPatient.isSuccess && 'Successfully signed up patient.'}
      {createDoseSpotPatient.isError && 'Error signing up patient.'}
      {!createDoseSpotPatient.isLoading &&
        !createDoseSpotPatient.isSuccess &&
        !createDoseSpotPatient.isError &&
        'Click here to sign up Patient in DoseSpot'}
    </button>
  )
}

export default ACreateDoseSpotPatientButton
