import { Employee, hasGroupRole } from '@shared/types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useAuthForCCPages = (user: Employee) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!hasGroupRole(user, 'careCoordinator', 'admin')) {
      navigate('/')
    }
  })
}
