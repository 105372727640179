import { Modal, PrimaryButton, Text } from '@shared/components'
import { useMutation } from 'react-query'
import { patientsApi } from '../../api'
import { usePatient } from './PPatientContext'

export const CreateDoseSpotPatientModal = (props: { closeModal: () => void }) => {
  const { patientQuery } = usePatient()

  const doseSpotId = patientQuery?.data?.doseSpotId

  const createDoseSpotPatient = useMutation(patientsApi.createDoseSpotPatient, {
    onSuccess: () => {
      void patientQuery?.refetch()
    },
  })

  return (
    <Modal opened onClose={props.closeModal} title='Sign up Patient in DoseSpot' footer={null}>
      {doseSpotId ? (
        <Text>This patient already has a DoseSpotId: {doseSpotId}</Text>
      ) : (
        <PrimaryButton
          fullWidth
          disabled={patientQuery?.isFetching}
          loading={createDoseSpotPatient.isLoading}
          onClick={() => {
            createDoseSpotPatient.mutate(patientQuery?.data?.oid || '')
          }}
        >
          Sign up
        </PrimaryButton>
      )}
      {createDoseSpotPatient.isSuccess && <Text>Successfully signed up patient.</Text>}
      {createDoseSpotPatient.isError && <Text>Error signing up patient.</Text>}
    </Modal>
  )
}
