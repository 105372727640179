import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  Banner,
  Checkbox,
  Drawer,
  Grid,
  Group,
  PrimaryButton,
  Select,
  showNotification,
  Stack,
  Text,
  TextInput,
  TitleFour,
  validateWith,
} from '@shared/components'
import { AddressData, Patient, ShipmentRationale } from '@shared/types'
import { name } from '@shared/utils'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { isRequired, isZipCode } from '../../../utils/formValidation'

export type LabOrderForm = {
  provider: string
  forceSignature: boolean
  rationale: ShipmentRationale
  shippingAddress: AddressData
}

type LabOrderDrawerProps = {
  patient: Patient | undefined
  opened: boolean
  onClose: () => void
}

const LabOrderDrawer = ({ patient, opened, onClose }: LabOrderDrawerProps) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  const [useDifferentShippingAddress, setUseDifferentShippingAddress] = useToggle()

  const allShipmentRationales = [
    'Transition to next level of care',
    'Unsuccessful delivery',
    'Lost or damaged shipment',
    'Suspected aberrant behavior',
    'Quarterly test',
    'Clinical necessity',
    'Transition to new PC',
  ] as const

  const patientShippingAddress = {
    name: name({
      first: patient?.personalData.firstName,
      last: patient?.personalData.lastName,
    }).full(),
    address: patient?.shippingData?.address || '',
    city: patient?.shippingData?.city || '',
    state: patient?.shippingData?.state || '',
    zip: patient?.shippingData?.zip?.substring(0, 5) || '',
    aptSuite: patient?.shippingData?.aptSuite || '',
  }

  const initialFormValues = {
    provider: currentUser.name,
    rationale: allShipmentRationales[0]!,
    forceSignature: false,
    shippingAddress: patientShippingAddress,
  }

  const labOrderForm = useForm<LabOrderForm>({
    initialValues: initialFormValues,
    validate: {
      provider: validateWith(isRequired),
      rationale: validateWith(isRequired),
      shippingAddress: {
        address: validateWith(isRequired),
        city: validateWith(isRequired),
        state: validateWith(isRequired),
        zip: validateWith(isRequired, isZipCode),
      },
    },
    clearInputErrorOnChange: false,
  })

  const [labOrdersQueryKey] = emrApi.getQuery('GET /patient/:patientId/shipments', {
    params: { patientId: patient?.oid || '' },
  })

  const createLabShipment = useMutation(emrApi.getMutation('POST /patient/:patientId/shipments'), {
    onSuccess: () => {
      void queryClient.invalidateQueries(labOrdersQueryKey)
      showNotification({
        message: 'Lab successfully ordered',
        variant: 'success',
      })
      labOrderForm.reset()
      onClose()
    },
  })

  const submitLabOrder = () => {
    if (labOrderForm.validate().hasErrors) {
      return
    }

    createLabShipment.mutate({
      params: { patientId: patient?.oid || '' },
      data: labOrderForm.values,
    })
  }

  useEffect(() => {
    labOrderForm.setValues(initialFormValues)
  }, [patient])

  const addressText = [patient?.shippingData?.address, patient?.shippingData?.aptSuite]
    .filter(Boolean)
    .join(', ')
  const cityStateZipText = `${patient?.shippingData?.city}, ${patient?.shippingData?.state} ${patient?.shippingData?.zip}`

  const handleShippingAddressCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseDifferentShippingAddress(event.currentTarget.checked)
    if (!event.currentTarget.checked) {
      labOrderForm.setValues({
        shippingAddress: patientShippingAddress,
      })
    }
  }

  return (
    <Drawer
      title='Order UDS cup'
      size='lg'
      opened={opened}
      position='right'
      onClose={() => {
        onClose()
        labOrderForm.reset()
        setUseDifferentShippingAddress(false)
      }}
      footer={
        <Group position='right'>
          <PrimaryButton onClick={submitLabOrder} loading={createLabShipment.isLoading}>
            Order UDS cup
          </PrimaryButton>
        </Group>
      }
    >
      <Stack p='md'>
        {createLabShipment.isError && (
          <Banner type='error' label='Something went wrong, please try again' />
        )}
        <Grid columns={12}>
          <Grid.Col span={12}>
            <Select
              placeholder='Select'
              label={<TitleFour>Reason</TitleFour>}
              data={allShipmentRationales.map(rationale => ({
                label: rationale,
                value: rationale,
              }))}
              {...labOrderForm.getInputProps('rationale')}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Stack spacing='sm'>
              <TitleFour>Shipping address</TitleFour>
              <Stack spacing='xs'>
                <Text>{addressText}</Text>
                <Text>{cityStateZipText}</Text>
              </Stack>
              <Checkbox
                checked={useDifferentShippingAddress}
                onChange={handleShippingAddressCheckboxChange}
                label='Ship to a different address'
              />
              {useDifferentShippingAddress && (
                <Grid>
                  <Grid.Col span={12}>
                    <TextInput
                      label='Address line 1'
                      placeholder='Address line 1'
                      {...labOrderForm.getInputProps('shippingAddress.address')}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label='Address line 2 (optional)'
                      placeholder='Address line 2'
                      {...labOrderForm.getInputProps('shippingAddress.aptSuite')}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label='City'
                      placeholder='City'
                      {...labOrderForm.getInputProps('shippingAddress.city')}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label='State'
                      placeholder='State'
                      {...labOrderForm.getInputProps('shippingAddress.state')}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label='Zip code'
                      placeholder='Zip code'
                      {...labOrderForm.getInputProps('shippingAddress.zip')}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Stack>
          </Grid.Col>
          <Grid.Col span={12}>
            <Stack spacing='sm'>
              <TitleFour>Signature</TitleFour>
              <Checkbox
                {...labOrderForm.getInputProps('forceSignature')}
                label="Require patient's signature upon delivery"
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Drawer>
  )
}

export default LabOrderDrawer
