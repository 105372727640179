import {
  Colors,
  FilePlusIcon,
  Grid,
  Group,
  SecondaryButton,
  Stack,
  Text,
  TitleThree,
} from '@shared/components'
import { ObjectiveMeasuresTitle } from '@shared/types'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { StartAfter, useMbcResponses } from '../../../utils/hooks/use-mbc-responses'
import { useMbcSession } from '../../../utils/hooks/use-mbc-session'
import ObjectiveMeasuresChartContainer from './ObjectiveMeasuresChartContainer'
import ObjectiveMeasuresFormCollectionDrawer from './ObjectiveMeasuresFormCollectionDrawer'

export const PatientMeasuresContent = () => {
  const { patientID = '' } = useParams<{ patientID: string }>()

  const [startAfter, setStartAfter] = useState<StartAfter>({})

  const {
    recentlySubmittedGad7Score,
    recentlySubmittedBarc10Score,
    recentlySubmittedPhq8Score,
    objectiveMeasure,
    workflowState,
    workflowType,
    workflowIsLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  } = useMbcSession({
    patientID,
  })

  const updateStartAfter = ({
    measure,
    date,
  }: {
    measure: ObjectiveMeasuresTitle
    date: string
  }) => {
    setStartAfter(startAfter => ({ ...startAfter, [measure]: date }))
  }

  const { gad7ResponsesQuery, barc10ResponsesQuery, asqResponsesQuery, phq8ResponsesQuery } =
    useMbcResponses({
      patientId: patientID,
      startAfter,
    })

  return (
    <>
      <ObjectiveMeasuresFormCollectionDrawer
        patientId={patientID}
        measure={objectiveMeasure}
        workflowState={workflowState}
        workflowType={workflowType}
        isLoading={workflowIsLoading}
        onClose={onCloseDrawer}
        setRecentlySubmittedScore={setRecentlySubmittedScore}
        displayDatePicker
      />
      <Stack p='md'>
        <Group position='apart'>
          <TitleThree>Objective measures</TitleThree>
          <Group>
            <Text color={(colors: Colors) => colors.text[1]}>Administer an objective measure</Text>
            <SecondaryButton
              leftIcon={<FilePlusIcon />}
              onClick={() => createWorkflowSessionAndOpenDrawer('PHQ-8')}
            >
              PHQ-8
            </SecondaryButton>

            <SecondaryButton
              leftIcon={<FilePlusIcon />}
              onClick={() => createWorkflowSessionAndOpenDrawer('GAD-7')}
            >
              GAD-7
            </SecondaryButton>
            <SecondaryButton
              leftIcon={<FilePlusIcon />}
              onClick={() => createWorkflowSessionAndOpenDrawer('BARC-10')}
            >
              BARC-10
            </SecondaryButton>
          </Group>
        </Group>
        <Grid>
          <Grid.Col span={6}>
            <ObjectiveMeasuresChartContainer
              title='PHQ-8'
              responsesQuery={phq8ResponsesQuery}
              recentlySubmittedScore={recentlySubmittedPhq8Score}
              updateStartAfter={updateStartAfter}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ObjectiveMeasuresChartContainer
              title='GAD-7'
              responsesQuery={gad7ResponsesQuery}
              recentlySubmittedScore={recentlySubmittedGad7Score}
              updateStartAfter={updateStartAfter}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ObjectiveMeasuresChartContainer
              title='BARC-10'
              responsesQuery={barc10ResponsesQuery}
              recentlySubmittedScore={recentlySubmittedBarc10Score}
              updateStartAfter={updateStartAfter}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ObjectiveMeasuresChartContainer
              title='ASQ'
              responsesQuery={asqResponsesQuery}
              updateStartAfter={updateStartAfter}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  )
}
