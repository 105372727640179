import { Text } from '@shared/components'
import { PromptContentHeader } from '@shared/types'
export type PromptHeaderProps = PromptContentHeader
export const PromptHeader = ({ primaryText, secondaryText }: PromptHeaderProps) => {
  return (
    <>
      {primaryText && (
        <Text bold color={colors => colors.text[0]}>
          {primaryText}
        </Text>
      )}
      {secondaryText && <Text color={colors => colors.text[0]}>{secondaryText}</Text>}
    </>
  )
}
