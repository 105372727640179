import { EMRTaskDecorated } from '@shared/types'
import { useTaskParams } from '../../PTasks'
import { CurrentTaskOperation } from '../../TaskTable'
import { OpenActionMenuItems } from './OpenActionMenuItems'
import { SnoozedActionMenuItems } from './SnoozedActionMenuItems'

export type ActionMenuItemsForTaskStatusProps = {
  task: EMRTaskDecorated
  onClick: ({ operation, task }: CurrentTaskOperation) => void
}

export const ActionMenuItemsForTaskStatus = ({
  task,
  onClick,
}: ActionMenuItemsForTaskStatusProps) => {
  const params = useTaskParams()

  const currentTab = params.queue

  switch (currentTab) {
    case 'open':
      return <OpenActionMenuItems task={task} onClick={onClick} />
    case 'snoozed':
      return <SnoozedActionMenuItems task={task} onClick={onClick} />
    default:
      return null
  }
}
