import { Box, ClockIcon, Group, Stack, TertiaryButton, Text } from '@shared/components'
import { EMRNotificationResponse } from '@shared/types'
import { dayjs } from '@shared/utils'
import { createElement } from 'react'
import { getNotificationInfo } from './notificationUtils'

type NotificationRowProps = {
  notification: EMRNotificationResponse
  onClick: ({ notificationId, url }: { notificationId: string; url: string }) => void
}

export const NotificationRow = ({ notification, onClick }: NotificationRowProps) => {
  const { oid: notificationId, message, url, seen } = notification

  const { buttonIcon, buttonText } = getNotificationInfo(notification)

  return (
    <Box
      p='md'
      sx={({ other: { colors } }) => ({
        borderColor: seen ? colors.background[0] : colors.error[0],
        borderLeftWidth: '0.5rem',
        backgroundColor: seen ? colors.background[0] : colors.background[2],
        cursor: seen ? 'default' : 'pointer',
      })}
      onClick={() => {
        if (!seen) {
          onClick({ notificationId, url })
        }
      }}
    >
      <Stack spacing='sm'>
        <Text>{message}</Text>
        <Group position='apart'>
          <Group spacing='xs'>
            <ClockIcon color={colors => colors.text[1]} />
            <Text size='xs' color={colors => colors.text[1]}>
              {dayjs(notification.createdAt).fromNow()}
            </Text>
          </Group>
          <TertiaryButton
            leftIcon={createElement(buttonIcon)}
            onClick={() => onClick({ notificationId, url })}
          >
            {buttonText}
          </TertiaryButton>
        </Group>
      </Stack>
    </Box>
  )
}
