import { Drawer, Group, ProgressDots, Radio, Stack, Text } from '@shared/components'

type PickScheduleTypeStepProps = {
  closeModal: () => void
  onNext: (args: { scheduleType: 'any' | 'specific' }) => void
  clinicianType?: string
}

export const PickScheduleTypeStep = ({
  closeModal,
  onNext,
  clinicianType = 'clinician',
}: PickScheduleTypeStepProps) => {
  return (
    <Drawer
      title='Schedule a visit'
      size='xl'
      opened
      position='right'
      onClose={closeModal}
      footer={
        <Group position='apart'>
          <ProgressDots steps={2} currentStep={0} />
        </Group>
      }
    >
      <Stack px='md'>
        <Text>Would you like to schedule the visit with any {clinicianType}?</Text>
        <Group spacing='sm'>
          <Radio onChange={() => onNext({ scheduleType: 'any' })} label='Yes' />
          <Radio onChange={() => onNext({ scheduleType: 'specific' })} label='No' />
        </Group>
      </Stack>
    </Drawer>
  )
}
