import { BannerPortal, Flex, ScrollArea, useBanner } from '@shared/components'
import { PropsWithChildren, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { emrApi } from '../../api'
import { useActionsState, useEmrQuery, useIframeChartViewedMessage } from '../../utils/hooks'
import { OutageBanner } from '../../utils/hooks/use-outage-banner'
import OActionModals from './OActionModals'
import { PatientContext, PatientContextType } from './PPatientContext'
import { PatientShellHeader } from './PatientShellHeader'
import { PatientSidebar } from './journey/PatientSidebar'

export const PatientShell = ({ children }: PropsWithChildren) => {
  const { patientID: patientId = '' } = useParams<{
    patientID: string
  }>()
  const { cleanBanner } = useBanner()
  const location = useLocation()

  const patientQuery = useEmrQuery('GET /patient/:patientId', {
    params: {
      patientId,
    },
  })

  useIframeChartViewedMessage(patientQuery.data?.personalData?.phone)

  const patientImagesQuery = useEmrQuery('GET /patient/:patientId/identification-images', {
    params: {
      patientId,
    },
  })

  const primaryClinicianQuery = useEmrQuery(
    'GET /employee/:employeeId',
    {
      params: {
        employeeId: patientQuery?.data?.primaryClinician?.id ?? '',
      },
    },
    {
      enabled: Boolean(patientQuery?.data?.primaryClinician?.id),
    },
  )

  const problemsListQuery = useEmrQuery('GET /patient/:patientId/problemsList', {
    params: {
      patientId,
    },
  })

  const pcId = patientQuery.data?.primaryClinician?.id

  const [daysSinceLastVisitKey, daysSinceLastVisitFn] = emrApi.getQuery(
    'GET /patient/:patientId/days-since-last-visit/:employeeId',
    {
      params: {
        patientId,
        employeeId: pcId || '',
      },
    },
  )

  const daysSinceLastVisitWithPcQuery = useQuery(daysSinceLastVisitKey, daysSinceLastVisitFn, {
    enabled: Boolean(pcId),
  })

  const { modal, setModal } = useActionsState()

  const context: PatientContextType = {
    modal,
    setModal,
    patientQuery,
    primaryClinicianQuery,
    patientImagesQuery,
    problemsListQuery,
    daysSinceLastVisitWithPcQuery,
    patientID: patientId,
    patientId,
  }

  useEffect(() => {
    return cleanBanner
    // Clean up all state when page changes or when the shell unmounts.
  }, [cleanBanner, location.pathname])

  // Get the page we're on - /patients/:patientId/edit -> edit
  const subpath = location.pathname.split('/')[3]?.split('?')[0]

  return (
    <PatientContext.Provider value={context}>
      <OutageBanner />
      <PatientShellHeader />
      <BannerPortal />
      <Flex
        h='100%'
        sx={{
          // Absolute children are positioned to be relative to this parent
          overflowX: 'auto',
          overflowY: 'hidden',
          position: 'relative',
        }}
      >
        <PatientSidebar canAddDiagnoses={subpath === 'notes'} />
        {children}
      </Flex>
      <OActionModals />
    </PatientContext.Provider>
  )
}

export const PatientShellScroll = ({ children }: PropsWithChildren) => {
  return (
    <ScrollArea constrict type='always' styles={{ root: { flex: 1 } }}>
      {children}
    </ScrollArea>
  )
}

export default PatientShell
