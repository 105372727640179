import { Box, Grid, TertiaryButton, TrashIcon } from '@shared/components'
import { LabeledData } from './LabeledData'

export type EncounterDiagnosisItemProps = {
  name?: string
  code?: string
  code_type?: string
  beingDeleted?: boolean
  removeOnClick: () => void
}

export const EncounterDiagnosisItem = ({
  name,
  code,
  code_type,
  beingDeleted,
  removeOnClick,
}: EncounterDiagnosisItemProps) => {
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.other.colors.background[2],
        borderRadius: theme.radius.md,
      })}
      p='md'
    >
      <Grid columns={12}>
        <Grid.Col span={4}>
          <LabeledData label='Diagnosis name' data={name} />
        </Grid.Col>
        <Grid.Col span={4}>
          <LabeledData label='Diagnosis code' data={code} />
        </Grid.Col>
        <Grid.Col span={4}>
          <LabeledData label='Diagnosis code type' data={code_type} />
        </Grid.Col>
        <Grid.Col span={12}>
          <TertiaryButton
            onClick={removeOnClick}
            rightIcon={<TrashIcon color={colors => colors.actions[1]} />}
            loading={beingDeleted}
          >
            Remove
          </TertiaryButton>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
