import {
  ArchiveIcon,
  CreditCardIcon,
  Menu,
  MessageCircleIcon,
  MoonIcon,
  UserPlusIcon,
} from '@shared/components'
import { EMRTaskDecorated, hasRole } from '@shared/types'
import { phone } from '@shared/utils'
import { Config } from '../../../../../config'
import { useAuth } from '../../../../../context/auth'
import { getStripeDashboardUrl } from '../../../../patient/invoices/InvoiceRow'
import { CurrentTaskOperation } from '../../TaskTable'

export type OpenActionMenuItemsProps = {
  task: EMRTaskDecorated
  onClick: ({ operation, task }: CurrentTaskOperation) => void
}

export const OpenActionMenuItems = ({ task, onClick }: OpenActionMenuItemsProps) => {
  const { currentUser } = useAuth()

  return (
    <>
      <Menu.Item
        onClick={() => onClick({ operation: 'snooze', task })}
        icon={<MoonIcon color={colors => colors.actions[0]} />}
      >
        Snooze
      </Menu.Item>
      <Menu.Item
        onClick={() => onClick({ operation: 'close', task })}
        icon={<ArchiveIcon color={colors => colors.actions[0]} />}
      >
        Close
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          window.open(
            `${Config.TWILIO_FLEX_URL}/conversations?search=${
              phone(task.metadata.patientPhone).normalized
            }`,
            '_blank',
          )
        }
        icon={<MessageCircleIcon color={colors => colors.actions[0]} />}
      >
        Flex conversation
      </Menu.Item>
      {hasRole(currentUser, 'financialCounselor') && (
        <Menu.Item
          onClick={() => {
            const url = getStripeDashboardUrl({
              pathname: `/search`,
              search: {
                query: `${task.metadata.patientFirstName} ${task.metadata.patientLastName}`,
              },
            })

            window.open(url.href, '_blank')
          }}
          icon={<CreditCardIcon color={colors => colors.actions[0]} />}
        >
          Stripe
        </Menu.Item>
      )}

      <Menu.Item
        onClick={() =>
          onClick({
            operation: 'edit',
            task,
          })
        }
        icon={<UserPlusIcon color={colors => colors.actions[0]} />}
      >
        Add/edit assignee
      </Menu.Item>
    </>
  )
}
