import React from 'react'
import IconClose from '../icons/IconClose'

export type TModalProps = {
  children: React.ReactNode
  closeModal: React.MouseEventHandler<HTMLElement>
  width?: string
  height?: string
  padding?: string
  closeButton?: boolean
  style?: Record<string, string | number>
}

const TModal = ({
  children,
  closeModal,
  width = 'sm:max-w-lg',
  height,
  padding = 'p-4 sm:p-6',
  closeButton = true,
  style = {},
}: TModalProps) => {
  return (
    <div style={{ zIndex: 51 }} className='fixed inset-0'>
      <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 block sm:p-0 h-full'>
        <div className='fixed inset-0 transition-opacity'>
          <div onClick={closeModal} className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>&#8203;
        <div
          className={`${padding} w-full ${width} inline-block align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle overflow-y-scroll relative ${
            height ?? ''
          }`}
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
          style={style}
        >
          {closeButton && (
            <div className='absolute top-0 right-0 z-40'>
              <button
                className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 m-2'
                onClick={closeModal}
              >
                <IconClose textColor='text-daybreak-text3' />
              </button>
            </div>
          )}
          <div className={`flex flex-col items-center justify-start ${height}`}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default TModal
