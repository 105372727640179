import { Select } from '@shared/components'
import { StateAbbr } from '@shared/types'
import { useQuery } from 'react-query'
import { employeesApi } from '../../../api'

export type ClinicianSearchBarProps = {
  label?: string
  licensedState?: StateAbbr
  onChange: (clinicianId: string) => void
  value: string
  error?: string
}

const ClinicianSelect = ({
  label,
  licensedState,
  onChange,
  value,
  error,
}: ClinicianSearchBarProps) => {
  const clinicianQuery = useQuery(['employeesApi.ocpClinicians'], employeesApi.ocpClinicians)
  const options =
    clinicianQuery.data
      ?.filter(clinician => {
        if (!licensedState) {
          return false
        }
        return clinician.licensedStates?.includes(licensedState)
      })
      .map(clinician => {
        return {
          value: clinician.oid,
          label: `${clinician.name} (NADEA #: ${clinician?.nadea})`,
        }
      }) || []
  return (
    <Select
      nothingFound='No clinicians found'
      searchable
      clearable
      label={label}
      data={options}
      placeholder='Search for an Ophelia clinician'
      onChange={onChange}
      value={value}
      error={error}
    />
  )
}
export default ClinicianSelect
