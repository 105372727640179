import { useForm } from '@mantine/form'
import {
  BetterDrawer,
  Center,
  Checkbox,
  CheckboxGroup,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  Loader,
  PrimaryButton,
  Radio,
  RadioGroup,
  SaveIcon,
  SearchIcon,
  Select,
  Stack,
  TextInput,
  validateWith,
} from '@shared/components'
import { EmrLunaApi, arrayIncludes } from '@shared/types'
import { getInsurancePlanId, toTime } from '@shared/utils'
import { isRequired } from '../../../../utils/formValidation'
import { useInvalidateLunaQuery, useLunaMutation, useLunaQuery } from '../../../../utils/hooks'
import { ServiceLineTypeValue, ServiceLinesSection } from './ServiceLinesSection'

type PayerConfigDrawerProps = {
  opened: boolean
  onClose: () => void
  payerConfigId?: string
}

export const PlanTypes = [
  { label: 'Medicare', value: 'medicare' },
  { label: 'Medicaid', value: 'medicaid' },
  { label: 'Commercial', value: 'commercial' },
] as const

type PlanType = (typeof PlanTypes)[number]['value']

export const INIT_SERVICE_LINE = {
  code: '',
  amount: '',
  modifier: '',
} as const

export const filterServiceLine = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceLines: any[],
  planType: PlanType,
  type: ServiceLineTypeValue,
) => {
  const res = serviceLines.filter(sl => sl.planType === planType && sl.type === type)
  return res.length ? res : [INIT_SERVICE_LINE]
}

/**
 * This intermediate component fetches the payer config data
 * when editing an existing payer config and only renders the
 * drawer content when the data is available. This makes
 * seeding the form with the existing data easier.
 */
const PayerConfigDrawerContentManager = (props: PayerConfigDrawerProps) => {
  const payerConfigQuery = useLunaQuery(
    'GET /payer-configs/:id',
    {
      params: {
        id: props.payerConfigId || '',
      },
    },
    {
      enabled: Boolean(props.payerConfigId),
    },
  )

  if (payerConfigQuery.isFetching) {
    return (
      <Center h='100%'>
        <Loader />
      </Center>
    )
  }

  return <PayerConfigDrawerContent {...props} payerConfig={payerConfigQuery.data?.data || null} />
}

const PayerConfigDrawerContent = ({
  payerConfig,
  onClose,
}: PayerConfigDrawerProps & {
  payerConfig: EmrLunaApi['GET /payer-configs/:id']['res']['data'] | null
}) => {
  const invalidateQuery = useInvalidateLunaQuery()
  const createPayerConfig = useLunaMutation('POST /payer-configs', {
    onSuccess: () => {
      void invalidateQuery('GET /payer-configs')
      onClose()
    },
  })
  const updatePayerConfig = useLunaMutation('PUT /payer-configs/:id', {
    onSuccess: () => {
      void invalidateQuery('GET /payer-configs')
      onClose()
    },
  })

  const eligiblePayersQuery = useLunaQuery('GET /insurance-payers', {
    staleTime: toTime('5 min').ms(),
  })

  const insurances = (eligiblePayersQuery.data?.data || []).map(({ payerId, name }) => {
    return {
      label: name,
      value: getInsurancePlanId(payerId, name),
    }
  })

  const title = payerConfig ? `Edit ${payerConfig.name}` : 'Add bundle'
  const prevServiceLines = payerConfig?.serviceLines || []

  const form = useForm({
    initialValues: {
      name: payerConfig ? payerConfig.name : '',
      eligiblePayerName: payerConfig ? payerConfig.eligiblePayerName : '',
      eligiblePayerId: payerConfig ? payerConfig.eligiblePayerId : '',
      changePayerId: payerConfig ? payerConfig.changePayerId : '',
      category: payerConfig ? payerConfig.category : 'monthly',
      renderingProvider: payerConfig ? payerConfig.renderingProvider : 'pc_on_account',
      planTypes: payerConfig ? payerConfig.planTypes : ([] as PlanType[]),
      // eslint-disable-next-line no-nested-ternary
      type: payerConfig
        ? arrayIncludes(['1_to_3_months', '4_months'], payerConfig.serviceLines[0]?.type)
          ? 'months'
          : 'visits'
        : ('' as 'months' | 'visits'),
      placeOfService: payerConfig ? payerConfig.placeOfService : '',
      serviceLines: {
        commercial: {
          intake: filterServiceLine(prevServiceLines, 'commercial', 'intake'),
          subsequent: filterServiceLine(prevServiceLines, 'commercial', 'subsequent'),
          ['1_to_3_months']: filterServiceLine(prevServiceLines, 'commercial', '1_to_3_months'),
          ['4_months']: filterServiceLine(prevServiceLines, 'commercial', '4_months'),
        },
        medicaid: {
          intake: filterServiceLine(prevServiceLines, 'medicaid', 'intake'),
          subsequent: filterServiceLine(prevServiceLines, 'medicaid', 'subsequent'),
          ['1_to_3_months']: filterServiceLine(prevServiceLines, 'medicaid', '1_to_3_months'),
          ['4_months']: filterServiceLine(prevServiceLines, 'medicaid', '4_months'),
        },
        medicare: {
          intake: filterServiceLine(prevServiceLines, 'medicare', 'intake'),
          subsequent: filterServiceLine(prevServiceLines, 'medicare', 'subsequent'),
          ['1_to_3_months']: filterServiceLine(prevServiceLines, 'medicare', '1_to_3_months'),
          ['4_months']: filterServiceLine(prevServiceLines, 'medicare', '4_months'),
        },
      },
    },
    validate: {
      name: payerConfig ? undefined : validateWith(isRequired),
      eligiblePayerId: validateWith(isRequired),
      eligiblePayerName: validateWith(isRequired),
      changePayerId: validateWith(isRequired),
      category: validateWith(isRequired),
      renderingProvider: validateWith(isRequired),
      placeOfService: validateWith(isRequired),
      planTypes: validateWith(isRequired),
      type: validateWith(isRequired),
    },
  })

  /**
   * This util converts the flattened services lines from the
   * form to the format that the API expects, which is a list
   * of objects with the planType and type properties.
   */
  const formatServiceLines = (serviceLines: typeof form.values.serviceLines) => {
    const result = []

    for (const planType in serviceLines) {
      const pt = planType as PlanType
      for (const type in serviceLines[pt]) {
        const t = type as ServiceLineTypeValue
        const details = serviceLines[pt][t]
        for (const detail of details) {
          // if code or amount is empty, skip this service line
          if (!detail?.code || !detail?.amount) {
            continue
          }
          result.push({
            type: t,
            planType: pt,
            ...detail,
          })
        }
      }
    }

    return result
  }

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    if (payerConfig) {
      updatePayerConfig.mutate({
        params: {
          id: payerConfig.oid,
        },
        data: {
          name: form.values.name,
          eligiblePayerName: form.values.eligiblePayerName,
          eligiblePayerId: form.values.eligiblePayerId,
          changePayerId: form.values.changePayerId,
          category: form.values.category,
          renderingProvider: form.values.renderingProvider,
          planTypes: form.values.planTypes,
          placeOfService: form.values.placeOfService,
          serviceLines: formatServiceLines(form.values.serviceLines),
        },
      })
    } else {
      createPayerConfig.mutate({
        data: {
          name: form.values.name,
          eligiblePayerName: form.values.eligiblePayerName,
          eligiblePayerId: form.values.eligiblePayerId,
          changePayerId: form.values.changePayerId,
          category: form.values.category,
          renderingProvider: form.values.renderingProvider,
          planTypes: form.values.planTypes,
          placeOfService: form.values.placeOfService,
          serviceLines: formatServiceLines(form.values.serviceLines),
        },
      })
    }
  }

  return (
    <>
      <DrawerHeader onClose={onClose}>{title}</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          <TextInput label='Bundle name' {...form.getInputProps('name')} />
          <Select
            label='Payer name'
            data={insurances}
            icon={eligiblePayersQuery.isLoading ? <Loader /> : <SearchIcon />}
            searchable
            clearable
            disabled={eligiblePayersQuery.isLoading}
            placeholder='Search payer name'
            {...form.getInputProps('eligiblePayerName')}
            value={`${form.values.eligiblePayerId}__${form.values.eligiblePayerName}`}
            onChange={value => {
              const [eligiblePayerId = '', eligiblePayerName = ''] = (value || '').split('__')
              form.setValues({
                eligiblePayerId,
                eligiblePayerName,
              })
            }}
          />
          <Group grow>
            <TextInput
              label='Eligible Payer ID'
              disabled
              {...form.getInputProps('eligiblePayerId')}
            />
            <TextInput label='Candid Payer ID' {...form.getInputProps('changePayerId')} />
          </Group>
          <Group>
            <Select
              style={{ flex: 1 }}
              label='Category'
              data={[
                { label: 'Monthly + $0 FFS', value: 'monthly_plus_ffs' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              {...form.getInputProps('category')}
            />
            <Select
              style={{ flex: 1 }}
              label='Rendering provider'
              data={[
                {
                  label: "Patient's prescribing clinician",
                  value: 'pc_on_account',
                },
              ]}
              {...form.getInputProps('renderingProvider')}
            />
          </Group>
          <Group>
            <TextInput
              style={{ flex: 1 }}
              label='Place of service'
              {...form.getInputProps('placeOfService')}
            />
            {/* empty div to make the half-width on the above text input work correctly */}
            <div style={{ flex: 1 }} />
          </Group>
          <CheckboxGroup label='Plan types' {...form.getInputProps('planTypes')}>
            <Checkbox value='medicare' label='Medicare' />
            <Checkbox value='medicaid' label='Medicaid' />
            <Checkbox value='commercial' label='Commercial' />
          </CheckboxGroup>
          <RadioGroup label='Timeframe' {...form.getInputProps('type')}>
            <Radio value='visits' label='Initial + subsequent visits' />
            <Radio value='months' label='1-3 months, 4+ months' />
          </RadioGroup>
          {arrayIncludes(form.values.planTypes, 'medicare') && form.values.type && (
            <ServiceLinesSection
              type={form.values.type}
              values={form.values.serviceLines.medicare}
              setFieldValue={form.setFieldValue}
              removeListItem={form.removeListItem}
              planType='medicare'
            />
          )}
          {arrayIncludes(form.values.planTypes, 'medicaid') && form.values.type && (
            <ServiceLinesSection
              type={form.values.type}
              values={form.values.serviceLines.medicaid}
              setFieldValue={form.setFieldValue}
              removeListItem={form.removeListItem}
              planType='medicaid'
            />
          )}
          {arrayIncludes(form.values.planTypes, 'commercial') && form.values.type && (
            <ServiceLinesSection
              type={form.values.type}
              values={form.values.serviceLines.commercial}
              setFieldValue={form.setFieldValue}
              removeListItem={form.removeListItem}
              planType='commercial'
            />
          )}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <PrimaryButton
          onClick={onSubmit}
          style={{ float: 'right' }}
          loading={createPayerConfig.isLoading || updatePayerConfig.isLoading}
          leftIcon={<SaveIcon />}
        >
          Save Changes
        </PrimaryButton>
      </DrawerFooter>
    </>
  )
}

export const PayerConfigDrawer = (props: PayerConfigDrawerProps) => {
  return (
    <BetterDrawer position='right' size='sm' opened={props.opened} onClose={props.onClose}>
      <PayerConfigDrawerContentManager {...props} />
    </BetterDrawer>
  )
}
