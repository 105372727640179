import { UseFormReturnType } from '@mantine/form'
import {
  Alert,
  BookmarkIcon,
  DailyCalendar,
  DailyCalendarRadioGroup,
  DatePicker,
  Group,
  InfoIcon,
  Pill,
  Radio,
  Stack,
  Text,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { CalendarDaysDateslot, SchedulingFormData } from './SchedulerDrawer'
import { TimeZoneSelect } from './TimeZoneSelect'
import { CalendarSlotsData } from './use-calendar'

type NextAvailableSchedulerProps = {
  schedulingForm: UseFormReturnType<SchedulingFormData>
  today: dayjs.Dayjs
  maxDate: dayjs.Dayjs
  isLoading: boolean
  availableCalendarDates: CalendarDaysDateslot[]
  availableAppointments: CalendarSlotsData[]
  hasInsurance: boolean
}

export const NextAvailableScheduler = ({
  schedulingForm,
  today,
  maxDate,
  isLoading,
  availableCalendarDates,
  availableAppointments,
  hasInsurance,
}: NextAvailableSchedulerProps) => {
  return (
    <>
      <Group grow>
        <DatePicker
          {...schedulingForm.getInputProps('date')}
          label='Schedule a date for the visit'
          clearable={false}
          minDate={today.toDate()}
          maxDate={maxDate.toDate()}
          placeholder='Select day...'
          excludeDate={(date: Date) =>
            !availableCalendarDates?.some(slot => dayjs(slot.date).isSame(date, 'day'))
          }
        />

        <TimeZoneSelect
          {...schedulingForm.getInputProps('timezone')}
          label='Time zone'
          disabled={isLoading}
          patientTimeZone={schedulingForm.values.timezone}
        />
      </Group>
      {Boolean(schedulingForm.values.date) && (
        <Stack>
          {!hasInsurance && (
            <Alert variant='warning' icon={<BookmarkIcon />}>
              Scheduling as cash pay. If this patient has insurance, add that in the left sidebar
              before proceeding.
            </Alert>
          )}
          <DailyCalendar
            {...schedulingForm.getInputProps('date')}
            range={availableCalendarDates?.map(slot => slot.date) ?? []}
          >
            <DailyCalendarRadioGroup
              {...schedulingForm.getInputProps('datetime')}
              loading={availableAppointments[0]?.loading}
              empty={
                <Alert variant='secondary' icon={<InfoIcon />}>
                  There are no available options to select on this day. Select another date.
                </Alert>
              }
            >
              {availableAppointments[0]?.slots.map(slot => (
                <Radio
                  key={slot.time}
                  value={slot.time}
                  label={
                    <Group position='apart'>
                      <Text>
                        {dayjs(slot.time).tz(schedulingForm.values.timezone).format('h:mma z')}
                      </Text>
                      {slot.isPriority && (
                        <Pill status='success' variant='filled'>
                          Preferred
                        </Pill>
                      )}
                    </Group>
                  }
                  disabled={isLoading}
                />
              ))}
            </DailyCalendarRadioGroup>
          </DailyCalendar>
        </Stack>
      )}
    </>
  )
}
