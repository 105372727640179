import { Skeleton, Stack, Text } from '@shared/components'
import { template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../api'
import EmptyDataCell from '../../../../components/atoms/EmptyDataCell'
import { MentalHealthEvalFormDiagnosis } from '../formHelpers'

export type MentalHealthDiagnosesReadOnlySectionProps = {
  diagnoses: MentalHealthEvalFormDiagnosis[]
}

export const MentalHealthDiagnosesReadOnlySection = ({
  diagnoses,
}: MentalHealthDiagnosesReadOnlySectionProps) => {
  const [behavioralHealthCodesQueryKey, behavioralHealthCodesQueryFn] = emrApi.getQuery(
    'GET /icd-10-codes',
    {
      query: {
        type: 'behavioralHealth',
      },
    },
  )
  const behavioralHealthCodesQuery = useQuery(
    behavioralHealthCodesQueryKey,
    behavioralHealthCodesQueryFn,
  )

  if (diagnoses?.length) {
    return (
      <>
        {diagnoses.map(diagnosis => {
          const fullDescription =
            behavioralHealthCodesQuery.data?.find(record => record.code === diagnosis.code)
              ?.full_description ?? diagnosis.code
          return (
            <Stack spacing='xs' key={diagnosis.code}>
              {behavioralHealthCodesQuery.isLoading ? (
                <Skeleton height={10} />
              ) : (
                <Text>{fullDescription}</Text>
              )}
              <Text size='xs' color={colors => colors.text[1]}>
                {template(['{code}', '| {progression}', '| {chronicity}', '| {comment}'], {
                  code: diagnosis.code,
                  progression: capitalize(diagnosis.progression),
                  chronicity: capitalize(diagnosis.chronicity),
                  comment: diagnosis.comment,
                })}
              </Text>
            </Stack>
          )
        })}
      </>
    )
  }
  return <EmptyDataCell />
}
