import { useForm } from '@mantine/form'
import {
  Banner,
  BetterDrawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  PrimaryButton,
  Stack,
  Textarea,
  useBanner,
  validateWith,
} from '@shared/components'
import { InvoiceListItem, getOpheliaHttpError } from '@shared/types'
import { inputCharacterLimitExceeded } from '../../../utils/formValidation'
import { useEmrMutation, useInvalidateQuery } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'

type VoidInvoiceDrawerProps = {
  invoice: InvoiceListItem
  opened: boolean
  onClose: () => void
}

export const VoiceInvoiceDrawerContent = (props: VoidInvoiceDrawerProps) => {
  const { showBanner } = useBanner()
  const invalidateQuery = useInvalidateQuery()
  const { patientQuery } = usePatient()

  const form = useForm({
    initialValues: {
      voidReason: '',
    },
    validate: {
      voidReason: validateWith(
        inputCharacterLimitExceeded({
          // Stripe's metadata value limit is 500 characters
          characterLimit: 500,
        }),
      ),
    },
  })

  const voidInvoice = useEmrMutation('POST /invoices/:invoiceId/void')

  const onVoidInvoice = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await voidInvoice.mutateAsync({
      params: {
        invoiceId: props.invoice.oid,
      },
      data: {
        details: form.values.voidReason ?? null,
      },
    })

    const patientFirstName = patientQuery?.data?.personalData?.firstName || ''
    const bannerLabel = `${patientFirstName}'s invoice was successfully voided`

    showBanner({
      type: 'success',
      label: bannerLabel,
      dismissable: true,
    })

    form.reset()

    void invalidateQuery('GET /invoices', {
      query: {
        patientId: props.invoice.patientId || '',
      },
    })

    props.onClose()
  }

  return (
    <>
      <DrawerHeader onClose={props.onClose}>Void invoice</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          {voidInvoice.error && (
            <Banner
              type='error'
              label={getOpheliaHttpError(voidInvoice.error, 'Error voiding invoice')}
            />
          )}
          <Textarea label='Void reason (optional)' {...form.getInputProps('voidReason')} />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton onClick={() => onVoidInvoice()} loading={voidInvoice.isLoading}>
            Void invoice
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const VoidInvoiceDrawer = (props: VoidInvoiceDrawerProps) => {
  return (
    <BetterDrawer size='sm' position='right' onClose={props.onClose} opened={props.opened}>
      <VoiceInvoiceDrawerContent {...props} />
    </BetterDrawer>
  )
}
