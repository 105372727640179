import { Box, Checkbox, Group, Radio, RadioGroup, Stack, Text, TextInput } from '@shared/components'
import { DiagnosisChronicity, DiagnosisProgression } from '@shared/types'
import { useMentalHealthEvaluationFormContext } from '../formHelpers'

export type MentalHealthDiagnosisItemProps = {
  fullDescription: string
  checked: boolean
  path: `diagnoses.${number}` | ''
  code: string
  checkboxOnChange: (checked: boolean) => void
  onDiagnosisCompleted: (diagnosis: {
    code: string
    full_description: string
    chronicity: DiagnosisChronicity
    progression: DiagnosisProgression
  }) => void
}

export const MentalHealthDiagnosisItem = ({
  fullDescription,
  checked,
  path,
  code,
  checkboxOnChange,
  onDiagnosisCompleted,
}: MentalHealthDiagnosisItemProps) => {
  const form = useMentalHealthEvaluationFormContext()
  const progressionInputProps = form.getInputProps(`${path}.progression`)
  const chronicityInputProps = form.getInputProps(`${path}.chronicity`)

  return (
    <Stack>
      <Checkbox
        checked={checked}
        label={fullDescription}
        onChange={event => {
          checkboxOnChange(event.target.checked)
        }}
      />
      {checked && (
        <Stack spacing='sm' mb='md'>
          <Group>
            <Box w='30%'>
              <Text>Progression</Text>
            </Box>
            <RadioGroup
              orientation='horizontal'
              sx={{ flexBasis: 0, flexGrow: 1 }}
              {...progressionInputProps}
              onChange={value => {
                progressionInputProps.onChange(value)
                const diagnosisItem = form.values.diagnoses.find(
                  diagnosis => diagnosis.code === code,
                )
                const progression = value as DiagnosisProgression
                const chronicity = diagnosisItem?.chronicity ?? ''
                if (progression && chronicity) {
                  onDiagnosisCompleted({
                    code,
                    full_description: fullDescription,
                    chronicity,
                    progression,
                  })
                }
              }}
            >
              <Radio label='Stable' value='stable' sx={{ flexBasis: 0, flexGrow: 1 }} />
              <Radio label='Exacerbating' value='exacerbating' sx={{ flexBasis: 0, flexGrow: 1 }} />
            </RadioGroup>
          </Group>
          <Group>
            <Box w='30%'>
              <Text>Acuity</Text>
            </Box>
            <RadioGroup
              orientation='horizontal'
              sx={{ flexBasis: 0, flexGrow: 1 }}
              {...chronicityInputProps}
              onChange={value => {
                chronicityInputProps.onChange(value)
                const diagnosisItem = form.values.diagnoses.find(
                  diagnosis => diagnosis.code === code,
                )
                const progression = diagnosisItem?.progression ?? ''
                const chronicity = value as DiagnosisChronicity

                if (progression && chronicity) {
                  onDiagnosisCompleted({
                    code,
                    full_description: fullDescription,
                    chronicity,
                    progression,
                  })
                }
              }}
            >
              <Radio label='Acute' value='acute' sx={{ flexBasis: 0, flexGrow: 1 }} />
              <Radio label='Chronic' value='chronic' sx={{ flexBasis: 0, flexGrow: 1 }} />
            </RadioGroup>
          </Group>
          <Group>
            <Box w='30%'>
              <Text>Comment (optional)</Text>
            </Box>
            <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
              <TextInput {...form.getInputProps(`${path}.comment`)} />
            </Box>
          </Group>
        </Stack>
      )}
    </Stack>
  )
}
