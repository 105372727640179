import {
  Box,
  Center,
  Group,
  Image,
  OpheliaWordmark,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { activeStates } from '@shared/types'
import sample from 'lodash/sample'
import { useEffect } from 'react'
import { analytics } from '../analytics'
import { Config } from '../config'
import { useAuth } from '../context/auth'
import * as Fullstory from '../utils/fullstory'

export const PLogin = () => {
  const auth = useAuth()
  // eslint-disable-next-line no-magic-numbers
  const age = Math.floor(Math.random() * 40) + 18
  const state = activeStates[Math.floor(Math.random() * activeStates.length)]!
  const descriptor = sample(['brother', 'sister', 'son', 'daughter', 'cousin', 'co-worker'])

  useLifecycle({
    onMount: () => {
      window.scrollTo(0, 0)
      analytics.page({ name: 'Login' })
      window.google.accounts.id.initialize({
        client_id: Config.GOOGLE_AUTH_KEY,
        callback: onSuccess,
        auto_select: false,
        ux_mode: 'popup',
      })

      window.google.accounts.id.prompt()
    },
  })

  useEffect(() => {
    window.google.accounts.id.renderButton(document.getElementById('signin'), {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: 'signin',
    })
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = async (response: any) => {
    const { credential } = response
    // Upon success, this will trigger a callback in onIdTokenChanged (see auth context for more details)
    try {
      await auth.signInWithGoogleIdToken(credential)
      Fullstory.log('debug', 'Login Attempt Succeeded')
    } catch (err) {
      Fullstory.log('debug', 'Login Attempt Failed')
    }
  }

  return (
    <Center sx={{ height: '80vh' }}>
      <Group spacing='lg'>
        <Image src={process.env.REACT_APP_LOGIN_PAGE_LOGO_URL} width={500} />
        <Stack>
          <Box
            sx={theme => ({
              color: theme.other.colors.actions[1],
              width: `calc(${theme.spacing.xl} * 2)`,
            })}
          >
            <OpheliaWordmark />
          </Box>
          <Stack spacing='xs'>
            <TitleTwo>
              Someone&apos;s {age}-year-old {descriptor} in {state.state}.
            </TitleTwo>
            <TitleTwo>Life saving treatment.</TitleTwo>
            <TitleTwo>Thanks to you.</TitleTwo>
            <Text>Keep up the good work.</Text>
          </Stack>

          <div id='signin' />
        </Stack>
      </Group>
    </Center>
  )
}
