import {
  InsuranceSelect,
  InsuranceSelectProps,
} from '../../../../../components/forms/InsuranceSelect'
import { usePatientVisitNote } from '../PatientVisitNoteContext'

export const PatientVisitNoteInsuranceSelect = (props: InsuranceSelectProps) => {
  const { canEdit } = usePatientVisitNote()

  return <InsuranceSelect disabled={!canEdit} {...props} />
}
