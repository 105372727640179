import { useForm } from '@mantine/form'
import {
  Alert,
  AlertIcon,
  ArrowLeftCircleIcon,
  Checkbox,
  Group,
  Modal,
  Stack,
  TertiaryButton,
  TitleThree,
} from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { EmployeeMentionNoteInput } from '../../../components/forms/EmployeeMentionNoteInput'
import { useAuth } from '../../../context/auth'
import { TaskProgress } from './TaskProgress'
import { ReactNode } from 'react'

export const useNoteForm = () => useForm({ initialValues: { note: '', keepAssigned: true } })

type Props = {
  task: EMRTaskDecorated
  onClose: () => void
  onBack: () => void
  step: { current: number; total: number }
  title: string
  form: ReturnType<typeof useNoteForm>
  allowUnassign?: boolean
  footer?: ReactNode
  error?: string
}

export const TaskNoteForm = ({
  task,
  onClose,
  onBack,
  step,
  title,
  form,
  allowUnassign,
  footer,
  error,
}: Props) => {
  const auth = useAuth()
  const assigneeId = task.employeeId || ''
  const hasAssignee = Boolean(assigneeId)
  const isAssigneeCurrentUser = assigneeId === auth.currentUser.oid

  return (
    <Modal
      opened
      onClose={onClose}
      title={
        <Group>
          <TertiaryButton ml='xs' leftIcon={<ArrowLeftCircleIcon styled />} onClick={onBack} />
          <TitleThree>{title}</TitleThree>
        </Group>
      }
      footer={<Group position='right'>{footer}</Group>}
    >
      <Stack>
        <TaskProgress step={step.current} total={step.total} />
        <EmployeeMentionNoteInput
          label='Add note (optional)'
          explanation='Your name and the time will be automatically added to the note'
          {...form.getInputProps('note')}
        />
        {hasAssignee && allowUnassign && (
          <Checkbox
            label={
              isAssigneeCurrentUser
                ? 'Keep myself assigned to this issue'
                : `Keep ${task.metadata.employeeName} assigned to this issue`
            }
            {...form.getInputProps('keepAssigned', { type: 'checkbox' })}
          />
        )}
        {error && (
          <Alert icon={<AlertIcon />} variant='error'>
            {error}
          </Alert>
        )}
      </Stack>
    </Modal>
  )
}
