import {
  Box,
  Group,
  MantineColor,
  PhoneIncomingIcon,
  PhoneMissedIcon,
  PhoneOutgoingIcon,
  Text,
  colorToHex,
  useMantineTheme,
} from '@shared/components'
import { formatDurationFromSeconds } from '../../../../../utils/communications'
import { PhoneCallStatus, getTwilioStatusText } from '../../../../../utils/twilio'
import { CommunicationsCard, CommunicationsCardProps } from '../CommunicationsCard'

type CallItemProps = {
  status: PhoneCallStatus
  duration: number
  iconColor: MantineColor
  direction: 'inbound' | 'outbound'
} & CommunicationsCardProps

export const CallItem = ({
  status,
  duration,
  iconColor,
  direction,
  backgroundColor,
  textColor,
  ...props
}: CallItemProps) => {
  const {
    radius,
    other: { colors },
  } = useMantineTheme()

  const getCallBody = () => {
    if (status === 'completed') {
      return formatDurationFromSeconds(duration)
    }

    return getTwilioStatusText(status)
  }

  const getStatusIcon = () => {
    if (['queued', 'ringing', 'in-progress', 'completed'].includes(status)) {
      return direction === 'outbound' ? (
        <PhoneOutgoingIcon xTransform={-1} yTransform={1} />
      ) : (
        <PhoneIncomingIcon xTransform={-1} yTransform={1} />
      )
    }
    return <PhoneMissedIcon color={colors.error[0]} xTransform={-1} yTransform={1} />
  }

  const iconWithBackground = (backgroundColor: MantineColor) => {
    return (
      <Box
        sx={{
          height: 'fit-content',
          width: 'fit-content',
          borderRadius: radius.lg,
          background: colorToHex(backgroundColor, colors),
        }}
        p='sm'
      >
        {getStatusIcon()}
      </Box>
    )
  }

  return (
    <CommunicationsCard backgroundColor={backgroundColor || colors.background[2]} {...props}>
      <Group>
        {direction === 'outbound' && (
          <Text color={textColor || colors.text[1]}>{getCallBody()}</Text>
        )}
        {iconWithBackground(iconColor || colors.background[0])}
        {direction === 'inbound' && (
          <Text color={textColor || colors.text[1]}>{getCallBody()}</Text>
        )}
      </Group>
    </CommunicationsCard>
  )
}
