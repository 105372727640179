import { DoseSpotMedication, DoseSpotMedicationSearchResult } from '@shared/types'
import { useQuery } from 'react-query'
import { prescriptionsApi } from '../../../../api'
import { buildSearchString, searchForExactMedication } from '../../../../utils/prescriptionUtils'
type OnSuccessFunction = (
  medication: DoseSpotMedication,
  searchResult: DoseSpotMedicationSearchResult,
) => void
type OnErrorFunction = (err: string) => void
type UseMedicationInfoQueryType = {
  onSuccess?: OnSuccessFunction
  onError?: OnErrorFunction
  medicationName?: string
  medicationStrength?: string
  dispensableDrugId?: number
  enabled: boolean
}

/*
 * @dosespotV2Migration
 * To get medication information in v2, we only need to send the dispensableDrugId.
 * Once we fully migrate to v2 we will deprecate the medication and medication_strength fields.
 */
export const useMedicationInfoQuery = ({
  onSuccess,
  onError,
  enabled,
  medicationName,
  medicationStrength,
  dispensableDrugId,
}: UseMedicationInfoQueryType) => {
  const medicationInfo = useQuery(
    [
      'prescriptionsApi.getMedication',
      'searchForExactMedication',
      medicationName,
      medicationStrength,
      dispensableDrugId,
    ],
    () => {
      return Promise.all([
        prescriptionsApi.getMedication({
          medication: medicationName || '',
          medication_strength: medicationStrength || '',
          dispensableDrugId,
        }),
        searchForExactMedication({
          medicationName: buildSearchString({
            displayName: medicationName || '',
            strength: medicationStrength || '',
          }),
          dispensableDrugId,
        }),
      ])
    },
    {
      onSuccess: ([medication, searchResult]: [
        DoseSpotMedication,
        DoseSpotMedicationSearchResult,
      ]) => {
        if (onSuccess) {
          onSuccess(medication, searchResult)
        }
      },
      onError,
      enabled,
    },
  )
  return medicationInfo
}
