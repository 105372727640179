import { useToggle } from '@mantine/hooks'
import {
  DatePicker,
  Drawer,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
} from '@shared/components'
import { FullWorkflowState, PromptResponsePayload } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation, useQueryClient } from 'react-query'
import { emrApi } from '../../api'
import {
  WorkflowFormProvider,
  WorkflowFormType,
  buildResponseSubmissionPayload,
  convertFullWorkflowStateToFormValues,
  getWorkflowValidationRules,
  useWorkflowForm,
} from '../../utils/workflowUtils'
import { Workflow } from './Workflow'

export type WorkflowDrawerProps = {
  fullWorkflowState: FullWorkflowState
  title: string
  opened: boolean
  onClose: () => void
  onSubmit: (responses: { promptId: string; promptResponse: PromptResponsePayload }[]) => void
  saveResponsesOnClose: boolean
  componentDisplayedWhileSubmitting?: React.ReactNode
  subHeader?: React.ReactNode
  patientId: string
  datePickerValue?: string | undefined
  onDatePickerValueChange?: (value: string) => void
  allFieldsOptional?: boolean
}

export const WorkflowDrawer = ({
  fullWorkflowState,
  title,
  subHeader,
  opened,
  onClose,
  onSubmit,
  saveResponsesOnClose,
  componentDisplayedWhileSubmitting,
  patientId,
  datePickerValue,
  onDatePickerValueChange,
  allFieldsOptional = false,
}: WorkflowDrawerProps) => {
  const queryClient = useQueryClient()

  const [isWarningModalOpened, toggleWarningModal] = useToggle()

  const submitWorkflowResponses = useMutation(
    emrApi.getMutation('POST /workflow/session/:sessionId'),
    {
      onSettled: () => {
        const [sessionInfoQueryKey] = emrApi.getQuery('GET /workflow/session/:sessionId/info', {
          params: {
            sessionId: fullWorkflowState.workflowSession.oid,
          },
        })
        void queryClient.invalidateQueries(sessionInfoQueryKey)
      },
    },
  )
  const form = useWorkflowForm({
    validate: getWorkflowValidationRules({ fullWorkflowState, allFieldsOptional }),
    initialValues: convertFullWorkflowStateToFormValues(fullWorkflowState),
  })

  const submitFormValues = (values: WorkflowFormType) => {
    const responses = buildResponseSubmissionPayload(fullWorkflowState, values)
    submitWorkflowResponses.mutate(
      {
        params: {
          sessionId: fullWorkflowState.workflowSession.oid,
        },
        data: {
          responses,
          markComplete: true,
          allFieldsOptional,
          patientId,
          ...(datePickerValue && { completedAt: datePickerValue }),
        },
      },
      {
        onSuccess: () => {
          onSubmit(responses)
        },
      },
    )
  }

  const sessionInProgress = Object.values(form.values).some(value => Boolean(value))

  return (
    <>
      <Drawer
        position='right'
        opened={opened}
        onClose={async () => {
          if (saveResponsesOnClose && fullWorkflowState.workflowSession.status !== 'complete') {
            onClose()
            const responses = buildResponseSubmissionPayload(fullWorkflowState, form.values)
            await submitWorkflowResponses.mutateAsync({
              params: {
                sessionId: fullWorkflowState.workflowSession.oid,
              },
              data: {
                responses,
                markComplete: false,
                allFieldsOptional,
                patientId,
                ...(datePickerValue && { createdAt: datePickerValue }),
              },
            })
          }
          // NOTE: Only display the warning modal if the form responses are not empty
          if (sessionInProgress) {
            toggleWarningModal(true)
          } else {
            onClose()
          }
        }}
        title={title}
        size='lg'
        footer={
          submitWorkflowResponses.isLoading ? undefined : (
            <Group position='right'>
              <PrimaryButton onClick={() => form.onSubmit(submitFormValues)()}>
                Submit
              </PrimaryButton>
            </Group>
          )
        }
        id='workflowDrawer'
        trapFocus={false}
      >
        <Stack p='md'>
          {datePickerValue && (
            <DatePicker
              label='Date'
              clearable={false}
              maxDate={dayjs().toDate()}
              placeholder='Select day...'
              value={datePickerValue}
              onChange={onDatePickerValueChange}
            />
          )}
          <WorkflowFormProvider form={form}>
            {submitWorkflowResponses.isLoading ? (
              componentDisplayedWhileSubmitting
            ) : (
              <>
                {subHeader}
                <Workflow workflowState={fullWorkflowState} />
              </>
            )}
          </WorkflowFormProvider>
        </Stack>
      </Drawer>
      <Modal
        opened={isWarningModalOpened}
        onClose={() => toggleWarningModal(false)}
        title='Your progress will be lost'
        footer={
          <Group position='right'>
            <SecondaryButton onClick={() => toggleWarningModal(false)}>
              No, don&apos;t close
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                toggleWarningModal(false)
                onClose()
              }}
            >
              Yes, close and lose progress
            </PrimaryButton>
          </Group>
        }
      >
        <Text>
          By closing right now, your progress will be lost and the objective measure will not be
          submitted. Are you sure you want to proceed?
        </Text>
      </Modal>
    </>
  )
}
