import { useToggle } from '@mantine/hooks'
import { CgSpinner } from '@react-icons/all-files/cg/CgSpinner'
import { HiOutlineLockClosed } from '@react-icons/all-files/hi/HiOutlineLockClosed'
import { IoIosSave } from '@react-icons/all-files/io/IoIosSave'
import { Tooltip, ZapIcon } from '@shared/components'
import { PatientStatus, hasStatus } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import React, { useEffect, useState } from 'react'
import ODSIconButton from '../../../components/ods/ODSIconButton'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import { useFlags } from '../../../utils/hooks'
import { usePatient } from '../PPatientContext'
import { SmartPhraseDrawer } from './SmartPhraseDrawer'

const MINUTES_TO_SUBTRACT = 10
const INTERVAL = toTime('1 min').ms()

type MVisitNoteSavingHeaderProps = {
  saving?: boolean
  dateTime?: string
  editMode: boolean
  saveNote: React.MouseEventHandler<HTMLButtonElement>
  primaryButtonMessage?: 'Sign' | 'Finish'
  hasInvalidDiagnosis?: boolean
  invalidPatientStatuses?: PatientStatus[]
  mustCompleteDischargeNote?: boolean
}

const MVisitNoteSavingHeader = ({
  saving,
  editMode,
  dateTime,
  saveNote,
  primaryButtonMessage = 'Sign',
  hasInvalidDiagnosis = false,
  invalidPatientStatuses = [],
  mustCompleteDischargeNote,
}: MVisitNoteSavingHeaderProps) => {
  const { patientQuery } = usePatient()
  const { smartPhrases } = useFlags()

  // let notes be locked 10 min before, so that ECs can take calls early
  const [futureNote, setFutureNote] = useState<boolean>(
    dayjs(dateTime).subtract(MINUTES_TO_SUBTRACT, 'minutes').isAfter(dayjs()),
  )
  const [isSmartPhraseDrawerOpened, toggleSmartPhraseDrawer] = useToggle()
  useEffect(() => {
    setInterval(() => {
      setFutureNote(dayjs(dateTime).subtract(MINUTES_TO_SUBTRACT, 'minutes').isAfter(dayjs()))
    }, INTERVAL)
  }, [])

  const patient = patientQuery?.data

  const isPatientStatusInvalid = invalidPatientStatuses.some(status => hasStatus(patient, status))

  let tooltipContent = 'Invalid patient note'

  if (futureNote) {
    tooltipContent = 'You cannot lock a future visit note'
  } else if (hasInvalidDiagnosis) {
    tooltipContent = 'You cannot lock a note with an invalid diagnosis'
  } else if (isPatientStatusInvalid) {
    tooltipContent = `You cannot lock this type of note for a patient with a status of ${patient?.statuses?.patient}`
  } else if (mustCompleteDischargeNote) {
    tooltipContent = 'You must complete the discharge notes before locking this note'
  }

  return (
    <div className='flex justify-end items-center w-full h-full'>
      {/* The smart phrase modal enables clinicians to select a smart phrase even if they're in a Tailwind component */}
      <SmartPhraseDrawer
        opened={isSmartPhraseDrawerOpened}
        onClose={() => toggleSmartPhraseDrawer(false)}
      />
      <span className='ml-3 inline-flex rounded-md shadow-sm'>
        {smartPhrases && (
          <ODSIconButton
            icon={<ZapIcon />}
            tooltipText='Smart phrases'
            onClick={() => toggleSmartPhraseDrawer(true)}
            className='mr-3'
          />
        )}
        <ODSIconButton
          tooltipText='Save'
          onClick={saveNote}
          icon={
            saving ? (
              <CgSpinner className='h-5 w-5 text-indigo-600 animate-spin' />
            ) : (
              <IoIosSave className='h-5 w-5' />
            )
          }
          className='mr-3'
        />
        <Tooltip
          disabled={
            !isPatientStatusInvalid &&
            !futureNote &&
            !hasInvalidDiagnosis &&
            !mustCompleteDischargeNote
          }
          label={tooltipContent}
          position='bottom'
          offset={5}
        >
          <span>
            <ODSPrimaryButton
              type='submit'
              icon={<HiOutlineLockClosed className='-ml-1 mr-3 h-5 w-5' />}
              message={primaryButtonMessage}
              disabled={
                !editMode ||
                futureNote ||
                isPatientStatusInvalid ||
                hasInvalidDiagnosis ||
                mustCompleteDischargeNote
              }
            />
          </span>
        </Tooltip>
      </span>
    </div>
  )
}

export default MVisitNoteSavingHeader
