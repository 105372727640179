import { useForm } from '@mantine/form'
import {
  Alert,
  AlertIcon,
  ArrowLeftCircleIcon,
  Group,
  Modal,
  PrimaryButton,
  SearchIcon,
  SecondaryButton,
  Select,
  Stack,
  TertiaryButton,
  Text,
  TitleThree,
  useMantineTheme,
} from '@shared/components'
import { EMRTaskDecorated } from '@shared/types'
import { EmployeeMentionNoteInput } from '../../../components/forms/EmployeeMentionNoteInput'
import { useEmployees } from '../../../utils/hooks'
import { TaskProgress } from './TaskProgress'
import { canViewIssues } from './canViewIssues'

export type NoteFormProps = {
  assignee: string
  note: string
}

export type Props = {
  step: { current: number; total: number }
  initialValues?: NoteFormProps
  onClose: () => void
  onSubmit: (data: NoteFormProps) => void
  onBack: () => void
  isSubmitting: boolean
  error?: string
  currentStatus: EMRTaskDecorated['status']
}

export const NoteForm = (props: Props) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const { values, getInputProps } = useForm<NoteFormProps>({
    initialValues: props.initialValues,
  })

  const employeesQuery = useEmployees({ status: 'currentEmployee' })

  const employees = (employeesQuery.data || []).filter(canViewIssues).map(employee => ({
    value: employee.oid,
    label: employee.name,
  }))

  const goBackText = `No, keep ${props.currentStatus}`

  return (
    <Modal
      opened
      onClose={() => {
        props.onClose()
      }}
      title={
        <Group>
          <TertiaryButton
            ml='xs'
            leftIcon={<ArrowLeftCircleIcon styled />}
            onClick={props.onBack}
          />
          <TitleThree>Move issue from in progress to do</TitleThree>
        </Group>
      }
      footer={
        <Group position='right'>
          <SecondaryButton
            loading={employeesQuery.isLoading}
            onClick={() => {
              props.onClose()
            }}
          >
            {goBackText}
          </SecondaryButton>
          <PrimaryButton
            loading={employeesQuery.isLoading || props.isSubmitting}
            onClick={() => {
              props.onSubmit(values)
            }}
          >
            Yes, move back to open
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <TaskProgress step={props.step.current} total={props.step.total} />
        <Text>Since you picked it up, how much time did you spend on the issue?</Text>
        <Select
          searchable
          clearable
          label='DRI (optional)'
          placeholder='Search by name...'
          icon={<SearchIcon />}
          data={employees}
          {...getInputProps('assignee')}
        />
        <EmployeeMentionNoteInput
          label='Add note (optional)'
          placeholder='Type here...'
          explanation='Your name and the time will be automatically added to the note'
          {...getInputProps('note')}
        />
        <Text size='xs' color={colors.text[1]}>
          This estimate is true and accurate to the best of my knowledge. I understand that I am
          being asked for this estimate for insurance billing purposes.
        </Text>
        {props.error && (
          <Alert icon={<AlertIcon />} variant='error'>
            {props.error}
          </Alert>
        )}
      </Stack>
    </Modal>
  )
}
