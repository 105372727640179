// eslint-disable-next-line no-restricted-imports
import { Checkbox } from '@mantine/core'
import {
  CheckboxGroup,
  Group,
  Flex,
  PackageIcon,
  PencilIcon,
  PrimaryButton,
  Radio,
  RadioGroup,
  ScrollArea,
  SecondaryButton,
  Stack,
  Tooltip,
  TrashCanIcon,
  SlashIcon,
  Text,
} from '@shared/components'
import { useState } from 'react'

export const CollectionsColumn = ({
  list,
  selectedCollection,
  setSelectedCollection,
  editing,
  toggleIsEditing,

  onDelete,
  onTransfer,
}: {
  selectedCollection: string
  setSelectedCollection: (value: string) => void
  list: string[] | undefined
  editing: 'collections' | 'documents' | 'json' | null
  toggleIsEditing: () => void
  onDelete: (list: string[]) => void
  onTransfer: (list: string[]) => void
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const onClickEdit = () => {
    toggleIsEditing()
    setSelectedItems([])
  }

  const isItemsSelected = selectedItems.length > 0

  return (
    <Flex
      sx={({ other: { colors }, radius }) => ({
        flex: 6,
        backgroundColor: colors.background[1],
        borderRadius: radius.sm,
        overflow: 'hidden',
      })}
    >
      <Stack w='100%' spacing='sm'>
        <Group m='sm' position='apart'>
          <Text bold>Path</Text>
          <Group spacing='sm'>
            {editing === 'collections' ? (
              <>
                <Tooltip label='Delete'>
                  <SecondaryButton
                    onClick={() => onDelete(selectedItems)}
                    disabled={!isItemsSelected}
                    leftIcon={<TrashCanIcon />}
                    size='xs'
                  />
                </Tooltip>
                <Tooltip label='Transfer'>
                  <SecondaryButton
                    onClick={() => onTransfer(selectedItems)}
                    disabled={!isItemsSelected}
                    leftIcon={<PackageIcon />}
                    size='xs'
                  />
                </Tooltip>
                <Tooltip label='Cancel edit'>
                  <PrimaryButton onClick={onClickEdit} leftIcon={<SlashIcon />} size='xs' />
                </Tooltip>
              </>
            ) : (
              <Tooltip label='Edit'>
                <PrimaryButton
                  disabled={editing !== null}
                  onClick={onClickEdit}
                  leftIcon={<PencilIcon />}
                  size='xs'
                />
              </Tooltip>
            )}
          </Group>
        </Group>
        <ScrollArea mx='xs' w='100%'>
          <RadioGroup px='xs' value={selectedCollection} onChange={setSelectedCollection}>
            <CheckboxGroup spacing='xs' value={selectedItems} onChange={setSelectedItems}>
              {list?.map(value => (
                <Radio
                  disabled={editing !== null && editing !== 'collections'}
                  key={value}
                  iconless
                  value={value}
                  label={
                    <Group spacing='sm' noWrap>
                      {editing === 'collections' && (
                        <Checkbox color='dark' sx={{ pointerEvents: 'all' }} value={value} />
                      )}
                      {value}
                    </Group>
                  }
                />
              ))}
            </CheckboxGroup>
          </RadioGroup>
        </ScrollArea>
      </Stack>
    </Flex>
  )
}
