import {
  DischargeNote,
  DischargeNoteModel,
  DrugScreenResults,
  FileCategory,
  Form,
  GetPatientTaskStatusSummaryResponse,
  IneligibleNoteModel,
  InsuranceType,
  ManualInsuranceData,
  NonVisitEventModel,
  PatientFile,
} from '@shared/types'
import { BaseApi, toTime } from '@shared/utils'

type Res = 'OK'

export class PatientsApi extends BaseApi {
  deleteNonVisitEvent = ({
    patientId,
    nonVisitEventId,
  }: {
    patientId: string
    nonVisitEventId: string
  }) => {
    return this.json<Res>({
      method: 'DELETE',
      url: `/patient/${patientId}/nonVisitEvents/${nonVisitEventId}`,
    })
  }

  createDoseSpotPatient = (patientId: string) => {
    return this.json<Res>({ method: 'POST', url: `/patient/${patientId}/dosespot` })
  }

  createIneligibleNote = (
    // 'employeeId' is mixed in on the backend based on the auth token for the current user
    data: Omit<IneligibleNoteModel, 'employeeId'>,
  ) => {
    return this.json<void>({
      method: 'POST',
      url: '/ineligibleNotes',
      data,
    })
  }

  updatePatientAuthData = ({
    patientId,
    data,
  }: {
    patientId: string
    data: { email?: string; phone?: string }
  }) => {
    return this.request<{ fileId: string }>({
      method: 'PUT',
      url: `/patient/${patientId}/auth`,
      data,
    })
  }

  cancelAppointment = ({
    patientID,
    appointmentID,
    data,
    params,
  }: {
    patientID: string
    appointmentID: string | undefined
    params?: { noEmail: boolean }
    data?: { noShow: boolean }
  }) => {
    return this.json<void>({
      method: 'POST',
      url: `/patient/${patientID}/appointments/${appointmentID}/cancel`,
      data,
      params,
    })
  }

  createNonVisitEvent = (data: NonVisitEventModel) => {
    return this.json({ method: 'POST', url: `/patient/${data.patientId}/nonVisitEvents`, data })
  }

  deleteFile = ({ patientId, fileId }: { patientId: string; fileId: string }) => {
    return this.json<{ fileId: string }>({
      method: 'DELETE',
      url: `/patient/${patientId}/files/${fileId}`,
    })
  }

  addFile = (arg: { patientId: string; category: FileCategory; name: string; type: string }) => {
    return this.json<{ signedUrl: string; fileId: string }>({
      method: 'POST',
      url: `/patient/${arg.patientId}/files`,
      data: {
        category: arg.category,
        name: arg.name,
        type: arg.type,
      },
    })
  }

  createReengagementNote = (data: { patientId: string; note: Record<string, string> }) => {
    return this.json<void>({
      method: 'POST',
      url: '/reengagementNotes',
      data,
    })
  }

  getFile = (arg: { patientId: string; fileId: string }) => {
    return this.json<{ signedUrl: string; category: FileCategory; name: string; type: string }>({
      method: 'GET',
      url: `/patient/${arg.patientId}/files/${arg.fileId}/meta`,
    })
  }

  verifyInsuranceData = ({
    patientId,
    data,
  }: {
    patientId: string
    data: {
      firstName: string
      lastName: string
      memberId: string
      eligiblePayerId: string
      insuranceType: InsuranceType
    }
  }) => {
    return this.json({
      method: 'POST',
      url: `/patient/${patientId}/insurance/verify`,
      data,
    })
  }

  updateFile = ({
    patientId,
    fileId,
    data,
  }: {
    patientId: string
    fileId: string
    data: PatientFile
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/patient/${patientId}/files/${fileId}/meta`,
      data,
      // fail fast so retries can happen sooner
      timeout: toTime('3 sec').ms(),
    })
  }

  updateVisitNote = ({
    patientID,
    visitID,
    body,
  }: {
    patientID: string
    visitID?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any
  }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/patient/${patientID}/visits/${visitID}/notes`,
      data: body,
    })
  }

  listDischargeNotes = (params: { patientId: string }) => {
    return this.json<DischargeNote[]>({
      method: 'GET',
      url: '/dischargeNotes',
      params,
    })
  }

  createDischargeNote = (data: { patientId: string; note: DischargeNoteModel }) => {
    return this.json({
      method: 'POST',
      url: '/dischargeNotes',
      data,
    })
  }

  listReengagementNotes = (params: { patientId: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.json<any[]>({
      method: 'GET',
      url: '/reengagementNotes',
      params,
    })
  }

  listIneligibleNotes = (params: { patientId: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.json<any[]>({
      method: 'GET',
      url: '/ineligibleNotes',
      params,
    })
  }

  getTaskStatusSummary = (arg: { patientId: string }) => {
    return this.json<GetPatientTaskStatusSummaryResponse>({
      method: 'GET',
      url: `/patient/${arg.patientId}/tasks`,
    })
  }

  getImpersonationToken = ({ patientId, reason }: { patientId: string; reason: string }) => {
    return this.json<string>({
      method: 'POST',
      url: `/patient/${patientId}/impersonate`,
      data: {
        reason,
      },
    })
  }

  createDrugScreen = ({
    patientId,
    ...data
  }: {
    patientId: string
    authorId: string
    createdAt: string
    administeredAt: string
    results: DrugScreenResults
  }) => {
    return this.json<Promise<void>>({
      method: 'POST',
      url: `/patient/${patientId}/drugScreens/create`,
      data,
    })
  }

  getIntakeForm = (patientId: string) => {
    return this.json<Form>({
      method: 'GET',
      url: `/patient/${patientId}/intake`,
    })
  }

  addToInsuranceAllowList = (data: { userId: string }) => {
    return this.json<void>({
      method: 'POST',
      url: '/insurance/patients/allow',
      data,
    })
  }

  removeFromInsuranceAllowList = (data: { userId: string }) => {
    return this.json<void>({
      method: 'DELETE',
      url: '/insurance/patients/allow',
      data,
    })
  }

  createPriorAuthorization = ({
    patientId,
    data,
  }: {
    patientId: string
    data: {
      drug?: string
      quantity?: string
      duration?: string
      startDate?: string
      endDate?: string
    }
  }) => {
    return this.json<void>({
      method: 'POST',
      url: `/patient/${patientId}/priorAuthorizations`,
      data,
    })
  }

  deletePriorAuthorization = ({
    patientId,
    priorAuthorizationId,
  }: {
    patientId: string
    priorAuthorizationId: string
  }) => {
    return this.json<void>({
      method: 'DELETE',
      url: `/patient/${patientId}/priorAuthorizations/${priorAuthorizationId}`,
    })
  }

  updateManualInsuranceData = ({
    patientId,
    data,
  }: {
    patientId: string
    data: ManualInsuranceData & { insuranceType?: InsuranceType | undefined }
  }) => {
    return this.json<void>({
      method: 'PUT',
      url: `/patient/${patientId}/insurance/update/manual`,
      data,
    })
  }
}
