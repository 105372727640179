import { Group, PlusIcon, PrimaryButton, Stack, Text } from '@shared/components'
import { EMRTask } from '@shared/types'
import { sortBy } from '@shared/utils'
import { useSidePane } from '../../../utils/hooks/use-side-pane'
import { IssueDrawer } from '../../care_team/tasks/IssueDrawer'
import { TaskEntry } from '../../care_team/tasks/TaskEntry'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

type IssuesContentProps = {
  open: EMRTask[]
  snoozed: EMRTask[]
}

export const IssuesContent = ({ open, snoozed }: IssuesContentProps) => {
  const { patientQuery } = usePatient()
  const { presentPane, hidePane } = useSidePane()

  const patient = patientQuery?.data

  const isEmpty = open.length === 0 && snoozed.length === 0

  const showIssueNotesDrawer = (taskId: EMRTask['oid']) => {
    presentPane({
      key: `issue-notes-${taskId}`,
      content: <IssueDrawer step='notes' taskId={taskId} onClose={hidePane} />,
    })
  }

  return (
    <Stack spacing='sm'>
      {isEmpty ? (
        <SidebarEmptyState
          textMessage={`Woohoo! Nothing pressing to resolve for ${
            patient?.personalData.preferredName || patient?.personalData.firstName
          }.`}
          pillMessage='No open or snoozed issues'
        />
      ) : (
        <>
          {open.length > 0 && (
            <>
              <Text size='xs' color={colors => colors.text[1]}>
                Open issues
              </Text>
              {open.sort(sortBy({ order: 'DESC', key: 'createdAt' })).map(task => (
                <TaskEntry
                  background={({ background }) => background[0]}
                  key={task.oid}
                  task={task}
                  openNotes={taskId => showIssueNotesDrawer(taskId)}
                />
              ))}
            </>
          )}
          {snoozed.length > 0 && (
            <>
              <Text size='xs' color={colors => colors.text[1]}>
                Snoozed issues
              </Text>
              {snoozed.sort(sortBy({ order: 'DESC', key: 'createdAt' })).map(task => (
                <TaskEntry
                  background={({ background }) => background[0]}
                  key={task.oid}
                  task={task}
                  openNotes={taskId => showIssueNotesDrawer(taskId)}
                />
              ))}
            </>
          )}
        </>
      )}
      <Group position='right'>
        <PrimaryButton
          size='xs'
          leftIcon={<PlusIcon />}
          onClick={() => {
            presentPane({
              key: `add-issue-${patient?.oid}`,
              content: <IssueDrawer step='add' patient={patient} onClose={hidePane} />,
            })
          }}
        >
          Add issue
        </PrimaryButton>
      </Group>
    </Stack>
  )
}
