import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Config } from '../config'
import { useAuth } from '../context/auth'

export const LDContext = React.createContext<{
  ldClient: LDClient | undefined
  isLdClientLoading: boolean
}>({
  ldClient: undefined,
  isLdClientLoading: false,
})

const retrieveLdClient = async ({
  key,
  name,
  email,
  role,
}: {
  key: string | null | undefined
  name: string | null | undefined
  email: string | null | undefined
  role: string | null | undefined
}) => {
  const ldClient = initialize(
    Config.LAUNCH_DARKLY_CLIENT_ID,
    {
      key: key ?? 'tempKey',
      name: name ?? '',
      email: email ?? '',
      custom: { role: role ?? '' },
    },
    {
      logger: {
        // eslint-disable-next-line no-console
        error: console.error,
        // Add stubs for non-error logs, as these are otherwise too noisy.
        warn: () => void 0,
        debug: () => void 0,
        info: () => void 0,
      },
      sendEventsOnlyForVariation: true,
      streaming: true,
    },
  )

  await ldClient.waitUntilReady()
  return ldClient
}

export const LaunchDarklyProvider = ({ children }: { children: React.ReactNode }) => {
  const { firebaseUser, currentUser } = useAuth()

  const { data, isLoading, refetch } = useQuery(
    [
      'retrieveLdClient',
      firebaseUser?.uid,
      firebaseUser?.displayName,
      firebaseUser?.email,
      currentUser.role,
    ],
    () =>
      retrieveLdClient({
        key: firebaseUser?.uid,
        name: firebaseUser?.displayName,
        email: firebaseUser?.email,
        role: currentUser.role,
      }),
  )

  useEffect(() => {
    if (data) {
      data?.on('change', refetch)
      return () => data.off('change', refetch)
    }
  }, [data, refetch])

  return (
    <LDContext.Provider value={{ ldClient: data, isLdClientLoading: isLoading }}>
      {children}
    </LDContext.Provider>
  )
}
