import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@shared/components'
import { useMutation, useQueryClient } from 'react-query'
import { prescriptionsApi } from '../../api'

type PrescriptionDeleteModalProps = {
  onClose: () => void
  patientId: string
  prescriptionId: number
  setPrescriptionBanner: (message: string, type: 'success' | 'warning' | 'error') => void
}

const PrescriptionDeleteContent = ({
  onClose,
  patientId,
  prescriptionId,
  setPrescriptionBanner,
}: PrescriptionDeleteModalProps) => {
  const queryClient = useQueryClient()

  const deletePrescription = useMutation(prescriptionsApi.deletePrescription, {
    onSuccess: () => {
      void queryClient.invalidateQueries()
      setPrescriptionBanner('Prescription successfully deleted', 'success')
      onClose()
    },
    onError: () => {
      setPrescriptionBanner('Something went wrong, unable to cancel prescription', 'error')
    },
  })

  return (
    <>
      <ModalHeader onClose={onClose}>Delete prescription</ModalHeader>
      <ModalContent>
        <Text>
          By deleting this prescription, it will not send to the pharmacy and will no longer be
          viewable. Are you sure you want to proceed?
        </Text>
      </ModalContent>
      <ModalFooter>
        <Group position='right'>
          <SecondaryButton onClick={onClose}>No, don’t delete</SecondaryButton>
          <PrimaryButton
            loading={deletePrescription.isLoading}
            onClick={() =>
              deletePrescription.mutate({
                patientId,
                prescriptionId: prescriptionId.toString(),
              })
            }
          >
            Yes, delete
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </>
  )
}

export const PrescriptionDeleteModal = ({
  opened,
  ...props
}: PrescriptionDeleteModalProps & { opened: boolean }) => {
  return (
    <BetterModal opened={opened} onClose={props.onClose}>
      <PrescriptionDeleteContent {...props} />
    </BetterModal>
  )
}
