import { Divider, Group, Stack, Tabs, TitleThree, UnstyledButton, XIcon } from '@shared/components'
import { OutOfOfficeForm } from './OutOfOfficeForm'
import { ScheduleVisit, ScheduleVisitProps } from './ScheduleVisit'

type AddCalendarEventProps = ScheduleVisitProps

export const AddCalendarEvent = (props: AddCalendarEventProps) => {
  return (
    <Stack w='100%' sx={{ borderLeftWidth: '1px' }}>
      {props.showTitle && (
        <Group position='apart' px='md'>
          <TitleThree>Manage calandar</TitleThree>
          <UnstyledButton onClick={props.onCloseDrawer}>
            <XIcon />
          </UnstyledButton>
        </Group>
      )}
      <Divider px='md' />
      <Tabs defaultValue='visit' h='100%' sx={{ minHeight: '0px' }}>
        <Tabs.List>
          <Tabs.Tab value='visit'>Visit</Tabs.Tab>
          <Tabs.Tab value='out-of-office'>Out of office</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='visit' sx={{ minHeight: '0px' }}>
          <Stack w='100%' justify='space-between' h='100%'>
            <ScheduleVisit {...props} showTitle={false} />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value='out-of-office' h='100%' sx={{ minHeight: '0px' }}>
          <OutOfOfficeForm calendarId={props.calendarId} onClose={props.onCloseDrawer} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
