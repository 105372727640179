import { DoseSpotPharmacy, DoseSpotPharmacySearchRequestQuery } from '@shared/types'
import { BaseApi } from '@shared/utils'

type Res = 'OK'

export class PharmaciesApi extends BaseApi {
  retrieve = (patientId: string) => {
    return this.json<DoseSpotPharmacy>({ method: 'GET', url: `/patient/${patientId}/pharmacy` })
  }

  update = ({ patientId, pharmacyId }: { patientId: string; pharmacyId: number }) => {
    return this.json<Res>({
      method: 'PUT',
      url: `/patient/${patientId}/pharmacy`,
      data: {
        pharmacyId,
      },
    })
  }

  search = ({
    patientId,
    params,
  }: {
    patientId: string
    params: DoseSpotPharmacySearchRequestQuery
  }) => {
    return this.json<DoseSpotPharmacy[]>({
      method: 'GET',
      url: `/pharmacy/search`,
      params,
      data: {
        patientId,
      },
    })
  }
}
