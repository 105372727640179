import { Banner, Collapse } from '@shared/components'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useOutage } from './use-outage'

type Banner = {
  message: string
  dismissable?: boolean
}

type Context = {
  banner: Banner | undefined
  bannerVisible: boolean
  hideBanner: () => void
}

const initialContext: Context = {
  banner: undefined,
  bannerVisible: false,
  hideBanner: () => void 0,
}

const OutageBannerContext = createContext<Context>(initialContext)

export const OutageBannerProvider = ({ children }: { children: React.ReactNode }) => {
  const [banner, setBanner] = useState<Banner | undefined>(undefined)
  const [visible, setVisible] = useState(false)
  const emrAppOutageBanner = useOutage()

  useEffect(() => {
    if (emrAppOutageBanner?.enabled) {
      setBanner({
        message: emrAppOutageBanner.message,
        dismissable: true,
      })
    }
    setVisible(Boolean(emrAppOutageBanner?.enabled))
  }, [emrAppOutageBanner])

  const context: Context = {
    banner,
    bannerVisible: visible,
    hideBanner: useCallback(() => setVisible(false), []),
  }

  return <OutageBannerContext.Provider value={context}>{children}</OutageBannerContext.Provider>
}

export const useOutageBanner = () => {
  return useContext(OutageBannerContext)
}

export const OutageBanner = () => {
  const { banner, bannerVisible, hideBanner } = useOutageBanner()

  return (
    <Collapse in={bannerVisible}>
      <Banner type='warning' label={banner?.message ?? ''} onClick={hideBanner} />
    </Collapse>
  )
}
