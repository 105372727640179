import { useForm } from '@mantine/form'
import { Grid, Stack, validateWith } from '@shared/components'
import { EncounterPatientData, stateAbbreviations } from '@shared/types'
import { useEffect } from 'react'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { validDateOfBirth } from '../../../../utils/encounterUtils'
import { isAbbreviatedUsState, isRequired, isZipCode } from '../../../../utils/formValidation'
import { CardHeader } from '../components/CardHeader'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'

export type PatientSectionProps = {
  patientData: EncounterPatientData
  isEditing: boolean
  editButtonEnabled: boolean
  setEditing: (bool: boolean) => void
  onSave: (patientData: EncounterPatientData) => Promise<void>
  saving: boolean
}

export const PatientSection = ({
  patientData,
  onSave,
  saving,
  isEditing,
  editButtonEnabled,
  setEditing,
}: PatientSectionProps) => {
  const form = useForm<EncounterPatientData>({
    initialValues: {
      first_name: patientData.first_name || '',
      last_name: patientData.last_name || '',
      date_of_birth: patientData.date_of_birth || '',
      gender: patientData.gender || '',
      address: {
        address1: patientData.address?.address1 || '',
        address2: patientData.address?.address2 || '',
        city: patientData.address?.city || '',
        state: patientData.address?.state || '',
        zip_code: patientData.address?.zip_code || '',
      },
    },
    validate: {
      first_name: validateWith(isRequired),
      last_name: validateWith(isRequired),
      date_of_birth: validDateOfBirth,
      gender: (value?: string) => {
        if (!value) {
          return 'Required'
        }
      },
      address: {
        address1: validateWith(isRequired),
        city: validateWith(isRequired),
        state: validateWith(isRequired, isAbbreviatedUsState),
        zip_code: validateWith(isRequired, isZipCode),
      },
    },
    clearInputErrorOnChange: false,
  })

  useEffect(() => {
    form.setValues({
      first_name: patientData.first_name || '',
      last_name: patientData.last_name || '',
      date_of_birth: patientData.date_of_birth || '',
      gender: patientData.gender || '',
      address: {
        address1: patientData.address?.address1 || '',
        address2: patientData.address?.address2 || '',
        city: patientData.address?.city || '',
        state: patientData.address?.state || '',
        zip_code: patientData.address?.zip_code || '',
      },
    })
    form.validate()
  }, [patientData])

  useEffect(() => {
    form.validate()
  }, [form.values])

  return (
    <Stack>
      <CardHeader
        title='Patient'
        showButton={editButtonEnabled}
        buttonOnClick={() => setEditing(true)}
        errorCount={Object.keys(form.errors).length}
        buttonLabel='Edit'
      />
      <Grid columns={12}>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('first_name')}
            label='First name'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('last_name')}
            label='Last name'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('date_of_birth')}
            label='Date of birth'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            {...form.getInputProps('gender')}
            label='Gender'
            editable={isEditing}
            data={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'other', label: 'Other' },
              { value: 'not_given', label: 'Not given' },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('address.address1')}
            label='Address line 1'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('address.address2')}
            label='Address line 2 (optional)'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('address.city')}
            label='City'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardSelect
            data={stateAbbreviations}
            searchable
            {...form.getInputProps('address.state')}
            label='State'
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CardTextInput
            {...form.getInputProps('address.zip_code')}
            label='Zip'
            editable={isEditing}
          />
        </Grid.Col>
      </Grid>
      {isEditing ? (
        <EditableSectionFooter
          onCancel={() => {
            setEditing(false)
            form.setValues(patientData)
          }}
          onSave={form.onSubmit(async () => {
            await onSave(form.values)
            setEditing(false)
          })}
          isSaving={saving}
        />
      ) : null}
    </Stack>
  )
}
