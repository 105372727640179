import { PrimaryButton, Stack, Text, TitleThree } from '@shared/components'
import { InsuranceReviewQueueItem } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'

export type IRQAdminSectionProps = {
  item: InsuranceReviewQueueItem
  refreshQueue: () => void
}

const IRQAdminSection = ({ item, refreshQueue }: IRQAdminSectionProps) => {
  const updatePatient = useMutation(emrApi.getMutation('PUT /patient/:patientId'))

  const remove = async () => {
    await updatePatient.mutateAsync({
      params: { patientId: item.userId },
      data: {
        insuranceData: {
          insuranceQueueData: null,
        },
      },
    })

    refreshQueue()
  }

  return (
    <>
      <TitleThree mb='md'>Lead and admin actions</TitleThree>
      <Stack>
        <Text>
          Clicking &apos;Remove from queue&apos; will permanently delete this patient from the queue
          without sending them any messages.
        </Text>
        <PrimaryButton onClick={remove}>Remove from queue</PrimaryButton>
      </Stack>
    </>
  )
}

export default IRQAdminSection
