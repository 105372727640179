import { Stack, Text } from '@shared/components'
import { DischargeNoteModel, dischargeVisitStatusMapping } from '@shared/types'

export const DischargeVisitSection = ({ dischargeNote }: { dischargeNote: DischargeNoteModel }) => {
  return dischargeNote.discharge_visit_status ? (
    <Stack spacing='xs'>
      <Text bold>Discharge visit</Text>
      <Text>{dischargeVisitStatusMapping[dischargeNote.discharge_visit_status]}</Text>
    </Stack>
  ) : null
}
