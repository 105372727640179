import {
  Center,
  CheckCircleIcon,
  EM_DASH,
  Group,
  PlusIcon,
  Text,
  useMantineTheme,
} from '@shared/components'
import { SlotEventForRender } from '@shared/types'
import { dayjs, template } from '@shared/utils'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'
import { CalendarEvent } from './CalendarEvent'
import { useVisitTypes } from './useVisitTypes'

export type SlotEventProps = {
  patientId: string | undefined
  block: SlotEventForRender
}

export const SlotEvent = ({ patientId, block }: SlotEventProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const { selected } = block.event
  const { visitTypes } = useVisitTypes({ patientId: patientId ?? '' })
  const visitTypeName = visitTypes.find(type => {
    return type.value === block.event.appointmentType
  })?.label

  const patientQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId', {
      params: {
        patientId: patientId ?? '',
      },
    }),
    {
      enabled: Boolean(patientId),
    },
  )
  const patientTimezone = patientQuery?.data?.account?.timezone

  return (
    <CalendarEvent
      color={selected ? colors.actions[0] : colors.background[0]}
      borderColor={colors.actions[0]}
      duration={block.duration}
      pulse
      tooltip={
        <>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {visitTypeName}
          </Text>
          <Text size='xs' bold color={colors => colors.text[3]}>
            {template(`{start} {dash} {end}`, {
              start: dayjs(block.start).format('h:mma'),
              dash: EM_DASH,
              end: dayjs(block.start).add(block.duration, 'minutes').format('h:mma'),
            })}
          </Text>
          {patientTimezone && (
            <Text size='xs' bold color={colors => colors.text[3]}>
              {template(`Patient time zone: {start} {dash} {end}`, {
                start: dayjs(block.start).tz(patientTimezone).format('h:mma'),
                dash: EM_DASH,
                end: dayjs(block.start)
                  .tz(patientTimezone)
                  .add(block.duration, 'minutes')
                  .format('h:mma'),
              })}
            </Text>
          )}
        </>
      }
    >
      <Center
        sx={{ height: '100%', cursor: 'pointer' }}
        onClick={() => {
          block.event.onSelect()
        }}
      >
        {!selected && (
          <Group spacing='sm' noWrap>
            <PlusIcon size='md' />
            <Text bold>{dayjs(block.start).format('h:mma z')}</Text>
          </Group>
        )}
        {selected && (
          <Group spacing='sm' noWrap>
            <CheckCircleIcon size='md' />
            <Text bold>{dayjs(block.start).format('h:mma z')}</Text>
          </Group>
        )}
      </Center>
    </CalendarEvent>
  )
}
