import { useForm } from '@mantine/form'
import {
  Banner,
  BetterDrawer,
  DollarSignIcon,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  NumberInput,
  PrimaryButton,
  Select,
  Stack,
  showNotification,
  validateWith,
} from '@shared/components'
import { PaymentPlan, getOpheliaHttpError } from '@shared/types'
import { CENTS_PER_DOLLAR, template } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import { isRequired } from '../../../utils/formValidation'
import { useEmrMutation, useEmrQuery, useInvalidateQuery } from '../../../utils/hooks'

type AddAbpPaymentDrawerProps = {
  paymentPlan: PaymentPlan
  stripePaymentIntentIdToReplace: string
  opened: boolean
  onClose: () => void
}

export const AddPaymentDrawerContent = (props: AddAbpPaymentDrawerProps) => {
  const invalidate = useInvalidateQuery()

  const { oid: paymentPlanId, totalAmountInCents, paymentDates, patientId } = props.paymentPlan

  const paymentAmountInCents = totalAmountInCents / paymentDates.length

  const paymentAmountInDollars = paymentAmountInCents / CENTS_PER_DOLLAR

  const form = useForm({
    initialValues: {
      method: '',
    },
    validate: {
      method: validateWith(isRequired),
    },
  })

  const methodsQuery = useEmrQuery(
    'GET /patient/:patientId/payment/methods',
    {
      params: {
        patientId,
      },
    },
    {
      onSuccess: paymentMethods => {
        const defaultMethod = paymentMethods?.find(method => method.isDefault)
        // pre-select the default payment method
        if (defaultMethod) {
          form.setValues({
            method: defaultMethod.id,
          })
        }
      },
    },
  )

  const methods = (methodsQuery.data || []).map(method => {
    return {
      value: method.id,
      label: template('{brand} (*{last4}) {isDefault}', {
        brand: capitalize(method.brand),
        last4: method.last4,
        isDefault: method.isDefault ? '(Default)' : '',
      }),
    }
  })

  const addPayment = useEmrMutation('POST /payment-plan/:paymentPlanId/retry-payment')

  const onPay = async () => {
    if (form.validate().hasErrors) {
      return
    }

    await addPayment.mutateAsync(
      {
        params: {
          paymentPlanId,
        },
        data: {
          stripePaymentMethodId: form.values.method,
          stripePaymentIntentIdToRetry: props.stripePaymentIntentIdToReplace,
        },
      },
      {
        onSuccess: () => {
          void invalidate('GET /patient/:patientId/payment-plans', {
            params: {
              patientId,
            },
          })

          void invalidate('GET /payment-plan/payments', {
            query: {
              paymentPlanId,
            },
          })

          void invalidate('GET /invoices', {
            query: {
              patientId,
            },
          })

          showNotification({
            message:
              'Successfully processed the payment. It may take a few moments for the changes to reflect in the EMR.',
            variant: 'success',
          })

          form.reset()
        },
      },
    )

    props.onClose()
  }

  return (
    <>
      <DrawerHeader onClose={props.onClose}>Add Payment</DrawerHeader>
      <DrawerContent>
        <Stack p='md'>
          {addPayment.error && (
            <Banner
              type='error'
              label={getOpheliaHttpError(addPayment.error, 'Error adding payment')}
            />
          )}
          <NumberInput
            label='Amount'
            icon={<DollarSignIcon />}
            precision={2}
            value={paymentAmountInDollars}
            disabled
          />
          <Select label='Method' data={methods} {...form.getInputProps('method')} />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton onClick={() => onPay()} loading={addPayment.isLoading}>
            Add payment
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}

export const AddAbpPaymentDrawer = (props: AddAbpPaymentDrawerProps) => {
  return (
    <BetterDrawer size='sm' position='right' onClose={props.onClose} opened={props.opened}>
      <AddPaymentDrawerContent {...props} />
    </BetterDrawer>
  )
}
