import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  Box,
  Group,
  PrimaryButton,
  SaveIcon,
  Skeleton,
  Space,
  Stack,
  Switch,
  Text,
  validateWith,
} from '@shared/components'
import { EMRTaskDecorated, hasRole, updateTaskTypes } from '@shared/types'
import Confetti from 'react-confetti'
import { useMutation, useQuery } from 'react-query'
import { emrApi } from '../../../../api'
import { EmployeeMentionNoteInput } from '../../../../components/forms/EmployeeMentionNoteInput'
import { useAuth } from '../../../../context/auth'
import { isRequired } from '../../../../utils/formValidation'
import { AttachmentsSection } from './AttachmentsSection'
import { NotesEntry } from './NotesEntry'

type Form = { text: string }

export type NotesLogProps = {
  task: EMRTaskDecorated
}

export const NotesLog = ({ task }: NotesLogProps) => {
  const { currentUser } = useAuth()

  const [showUpdateTasks, toggleShowUpdateTasks] = useToggle()

  const { values, setFieldValue, validate, getInputProps, reset } = useForm<Form>({
    validate: {
      text: validateWith(isRequired),
    },
  })

  const taskId = task?.oid

  const [notesQueryKey, notesQueryFn] = emrApi.getQuery('GET /tasks/v2/:taskId/notes', {
    params: {
      taskId: taskId || '',
    },
  })
  const notesQuery = useQuery(notesQueryKey, notesQueryFn, {
    enabled: Boolean(taskId),
  })
  const notesData = notesQuery.data || []
  const notes = showUpdateTasks
    ? notesData
    : notesData.filter(note => !updateTaskTypes.includes(note.type))

  const createNoteMutation = useMutation(emrApi.getMutation('POST /tasks/v2/:taskId/batch'))

  const onSubmit = async () => {
    if (!task || !taskId) {
      return
    }

    if (validate().hasErrors) {
      return
    }

    await createNoteMutation.mutateAsync(
      {
        params: { taskId: task.oid },
        data: { note: { text: values.text } },
      },
      {
        onSuccess: async () => {
          reset()
          await notesQuery.refetch()
          setFieldValue('text', '')
        },
      },
    )
  }

  return (
    <Stack>
      {values.text === '5000' && (
        <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={5000} />
      )}
      {task.status !== 'closed' && (
        <Box mx='md'>
          <EmployeeMentionNoteInput
            label='Add note'
            placeholder='Type here...'
            explanation='Your name and the time will be automatically added to the note'
            {...getInputProps('text')}
          />
          <PrimaryButton
            mt='md'
            leftIcon={<SaveIcon />}
            onClick={onSubmit}
            loading={createNoteMutation.isLoading}
          >
            Save note
          </PrimaryButton>
        </Box>
      )}
      <AttachmentsSection task={task} />
      <Group mx='md' position='apart'>
        <Text bold>Notes log</Text>
        {hasRole(currentUser, 'engineer') && (
          <Text size='xs' color={({ text }) => text[1]}>
            {task.oid}
          </Text>
        )}
        <Switch
          size='sm'
          label='Assignee & status updates'
          checked={showUpdateTasks}
          onClick={() => toggleShowUpdateTasks()}
        />
      </Group>
      {notesQuery.isFetching && (
        <Stack spacing='md' px='md'>
          <Skeleton h={24} />
          <Skeleton h={24} />
        </Stack>
      )}
      {!notesQuery.isFetching && notes.length === 0 && <Text mx='md'>No notes</Text>}
      {!notesQuery.isFetching && notes.length > 0 && (
        <Stack spacing='lg' mx='md'>
          {notes.map(note => (
            <NotesEntry key={note.oid} note={note} />
          ))}
        </Stack>
      )}
      <Space h='sm' />
    </Stack>
  )
}
