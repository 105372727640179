import { useForm } from '@mantine/form'
import {
  Checkbox,
  Grid,
  PrimaryButton,
  ScrollAreaAutosize,
  Select,
  skipIfEmpty,
  Stack,
  Text,
  TextInput,
  TitleTwo,
  validateWith,
} from '@shared/components'
import {
  DoseSpotPharmacy,
  DoseSpotPharmacySearchRequestQuery,
  stateAbbreviations,
} from '@shared/types'
import { sortBy } from '@shared/utils'
import { useQuery } from 'react-query'
import { emrApi, pharmaciesApi } from '../../../api'
import { isZipCode } from '../../../utils/formValidation'
import PharmacyRow from './PharmacyRow'

export type MEditPharmacyFormProps = {
  patientId: string
  onSelectPharmacy: (pharmacy?: DoseSpotPharmacy) => void
  selectedPharmacy?: DoseSpotPharmacy
  alwaysSetDefault?: boolean
  title: string
  setShouldSetPreferredPharmacy?: (val: boolean) => void
  shouldSetPreferredPharmacy?: boolean
}

export type PharmacyFormData = {
  name: string
  phoneOrFax: string
  address: string
  city: string
  state: string
  zip: string
  searchOption: string
}

const MEditPharmacyForm = ({
  patientId,
  onSelectPharmacy,
  selectedPharmacy,
  alwaysSetDefault = false,
  setShouldSetPreferredPharmacy,
  shouldSetPreferredPharmacy,
  title,
}: MEditPharmacyFormProps) => {
  const form = useForm<PharmacyFormData>({
    initialValues: {
      searchOption: 'All pharmacies',
      name: '',
      phoneOrFax: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    validate: {
      zip: validateWith(skipIfEmpty, isZipCode),
    },
  })

  const getValues = (keys: (keyof PharmacyFormData)[]): Partial<PharmacyFormData> => {
    return keys.reduce(
      (prev, currentKey) => ({ ...prev, [currentKey]: form.values[currentKey] }),
      {} as Partial<PharmacyFormData>,
    )
  }

  const pharmacySearch = useQuery(
    [
      'pharmaciesApi.search',
      {
        ...getValues(['name', 'phoneOrFax', 'address', 'city', 'state', 'zip', 'searchOption']),
      },
    ],
    () => {
      const query = {
        name: form.values.name,
        phoneOrFax: form.values.phoneOrFax,
        address: form.values.address,
        city: form.values.city,
        state: form.values.state,
        zip: form.values.zip,
      } as DoseSpotPharmacySearchRequestQuery

      if (form.values.searchOption === 'Retail pharmacies only') {
        query['specialty[0]'] = '8'
      } else if (form.values.searchOption === 'Mail-order pharmacies only') {
        query['specialty[0]'] = '1'
      }

      /*
       * @dosespotV2Migration
       * Include the patientId to identify whether we should use the v1 or v2 API
       */
      return pharmaciesApi.search({ patientId, params: query })
    },
    {
      enabled: false,
    },
  )
  const dosespotCurrentPharmacy = useQuery(['pharmaciesApi.retrieve', patientId], () =>
    pharmaciesApi.retrieve(patientId),
  )

  const patientRecents = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/prescriptions', {
      params: { patientId },
      query: { doseSpotOnly: 'true', useDoseSpot: 'true' },
    }),
  )

  const sortedRecents = patientRecents.data?.sort(sortBy({ key: 'writtenDate', order: 'DESC' }))
  const mostRecentPharmacy = sortedRecents?.[0]?.pharmacyId

  // This makes the patient's most recent pharmacy and the preferred pharmacy appear at the top of the list
  const sortedPharmacySearch = pharmacySearch.data?.sort((a, b) => {
    const preferredPharmacyId = dosespotCurrentPharmacy.data?.PharmacyId
    if (a.PharmacyId === preferredPharmacyId) {
      return -1
    }

    if (b.PharmacyId === preferredPharmacyId) {
      return 1
    }

    if (a.PharmacyId === mostRecentPharmacy) {
      return -1
    }

    if (b.PharmacyId === mostRecentPharmacy) {
      return 1
    }

    return 0
  })

  return (
    <Stack>
      <Grid columns={12}>
        <Grid.Col span={12}>
          <TitleTwo> {title}</TitleTwo>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text>
            Include as much information as you can. For best results, include the pharmacy phone
            number and/or zip code.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            {...form.getInputProps('name')}
            label='Pharmacy name (optional)'
            placeholder='e.g. CVS'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            {...form.getInputProps('phoneOrFax')}
            label='Pharmacy phone number (optional)'
            placeholder='+1 XXX (XXX) – XXXX'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            {...form.getInputProps('address')}
            label='Address (optional)'
            placeholder='e.g. 101 N Main Street'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps('city')}
            label='City (optional)'
            placeholder='e.g. New York'
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            {...form.getInputProps('state')}
            label='State (optional)'
            placeholder='Select one...'
            data={stateAbbreviations}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            {...form.getInputProps('zip')}
            label='Zip code (optional)'
            placeholder='#####'
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            {...form.getInputProps('searchOption')}
            label='Search'
            data={['All pharmacies', 'Retail pharmacies only', 'Mail-order pharmacies only']}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <PrimaryButton
            loading={pharmacySearch.isFetching}
            onClick={() => pharmacySearch.refetch()}
            type='button'
          >
            Search
          </PrimaryButton>
        </Grid.Col>
      </Grid>
      {!pharmacySearch.isFetching && pharmacySearch.data && pharmacySearch.data.length === 0 && (
        <span>No results found for these fields.</span>
      )}
      {!pharmacySearch.isFetching && (
        <ScrollAreaAutosize maxHeight='25vh'>
          <Stack pr='md' pl='xs' pt='xs' sx={() => ({ flexShrink: 1 })}>
            {sortedPharmacySearch?.map((dsPharmacy: DoseSpotPharmacy) => {
              return (
                <PharmacyRow
                  key={dsPharmacy.PharmacyId}
                  selected={selectedPharmacy?.PharmacyId === dsPharmacy.PharmacyId}
                  pharmacy={dsPharmacy}
                  onSelect={(dsPharmacy?: DoseSpotPharmacy) => {
                    onSelectPharmacy(dsPharmacy)
                  }}
                  isPreferred={dosespotCurrentPharmacy.data?.PharmacyId === dsPharmacy.PharmacyId}
                  isMostRecent={mostRecentPharmacy === dsPharmacy.PharmacyId}
                />
              )
            })}
          </Stack>
        </ScrollAreaAutosize>
      )}
      {!alwaysSetDefault &&
        selectedPharmacy &&
        selectedPharmacy?.PharmacyId !== dosespotCurrentPharmacy.data?.PharmacyId &&
        setShouldSetPreferredPharmacy && (
          <Checkbox
            label={`Set as patient's preferred pharmacy`}
            checked={shouldSetPreferredPharmacy}
            onChange={event => {
              setShouldSetPreferredPharmacy(event.target.checked)
            }}
          />
        )}
    </Stack>
  )
}

export default MEditPharmacyForm
