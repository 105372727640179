import { ScrollAreaAutosize, Stack } from '@shared/components'
import { PropsWithChildren } from 'react'

export const FixedDrawerScrollArea = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      h='100%'
      w='100%'
      style={{
        wordBreak: 'break-word',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <ScrollAreaAutosize>{children}</ScrollAreaAutosize>
    </Stack>
  )
}
