import { List, ListItem, Stack, Text } from '@shared/components'
import { DischargeNoteModel, dischargeNoteMapping, reasonsForDischarge } from '@shared/types'

export const ReasonsForDischargeSection = ({
  dischargeNote,
}: {
  dischargeNote: DischargeNoteModel
}) => {
  // Some reasons for discharge are booleans and some are yes/no values
  const shouldShowDischargeReason = (reason: string | boolean | undefined) => {
    if (typeof reason === 'boolean') {
      return reason
    }

    if (typeof reason === 'string') {
      return 'yes' === reason.toLowerCase()
    }

    return false
  }

  return (
    <Stack spacing='xs'>
      <Text bold>Reasons for discharge</Text>
      <List spacing='xs' listStyleType='disc'>
        {reasonsForDischarge.map(
          reason =>
            shouldShowDischargeReason(dischargeNote?.[reason]) &&
            dischargeNoteMapping?.[reason] && (
              <ListItem key={reason}>{dischargeNoteMapping?.[reason]}</ListItem>
            ),
        )}
        {dischargeNote.relocation && <ListItem>Relocation</ListItem>}
        {dischargeNote.additional_explanation_for_discharge && (
          <ListItem>{`Other: ${dischargeNote.additional_explanation_for_discharge}`}</ListItem>
        )}
      </List>
    </Stack>
  )
}
