import { Box, Group, Stack, Text, UserIcon } from '@shared/components'
import { VisitNoteType } from '@shared/types'
import { useQuery } from 'react-query'
import { formsApi } from '../../../../api'
import { FORMS_ENUM } from '../../../../utils/utils'
import AScreeningFormQuestionAnswer from '../AScreeningFormQuestionAnswer'
import { ScreeningFormForm } from '../OScreeningFormSidebar'
import { usePatientVisitNote } from './PatientVisitNoteContext'
import { PatientVisitNoteLoader } from './visitNoteForm/PatientVisitNoteLoader'

export const PatientVisitNoteSidebar = () => {
  const { patientId, visitNoteQuery } = usePatientVisitNote()

  const formsQuery = useQuery(['formsApi.getForms', patientId], () =>
    formsApi.getForms(patientId, { formType: FORMS_ENUM.screening }),
  )
  const forms = formsQuery.data || []
  const screeningForm = forms[forms.length - 1] as ScreeningFormForm

  const screeningFormVisitTypes: VisitNoteType[] = ['consultation', 'reenrollment']
  const shouldShowScreeningForm =
    visitNoteQuery?.data?.type && screeningFormVisitTypes.includes(visitNoteQuery?.data?.type)

  return (
    <Stack
      h='100%'
      w={400}
      p='md'
      sx={({ other: { colors }, radius }) => ({
        backgroundColor: colors.background[1],
        position: 'relative',
        borderRadius: radius.sm,
      })}
    >
      <Group spacing='sm'>
        <UserIcon color={colors => colors.actions[0]} />
        <Text bold>Patient onboarding responses</Text>
      </Group>
      {formsQuery?.isLoading && <PatientVisitNoteLoader />}
      {shouldShowScreeningForm &&
        forms.length > 0 &&
        screeningForm.answers &&
        Object.values(screeningForm.answers).map(element => (
          <Box key={`${element.key}-${element.value}`}>
            <AScreeningFormQuestionAnswer
              question={element.description || element.key}
              answer={element.value}
            />
          </Box>
        ))}
    </Stack>
  )
}
