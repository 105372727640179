import { Alert, AlertIcon, Box, Skeleton } from '@shared/components'
import {
  EditableSectionFooter,
  EditableSectionFooterProps,
} from '../../../components/forms/EditableSectionFooter'
import { EditableSectionHeader, EditableSectionHeaderProps } from './EditableSectionHeader'

export type EditableSectionProps = EditableSectionHeaderProps &
  EditableSectionFooterProps & {
    children: React.ReactNode
    isLoading: boolean
    error?: string
  }

export const EditableSection = (props: EditableSectionProps) => {
  return (
    <Box>
      <EditableSectionHeader
        isEditing={props.isEditing}
        onEdit={props.onEdit}
        title={props.title}
        moreActions={props.moreActions}
      />
      {props.error && (
        <Alert mb='sm' variant='error' icon={<AlertIcon />}>
          {props.error}
        </Alert>
      )}
      <Skeleton visible={props.isLoading}>{props.children}</Skeleton>
      {props.isEditing && (
        <Box mt='md'>
          <EditableSectionFooter
            isSaving={props.isSaving}
            onCancel={props.onCancel}
            onSave={props.onSave}
          />
        </Box>
      )}
    </Box>
  )
}
