import { Card, Image, Skeleton, Stack, Text } from '@shared/components'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

type IdentificationContentProps = {
  side: 'front' | 'back'
}

export const IdentificationContent = ({ side }: IdentificationContentProps) => {
  const { patientQuery, patientImagesQuery } = usePatient()
  const patient = patientQuery?.data
  const patientImages = patientImagesQuery?.data
  const identification =
    side === 'front' ? patientImages?.frontIdentification : patientImages?.backIdentification

  if (patientImagesQuery?.isLoading || patientImagesQuery?.isError) {
    return <Skeleton h='5rem' />
  }

  return (
    <Stack spacing='sm'>
      <Text size='xs' color={colors => colors.text[1]} style={{ textTransform: 'capitalize' }}>
        {side}
      </Text>
      {identification ? (
        <Card>
          <Image src={identification} />
        </Card>
      ) : (
        <SidebarEmptyState
          pillMessage={`No ${side} identification`}
          textMessage={`As soon as ${
            patient?.personalData.preferredName || patient?.personalData.firstName
          } uploads a photo of their ID in the patient portal, it'll appear here`}
        />
      )}
    </Stack>
  )
}
