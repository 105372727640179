import { YYYYMMDD } from '@shared/types'
import { useFlags, useLunaQuery } from '../../../utils/hooks'

export const useCalendarHolds = ({
  calendarId,
  start,
  end,
}: {
  calendarId?: string
  start: YYYYMMDD
  end: YYYYMMDD
}) => {
  const flags = useFlags()
  const isEnabled = Boolean(flags.visitHoldsV2)

  const holdsQuery = useLunaQuery(
    'GET /scheduling/calendars/:calendarId/holds',
    {
      params: {
        calendarId: calendarId || '',
      },
      query: {
        start,
        end,
      },
    },
    {
      enabled: isEnabled && Boolean(calendarId) && Boolean(start) && Boolean(end),
    },
  )

  return {
    isEnabled,
    holds: holdsQuery.data?.data || [],
    refetchHolds: () => holdsQuery.refetch(),
  }
}
