import React from 'react'

export type AHeadingProps = {
  children: React.ReactNode
  className?: string
}

const AHeading = ({ children, className = '' }: AHeadingProps) => {
  return <h2 className={`text-2xl ${className}`}>{children}</h2>
}

export default AHeading
