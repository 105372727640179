import {
  AlertIcon,
  CheckCircleIcon,
  Collapse,
  Grid,
  Group,
  List,
  ListItem,
  MinusCircleIcon,
  PlusCircleIcon,
  Select,
  Stack,
  Table,
  TertiaryButton,
  Text,
  Tooltip,
  useMantineTheme,
} from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
  hasRole,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import range from 'lodash/range'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import AGeneralTablePaginationFooter from '../../../components/atoms/AGeneralTablePaginationFooter'
import EmptyDataCell from '../../../components/atoms/EmptyDataCell'
import LoadingRow from '../../../components/atoms/LoadingRow'
import NoDataRow from '../../../components/atoms/NoDataRow'
import { useAuth } from '../../../context/auth'
import { useEmployees, useEmrQuery } from '../../../utils/hooks'
import SearchBar from '../../care_team/irq/SearchBar'
import ObjectiveMeasuresChartDrawer from '../../patient/forms/ObjectiveMeasuresChartDrawer'
import { ObjectiveMeasureTrendPercentage } from '../objectiveMeasures/ObjectiveMeasureTrendPercentage'
import CocmMins from './CocmMins'
import ContextMenuPopover from './ContextMenuPopover'

const PAGE_SIZE = 20

const numSkeletonRows = 10

const CocmPatientPanel = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const hasSearchQueryParam = params.has('q')
  const searchQueryParam = params.get('q')
  const { currentUser } = useAuth()
  const [endBefore, setEndBefore] = useState('')
  const [startAfter, setStartAfter] = useState('')
  const [searchParam, setSearchParam] = useState(params.get('q'))
  const [offset, setOffset] = useState(0)
  const [selectedProviderID, setSelectedProviderID] = useState(
    hasRole(currentUser, 'sncm', 'ncm', 'ncm_tn') ? currentUser.oid : '',
  )
  const [selectedMeasureData, setSelectedMeasureData] =
    useState<DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[]>()
  const [drawerContentIndex, setDrawerContentIndex] = useState<number | null>(null)
  const [selectedMeasuresTitle, setSelectedMeasuresTitle] = useState<ObjectiveMeasuresTitle | null>(
    null,
  )
  const [selectedPatientName, setSelectedPatientName] = useState<string | undefined>()
  const [query, setQuery] = useState('')

  const handleMeasuresClick = ({
    measureData,
    title,
    firstOrLast,
    patientName,
  }: {
    measureData: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>[] | null
    title: ObjectiveMeasuresTitle
    firstOrLast: 'first' | 'last'
    patientName: string
  }) => {
    if (measureData === null) {
      return
    }
    const index = firstOrLast === 'first' ? 0 : measureData.length - 1
    setSelectedMeasureData(measureData)
    setDrawerContentIndex(index)
    setSelectedMeasuresTitle(title)
    setSelectedPatientName(patientName)
  }

  const registryQuery = useEmrQuery('GET /cocmRegistry', {
    query: {
      limit: String(PAGE_SIZE),
      endBefore,
      startAfter,
      search: searchParam as string,
      employeeId: selectedProviderID,
    },
  })

  const registryData = registryQuery.data || []

  useEffect(() => {
    if (hasSearchQueryParam && !registryQuery.isLoading) {
      setQuery(searchQueryParam ?? '')
      void onSubmit()
    }
  }, [hasSearchQueryParam, registryQuery.isLoading])

  const onClear = () => {
    setStartAfter('')
    setSearchParam('')
    setOffset(0)
    setQuery('')
    void queryClient.invalidateQueries('GET /cocmRegistry')
    navigate({ pathname: '/registry/patient-panel' })
  }

  const onSubmit = () => {
    if (query) {
      navigate({
        pathname: `/registry/patient-panel/`,
        search: `?q=${query}`,
      })
      setSearchParam(query)
      setOffset(0)
    } else {
      onClear()
    }
  }

  const [currentValue, setCurrentValue] = React.useState<string | null>(null)

  const employeesQuery = useEmployees({
    status: 'currentEmployee',
    role: ['sncm', 'ncm', 'ncm_tn'],
  })

  const ccms = (employeesQuery.data || []).map(ccm => {
    return {
      label: ccm.name,
      value: ccm.oid,
    }
  })

  const isLoading = registryQuery.isLoading || registryQuery.isFetching

  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const patientPanelHeaders = [
    'Patient name',
    'Case review?',
    'PHQ-8',
    'GAD-7',
    'BARC-10',
    'Time in CoCM',
    'CCM',
    'Mins',
  ]
  return (
    <>
      {selectedMeasureData && selectedMeasuresTitle && selectedPatientName && (
        <ObjectiveMeasuresChartDrawer
          data={selectedMeasureData}
          drawerContentIndex={drawerContentIndex}
          onClose={() => {
            setSelectedMeasureData(undefined)
            setDrawerContentIndex(null)
            setSelectedMeasuresTitle(null)
            setSelectedPatientName(undefined)
          }}
          title={selectedMeasuresTitle}
          patientName={selectedPatientName}
        />
      )}
      <Stack mx='lg' my='sm' px='0'>
        <Group position='apart'>
          <SearchBar
            onEnter={onSubmit}
            placeholder='Search for a patient...'
            onClear={onClear}
            onChange={setQuery}
            value={query}
          />
          <Select
            value={selectedProviderID}
            placeholder='Select a triage nurse'
            data={[{ label: 'All CCMs', value: '' }].concat(ccms)}
            onChange={value => {
              setSelectedProviderID(value ?? '')
              setOffset(0)
              setEndBefore('')
              setStartAfter('')
            }}
          />
        </Group>
        <Table
          striped
          sx={{
            tableLayout: 'fixed',
            backgroundColor: colors.text[3],
            borderWidth: sizes.border.md,
            borderStyle: 'solid',
            borderColor: colors.background[3],
          }}
        >
          <thead>
            <tr>
              {patientPanelHeaders.map(header => (
                <th colSpan={1} key={header}>
                  <Text size='xs' bold>
                    {header}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              registryData?.map((item, index) => {
                const isSelected = currentValue === item.patientId
                return (
                  <>
                    <tr
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? colors.background[0] : colors.background[1],
                      }}
                    >
                      <td colSpan={1}>
                        <Group noWrap>
                          {isSelected ? (
                            <TertiaryButton
                              size='sm'
                              onClick={() => setCurrentValue('')}
                              leftIcon={<MinusCircleIcon styled />}
                            />
                          ) : (
                            <TertiaryButton
                              size='sm'
                              onClick={() => setCurrentValue(item.patientId)}
                              leftIcon={<PlusCircleIcon styled />}
                            />
                          )}
                          <Text bold style={{ textOverflow: 'ellipsis', overflow: 'auto' }}>
                            {`${item.firstName} ${item.lastName}`}
                          </Text>
                          {item.suicidality && (
                            <Tooltip label='Suicidal risk' color={colors.background[6]}>
                              <AlertIcon color={colors.error[0]} />
                            </Tooltip>
                          )}
                        </Group>
                      </td>
                      <td>{item.needsReview && <CheckCircleIcon color={colors.text[0]} />}</td>
                      <td>
                        <ObjectiveMeasureTrendPercentage
                          compositeScore={item?.mostRecentPhqResponse?.compositeScore}
                          scoreToCompare={
                            isEqual(
                              omit(item.firstPhqResponse, ['timestamps']),
                              omit(item.mostRecentPhqResponse, ['timestamps']),
                            )
                              ? undefined
                              : item?.firstPhqResponse?.compositeScore
                          }
                        />
                      </td>
                      <td>
                        <ObjectiveMeasureTrendPercentage
                          compositeScore={item?.mostRecentGad7Response?.compositeScore}
                          scoreToCompare={
                            isEqual(item.firstGad7Response, item.mostRecentGad7Response)
                              ? undefined
                              : item?.firstGad7Response?.compositeScore
                          }
                        />
                      </td>
                      <td>
                        <ObjectiveMeasureTrendPercentage
                          compositeScore={item?.mostRecentBarc10Response?.compositeScore}
                          scoreToCompare={
                            isEqual(item.firstBarc10Response, item.mostRecentBarc10Response)
                              ? undefined
                              : item?.firstBarc10Response?.compositeScore
                          }
                        />
                      </td>

                      <td>
                        <Text>{dayjs(item.dateAdded).fromNow(true)}</Text>
                      </td>
                      <td>
                        <Group>
                          {item.employeeName ? (
                            <Text style={{ textOverflow: 'ellipsis', overflow: 'auto' }}>
                              {item.employeeName}
                            </Text>
                          ) : (
                            <EmptyDataCell />
                          )}
                        </Group>
                      </td>
                      <td>
                        <Group position='apart' mr='md'>
                          <CocmMins
                            treatmentTime={item.timeThisMonth ?? 0}
                            dateAdded={item.dateAdded}
                          />
                          <ContextMenuPopover cocmPatient={item} />
                        </Group>
                      </td>
                    </tr>
                    <tr
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? colors.background[0] : colors.background[1],
                      }}
                    >
                      <td
                        // This must span the full length of the table
                        colSpan={patientPanelHeaders.length}
                        style={{
                          // Remove default padding from table data
                          padding: 0,
                          // Only dislay border when the pane is open. Prevents double border effect from collapsed rows.
                          borderWidth: currentValue === item.patientId ? 1 : 0,
                        }}
                      >
                        <Collapse in={currentValue === item.patientId}>
                          <Grid my='sm' mx='sm'>
                            <Grid.Col sx={{ alignSelf: 'start' }} span={4}>
                              <Stack>
                                <Stack spacing='xs'>
                                  <Text bold size='xs'>
                                    Patient ID
                                  </Text>
                                  <Text>{item.patientId}</Text>
                                </Stack>
                                <Stack spacing='xs'>
                                  <Text bold size='xs'>
                                    Time in treatment
                                  </Text>

                                  {item.startedTreatmentDate ? (
                                    <Text>{dayjs(item.startedTreatmentDate).fromNow(true)}</Text>
                                  ) : (
                                    <EmptyDataCell />
                                  )}
                                </Stack>
                              </Stack>
                            </Grid.Col>

                            <Grid.Col sx={{ alignSelf: 'start' }} span={4}>
                              <Text bold size='xs'>
                                Diagnoses
                              </Text>
                              <List listStyleType='disc'>
                                {item.behavioralHealthDiagnoses.map(diagnosis => (
                                  <ListItem key={diagnosis.code}>
                                    {diagnosis?.full_description}
                                  </ListItem>
                                ))}
                              </List>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Group position='apart'>
                                <Stack>
                                  <Stack spacing='xs'>
                                    <Text bold size='xs'>
                                      First PHQ-8
                                    </Text>
                                    {item.decoratedPhqResponses && item.firstPhqResponse ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedPhqResponses,
                                            title: 'PHQ-8',
                                            firstOrLast: 'first',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.firstPhqResponse.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                  <Stack spacing='xs'>
                                    <Group spacing='xs'>
                                      {item.suicidality && (
                                        <Tooltip label='Suicidal risk' color={colors.background[6]}>
                                          <AlertIcon color={colors.error[0]} />
                                        </Tooltip>
                                      )}
                                      <Text bold size='xs'>
                                        Latest PHQ-8
                                      </Text>
                                    </Group>
                                    {item.decoratedPhqResponses && item.mostRecentPhqResponse ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedPhqResponses,
                                            title: 'PHQ-8',
                                            firstOrLast: 'last',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.mostRecentPhqResponse.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                </Stack>
                                <Stack>
                                  <Stack spacing='xs'>
                                    <Text bold size='xs'>
                                      First GAD-7
                                    </Text>
                                    {item.decoratedGad7Responses && item.firstGad7Response ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedGad7Responses,
                                            title: 'GAD-7',
                                            firstOrLast: 'first',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.firstGad7Response.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                  <Stack spacing='xs'>
                                    <Text bold size='xs'>
                                      Latest GAD-7
                                    </Text>
                                    {item.decoratedGad7Responses && item.mostRecentGad7Response ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedGad7Responses,
                                            title: 'GAD-7',
                                            firstOrLast: 'last',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.mostRecentGad7Response.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                </Stack>
                                <Stack>
                                  <Stack spacing='xs'>
                                    <Text bold size='xs'>
                                      First BARC-10
                                    </Text>
                                    {item.decoratedBarc10Responses && item.firstBarc10Response ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedBarc10Responses,
                                            title: 'BARC-10',
                                            firstOrLast: 'first',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.firstBarc10Response.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                  <Stack spacing='xs'>
                                    <Text bold size='xs'>
                                      Latest BARC-10
                                    </Text>
                                    {item.decoratedBarc10Responses &&
                                    item.mostRecentBarc10Response ? (
                                      <TertiaryButton
                                        onClick={() =>
                                          handleMeasuresClick({
                                            measureData: item.decoratedBarc10Responses,
                                            title: 'BARC-10',
                                            firstOrLast: 'first',
                                            patientName: `${item.firstName} ${item.lastName}`,
                                          })
                                        }
                                      >
                                        {String(item.mostRecentBarc10Response.compositeScore)}
                                      </TertiaryButton>
                                    ) : (
                                      <EmptyDataCell />
                                    )}
                                  </Stack>
                                </Stack>
                              </Group>
                            </Grid.Col>
                          </Grid>
                        </Collapse>
                      </td>
                    </tr>
                  </>
                )
              })}
            {isLoading &&
              range(0, numSkeletonRows).map(i => (
                <LoadingRow key={i} headersLength={patientPanelHeaders.length} />
              ))}
            {!isLoading && registryData.length === 0 && (
              <NoDataRow
                message='No patient data found'
                headersLength={patientPanelHeaders.length}
              />
            )}
          </tbody>
        </Table>

        <Group position='center'>
          {!isLoading && (
            <AGeneralTablePaginationFooter
              offset={offset}
              pageSize={PAGE_SIZE}
              total={registryData.length < PAGE_SIZE ? registryData.length : undefined}
              onNextClick={() => {
                setOffset(offset + PAGE_SIZE)
                if (registryQuery.data?.[registryQuery.data.length - 1]) {
                  setEndBefore('')
                  setStartAfter(registryQuery.data?.[registryQuery.data.length - 1]!.oid)
                }
              }}
              onPreviousClick={() => {
                if (offset > 0) {
                  setOffset(offset - PAGE_SIZE)
                  if (registryQuery.data?.[registryQuery.data.length - 1]) {
                    setStartAfter('')
                    setEndBefore(registryQuery.data?.[registryQuery.data.length - 1]!.oid)
                  } else {
                    setStartAfter('')
                    setEndBefore('')
                  }
                }
              }}
            ></AGeneralTablePaginationFooter>
          )}
        </Group>
      </Stack>
    </>
  )
}

export default CocmPatientPanel
