import React from 'react'
import { FieldError } from 'react-hook-form-latest'

export type ANoteCheckboxPositiveInputProps = {
  id?: string
  className?: string
  name?: string
  label?: string
  sublabel?: string
  disabled?: boolean
  checked?: boolean
  labelFont?: string
  textSize?: string
  error?: FieldError
}

const ANoteCheckboxPositiveInput = React.forwardRef<
  HTMLInputElement,
  ANoteCheckboxPositiveInputProps
>(
  (
    {
      id,
      className,
      name,
      label,
      sublabel,
      disabled,
      checked,
      labelFont = 'font-semibold',
      textSize = 'text-xs',
      error,
      ...props
    },
    ref,
  ) => {
    const path =
      'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'

    return (
      <>
        <div className={`${className || ''} relative flex items-start`}>
          <div className='flex items-center h-5'>
            <input
              defaultChecked={Boolean(checked)}
              disabled={disabled}
              id={id}
              name={name}
              ref={ref}
              type='checkbox'
              className='focus:ring-daybreak-actions1 h-4 w-4 text-daybreak-actions1 border-gray-300 rounded'
              {...props}
            />
          </div>
          <div className={`ml-3 leading-5 ${textSize}`}>
            <label htmlFor={name} className={labelFont}>
              {label}
            </label>
            {sublabel && <p className='text-gray-500'>{sublabel}</p>}
          </div>
        </div>
        {error && (
          <p className='inline-flex flex-row mt-1 col-span-6 text-error text-xs ml-8'>
            <svg
              fill='currentColor'
              viewBox='0 0 20 20'
              className='mt-px w-4 h-4 mr-1 flex-shrink-0'
            >
              <path fillRule='evenodd' d={path} clipRule='evenodd' />
            </svg>
            {error.message || 'This field has an error.'}
          </p>
        )}
      </>
    )
  },
)

ANoteCheckboxPositiveInput.displayName = 'ANoteCheckboxPositiveInput'

export default ANoteCheckboxPositiveInput
