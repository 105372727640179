import { Box, Group, Stack, Text, Tooltip, useMantineTheme } from '@shared/components'
import {
  IANAZone,
  dayjs,
  getFormattedDatetimeWithTimeZone,
  getFormattedTimeWithTimeZone,
} from '@shared/utils'
import { PhoneCallStatus } from '../../../../../utils/twilio'
import { CallItem } from './CallItem'

type CommunicationsCallProps = {
  externalId: string
  type?: 'sms' | 'call'
  direction: 'inbound' | 'outbound'
  datetime: string | null
  status: PhoneCallStatus
  duration: number
}

export const CommunicationsCall = ({
  direction,
  datetime,
  status,
  duration,
}: CommunicationsCallProps) => {
  const {
    other: { colors },
  } = useMantineTheme()
  const timeZone = (dayjs.tz.guess() as IANAZone) ?? IANAZone.Eastern

  const notDeliveredText = 'Not yet delivered'

  const formattedTime = datetime
    ? getFormattedTimeWithTimeZone({
        datetime,
        timeZone,
      })
    : notDeliveredText

  const formattedDatetime = datetime
    ? getFormattedDatetimeWithTimeZone({
        datetime,
        timeZone,
      })
    : notDeliveredText

  return direction === 'outbound' ? (
    <Stack spacing='sm'>
      <Box ml='xl'>
        <CallItem
          status={status}
          duration={duration}
          direction={direction}
          iconColor={colors.background[2]}
          textColor={colors.text[2]}
          backgroundColor={colors.background[6]}
          ml='auto'
        />
      </Box>
      <Group spacing='sm' ml='auto'>
        <Text color={colors.text[1]} size='xs'>
          {formattedTime}
        </Text>
      </Group>
    </Stack>
  ) : (
    <Stack spacing='sm'>
      <CallItem
        status={status}
        duration={duration}
        direction={direction}
        iconColor={colors.background[0]}
        textColor={colors.text[0]}
        backgroundColor={colors.background[2]}
        mr='xl'
      />
      <Group spacing='xs'>
        <Tooltip label={formattedDatetime}>
          <Text color={colors.text[1]} size='xs'>
            {formattedTime}
          </Text>
        </Tooltip>
      </Group>
    </Stack>
  )
}
