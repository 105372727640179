import {
  Group,
  LinkText,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Text,
  showNotification,
} from '@shared/components'
import { AuthReassignmentInfo } from '@shared/types'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { usePatient } from './PPatientContext'

export const ReassignPatientAuthModal = ({
  authReassignmentInfo,
  opened,
  onClose,
  onSave,
}: {
  authReassignmentInfo: AuthReassignmentInfo
  opened: boolean
  onClose: () => void
  onSave: () => void
}) => {
  const { patientId, patientQuery } = usePatient()
  const patientFirstName = patientQuery?.data?.personalData.firstName
  const updatePatientAuthData = useMutation(emrApi.getMutation('PUT /patient/:patientId/auth'))
  const saving = updatePatientAuthData.isLoading

  const {
    newEmail,
    newPhone,
    duplicatesAreInTreatment,
    duplicateEmailAccountId,
    duplicatePhoneAccountId,
    duplicateEmailAccountName,
    duplicatePhoneAccountName,
  } = authReassignmentInfo

  const hasSameEmailAndPhoneAccount =
    duplicatePhoneAccountId &&
    duplicateEmailAccountId &&
    duplicatePhoneAccountId === duplicateEmailAccountId
  const hasSamePhoneAccount =
    duplicatePhoneAccountId && duplicatePhoneAccountId !== duplicateEmailAccountId
  const hasSameEmailAccount =
    duplicateEmailAccountId && duplicateEmailAccountId !== duplicatePhoneAccountId

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title='Remove and re-assign patient info'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={onClose}>
            {duplicatesAreInTreatment ? 'Close and go back' : 'No, close and go back'}
          </SecondaryButton>
          {!duplicatesAreInTreatment && (
            <PrimaryButton
              loading={saving}
              onClick={() => {
                updatePatientAuthData.mutate(
                  {
                    params: { patientId },
                    data: {
                      email: newEmail,
                      phone: newPhone,
                      forceReassignment: true,
                    },
                  },
                  {
                    onSuccess: () => {
                      showNotification({
                        message: 'Successfully reassigned patient phone and/or email',
                        variant: 'success',
                      })
                      onSave()
                    },
                    onError: () => {
                      showNotification({
                        message: 'Failed to reassign patient phone or email',
                        variant: 'error',
                      })
                      onClose()
                    },
                  },
                )
              }}
            >
              Yes, re-assign patient info
            </PrimaryButton>
          )}
        </Group>
      }
    >
      <Text mb='sm'>
        Some of this info is currently being used by other patient(s). You are about to remove the
        following and re-assign it to{' '}
        {patientFirstName ? `${patientFirstName}'s` : "this patient's"} account:
      </Text>
      {hasSameEmailAndPhoneAccount && (
        <Text>
          Email address and phone number{' '}
          <LinkText
            component='a'
            href={`/patients/${duplicatePhoneAccountId}`}
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            currently used by {duplicateEmailAccountName}
          </LinkText>
        </Text>
      )}
      {hasSamePhoneAccount && (
        <Text>
          Phone number{' '}
          <LinkText
            component='a'
            href={`/patients/${duplicatePhoneAccountId}`}
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            currently used by {duplicatePhoneAccountName}
          </LinkText>
        </Text>
      )}
      {hasSameEmailAccount && (
        <Text>
          Email address{' '}
          <LinkText
            component='a'
            href={`/patients/${duplicateEmailAccountId}`}
            style={{ textDecoration: 'underline' }}
            target='_blank'
          >
            currently used by {duplicateEmailAccountName}
          </LinkText>
        </Text>
      )}
      <Text mb='sm'>
        {duplicatesAreInTreatment
          ? 'Since a patient with this info is / was in treatment, you cannot re-assign their email or phone. Submit an issue for Engineering to help further.'
          : 'Are you sure you want to proceed?'}
      </Text>
    </Modal>
  )
}
