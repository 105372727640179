import { Card, Skeleton, Stack, Text } from '@shared/components'
import { usePatient } from '../PPatientContext'
import { SidebarEmptyState } from './SidebarEmptyState'

export const DiagnosisContent = () => {
  const { patientQuery, problemsListQuery } = usePatient()
  const patient = patientQuery?.data

  const problemsList = problemsListQuery?.data ?? []

  if (problemsListQuery?.isLoading || problemsListQuery?.isError) {
    return <Skeleton h='5rem' />
  }

  if (problemsList.length === 0) {
    return (
      <SidebarEmptyState
        pillMessage='No diagnoses'
        textMessage={`Diagnoses made for ${
          patient?.personalData.preferredName || patient?.personalData.firstName
        } will appear here`}
      />
    )
  }

  return (
    <Stack spacing='sm'>
      {problemsList?.map(diagnosis => (
        <Card key={diagnosis.oid}>
          <Text size='xs' bold color={colors => colors.text[0]}>
            {diagnosis.full_description} ({diagnosis.code})
          </Text>
        </Card>
      ))}
    </Stack>
  )
}
