import { UseFormReturnType } from '@mantine/form'
import { Group, PrimaryButton, SecondaryButton, Stack, TextInput } from '@shared/components'
import { validCharacters } from '@shared/utils'

import { QuickMergeForm } from './QuickMergeModal'

type QuickMergeInformationProps = {
  form: UseFormReturnType<QuickMergeForm>
  fromPatientId: string
  onFormSuccess: (toPatientId: string) => void
  onClose: () => void
}

export const QuickMergeFormStep = ({
  form,
  fromPatientId,
  onFormSuccess,
  onClose,
}: QuickMergeInformationProps) => {
  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    onFormSuccess(form.values.toPatientId)
  }

  return (
    <Stack spacing='md'>
      <TextInput
        label='From Patient ID:'
        placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
        formatter={validCharacters.id}
        value={fromPatientId}
        disabled
      />
      <TextInput
        label='To Patient ID:'
        placeholder='XXXXXXXXXXXXXXXXXXXXXXXXXXXX'
        formatter={validCharacters.id}
        {...form.getInputProps('toPatientId')}
      />
      <Group position='right'>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
      </Group>
    </Stack>
  )
}
