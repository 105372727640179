import { InvoiceListItem } from '@shared/types'
import { InvoiceRow } from './InvoiceRow'

type InvoicesTableBodyProps = {
  invoices: InvoiceListItem[]
}

export const InvoicesTableBody = ({ invoices }: InvoicesTableBodyProps) => {
  return (
    <tbody className='mantine'>
      {invoices.map((invoice, idx) => {
        const isEven = idx % 2 === 0
        return <InvoiceRow isFilled={isEven} key={invoice.oid} invoice={invoice} />
      })}
    </tbody>
  )
}
