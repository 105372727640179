import { OnboardingCallVisitNoteContent } from '@shared/types'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import {
  FullOnboardingCallVisitNoteFormContent,
  OnboardingVisitNoteForm,
} from '../WelcomeCallVisitNote'
import { PatientVisitNoteCheckbox } from './PatientVisitNoteCheckbox'

export const PatientVisitNoteNotDiscussedCheckbox = ({
  field,
  form,
}: {
  field: OnboardingCallVisitNoteContent
  form: OnboardingVisitNoteForm
}) => {
  const { isLocked } = usePatientVisitNote()

  const notDiscussedField: keyof FullOnboardingCallVisitNoteFormContent =
    `${field}_not_discussed` as keyof FullOnboardingCallVisitNoteFormContent

  if (isLocked && form.values[notDiscussedField] !== true) {
    return null
  }

  return (
    <PatientVisitNoteCheckbox
      w='100%'
      label='Not discussed'
      checked={form.values[notDiscussedField]}
      {...form.getInputProps(notDiscussedField)}
    />
  )
}
