import { Drawer, Loader, LoadingOverlay, Stack, Text } from '@shared/components'
import {
  DecoratedObjectiveMeasureResponse,
  FullWorkflowState,
  ObjectiveMeasureResponse,
  ObjectiveMeasuresTitle,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import { useState } from 'react'
import { WorkflowDrawer } from '../../../components/workflows/WorkflowDrawer'
import { useAuth } from '../../../context/auth'
import { computeScoreFromWorkflowResponses, getScoresFromResponses } from '../../../utils/cocmUtils'
import {
  convertToResponseCollectionDataForType,
  sortResponseValueForType,
} from '../../../utils/workflowUtils'

type ObjectiveMeasuresFormCollectionDrawerProps = {
  patientId: string
  measure: ObjectiveMeasuresTitle | null
  workflowState: FullWorkflowState | null
  workflowType: 'phq_9_form' | 'phq_8_form' | 'gad_7_form' | 'barc_10_form' | null
  isLoading: boolean
  onClose: () => void
  setRecentlySubmittedScore: ({
    measure,
    tempScore,
  }: {
    measure: ObjectiveMeasuresTitle
    tempScore: DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
  }) => void
  displayDatePicker?: boolean
  saveResponsesOnClose?: boolean
}

const ObjectiveMeasuresFormCollectionDrawer = ({
  patientId,
  measure,
  setRecentlySubmittedScore,
  workflowState,
  workflowType,
  isLoading,
  onClose,
  displayDatePicker = false,
  saveResponsesOnClose = true,
}: ObjectiveMeasuresFormCollectionDrawerProps) => {
  const { currentUser } = useAuth()

  // NOTE: Defining `datePickerValue` here instead of in `WorkflowDrawer` so that we can set the date correctly in the temp score below
  const [datePickerValue, setDatePickerValue] = useState<string>(dayjs().toISOString())

  const getWorkflowSubheader = (measure: ObjectiveMeasuresTitle) => {
    const phq9AndGad7: ObjectiveMeasuresTitle[] = ['PHQ-8', 'PHQ-9', 'GAD-7']
    if (phq9AndGad7.includes(measure)) {
      return (
        <Text>
          Over the last 2 weeks, how often have you been bothered by any of the following problems?
        </Text>
      )
    }
    return (
      <Text>
        On a scale of 1 to 6, please indicate your level of agreement with the following statement:
      </Text>
    )
  }

  return workflowState && measure ? (
    <WorkflowDrawer
      title={measure}
      fullWorkflowState={workflowState}
      opened={Boolean(workflowState)}
      onClose={() => {
        setDatePickerValue(dayjs().toISOString())
        onClose()
      }}
      onSubmit={responses => {
        if (workflowType) {
          const responsesWithScores = getScoresFromResponses(responses, workflowType)
          const score = computeScoreFromWorkflowResponses(responsesWithScores)
          const completedAt = dayjs(datePickerValue).toISOString()
          if (score === undefined) {
            return
          }
          const promptResponses = responsesWithScores
            .map(response => response.promptResponse.value)
            .sort((a, b) => sortResponseValueForType({ a, b, workflowType }))
            .map(response =>
              convertToResponseCollectionDataForType({
                response,
                workflowType,
              }),
            )
          const tempScore = {
            workflowSessionId: workflowState.workflowSession.oid,
            compositeScore: score,
            completedAt,
            completedBy: 'clinician',
            employeeName: currentUser?.name || '',
            data: promptResponses,
          } as DecoratedObjectiveMeasureResponse<ObjectiveMeasureResponse>
          setRecentlySubmittedScore({ measure, tempScore })
        }
        setDatePickerValue(dayjs().toISOString())
        onClose()
      }}
      componentDisplayedWhileSubmitting={
        <Stack pos='absolute' justify='center' align='center' top={0} left={0} right={0} bottom={0}>
          <Loader />
          <Text>Calculating...</Text>
        </Stack>
      }
      subHeader={getWorkflowSubheader(measure)}
      patientId={patientId}
      saveResponsesOnClose={saveResponsesOnClose}
      datePickerValue={displayDatePicker ? datePickerValue : undefined}
      onDatePickerValueChange={(value: string) => setDatePickerValue(dayjs(value).toISOString())}
    />
  ) : (
    <Drawer position='right' size='lg' title={measure} opened={isLoading} onClose={onClose}>
      <LoadingOverlay visible />
    </Drawer>
  )
}

export default ObjectiveMeasuresFormCollectionDrawer
