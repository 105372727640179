import { useForm } from '@mantine/form'
import { PrimaryButton, Select, Stack, TextInput, validateWith } from '@shared/components'
import { NonVisitEventModel, Patient } from '@shared/types'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { patientsApi } from '../../api'
import { EmployeeMentionNoteInput } from '../../components/forms/EmployeeMentionNoteInput'
import TModal from '../../components/templates/TModal'
import { useAuth } from '../../context/auth'
import { isRequired } from '../../utils/formValidation'

export type OAddNonVisitEventModalProps = {
  patient: Patient
  closeModal: () => void
}

type FormData = {
  title: NonVisitEventModel['title']
  titleFreeform: string
  content: string
}
/*
 * There are varying title options depending how the non visit event modal is accessed:
 * Natively from EMR vs from Kustomer widget.
 */
const TITLE_OPTIONS: NonVisitEventModel['title'][] = [
  'Miscellaneous Event',
  'Pharmacy Event',
  'Medical/Psych Event',
  'Social Event',
]

const OAddNonVisitEventModal = (props: OAddNonVisitEventModalProps) => {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const createNonVisitEvent = useMutation(patientsApi.createNonVisitEvent)

  const closeModal = useCallback(() => {
    // Remove any search params this way before closing modal.
    navigate({ pathname: location.pathname })
    props.closeModal()
  }, [navigate, location.pathname, props])

  const [searchParams] = useSearchParams()
  const titleParam = searchParams.get('title')
  const titleFreeformParam = searchParams.get('title_freeform')
  const contentParam = searchParams.get('content')

  const form = useForm<FormData>({
    initialValues: {
      title: titleParam ?? '',
      titleFreeform: titleFreeformParam ?? '',
      content: contentParam ?? '',
    },
    validate: {
      title: validateWith(isRequired),
      content: validateWith(isRequired),
    },
  })

  const handleSubmit = async () => {
    const data = form.values
    const nonVisitEvent: NonVisitEventModel = {
      title: data.title || '',
      content: data.content || '',
      patientId: props.patient.oid,
      authorId: auth.currentUser?.oid || '',
      createdAt: new Date().toISOString(),
    }

    if (data.titleFreeform) {
      nonVisitEvent.titleFreeform = data.titleFreeform
    }

    await createNonVisitEvent.mutateAsync(nonVisitEvent)

    void queryClient.invalidateQueries()

    closeModal()
  }

  return (
    <TModal closeModal={closeModal}>
      <Stack w='100%'>
        <Select
          {...form.getInputProps('title')}
          label='Type'
          data={TITLE_OPTIONS}
          disabled={TITLE_OPTIONS.length === 1}
        />

        <TextInput label='Title (optional)' spellCheck {...form.getInputProps('titleFreeform')} />

        <EmployeeMentionNoteInput
          mih='xl'
          label='Description'
          placeholder='Please include relevant details such as date of occurrence and severity'
          explanation='Note: Tagging a coworker with "@" will not send them a notification'
          mentionData={[]}
          {...form.getInputProps('content')}
        />

        <PrimaryButton
          loading={createNonVisitEvent.isLoading}
          onClick={() => form.onSubmit(handleSubmit)()}
        >
          Create new event
        </PrimaryButton>
      </Stack>
    </TModal>
  )
}

export default OAddNonVisitEventModal
