import { CalendarIcon, Group, SecondaryButton } from '@shared/components'
import { dayjs } from '@shared/utils'
import pluralize from 'pluralize'
import { CalendarSelect } from '../CalendarSelect'
import { DayNavigator, WeekNavigator } from './DateNavigator'
import { InfoBar } from './InfoBar'

export type CalendarHeaderProps = {
  date: string
  setDate: (date: string) => void
  calendarId?: string
  setCalendarId: (calendarId: string) => void
  visitCount: number
  setShouldShowSidebar: (shouldShowSidebar: boolean) => void
  drawerStyling: boolean
}

export const getNumberOfWeeksOut = (date: string) => {
  const thisWeek = dayjs().startOf('week')
  const appointmentWeek = dayjs(date).startOf('week')
  const num = appointmentWeek.diff(thisWeek, 'weeks')
  if (num === 0) {
    return '(this week)'
  }
  if (num === 1) {
    return '(next week)'
  }
  return `(in ${num} ${pluralize('week', num)})`
}

const CalendarHeader = ({
  date,
  setDate,
  calendarId,
  setCalendarId,
  visitCount,
  setShouldShowSidebar,
  drawerStyling = false,
}: CalendarHeaderProps) => {
  return (
    <Group position='apart' py='md'>
      <Group>
        {!drawerStyling && <CalendarSelect calendarId={calendarId} setCalendarId={setCalendarId} />}
        <InfoBar date={date} calendarId={calendarId} visitCount={visitCount} />
      </Group>
      <Group>
        {calendarId ? (
          <WeekNavigator date={date} setDate={setDate} />
        ) : (
          <DayNavigator date={date} setDate={setDate} />
        )}
        {calendarId && (
          <SecondaryButton leftIcon={<CalendarIcon />} onClick={() => setShouldShowSidebar(true)} />
        )}
      </Group>
    </Group>
  )
}

export default CalendarHeader
