import { useForm } from '@mantine/form'
import { useClipboard } from '@mantine/hooks'
import {
  ArrowRightIcon,
  Box,
  CopyIcon,
  EditIcon,
  Grid,
  Group,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  TitleThree,
  validateWith,
} from '@shared/components'
import {
  BUNDLE_ENCOUNTER_APPOINTMENT_TYPES,
  BundleEncounterAppointmentType,
  ENCOUNTER_APPOINTMENT_TYPES,
  EncounterAppointmentData,
  EncounterAppointmentType,
  EncounterStatuses,
  YYYYMMDD,
} from '@shared/types'
import { dayjs, isTruthy } from '@shared/utils'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { emrApi } from '../../../../api'
import { EditableSectionFooter } from '../../../../components/forms/EditableSectionFooter'
import { isRequired } from '../../../../utils/formValidation'
import { useLunaQuery } from '../../../../utils/hooks'
import { CardSelect } from '../components/CardSelect'
import { CardTextInput } from '../components/CardTextInput'
import { LabeledData } from '../components/LabeledData'

export type EncounterHeaderSectionProps = {
  isEditing: boolean
  setEditing: (bool: boolean) => void
  onSave: (data: EncounterAppointmentData) => Promise<void>
  saving: boolean
  appointmentType?: EncounterAppointmentType
  dateOfService?: YYYYMMDD
  endDateOfService?: YYYYMMDD
  encounterId: string
  lastUpdatedById?: string
  encounterStatus?: string
  patientId: string
  patientName: string
  visitId?: number
  patientTime?: number
}

export const EncounterHeaderSection = ({
  isEditing,
  setEditing,
  onSave,
  saving,
  appointmentType,
  dateOfService,
  endDateOfService,
  encounterId,
  lastUpdatedById,
  encounterStatus,
  patientId,
  patientName,
  visitId,
  patientTime,
}: EncounterHeaderSectionProps) => {
  const { copy, copied } = useClipboard()
  const navigate = useNavigate()

  const [lastUpdatedByKey, lastUpdatedByFunction] = emrApi.getQuery('GET /employee/:employeeId', {
    params: {
      employeeId: lastUpdatedById || '',
    },
  })

  const lastUpdatedByQuery = useQuery(lastUpdatedByKey, lastUpdatedByFunction, {
    enabled: Boolean(lastUpdatedById),
  })

  const lastUpdatedLabel =
    encounterStatus === EncounterStatuses.Submitted ? 'Submitted by' : 'Last updated by'

  const lastUpdatedBySystem = lastUpdatedById === 'system' ? 'System' : null
  const lastUpdatedByValue = lastUpdatedByQuery.data?.name || lastUpdatedBySystem

  const isEditable = encounterStatus === EncounterStatuses.Unsubmitted

  const form = useForm<EncounterAppointmentData>({
    initialValues: {
      appointment_type: appointmentType,
      date_of_service: dateOfService,
      end_date_of_service: endDateOfService,
    },
    validate: {
      appointment_type: validateWith(isRequired),
      date_of_service: value => {
        // Value is required
        if (!value) {
          return 'Required'
        }

        // Make sure value is YYYY-MM-DD
        const isValidFormat = dayjs(value, 'YYYY-MM-DD', true).isValid()
        if (!isValidFormat) {
          return 'Invalid date format'
        }
      },
      end_date_of_service: value => {
        // If a value is specified, make sure it's YYYY-MM-DD
        if (value) {
          const isValidFormat = dayjs(value, 'YYYY-MM-DD', true).isValid()
          if (!isValidFormat) {
            return 'Invalid date format'
          }
        }
      },
    },
    clearInputErrorOnChange: false,
  })

  const [serviceDates, setServiceDates] = useState<YYYYMMDD[]>(
    [form.values.date_of_service].filter(isTruthy),
  )

  // Display a list of appointment dates if the appointment type is FFS
  const serviceDatesQuery = useLunaQuery(
    'GET /patients/:patientId/service-dates',
    {
      params: {
        patientId,
      },
    },
    {
      onSuccess: ({ data }) => {
        setServiceDates(data.serviceDates)
      },
    },
  )

  const isBundle = BUNDLE_ENCOUNTER_APPOINTMENT_TYPES.includes(
    // Defining the type here to avoid TS error
    form.values.appointment_type as BundleEncounterAppointmentType,
  )

  return (
    <Stack spacing='md'>
      <Box
        sx={theme => ({
          borderBottomColor: theme.other.colors.background[3],
          borderBottomWidth: theme.other.sizes.border.md,
          paddingBottom: theme.other.sizes.padding.md,
        })}
      >
        <Group position='apart'>
          <Group>
            <TitleThree>{patientName}</TitleThree>
            <TertiaryButton
              rightIcon={<CopyIcon color={colors => colors.actions[0]} />}
              onClick={() => {
                copy(patientId)
              }}
            >
              {copied ? 'Copied' : 'Patient ID'}
            </TertiaryButton>
          </Group>
          {/* If the encounter is in the unsubmitted queue, let the user update this info */}
          {isEditable ? (
            <TertiaryButton
              rightIcon={<EditIcon color={colors => colors.actions[0]} />}
              onClick={() => {
                setEditing(true)
              }}
            >
              Edit
            </TertiaryButton>
          ) : visitId ? (
            <TertiaryButton
              rightIcon={<ArrowRightIcon color={colors => colors.actions[0]} />}
              onClick={() => {
                navigate(`/patients/${patientId}/notes/${visitId}`)
              }}
            >
              Visit note
            </TertiaryButton>
          ) : (
            <TertiaryButton
              rightIcon={<ArrowRightIcon color={colors => colors.actions[0]} />}
              onClick={() => {
                navigate(`/patients/${patientId}`)
              }}
            >
              Patient chart
            </TertiaryButton>
          )}
        </Group>
      </Box>
      <Grid columns={12}>
        <Grid.Col span={4}>
          <CardSelect
            {...form.getInputProps('appointment_type')}
            label='Appointment type'
            data={ENCOUNTER_APPOINTMENT_TYPES}
            editable={isEditing}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          {/* If the appointment type is FFS, show a list of the patient's appointment dates */}
          {isBundle ? (
            <CardTextInput
              {...form.getInputProps('date_of_service')}
              label='Start date of service'
              placeholder='YYYY-MM-DD'
              editable={isEditing}
            />
          ) : (
            <CardSelect
              {...form.getInputProps('date_of_service')}
              label='Appointment date'
              placeholder='Select date'
              data={serviceDates}
              editable={isEditing}
              disabled={serviceDatesQuery.isLoading}
            />
          )}
        </Grid.Col>
        {/* Only bundles will have an end date of service */}
        {(endDateOfService || isBundle) && (
          <Grid.Col span={4}>
            <CardTextInput
              {...form.getInputProps('end_date_of_service')}
              label='End date of service'
              placeholder='YYYY-MM-DD'
              editable={isEditing}
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <LabeledData label='Encounter ID' data={encounterId} />
        </Grid.Col>
        <Grid.Col span={4}>
          {lastUpdatedByQuery.isLoading ? (
            <Stack>
              <Text bold>{lastUpdatedLabel}</Text>
              <Skeleton width='50%' height={10} />
            </Stack>
          ) : (
            <LabeledData label={lastUpdatedLabel} data={lastUpdatedByValue} />
          )}
        </Grid.Col>
        {patientTime && appointmentType === 'Collaborative Care Management' && (
          <Grid.Col span={4}>
            <LabeledData label='Total time' data={String(patientTime)} />
          </Grid.Col>
        )}
      </Grid>
      {isEditing ? (
        <EditableSectionFooter
          onCancel={() => {
            setEditing(false)
            form.reset()
          }}
          onSave={async () => {
            if (form.validate().hasErrors) {
              return
            }

            await onSave(form.values)
            setEditing(false)
          }}
          isSaving={saving}
        />
      ) : null}
    </Stack>
  )
}
