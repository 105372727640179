import { useForm } from '@mantine/form'
import { useDidUpdate } from '@mantine/hooks'
import {
  CheckCircleIcon,
  Checkbox,
  Group,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextInput,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { Alert } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { analytics } from '../../analytics'
import { emrApi } from '../../api'
import { useAuth } from '../../context/auth'
import { inputNumberBetween } from '../../utils/formValidation'

export type CancelVisitModalProps = {
  closeModal: () => void
  appointmentID?: string
  patientID: string
  setAlert?: (alert: Alert) => void
  appointmentTime?: string
}

const NO_SHOW_WINDOW_HOURS = 24

const CancelVisitModal = ({
  closeModal,
  appointmentID,
  patientID,
  setAlert,
  appointmentTime,
}: CancelVisitModalProps) => {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()

  const noShowTiming = dayjs().add(NO_SHOW_WINDOW_HOURS, 'hour')

  const disabled = Boolean(appointmentTime) && dayjs(appointmentTime).isAfter(noShowTiming)

  const patientCocmRecord = useQuery(
    ...emrApi.getQuery('GET /cocmRegistry/patient/:patientId', {
      params: {
        patientId: patientID ?? '',
      },
    }),
  )

  const cancelAppointmentMutation = useMutation(
    emrApi.getMutation('POST /patient/:patientId/appointments/:appointmentId/cancel'),
    {
      onSuccess: () => {
        const [queryKey] = emrApi.getQuery('GET /patient/:patientId/appointments/:appointmentId', {
          params: {
            patientId: patientID,
            appointmentId: appointmentID ?? '',
          },
        })
        void queryClient.invalidateQueries(queryKey)
      },
    },
  )
  const isCurrentUserPatientsBhcm = patientCocmRecord.data?.employeeId === currentUser.oid

  type CancelVisitForm = {
    noShow: boolean
    minutesWaiting: `${number}` | ''
  }
  const DEFAULT_MINUTES_SPENT = 10

  const skipIfUserIsNotBhcm = () => !isCurrentUserPatientsBhcm

  const form = useForm<CancelVisitForm>({
    initialValues: {
      noShow: false,
      minutesWaiting: isCurrentUserPatientsBhcm ? `${DEFAULT_MINUTES_SPENT}` : '',
    },
    validate: {
      minutesWaiting: validateWith(
        skipIfUserIsNotBhcm,
        skipIfOtherField('noShow', 'is', false),
        inputNumberBetween({ start: 0, end: 100 }),
      ),
    },
  })

  const onSubmit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    try {
      await cancelAppointmentMutation.mutateAsync({
        params: { patientId: patientID, appointmentId: appointmentID ?? '' },
        data: {
          noShow: form.values.noShow,
          minutesWaiting: form.values.minutesWaiting ? Number(form.values.minutesWaiting) : null,
        },
      })
      analytics.track('Appointment canceled via scheduling modal', {
        userId: currentUser.oid,
        userName: currentUser.name,
      })
      if (setAlert) {
        setAlert({
          message: form.values.noShow
            ? 'The visit has been marked as a no show.'
            : 'The visit has been successfully canceled.',
          type: 'success',
        })
      }
    } catch (error) {
      if (setAlert) {
        setAlert({ message: 'Unable to cancel this appointment.', type: 'error' })
      }
    }
    closeModal()
  }

  useDidUpdate(() => {
    if (!form.values.noShow) {
      form.setFieldValue('minutesWating', '')
      return
    }

    if (isCurrentUserPatientsBhcm && form.values.noShow) {
      form.setFieldValue('minutesWaiting', `${DEFAULT_MINUTES_SPENT}`)
    }
  }, [form.values.noShow, isCurrentUserPatientsBhcm])

  return (
    <Modal
      opened
      onClose={closeModal}
      title='Cancel visit'
      footer={
        <Group position='right'>
          <SecondaryButton onClick={closeModal}>
            {form.values.noShow ? `No, don't mark as no show` : `No, don't cancel`}
          </SecondaryButton>
          <PrimaryButton
            loading={cancelAppointmentMutation.isLoading}
            disabled={cancelAppointmentMutation.isLoading || patientCocmRecord.isLoading}
            onClick={onSubmit}
          >
            {form.values.noShow ? 'Yes, mark as no show' : 'Yes, cancel visit'}
          </PrimaryButton>
        </Group>
      }
    >
      <Stack>
        <Text> This will cancel the appointment and remove it from the calendar.</Text>
        {isCurrentUserPatientsBhcm && (
          <Stack>
            <Group spacing='sm' noWrap>
              <CheckCircleIcon color={colors => colors.success[0]} />
              <Text>Patient is participating in CoCM</Text>
            </Group>
            <Group spacing='sm' noWrap>
              <CheckCircleIcon color={colors => colors.success[0]} />
              <Text>
                Waiting for the patient to join a visit qualifies as CoCM general outreach activity
              </Text>
            </Group>
          </Stack>
        )}
        <Checkbox disabled={disabled} label='Mark as a no show' {...form.getInputProps('noShow')} />
        {form.values.noShow && isCurrentUserPatientsBhcm && (
          <>
            <TextInput
              label='Minutes spent waiting for patient'
              type='number'
              {...form.getInputProps('minutesWaiting')}
            />
            <Text size='xs' color={colors => colors.text[1]}>
              This estimate is true and accurate to the best of my knowledge. I understand that I am
              being asked for this estimate for insurance billing purposes.
            </Text>
          </>
        )}
      </Stack>
    </Modal>
  )
}

export default CancelVisitModal
