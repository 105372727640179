import { ClockIcon, Group, Pill, Stack, Text } from '@shared/components'
import { EMRTaskNoteDecorated } from '@shared/types'
import { dayjs } from '@shared/utils'
import { NoteCreatedBy } from './NoteCreatedBy'

export type NotesAssigneeChangedEntryProps = {
  note: EMRTaskNoteDecorated<'assignee_changed'>
}

const getTextForEntry = (note: EMRTaskNoteDecorated<'assignee_changed'>) => {
  if (note.metadata.assigneeAfterName) {
    return ['New assignee', `Assigned to ${note.metadata.assigneeAfterName}`]
  }
  return ['Assignee removed', `${note.metadata.assigneeBeforeName} removed as assignee`]
}

export const NotesAssigneeChangedEntry = ({ note }: NotesAssigneeChangedEntryProps) => {
  const [title, description] = getTextForEntry(note)

  return (
    <Stack spacing='sm'>
      <Pill variant='filled' status='none'>
        {title}
      </Pill>
      <Group>
        <Text size='xs'>
          <NoteCreatedBy note={note} />
        </Text>
        <Text size='xs'>
          <Group spacing='xs'>
            <ClockIcon color={({ text }) => text[1]} size='sm' />
            {dayjs(note.createdAt).format('MM/DD/YYYY')} | {dayjs(note.createdAt).format('h:mma z')}
          </Group>
        </Text>
      </Group>
      <Text style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>{description}</Text>
    </Stack>
  )
}
