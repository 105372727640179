import { useForm } from '@mantine/form'
import {
  Box,
  CloseButton,
  Divider,
  Pill,
  RichTextEditor,
  Stack,
  Text,
  validateWith,
} from '@shared/components'
import { Patient } from '@shared/types'
import { Editor } from '@tiptap/react'
import { useRef } from 'react'
import {
  inputCharacterLimitExceeded,
  isConditionallyRequired,
} from '../../../../../utils/formValidation'
import { useLunaMutation } from '../../../../../utils/hooks'
import { useAccess } from '../../../../../utils/hooks/use-access'
import { twilioShortcuts } from '../../../../../utils/twilio'
import { OutboundSmsButtonGroup } from './OutboundSmsButtonGroup'
import { useSmsAttachment } from './use-sms-attachment'

type OutboundFormValues = {
  message: string
}

type OutboundBlockProps = {
  patientId: Patient['oid']
  onRefresh: () => Promise<void>
  lastLoadedTimestamp: string
  isFetching: boolean
}

const TWILIO_SMS_CHARACTER_LIMIT = 1600

export const OutboundSmsBlock = ({ patientId, onRefresh }: OutboundBlockProps) => {
  const sendMessage = useLunaMutation('POST /communications/sms')
  const {
    file,
    isLoading: isAttachmentLoading,
    attachmentFilePath,
    selectAttachment,
    resetAttachment,
    resetAttachmentButtonRef,
  } = useSmsAttachment()

  const sendButtonRef = useRef<HTMLDivElement | null>(null)
  const editorRef = useRef<Editor>(null)
  const { outboundSms } = useAccess()

  const form = useForm<OutboundFormValues>({
    initialValues: {
      message: '',
    },
    validate: {
      message: validateWith(
        isConditionallyRequired(!attachmentFilePath),
        inputCharacterLimitExceeded({ characterLimit: TWILIO_SMS_CHARACTER_LIMIT }),
      ),
    },
  })

  const handleSubmit = () => {
    const validation = form.validate()
    if (validation.hasErrors) {
      return
    }

    sendButtonRef.current?.focus()

    sendMessage.mutate(
      {
        data: {
          body: form.values.message,
          attachmentFilePath: attachmentFilePath || undefined,
          patientId,
        },
      },
      {
        onSuccess: () => {
          resetAttachment()
          form.reset()
          void onRefresh()
        },
      },
    )
  }
  const handleTriggerShortcut = () => {
    if (editorRef.current) {
      const editor = editorRef.current

      // '#' triggers the shortcuts, and if the editor is not empty, there must be a space before it
      const shortcutTriggerText = editor.getText() === '' ? '#' : ' #'
      editor.chain().focus().insertContent(shortcutTriggerText).run()
    }
  }
  return (
    <Box w='100%'>
      <Divider w='95%' m='auto' color={colors => colors.background[2]} />
      {outboundSms && (
        <Stack spacing={0}>
          <Stack w='auto' m='md'>
            <RichTextEditor
              mah='20rem'
              placeholder='Type here...'
              {...form.getInputProps('message')}
              plainText
              disabled={sendMessage.isLoading}
              smartPhrasesData={twilioShortcuts}
              ref={editorRef}
            />
            {file && (
              <Stack spacing='sm'>
                <Pill
                  maw='100%'
                  variant='filled'
                  status='none'
                  py='xs'
                  px='sm'
                  rightSection={<CloseButton onClick={resetAttachment} radius='lg' size='xs' />}
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {file.name}
                </Pill>
                <Text color={colors => colors.text[1]} size='xs'>
                  Attachments may take a moment to appear in the message log
                </Text>
              </Stack>
            )}
          </Stack>
          <OutboundSmsButtonGroup
            ref={sendButtonRef}
            handleSubmit={handleSubmit}
            handleSelectAttachment={selectAttachment}
            disabled={sendMessage.isLoading || isAttachmentLoading}
            isLoading={sendMessage.isLoading || isAttachmentLoading}
            handleTriggerShortcut={handleTriggerShortcut}
            resetAttachmentButtonRef={resetAttachmentButtonRef}
          />
        </Stack>
      )}
    </Box>
  )
}
