import { ScrollArea, Table, Text } from '@shared/components'
import { dayjs, sortBy } from '@shared/utils'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { usePatient } from '../PPatientContext'

export const RSEventsSection = () => (
  <>
    <Text>Note that only the last 7 days of RudderStack events are stored.</Text>
    <ScrollArea style={{ height: 400 }}>
      <RSEventsTable />
    </ScrollArea>
  </>
)

const RSEventsTable = () => {
  const { patientID } = usePatient()

  const [eventsKey, eventsFn] = emrApi.getQuery('GET /patient/:patientId/events', {
    params: {
      patientId: patientID,
    },
  })
  const eventsQuery = useQuery(eventsKey, eventsFn)
  const events = eventsQuery.data ?? []

  return (
    <Table horizontalSpacing='10px' striped>
      <thead>
        <tr>
          <th
            style={{
              position: 'sticky',
              top: 0,
              minWidth: '100px',
              width: 'auto',
              whiteSpace: 'nowrap',
            }}
            colSpan={1}
          >
            Datetime
          </th>
          <th
            style={{
              position: 'sticky',
              top: 0,
              minWidth: '100px',
              width: 'auto',
              whiteSpace: 'nowrap',
            }}
            colSpan={1}
          >
            Method
          </th>
          <th
            style={{
              position: 'sticky',
              top: 0,
              minWidth: '100px',
              width: 'auto',
              whiteSpace: 'nowrap',
            }}
            colSpan={1}
          >
            Event
          </th>
          <th
            style={{
              position: 'sticky',
              top: 0,
              minWidth: '100px',
              width: 'auto',
              whiteSpace: 'nowrap',
            }}
            colSpan={1}
          >
            Traits
          </th>
          <th
            style={{
              position: 'sticky',
              top: 0,
              minWidth: '100px',
              width: 'auto',
              whiteSpace: 'nowrap',
            }}
            colSpan={1}
          >
            Properties
          </th>
        </tr>
      </thead>
      <tbody>
        {events.sort(sortBy({ key: 'timestamp', order: 'DESC' })).map(event => (
          <tr key={event.oid}>
            <td>{dayjs(event.timestamp).toISOString()}</td>
            <td>{event.method}</td>
            <td>{event.context.event}</td>
            <td>
              <pre>
                <code>{JSON.stringify(event.context.traits, null, 4)}</code>
              </pre>
            </td>
            <td>
              <pre>
                <code>{JSON.stringify(event.context.properties, null, 4)}</code>
              </pre>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
