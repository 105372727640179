import { Pill, Tooltip } from '@shared/components'
import { hasAppointmentType } from '@shared/types'
import { useState } from 'react'
import type { Visit } from '../api/types'

export const HighRiskVisitPillStatus = ({ visit }: { visit: Visit }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHovered, setIsHovered] = useState(false)

  if (
    visit?.metadata?.highRiskPatient &&
    hasAppointmentType(visit, 'Free Consultation Call', 'Returning welcome call')
  ) {
    return (
      <Tooltip
        label='Patient is potentially high risk intake'
        onMouseEnter={() => setIsHovered(true)}
        position='top'
      >
        <Pill variant='filled' status='error'>
          High risk
        </Pill>
      </Tooltip>
    )
  }

  return null
}
