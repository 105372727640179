import { useHover } from '@mantine/hooks'
import {
  ActionIcon,
  Box,
  CloseButton,
  Grid,
  Group,
  MicIcon,
  MoreVerticalIcon,
  PhoneIcon,
  PhoneInput,
  Select,
  SelectProps,
  Stack,
  Text,
  TextInput,
  VolumeIcon,
  useMantineTheme,
} from '@shared/components'
import { phone } from '@shared/utils'
import { Call, Device } from '@twilio/voice-sdk'
import { useReducer, useState } from 'react'
import Draggable from 'react-draggable'
import { useMutation } from 'react-query'
import { emrApi } from '../../api'
import { Config } from '../../config'
import * as FullStory from '../../utils/fullstory'
import { logger } from '../../utils/logger'
import { ActiveCallStatus } from './ActiveCallStatus'

const EMR_CALLING_UI_Z_INDEX = 300

const DeviceSelect = (props: SelectProps) => {
  const theme = useMantineTheme()
  return (
    <Select
      {...props}
      sx={{
        height: '36px',
        div: {
          boxShadow: 'none',
        },
        input: {
          textDecoration: 'underline',
          textDecorationColor: theme.colors.primary?.[0],
          textDecorationThickness: '2px',
        },
      }}
    />
  )
}

export type DialpadState = {
  dialpadOpen: boolean
  phoneNumber: string
  isPhoneNumberValid: boolean
  extension: string
  device: Device | null
  callStatus: Call.State | null
  call: Call | null
  callError: boolean
  selectedInputDevice: MediaDeviceInfo['deviceId'] | null
  inputDevices: MediaDeviceInfo[]
  selectedOutputDevice: MediaDeviceInfo['deviceId'] | null
  outputDevices: MediaDeviceInfo[]
}

type DialpadAction = {
  type:
    | 'CLICK_PHONE_BUTTON'
    | 'CLOSE_DIALPAD'
    | 'UPDATE_PHONE_NUMBER'
    | 'UPDATE_EXTENSION'
    | 'SET_ACTIVE_CALL'
    | 'UPDATE_CALL_STATUS'
    | 'TRIGGER_CALL_ERROR'
    | 'UPDATE_AUDIO_DEVICES'
    | 'SELECT_INPUT_DEVICE'
    | 'SELECT_OUTPUT_DEVICE'
  payload: Partial<DialpadState>
}

const INITIAL_STATE: DialpadState = {
  dialpadOpen: false,
  phoneNumber: '',
  isPhoneNumberValid: false,
  extension: '',
  device: null,
  call: null,
  callStatus: null,
  callError: false,
  selectedInputDevice: null,
  inputDevices: [],
  selectedOutputDevice: null,
  outputDevices: [],
}

const dialpadReducer = (prevState: DialpadState, action: DialpadAction): DialpadState => {
  const { type, payload } = action
  switch (type) {
    case 'CLOSE_DIALPAD':
      prevState.device?.destroy()
      return INITIAL_STATE
    case 'TRIGGER_CALL_ERROR':
      /*
       * An error has occurred during the initialization of the Twilio
       * Voice SDK, or during the VoIP call
       */
      return {
        ...prevState,
        callError: true,
      }
    case 'UPDATE_PHONE_NUMBER': {
      // The phone number input value has changed
      const normalizedPhone = phone(payload.phoneNumber).normalized
      // 12 = 1 plus symbol + 1 country code digit + 10 phone digits
      const VALID_PHONE_NUMBER_LENGTH = 12
      const isPhoneNumberValid = normalizedPhone.length === VALID_PHONE_NUMBER_LENGTH
      return {
        ...prevState,
        isPhoneNumberValid,
        phoneNumber: payload.phoneNumber || '',
      }
    }
    case 'UPDATE_EXTENSION': {
      // The extension number input value has changed
      return {
        ...prevState,
        extension: payload.extension || '',
      }
    }
    case 'SET_ACTIVE_CALL':
      /*
       * A new call has been requested by the Twilio SDK.
       * In between this event and a call beginning, The Twilio API asks
       * Ophelia's /twilio/voip/call endpoint for TWIML instructions,
       * and then the Twilio System initiates the actual VoIP call
       */
      return {
        ...prevState,
        call: payload.call || null,
      }
    case 'UPDATE_CALL_STATUS': {
      const { callStatus } = payload

      if (callStatus === null || callStatus === Call.State.Closed) {
        prevState.call?.disconnect()
        prevState.device?.destroy()
        return INITIAL_STATE
      }

      // A change to the call state was detected
      return {
        ...prevState,
        callStatus: callStatus || null,
      }
    }
    case 'CLICK_PHONE_BUTTON':
      /**
       * This action handles the logic for any time the is clicked. The behavior
       * of this button depends on which one of four states the dialpad is in:
       */

      // State 1: Floating button is neutral, dialpad is closed -> Open the dialpad
      if (!prevState.dialpadOpen) {
        return {
          ...prevState,
          device: payload.device || null,
          dialpadOpen: true,
        }
      }
      // State 2: Floating button is neutral, dialpad is open -> Close the dialpad
      if (prevState.dialpadOpen && !prevState.isPhoneNumberValid) {
        prevState.device?.destroy()
        return INITIAL_STATE
      }

      /*
       * State 4: Floating button is red, dialpad is open, there is currently an
       * initializing call or an active call -> Hang up the call, close the dialpad
       */
      if (
        prevState.call &&
        prevState.callStatus &&
        [Call.State.Connecting, Call.State.Ringing, Call.State.Open].includes(prevState.callStatus)
      ) {
        prevState.call.disconnect()
        prevState.device?.destroy()
        return INITIAL_STATE
      }

      return prevState
    case 'UPDATE_AUDIO_DEVICES':
      return {
        ...prevState,
        inputDevices: payload.inputDevices || prevState.inputDevices,
        outputDevices: payload.outputDevices || prevState.outputDevices,
      }
    case 'SELECT_INPUT_DEVICE': {
      const selectedInputDevice = payload.selectedInputDevice || prevState.selectedInputDevice
      if (selectedInputDevice) {
        return {
          ...prevState,
          selectedInputDevice: payload.selectedInputDevice || prevState.selectedInputDevice,
        }
      }

      return prevState
    }
    case 'SELECT_OUTPUT_DEVICE': {
      const selectedOutputDevice = payload.selectedOutputDevice || prevState.selectedOutputDevice
      if (selectedOutputDevice) {
        void prevState.device?.audio?.speakerDevices.set([selectedOutputDevice])

        return {
          ...prevState,
          selectedOutputDevice,
        }
      }

      return prevState
    }
    default:
      return prevState
  }
}

export const EMRCalling = () => {
  const theme = useMantineTheme()
  const [initializing, setInitializing] = useState(false)
  const [dialpadState, dispatchDialpadAction] = useReducer(dialpadReducer, INITIAL_STATE)

  const { ref, hovered } = useHover()

  const inputDeviceOptions = dialpadState.inputDevices.map(device => ({
    label: device.label,
    value: device.deviceId,
  }))
  const outputDeviceOptions = dialpadState.outputDevices.map(device => ({
    label: device.label,
    value: device.deviceId,
  }))

  const initializeVoipDeviceMutation = useMutation(emrApi.getMutation('POST /voip/token'))

  const setupDevice = async (device: Device) => {
    const defaultInputDevice = device.audio?.availableInputDevices.get('default')?.deviceId
    if (defaultInputDevice) {
      await dialpadState.device?.audio?.setInputDevice(defaultInputDevice)
    }
    const outputDevices: MediaDeviceInfo[] = []
    device.audio?.availableOutputDevices.forEach(device => {
      outputDevices.push(device)
    })

    const inputDevices: MediaDeviceInfo[] = []
    device.audio?.availableInputDevices.forEach(device => {
      inputDevices.push(device)
    })

    dispatchDialpadAction({
      type: 'UPDATE_AUDIO_DEVICES',
      payload: {
        inputDevices,
        outputDevices,
      },
    })
    dispatchDialpadAction({
      type: 'SELECT_INPUT_DEVICE',
      payload: {
        selectedInputDevice: device.audio?.availableInputDevices.get('default')?.deviceId || null,
      },
    })
    dispatchDialpadAction({
      type: 'SELECT_OUTPUT_DEVICE',
      payload: {
        selectedOutputDevice: device.audio?.availableOutputDevices.get('default')?.deviceId || null,
      },
    })
  }

  const addDeviceListeners = (device: Device) => {
    if (!device?.audio) {
      logger.error(
        `Error initializing Twilio Voice Device: Audio I/O is not enabled on this browser`,
      )
      return dispatchDialpadAction({ type: 'TRIGGER_CALL_ERROR', payload: {} })
    }

    device.on('error', error => {
      logger.error(
        `Error initializing Twilio Voice Device: Error event occurred after initialization`,
        { error },
      )
      return dispatchDialpadAction({ type: 'TRIGGER_CALL_ERROR', payload: {} })
    })
  }

  const startCall = async () => {
    // Make sure a call has been started, and there is no existing connected Twilio Call
    if (dialpadState.callStatus === null && !dialpadState.call) {
      if (!dialpadState.device?.audio) {
        return dispatchDialpadAction({ type: 'TRIGGER_CALL_ERROR', payload: {} })
      }

      const normalizedPhone = phone(dialpadState.phoneNumber).normalized
      const call = await dialpadState.device.connect({ params: { To: normalizedPhone } })
      dispatchDialpadAction({ type: 'SET_ACTIVE_CALL', payload: { call } })

      call.on('ringing', () => {
        dispatchDialpadAction({
          type: 'UPDATE_CALL_STATUS',
          payload: { callStatus: call.status() },
        })
        FullStory.event('EMR Call Ringing')
      })
      call.on('accept', () => {
        dispatchDialpadAction({
          type: 'UPDATE_CALL_STATUS',
          payload: { callStatus: call.status() },
        })
        FullStory.event('EMR Call Accepted')

        if (dialpadState.extension) {
          /*
           * "w" represents a 500ms wait https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callsenddigitsdigits
           * Twilio recommends sending a few along with an extension when dialing a number:
           * https://www.twilio.com/docs/voice/twiml/number?code-sample=code-using-senddigits&code-language=Node.js&code-sdk-version=4.x
           */
          call.sendDigits(`wwww${dialpadState.extension}`)
        }
      })
      call.on('disconnect', () => {
        dispatchDialpadAction({
          type: 'UPDATE_CALL_STATUS',
          payload: { callStatus: call.status() },
        })
        FullStory.event('EMR Call Ended')
      })
      call.on('cancel', () => {
        dispatchDialpadAction({
          type: 'UPDATE_CALL_STATUS',
          payload: { callStatus: call.status() },
        })
        FullStory.event('EMR Call Canceled')
      })
    }
  }

  const getFloatingCallButtonColors = () => {
    if (dialpadState.call) {
      return {
        icon: 'white',
        background: theme.colors.red[0],
      }
    }
    if (dialpadState.isPhoneNumberValid) {
      return {
        icon: 'black',
        background: theme.colors.green[0],
      }
    }
    return {
      icon: 'black',
      background: theme.colors.primary?.[0],
    }
  }

  const handlePhoneButtonPress = async () => {
    if (
      dialpadState.dialpadOpen &&
      dialpadState.isPhoneNumberValid &&
      dialpadState.callStatus === null &&
      !dialpadState.call
    ) {
      // Phone button is green, start the call
      return startCall()
    }

    if (dialpadState.dialpadOpen) {
      dispatchDialpadAction({ type: 'CLICK_PHONE_BUTTON', payload: {} })
      return
    }

    try {
      setInitializing(true)
      const { token } = await initializeVoipDeviceMutation.mutateAsync({})
      const device = new Device(token, {
        // Log level 5 = SILENT, 1 = DEBUG
        logLevel: Config.ENV === 'production' ? 5 : 1,
        // Recommended by Twilio documentation
        codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        /*
         * Presents the user with a confirmation modal if they are about
         * to perform a browser-level navigation while a call is active
         */
        closeProtection: true,
      })

      await device.register()
      await setupDevice(device)
      addDeviceListeners(device)

      dispatchDialpadAction({ type: 'CLICK_PHONE_BUTTON', payload: { device } })
    } catch (error) {
      logger.error(`Error initializing Twilio Voice Device: Error occured during initialization`, {
        error,
      })
      dispatchDialpadAction({ type: 'TRIGGER_CALL_ERROR', payload: {} })
    }
    setInitializing(false)
  }

  const dialpad = (
    <Stack spacing='xs' sx={{ display: 'relative' }}>
      <CloseButton
        onClick={() => dispatchDialpadAction({ type: 'CLOSE_DIALPAD', payload: {} })}
        sx={{ position: 'absolute', right: 8, top: 16 }}
      />
      <Grid gutter='sm' align='end'>
        <Grid.Col span={9}>
          <PhoneInput
            label={dialpadState.device ? 'Phone number' : 'Initializing...'}
            value={dialpadState.phoneNumber}
            disabled={!dialpadState.device}
            onChange={updatedPhoneNumber =>
              dispatchDialpadAction({
                type: 'UPDATE_PHONE_NUMBER',
                payload: { phoneNumber: updatedPhoneNumber },
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            value={dialpadState.extension}
            disabled={!dialpadState.device}
            onChange={updatedExtension =>
              dispatchDialpadAction({
                type: 'UPDATE_EXTENSION',
                payload: { extension: updatedExtension },
              })
            }
            placeholder='ext.'
          />
        </Grid.Col>
      </Grid>
      <Stack spacing='0'>
        <DeviceSelect
          icon={<VolumeIcon />}
          onChange={deviceId => {
            dispatchDialpadAction({
              type: 'SELECT_OUTPUT_DEVICE',
              payload: {
                selectedOutputDevice: deviceId,
              },
            })
          }}
          defaultValue={dialpadState.device?.audio?.availableOutputDevices.get('default')?.deviceId}
          value={dialpadState.selectedOutputDevice}
          data={outputDeviceOptions}
          placeholder='Select audio output'
        />
        <DeviceSelect
          icon={<MicIcon />}
          onChange={async deviceId => {
            if (deviceId) {
              await dialpadState.device?.audio?.setInputDevice(deviceId)
            }

            dispatchDialpadAction({
              type: 'SELECT_INPUT_DEVICE',
              payload: {
                selectedInputDevice: deviceId,
              },
            })
          }}
          defaultValue={dialpadState.device?.audio?.availableInputDevices.get('default')?.deviceId}
          value={dialpadState.selectedInputDevice}
          data={inputDeviceOptions}
          placeholder='Select audio input'
        />
      </Stack>
    </Stack>
  )

  const dialpadContent = (
    <Stack
      p='md'
      sx={{
        background: theme.other.colors.background[0],
        boxShadow: theme.shadows.sm,
        borderRadius: theme.radius.md,
      }}
      spacing='xs'
    >
      {dialpadState.callError &&
        (dialpadState.call ? (
          <Text>Call failed—try again</Text>
        ) : (
          <Text>Phone setup failed—try again</Text>
        ))}
      {dialpadState.call ? <ActiveCallStatus dialpadState={dialpadState} /> : dialpad}
    </Stack>
  )

  const viewportHeight = window.innerHeight
  const callingUiHeight = 300
  const topDraggableBounds = -(viewportHeight - callingUiHeight)

  return (
    <Draggable
      /**
       * 'handle' prop sets the CSS selector which designates
       * the element to control dragging. In this
       * case, we have a build a drag handle below, using
       * a Box component and two MoreVerticalIcon components
       */
      handle='.handle'
      /**
       * Similar to 'handle', the 'cancel' prop sets the
       * CSS selector which designates the element to cancel
       * dragging. In this case, we don't want users to
       * accidentally open the dialpad or make a call, when they
       * are just trying to drag the phone icon, so the ActionIcon
       * component below will cancel dragging
       */
      cancel='.cancel'
      // Only move the phone up and down
      axis='y'
      // Only allow the phone to move within the height of the dialpad for now
      bounds={{ top: topDraggableBounds, bottom: 0 }}
    >
      <Group
        position='right'
        spacing='xs'
        sx={{
          zIndex: EMR_CALLING_UI_Z_INDEX,
          position: 'relative',
        }}
      >
        {dialpadState.dialpadOpen && (
          <Box
            style={{
              position: 'fixed',
              bottom: 116,
              right: 60,
              width: 250,
              zIndex: EMR_CALLING_UI_Z_INDEX,
            }}
          >
            {dialpadContent}
          </Box>
        )}
        <Box ref={ref}>
          {hovered && (
            <Box
              className='handle'
              sx={({ other: { colors } }) => ({
                cursor: 'move',
                color: colors.actions[0],
              })}
            >
              <MoreVerticalIcon
                size='xl'
                style={{
                  position: 'fixed',
                  bottom: 76,
                  right: 61,
                }}
              />
              <MoreVerticalIcon
                size='xl'
                style={{
                  position: 'fixed',
                  bottom: 76,
                  right: 52,
                }}
              />
            </Box>
          )}
          <ActionIcon
            size='xl'
            className='cancel'
            radius='xl'
            style={{
              position: 'fixed',
              bottom: 72,
              right: 16,
              background: getFloatingCallButtonColors().background,
              transition: 'background-color 200ms linear',
            }}
            loading={initializing}
            onClick={handlePhoneButtonPress}
          >
            <PhoneIcon size='lg' color={getFloatingCallButtonColors().icon} />
          </ActionIcon>
        </Box>
      </Group>
    </Draggable>
  )
}
