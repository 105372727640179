import { Navigate, Route, Routes } from 'react-router-dom'
import RegistryContent from './RegistryContent'

const PRegsitry = () => {
  return (
    <Routes>
      <Route path='*' element={<Navigate replace to='/registry/case-review' />} />
      <Route path='/patient-panel' element={<RegistryContent />} />
      <Route path='/case-review' element={<RegistryContent />} />
    </Routes>
  )
}

export default PRegsitry
