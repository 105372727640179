import { useContext } from 'react'
import { LDContext } from '../../components/LaunchDarklyProvider'

export function useFlags() {
  const { ldClient } = useContext(LDContext)
  const flags = ldClient?.allFlags()

  return {
    unusedTime: flags?.unusedTime as boolean | undefined,
    overrides: flags?.overrides as boolean | undefined,
    unconfirmedAppointmentsPage: flags?.['unconfirmed-appointments-page'] as boolean | undefined,
    patientAndClinicianLocations: flags?.['patient-and-clinician-locations'] as boolean | undefined,
    isCarePathwayModalEnabled: flags?.['is-care-pathway-modal-enabled'] as boolean | undefined,
    techTeam: flags?.['tech-team'] as boolean | undefined,
    canUpdateCCM: flags?.canUpdateCCM as boolean | undefined,
    showPhq8Form: flags?.['show-phq-8-form'] as boolean | undefined,
    ccmTransitionSection: flags?.['ccm-transition-section'] as boolean | undefined,
    isPaymentPlanDrawerEnabled: flags?.['is-payment-plan-drawer-enabled'] as boolean | undefined,
    revampedCaseReviewNotes: flags?.['revamped-case-review-notes'] as boolean | undefined,
    bookedCandidates: flags?.['booked-candidates'] as boolean | undefined,
    emrCalling: flags?.['emr-calling'] as boolean | undefined,
    fortunaHealthPilotEmployees: flags?.['fortuna-health-pilot-ec-ids'] as string[] | undefined,
    outOfOffice: flags?.['out-of-office'] as boolean | undefined,
    paCoe: flags?.['pa-coe'] as boolean | undefined,
    delayScheduling: flags?.['visit-holds'] as boolean | undefined,
    mantineVisitNoteWelcomeCall: flags?.['mantine-visit-note-welcome-call'] as boolean | undefined,
    visitHoldsV2: flags?.['visit-holds-v-2'] as boolean | undefined,
    twilioStatus: flags?.['twilio-status'] as boolean | undefined,
    communicationsDrawer: flags?.['communications-drawer'] as boolean | undefined,
    ccReorg: flags?.['cc-reorg'] as boolean | undefined,
    canScheduleCustomTime: flags?.['custom-time-scheduling'] as boolean | undefined,
    outboundSms: flags?.['outbound-sms'] as boolean | undefined,
    outboundSmsAttachment: flags?.['outbound-sms-attachment'] as boolean | undefined,
    scheduledOutboundSms: flags?.['scheduled-outbound-sms'] as boolean | undefined,
    isSubstanceUseSectionEnabled: Boolean(flags?.['visit-note-substance-use-section']),
    communicationsPodFilter: Boolean(flags?.['communications-pod-filter']),
    udsCheckInSchedulingV2: Boolean(flags?.['uds-check-in-scheduling-v2']),
    smartPhrases: Boolean(flags?.['smart-phrases']),
    smartPhrasesRte: Boolean(flags?.['smart-phrases-rte']),
    qgendaMigration: Boolean(flags?.['qgenda-migration']),
  }
}

export function useLDClient() {
  return useContext(LDContext)
}
