import ANoteCheckboxPositiveInput from '../../../components/atoms/ANoteCheckboxPositiveInput'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import { ReactHookRegister, ReactHookWatch } from '../../../utils/reactHookForm'
import MReferralNoteSection from '../MReferralNoteSection'

export type MIneligibleNoteProps = {
  watch: ReactHookWatch
  register: ReactHookRegister
  sidePanel?: boolean
}

const MIneligibleNote = ({ register, watch, sidePanel = false }: MIneligibleNoteProps) => {
  const no_referral_request = watch('no_referral_request')
  const referral_request = watch('referral_request')
  const substance_user_or_psychiatric_ineligibility = watch(
    'substance_user_or_psychiatric_ineligibility',
  )

  return (
    <div
      className={`${
        sidePanel ? 'px-4 border-t border-gray-200' : ''
      } flex flex-col space-y-6 overflow-y-auto text-left pb-6`}
    >
      <ANoteSection hideBorder>
        <ANoteHeader text='Rationale for Ineligible' />
        <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
          <ANoteCheckboxPositiveInput
            name='not_interested_or_ready_to_begin'
            id='not_interested_or_ready_to_begin'
            label='Patient self-determined not interested/ready to begin treatment'
            ref={register()}
            textSize='text-sm'
            disabled={sidePanel}
          />
          <ANoteCheckboxPositiveInput
            name='substance_user_or_psychiatric_ineligibility'
            id='substance_user_or_psychiatric_ineligibility'
            label='Substance use or psychiatric ineligibility'
            ref={register()}
            textSize='text-sm'
            disabled={sidePanel}
          />
          <div
            className={`${
              substance_user_or_psychiatric_ineligibility ? 'ml-2 space-y-2' : 'hidden'
            }`}
          >
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_dsm_five'
              id='substance_user_or_psychiatric_ineligibility_dsm_five'
              label='Does not meet DSM-5 criteria for OUD'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_medically_unstable'
              id='substance_user_or_psychiatric_ineligibility_medically_unstable'
              label='Medically or psychiatrically unstable (e.g. active suicidality or psychosis)'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_severe_polysubstance_use'
              id='substance_user_or_psychiatric_ineligibility_severe_polysubstance_use'
              label='Severe polysubstance use with physiological dependence (e.g. IV cocaine/meth use + binge alcohol)'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_severe_polysubstance_use'
              id='substance_user_or_psychiatric_ineligibility_benzos_dependence'
              label='Physically dependent on benzodiazepines (see inclusion/exclusion criteria)'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_severe_polysubstance_use'
              id='substance_user_or_psychiatric_ineligibility_high_dose_methadone_program'
              label='Actively enrolled in methadone program with dose >80mg/d'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
            <ANoteCheckboxPositiveInput
              name='substance_user_or_psychiatric_ineligibility_other'
              id='substance_user_or_psychiatric_ineligibility_other'
              label='Other'
              ref={register()}
              labelFont='font-light'
              textSize='text-sm'
              disabled={sidePanel}
              className={`${substance_user_or_psychiatric_ineligibility ? '' : 'hidden'}`}
            />
          </div>
          <ANoteTextArea
            label='Explanation (for above)'
            name='ineligible_explanation'
            id='ineligible_explanation'
            minRows={2}
            ref={register()}
            disabled={sidePanel}
          />
        </ANoteSectionContent>
      </ANoteSection>
      <MReferralNoteSection
        register={register}
        watch={watch}
        referral_request={referral_request}
        no_referral_request={no_referral_request}
        sidePanel={sidePanel}
      />
      <ANoteSection>
        <ANoteHeader text='Additional Notes' />
        <ANoteSectionContent className='flex flex-col space-y-4 px-1'>
          <ANoteTextArea
            name='additional_notes'
            id='additional_notes'
            minRows={2}
            ref={register()}
            disabled={sidePanel}
          />
        </ANoteSectionContent>
      </ANoteSection>
    </div>
  )
}

export default MIneligibleNote
