import { Employee } from '@shared/types'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { employeesApi } from '../../api'
import IconClose from '../../components/icons/IconClose'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'

export type OInactiveEmployeeModalProps = {
  closeModal: () => void
  employeeID: string
}

const OInactiveEmployeeModal = ({ closeModal, employeeID }: OInactiveEmployeeModalProps) => {
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [errorStatus, setErrorStatus] = useState(false)

  const updateEmployee = useMutation(employeesApi.update, {
    onSuccess: () => {
      closeModal()
      navigate('/admin/employees')
    },
    onError: () => {
      setErrorStatus(true)
    },
  })

  const onSubmit = () => {
    setSaving(true)

    const data = { status: 'formerEmployee' } as
      | Employee<'clinician'>
      | Employee<'care-coordinator'>

    updateEmployee.mutate({ employeeId: employeeID, ...data })
  }

  return (
    <div className='z-50 fixed inset-0'>
      <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 block sm:p-0'>
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>&#8203;
        <div
          className='w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div className='absolute top-0 right-0'>
            <button
              className='focus:outline-none rounded-full flex justify-center items-center w-6 h-6 hover:bg-gray-100 m-2'
              onClick={closeModal}
            >
              <IconClose textColor='text-daybreak-text3' />
            </button>
          </div>
          <div className='flex flex-col items-center justify-start'>
            {!errorStatus && (
              <>
                <div className='flex flex-col items-start justify-start w-full'>
                  <p className='font-semibold leading-8 text-base'>
                    Are you sure you want to mark this employee as inactive? This employee&apos;s
                    information will no longer be accessible.
                  </p>
                </div>
                <div className='flex flex-row flex-grow-1 justify-center items-center w-full mt-5'>
                  <span className='flex w-full rounded-md shadow-sm'>
                    <ODSPrimaryButton
                      onClick={onSubmit}
                      message='Mark Inactive'
                      className='inline-flex justify-center w-full'
                      loading={saving}
                    />
                  </span>
                </div>
              </>
            )}
            {errorStatus && (
              <div className='flex flex-col'>
                <div className='flex flex-col items-start justify-start w-full mb-8'>
                  <p className='text-base leading-8'>
                    Unfortunately your request could not be submitted at this time, please send a
                    message to the{' '}
                    <a href='slack://channel?team=TRN06CLEM&id=C01083KPYJX'>
                      #clinical-product-support
                    </a>{' '}
                    channel in Slack.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OInactiveEmployeeModal
