import { useDisclosure } from '@mantine/hooks'
import {
  Collapse,
  Grid,
  Group,
  MicOffIcon,
  MinusIcon,
  PlusIcon,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Call } from '@twilio/voice-sdk'
import { useEffect } from 'react'
import { useStopwatch } from 'react-timer-hook'
import { DialpadState } from './EMRCalling'

export const ActiveCallStatus = ({ dialpadState }: { dialpadState: DialpadState }) => {
  const [opened, { toggle: toggleKeypad }] = useDisclosure(false)
  const { seconds, minutes, start: startStopwatch } = useStopwatch()

  const numberPadDigits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

  const handleDigitPress = (digit: string) => {
    dialpadState.call?.sendDigits(digit)
  }

  const handleToggleMute = () => {
    dialpadState.call?.mute(!dialpadState.call?.isMuted())
  }

  useEffect(() => {
    if (dialpadState.callStatus === Call.State.Open) {
      startStopwatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialpadState.callStatus])

  const getCallStatusText = () => {
    switch (dialpadState.callStatus) {
      case Call.State.Connecting:
        return 'Starting call...'
      case Call.State.Ringing:
        return 'Ringing...'
      case Call.State.Open:
        return `${minutes}:${String(seconds).padStart(2, '0')}`
      default:
        return ''
    }
  }

  return (
    <Stack spacing='sm'>
      <Text>{getCallStatusText()}</Text>
      <Text bold>{dialpadState.phoneNumber}</Text>
      <Group position='right' mt='sm'>
        <TertiaryButton
          size='sm'
          leftIcon={
            <MicOffIcon
              color={colors => (dialpadState.call?.isMuted() ? colors.error[0] : colors.actions[0])}
            />
          }
          onClick={handleToggleMute}
        >
          {dialpadState.call?.isMuted() ? 'Unmute' : 'Mute'}
        </TertiaryButton>{' '}
        <TertiaryButton
          size='sm'
          leftIcon={opened ? <MinusIcon /> : <PlusIcon />}
          onClick={toggleKeypad}
        >
          {opened ? 'Hide keypad' : 'Keypad'}
        </TertiaryButton>
      </Group>
      <Collapse in={opened}>
        <Grid grow gutter='sm'>
          {numberPadDigits.map(digit => (
            <Grid.Col key={digit} span={4}>
              <PrimaryButton w='100%' onClick={() => handleDigitPress(digit)}>
                {digit}
              </PrimaryButton>
            </Grid.Col>
          ))}
        </Grid>
      </Collapse>
    </Stack>
  )
}
