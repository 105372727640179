import React, { ReactNode } from 'react'
import * as FullStory from '../../utils/fullstory'

type ErrorBoundaryProps = { fallback: ReactNode; children: ReactNode }
type ErrorBoundaryState = { hasError: boolean }

// This component is grabbed straight from React documentation: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    FullStory.event('EMR ErrorBoundary Rendered', {
      errorName: error.name,
      errorMessage: error.message,
      errorStack: error.stack || '',
      componentStack: info.componentStack,
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}
