import { useDisclosure } from '@mantine/hooks'
import {
  DownloadIcon,
  FeatherIcon,
  Group,
  LockIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  Tooltip,
  UnlockIcon,
  useMantineTheme,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import { usePatientVisitNote } from '../PatientVisitNoteContext'
import { PatientVisitNoteLockModal } from './PatientVisitNoteLockModal'
import { PatientVisitNoteUnlockNoteModal } from './PatientVisitNoteUnlockModal'

const getFormattedDateString = (iso: string | dayjs.Dayjs): string => {
  const date = dayjs(iso)

  // There are some invalid formatted dates in older visits
  if (typeof iso === 'string' && !date.isValid()) {
    return iso
  }

  if (date.isToday()) {
    return `today at ${date.format('h:mma z')}`
  }

  return date.format('dddd, MMMM D, YYYY hh:mma z')
}

const LockedVisitNoteUtilityBarContent = () => {
  const { lockedAt, lockedByName, isSidebar } = usePatientVisitNote()
  const {
    other: { colors, sizes },
  } = useMantineTheme()
  const [isUnlockNoteModalOpened, { open: openUnlockNoteModal, close: closeUnlockNoteModal }] =
    useDisclosure(false)

  return (
    <Group w='100%' position='apart' noWrap>
      <Stack spacing='xs'>
        {lockedAt && (
          <Group spacing='sm'>
            <LockIcon strokeWidth={sizes.border.lg} />
            <Text fz='sm'>Locked {getFormattedDateString(lockedAt)}</Text>
          </Group>
        )}
        {lockedByName && (
          <Group spacing='sm'>
            <FeatherIcon strokeWidth={sizes.border.lg} />
            <Text fz='sm'>Signed by {lockedByName}</Text>
          </Group>
        )}
      </Stack>
      {!isSidebar && (
        <Group spacing='sm'>
          <SecondaryButton size='sm' leftIcon={<UnlockIcon />} onClick={openUnlockNoteModal}>
            Unlock
          </SecondaryButton>
          <PrimaryButton
            size='sm'
            leftIcon={<DownloadIcon color={colors.text[0]} />}
          ></PrimaryButton>
        </Group>
      )}
      {isUnlockNoteModalOpened && <PatientVisitNoteUnlockNoteModal close={closeUnlockNoteModal} />}
    </Group>
  )
}

const UnlockedVisitNoteUtilityBarContent = () => {
  const { form, lastSavedAt, canEdit, saveVisitNote, saveVisitNoteIsLoading, isSidebar } =
    usePatientVisitNote()
  const [isLockNoteModalOpened, { open: openLockNoteModal, close: closeLockNoteModal }] =
    useDisclosure(false)

  const lockButton = (
    <PrimaryButton
      size='sm'
      leftIcon={form?.isValid() ? <FeatherIcon /> : <LockIcon />}
      disabled={!canEdit}
      onClick={() => {
        if (!form?.validate().hasErrors) {
          openLockNoteModal()
        }
      }}
    >
      Sign & lock
    </PrimaryButton>
  )

  return (
    <Group w='100%' position='apart' spacing='lg' noWrap>
      <Text fz='xs' color={colors => colors.text[1]}>
        {lastSavedAt
          ? `Last saved at ${dayjs(lastSavedAt).tz(dayjs.tz.guess()).format('MM/DD/YYYY h:mma z')}`
          : ''}
      </Text>

      {!isSidebar && (
        <Group spacing='sm'>
          <SecondaryButton
            size='sm'
            onClick={() => saveVisitNote?.()}
            loading={saveVisitNoteIsLoading}
            disabled={!canEdit}
          >
            Save
          </SecondaryButton>
          {form?.isValid() ? (
            lockButton
          ) : (
            <Tooltip label='Some required fields are incomplete'>{lockButton}</Tooltip>
          )}
        </Group>
      )}
      {isLockNoteModalOpened && <PatientVisitNoteLockModal close={closeLockNoteModal} />}
    </Group>
  )
}

export const PatientVisitNoteUtilityBar = () => {
  const { isLocked } = usePatientVisitNote()

  return isLocked ? <LockedVisitNoteUtilityBarContent /> : <UnlockedVisitNoteUtilityBarContent />
}
