import { BoxProps, MantineColor } from '@shared/components'
import { MessageFile } from './MessageFile'
import { MessageImage } from './MessageImage'

export type MessageAttachment = {
  externalId: string
  url: string
  type: 'image' | 'blob'
}

type MessageAttachmentsProps = {
  attachments: MessageAttachment[]
  backgroundColor: MantineColor
  textColor: MantineColor
} & BoxProps

export const MessageAttachments = ({
  attachments,
  backgroundColor,
  textColor,
  ...props
}: MessageAttachmentsProps) => {
  return (
    <>
      {attachments.map((attachment: MessageAttachment) =>
        attachment.type === 'image' ? (
          <MessageImage key={attachment.externalId} url={attachment.url} {...props} />
        ) : (
          <MessageFile
            key={attachment.externalId}
            url={attachment.url}
            backgroundColor={backgroundColor}
            textColor={textColor}
            {...props}
          />
        ),
      )}
    </>
  )
}
