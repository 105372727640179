import { Select, SelectItem, SelectProps } from '@shared/components'
import { LabeledData } from './LabeledData'

export type CardSelectProps = SelectProps & {
  editable: boolean
}

const getLabelByValue = (data: SelectProps['data'], value: string | null | undefined) => {
  if (data.every(item => typeof item === 'string')) {
    return value
  }
  const item = (data as SelectItem[]).find(item => item.value === value)
  return item?.label || value
}

export const CardSelect = ({ editable, ...props }: CardSelectProps) => {
  if (editable) {
    return <Select {...props} />
  }

  return (
    <LabeledData
      label={props.label}
      data={getLabelByValue(props.data, props.value)}
      hasError={Boolean(props.error)}
    />
  )
}
